import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DataEntity } from 'src/app/models/data-entities';
import { Requirement } from 'src/app/models/requirement';
import { ModalConfirmComponent } from '../../system/modal-confirm/modal-confirm.component';

@Component({
  selector: 'wm-requirement-editor',
  templateUrl: './requirement-editor.component.html',
  styleUrls: ['./requirement-editor.component.css']
})
export class RequirementEditorComponent implements OnInit, OnDestroy {
  @Input() requirement: Requirement;
  @Input() entities: DataEntity[];
  @Input() parentForm: UntypedFormGroup;
  @Input() isOpen = false;

  @Output() applied: EventEmitter<Requirement> = new EventEmitter<
    Requirement
  >();
  @Output() removed: EventEmitter<Requirement> = new EventEmitter<
    Requirement
  >();
  @Output() cancelled: EventEmitter<Requirement> = new EventEmitter<
    Requirement
  >();

  @ViewChild('confirmTemplateCodeChange')
  confirmTemplateCodeChange: ModalConfirmComponent;
  @ViewChild('deleteConfirmModal') deleteConfirmModal: ModalConfirmComponent;

  modifiedRequirement: Requirement;
  form: UntypedFormGroup;

  get label(): string {
    if (this.requirement) {
      return `${this.requirement.label || ''} {${this.requirement
        .templateCode || ''}}`;
    } else {
      return '';
    }
  }

  get formId() {
    return `requirement-${this.requirement.id}`;
  }

  get dataEntityLabel(): string {
    const entity = this.entities.find(
      de => de.templateCode === this.requirement.templateCode
    );

    return (entity && entity.label) || this.requirement.templateCode;
  }

  constructor() {}

  ngOnInit() {
    if (this.isOpen) {
      this.edit();
    }

    this.form = new UntypedFormGroup({
      label: new UntypedFormControl(this.requirement.label, Validators.required),
      entity: new UntypedFormControl(
        this.requirement.templateCode,
        Validators.required
      )
    });

    this.parentForm.addControl(this.formId, this.form);
  }

  ngOnDestroy() {
    this.parentForm.removeControl(this.formId);
  }

  openDeleteConfirmModal() {
    this.deleteConfirmModal.open();
  }

  deleteCancel() {
    this.deleteConfirmModal.cancel();
  }

  remove() {
    this.removed.emit(this.requirement);
  }

  cancel() {
    this.isOpen = false;
    this.cancelled.emit(this.requirement);
  }

  edit() {
    this.modifiedRequirement = { ...this.requirement };
    this.isOpen = true;
  }

  attemptApply() {
    if (
      this.requirement.templateCode &&
      this.requirement.templateCode !== '' &&
      this.modifiedRequirement.templateCode !== this.requirement.templateCode
    ) {
      this.confirmTemplateCodeChange.open();
    } else {
      this.apply();
    }
  }

  confirmTemplateCodeCancel() {
    this.confirmTemplateCodeChange.cancel();
  }

  apply() {
    Object.assign(this.requirement, this.modifiedRequirement);
    this.isOpen = false;
    this.applied.emit(this.requirement);
  }
}

<div *ngIf="queueItem">
  <div class="row">
    <div class="col">
      <div [innerHTML]="queueItem.evaluatedAgendaItem"></div>
    </div>
  </div>

  <div class="pl-3 pr-3">
    <hr />
  </div>

  <div class="row form-group">
    <div class="col">
      <label for="minutes">Minutes</label>
      <textarea
        class="form-control border-primary"
        id="minutes"
        rows="6"
        [(ngModel)]="queueItem.minutes"
      ></textarea>
    </div>
  </div>

  <div class="row">
    <div class="col-2">
      <div class="form-group">
        <label class="bmd-label-floating" for="status">
          Status
        </label>
        <select
          [(ngModel)]="queueItem.status"
          class="form-control"
          name="status"
        >
          <option value="1">Unheard</option>
          <option value="2">Tabled</option>
          <option value="3">Approved</option>
          <option value="4">Denied</option>
        </select>
      </div>
    </div>
    <div class="col-1"></div>
    <div class="col-3 mt-4">
      <a
        [routerLink]="[
          '/application/workflow-application',
          queueItem.applicationId
        ]"
        [queryParams]="{ returnURL: currentUrl }"
        target="blank"
      >
        <h6>View Application</h6>
      </a>
    </div>

    <div class="col-5 mt-4">
      <div *ngIf="queueItem.ballotPath && queueItem.ballotPath !== ''">
        Ballot:
        <a href="{{ queueItem.ballotPath }}" target="blank">
          {{ queueItem.ballotName }}
        </a>
      </div>
    </div>
    <div class="col-1 mt-3">
      <button
        type="button"
        class="btn btn-raised btn-success float-right"
        (click)="saveQueueItem()"
        [disabled]="isSaving"
      >
        {{ isSaving ? 'Saving...' : 'Save' }}
      </button>
    </div>
  </div>
</div>

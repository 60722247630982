import { DataEntity } from './data-entities';

export class WorkflowApplicationActivityDataAudit {
  id: string;
  workflowApplicationId: string;
  workflowApplicationActivityDataId: string;
  action: string;
  oldStatus: string;
  newStatus: string;
  oldValue: string;
  newValue: string;
  modifiedOnString: string;
  modifiedBy: string;
  activityType: string;
}

export class WorkflowApplicationEntityDataAudit {
  id: string;
  workflowApplicationId: string;
  workflowApplicationEntityDataId: string;
  workflowApplicationActivityDataAuditId?: string;
  action: string;
  oldValue: string;
  newValue: string;
  modifiedBy: string;
  modifiedOnString: String;
  entityType: string;
  templateCode: string;
  oldEntity: DataEntity;
  newEntity: DataEntity;
}

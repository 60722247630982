import { EmailActivityModel, EmailActivity } from './email-activity';

export class AppointmentActivityModel extends EmailActivityModel {
  dateDataEntity: string;
  timeDataEntity: string;
  location: string;
  durationMinutes: number;
  allDay: boolean;
  messages: string[];
}

export class AppointmentActivity extends EmailActivity<
  AppointmentActivityModel
> {
  constructor(options?: Partial<AppointmentActivity>) {
    super(options);

    this.model = new AppointmentActivityModel();
    if (options) {
      Object.assign(this, options);
    }

    this.model = new AppointmentActivityModel(options ? options.model : null);

    this.model.screenName = this.model.screenName || 'Appointment';
  }
}

<form [formGroup]="form" *ngIf="entity && entity.visibleToUser" novalidate>
  <div [ngClass]="divClass">
    <wm-de-label [entity]="entity" [for]="entity.templateCode"></wm-de-label>
    <div class="input-group mb-3">
      <div class="input-group-prepend">
        <span class="input-group-text">$</span>
      </div>
      <input
        *ngIf="!entity.isCalculating"
        type="number"
        [readonly]="!entity.isEditable"
        [ngClass]="inputClass"
        [formControlName]="entity.templateCode"
        [(ngModel)]="modelValue"
        [name]="entity.templateCode"
        [id]="entity.templateCode"
      />
      <app-loader *ngIf="entity.isCalculating"></app-loader>
    </div>
  </div>
</form>

import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Client, Contact } from '../../../../models';
import {
  UntypedFormGroup,
  AbstractControl,
  UntypedFormBuilder,
  Validators
} from '@angular/forms';
import {
  ClientService,
  WorkflowContextService,
  ValidationService
} from '../../../../services';
import { Location } from '@angular/common';
import { map } from 'rxjs/operators';
import { ContactAddress, ContactEmailAddress } from '../../../../models';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'wm-contact-detail-view',
  templateUrl: './contact-detail-view.component.html',
  styleUrls: ['./contact-detail-view.component.css']
})
export class ContactDetailViewComponent implements OnInit {
  form: UntypedFormGroup;
  @Input() showGoBackToList = true;
  contact: Contact;
  client: Client;

  contactId: string;
  firstName: AbstractControl;
  lastName: AbstractControl;
  phoneNumber: AbstractControl;
  emailAddress: AbstractControl;
  title: AbstractControl;
  officeHours: AbstractControl;

  constructor(
    public context: WorkflowContextService,
    private _clientSvc: ClientService,
    private route: ActivatedRoute,
    private _router: Router,
    private fb: UntypedFormBuilder,
    private _location: Location,
    private toastr: ToastrService,
    private _context: WorkflowContextService
  ) {
    if (fb) {
      this.form = this.fb.group({
        firstName: [''],
        lastName: [''],
        phoneNumber: [
          '',
          Validators.compose([ValidationService.phoneNumberValidator])
        ],
        emailAddress: [
          '',
          Validators.compose([ValidationService.emailValidator])
        ],
        title: [''],
        officeHours: ['']
      });
      //#endregion
      this.firstName = this.form.controls['firstName'];
      this.lastName = this.form.controls['lastName'];
      this.phoneNumber = this.form.controls['phoneNumber'];

      this.title = this.form.controls['title'];
      this.officeHours = this.form.controls['officeHours'];
    }
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.contactId = params['id'];
      if (this.contactId) {
        const z = this._clientSvc.getContact(this.contactId);

        z.pipe(
          map(contact => {
            this.contact = contact;
            if (this.contact.emailAddress == null) {
              this.contact.emailAddress = new ContactEmailAddress();
            }
            if (this.contact.address == null) {
              this.contact.address = new ContactAddress();
            }
            return this.contact;
          })
        ).subscribe(res => {
          this.contact = res;
        });
      } else {
        this.contact = this._clientSvc.createContact();
      }
    });
  }

  save() {
    this.contact.client = this.context.client;
    this._clientSvc.saveContact(this.contact).subscribe(res => {
      // navigate back to the list when the save is finished
      this.toastr.success('Saved!');
      this._router.navigate([
        'admin',
        'jurisdiction',
        this._context.client.id,
        'settings',
        'contacts'
      ]);
    });
  }

  removeContact() {
    this._clientSvc.removeContact(this.contact).subscribe(res => {
      this.toastr.success('Removed!');
      // navigate back to the list when the save is finished
      this._router.navigate([
        'admin',
        'jurisdiction',
        this._context.client.id,
        'settings',
        'contacts'
      ]);
    });
  }

  canTest() {
    return true;
  }

  goBack() {
    this._location.back();
  }

  canRemove() {
    return !!this.contactId;
  }
}

import { Injectable, forwardRef, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Utilities } from 'src/app/services/index';
import { WorkflowContextService } from 'src/app/services/workflow-context.service';
import {
  SavedFilter,
  StoredFilter,
  IFilterStorageAdapter
} from 'src/app/models/saved-filter';

@Injectable()
export class FilterService implements IFilterStorageAdapter {
  constructor(
    @Inject(forwardRef(() => WorkflowContextService))
    private _context: WorkflowContextService,
    @Inject(forwardRef(() => HttpClient)) private _http: HttpClient
  ) {}

  async upsert(id: string, filter: SavedFilter): Promise<SavedFilter> {
    const storedFilter = SavedFilter.store(filter);
    const savedFilter = await Utilities.postData(
      this._context,
      this._http,
      StoredFilter,
      '/api/client/filters/upsert',
      storedFilter
    ).toPromise();

    return SavedFilter.restore(savedFilter);
  }

  async list(clientId: string, type: string): Promise<SavedFilter[]> {
    const filters = await Utilities.getDataList(
      this._context,
      this._http,
      StoredFilter,
      '/api/client/filters',
      { clientId, type }
    ).toPromise();

    return filters.map(f => SavedFilter.restore(f));
  }

  async destroy(id: string): Promise<void> {
    await Utilities.postData(
      this._context,
      this._http,
      null,
      '/api/client/filters/destroy',
      null,
      null,
      null,
      { filterId: id }
    ).toPromise();
  }
}

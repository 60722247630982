import { ContractorDocumentCategory } from './contractor-document-category';

export class ContractorRegistrationDocument {
  id: string;
  documentName: string;
  registrationId: string;
  categoryId?: string = null;
  category?: ContractorDocumentCategory;
  documentPath: string;
  createdOn: string;
  createdBy: string;
  modifiedOn: string;
  modifiedBy: string;
  editing: boolean;

  constructor(options?: Partial<ContractorRegistrationDocument>) {
    if (options) {
      Object.assign(this, options);
    }
  }
}

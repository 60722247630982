<ng-container *ngIf="registration">
  <div class="row my-4">
    <div class="col">
      <div class="card">
        <div class="card-body">
          <h6 class="card-title">
            {{ registration.workflowName }}
            <span class="badge badge-pill badge-secondary">
              {{ registrationStatus[registration.status] }}
            </span>
          </h6>
          <a
            *ngIf="registration.paymentRequest"
            [href]="
              apiBase + '/files/receipt/' + registration.paymentRequest.id
            "
            class="btn btn-raised btn-primary"
          >
            View receipt
          </a>
          <div class="form-group">
            <label for="createdOn" class="bmd-label-static">
              Registered On
            </label>
            <input
              type="text"
              readonly
              class="form-control-plaintext"
              id="createdOn"
              [value]="registration.registeredOnString"
            />
          </div>
          <div class="form-group">
            <label for="approvedOn" class="bmd-label-static">Approved On</label>
            <input
              type="text"
              readonly
              class="form-control-plaintext"
              id="approvedOn"
              [value]="registration.approvedOnString"
            />
          </div>
          <div class="form-group">
            <label for="effectiveOn" class="bmd-label-static">
              Effective On
            </label>
            <input
              type="text"
              readonly
              class="form-control-plaintext"
              id="effectiveOn"
              [value]="registration.effectiveOnString"
            />
          </div>
          <div class="form-group">
            <label for="expiresOn" class="bmd-label-static">Expires On</label>
            <input
              type="text"
              readonly
              class="form-control-plaintext"
              id="expiresOn"
              [value]="registration.expiresOnString"
            />
          </div>
          <div class="form-group">
            <label for="paymentMethod" class="bmd-label-static">
              Payment Method
            </label>
            <input
              type="text"
              readonly
              class="form-control-plaintext"
              id="paymentMethod"
              [value]="registration.paymentRequest?.paymentMethod"
            />
          </div>
          <div class="form-group">
            <label for="paymentAmount" class="bmd-label-static">
              Payment Amount
            </label>
            <input
              type="text"
              readonly
              class="form-control-plaintext"
              id="paymentAmount"
              [value]="registration.paymentRequest?.requestedAmount | currency"
            />
            <a
              (click)="viewReceipt()"
              style="cursor: pointer"
              *ngIf="registration.paymentRequest"
            >
              View Receipt
            </a>
          </div>
          <div class="form-group" *ngIf="registration.certificateDocument">
            <a
              [href]="getAzureDocumentPath(registration.certificateDocument)"
              target="_blank"
            >
              View Certificate
            </a>
          </div>
        </div>
      </div>

      <div class="card mt-4">
        <div class="card-body">
          <h6 class="card-title">
            Registration Documents
          </h6>
          <wm-renewal-document-list
            [documents]="documents"
            (saved)="saveDocument($event)"
            [parentId]="registrationId"
            (deleted)="deleteDocument($event)"
            (added)="addNewDocuments($event)"
          ></wm-renewal-document-list>
        </div>
      </div>
    </div>

    <div class="col">
      <div class="card mb-4" *ngIf="requirementFields && requirementFields.length > 0">
        <div class="card-body">
          <h6 class="card-title">Requirements</h6>

          <app-loader *ngIf="!registration.workflow"></app-loader>
          <wm-renewal-custom-field-list
            *ngIf="requirementFields"
            [customFields]="requirementFields"
            [form]="requirementForm"
            #customFields
          ></wm-renewal-custom-field-list>

          <button
            class="btn btn-raised btn-success float-right"
            (click)="save()"
            [disabled]="requirementForm.invalid || savingRequirements"
          >
            <span>{{ savingRequirements ? 'Saving...' : 'Save' }}</span>
          </button>
        </div>
      </div>

      <div
        class="card mb-4"
        *ngIf="
          registration.customFields && registration.customFields.length > 0
        "
      >
        <div class="card-body">
          <h6 class="card-title">Additional Fields</h6>
          <wm-renewal-custom-field-list
            [customFields]="registration.customFields"
            [form]="customFieldForm"
            [isReadOnly]="true"
          ></wm-renewal-custom-field-list>
        </div>
      </div>
    </div>
  </div>
  <div class="row my-4">
    <div class="col">
      <div class="card">
        <div class="card-body">
          <h6 class="card-title">
            Notes
          </h6>
          <wm-modal-confirm
            title="Add note"
            commandText="Add note"
            [showButtonText]="true"
            buttonText="Add note"
            (accepted)="addNote()"
            (canceled)="cancelNote()"
          >
            <input type="text" class="form-control" [(ngModel)]="manualNote" />
          </wm-modal-confirm>
          <table class="table">
            <tr>
              <th>Notes</th>
              <th>Created By</th>
              <th>Created On</th>
            </tr>
            <tr *ngFor="let note of registration.notes">
              <td>{{ note.notes }}</td>
              <td>{{ note.createdBy }}</td>
              <td>{{ note.createdOn | date: 'M/d/yyyy h:mm a' }}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</ng-container>

import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { Actions, Role } from 'src/app/models';
import {
  InviteUserRequest,
  InviteUserResponse,
  InvitedUser
} from 'src/app/models/invited-user';
import { SecurityService, WorkflowContextService } from 'src/app/services';

@Component({
  selector: 'wm-invite-user',
  templateUrl: './invite-user.component.html',
  styleUrls: ['./invite-user.component.css']
})
export class InviteUserComponent implements OnInit, OnDestroy {
  availableRoles: Role[];
  selectedRoles: Role[];
  canManageUsers: boolean;
  canManageRoles: boolean;
  email: string;
  form: UntypedFormGroup;

  clientSub: Subscription;
  response: InviteUserResponse;
  selectedRoleIds: { [key: string]: Role } = {};

  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private _securitySvc: SecurityService,
    private _context: WorkflowContextService
  ) {}

  ngOnDestroy(): void {
    if (this.clientSub) {
      this.clientSub.unsubscribe();
    }
  }

  ngOnInit() {
    this.selectedRoles = [];

    this.form = new UntypedFormGroup({
      email: new UntypedFormControl('', [Validators.required, Validators.email])
    });

    this._securitySvc.isLoginEntitled(Actions.ROLE_MANAGE).subscribe(result => {
      this.canManageRoles = result;
    });
    this._securitySvc.isLoginEntitled(Actions.USER_MANAGE).subscribe(result => {
      this.canManageUsers = result;
    });

    const init = () => {
      this._securitySvc.getRoles(this._context.client).subscribe(roles => {
        this.availableRoles = roles;
      });
    };

    if (this._context.client) {
      init();
    } else {
      this.clientSub = this._context.client$.subscribe(() => {
        init();
      });
    }
  }

  isRoleSelected(id) {
    return this.selectedRoleIds && this.selectedRoleIds[id] != null;
  }

  toggleRole(e) {
    const roleId = e.target.value;
    const role = this.availableRoles.find(r => r.id === roleId);
    const selectedRole = this.selectedRoles.find(r => r.id === roleId);

    if (e.target.checked) {
      if (!selectedRole) {
        this.selectedRoles.push(role);
        this.selectedRoleIds[role.id] = role;
      }
    } else {
      this.selectedRoles = this.selectedRoles.filter(r => r.id !== roleId);
      this.selectedRoleIds[role.id] = null;
    }
  }

  inviteUser() {
    if (this.form.valid) {
      const request = new InviteUserRequest({
        email: this.email,
        roles: this.selectedRoles
      });

      this._securitySvc.inviteUser(request).subscribe(result => {
        this.response = result;
      });
    } else {
      this.validateAllFormFields(this.form);
    }
  }

  private validateAllFormFields(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  inviteAnotherUser() {
    this.response = null;
    this.selectedRoles = [];
    this.email = null;
    this.selectedRoleIds = {};
  }
}

export class ExportEntityOption {
  name: string;
  header: string;
  isGeoJSON: boolean;
  includeInExport: boolean;
  checked: boolean;
}
export class ItemSearchOptionFieldOption {
  title = '';
  selected: boolean;
  filterText: string;
  searchText: string;
  strOperator: string;
  searchOptionMethod? = '';
  inputType?: string;
  dataEntityTypeCode?: string;
  constructor(options?: Partial<ItemSearchOptionFieldOption>) {
    if (options) {
      Object.assign(this, options);
    }
  }
}

export class ItemSearchOptionField {
  title = 'FieldName';
  options: ItemSearchOptionFieldOption[] = [];
  constructor(options?: Partial<ItemSearchOptionField>) {
    if (options) {
      Object.assign(this, options);
    }
  }
}

export class ItemSearchUtils {
  static findByName(
    filters: ItemSearchOptionField[],
    name: string
  ): ItemSearchOptionField {
    return filters.find((v, i, o) => v.title === name);
  }

  static mergeFilters(
    inputFilter: ItemSearchOptionField[],
    staticFilter: ItemSearchOptionField[]
  ): ItemSearchOptionField[] {
    if (!staticFilter) {
      return inputFilter || [];
    }

    if (!inputFilter) {
      return staticFilter;
    }

    const newFilter: ItemSearchOptionField[] = [];
    staticFilter.forEach(sfo => newFilter.push(sfo));

    inputFilter.forEach(fo => {
      const item = ItemSearchUtils.findByName(staticFilter, fo.title);
      if (!item) {
        // only add the filters that aren't defined by the static filter
        newFilter.push(fo);
      }
    });

    return newFilter;
  }
}

export class ItemSearchOption {
  field: ItemSearchOptionField;
  constructor(options?: Partial<ItemSearchOption>) {
    if (options) {
      Object.assign(this, options);
    }
  }
}

export class ItemSearchFilter {
  public options: ItemSearchOption[] = [];
  constructor(options?: Partial<ItemSearchFilter>) {
    if (options) {
      Object.assign(this, options);
    }
  }
}

export class ItemSearchFilterBuilder {
  workflowFilter: ItemSearchFilter = null;

  constructor(Items: any[], options?: Partial<ItemSearchFilterBuilder>) {
    if (options) {
      Object.assign(this, options);
    }

    const workflowFilter = new ItemSearchFilter();
    // populate the filter with all the keys in a single applicationVM
    for (const prop in Items[0]) {
      if (prop !== '') {
        workflowFilter.options.push(<ItemSearchOption>{
          field: <ItemSearchOptionField>{ title: prop, options: [] }
        });
      }
    }

    Items.forEach(Item => {
      const o = workflowFilter.options.forEach(option => {
        for (const prop in Item) {
          if (option.field.title === prop) {
            // add this value to the filter options (ItemSearchOptionField) if it isn't already there
            const optionValue = option.field.options.filter(v => {
              if (v.title === Item[prop].value) {
                return v;
              }
            });

            if (optionValue.length === 0) {
              // make sure this option isn't already in the field options
              const testValue = option.field.options.filter(
                option_i => option_i.title === Item[prop]
              );
              if (testValue.length === 0) {
                option.field.options.push(<ItemSearchOptionFieldOption>{
                  title: Item[prop],
                  filterText: '',
                  searchText: '',
                  selected: false
                });
              }
            }
          }
        }
      });
    });
    this.workflowFilter = workflowFilter;
  }

  Build = function() {
    return this.workflowFilter;
  };
}

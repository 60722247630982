import {
  ScreenActivity,
  ActivityModel
} from '../../models/activities/activity';
import { MapDisplayConfiguration } from 'src/app/components/arcgis/map-display-configuration';

export class MapActivityModel extends ActivityModel {
  message = '';
  selectedGeometryWkt: string = null;
  mapExtent: any;

  mapConfig: MapDisplayConfiguration = new MapDisplayConfiguration();

  constructor(options?: Partial<MapActivityModel>) {
    super(options);
    if (options) {
      Object.assign(this, options);
    }
  }
}

export class MapActivity extends ScreenActivity<MapActivityModel> {
  constructor(options: { [key: string]: any }) {
    super(options);
    const modelOptions = options != null ? options.model : null;
    this.model = new MapActivityModel(modelOptions);
    this.type = 'map-activity';
    this.model.screenName = this.model.screenName || 'Map Locate';
  }

  public displayOnScreen(): boolean {
    return true;
  }
}

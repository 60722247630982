<div *ngIf="address">
  <form>
    <div class="form-row">
      <div class="col-12" *ngIf="showName">
        <div class="form-group">
          <label for="addressName" class="bmd-label-static"
            >Address Name (Work, Home, etc.)
            <span class="text-danger" *ngIf="required">*</span></label
          >
          <input
            type="text"
            class="form-control"
            [(ngModel)]="address.name"
            ngControl="addressName"
            ngRequired="required"
            name="addressName"
          />
        </div>
      </div>
      <div class="col-12">
        <div class="form-group">
          <label for="address1" class="bmd-label-static"
            >Street Address
            <span class="text-danger" *ngIf="required">*</span></label
          >
          <input
            type="text"
            class="form-control"
            [(ngModel)]="address.address1"
            ngControl="address1"
            ngRequired="required"
            name="address1"
          />
        </div>
      </div>
      <div class="col-12">
        <div class="form-group">
          <label for="address2" class="bmd-label-static">Address 2</label>
          <input
            type="text"
            class="form-control"
            [(ngModel)]="address.address2"
            ngControl="address2"
            name="address2"
          />
        </div>
      </div>

      <div class="form-group col-sm-6">
        <label for="addressCity" class="bmd-label-static"
          >City <span class="text-danger" *ngIf="required">*</span></label
        >
        <input
          type="text"
          class="form-control"
          [(ngModel)]="address.city"
          ngControl="addressCity"
          ngRequired="required"
          name="addressCity"
          id="addressCity"
        />
      </div>
      <div class="form-group col-sm-3">
        <label for="addressState" class="bmd-label-static"
          >State <span class="text-danger" *ngIf="required">*</span></label
        >
        <input
          type="text"
          class="form-control"
          [(ngModel)]="address.state"
          ngControl="addressState"
          ngRequired="required"
          name="addressState"
        />
      </div>
      <div class="form-group col-sm-3">
        <label for="addressZip" class="bmd-label-static"
          >Zip <span class="text-danger" *ngIf="required">*</span></label
        >
        <input
          type="text"
          class="form-control"
          [(ngModel)]="address.zip"
          ngControl="addressZip"
          ngRequired="required"
          name="addressZip"
        />
      </div>
    </div>
    <div class="row" *ngIf="showButtons">
      <div class="form-group col-sm-6">
        <button class="btn btn-primary btn-block" (click)="save()">
          Save
        </button>
        <wm-processing-status
          [status]="'Saving Address'"
          #saveAddressStatus
        ></wm-processing-status>
      </div>
      <div class="col-sm-6">
        <button class="btn btn-default btn-block" (click)="cancel()">
          Cancel
        </button>
      </div>
    </div>
  </form>
</div>

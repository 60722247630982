<div>
  <app-loader *ngIf="!groupedClients"></app-loader>

  <ul class="list-group" *ngIf="groupedClients && groupedClients.length === 0">
    <li class="list-group-item">
      No jurisdictions to display
    </li>
  </ul>

  <div *ngFor="let state of groupedClients">
    <h5>{{ state.state }}</h5>
    <ul class="list-group" *ngIf="state.clients && state.clients.length > 0">
      <div class="list-group-item" *ngFor="let client of state.clients">
        <div class="bmd-list-group-col">
          <a [routerLink]="['/admin/jurisdiction/', client.id]">
            <p class="list-group-item-heading">
              {{ client.name }}
            </p>
            <p class="list-group-item-text">
              {{ !client.isEnabled ? ' Disabled' : 'Enabled' }}
            </p>
          </a>
        </div>
      </div>
    </ul>
  </div>
</div>

import {
  Component,
  OnInit,
  Input,
  Optional,
  Self,
  SkipSelf,
  OnChanges,
  SimpleChanges,
  ChangeDetectorRef
} from '@angular/core';
import {
  PaymentProcessorAccountConfigurationBase,
  PaymentAccount
} from '../../../models';
import { Utilities } from '../../../services';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'wm-payment-account-config-base',
  templateUrl: './payment-account-config-base.component.html',
  styleUrls: ['./payment-account-config-base.component.css']
})
export class PaymentAccountConfigBaseComponent implements OnInit, OnChanges {
  @Input() config: PaymentProcessorAccountConfigurationBase;
  @Input() form: UntypedFormGroup;
  @Input() showUsername = true;
  @Input() showPassword = true;
  @Input() account: PaymentAccount;

  constructor(private _fb: UntypedFormBuilder) {}

  ngOnInit() {
    if (this.form) {
      this.setupUserNameValidation();
      this.setupPassword();
    }
  }

  addValidation(name: string, validator?: any, initialValue?: any) {
    if (!this.form.controls[name]) {
      this.form.addControl(
        name,
        this._fb.control(initialValue || '', validator)
      );
    }
  }

  removeValidation(name: string) {
    if (this.form.controls[name]) {
      this.form.removeControl(name);
    }
  }

  setupPassword() {
    if (this.showPassword && this.config) {
      this.addValidation('password', Validators.required, this.config.password);
    } else {
      this.removeValidation('password');
    }
  }

  setupUserNameValidation() {
    if (this.showUsername && this.config) {
      this.addValidation('userName', Validators.required, this.config.userName);
    } else {
      this.removeValidation('userName');
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setupUserNameValidation();
    this.setupPassword();
  }
}

import { PaymentProcessorAccountConfigurationBase } from '../payment-processor-account-configuration-base';

export class VpsPaymentProcessorAccountConfiguration extends PaymentProcessorAccountConfigurationBase {
  partnerId: string;
  lineItemDetailField: VPSLineItemDetail

  constructor(options?: Partial<VpsPaymentProcessorAccountConfiguration>) {
    super(options);
  }
}

export enum VPSLineItemDetail {
  Recipient = 1,
  ApplicationNumber = 2
}
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { PaymentAccountConfigBaseComponent } from './../payment-account-config-base/payment-account-config-base.component';
import { Component, Input, OnInit } from '@angular/core';
import { MunicipayPaymentProcessorAccountConfiguration } from 'src/app/models/payment-providers/municipay/municipay-payment-processor-account-configuration';

@Component({
  selector: 'wm-payment-account-config-municipay',
  templateUrl: './payment-account-config-municipay.component.html',
  styleUrls: ['./payment-account-config-municipay.component.css']
})
export class PaymentAccountConfigMunicipayComponent
  extends PaymentAccountConfigBaseComponent
  implements OnInit {
  @Input() config: MunicipayPaymentProcessorAccountConfiguration;

  constructor(_fb: UntypedFormBuilder) {
    super(_fb);
    this.showUsername = false;
    this.showPassword = false;
  }

  ngOnInit() {
    this.addValidation('siteId', Validators.required, this.config.siteId);
    this.addValidation('prodId', Validators.required, this.config.prodId);
    this.addValidation('urlKey', Validators.required, this.config.urlKey);
  }
}

<app-loader *ngIf="!reports"></app-loader>

<h3>Custom Reports</h3>

<table class="table">
  <tr>
    <th>Name</th>
  </tr>

  <tr *ngFor="let r of reports">
    <td class="align-middle">
      <a
        [routerLink]="[
          '/admin/jurisdiction',
          context.client.id,
          'reports',
          'custom-report',
          r.id
        ]"
        >{{ r.name }}
      </a>
      <p class="text-muted">{{ r.description }}</p>
    </td>
  </tr>

  <tr *ngIf="reports && reports.length === 0">
    <td>No reports to display</td>
  </tr>
</table>

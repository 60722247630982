import { PaymentProcessorConfigurationBase } from '../payment-processor-configuration-base';

export class eGOVPaymentProcessorConfiguration extends PaymentProcessorConfigurationBase {
  soapurl: string;

  constructor(options?: Partial<eGOVPaymentProcessorConfiguration>) {
    super(options);

    if (options) {
      Object.assign(this, options);
    }
  }
}

<div class="form-inline" [formGroup]="form">
  <div *ngIf="!isOpen">
    <span>
      {{ requirement.label }}
      (<code class="mr-0">{{ dataEntityLabel }}</code
      >)
    </span>
    <button
      *ngIf="!isOpen"
      class="btn btn-raised btn-primary mb-2 mr-sm-2"
      (click)="edit()"
    >
      Edit
    </button>
    <button
      *ngIf="!isOpen"
      class="btn btn-raised btn-danger mb-2 mr-sm-2"
      (click)="openDeleteConfirmModal()"
    >
      Delete
    </button>
  </div>
  <div *ngIf="isOpen">
    <label [for]="requirement.id + '-label'" class="sr-only">
      Label
    </label>
    <input
      type="text"
      class="form-control mb-2 mr-sm-2"
      formControlName="label"
      [(ngModel)]="modifiedRequirement.label"
      placeholder="Label"
    />
    <label [for]="requirement.id + '-entity'" class="sr-only">
      Entity
    </label>
    <select
      [(ngModel)]="modifiedRequirement.templateCode"
      class="form-control mb-2 mr-sm-2"
      formControlName="entity"
      [id]="requirement.id + '-entity'"
    >
      <option selected disabled [value]="undefined">
        Entity
      </option>
      <option *ngFor="let entity of entities" [value]="entity.templateCode">
        {{ entity.label }}
      </option>
    </select>

    <button
      class="btn btn-raised btn-success mb-2 mr-sm-2"
      [disabled]="form.invalid"
      (click)="attemptApply()"
    >
      Apply
    </button>
    <button
      class="btn btn-raised btn-secondary mb-2 mr-sm-2"
      (click)="cancel()"
    >
      Cancel
    </button>
  </div>
</div>

<wm-modal-confirm
  [showButton]="false"
  commandText="Yes"
  (accepted)="apply()"
  (canceled)="confirmTemplateCodeCancel()"
  title="Change Requirement Data Entity"
  #confirmTemplateCodeChange
>
  <p>
    If a new Data Entity is selected for this Requirement, the former Data
    Entity's values will no longer be associated with this Requirement. This may
    cause some registrations to become inactive.
  </p>
  <p>
    Are you sure you want to select a new Data Entity for this Requirement?
  </p>
</wm-modal-confirm>

<wm-modal-confirm
  [showButton]="false"
  commandText="Delete"
  (accepted)="remove()"
  (canceled)="deleteCancel()"
  title="Delete Requirement"
  #deleteConfirmModal
>
  <p>
    This Requirement will no longer apply to any registrations of this
    Contractor Type. This will cause any inactive registrations of this
    Contractor Type to potentially become active again.
  </p>
  <p>
    Are you sure you want to delete this Requirement?
  </p>
</wm-modal-confirm>

import { NumericDataEntity } from './../../../models/data-entities/numeric-data-entity';
import { DateDataEntity } from './../../../models/data-entities/date-data-entity';
import {
  DataEntityFactory,
  WorkflowService
} from './../../../services/workflow.service';
import { DataTableHelpers } from './../../system/datatable/datatable-helper';
import { ItemSearchOptionField } from './../../filter-list/models/filterClasses';
import {
  Component,
  OnInit,
  Input,
  ViewChild,
  Output,
  EventEmitter,
  TemplateRef
} from '@angular/core';
import { Actions } from 'src/app/models';
import { ModalConfirmComponent } from '../../system/modal-confirm/modal-confirm.component';
import { WorkflowContextService, SecurityService } from 'src/app/services';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { combineLatest, Subject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TableColumn } from '@swimlane/ngx-datatable';
import {
  FilterBuilderParam,
  FilterBuilderParamOpt
} from 'src/app/models/filter-builder';
import { DataEntity } from 'src/app/models/data-entities';
import { Command } from '../../contractors/contractor-registration-list/contractor-registration-list.component';
import {
  RegistrationVM,
  RegistrationStatus
} from 'src/app/models/registration';
import { RegistrationStatusPipe } from 'src/app/pipes/registration-status.pipe';
import { ColumnOption, GridSettings } from 'src/app/models/grid-settings';
import { ComponentIds } from 'src/app/models/component-ids';
import { Requirement } from 'src/app/models/requirement';

@Component({
  selector: 'wm-renewal-list',
  templateUrl: './renewal-list.component.html',
  styleUrls: ['./renewal-list.component.css']
})
export class RenewalListComponent implements OnInit {
  @Input() id = ComponentIds.RENEWALS_SEARCH;
  @Input() filterCriteria: FilterBuilderParam[];
  @Input() clientId: string;
  @Input() additionalCommands: Command[];
  @Input() public initialColumns: TableColumn[];
  @Input() public sort: { dir: string; prop: string } = {
    dir: 'desc',
    prop: 'registeredOn'
  };
  @Input() staticFilters: ItemSearchOptionField[] = [];
  @Input() dynamicColumnsVisibleByDefault = false;
  @Input() renderTitle = true;

  @Output() SortEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() filter: EventEmitter<any> = new EventEmitter<any>();
  @Output() recordCountSet = new EventEmitter<number>();
  @Output() filtersChanged = new EventEmitter<boolean>();

  @ViewChild('deleteRegModal', { static: true }) deleteRegModal: ModalConfirmComponent;
  @ViewChild('actionsTmpl', { static: true }) actionsTmpl: TemplateRef<any>;
  @ViewChild('disableRegModal', { static: true }) disableRegModal: ModalConfirmComponent;
  @ViewChild('enableRegModal', { static: true }) enableRegModal: ModalConfirmComponent;

  mappedRegistrations: any[];
  renewableRegistrations: RegistrationVM[] = [];
  requirements: Requirement[] = [];
  deleteRegistrationId: string;
  disableRegistrationId: string;
  enableRegistrationId: string;
  renewableWorkflowsOpts: FilterBuilderParamOpt[] = [];

  commands: Command[];

  canApprove = false;
  canDeny = false;
  canRenew = false;
  canEdit = false;
  unpermissibleCount = 0;

  public loading = false;
  public page = {
    // The number of elements in the page
    size: 10,
    // The total number of elements
    totalElements: 0,
    // The total number of pages
    totalPages: 0,
    // The current page number
    pageNumber: 0
  };
  public filterOptions: ItemSearchOptionField[] = [];

  customFields = {};

  columnsWithExtra: TableColumn[] = [];
  availableColumns: TableColumn[];
  columnOptions: ColumnOption[] = [];
  exportColumns: string[];
  dynamicColumnsSetSubject: Subject<TableColumn[]> = new Subject<
    TableColumn[]
  >();

  public useExcelFormat: boolean;

  // column prop strings to be hidden on the grid by default
  defaultHiddenColumns: string[] = [];
  // column prop strings that should have no option to hide in the grid, and are not exportable (such as button columns like Refund or Actions)
  actionColumns: string[] = ['actions'];
  // column prop strings to be excluded from exports by default
  defaultExportExludedColumns: string[] = [];

  constructor(
    private _workflowSvc: WorkflowService,
    private _router: Router,
    private _toastrSvc: ToastrService,
    private _context: WorkflowContextService,
    private dtHelpers: DataTableHelpers,
    private _securitySvc: SecurityService
  ) {}

  setColumns(columns: TableColumn[]) {
    this.columnsWithExtra = [...this.initialColumns, ...columns];

    this.columnsWithExtra.push({
      prop: 'actions',
      name: 'Actions',
      cellTemplate: this.actionsTmpl
    });

    this.setAvailableColumns();
  }

  setExtraColumns(columns: TableColumn[]) {
    for (const c of columns) {
      if (
        !this.availableColumns.find(
          ac => (ac.prop as string) === (c.prop as string)
        )
      ) {
        this.availableColumns.push(c);
      }
    }

    // create columnOptions from new available ones since datatable hasn't updated it at this point yet
    for (const ac of this.availableColumns) {
      if (
        !this.columnOptions.find(co => co.name === (ac.prop as string)) &&
        !this.actionColumns.includes(ac.prop as string)
      ) {
        const newOption = new ColumnOption({
          label: ac.name,
          name: ac.prop as string,
          checked: this.dynamicColumnsVisibleByDefault,
          includeInExport: true
        });

        this.columnOptions.push(newOption);
      }
    }

    const visibleColumnProps = this.columnOptions
      .filter(co => co.checked)
      .map(co => co.name);

    // filter columns on visibility while putting action columns at the end
    this.columnsWithExtra = [
      ...this.availableColumns.filter(c =>
        visibleColumnProps.includes(c.prop as string)
      ),
      ...this.availableColumns.filter(c =>
        this.actionColumns.includes(c.prop as string)
      )
    ];
  }

  setAvailableColumns() {
    this.availableColumns = this.columnsWithExtra;
  }

  handleSettingsChanged(event: GridSettings) {
    this.filterOptions = event.legacyFilters;
    this.page.size = event.pageSize;
    this.page.pageNumber = event.pageNumber;
    this.sort.prop = event.sortField;
    this.sort.dir = event.sortDescending ? 'desc' : 'asc';
    this.columnOptions = event.columnOptions;

    const visibleColumnProps = this.columnOptions
      .filter(co => co.checked)
      .map(co => co.name);

    // filter columns on visibility while putting action columns at the end
    this.columnsWithExtra = [
      ...this.availableColumns.filter(c =>
        visibleColumnProps.includes(c.prop as string)
      ),
      ...this.availableColumns.filter(c =>
        this.actionColumns.includes(c.prop as string)
      )
    ];

    this.exportColumns = this.columnOptions
      .filter(co => co.includeInExport)
      .map(co => co.name);

    this.searchRegistrations();

    this.filtersChanged.emit(true);
  }

  handleColumnOptionsChanged(event: ColumnOption[]) {
    this.columnOptions = event;

    this.exportColumns = this.columnOptions
      .filter(co => co.includeInExport)
      .map(co => co.name);
  }

  ngOnInit() {
    // this is initialized in OnInit so that the cell templates exist before trying to render it
    this.initialColumns = this.initialColumns || [
      {
        prop: 'appDescription',
        name: 'Registration Info'
      },
      {
        prop: 'workflowName',
        name: 'Type'
      },
      {
        prop: 'registeredOn',
        name: 'Registered On'
      },
      {
        prop: 'effectiveOn',
        name: 'Effective On'
      },
      {
        prop: 'expiresOn',
        name: 'Expires On'
      },
      {
        prop: 'status',
        name: 'Status',
        pipe: new RegistrationStatusPipe()
      }
    ];

    this.setColumns([]);

    this.loadPermissions();

    this.loadRenewableWorkflows().subscribe(() => {
      this.initFilters();
    });
  }

  initFilters() {
    this.filterCriteria = [
      {
        id: 'appDescription',
        name: 'Registration Info'
      },
      {
        id: 'registeredOn',
        name: 'Registered On',
        types: ['is', 'range'],
        inputType: 'date'
      },
      {
        id: 'effectiveOn',
        name: 'Effective On',
        types: ['is', 'range'],
        inputType: 'date'
      },
      {
        id: 'expiresOn',
        name: 'Expires On',
        types: ['is', 'range'],
        inputType: 'date'
      },
      {
        id: 'approvedOn',
        name: 'Approved On',
        types: ['is', 'range'],
        inputType: 'date'
      },
      {
        id: 'rejectedOn',
        name: 'Rejected On',
        types: ['is', 'range'],
        inputType: 'date'
      },
      {
        id: 'status',
        name: 'Status',
        types: ['is', 'in'],
        options: [
          {
            name: 'Pending',
            value: 'Pending'
          },
          {
            name: 'Approved',
            value: 'Approved'
          },
          {
            name: 'Denied',
            value: 'Denied'
          },
          {
            name: 'Expired',
            value: 'Expired'
          },
          {
            name: 'Inactive',
            value: 'Inactive'
          },
          {
            name: 'Deleted',
            value: 'Deleted'
          },
          {
            name: 'Renewed',
            value: 'Renewed'
          },
          {
            name: 'Disabled',
            value: 'Disabled'
          }
        ]
      },
      {
        id: 'notes',
        name: 'Notes'
      },
      {
        name: 'Renewal Type',
        id: 'renewalType',
        types: ['is', 'in'],
        options: this.renewableWorkflowsOpts
      }
    ];
  }

  private loadRenewableWorkflows(): Observable<any> {
    return this._workflowSvc
      .getRenewableWorkflows(this._context.client, false, false)
      .pipe(map(rws => {
        this.renewableWorkflowsOpts = rws.map(rw => {
          return { name: rw.version.name, value: rw.id };
        });
      }));
  }

  private loadPermissions() {
    combineLatest(
      this._securitySvc.isLoginEntitled(Actions.RENEWALS_APPROVE).pipe(
        map(e => {
          this.canApprove = e;
          return e;
        })
      ),
      this._securitySvc.isLoginEntitled(Actions.RENEWALS_DENY).pipe(
        map(e => {
          this.canDeny = e;
          return e;
        })
      ),
      this._securitySvc.isLoginEntitled(Actions.RENEWALS_RENEW).pipe(
        map(e => {
          this.canRenew = e;
          return e;
        })
      ),
      this._securitySvc.isLoginEntitled(Actions.RENEWALS_RENEW).pipe(
        map(e => {
          this.canEdit = e;
          return e;
        })
      )
    ).subscribe(() => {
      this.commands = [
        {
          title: 'View Details',
          canPerform: this.canViewDetails.bind(this),
          handler: this.viewDetails.bind(this)
        },
        {
          title: 'View Application',
          canPerform: this.canViewApplication.bind(this),
          handler: this.navigateToApplication.bind(this)
        },
        {
          title: 'Renew',
          canPerform: this.canRenewRegistration.bind(this),
          handler: this.renewRegistration.bind(this)
        },
        {
          title: 'Delete',
          canPerform: this.canDeleteRegistration.bind(this),
          handler: this.startDeleteRegistration.bind(this)
        },
        {
          title: 'Disable',
          canPerform: this.canDisableRegistration.bind(this),
          handler: this.startDisableRegistration.bind(this)
        },
        {
          title: 'Enable',
          canPerform: this.canEnableRegistration.bind(this),
          handler: this.startEnableRegistration.bind(this)
        }
      ];

      // add additional commands
      if (this.additionalCommands) {
        this.additionalCommands.forEach(command => {
          this.commands.push(command);
        });
      }
    });
  }

  searchRegistrations() {
    this.loading = true;
    this.renewableRegistrations = null;

    const sortDescending = this.sort.dir === 'desc' ? true : false;

    let filters = [...this.filterOptions];

    if (this.staticFilters) {
      filters = [...this.filterOptions, ...this.staticFilters];
    }

    return this._workflowSvc
      .searchRegistrationsPaged(
        this.clientId ||
          (this._context.client ? this._context.client.id : null),
        this.sort.prop,
        sortDescending,
        filters,
        this.page.pageNumber,
        this.page.size
      )
      .subscribe(
        result => {
          this.renewableRegistrations = result.registrations;
          this.requirements = result.requirements;
          this.unpermissibleCount = result.unpermissibleCount;
          this.page.totalPages = result.pageCount;
          this.page.totalElements = result.recordCount;

          this.recordCountSet.emit(this.page.totalElements);

          if (this.renewableRegistrations) {
            // add requirement fields from the registration to the custom fields of the grid
            if (this.requirements && this.requirements.length > 0) {
              // for (const registration of this.renewableRegistrations) {
              if (this.requirements && this.requirements.length > 0) {
                for (const requirement of this.requirements) {
                  this.customFields[requirement.templateCode] =
                    requirement.label;
                }
              }
              // }
            }

            const extraColumns = [];
            for (const field in this.customFields) {
              if (this.customFields[field]) {
                extraColumns.push({
                  name: this.customFields[field],
                  prop: field
                });
              }
            }

            this.setExtraColumns(extraColumns);

            this.dynamicColumnsSetSubject.next(this.availableColumns);

            const isSortCustomField: boolean =
              extraColumns.find(f => f.prop === this.sort.prop) != null;

            const customDataTypes: { [key: string]: string } = {};
            const registrations = this.renewableRegistrations.map(
              registration => {
                const customData = {};

                if (registration.workflowRequirements) {
                  for (const req of registration.workflowRequirements) {
                    const customField = registration.customFields.find(
                      f => f.templateCode === req.templateCode
                    );

                    if (customField) {
                      const fieldDE: DataEntity = DataEntityFactory.createDataEntity(
                        customField.dataEntityType,
                        {
                          value: customField.fieldValue,
                          label: customField.label,
                          templateCode: customField.templateCode
                        }
                      );

                      customDataTypes[customField.templateCode] =
                        fieldDE instanceof DateDataEntity
                          ? 'date'
                          : fieldDE instanceof NumericDataEntity
                          ? 'numeric'
                          : 'string';
                      customData[customField.templateCode] =
                        fieldDE.getFormattedValue() || 'N/A';
                    }
                  }
                }
                return {
                  id: registration.id,
                  createdApplicationId: registration.registerApplicationId,
                  appDescription: registration.appDescription,
                  workflowName: registration.workflowName,
                  registeredOn: registration.registeredOnString,
                  effectiveOn: registration.effectiveOnString,
                  expiresOn: registration.expiresOnString,
                  status: registration.status,
                  ...customData
                };
              }
            );

            if (isSortCustomField) {
              this.mappedRegistrations = registrations.sort((a, b) => {
                const dataType = customDataTypes[this.sort.prop];
                const aValue = (a[this.sort.prop] || '').trim().toLowerCase();
                const bValue = (b[this.sort.prop] || '').trim().toLowerCase();

                if (dataType === 'date') {
                  let sortValue = -1;

                  if (sortDescending) {
                    sortValue = !aValue
                      ? 1
                      : new Date(aValue) < new Date(bValue)
                      ? 1
                      : new Date(aValue) === new Date(bValue)
                      ? 0
                      : -1;
                  } else {
                    sortValue = !aValue
                      ? -1
                      : new Date(aValue) < new Date(bValue)
                      ? -1
                      : new Date(aValue) === new Date(bValue)
                      ? 0
                      : 1;
                  }
                  return sortValue;
                } else if (dataType === 'numeric') {
                  if (sortDescending) {
                    return !aValue
                      ? 1
                      : parseInt(aValue, 10) < parseInt(bValue, 10)
                      ? 1
                      : parseInt(aValue, 10) === parseInt(bValue, 10)
                      ? 0
                      : -1;
                  } else {
                    return !aValue
                      ? -1
                      : parseInt(aValue, 10) > parseInt(bValue, 10)
                      ? 1
                      : parseInt(aValue, 10) === parseInt(bValue, 10)
                      ? 0
                      : -1;
                  }
                } else {
                  let sortValue = 0;
                  if (sortDescending) {
                    sortValue = !aValue
                      ? 1
                      : aValue < bValue
                      ? 1
                      : aValue === bValue
                      ? 0
                      : -1;
                  } else {
                    sortValue = !aValue
                      ? -1
                      : aValue < bValue
                      ? -1
                      : aValue === bValue
                      ? 0
                      : 1;
                  }
                  return sortValue;
                }
              });
            } else {
              this.mappedRegistrations = registrations;
            }
          }

          this.loading = false;
        },
        err => {
          this.loading = false;
          throw err;
        }
      );
  }

  viewDetails(id: string) {
    const clientId = this.renewableRegistrations.find(i => i.id === id)
      .clientId;
    if (clientId && id) {
      this._router.navigate([
        '/admin/jurisdiction',
        clientId,
        'renewals',
        'details',
        id
      ]);
    }
  }

  deleteRegistration() {
    this._workflowSvc
      .deleteRegistration(this.deleteRegistrationId)
      .subscribe(() => {
        this._toastrSvc.success('Registration Deleted');
        this.searchRegistrations();
      });
  }

  disableRegistration() {
    this._workflowSvc
      .disableRegistration(this.disableRegistrationId)
      .subscribe(() => {
        this._toastrSvc.success('Registration Disabled');
        this.searchRegistrations();
      });
  }

  enableRegistration() {
    this._workflowSvc
      .reEnableRegistration(this.enableRegistrationId)
      .subscribe(() => {
        this._toastrSvc.success('Registration Enabled');
        this.searchRegistrations();
      });
  }

  navigateToApplication(id: string) {
    const reg = this.mappedRegistrations.find(mr => mr.id === id);
    if (reg) {
      this._router.navigate([
        '/application/workflow-application',
        reg.createdApplicationId
      ]);
    }
  }

  renewRegistration(id: string) {
    this._workflowSvc.startRegistrationRenewal(id).subscribe(result => {
      this._router.navigate([
        '/application/workflow-application',
        result.applicationId
      ]);
    });
  }

  startDeleteRegistration(id: string) {
    this.deleteRegistrationId = id;
    this.deleteRegModal.open();
  }

  startDisableRegistration(id: string) {
    this.disableRegistrationId = id;
    this.disableRegModal.open();
  }

  startEnableRegistration(id: string) {
    this.enableRegistrationId = id;
    this.enableRegModal.open();
  }

  canViewDetails(item: any): boolean {
    return true;
  }

  canDeleteRegistration(item: any): boolean {
    return (
      item.status !== RegistrationStatus.Deleted &&
      (this.canEdit ||
        (item.status === RegistrationStatus.Pending &&
          (this._context.user && item.registrantId === this._context.user.id)))
    );
  }

  canDisableRegistration(item: any): boolean {
    return (
      (item.status === RegistrationStatus.Approved ||
        item.status === RegistrationStatus.Denied ||
        item.status === RegistrationStatus.Inactive) &&
      this.canEdit
    );
  }

  canEnableRegistration(item: any): boolean {
    return item.status === RegistrationStatus.Disabled && this.canEdit;
  }

  canViewApplication(item: any): boolean {
    return (
      this.canApprove ||
      this.canDeny ||
      (this._context.user && item.registrantId === this._context.user.id)
    );
  }

  canRenewRegistration(item: any): boolean {
    return (
      item.status !== RegistrationStatus.Pending &&
      item.status !== RegistrationStatus.Deleted &&
      (this.canRenew || item.registrantId === this._context.user.id)
    );
  }

  exportExcel() {
    this.useExcelFormat = true;
    this.export();
  }

  exportCsv() {
    this.useExcelFormat = false;
    this.export();
  }

  export() {
    const sortDescending = this.sort.dir === 'desc' ? true : false;

    let filters = [...this.filterOptions];
    if (this.staticFilters) {
      filters = [...this.filterOptions, ...this.staticFilters];
    }

    return this._workflowSvc
      .exportRegistrations(
        this.clientId ||
          (this._context.client ? this._context.client.id : null),
        this.useExcelFormat,
        this.exportColumns,
        this.sort.prop,
        sortDescending,
        filters,
        this.page.pageNumber,
        this.page.size
      )
      .subscribe(
        data => {
          if (this.useExcelFormat) {
            const blob = new Blob([data], {
              type:
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            });
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = `renewals-export-${new Date().getTime()}.xlsx`;
            link.click();
          } else {
            const blob = new Blob([data], { type: 'text/csv' });
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = `renewals-export-${new Date().getTime()}.csv`;
            link.click();
          }
        },
        err => {
          this._toastrSvc.error('Problem while downloading the file.');
          console.error(err);
        }
      );
  }
}

import { SFTPExternalConnection } from './../models/external-data-connection';
import { PaymentRequest } from '../models/activities/payment-activity';
import {
  FeeRecipientReportRequest,
  PagedFeeRecipientReportRequest,
  FeeRecipientReportResponse,
  PagedFeeRecipientReportResponse,
  DailyFeeReportResponse,
  PagedDailyFeeReportResponse,
  DailyFeeReportRequest,
  PagedDailyFeeReportRequest,
  PagedPaymentReportResponse,
  PagedPaymentsReportRequest,
  PaymentsReportRequest
} from './../models/financial-reports';
import { Injectable, Inject, forwardRef } from '@angular/core';
import { Observable, empty } from 'rxjs';

import {
  Address,
  UserAddress,
  ContractorAddress,
  ContactAddress
} from '../models/address';
import {
  PhoneNumber,
  UserPhoneNumber,
  ContractorPhoneNumber
} from '../models/phone-number';
import {
  EmailAddress,
  UserEmailAddress,
  ContractorEmailAddress,
  ContactEmailAddress
} from '../models/email-address';
import { DataService } from './data.service';
import { Client } from '../models/client';
import {
  ExternalDataConnection,
  ParcelDataConnection
} from '../models/external-data-connection';
import { MapService } from '../models/map-service';
import { MapServiceProvider } from '../services/map.service';
import { ApplicationConfiguration } from '../models/application-configuration';
import { WorkflowContextService } from '.';
import {
  PaymentProcessor,
  PaymentAccount,
  PaymentProcessorType,
  ParcelDataSource
} from '../models';
import { PaymentTransactionRequest } from '../models/payment-transaction-request';
import { map } from 'rxjs/operators';
import { WorkflowTag } from '../models/workflow-tag';
import { ContractorTypeTag } from '../models/contractor-type-tag';
import { Tag } from '../models/tag';
import { MapExtent } from '../models/mapextent';

@Injectable()
export class SystemService {
  testExternalConnection(
    externalDataConnection: ExternalDataConnection
  ): Observable<boolean> {
    return this._dataSvc.testExternalConnection(externalDataConnection);
  }
  enablePaymentAccount(paymentAccountId: string): Observable<void> {
    return this._dataSvc.enablePaymentAccount(paymentAccountId);
  }
  disablePaymentAccount(paymentAccountId: string): Observable<void> {
    return this._dataSvc.disablePaymentAccount(paymentAccountId);
  }
  getTagsAlphabetically(clientId: string): Observable<Tag[]> {
    return this._dataSvc.getTagsAlphabetically(clientId);
  }
  changeQueryMapLayerFeatureIdField(
    mapLayerIndexItemId: string,
    newFeatureIdField: string
  ): Observable<void> {
    return this._dataSvc.changeQueryMapLayerFeatureIdField(
      mapLayerIndexItemId,
      newFeatureIdField
    );
  }
  refundPaymentRequest(paymentRequestId: string): Observable<boolean> {
    return this._dataSvc.refundPaymentRequest(paymentRequestId);
  }
  getParentAccounts(
    processorType: PaymentProcessorType,
    clientId: string,
    currentAccountId: string
  ): Observable<PaymentAccount[]> {
    // throw new Error("Method not implemented.");
    return this._dataSvc.getParentAccounts(
      processorType,
      clientId,
      currentAccountId
    );
  }
  constructor(
    @Inject(forwardRef(() => DataService)) private _dataSvc: DataService,
    @Inject(forwardRef(() => MapServiceProvider))
    private _mapSvc: MapServiceProvider,
    @Inject(forwardRef(() => WorkflowContextService))
    private _context: WorkflowContextService
  ) {}

  getPaymentRequest(requestId: string): Observable<PaymentRequest> {
    return this._dataSvc.getPaymentRequest(requestId);
  }

  getPaymentTransactions(request: PaymentTransactionRequest): any {
    return this._dataSvc.getPaymentTransactions(request);
  }
  saveEmailAddress(emailAddress: EmailAddress): Observable<EmailAddress> {
    return this._dataSvc.saveEmailAddress(emailAddress);
  }
  saveAddress(address: Address): Observable<Address> {
    return this._dataSvc.saveAddress(address);
  }
  savePhoneNumber(phoneNumber: PhoneNumber): Observable<PhoneNumber> {
    return this._dataSvc.savePhoneNumber(phoneNumber);
  }
  savePaymentAccount(account: PaymentAccount): Observable<boolean> {
    return this._dataSvc.savePaymentAccount(account);
  }
  deletePaymentAccount(accountId: string): Observable<boolean> {
    return this._dataSvc.deletePaymentAccount(accountId);
  }
  getPaymentAccount(accountId: string): Observable<PaymentAccount> {
    return this._dataSvc.getPaymentAccount(accountId).pipe(
      map((account: PaymentAccount) => {
        if (account) {
          account.initConfig(
            account.providerAccountSettings,
            account.testProviderAccountSettings
          );
        }

        return account;
      })
    );
  }
  getPaymentAccounts(clientId: string): Observable<PaymentAccount[]> {
    return this._dataSvc.getClientPaymentAccounts(clientId);
  }
  getPaymentProcessors(): Observable<PaymentProcessor[]> {
    return this._dataSvc.getPaymentProcessors();
  }
  getPaymentProcessor(
    paymentProcessorId: string
  ): Observable<PaymentProcessor> {
    return this._dataSvc.getPaymentProcessor(paymentProcessorId).pipe(
      map((processor: PaymentProcessor) => {
        if (processor) {
          processor.initConfig(
            processor.configuration,
            processor.testConfiguration
          );
        }

        return processor;
      })
    );
  }
  savePaymentProcessor(processor: PaymentProcessor): Observable<boolean> {
    return this._dataSvc.savePaymentProcessor(processor);
  }
  getParcelDataFields(parcelDataSourceId: string) {
    return this._dataSvc.getParcelDataSourceFields(parcelDataSourceId);
  }
  buildExternalEndpoint(url: string): Observable<ExternalDataConnection> {
    return empty(); // Return<ExternalDataConnection>(null);
  }
  getExternalDataConnection(id: string): Observable<ExternalDataConnection> {
    return this._dataSvc.getExternalDataConnection(id);
  }
  searchExternalDataConnections(options): Observable<ExternalDataConnection[]> {
    return this._dataSvc.searchExternalDataConnections(options);
  }
  saveExternalDataConnection(
    edc: ExternalDataConnection
  ): Observable<ExternalDataConnection> {
    return this._dataSvc.saveExternalDataConnection(edc);
  }
  getApplicationConfiguration(): Observable<ApplicationConfiguration> {
    return this._dataSvc.getApplicationConfiguration();
  }
  deleteExternalDataConection(
    edc: ExternalDataConnection
  ): Observable<boolean> {
    return this._dataSvc.deleteExecternalDataConnection(edc);
  }

  getMapServicesByClient(client: Client): Observable<MapService[]> {
    return this._dataSvc.getClientMapServices(client);
  }
  getParcelDataSources(): Observable<ParcelDataSource[]> {
    return this._dataSvc.getGuidepostSources();
  }
  hasParcelDataSource(clientId: string): Observable<boolean> {
    return this._dataSvc.hasParcelDataSource(clientId);
  }
  getFeeRecipientReport(
    params: FeeRecipientReportRequest
  ): Observable<FeeRecipientReportResponse> {
    return this._dataSvc.getFeeRecipientReport(params);
  }
  getPagedFeeRecipientReport(
    params: PagedFeeRecipientReportRequest
  ): Observable<PagedFeeRecipientReportResponse> {
    return this._dataSvc.getPagedFeeRecipientReport(params);
  }
  exportFeeRecipientReport(params: FeeRecipientReportRequest): Observable<any> {
    return this._dataSvc.exportFeeRecipientReport(params);
  }
  getDailyFeeReport(
    params: DailyFeeReportRequest
  ): Observable<DailyFeeReportResponse> {
    return this._dataSvc.getDailyFeeReport(params);
  }
  getPagedDailyFeeReport(
    params: PagedDailyFeeReportRequest
  ): Observable<PagedDailyFeeReportResponse> {
    return this._dataSvc.getPagedDailyFeeReport(params);
  }
  exportPaymentsReport(params: PaymentsReportRequest): Observable<any> {
    return this._dataSvc.exportPaymentsReport(params);
  }
  exportDailyFeeReport(params: DailyFeeReportRequest): Observable<any> {
    return this._dataSvc.exportDailyFeeReport(params);
  }
  exportInspectionQueue(
    clientId: string,
    sortField: string,
    sortDescending: boolean,
    filterObjects: any,
    pageNumber: number,
    pageSize: number,
    ext: MapExtent,
    useExcelFormat: boolean,
    exportColumns: string[]
  ): Observable<any> {
    return this._dataSvc
      .exportInspectionQueue(
        clientId,
        sortField,
        sortDescending,
        filterObjects,
        pageNumber,
        pageSize,
        ext,
        useExcelFormat,
        exportColumns
      )
      .pipe(
        map(regs => {
          return regs;
        })
      );
  }

  hasOnlinePaymentIntegration(client: Client): Observable<boolean> {
    return this._dataSvc.hasOnlinePaymentIntegration(client);
  }
  createExternalDataConnection(type: string): ExternalDataConnection {
    if (type === 'ParcelData') {
      return new ParcelDataConnection({ clientID: this._context.client.id });
    }

    switch (type) {
      case 'SecureFTP':
        return new SFTPExternalConnection();
    }
    //  new ExternalDataConnection({
    //   id: '',
    //   url: '',
    //   method: 'GET',
    //   name: '',
    //   parameters: [],
    //   resultFields: [],
    //   connectionType: 'ParcelData',
    //   credentials: null,
    //   clientID: this._context.client.id
    // });
  }
  createAddress(parentType: string): Address {
    switch (parentType) {
      case 'User':
        return new UserAddress();
      case 'Contractor':
        return new ContractorAddress();
      case 'Contact':
        return new ContactAddress();
      default:
        return null;
    }
  }
  createPhoneNumber(parentType: string): PhoneNumber {
    switch (parentType) {
      case 'User':
        return new UserPhoneNumber();
      case 'Contractor':
        return new ContractorPhoneNumber();
      default:
        return null;
    }
  }
  createEmailAddress(parentType: string): EmailAddress {
    switch (parentType) {
      case 'User':
        return new UserEmailAddress();
      case 'Contractor':
        return new ContractorEmailAddress();
      case 'Contact':
        return new ContactEmailAddress();

      default:
        return null;
    }
  }
  createMapService(): MapService {
    return new MapService();
  }

  saveTag(tag: Tag): Observable<Tag> {
    return this._dataSvc.saveTag(tag);
  }

  deleteTag(tag: Tag): Observable<void> {
    return this._dataSvc.deleteTag(tag);
  }

  addWorkflowTag(workflowTag: WorkflowTag): Observable<void> {
    return this._dataSvc.addWorkflowTag(workflowTag);
  }

  addContractorTypeTag(contractorTypeTag: ContractorTypeTag): Observable<void> {
    return this._dataSvc.addContractorTypeTag(contractorTypeTag);
  }

  removeWorkflowTag(workflowTag: WorkflowTag): Observable<void> {
    return this._dataSvc.removeWorkflowTag(workflowTag);
  }
  removeContractorTypeTag(
    contractorTypeTag: ContractorTypeTag
  ): Observable<void> {
    return this._dataSvc.removeContractorTypeTag(contractorTypeTag);
  }
  getTags(clientId: string): Observable<Tag[]> {
    return this._dataSvc.getTags(clientId);
  }
  getTagsForWorkflows(clientId: string): Observable<Tag[]> {
    return this._dataSvc.getTagsForWorkflows(clientId);
  }
  getTagsForContractorTypes(clientId: string): Observable<Tag[]> {
    return this._dataSvc.getTagsForContractorTypes(clientId);
  }
  getPagedPaymentsReport(
    params: PagedPaymentsReportRequest
  ): Observable<PagedPaymentReportResponse> {
    return this._dataSvc.getPagedPaymentsReport(params);
  }
}

import {
  Activity,
  ScreenActivity,
  ActivityModel
} from '../../models/activities/activity';
import { ConditionTarget } from 'src/app/components/system/condition-builder/condition-builder.model';
import { Document } from '../../models/document';

export class TemplateModel {
  pages: TemplatePage[];
  templateDocName?: string;
  templateDocPath?: string;
  templateImages?: any[];
  migrationHTMLTemplate?: string;
}

export class TemplatePage {
  index: number;
  fields: TemplateField[];
  isLandscape: boolean;
  imagePath: string;
  pageHeight: number;
  pageWidth: number;

  constructor() {
    this.fields = [];
  }
}

export enum TemplateFieldType {
  DataEntity,
  Image
}

export enum FieldOrientation {
  Horizontal = 1,
  Vertical = 2
}

export enum VerticalOrientation {
  Left = 1,
  Right = 2
}

export class TemplateField {
  x: number;
  y: number;
  width: number;
  height: number;
  name: string;
  label: string;
  target?: any;
  id: string;
  extraData: any;
  fontWeight?: string;
  fontSize?: string;
  fontColor?: string;
  emptyText?: string;
  criteria?: ConditionTarget[];
  fieldType: TemplateFieldType;
  outputFormat?: string;
  orientation: FieldOrientation;
  verticalOrientation: VerticalOrientation;

  constructor(options?: Partial<TemplateField>) {
    this.fieldType = TemplateFieldType.DataEntity;

    if (options) {
      Object.assign(this, options);
    }
  }
}

export class PrintTemplateActivityModel extends ActivityModel {
  templateModel: TemplateModel;
  documentPath: string;
  autoContinue: boolean;
  customNameCriteria: ConditionTarget[];
  evaluatedDocName: string;

  reset() {
    this.templateModel = {
      pages: [],
      templateDocName: null,
      templateDocPath: null
    };
  }

  constructor(options?: Partial<PrintTemplateActivityModel>) {
    super(options);

    this.reset();

    if (options) {
      Object.assign(this, options);
    }
  }
}

export class PrintTemplateActivity extends ScreenActivity<
  PrintTemplateActivityModel
> {
  canRegenerate: boolean;

  constructor(options?: Partial<PrintTemplateActivity>) {
    super(options);
    this.type = 'print-template-activity';
    this.description = 'Generate a PDF with collected data';
    this.name = 'Generate PDF';

    this.model = new PrintTemplateActivityModel(options ? options.model : null);

    this.model.screenName = this.model.screenName || 'Documents';
  }
}

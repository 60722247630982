import {
  DataService,
  WorkflowContextService,
  ContractorService
} from 'src/app/services';
import { FilterBuilderParam } from 'src/app/models/filter-builder';
import {
  Component,
  OnInit,
  Inject,
  forwardRef,
  Output,
  EventEmitter
} from '@angular/core';

@Component({
  selector: 'wm-workflow-application-list-view',
  templateUrl: './workflow-application-list-view.component.html',
  styleUrls: ['./workflow-application-list-view.component.css']
})
export class WorkflowApplicationListViewComponent implements OnInit {
  @Output() filtersChanged = new EventEmitter<boolean>();

  public filterCriteria: FilterBuilderParam[];

  constructor(
    private _dataSvc: DataService,
    @Inject(forwardRef(() => ContractorService))
    private _contractorSvc: ContractorService,
    private _context: WorkflowContextService
  ) {}

  initFilters() {
    const workflows = this._dataSvc
      .getWorkflows(this._context.client, false)
      .toPromise();
    const contractorWorkflows = this._contractorSvc
      .getContractorRegistrationTypes(this._context.client)
      .toPromise();
    Promise.all([workflows, contractorWorkflows]).then(
      ([workflows, contractorWorkflows]) => {
        let workflowOpts = [
          ...workflows.map(wf => ({
            name: wf.version.name,
            value: wf.id
          })),
          ...contractorWorkflows.map(c => ({
            name: `${c.name} (Registration)`,
            value: c.registerWorkflowId
          })),
          ...contractorWorkflows.map(c => ({
            name: `${c.name} (Renewal)`,
            value: c.renewWorkflowId
          }))
        ];
        workflowOpts = workflowOpts.sort((a, b) =>
          a.name.localeCompare(b.name)
        );
        const param = this.filterCriteria.find(p => p.id === 'permitType');
        param.options = workflowOpts
          .map(w => ({
            name: w.name,
            value: w.value
          }))
          .sort((a: any, b: any) => a.name - b.name);
      }
    );

    this.filterCriteria = [
      {
        name: 'Application Number',
        id: 'applicationNumber'
      },
      {
        name: 'Permit Type',
        id: 'permitType',
        types: ['is', 'in']
      },
      {
        name: 'Description',
        id: 'description'
      },
      {
        name: 'Applicant Name',
        id: 'applicantsName'
      },
      {
        name: 'Date Created',
        id: 'dateCreated',
        inputType: 'date',
        types: ['is', 'range']
      },
      {
        name: 'Submitter',
        id: 'submitterId',
        types: ['is']
      },
      {
        name: 'Status',
        id: 'status',
        types: ['is', 'in'],
        options: [
          {
            name: 'Not Started',
            value: '0'
          },
          {
            name: 'In Progress',
            value: '1'
          },
          {
            name: 'Completed',
            value: '2'
          },
          {
            name: 'Voided',
            value: '3'
          },
          {
            name: 'Rejected',
            value: '4'
          },
          {
            name: 'Discarded',
            value: '5'
          }
        ]
      },
      {
        name: 'Parcel Address',
        id: 'parcelAddress'
      },
      {
        name: 'Parcel City',
        id: 'parcelCity',
        types: ['is', 'contains', 'in']
      },
      {
        name: 'Parcel Id',
        id: 'parcelId',
        types: ['is', 'contains', 'in']
      },
      {
        name: 'Parcel Owner',
        id: 'parcelOwner'
      },
      {
        name: 'Current Activity',
        id: 'currentActivity',
        types: ['is', 'contains', 'in']
      }
    ];
  }

  ngOnInit() {
    this.initFilters();
  }

  filtersChange() {
    this.filtersChanged.emit(true);
  }
}

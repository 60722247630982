import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild
} from '@angular/core';
import { Address } from '../../../models';

import { ProcessingStatusComponent } from '../processing-status/processing-status.component';
import { DataService, SystemService } from '../../../services';

/**
 * Address details form
 *
 * <example-url>https://workflowmanagerdev.schneidercorp.com/auth/account</example-url>
 *
 * @example
 * <wm-address-detail
 *              [required]="false"
 *              [showButtons]="false"
 *              [address]="contact.address"
 *              [showName]="true"
 *              parentType="Contact"
 *              (saved)="addressSaved($event)"
 *              (canceled)="addressCanceled($event)"
 * >
 * </wm-address-detail>
 *
 * <!-- minimum example -->
 * <wm-address-detail [address]="contact.address" parentType="Contact"></wm-address-detail>
 */
@Component({
  selector: 'wm-address-detail',
  templateUrl: './address-detail.component.html',
  styleUrls: ['./address-detail.component.css']
})
export class AddressDetailComponent implements OnInit {
  /**
   * {@link Address} model
   */
  @Input() address: Address;

  /**
   * Emits an event with the {@link Address} when saved
   */
  @Output() saved: EventEmitter<Address> = new EventEmitter<Address>();
  /**
   * Emits an event with the {@link Address} when canceled
   */
  @Output() canceled: EventEmitter<Address> = new EventEmitter<Address>();

  /**
   * @ignore
   */
  @ViewChild('saveAddressStatus', { static: false }) saveAddressStatus: ProcessingStatusComponent;

  /**
   * Type of the parent form. Can be `User`, `Contractor` or `Contact`.
   */
  @Input() parentType: string;
  /**
   * Show save and cancel buttons
   */
  @Input() showButtons = true;
  /**
   * Require the address fields to be filled out
   */
  @Input() required = true;
  /**
   * Show a "Name of Address" field
   */
  @Input() showName = false;

  /**
   * @ignore
   */
  constructor(
    private _dataSvc: DataService,
    private _systemSvc: SystemService
  ) {}

  /**
   * @ignore
   */
  ngOnInit() {
    if (!this.address) {
      this.address = this._systemSvc.createAddress(this.parentType);
    }
  }

  /**
   * Save the address and emit a [saved]{@link AddressDetailComponent#saved} event
   */
  save() {
    this.saveAddressStatus.show();
    this._dataSvc.saveAddress(this.address).subscribe(res => {
      this.saveAddressStatus.finished('Address Saved', 1000).subscribe(() => {
        this.address = null;
        this.saved.next(res);
      });
    });
  }

  /**
   * Emit a [canceled]{@link AddressDetailComponent#canceled} event
   */
  cancel() {
    this.canceled.next(this.address);
  }
}

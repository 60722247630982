import {
  Component,
  OnInit,
  Input,
  Type,
  ViewChild,
  ComponentRef,
  ViewContainerRef,
  ComponentFactoryResolver,
  DoCheck,
  Output,
  EventEmitter,
  OnDestroy,
  Inject,
  forwardRef,
  ChangeDetectorRef
} from '@angular/core';
import {
  FormActivity,
  FormLayoutModes,
  SingleColumnFormLayoutModel,
  Activity,
  ActivityModel
} from '../../../../../../models/activities';
import { UntypedFormGroup } from '@angular/forms';
import { FormActivityInputLayoutSingleColumnComponent } from '../form-activity-input-layout-single-column/form-activity-input-layout-single-column.component';
import { FormActivityInputLayoutTwoColumnComponent } from '../form-activity-input-layout-two-column/form-activity-input-layout-two-column.component';
import { FormActivityInputLayoutBaseComponent } from '../form-activity-input-layout-base/form-activity-input-layout-base.component';
import { WorkflowApplication } from '../../../../../../models';
import { Subscription, interval } from 'rxjs';
import * as moment from 'moment';
import { WorkflowContextService, WorkflowService } from 'src/app/services';
import { CalculatedValueDataEntity } from 'src/app/models/data-entities';

@Component({
  selector: 'wm-form-activity-input-view',
  templateUrl: './form-activity-input-view.component.html',
  styleUrls: ['./form-activity-input-view.component.css']
})
export class FormActivityInputViewComponent implements OnInit, OnDestroy {
  @Input() activity: FormActivity;
  @Input() form: UntypedFormGroup;
  @Input() isReadOnly: boolean;
  @Input() applicationId: string;
  @Output() calculateActivity: EventEmitter<string> = new EventEmitter();

  calculating = false;
  hasChanges = false;
  waitingForCalculate = false;
  calcSubscription: Subscription = null;
  lastCalcRequest: Date;
  entityChanges: string[] = [];

  @ViewChild('layoutContainer', { read: ViewContainerRef, static: true })
  layoutContainer: ViewContainerRef;
  componentRef: ComponentRef<FormActivityInputLayoutBaseComponent>;

  layoutTypes: any = {
    0: {
      layoutComponent: FormActivityInputLayoutSingleColumnComponent
    },
    1: {
      layoutComponent: FormActivityInputLayoutTwoColumnComponent
    }
  };

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    @Inject(forwardRef(() => WorkflowService))
    private _workflowSvc: WorkflowService,
    @Inject(forwardRef(() => WorkflowContextService))
    private _context: WorkflowContextService,
    private _ref: ChangeDetectorRef
  ) {
    this.isReadOnly = false;
  }

  ngOnDestroy(): void {
    if (this.calcSubscription && !this.calcSubscription.closed) {
      this.calcSubscription.unsubscribe();
    }
  }

  async persistChildComponent() {
    return this.componentRef.instance.persistChildComponent();
  }

  ngOnInit() {
    if (this.activity) {
      this.loadLayoutComponent(this.activity.model.formLayout);
    }

    // check for changes every 500 ms.
    this.calcSubscription = interval(500).subscribe(() => {
      // this is here as a buffer to make sure we aren't firing this more than necessary
      const msSinceLastCalcRequest: number = moment().diff(
        moment(this.lastCalcRequest),
        'milliseconds'
      );

      if (
        this.hasChanges &&
        !this.calculating &&
        msSinceLastCalcRequest > 500
      ) {
        this.hasChanges = false;
        const templateCodes: string[] = this.entityChanges;
        this.entityChanges = [];
        this.calculating = true;
        // persist child components for all entities on form
        this.persistChildComponent();

        this._workflowSvc
          .calculateFormulas(
            this.activity,
            this.applicationId || this._context.applicationId,
            templateCodes
          )
          .subscribe(
            result => {
              const entities = this.activity.model.getEntities();
              let stillCalculating = false;

              // update each of the entities with the calculated value
              entities.forEach(e => {
                const tempCodeKey = e.templateCode.toLowerCase();
                if (
                  templateCodes.indexOf(e.templateCode) > -1 &&
                  e instanceof CalculatedValueDataEntity
                ) {
                  const calcE = e as CalculatedValueDataEntity;

                  calcE.valueUpdate$.emit(result.calculatedValues[tempCodeKey]);

                  // make sure other changes weren't queued while calculating before setting it back to not calculating
                  if (
                    !this.entityChanges.find(ec => ec == calcE.templateCode)
                  ) {
                    calcE.isCalculating = false;
                  } else {
                    stillCalculating = true;
                  }
                }
                if (!stillCalculating) {
                  this.calculating = false;
                }
              });
              this._ref.detectChanges();
            },
            err => {
              this.calculating = false;
              this._ref.detectChanges();
            }
          );
      }
    });
  }

  loadLayoutComponent(layoutMethod: FormLayoutModes) {
    let compType: Type<FormActivityInputLayoutBaseComponent>;

    if (this.layoutContainer) {
      this.layoutContainer.clear();

      if (this.layoutTypes[layoutMethod]) {
        compType = this.layoutTypes[layoutMethod].layoutComponent;
      }

      if (compType && this.layoutContainer) {
        this.componentRef = this.layoutContainer.createComponent(compType);
        this.componentRef.instance.activity = this.activity;
        this.componentRef.instance.form = this.form;
        this.componentRef.instance.isReadOnly = this.isReadOnly;
        this.componentRef.instance.applicationId = this.applicationId;
        this.componentRef.instance.calculateActivity.subscribe(e => {
          this.hasChanges = true;
          if (!this.entityChanges.find(ec => ec == e)) {
            this.entityChanges.push(e);
          }
          this.lastCalcRequest = moment().toDate();
          this.calculateActivity.emit(e);
        });
      }
    }
  }
}

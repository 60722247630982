import { ApplicationStatusPipe } from 'src/app/pipes/application-status.pipe';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { TableColumn } from '@swimlane/ngx-datatable';
import { DataTableHelpers } from './../../../../components/system/datatable/datatable-helper';
import { Actions } from './../../../../models/actions';
import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  ViewChild,
  ComponentRef,
  TemplateRef
} from '@angular/core';
import {
  SearchParcelDataActivity,
  SearchParcelDataActivityModel
} from 'src/app/models/activities';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import {
  WorkflowService,
  WorkflowContextService,
  ValidationService,
  DataService,
  SecurityService
} from 'src/app/services';
import {
  ItemSearchOptionFieldOption,
  ItemSearchOptionField,
  ItemSearchFilter,
  ItemSearchOption
} from 'src/app/components/filter-list/models/filterClasses';
import { ActivityView } from 'src/app/views/master-views/app.view/app.view.component';
import { Filter } from 'src/app/models/filter-builder';
import { Observable } from 'rxjs';
import { ApplicationStatus } from 'src/app/models';

@Component({
  selector: 'wm-workflow-application-search-view',
  templateUrl: './workflow-application-search-view.component.html',
  styleUrls: ['./workflow-application-search-view.component.css']
})
export class WorkflowApplicationSearchViewComponent implements OnInit {
  activityView: ActivityView;
  showResults = false;
  showPreviousSystemResults = false;
  parcelDataConfigured = false;
  canEditExternalConnections = false;
  public currentApplication: any = null;
    @ViewChild('currentActivityTmpl', { static: false }) currentActivityTmpl: TemplateRef<any>;
  @ViewChild('activityInput', { static: false }) view: ComponentRef<ActivityView>;
  @ViewChild('appIdTmpl', { static: true }) appIdTmpl: TemplateRef<any>;

  public columns: TableColumn[];

  @Output() updateStatus = new EventEmitter();
  activity: SearchParcelDataActivity;
  form: UntypedFormGroup;
  modelOptions: SearchParcelDataActivityModel;

  myWorkflowsFilter = [];
  parcelNumberFilter: Filter[];

  clientId: string;
  ApplicationStatus = ApplicationStatus;
  constructor(
    public _workflowSvc: WorkflowService,
    public _fb: UntypedFormBuilder,
    public context: WorkflowContextService,
    private _dataSvc: DataService,
    private dtHelpers: DataTableHelpers,
    private modalService: NgbModal,
    private _securitySvc: SecurityService
  ) {}

  createComponent() {
    this.activity = new SearchParcelDataActivity({ model: this.modelOptions });
    this.form = this._fb.group(
      ValidationService.createValidationGroup(this.activity)
    );
  }

  buildInspectionFilter(id) {
    let iStaticFilters: ItemSearchFilter;
    iStaticFilters = new ItemSearchFilter({
      options: [
        new ItemSearchOption({
          field: new ItemSearchOptionField({
            title: 'workflowApplicationId',
            options: [
              new ItemSearchOptionFieldOption({
                title: 'workflowApplicationId',
                selected: true,
                filterText: '',
                searchText: id,
                strOperator: ''
              })
            ]
          })
        })
      ]
    });
    return iStaticFilters;
  }

  goNext(evt) {
    this.SearchParcel(evt);
  }

  ngOnInit() {
    this.clientId = this.context.client.id;
    this.columns = [
      {
        prop: 'applicationNumber',
        name: 'Application Number',
        cellTemplate: this.appIdTmpl
      },
      { prop: 'permitType', name: 'Type' },
      { prop: 'description', name: 'Description' },
      { prop: 'applicantsName', name: 'Applicant' },
      {
        prop: 'dateCreated',
        name: 'Date Created',
        pipe: this.dtHelpers.getDatePipe()
      },
      { prop: 'parcelId', name: 'Parcel ID' },
      {
        prop: 'status',
        name: 'Status',
        pipe: new ApplicationStatusPipe()
      },
      {
        prop: 'currentActivityData',
        name: 'Current Activity',
        cellTemplate: this.currentActivityTmpl
      }
    ];
    this.createComponent();
    this.checkForParcelService();
  }

  canViewPSD(): Observable<boolean> {
    return this._securitySvc.isLoginEntitled(Actions.VIEW_PREVIOUS_SYSTEM_DATA);
  }

  SearchParcel(evt: any) {
    this.myWorkflowsFilter = [
      new ItemSearchOptionField({
        title: 'parcelId',
        options: [
          new ItemSearchOptionFieldOption({
            title: 'parcelId',
            selected: true,
            filterText: '',
            searchText: this.activity.model.selectedParcel.parcelID,
            strOperator: ''
          })
        ]
      })
    ];
    this.showResults = true;

    this.parcelNumberFilter = [
      {
        id: 'staticParcelNumber',
        type: 'is',
        value: this.activity.model.selectedParcel.parcelID
      }
    ];
    this.showPreviousSystemResults = true;
  }

  onParcelSearch() {
    this.showResults = false;
    this.myWorkflowsFilter = null;
    this.activity.model.selectedParcel = null;
  }

  checkForParcelService() {
    this._dataSvc
      .getParcelDataSources(this.clientId)
      .subscribe(parcelSources => {
        this.parcelDataConfigured = parcelSources && parcelSources.length > 0;
      });

    this._securitySvc
      .isLoginEntitled(Actions.EXTERNAL_CONNECTION_MANAGE)
      .subscribe(r => {
        this.canEditExternalConnections = r;
      });
  }

  openInspections(content, application: any) {
    this.currentApplication = application;
    const opts = {
      ariaLabelledBy: 'modal-inspections-title',
      size: 'xl'
    };

    // cast opts to NgbModalOptions because TSLint doesn't think size xl is valid even though it is
    this.modalService.open(content, opts as NgbModalOptions).result.then(
      result => {
        // closed
      },
      reason => {
        // dismissed
      }
    );
  }
}

<div class="switch" *ngIf="!isAgendaItemActivity">
  <label>
    <input type="checkbox" [(ngModel)]="activity.model.autoContinue" />
    Auto continue
  </label>
</div>

<h6 *ngIf="showTitle">Document</h6>

<table
  class="table"
  *ngIf="!activity.model.templateModel.migrationHTMLTemplate"
>
  <tbody>
    <tr *ngIf="templateDocument">
      <td>
        <p>
          {{ templateDocument ? templateDocument.name : 'SomeDocument.pdf' }}
        </p>
      </td>
      <td>
        <div class="float-right">
          <wm-activity-details-modal
            [title]="'Generate PDF'"
            btnText="Edit"
            class="mr-1"
            containerType="container-fluid"
            [form]="form"
            (opened)="onOpened()"
            (saved)="onSaved()"
            (closed)="onClosed()"
          >
            <div
              class="row"
              [ngStyle]="{ 'min-height.px': templateHeight + 250 }"
            >
              <div class="col-3">
                <div [ngStyle]="{ 'min-height.px': templateHeight + 50 }">
                  <div class="card sticky-top" style="top: 10px; height: 100%">
                    <div class="card-body">
                      <h4>{{ fieldTitle }}</h4>
                      <h6>Format Options</h6>

                      <ng-container *ngIf="!anythingToFormat || !formatData"
                        ><i>Nothing to format</i></ng-container
                      >
                      <ng-container *ngIf="anythingToFormat && formatData">
                        <div
                          style="height: 100%;overflow-y: auto;overflow-x: hidden; padding-right: 15px"
                        >
                          <div class="form-group" *ngIf="formatData.fontSize">
                            <label class="bmd-label-floating" for="fontSize">
                              Font Size
                            </label>
                            <input
                              type="text"
                              class="form-control"
                              [(ngModel)]="formatData.fontSize.value"
                              (change)="applyFormat()"
                              name="fontSize"
                              [attr.disabled]="
                                formatData.fontSize.canEdit ? null : true
                              "
                            />
                          </div>
                          <div class="form-group" *ngIf="formatData.fontWeight">
                            <label class="bmd-label-floating" for="fontWeight">
                              Font Weight
                            </label>
                            <select
                              class="form-control"
                              [(ngModel)]="formatData.fontWeight.value"
                              name="fontWeight"
                              (change)="applyFormat()"
                              [attr.disabled]="
                                formatData.fontWeight.canEdit ? null : true
                              "
                            >
                              <option value="normal">Normal</option>
                              <option value="bold">Bold</option>
                            </select>
                          </div>
                          <div class="form-group" *ngIf="formatData.fontColor">
                            <label class="bmd-label-floating" for="fontColor">
                              Font Color
                            </label>
                            <select
                              class="form-control"
                              [(ngModel)]="formatData.fontColor.value"
                              name="fontColor"
                              (change)="applyFormat()"
                              [attr.disabled]="
                                formatData.fontColor.canEdit ? null : true
                              "
                            >
                              <option value="0,0,0">Black</option>
                              <option value="255,0,0">Red</option>
                            </select>
                          </div>
                          <div
                            class="form-group pt-2"
                            *ngIf="
                              formatData.criteria && formatData.criteria.canEdit
                            "
                          >
                            <wm-data-entity-formula
                              id="data-entity-value"
                              [required]="true"
                              [criteria]="formatData.criteria.value"
                              [thenLabelGetter]="getThenLabel"
                              (criteriaChanged)="updateCriteria($event)"
                              [formulaTitle]="'entity value'"
                              [activity]="activity"
                              [workflow]="workflow"
                              *ngIf="formatData.criteria"
                            ></wm-data-entity-formula>
                          </div>
                          <div
                            class="form-group"
                            *ngIf="formatData.outputFormat"
                          >
                            <label
                              class="bmd-label-floating"
                              for="outputFormat"
                            >
                              Output Format
                            </label>
                            <select
                              class="form-control"
                              [(ngModel)]="formatData.outputFormat.value"
                              name="outputFormat"
                              (change)="applyFormat()"
                              [attr.disabled]="
                                formatData.outputFormat.canEdit ? null : true
                              "
                            >
                              <option value="0">General</option>
                              <option value="1">Text</option>
                              <option value="2">Number</option>
                              <option value="3">Decimal</option>
                              <option value="4">Date</option>
                              <option value="5">Date & Time</option>
                            </select>
                          </div>
                          <div
                            class="form-group"
                            *ngIf="formatData.orientation"
                          >
                            <label class="bmd-label-floating" for="orientation">
                              Orientation
                            </label>
                            <select
                              class="form-control"
                              [(ngModel)]="formatData.orientation.value"
                              name="orientation"
                              (change)="applyFormat()"
                              [attr.disabled]="
                                formatData.orientation.canEdit ? null : true
                              "
                            >
                              <option value="1">Horizontal</option>
                              <option value="2">Vertical</option>
                            </select>
                          </div>
                          <div
                            class="form-group"
                            *ngIf="
                              formatData.orientation &&
                              isVertical(formatData.orientation.value) &&
                              formatData.verticalOrientation
                            "
                          >
                            <label
                              class="bmd-label-floating"
                              for="vertical_orientation"
                            >
                              Vertical Orientation
                            </label>
                            <select
                              class="form-control"
                              [(ngModel)]="formatData.verticalOrientation.value"
                              name="vertical_orientation"
                              (change)="applyFormat()"
                              [attr.disabled]="
                                formatData.verticalOrientation.canEdit
                                  ? null
                                  : true
                              "
                            >
                              <option value="1">Left</option>
                              <option value="2">Right</option>
                            </select>
                          </div>
                          <div>
                            <button
                              class="btn btn-default pr-1"
                              type="button"
                              (click)="alignControlsToLeft()"
                              [disabled]="selectedFields.length === 1"
                            >
                              <fa-icon
                                [icon]="faAlignLeft"
                                style="cursor: pointer;"
                                class="pr-2"
                              ></fa-icon>
                              Arrange Left
                            </button>
                            <button
                              class="btn btn-default"
                              type="button"
                              (click)="alignControlsToRight()"
                              [disabled]="selectedFields.length === 1"
                            >
                              <fa-icon
                                [icon]="faAlignRight"
                                style="cursor: pointer;"
                                class="pr-2"
                              ></fa-icon>
                              Arrange Right
                            </button>
                          </div>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="row">
                  <div class="col-4">
                    <h4
                      class="truncate"
                      [ngbTooltip]="
                        templateDocument ? templateDocument.name : 'Document'
                      "
                    >
                      {{
                        templateDocument ? templateDocument.name : 'Document'
                      }}
                    </h4>
                  </div>

                  <div
                    class="col-4 text-center"
                    style="user-select: none;"
                    *ngIf="templateDocument"
                  >
                    <span
                      class="mr-2"
                      *ngIf="canPreviousPage()"
                      (click)="goPreviousPage()"
                      style="cursor: pointer"
                    >
                      <i class="material-icons">
                        arrow_back
                      </i>
                    </span>

                    <span>Page {{ pageIndex + 1 }}</span>
                    <span
                      class="ml-2"
                      *ngIf="canNextPage()"
                      (click)="goNextPage()"
                      style="cursor: pointer"
                    >
                      <i class="material-icons">
                        arrow_forward
                      </i>
                    </span>
                  </div>
                  <div
                    *ngIf="(templatePages || []).length > 0"
                    class="col-4 text-right"
                  >
                    <wm-upload
                      buttonText="Replace PDF"
                      [validDocumentTypes]="'pdf'"
                      [pathSegments]="[
                        (workflow || _context.workflow).id,
                        activity.id
                      ]"
                      [allowMultipleFiles]="false"
                      category="Workflow"
                      (fileUploaded)="templateUploaded($event)"
                      [key]="activity.id"
                    ></wm-upload>

                    <wm-upload
                      [allowMultipleFiles]="false"
                      validDocumentTypes="gif,png,jpg,jpeg"
                      [pathSegments]="[activity.id, 'assets']"
                      [showSingleButton]="true"
                      buttonText="Insert Image"
                      (fileUploaded)="fileUploaded($event)"
                      category="Workflow"
                    >
                    </wm-upload>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <b
                      ><p>
                        Warning: Do not insert private data entities or images,
                        such as credit card numbers, SSN’s, DL numbers, medical
                        info, ID images, etc.
                      </p></b
                    >
                  </div>
                </div>

                <div class="row">
                  <div class="col">
                    <div
                      #template
                      id="template"
                      class="template dropzone"
                      [ngStyle]="
                        templateHeight ? { 'height.px': templateHeight } : {}
                      "
                      style="border: 1px solid black"
                    >
                      <p *ngIf="pageLoading">
                        Loading Page...
                      </p>
                      <canvas
                        id="c"
                        style="width: 100%;position: absolute;left:0; right: 0;bottom: 0;top: 0; z-index: 10;"
                      ></canvas>
                      <app-loader *ngIf="!isOpen"></app-loader>
                      <img
                        *ngIf="templatePagePath && isOpen"
                        #imgDoc
                        id="imgDoc"
                        style="position: absolute; left: 0; top: 0;z-index: 1;"
                        [src]="templatePagePath"
                        (load)="pageImageLoaded()"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-3">
                <div [ngStyle]="{ 'min-height.px': templateHeight + 50 }">
                  <div class="card sticky-top" style="height: 100%">
                    <div class="card-body">
                      <h4>Data</h4>

                      <div class="form-group">
                        <label class="bmd-label-floating" for="searchText">
                          Search
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          (keyup)="searchDataEntities()"
                          [(ngModel)]="searchText"
                          name="searchText"
                        />
                      </div>

                      <div *ngIf="!filteredEntities">
                        <p>Loading Data Entities...</p>
                      </div>
                      <div
                        *ngIf="
                          filteredEntities && filteredEntities.length === 0
                        "
                      >
                        <p>No Data Entities Available</p>
                      </div>

                      <ul
                        class="list-group scroll"
                        [ngStyle]="{ 'max-height.px': templateHeight - 120 }"
                      >
                        <div *ngFor="let de of filteredEntities">
                          <li
                            class="list-group-item"
                            [ngClass]="{
                              draggable: !hasChildren(de)
                            }"
                            [draggable]="!hasChildren(de)"
                            [attr.data-value]="serialize(de)"
                          >
                            {{ de.text }}
                          </li>
                          <li
                            *ngFor="let dec of de.children"
                            class="list-group-item draggable pl-5"
                            draggable="true"
                            [attr.data-value]="serialize(dec)"
                          >
                            {{ dec.text }}
                          </li>
                        </div>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </wm-activity-details-modal>
          <wm-modal-confirm
            *ngIf="templateDocument"
            [moduleId]="'clear_document' + activity.id"
            [commandText]="'Delete File'"
            [title]="'Delete Document'"
            [showButtonText]="true"
            (accepted)="clearTemplate()"
            [enabled]="true"
            btnColor="danger"
          >
            <p>Are you sure you want to clear the template file?</p>
            <ul>
              <li>
                Deleting this Document will remove all fields placed on the
                template.
              </li>
            </ul>
          </wm-modal-confirm>
        </div>
      </td>
    </tr>
    <tr
      *ngIf="
        !templateDocument &&
        activity &&
        (workflow || _context.workflow) &&
        uploadService
      "
    >
      <td>Select File</td>
      <td>
        <div class="float-right">
          <wm-upload
            [validDocumentTypes]="'pdf'"
            [pathSegments]="[(workflow || _context.workflow).id, activity.id]"
            [documentService]="this.uploadService"
            [allowMultipleFiles]="false"
            category="Workflow"
            (fileUploaded)="templateUploaded($event)"
            [key]="activity.id"
          ></wm-upload>

          <span>{{ statusMessage }}</span>
        </div>
      </td>
    </tr>
  </tbody>
</table>

<div class="row mt-4">
  <div class="col-11">
    <h6 *ngIf="!isAgendaItemActivity">Document Name Custom Formula</h6>
    <h6 *ngIf="isAgendaItemActivity">Ballot Name Formula</h6>
  </div>
  <div class="col-1" style="padding-top: 1px; padding-left: 0">
    <wm-help-text
      placement="left"
      helpText="Build a formula to name each PDF created by this activity"
    ></wm-help-text>
  </div>
</div>

<div class="row mt-0 pt-0 mb-4">
  <div class="col-12">
    <div class="form-group mt-0 pt-0">
      <wm-data-entity-formula
        [required]="true"
        [criteria]="activity.model.customNameCriteria"
        [workflow]="workflow || _context.workflow"
        [thenLabelGetter]="getThenLabel"
        (criteriaChanged)="updateCustomNameCriteria($event)"
        formulaTitle=" "
        [onlyGlobalDataEntities]="false"
        placement="left"
      >
      </wm-data-entity-formula>
      <wm-control-message
        [control]="form.controls['customNameFormula']"
      ></wm-control-message>
    </div>
  </div>
</div>

<div *ngIf="activity.model.templateModel.migrationHTMLTemplate">
  <div class="text-danger pb-2">
    This print template activity was migrated from the legacy system and can't
    be edited in this version. If you want to make changes you will need to
    click the Migrate button below
  </div>
  <wm-modal-confirm
    [moduleId]="'migrate' + activity.id"
    [commandText]="'Migrate Activity'"
    [title]="'Migrage Activity'"
    [showButtonText]="true"
    (accepted)="migrateActivity(templateDocument)"
    [enabled]="true"
    btnColor="danger"
  >
    <p>Are you sure you want to migrate this print template activity?</p>
    <ul>
      <li>
        Migrating this Print Template Activity will require you to recreate the
        document starting with a PDF as the template and placing Data Entities
        at the desired locations. This cannot be undone.
      </li>
    </ul>
  </wm-modal-confirm>
</div>

<ng-template #formatContent let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-format-title">Format Options</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <ng-container *ngIf="!anythingToFormat || !formatData"
      ><i>Nothing to format</i></ng-container
    >
    <ng-container *ngIf="anythingToFormat && formatData">
      <div class="form-group" *ngIf="formatData.fontSize">
        <label class="bmd-label-floating" for="fontSize">
          Font Size
        </label>
        <input
          type="text"
          class="form-control"
          [(ngModel)]="formatData.fontSize.value"
          name="fontSize"
          [attr.disabled]="formatData.fontSize.canEdit ? null : true"
        />
      </div>
      <div class="form-group" *ngIf="formatData.fontWeight">
        <label class="bmd-label-floating" for="fontWeight">
          Font Weight
        </label>
        <select
          class="form-control"
          [(ngModel)]="formatData.fontWeight.value"
          name="fontWeight"
          [attr.disabled]="formatData.fontWeight.canEdit ? null : true"
        >
          <option value="normal">Normal</option>
          <option value="bold">Bold</option>
        </select>
      </div>
      <div class="form-group" *ngIf="formatData.fontColor">
        <label class="bmd-label-floating" for="fontColor">
          Font Color
        </label>
        <select
          class="form-control"
          [(ngModel)]="formatData.fontColor.value"
          name="fontColor"
          [attr.disabled]="formatData.fontColor.canEdit ? null : true"
        >
          <option value="0,0,0">Black</option>
          <option value="255,0,0">Red</option>
        </select>
      </div>
      <div
        class="form-group pt-2"
        *ngIf="formatData.criteria && formatData.criteria.canEdit"
      >
        <wm-data-entity-formula
          id="data-entity-value"
          [required]="true"
          [criteria]="formatData.criteria.value"
          [thenLabelGetter]="getThenLabel"
          (criteriaChanged)="updateCriteria($event)"
          [formulaTitle]="'entity value'"
          [activity]="activity"
          [workflow]="workflow"
          *ngIf="formatData.criteria"
        ></wm-data-entity-formula>
      </div>
    </ng-container>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-dark"
      (click)="modal.close('Apply click')"
    >
      Apply
    </button>
  </div>
</ng-template>

<wm-modal-confirm
  [showCommandButton]="false"
  cancelText="Ok"
  [showButton]="false"
  [showButtonText]="false"
  title="Not Supported"
  moduleId="rotation"
  #rotationtModal
>
  <p>
    Rotated PDFs are not supported. Rotation can be removed from a PDF by
    loading in a PDF viewer/editor and re-outputting as a PDF through a print
    dialogue.
  </p>
</wm-modal-confirm>

<wm-modal-confirm
  [showCommandButton]="false"
  cancelText="Ok"
  [showButton]="false"
  [showButtonText]="false"
  title="Invalid Document"
  moduleId="invalidTemplate"
  #invalidTemplate
>
  <p>
    The document you uploaded failed to process. Please try again.
  </p>
  <p>
    If the problem persists, please try another document.
  </p>
</wm-modal-confirm>

<div class="row">
  <div class="col">
    <h3>Map Services</h3>
  </div>
  <div class="col">
    <button
      class="btn btn-raised btn-success float-right"
      (click)="addMapService()"
    >
      New Map Service
    </button>
  </div>
</div>

<wm-map-service-list [mapServices]="mapServices"></wm-map-service-list>

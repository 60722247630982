import { ApplicationStatus } from './../models/workflow-application';
import { Pipe, PipeTransform } from '@angular/core';
import { ActivityStatus } from '../models/activities';

@Pipe({
  name: 'applicationStatus'
})
export class ApplicationStatusPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    const a = ApplicationStatus[value];
    if (a) {
      const b = a
        .replace(/([A-Z])/g, ' $1')
        .replace(a.substring(0, 1), a.substring(0, 1).toUpperCase());
      return b;
    }
    return '';
  }
}

@Pipe({
  name: 'activityStatus'
})
export class ActivityStatusPipe implements PipeTransform {
  transform(value: any, ...args: any[]) {
    const a = ActivityStatus[value];
    if (a) {
      const b = a
        .replace(/([A-Z])/g, ' $1')
        .replace(a.substring(0, 1), a.substring(0, 1).toUpperCase());
      return b;
    }
    return '';
  }
}

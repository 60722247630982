import { TemplateField } from './../activities/print-template-activity';
import { DataEntity } from './data-entity';
import { MapService } from '../map-service';
import { MapLayer } from '../map-layer';
import { MapExtent } from '../mapextent';
import { fabric } from 'fabric';
import { MapDisplayConfiguration } from 'src/app/components/arcgis/map-display-configuration';
import { of, Observable } from 'rxjs';

// using literal types to avoid misspelling issues, but adds a layer of complexity because if we
// add a new class we will need to also update isValidhtmlClassName to handle the new class html class name.
export type MapDimensionLayout_htmlClassName =
  | 'screen-capture-layout-square'
  | 'screen-capture-layout-landscape'
  | 'screen-capture-layout-portrait'
  | 'screen-capture-layout-default';

export enum ScreenCaptureLayoutMethod {
  Default = 1,
  Square = 2,
  Landscape = 3,
  Portrait = 4
}

export class MapsketchDataEntity extends DataEntity {
  /** ArcGIS map service definition */
  // mapServiceId: string;
  mapConfig: MapDisplayConfiguration = new MapDisplayConfiguration();
  geometryWKT: string;
  geometryWKTs: string[];
  parcelNumber: string;
  parcelNumbers: string[];
  featureGeometryWKT: { [key: string]: { item1: string; item2: string }[] };
  screenCaptureLayoutMethod: ScreenCaptureLayoutMethod =
    ScreenCaptureLayoutMethod.Default;
  constructor(options?: Partial<MapsketchDataEntity>) {
    super(options);

    this.dataEntityTypeCode = 'mapsketch-data';
    this.dataEntityTypeDescription =
      'Use this entity type if you need to collect map sketch data.';
    this.dataEntityTypeName = 'Map Sketch Data';

    if (options) {
      Object.assign(this, options);
    }

    this.hasPreview = false;
  }

  public checkIfOrientationIsExpectedRatio(
    fieldInfo,
    expectedOrientation: ScreenCaptureLayoutMethod
  ) {
    const ratio = fieldInfo.width / fieldInfo.height;
    if (expectedOrientation === ScreenCaptureLayoutMethod.Default) {
      return true;
    }
    // i.e if the default was set and it was stretch the width of a landscape page, it will be above 1.97 which will stretch it too much, so tighten it up.
    if (
      expectedOrientation === ScreenCaptureLayoutMethod.Landscape &&
      (ratio > 1.57 && ratio < 1.97)
    ) {
      return true;
    }
    if (
      expectedOrientation === ScreenCaptureLayoutMethod.Portrait &&
      (ratio > 0.36 && ratio < 0.76)
    ) {
      return true;
    }
    if (
      expectedOrientation === ScreenCaptureLayoutMethod.Square &&
      ratio > 0.9 &&
      ratio < 1.1
    ) {
      return true;
    }
    return false;
  }
  public generateValid_htmlClassNameFromOptionText(
    layoutOptionText: string
  ): MapDimensionLayout_htmlClassName {
    const testMaphtmlClassName = `screen-capture-layout-${layoutOptionText.toLowerCase()}`;
    function isValidhtmlClassName(
      testText: string
    ): testText is MapDimensionLayout_htmlClassName {
      return [
        'screen-capture-layout-square',
        'screen-capture-layout-landscape',
        'screen-capture-layout-portrait',
        'screen-capture-layout-default'
      ].indexOf(testText) !== -1
        ? true
        : false;
    }

    return isValidhtmlClassName(testMaphtmlClassName)
      ? testMaphtmlClassName
      : 'screen-capture-layout-default';
  }

  private setScale(fieldInfo, image, helperFunctions) {
    const placeHolderWidth = fieldInfo.width
      ? helperFunctions.documentToScreenX(fieldInfo.width)
      : helperFunctions.documentToScreenX(200);
    const placeHolderHeight = fieldInfo.height
      ? helperFunctions.documentToScreenY(fieldInfo.height)
      : helperFunctions.documentToScreenY(200);

    // scale the placeholder width & height based on the scale of the document in the browser
    image.scaleX = placeHolderWidth / image.width;
    image.scaleY = placeHolderHeight / image.height;
  }

  async formatTemplateItem(fieldInfo, helperFunctions): Promise<fabric.Object> {
    // wait for the image to load before returning object. using promise so I don't have to change calling code
    const ScreenCaptureLayoutMethod_TextOption = ScreenCaptureLayoutMethod;

    return new Promise((resolve, reject) => {
      if (this.screenCaptureLayoutMethod == null) {
        this.screenCaptureLayoutMethod = ScreenCaptureLayoutMethod.Default;
      }
      const screenlayoutOption = ScreenCaptureLayoutMethod_TextOption[
        this.screenCaptureLayoutMethod
      ].toLowerCase();
      const url = `/assets/images/streetmap_${screenlayoutOption}.png`;
      const orientationValid = this.checkIfOrientationIsExpectedRatio(
        fieldInfo,
        this.screenCaptureLayoutMethod
      );
      if (!orientationValid) {
        switch (screenlayoutOption) {
          case 'default': {
            break;
          }
          case 'square': {
            fieldInfo.height = 200;
            fieldInfo.width = 200;
            break;
          }
          case 'landscape': {
            fieldInfo.height = 112;
            fieldInfo.width = 200;
            break;
          }
          case 'portrait': {
            fieldInfo.height = 200;
            fieldInfo.width = 112;
            break;
          }
        }
      }

      fabric.Image.fromURL(url, image => {
        this.setScale(fieldInfo, image, helperFunctions);
        image.left = helperFunctions.documentToScreenX(fieldInfo.x);
        image.top = helperFunctions.documentToScreenY(fieldInfo.y);
        image.hasBorders = true;
        image.padding = 1;
        image.borderColor = 'black';
        image.editable = false;
        image.lockUniScaling =
          this.screenCaptureLayoutMethod !== ScreenCaptureLayoutMethod.Default;
        if (!this.isValid) {
          image.strokeWidth = 8;
          image.stroke = '#07C';
        }

        resolve(image);
      });
    });
  }

  resizeTemplateItem(
    fieldInfo: TemplateField,
    helperFunctions,
    image: fabric.Object
  ): void {
    if (fieldInfo) {
      image.left = helperFunctions.documentToScreenX(fieldInfo.x);
      image.top = helperFunctions.documentToScreenY(fieldInfo.y);

      this.setScale(fieldInfo, image, helperFunctions);

      image.setCoords();
    }
  }
}

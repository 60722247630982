import {
  NumericDataEntity,
  NumericDataType
} from './../../../../../models/data-entities/numeric-data-entity';
import { Component, OnInit, ComponentFactoryResolver } from '@angular/core';
import { DataEntityInputComponent } from '../../data-entity-input/data-entity-input.component';

@Component({
  selector: 'wm-numeric-data-entity-input',
  templateUrl: './numeric-data-entity-input.component.html',
  styleUrls: ['./numeric-data-entity-input.component.css']
})
export class NumericDataEntityInputComponent extends DataEntityInputComponent
  implements OnInit {
  numericDE: NumericDataEntity;
  NumericDataType = NumericDataType;

  constructor(componentFactory: ComponentFactoryResolver) {
    super(componentFactory);
  }

  ngOnInit() {
    this.numericDE = this.entity as NumericDataEntity;

    if (
      !this.entity.externalDataSource ||
      !this.entity.externalDataSource.sourceConfig ||
      (this.entity.canEditExternalValue &&
        this.form.controls[this.entity.templateCode])
    ) {
      this.form.controls[this.entity.templateCode].enable();
    } else {
      this.form.controls[this.entity.templateCode].disable();
    }
  }
}

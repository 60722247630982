<div *ngIf="activity.model.reason == 'Completed'">
  You are about to close this application. Please carefully review the
  information entered below before clicking submit.
  <wm-application-readonly
    [applicationId]="context.applicationId"
    [activities]="activity.model.activities"
    [isTestApplication]="isTestApplication"
  ></wm-application-readonly>
</div>
<div *ngIf="activity.model.reason == 'Rejected'">
  Your Application has been rejected.
</div>

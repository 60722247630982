import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { DataService } from 'src/app/services';

@Component({
  selector: 'wm-auto-void-tester',
  templateUrl: './auto-void-tester.component.html',
  styleUrls: ['./auto-void-tester.component.css']
})
export class AutoVoidTesterComponent implements OnInit {
  asOfDateTime: string;
  processRunning: boolean = false;
  results: { applicationsVoided: number; applicationsProcessed: number };

  constructor(private _dataSvc: DataService) {}

  ngOnInit() {
    this.asOfDateTime = moment()
      .set('hour', 23)
      .set('minute', 0)
      .format('MM/DD/YYYY hh:mm A');
  }

  runProcess() {
    this.processRunning = true;
    const asOfDate: Date = moment(this.asOfDateTime).toDate();

    this._dataSvc.runAutoVoidProcess(asOfDate).subscribe(result => {
      this.processRunning = false;
      this.results = result;
    });
  }
}

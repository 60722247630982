<div class="row">
  <div class="col">
    <h2>Tags</h2>
  </div>
  <div class="col">
    <button
      class="btn btn-raised btn-primary float-right"
      (click)="showNewTag()"
    >
      Add Tag
    </button>
  </div>
</div>
<form [formGroup]="form">
  <div class="row" *ngIf="newTagName != null">
    <div class="col">
      <div class="form-group">
        <label class="bmd-label-static" for="newTag">Name *</label>
        <input
          class="form-control"
          [(ngModel)]="newTagName"
          formControlName="newTagName"
        />
        <wm-control-message
          [control]="form.controls['newTagName']"
        ></wm-control-message>
        <button
          type="reset"
          class="btn btn-raised btn-default"
          (click)="cancelTag()"
        >
          Cancel
        </button>
        <button
          type="submit"
          class="btn btn-raised btn-success"
          (click)="addTag()"
          [disabled]="form.invalid"
        >
          Save
        </button>
      </div>
    </div>
  </div>
</form>

<div class="row">
  <div class="col-lg-6">
    <div *ngIf="tags as tag; else loading">
      <ng-container *ngIf="tag.length; else noItems">
        <ul
          class="list-group"
          style="cursor: move;"
          dragula="tags"
          [(dragulaModel)]="tags"
          (dragulaModelChange)="orderChange()"
        >
          <li
            *ngFor="let tag of tags"
            class="list-group-item d-flex justify-content-between align-items-start"
          >
            {{ tag.name }}

            <wm-modal-confirm
              (accepted)="removeTag(tag)"
              [btnIcon]="'delete'"
              [showButton]="true"
              [showButtonText]="true"
              [commandText]="'Delete'"
              title="Delete Tag"
            >
              <p>Are you sure you want to remove this tag ({{ tag.name }})?</p>
              <br />
              <p>
                Some things to take note of.
              </p>
              <ul>
                <li>
                  This tag will be removed from all workflows.
                </li>
                <li>
                  This tag will be removed from all contractor types.
                </li>
              </ul>
            </wm-modal-confirm>
          </li>
        </ul>
      </ng-container>
      <ng-template #noItems><i>No tags defined</i></ng-template>
    </div>
  </div>
</div>
<ng-template #loading><i>loading...</i></ng-template>

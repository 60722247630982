import { ConditionSource } from './../condition-builder.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  Component,
  OnInit,
  Input,
  Output,
  TemplateRef,
  EventEmitter
} from '@angular/core';
import { ConditionTarget } from '../condition-builder.model';

@Component({
  selector: 'wm-condition-modal',
  templateUrl: './condition-modal.component.html',
  styleUrls: ['./condition-modal.component.css']
})
export class ConditionModalComponent implements OnInit {
  @Input() templateRef: TemplateRef<any>;
  @Input() target: ConditionTarget;
  @Input() conditionSources: ConditionSource[];
  @Output() conditionChanges: EventEmitter<boolean> = new EventEmitter();
  @Output() deleteTarget: EventEmitter<string> = new EventEmitter<string>();

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {}

  removeTarget() {
    this.deleteTarget.emit(this.target.id);
    this.activeModal.close('Remove Target');
  }
}

import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { TableColumn } from '@swimlane/ngx-datatable';
import { ToastrService } from 'ngx-toastr';
import { DataTableHelpers } from 'src/app/components/system/datatable/datatable-helper';
import { Actions, WorkflowType } from 'src/app/models';
import { ComponentIds } from 'src/app/models/component-ids';
import {
  FilterBuilderFilter,
  FilterBuilderOutput,
  FilterBuilderParam
} from 'src/app/models/filter-builder';
import {
  PagedPaymentReportResponse,
  PagedPaymentsReportRequest,
  PaymentTransaction,
  PaymentsReportRequest
} from 'src/app/models/financial-reports';
import { ColumnOption, GridSettings } from 'src/app/models/grid-settings';
import { ApplicationStatusPipe } from 'src/app/pipes/application-status.pipe';
import {
  DataService,
  SecurityService,
  SystemService,
  Utilities,
  WorkflowContextService
} from 'src/app/services';

@Component({
  selector: 'wm-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.css']
})
export class PaymentsComponent implements OnInit {
  loading = true;
  id = ComponentIds.PAYMENTS;
  title = 'Payments';
  report: PagedPaymentReportResponse;
  defaultFilters: FilterBuilderFilter[];
  @Output() itemRefunded: EventEmitter<PaymentTransaction> = new EventEmitter<
    PaymentTransaction
  >();
  timeFrame: FilterBuilderParam = {
    name: 'Confirmed Date',
    id: 'timeframe',
    inputType: 'date',
    types: ['range']
  };
  params: FilterBuilderParam[] = [
    {
      name: 'Permit Number',
      types: ['is', 'contains'],
      id: 'permitNumber'
    },
    {
      name: 'Amount',
      id: 'amount',
      types: ['is'],
      inputType: 'number'
    },
    {
      name: 'Payment Method',
      id: 'paymentMethod',
      types: ['is', 'in'],
      options: ['Online', 'CreditCardPOS', 'Check', 'Cash']
    },
    {
      name: 'Application',
      id: 'applicationName',
      options: []
    },
    {
      name: 'Applicant',
      id: 'payer'
    },
    this.timeFrame,
    {
      name: 'Collected By',
      id: 'collectedBy',
      types: ['is', 'contains']
    },
    {
      name: 'Recipients',
      id: 'recipients',
      types: ['is', 'contains']
    }
  ];

  filters: FilterBuilderOutput = {};
  canRefund = false;

  public useExcelFormat: boolean;

  @ViewChild('permitNumTmpl', { static: true }) permitNumTmpl: TemplateRef<any>;
  @ViewChild('refundTmpl', { static: true }) refundTmpl: TemplateRef<any>;
  @ViewChild('stackTmpl', { static: true }) stackTmpl: TemplateRef<any>;
  @ViewChild('paymentDateTmpl', { static: true }) paymentDateTmpl: TemplateRef<any>;

  columns: TableColumn[];
  availableColumns: TableColumn[];
  columnOptions: ColumnOption[] = [];
  exportColumns: string[];
  rows: any[];
  public page = {
    // The number of elements in the page
    size: 10,
    // The total number of elements
    totalElements: 0,
    // The total number of pages
    totalPages: 0,
    // The current page number
    pageNumber: 0
  };
  public sortField: string;
  public sortDescending = false;

  lastQuery = {};

  // column prop strings to be hidden on the grid by default
  defaultHiddenColumns: string[] = [
    'orderNumber',
    'transactionNumber',
    'appDescription',
    'paymentRequestId',
    'clientName',
    'collectedBy'
  ];
  // column prop strings that should have no option to hide in the grid, and are not exportable (such as Actions button)
  actionColumns: string[] = [];
  // column prop strings to be excluded from exports by default
  defaultExportExludedColumns: string[] = ['numbers'];

  constructor(
    private systemSvc: SystemService,
    private dataSvc: DataService,
    private securitySvc: SecurityService,
    private toast: ToastrService,
    private _context: WorkflowContextService,
    private appStatusPipe: ApplicationStatusPipe,
    private _dtHelpers: DataTableHelpers
  ) {}

  ngOnInit() {
    this.defaultFilters = [
      new FilterBuilderFilter({
        type: 'range',
        value: 'this_1_months',
        queryType: 'range',
        param: this.timeFrame,
        paramId: this.timeFrame.id
      })
    ];

    this.securitySvc
      .isLoginEntitled(Actions.REFUND_PAYMENT)
      .subscribe(result => {
        this.canRefund = result;
      });

    this.getApplications();

    this.columns = [
      {
        prop: 'permitId',
        name: 'Permit #',
        cellTemplate: this.permitNumTmpl
      },
      {
        prop: 'numbers',
        name: '#',
        cellTemplate: this.stackTmpl,
        width: 300
      },
      {
        prop: 'orderNumber',
        name: 'Order Number'
      },
      {
        prop: 'transactionNumber',
        name: 'Transaction Number'
      },
      {
        prop: 'amount',
        name: 'Amount'
      },
      {
        prop: 'paymentMethod',
        name: 'Payment Method'
      },
      {
        prop: 'paymentDate',
        name: 'Paid',
        cellTemplate: this.paymentDateTmpl
      },
      {
        prop: 'applicationName',
        name: 'Application'
      },
      {
        prop: 'username',
        name: 'Applicant'
      },
      {
        prop: 'appStatus',
        name: 'Status'
      },
      {
        prop: 'appDescription',
        name: 'Application Description'
      },
      {
        prop: 'paymentRequestId',
        name: 'Payment Request ID'
      },
      {
        prop: 'clientName',
        name: 'Jurisdiction'
      },
      {
        prop: 'collectedBy',
        name: 'Collected By'
      },
      {
        prop: 'recipients',
        name: 'Recipients'
      },
      {
        prop: 'isRefunded',
        name: 'Is Refunded',
        cellTemplate: this.refundTmpl
      }
    ];
    this.setAvailableColumns();
  }

  setAvailableColumns() {
    this.availableColumns = this.columns;
  }

  isEquivalent(a: any, b: any) {
    const aProps = Object.getOwnPropertyNames(a);
    const bProps = Object.getOwnPropertyNames(b);

    if (aProps.length !== bProps.length) {
      return false;
    }

    for (let i = 0; i < aProps.length; i++) {
      const propName = aProps[i];
      if (a[propName] !== b[propName]) {
        return false;
      }
    }
    return true;
  }

  resetColumnWidths() {
    for (const c of this.columns) {
      c.width = null;
    }

    this.columns = [...this.columns];
  }

  handleSettingsChanged(event: GridSettings) {
    this.filters = event.filters;
    this.page.size = event.pageSize;
    this.page.pageNumber = event.pageNumber;
    this.sortField = event.sortField;
    this.sortDescending = event.sortDescending;
    this.columnOptions = event.columnOptions;

    const visibleColumnProps = this.columnOptions
      .filter(co => co.checked)
      .map(co => co.name);

    this.columns = this.availableColumns.filter(
      c =>
        visibleColumnProps.includes(c.prop as string) ||
        this.actionColumns.includes(c.prop as string)
    );

    this.exportColumns = this.columnOptions
      .filter(co => co.includeInExport)
      .map(co => co.name);

    this.getReport();
  }

  handleColumnOptionsChanged(event: ColumnOption[]) {
    this.columnOptions = event;
    this.columnOptions = [...this.columnOptions];

    this.exportColumns = this.columnOptions
      .filter(co => co.includeInExport)
      .map(co => co.name);
  }

  getApplications() {
    this.dataSvc
      .getPublishedWorkflows(this._context.client, [
        WorkflowType.ContractorRegistration,
        WorkflowType.ContractorRenewal,
        WorkflowType.Other,
        WorkflowType.Permit,
        WorkflowType.Renewable
      ])
      .subscribe(workflows => {
        const param = this.params.find(p => p.id === 'applicationName');
        param.options = workflows
          .map(w => ({
            name: w.version.name,
            value: w.id
          }))
          .sort((a: any, b: any) => a.name - b.name);
      });
  }

  private getQuery(): PaymentsReportRequest {
    const filters = [];
    for (const id in this.filters) {
      if (this.filters[id]) {
        filters.push(this.filters[id]);
      }
    }

    const query = {
      clientId: this._context.client.id,
      filters,
      useExcelFormat: this.useExcelFormat,
      sortField: this.sortField,
      sortDescending: this.sortDescending,
      exportColumns: this.exportColumns
    };

    return query;
  }

  private getPagedQuery(): PagedPaymentsReportRequest {
    const filters = [];
    for (const id in this.filters) {
      if (this.filters[id]) {
        filters.push(this.filters[id]);
      }
    }

    const query = {
      clientId: this._context.client.id,
      filters,
      pageNumber: this.page.pageNumber,
      pageSize: this.page.size,
      sortField: this.sortField,
      sortDescending: this.sortDescending
    };

    return query;
  }

  getReport() {
    const query = this.getPagedQuery();
    if (!this.isEquivalent(query, this.lastQuery)) {
      this.lastQuery = { ...query };
      this.loading = true;
      this.systemSvc.getPagedPaymentsReport(query).subscribe(report => {
        this.page.totalElements = report.recordCount;
        this.page.totalPages = report.pageCount;
        this.rows = report.items.map(i => {
          i['permitId'] = i.permitId;
          i['orderNumber'] = i.orderNumber;
          i['transactionNumber'] = i.transactionNumber;
          i['amount'] = Utilities.formatCurrency(i.amount);
          i['paymentMethod'] = i.paymentMethod;
          i['paymentDate'] = i.paymentDate;
          i['applicationName'] = i.applicationName;
          i['username'] = i.username;
          i['appStatus'] = this.appStatusPipe.transform(i.appStatus);
          i['appDescription'] = i.applicationDescription;
          i['paymentRequestId'] = i.paymentRequestId;
          i['clientName'] = i.clientName;
          i['isRefunded'] = i.isRefunded;
          i['collectedBy'] = i.collectedBy;
          i['recipients'] = i.recipients;
          return i;
        });
        this.report = report;
        this.loading = false;
      });
    }
  }

  exportExcel() {
    this.useExcelFormat = true;
    this.export();
  }

  exportCsv() {
    this.useExcelFormat = false;
    this.export();
  }

  export() {
    const query = this.getQuery();
    this.systemSvc.exportPaymentsReport(query).subscribe(
      data => {
        if (this.useExcelFormat) {
          const blob = new Blob([data], {
            type:
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          });
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = `payments-report-export-${new Date().getTime()}.xlsx`;
          link.click();
        } else {
          const blob = new Blob([data], { type: 'text/csv' });
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = `payments-report-export-${new Date().getTime()}.csv`;
          link.click();
        }
      },
      err => {
        this.toast.error('Problem while downloading the file.');
        console.error(err);
      }
    );
  }

  refundPayment(e) {
    this.systemSvc.refundPaymentRequest(e).subscribe(result => {
      if (result) {
        // update local payment request to refunded
        const pr = this.report.items.find(p => p.paymentRequestId === e);
        if (pr) {
          pr.isRefunded = true;
          this.itemRefunded.next(pr);
        }
      }
    });
  }
}

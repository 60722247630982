import {
  ScreenActivity,
  ActivityModel
} from '../../models/activities/activity';

export class DecisionActivityModel extends ActivityModel {
  constructor(options?: Partial<DecisionActivityModel>) {
    super(options);
    if (options) {
      Object.assign(this, options);
    }
  }
}

export class ConditionDataDetails {
  templateCode: string;
  value: string;
}

export class DecisionActivity extends ScreenActivity<DecisionActivityModel> {
  conditionAppData: ConditionDataDetails[];

  constructor(options?: { [key: string]: any }) {
    super(options);
    this.type = 'decision-activity';
    this.name = 'Condition';
    this.description = 'Route the workflow based on a condition';
    if (options) {
      this.model = new DecisionActivityModel(options.model);
    } else {
      this.model = new DecisionActivityModel();
    }

    this.model.screenName = this.model.screenName || 'Condition';
  }

  public displayOnScreen(): boolean {
    return false;
  }
}

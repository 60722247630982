import { Component, OnInit, Input, SimpleChange } from '@angular/core';
import { Securable, Role, Actions, Permission, Action } from '../../../models';
import { SecurityService, WorkflowContextService } from '../../../services';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'wm-advanced-permissions-editor',
  templateUrl: './advanced-permissions-editor.component.html',
  styleUrls: ['./advanced-permissions-editor.component.css']
})
export class AdvancedPermissionsEditorComponent implements OnInit {
  @Input() securable: Securable;
  @Input() includeApplicant = false;
  @Input() permissionTitle: string;
  @Input() showExpandCollapse = true;

  roles: Role[];
  shouldShowAdvancePermissions = false;
  Actions: Actions = Actions;
  availableActions: string[];
  canManageRoles = false;

  constructor(
    private _securitySvc: SecurityService,
    public context: WorkflowContextService,
    private _toastrSvc: ToastrService
  ) {}

  // ngOnChanges(changes: { [key: string]: SimpleChange }) {
  //   for (const change in changes) {
  //     if (change === 'securable') {
  //       this.loadInheritedPermissions();
  //     }
  //   }
  // }
  getActionName(actionId: string) {
    return Actions.ActionInformation[actionId.toLowerCase()].name;
  }

  refreshPermissions() {
    this.loadInheritedPermissions();
  }

  getRoleById(roleId: string): Role {
    let role: Role;

    this.roles.some((value: Role) => {
      if (value.id === roleId) {
        role = value;
        return true;
      }
    });

    return role;
  }

  disabledTooltip(roleId: string, a: string): string {
    const perm = this.getSecurablePermission(roleId, a);
    if (perm) {
      return perm.inheritanceMessage;
    }
  }

  getSecurablePermission(
    roleId: string,
    actionId: string
  ): Partial<Permission> {
    roleId = roleId.toLowerCase();
    actionId = actionId.toLowerCase();
    return this.securable.permissions[roleId] &&
      this.securable.permissions[roleId][actionId]
      ? this.securable.permissions[roleId][actionId]
      : null;
  }

  isInherited(roleId: string, actionId: string): boolean {
    const perm = this.getSecurablePermission(roleId, actionId);

    return perm ? perm.isInherited : false;
  }

  hasPermission(roleId: string, a: string): boolean {
    return (
      this.isInherited(roleId, a) ||
      this.getSecurablePermission(roleId, a) !== null
    );
  }

  showAdvancedPermissions() {
    this.shouldShowAdvancePermissions = !this.shouldShowAdvancePermissions;
  }

  getAvailableActions(securable: Securable): Observable<string[]> {
    return this._securitySvc.getSecurableActions(securable);
  }

  togglePermission(roleId: string, a: string, checked: boolean) {
    if (!this.securable.permissions) {
      this.securable.permissions = {};
    }
    a = a.toLowerCase();

    let rolePermissions = this.securable.permissions[roleId];

    if (!rolePermissions) {
      rolePermissions = {};
      this.securable.permissions[roleId] = rolePermissions;
    }

    let roleAction = rolePermissions[a];

    if (checked) {
      roleAction = new Permission({
        isInherited: false,
        actionId: a,
        roleID: roleId,
        securableId: this.securable.id
      });
      rolePermissions[a] = roleAction;
    } else {
      delete rolePermissions[a];
    }

    this.securable.permissions[roleId] = rolePermissions;
  }

  loadInheritedPermissions(): Observable<{
    [key: string]: { [key: string]: Partial<Permission> };
  }> {
    return this._securitySvc.getSecurablePermissions(this.securable);
  }

  ngOnInit() {
    this.roles = null;

    if (!this.showExpandCollapse) {
      this.shouldShowAdvancePermissions = true;
    }

    this._securitySvc
      .isLoginEntitled(Actions.ROLE_MANAGE)
      .subscribe(isEntitled => {
        this.canManageRoles = isEntitled;
      });

    this._securitySvc.getRoles(this.context.client).subscribe(roles => {
      if (this.includeApplicant) {
        if (
          roles.filter((value: Role) => {
            return value.id.toUpperCase() === SecurityService.APPLICANT_ROLE.id;
          }).length === 0
        ) {
          roles.splice(0, 0, SecurityService.APPLICANT_ROLE);
        }
      }

      this.availableActions = this.securable.availableActions;
      this.roles = roles;
    });
  }
}

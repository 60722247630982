<div class="modal-header">
  <h4 class="modal-title">Conditions</h4>
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="activeModal.dismiss('Cross click')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" *ngIf="target">
  <p>If the following conditions are met...</p>

  <ng-container *ngTemplateOutlet="templateRef; context: { target: target }">
  </ng-container>

  <div class="mt-3"></div>
  <wm-condition-group
    [group]="target.group"
    [level]="0"
    [target]="target"
    [conditionSources]="conditionSources"
    (conditionChanges)="conditionChanges.emit($event)"
  ></wm-condition-group>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-red" (click)="removeTarget()">Delete Value</button>
  <button
    type="button"
    class="btn btn-outline-dark"
    (click)="activeModal.close('Close click')"
  >
    Close
  </button>
</div>

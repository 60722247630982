import { map } from 'rxjs/operators';
import { of } from 'rxjs';
import { WorkflowValidationItemComponent } from './../components/workflow/workflow-validation-item/workflow-validation-item.component';
import { DataService } from 'src/app/services';
import { Injectable, EventEmitter, Output } from '@angular/core';
import { ValidationSeverity, ValidationResponse } from '../models/validation';

/**
 * Service and utilities to validate workflows
 */
@Injectable({
  providedIn: 'root'
})
export class WorkflowValidationService {
  validationResult: Promise<ValidationResponse[]>;
  activityValidationResult: {
    [key: string]: Promise<ValidationResponse[]>;
  } = {};
  dataEntityValidationResult: {
    [key: string]: Promise<ValidationResponse[]>;
  } = {};

  validationResultCache: { [key: string]: ValidationResponse[] } = {};

  @Output() validating: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() validated: EventEmitter<boolean> = new EventEmitter<boolean>();
  /**
   * @ignore
   */
  constructor(private _dataSvc: DataService) {}

  /**
   * @param items: validation response items to cache
   */
  cacheValidation(
    items: ValidationResponse[],
    workflowId?: string,
    activityId?: string,
    templateCode?: string
  ) {
    if ((items || []).length === 0) {
      if (templateCode) {
        this.validationResultCache[templateCode] = [];
      } else if (activityId) {
        this.validationResultCache[activityId] = [];
      } else if (workflowId) {
        this.validationResultCache[workflowId] = [];
      }
    } else {
      let hasActivityErrors = false;
      let hasWorkflowErrors = false;

      for (let idx = 0; idx < items.length; idx++) {
        const item = items[idx];
        const key = `${item.id}|${item.activityId}`;
        if (item.messages.length > 0) {
          this.validationResultCache[key] = [item];
        } else if (this.validationResultCache[key]) {
          delete this.validationResultCache[key];
        }

        if (key === activityId) {
          hasActivityErrors = true;
        }
        if (key === workflowId) {
          hasWorkflowErrors = true;
        }
      }

      // cache empty list if there are specific activity errors when caching activity validation
      if (activityId && !hasActivityErrors) {
        this.validationResultCache[activityId] = [];
      }

      if (workflowId && !hasWorkflowErrors) {
        this.validationResultCache[workflowId] = [];
      }
    }
  }

  getCachedItem(
    workflowId: string,
    activityId?: string,
    templateCode?: string
  ) {
    let result = null;

    if (templateCode) {
      result = this.validationResultCache[templateCode];
    }

    if (!result && activityId) {
      result = this.validationResultCache[activityId];
    }

    if (!result && workflowId) {
      const keys = Object.keys(this.validationResultCache);
      if (keys.length > 0) {
        result = keys
          .filter(a => this.validationResultCache[a].length > 0)
          .map(a => {
            return Object.assign(
              new ValidationResponse(),
              this.validationResultCache[a][0]
            );
          });
      } else {
        result = null;
      }
    }

    return result;
  }

  clearValidationCache(key?: string) {
    if (key) {
      delete this.validationResultCache[key];
    } else {
      this.validationResultCache = {};
    }
  }

  /**
   * Get validation information for a workflow
   *
   * @param workflowId ID of the workflow to validate
   */
  async validate(
    workflowId: string,
    force?: boolean,
    runExtendedValidation?: boolean,
    versionId?: string
  ): Promise<ValidationResponse[]> {
    const cacheResult = this.getCachedItem(workflowId);

    if (!cacheResult || force) {
      if (!this.validationResult) {
        this.validating.emit(true);
        this.validationResult = this._dataSvc
          .validateWorkflow(workflowId, runExtendedValidation, versionId)
          .toPromise();
        this.validationResult.then(result => {
          this.cacheValidation(result, workflowId);
          this.validationResult = null;
          this.validated.emit(true);
        });
      }

      return this.validationResult;
    } else {
      return of(cacheResult).toPromise();
    }
  }

  async validateActivity(
    workflowId: string,
    activityId: string,
    force?: boolean,
    fullTree?: boolean
  ): Promise<ValidationResponse[]> {
    // check to see if the workflow validation is running
    // if so, wait on it and grab the items from cache
    if (this.validationResult) {
      return this.validationResult.then(() => {
        // call again when the validation stops
        return this.validateActivity(workflowId, activityId, fullTree);
      });
    } else {
      const cachedResults = this.getCachedItem(workflowId, activityId);

      if (!cachedResults || force) {
        let activityObs = this.activityValidationResult[activityId];

        if (!activityObs) {
          this.validating.emit(true);
          activityObs = this._dataSvc
            .validateWorkflowActivity(workflowId, activityId, fullTree)
            .toPromise();

          this.activityValidationResult[activityId] = activityObs;

          activityObs.then(items => {
            this.cacheValidation(items, workflowId, activityId);
            delete this.activityValidationResult[activityId];
            this.validated.emit(true);
          });
        }

        return activityObs;
      }

      return of(cachedResults).toPromise();
    }
  }

  async validateDataEntity(
    workflowId: string,
    activityId: string,
    templateCode: string
  ): Promise<ValidationResponse[]> {
    // check to see if the workflow validation is running
    // if so, wait on it and grab the items from cache
    if (this.validationResult) {
      return this.validationResult.then(() => {
        // call again when the validation stops
        return this.validateDataEntity(workflowId, activityId, templateCode);
      });
    } else {
      const activityObs = this.activityValidationResult[activityId];

      if (activityObs) {
        return activityObs.then(() => {
          return this.validateDataEntity(workflowId, activityId, templateCode);
        });
      } else {
        const cachedDEResults = this.getCachedItem(
          workflowId,
          activityId,
          templateCode
        );

        if (!cachedDEResults) {
          const deObs = this._dataSvc
            .validateWorkflowDataEntity(workflowId, activityId, templateCode)
            .toPromise();

          this.dataEntityValidationResult[templateCode] = deObs;

          deObs.then(items => {
            this.cacheValidation(items, workflowId, activityId, templateCode);
            delete this.dataEntityValidationResult[templateCode];
            this.validated.emit(true);
          });

          return deObs;
        } else {
          return of(cachedDEResults).toPromise();
        }
      }
    }
  }

  public getSeverity(severity: ValidationSeverity) {
    switch (severity) {
      case ValidationSeverity.Error:
        return { class: 'text-danger', name: 'ERROR:' };

      case ValidationSeverity.Warning:
        return { class: 'text-warning', name: 'WARNING:' };

      default:
        return { class: '', name: '' };
    }
  }
}

<div *ngIf="agendaType">
  <div class="row m-1 pb-3">
    <div class="flex-column">
      <h3>Agendas - {{ agendaType.name }}</h3>
    </div>
    <div class="flex-column pl-5">
      <div *ngIf="canManageAgendas() | async">
        <button
          type="button"
          class="btn btn-raised btn-primary float-right"
          (click)="agendaTypeSettingsModal.open()"
        >
          Agenda Type Settings
        </button>
      </div>
    </div>
    <div class="flex-column pl-3">
      <div *ngIf="canManageAgendas() | async">
        <button
          type="button"
          class="btn btn-raised btn-success float-right"
          (click)="newAgenda()"
        >
          New Agenda
        </button>
      </div>
    </div>
  </div>

  <table class="table">
    <tr>
      <th>Date</th>
      <th>Description</th>
    </tr>
    <tr *ngFor="let agenda of agendas">
      <td class="align-middle" style="white-space:nowrap">
        <a
          [routerLink]="[
            '/admin/jurisdiction',
            clientId,
            'meetings',
            'agenda-types',
            'agenda-type',
            agendaTypeId,
            'view-agenda',
            agenda.id
          ]"
        >
          {{ agenda.meetingDateTime | date: 'M/d/yyyy h:mm a' }}
        </a>
      </td>
      <td class="align-left">
        <i>
          {{ agenda.description }}
        </i>
      </td>
    </tr>
    <tr *ngIf="!agendas || (agendas && agendas.length === 0)">
      <td>No agendas to display</td>
    </tr>
  </table>
</div>

<wm-modal-confirm
  title="Agenda Type Settings"
  [form]="form"
  [showButtonText]="false"
  [showButton]="false"
  commandText="Save"
  (canceled)="onModalCancel()"
  (accepted)="onModalSave()"
  #agendaTypeSettingsModal
>
  <div [formGroup]="form" *ngIf="changingAgendaType">
    <div class="form-group">
      <label class="bmd-label-floating" for="name">Name</label>
      <input
        type="text"
        class="form-control"
        [(ngModel)]="changingAgendaType.name"
        name="name"
        formControlName="name"
      />
      <wm-control-message
        [control]="form.controls['name']"
      ></wm-control-message>
    </div>
    <div class="form-group">
      <label class="bmd-label-floating" for="description">Description</label>
      <input
        type="text"
        class="form-control"
        [(ngModel)]="changingAgendaType.description"
        name="description"
        formControlName="description"
      />
      <wm-control-message
        [control]="form.controls['description']"
      ></wm-control-message>
    </div>
    <div class="form-group">
      <label class="bmd-label-floating" for="header">Agenda Header</label>
      <ckeditor
        #headerEditor
        [editor]="Editor"
        [config]="editorConfig"
        formControlName="header"
        [(ngModel)]="changingAgendaType.header"
        (ready)="editorReady($event)"
      ></ckeditor>
      <wm-control-message
        [control]="form.controls['header']"
      ></wm-control-message>
    </div>
    <div class="form-group">
      <label class="bmd-label-floating" for="defaultItemsNumber"
        >Default number of agenda items per meeting</label
      >
      <input
        type="text"
        class="form-control"
        [(ngModel)]="changingAgendaType.defaultItemCount"
        name="defaultItemsNumber"
        formControlName="defaultItemsNumber"
      />
      <wm-control-message
        [control]="form.controls['defaultItemsNumber']"
      ></wm-control-message>
    </div>
  </div>
</wm-modal-confirm>

<h3>SFTP Configuration</h3>
<div [formGroup]="form" *ngIf="configuration">
  <div class="form-group col-md-12 col-sm-12">
    <label for="connection" class="bmd-label-static">
      SFTP Connection
    </label>
    <select
      class="form-control"
      name="connection"
      formControlName="connection"
      [(ngModel)]="configuration.connectionId"
    >
      <option [ngValue]="null" selected disabled>
        Select an SFTP Connection
      </option>
      <option
        *ngFor="let cx of configuration.availableConnections"
        [ngValue]="cx.id"
      >
        {{ cx.name }}
      </option>
    </select>
  </div>
</div>

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Activity, ActivityModel } from '../../../../models/activities';
import { Workflow } from '../../../../models';

@Component({
  selector: 'wm-activity-editor-base',
  templateUrl: './activity-editor-base.component.html',
  styleUrls: ['./activity-editor-base.component.css']
})
export class ActivityEditorBaseComponent implements OnInit {
  @Input() form: UntypedFormGroup;
  @Input() activity: Activity<ActivityModel>;
  @Output() saved: EventEmitter<any> = new EventEmitter<any>();
  @Output() loading: EventEmitter<any> = new EventEmitter<any>();
  @Input() workflow: Workflow;
  @Output() needsValidated: EventEmitter<any> = new EventEmitter<any>();

  constructor() {}

  ngOnInit() {}

  save(): boolean {
    return true;
  }
}

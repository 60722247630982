<div class="container">
  <form [formGroup]="form">
    <div class="row">
      <div class="col">
        <div class="form-group">
          <label class="bmd-label-floating" for="mapLayer">Map Layer</label>
          <app-loader *ngIf="!mapLayers"></app-loader>
          <select
            class="form-control"
            id="mapLayer"
            *ngIf="mapLayers"
            [(ngModel)]="selectedMapLayerId"
            formControlName="mapLayer"
            (ngModelChange)="getCombinedQueryMapLayer()"
          >
            <option></option>
            <option
              *ngFor="let l of mapLayers"
              [value]="l.mapLayerIndexItemId"
              >{{ l.mapLayerIndexItem.name }}</option
            >
          </select>
        </div>
      </div>
    </div>
  </form>

  <ng-container *ngIf="combinedLayerConfig">
    <div class="row">
      <div class="col-xs-2 col-lg-12">
        <wm-query-map-layer-activity-input
          id="activityInput"
          [activity]="activity"
          [hideResultsOnSelect]="true"
          [form]="form"
          *ngIf="activity"
          [goNextOnMapClick]="true"
          (goNext)="goNext($event)"
          (searched)="onFeatureSearch()"
        >
        </wm-query-map-layer-activity-input>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <wm-workflow-application-list
          *ngIf="showResults && myWorkflowsFilter"
          id="workflow-application-list-parcel"
          [columns]="columns"
          [workflowId]="null"
          [staticFilters]="myWorkflowsFilter"
          [expandToChild]="true"
          [UseMap]="false"
          [showAll]="true"
        >
        </wm-workflow-application-list>
      </div>
    </div>
  </ng-container>
</div>

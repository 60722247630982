import { Component, OnInit } from '@angular/core';
import { ActivityEditorBaseComponent } from '../../activity-editor-base/activity-editor-base.component';
import { ActivityView } from 'src/app/views/master-views/app.view/app.view.component';

@Component({
  selector: 'wm-required-user-activity-view',
  templateUrl: './required-user-activity-view.component.html',
  styleUrls: ['./required-user-activity-view.component.css']
})
export class RequiredUserActivityViewComponent extends ActivityView
  implements OnInit {
  constructor() {
    super();
  }

  ngOnInit() {}
}

<app-loader *ngIf="!members"></app-loader>

<div *ngIf="members && members.length === 0">
  <p>No role members to display</p>
</div>

<div *ngIf="members && members.length > 0">
  <ul class="list-group">
    <li
      *ngFor="let member of members"
      class="list-group-item d-flex justify-content-between align-items-start"
    >
      <span class="align-self-center">
        {{
          member.user
            ? member.user.userName + ' (' + member.user.name + ')'
            : 'unknown'
        }}
      </span>

      <wm-modal-confirm
        [commandText]="'Remove'"
        [showButtonText]="true"
        [moduleId]="member.user?.id"
        [title]="'Remove Member'"
        (accepted)="removed.emit($event)"
        class="align-self-center"
      >
        <p>
          Are you sure you want to remove this member ({{
            member.user?.userName
          }})?
        </p>

        <p>Removing the user from this role will do the following:</p>
        <ul>
          <li>
            Remove ability to perform administrative functions on workflows this
            role is responsible for.
          </li>
          <li>
            Remove access to workflows that this role is responsible for.
          </li>
        </ul>
      </wm-modal-confirm>
    </li>
  </ul>
</div>

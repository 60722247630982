import { FormActivity, FormActivityModel } from '.';
import {
  DataEntity,
  FreeFormTextDataEntity,
  DateDataEntity,
  ListDataEntity,
  ControlType
} from '../data-entities';
import { Utilities } from '../../services';

export class ContractorRenewalDecisionActivity extends FormActivity {
  hideExpirationDate: boolean;
  showHideExpirationDateOption: boolean;

  constructor(options?: Partial<ContractorRenewalDecisionActivity>) {
    super(options);

    if (options) {
      Object.assign(this, options);
    }

    this.model = new FormActivityModel(options ? options.model : null);

    this.model.screenName =
      this.model.screenName || 'Contractor Renewal Decision';
  }
}

import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { PaymentAccountConfigBaseComponent } from '../payment-account-config-base/payment-account-config-base.component';

@Component({
  selector: 'wm-payment-account-config-payment-stub',
  templateUrl: './payment-account-config-payment-stub.component.html',
  styleUrls: ['./payment-account-config-payment-stub.component.css']
})
export class PaymentAccountConfigPaymentStubComponent
  extends PaymentAccountConfigBaseComponent
  implements OnInit {
  constructor(fb: UntypedFormBuilder) {
    super(fb);
    this.showUsername = false;
    this.showPassword = false;
  }

  ngOnInit() {}
}

import { ToastrService } from 'ngx-toastr';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  tap,
  filter
} from 'rxjs/operators';
import { Observable } from 'rxjs';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DataEntity } from './../../../../../models/data-entities/data-entity';
import { Workflow, WorkflowType } from 'src/app/models';
import { ActivityEditorBaseComponent } from './../../activity-editor-base/activity-editor-base.component';
import {
  Component,
  OnInit,
  Inject,
  forwardRef,
  Input,
  ViewChild,
  ChangeDetectorRef
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { WorkflowService, WorkflowContextService } from 'src/app/services';
import { ActivityEditorComponent } from '../../activity-editor/activity-editor.component';
import { ModalConfirmComponent } from 'src/app/components/system/modal-confirm/modal-confirm.component';
import { InitiateWorkflowActivity } from 'src/app/models/activities/initiate-workflow-activity';
import { DataEntityMapping } from 'src/app/models/activities/data-entity-mapping';

export class AddMappingConfig {
  sourceName: string;
  targetName: string;
  to: string;
  sourceEntities: DataEntity[];
  targetEntities: DataEntity[];
}

@Component({
  selector: 'wm-initiate-workflow-editor',
  templateUrl: './initiate-workflow-editor.component.html',
  styleUrls: ['./initiate-workflow-editor.component.css']
})
export class InitiateWorkflowEditorComponent extends ActivityEditorBaseComponent
  implements OnInit {
  @Input() activity: InitiateWorkflowActivity;
  @ViewChild('warnMappingsResetModal', { static: true })
  warnMappingsResetModal: NgbModalRef;
  @ViewChild('updateConfirmationModal', { static: true })
  updateConfirmationModal: ModalConfirmComponent;
  @ViewChild('deleteInvalidConfirmationModal', { static: false })
  deleteInvalidConfirmationModal: ModalConfirmComponent;
  activityForm: UntypedFormGroup;
  parentEntities: DataEntity[] = [];
  childEntityOptions: DataEntity[] = [];
  dataMappingForm!: UntypedFormGroup;
  newWorkflowVersionId: string;
  newMapping: DataEntityMapping = {
    sourceDataEntity: null,
    targetDataEntity: null,
    isSourceValid: true,
    isTargetValid: true,
    isPublishedTargetValid: true
  };

  showInvalidMappingsText = false;
  invalidMappingsTextHeader = 'show details';
  invalidMappingsTo = '';

  loadingWorkflowVersionOptions = true;
  workflowExistsInOptionsList = true;
  workflowVersionOptions: {
    versionId: string;
    workflowVersionName: string;
    workflowId: string;
  }[];

  parentName = 'Parent';

  addMappingConfig: AddMappingConfig = {
    sourceName: 'Child',
    targetName: 'Parent',
    to: 'parent',
    sourceEntities: [],
    targetEntities: []
  };

  constructor(
    @Inject(forwardRef(() => WorkflowService))
    public workflowSvc: WorkflowService,
    @Inject(forwardRef(() => WorkflowContextService))
    private _context: WorkflowContextService,
    public modalService: NgbModal,
    private toastr: ToastrService,
    private _ref: ChangeDetectorRef
  ) {
    super();
  }

  async ngOnInit() {
    this.workflow = this.workflow || this._context.workflow;
    this.parentName = this.workflow.version.name;
    this.newWorkflowVersionId = this.activity.mostRecentlyPublishedTargetWorkflowVersionId;
    this.dataMappingForm = new UntypedFormGroup({
      addMappingSource: new UntypedFormControl(
        this.newMapping.sourceDataEntity,
        [Validators.required]
      ),
      addMappingTarget: new UntypedFormControl(
        this.newMapping.targetDataEntity,
        [Validators.required]
      ),
      addMappingTargetInput: new UntypedFormControl(
        this.newMapping.targetDataEntity,
        [Validators.required]
      )
    });

    this.activityForm = new UntypedFormGroup({});
    this.populateParentEntityOptions();

    this.workflowVersionOptions = [];

    await this.setChildEntityOptions();
    const workflows = await this.workflowSvc
      .getPublishedWorkflows(this._context.client, [
        WorkflowType.Permit,
        WorkflowType.Other,
        WorkflowType.Renewable
      ])
      .toPromise();
    this.loadingWorkflowVersionOptions = false;
    this.workflowVersionOptions = workflows
      .filter(w => w.id !== this.workflow.id)
      .map(v => {
        return {
          versionId: v.version.id,
          workflowVersionName: v.version.name,
          workflowId: v.version.workflowId
        };
      });
    // figure out if the child workflow that is currently selected is in the list.  If it isn't then notify the user.
    if (this.newWorkflowVersionId) {
      this.workflowExistsInOptionsList =
        this.workflowVersionOptions.filter(
          w => w.versionId === this.newWorkflowVersionId
        ).length > 0;
    }
  }
  async populateParentEntityOptions() {
    this.parentEntities = await this.workflowSvc
      .getDataEntitiesBeforeMe(this.workflow, this.activity)
      .toPromise();
    // now filter out the system entities

    if (this.parentEntities) {
      this.parentEntities.filter(de => {
        if (de.isSystemEntity === false) {
          return true;
        }
      });
      this.parentEntities = this.parentEntities.filter(
        v => v.activityId !== this.activity.id
      );
    } else {
      this.parentEntities = [];
    }
  }
  getUpdateTitle() {
    if (
      this.activity.mostRecentlyPublishedTargetWorkflowVersionId ===
      this.activity.model.targetWorkflowVersionId
    ) {
      return 'This Activity is already referencing the most recently published version.';
    } else {
      return 'Update the Workflow to the most recently published version.';
    }
  }
  updateWorkflow() {
    this.activity.model.targetWorkflowVersionId = this.activity.mostRecentlyPublishedTargetWorkflowVersionId;
    this.setChildEntityOptions(true);
  }
  startUpdateWorkflow() {
    this.updateConfirmationModal.open();
  }
  changeWorkflow() {
    this.modalService
      .open(this.warnMappingsResetModal, {
        ariaLabelledBy: 'modal-warn-title'
      })
      .result.then(e => {
        if (e === 'continue') {
          this.activity.model.targetWorkflowVersionId = this.newWorkflowVersionId;
          const versionWorkflowList = this.workflowVersionOptions.map(
            workflow => {
              return {
                workflowVersionId: workflow.versionId,
                workflowId: workflow.workflowId,
                workflowName: workflow.workflowVersionName
              };
            }
          );
          const targetWorkflow = versionWorkflowList.filter(
            vw => vw.workflowVersionId === this.newWorkflowVersionId
          )[0];
          this.activity.model.targetWorkflowId = targetWorkflow.workflowId;
          this.activity.model.targetWorkflowName = targetWorkflow.workflowName;
          this.activity.model.targetWorkflowVersionId =
            targetWorkflow.workflowVersionId;
          // this.getChildWorkflow();
          this.setChildEntityOptions(true);
        }
      })
      .catch(e => {
        // this is here to catch the cancel of the modal which we don't need to do anything about.
      });
  }

  onSave() {
    this.saved.emit();
  }

  async getChildWorkflow() {
    if (this.workflowVersionOptions) {
      const childWorkflow = this.workflowVersionOptions.find(
        wf => wf.versionId === this.activity.model.targetWorkflowVersionId
      );

      if (childWorkflow) {
        // updating child workflow model with new data.

        this.activity.model.targetWorkflowName =
          childWorkflow.workflowVersionName;
        this.activity.model.targetWorkflowVersionId = childWorkflow.versionId;
        this.activity.model.targetWorkflowId = childWorkflow.workflowId;
      }
    }
  }
  resetMappings() {
    // no childToParentMappings to reset
    this.activity.model.parentToChildMappings =
      this.activity.model.parentToChildMappings || [];
    // this.generateMappingsChild(); // eventually we can remove this method, but for the time being we are commenting it out. because of performance issues we decided not to automatically add them anymore ~jm20230404
    this.onSave();
  }
  async setChildEntityOptions(generateMappings?: boolean) {
    this.childEntityOptions = await this.workflowSvc
      .getWorkflowVersionDataEntities(
        this.activity.model.targetWorkflowVersionId,
        null,
        true
      )
      .toPromise();
    if (this.childEntityOptions) {
      this.childEntityOptions.filter(de => {
        if (de.isSystemEntity === false) {
          return true;
        }
      });
    }
    if (generateMappings) {
      this.resetMappings();
    }
  }

  get addMappingSource() {
    return this.dataMappingForm.get('addMappingSource');
  }

  get addMappingTarget() {
    return this.dataMappingForm.get('addMappingTarget');
  }
  get addMappingTargetInput() {
    return this.dataMappingForm.get('addMappingTargetInput');
  }

  changeMappingType(mapping: DataEntityMapping) {
    mapping.targetDataEntity = null;
  }

  deleteMapping(mapping: DataEntityMapping, to: string) {
    if (to === 'parent') {
      // no child to parent on initiate workflow
    } else {
      const index = this.activity.model.parentToChildMappings.findIndex(
        m =>
          m.sourceDataEntity === mapping.sourceDataEntity &&
          m.targetDataEntity === mapping.targetDataEntity
      );
      this.activity.model.parentToChildMappings.splice(index, 1);
    }
  }
  removeAllMappings(to: string) {
    if (to === 'parent') {
      // no child to parent on initiate workflow
    } else {
      this.activity.model.parentToChildMappings = [];
    }
  }
  startRemoveInvalidMappings(to: string) {
    this.showInvalidMappingsText = false;
    if (
      this.activity.mostRecentlyPublishedTargetWorkflowVersionId !==
      this.activity.model.targetWorkflowVersionId
    ) {
      this.deleteInvalidConfirmationModal.open();
    } else {
      this.removeInvalidMappings(to);
    }

    this.invalidMappingsTo = to;
  }
  toggleInvalidMappingsText() {
    this.showInvalidMappingsText = !this.showInvalidMappingsText;
    this.invalidMappingsTextHeader = this.showInvalidMappingsText
      ? 'hide details'
      : 'show details';
  }
  removeInvalidMappings(to?: string) {
    to = to ? to : this.invalidMappingsTo;
    if (to === 'parent') {
      // no child to parent on initiate workflow
    } else {
      this.activity.model.parentToChildMappings = this.activity.model.parentToChildMappings.filter(
        function(el) {
          return el.isTargetValid === true && el.isSourceValid === true;
        }
      );
    }
  }
  nameMyMappedDataEntity(e) {
    // since the parent to child will expect us to select from a drop down list, please to auto name it.
    if (this.addMappingConfig.to === 'parent') {
      this.newMapping.targetDataEntity = `CHILD_${e.target.value.toUpperCase()}`;
    }
  }
  openAddMapping(content, to: string) {
    if (!this.newMapping) {
      this.newMapping = {
        sourceDataEntity: null,
        targetDataEntity: null,
        isSourceValid: false,
        isTargetValid: false,
        isPublishedTargetValid: false
      };
    } else {
      this.newMapping.sourceDataEntity = null;
      this.newMapping.targetDataEntity = null;
      this.newMapping.isSourceValid = true;
      this.newMapping.isTargetValid = true;
      this.newMapping.isPublishedTargetValid = true;
    }

    if (to === 'parent') {
      this.addMappingConfig = {
        to,
        sourceName: this.activity.model.targetWorkflowName,
        targetName: this.parentName,
        sourceEntities: this.childEntityOptions,
        targetEntities: this.parentEntities
      };
    } else {
      this.addMappingConfig = {
        to,
        sourceName: this.parentName,
        targetName: this.activity.model.targetWorkflowName,
        sourceEntities: this.parentEntities,
        targetEntities: this.childEntityOptions
      };
    }

    this.modalService
      .open(content, { ariaLabelledBy: 'modal-add-title' })
      .result.then(
        result => {
          if (result === 'add') {
            const newMapping = {
              ...this.newMapping
            };

            if (to === 'parent') {
              // there is no child to parent mappings on the initiate workflow
            } else {
              this.activity.model.parentToChildMappings =
                this.activity.model.parentToChildMappings || [];
              this.activity.model.parentToChildMappings.push(newMapping);
            }
          }
        },
        reason => {
          this.newMapping = null;
        }
      )
      .catch(e => {
        // this is here to catch the cancel of the modal which we don't need to do anything about.
      });
  }

  generateMappingsChild() {
    // there are no child to parent mappings in the initate workflow
  }

  searchParent = (text$: Observable<string>) => {
    return text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => {
        const entities =
          this.parentEntities && this.parentEntities.map(e => e.templateCode);

        return {
          entities,
          term
        };
      }),
      map(({ term, entities }) =>
        term === '?'
          ? entities.filter(v => v === v)
          : term.length < 2
          ? []
          : entities
              .filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1)
              .slice(0, 10)
      )
    );
  };

  formatter = (result: string) => result.toUpperCase();
}

<div *ngIf="hasContractorToRegistrationMove">
  <!-- One or more data entities have been moved from a Contractor Info Form Activity to a Contractor Registration Info Form Activity   -->
  Moving a Data Entity from a Contractor Info Form Activity to a Contractor
  Registration Info Form Activity will have the following effect:
  <ul>
    <li>
      Values for this Data Entity will only be associated with each particular
      Registration of this Contractor Type
    </li>
    <li>
      Values for this Data Entity will NOT be associated with the Contractor and
      potentially carried over to different Contractor Types or jurisdictions
      that use the same Template Code.
    </li>
  </ul>
</div>
<div *ngIf="hasRegistrationToContractorMove">
  <!-- One or more data entities have been moved from a Contractor Registration Info Form Activity to a Contractor Info Form Activity  -->
  Moving a Data Entity from a Contractor Registration Info Form Activity to a
  Contractor Info Form Activity will have the following effect:
  <ul>
    <li>
      Values for this Data Entity will become associated with the Contractor.
    </li>
    <li>
      The Contractor's value for this Data Entity will carry over to different
      Contractor Types or jurisdictions if those Contractor Type workflows use
      the same Template Code.
    </li>
    <li>
      This will only take effect upon publishing this Contractor Type.
    </li>
  </ul>
</div>

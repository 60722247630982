import { DocumentDataEntity } from '.';

export class InspectionDocumentDataEntity extends DocumentDataEntity {
  constructor(options?: Partial<InspectionDocumentDataEntity>) {
    super();
    this.dataEntityTypeCode = 'inspector-document';
    this.dataEntityTypeDescription =
      'Use this entity type to accept inspector documents.';
    this.dataEntityTypeName = 'Inspector Document';

    if (options) {
      Object.assign(this, options);
    }
  }
}

import { IDateValidator } from '../../date-validators';
import { DayOfYearOptions } from '../day-of-year-date-validator';

export class DayOfYearValidator implements IDateValidator {
  validateMaxDate(date: Date, options: DayOfYearOptions): boolean {
    throw new DOMException();
  }
  validateMinDate(date: Date, options: DayOfYearOptions): boolean {
    throw new DOMException();
  }
  validateDate(date: Date, options: DayOfYearOptions): boolean {
    throw new DOMException();
  }
  calculateExample(date: Date, options: DayOfYearOptions): Date {
    return new Date(new Date().getFullYear(), options.month, options.day);
  }
}

<div class="row">
  <div class="col-sm">
    <ul
      ngbNav
      #tabs="ngbNav"
      class="nav-tabs"
      (navChange)="onSearchMapTabChange($event)"
      [destroyOnHide]="true"
    >
      <li ngbNavItem="searchTab" id="searchTab">
        <a ngbNavLink>Search</a>
        <ng-template ngbNavContent>
          <div>
            <div class="row search-row flex-fill ">
              <!-- Search -->
              <div class="col-sm">
                <div class="row" style="min-height:0">
                  <!-- Search -->
                  <div class="col-sm">
                    <form
                      [formGroup]="form"
                      (submit)="submitSearchParcelDataCriteria($event)"
                    >
                      <ng-container formGroupName="searchParcelDataCriteria">
                        <div class="form-group">
                          <label for="parcelIdSearch" class="bmd-label-static">
                            Parcel ID:
                          </label>
                          <input
                            id="parcelIdSearch"
                            type="text"
                            class="form-control"
                            formControlName="searchParcelId"
                            [(ngModel)]="
                              searchParcelDataCriteria.searchParcelId
                            "
                          />
                        </div>
                        <div class="form-group">
                          <label
                            for="parcelOwnerSearch"
                            class="bmd-label-static"
                          >
                            Property Owner:
                          </label>
                          <input
                            id="parcelOwnerSearch"
                            type="text"
                            class="form-control"
                            formControlName="searchParcelOwnerName"
                            [(ngModel)]="
                              searchParcelDataCriteria.searchParcelOwnerName
                            "
                          /><br />
                        </div>
                        <div class="form-group">
                          <label
                            for="propertyAddressSearch"
                            class="bmd-label-static"
                          >
                            Property Address:
                          </label>
                          <input
                            id="propertyAddressSearch"
                            type="text"
                            class="form-control"
                            formControlName="searchParcelAddress"
                            [(ngModel)]="
                              searchParcelDataCriteria.searchParcelAddress
                            "
                          />
                        </div>
                      </ng-container>
                      <button
                        type="submit"
                        class="btn btn-primary btn-raised"
                        [disabled]="
                          !(
                            searchParcelDataCriteria.searchParcelAddress ||
                            searchParcelDataCriteria.searchParcelOwnerName ||
                            searchParcelDataCriteria.searchParcelId
                          )
                        "
                        (click)="submitSearchParcelDataCriteria($event)"
                      >
                        Search
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </li>
      <li ngbNavItem="mapTab" id="mapTab" [disabled]="!activity.model.hasMap">
        <a ngbNavLink>Map</a>
        <ng-template ngbNavContent>
          <div class="tab-pane fade active show" id="mapTab">
            <app-esri-map
              #esriMap
              [mapServiceId]="activity.model.mapConfig.mapServiceId"
              [basemapName]="activity.model.mapConfig.basemapName"
              [initialExtent]="activity.model.mapConfig.initialExtent"
              [mapLayerOptions]="activity.model.mapConfig.layers"
              [showToc]="activity.model.mapConfig.showToc"
              (featuresIdentified)="onParcelsIdentified($event)"
              (featuresBeforeIdentifying)="onParcelsBeforeIdentifying()"
              (mapLoaded)="onMapLoaded()"
              [clearSelection]="false"
              useSketchLayer="false"
              [multiSelect]="true"
            >
            </app-esri-map>
          </div>
        </ng-template>
      </li>
      <li ngbNavItem="myParcelsTab" id="myParcelsTab" [disabled]="!userId">
        <a ngbNavLink>My Parcels</a>
        <ng-template ngbNavContent>
          <div style="border-bottom: 1px solid gray">
            <wm-my-application-parcels
              [userId]="userId"
              (parcelSelected)="myParcelSelected($event)"
              [allowMultiples]="true"
              [formControls]="this.activity.model.selectedParcels"
            ></wm-my-application-parcels>
          </div>
        </ng-template>
      </li>
    </ul>
    <div [ngbNavOutlet]="tabs" class="mt-2"></div>
  </div>
  <div class="col-sm-3" [hidden]="!showSelectedParcelCard">
    <!--selected parcels -->

    <div class="col">
      <div class="row">
        <div class="col">
          <div class="card">
            <div class="card-header">Selected Parcels</div>
            <div class="card-body overflow-auto" style="height:370px;">
              <ng-container>
                <ng-container
                  *ngFor="
                    let s of activity.model.selectedParcels;
                    let i = index
                  "
                >
                  <div class="row">
                    <div
                      class="col"
                      *ngIf="
                        activity.model.selectedParcels &&
                        activity.model.selectedParcels.length > 0
                      "
                    >
                      <div
                        class="chip"
                        [autoClose]="'outside'"
                        triggers="manual"
                        #p="ngbPopover"
                        (click)="removeFromSelected(s)"
                        #popovers="ngbPopover"
                        [ngbPopover]="popContent"
                        container="body"
                        placement="bottom"
                        [id]="'filter-' + s.parcelID"
                        style="margin-bottom:0.5rem; height:auto;"
                      >
                        <fa-icon
                          ngbTooltip="Remove this feature"
                          placement="left"
                          class="close"
                          [icon]="faTimes"
                          (click)="removeFromSelected(s)"
                        ></fa-icon>
                        <span class="font-weight-bold">{{ s.parcelID }}</span>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end selected parcels -->
  </div>
</div>

<div style="height:2rem"><app-loader *ngIf="loading"></app-loader></div>
<div class="row flex-shrink-0" [hidden]="!showSearchResults">
  <!-- Search Results -->
  <div class="col-sm">
    <table
      class="table"
      *ngIf="
        this.parcels &&
        this.parcels.controls &&
        this.parcels.controls.length > 0
      "
    >
      <thead>
        <tr>
          <th scope="col"></th>
          <th scope="col">Parcel ID</th>
          <th scope="col">Address</th>
          <th scope="col">City</th>
          <th scope="col">Owner Name</th>
          <th scope="col" class="algin-right">Acres</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody [formGroup]="form">
        <ng-container formArrayName="parcels">
          <ng-container *ngFor="let r of parcels.controls; let i = index">
            <tr [ngClass]="{ 'table-active': r.selected }" [formGroup]="r">
              <td>
                <div
                  class="custom-control custom-checkbox"
                  *ngIf="!hideResultsOnSelect"
                >
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    formControlName="selected"
                    [id]="r.value.parcelId"
                    [value]="r.value.selected"
                    (change)="selectOneResult(r, true)"
                  />
                  <label
                    class="custom-control-label"
                    [for]="r.value.parcelId"
                  ></label>
                </div>

                <a
                  *ngIf="hideResultsOnSelect"
                  href="javascript:void(0)"
                  (click)="selectOneResult(r, true, true)"
                >
                  <span *ngIf="isParcelSelected(r)">Selected</span>
                  <span *ngIf="!isParcelSelected(r)">Select</span>
                </a>
              </td>
              <td>{{ r.value.parcelId }}</td>
              <td>{{ r.value.parcel.address }}</td>
              <td>{{ r.value.parcel.city }}</td>
              <td>{{ r.value.parcel.ownerName }}</td>
              <td class="algin-right">
                {{ r.value.parcel.acres * 1 | number: '1.3-3' }}
              </td>

              <td class="algin-right">
                <button
                  type="button"
                  class="btn btn-raised btn-primary float-right notes-button"
                  (click)="parcelNotesModal.open()"
                  *ngIf="
                    !(
                      (r.value.parcel.internalNote == '0' &&
                        r.value.parcel.publicNote == '0' &&
                        !canManageParcelNotes == true) ||
                      (r.value.parcel.publicNote == '0' &&
                        !canViewInternalParcelNotes == true)
                    )
                  "
                >
                  <!--
                    *ngIf ="!(((r.value.parcel.internalNote=='0' && r.value.parcel.publicNote=='0') && !canManageParcelNotes==true)||((r.value.parcel.publicNote=='0') && !canViewInternalParcelNotes==true))"
                  Currently No Notes on the parcel, but I can add some.
                  Currently Either internal or public Notes on the parcel that can be edited
                  Currently Either internal or public Notes on the parcel that can't be edited
                  Currently There are either No Notes on the parcel OR no public notes and you don't have permission to view the internal notes.  --there is no visible button in this case because it won't pass the inIf for the button element.
                -->
                  <span
                    *ngIf="
                      r.value.parcel.internalNote == '0' &&
                      r.value.parcel.publicNote == '0' &&
                      canManageParcelNotes == true
                    "
                    >Add Note</span
                  >
                  <span
                    *ngIf="
                      !(
                        r.value.parcel.internalNote == '0' &&
                        r.value.parcel.publicNote == '0'
                      ) && canManageParcelNotes == true
                    "
                    >Modify Note</span
                  >
                  <span
                    *ngIf="
                      !(
                        r.value.parcel.internalNote == '0' &&
                        r.value.parcel.publicNote == '0'
                      ) && !canManageParcelNotes == true
                    "
                    >View Note</span
                  >
                  <!-- this should stay hidden -->
                  <span
                    *ngIf="
                      (r.value.parcel.internalNote == '0' &&
                        r.value.parcel.publicNote == '0' &&
                        !canManageParcelNotes == true) ||
                      (r.value.parcel.publicNote == '0' &&
                        !canViewInternalParcelNotes == true)
                    "
                    >No Note</span
                  >
                </button>
              </td>

              <wm-modal-confirm
                title="Parcel Notes"
                [small]="false"
                [showButtonText]="false"
                [showButton]="false"
                commandText="Save"
                (accepted)="
                  notesModal(publicNote, internalNote, r.value.parcel.parcelID)
                "
                (opened)="getParcelNote(r.value.parcel.parcelID)"
                [showCommandButton]="canManageParcelNotes"
                #parcelNotesModal
              >
                <label id="lblParcelID"
                  ><strong>{{ r.value.parcel.parcelID }}</strong></label
                ><br />
                <label id="lblPublicNote">Public Note</label>
                <textarea
                  class="form-control"
                  name="publicNote"
                  id="publicNote"
                  rows="20"
                  aria-describedby="lblPublicNote"
                  formControlName="publicNote"
                  [(ngModel)]="publicNote"
                  [readonly]="!canManageParcelNotes"
                >
                </textarea>
                <!-- TODO: Make sure I check permissions server side, so I don't even return value if I don't have permissions.  -->
                <br />
                <label
                  id="lblInternalNote"
                  *ngIf="canViewInternalParcelNotes == true"
                >
                  Internal Note
                </label>
                <textarea
                  class="form-control"
                  name="InternalNote"
                  id="InternalNote"
                  rows="20"
                  aria-describedby="lblInternalNote"
                  formControlName="internalNote"
                  [(ngModel)]="internalNote"
                  *ngIf="canViewInternalParcelNotes == true"
                  [readonly]="!canManageParcelNotes"
                >
                </textarea>
              </wm-modal-confirm>
            </tr>
          </ng-container>
        </ng-container>
      </tbody>
    </table>
  </div>
</div>

<p
  *ngIf="
    !loading &&
    this.parcels &&
    this.parcels.controls &&
    this.parcels.controls.length < 1
  "
>
  No results
</p>

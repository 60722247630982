<form [formGroup]="form" novalidate>
  <br />
  <wm-de-label [entity]="entity" [for]="entity.templateCode"></wm-de-label>
  <div class="sig-container" #sigContainer1>
    <div fxLayout="row">
      <wm-signature-field
        formControlName="signatureField1"
        (EndEvent)="finishedSigning($event)"
      >
      </wm-signature-field>
    </div>
    <div fxLayout="row">
      <button
        type="button"
        class="btn btn-raised btn-sm btn-danger"
        (click)="clear()"
        [disabled]="false"
      >
        Clear
      </button>
    </div>
  </div>
</form>

<div class="row">
  <div class="col">
    <h3>Roles</h3>
  </div>
  <div class="col">
    <button class="btn btn-raised btn-primary float-right" (click)="addRole()">
      Add Role
    </button>
  </div>
</div>

<wm-role-list [roles]="roles" (removed)="removeRole($event)"></wm-role-list>

<div class="px-4" *ngIf="requestDetails">
  <div class="row" style="padding-top: 65px">
    <div class="form-group">
      <div class="col">
        <b
          ><span>{{ requestDetails?.label }}</span></b
        ><span>{{ requestDetails?.description }}</span>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <form #f ngNoForm method="POST" [formGroup]="form">
        <div class="row">
          <div class="col-6">
            <div class="form-group">
              <label class="bmd-label-static">Credit Card Number</label>
              <div
                style="background-image: linear-gradient(to top, #066675 2px, rgba(6,102,117,0) 2px),linear-gradient(to top, rgba(0,0,0,0.26) 1px, rgba(0,0,0,0) 1px);background-size: 0 100%,100% 100% "
              >
                <iframe
                  id="tokenFrame"
                  name="tokenFrame"
                  style="height: 25px"
                  #frame
                  src=""
                  frameborder="0"
                  scrolling="no"
                ></iframe>
              </div>
              <input type="hidden" name="token" id="token" #token />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label for="cc_exp">Exp Date (MM/YY)</label>
              <input
                type="text"
                class="form-control"
                formControlName="expDate"
                style="width: 75px"
                name="cc_exp"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label for="cvv">CVV</label>
              <input
                type="text"
                class="form-control"
                style="width: 75px"
                formControlName="cvv"
                name="cvv"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="form-group">
              <button
                class="btn btn-raised btn-primary"
                [disabled]="processing || !form.valid"
                (click)="submit()"
              >
                <span *ngIf="!processing">Submit Payment</span>

                <span *ngIf="processing">
                  <span
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Processing...
                </span>
              </button>
              <button
                class="btn btn-raised btn-default ml-3"
                [disabled]="processing"
                (click)="cancel()"
              >
                <span *ngIf="!processing">Cancel</span>

                <span *ngIf="processing">
                  <span
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Processing...
                </span>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="col">
      <div class="form-group">
        <div class="card">
          <div class="card-body">
            <h6 class="card-title">Fee Summary</h6>
            <div *ngIf="!requestDetails?.lineItems"><i>Loading Fees</i></div>

            <ng-container *ngIf="requestDetails?.lineItems">
              <ul class="list-unstyled mt-3">
                <li *ngFor="let fee of requestDetails?.lineItems" class="my-2">
                  {{ fee.feeName }}
                  <span class="float-right">{{
                    fee.feeAmount | currency
                  }}</span>
                </li>
              </ul>

              <hr />
            </ng-container>

            <h6 class="mt-4">
              Total Due

              <span class="float-right font-weight-bold">
                {{ totalAmount | currency }}
              </span>
            </h6>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

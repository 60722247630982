import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { DataEntity } from '../../../../models/data-entities';
import { Workflow } from 'src/app/models';
import { Activity, ActivityModel } from 'src/app/models/activities';

@Component({
  selector: 'wm-data-entity-editor-base',
  templateUrl: './data-entity-editor-base.component.html',
  styleUrls: ['./data-entity-editor-base.component.css']
})
export class DataEntityEditorBaseComponent implements OnInit {
  constructor() {}

  @Input() form: UntypedFormGroup;

  @Input() dataEntity: DataEntity;

  @Output() dataEntityChange: EventEmitter<DataEntity> = new EventEmitter<
    DataEntity
  >();
  @Input() workflow: Workflow;
  @Input() activity: Activity<ActivityModel>;
  @Output() closeRequested: EventEmitter<any> = new EventEmitter<any>();
  /**
   * Trigger a preview rebuild from within the editor component.
   */
  @Output() buildPreview: EventEmitter<any> = new EventEmitter<any>();
  @Output() updated: EventEmitter<DataEntity> = new EventEmitter<DataEntity>();

  ngOnInit() {}
}

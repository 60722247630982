import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  AfterViewInit
} from '@angular/core';
import { Utilities, WorkflowService } from '../../../services';
import { WorkflowCheckpoint, Workflow } from '../../../models';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'wm-workflow-checkpoint-list',
  templateUrl: './workflow-checkpoint-list.component.html',
  styleUrls: ['./workflow-checkpoint-list.component.css']
})
export class WorkflowCheckpointListComponent implements OnInit {
  UtilitiesClass = Utilities;

  @Input() checkpoints: WorkflowCheckpoint[];
  @Input() workflow: Workflow;
  @Input() workflowId: string;
  @Output() onRestore: EventEmitter<string> = new EventEmitter<string>();

  restoreId: string;

  constructor(
    private _workflowSvc: WorkflowService,
    private _toastrSvc: ToastrService
  ) {}

  cleanupHistory() {
    const id = this.workflow ? this.workflow.id  : this.workflowId;
    this._workflowSvc
      .cleanupWorkflowHistory(id)
      .subscribe(res => {
        this._toastrSvc.success('Autosave History cleared');
      });
  }

  isRestoring(id: string): boolean {
    return this.restoreId === id;
  }

  clearRestore() {
    this.restoreId = null;
  }

  restoreVersion(id: string) {
    this.restoreId = id;
    this._workflowSvc.restoreWorkflowCheckpoint(id).subscribe(res => {
      this.restoreId = null;
      this.onRestore.emit(id);
    });
  }
  ngOnInit() {}
}

import { Input, Component, OnInit } from '@angular/core';
import { ActivityEditorBaseComponent } from '../../activity-editor-base/activity-editor-base.component';
import { ContractorRegistrationDecisionActivity } from 'src/app/models/activities/contractor-registration-decision-activity';

@Component({
  selector: 'wm-contractor-registration-decision-activity-editor',
  templateUrl:
    './contractor-registration-decision-activity-editor.component.html',
  styleUrls: [
    './contractor-registration-decision-activity-editor.component.css'
  ]
})
export class ContractorRegistrationDecisionActivityEditorComponent
  extends ActivityEditorBaseComponent
  implements OnInit {
  constructor() {
    super();
  }

  @Input() activity: ContractorRegistrationDecisionActivity;

  ngOnInit() {}
}

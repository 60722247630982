import * as Workflow from 'esri/widgets/Editor/Workflow';

export class Requirement {
  id: string;
  workflowId: string;
  workflow: Workflow;
  isEditing: boolean;
  label: string;
  templateCode: string;

  constructor(options?: Partial<Requirement>) {
    if (options) {
      Object.assign(this, options);
    }
  }
}

import {
  Component,
  OnInit,
  Input,
  ViewChild,
  Inject,
  forwardRef
} from '@angular/core';
import { ActivityView } from '../../../../../views/master-views/app.view/app.view.component';
import { SearchParcelDataActivity } from '../../../../../models/activities';
import { DataService } from '../../../../../services';
import { EsriMapComponent } from 'src/app/components/arcgis/esri-map/esri-map.component';
import { MapService } from 'src/app/models';

@Component({
  selector: 'wm-search-parcel-data-activity-view',
  templateUrl: './search-parcel-data-activity-view.component.html',
  styleUrls: ['./search-parcel-data-activity-view.component.css']
})
export class SearchParcelDataActivityViewComponent extends ActivityView
  implements OnInit {
  @ViewChild(EsriMapComponent, { static: false })
  private esriMap: EsriMapComponent;

  @Input()
  public activity: SearchParcelDataActivity;

  public results: { [key: string]: string }[];
  private _cachedMapService: MapService;

  constructor(
    @Inject(forwardRef(() => DataService)) private _dataSvc: DataService
  ) {
    super();
  }

  ngOnInit() {
    if (this.activity.model.selectedParcel) {
      this.results = [
        {
          parcelID: 'ParcelID',
          address: 'Address',
          city: 'City',
          ownerName: 'OwnerName',
          acres: 'Acres'
        },
        <any>this.activity.model.selectedParcel
      ];
    }
  }

  async getParcelLayerInfo(): Promise<MapService> {
    if (!this._cachedMapService) {
      this._cachedMapService = await this._dataSvc
        .getMapService(this.activity.model.mapConfig.mapServiceId)
        .toPromise();
    }
    return this._cachedMapService;
  }

  async onMapLoaded() {
    const ms = await this.getParcelLayerInfo();
    if (this.esriMap) {
      await this.esriMap.enableFeatureSelection(
        ms.parcelLayer,
        ms.parcelLayerKeyField,
        true
      );

      const parcelIds = this.results
        .filter((parcel, i) => i > 0) // skip the header row
        .map(parcel => parcel.parcelID);

      await this.esriMap.selectFeaturesOnMap(parcelIds, 'redlayer');
    }
  }
}

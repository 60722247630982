import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ComponentFactoryResolver } from '@angular/core';
import { DataEntityViewComponent } from './../../data-entity-view/data-entity-view.component';
import { Component, OnInit } from '@angular/core';
import { RichTextDataEntity } from 'src/app/models/data-entities/rich-text-data-entity';

@Component({
  selector: 'wm-rich-text-data-entity-view',
  templateUrl: './rich-text-data-entity-view.component.html',
  styleUrls: ['./rich-text-data-entity-view.component.css']
})
export class RichTextDataEntityViewComponent extends DataEntityViewComponent
  implements OnInit {
  public de: RichTextDataEntity;

  public html: SafeHtml;

  constructor(
    private sanitizer: DomSanitizer,
    componentFactoryResolver: ComponentFactoryResolver
  ) {
    super(componentFactoryResolver);
  }

  ngOnInit() {
    this.de = this.entity as RichTextDataEntity;

    // we sanitize the html server side so this is safe
    this.html = this.sanitizer.bypassSecurityTrustHtml(this.de.renderedHtml);
  }
}

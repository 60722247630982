import { DataEntity } from './data-entity';
import { Document } from '../document';
import { ValidationService } from '../../services';
import { Validators } from '@angular/forms';

export class DocumentDataEntity extends DataEntity {
  validDocumentTypes: string;
  documents: Document[];

  constructor(options?: Partial<DocumentDataEntity>) {
    super();
    this.dataEntityTypeCode = 'document';
    this.dataEntityTypeDescription =
      'Use this entity type to accept documents.';
    this.dataEntityTypeName = 'Document';

    if (options) {
      Object.assign(this, options);
    }
  }

  getValidators(): any[] {
    const validators: any[] = [];

    if (this.isRequired) {
      validators.push(ValidationService.requiredFileValidator);
    } else {
      validators.push(Validators.nullValidator);
    }

    return validators;
  }
}

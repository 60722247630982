<h3>Applications</h3>

<wm-workflow-application-list
  [workflowId]="null"
  [filterCriteria]="filterCriteria"
  [showAll]="false"
  (filtersChanged)="filtersChange()"
  id="application-list-view"
>
</wm-workflow-application-list>

<p *ngIf="!results">
  No parcel was selected.
</p>

<div *ngIf="results">
  <div class="tab-pane fade active show" *ngIf="activity.model.hasMap">
    <app-esri-map
      #mapComp
      [mapServiceId]="activity.model.mapConfig.mapServiceId"
      [basemapName]="activity.model.mapConfig.basemapName"
      [initialExtent]="activity.model.mapConfig.initialExtent"
      [mapLayerOptions]="activity.model.mapConfig.layers"
      [showToc]="false"
      (mapLoaded)="onMapLoaded()"
      useSketchLayer="false"
      [multiSelect]="true"
    ></app-esri-map>
  </div>
  <div *ngIf="results && results.length > 0" style="background-color: white">
    <table class="table">
      <tr
        *ngFor="let parcel of results; let isFirstRow = first"
        [ngClass]="{ RowHeaderCSS: isFirstRow }"
      >
        <td>{{ parcel.parcelID }}</td>
        <td>{{ parcel.address }}</td>
        <td>{{ parcel.city }}</td>
        <td>{{ parcel.ownerName }}</td>
        <td align="right">
          {{ isFirstRow ? parcel.acres : (parcel.acres | number: '1.3-3') }}
        </td>
      </tr>
    </table>
  </div>
</div>

import {
  Component,
  OnInit,
  ComponentFactoryResolver,
  Input
} from '@angular/core';
import { DataEntityInputComponent } from '../../data-entity-input/data-entity-input.component';
import { FreeFormTextDataEntity } from '../../../../../models/data-entities';

@Component({
  selector: 'wm-free-form-text-data-entity-input',
  templateUrl: './free-form-text-data-entity-input.component.html',
  styleUrls: ['./free-form-text-data-entity-input.component.css']
})
export class FreeFormTextDataEntityInputComponent
  extends DataEntityInputComponent
  implements OnInit {
  @Input() entity: FreeFormTextDataEntity;
  labelClass = 'bmd-label-floating';
  placeholder = '';

  constructor(componentFactory: ComponentFactoryResolver) {
    super(componentFactory);
  }

  ngOnInit() {
    if (this.form && this.form.controls[this.entity.templateCode]) {
      if (
        !this.entity.externalDataSource ||
        !this.entity.externalDataSource.sourceConfig ||
        (this.entity.canEditExternalValue &&
          this.form.controls[this.entity.templateCode])
      ) {
        this.form.controls[this.entity.templateCode].enable();
      } else {
        this.form.controls[this.entity.templateCode].disable();
      }
    }

    if (this.entity.getRestrictionMask()) {
      this.labelClass = 'bmd-label-static';
    }
    // set the mask anyway cause it might be an empty sting which is falsey
    this.placeholder = this.entity.getRestrictionMask();
  }
}

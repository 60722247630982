import { Component, OnInit, EventEmitter, ViewChild } from '@angular/core';
import { ActivityView } from '../../../../../views/master-views/app.view/app.view.component';
import { Activity, ActivityModel } from '../../../../../models/activities';
import { FormGroup } from '@angular/forms';
import { WorkflowContextService } from '../../../../../services';
import { FormActivityInputViewComponent } from '../../form-activity-editor/form-activity-input/form-activity-input-view/form-activity-input-view.component';

@Component({
  selector: 'wm-contractor-registration-info-form-activity-input',
  templateUrl:
    './contractor-registration-info-form-activity-input.component.html',
  styleUrls: [
    './contractor-registration-info-form-activity-input.component.css'
  ]
})
export class ContractorRegistrationInfoFormActivityInputComponent
  extends ActivityView
  implements OnInit {
  
  @ViewChild('formActivityInputView', { static: false })
  formActivityInputViewCtl: FormActivityInputViewComponent;

  constructor() {
    super();
  }

  ngOnInit() {}

  async persistChildComponent() {
    return this.formActivityInputViewCtl.persistChildComponent();
  }
}

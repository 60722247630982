import {
  Component,
  OnInit,
  Input,
  Inject,
  forwardRef,
  ChangeDetectorRef
} from '@angular/core';
import { ActivityEditorBaseComponent } from '../../activity-editor-base/activity-editor-base.component';
import { WaitActivity } from '../../../../../models/activities/wait-activity';
import {
  WaitCriteria,
  Activity,
  ActivityModel
} from '../../../../../models/activities';
import {
  WorkflowService,
  WorkflowContextService
} from '../../../../../services';
import { Validators, FormBuilder } from '@angular/forms';

@Component({
  selector: 'wm-wait-activity-editor',
  templateUrl: './wait-activity-editor.component.html',
  styleUrls: ['./wait-activity-editor.component.css']
})
export class WaitActivityEditorComponent extends ActivityEditorBaseComponent
  implements OnInit {

  constructor() {
    super();
  }

  ngOnInit() {

  }

}

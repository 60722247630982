<wm-upload
  [validDocumentTypes]="'csv'"
  [pathSegments]="[
    this.clientId,
    'dataImport',
    'previousSystem',
    this.newUploadKey
  ]"
  alignment="left"
  (fileUploaded)="fileUploaded($event)"
  [documentService]="this.uploadService"
  [key]="newUploadKey"
  [isPreview]="isPreview"
  [showSingleButton]="true"
  category="Application"
  (uploadComplete)="newDocumentsUploaded($event)"
  #uploadComponent
></wm-upload>
<button
  type="button"
  class="btn btn-raised btn-primary mr-1"
  (click)="checkUpload($event)"
>
  Upload Files
</button>

<div [formGroup]="profileForm">
  <div>
    <label>
      <input type="checkbox" formControlName="clearRecords" />
      Import should clear all others and start with a clean slate.
    </label>
  </div>

  <div formGroupName="type">
    <div formArrayName="documents">
      <div
        [id]="i"
        *ngFor="
          let item of profileForm.get('type.documents')['controls'];
          let i = index
        "
      >
        <div [formGroupName]="i">
          <input formControlName="label" placeholder="label" />
        </div>
        {{ i }}
      </div>
    </div>
  </div>
</div>
<wm-modal-confirm
  title="Clear Previously Imported Records"
  [showButton]="false"
  commandText="Yes I want a fresh import."
  moduleId="clear"
  (accepted)="acceptClear()"
  (canceled)="canceledClear()"
  #clearModal
  ><div>
    Are you sure you want to wipe any previously imported system permits and
    start with a clean slate?
  </div>
</wm-modal-confirm>

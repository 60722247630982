<form [formGroup]="form" *ngIf="form && richDe">
  <div ngbDropdown class="d-inline-block" *ngIf="body">
    <button
      class="btn btn-outline-primary"
      id="dropdownBasic1"
      ngbDropdownToggle
      *ngIf="showInsertDataEntities"
    >
      Insert Data
    </button>
    <div
      ngbDropdownMenu
      aria-labelledby="dropdownBasic1"
      *ngIf="showInsertDataEntities"
    >
      <button
        ngbDropdownItem
        *ngFor="let de of entities"
        style="cursor: pointer"
        (click)="insertBody(de)"
      >
        {{ de.label }}
      </button>
    </div>
  </div>
  <div class="form-group">
    <label class="bmd-label-static" [for]="dataEntity.templateCode">Body</label>
    <ckeditor
      #body
      [editor]="Editor"
      [config]="editorConfig"
      [attr.name]="dataEntity.templateCode"
      [formControlName]="dataEntity.templateCode"
      [(ngModel)]="richDe.html"
      (ready)="editorReady($event)"
    ></ckeditor>
  </div>
</form>

import { ScreenTemplateActivityEditorComponent } from './../activities/screen-template-activity-editor/screen-template-activity-editor.component';
import { DataEntity } from 'src/app/models/data-entities';
import { Subscriber, Subscription } from 'rxjs';
import { WorkflowValidationService } from './../../../services/workflow-validation.service';
import { ValidationResponse } from 'src/app/models/validation';
import { WorkflowActivityPreviewModalComponent } from './../workflow-activity-preview-modal/workflow-activity-preview-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EventEmitter, OnDestroy } from '@angular/core';
import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ComponentFactoryResolver,
  ComponentRef,
  Output
} from '@angular/core';
import { ActivityEditorHostDirective } from './activity-editor-host.directive';
import { ActivityEditorComponent } from '../activities/activity-editor/activity-editor.component';
import {
  WorkflowService,
  WorkflowContextService,
  ActivityFactory
} from '../../../services';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Workflow } from 'src/app/models';
import { Activity, ActivityModel } from 'src/app/models/activities';
import { SavingChange } from 'src/app/models/saving-change';

@Component({
  selector: 'wm-workflow-activity-sidebar',
  templateUrl: './workflow-activity-sidebar.component.html',
  styleUrls: ['./workflow-activity-sidebar.component.css']
})
export class WorkflowActivitySidebarComponent implements OnInit, OnDestroy {
  @Input() size = 500;

  /**
   * Emits an event whenever the activity is saved
   */
  @Output()
  saved: EventEmitter<Activity<ActivityModel>> = new EventEmitter();
  /**
   * Emits an event whenever the activity is closed
   */
  @Output()
  closed: EventEmitter<string> = new EventEmitter();
  /**
   * Emits an event whenever the activity is opened
   */
  @Output()
  opened: EventEmitter<string> = new EventEmitter();

  @ViewChild(ActivityEditorHostDirective, { static: true })
  activityEditorHost: ActivityEditorHostDirective;
  componentRef: ComponentRef<ActivityEditorComponent>;
  @Input() workflow: Workflow;

  form: UntypedFormGroup;

  activity: Activity<ActivityModel>;
  activityType: string;
  activityId: string;
  activityName: string;
  validationErrors: ValidationResponse;
  deValidationErrors: ValidationResponse[] = [];

  openStyle = { 'right.px': 0 };
  closedStyle = { 'right.px': -10 - this.size };
  sizeStyle = {
    'width.px': this.size
  };

  currentStyle: any;

  isOpen = false;

  saving = false;
  savingChanges: SavingChange[];

  validatingListener: Subscription;
  validatedListener: Subscription;
  isValidating = false;
  loaded: boolean = false;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    public _workflowSvc: WorkflowService,
    private _context: WorkflowContextService,
    private toastr: ToastrService,
    private modalService: NgbModal,
    public validationSvc: WorkflowValidationService,
    fb: UntypedFormBuilder
  ) {
    this.setStyle(this.closedStyle);
    this.form = fb.group({});

    this.opened.subscribe(() => {
      this.isOpen = true;
    });
    this.closed.subscribe(() => {
      this.isOpen = false;
    });
  }
  ngOnDestroy(): void {
    if (this.validatingListener) {
      this.validatingListener.unsubscribe();
    }
    if (this.validatedListener) {
      this.validatedListener.unsubscribe();
    }
  }

  ngOnInit() {
    this.validatingListener = this.validationSvc.validating.subscribe(
      result => {
        this.isValidating = true;
      }
    );
    this.validatedListener = this.validationSvc.validated.subscribe(result => {
      this.getValidation(this.activity);
    });
  }

  setStyle(style) {
    this.currentStyle = { ...style, ...this.sizeStyle };
  }

  async getValidation(
    activity: Activity<ActivityModel>,
    force?: boolean,
    fullTree?: boolean
  ) {
    this.deValidationErrors = [];
    if (activity) {
      if (force) {
        // clear validations for each entity
        const dataEntities = activity.model.getEntities();
        if (dataEntities && dataEntities.length > 0) {
          for (let idx = 0; idx < dataEntities.length; idx++) {
            const entity: DataEntity = dataEntities[idx];
            this.validationSvc.clearValidationCache(entity.templateCode);
          }
        }
      }
      const validationInfo = await this.validationSvc.validateActivity(
        this._context.workflow.id,
        activity.id,
        force,
        fullTree
      );
      this.isValidating = false;
      this.validationErrors = validationInfo.find(
        e => e.id === activity.id && e.messages.length > 0
      );

      // create activity object if the variable is just an object and not the class
      if (activity) {
        if (!activity.model.getEntities) {
          activity = ActivityFactory.createActivity(activity, activity, true);
        }

        const dataEntities = activity.model.getEntities();
        if (dataEntities && dataEntities.length > 0) {
          this.deValidationErrors = validationInfo.filter(error => {
            const isEntity = dataEntities
              ? dataEntities.find(
                  de =>
                    de.templateCode === error.id &&
                    error.activityId === this.activityId
                )
              : null;

            if (isEntity && error.messages.length > 0) {
              return true;
            }
            return false;
          });
        }
      }
    }
  }

  open(params?) {
    this.validationErrors = null;
    this.deValidationErrors = [];
    this.savingChanges = params.changes;

    if (params && params.activityId && params.activityType) {
      this.activityId = params.activityId;
      this.activityType = params.activityType;

      this.activity = this._workflowSvc.getWorkflowActivity(
        this._context.workflow,
        this.activityId
      );
      this.activityName = this.activity && this.activity.name;

      if (!this.activity) {
        this.toastr.warning(`This activity doesn't seem to exist...`);
        return;
      }

      this.getValidation(this.activity);

      this.loadComponent();
      this.loaded = true;
      this.opened.emit(params.activityId);
    }

    this.setStyle(this.openStyle);
  }

  // tslint:disable-next-line: member-ordering
  saves: any[] = [];

  save(activity: Activity<ActivityModel>, buttonClicked: boolean = false) {
    if (this.componentRef.instance.save()) {
      if (activity && this.activity.id === activity.id) {
        this.activity = activity;
      } else if (!activity) {
        // if activity is null use this.activity as activity
        activity = this.activity;
      }
      this._workflowSvc.updateWorkflowActivity(
        this._context.workflow,
        activity
      );
      if (buttonClicked) {
        this.saved.emit(activity);
      } else {
        // clear the validation cache for the activity
        this.saving = true;
        const saveActivity$ = this._workflowSvc.saveWorkflowChanges(
          this.workflow.id,
          [
            {
              changeType: 'activity',
              typeOfChange: 'update',
              changedActivity: activity
            }
          ],
          this.workflow.designStatus
        );

        const saveFinished = () => {
          this.saving = false;
          this.saves.splice(0, 1);

          if (this.saves.length > 0) {
            this.saves[0].subscribe(saveFinished);
          }

          // reload entities before me so we have any new de's that were added.
          this._workflowSvc.clearDataEntityBeforeMeCache(this.activity.id);
          this._workflowSvc.getDataEntitiesBeforeMe(
            this.workflow,
            this.activity,
            null,
            [WorkflowService.DATA_ENTITIES.RichText.code]
          );
          if (activity.id === this.activity.id) {
            this.getValidation(activity, true, true);
            this.loadComponent();
          }
        };

        this.saves.push(saveActivity$);

        if (this.saves.length === 1) {
          saveActivity$.subscribe(saveFinished);
        }
      }
    }
  }

  saveClicked() {
    this.save(this.activity, true);
    this.close();
  }

  close() {
    this.setStyle(this.closedStyle);
    this.activityEditorHost.viewContainerRef.clear();
    // reset form
    this.form = new UntypedFormGroup({});

    this.closed.emit(this.activityId);
  }

  toggle() {
    this.isOpen = !this.isOpen;
    if (this.isOpen) {
      this.open();
    } else {
      this.close();
    }
  }

  preview() {
    const modalRef = this.modalService.open(
      WorkflowActivityPreviewModalComponent,
      { size: 'lg' }
    );
    modalRef.componentInstance.activityId = this.activityId;
    modalRef.componentInstance.workflow = this._context.workflow;
  }

  loadComponent() {
    // clear the cache for this activity in case things have changed
    this._workflowSvc.clearDataEntityBeforeMeCache(this.activity.id);
    if (this.workflow && this.activity) {
      this._workflowSvc
        .getDataEntitiesBeforeMe(
          this.workflow,
          this.activity,
          null,
          [WorkflowService.DATA_ENTITIES.RichText.code],
          true
        )
        .subscribe();
    }

    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
      ActivityEditorComponent
    );

    this.activity.model.responsibleRoleId = this.activity.model.responsibleRoleId.toUpperCase();
    const viewContainerRef = this.activityEditorHost.viewContainerRef;
    if (this.componentRef) {
      this.componentRef.destroy();
    }
    viewContainerRef.clear();

    this.componentRef = viewContainerRef.createComponent(componentFactory);
    (<ActivityEditorComponent>(
      this.componentRef.instance
    )).activityId = this.activityId;
    (<ActivityEditorComponent>(
      this.componentRef.instance
    )).activityType = this.activityType;
    (<ActivityEditorComponent>(
      this.componentRef.instance
    )).activity = this.activity;
    (<ActivityEditorComponent>this.componentRef.instance).form = this.form;
    (<ActivityEditorComponent>this.componentRef.instance).clientId = (
      this.workflow || this._context.workflow
    ).clientID;
    (<ActivityEditorComponent>this.componentRef.instance).saved.subscribe(e => {
      this.save(e);
    });
    (<ActivityEditorComponent>(
      this.componentRef.instance
    )).needsValidated.subscribe(e => {
      this.getValidation(this.activity, true);
    });
    (<ActivityEditorComponent>(
      this.componentRef.instance
    )).savingChanges = this.savingChanges;
  }
}

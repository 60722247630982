import { CustomReport } from './../../../models/custom-report';
import { WorkflowContextService } from './../../../services/workflow-context.service';
import { ReportService } from './../../../services/report.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'wm-custom-report-list',
  templateUrl: './custom-report-list.component.html',
  styleUrls: ['./custom-report-list.component.css']
})
export class CustomReportListComponent implements OnInit {
  constructor(
    private _reportService: ReportService,
    public context: WorkflowContextService
  ) {}
  reports: CustomReport[];

  ngOnInit() {
    this._reportService
      .getCustomReports(this.context.client.id)
      .subscribe(reports => {
        this.reports = reports;
      });
  }
}

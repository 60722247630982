<div class="row">
  <div class="col">
    <div class="form-group">
      <label class="bmd-label-floating" for="selectedUser">{{ label }}</label>
      <input
        type="text"
        class="form-control"
        [formControl]="searchText"
        [(ngModel)]="selectedUser"
        name="selectedUser"
        [ngbTypeahead]="search"
        [resultTemplate]="rt"
        [inputFormatter]="formatter"
        #typeahead
        (selectItem)="associateUser($event, $event.item, typeahead)"
        #instance="ngbTypeahead"
      />
    </div>
  </div>
</div>

<ng-template #rt let-r="result" let-t="term">
  {{ r.userName }} ({{ r.name }})
</ng-template>

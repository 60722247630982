<button
  class="btn btn-raised btn-primary"
  type="button"
  (click)="launchChild()"
>
  <span *ngIf="callActivity.status !== ActivityStatus.Completed">Launch</span>
  <span *ngIf="callActivity.status === ActivityStatus.Completed">
    Open Summary
  </span>
</button>

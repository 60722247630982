import { WorkflowService } from './../../../../../../services/workflow.service';
import { Validators, UntypedFormControl } from '@angular/forms';
import {
  DestinationConfiguration,
  SFTPConfiguration,
  TransportEditor
} from './../../../../../../models/activities/export-data-activity';
import { Component, OnInit, forwardRef, Inject, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'wm-sftp-configuration-editor',
  templateUrl: './sftp-configuration-editor.component.html',
  styleUrls: ['./sftp-configuration-editor.component.css']
})
export class SftpConfigurationEditorComponent extends TransportEditor
  implements OnInit {
  configuration: SFTPConfiguration;

  @Output() updated: EventEmitter<DestinationConfiguration> = new EventEmitter<DestinationConfiguration>();

  constructor(
    @Inject(forwardRef(() => WorkflowService))
    private _workflowSvc: WorkflowService
  ) {
    super();
  }

  ngOnInit() {
    if (!this.configuration) {
      this.configuration = new SFTPConfiguration();
    }

    if (this.form) {
      this.form.addControl(
        'connection',
        new UntypedFormControl({ value: '', validator: Validators.required })
      );
    }

    if ((this.configuration.availableConnections || []).length == 0) {
      this._workflowSvc.loadDestinationEditor('SecureFTP').subscribe(result => {
        let sftpConfig = result as SFTPConfiguration;
        if(!sftpConfig) {
          sftpConfig = new SFTPConfiguration();
        }

        if(!sftpConfig.connectionId) {
          sftpConfig.connectionId = '';
        }

        this.configuration = sftpConfig;
        this.updated.emit(this.configuration);
      });
    }
  }
}

import { WorkflowContextService } from './../../../services/workflow-context.service';
import { PaymentRequest } from './../../../models/payment-request';
import { WorkflowService } from './../../../services/workflow.service';
import { Observable } from 'rxjs';
import {
  Component,
  OnInit,
  ViewChild,
  AfterViewInit,
  ElementRef
} from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SystemService } from 'src/app/services';
import { CardpointePaymentRequestGet } from 'src/app/models/payment-providers/cardpointe/cardpointe-payment-settings';

@Component({
  selector: 'wm-cardpointe-payment-form',
  templateUrl: './cardpointe-payment-form.component.html',
  styleUrls: ['./cardpointe-payment-form.component.css']
})
export class CardpointePaymentFormComponent implements OnInit, AfterViewInit {
  frameEl: HTMLIFrameElement;
  tokenEL: HTMLInputElement;

  @ViewChild('f', { static: false }) f: HTMLFormElement;
  requestId: any;
  @ViewChild('frame', { static: false }) frame: ElementRef<HTMLIFrameElement>;
  processing = false;

  request: PaymentRequest;

  requestDetails: {
    label: string;
    description: string;
    lineItems: { feeName: string; feeAmount: number }[];
  };
  totalAmount: number;
  formPostURL: string;
  form: UntypedFormGroup;
  // frameURL = 'https://fts.cardconnect.com:8443/itoke/ajax-tokenizer.html'; // 'itoke/ajax-tokenizer.html';
  token: string;

  requestLoaded$: Observable<any>;

  constructor(
    private route: ActivatedRoute,
    private _router: Router,
    private _systemSvc: SystemService,
    private _workflowSvc: WorkflowService
  ) {}


  ngAfterViewInit(): void {
    this.requestLoaded$ = this._systemSvc.getPaymentRequest(this.requestId);

    this.requestLoaded$.subscribe(request => {
      if (request) {
        const cardpointeProviderRequest: CardpointePaymentRequestGet = request.providerRequest as CardpointePaymentRequestGet;
        if (cardpointeProviderRequest && cardpointeProviderRequest.details) {
          this.formPostURL = cardpointeProviderRequest.details.formAction;

          this.requestDetails = {
            label: cardpointeProviderRequest.details.paymentLabel,
            description: cardpointeProviderRequest.details.paymentDescription,
            lineItems: (request.requestData.lineItems || []).map(li => {
              return {
                feeName: li.feeName,
                feeAmount: li.amount
              };
            })
          };

          this.totalAmount = 0;
          this.requestDetails.lineItems.forEach((fee, idx) => {
            this.totalAmount = this.totalAmount + fee.feeAmount || 0;
          });
        }

        let frameURL: string;

        this.request = request;

        if (cardpointeProviderRequest && cardpointeProviderRequest.details) {
          this.formPostURL = cardpointeProviderRequest.details.formAction;
          frameURL = cardpointeProviderRequest.details.tokenURL;
        }

        // this is here to allow the UI to process so that the frame ViewChild is available
        setTimeout(() => {
          const frameEl: HTMLIFrameElement = document.getElementById(
            'tokenFrame'
          ) as HTMLIFrameElement;

          if (this.frame) {
            const frameSrcWithStyle = `${frameURL}?formatinput=true&css=${escape(
              '.error{color: red;} body { margin: 0;} input{color:#495057;display:block;width:250px;height:20px;font-size:1rem;border: 0; background: no-repeat center bottom,center calc(100% - 1px);padding-bottom: 5px padding-top: 5px;border:0;outline:0}'
            )}`;
            this.frame.nativeElement.src = frameSrcWithStyle;
          }
        }, 100);

        let lTokenEl: HTMLInputElement = this.tokenEL;
        const frm: UntypedFormGroup = this.form;

        window.addEventListener(
          'message',
          function(event) {
            if (event.data && event.data) {
              try {
                const token = JSON.parse(event.data);
                lTokenEl = document.getElementById('token') as HTMLInputElement;

                if (lTokenEl) {
                  lTokenEl.value = token.message;
                  frm.controls['token'].setValue(token.message);
                }
              } catch (ex) {
                // event.data isn't always string data
              }
            }
          },
          false
        );
      }
    });
  }

  navigateToApp() {
    this._router.navigate([
      '/application/workflow-application',
      this.request.workflowApplicationId
    ]);
  }
  cancel() {
    this.navigateToApp();
  }

  submit() {
    this.processing = true;

    this._workflowSvc
      .confirmWorkflowPaymentRequest(this.request.id, {
        cvv: this.form.controls['cvv'].value,
        expiry: this.form.controls['expDate'].value,
        token: this.form.controls['token'].value
      })
      .subscribe(
        confirmation => {
          this.processing = false;
          this.navigateToApp();
        },
        err => {
          this.processing = false;
          throw err;
        }
      );
  }

  ngOnInit() {
    this.form = new UntypedFormGroup({
      expDate: new UntypedFormControl('', Validators.required),
      cvv: new UntypedFormControl('', Validators.required),
      token: new UntypedFormControl('', Validators.required)
    });

    this.route.params.subscribe(parms => {
      this.requestId = parms['paymentRequestId'];
    });
  }
}

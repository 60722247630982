import { Validators, UntypedFormControl } from '@angular/forms';
import { CsvConfiguration, OutputEditor } from './../../../../../../models/activities/export-data-activity';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'wm-csv-configuration-editor',
  templateUrl: './csv-configuration-editor.component.html',
  styleUrls: ['./csv-configuration-editor.component.css']
})
export class CsvConfigurationEditorComponent extends OutputEditor implements OnInit {

  configuration: CsvConfiguration;

  constructor() {
    super();
  }

  ngOnInit() {
    if(!this.configuration) {
      this.configuration = new CsvConfiguration();
    }

    if(this.form) {
      this.form.addControl('includeHeaderRow', new UntypedFormControl({ value: '', validator: Validators.nullValidator }));
    }
  }

}

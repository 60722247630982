import { Template } from './template';
import { ContractorRequirementType } from './contractor-requirement-type';
import { DataEntity, FeeDataEntity } from './data-entities';
import {
  ActivityModel,
  Activity,
  EmailActivity,
  PrintTemplateActivity,
  FormActivity
} from './activities';
import { Workflow, ContractorTypeRequirement } from '.';
import { WorkflowService } from '../services';
import { Inject, forwardRef } from '@angular/core';
import { Client } from './client';
import { ContractorTypeTag } from './contractor-type-tag';
import { ConditionTarget } from '../components/system/condition-builder/condition-builder.model';

export class ContractorType {
  id: string;
  clientID: string;
  client: Client;
  workflowVersionId: string;
  name: string;
  description: string;
  //termLength: number;
  registrationFee: string;
  renewalFee: string;
  lateFee: string;
  lateFeeStartDays: number;
  isEnabled: boolean;
  registerWorkflowId: string;
  renewWorkflowId: string;
  registerWorkflow: Workflow;
  renewWorkflow: Workflow;
  requirements: ContractorTypeRequirement[];
  isPublished: boolean;
  visibleToPublic: boolean;
  contractorTypeTags: ContractorTypeTag[];
  expirationFormulaCriteria: ConditionTarget[];

  registerFeeActivities: { [type: string]: FormActivity };
  renewFeeActivities: { [type: string]: FormActivity };

  registerFormActivities: { [type: string]: FormActivity };
  renewFormActivities: { [type: string]: FormActivity };

  registerPrintTemplateActivities: {
    [id: string]: PrintTemplateActivity;
  };
  renewPrintTemplateActivities: {
    [id: string]: PrintTemplateActivity;
  };

  registerEmailActivities: { [type: string]: EmailActivity };
  renewEmailActivities: { [type: string]: EmailActivity };

  registerActivities: { [type: string]: Activity<ActivityModel> };
  renewActivities: { [type: string]: Activity<ActivityModel> };

  public constructor(options?: Partial<ContractorType>) {
    if (options) {
      Object.assign(this, options);
    }
  }
}

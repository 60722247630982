import { ContractorType } from './contractor-type';
import { Contractor } from './contractor';
import { PaymentRequest } from './payment-request';
import { ContractorRegistrationDocument } from './contractor-registration-document';
import { RegistrationStatus } from './registration';
import { ContractorTypeRequirement } from './contractor-type-requirement';
import { WorkflowApplication } from './workflow-application';

export class ContractorRegistrationNote {
  createdBy: string;
  modifiedBy: string;
  createdOn: Date;
  notes: string;
  registrationID: string;
}

export class ContractorRegistration {
  id: string;
  type: ContractorType;
  contractorTypeId?: string;
  contractor?: Contractor;
  contractorID: string;
  registeredOnString: Date;
  effectiveOnString?: Date;
  expiresOnString?: Date;
  approvedOnString?: Date;
  rejectedOnString?: Date;
  status: RegistrationStatus;
  paymentRequestId?: string;
  notes?: ContractorRegistrationNote[];
  documents?: ContractorRegistrationDocument[];
  registerApplicationId: string;
  registerApplication: WorkflowApplication;
  createdOn: Date;
  createdBy: string;
  modifiedOn: Date;
  modifiedBy: string;
  paymentRequest?: PaymentRequest;
  customFields: any[];
  certificatePath: string;
  certificateDocument: ContractorRegistrationDocument;
}
export class PagedContractorRegistrationVM {
  pageCount: number;
  recordCount: number;
  unpermissibleCount: number;
  contractorRegistrations: ContractorRegistration[];
  requirements?: ContractorTypeRequirement[];
}

export class UnpagedContractorRegistrationVM {
  contractorRegistrations: ContractorRegistration[];
}

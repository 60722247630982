import { SystemDataEntity } from './../../../../../models/data-entities/system-data-entity';
import { FreeFormTextDataEntity } from './../../../../../models/data-entities/free-form-text-data-entity';
import { SignatureDataEntity } from './../../../../../models/data-entities/signature-data-entity';
import { MapsketchDataEntity } from './../../../../../models/data-entities/mapsketch-data-entity';
import { DocumentDataEntity } from './../../../../../models/data-entities/document-data-entity';
import {
  Component,
  Input,
  ViewChild,
  ElementRef,
  OnInit,
  Inject,
  forwardRef,
  ChangeDetectorRef,
  AfterViewInit,
  OnDestroy,
  Renderer2
} from '@angular/core';
import {
  EmailActivity,
  EmailActivityAttachment
} from '../../../../../models/activities';

import {
  TemplateService,
  WorkflowContextService,
  WorkflowService,
  Utilities,
  ValidationService,
  SecurityService
} from '../../../../../services';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
  ValidatorFn,
  ValidationErrors,
  AbstractControl
} from '@angular/forms';
import { ActivityEditorBaseComponent } from '../../activity-editor-base/activity-editor-base.component';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ChangeEvent } from '@ckeditor/ckeditor5-angular';
import { DataEntity, ListDataEntity } from 'src/app/models/data-entities';
import { Role } from 'src/app/models';
import { DataEntityAutocompleteComponent } from '../../../data-entity-autocomplete/data-entity-autocomplete.component';

@Component({
  selector: 'wm-email-activity-editor',
  templateUrl: './email-activity-editor.component.html',
  styleUrls: ['./email-activity-editor.component.css']
})
export class EmailActivityEditorComponent extends ActivityEditorBaseComponent
  implements OnInit, OnDestroy {
  @Input() activity: EmailActivity;
  @ViewChild('emailBody', { static: false }) emailBody: any;
  @ViewChild('emailSubject', { static: false }) emailSubject: ElementRef;
  @ViewChild('emailRecipient', { static: false })
  emailRecipient: DataEntityAutocompleteComponent;

  emailRegEx = /@\((.*)\)/i;

  entities: DataEntity[];
  attachmentEntities: EmailActivityAttachment[];
  emailDataEntities: DataEntity[];
  newEmailAddress: string;
  newEmailType: string;
  emailBodyField: AbstractControl;
  formGroupId: string;
  availableRoles: Role[];
  selectedRole: string;
  recipientDETypes: string[];

  @Input()
  loadFull = false;

  form: UntypedFormGroup;
  activityForm: UntypedFormGroup;

  toEmailForm: UntypedFormGroup;
  attachForm: UntypedFormGroup;
  roleRecipientForm: UntypedFormGroup;
  isGlobal = false;

  public Editor = ClassicEditor;

  editorConfig = {
    toolbar: [
      'heading',
      '|',
      'bold',
      'italic',
      'link',
      '|',
      'bulletedList',
      'numberedList',
      '|',
      'undo',
      'redo'
      // 'image'
    ]
  };

  refreshing = false;

  constructor(
    @Inject(forwardRef(() => TemplateService))
    public templateSvc: TemplateService,
    private _renderer: Renderer2,
    @Inject(forwardRef(() => WorkflowService))
    public workflowSvc: WorkflowService,
    @Inject(forwardRef(() => WorkflowContextService))
    private _context: WorkflowContextService,
    @Inject(forwardRef(() => SecurityService))
    private _securitySvc: SecurityService,
    private _fb: UntypedFormBuilder,
    private _ref: ChangeDetectorRef
  ) {
    super();
    this.toEmailForm = this._fb.group({});
    this.attachForm = this._fb.group({});
    this.roleRecipientForm = this._fb.group({});

    this.attachForm.setValidators([]);
  }

  cleanup() {
    this.loadFull = false;
    this.activityForm = null;
  }

  showDetails(e) {
    this.loadFull = true;
    this.init();
  }

  recipientSpecified: ValidatorFn = (
    group: UntypedFormGroup
  ): ValidationErrors | null => {
    if (
      group.controls['applicant'].value ||
      false ||
      this.activity.model.to.length > 0 ||
      this.activity.model.cc.length > 0 ||
      this.activity.model.bcc.length > 0 ||
      this.activity.model.roles.length > 0
    ) {
      return null;
    } else {
      return { recipientSpecified: true };
    }
  };

  initEmail() {
    this.toEmailForm.addControl(
      'newEmailAddress',
      this._fb.control(
        '',
        Validators.compose([
          Validators.required,
          ValidationService.emailOrEntityValidator
        ])
      )
    );
    this.toEmailForm.addControl(
      'newEmailType',
      this._fb.control('to', Validators.required)
    );
    this.roleRecipientForm.addControl(
      'role',
      this._fb.control('', Validators.required)
    );

    this.toEmailForm.controls['newEmailAddress'].valueChanges.subscribe(
      changes => {
        // when an email is added, force the recipient component to re-load
        if (changes === 'RESET') {
          this.refreshing = true;
          setTimeout(() => {
            this.initEmail();
            this.refreshing = false;
          }, 1);
        }
      }
    );
  }

  init() {
    this.recipientDETypes = [
      WorkflowService.DATA_ENTITIES.FreeFormText.code,
      WorkflowService.DATA_ENTITIES.SystemData.code,
      WorkflowService.DATA_ENTITIES.ListData.code
    ];

    this.initEmail();

    const activityGroup = this._fb.group({
      subject: [this.activity.model.subject, Validators.required],
      body: [this.activity.model.body, Validators.required],
      applicant: [this.activity.model.emailApplicant, Validators.nullValidator]
    });

    this.activityForm = activityGroup;

    this.emailBodyField = this.activityForm.controls['body'];

    this.activityForm.setValidators([this.recipientSpecified.bind(this)]);

    const that = this;

    this.workflowSvc
      .getDataEntitiesBeforeMe(
        this.workflow,
        this.activity,
        WorkflowService.getEmailDataEntityTypes()
      )
      .subscribe(entities => {
        this.entities = entities;
      });

    this.workflowSvc
      .getEmailDataEntities(this.workflow, this.activity)
      .subscribe(entities => {
        this.emailDataEntities = entities;
      });

    this.buildAvailableAttachments();

    this._securitySvc.getRoles(this._context.client).subscribe(roles => {
      this.availableRoles = roles;
    });
  }

  ngOnInit() {
    this.workflow = this.workflow || this._context.workflow;
    this.isGlobal = !this.workflow.clientID;

    if (this.loadFull) {
      this.init();
    }
  }

  ngOnDestroy(): void {
    this.loadFull = false;
  }

  insertSubject(de: DataEntity) {
    Utilities.insertValue(
      this.emailSubject.nativeElement,
      this._renderer,
      this.templateSvc.generateToken(de),
      this.activityForm.controls['subject']
    );
  }

  onChange({ editor }: ChangeEvent) {
    const data = editor.data.get();
    this.activity.model.body = data;
  }

  insertRecipient(de: DataEntity) {
    this.emailRecipient.insertDataEntity(de.templateCode);
  }

  insertBody(de: DataEntity) {
    const editor = this.emailBody.editorInstance;

    editor.model.change(writer => {
      const insertPosition = editor.model.document.selection.getFirstPosition();
      writer.insertText(this.templateSvc.generateToken(de), insertPosition);
    });
  }

  buildAvailableAttachments() {
    this.workflowSvc
      .getEmailAttachments(this.workflow, this.activity)
      .subscribe(attachments => {
        const availAttachments = [];

        attachments.forEach((a, i) => {
          const exAttachment = this.activity.model.attachments.find(
            exA =>
              exA.activityId === a.activityId &&
              (exA.deTemplateCode || '') === (a.deTemplateCode || '')
          );
          if (!exAttachment) {
            availAttachments.push(a);
          }
        });
        this.attachmentEntities = availAttachments;
      });
  }

  selectImage() {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.click();

    // Listen upload local image and save to server
    input.onchange = () => {
      const file = input.files[0];

      // file type is only image.
      if (/^image\//.test(file.type)) {
        this.uploadImage(file);
      } else {
        console.warn('You can only upload images.');
      }
    };
  }

  uploadImage(file: File) {
    // TODO: Implement upload
    this.insertImage(file.name);
  }

  insertImage(url) {}

  attachDoc(de: EmailActivityAttachment) {
    if (!this.activity.model.attachments) {
      this.activity.model.attachments = [];
    }
    this.activity.model.attachments.push(de);
    this.attachmentEntities.splice(this.attachmentEntities.indexOf(de), 1);
    this.attachForm.updateValueAndValidity();
  }

  removeAttachment(de: EmailActivityAttachment) {
    this.activity.model.attachments.splice(
      this.activity.model.attachments.indexOf(de),
      1
    );
    this.buildAvailableAttachments();
  }

  addEmail() {
    const type = this.toEmailForm.controls['newEmailType'];
    const email = this.toEmailForm.controls['newEmailAddress'];

    const swappedEntityEmail = email.value.replace(this.emailRegEx, '${$1}');

    switch (type.value) {
      case 'bcc':
        this.addBCCAddress(swappedEntityEmail);
        break;
      case 'cc':
        this.addCCAddress(swappedEntityEmail);
        break;
      case 'to':
        this.addToAddress(swappedEntityEmail);
        break;
      default:
        alert(swappedEntityEmail);
        break;
    }

    email.setValue('RESET');
    type.setValue('to');

    this._ref.detectChanges();
    email.markAsUntouched();
    this.form.updateValueAndValidity();
    this.activityForm.updateValueAndValidity();
  }

  addCCAddress(emailAddress: string) {
    if (!this.activity.model.cc) {
      this.activity.model.cc = [];
    }

    if (!this.activity.model.cc.includes(emailAddress)) {
      this.activity.model.cc.push(emailAddress);
    }
  }

  addBCCAddress(emailAddress: string) {
    if (!this.activity.model.bcc) {
      this.activity.model.bcc = [];
    }

    if (!this.activity.model.bcc.includes(emailAddress)) {
      this.activity.model.bcc.push(emailAddress);
    }
  }

  addToAddress(emailAddress: string) {
    if (!this.activity.model.to) {
      this.activity.model.to = [];
    }

    if (!this.activity.model.to.includes(emailAddress)) {
      this.activity.model.to.push(emailAddress);
    }
  }
  addRole() {
    if (!this.activity.model.roles) {
      this.activity.model.roles = [];
    }

    const role = this.availableRoles.find(r => r.id === this.selectedRole);

    if (role) {
      if (!this.activity.model.roles.includes(role)) {
        this.activity.model.roles.push(role);
      }
      this.selectedRole = null;
    }

    this.activityForm.updateValueAndValidity();
  }
  removeToAddress(emailAddress) {
    this.activity.model.to.splice(
      this.activity.model.to.indexOf(emailAddress),
      1
    );
    this.form.updateValueAndValidity();
    this.activityForm.updateValueAndValidity();
  }
  removeCCAddress(emailAddress) {
    this.activity.model.cc.splice(
      this.activity.model.cc.indexOf(emailAddress),
      1
    );
    this.form.updateValueAndValidity();
    this.activityForm.updateValueAndValidity();
  }
  removeBCCAddress(emailAddress) {
    this.activity.model.bcc.splice(
      this.activity.model.bcc.indexOf(emailAddress),
      1
    );
    this.form.updateValueAndValidity();
    this.activityForm.updateValueAndValidity();
  }
  removeRole(role) {
    this.activity.model.roles.splice(
      this.activity.model.roles.indexOf(role),
      1
    );
  }
}

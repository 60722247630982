<nav class="navbar fixed-top navbar-expand-sm navbar-dark bg-primary">
  <button
    class="navbar-toggler mr-3"
    id="sidebar-toggler"
    type="button"
    data-toggle="drawer"
    data-target="#dw-s1"
    *ngIf="isAdminArea"
  >
    <span class="sr-only">Toggle drawer</span>
    <i class="material-icons">menu</i>
  </button>

  <a class="navbar-brand" [routerLink]="['/']">
    <img
      *ngIf="appConfiguration"
      [src]="appConfiguration.applicationLogo"
      height="30"
      alt=""
    />
    <img
      *ngIf="!appConfiguration"
      src="/assets/images/permittingLogo.png"
      height="30"
      alt=""
    />
  </a>
  <a class="navbar-brand navbar-brand-secondary" *ngIf="client && !isAdminArea">
    <img
      *ngIf="context.client && context.client.headerLogoURL"
      [src]="context.client.headerLogoURL"
      [alt]="context.client.name"
      style="max-height: 27px;"
      class="img-fluid"
    />
  </a>
  <a class="navbar-brand navbar-brand-secondary" *ngIf="client && !isAdminArea">
    {{ client.name }}
  </a>
  <button
    class="navbar-toggler"
    type="button"
    data-toggle="collapse"
    data-target="#appNavbar"
    aria-controls="appNavbar"
    aria-expanded="false"
    aria-label="Toggle navigation"
  >
    <i class="material-icons">more_horiz</i>
  </button>

  <div class="collapse navbar-collapse" id="appNavbar">
    <ul class="navbar-nav ml-auto" *ngIf="context.isLoggedIn && context.user">
      <li
        class="nav-item"
        *ngIf="
          context.client && context.client.id && isAdministrator$
            | async as boolean
        "
      >
        <a
          class="nav-link"
          [routerLink]="['/jurisdiction', context.client.id]"
          [routerLinkActive]="['active']"
        >
          Home
        </a>
      </li>
      <li class="nav-item" *ngIf="isAdministrator$ | async as boolean">
        <a
          class="nav-link"
          [routerLink]="['/admin']"
          [routerLinkActive]="['active']"
          >Admin</a
        >
      </li>
      <li class="nav-item dropdown" *ngIf="isGlobalUser$ | async as boolean">
        <a
          class="nav-link dropdown-toggle"
          href="javascript:void(0)"
          id="navbarDropdownGlobal"
          role="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          Global
        </a>
        <div
          class="dropdown-menu dropdown-menu-right"
          aria-labelledby="navbarDropdownGlobal"
        >
          <a
            class="dropdown-item"
            [routerLinkActive]="['active']"
            [routerLink]="['/admin/global/users']"
          >
            Users
          </a>
          <a
            class="dropdown-item"
            [routerLinkActive]="['active']"
            [routerLink]="['/admin/global/roles']"
          >
            Roles
          </a>
          <a
            class="dropdown-item"
            [routerLinkActive]="['active']"
            [routerLink]="['/admin/global/payment-processors']"
          >
            Payment Processors
          </a>
          <a
            class="dropdown-item"
            [routerLinkActive]="['active']"
            [routerLink]="['/admin/global/workflows']"
          >
            Workflows
          </a>
          <a
            class="dropdown-item"
            [routerLinkActive]="['active']"
            [routerLink]="['/admin/global/contractors/documentCategories']"
          >
            Contractor Document Types
          </a>
          <div *ngIf="!isProduction">
            <a
              class="dropdown-item"
              href="javascript:void(0)"
              id="navbarDropdownTesting"
              >---- Testing ----</a
            >
            <a
              class="dropdown-item"
              [routerLinkActive]="['active']"
              [routerLink]="['/admin/global/testing/runSchedulingEngine']"
            >
              Run Scheduling Engine
            </a>
            <a
              class="dropdown-item"
              [routerLinkActive]="['active']"
              [routerLink]="['/admin/global/testing/autoVoidProcessRunner']"
            >
              Run Auto Void Tester
            </a>
          </div>
        </div>
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          [routerLink]="['/applications']"
          [routerLinkActive]="['active']"
        >
          My Dashboard
        </a>
      </li>
      <li class="nav-item dropdown">
        <a
          class="nav-link dropdown-toggle"
          href="javascript:void(0)"
          id="navbarDropdown"
          role="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          {{ context.user.userName }}
        </a>
        <div
          class="dropdown-menu dropdown-menu-right"
          aria-labelledby="navbarDropdown"
        >
          <a
            class="dropdown-item"
            *ngIf="isAdministrator$ | async as boolean"
            [routerLink]="['/admin']"
            >Admin</a
          >
          <a class="dropdown-item" [routerLink]="['/auth/account']">Account</a>
          <a
            class="dropdown-item"
            *ngIf="isContractor"
            [routerLink]="['/auth/contractor-profile']"
            >Contractor Profile</a
          >
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" (click)="logout()">Logout</a>
        </div>
      </li>
    </ul>
    <ul class="navbar-nav ml-auto" *ngIf="!context.isLoggedIn || !context.user">
      <li class="nav-item">
        <a
          class="nav-link"
          [routerLink]="['/auth/login']"
          [routerLinkActive]="['active']"
          >Login</a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          [routerLink]="['/auth/register']"
          [queryParams]="{ returnUrl: context.returnUrl }"
          [routerLinkActive]="['active']"
          >Signup</a
        >
      </li>
    </ul>
  </div>
</nav>

import { GenerateApplicationNumberActivity } from './../../../../../models/activities/generate-application-number-activity';
import { ConditionTarget } from './../../../../system/condition-builder/condition-builder.model';
import { UntypedFormControl, Validators } from '@angular/forms';
import { ActivityEditorBaseComponent } from 'src/app/components/workflow/activities/activity-editor-base/activity-editor-base.component';
import { Component, OnInit, ChangeDetectorRef, Input } from '@angular/core';

@Component({
  selector: 'wm-generate-application-number-activity-editor',
  templateUrl: './generate-application-number-activity-editor.component.html',
  styleUrls: ['./generate-application-number-activity-editor.component.css']
})
export class GenerateApplicationNumberActivityEditorComponent
  extends ActivityEditorBaseComponent
  implements OnInit {
  @Input() activity: GenerateApplicationNumberActivity;

  constructor(private changeDetRef: ChangeDetectorRef) {
    super();
  }

  isDestroyed = false;

  ngOnInit() {
    if (this.form) {
      this.form.addControl(
        'numberPrefix',
        new UntypedFormControl('', [Validators.nullValidator])
      );
    }
  }

  detectChanges() {
    if (this.changeDetRef && !this.isDestroyed) {
      this.changeDetRef.detectChanges();
    }
  }

  // Use => in order to force `this` into being the FeeDataEntityEditorComponent
  getThenLabel = (clause: ConditionTarget): string => {
    if (clause) {
      return clause.value;
    } else {
      return 'needs refactored';
    }
  }

  updateCriteria(criteria: ConditionTarget[]) {
    this.activity.model.numberPrefixCriteria = criteria;
    let hasValue = false;

    if (criteria && criteria.length > 0) {
      criteria.forEach((c, index) => {
        if ((c.value || '') !== '') {
          hasValue = true;
          return false;
        }
      });
    }
    this.form.controls['numberPrefix'].setValue(hasValue ? 'done' : '');
    this.detectChanges();
  }
}

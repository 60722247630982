<h3>Users</h3>

<div
  *ngIf="statusMessage && statusMessage !== null && statusMessage !== ''"
  [class]="alertClass"
  role="alert"
>
  {{ statusMessage }}
</div>

<wm-user-search
  (searchStarted)="searchStarted()"
  (searchFinished)="searchFinished($event)"
  [searchText]="searchText"
>
</wm-user-search>

<wm-user-list
  [users]="users"
  [raiseEvent]="raiseEvent"
  [searching]="searching"
  (selected)="userSelected($event)"
></wm-user-list>

import { Pipe, PipeTransform } from '@angular/core';
import { RegistrationStatus } from '../models/registration';

@Pipe({
  name: 'registrationStatus'
})
export class RegistrationStatusPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    const a = RegistrationStatus[value];
    if (a) {
      const b = a
        .replace(/([A-Z])/g, ' $1')
        .replace(a.substring(0, 1), a.substring(0, 1).toUpperCase());
      return b;
    }
    return '';
  }
}

<wm-activity-details-modal
  [title]="activity.model.screenName"
  btnText="Configure Map"
  name="mapConfig"
  [moduleId]="activity.id"
  [form]="form"
  (saved)="save()"
>
  <p [ngClass]="['text-' + txtClass]">{{ message }}</p>

  <app-map-display-configuration-editor
    [client]="client"
    [mapConfig]="activity.model.mapConfig"
    [mapServiceId]="activity.model.mapConfig.mapServiceId"
  >
  </app-map-display-configuration-editor>
</wm-activity-details-modal>

import { Component, OnInit, Input } from '@angular/core';
import { PaymentAccountConfigBaseComponent } from '../payment-account-config-base/payment-account-config-base.component';
import { NmiPaymentProcessorAccountConfiguration } from 'src/app/models/payment-providers/nmi/nmi-payment-processor-account-configuration';
import { UntypedFormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'wm-payment-account-config-nmi',
  templateUrl: './payment-account-config-nmi.component.html',
  styleUrls: ['./payment-account-config-nmi.component.css']
})
export class PaymentAccountConfigNmiComponent
  extends PaymentAccountConfigBaseComponent
  implements OnInit {
  @Input() config: NmiPaymentProcessorAccountConfiguration;

  constructor(_fb: UntypedFormBuilder) {
    super(_fb);
    this.showUsername = false;
    this.showPassword = false;
  }

  ngOnInit() {
    this.addValidation('apiKey', Validators.required, this.config.apiKey);
  }
}

<button
  *ngIf="btnShow"
  type="button"
  [class]="'btn mr-0 btn-raised btn-' + btnColor"
  (click)="open()"
  [id]="moduleId + '_btn'"
  [disabled]="disabled"
>
  {{ btnText }}
</button>

<ng-template #content let-modal>
  <form>
    <nav class="navbar fixed-top navbar-dark bg-primary" [formGroup]="form">
      <ul class="navbar-nav mr-3">
        <li class="nav-item">
          <a class="nav-link" (click)="modal.dismiss('close')">
            <i class="material-icons" style="vertical-align: middle;">
              close
            </i>
          </a>
        </li>
      </ul>

      <span class="navbar-brand mb-0 h1" [id]="moduleId + '_modallabel'">
        {{ title }}
      </span>

      <ul class="navbar-nav ml-auto">
        <li class="nav-item" *ngIf="form">
          <a
            class="nav-link"
            [class.disabled]="!form.valid || _workflowSvc.designerVersionMode"
            (click)="form.valid && modal.dismiss('save')"
            >Save</a
          >
        </li>
      </ul>
    </nav>

    <div class="modal-body mt-4" style="padding-top: 56px;">
      <div [class]="containerType">
        <ng-content></ng-content>
      </div>
    </div>
  </form>
</ng-template>

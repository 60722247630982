import { Activity, ActivityModel, ScreenActivity } from './activity';

export class CompleteWorkflowActivityModel extends ActivityModel {
  reason: string;
  activities: Activity<ActivityModel>[];
  autoSubmit: boolean;

  constructor(options?: Partial<CompleteWorkflowActivityModel>) {
    super(options);

    this.reason = 'Completed';
    this.autoSubmit = false;
    this.screenName = 'End';

    if (options) {
      Object.assign(this, options);
    }
  }
}

export class CompleteWorkflowActivity extends ScreenActivity<
  CompleteWorkflowActivityModel
> {
  constructor(options?: Partial<CompleteWorkflowActivity>) {
    super(options);

    this.type = 'complete-workflow-activity';
    this.name = 'End';
    this.description = 'End the workflow';

    if (options) {
      Object.assign(this, options);
    }

    this.model = new CompleteWorkflowActivityModel(
      options ? options.model : null
    );
  }

  dereference() {
    this.model.activities = [];
  }
}

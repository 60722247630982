<div class="card mt-3">
  <div class="card-body">
    <div class="card-title"><h3>Requested Reports</h3><h6 class="text-danger">You may navigate away from this page and come back later to view your report.</h6></div>
    <table class="table">
      <thead>
        <tr>
          <th>Requested On</th>
          <th>Requested By</th>
          <th>Status</th>
          <th>Completed On</th>
          <th>Output</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let cr of queuedReports">
          <td>{{ cr.queuedOnString }}</td>
          <td>{{ cr.queuedByUser }}</td>
          <td>{{ cr.status }}</td>
          <td>{{ cr.completedOnString }}</td>
          <td>
            <a *ngIf="cr.documentPath" target="_blank" [href]="cr.documentPath"
              >View Document</a
            >
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<div class="row" *ngIf="canAddJurisdiction">
  <div class="col">
    <h2>Jurisdictions</h2>
  </div>
  <div class="col">
    <button
      class="btn btn-raised btn-primary float-right"
      (click)="addClient()"
    >
      Add Jurisdiction
    </button>
  </div>
</div>

<div class="row">
  <div class="col">
    <wm-client-search
      (searchStarted)="clients = []"
      (searchFinished)="clients = $event"
    ></wm-client-search>
  </div>
</div>

<div class="row">
  <div class="col">
    <wm-client-list
      *ngIf="clients"
      [clients]="clients"
      (removed)="removeClient($event)"
    ></wm-client-list>
  </div>
</div>

import { ApplicationStatus } from './workflow-application';
/**
 * Type of the object that was validated
 */
export enum ValidationType {
  DataEntity = 0,
  Activity = 1,
  Workflow = 2
}
/**
 * Severity of the validation message
 */
export enum ValidationSeverity {
  Error = 0,
  Warning = 1
}

/**
 * Object representing a validation message
 */
export class ValidationMessage {
  /**
   * Validation error message
   */
  message: string;
  /**
   * Severity of the validation error
   */
  severity: ValidationSeverity;
}

/**
 * Object representing an item that has validation errors
 */
export class ValidationResponse {
  /**
   * Type of the object that was validated
   */
  type: ValidationType;
  /**
   * Array of validation messages
   */
  messages: ValidationMessage[];
  /**
   * ID of the object that was validated
   */
  id?: string;
  /**
   * Activity Group that the error relates to
   */
  activityGroup: ApplicationStatus;
  /**
   * Activity Id of the data Entity
   */
  activityId?: string;
}

import {
  Component,
  OnInit,
  ComponentFactoryResolver,
  Inject,
  forwardRef,
  OnDestroy,
  ChangeDetectorRef
} from '@angular/core';
import { DataEntityInputComponent } from '../../data-entity-input/data-entity-input.component';
import { CalculatedValueDataEntity } from '../../../../../models/data-entities';
import {
  WorkflowService,
  WorkflowContextService,
  SecurityService
} from '../../../../../services';
import { Subscription, interval } from 'rxjs';
import * as _ from 'lodash';
import { CalcOnChangeDataEntityComponent } from '../../data-entity-input/calc-on-change-data-entity';

@Component({
  selector: 'wm-calculate-value-data-entity-input',
  templateUrl: './calculate-value-data-entity-input.component.html',
  styleUrls: ['./calculate-value-data-entity-input.component.css']
})
export class CalculateValueDataEntityInputComponent
  extends CalcOnChangeDataEntityComponent
  implements OnInit, OnDestroy {
  entity: CalculatedValueDataEntity;

  constructor(
    componentFactoryResolver: ComponentFactoryResolver,
    ref: ChangeDetectorRef,
    @Inject(forwardRef(() => WorkflowService))
    workflowSvc: WorkflowService,
    @Inject(forwardRef(() => WorkflowContextService))
    context: WorkflowContextService,
    @Inject(forwardRef(() => SecurityService))
    securitySvc: SecurityService
  ) {
    super(componentFactoryResolver, workflowSvc, context, securitySvc, ref);
  }
}

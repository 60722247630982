import { PaymentProcessorAccountConfigurationBase } from '../payment-processor-account-configuration-base';

export class GovPayPaymentProcessorAccountConfiguration extends PaymentProcessorAccountConfigurationBase {
  partnerId: string;

  constructor(options?: Partial<GovPayPaymentProcessorAccountConfiguration>) {
    super(options);

    if (options) {
      Object.assign(this, options);
    }
  }
}

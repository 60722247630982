import {
  Component,
  OnInit,
  Input,
  ComponentFactoryResolver,
  Inject,
  forwardRef
} from '@angular/core';
import {
  DataEntity,
  DocumentDataEntity
} from '../../../../../models/data-entities';
import { DataEntityViewComponent } from '../../data-entity-view/data-entity-view.component';
import {
  WorkflowContextService,
  DocumentService
} from '../../../../../services';
import { Document } from '../../../../../models';

@Component({
  selector: 'wm-document-data-entity-view',
  templateUrl: './document-data-entity-view.component.html',
  styleUrls: ['./document-data-entity-view.component.css']
})
export class DocumentDataEntityViewComponent extends DataEntityViewComponent
  implements OnInit {
  @Input() entity: DocumentDataEntity;
  @Input() applicationId: string;
  @Input() parentEntityId: string;
  documentTemplateCode: string;
  // documents: Document[];

  constructor(
    componentFactoryResolver: ComponentFactoryResolver,
    @Inject(forwardRef(() => DocumentService))
    public uploadService: DocumentService,
    @Inject(forwardRef(() => WorkflowContextService))
    private _context: WorkflowContextService
  ) {
    super(componentFactoryResolver);
  }

  ngOnInit() {
    this.applicationId = this.applicationId || this._context.applicationId;
    if (this.parentEntityId || this.entity.parent) {
      this.parentEntityId = this.parentEntityId || this.entity.parent.id;
    }
    this.documentTemplateCode = this.entity.templateCode;
    this.getDocuments();
  }

  getDocuments() {
    if (
      this.applicationId &&
      this.parentEntityId &&
      this.documentTemplateCode
    ) {
      if ((this.entity.documents || []).length === 0 && this.entity.value) {
        this.entity.documents = JSON.parse(
          this.entity.value.replace(/\\"/g, '"')
        );
      }
    }
  }
}

import { LoginResponse } from './../../../models/security';
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import {
  UntypedFormGroup,
  AbstractControl,
  UntypedFormBuilder,
  Validators
} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { User } from 'src/app/models';
import {
  WorkflowContextService,
  SystemService,
  SecurityService,
  ValidationService
} from 'src/app/services';
import { InvitedUser } from 'src/app/models/invited-user';

@Component({
  selector: 'wm-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  user: User;
  // confirmPassword: string;
  form: UntypedFormGroup;
  fullName: AbstractControl;
  userName: AbstractControl;
  password: AbstractControl;
  confirmPasswordCtl: AbstractControl;
  emailAddress: AbstractControl;
  phoneNumber: AbstractControl;
  registrationCompleted = false;
  statusMessage: string;
  invitedEmailHasUser = false;
  @Input() returnURL: string;
  @Input() showPasswordInput = true;
  @Output() registered: EventEmitter<LoginResponse> = new EventEmitter<
    LoginResponse
  >();
  @Input() raiseEvents = false;
  @Output() canceled: EventEmitter<any> = new EventEmitter<any>();
  saving = false;
  @Input() returnApplicationId: string;
  @Input() invitationId: string;
  invitation: InvitedUser;
  loading = true;

  constructor(
    public context: WorkflowContextService,
    private _securitySvc: SecurityService,
    private _systemSvc: SystemService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _fb: UntypedFormBuilder,
    private _toastrSvc: ToastrService
  ) {
    //#region init user model
    this.user = this._securitySvc.createUser();

    this.user.emailAddress = ''; /// this._systemSvc.createEmailAddress('User');
    this.user.phoneNumber = ''; // this._systemSvc.createPhoneNumber('User');
    this.user.address = this._systemSvc.createAddress('User');
    //#endregion
  }
  registerUser(result) {
    // TODO: Handle bad register Errors
    // display message that confirm link has been emailed to them
    this.saving = false;
    if (result.loginValid) {
      if (!this.showPasswordInput) {
        this.registered.emit(result);
        this.context.returnUrl$.next('');
      } else {
        this.registrationCompleted = true;
      }
    } else {
      this.registrationCompleted = false;
      this._toastrSvc.error(result.errorMessage);
    }
  }
  cancel() {
    if (this.raiseEvents) {
      this.canceled.emit();
    } else {
      this._router.navigate(['/']);
    }
  }
  save() {
    if ((this.user.confirmPassword || '') === (this.user.password || '')) {
      this.saving = true;
      const returnUrl = this.returnURL || this.context.returnUrl;

      this._securitySvc
        .registerUser(
          this.user,
          returnUrl,
          !this.showPasswordInput,
          this.invitationId
        )
        .subscribe(
          res => this.registerUser(res),
          err => {
            this.saving = false;
            throw err;
          }
        );
    } else {
      // handle validation error
    }
  }

  ngOnInit() {
    //#region setup form validation
    this.form = this._fb.group({
      fullName: ['', Validators.required],
      userName: ['', Validators.required],
      password: [
        '',
        this.showPasswordInput ? Validators.required : Validators.nullValidator
      ],
      confirmPassword: [
        '',
        this.showPasswordInput ? Validators.required : Validators.nullValidator
      ],
      emailAddress: [
        '',
        Validators.compose([
          Validators.required,
          ValidationService.emailValidator
        ])
      ],
      phoneNumber: ['', Validators.nullValidator]
    });

    if (this.showPasswordInput) {
      this.form.setValidators(
        ValidationService.passwordMatchValidator('password', 'confirmPassword')
      );
    }

    this.fullName = this.form.controls['fullName'];
    this.emailAddress = this.form.controls['emailAddress'];
    this.userName = this.form.controls['userName'];
    this.confirmPasswordCtl = this.form.controls['confirmPassword'];
    this.password = this.form.controls['password'];
    this.phoneNumber = this.form.controls['phoneNumber'];

    if (this.invitationId) {
      this._securitySvc
        .getUserInvitation(this.invitationId)
        .subscribe(invitation => {
          this.invitation = invitation;

          if (invitation) {
            this.user.emailAddress = invitation.email;
            this.emailAddress.disable();
            this._securitySvc
              .getUserListByEmail(invitation.email)
              .subscribe(users => {
                this.loading = false;
                if ((users || []).length > 0) {
                  this.invitedEmailHasUser = true;
                }
              });
          }
        });
    } else {
      this.loading = false;
    }

    this._route.queryParams.subscribe(parms => {
      const rURL = parms['returnUrl'];

      if (rURL) {
        this.returnURL = rURL;
      }
    });
  }
}

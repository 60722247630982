import { Subscription } from 'rxjs';
import {
  Component,
  OnInit,
  Inject,
  forwardRef,
  Input,
  OnDestroy
} from '@angular/core';
import { ActivityEditorBaseComponent } from '../../activity-editor-base/activity-editor-base.component';
import {
  WorkflowService,
  WorkflowContextService,
  ContractorService
} from 'src/app/services';
import { ActivatedRoute } from '@angular/router';
import { ContractorSelectionActivity } from 'src/app/models/activities/contractor-selection-activity';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  UntypedFormArray,
  UntypedFormControl,
  ValidatorFn,
  Validators
} from '@angular/forms';

@Component({
  selector: 'wm-contractor-selection-activity-editor',
  templateUrl: './contractor-selection-activity-editor.component.html',
  styleUrls: ['./contractor-selection-activity-editor.component.css']
})
export class ContractorSelectionActivityEditorComponent
  extends ActivityEditorBaseComponent
  implements OnInit, OnDestroy {
  @Input() activity: ContractorSelectionActivity;
  activityId: string;
  form: UntypedFormGroup;

  contractorTypes: any;
  selectedValue: any;

  private clientSubscription: Subscription;

  constructor(
    @Inject(forwardRef(() => WorkflowService))
    private _workflowSvc: WorkflowService,
    @Inject(forwardRef(() => WorkflowContextService))
    private _context: WorkflowContextService,
    @Inject(forwardRef(() => ContractorService))
    private _contractorSvc: ContractorService,
    private _route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder
  ) {
    super();
  }

  loadContractorTypes() {
    if (this._context.client) {
      this._contractorSvc
        .getContractorRegistrationTypes(this._context.client)
        .subscribe(types => {
          this.contractorTypes = types;
          this.addCheckboxes();
        });
    }
  }

  isSelected(value) {
    return true;
  }
  selectItem(evt, id) {
    if (!evt) {
      const index = this.activity.contractorTypeIds.indexOf(id);
      this.activity.contractorTypeIds.splice(index, 1);
    } else {
      this.activity.contractorTypeIds.push(id);
    }
  }
  ngOnInit() {
    this.form = this.formBuilder.group({
      contractorTypes: new UntypedFormArray([]),
      allowNotListedOption: new UntypedFormControl('', Validators.required)
    });
    // get the contractor types to choose from.
    this.loadContractorTypes();
    if (!this._context.client) {
      this.clientSubscription = this._context.client$.subscribe(res => {
        this.loadContractorTypes();
      });
    }
  }

  ngOnDestroy() {
    if (this.clientSubscription) {
      this.clientSubscription.unsubscribe();
      this.clientSubscription = null;
    }
  }

  private addCheckboxes() {
    if (this.contractorTypes) {
      this.contractorTypes.map((o, i) => {
        const isSelected = this.activity.contractorTypeIds.includes(o.id);
        const control = new UntypedFormControl(isSelected); // if first item set to true, else false
        (this.form.controls.contractorTypes as UntypedFormArray).push(control);
      });
    }
  }

  submit() {}
}
function minSelectedCheckboxes(min = 1) {
  const validator: ValidatorFn = (formArray: UntypedFormArray) => {
    const totalSelected = formArray.controls
      // get a list of checkbox values (boolean)
      .map(control => control.value)
      // total up the number of checked checkboxes
      .reduce((prev, next) => (next ? prev + next : prev), 0);

    // if the total is not greater than the minimum, return the error message
    return totalSelected >= min ? null : { required: true };
  };

  return validator;
}

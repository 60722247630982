export class PanelConfig {
  name: string;
  panelId: string;
  templateId: string;
  displayOrder: number;
  visibility: PanelVisibilityOption;

  constructor(
    name: string,
    panelId: string,
    templateId: string,
    displayOrder: number,
    visibility: PanelVisibilityOption
  ) {
    this.name = name;
    this.panelId = panelId;
    this.templateId = templateId;
    this.displayOrder = displayOrder;
    this.visibility = visibility;
  }
}

export enum PanelVisibilityOption {
  Expanded = 1,
  Collapsed = 2
}

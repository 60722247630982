<form [formGroup]="dateForm" *ngIf="entity && dateForm" novalidate>
  <div class="form-group">
    <wm-de-label
      [entity]="entity"
      [for]="entity.templateCode + '-DATE'"
    ></wm-de-label>
    <div class="d-flex align-items-center">
      <div class="mr-2">
        <input
          class="form-control"
          placeholder="mm/dd/yyyy"
          [name]="entity.templateCode + '-DATE'"
          [id]="entity.templateCode + '-DATE'"
          [formControlName]="entity.templateCode + '-DATE'"
          ngbDatepicker
          #d="ngbDatepicker"
          [markDisabled]="isDisabled"
          (focus)="onFocus()"
          (dateSelect)="
            date = dateForm.controls[entity.templateCode + '-DATE'].value
          "
          [maxDate]="maxDate"
          [minDate]="minDate"
          [restoreFocus]="false"
          [autoClose]="true"
        />
      </div>
      <ngb-timepicker
        *ngIf="entity.includeTime"
        [id]="entity.templateCode + '-TIME'"
        [name]="entity.templateCode + '-TIME'"
        [formControlName]="entity.templateCode + '-TIME'"
        [(ngModel)]="time"
        [meridian]="true"
      ></ngb-timepicker>
      <span *ngIf="entity.includeTime">&nbsp;{{ timeZoneCode }}</span>
    </div>
  </div>
</form>

import { Activity, ActivityModel } from 'src/app/models/activities';
import { UntypedFormGroup } from '@angular/forms';
import { WorkflowContextService } from 'src/app/services';
import {
  Component,
  OnInit,
  EventEmitter,
  ViewChild,
  Input
} from '@angular/core';
import { EsriMapComponent } from 'src/app/components/arcgis/esri-map/esri-map.component';
import { MapActivity } from 'src/app/models/activities/map-activity';
import { MapsketchData } from 'src/app/components/arcgis/mapsketch-data';
import { MapExtent } from 'src/app/models/mapextent';
import { GeomUtils } from 'src/app/components/arcgis/geom-utils';
import { ActivityView } from 'src/app/views/master-views/app.view/app.view.component';

@Component({
  selector: 'wm-map-activity-view',
  templateUrl: './map-activity-view.component.html',
  styleUrls: ['./map-activity-view.component.css']
})
export class MapActivityViewComponent implements OnInit, ActivityView {
  @Input() activity: MapActivity;
  isPreview: boolean;
  isTestApplication: boolean;
  showSave?: EventEmitter<boolean>;
  showCancel?: EventEmitter<boolean>;
  showNext?: EventEmitter<boolean>;
  showPrevious?: EventEmitter<boolean>;
  nextOnSelect: true;
  goNext?: EventEmitter<boolean>;
  value$: EventEmitter<any>;
  initActivity: any;
  form: UntypedFormGroup;
  context: WorkflowContextService;


  @ViewChild(EsriMapComponent, { static: true })
  private esriMap: EsriMapComponent;

  constructor() {}
  switchApplication?: EventEmitter<string>;
  applicationId?: string;

  viewTitle(): string {
    return this.activity.model.screenName;
  }

  ngOnInit() {}
  async onMapLoaded() {
    if (this.activity.model.selectedGeometryWkt) {
      const geom = GeomUtils.WktToArcGisGeom(
        this.activity.model.selectedGeometryWkt
      );
      const graphic = {
        geometry: geom
      };
      this.esriMap.setSketchData(
        [graphic],
        this.activity.model.mapExtent,
        null
      );
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { WorkflowContextService } from 'src/app/services';

@Component({
  selector: 'wm-workflow-application-previous-system-list-view',
  templateUrl:
    './workflow-application-previous-system-list-view.component.html',
  styleUrls: ['./workflow-application-previous-system-list-view.component.css']
})
export class WorkflowApplicationPreviousSystemListViewComponent
  implements OnInit {
  id = 'previous-system-list-view';
  clientId: string;

  constructor(public context: WorkflowContextService) {}

  ngOnInit() {
    this.clientId = this.context.client.id;
  }
}

<app-loader *ngIf="!dataSets"></app-loader>
<div>
  <div class="row">
    <div class="col">
      <h3>Data Sets</h3>
    </div>
    <div class="col">
      <button
        type="button"
        class="btn btn-raised btn-success float-right"
        [routerLink]="[
          '/admin/jurisdiction',
          context.client.id,
          'reports',
          'data-set',
          EMPTY_GUID
        ]"
      >
        New DataSet
      </button>
    </div>
  </div>

  <table class="table">
    <tr>
      <th>Name</th>
      <th>Last Published On</th>
    </tr>

    <tr *ngFor="let ds of dataSets">
      <td class="align-middle">
        <a
          [routerLink]="[
            '/admin/jurisdiction',
            context.client.id,
            'reports',
            'data-set',
            ds.id
          ]"
          >{{ ds.name }}
        </a>
        <p class="text-muted">{{ ds.description }}</p>
      </td>
      <td>
        {{ ds.publishedOnString || 'Never Published' }}
      </td>
      <td>
        <div ngbDropdown container="body">
          <button
            class="btn btn-secondary"
            id="dropdownMenuButton"
            ngbDropdownToggle
            style="padding-top:0px;"
          >
            Action
          </button>
          <div ngbDropdownMenu aria-labelledby="dropdownMenuButton">
            <wm-modal-confirm
              commandText="Delete"
              [showButton]="false"
              [moduleId]="ds.id + 'delete'"
              [id]="ds.id + 'delete'"
              [title]="'Delete Data Set'"
              (accepted)="deleteDataSet(ds)"
              #deleteButton
            >
              Are you sure you want to delete Data Set "{{ ds.name }}"?
            </wm-modal-confirm>
            <button ngbDropdownItem (click)="deleteButton.open()">
              Delete
            </button>
            <!-- <wm-modal-confirm
            title="Copy Workflow"
            moduleId="copy-workflow"
            [showButtonText]="true"
            [enabled]="true"
            [showButton]="false"
            commandText="Copy"
            [form]="targetForm"
            (accepted)="copyWorkflow(copyWorkflowId)"
            [closeOnAccept]="false"
            #copyWorkflowModal
          >
            <form [formGroup]="targetForm">
              <label for="targetClientId" class="bmd-label-static">Jurisdiction</label>
              <select
                class="form-control"
                [(ngModel)]="targetClientId"
                name="targetClientId"
                formControlName="targetClientId"
                *ngIf="(clients || []).length > 1"
              >
                <option value="" *ngIf="isSysAdmin">Global</option>
                <option *ngFor="let client of clients" [value]="client.id">
                  {{ client.name }}
                </option>
              </select>
              <label for="targetName" class="bmd-label-static">New Name</label>
              <input
                type="text"
                name="targetName"
                formControlName="targetName"
                [(ngModel)]="targetName"
                class="form-control"
              />
            </form>
          </wm-modal-confirm>            -->
            <wm-modal-confirm
              commandText="Copy"
              [showButton]="false"
              [moduleId]="ds.id + 'copy'"
              [id]="ds.id + 'copy'"
              [title]="'Copy Data Set'"
              (opened)="startDataSetCopy(ds)"
              (accepted)="copyDataSet(ds)"
              #copyButton
            >
              Are you sure you want to copy Data Set "{{ ds.name }}"?
              <form [formGroup]="targetForm">
                <label for="targetClientId" class="bmd-label-static"
                  >Jurisdiction</label
                >
                <select
                  class="form-control"
                  [(ngModel)]="targetClientId"
                  name="targetClientId"
                  formControlName="targetClientId"
                  *ngIf="(clients || []).length > 1"
                >
                  <option value="" *ngIf="isSysAdmin">Global</option>
                  <option *ngFor="let client of clients" [value]="client.id">
                    {{ client.name }}
                  </option>
                </select>
                <label for="targetName" class="bmd-label-static"
                  >New Name</label
                >
                <input
                  type="text"
                  name="targetName"
                  formControlName="targetName"
                  [(ngModel)]="targetName"
                  class="form-control"
                />
              </form>
            </wm-modal-confirm>
            <button ngbDropdownItem (click)="copyButton.open()">
              Copy
            </button>
            <wm-modal-confirm
              commandText="Publish"
              [showButton]="false"
              [moduleId]="ds.id + 'publish'"
              [id]="ds.id + 'publish'"
              [title]="'Publish Data Set'"
              (accepted)="publishDataSet(ds)"
              #publishButton
            >
              Are you sure you want to publish Data Set "{{ ds.name }}"?
            </wm-modal-confirm>
            <button ngbDropdownItem (click)="publishButton.open()">
              Publish
            </button>
          </div>
        </div>
      </td>
    </tr>

    <tr *ngIf="dataSets && dataSets.length === 0">
      <td>No data sets to display</td>
    </tr>
  </table>
</div>

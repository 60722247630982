<form [formGroup]="form" *ngIf="config">
  <div class="form-group">
    <label class="bmd-label-floating" for="merchantId">Merchant ID</label>
    <input
      type="text"
      class="form-control"
      formControlName="merchantId"
      name="merchantId"
      [(ngModel)]="config.merchantId"
    />
  </div>
</form>

<form [formGroup]="form" novalidate>
  <div class="form-group">
    <label class="bmd-label-floating" for="display-entity-value-select">
      Data Entity to Display:
    </label>
    <select
      class="form-control"
      [(ngModel)]="displayEntityValue.templateCodeRef"
      formControlName="display-entity-value-select"
    >
      <option *ngFor="let e of entities" [value]="e.templateCode">
        {{ e.label }} ({{ e.templateCode }})
      </option>
    </select>
  </div>
  <div class="form-group">
    <div class="switch">
      <label>
        <input
          class="form-control"
          [(ngModel)]="displayEntityValue.useDataEntityLabel"
          formControlName="use-data-entity-label-check"
          [checked]="this.useDataEntityLabel"
          type="checkbox"
        />
        Use label from Data Entity being displayed</label
      >
    </div>
    <div class="switch">
      <label>
        <input
          class="form-control"
          [(ngModel)]="displayEntityValue.hideLabelAndValueWhenNullorEmpty"
          formControlName="hide-label-and-value-when-null-or-empty-check"
          [checked]="this.hideLabelAndValue"
          type="checkbox"
        />
        Only display label and value when there is a value</label
      >
    </div>
  </div>
</form>

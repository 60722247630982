import { Component, forwardRef, Inject, Input, OnInit } from '@angular/core';
import { AgendaItemActivity } from 'src/app/models/activities';
import { DataService } from 'src/app/services';
import { ActivityView } from 'src/app/views/master-views/app.view/app.view.component';

@Component({
  selector: 'wm-agenda-item-activity-view',
  templateUrl: './agenda-item-activity-view.component.html',
  styleUrls: ['./agenda-item-activity-view.component.css']
})
export class AgendaItemActivityViewComponent extends ActivityView
  implements OnInit {
  @Input() activityDataId: string;
  @Input() applicationId: string;
  evaluatedAgendaItem: string;

  constructor(
    @Inject(forwardRef(() => DataService))
    private _dataSvc: DataService
  ) {
    super();
  }

  ngOnInit() {
    const populateEvaluatedAgendaItem = () => {
      const activity = this.activity as AgendaItemActivity;
      this.evaluatedAgendaItem = activity.model.queueItem.evaluatedAgendaItem;
    };

    if (this.activityDataId) {
      this._dataSvc
        .getActivity(this.applicationId, this.activityDataId)
        .subscribe(activity => {
          this.activity = activity;
          populateEvaluatedAgendaItem();
        });
    } else {
      populateEvaluatedAgendaItem();
    }
  }
}

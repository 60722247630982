<form [formGroup]="form" *ngIf="dataEntity">
  <div class="form-row">
    <div class="switch">
      <label>
        <input
          type="checkbox"
          formControlName="allowEdit"
          [(ngModel)]="dataEntity.allowEdit"
        />
        Allow Editing
      </label>
    </div>
  </div>
  <div class="form-row">
    <div class="switch">
      <label>
        <input
          type="checkbox"
          formControlName="visibleToUser"
          [(ngModel)]="dataEntity.visibleToUser"
        />
        Visible To User
      </label>
    </div>
  </div>
  <div class="form-group">
    <label class="bmd-label-floating" for="output-format">Output Format</label>
    <select
      class="form-control"
      [(ngModel)]="dataEntity.outputFormat"
      formControlName="output-format"
    >
      <option value="0">General</option>
      <option value="1">Text</option>
      <option value="2">Number</option>
      <option value="3">Decimal</option>
      <option value="4">Date</option>
      <option value="5">Date & Time</option>
    </select>
  </div>
  <div class="form-row">
    <div class="col-12">
      <wm-data-entity-formula
        id="formula"
        [form]="form"
        [workflow]="workflow"
        [activity]="activity"
        [criteria]="dataEntity.formulaCriteria"
        [thenLabelGetter]="getThenLabel"
        (criteriaChanged)="updateCriteria($event)"
        [formulaTitle]="'formula'"
        [activity]="activity"
      ></wm-data-entity-formula>
    </div>
  </div>
</form>

import { Client } from './../../../../models/client';
import { DataTableHelpers } from './../../../../components/system/datatable/datatable-helper';
import { Component, OnInit, Output, Input } from '@angular/core';
import {
  ItemSearchOptionField,
  ItemSearchFilter,
  ItemSearchOption,
  ItemSearchOptionFieldOption
} from 'src/app/components/filter-list/models/filterClasses';
import { EventEmitter } from '@angular/core';
import { ComponentIds } from 'src/app/models/component-ids';

@Component({
  selector: 'app-my-inspection-queue-schedule',
  templateUrl: './my-inspection-queue-schedule.component.html',
  styleUrls: []
})
export class MyInspectionQueueScheduleComponent implements OnInit {
  @Output() updateStatus = new EventEmitter();
  @Output() dataLoaded = new EventEmitter<string>();
  @Output() recordCountSet = new EventEmitter<number>();
  @Output() filtersChanged = new EventEmitter<boolean>();
  @Input() client: Client;

  id = ComponentIds.MY_INSPECTION_QUEUE_SCHEDULE;

  public columns = [
    {
      prop: 'address',
      name: 'Address'
    },
    {
      prop: 'city',
      name: 'City'
    },
    { prop: 'inspectionType', name: 'Inspection Type' },
    {
      prop: 'requestedOn',
      name: 'Requested Date',
      pipe: this.dtHelpers.getDatePipe('MM/dd/yyy, h:mm a')
    },
    { prop: 'aMpM', name: 'Request Time (am/pm)' },
    { prop: 'requestorName', name: 'Requestor Name' },
    { prop: 'requestorPhoneNumber', name: 'Requestor Phone' },
    {
      prop: 'inspectionStatus',
      name: 'Status'
    }
  ];
  staticFilters: ItemSearchFilter;

  constructor(private dtHelpers: DataTableHelpers) {}

  ngOnInit() {
    this.staticFilters = new ItemSearchFilter({
      options: [
        new ItemSearchOption({
          field: new ItemSearchOptionField({
            title: 'CanPerformAction',
            options: [
              new ItemSearchOptionFieldOption({
                title: 'CanPerformAction',
                selected: true,
                filterText: '',
                searchText: 'scheduleActivity',
                strOperator: ''
              })
            ]
          })
        }),
        new ItemSearchOption({
          field: new ItemSearchOptionField({
            title: 'inspectorId',
            options: [
              new ItemSearchOptionFieldOption({
                title: 'inspectorId',
                selected: true,
                filterText: '',
                searchText: 'NULL',
                strOperator: 'is'
              })
            ]
          })
        })
      ]
    });
  }

  updateStatusEvent(evt) {
    this.updateStatus.emit(evt);
  }

  emitDataLoaded() {
    this.dataLoaded.emit('MyInspectionQueueScheduleComponent');
  }

  setRecordCount(count: number) {
    this.recordCountSet.emit(count);
  }

  filtersChange() {
    this.filtersChanged.emit(true);
  }
}

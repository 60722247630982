import { Component, OnInit } from '@angular/core';
import { ActivityView } from '../../../../../views/master-views/app.view/app.view.component';
import { InspectionScheduleActivity } from 'src/app/models/activities/inspection-schedule-activity';

@Component({
  selector: 'wm-inspection-schedule-activity-view',
  templateUrl: './inspection-schedule-activity-view.component.html',
  styleUrls: ['./inspection-schedule-activity-view.component.css']
})
export class InspectionScheduleActivityViewComponent extends ActivityView
  implements OnInit {
  activity: InspectionScheduleActivity;

  constructor() {
    super();
  }

  ngOnInit() {}
}

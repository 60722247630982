import { Injectable, Inject, forwardRef } from '@angular/core';
import { WorkflowContextService } from './workflow-context.service';
import { DataService } from './data.service';

@Injectable()
export class NotificationService {
  constructor(
    @Inject(forwardRef(() => DataService)) private _dataSvc: DataService,
    @Inject(forwardRef(() => WorkflowContextService))
    private _context: WorkflowContextService
  ) {}

  buildNotification(notificationType: string) {}
}

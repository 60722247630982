import { EventEmitter } from '@angular/core';
import { DataEntity } from './data-entity';
import { DateValidatorOptions, ValidationService } from '../../services/';
import { Validators } from '@angular/forms';
import * as moment from 'moment';

export interface DateValueObject {
  Value: string;
  HasTime: boolean;
}

export class DateDataEntity extends DataEntity {
  model: any;
  minimumDate?: Date;
  includeWeekends?: boolean;
  includeTime?: boolean;
  requireTime?: boolean;
  minimumDateType?: string;
  minimumDateOptions?: DateValidatorOptions;
  timeZoneCode: string;
  daylightTimeZoneCode: string;
  timeZoneOffset: number;

  // setting a 1000-year finite limit in both directions.
  // values that can't bind to the datepicker it won't get saved.
  forwardLimit = 365000; // (in days)
  backwardLimit = 365000; // (in days)

  maximumDateType?: string;
  maximumDateOptions?: DateValidatorOptions;

  valueObj: DateValueObject;

  changeStatus: EventEmitter<boolean> = new EventEmitter();

  constructor(options?: Partial<DateDataEntity>) {
    super(options);

    this.dataEntityTypeCode = 'date';
    this.dataEntityTypeDescription = 'Use this entity type to create a date.';
    this.dataEntityTypeName = 'Date';

    if (options) {
      Object.assign(this, options);
    }

    // parse the value into valueObj
    if (
      typeof this.value === 'string' &&
      this.value.trim().indexOf('{') === -1
    ) {
      this.valueObj = {
        Value: this.value,
        HasTime: false
      };
    } else if (
      typeof this.value === 'string' &&
      this.value.trim().indexOf('{') > -1
    ) {
      const val = JSON.parse(this.value as string);

      this.valueObj = {
        Value: val.value === undefined ? val.Value : val.value,
        HasTime: val.hasTime === undefined ? val.HasTime : val.hasTime
      };
    } else if (typeof this.value === 'object' && this.value.value) {
      this.valueObj = {
        Value: this.value.value,
        HasTime: this.value.hasTime
      };
    } else {
      this.valueObj = { Value: '', HasTime: false };
    }

    this.changeStatus = new EventEmitter();
  }

  public static createFormattedValue(
    date: DateValueObject,
    tz: {
      timeZoneCode: string;
      timeZoneOffset: number;
      daylightTimeZoneCode: string;
    }
  ) {
    let value = '';

    if (date && date.Value && moment(date.Value).year() > 1) {
      // handle the difference between entity date and now for DST offset
      const isNowDST = moment().isDST();
      const isEntityDST = moment(date.Value).isDST();
      let tzCode = tz.timeZoneCode;

      if (isEntityDST) {
        tzCode = tz.daylightTimeZoneCode;
      }

      if (isNowDST && !isEntityDST) {
        tz.timeZoneOffset -= 1;
      } else if (!isNowDST && isEntityDST) {
        tz.timeZoneOffset += 1;
      }

      let formatString = 'MM/DD/YYYY';

      if (date.HasTime) {
        formatString = `MMMM Do YYYY, h:mm a [${tzCode}]`;
      }

      const d = moment.utc(date.Value).utcOffset(tz.timeZoneOffset);

      if (d.year() > 0) {
        value = d.format(formatString);
      } else {
        value = '';
      }
    }

    return value;
  }

  public formatValue(): string {
    const tz = {
      timeZoneCode: this.timeZoneCode,
      timeZoneOffset: this.timeZoneOffset,
      daylightTimeZoneCode: this.daylightTimeZoneCode
    };

    return DateDataEntity.createFormattedValue(this.valueObj, tz);
  }

  getFormattedValue(): any {
    return this.formatValue();
  }

  preStringify() {
    if (this.changeStatus && this.changeStatus.unsubscribe) {
      this.changeStatus.unsubscribe();
    }
  }

  getValidators(): any[] {
    const validators: any[] = [];

    if (this.minimumDate) {
      validators.push(ValidationService.DateValidator(null, this.minimumDate));
    }

    const baseValidators = super.getValidators();

    if (baseValidators) {
      for (const bv of baseValidators) {
        if (!validators.find(v => v === bv)) {
          validators.push(bv);
        }
      }
    }

    return validators;
  }
}

<form [formGroup]="form" (submit)="save()">
  <div class="form-group" *ngIf="showName">
    <label class="bmd-label-floating" for="emailName">
      Name (Work, Home, etc.)
    </label>
    <input
      type="text"
      class="form-control required"
      [(ngModel)]="emailAddress.name"
      ngControl="emailName"
      formControlName="emailName"
      name="emailName"
    />
  </div>

  <div class="form-group">
    <label class="bmd-label-floating" for="emailAddress" *ngIf="showHeader">
      Email Address
    </label>
    <wm-email-address
      *ngIf="emailAddress"
      [required]="required"
      [form]="form"
      [(emailModel)]="emailAddress.email"
      [controlName]="emailAddressCtlName"
      name="emailAddress"
    ></wm-email-address>
  </div>

  <div class="row" *ngIf="showButtons">
    <div class="col">
      <button
        type="submit"
        class="btn btn-success btn-raised"
        [disabled]="!emailForm.valid"
      >
        Save
      </button>
      <wm-processing-status
        [status]="'Saving Email Address'"
        #saveEmailAddressStatus
      ></wm-processing-status>
    </div>
    <div class="col">
      <button
        type="button"
        class="btn btn-default btn-raised"
        (click)="cancel()"
      >
        Cancel
      </button>
    </div>
  </div>
</form>

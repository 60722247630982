<form
  [formGroup]="form"
  novalidate
  *ngIf="localActivity && localActivity.model"
>
  <div class="d-flex justify-content-between align-items-start">
    <h6 class="align-self-center">
      Form Data
    </h6>
    <div class="row justify-content-between">
      <div class="col">
        <div class="btn-group">
          <wm-form-activity-data-entities-bulk-actions
            [dataEntities]="
              filterEntities(
                sortEntities(localActivity.model.formLayoutModel.columnEntities)
              )
            "
            [workflow]="workflow"
            [activity]="activity"
            (saveActivity)="onSaved($event)"
          >
          </wm-form-activity-data-entities-bulk-actions>

          <wm-activity-details-modal
            *ngIf="newDEForm"
            [title]="localActivity.model.screenName + ' - New Data Entity'"
            btnText="Add New Data Entity"
            moduleId="modal-new-entity"
            class="float-right"
            [form]="newDEForm"
            (saved)="saveNew()"
            (canceled)="cancelNew()"
            (opened)="loadEditor()"
            [disabled]="_workflowSvc.designerVersionMode"
          >
            <wm-data-entity-editor
              *ngIf="showEditor"
              #newDEEditor
              [workflow]="workflow || _context.workflow"
              [activity]="localActivity"
              [form]="newDEForm"
            ></wm-data-entity-editor>
          </wm-activity-details-modal>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="localActivity.model.formLayoutModel">
    <!-- Single column-->
    <div class="row" *ngIf="localActivity?.model?.formLayout == 0">
      <div class="col">
        <div class="row">
          <div class="col-sm-12 wrapper">
            <ul
              class="list-group"
              *ngIf="localActivity.model.formLayoutModel"
              [dragula]="isFiltered ? null : 'columnEntities'"
              [(dragulaModel)]="
                isFiltered
                  ? null
                  : localActivity.model.formLayoutModel.columnEntities
              "
            >
              <wm-data-entity-item
                *ngFor="
                  let de of filterEntities(
                    sortEntities(
                      localActivity.model.formLayoutModel.columnEntities
                    )
                  )
                "
                [de]="de"
                [workflow]="workflow"
                [activity]="localActivity"
                (deleteEntity)="removeDataEntity(de)"
                (editEntity)="viewDataEntity(de)"
                (saveEntity)="saveDataEntity(de)"
                (saveActivity)="onSaved($event)"
              >
              </wm-data-entity-item>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>

import {
  ListDataEntity,
  ControlType
} from 'src/app/models/data-entities/list-data-entity';
import { FreeFormTextDataEntity } from '../data-entities/free-form-text-data-entity';
import { FormActivity, FormActivityModel } from './form-activity';
import { Utilities } from '../../services/utilities/index';
import { InspectionActivityModel } from './inspection-request-activity';

export class InspectionScheduleActivity extends FormActivity {
  inspectionId: string;
  time: any;
  model: InspectionActivityModel;
  constructor(options?: Partial<InspectionScheduleActivity>) {
    super(options);
    if (options) {
      Object.assign(this, options);
    }

    this.model = new InspectionActivityModel(options ? options.model : null);
  }
}

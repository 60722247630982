import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { WorkflowContextService } from './workflow-context.service';

@Injectable()
export class VersionInterceptor implements HttpInterceptor {
  constructor(private _context: WorkflowContextService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap(response => {
        if (response instanceof HttpResponse) {
          const resp: HttpResponse<any> = response as HttpResponse<any>;

          this._context.checkVersion(resp.headers.get('Version'));
        }
      })
    );
  }
}

import { map } from 'rxjs/operators';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Workflow, Actions } from '../../../../models';
import {
  WorkflowContextService,
  WorkflowService,
  SecurityService
} from '../../../../services';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators
} from '@angular/forms';

@Component({
  selector: 'wm-workflow-list-view',
  templateUrl: './workflow-list-view.component.html',
  styleUrls: ['./workflow-list-view.component.css']
})
export class WorkflowListViewComponent implements OnInit, OnDestroy {
  workflows: Workflow[];
  archivedWorkflows: Workflow[];
  loadGlobal = false;
  clientSub: Subscription;
  canCreateWorkflow: boolean;

  isSysAdmin = false;

  ngOnDestroy() {
    if (this.clientSub) {
      this.clientSub.unsubscribe();
    }
  }

  constructor(
    public _context: WorkflowContextService,
    private _workflowSvc: WorkflowService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _securitySvc: SecurityService
  ) {}

  _loadWorkflows() {
    return this._workflowSvc
      .getWorkflows(this._context.client, false, this.loadGlobal)
      .pipe(
        map(res => {
          this.workflows = res;
        })
      );
  }
  _loadArchivedWorkflows() {
    return this._workflowSvc
      .getWorkflows(this._context.client, true, this.loadGlobal)
      .subscribe(res => {
        this.archivedWorkflows = res.filter(w => w.isArchived);
      });
  }

  loadWorkflows() {
    const func = () => {
      this._loadWorkflows().subscribe(() => {
        this._loadArchivedWorkflows();
      });
    };

    if (this._context.client || this.loadGlobal) {
      func();
    } else {
      this.clientSub = this._context.client$.subscribe(res => {
        if (this._context.client || this.loadGlobal) {
          func();
        }
      });
    }
  }

  createWorkflow() {
    this._router.navigate(
      this.loadGlobal
        ? ['admin', 'global', 'workflows', 'add']
        : ['admin', 'jurisdiction', this._context.client.id, 'workflows', 'add']
    );
  }

  isSystemAdmin() {
    this._securitySvc.isSystemAdministrator().subscribe(result => {
      this.isSysAdmin = result;
    });
  }

  restoreWorkflow(e) {
    this.loadWorkflows();
  }

  copyWorkflow(e) {
    this.loadWorkflows();
  }

  enableWorkflow(e) {
    const wf = this.workflows.find(w => w.id == e);

    if (wf) {
      wf.isEnabled = true;
    }
  }

  disableWorkflow(e) {
    const wf = this.workflows.find(w => w.id == e);

    if (wf) {
      wf.isEnabled = false;
    }
  }

  publishWorkflow(e) {
    this.loadWorkflows();
  }

  restoreCheckpoint(e) {
    this.loadWorkflows();
  }

  archiveWorkflow(e) {
    this.loadWorkflows();
  }

  ngOnInit() {
    this.isSystemAdmin();

    this._route.data.subscribe(d => {
      this.loadGlobal = d.loadGlobal;
      this._securitySvc
        .isLoginEntitled(Actions.WORKFLOW_ADD)
        .subscribe(result => {
          this.canCreateWorkflow = result;
        });

      this.loadWorkflows();
    });
  }
}

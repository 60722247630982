import { PaymentProcessorConfigurationBase } from '../payment-processor-configuration-base';

export class CertifiedPaymentsPaymentProcessorConfiguration extends PaymentProcessorConfigurationBase {
  soapurl: string;

  constructor(
    options?: Partial<CertifiedPaymentsPaymentProcessorConfiguration>
  ) {
    super(options);

    if (options) {
      Object.assign(this, options);
    }
  }
}

import {
  ListDataEntity,
  ControlType
} from 'src/app/models/data-entities/list-data-entity';
import { Utilities } from '../../services/utilities/index';
import { InspectionVM } from '../inspections';
import { FormActivity, FormActivityModel } from './form-activity';
export class InspectionActivityModel extends FormActivityModel {
  inspection: InspectionVM;

  constructor(options: { [key: string]: any }) {
    super(options);
    this.inspection = new InspectionVM();

    if (options && options.inspection) {
      Object.assign(this.inspection, options.inspection);
    }
  }
}
export class InspectionSimpleActivity extends FormActivity<
  InspectionActivityModel
> {
  inspectorOptions: any;
  defaultOption: string;
  activtyInspectorName: string;
  showActivityInspectorName: boolean;
  constructor(options?: Partial<InspectionSimpleActivity>) {
    super(options);

    if (options) {
      Object.assign(this, options);
    }

    this.model = new InspectionActivityModel(options ? options.model : null);
  }
}

import { Component, OnInit, EventEmitter } from '@angular/core';
import { ActivityView } from 'src/app/views/master-views/app.view/app.view.component';
import { WorkflowContextService } from 'src/app/services';
import { FormGroup } from '@angular/forms';
import { Activity, ActivityModel } from 'src/app/models/activities';
import { InspectionScheduleActivity } from 'src/app/models/activities/inspection-schedule-activity';
import { InspectionStatus } from 'src/app/models';

@Component({
  selector: 'wm-complete-inspection-activity-view',
  templateUrl: './complete-inspection-activity-view.component.html',
  styleUrls: ['./complete-inspection-activity-view.component.css']
})
export class CompleteInspectionActivityViewComponent extends ActivityView
  implements OnInit {
  activity: InspectionScheduleActivity;
  stirngDate: Date;
  public scheduleTime = '';

  stringDate: Date;
  d: string;
  public status: string;

  constructor() {
    super();
  }
  private pad(i: number): string {
    return i < 10 ? `0${i}` : `${i}`;
  }

  ngOnInit() {
    this.status = InspectionStatus[this.activity.model.inspection.status];
  }
}

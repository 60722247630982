<div class="row">
  <div class="col">
    <h3>External Data Connections</h3>
  </div>
  <div class="col">
    <button
      class="btn btn-raised btn-success float-right"
      (click)="addExternalDataConnection()"
    >
      New External Connection
    </button>
  </div>
</div>

<!-- TODO: Incomplete search -->
<!-- <div class="row">
  <wm-external-data-connections-search
    (searchStarted)="externalDataConnections = null"
    (searchFinished)="externalDataConnections = $event"
  >
  </wm-external-data-connections-search>
</div> -->

<wm-external-data-connections-list
  [allowSelect]="true"
  [externalDataConnections]="externalDataConnections"
>
</wm-external-data-connections-list>

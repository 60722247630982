import {
  Component,
  OnInit,
  Input,
  Inject,
  forwardRef,
  ViewChild
} from '@angular/core';
import { ActivityEditorBaseComponent } from '../activity-editor-base/activity-editor-base.component';
import { CompleteWorkflowActivity } from '../../../../models/activities';
import { ActivatedRoute } from '@angular/router';
import { WorkflowContextService, WorkflowService } from '../../../../services';
import { ModalConfirmComponent } from './../../../system/modal-confirm/modal-confirm.component';

@Component({
  selector: 'wm-complete-workflow-activity-editor',
  templateUrl: './complete-workflow-activity-editor.component.html',
  styleUrls: ['./complete-workflow-activity-editor.component.css']
})
export class CompleteWorkflowActivityEditorComponent
  extends ActivityEditorBaseComponent
  implements OnInit {
  @Input() activity: CompleteWorkflowActivity;
  activityId: string;

  @ViewChild('autoSubmitModal', { static: true }) autoSubmitModal: ModalConfirmComponent;

  constructor(
    @Inject(forwardRef(() => WorkflowService))
    private _workflowSvc: WorkflowService,
    @Inject(forwardRef(() => WorkflowContextService))
    private _context: WorkflowContextService,
    private _route: ActivatedRoute
  ) {
    super();
  }

  ngOnInit() {}

  autoSubmitWarn() {
    if (this.activity.model.autoSubmit === true) {
      setTimeout(() => {
        this.autoSubmitModal.open();
      }, 10);
    }
  }

  cancelAutoSubmit() {
    this.activity.model.autoSubmit = false;
  }
}

import { ActivityView } from 'src/app/views/master-views/app.view/app.view.component';
import { Component, OnInit } from '@angular/core';
import { InitiateWorkflowActivity } from 'src/app/models/activities/initiate-workflow-activity';
import { Router } from '@angular/router';

@Component({
  selector: 'wm-initiate-workflow-view',
  templateUrl: './initiate-workflow-view.component.html',
  styleUrls: ['./initiate-workflow-view.component.css']
})
export class InitiateWorkflowViewComponent extends ActivityView implements OnInit {
  initiateActivity: InitiateWorkflowActivity;

  constructor(private router: Router) {
    super();
  }

  launchChildSummary() {
    if (this.initiateActivity.model.callingWorkflowApplicationId) {
      this.router.navigate([
        '/application/workflow-application-summary',
        this.initiateActivity.model.callingWorkflowApplicationId
      ]);
    }
  }

  ngOnInit() {
    this.initiateActivity = this.activity as InitiateWorkflowActivity;
  }
}

<wm-activity-details-modal
  [title]="activity.model.screenName"
  [btnText]="'Edit Email'"
  [moduleId]="activity.id"
  [form]="activityForm"
  (opened)="showDetails($event)"
  (saved)="cleanup()"
  (canceled)="cleanup()"
>
  <form [formGroup]="activityForm" *ngIf="activityForm">
    <div class="row">
      <div class="col-8">
        <h4>Compose Email</h4>

        <div class="switch">
          <label>
            <input
              type="checkbox"
              [(ngModel)]="activity.model.emailApplicant"
              formControlName="applicant"
            />
            Send email to applicant?
          </label>
        </div>

        <h6>Recipients</h6>
        <ng-container *ngIf="isGlobal">
          <div>
            You cannot add recipients to Email Activities in a Global Workflow
          </div>
        </ng-container>
        <ng-container *ngIf="!isGlobal">
          <form [formGroup]="roleRecipientForm">
            <div class="form-row">
              <div class="col">
                <label class="bmd-label-static" for="role">Role</label>
                <select
                  class="form-control"
                  id="role"
                  [(ngModel)]="selectedRole"
                  formControlName="role"
                >
                  <option></option>
                  <option *ngFor="let r of availableRoles" [value]="r.id">{{
                    r.name
                  }}</option>
                </select>
              </div>
              <div class="col-auto align-self-center">
                <button
                  type="button"
                  class="btn btn-raised btn-primary"
                  [disabled]="roleRecipientForm.invalid"
                  (click)="addRole()"
                >
                  Add
                </button>
              </div>
            </div>
          </form>
          <form [formGroup]="toEmailForm">
            <div class="form-row">
              <div class="col">
                <wm-data-entity-autocomplete
                  *ngIf="!refreshing"
                  [types]="recipientDETypes"
                  [form]="toEmailForm"
                  [activity]="activity"
                  id="newEmailAddress"
                  [required]="false"
                  [title]="'Recipient'"
                  [workflow]="workflow"
                  [serverValidator]="workflowSvc.validateEmailFields"
                  [columns]="60"
                  #emailRecipient
                >
                </wm-data-entity-autocomplete>
              </div>
              <div class="col-2">
                <label class="bmd-label-static" for="emailRecipientType">
                  Type
                </label>
                <select
                  class="form-control"
                  id="emailRecipientType"
                  formControlName="newEmailType"
                >
                  <option value="to" selected>To</option>
                  <option value="cc">CC</option>
                  <option value="bcc">BCC</option>
                </select>
              </div>
              <div class="col-auto align-self-center">
                <button
                  type="button"
                  class="btn btn-raised btn-primary"
                  [disabled]="!toEmailForm.valid"
                  (click)="addEmail()"
                >
                  Add
                </button>
              </div>
            </div>
          </form>

          <ul class="list-group">
            <li
              class="list-group-item d-flex justify-content-between align-items-start"
              *ngFor="let toAddress of activity.model.to"
            >
              <span class="align-self-center">{{ toAddress }}</span>

              <div class="d-flex align-items-center">
                <span class="pr-3">To</span>
                <a style="cursor: pointer" (click)="removeToAddress(toAddress)">
                  <i class="material-icons">
                    delete
                  </i>
                </a>
              </div>
            </li>
            <li
              class="list-group-item d-flex justify-content-between align-items-start"
              *ngFor="let bccAddress of activity.model.bcc"
            >
              <span class="align-self-center">{{ bccAddress }}</span>

              <div class="d-flex align-items-center">
                <span class="pr-3">BCC</span>
                <a
                  style="cursor: pointer"
                  (click)="removeBCCAddress(bccAddress)"
                >
                  <i class="material-icons">
                    delete
                  </i>
                </a>
              </div>
            </li>
            <li
              class="list-group-item d-flex justify-content-between align-items-start"
              *ngFor="let ccAddress of activity.model.cc"
            >
              <span class="align-self-center">{{ ccAddress }}</span>

              <div class="d-flex align-items-center">
                <span class="pr-3">CC</span>
                <a style="cursor: pointer" (click)="removeCCAddress(ccAddress)">
                  <i class="material-icons">
                    delete
                  </i>
                </a>
              </div>
            </li>
            <li
              class="list-group-item d-flex justify-content-between align-items-start"
              *ngFor="let role of activity.model.roles"
            >
              <span class="align-self-center">Role: {{ role.name }}</span>

              <div class="d-flex align-items-center">
                <span class="pr-3">To</span>
                <a style="cursor: pointer" (click)="removeRole(role)">
                  <i class="material-icons">
                    delete
                  </i>
                </a>
              </div>
            </li>
          </ul>
        </ng-container>
        <div class="form-group">
          <label class="bmd-label-floating" for="subject">Subject</label>
          <input
            type="text"
            class="form-control"
            #emailSubject
            [(ngModel)]="activity.model.subject"
            formControlName="subject"
            name="subject"
          />
        </div>

        <div class="form-group">
          <label class="bmd-label-static" for="body">Body</label>
          <ckeditor
            #emailBody
            [editor]="Editor"
            [config]="editorConfig"
            (change)="onChange($event)"
            formControlName="body"
          ></ckeditor>
        </div>

        <div class="form-group">
          <b><p>Warning: Do not insert private data entities or images, such as credit card numbers, SSN’s, DL numbers, medical info, ID images, etc.  Do not attach files containing similar private information.</p></b>
          <h6>
            Attachments
          </h6>

          <ul
            class="list-group"
            *ngIf="
              activity &&
              (activity.model.attachments &&
                activity.model.attachments.length > 0)
            "
          >
            <li
              class="list-group-item d-flex justify-content-between align-items-start"
              *ngFor="let de of activity.model.attachments"
            >
              {{ de.name }}

              <a style="cursor: pointer" (click)="removeAttachment(de)">
                <i class="material-icons">
                  delete
                </i>
              </a>
            </li>
          </ul>
          <div
            *ngIf="
              activity &&
              (!activity.model.attachments ||
                activity.model.attachments.length === 0)
            "
          >
            <i>Nothing will be attached to this email</i>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <h4>Data</h4>

        <div class="form-group">
          <ul class="list-group">
            <li
              class="list-group-item d-flex justify-content-between align-items-start"
              *ngFor="let de of entities"
            >
              <span class="align-self-center">{{ de.templateCode }}</span>

              <div ngbDropdown>
                <a
                  style="cursor: pointer"
                  [id]="['dropdown-' + de.templateCode]"
                  ngbDropdownToggle
                >
                  <i class="material-icons">
                    add
                  </i>
                </a>
                <div
                  ngbDropdownMenu
                  [attr.aria-labelledby]="['dropdown-' + de.templateCode]"
                >
                  <button
                    ngbDropdownItem
                    style="cursor: pointer"
                    (click)="insertSubject(de)"
                  >
                    Subject
                  </button>
                  <button
                    ngbDropdownItem
                    style="cursor: pointer"
                    (click)="insertBody(de)"
                  >
                    Body
                  </button>
                  <button
                    ngbDropdownItem
                    style="cursor: pointer"
                    (click)="insertRecipient(de)"
                  >
                    Recipient
                  </button>
                </div>
              </div>
            </li>
          </ul>
        </div>

        <div class="form-group">
          <h4>
            Attachments
          </h4>
          <ul class="list-group">
            <li
              class="list-group-item"
              *ngIf="
                (!attachmentEntities || attachmentEntities.length === 0) &&
                (!activity ||
                  !activity.model.attachments ||
                  activity.model.attachments.length === 0)
              "
            >
              <i>There aren't any documents to attach to the email</i>
            </li>
            <li
              class="list-group-item"
              *ngIf="
                (!attachmentEntities || attachmentEntities.length === 0) &&
                (activity &&
                  activity.model.attachments &&
                  activity.model.attachments.length > 0)
              "
            >
              <i>
                All of the available documents will be attached to this email.
              </i>
            </li>
            <li
              class="list-group-item d-flex justify-content-between align-items-start"
              *ngFor="let de of attachmentEntities"
            >
              <span class="align-self-center">{{ de.name }}</span>

              <a style="cursor: pointer" (click)="attachDoc(de)">
                <i class="material-icons">
                  add
                </i>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </form>
</wm-activity-details-modal>

import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Client } from 'src/app/models';
import {
  DataSet,
  WorkflowApplicationReportDataSet
} from 'src/app/models/report-data-set';
import {
  ClientService,
  Utilities,
  WorkflowContextService,
  WorkflowService
} from 'src/app/services';
import { ModalConfirmComponent } from '../../system/modal-confirm/modal-confirm.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'wm-dataset-list',
  templateUrl: './dataset-list.component.html',
  styleUrls: ['./dataset-list.component.css']
})
export class DatasetListComponent implements OnInit, OnDestroy {
  constructor(
    private _workflowSvc: WorkflowService,
    public context: WorkflowContextService,
    private _router: Router,
    private _toastr: ToastrService,
    private _clientSvc: ClientService
  ) {}

  ngOnDestroy(): void {
    if (this.clientSub) {
      this.clientSub.unsubscribe();
      this.clientSub = null;
    }
  }

  dataSets: DataSet[];
  EMPTY_GUID = Utilities.EMPTY_GUID;

  clients: Client[];
  targetClientId?: string;
  targetName: string;
  copyDataSetId: string;
  targetForm: UntypedFormGroup;
  copyingDataSet = false;
  @ViewChild('copyDataSetModal', { static: false }) copyDataSetModal: ModalConfirmComponent;
  clientSub: Subscription;

  ngOnInit() {
    this.loadDataSets();
    this._clientSvc.getAdminClients().subscribe(c => {
      this.clients = c;
    });

    this.targetForm = new UntypedFormGroup({
      targetClientId: new UntypedFormControl('', Validators.nullValidator),
      targetName: new UntypedFormControl('', Validators.required)
    });
  }

  loadDataSets() {
    const load = clientId => {
      this._workflowSvc.getDataSets(clientId).subscribe(dataSets => {
        this.dataSets = dataSets;
      });
    };

    if (this.context.client.id) {
      load(this.context.client.id);
    } else {
      this.clientSub = this.context.client$.subscribe(() => {
        load(this.context.client.id);
      });
    }
  }

  deleteDataSet(dataSet: DataSet) {
    this._workflowSvc.deleteDataSet(dataSet.id).subscribe(result => {
      if (result) {
        const idx = this.dataSets.findIndex(ds => ds.id == dataSet.id);
        if (idx > -1) {
          this.dataSets.splice(idx, 1);
          this._toastr.success(`Data set "${dataSet.name}" has been deleted.`);
        }
      }
    });
  }

  startDataSetCopy(ds: DataSet) {
    if (this.context.client) {
      this.targetClientId = this.context.client.id;
    }
    this.targetName = `Copy Of - ${ds.name}`;

    this.copyDataSetId = ds.id;
  }

  copyDataSet(dataSet: DataSet) {
    this.copyingDataSet = true;
    this._workflowSvc
      .copyDataSet({
        dataSetId: this.copyDataSetId,
        targetClientId: this.targetClientId,
        targetName: this.targetName
      })
      .subscribe(() => {
        this.copyingDataSet = false;
        //this.copyDataSetModal.cancel();
        this._toastr.success('Data Set Copied');
        this.loadDataSets();
      });
  }

  publishDataSet(dataSet: DataSet) {
    this._workflowSvc.publishDataSet(dataSet.id).subscribe(result => {
      if (result) {
        this.loadDataSets();
        this._toastr.success(`Data set "${dataSet.name}" has been published.`);
      }
    });
  }
}

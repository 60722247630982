import { Component, OnInit, ComponentFactoryResolver } from '@angular/core';
import { DataEntityViewComponent } from '../../data-entity-view/data-entity-view.component';
import { TodayDataEntity } from 'src/app/models/data-entities/today-data-entity';

@Component({
  selector: 'wm-today-data-entity-view',
  templateUrl: './today-data-entity-view.component.html',
  styleUrls: ['./today-data-entity-view.component.css']
})
export class TodayDataEntityViewComponent extends DataEntityViewComponent
  implements OnInit {
  constructor(componentFactoryResolver: ComponentFactoryResolver) {
    super(componentFactoryResolver);
  }
  todayEntity: TodayDataEntity;

  ngOnInit() {
    this.todayEntity = <TodayDataEntity>this.entity;
  }
}

import { ActivityStatus } from './../../../../../models/activities/activity';
import { ActivityView } from 'src/app/views/master-views/app.view/app.view.component';
import { Component, OnInit } from '@angular/core';
import { CallWorkflowActivity } from 'src/app/models/activities/call-workflow-activity';
import { Router } from '@angular/router';

@Component({
  selector: 'wm-call-workflow-input',
  templateUrl: './call-workflow-input.component.html',
  styleUrls: ['./call-workflow-input.component.css']
})
export class CallWorkflowInputComponent extends ActivityView implements OnInit {
  callActivity: CallWorkflowActivity;
  constructor() {
    super();
  }
  ActivityStatus = ActivityStatus;

  launchChild() {
    if (this.callActivity.model.callingWorkflowApplicationId) {
      this.switchApplication.emit(
        this.callActivity.model.callingWorkflowApplicationId
      );
    }
  }

  ngOnInit() {
    this.callActivity = this.activity as CallWorkflowActivity;
  }
}

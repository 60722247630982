import { FilterBuilderFilterFlat } from './filter-builder';

export interface SavedColumn {
  name: string;
  header: string;
}

export interface FilterSet {
  filters: FilterBuilderFilterFlat[];
  columns?: SavedColumn[];
  exportColumns?: SavedColumn[];
}

export interface ColumnSet {
  columns?: SavedColumn[];
  exportColumns?: SavedColumn[];
}

export class StoredFilter {
  id?: string;
  name: string;
  type: string;
  clientId: string;
  filters: string;
  columns?: string;
  exportColumns?: string;
}

export class SavedFilter {
  id?: string;
  name: string;
  type: string;
  clientId: string;
  filters: FilterBuilderFilterFlat[];
  columns?: SavedColumn[];
  exportColumns?: SavedColumn[];

  static store(filter: SavedFilter): StoredFilter {
    return {
      id: filter.id,
      name: filter.name,
      type: filter.type,
      clientId: filter.clientId,
      filters: JSON.stringify(filter.filters),
      columns: filter.columns ? JSON.stringify(filter.columns) : null,
      exportColumns: filter.exportColumns
        ? JSON.stringify(filter.exportColumns)
        : null
    };
  }

  static restore(filter: StoredFilter): SavedFilter {
    const savedFilter: any = { ...filter };

    savedFilter.filters =
      typeof savedFilter.filters === 'string' ||
      savedFilter.filters instanceof String
        ? JSON.parse(savedFilter.filters)
        : savedFilter.filters;
    savedFilter.columns =
      typeof savedFilter.columns === 'string' ||
      savedFilter.columns instanceof String
        ? JSON.parse(savedFilter.columns)
        : savedFilter.columns;
    savedFilter.exportColumns =
      typeof savedFilter.exportColumns === 'string' ||
      savedFilter.exportColumns instanceof String
        ? JSON.parse(savedFilter.exportColumns)
        : savedFilter.exportColumns;

    return savedFilter;
  }
}

export type GetFilter = () => Promise<FilterSet>;
export type ApplyFilter = (filter: FilterSet) => Promise<void>;

export interface IFilterStorageAdapter {
  upsert(id: string, filter: SavedFilter): Promise<SavedFilter>;
  list(clientId: string, type: string): Promise<SavedFilter[]>;
  destroy(id: string): Promise<void>;
}

<!-- TODO: This could use some more UX work... -->
<form [formGroup]="form">
  <h3>External Connection Detail</h3>

  <app-loader *ngIf="!externalDataConnection"></app-loader>

  <div class="row" *ngIf="externalDataConnection">
    <div class="col">
      <div
        class="form-group"
        [class.has-error]="
          connectionTypeCtl.touched && !connectionTypeCtl.valid
        "
      >
        <label class="bmd-label-floating" for="connectionType">
          Connection Type
        </label>
        <select
          class="form-control required"
          [(ngModel)]="externalDataConnection.connectionType"
          [formControl]="connectionTypeCtl"
          (change)="connectionTypeChange(externalDataConnection.connectionType)"
          name="connectionType"
        >
          <option value="ParcelData">Parcel Data</option>
          <option value="SecureFTP">Secure FTP</option>
          <!-- <option value="Other">HTTP</option> -->
        </select>
      </div>
    </div>
  </div>

  <div
    *ngIf="
      externalDataConnection &&
      externalDataConnection.connectionType == 'SecureFTP'
    "
  >
    <div
      class="form-group"
      [class.has-error]="nameCtl.touched && !nameCtl.valid"
    >
      <label class="bmd-label-floating" for="conName">Name</label>
      <input
        type="text"
        class="form-control required"
        [(ngModel)]="externalDataConnection.name"
        [formControl]="nameCtl"
        name="conName"
      />
      <wm-control-message [controlName]="'name'"></wm-control-message>
    </div>
    <div class="form-group">
      <label class="bmd-label-floating" for="host">
        Host
      </label>
      <input
        type="text"
        class="form-control required"
        formControlName="host"
        name="host"
        [(ngModel)]="externalDataConnection.host"
      />
    </div>
    <div class="form-group">
      <label class="bmd-label-floating" for="username">
        Username
      </label>
      <input
        type="text"
        class="form-control required"
        formControlName="username"
        name="username"
        [(ngModel)]="externalDataConnection.username"
      />
    </div>
    <div class="form-group">
      <label class="bmd-label-floating" for="password">
        Password
      </label>
      <input
        type="text"
        class="form-control required"
        formControlName="password"
        name="password"
        [(ngModel)]="externalDataConnection.password"
      />
    </div>
    <div class="form-group">
      <label class="bmd-label-floating" for="path">
        Path
      </label>
      <input
        type="text"
        class="form-control required"
        formControlName="path"
        name="path"
        [(ngModel)]="externalDataConnection.path"
      />
    </div>
    <div class="form-group">
      <button class="btn btn-default btn-raised" (click)="testSFTPConnection()">
        Test Connection
      </button>
    </div>
  </div>
  <div
    *ngIf="
      externalDataConnection &&
      externalDataConnection.connectionType === 'Other'
    "
  >
    <div class="row">
      <div class="col">
        <div
          class="form-group"
          [class.has-error]="nameCtl.touched && !nameCtl.valid"
        >
          <label class="bmd-label-floating" for="conName">Name</label>
          <input
            type="text"
            class="form-control required"
            [(ngModel)]="externalDataConnection.name"
            [formControl]="nameCtl"
            name="conName"
          />
          <wm-control-message [controlName]="'name'"></wm-control-message>
        </div>
      </div>
      <div class="col">
        <div
          class="form-group"
          [class.has-error]="methodCtl.touched && !methodCtl.valid"
        >
          <label class="bmd-label-floating" for="conMethod">Method</label>
          <select
            class="form-control"
            [(ngModel)]="externalDataConnection.method"
            [formControl]="methodCtl"
            name="conMethod"
          >
            <option>POST</option>
            <option>GET</option>
          </select>
          <wm-control-message [controlName]="'method'"></wm-control-message>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <div
          class="form-group"
          [class.has-error]="urlCtl.touched && !urlCtl.valid"
        >
          <label class="bmd-label-floating" for="conUrl">URL</label>
          <input
            type="text"
            class="form-control required"
            [(ngModel)]="externalDataConnection.url"
            [formControl]="urlCtl"
            name="conUrl"
          />
          <wm-control-message [controlName]="'url'"></wm-control-message>
        </div>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col">
        <h5>Search Parameters</h5>
        <p>
          A key-value pair of search parameters consisting of the parameter name
          and a user facing label.
        </p>

        <div
          class="form-inline"
          *ngFor="let param of externalDataConnection.parameters"
        >
          <label class="sr-only" for="paramKey">Parameter key</label>
          <input
            type="text"
            class="form-control mb-2 mr-sm-2"
            [(ngModel)]="param.name"
            [ngModelOptions]="{ standalone: true }"
            id="paramKey"
            name="paramKey"
          />

          <div class="input-group mb-2 mr-sm-2">
            <label class="sr-only" for="paramLabel">Parameter label</label>
            <input
              type="text"
              class="form-control"
              [(ngModel)]="param.label"
              [ngModelOptions]="{ standalone: true }"
              id="paramLabel"
              name="paramLabel"
            />

            <div class="input-group-append">
              <button
                type="button"
                class="btn btn-raised btn-default btn-sm"
                (click)="removeSearchParam(param)"
              >
                <i class="material-icons">
                  remove
                </i>
              </button>
            </div>
          </div>
        </div>

        <button
          type="button"
          class="btn btn-raised btn-default btn-sm"
          (click)="addSearchParam($event)"
        >
          <i class="material-icons">
            add
          </i>
        </button>
      </div>

      <div class="col">
        <h5>Result Fields</h5>
        <p>
          A key-value pair of result fields consisting of the field name and a
          user facing label.
        </p>

        <div
          class="form-inline"
          *ngFor="let field of externalDataConnection.resultFields"
        >
          <label class="sr-only" for="fieldKey">Field key</label>
          <input
            type="text"
            class="form-control mb-2 mr-sm-2"
            [(ngModel)]="field.field"
            [ngModelOptions]="{ standalone: true }"
            id="fieldKey"
            name="fieldKey"
          />

          <div class="input-group mb-2 mr-sm-2">
            <label class="sr-only" for="fieldLabel">Field label</label>
            <input
              type="text"
              class="form-control"
              [(ngModel)]="field.alias"
              [ngModelOptions]="{ standalone: true }"
              id="fieldLabel"
              name="fieldLabel"
            />

            <div class="input-group-append">
              <button
                type="button"
                class="btn btn-raised btn-default btn-sm"
                (click)="removeResultField(field)"
              >
                <i class="material-icons">
                  remove
                </i>
              </button>
            </div>
          </div>
        </div>

        <button
          class="btn btn-raised btn-default btn-sm"
          (click)="addResultField($event)"
        >
          <i class="material-icons">
            add
          </i>
        </button>
      </div>
    </div>
  </div>

  <div
    class="row"
    *ngIf="
      externalDataConnection &&
      externalDataConnection.connectionType === 'ParcelData'
    "
  >
    <div class="col">
      <app-loader *ngIf="!parcelDataSources"></app-loader>
      <div
        *ngIf="parcelDataSources"
        class="form-group"
        [class.has-error]="
          parcelDataSourceIdCtl.touched && !parcelDataSourceIdCtl.valid
        "
      >
        <label class="bmd-label-floating" for="sourceId">Source:</label>
        <select
          class="form-control required"
          [(ngModel)]="parcelDataSourceId"
          [formControl]="parcelDataSourceIdCtl"
          name="sourceId"
        >
          <option></option>
          <option *ngFor="let s of parcelDataSources" [value]="s.sourceId">
            {{ s.name }}
          </option>
        </select>
        <div
          class="text-danger"
          *ngIf="
            parcelDataSourceExists && (externalDataConnectionId || '') == ''
          "
        >
          A Parcel Data Source has already been configured for this client. Edit
          that connection if you want to change the source of the Parcel Data.
        </div>
      </div>
    </div>
  </div>

  <div class="row mt-5" *ngIf="this.externalDataConnection">
    <div class="col">
      <button
        type="button"
        class="btn btn-raised btn-success"
        [disabled]="!isFormValid()"
        (click)="save()"
      >
        Save
      </button>
    </div>
    <div class="col">
      <div class="float-right">
        <wm-modal-confirm
          [commandText]="'Delete'"
          [showButtonText]="true"
          [title]="'Delete Connection'"
          (canceled)="cancelDelete($event)"
          (accepted)="deleteConnection($event)"
          *ngIf="isNotNew()"
          btnColor="danger"
          class="mx-2"
        >
          <p>
            Are you sure you want to delete this connection ({{
              externalDataConnection?.name
            }})?
          </p>
          <p>Deleting the connection will do the following things:</p>
          <ul>
            <li>
              Make the following workflows that reference this connection
              invalid and need modification.
            </li>
          </ul>
        </wm-modal-confirm>

        <button
          type="button"
          class="btn btn-raised btn-default mx-2"
          (click)="cancel()"
        >
          Cancel
        </button>
      </div>
    </div>
  </div>
</form>

import {
  Component,
  OnInit,
  Input,
  Inject,
  forwardRef,
  ViewChild,
  AfterViewInit,
  ChangeDetectorRef,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import {
  FormActivity,
  FormLayoutModes,
  FormLayoutModel,
  TwoColumnFormLayoutModel,
  DataEntityLayoutModel,
  SingleColumnFormLayoutModel
} from '../../../../models/activities';
import { DataEntity, ParentReference } from '../../../../models/data-entities';
import {
  WorkflowService,
  WorkflowContextService,
  Utilities
} from '../../../../services';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivityEditorBaseComponent } from '../activity-editor-base/activity-editor-base.component';
import * as autoScroll from 'dom-autoscroller';
import { Workflow } from '../../../../models';
import { DragulaService } from 'ng2-dragula';
import { DataEntityEditorComponent } from '../../data-entities/data-entity-editor/data-entity-editor.component';
import { ToastrService } from 'ngx-toastr';
import { BulkAction } from '../../../system/datatable/datatable.component';
@Component({
  selector: 'wm-form-activity-editor',
  templateUrl: './form-activity-editor.component.html',
  styleUrls: ['./form-activity-editor.component.css']
})
export class FormActivityEditorComponent extends ActivityEditorBaseComponent
  implements OnInit, AfterViewInit, OnChanges {
  @Input() activity: FormActivity;
  localActivity: FormActivity;
  showNewEditor = false;
  newDEForm: UntypedFormGroup;
  newDE: DataEntity;
  @ViewChild('newDEEditor', { static: false })
  newEditor: DataEntityEditorComponent;
  @Input() workflow: Workflow;
  @Input() includedDataEntityTypes?: string[];
  @Input() excludedDataEntityTypes?: string[];
  isFiltered: boolean;
  formLayouts: { display: string; value: number }[];
  selected: string[];

  showEditor = false;

  ngAfterViewInit(): void {
    this._dragulaService.dropModel().subscribe(value => {
      this.onDropDataEntity(value);
    });

    this._ref.detectChanges();
  }

  initFormLayouts() {
    const keys = Object.keys(FormLayoutModes);

    this.formLayouts = keys.slice(keys.length / 2).map((index, layout) => {
      return {
        value: layout,
        display: Utilities.addSpaceToTitleCase(FormLayoutModes[layout])
      };
    });
  }

  changeLayout(e) {
    this.localActivity.model.formLayoutModel = FormLayoutModel.getModeModel(
      this.localActivity,
      parseFloat(this.localActivity.model.formLayout.toString()),
      this.localActivity.model.formLayoutModel
    );
  }

  constructor(
    @Inject(forwardRef(() => WorkflowService))
    public _workflowSvc: WorkflowService,
    @Inject(forwardRef(() => WorkflowContextService))
    public _context: WorkflowContextService,
    private toastr: ToastrService,
    private _fb: UntypedFormBuilder,
    private _dragulaService: DragulaService,
    private _ref: ChangeDetectorRef
  ) {
    super();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['activity']) {
      this.localActivity = this.activity;
    }
  }

  entitiesToExcluded() {
    if (this.localActivity.model.formLayoutModel) {
      return (<TwoColumnFormLayoutModel>(
        this.localActivity.model.formLayoutModel
      )).leftColumnEntities.concat(
        (<TwoColumnFormLayoutModel>this.localActivity.model.formLayoutModel)
          .rightColumnEntities
      );
    }
  }

  removeDataEntity(de, parentList) {
    this.localActivity.model.formLayoutModel.removeEntity(de);
    this.saved.emit(this.localActivity);
  }

  filterEntities(entities: DataEntityLayoutModel[]) {
    this.isFiltered = false;
    let filteredEntities = entities;

    if ((this.includedDataEntityTypes || []).length > 0) {
      filteredEntities = entities.filter(
        de =>
          this.includedDataEntityTypes.indexOf(de.entity.dataEntityTypeCode) >
          -1
      );
      this.isFiltered = entities.length !== filteredEntities.length;
    } else if ((this.excludedDataEntityTypes || []).length > 0) {
      filteredEntities = entities.filter(
        de =>
          this.excludedDataEntityTypes.indexOf(de.entity.dataEntityTypeCode) ===
          -1
      );
      this.isFiltered = entities.length !== filteredEntities.length;
    }

    return filteredEntities;
  }
  sortEntities(entities: DataEntityLayoutModel[]) {
    return entities.sort((a, b) => a.displayOrder - b.displayOrder);
  }

  viewDataEntity(de: DataEntityLayoutModel) {}

  resetNew() {
    this.showEditor = false;
    this.newDEForm = this._fb.group({});
  }

  saveNew(e: any) {
    this.localActivity.addEntity(this.newEditor.dataEntity);

    this.saved.emit(this.localActivity);
    this.resetNew();
  }

  cancelNew() {
    this.showEditor = false;
  }

  loadEditor() {
    this.showEditor = true;
    this.newDEForm = this._fb.group({});
  }

  onSaved(e: any) {
    this.saved.emit(e);
  }

  saveDataEntity(e: any) {
    this.saved.emit(this.localActivity);
  }

  onDropDataEntity(args: any) {
    for (let i = 0; i < args.sourceModel.length; i++) {
      if (args.sourceModel[i]) {
        args.sourceModel[i].displayOrder = i + 1;
      }
    }
  }

  ngOnInit() {
    this.localActivity = this.activity;

    if (!this.workflow) {
      this.workflow = this._context.workflow;
    }
    this.initFormLayouts();

    // set the parent reference to the activity in the event that the parent reference is not set because it is used down below in the validation logic
    this.activity.model.getEntities().forEach(de => {
      if (!de) {
        return;
      }
      de.parent = new ParentReference({ id: this.activity.id });
    });

    this.newDEForm = this._fb.group({});

    if (!this.localActivity.model.formLayoutModel) {
      if (this.localActivity.model.formLayout === 0) {
        this.localActivity.model.formLayoutModel = new SingleColumnFormLayoutModel(
          this.localActivity
        );
      } else {
        this.localActivity.model.formLayoutModel = new TwoColumnFormLayoutModel(
          this.localActivity
        );
      }
    }

    this.changeLayout(null);

    setTimeout(() => {
      const scroll = autoScroll([window], {
        margin: 20,
        maxSpeed: 5,
        scrollWhenOutside: true,
        autoScroll: function() {
          // Only scroll when the pointer is down.
          return this.down;
        }
      });
    }, 3000);
  }
}

import { Role } from './role';
import { Action } from './actions';

export class SecurablePermission {
  id: string;
  securableId: string;
  securableType: string;
  securableName: string;
}

export class Permission {
  id: string;
  role: Partial<Role>;
  roleID: string;
  actionId: string;
  actions: string[];
  action: Partial<Action>;
  securableId?: string;
  isInherited: boolean;
  inheritanceMessage: string;
  hasSecuredItems: boolean;
  securableName: string;
  actionSecurables: SecurablePermission[];
  constructor(options?: Partial<Permission>) {
    if (options) {
      Object.assign(this, options);
    }
  }
}

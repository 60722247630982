import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { DataService } from 'src/app/services';

@Component({
  selector: 'wm-schedling-engine-tester',
  templateUrl: './schedling-engine-tester.component.html',
  styleUrls: ['./schedling-engine-tester.component.css']
})
export class SchedlingEngineTesterComponent implements OnInit {
  durationMinutes: number;
  engineRunning = false;
  noUserId = false;
  startedOn: Date;
  completeOn: Date;

  constructor(private _dataSvc: DataService) { }

  ngOnInit() { }

  runEngine() {
    this.startedOn = new Date();
    this.completeOn = moment(this.startedOn).add(this.durationMinutes, 'minutes').toDate();

    this.engineRunning = true;

    this._dataSvc
      .runSchedulingEngine(this.durationMinutes)
      .subscribe(result => {
        this.engineRunning = false;

        if (!result) {
          this.noUserId = true;
        }
      }, (error) => {
        this.engineRunning = false;
      });
  }
}

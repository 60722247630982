import { Router } from '@angular/router';
import { WorkflowApplicationVM } from './../../../models/workflow-application';
import { Workflow } from '../../../models/workflow';
import { Component, OnInit, Input } from '@angular/core';
import { WorkflowService } from 'src/app/services';
import { StartApplicationResponse } from 'src/app/models/start-application-response';

@Component({
  selector: 'wm-workflow-item',
  templateUrl: './workflow-item.component.html',
  styleUrls: ['./workflow-item.component.css']
})
export class WorkflowItemComponent implements OnInit {
  @Input() workflow: Workflow;
  @Input() application: WorkflowApplicationVM;
  saving = false;

  constructor(private _workflowSvc: WorkflowService, private _router: Router) {}

  startApplication(workflowId: string) {
    this.saving = true;
    this._workflowSvc
      .startApplication(workflowId, false)
      .subscribe((applicationResponse: StartApplicationResponse) => {
        const navOptions = [
          '/application/workflow-application',
          applicationResponse.applicationId
        ];
        this._router.navigate(navOptions);
      });
  }
  ngOnInit() {}
}

<div class="form-group">
  <wm-de-label
    [entity]="entity"
    [for]="entity.templateCode"
    [displayOnly]="true"
  ></wm-de-label>
  <input
    type="text"
    readonly
    class="form-control-plaintext"
    [id]="entity.templateCode"
    [value]="
      entity.value != null
        ? entity.formattedValue
        : 'Feature Made Available By Enabling Map Search Capability'
    "
  />
</div>

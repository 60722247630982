import { Component, OnInit, Input } from '@angular/core';
import { PaymentAccountConfigBaseComponent } from '../payment-account-config-base/payment-account-config-base.component';
import { OfficialpaymentsPaymentProcessorAccountConfiguration } from '../../../models/payment-providers/officialpayments/officialpayments-payment-processor-account-configuration';
import { UntypedFormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'wm-payment-account-config-officialpayments',
  templateUrl: './payment-account-config-officialpayments.component.html',
  styleUrls: ['./payment-account-config-officialpayments.component.css']
})
export class PaymentAccountConfigOfficialpaymentsComponent
  extends PaymentAccountConfigBaseComponent
  implements OnInit {
  @Input() config: OfficialpaymentsPaymentProcessorAccountConfiguration;

  constructor(fb: UntypedFormBuilder) {
    super(fb);
    this.showUsername = false;
    this.showPassword = false;
  }

  ngOnInit() {
    if (!this.config) {
      this.config = new OfficialpaymentsPaymentProcessorAccountConfiguration();
    }

    this.addValidation('productId', Validators.required, this.config.productId);
    this.addValidation(
      'uniqueFieldId',
      Validators.required,
      this.config.uniqueFieldId
    );
    this.addValidation(
      'permitNumberFieldId',
      Validators.required,
      this.config.permitNumberFieldId
    );
    this.addValidation(
      'parcelNumberFieldId',
      Validators.required,
      this.config.parcelNumberFieldId
    );
    this.addValidation(
      'parcelNumberDataEntityName',
      Validators.required,
      this.config.parcelNumberDataEntityName
    );
  }
}

import {
  Component,
  OnInit,
  ComponentFactoryResolver,
  ViewChild
} from '@angular/core';
import { MapsketchDataEntity } from 'src/app/models/data-entities';
import { EsriMapComponent } from 'src/app/components/arcgis/esri-map/esri-map.component';
import { MapsketchData } from 'src/app/components/arcgis/mapsketch-data';
import { MapExtent } from 'src/app/models/mapextent';
import { DataEntityViewComponent } from 'src/app/components/workflow/data-entities/data-entity-view/data-entity-view.component';

@Component({
  selector: 'wm-mapsketch-data-entity-view',
  templateUrl: './mapsketch-data-entity-view.component.html',
  styleUrls: ['./mapsketch-data-entity-view.component.css']
})
export class MapsketchDataEntityViewComponent extends DataEntityViewComponent
  implements OnInit {
  mapsketchDataEntity: MapsketchDataEntity;
  @ViewChild(EsriMapComponent, { static: false })
  private esriMap: EsriMapComponent;

  constructor(componentFactoryResolver: ComponentFactoryResolver) {
    super(componentFactoryResolver);
  }

  ngOnInit() {
    // this.entity.value = '{"extent":{"xmin":-92.85731211569862,"ymin":42.39449835086383,"xmax":-92.8500808801951,"ymax":42.39845995627806},"graphics":[{"geometry":{"spatialReference":{"wkid":4326},"rings":[[[-92.85493031409362,42.397509193786895],[-92.85251632598042,42.397509193786895],[-92.85250559714436,42.39649503125188],[-92.85510197547055,42.39661387927176],[-92.85493031409362,42.397509193786895]]]},"symbol":{"type":"esriSFS","color":[138,43,226,204],"outline":{"type":"esriSLS","color":[255,255,255,255],"width":1,"style":"esriSLSSolid"},"style":"esriSFSSolid"},"attributes":{},"popupTemplate":null}]}';
    this.mapsketchDataEntity = this.entity as MapsketchDataEntity;
  }

  async onMapLoaded() {
    const parcelNumbers = this.mapsketchDataEntity.parcelNumbers || [];
    if (
      (!this.mapsketchDataEntity.geometryWKTs ||
        this.mapsketchDataEntity.geometryWKTs.length === 0) &&
      parcelNumbers.length > 0
    ) {
      await this.esriMap.enableFeatureSelection(
        this.mapsketchDataEntity.mapConfig.parcelLayerName,
        this.mapsketchDataEntity.mapConfig.parcelIdField,
        true
      );

      await this.esriMap.selectFeaturesOnMap(parcelNumbers);
    }
  }

  async onSketchLayerReady() {
    let extent = null;

    let referenceGeoWkt: string[] = [];

    if (this.mapsketchDataEntity.geometryWKT) {
      referenceGeoWkt.push(this.mapsketchDataEntity.geometryWKT);
    }

    if (this.mapsketchDataEntity.featureGeometryWKT) {
      const keys = Object.keys(this.mapsketchDataEntity.featureGeometryWKT).filter(f => f != '$id');
      const featureWkt: string[] = [];
      keys.forEach(k => {
        if (this.mapsketchDataEntity.featureGeometryWKT[k]) {
          this.mapsketchDataEntity.featureGeometryWKT[k].forEach(f => {
            if (f.item2) {
              featureWkt.push(f.item2);
            }
          });
        }
      });

      referenceGeoWkt = referenceGeoWkt.concat(featureWkt);
    }

    if (this.mapsketchDataEntity.value) {
      extent = await this.esriMap.getCurrentMapExtentWGS84();
      const g: MapsketchData = JSON.parse(this.mapsketchDataEntity
        .value as string);
      extent = g.extent;
      this.esriMap.setSketchData(g.graphics, extent, referenceGeoWkt);
    }
  }
}

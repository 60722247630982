import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import {
  ExternalDataConnection,
  ParcelDataConnection,
  SearchParameter,
  ResultField
} from '../../../../models';
import {
  UntypedFormGroup,
  AbstractControl,
  UntypedFormBuilder,
  Validators
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SystemService, WorkflowContextService } from '../../../../services';
import { Subscription, Subscriber } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'wm-external-data-connections-detail-view',
  templateUrl: './external-data-connections-detail-view.component.html',
  styleUrls: ['./external-data-connections-detail-view.component.css']
})
export class ExternalDataConnectionsDetailViewComponent
  implements OnInit, OnDestroy {
  ngOnDestroy(): void {
    if (this.clientSubscription) {
      this.clientSubscription.unsubscribe();
    }
  }
  externalDataConnection: ExternalDataConnection = null;
  externalDataConnectionId: string;

  clientSubscription: Subscription;

  form: UntypedFormGroup;
  nameCtl: AbstractControl;
  urlCtl: AbstractControl;
  methodCtl: AbstractControl;
  connectionTypeCtl: AbstractControl;
  parcelDataSourceIdCtl: AbstractControl;
  hostCtl: AbstractControl;
  usernameCtl: AbstractControl;
  passwordCtl: AbstractControl;
  pathCtl: AbstractControl;

  parcelDataSourceExists: boolean;

  parcelDataSourceId: string;
  searchResults: any;
  parcelDataSources: any;

  constructor(
    private route: ActivatedRoute,
    private _systemSvc: SystemService,
    private fb: UntypedFormBuilder,
    private _router: Router,
    private _context: WorkflowContextService,
    private _changeRef: ChangeDetectorRef,
    private _toastr: ToastrService
  ) {
    // #region Setup Validation
    this.form = this.fb.group({
      name: ['', Validators.required],
      url: ['', Validators.required],
      method: ['', Validators.required],
      connectionType: ['', Validators.required],
      parcelDataSourceId: ['', Validators.required],
      host: ['', Validators.required],
      username: ['', Validators.required],
      password: ['', Validators.required],
      path: ['', Validators.nullValidator]
    });

    this.nameCtl = this.form.controls['name'];
    this.urlCtl = this.form.controls['url'];
    this.methodCtl = this.form.controls['method'];
    this.connectionTypeCtl = this.form.controls['connectionType'];
    this.parcelDataSourceIdCtl = this.form.controls['parcelDataSourceId'];
    this.hostCtl = this.form.controls['host'];
    this.usernameCtl = this.form.controls['username'];
    this.passwordCtl = this.form.controls['password'];
    this.pathCtl = this.form.controls['path'];

    // #endregion
  }

  cancelDelete(e) {}

  deleteConnection(e) {
    this._systemSvc
      .deleteExternalDataConection(this.externalDataConnection)
      .subscribe(e => {
        this.returnToList();
      });
  }

  isNotNew() {
    if (this.externalDataConnection) {
      return (this.externalDataConnectionId || '') !== '';
    } else {
      return false;
    }
  }

  removeSearchParam(param: SearchParameter) {
    const index: number = this.externalDataConnection.parameters.indexOf(param);

    if (index > -1) {
      this.externalDataConnection.parameters.splice(index, 1);
    }
  }

  testSFTPConnection() {
    this._systemSvc
      .testExternalConnection(this.externalDataConnection)
      .subscribe(
        result => {
          if (result) {
            this._toastr.success('Connection was successful');
          }
        },
        err => {
          console.log('Error', err); // This is here on purpose
          this._toastr.error('Connection Test Failed');
        }
      );
  }

  removeResultField(field: ResultField) {
    const index: number = this.externalDataConnection.resultFields.indexOf(
      field
    );

    if (index > -1) {
      this.externalDataConnection.resultFields.splice(index, 1);
    }
  }

  addSearchParam() {
    const param = new SearchParameter();
    if (!this.externalDataConnection.parameters) {
      this.externalDataConnection.parameters = [];
    }

    this.externalDataConnection.parameters.push(param);
  }

  addResultField() {
    const field = new ResultField();
    if (!this.externalDataConnection.resultFields) {
      this.externalDataConnection.resultFields = [];
    }

    this.externalDataConnection.resultFields.push(field);
  }

  save() {
    if (this.externalDataConnection.connectionType === 'ParcelData') {
      let sourceParam: Partial<SearchParameter>;

      const hasSourceParam = this.externalDataConnection.parameters.some(
        (value, index) => {
          if (value.name === 'SourceID') {
            sourceParam = value;
            return true;
          }
        }
      );

      if (!sourceParam) {
        sourceParam = new SearchParameter({
          label: 'Source ID',
          name: 'SourceID',
          value: null
        });
        this.externalDataConnection.parameters.push(sourceParam);
      }

      sourceParam.value = this.parcelDataSourceId;
      this.externalDataConnection.id = this.externalDataConnectionId;
    }

    this._systemSvc
      .saveExternalDataConnection(this.externalDataConnection)
      .subscribe(() => {
        this.returnToList();
      });
  }

  returnToList() {
    this._router.navigate([
      'admin',
      'jurisdiction',
      this._context.client.id,
      'settings',
      'data'
    ]);
  }

  cancel() {
    this.returnToList();
  }

  isFormValid(): boolean {
    const edcType =
      this.externalDataConnection && this.externalDataConnection.connectionType;

    // If it is a new connection parcel data connection, make sure one does't exist already
    const edcValid =
      edcType === 'ParcelData' && !this.isNotNew()
        ? !this.parcelDataSourceExists
        : true;

    return this.form.valid && edcValid;
  }

  getParcelDataSourcesSub: Subscription;

  setupControllers(connectionType: string) {
    // disable all form controls
    for (const idx in this.form.controls) {
      if (idx !== 'connectionType') {
        this.form.controls[idx].disable();
      }
    }

    if (connectionType === 'ParcelData') {
      this.parcelDataSourceIdCtl.enable();

      this._systemSvc
        .hasParcelDataSource(this._context.client.id)
        .subscribe(r => (this.parcelDataSourceExists = r));

      if (
        (this.parcelDataSources || []).length === 0 &&
        !this.getParcelDataSourcesSub
      ) {
        this.getParcelDataSourcesSub = this._systemSvc
          .getParcelDataSources()
          .subscribe(
            result => {
              this.getParcelDataSourcesSub.unsubscribe();
              this.getParcelDataSourcesSub = null;

              this.parcelDataSources = result;
            },
            err => (this.parcelDataSources = [])
          );
      }
    } else if (connectionType === 'SecureFTP') {
      this.hostCtl.enable();
      this.nameCtl.enable();
      this.usernameCtl.enable();
      this.passwordCtl.enable();
      this.pathCtl.enable();
    } else {
      this.nameCtl.enable();
      this.urlCtl.enable();
      this.methodCtl.enable();
    }

    this.connectionTypeCtl.valueChanges.subscribe(value =>
      this.connectionTypeChange(value)
    );
  }

  connectionTypeChange(newType: string) {
    if (this.externalDataConnection.connectionType !== newType) {
      if (newType === 'ParcelData') {
        this.externalDataConnection = new ParcelDataConnection();
        this.setupControllers(newType);
      } else {
        this.externalDataConnection = this._systemSvc.createExternalDataConnection(
          newType
        );
        this.setupControllers(newType);
      }
    }

    if (this.externalDataConnection) {
      this.externalDataConnection.id = this.externalDataConnectionId;
      this.externalDataConnection.clientID = this._context.client.id;
    } else {
      this.externalDataConnection = new ExternalDataConnection();
    }
    this.setupControllers(newType);

    this._changeRef.detectChanges();
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      if (params['id']) {
        this.externalDataConnectionId = params['id'];
      }

      if ((this.externalDataConnectionId || '') !== '') {
        const init = () =>
          this._systemSvc
            .getExternalDataConnection(this.externalDataConnectionId)
            .subscribe(edc => {
              this.externalDataConnection = edc;
              this.setupControllers(this.externalDataConnection.connectionType);
              if (this.externalDataConnection.connectionType === 'ParcelData') {
                // Search for sourceid
                this.externalDataConnection.parameters.forEach(
                  (param, index) => {
                    if (param.name === 'SourceID') {
                      this.parcelDataSourceId = param.value;
                      return false;
                    }
                  }
                );
              }
              this.clientSubscription = this._context.client$.subscribe(c => {
                this.cancel();
              });
            });

        if (this._context.client) {
          init();
        } else {
          this.clientSubscription = this._context.client$.subscribe(() => {
            init();
          });
        }
      } else {
        this.externalDataConnection = new ExternalDataConnection();
        // this.externalDataConnection = this._systemSvc.createExternalDataConnection(null);
        // this.setupControllers(this.externalDataConnection.connectionType);
      }

      this._changeRef.detectChanges();
    });
  }
}

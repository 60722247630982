import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'wm-contacts-editor',
  templateUrl: './contacts-editor.component.html',
  styleUrls: ['./contacts-editor.component.css']
})
export class ContactsEditorComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}

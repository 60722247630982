import { Component, OnInit, Input } from '@angular/core';
import { PaymentProcessor } from '../../../models';

@Component({
  selector: 'wm-payment-processor-list',
  templateUrl: './payment-processor-list.component.html',
  styleUrls: ['./payment-processor-list.component.css']
})
export class PaymentProcessorListComponent implements OnInit {
  @Input() processors: PaymentProcessor[];

  constructor() {}

  ngOnInit() {}
}

<div class="row" *ngIf="!canViewComponent && !checkingPermissions">
  <div class="col">
    <p class="text-danger">
      This the role that you are in does not have permission to view the
      information in this activity
    </p>
  </div>
</div>
<div class="row" *ngIf="canViewComponent && !checkingPermissions">
  <div class="col">
    <div class="d-flex justify-content-between">
      <div>
        <h1 *ngIf="showTitle">{{ viewTitle }}</h1>
        <p>{{ activity.model.screenHelpText }}</p>
      </div>

      <div *ngIf="context.user && activity.activityDataId">
        <button
          type="button"
          class="btn btn-secondary d-flex justify-content-center align-content-between mr-1 float-right"
          (click)="shareApplicationAccess.open()"
        >
          <i class="material-icons mr-1">share</i> <span>Share</span>
        </button>
        <button
          type="button"
          class="btn btn-secondary d-flex justify-content-center align-content-between mr-1 float-right"
          (click)="openWorkflowDesign(activity)"
          *ngIf="canViewDesign"
        >
          <i class="material-icons mr-1">schema</i> <span>Design</span>
        </button>

        <wm-modal-confirm
          class="text-right"
          (opened)="getShareApplicationDetails()"
          (accepted)="shareApplication()"
          (canceled)="this.details = null"
          commandText="Share"
          [moduleId]="'shareActivity' + this.activity.id"
          [showButtonText]="false"
          [showButton]="false"
          btnIcon="share"
          title="Share Activity"
          buttonClass="btn btn-sm"
          class="mr-1"
          [closeOnAccept]="false"
          tooltip="Share access to this application"
          [form]="shareForm"
          [showCommandButton]="showCommandButton"
          [cancelText]="cancelText"
          #shareApplicationAccess
        >
          <div *ngIf="sharingStatus == SharingStatus.Loading">
            Loading Share Details...
          </div>
          <ng-container *ngIf="details">
            <h3>Share</h3>
            <div *ngIf="sharingStatus == SharingStatus.Shared">
              An email has been sent to {{ details.emailAddress }} with a link
              allowing access to this application.
            </div>
            <div *ngIf="sharingStatus == SharingStatus.Sharing">
              Sharing application with {{ details.emailAddress }}...
            </div>
            <ng-container
              *ngIf="details && sharingStatus == SharingStatus.Loaded"
            >
              <ul ngbNav #shareNavs="ngbNav" class="nav-tabs">
                <li ngbNavItem>
                  <a ngbNavLink>Share</a>
                  <ng-template ngbNavContent>
                    <form [formGroup]="shareForm">
                      <div class="form-group">
                        <div class="label">Email</div>
                        <input
                          type="text"
                          class="form-control"
                          [(ngModel)]="details.emailAddress"
                          formControlName="emailAddress"
                        />
                        <div>
                          <sub
                            >Email address of the person who you are sharing
                            access with</sub
                          >
                        </div>
                      </div>
                    </form>

                    <div class="label"><strong>Access</strong></div>
                    <div class="form-group">
                      <div class="col-12">
                        <input
                          type="text"
                          class="form-control"
                          placeholder="search activities..."
                          (keyup)="searchActivities($event)"
                        />
                      </div>
                    </div>
                    <div style="overflow: auto; max-height: 600px;">
                      <ul class="list-group">
                        <li class="list-group-item border-bottom">
                          <div class="col-8"><strong>Activity</strong></div>
                          <div class="col-2"><strong>Access</strong></div>
                        </li>
                        <li
                          class="list-group-item  border-bottom"
                          *ngIf="details.currentActivity"
                        >
                          <div class="col-8">
                            {{ details.currentActivity.activityName }}
                          </div>
                          <div class="col-2">
                            <select
                              class="form-control"
                              [(ngModel)]="details.currentActivity.permission"
                            >
                              <option value="2">None</option>
                              <option value="0">Read</option>
                              <option
                                value="1"
                                *ngIf="canEdit(details.currentActivity)"
                                >Edit</option
                              >
                            </select>
                          </div>
                        </li>
                        <li class="list-group-item border-bottom">
                          <p><i>Prior Activities</i></p>
                        </li>
                        <ng-container *ngFor="let a of filteredPriorActivities">
                          <li
                            class="list-group-item  border-bottom"
                            *ngIf="canShareView(a)"
                          >
                            <div class="col-8">{{ a.activityName }}</div>
                            <div class="col-2">
                              <select
                                class="form-control"
                                [(ngModel)]="a.permission"
                              >
                                <option value="2">None</option>
                                <option value="0" *ngIf="canShareView(a)"
                                  >Read</option
                                >
                                <option value="1" *ngIf="canEdit(a)"
                                  >Edit</option
                                >
                              </select>
                            </div>
                          </li>
                        </ng-container>
                        <ng-container
                          *ngIf="(filteredPriorActivities || []).length == 0"
                        >
                          <li class="list-group-item border-bottom">
                            <div class="col-12">
                              <i>No Prior Activities</i>
                            </div>
                          </li>
                        </ng-container>
                        <li class="list-group-item border-bottom">
                          <p><i>Future Activities</i></p>
                        </li>
                        <ng-container
                          *ngIf="(filteredFutureActivities || []).length > 0"
                        >
                          <ng-container
                            *ngFor="let a of filteredFutureActivities"
                          >
                            <li
                              class="list-group-item  border-bottom"
                              *ngIf="canShareView(a)"
                            >
                              <div class="col-8">{{ a.activityName }}</div>
                              <div class="col-2">
                                <select
                                  class="form-control"
                                  [(ngModel)]="a.permission"
                                >
                                  <option value="2">None</option>
                                  <option value="0" *ngIf="canShareView(a)"
                                    >Read</option
                                  >
                                  <option value="1" *ngIf="canEdit(a)"
                                    >Edit</option
                                  >
                                </select>
                              </div>
                            </li>
                          </ng-container>
                        </ng-container>
                        <ng-container
                          *ngIf="(filteredFutureActivities || []).length == 0"
                        >
                          <li class="list-group-item border-bottom">
                            <div class="col-12">
                              <i>No Future Activities</i>
                            </div>
                          </li>
                        </ng-container>
                      </ul>
                    </div>
                  </ng-template>
                </li>
                <li ngbNavItem>
                  <a ngbNavLink>Manage Access</a>
                  <ng-template ngbNavContent>
                    <ng-container *ngIf="details.shares?.length == 0">
                      <div class="form-group mt-4">
                        <div class="col-12">
                          <p><i>Application has not been shared yet.</i></p>
                        </div>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="details.shares.length > 0">
                      <table class="table">
                        <thead>
                          <tr>
                            <td>
                              <b>Email Address</b>
                            </td>
                            <td>
                              <b>Shared On</b>
                            </td>
                            <td>
                              <b>Activity</b>
                            </td>
                            <td></td>
                          </tr>
                        </thead>
                        <tbody>
                          <ng-container *ngFor="let share of details.shares">
                            <tr>
                              <td>{{ share.emailAddress }}</td>
                              <td>
                                {{
                                  share.sharedOn | date: 'MM/dd/yyyy hh:mm a'
                                }}
                              </td>
                              <td>{{ share.currentActivity.activityName }}</td>
                              <td>
                                <a href="#" (click)="showActivities(share)"
                                  >Details</a
                                >
                              </td>
                              <td>
                                <wm-modal-confirm
                                  showButtonText="false"
                                  showButton="true"
                                  buttonText="Remove"
                                  commandText="Remove Access"
                                  [buttonStyle]="ButtonStyle.Link"
                                  [moduleId]="share.id"
                                  title="Remove Access"
                                  [showButtonText]="true"
                                  (accepted)="removeSharedAccess($event)"
                                >
                                  <p>
                                    Are you sure you want to remove access to
                                    this application for "{{
                                      share.emailAddress
                                    }}"?
                                  </p>
                                </wm-modal-confirm>
                              </td>
                            </tr>
                            <tr *ngIf="share.showDetails">
                              <td colspan="5">
                                <table class="table">
                                  <thead>
                                    <tr>
                                      <td><b>Activity</b></td>
                                      <td><b>Permission</b></td>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr *ngIf="!share.activities">
                                      <i>Loading...</i>
                                    </tr>
                                    <ng-container *ngIf="share.activities">
                                      <tr *ngFor="let a of share.activities">
                                        <td>{{ a.activityName }}</td>
                                        <td>
                                          {{
                                            a.permission | sharePermissionStatus
                                          }}
                                        </td>
                                      </tr>
                                    </ng-container>
                                    <tr
                                      *ngIf="
                                        share.activities &&
                                        share.activities.length == 0
                                      "
                                    >
                                      <i>No Activities Shared</i>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </ng-container>
                        </tbody>
                      </table>
                    </ng-container>
                  </ng-template>
                </li>
              </ul>
              <div [ngbNavOutlet]="shareNavs"></div>
            </ng-container>
          </ng-container>
        </wm-modal-confirm>
      </div>
    </div>

    <form [formGroup]="form" novalidate class="mt-4">
      <!--<app-loader *ngIf="processing"></app-loader>-->
      <!--*ngIf="!processing"-->
      <wm-activity-view
        [activity]="activity"
        [isPreview]="isPreview"
        [isTestApplication]="isTestApplication"
        [form]="form"
        [applicationId]="applicationId"
        (viewTitle$)="setViewTitle($event)"
        (showSave)="onShouldShowSave.emit($event)"
        (showNext)="onShouldShowNext.emit($event)"
        (showPrevious)="onShouldShowPrevious.emit($event)"
        (goNext)="next()"
        (switchApplication)="switchApplication.emit($event)"
        #activityView
      ></wm-activity-view>
    </form>
  </div>
</div>

import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
  OnChanges
} from '@angular/core';
import { Activity, ActivityModel } from '../../../models/activities';
import { ActivityNavigationInfo } from '../../../models/activity-navigation-Info';

@Component({
  selector: 'wm-workflow-application-navigation',
  templateUrl: './workflow-application-navigation.component.html',
  styleUrls: ['./workflow-application-navigation.component.css']
})
export class WorkflowApplicationNavigationComponent
  implements OnInit, OnChanges {
  @Input() currentActivity: Activity<ActivityModel>;
  @Input() applicationId: string;
  @Input() navigationItems: ActivityNavigationInfo[];
  @Output() onNavigate: EventEmitter<string> = new EventEmitter<string>();
  @Output() switchApplication: EventEmitter<string> = new EventEmitter<
    string
  >();
  loading = false;
  hasEmailsToDisplay = false;
  showEmails = false;
  currentNavigationItems: ActivityNavigationInfo[];

  emailActivityTypeCodes = [
    'email-activity',
    'schedule-email-activity',
    'appointment-activity'
  ];

  constructor() {}

  navigate(ni: ActivityNavigationInfo) {
    const activityDataId = ni.activityDataId;
    const applicationId = ni.applicationId;
    if (ni.canNavigate) {
      if (applicationId) {
        this.switchApplication.emit(applicationId);
      } else if (
        activityDataId &&
        activityDataId !== this.currentActivity.activityDataId
      ) {
        this.onNavigate.emit(activityDataId);
        return true;
      }
    }

    return false;
  }

  isActive(activity) {
    return activity.activityId === this.currentActivity.id;
  }

  ngOnInit() {
    this.checkForEmails();
    this.setCurrentNavigationItems();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.navigationItems) {
      this.checkForEmails();
      this.setCurrentNavigationItems();
    }
  }

  getTooltip(item: ActivityNavigationInfo) {
    return item.activityRole ? `Role: ${item.activityRole}` : null;
  }

  navClass(item: ActivityNavigationInfo) {
    if (!item.activityColor) {
      return;
    }

    return {
      ['rc-' + item.activityColor]: this.isActive(item),
      ['rc-border-' + item.activityColor]: !this.isActive(item)
    };
  }

  checkForEmails() {
    this.hasEmailsToDisplay =
      this.navigationItems.filter(ni =>
        this.emailActivityTypeCodes.includes(ni.activityType)
      ).length > 0;
  }

  setCurrentNavigationItems() {
    this.currentNavigationItems = this.showEmails
      ? this.navigationItems
      : this.navigationItems.filter(
          ni => !this.emailActivityTypeCodes.includes(ni.activityType)
        );
  }
}

import { Subscription } from 'rxjs';
import { CustomList } from './../../../../models/custom-list';
import { ClientService } from './../../../../services/client.service';
import { WorkflowContextService } from './../../../../services/workflow-context.service';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-custom-list-list-view',
  templateUrl: './custom-list-list-view.component.html',
  styleUrls: ['./custom-list-list-view.component.css']
})
export class CustomListListViewComponent implements OnInit {
  public customLists: CustomList[] = [];
  clientSubscribe: Subscription;

  constructor(
    private _router: Router,
    private _service: ClientService,
    private _context: WorkflowContextService
  ) {}

  getCustomLists() {
    this._service
      .getCustomLists(this._context.client.id)
      .subscribe(customLists => {
        this.customLists = customLists;
      });
  }

  ngOnInit() {
    if (this._context.client) {
      this.getCustomLists();
    } else {
      this.clientSubscribe = this._context.client$.subscribe(res => {
        this.getCustomLists();
      });
    }
  }

  addCustomList() {
    this._router.navigate([
      `/admin/jurisdiction/${this._context.client.id}/settings/lists/add`
    ]);
  }

  ngOnDestroy(): void {
    if (this.clientSubscribe) {
      this.clientSubscribe.unsubscribe();
    }
  }
}

<form [formGroup]="form" *ngIf="config">
  <div class="form-group">
    <label class="bmd-label-floating" for="accessToken">
      Access Token
    </label>
    <input
      type="text"
      class="form-control"
      formControlName="accessToken"
      name="accessToken"
      [(ngModel)]="config.accessToken"
    />
  </div>
  <div class="form-group">
    <label class="bmd-label-floating" for="locationId">
      Location Id
    </label>
    <input
      type="text"
      class="form-control"
      formControlName="locationId"
      name="locationId"
      [(ngModel)]="config.locationId"
    />
  </div>
</form>

import {
  Component,
  OnInit,
  Input,
  ViewChild,
  Output,
  EventEmitter,
  AfterViewInit
} from '@angular/core';
import {
  FormActivity,
  SingleColumnFormLayoutModel
} from '../../../../../../models/activities';
import { UntypedFormGroup } from '@angular/forms';
import { FormActivityInputLayoutBaseComponent } from '../form-activity-input-layout-base/form-activity-input-layout-base.component';
import { FormActivityInputLayout } from '../form-activity-input.component';
import { WorkflowApplication } from '../../../../../../models';
import { DataEntityInputListComponent } from 'src/app/components/workflow/data-entities/data-entity-input-list/data-entity-input-list.component';

@Component({
  selector: 'wm-form-activity-input-layout-single-column',
  templateUrl: './form-activity-input-layout-single-column.component.html',
  styleUrls: ['./form-activity-input-layout-single-column.component.css']
})
export class FormActivityInputLayoutSingleColumnComponent
  extends FormActivityInputLayoutBaseComponent
  implements OnInit, FormActivityInputLayout, AfterViewInit {
  @Input() activity: FormActivity;
  @Input() form: UntypedFormGroup;
  @Input() applicationId: string;
  @Output() calculateActivity: EventEmitter<string> = new EventEmitter();

  @ViewChild('dataEntityInputList', { static: false })
  dataEntityInputListCtl: DataEntityInputListComponent;
  @ViewChild('dataEntityViewList', { static: false })
  dataEntityViewListCtl: DataEntityInputListComponent;

  formLayoutModel: SingleColumnFormLayoutModel;

  constructor() {
    super();
  }
  ngAfterViewInit(): void {
    if (this.dataEntityInputListCtl) {
      this.dataEntityInputListCtl.calculateActivity.subscribe(e => {
        this.calculateActivity.emit(e);
      });
    }
  }

  ngOnInit() {
    this.formLayoutModel = this.activity.model
      .formLayoutModel as SingleColumnFormLayoutModel;
  }
  async persistChildComponent() {
    return this.dataEntityInputListCtl.persistChildComponent();
  }
}

import { EventEmitter } from '@angular/core';
import { DataEntity } from './data-entity';
import { Validators } from '@angular/forms';
import { ConditionTarget } from 'src/app/components/system/condition-builder/condition-builder.model';

export enum OutputFormatEnum {
  General,
  Text,
  Number,
  Decimal,
  Date,
  DateTime
}

export class CalculatedValueDataEntity extends DataEntity {
  formulaCriteria: ConditionTarget[];
  calculateOnChange: boolean;
  allowEdit: boolean;
  visibleToUser: boolean;
  sameActivityFormulaDataEntities: string[];
  formattedValue: string;
  editedValue?: string;
  formulaValue: string;
  outputFormat: OutputFormatEnum;
  valueUpdate$: EventEmitter<string> = new EventEmitter();

  constructor(options?: Partial<CalculatedValueDataEntity>) {
    super(options);

    if (options) {
      Object.assign(this, options);

      if (this.visibleToUser == null) {
        this.visibleToUser = true;
      }
    }
  }

  getValidators(): any[] {
    let validators: any[] = super.getValidators();

    // if you can't edit then it shouldn't be required
    if (!this.allowEdit) {
      validators = validators.filter(v => v != Validators.required);
    }

    return validators;
  }

  preStringify() {
    if (this.valueUpdate$ && this.valueUpdate$.unsubscribe) {
      this.valueUpdate$.unsubscribe();
    }
  }
}

import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { Client } from '../../../models';

@Component({
  selector: 'wm-client-list',
  templateUrl: './client-list.component.html',
  styleUrls: ['./client-list.component.css']
})
export class ClientListComponent implements OnInit, OnChanges {
  @Input() clients: Client[];

  groupedClients: { state: string; clients: Client[] }[];

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    this.groupClientsByState();
  }

  private sort(a: Client, b: Client) {
    if (a.name > b.name) {
      return 1;
    } else if (a.name < b.name) {
      return -1;
    } else {
      return 0;
    }
  }

  private buildStates(clients: Client[]): string[] {
    // get unique list of states for clients;
    const states: string[] = [];

    if (clients) {
      for (const client of clients) {
        if (!states.includes(client.state)) {
          states.push(client.state);
        }
      }
    }

    // Initialize variables
    return states.sort();
  }

  groupClientsByState() {
    this.groupedClients = this.buildStates(this.clients).map(state => ({
      state,
      clients: this.clients
        .filter(client => client.state === state)
        .sort(this.sort)
    }));
  }

  ngOnInit() {
    this.groupClientsByState();
  }
}

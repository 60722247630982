<div class="form-group">
  <label class="bmd-label-floating" for="reason">Reason for Completion</label>
  <select
    class="form-control"
    [(ngModel)]="activity.model.reason"
    name="reason"
  >
    <option>Completed</option>
    <option>Rejected</option>
  </select>
</div>

<div class="switch">
  <label>
    <input
      id="autoSubmitSwitch"
      type="checkbox"
      [(ngModel)]="activity.model.autoSubmit"
      (ngModelChange)="autoSubmitWarn()"
    />
    Auto submit
  </label>
</div>

<wm-modal-confirm
  commandText="I'm Sure"
  [showButton]="false"
  [showButtonText]="false"
  title="Auto Submit"
  (canceled)="cancelAutoSubmit()"
  moduleId="autoSubmit"
  #autoSubmitModal
>
  <p>
    Are you sure you want automatically submit when this workflow reaches the
    End activity?
  </p>
  <br />
  <p>
    Application data cannot be edited or corrected after the End activity is
    submitted.
  </p>
</wm-modal-confirm>

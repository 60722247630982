import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Workflow } from '../../../models';

@Component({
  selector: 'wm-workflow-list',
  templateUrl: './workflow-list.component.html',
  styleUrls: ['./workflow-list.component.css']
})
export class WorkflowListComponent implements OnInit {
  // Selected client, can be null;
  @Input() workflows: Workflow[];
  @Input() showAdmin = true;
  @Input() title = '';
  @Input() clientId: string;
  @Input() loadGlobal = false;
  @Output() onRestore: EventEmitter<string> = new EventEmitter<string>();
  @Output() onDisable: EventEmitter<string> = new EventEmitter<string>();
  @Output() onEnable: EventEmitter<string> = new EventEmitter<string>();
  @Output() onPublish: EventEmitter<string> = new EventEmitter<string>();
  @Output() onHistory: EventEmitter<string> = new EventEmitter<string>();
  @Output() onCopy: EventEmitter<Workflow> = new EventEmitter<Workflow>();
  @Output() onArchive: EventEmitter<{
    id: string;
    archiveNotes: string;
  }> = new EventEmitter<{ id: string; archiveNotes: string }>();
  @Output() toggleVisible: EventEmitter<Workflow> = new EventEmitter<
    Workflow
  >();

  archiveNotes: string;
  isValidating = false;

  constructor() {}

  canPublish(wf) {
    return wf.canPublish && wf.isEnabled && wf.areThereChangesNotPublished;
  }

  isPublished(wf) {
    return wf.isEnabled && wf.areThereChangesNotPublished;
  }

  ngOnInit() {}
}

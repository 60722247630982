import { Component, OnInit, ComponentFactoryResolver } from '@angular/core';
import { DataEntityViewComponent } from '../../data-entity-view/data-entity-view.component';

@Component({
  selector: 'wm-system-data-entity-view',
  templateUrl: './system-data-entity-view.component.html',
  styleUrls: ['./system-data-entity-view.component.css']
})
export class SystemDataEntityViewComponent extends DataEntityViewComponent
  implements OnInit {
  constructor(componentFactoryResolver: ComponentFactoryResolver) {
    super(componentFactoryResolver);
  }

  ngOnInit() {}
}

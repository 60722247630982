import { DataEntity } from './data-entity';

export class SystemDataEntity extends DataEntity {
  isMultiline = false;
  dataType: string;

  constructor(options?: Partial<SystemDataEntity>) {
    super(options);

    if (options) {
      Object.assign(this, options);
    }
  }
}

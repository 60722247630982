import {
  Component,
  OnInit,
  Input,
  Inject,
  forwardRef,
  ViewChild
} from '@angular/core';
import { ActivityEditorBaseComponent } from '../../activity-editor-base/activity-editor-base.component';
import { Client } from 'src/app/models';
import { MapActivity } from 'src/app/models/activities/map-activity';
import { UntypedFormBuilder } from '@angular/forms';
import {
  SystemService,
  DataService,
  WorkflowContextService
} from 'src/app/services';
@Component({
  selector: 'wm-map-activity-editor',
  templateUrl: './map-activity-editor.component.html',
  styleUrls: ['./map-activity-editor.component.css']
})
export class MapActivityEditorComponent extends ActivityEditorBaseComponent
  implements OnInit {
  @Input() activity: MapActivity;
  activityId: string;
  message = 'Pick something on this map';

  client: Client;
  txtClass = '';

  constructor(
    private _fb: UntypedFormBuilder,
    @Inject(forwardRef(() => SystemService)) private _systemsvc: SystemService,
    @Inject(forwardRef(() => DataService)) private _dataSvc: DataService,
    @Inject(forwardRef(() => WorkflowContextService))
    private _context: WorkflowContextService
  ) {
    super();
  }

  ngOnInit() {
    this.client = this._context.client;
  }
}

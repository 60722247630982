<form [formGroup]="form" *ngIf="config">
  <div class="form-group">
    <label class="bmd-label-floating" for="id">
      ID
    </label>
    <input
      type="text"
      class="form-control"
      formControlName="id"
      name="id"
      [(ngModel)]="config.id"
    />
  </div>
  <div class="form-group">
    <label class="bmd-label-floating" for="accessCode">
      Access Code
    </label>
    <input
      type="text"
      class="form-control"
      formControlName="accessCode"
      name="accessCode"
      [(ngModel)]="config.accessCode"
    />
  </div>
  <div class="form-group">
    <label class="bmd-label-floating" for="bureauCode">
      Bureau Code
    </label>
    <input
      type="text"
      class="form-control"
      formControlName="bureauCode"
      name="bureauCode"
      [(ngModel)]="config.bureauCode"
    />
  </div>
  <div class="form-group">
    <label class="bmd-label-floating" for="paymentType">
      Payment Type
    </label>
    <input
      type="text"
      class="form-control"
      formControlName="paymentType"
      name="paymentType"
      [(ngModel)]="config.paymentType"
    />
    <div class="text-danger">
      This value is optional. If you provide a value for this, it should be the
      two character code from the Payment List configured in the Certified
      Payments account. You will likely need to contact Certified Payments for
      this value.
    </div>
  </div>
</form>

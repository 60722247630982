import { ActivityView } from './../../../../../views/master-views/app.view/app.view.component';
import {
  ApplicationEmailVM,
  EmailActivity
} from './../../../../../models/activities/email-activity';
import { Component, Inject, OnInit, forwardRef } from '@angular/core';
import { SecurityService, WorkflowService } from 'src/app/services';
import { ToastrService } from 'ngx-toastr';
import { Actions } from 'src/app/models';
import { Observable } from 'rxjs';

@Component({
  selector: 'wm-email-activity-view',
  templateUrl: './email-activity-view.component.html',
  styleUrls: ['./email-activity-view.component.css']
})
export class EmailActivityViewComponent extends ActivityView implements OnInit {
  activity: EmailActivity;
  applicationEmails: ApplicationEmailVM[] = [];
  resendingEmail = false;

  constructor(
    @Inject(forwardRef(() => SecurityService))
    private _securitySvc: SecurityService,
    @Inject(forwardRef(() => WorkflowService))
    private _workflowSvc: WorkflowService,
    private _toastr: ToastrService
  ) {
    super();
  }

  viewTitle(): string {
    return (
      (this.activity &&
        this.activity.model &&
        this.activity.model.screenName) ||
      'Email'
    );
  }

  ngOnInit() {
    if (
      this.activity.applicationEmails &&
      this.activity.applicationEmails.length > 0
    ) {
      this.applicationEmails = this.activity.applicationEmails;
    }
  }

  canResendEmails(): Observable<boolean> {
    return this._securitySvc.isLoginEntitled(Actions.RESEND_EMAILS);
  }

  resendEmail() {
    this.resendingEmail = true;

    this._workflowSvc
      .reSendEmail(this.context.applicationId, this.activity.activityDataId)
      .subscribe(result => {
        this.resendingEmail = false;

        if (result) {
          this._toastr.success(null, 'Email successfully re-sent', {
            disableTimeOut: true
          });

          this.refreshView.emit();
        } else {
          this._toastr.error(
            'Please contact support if this persists.',
            'Failed to re-send email.',
            { disableTimeOut: true }
          );
        }
      });
  }
}

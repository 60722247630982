import { LoginResponse } from './../models/security';
import { SecurityService } from '../services/security.service';
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { WorkflowContextService } from '.';

import { mergeMap } from 'rxjs/operators';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  authenticator: Observable<any>;

  constructor(
    private _context: WorkflowContextService,
    private _security: SecurityService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // add authorization header with jwt token if available
    // not all requests require authentication, so only set this if the token is there.

    if (this._context.authToken && !request.url.includes('token')) {
      const tokenIsExpired = Date.now() > this._context.authTokenExpiration;
      if (tokenIsExpired) {
        // if the token is expired, refresh it first
        return this._security.authenticate().pipe(
          mergeMap((res: LoginResponse) => {
            request = request.clone({
              setHeaders: {
                Authorization: `Bearer ${this._context.authToken}`
              }
            });

            return next.handle(request);
          })
        );
      }

      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this._context.authToken}`
        }
      });
    }

    return next.handle(request);
  }
}

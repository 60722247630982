<wm-modal-confirm
  [showButton]="false"
  [showButtonText]="false"
  [commandText]="'Disable Workflow'"
  [enabled]="canDisable()"
  [moduleId]="workflow.id + 'disable'"
  title="Disable Workflow"
  (accepted)="disableWorkflow()"
  #disableButton
>
  <p>
    Are you sure you want to disable this workflow ({{
      workflow.version.name
    }})?
  </p>
  <br />
  <p>
    Some things to take note of.
  </p>
  <ul>
    <li>
      Applications will no longer be able to be completed for this workflow.
    </li>
    <li>This workflow will be visible for editing.</li>
  </ul>
</wm-modal-confirm>

<wm-modal-confirm
  title="Copy Workflow"
  moduleId="copy-workflow"
  [showButtonText]="true"
  [enabled]="true"
  [showButton]="false"
  commandText="Copy"
  [form]="targetForm"
  (accepted)="copyWorkflow()"
  [closeOnAccept]="false"
  #copyWorkflowModal
>
  <form [formGroup]="targetForm">
    <label for="targetClientId" class="bmd-label-static">Jurisdiction</label>
    <select
      class="form-control"
      [(ngModel)]="targetClientId"
      name="targetClientId"
      formControlName="targetClientId"
      *ngIf="(clients || []).length > 1"
    >
      <option value="" *ngIf="isSysAdmin">Global</option>
      <option *ngFor="let client of clients" [value]="client.id">
        {{ client.name }}
      </option>
    </select>
    <label for="targetName" class="bmd-label-static">New Name</label>
    <input
      type="text"
      name="targetName"
      formControlName="targetName"
      [(ngModel)]="targetName"
      class="form-control"
    />
  </form>
</wm-modal-confirm>
<wm-modal-confirm
  title="Workflow Checkpoints"
  moduleId="workflow-checkpoints"
  [showButtonText]="false"
  [enabled]="true"
  [showButton]="false"
  #checkpointList
>
  <wm-workflow-checkpoint-list
    [checkpoints]="checkpoints"
    (onRestore)="restoreCheckpoint($event)"
    [workflow]="workflow"
  ></wm-workflow-checkpoint-list>
</wm-modal-confirm>

<wm-modal-confirm
  commandText="Publish"
  [showButton]="false"
  [enabled]="canPublish()"
  [moduleId]="workflow.id + 'publish'"
  [id]="workflow.id"
  [title]="'Publish Workflow'"
  (accepted)="publishWorkflow()"
  (opened)="validateWorkflow()"
  [form]="publishForm"
  #publishButton
>
  <div *ngIf="isValidating" class="text-danger">
    Validating workflow...
  </div>
  <div *ngIf="!isValidating && publishForm.invalid" class="text-danger">
    Workflow has errors, please review the errors in the
    <a
      [routerLink]="['/admin/workflow-builder', workflow.id]"
      (click)="dismissPublish(this)"
      >Workflow Builder</a
    >
  </div>
  <div *ngIf="!isValidating && !publishForm.invalid" class="text-success">
    Workflow is valid
  </div>
  <br />
  <div>
    Are you sure you want to publish this workflow ({{
      workflow.version.name
    }})?
  </div>
  <br />
  <div>
    Some things to take note of.
  </div>
  <ol>
    <li>
      All new Applications for this Workflow will use the new configuration.
    </li>
    <li>
      Existing Applications already in progress will continue to use the old
      configuration.
    </li>
    <li>
      Any renewable workflows that use this workflow as its renewal will also be
      published.
    </li>
    <li>
      Any workflow that is a renewal of this workflow will also be published.
    </li>
  </ol>
</wm-modal-confirm>

<wm-modal-confirm
  [showButton]="false"
  [showButtonText]="false"
  commandText="Archive"
  [enabled]="canArchiveWorkflow()"
  [moduleId]="workflow.id"
  title="Archive Workflow"
  (canceled)="onArchiveCanceled()"
  (accepted)="onArchiveCompleted()"
  #archiveButton
>
  <p>
    Are you sure you want to archive this workflow ({{
      workflow.version.name
    }})?
  </p>

  <div class="form-group">
    <label for="archiveNotes" class="bmd-label-static">Reason</label>
    <textarea
      id="archiveNotes"
      [(ngModel)]="archiveNotes"
      class="form-control"
    ></textarea>
  </div>

  <p>
    Some things to take note of.
  </p>
  <ol>
    <li>
      Applications will be no longer be able to be completed for this workflow.
    </li>
    <li>This application will not be visible for editing.</li>
  </ol>
</wm-modal-confirm>

<wm-modal-confirm
  #denyStartApp
  [showCommandButton]="false"
  [showButton]="false"
  [showButtonText]="false"
  cancelText="OK"
  title="Renewal Workflow"
>
  <p>
    This is a renewal workflow of a Renewable workflow.
  </p>
  <br />
  <p>
    Applications of renewal workflows can only be started from the Renew option
    of a registration.
  </p>
</wm-modal-confirm>

<div class="btn-group float-right" ngbDropdown placement="bottom-right">
  <button
    type="button"
    class="btn btn-raised btn-primary"
    [disabled]="!canEditWorkflow()"
    [routerLink]="['/admin/workflow-builder', workflow.id]"
  >
    Builder
  </button>
  <button
    class="btn btn-raised btn-primary dropdown-toggle-split"
    ngbDropdownToggle
  ></button>

  <div class="dropdown-menu" ngbDropdownMenu>
    <button
      ngbDropdownItem
      [disabled]="!canPublish()"
      (click)="publishButton.open()"
    >
      {{ canPublish() ? 'Publish' : 'Published' }}
    </button>

    <button ngbDropdownItem *ngIf="canEnable()" (click)="enableWorkflow()">
      Enable
    </button>
    <button ngbDropdownItem *ngIf="canDisable()" (click)="disableButton.open()">
      Disable
    </button>

    <button
      ngbDropdownItem
      *ngIf="canArchiveWorkflow()"
      (click)="archiveButton.open()"
    >
      Archive
    </button>
    <button
      ngbDropdownItem
      *ngIf="canRestoreWorkflow()"
      (click)="restoreWorkflow()"
    >
      Restore
    </button>
    <button ngbDropdownItem *ngIf="canViewHistory()" (click)="loadHistory()">
      History
    </button>
    <button
      ngbDropdownItem
      *ngIf="canEditWorkflow()"
      (click)="startWorkflowCopy()"
    >
      Copy
    </button>
    <button
      ngbDropdownItem
      (click)="toggleVisibleToPublic()"
      *ngIf="canEditWorkflow()"
    >
      {{ workflow.visibleToPublic ? 'Hide from Public' : 'Make Public' }}
    </button>

    <button
      ngbDropdownItem
      [disabled]="!canStartApplication()"
      (click)="startApplication(workflow.id)"
      *ngIf="clientId"
    >
      Start Application
    </button>
  </div>
</div>

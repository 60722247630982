import { ScreenActivity, ActivityModel } from '.';
import { User } from '../user';

export class RequiredUserActivityModel extends ActivityModel {
  isUserLoggedIn = false;
  userId: string;
  user: Partial<User>;
  constructor(options: { [key: string]: any }) {
    super(options);

    if (options) {
      Object.assign(this, options);
    }
  }
}

export class RequiredUserActivity extends ScreenActivity<
  RequiredUserActivityModel
> {
  inspectionId: string;
  completedOn: any;
  model: RequiredUserActivityModel;
  constructor(options?: Partial<RequiredUserActivity>) {
    super(options);

    if (options) {
      Object.assign(this, options);
    }

    this.model = new RequiredUserActivityModel(options ? options.model : null);
  }
}

<div class="switch">
  <label>
    <input type="checkbox" [(ngModel)]="paymentActivity.model.autoContinue" />
    Auto continue
  </label>
</div>
<div class="switch">
  <label>
    <input
      type="checkbox"
      [(ngModel)]="paymentActivity.model.allowMultiplePayments"
    />
    Allow Split Payments
  </label>
</div>

<h6>Potential fees to be paid</h6>
<ul class="list-group">
  <!-- TODO: When one of these is clicked, go to the data entity? -->
  <li
    class="list-group-item"
    *ngFor="let de of paymentActivity.model.potentialFees"
  >
    {{ de.label }}
  </li>
</ul>

<!-- display a config section for the payment account if it has one to display-->
<div class="card" [style.display]="showAccountConfig ? 'block' : 'none'">
  <div class="card-body">
    <h5 class="card-title">Account Configuration</h5>
    <template #activityConfigurationEditor></template>
  </div>
</div>

import { PaymentAccountActivityConfigBaseComponent } from '../../../../payment-account/payment-account-activity-config-base/payment-account-activity-config-base.component';
import { PaymentAccountActivityConfigurationBase } from './../../../../../models/payment-providers/payment-account-activity-configuration-base';
import { PaymentAccountActivityConfigPointnpayComponent } from '../../../../payment-account/payment-account-activity-config-pointnpay/payment-account-activity-config-pointnpay.component';
import {
  Component,
  OnInit,
  Inject,
  forwardRef,
  ViewChild,
  ViewContainerRef,
  OnDestroy,
  Type,
  ChangeDetectorRef,
  ComponentFactoryResolver,
  ComponentRef
} from '@angular/core';
import { ActivityEditorBaseComponent } from '../../activity-editor-base/activity-editor-base.component';
import { FeeDataEntity } from '../../../../../models/data-entities';
import {
  WorkflowService,
  WorkflowContextService
} from '../../../../../services';
import { PaymentActivity } from 'src/app/models/activities';
import { Subscription } from 'rxjs';
import { PaymentProcessorType } from 'src/app/models';

@Component({
  selector: 'wm-payment-activity-editor',
  templateUrl: './payment-activity-editor.component.html',
  styleUrls: ['./payment-activity-editor.component.css']
})
export class PaymentActivityEditorComponent extends ActivityEditorBaseComponent
  implements OnInit, OnDestroy {
  potentialFees: FeeDataEntity[];
  paymentActivity: PaymentActivity;
  configComponents: any;
  activityConfigSub: Subscription;
  showAccountConfig: boolean;

  @ViewChild('activityConfigurationEditor', { read: ViewContainerRef, static: true })
  itemViewContainer: ViewContainerRef;
  activityEditorComponentRef: ComponentRef<
    PaymentAccountActivityConfigBaseComponent
  >;

  ngOnDestroy(): void {
    if (this.activityConfigSub) {
      this.activityConfigSub.unsubscribe();
    }
  }

  constructor(
    @Inject(forwardRef(() => WorkflowService))
    private _workflowSvc: WorkflowService,
    @Inject(forwardRef(() => WorkflowContextService))
    private _context: WorkflowContextService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private ref: ChangeDetectorRef
  ) {
    super();

    // Setup the component types for each processor type
    this.configComponents = {};
    this.showAccountConfig = false;

    this.configComponents[
      PaymentProcessorType.PointNPay
    ] = PaymentAccountActivityConfigPointnpayComponent;
  }

  ngOnInit() {
    this.paymentActivity = <PaymentActivity>this.activity;

    // load the fee configuration editor if the selected account supports fee configuration
    this.loadActivityAccountConfigurationEditor();
  }

  clearActivityConfiguration() {
    this.paymentActivity.activityAccountConfiguration = null;
    if (this.itemViewContainer) {
      this.itemViewContainer.clear();
    }
    this.showAccountConfig = false;
  }

  /*
  load the configuration editor for the payment provider for fee level configuration
  */
  loadActivityAccountConfigurationEditor() {
    if (this.paymentActivity.activityAccountConfiguration) {
      this.showAccountConfig = true;
      const type = this.paymentActivity.activityAccountConfiguration.type;

      if (this.configComponents[type]) {
        let compType: Type<PaymentAccountActivityConfigBaseComponent>;

        compType = this.configComponents[type];

        if (compType && this.itemViewContainer) {
          this.itemViewContainer.clear();
          const componentFactory = this.componentFactoryResolver.resolveComponentFactory<
            PaymentAccountActivityConfigBaseComponent
          >(compType);

          this.activityEditorComponentRef = this.itemViewContainer.createComponent(
            componentFactory
          );

          this.activityEditorComponentRef.instance.workflow = this.workflow;
          this.activityEditorComponentRef.instance.activity = this.activity;
          this.activityEditorComponentRef.instance.paymentActivity = this.paymentActivity;
          this.activityConfigSub = this.activityEditorComponentRef.instance.configUpdated.subscribe(
            config => {
              this.paymentActivity.activityAccountConfiguration = config;
            }
          );
        }

        this.form.updateValueAndValidity();
        this.ref.detectChanges();
      } else {
        this.clearActivityConfiguration();
      }
    } else {
      this.clearActivityConfiguration();
    }
  }
}

import { AppointmentActivityModel } from './../../../../../models/activities/appointment-activity';
import { WorkflowService } from './../../../../../services/workflow.service';
import { ActivityEditorBaseComponent } from './../../activity-editor-base/activity-editor-base.component';
import { Component, OnInit, Inject, forwardRef } from '@angular/core';
import {
  UntypedFormControl,
  Validators,
  AbstractControl,
  ValidationErrors
} from '@angular/forms';

@Component({
  selector: 'wm-appointment-activity-editor',
  templateUrl: './appointment-activity-editor.component.html',
  styleUrls: ['./appointment-activity-editor.component.css']
})
export class AppointmentActivityEditorComponent
  extends ActivityEditorBaseComponent
  implements OnInit {
  constructor(
    @Inject(forwardRef(() => WorkflowService))
    public workflowSvc: WorkflowService
  ) {
    super();
  }

  model: AppointmentActivityModel;

  ngOnInit() {
    if (this.activity) {
      this.model = this.activity.model as AppointmentActivityModel;
    }

    if (this.form) {
      this.form.addControl(
        'appt_date',
        new UntypedFormControl('', Validators.required)
      );
      this.form.addControl(
        'appt_location',
        new UntypedFormControl('', Validators.required)
      );
      this.form.addControl(
        'duration_minutes',
        new UntypedFormControl(
          '',
          (control: AbstractControl): ValidationErrors | null => {
            // required if allDay is false
            if (!this.model.allDay && !control.value) {
              const error: ValidationErrors = {
                required: true
              };

              return error;
            }

            return null;
          }
        )
      );
      this.form.addControl(
        'allDay',
        new UntypedFormControl(false, Validators.nullValidator)
      );
    }
  }
}

<ng-container *ngIf="inspection">
  <div class="row">
    <div class="col">
      <div class="card mt-4 mb-4">
        <div class="card-body">
          <h6 class="card-title">
            Inspection:
            {{ inspection.permitNumber }}
            ({{ InspectionStatus[inspection.status] }})
            <!-- {{ registration.type.name }} ({{
                    RegistrationStatus[registration.status]
                  }}) -->
          </h6>
          <div class="form-group">
            <label for="createdOn" class="bmd-label-static"
              >Requestor Name</label
            >
            <input
              type="text"
              readonly
              class="form-control-plaintext"
              id="createdOn"
              [value]="inspection.requestorName"
            />
          </div>

          <div class="form-group">
            <label for="createdOn" class="bmd-label-static"
              >Requestor Address</label
            ><br />
            <ng-containter *ngIf="inspection.requestorAddress">
              <address>
                <strong>{{ inspection.requestorName }}</strong
                ><br />
                {{ inspection.requestorAddress.address1 }}<br />
                {{ inspection.requestorAddress.address2 }}<br />
                {{ inspection.requestorAddress.city }},
                {{ inspection.requestorAddress.state }}
                {{ inspection.requestorAddress.zip }}<br />
              </address>
            </ng-containter>
            <ng-container *ngIf="!inspection.requestorAddress">
              <p>&nbsp;</p>
            </ng-container>
            <label for="createdOn" class="bmd-label-static"
              >Requestor Phone Number</label
            ><br />
            <abbr title="Phone">P:</abbr>
            {{ inspection.requestorPhoneNumber }}<br />
            <label for="createdOn" class="bmd-label-static"
              >Requestor Email</label
            ><br />
            <span *ngIf="inspection.requestorEmail">
              <abbr title="Email">E:</abbr>
              <a href="mailto:{{ inspection.requestorEmail }}">{{
                inspection.requestorEmail
              }}</a>
            </span>
          </div>
          <div class="form-group">
            <label for="expiresOn" class="bmd-label-static">Scheduled On</label>
            <input
              type="text"
              readonly
              class="form-control-plaintext"
              id="expiresOn"
              [value]="inspection.scheduledOn | date: 'M/d/yyyy'"
            />
          </div>
          <div
            class="form-group"
            *ngIf="inspection.scheduledOnIncludesTime !== false"
          >
            <label for="expiresOn" class="bmd-label-static">Time</label>
            <input
              type="text"
              readonly
              class="form-control-plaintext"
              id="expiresOn"
              [value]="inspection.scheduledOn | date: 'shortTime'"
            />
          </div>
          <wm-contractor-custom-field-list
            [customFields]="inspection.customFields"
            [form]="form"
            [isReadOnly]="true"
            #customFields
          ></wm-contractor-custom-field-list>
        </div>
      </div>
    </div>

    <div class="col">
      <div class="card mt-4 mb-4">
        <div class="card-body">
          <h6 class="card-title">
            Documents2
          </h6>
          <wm-inspection-document-list
            [documents]="documents"
            (saved)="saveDocument($event)"
            [parentId]="inspectionId"
            (deleted)="deleteDocument($event)"
            (added)="addNewDocuments($event)"
          ></wm-inspection-document-list>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <div class="card mt-12 mb-12">
        <div class="card-body">
          <h6 class="card-title">
            Notes
          </h6>
          <table class="table">
            <tr>
              <th>Notes</th>
              <th>Created By</th>
              <th>Created On</th>
            </tr>
            <tr *ngFor="let note of inspection.notes">
              <td>{{ note.notes }}</td>
              <td>{{ note.createdBy }}</td>
              <td>{{ note.createdOn | date: 'M/d/yyyy h:mm a' }}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</ng-container>

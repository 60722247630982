import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {
  PaymentProcessorConfigurationBase,
  PayGovPaymentProcessorConfiguration
} from '../models';
import { Observable, of } from 'rxjs';
import { PaymentProcessorConfigPayGovComponent } from '../components/payment-processor/payment-processor-config-paygov/payment-processor-config-paygov.component';

@Injectable()
export class PaymentProcessorConfigurationResolver
   {
  constructor(private router: Router) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<PaymentProcessorConfigurationBase> {
    //route.component = new PaymentProcessorConfigPayGovComponent();
    return of(new PayGovPaymentProcessorConfiguration());
    //let id = route.paramMap.get('id');

    //return this.cs.getCrisis(id).pipe(
    //  take(1),
    //  map(crisis => {
    //    if (crisis) {
    //      return crisis;
    //    } else { // id not found
    //      this.router.navigate(['/crisis-center']);
    //      return null;
    //    }
    //  })
    //);
  }
}

import { ItemSearchOptionField } from '../components/filter-list/models/filterClasses';
import { FilterBuilderOutput } from './filter-builder';

export class GridSettings {
  filters?: FilterBuilderOutput;
  legacyFilters?: ItemSearchOptionField[];
  pageSize?: number;
  pageNumber?: number;
  sortField?: string;
  sortDescending?: boolean;
  columnOptions?: ColumnOption[];

  constructor(options?: Partial<GridSettings>) {
    if (options) {
      Object.assign(this, options);
    }
  }
}

export class ColumnOption {
  name: string;
  label: string;
  checked: boolean;
  includeInExport: boolean;

  constructor(options?: Partial<ColumnOption>) {
    if (options) {
      Object.assign(this, options);
    }
  }
}

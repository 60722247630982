import { Subscription } from 'rxjs';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { MapService } from '../../../../models';
import { WorkflowContextService, DataService } from '../../../../services';
import { Router } from '@angular/router';

@Component({
  selector: 'wm-map-service-list-view',
  templateUrl: './map-service-list-view.component.html',
  styleUrls: ['./map-service-list-view.component.css']
})
export class MapServiceListViewComponent implements OnInit, OnDestroy {
  mapServices: MapService[];
  clientSub$: Subscription;

  constructor(
    private _context: WorkflowContextService,
    private _service: DataService,
    private _router: Router
  ) {}

  ngOnDestroy(): void {
    if (this.clientSub$) {
      this.clientSub$.unsubscribe();
    }
  }

  ngOnInit() {
    const load = () => {
      this._service
        .getMapServices(this._context.client.id)
        .subscribe(mapServices => (this.mapServices = mapServices));
    };

    if (this._context.client) {
      load();
    } else {
      this.clientSub$ = this._context.client$.subscribe(() => {
        load();
      });
    }
  }
  removeMapService(e: MapService) {
    const index = this.mapServices.indexOf(e);
    this.mapServices.splice(index, 1);
  }
  addMapService() {
    this._router.navigate([
      '/admin/jurisdiction',
      this._context.client.id,
      'settings',
      'maps',
      'add'
    ]);
  }
}

<app-loader *ngIf="!roles"></app-loader>

<div *ngIf="roles && roles.length === 0">
  <p>No roles to display</p>
</div>

<div *ngIf="roles && roles.length > 0">
  <ul class="list-group">
    <li
      *ngFor="let role of roles"
      class="list-group-item"
      style="cursor: pointer"
      (click)="editRole(role)"
    >
      {{ role.name }}
    </li>
  </ul>
</div>

import { Activity, ActivityModel } from './activities';
import { WorkflowApplication } from './workflow-application';

export class StartApplicationResponse {
  applicationId: string;
  activity: Activity<ActivityModel>;
  application: WorkflowApplication;

  constructor(options?: Partial<StartApplicationResponse>) {
    if (options) {
      Object.assign(this, options);
    }
  }
}

import { FormActivity, FormActivityModel, FormLayoutModes } from '.';
import {
  FreeFormTextDataEntity,
  ListDataEntity,
  ControlType,
  RestrictedFormat
} from '../data-entities';
import { Utilities } from '../../services';

export class ContractorInfoFormActivity extends FormActivity {
  constructor(options?: Partial<ContractorInfoFormActivity>) {
    super(options);

    this.type = 'contractor-info-form-activity';
    this.name = 'Contractor Info Form Activity';
    this.description =
      'Use this activity to add fields to your workflow that pertain to the Contractor.';

    if (options) {
      Object.assign(this, options);
    }

    this.model = new FormActivityModel(options ? options.model : null);

    this.model.screenName = this.model.screenName || 'Contractor Info';
  }
}

import { DaysOfWeekAvailabilityProviderOptions } from '../days-of-week-availability-provider';
import { AvailabilityProvider } from '../availability-provider';
import { Directive } from "@angular/core";

@Directive()
export class DaysOfWeekAvailabilityProvider extends AvailabilityProvider {
  getAvailableDates(
    currentDate: Date,
    endDate: Date,
    options: DaysOfWeekAvailabilityProviderOptions
  ): Date[] {
    return null;
  }
  getAvailableTimes(
    currentDate: Date,
    options?: Partial<DaysOfWeekAvailabilityProviderOptions>
  ): Date[] {
    return null;
  }
}

import {
  DecisionActivity,
  ConditionDataDetails
} from './../../../../models/activities/decision-activity';
import { ActivityView } from 'src/app/views/master-views/app.view/app.view.component';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'wm-decision-activity-view',
  templateUrl: './decision-activity-view.component.html',
  styleUrls: ['./decision-activity-view.component.css']
})
export class DecisionActivityViewComponent extends ActivityView
  implements OnInit {
  constructor() {
    super();
  }

  conditionAppData: ConditionDataDetails[];

  ngOnInit() {
    this.conditionAppData = (<DecisionActivity>this.activity).conditionAppData;
  }
}

import {
  Component,
  OnInit,
  Output,
  Input,
  EventEmitter,
  ViewChild
} from '@angular/core';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';

import { Client, Role } from '../../../models';
import { Observable, Subject, merge, of } from 'rxjs';
import { UntypedFormControl } from '@angular/forms';
import { SecurityService } from '../../../services';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  catchError
} from 'rxjs/operators';

@Component({
  selector: 'wm-associate-role',
  templateUrl: './associate-role.component.html',
  styleUrls: ['./associate-role.component.css']
})
export class AssociateRoleComponent implements OnInit {
  @Output() roleAssociated: EventEmitter<Role> = new EventEmitter<Role>();
  @Output() canceled: EventEmitter<any> = new EventEmitter<any>();
  @Input() client: Client;
  roles: Observable<Role[]>;
  searchText = new UntypedFormControl();
  selectedRole: Role = null;
  showList = false;

  @ViewChild('instance', { static: true }) instance: NgbTypeahead;

  focus$ = new Subject<string>();
  click$ = new Subject<string>();
  searchText$: EventEmitter<string> = new EventEmitter<string>();

  constructor(private _securitySvc: SecurityService) {
  }

  formatter = (x: Role) => x.name;

  search = (text$: Observable<string>) => {
    return text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap(term => {
        return this._securitySvc.getRoles(this.client);
      })
    );
  }

  ngOnInit() { }

  selectRole(role: Role) {
    this.showList = false;
    this.selectedRole = role;
    this.searchText.updateValueAndValidity({ onlySelf: true });
  }
  associateRole() {
    this.roleAssociated.next(this.selectedRole);
  }
  cancel() {
    this.selectedRole = null;
    this.showList = false;
    this.canceled.next(null);
  }
}

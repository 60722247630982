import { ToastrService } from 'ngx-toastr';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Tag } from 'src/app/models/tag';
import { Observable, Subscription } from 'rxjs';
import { SystemService, WorkflowContextService } from 'src/app/services';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';

@Component({
  selector: 'wm-tag-editor',
  templateUrl: './tag-editor.component.html',
  styleUrls: ['./tag-editor.component.css']
})
export class TagEditorComponent implements OnInit, OnDestroy {
  constructor(
    private _systemSvc: SystemService,
    private toastr: ToastrService,
    private _context: WorkflowContextService
  ) {}

  form: UntypedFormGroup;
  newTagName: string = null;
  tags: Tag[];
  clientSub: Subscription;

  ngOnDestroy(): void {
    if (this.clientSub) {
      this.clientSub.unsubscribe();
    }
  }

  showNewTag() {
    this.newTagName = '';
  }

  cancelTag() {
    this.newTagName = null;
  }

  addTag() {
    const newTag = new Tag();
    newTag.name = this.newTagName;
    newTag.clientId = this._context.client.id;

    this._systemSvc.saveTag(newTag).subscribe(tag => {
      this.newTagName = null;

      this.loadTags();
    });
  }

  removeTag(tag: Tag) {
    this._systemSvc.deleteTag(tag).subscribe(() => {
      this.loadTags();
    });
  }

  loadTags() {
    const load = () => {
      this._systemSvc.getTags(this._context.client.id).subscribe(tags => {
        this.tags = tags;
      });
    };

    if (this._context.client) {
      load();
    } else {
      this.clientSub = this._context.client$.subscribe(() => {
        load();
      });
    }
  }

  async orderChange() {
    await Promise.all(
      this.tags.map(async (tag, i) => {
        tag.position = i;

        const newTag = await this._systemSvc.saveTag(tag).toPromise();

        return newTag;
      })
    );

    this.toastr.success('Saved!');
  }

  ngOnInit() {
    this.form = new UntypedFormGroup({
      newTagName: new UntypedFormControl('', Validators.required)
    });

    this.loadTags();
  }
}

import { WorkflowService } from './../../../../../services/workflow.service';
import { WorkflowContextService, ValidationService } from 'src/app/services';
import { DragulaService } from 'ng2-dragula';
import { UntypedFormArray, UntypedFormBuilder, Validators } from '@angular/forms';
import { FormActivityEditorComponent } from './../../form-activity-editor/form-activity-editor.component';
import {
  Component,
  OnInit,
  Inject,
  Input,
  OnDestroy,
  ComponentFactoryResolver,
  forwardRef,
  ChangeDetectorRef
} from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ListOption } from '../../../../../models/listOption';
import { FormGroup, UntypedFormControl } from '@angular/forms';
import { InspectionSimpleActivity } from 'src/app/models/activities/inspection-simple-activity';

@Component({
  selector: 'wm-inspection-simple-activity-editor',
  templateUrl: './inspection-simple-activity-editor.component.html',
  styleUrls: ['./inspection-simple-activity-editor.component.css']
})
export class InspectionSimpleActivityEditorComponent
  extends FormActivityEditorComponent
  implements OnInit, OnDestroy {
  @Input() activity: InspectionSimpleActivity;

  get options() {
    return this.form.get('opt') as UntypedFormArray;
  }

  os: ListOption[] = [];

  name = 'InspectionDropDown';

  // defaultOption: any = null;
  private sub: any;
  constructor(
    @Inject(forwardRef(() => WorkflowService))
    private workflowSvc: WorkflowService,
    @Inject(forwardRef(() => WorkflowContextService))
    context: WorkflowContextService,
    private fb: UntypedFormBuilder,
    dragulaSvc: DragulaService,
    ref: ChangeDetectorRef,
    toastr: ToastrService
  ) {
    super(workflowSvc, context, toastr, fb, dragulaSvc, ref);
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }
  ngOnInit() {
    const roleControl = this.form.controls['responsibleRole'];
    this.sub = roleControl.valueChanges.subscribe(selectedValue => {
      this.activity.model.responsibleRoleId = selectedValue;
      this.loading.emit(true);
      this.saved.emit(this.activity);
    });

    this.activity.inspectorOptions.map((value, index) => {
      if (value.hasOwnProperty('item1')) {
        this.os.push({
          text: value.item2,
          value: value.item1,
          position: index,
          customListId: '',
          id: index.toString()
        });
      }
    });

    this.form.addControl(
      'opt',
      new UntypedFormControl(
        '',
        ValidationService.requiredIfDeNotPresent(
          this.activity,
          'INSP_SELECTED_INSPECTOR',
          null
        )
      )
    );
    this.form.get('opt').markAsDirty();
    if (this.activity.defaultOption) {
      this.form.controls['opt'].setValue(this.activity.defaultOption);
    }
  }

  inspectorRequired() {
    let required = false;
    this.activity.model.getEntities().forEach((de: any, i) => {
      // the only way the default option is valid when null is if the inspectorDefaultDE is required.
      if (de.templateCode === 'INSP_SELECTED_INSPECTOR') {
        if (de.isRequired) {
          // if the Inspector DE is required, then we don't need to select a default.
          // console.log('dont show ME!');
          required = true;
        }
      }
    });
    return !required;
  }
  addItem(checked: boolean, value: any) {
    // console.log(value);
    // value ='DE0F2CFA-1F79-42CA-9C39-62265B86E7AE';
    this.activity.defaultOption = value;
  }
  isDefaultSelected(i: any): boolean {
    if (i.value === this.activity.defaultOption) {
      return true;
    }
    return false;
  }
}

import { Component, OnInit } from '@angular/core';
import { DataEntityEditorBaseComponent } from '../../data-entity-editor-base/data-entity-editor-base.component';

@Component({
  selector: 'wm-signature-data-entity-editor',
  templateUrl: './signature-data-entity-editor.component.html',
  styleUrls: ['./signature-data-entity-editor.component.css']
})
export class SignatureDataEntityEditorComponent
  extends DataEntityEditorBaseComponent
  implements OnInit {
  constructor() {
    super();
  }

  ngOnInit() {}
}

import { eGOVPaymentProcessorConfiguration } from './egov/egov-payment-processor-configuration';
import { KanPayPaymentProcessorConfiguration } from './kanpay/kanpay-payment-processor-configuration';
import { PaymentProcessorConfigurationBase } from './payment-processor-configuration-base';
import { PayGovPaymentProcessorConfiguration } from './paygov';
import { CardpointePaymentProcessorConfiguration } from './cardpointe/cardpointe-payment-processor-configuration';
import { VpsPaymentProcessorConfiguration } from './vps/vps-payment-processor-configuration';

export enum PaymentProcessorType {
  Cardpointe = 0,
  GovPay = 1,
  OfficialPayments = 2,
  PayGov = 3,
  PaymentStub = 4,
  SecurePay = 5,
  VPS = 6,
  PointNPay = 7,
  ManualPayment = 8,
  NMI = 9,
  PSN = 15,
  Forte = 10,
  KanPay = 11,
  Municipay = 13,
  Square = 14,
  eGOV = 16,
  CertifiedPayments = 17
}

export class PaymentProcessor {
  id: string;
  name: string;
  description: string;
  type: PaymentProcessorType;
  configuration: PaymentProcessorConfigurationBase;
  testConfiguration: PaymentProcessorConfigurationBase;
  inTestMode: boolean;

  constructor(options?: Partial<PaymentProcessor>) {
    if (options) {
      Object.assign(this, options);
    }
  }

  initConfig(
    config: PaymentProcessorConfigurationBase,
    testConfig: PaymentProcessorConfigurationBase
  ) {
    switch (this.type) {
      case PaymentProcessorType.PayGov:
        this.configuration = new PayGovPaymentProcessorConfiguration(config);
        this.testConfiguration = new PayGovPaymentProcessorConfiguration(
          testConfig
        );
        break;
      case PaymentProcessorType.Cardpointe:
        this.configuration = new CardpointePaymentProcessorConfiguration(
          config
        );
        this.testConfiguration = new CardpointePaymentProcessorConfiguration(
          testConfig
        );
        break;
      case PaymentProcessorType.VPS:
        this.configuration = new VpsPaymentProcessorConfiguration(config);
        this.testConfiguration = new VpsPaymentProcessorConfiguration(
          testConfig
        );
        break;
      case PaymentProcessorType.KanPay:
        this.configuration = new KanPayPaymentProcessorConfiguration(config);
        this.testConfiguration = new KanPayPaymentProcessorConfiguration(
          testConfig
        );
        break;
      case PaymentProcessorType.eGOV:
        this.configuration = new eGOVPaymentProcessorConfiguration(config);
        this.testConfiguration = new eGOVPaymentProcessorConfiguration(
          testConfig
        );
        break;
      default:
        this.configuration = new PaymentProcessorConfigurationBase(config);
        this.testConfiguration = new PaymentProcessorConfigurationBase(
          testConfig
        );
        break;
    }
  }
}

import { UntypedFormBuilder, Validators } from '@angular/forms';
import { PaymentAccountConfigBaseComponent } from './../payment-account-config-base/payment-account-config-base.component';
import { Component, Input, OnInit } from '@angular/core';
import { SquarePaymentProcessorAccountConfiguration } from 'src/app/models/payment-providers/square/square-payment-processor-account-configuration';

@Component({
  selector: 'wm-payment-account-config-square',
  templateUrl: './payment-account-config-square.component.html',
  styleUrls: ['./payment-account-config-square.component.css']
})
export class PaymentAccountConfigSquareComponent
  extends PaymentAccountConfigBaseComponent
  implements OnInit {
  @Input() config: SquarePaymentProcessorAccountConfiguration;

  constructor(_fb: UntypedFormBuilder) {
    super(_fb);
    this.showUsername = false;
    this.showPassword = false;
  }

  ngOnInit() {
    this.addValidation(
      'accessToken',
      Validators.required,
      this.config.accessToken
    );
    this.addValidation(
      'locationId',
      Validators.required,
      this.config.locationId
    );
  }
}

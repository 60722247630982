import {
  Component,
  OnInit,
  Input,
  ComponentFactoryResolver,
  Inject,
  forwardRef
} from '@angular/core';
import { GenerateClientApplicationNumberDataEntity } from '../../../../../models/data-entities/client-app-number-data-entity';
import { DataEntityInputComponent } from '../../data-entity-input/data-entity-input.component';
import { WorkflowService } from 'src/app/services/workflow.service';
import { WorkflowContextService } from 'src/app/services/workflow-context.service';
import { Workflow } from 'src/app/models';

@Component({
  selector: 'wm-client-app-number-data-entity-input',
  templateUrl: './client-app-number-data-entity-input.component.html',
  styleUrls: ['./client-app-number-data-entity-input.component.css']
})
export class GenerateClientApplicationNumberDataEntityInputComponent
  extends DataEntityInputComponent
  implements OnInit {
  @Input() entity: GenerateClientApplicationNumberDataEntity;
  @Input() isPreview: boolean;

  constructor(
    componentFactoryResolver: ComponentFactoryResolver,
    @Inject(forwardRef(() => WorkflowContextService))
    private _context: WorkflowContextService,
    @Inject(forwardRef(() => WorkflowService))
    private _workflowSvc: WorkflowService
  ) {
    super(componentFactoryResolver);
  }

  getNumberPrefix(workflow: Workflow) {
    if (workflow.numberPrefixCriteria.length > 1) {
      return 'Conditional';
    } else {
      return workflow.numberPrefixCriteria[0].value;
    }
    // workflow.numberPrefix
  }

  ngOnInit() {
    if (this.isPreview && !this.entity.value) {
      this._workflowSvc
        .getWorkflow(this._context.workflow.id)
        .subscribe(workflow => {
          this.entity.value = `${this.getNumberPrefix(workflow)}9999`;
        });
    }
  }
}

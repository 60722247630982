<app-loader *ngIf="!workflows"></app-loader>

<table class="table">
  <tr>
    <th>Name</th>
    <th>Last Published</th>
    <th></th>
  </tr>
  <tr *ngFor="let wf of workflows">
    <td class="align-middle">
      <a
        [routerLink]="
          clientId
            ? ['/admin/jurisdiction', clientId, 'workflows', 'details', wf.id]
            : ['/admin', 'global', 'workflows', 'edit', wf.id]
        "
      >
        {{ wf.version.name }}
      </a>
    </td>
    <td class="align-middle">
      <ng-container *ngIf="wf.isEnabled">
        <span *ngIf="wf.lastPublishedOn">
          {{ wf.lastPublishedOn | date: 'MM/dd/y, hh:mm a' }}
        </span>
        <span
          *ngIf="!wf.lastPublishedOn"
          class="badge badge-pill badge-warning"
        >
          Never published
        </span>
        <span
          *ngIf="canPublish(wf) && isPublished(wf) && wf.lastPublishedOn"
          class="badge badge-pill badge-success"
        >
          Changes available
        </span>
      </ng-container>

      <span *ngIf="!wf.isEnabled" class="badge badge-pill badge-danger">
        Disabled
      </span>
    </td>
    <td>
      <wm-workflow-actions-menu
        [workflow]="wf"
        [clientId]="clientId"
        [loadGlobal]="loadGlobal"
        (onRestore)="onRestore.emit($event)"
        (onDisable)="onDisable.emit($event)"
        (onEnable)="onEnable.emit($event)"
        (onPublish)="onPublish.emit($event)"
        (onArchive)="onArchive.emit($event)"
        (onCopy)="onCopy.emit($event)"
        (toggleVisible)="toggleVisible.emit($event)"
      ></wm-workflow-actions-menu>
    </td>
  </tr>

  <tr *ngIf="workflows && workflows.length === 0">
    <td>No workflows to display</td>
  </tr>
</table>

import { FormActivity, FormActivityModel } from '.';

export class ContractorRegistrationInfoFormActivity extends FormActivity {
  constructor(options?: Partial<ContractorRegistrationInfoFormActivity>) {
    super(options);

    this.type = 'contractor-registration-info-form-activity';
    this.name = 'Contractor Registration Info Form Activity';
    this.description =
      'Use this activity to add fields to your workflow that pertain to the Contractor Registration.';


    if (options) {
      Object.assign(this, options);
    }

    this.model = new FormActivityModel(options ? options.model : null);
    this.model.screenName =
      this.model.screenName || 'Contractor Registration Info';
  }
}

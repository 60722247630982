<form>
  <div *ngIf="isFormSet">
    <div [formGroup]="form" class="form-group">
      <ng-container
        *ngIf="
          (!availableExternalDataSources ||
            availableExternalDataSources.length === 0) &&
          !entity.externalDataSource
        "
      >
        <i>
          There are no external data sources available for this Data Entity
        </i>
      </ng-container>
      <ng-container
        *ngIf="
          (availableExternalDataSources &&
            availableExternalDataSources.length > 0) ||
          entity.externalDataSource
        "
      >
        <label class="bmd-label-floating" for="externalDataSource">
          Get value from an external data source?
        </label>
        <select
          class="form-control"
          formControlName="externalDataSource"
          name="externalDataSource"
          (change)="changeExternalSource()"
          [(ngModel)]="externalDataSource"
        >
          <option [ngValue]="null">None</option>
          <option
            *ngFor="let eds of availableExternalDataSources"
            [ngValue]="eds"
          >
            {{ eds.label }}
          </option>
        </select>
      </ng-container>
    </div>

    <div class="form-group card p-2" *ngIf="externalDataSource">
      <!-- Search parcel source options -->
      <div
        [formGroup]="form"
        *ngIf="externalDataSource.type === parcelSearchEDStypeCode"
      >
        Field from <b>{{ externalDataSource.label }}</b>
        <select
          class="form-control"
          formControlName="parcelSearchExternalDataField"
          name="parcelSearchExternalDataField"
          (change)="handleChanges()"
          [(ngModel)]="externalDataSource.sourceConfig.selectedOption"
        >
          <option
            *ngFor="let psOption of externalDataSource.sourceConfig.options"
            [ngValue]="psOption"
          >
            {{ psOption.fieldAlias }}
          </option>
        </select>
      </div>

      <!-- Previous Registration Data source options -->
      <ng-container *ngIf="externalDataSource.type === registrationEDStypeCode">
        <span>
          Select previous registration field(s)
          <wm-help-text
            placement="right"
            helpText="Each of the Renewable workflows listed here uses this workflow as its Renewal workflow.  Select a field from any or each of the workflows to carry over to this Data Entity in a renewal application."
          ></wm-help-text>
        </span>

        <br />

        <div
          class="mt-4"
          *ngFor="
            let option of externalDataSource.sourceConfig.options;
            let i = index
          "
        >
          <ng-container *ngIf="!option.isThisWorkflow">
            <b>{{ option.sourceWorkflowDraftVersionName }}</b>
            <div class="mt-1">
              <wm-data-entity-autocomplete
                id="'registrationExternalDataSource-' + option.sourceWorkflowId"
                [showFormulaEditor]="false"
                [clearOnSelect]="true"
                [form]="form"
                [model]="mappedValues[i]"
                [required]="false"
                [workflowId]="option.sourceWorkflowId"
                [workflowVersionId]="option.sourceWorkflowDraftVersionId"
                [types]=""
                [activityTypes]="renewableWorkflowForms"
                (valueUpdate)="changeParentRenewableMapping(option, $event)"
              ></wm-data-entity-autocomplete>
            </div>
          </ng-container>

          <div [formGroup]="form" *ngIf="option.isThisWorkflow">
            <span>
              <b>This workflow</b>&nbsp;
              <wm-help-text
                placement="right"
                helpText="Since this workflow uses itself as its Renewal workflow, values from this Data Entity can be carried over to renewal applications."
              ></wm-help-text>
            </span>

            <select
              class="form-control"
              formControlName="mapFromThisWorkflow"
              (change)="handleChanges()"
              [(ngModel)]="
                externalDataSource.sourceConfig.selectedMappings
                  .mapFromThisWorkflow
              "
            >
              <option [ngValue]="false">(Do not map to this workflow)</option>
              <option [ngValue]="true">{{ entity.templateCode }}</option>
            </select>
          </div>
        </div>
      </ng-container>

      <wm-control-message
        [control]="form.controls['registrationExternalDataField']"
      ></wm-control-message>

      <!-- Can Edit Mapped Value toggle -->
      <div [formGroup]="form" class="switch mt-5">
        <label>
          <input
            type="checkbox"
            formControlName="canEditExternalValue"
            [(ngModel)]="entity.canEditExternalValue"
          />
          Can edit mapped value
        </label>
      </div>
    </div>
  </div>
</form>

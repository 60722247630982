import { FormActivity, FormActivityModel } from '.';

export class RenewalDecisionActivity extends FormActivity {
  hideExpirationDate: boolean;
  showHideExpirationDateOption: boolean;

  constructor(options?: Partial<RenewalDecisionActivity>) {
    super(options);

    this.type = 'renewal-decision-activity';
    this.name = 'Renewal Decision Activity';
    this.description =
      'Use this activity to make a decision on renewable applications.';

    if (options) {
      Object.assign(this, options);
    }

    this.model = new FormActivityModel(options ? options.model : null);

    this.model.screenName = this.model.screenName || 'Renewable Decision';
  }
}

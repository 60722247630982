import { UntypedFormBuilder, Validators } from '@angular/forms';
import { KanPayPaymentProcessorAccountConfiguration } from './../../../models/payment-providers/kanpay/kanpay-payment-processor-account-configuration';
import { Component, Input, OnInit } from '@angular/core';
import { PaymentAccountConfigBaseComponent } from '../payment-account-config-base/payment-account-config-base.component';

@Component({
  selector: 'wm-payment-account-config-kanpay',
  templateUrl: './payment-account-config-kanpay.component.html',
  styleUrls: ['./payment-account-config-kanpay.component.css']
})
export class PaymentAccountConfigKanpayComponent
  extends PaymentAccountConfigBaseComponent
  implements OnInit {
  @Input() config: KanPayPaymentProcessorAccountConfiguration;

  constructor(fb: UntypedFormBuilder) {
    super(fb);
    this.showPassword = false;
  }

  ngOnInit() {
    this.addValidation(
      'applicationId',
      Validators.required,
      this.config.applicationId
    );
    this.addValidation(
      'serviceCode',
      Validators.required,
      this.config.serviceCode
    );
    this.addValidation(
      'merchantID',
      Validators.required,
      this.config.merchantID
    );
    this.addValidation(
      'merchantKey',
      Validators.required,
      this.config.merchantKey
    );
  }
}

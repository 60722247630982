import { Activity, ContainerActivity, ActivityModel } from './activity';
import { SequentialActivity } from '.';

export class LoopingActivity extends ContainerActivity<ActivityModel> {
  constructor(options?: { [key: string]: any }) {
    super(options);

    this.type = 'looping-activity';
    this.name = 'Looping Steps';
    this.activities = [new SequentialActivity()];
    this.description =
      'Use this activity to define a set of sequential activities that loop until a defined condition is satisfied.';
    this.isContainer = true;
  }
}

<form [formGroup]="form" *ngIf="processorConfig">
  <div class="form-group">
    <label class="bmd-label-floating" for="url">URL</label>
    <input
      type="text"
      class="form-control"
      formControlName="url"
      name="url"
      [(ngModel)]="processorConfig.url"
    />
  </div>
</form>

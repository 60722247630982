import { ToastrService } from 'ngx-toastr';
import {
  FilterBuilderParam,
  FilterBuilderOutput,
  Filter
} from 'src/app/models/filter-builder';
import { PagedFeeRecipientReportResponse } from './../../../../models/financial-reports';
import { WorkflowContextService } from 'src/app/services';
import { Component, OnInit } from '@angular/core';
import { SystemService } from 'src/app/services';
import { Utilities } from 'src/app/services';
import { ColumnOption, GridSettings } from 'src/app/models/grid-settings';
import { TableColumn } from '@swimlane/ngx-datatable';
import { ComponentIds } from 'src/app/models/component-ids';

@Component({
  selector: 'wm-fee-recipients-report',
  templateUrl: './fee-recipients.component.html',
  styleUrls: ['./fee-recipients.component.css']
})
export class FeeRecipientsComponent implements OnInit {
  id = ComponentIds.FEE_RECIPIENTS;
  loading = true;
  report: PagedFeeRecipientReportResponse;
  params: FilterBuilderParam[] = [
    {
      name: 'Recipient Name',
      id: 'recipient'
    },
    {
      name: 'Payment Type',
      id: 'paymentMethod',
      types: ['is', 'in'],
      options: ['Online', 'CreditCardPOS', 'Check', 'Cash']
    },
    {
      name: 'Confirmed Date',
      id: 'timeframe',
      inputType: 'date',
      types: ['range']
    }
  ];
  filters: FilterBuilderOutput = {};

  public useExcelFormat: boolean;

  columns: TableColumn[];
  availableColumns: TableColumn[];
  columnOptions: ColumnOption[] = [];
  exportColumns: string[];
  rows: any[];
  public page = {
    // The number of elements in the page
    size: 10,
    // The total number of elements
    totalElements: 0,
    // The total number of pages
    totalPages: 0,
    // The current page number
    pageNumber: 0
  };
  public sortField: string;
  public sortDescending = false;

  // column prop strings to be hidden on the grid by default
  defaultHiddenColumns: string[] = [];
  // column prop strings that should have no option to hide in the grid, and are not exportable (such as button columns like Refund or Actions)
  actionColumns: string[] = [];
  // column prop strings to be excluded from exports by default
  defaultExportExludedColumns: string[] = [];

  constructor(
    private systemSvc: SystemService,
    private toast: ToastrService,
    private _context: WorkflowContextService
  ) {}

  ngOnInit() {
    this.columns = [
      {
        prop: 'recipient',
        name: 'Recipient'
      },
      {
        prop: 'payments',
        name: 'Payments'
      },
      {
        prop: 'amount',
        name: 'Amount'
      },
      {
        prop: 'jurisdiction',
        name: 'Jurisdiction'
      }
    ];
    this.setAvailableColumns();
  }

  setAvailableColumns() {
    this.availableColumns = this.columns;
  }

  handleSettingsChanged(event: GridSettings) {
    this.filters = event.filters;
    this.page.size = event.pageSize;
    this.page.pageNumber = event.pageNumber;
    this.sortField = event.sortField;
    this.sortDescending = event.sortDescending;
    this.columnOptions = event.columnOptions;

    const visibleColumnProps = this.columnOptions
      .filter(co => co.checked)
      .map(co => co.name);

    this.columns = this.availableColumns.filter(
      c =>
        visibleColumnProps.includes(c.prop as string) ||
        this.actionColumns.includes(c.prop as string)
    );

    this.exportColumns = this.columnOptions
      .filter(co => co.includeInExport)
      .map(co => co.name);

    this.getReport();
  }

  handleColumnOptionsChanged(event: ColumnOption[]) {
    this.columnOptions = event;

    this.exportColumns = this.columnOptions
      .filter(co => co.includeInExport)
      .map(co => co.name);
  }

  getQuery() {
    const filters: Filter[] = [];
    for (const id in this.filters) {
      if (this.filters[id]) {
        filters.push(this.filters[id]);
      }
    }
    return {
      clientId: this._context.client.id,
      filters,
      useExcelFormat: this.useExcelFormat,
      sortField: this.sortField,
      sortDescending: this.sortDescending,
      exportColumns: this.exportColumns
    };
  }

  getPagedQuery() {
    const filters: Filter[] = [];
    for (const id in this.filters) {
      if (this.filters[id]) {
        filters.push(this.filters[id]);
      }
    }
    return {
      clientId: this._context.client.id,
      filters,
      pageNumber: this.page.pageNumber,
      pageSize: this.page.size,
      sortField: this.sortField,
      sortDescending: this.sortDescending
    };
  }

  getReport() {
    const query = this.getPagedQuery();
    this.loading = true;
    this.systemSvc.getPagedFeeRecipientReport(query).subscribe(report => {
      this.page.totalElements = report.recordCount;
      this.page.totalPages = report.pageCount;
      this.rows = report.items.map(i => {
        i['recipient'] = i.recipientName;
        i['payments'] = i.payments;
        i['amount'] = Utilities.formatCurrency(i.amount);
        i['jurisdiction'] = i.clientName;
        return i;
      });
      this.report = report;
      this.loading = false;
    });
  }

  exportExcel() {
    this.useExcelFormat = true;
    this.export();
  }

  exportCsv() {
    this.useExcelFormat = false;
    this.export();
  }

  export() {
    const query = this.getQuery();
    this.systemSvc.exportFeeRecipientReport(query).subscribe(
      data => {
        if (this.useExcelFormat) {
          const blob = new Blob([data], {
            type:
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          });
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = `fee-recipient-report-export-${new Date().getTime()}.xlsx`;
          link.click();
        } else {
          const blob = new Blob([data], { type: 'text/csv' });
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = `fee-recipient-report-export-${new Date().getTime()}.csv`;
          link.click();
        }
      },
      err => {
        this.toast.error('Problem while downloading the file.');
        console.error(err);
      }
    );
  }
}

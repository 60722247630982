import { Component, OnInit } from '@angular/core';
import { WorkflowContextService } from '../../../services';

@Component({
  selector: 'wm-admin.view',
  templateUrl: './admin.view.component.html',
  styleUrls: ['./admin.view.component.css']
})
export class AdminViewComponent implements OnInit {
  constructor(private _context: WorkflowContextService) {}

  ngOnInit() {
    this._context.viewAsRole$.emit(null);
  }
}

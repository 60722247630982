import { Observable } from 'rxjs';
import { DataEntity } from './../../../models/data-entities/data-entity';
import { Activity, ActivityModel } from 'src/app/models/activities';
import { WorkflowValidationService } from './../../../services/workflow-validation.service';
import {
  ValidationType,
  ValidationSeverity,
  ValidationResponse
} from 'src/app/models/validation';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { WorkflowService } from 'src/app/services';
import { Workflow } from 'src/app/models/workflow';

export interface ValidationError {
  value: ValidationResponse;
  activity?: Activity<ActivityModel>;
  dataEntity?: DataEntity;
}

@Component({
  selector: 'wm-workflow-validity-modal',
  templateUrl: './workflow-validity-modal.component.html',
  styleUrls: ['./workflow-validity-modal.component.css']
})
export class WorkflowValidityModalComponent implements OnInit {
  @Input() validity: ValidationResponse[];
  @Input() workflowId: string;
  @Output() clickedError: EventEmitter<ValidationError> = new EventEmitter();
  @Input() workflow: Workflow;

  public ValidationType = ValidationType;
  public activities: Activity<ActivityModel>[];
  public dataEntities: DataEntity[];

  public activityErrors: ValidationError[] = [];
  public deErrors: ValidationError[] = [];
  public workflowErrors: ValidationError[] = [];

  public loading = true;

  constructor(
    public activeModal: NgbActiveModal,
    private validationSvc: WorkflowValidationService,
    private _workflowSvc: WorkflowService
  ) {}

  severity(severity: ValidationSeverity) {
    return this.validationSvc.getSeverity(severity);
  }

  async getWorkflow() {
    // get the workflow
    if (!this.workflow) {
      this.workflow = await this._workflowSvc
        .getWorkflow(this.workflowId, false)
        .toPromise();
    }

    // get all the activities
    this.activities = await this._workflowSvc
      .getAllWorkflowActivities(this.workflow)
      .toPromise();

    let templateCodes: string[] = null;

    if (this.validity) {
      this.validity.forEach(v => {
        if (v.type === ValidationType.DataEntity) {
          if (!templateCodes) {
            templateCodes = [];
          }
          templateCodes.push(v.id);
        }
      });
    }

    if (templateCodes) {
      const processEntities = await this._workflowSvc
        .getWorkflowVersionDataEntities(this.workflow.version.id, templateCodes)
        .toPromise();

      this.dataEntities = processEntities;
    } else {
      this.dataEntities = [];
    }

    this.formatErrors();

    this.loading = false;
  }

  formatErrors() {
    for (let idx = 0; idx < this.validity.length; idx++) {
      const error = this.validity[idx];
      const item: ValidationError = {
        value: error
      };
      switch (error.type) {
        case ValidationType.Activity:
          const activity = this.activities.find(
            a => a.id.toLowerCase() === error.id.toLowerCase()
          );
          item.activity = activity;
          this.activityErrors.push(item);
          break;

        case ValidationType.DataEntity:
          const de = this.dataEntities.find(
            d =>
              d.templateCode.toLowerCase() === error.id.toLowerCase() &&
              d.parent.id === error.activityId
          );
          if (error.activityId) {
            item.activity = this.activities.find(
              a => a.id.toLowerCase() === error.activityId.toLowerCase()
            );
          }
          item.dataEntity = de;
          this.deErrors.push(item);
          break;

        case ValidationType.Workflow:
          this.workflowErrors.push(item);
          break;
        default:
          break;
      }
    }
  }

  click(error: ValidationError) {
    this.clickedError.emit(error);
  }

  ngOnInit() {
    this.getWorkflow();
  }
}

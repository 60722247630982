<div class="row">
  <div class="col">
    <h3>Contacts</h3>
  </div>
  <div class="col-2">
    <button
      class="btn btn-raised btn-primary float-right"
      (click)="addContact()"
    >
      Add Contact
    </button>
  </div>
</div>

<wm-contacts-list [contacts]="contacts"></wm-contacts-list>

import {
  Component,
  OnInit,
  ComponentFactoryResolver,
  Inject,
  forwardRef,
  OnDestroy,
  Input
} from '@angular/core';
import { DataEntityInputComponent } from '../../data-entity-input/data-entity-input.component';
import { DocumentDataEntity } from '../../../../../models/data-entities';
import { WorkflowContextService, Utilities } from '../../../../../services';
import { DocumentService } from '../../../../../services';
import { Document } from '../../../../../models';
import { Subscription, empty, Observable } from 'rxjs';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { map } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'wm-document-data-entity-input',
  templateUrl: './document-data-entity-input.component.html',
  styleUrls: ['./document-data-entity-input.component.css']
})
export class DocumentDataEntityInputComponent extends DataEntityInputComponent
  implements OnInit, OnDestroy {
  @Input() isPreview: boolean;
  documentEntity: DocumentDataEntity;
  // documents: Document[];
  showRemove: boolean;
  document: Document;
  @Input() applicationId: string;
  self: any;
  @Input() parentEntityId: string;
  documentTemplateCode: string;
  fileCompleteSub: Subscription;
  allowSelect = false;
  fileAddedSub: Subscription;
  @Input() deleteStoredDocument = false;

  constructor(
    componentFactory: ComponentFactoryResolver,
    @Inject(forwardRef(() => DocumentService))
    public uploadService: DocumentService,
    @Inject(forwardRef(() => WorkflowContextService))
    private _context: WorkflowContextService,
    private _fb: UntypedFormBuilder,
    private _toastrSvc: ToastrService
  ) {
    super(componentFactory);
  }

  ngOnDestroy(): void {
    if (this.fileCompleteSub) {
      this.fileCompleteSub.unsubscribe();
    }

    if (this.fileAddedSub) {
      this.fileAddedSub.unsubscribe();
    }
  }

  fileUploaded(e) {
    if (!this.documentEntity.documents) {
      this.documentEntity.documents = [];
    }
    const exDoc = this.documentEntity.documents.find(d => d.name === e.name);

    if (exDoc) {
      const docIdx = this.documentEntity.documents.indexOf(exDoc);

      this.documentEntity.documents[docIdx] = e;
    } else {
      this.documentEntity.documents.push(e);
    }
  }

  getDocuments(): Observable<Document[]> {
    if ((this.documentEntity.documents || []).length > 0) {
      this.form.controls[this.entity.templateCode].setValue('true');
    }

    return empty();
  }

  ngOnInit() {
    this.documentEntity = this.entity as DocumentDataEntity;

    if (this.fileAddedSub) {
      this.fileAddedSub.unsubscribe();
    }

    this.fileAddedSub = this.uploadService.filesAdded.subscribe(
      (files: { files: any; key: string }) => {
        if (files.key === this.entity.templateCode) {
          this.form.controls[this.entity.templateCode + '_uploaded'].setValue(
            null
          );
        }
      }
    );

    if (this.fileCompleteSub) {
      this.fileCompleteSub.unsubscribe();
    }

    this.fileCompleteSub = this.uploadService.fileUploadComplete.subscribe(
      (files: { files: any; key: string }) => {
        if (files.key === this.entity.templateCode) {
          this.form.controls[this.entity.templateCode + '_uploaded'].setValue(
            'true'
          );
          this.form.controls[this.entity.templateCode].setValue('true');
          this.updateValue();
        }
      }
    );

    if (this.form) {
      // this is here to make sure that the selected files are uploaded before you can click next
      this.form.addControl(
        this.entity.templateCode + '_uploaded',
        this._fb.control('true', Validators.required)
      );
    }

    this.showRemove = true;
    if (!this.parentEntityId) {
      this.parentEntityId = this.entity.parent && this.entity.parent.id;
    }
    this.documentTemplateCode = this.entity.templateCode;
    this.applicationId =
      this.applicationId ||
      this._context.applicationId ||
      Utilities.generateId();

    // this is here to handle existing data since they weren't stored on the data entity.
    this.getDocuments().subscribe();
  }

  updateValue() {
    this.entity.value =
      this.documentEntity.documents.length === 0
        ? ''
        : JSON.stringify(this.documentEntity.documents);
    // the validator isn't flagging an empty string set by updateValue() after deleting all documents. Set it manually here
    this.form.controls[this.entity.templateCode].setValue(this.entity.value);
  }

  deleteDocument(document: Document) {
    const updateDocs = () => {
      const docs = this.documentEntity.documents.filter(
        d => d.id !== document.id
      );
      this.documentEntity.documents = docs;
    };

    // internal and external notes use this component. Clicking delete should delete their docs
    // from storage.  Application documents don't need to be deleted from storage, their value
    // just needs updated so the application EntityData will be updated on clicking Next/Update
    // deleteStoredDocument is a flag only internal and external notes set to true
    if (this.deleteStoredDocument) {
      this.uploadService
        .removeDocument(this.applicationId, document)
        .subscribe(result => {
          if (result) {
            updateDocs();
            this.updateValue();
          } else {
            this._toastrSvc.error('Document failed to delete.');
          }
        });
    } else {
      updateDocs();
      this.updateValue();
    }
  }
}

import {
  ConditionSource,
  ConditionTarget
} from './../../../system/condition-builder/condition-builder.model';
import { Component, OnInit, Input, Inject, forwardRef } from '@angular/core';
import {
  RouteDefinition,
  DecisionActivity,
  Activity,
  ActivityModel,
  RouteCriteria
} from '../../../../models/activities';
import {
  WorkflowService,
  WorkflowContextService,
  Utilities
} from '../../../../services';
import { ActivityEditorBaseComponent } from '../activity-editor-base/activity-editor-base.component';

@Component({
  selector: 'wm-decision-activity-editor',
  templateUrl: './decision-activity-editor.component.html',
  styleUrls: ['./decision-activity-editor.component.css']
})
export class DecisionActivityEditorComponent extends ActivityEditorBaseComponent
  implements OnInit {
  @Input() activity: DecisionActivity;
  @Input() route: RouteDefinition;
  decisionActivity: DecisionActivity;
  targetActivity: Activity<ActivityModel>;
  sourceActivities: Activity<ActivityModel>[];
  workflowActivities: Activity<ActivityModel>[];

  conditionSources: ConditionSource[] = [];
  otherwiseCondition: ConditionTarget = new ConditionTarget({
    id: 'otherwise'
  });

  conditions: ConditionTarget[] = [];

  constructor(
    @Inject(forwardRef(() => WorkflowService))
    public _workflowSvc: WorkflowService,
    @Inject(forwardRef(() => WorkflowContextService))
    private _context: WorkflowContextService
  ) {
    super();
  }

  ngOnInit() {
    this._workflowSvc
      .getWorkflowActivities(this._context.workflow)
      .subscribe(activities => {
        this.workflowActivities = activities.filter(
          a =>
            !this.activity.previousActivityIds.includes(a.id) &&
            a.id !== this.activity.id
        );
      });

    // TODO: this should be getDataEntitiesBeforeMe
    this._workflowSvc
      .getDataEntitiesBeforeMe(this._context.workflow, this.activity)
      .subscribe(dataEntities => {
        if (dataEntities) {
          this.conditionSources = dataEntities.map(de => {
            return {
              name: de.templateCode,
              value: de.templateCode,
              operators: de.getAvailableOperators(),
              possibleValues: de.getPossibleValues()
            };
          });
        }
      });

    this.buildConditions();

    this._workflowSvc.routingChanges.subscribe(() => {
      this.buildConditions();
    });
  }

  getConditionsFromRoutes(routes: RouteDefinition[]) {
    const conditions = [];
    for (const route of routes) {
      if (route.criteria && route.criteria.length > 0) {
        for (const criteria of route.criteria) {
          conditions.push(
            new ConditionTarget({
              ...criteria.criteria,
              shouldRoute: route.shouldRoute
            })
          );
        }
      } else {
        conditions.push(
          new ConditionTarget({
            isOtherwise: true,
            value: route.targetId,
            shouldRoute: route.shouldRoute
          })
        );
      }
    }

    return conditions;
  }

  buildConditions() {
    this.sourceActivities = [];

    this.conditions = this.getConditionsFromRoutes(this.activity.routing);

    if (this.activity.previousActivityIds) {
      for (const id of this.activity.previousActivityIds) {
        this.sourceActivities.push(
          this._workflowSvc.getWorkflowActivity(this._context.workflow, id)
        );
      }
    }

    this.targetActivity = this._workflowSvc.getWorkflowActivity(
      this._context.workflow,
      (
        this.activity.routing.find(
          r => !r.criteria || r.criteria.length === 0
        ) || new RouteDefinition()
      ).targetId
    );

    if (this.targetActivity) {
      this.otherwiseCondition = this.conditions.find(
        c => c.isOtherwise === true
      );

      if (!this.otherwiseCondition) {
        this.otherwiseCondition = new ConditionTarget({
          isOtherwise: true,
          value: this.targetActivity.id
        });
        this.conditions.push(this.otherwiseCondition);
      }
    }
  }

  removePreviousIds(activityId: string, value: string) {
    const target = this._workflowSvc.getWorkflowActivity(
      this._context.workflow,
      value
    );

    if (target) {
      const previousIndex = target.previousActivityIds.findIndex(
        id => id === activityId
      );
      target.previousActivityIds.splice(previousIndex, 1);
    }
  }
  addPreviousIds(activityId: string, value: string) {
    const target = this._workflowSvc.getWorkflowActivity(
      this._context.workflow,
      value
    );

    if (target) {
      target.previousActivityIds.push(activityId);
    }
  }

  cleanupRoutes() {
    // find all of the old routes and remove them
    const oldConditions = this.getConditionsFromRoutes(this.activity.routing);
    for (const condition of oldConditions) {
      this.removePreviousIds(this.activity.id, condition.value);
    }

    this.activity.routing = [];
  }

  rebuildRoutes() {
    this.cleanupRoutes();

    // create all of the route definitions
    for (const condition of this.conditions) {
      if (!condition.isOtherwise) {
        const criteriaRoute = new RouteDefinition({
          id: Utilities.generateId(),
          sourceId: this.activity.id,
          targetId: condition.value,
          criteria: [
            new RouteCriteria({
              criteria: new ConditionTarget(condition),
              targetId: condition.value
            })
          ]
        });
        this.activity.routing.push(criteriaRoute);
        this.addPreviousIds(this.activity.id, condition.value);
      }
    }

    // add a route to the default target
    const defaultRoute = new RouteDefinition({
      id: Utilities.generateId(),
      sourceId: this.activity.id,
      targetId: this.otherwiseCondition.value
    });
    this.activity.routing.push(defaultRoute);
    this.addPreviousIds(this.activity.id, this.otherwiseCondition.value);
  }

  save(): boolean {
    this.rebuildRoutes();

    return true;
  }

  // Use => in order to force `this` into being the DecisionActivityEditorComponent
  getThenLabel = (target: ConditionTarget): string => {
    if (target) {
      const activity = this.workflowActivities.find(a => a.id === target.value);

      return (activity && activity.model && activity.model.screenName) || null;
    }

    return null;
  }

  conditionsChanged(e) {
    this.conditions = e;
    const ow = e.find(c => c.isOtherwise === true);

    if (ow) {
      this.otherwiseCondition = ow;
    }
  }
}

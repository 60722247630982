import { ToastrService } from 'ngx-toastr';
import { WorkflowType } from './../../../../models/workflow';
import { DataService, WorkflowContextService } from 'src/app/services';
import {
  FilterBuilderParam,
  FilterBuilderOutput
} from 'src/app/models/filter-builder';
import { PagedPermitSummaryReportResponse } from './../../../../models/permits-reports';
import { Component, OnInit } from '@angular/core';
import { Utilities } from 'src/app/services';
import { ColumnOption, GridSettings } from 'src/app/models/grid-settings';
import { TableColumn } from '@swimlane/ngx-datatable';
import { ComponentIds } from 'src/app/models/component-ids';

@Component({
  selector: 'wm-permits-summary',
  templateUrl: './permits-summary.component.html',
  styleUrls: ['./permits-summary.component.css']
})
export class PermitsSummaryComponent implements OnInit {
  id = ComponentIds.PERMITS_SUMMARY;
  loading = true;
  report: PagedPermitSummaryReportResponse;
  params: FilterBuilderParam[] = [
    {
      name: 'Workflow',
      id: 'workflowName',
      options: []
    },
    {
      name: 'Created Date',
      id: 'timeframe',
      inputType: 'date',
      types: ['range']
    }
  ];
  filters: FilterBuilderOutput = {};

  public useExcelFormat: boolean;

  columns: TableColumn[];
  availableColumns: TableColumn[];
  columnOptions: ColumnOption[] = [];
  exportColumns: string[];
  rows: any[];
  public page = {
    // The number of elements in the page
    size: 10,
    // The total number of elements
    totalElements: 0,
    // The total number of pages
    totalPages: 0,
    // The current page number
    pageNumber: 0
  };
  public sortField: string;
  public sortDescending = false;

  lastQuery = {};

  // column prop strings to be hidden on the grid by default
  defaultHiddenColumns: string[] = [];
  // column prop strings that should have no option to hide in the grid, and are not exportable (such as button columns like Refund or Actions)
  actionColumns: string[] = [];
  // column prop strings to be excluded from exports by default
  defaultExportExludedColumns: string[] = [];

  constructor(
    private dataSvc: DataService,
    private toast: ToastrService,
    private _context: WorkflowContextService
  ) {}

  ngOnInit() {
    this.columns = [
      {
        prop: 'workflow',
        name: 'Workflow'
      },
      {
        prop: 'amount',
        name: 'Amount'
      },
      {
        prop: 'completed',
        name: 'Completed'
      },
      {
        prop: 'started',
        name: 'Started'
      },
      {
        prop: 'client',
        name: 'Client'
      }
    ];
    this.setAvailableColumns();

    this.getApplications();
  }

  setAvailableColumns() {
    this.availableColumns = this.columns;
  }

  isEquivalent(a: any, b: any) {
    const aProps = Object.getOwnPropertyNames(a);
    const bProps = Object.getOwnPropertyNames(b);

    if (aProps.length !== bProps.length) {
      return false;
    }

    for (let i = 0; i < aProps.length; i++) {
      const propName = aProps[i];
      if (a[propName] !== b[propName]) {
        return false;
      }
    }

    return true;
  }

  handleSettingsChanged(event: GridSettings) {
    this.filters = event.filters;
    this.page.size = event.pageSize;
    this.page.pageNumber = event.pageNumber;
    this.sortField = event.sortField;
    this.sortDescending = event.sortDescending;
    this.columnOptions = event.columnOptions;

    const visibleColumnProps = this.columnOptions
      .filter(co => co.checked)
      .map(co => co.name);

    this.columns = this.availableColumns.filter(
      c =>
        visibleColumnProps.includes(c.prop as string) ||
        this.actionColumns.includes(c.prop as string)
    );

    this.exportColumns = this.columnOptions
      .filter(co => co.includeInExport)
      .map(co => co.name);

    this.getReport();
  }

  handleColumnOptionsChanged(event: ColumnOption[]) {
    this.columnOptions = event;

    this.exportColumns = this.columnOptions
      .filter(co => co.includeInExport)
      .map(co => co.name);
  }

  getApplications() {
    this.dataSvc
      .getWorkflows(this._context.client, true, [
        WorkflowType.ContractorRegistration,
        WorkflowType.ContractorRenewal,
        WorkflowType.Other,
        WorkflowType.Permit,
        WorkflowType.Renewable
      ])
      .subscribe(workflows => {
        const param = this.params.find(p => p.id === 'workflowName');
        param.options = workflows.map(w => w.version.name).sort();
      });
  }

  getQuery() {
    const timeframe = this.filters['timeframe'];
    const workflowName = this.filters['workflowName'];

    const query = {
      clientId: this._context.client.id,
      timeframe: timeframe && timeframe.value,
      workflowName: workflowName && workflowName.value,
      useExcelFormat: this.useExcelFormat,
      sortField: this.sortField,
      sortDescending: this.sortDescending,
      exportColumns: this.exportColumns
    };

    return query;
  }

  getPagedQuery() {
    const timeframe = this.filters['timeframe'];
    const workflowName = this.filters['workflowName'];

    const query = {
      clientId: this._context.client.id,
      timeframe: timeframe && timeframe.value,
      applicationName: workflowName && workflowName.value,
      workflowName: workflowName && workflowName.value,
      pageNumber: this.page.pageNumber,
      pageSize: this.page.size,
      sortField: this.sortField,
      sortDescending: this.sortDescending
    };

    return query;
  }

  getReport() {
    const query = this.getPagedQuery();

    if (!this.isEquivalent(query, this.lastQuery)) {
      this.lastQuery = { ...query };
      this.loading = true;
      this.dataSvc.getPagedPermitSummaryReport(query).subscribe(report => {
        // added -->
        this.page.totalElements = report.recordCount;
        this.page.totalPages = report.pageCount;
        this.rows = report.items.map(i => {
          i['workflow'] = i.workflowName;
          i['amount'] = Utilities.formatCurrency(i.amount);
          i['completed'] = i.completedCount;
          i['started'] = i.startedCount;
          i['client'] = i.clientName;
          return i;
        });
        // <--
        this.report = report;
        this.loading = false;
      });
    }
  }

  exportExcel() {
    this.useExcelFormat = true;
    this.export();
  }

  exportCsv() {
    this.useExcelFormat = false;
    this.export();
  }

  export() {
    const query = this.getQuery();
    this.dataSvc.exportPermitSummaryReport(query).subscribe(
      data => {
        if (this.useExcelFormat) {
          const blob = new Blob([data], {
            type:
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          });
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = `permits-summary-report-export-${new Date().getTime()}.xlsx`;
          link.click();
        } else {
          const blob = new Blob([data], { type: 'text/csv' });
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = `permits-summary-report-export-${new Date().getTime()}.csv`;
          link.click();
        }
      },
      err => {
        this.toast.error('Problem while downloading the file.');
        console.error(err);
      }
    );
  }
}

<div class="form-group" *ngIf="activity.showActivityInspectorName">
  <label for="inspector" class="text-label mb-0">
    Inspector
  </label>
  <input
    type="text"
    readonly
    class="form-control-plaintext"
    id="inspector"
    [value]="activity.activtyInspectorName"
  />
</div>

<wm-form-activity-input-view
  [activity]="activity"
  [form]="form"
  [isReadOnly]="true"
></wm-form-activity-input-view>

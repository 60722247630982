<div class="mt-4">
  <wm-exports-view
    *ngIf="columnOptions && columnOptions.length > 0"
    [id]="id"
    [title]="title"
    [params]="params"
    [currentFilters]="filters"
    [currentColumnOptions]="columnOptions"
    (exptExcel)="exportExcel()"
    (exptCsv)="exportCsv()"
  ></wm-exports-view>

  <wm-datatable
    [id]="id"
    [loading]="loading"
    [useMap]="false"
    [rows]="rows"
    [columns]="columns"
    [count]="page.totalElements"
    [limit]="page.size"
    [offset]="page.pageNumber"
    [defaultFilters]="defaultFilters"
    [params]="params"
    [translateToLegacy]="false"
    [showSimpleSearch]="false"
    [savedFilters]="true"
    (settingsChanged)="handleSettingsChanged($event)"
    [sortField]="sortField"
    [sortDescending]="sortDescending"
    [defaultHiddenColumns]="defaultHiddenColumns"
    [actionColumns]="actionColumns"
    [defaultExportExludedColumns]="defaultExportExludedColumns"
    [availableColumns]="availableColumns"
    (columnOptionsChanged)="handleColumnOptionsChanged($event)"
    [exportingEnabled]="true"
  ></wm-datatable>

  <ng-template #permitNumTmpl let-row="row" let-value="value">
    <a [routerLink]="['/application/workflow-application/', row.applicationId]">
      {{ value }}
    </a>
  </ng-template>

  <ng-template #stackTmpl let-row="row" let-value="value">
    <b>Order:</b> {{ row.orderNumber }} <br />
    <b>Transaction:</b> {{ row.transactionNumber }}
  </ng-template>

  <ng-template #paymentDateTmpl let-row="row" let-value="value">
    {{ row.clientPaymentDate }}
  </ng-template>

  <ng-template #refundTmpl let-row="row">
    <span *ngIf="row.isRefunded">Refunded</span>
    <wm-modal-confirm
      *ngIf="!row.isRefunded && canRefund"
      [moduleId]="row.paymentRequestId"
      title="Refund Payment"
      commandText="Refund"
      [showButtonText]="true"
      buttonText="Refund"
      (accepted)="refundPayment(row.paymentRequestId)"
    >
      Are you sure you want to mark this payment as refunded in GeoPermits? This
      action cannot be undone.<br /><br />This will only mark the payment as
      refunded. You will still need to refund the payment manually in the source
      payment system.
    </wm-modal-confirm>
  </ng-template>
</div>

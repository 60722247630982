<app-loader *ngIf="!contractorTypes"></app-loader>

<table class="table">
  <tr>
    <th>Name</th>
    <th>Last Published</th>
    <th></th>
  </tr>

  <tr *ngFor="let ct of contractorTypes">
    <td class="align-middle">
      <a
        [routerLink]="[
          '/admin/jurisdiction',
          context.client.id,
          'contractors',
          'types',
          'details',
          ct.id
        ]"
        >{{ ct.name }}
      </a>
      <p class="text-muted">{{ ct.description }}</p>
    </td>
    <td class="align-middle">
      <span *ngIf="ct.lastPublishedOn">
        {{ ct.lastPublishedOn | date: 'MM/dd/y, hh:mm a' }}
      </span>
      <span *ngIf="!ct.lastPublishedOn" class="badge badge-pill badge-warning">
        Never published
      </span>
      <span
        *ngIf="canPublish(ct) && ct.lastPublishedOn"
        class="badge badge-pill badge-success"
      >
        Changes available
      </span>
    </td>
    <td>
      <div class="btn-group float-right" ngbDropdown placement="bottom-right">
        <button
          type="button"
          class="btn btn-raised btn-primary"
          [disabled]="!canManageContractors"
          [routerLink]="[
            '/admin/jurisdiction',
            context.client.id,
            'contractors',
            'types',
            'edit',
            ct.id
          ]"
        >
          Edit
        </button>
        <button
          class="btn btn-raised btn-primary dropdown-toggle-split"
          ngbDropdownToggle
        ></button>

        <div class="dropdown-menu" ngbDropdownMenu>
          <wm-modal-confirm
            commandText="Publish"
            [showButton]="false"
            [enabled]="canPublish(ct)"
            [moduleId]="ct.id + 'publish'"
            [showButtonText]="false"
            [title]="'Publish ' + ct.name"
            (accepted)="onPublish.emit(ct.id)"
            (opened)="validateContractorType(ct)"
            [form]="publishForm"
            #publishButton
          >
            <div *ngIf="isValidating" class="text-danger">
              Validating workflows...
            </div>
            <div
              *ngIf="!isValidating && publishForm.invalid"
              class="text-danger"
            >
              Contractor type has errors, please review the errors in the
              <a
                [routerLink]="[
                  '/admin/jurisdiction',
                  context.client.id,
                  'contractors',
                  'types',
                  'edit',
                  ct.id
                ]"
                (click)="dismissPublish(this)"
                >Contractor Type Editor</a
              >
            </div>
            <div
              *ngIf="!isValidating && !publishForm.invalid"
              class="text-success"
            >
              Contractor Type is valid
            </div>
            <br />
            <p>
              Are you sure you want to publish the pending changes to the
              Registration and Renewal Workflow for contractor type ({{
                ct.name
              }})?
            </p>
            <br />
            <p>
              Some things to take note of.
            </p>
            <ul>
              <li>
                All new contractor registrations for this type of contractor
                will use the new workflow.
              </li>
              <li>
                Any existing applications will not use these changes.
              </li>
              <ng-container
                *ngIf="
                  customFieldMigrationQueueItems &&
                  customFieldMigrationQueueItems.length > 0
                "
              >
                <li>
                  <wm-move-custom-field-info
                    [hasContractorToRegistrationMove]="
                      hasContractorToRegistrationMove
                    "
                    [hasRegistrationToContractorMove]="
                      hasRegistrationToContractorMove
                    "
                  ></wm-move-custom-field-info>
                </li>
              </ng-container>
            </ul>
          </wm-modal-confirm>
          <button
            ngbDropdownItem
            [disabled]="!canPublish(ct)"
            (click)="publishButton.open()"
          >
            {{ canPublish(ct) ? 'Publish' : 'Published' }}
          </button>

          <wm-modal-confirm
            [showButton]="false"
            [commandText]="'Delete Contractor Type'"
            [enabled]="canDelete(ct)"
            [moduleId]="ct.id + 'disable'"
            [showButtonText]="false"
            [title]="'Disable Workflow'"
            (accepted)="delete(ct.id)"
            #deleteButton
          >
            <p>
              Are you sure you want to delete this contractor type ({{
                ct.name
              }})?
            </p>
            <br />
            <p>
              Some things to take note of.
            </p>
            <ul>
              <li>
                Applications will no longer be able to be completed for this
                workflow.
              </li>
              <li>This workflow will still be visible for editing.</li>
            </ul>
          </wm-modal-confirm>
          <button
            ngbDropdownItem
            *ngIf="canDelete(ct)"
            (click)="deleteButton.open()"
          >
            Delete
          </button>

          <button
            ngbDropdownItem
            *ngIf="canManageContractors"
            (click)="onCopy.emit(ct)"
          >
            Copy
          </button>
          <div
            style="position: relative; display: flex;min-height: 3rem; padding: .8rem 1rem .8rem 1.5rem"
          >
            Register Workflow
          </div>
          <button
            ngbDropdownItem
            *ngIf="canViewHistory()"
            (click)="
              onHistory.emit({
                id: ct.registerWorkflowId,
                name: ct.name + ' Register'
              })
            "
          >
            <span style="padding-left: 15px">Workflow History</span>
          </button>
          <button
            ngbDropdownItem
            *ngIf="canManageContractors"
            [routerLink]="['/admin/workflow-builder', ct.registerWorkflowId]"
          >
            <span style="margin-left: 15px">Workflow Builder</span>
          </button>
          <div
            style="position: relative; display: flex;min-height: 3rem; padding: .8rem 1rem .8rem 1.5rem"
          >
            Renewal Workflow
          </div>
          <button
            ngbDropdownItem
            *ngIf="canViewHistory()"
            (click)="
              onHistory.emit({
                id: ct.renewWorkflowId,
                name: ct.name + ' Renew'
              })
            "
          >
            <span style="margin-left: 15px">Workflow History</span>
          </button>
          <button
            ngbDropdownItem
            *ngIf="canManageContractors"
            [routerLink]="['/admin/workflow-builder', ct.renewWorkflowId]"
          >
            <span style="margin-left: 15px">Workflow Builder</span>
          </button>
        </div>
      </div>
    </td>
  </tr>

  <tr *ngIf="contractorTypes && contractorTypes.length === 0">
    <td>No contractor types to display</td>
  </tr>
</table>

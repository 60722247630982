import { Component, OnInit, ComponentFactoryResolver } from '@angular/core';
import { DataEntityViewComponent } from '../../data-entity-view/data-entity-view.component';
import { GeoJSONDataEntity } from 'src/app/models/data-entities/geojson-data-entity';

@Component({
  selector: 'wm-y-data-entity-view',
  templateUrl: './geojson-data-entity-view.component.html',
  styleUrls: ['./geojson-data-entity-view.component.css']
})
export class GeoJSONDataEntityViewComponent extends DataEntityViewComponent
  implements OnInit {
  constructor(componentFactoryResolver: ComponentFactoryResolver) {
    super(componentFactoryResolver);
  }
  geoJSONEntity: GeoJSONDataEntity;

  ngOnInit() {
    this.geoJSONEntity = <GeoJSONDataEntity>this.entity;
  }
}

<!-- agenda type -->
<div class="form-group">
  <label class="bmd-label-floating" for="agendaType">
    Agenda Type
  </label>
  <select
    class="form-control"
    name="agendaType"
    [(ngModel)]="activity.selectedAgendaTypeId"
  >
    <option *ngFor="let at of activity.availableAgendaTypes" [ngValue]="at.id">
      {{ at.name }}
    </option>
  </select>
</div>

<br />

<!-- agenda item format -->
<div>
  <wm-activity-details-modal
    [title]="activity.model.screenName"
    [btnText]="'Agenda Item Format'"
    [moduleId]="activity.id"
    [form]="activityForm"
    (opened)="showDetails($event)"
    (saved)="cleanup()"
    (canceled)="cleanup()"
  >
    <form [formGroup]="activityForm" *ngIf="activityForm">
      <div class="row">
        <div class="col-8">
          <h4>Agenda Item Format</h4>
          <div class="form-group">
            <label class="bmd-label-static" for="agendaItemTemplate">
              <b
                >Select application data from the workflow that will appear on
                each generated agenda item</b
              >
            </label>
            <ckeditor
              #agendaItemTemplate
              [editor]="Editor"
              [config]="editorConfig"
              (change)="onChange($event)"
              formControlName="agendaItemTemplate"
            ></ckeditor>
          </div>
        </div>

        <div class="col-lg-4">
          <h4>Data</h4>
          <div class="form-group">
            <ul class="list-group">
              <li
                class="list-group-item d-flex justify-content-between align-items-start"
                *ngFor="let de of entities"
              >
                <span class="align-self-center">{{ de.templateCode }}</span>

                <div>
                  <a style="cursor: pointer" (click)="insert(de)">
                    <i class="material-icons">
                      add
                    </i>
                  </a>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </form>
  </wm-activity-details-modal>
</div>

<br />

<!-- ballot configs -->
<div class="switch">
  <label>
    <input
      type="checkbox"
      [(ngModel)]="activity.requiresBallot"
      (change)="requiresBallotChanged()"
    />
    Requires ballot
  </label>
</div>

<br />

<div *ngIf="activity.requiresBallot">
  <h6>Ballot</h6>
  <wm-print-template-activity-editor
    [activity]="activity"
    [showTitle]="false"
    [form]="ballotForm"
  ></wm-print-template-activity-editor>
</div>

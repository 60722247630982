import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { MapDisplayConfiguration } from 'src/app/components/arcgis/map-display-configuration';
import {
  ItemSearchOptionField,
  ItemSearchOptionFieldOption
} from 'src/app/components/filter-list/models/filterClasses';
import { MapLayer, MapService } from 'src/app/models';
import {
  QueryLayerConfig,
  QueryMapLayerActivity,
  QueryMapLayerActivityModel
} from 'src/app/models/activities';
import { Filter } from 'src/app/models/filter-builder';
import { QueryMapLayer } from 'src/app/models/query-map-layer';
import { DataService, WorkflowContextService } from 'src/app/services';

@Component({
  selector: 'wm-workflow-application-search-by-feature-view',
  templateUrl: './workflow-application-search-by-feature-view.component.html',
  styleUrls: ['./workflow-application-search-by-feature-view.component.css']
})
export class WorkflowApplicationSearchByFeatureViewComponent
  implements OnInit, OnDestroy {
  private clientSubscription: Subscription;

  mapLayers: QueryMapLayer[];
  combinedLayerConfig: QueryMapLayer;
  selectedMapLayerId: string;
  form: FormGroup;
  activity: QueryMapLayerActivity;
  myWorkflowsFilter = [];
  showResults = false;

  constructor(
    private _dataSvc: DataService,
    private _context: WorkflowContextService
  ) {}

  ngOnDestroy() {
    if (this.clientSubscription) {
      this.clientSubscription.unsubscribe();
      this.clientSubscription = null;
    }
  }

  ngOnInit() {
    this.activity = new QueryMapLayerActivity({
      model: new QueryMapLayerActivityModel({
        layerConfig: []
      })
    });

    this.form = new FormGroup({
      mapLayer: new FormControl('', Validators.required)
    });

    if (this._context.client) {
      this.getQueryMapLayers();
    } else {
      this.clientSubscription = this._context.client$.subscribe(client => {
        if (!client) {
          return;
        }
        this.getQueryMapLayers();
      });
    }
  }

  getQueryMapLayers() {
    this._dataSvc
      .getQueryMapLayers(this._context.client.id)
      .subscribe(results => {
        this.mapLayers = results;
      });
  }

  combinedLayer: QueryMapLayer;

  getCombinedQueryMapLayer() {
    if (this.selectedMapLayerId) {
      this._dataSvc
        .getCombinedQueryMapLayer(this.selectedMapLayerId)
        .subscribe(layer => {
          this.combinedLayer = layer;

          const searchConfig = layer.searchFields.map(
            sf =>
              new QueryLayerConfig({
                isSearchable: true,
                searchLabel: sf.label,
                fieldName: sf.fieldName
              })
          );
          const resultConfig = layer.resultFields.map(
            rf =>
              new QueryLayerConfig({
                showInResults: true,
                resultHeader: rf.resultHeader,
                fieldName: rf.fieldName
              })
          );

          const activity: QueryMapLayerActivity = new QueryMapLayerActivity({
            id: layer.mapLayerIndexItem.mapLayer.id,
            model: new QueryMapLayerActivityModel({
              layerConfig: searchConfig.concat(resultConfig),
              mapService: layer.mapLayerIndexItem.mapService,
              mapLayer: layer.mapLayerIndexItem.mapLayer,
              featureIdField: layer.featureIdField,
              mapConfig: new MapDisplayConfiguration()
            })
          });

          this.activity = activity;
          this.combinedLayerConfig = layer;
          this.showResults = false;
        });
    }
  }

  SearchFeature(evt: any) {
    this.myWorkflowsFilter = [
      new ItemSearchOptionField({
        title: 'featureId',
        options: [
          new ItemSearchOptionFieldOption({
            title: 'featureId',
            selected: true,
            filterText: this.activity.model.selectedFeature['KEY'],
            searchText: '',
            strOperator: ''
          })
        ]
      }),
      new ItemSearchOptionField({
        title: 'mapLayerIndexItemId',
        options: [
          new ItemSearchOptionFieldOption({
            title: 'mapLayerIndexItemId',
            selected: true,
            filterText: this.combinedLayer.mapLayerIndexItemId,
            searchText: '',
            strOperator: ''
          })
        ]
      })
    ];
    this.showResults = true;
  }

  goNext(e) {
    this.SearchFeature(e);
  }

  onFeatureSearch() {
    this.showResults = false;
  }
}

import {
  Component,
  OnInit,
  ChangeDetectorRef,
  Inject,
  forwardRef
} from '@angular/core';
import { FormActivityEditorComponent } from '../../form-activity-editor/form-activity-editor.component';
import {
  WorkflowService,
  WorkflowContextService
} from '../../../../../services';
import { UntypedFormBuilder } from '@angular/forms';
import { DragulaService } from 'ng2-dragula';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'wm-contractor-registration-info-form-activity-editor',
  templateUrl:
    './contractor-registration-info-form-activity-editor.component.html',
  styleUrls: [
    './contractor-registration-info-form-activity-editor.component.css'
  ]
})
export class ContractorRegistrationInfoFormActivityEditorComponent
  extends FormActivityEditorComponent
  implements OnInit {
  constructor(
    @Inject(forwardRef(() => WorkflowService)) workflowSvc: WorkflowService,
    @Inject(forwardRef(() => WorkflowContextService))
    context: WorkflowContextService,
    fb: UntypedFormBuilder,
    dragulaSvc: DragulaService,
    ref: ChangeDetectorRef,
    toastr: ToastrService
  ) {
    super(workflowSvc, context, toastr, fb, dragulaSvc, ref);
  }

  ngOnInit() {}
}

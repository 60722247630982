import { map } from 'rxjs/operators';
import { ContractorCustomFieldListComponent } from './../contractor-custom-field-list/contractor-custom-field-list.component';
import { Contractor } from './../../../models/contractor';
import { Utilities } from './../../../services/utilities/index';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  ContractorRegistration,
  ContractorRegistrationNote,
  Document
} from 'src/app/models';
import { ContractorService, DataEntityFactory, WorkflowContextService } from 'src/app/services';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ContractorRegistrationDocument } from 'src/app/models/contractor-registration-document';
import { ToastrService } from 'ngx-toastr';
import { ContractorDocument } from 'src/app/models/contractor-document';
import { Observable } from 'rxjs';
import { notStrictEqual } from 'assert';
import { ContextmenuType } from '@swimlane/ngx-datatable';
import * as Workflow from 'esri/widgets/Editor/Workflow';
import { RegistrationStatus } from 'src/app/models/registration';

@Component({
  selector: 'wm-contractor-registration-details',
  templateUrl: './contractor-registration-details.component.html',
  styleUrls: ['./contractor-registration-details.component.css']
})
export class ContractorRegistrationDetailsComponent implements OnInit {
  @Input() registrationId: string;
  registration: ContractorRegistration;
  contractorId: string;
  contractor: Contractor;
  RegistrationStatus = RegistrationStatus;
  form: UntypedFormGroup;
  rcfForm: UntypedFormGroup;
  contractorForm: UntypedFormGroup;
  documents: ContractorRegistrationDocument[];
  contractorDocuments: ContractorDocument[];
  contractorRequirementFields: any[];
  registrationRequirementFields: any[];
  savingContractorRequirements = false;
  savingRegistrationRequirements = false;

  manualNote: string;

  apiBase = Utilities.getRootURL();
  @ViewChild('customFields', { static: false }) customFields: ContractorCustomFieldListComponent;
  @ViewChild('registrationCustomFields', { static: false })
  registrationCustomFields: ContractorCustomFieldListComponent;

  constructor(
    private _route: ActivatedRoute,
    private _contractorSvc: ContractorService,
    private _fb: UntypedFormBuilder,
    private _toastrSvc: ToastrService
  ) {}

  ngOnInit() {
    this.form = this._fb.group({});
    this.contractorForm = this._fb.group({});
    this.rcfForm = this._fb.group({});

    this._route.params.subscribe(parms => {
      if (!this.registrationId) {
        // registrationId is passed as an Input from
        // user-contractor-registration-details, otherwise
        // it should be a parameter on the ActivtedRoute
        this.registrationId = parms.registrationId;
      }

      this.loadRegistration().subscribe();
    });
  }

  loadRegistration(): Observable<ContractorRegistration> {
    return this._contractorSvc
      .getContractorRegistration(this.registrationId)
      .pipe(
        map(registration => {
          if (registration) {
            this.registration = registration;
            this.documents = registration.documents;
            this.contractorId = registration.contractorID;
            this.contractor = this.registration.contractor;

            if (registration.type.requirements) {
              const requirementIndex = {};
              registration.type.requirements.forEach((x, idx) => {
                requirementIndex[x.templateCode] = x;
              });

              const cCustomFields = Object.assign(
                {},
                ...registration.contractor.customFields.map(cf => {
                  return { [cf.templateCode]: cf.fieldValue };
                })
              );
              const rCustomFields = Object.assign(
                {},
                ...registration.customFields.map(cf => {
                  return { [cf.templateCode]: cf.fieldValue };
                })
              );

              this.contractorRequirementFields = registration.type.requirements
                .filter(r => r.isContractorRequirement)
                .map(r => {
                  return {
                    label: r.label,
                    templateCode: r.templateCode,
                    dataEntityDef:
                      r.dataEntityDefinition ||
                      DataEntityFactory.createDataEntity('free-form-text', {
                        templateCode: r.templateCode,
                        label: r.label
                      }),
                    dataEntityTypeCode: r.dataEntityDefinition
                      ? r.dataEntityDefinition.dataEntityTypeCode
                      : 'free-form-text',
                    fieldValue: cCustomFields[r.templateCode],
                    contractorId: registration.contractorID,
                    dataEntityType: r.dataEntityDefinition
                      ? r.dataEntityDefinition.dataEntityTypeCode
                      : 'free-form-text'
                  };
                });
              this.registrationRequirementFields = registration.type.requirements
                .filter(r => !r.isContractorRequirement)
                .map(r => {
                  return {
                    label: r.label,
                    templateCode: r.templateCode,
                    dataEntityDef:
                      r.dataEntityDefinition ||
                      DataEntityFactory.createDataEntity('free-form-text', {
                        templateCode: r.templateCode,
                        label: r.label
                      }),
                    dataEntityTypeCode: r.dataEntityDefinition
                      ? r.dataEntityDefinition.dataEntityTypeCode
                      : 'free-form-text',
                    fieldValue: rCustomFields[r.templateCode],
                    registrationId: registration.id,
                    dataEntityType: r.dataEntityDefinition
                      ? r.dataEntityDefinition.dataEntityTypeCode
                      : 'free-form-text'
                  };
                });
            } else {
              this.contractorRequirementFields = [];
              this.registrationRequirementFields = [];
            }

            this._contractorSvc
              .getContractorDocuments(this.contractorId)
              .subscribe(docs => {
                this.contractorDocuments = docs;
              });

            return registration;
          } else {
            this._toastrSvc.error(
              'Registration either doesn\'t exist or you don\'t have permission to view.'
            );
          }
        })
      );
  }

  getAzureDocumentPath(doc: ContractorDocument) {
    return Utilities.getAzureUrl(doc);
  }

  addNewDocuments(documents: Document[]) {
    const regDocs: ContractorRegistrationDocument[] = documents.map(d => {
      const doc = new ContractorRegistrationDocument();

      doc.documentName = d.name;
      doc.documentPath = d.path;

      return doc;
    });

    this._contractorSvc
      .saveContractorRegistrationDocuments(this.registrationId, regDocs)
      .subscribe(docs => {
        this.documents = docs;
        this._toastrSvc.success('Document(s) Saved');
      });
  }

  addNewContractorDocuments(documents: Document[]) {
    const contractorDocs: ContractorDocument[] = documents.map(d => {
      const doc = new ContractorDocument();

      doc.documentName = d.name;
      doc.documentPath = d.path;

      return doc;
    });

    this._contractorSvc
      .saveContractorDocuments(this.contractorId, contractorDocs)
      .subscribe(docs => {
        this.loadDocuments();
        this._toastrSvc.success('Document(s) Saved');
      });
  }

  loadDocuments() {
    this._contractorSvc
      .getContractorDocuments(this.contractorId)
      .subscribe(docs => {
        this.contractorDocuments = docs;
      });
    this._contractorSvc
      .getContractorRegistrationDocuments(this.registrationId)
      .subscribe(docs => {
        this.documents = docs;
      });
  }

  deleteDocument(documentId: string) {
    this._contractorSvc
      .deleteContractorRegistrationDocument(documentId)
      .subscribe(
        () => {
          this._toastrSvc.success('Document Deleted');
          this.loadDocuments();
        },
        err => {
          console.error(err);
        }
      );
  }

  deleteContractorDocument(documentId: string) {
    this._contractorSvc.deleteContractorDocument(documentId).subscribe(
      () => {
        this._toastrSvc.success('Document Deleted');
        this.loadDocuments();
      },
      err => {
        console.error(err);
      }
    );
  }

  saveDocument(document: ContractorRegistrationDocument) {
    this._contractorSvc
      .saveContractorRegistrationDocument(document)
      .subscribe(doc => {
        this._toastrSvc.success('Document Saved');
      });
  }

  saveContractorDocument(document: ContractorDocument) {
    this._contractorSvc.saveContractorDocument(document).subscribe(doc => {
      this._toastrSvc.success('Document Saved');
    });
  }

  public saveContractorDetails() {
    this.contractor.customFields = this.customFields.persistValues();

    // we don't need to send all of these across the web.
    this.contractor.registrations = [];
    this.contractor.documents = [];
    this.contractor.user = null;
    this.contractor.customFields = this.registration.contractor.customFields.map(
      field => {
        field.contractor = null;
        return field;
      }
    );

    this.savingContractorRequirements = true;
    this._contractorSvc.saveContractorDetails(this.contractor).subscribe(
      () => {
        this.loadRegistration().subscribe(() => {
          this._toastrSvc.success('Contractor Saved');
          this.savingContractorRequirements = false;
        });
      },
      err => {
        this._toastrSvc.error(
          'Your changes were not saved.',
          'Something went wrong!'
        );
      }
    );
  }

  saveRegistrationCustomFields() {
    this.registration.customFields = this.registrationCustomFields.persistValues();

    this.savingRegistrationRequirements = true;
    this._contractorSvc
      .saveContractorRegistrationCustomFields(
        this.registration.customFields,
        this.contractorId
      )
      .subscribe(
        () => {
          this.loadRegistration().subscribe(() => {
            this._toastrSvc.success('Registration Saved');
            this.savingRegistrationRequirements = false;
          });
        },
        err => {
          this._toastrSvc.error(
            'Your changes were not saved.',
            'Something went wrong!'
          );
        }
      );
  }

  viewReceipt() {
    window.event.preventDefault();
  }

  viewCertificate() {
    window.event.preventDefault();
  }

  addNote() {
    this._contractorSvc
      .saveRegistrationNote(
        {
          notes: this.manualNote,
          createdBy: '',
          modifiedBy: '',
          createdOn: new Date(),
          registrationID: this.registration.id
        },
        this.contractor.id,
        this.contractor.userId
      )
      .subscribe(
        () => {
          // if loading the note was successful, reload the registration to get the updated data
          this.loadRegistration().subscribe(() => {
            this._toastrSvc.success('Note saved');
            this.manualNote = '';
          });
        },
        err => {
          // Otherwise give the user a nice, Windows-10-style error..
          this._toastrSvc.error(
            'Your note was not saved.',
            'Something went wrong!'
          );
        }
      );
  }

  cancelNote(noteID) {
    this.manualNote = ''; // discard the note
  }

  saveNotes() {}
}

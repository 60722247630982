import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AgendaQueueItem } from 'src/app/models/agenda';
import { DataService } from 'src/app/services';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'wm-agenda-queue-item-input',
  templateUrl: './agenda-queue-item-input.component.html',
  styleUrls: ['./agenda-queue-item-input.component.css']
})
export class AgendaQueueItemInputComponent implements OnInit {
  @Input() queueItem: AgendaQueueItem;

  isSaving = false;
  currentUrl: string;

  constructor(private _dataSvc: DataService, private _toastr: ToastrService) {}

  ngOnInit() {
    this.currentUrl = window.location.pathname;
  }

  saveQueueItem() {
    this.isSaving = true;
    this._dataSvc.saveAgendaQueueItem(this.queueItem).subscribe(() => {
      this._toastr.success('Item Saved!');
      this.isSaving = false;
    });
  }
}

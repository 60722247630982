import { TemplateField } from './../activities/print-template-activity';
import { Observable } from 'rxjs';
import { DataEntity } from './data-entity';
import { fabric } from 'fabric';
import { PointGroup } from 'signature_pad';

export class SignatureDataEntity extends DataEntity {
  image?: string;
  data: Array<PointGroup>;

  constructor(options?: Partial<SignatureDataEntity>) {
    super(options);

    this.dataEntityTypeCode = 'signature';
    this.dataEntityTypeDescription =
      'Use this entity type to represent a Signature.';
    this.dataEntityTypeName = 'Signature';
    this.hasEditor = false;

    if (options) {
      Object.assign(this, options);
    }
  }

  private setScale(fieldInfo, image, helperFunctions) {
    const placeHolderWidth = fieldInfo.width
      ? helperFunctions.documentToScreenX(fieldInfo.width)
      : helperFunctions.documentToScreenX(392);
    const placeHolderHeight = fieldInfo.height
      ? helperFunctions.documentToScreenY(fieldInfo.height)
      : helperFunctions.documentToScreenY(124);

    // scale the placeholder width & height based on the scale of the document in the browser
    image.scaleX = placeHolderWidth / image.width;
    image.scaleY = placeHolderHeight / image.height;
  }

  async formatTemplateItem(fieldInfo, helperFunctions): Promise<fabric.Object> {
    // wait for the image to load before returning object. using promise so I don't have to change calling code
    return new Promise((resolve, reject) => {
      fabric.Image.fromURL(
        '/assets/images/pdf-signature-placeholder_pink.jpg',
        img => {
          this.setScale(fieldInfo, img, helperFunctions);

          img.left = helperFunctions.documentToScreenX(fieldInfo.x);
          img.top = helperFunctions.documentToScreenY(fieldInfo.y);
          img.hasBorders = true;
          img.padding = 1;
          img.borderColor = 'black';
          img.editable = false;
          img.backgroundColor = !this.isValid ? 'red' : 'rgba(255, 0, 0, .25)';

          return resolve(img);
        }
      );
    });
  }

  resizeTemplateItem(
    fieldInfo: TemplateField,
    helperFunctions,
    image: fabric.Object
  ): void {
    if (fieldInfo) {
      image.left = helperFunctions.documentToScreenX(fieldInfo.x);
      image.top = helperFunctions.documentToScreenY(fieldInfo.y);

      this.setScale(fieldInfo, image, helperFunctions);

      image.setCoords();
    }
  }
}

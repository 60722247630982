<div class="container">
  <div class="row justify-content-center align-items-center pt-5">
    <div class="col-6">
      <div class="card">
        <div class="card-body">
          <h6 class="card-title">
            Scheduling Engine Tester
          </h6>
          <div class="form-group">
            <label class="label">How many minutes to run engine?</label>
            <input
              type="datetime"
              [(ngModel)]="durationMinutes"
              class="form-control"
            />
          </div>
          <button
            class="btn btn-raised btn-primary btn-justified"
            (click)="runEngine()"
            [disabled]="engineRunning"
          >
            Run
          </button>
          <div class="mt-2">
            <div *ngIf="engineRunning">
              <div class="text-danger">KEEP THIS TAB OPEN WHILE THE ENGINE IS RUNNING!</div>
              Scheduling engine is now running...
              <br>
              Started at {{ startedOn | date: 'MM/dd/yyyy hh:mm aa' }} and will end at {{ completeOn | date: 'MM/dd/yyyy hh:mm aa' }}
              <br />
              <app-loader></app-loader>
            </div>
            <div *ngIf="!engineRunning">
              Scheduling engine stopped!
            </div>
          </div>
          <div *ngIf="noUserId" class="text-danger">
            ERROR: There was no userId on context. Engine was not run.
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

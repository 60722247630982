import { Component, OnInit } from '@angular/core';
import { SecurityService } from 'src/app/services';
import { Actions } from 'src/app/models';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'wm-contractors-report',
  templateUrl: './contractors-report.component.html',
  styleUrls: ['./contractors-report.component.css']
})
export class ContractorsReportComponent implements OnInit {
  canViewSummary = false;
  canViewFeeSummary = false;

  constructor(private _securitySvc: SecurityService) {}

  ngOnInit() {
    combineLatest(
      this._securitySvc.isLoginEntitled(Actions.VIEW_CONTRACTOR_SUMMARY).pipe(
        map(r => {
          this.canViewSummary = r;
          return r;
        })
      ),
      this._securitySvc
        .isLoginEntitled(Actions.VIEW_CONTRACTOR_FEE_SUMMARY)
        .pipe(
          map(r => {
            this.canViewFeeSummary = r;
            return r;
          })
        )
    ).subscribe();
  }
}

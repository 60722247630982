import { Component, OnInit, Input } from '@angular/core';
import {
  WorkflowApplicationVM,
  User,
  ApplicationStatus
} from '../../../models';
import { DataService, WorkflowContextService } from '../../../services';
import { ItemSearchOptionFieldOption } from '../../filter-list/models/filterClasses';

@Component({
  selector: 'wm-user-workflow-application-list',
  templateUrl: './user-workflow-application-list.component.html',
  styleUrls: ['./user-workflow-application-list.component.css']
})
export class UserWorkflowApplicationListComponent implements OnInit {
  @Input() user: User;
  @Input() workflowId: string;

  applications: WorkflowApplicationVM[];

  // only return NotStarted and InProgress applications
  staticFilters = [
    {
      title: 'status',
      options: [
        new ItemSearchOptionFieldOption({
          title: 'status',
          selected: true,
          filterText: '',
          searchText: `${ApplicationStatus['NotStarted']}, ${ApplicationStatus['InProgress']}`,
          strOperator: 'in'
        })
      ]
    }
  ];

  constructor(
    private _service: DataService,
    public context: WorkflowContextService
  ) {}

  buildWorkflows() {
    if (this.user) {
      this._service
        .getUserApplications(
          this.user,
          this.workflowId,
          null,
          null,
          this.staticFilters
        )
        .subscribe(applications => {
          if (applications) {
            this.applications = applications.workflowApplications;
          }
        });
    }
  }

  toggleCompleted(e) {}

  ngOnInit() {
    this.buildWorkflows();
  }
}

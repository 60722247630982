import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivityView } from '../../../../../views/master-views/app.view/app.view.component';
import { InspectionScheduleActivity } from 'src/app/models/activities/inspection-schedule-activity';
import { FormActivityInputViewComponent } from '../../form-activity-editor/form-activity-input/form-activity-input-view/form-activity-input-view.component';

@Component({
  selector: 'wm-inspection-schedule-activity-input',
  templateUrl: './inspection-schedule-activity-input.component.html',
  styleUrls: ['./inspection-schedule-activity-input.component.css']
})
export class InspectionScheduleActivityInputComponent extends ActivityView
  implements OnInit {
  activity: InspectionScheduleActivity;

  @ViewChild('formActivityInputView', { static: false })
  formActivityInputViewCtl: FormActivityInputViewComponent; 

  constructor() {
    super();
  }

  ngOnInit() {}

  async persistChildComponent() {
    return this.formActivityInputViewCtl.persistChildComponent();
  }
}

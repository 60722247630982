import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css']
})
export class LoaderComponent implements OnInit {
  @Input() msDelay = 0;

  constructor() {}
  delayed = true;

  ngOnInit() {
    setTimeout(() => {
      this.delayed = false;
    }, this.msDelay);
  }
}

import { Workflow } from './../../../models/workflow';
import { Component, OnInit, Input } from '@angular/core';
import { ContractorRegistration } from 'src/app/models';
import { RegistrationVM } from 'src/app/models/registration';

@Component({
  selector: 'wm-registration-list-section',
  templateUrl: './registration-list-section.component.html',
  styleUrls: ['./registration-list-section.component.css']
})
export class RegistrationListSectionComponent implements OnInit {
  @Input() title: string;
  @Input() areRenewalRegistrations: boolean;
  @Input() contractorRegistrations: ContractorRegistration[];
  @Input() renewalRegistrations: RegistrationVM[];

  constructor() {}

  ngOnInit() {}
}

import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'wm-qr-code',
  templateUrl: './qr-code.component.html',
  styleUrls: ['./qr-code.component.css']
})
export class QrCodeComponent implements OnInit {
  @Input() data: string;
  @Input() imgClass = 'img-fluid';
  @Input() imgAlt = 'QR code';

  apiBase = 'https://qrapi.dev';

  qrUrl: string;

  constructor() {}

  generateQr(data) {
    return `${this.apiBase}/api/generate/?data=${encodeURIComponent(data)}`;
  }

  ngOnInit() {
    this.qrUrl = this.generateQr(this.data);
  }
}

import {
  Component,
  OnInit,
  forwardRef,
  Inject,
  ViewChild
} from '@angular/core';
import { DataEntityEditorBaseComponent } from '../../data-entity-editor-base/data-entity-editor-base.component';
import { WorkflowContextService } from '../../../../../services';
import { Client } from '../../../../../models';
import {
  MapsketchDataEntity,
  ScreenCaptureLayoutMethod,
  MapDimensionLayout_htmlClassName
} from '../../../../../models/data-entities';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'wm-mapsketch-data-entity-editor',
  templateUrl: './mapsketch-data-entity-editor.component.html',
  styleUrls: ['./mapsketch-data-entity-editor.component.css']
})
export class MapsketchDataEntityEditorComponent
  extends DataEntityEditorBaseComponent
  implements OnInit {
  mapsketchDataEntity: MapsketchDataEntity;
  client: Client;

  ScreenCaptureLayoutMethods = [
    ScreenCaptureLayoutMethod.Default,
    ScreenCaptureLayoutMethod.Landscape,
    ScreenCaptureLayoutMethod.Portrait,
    ScreenCaptureLayoutMethod.Square
  ];
  ScreenCaptureLayoutMethod_Options = ScreenCaptureLayoutMethod;
  form: UntypedFormGroup = new UntypedFormGroup({});
  mapDimensionLayout_class: MapDimensionLayout_htmlClassName;
  constructor(
    private _fb: UntypedFormBuilder,
    @Inject(forwardRef(() => WorkflowContextService))
    private _context: WorkflowContextService
  ) {
    super();
  }

  async ngOnInit() {
    this.mapsketchDataEntity = this.dataEntity as MapsketchDataEntity;
    this.mapDimensionLayout_class = this.mapsketchDataEntity.generateValid_htmlClassNameFromOptionText(
      ScreenCaptureLayoutMethod[
        this.mapsketchDataEntity.screenCaptureLayoutMethod
      ].toLowerCase()
    );

    this.client = this._context.client;
    this.form.addControl(
      'PrintTemplateLayoutControl',
      this._fb.control(this.mapsketchDataEntity.screenCaptureLayoutMethod, [
        Validators.nullValidator
      ])
    );
  }
  public printTemplateLayout_ChangeSelection(event: any) {
    this.mapsketchDataEntity.screenCaptureLayoutMethod = this.form.controls[
      'PrintTemplateLayoutControl'
    ].value;

    this.mapDimensionLayout_class = this.mapsketchDataEntity.generateValid_htmlClassNameFromOptionText(
      ScreenCaptureLayoutMethod[
        this.mapsketchDataEntity.screenCaptureLayoutMethod
      ].toLowerCase()
    );
  }
}

<app-loader *ngIf="!contractorType"></app-loader>

<div *ngIf="context && context.client && contractorType">
  <div class="row mb-3">
    <div class="col-6">
      <div class="row">
        <div class="col-auto">
          <h3>{{ contractorType.name }}</h3>
          <p>
            {{ contractorType.description }}
          </p>
        </div>

        <div class="col" *ngIf="canEdit">
          <div class="float-right">
            <button
              class="btn btn-raised btn-primary mx-1"
              [routerLink]="[
                '/admin/workflow-builder',
                contractorType.registerWorkflowId
              ]"
            >
              Register Builder
            </button>
            <button
              class="btn btn-raised btn-primary mx-1"
              [routerLink]="[
                '/admin/workflow-builder',
                contractorType.renewWorkflowId
              ]"
            >
              Renew Builder
            </button>
            <button
              class="btn btn-raised btn-default mx-1"
              [routerLink]="[
                '/admin/jurisdiction',
                context.client.id,
                'contractors',
                'types',
                'edit',
                contractorType.id
              ]"
            >
              Edit
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="col-6">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-auto">
              <p class="tex-muted">Registration Fee</p>
              <h4>{{ contractorType.registrationFee }}</h4>
            </div>
            <div class="col-auto">
              <p class="tex-muted">Renewal Fee</p>
              <h4>{{ contractorType.renewalFee }}</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <h4>Contractors</h4>

      <wm-contractor-registration-list
        [id]="id"
        [additionalCommands]="commands"
        [initialColumns]="columns"
        [staticFilters]="filterOptions"
        [dynamicColumnsVisibleByDefault]="dynamicColumnsVisibleByDefault"
      >
      </wm-contractor-registration-list>
    </div>
  </div>
</div>

import { Component, OnInit } from '@angular/core';
import { ActivityView } from 'src/app/views/master-views/app.view/app.view.component';

@Component({
  selector: 'wm-contractor-renewal-decision-activity-input',
  templateUrl: './contractor-renewal-decision-activity-input.component.html',
  styleUrls: ['./contractor-renewal-decision-activity-input.component.css']
})
export class ContractorRenewalDecisionActivityInputComponent
  extends ActivityView
  implements OnInit {
  constructor() {
    super();
  }

  ngOnInit() {}
}

import { Utilities } from './../../../../../services/utilities/index';
import { LoginResponse } from './../../../../../models/security';
import {
  Component,
  OnInit,
  Inject,
  forwardRef,
  OnDestroy
} from '@angular/core';
import { ActivityView } from 'src/app/views/master-views/app.view/app.view.component';
import { Router, ActivatedRoute } from '@angular/router';
import { RequiredUserActivity } from 'src/app/models/activities/required-user-activity';
import { SecurityService } from 'src/app/services';
import { Actions, User } from 'src/app/models';
import {
  UntypedFormBuilder,
  Validators,
  UntypedFormGroup,
  UntypedFormControl,
  FormsModule
} from '@angular/forms';
@Component({
  selector: 'wm-required-user-activity-input',
  templateUrl: './required-user-activity-input.component.html',
  styleUrls: ['./required-user-activity-input.component.css']
})
export class RequiredUserActivityInputComponent extends ActivityView
  implements OnInit, OnDestroy {
  activity: RequiredUserActivity;
  public showLogin = true;
  public applicationId: string;
  public canApplyOnBehalfOf = false;
  showRegister = false;
  returnUrl: string;
  private sub: any;
  public userForm: UntypedFormGroup;

  constructor(
    private _fb: UntypedFormBuilder,
    public router: Router,
    public route: ActivatedRoute,
    @Inject(forwardRef(() => SecurityService))
    private _securitySvc: SecurityService
  ) {
    super();
  }
  isValidFormSubmitted: boolean = null;
  rdoOtherUser = true;
  rdoLoggedInUser = false;
  rdoregisterNewUser = false;

  toggleLogin() {
    this.showLogin = !this.showLogin;
  }
  onLoggedIn() {
    this.showLogin = false;
    this.goNext.emit(true);
  }
  clearUser() {
    this.activity.model.user = null;
    this.activity.model.userId = null;
    this.showNext.emit(false);
    if (window.event) {
      window.event.preventDefault();
    }
  }
  startRegister() {
    this.showRegister = true;
    this.showNext.emit(false);
    this.activity.model.user = null;
    this.activity.model.userId = null;
  }
  behalfOfValue(testValue: string) {
    return this.userForm.get('behalfOf').value === testValue;
  }
  onChanges(): void {
    this.sub = this.userForm.get('behalfOf').valueChanges.subscribe(val => {
      if (val !== 'registerNewUser') {
        this.showRegister = false;
      }

      switch (val) {
        case 'loggedInUser':
          this.activity.model.userId = this.context.user.id;
          this.activity.model.user = this.context.user;
          this.showNext.emit(true);
          break;
        case 'anotherUser':
          this.clearUser();
          break;

        case 'registerNewUser':
          this.clearUser();
          this.startRegister();
      }
    });
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  ngOnInit() {
    // don't allow the next/updated until a radio button is selected, done by including the userId as a required feel, only filled in by selecting the radio button.

    this.userForm = new UntypedFormGroup({
      behalfOf: new UntypedFormControl()
    });

    // capture the loaded user because it will get cleared out when onChanges happens
    const existingUser = this.activity.model.user;
    this.form.addControl('behalfOf', this._fb.control(''));
    this.showNext.emit(false);
    this.onChanges();
    this.userForm.patchValue({ behalfOf: 'anotherUser' });

    // if there was a user on load re-select that user
    if (existingUser) {
      this.selectUser(existingUser);
    }

    this.route.params.subscribe(params => {
      this.applicationId = params['applicationId'];

      this.returnUrl =
        Utilities.getRootURL() +
        '/home/AssociateUserFromRequireUserActivity?appId=' +
        this.applicationId;
    });
    // this would only happen if I were on this activity, then registered a new user, then left the application and logged back in at the new registered user ... it could happen.
    if (this.activity.model.isUserLoggedIn) {
      this.showLogin = false;

      this._securitySvc
        .isLoginEntitled(Actions.APPLY_ON_BEHALF_OF)
        .subscribe(entitled => {
          this.canApplyOnBehalfOf = entitled;
        });
      // this.onLoggedIn();
    } else {
      this.showNext.emit(false);
    }
  }

  selectUser(e: Partial<User>, goNext: boolean = false) {
    this.activity.model.user = e;
    this.activity.model.userId = e.id;
    this.showNext.emit(true);
    this.showRegister = false;
    if (goNext) {
      this.goNext.emit();
    }
  }

  registerCanceled() {
    this.showRegister = false;
    this.userForm.patchValue({ behalfOf: 'anotherUser' });
  }

  registered(e: LoginResponse) {
    this.selectUser(e.user, true);
  }
}

<app-loader *ngIf="loading"></app-loader>

<div *ngIf="!loading && navigationItems && navigationItems.length > 0">
  <div class="switch pl-5 pt-2" *ngIf="hasEmailsToDisplay">
    <label>
      <input
        type="checkbox"
        [(ngModel)]="showEmails"
        (change)="setCurrentNavigationItems()"
      />
      Show emails
    </label>
  </div>

  <ul class="list-group">
    <li
      *ngFor="let ni of currentNavigationItems; index as i"
      [ngbTooltip]="getTooltip(ni)"
      container="body"
    >
      <a
        class="list-group-item list-group-item-action activity-item"
        [ngClass]="{ 'active-activity': isActive(ni) }"
        (click)="navigate(ni)"
      >
        <div class="d-flex w-100">
          <div class="activity-circle" [ngClass]="navClass(ni)">
            {{ i + 1 }}
            <div class="vl" *ngIf="!ni.isEnd">&nbsp;</div>
          </div>

          <div
            class="align-self-center"
            [ngStyle]="{
              'text-decoration': !ni.activityRole ? 'none' : 'underline'
            }"
            [ngClass]="{ 'font-weight-bold': !ni.completed }"
          >
            {{ ni.activityLabel }}
          </div>
        </div>
      </a>
    </li>
    <li
      *ngIf="!currentNavigationItems[currentNavigationItems.length - 1].isEnd"
    >
      <a class="list-group-item list-group-item-action activity-item">
        <div class="activity-circle">
          {{ currentNavigationItems.length + 1 }}
        </div>
        Next Steps...
      </a>
    </li>
  </ul>
</div>

import { Component, OnInit, ComponentFactoryResolver } from '@angular/core';
import { DataEntityViewComponent } from '../../data-entity-view/data-entity-view.component';
import { YesNoDataEntity } from 'src/app/models/data-entities';

@Component({
  selector: 'wm-yes-no-data-entity-view',
  templateUrl: './yes-no-data-entity-view.component.html',
  styleUrls: ['./yes-no-data-entity-view.component.css']
})
export class YesNoDataEntityViewComponent extends DataEntityViewComponent
  implements OnInit {
  constructor(componentFactoryResolver: ComponentFactoryResolver) {
    super(componentFactoryResolver);
  }
  yesNoEntity: YesNoDataEntity;

  ngOnInit() {
    this.yesNoEntity = <YesNoDataEntity>this.entity;
  }
}

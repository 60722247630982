import { Component, OnInit, ComponentFactoryResolver } from '@angular/core';
import { FeeDataEntity } from 'src/app/models/data-entities';
import { DataEntityViewComponent } from '../../data-entity-view/data-entity-view.component';

@Component({
  selector: 'wm-fee-data-entity-view',
  templateUrl: './fee-data-entity-view.component.html',
  styleUrls: ['./fee-data-entity-view.component.css']
})
export class FeeDataEntityViewComponent extends DataEntityViewComponent
  implements OnInit {
  constructor(componentFactoryResolver: ComponentFactoryResolver) {
    super(componentFactoryResolver);
  }

  entity: FeeDataEntity;

  modelValue: number;

  ngOnInit() {
    const value = parseFloat(this.entity.formulaValue);
    if (isNaN(value)) {
      this.modelValue = 0;
    } else {
      this.modelValue = value;
    }
  }
}

import {
  Component,
  OnInit,
  AfterViewInit,
  EventEmitter,
  Sanitizer,
  Inject,
  forwardRef
} from '@angular/core';
import { ActivityView } from '../../../../../views/master-views/app.view/app.view.component';
import { PrintTemplateActivity } from '../../../../../models/activities';
import { WorkflowService } from '../../../../../services';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'wm-print-template-activity-input',
  templateUrl: './print-template-activity-input.component.html',
  styleUrls: ['./print-template-activity-input.component.css']
})
export class PrintTemplateActivityInputComponent extends ActivityView
  implements OnInit, AfterViewInit {
  activity: PrintTemplateActivity;
  documentPath: SafeUrl;
  documentName: string;

  constructor(
    private sanitizer: DomSanitizer,
    @Inject(forwardRef(() => WorkflowService))
    private _workflowSvc: WorkflowService,
    private _toastr: ToastrService
  ) {
    super();
  }

  ngOnInit() {
    this.documentName = this.activity.model.evaluatedDocName;
    this.documentPath = this.sanitizer.bypassSecurityTrustResourceUrl(
      this.activity.model.documentPath
    );
  }

  ngAfterViewInit() {
    if (this.activity.model.autoContinue && !this.activity.completedOn) {
      // go next in the next tick so errors aren't thrown...
      setTimeout(() => {
        this.goNext.emit(true);
      }, 0);
    }
  }

  regeneratingDocument = false;

  regenerateDocument() {
    this.regeneratingDocument = true;
    this._workflowSvc
      .regeneratePrintTemplate(
        this.context.applicationId,
        this.activity.id,
        this.activity.applicationIteration
      )
      .subscribe(result => {
        this._workflowSvc
          .navigateToActivity(
            this.context.applicationId,
            this.activity.activityDataId
          )
          .subscribe(resp => {
            const reloadedActivity = resp.activity;

            this.regeneratingDocument = false;
            if (result === true) {
              this.activity.model = (<PrintTemplateActivity>(
                reloadedActivity
              )).model;

              this._toastr.success(
                'The document has been regenerated successfully.'
              );
              this.goNext.emit(true);
            } else {
              this._toastr.error(
                'The document failed to regenerate, please try again. If the problem persists, please contact support.'
              );
            }
          });
      });
  }
}

import {
  Component,
  OnInit,
  Input,
  ComponentFactoryResolver
} from '@angular/core';
import { DataEntityInputComponent } from '../../data-entity-input/data-entity-input.component';
import { TodayDataEntity } from 'src/app/models/data-entities/today-data-entity';
import { UntypedFormBuilder, NgForm } from '@angular/forms';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'wm-today-data-entity-input',
  templateUrl: './today-data-entity-input.component.html',
  styleUrls: ['./today-data-entity-input.component.css']
})
export class TodayDataEntityInputComponent extends DataEntityInputComponent
  implements OnInit {
  @Input() entity: TodayDataEntity;
  constructor(
    componentFactoryResolver: ComponentFactoryResolver,
    private _dataSvc: DataService,
    private _fb: UntypedFormBuilder
  ) {
    super(componentFactoryResolver);
  }

  todaysDate: Date;

  ngOnInit() {
    this.todaysDate = this.entity.value;
  }
}

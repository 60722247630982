import {
  Activity,
  ScreenActivity,
  ActivityModel
} from '../../models/activities/activity';

export class CreateUserActivity extends ScreenActivity<ActivityModel> {
  userName: string;
  password: string;
  emailAddress: string;
  validationCode: string;

  constructor(options?: { [key: string]: any }) {
    super(options);

    this.type = 'create-user-activity';
    this.name = 'Create User';
    this.description =
      'Use this activity to require the applicant to create a user in the system.';
  }
}

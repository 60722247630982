import { Component, OnInit, EventEmitter } from '@angular/core';
import { ActivityView } from '../../../../../views/master-views/app.view/app.view.component';
import { Activity, ActivityModel } from '../../../../../models/activities';
import { FormGroup } from '@angular/forms';
import { WorkflowContextService } from '../../../../../services';
import { InspectionActivityModel } from 'src/app/models/activities/inspection-request-activity';
import { InspectionRequestActivity } from 'src/app/models/activities/inspection/inspection-request-activity';

@Component({
  selector: 'wm-inspection-request-activity-view',
  templateUrl: './inspection-request-activity-view.component.html',
  styleUrls: ['./inspection-request-activity-view.component.css']
})
export class InspectionRequestActivityViewComponent extends ActivityView
  implements OnInit {
  activity: InspectionRequestActivity;
  model: InspectionActivityModel;

  constructor() {
    super();
  }

  ngOnInit() {
    this.model = new InspectionActivityModel(
      this.activity.model ? this.activity.model : null
    );

    // this.requestorEmail =this.model.inspection.requestorEmail;
  }
}

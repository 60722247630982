import { Component, OnInit, forwardRef, Inject, Input } from '@angular/core';
import { ContractorSelectionActivity } from 'src/app/models/activities/contractor-selection-activity';
import { ActivityView } from 'src/app/views/master-views/app.view/app.view.component';
import { ContractorService, ValidationService } from 'src/app/services';
import { Contractor, Client, Contact } from 'src/app/models';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  AbstractControl,
  Validators
} from '@angular/forms';

@Component({
  selector: 'wm-contractor-selection-activity-view',
  templateUrl: './contractor-selection-activity-view.component.html',
  styleUrls: ['./contractor-selection-activity-view.component.css']
})
export class ContractorSelectionActivityViewComponent extends ActivityView
  implements OnInit {
  form: UntypedFormGroup;
  @Input() showGoBackToList = true;
  contact: Contact;
  client: Client;
  contactId: string;
  firstName: AbstractControl;
  lastName: AbstractControl;
  phoneNumber: AbstractControl;
  emailAddress: AbstractControl;
  title: AbstractControl;
  activity: ContractorSelectionActivity;
  contractor: Contractor;

  constructor(
    @Inject(forwardRef(() => ContractorService))
    public _contractorSvc: ContractorService,
    private fb: UntypedFormBuilder
  ) {
    super();
    if (fb) {
      this.form = this.fb.group({
        firstName: ['', Validators.required],
        lastName: ['', Validators.required],
        phoneNumber: [
          '',
          Validators.compose([ValidationService.phoneNumberValidator])
        ],
        emailAddress: [
          '',
          Validators.compose([
            Validators.required,
            ValidationService.emailValidator
          ])
        ],
        title: ['', Validators.required]
      });
      //#endregion
      this.firstName = this.form.controls['firstName'];
      this.lastName = this.form.controls['lastName'];
      this.phoneNumber = this.form.controls['phoneNumber'];

      this.title = this.form.controls['title'];
    }
  }

  ngOnInit() {
    const contractorId = this.activity.model['contractorSelection'][
      'selectedRegistrationId'
    ];
    if (contractorId) {
      this._contractorSvc
        .getContractorByRegistrationId(
          this.activity.model['contractorSelection']['selectedRegistrationId']
        )
        .subscribe(type => {
          this.contractor = type;
        });
    }
  }
}

import { Activity, ContainerActivity, ActivityModel } from './activity';

export class SequentialActivity extends ContainerActivity<ActivityModel> {
  constructor(options?: Partial<SequentialActivity>) {
    super(options);

    this.type = 'sequential-activity';
    this.activities = [];
    this.name = 'Sequential Steps';
    this.description =
      'Use this activity to define a set of activities to run in sequence.';
    this.isContainer = true;

    if (options) {
      Object.assign(this, options);
    }
  }
}

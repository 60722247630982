import { PaymentActivity } from '../../../models/activities/payment-activity';
import { PaymentAccount } from '../../../models/payment-providers/payment-account';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { PaymentAccountActivityConfigurationBase } from 'src/app/models/payment-providers/payment-account-activity-configuration-base';
import { Workflow } from 'src/app/models';
import { Activity, ActivityModel } from 'src/app/models/activities';

@Component({
  selector: 'wm-payment-account-activity-config-base',
  templateUrl: './payment-account-activity-config-base.component.html',
  styleUrls: ['./payment-account-activity-config-base.component.css']
})
export class PaymentAccountActivityConfigBaseComponent implements OnInit {
  @Input() workflow: Workflow;
  @Input() activity: Activity<ActivityModel>;
  @Input() paymentActivity: PaymentActivity;

  @Output() configUpdated: EventEmitter<
    PaymentAccountActivityConfigurationBase
  > = new EventEmitter();

  constructor() {}

  ngOnInit() {}
}

import {
  Component,
  OnInit,
  Input,
  Inject,
  forwardRef,
  ViewChild,
  OnDestroy
} from '@angular/core';
import { ActivityEditorBaseComponent } from '../../activity-editor-base/activity-editor-base.component';
import { MultipleParcelSearchActivity } from 'src/app/models/activities';
import { Client } from 'src/app/models';
import {
  DataService,
  WorkflowContextService,
  SystemService
} from '../../../../../services';
import {
  FormGroup,
  Validators,
  UntypedFormBuilder,
  ValidatorFn,
  AbstractControl,
  FormControl
} from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'wm-multiple-parcel-search-activity-editor',
  templateUrl: './multiple-parcel-search-activity-editor.component.html',
  styleUrls: ['./multiple-parcel-search-activity-editor.component.css']
})
export class MultipleParcelSearchActivityEditorComponent
  extends ActivityEditorBaseComponent
  implements OnInit, OnDestroy {
  @Input() activity: MultipleParcelSearchActivity;
  activityId: string;
  parcelServiceExists = 'checking';
  message = 'Parcel Editor';

  client: Client;
  txtClass = '';

  private clientSubscription: Subscription;
  constructor(
    private _fb: UntypedFormBuilder,
    @Inject(forwardRef(() => SystemService)) private _systemsvc: SystemService,
    @Inject(forwardRef(() => DataService)) private _dataSvc: DataService,
    @Inject(forwardRef(() => WorkflowContextService))
    private _context: WorkflowContextService
  ) {
    super();
  }
  async ngOnInit() {
    if (this._context.client) {
      this.client = this._context.client;
      this.checkForParcelService();
    } else {
      this.clientSubscription = this._context.client$.subscribe(() => {
        this.client = this._context.client;
        this.checkForParcelService();
      });
    }
  }
  ngOnDestroy() {
    if (this.clientSubscription) {
      this.clientSubscription.unsubscribe();
      this.clientSubscription = null;
    }
  }

  checkForParcelService() {
    const results = this._dataSvc
      .getParcelDataSources(this._context.client.id)
      .subscribe(res => {
        if (res.length > 1) {
          this.message =
            'There Have been more than one Parcel Services defined for this \
       client. \n  Taking the first one by default';
          this.txtClass = 'info';
        }
        if (res.length < 1) {
          this.message =
            'There hasn\'t been any External data connections configured for this\
        client, please contact your support representative';
          this.txtClass = 'danger';
        }
        if (res.length === 1) {
          this.message = 'Successfully configured';
          this.txtClass = 'success';
        }
      });
  }
}

import { Component, OnInit } from '@angular/core';
import { PaymentProcessor } from '../../../../models';
import { SystemService } from '../../../../services';

@Component({
  selector: 'wm-payment-processor-list-view',
  templateUrl: './payment-processor-list-view.component.html',
  styleUrls: ['./payment-processor-list-view.component.css']
})
export class PaymentProcessorListViewComponent implements OnInit {
  processors: PaymentProcessor[];

  constructor(private _systemSvc: SystemService) {}

  ngOnInit() {
    this._systemSvc.getPaymentProcessors().subscribe(processors => {
      this.processors = processors;
    });
  }
}

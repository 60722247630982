import {
  Component,
  OnInit,
  forwardRef,
  ChangeDetectorRef,
  Inject
} from '@angular/core';
import { DataEntityEditorBaseComponent } from '../../data-entity-editor-base/data-entity-editor-base.component';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import {
  SystemService,
  WorkflowContextService,
  WorkflowService
} from 'src/app/services';
import { YesNoDataEntity } from 'src/app/models/data-entities';
import { RestrictedFormatHandler } from '../../../../../models/data-entities';

@Component({
  selector: 'wm-yes-no-data-entity-editor',
  templateUrl: './yes-no-data-entity-editor.component.html',
  styleUrls: ['./yes-no-data-entity-editor.component.css']
})
export class YesNoDataEntityEditorComponent
  extends DataEntityEditorBaseComponent
  implements OnInit {
  public yesnoEntity: YesNoDataEntity;

  constructor(
    @Inject(forwardRef(() => WorkflowService))
    public _workflowSvc: WorkflowService,
    @Inject(forwardRef(() => WorkflowContextService))
    private _context: WorkflowContextService,
    @Inject(forwardRef(() => SystemService)) private _systemSvc: SystemService,
    private changeDetRef: ChangeDetectorRef,
    private _fb: UntypedFormBuilder
  ) {
    super();
  }
  isRequiredChecked() {
    return true;
  }
  ngOnInit() {
    this.form.addControl(
      'listTitle',
      this._fb.control(false, [Validators.nullValidator])
    );
    this.form.addControl(
      'listValue',
      this._fb.control(false, [Validators.nullValidator])
    );
    this.form.addControl(
      'controlType',
      this._fb.control(false, [Validators.nullValidator])
    );
    this.form.addControl(
      'controlOptions',
      this._fb.control(false, [Validators.nullValidator])
    );
    this.form.addControl(
      'requiredEntry',
      this._fb.control(false, [Validators.nullValidator])
    );
    this.yesnoEntity = this.dataEntity as YesNoDataEntity;
  }
}

export interface RestrictedFormatEditor {
  form: UntypedFormGroup;
  formatter: RestrictedFormatHandler;
}

import { Directive, HostListener, OnInit, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

import * as numeral from 'numeral';

@Directive({
  selector: '[ngModel][wmNumberFormat]'
})
export class NumberFormatDirective implements OnInit {
  /**
   * Number of decimals to use
   */
  @Input() wmNumberFormat: number;

  constructor(private control: NgControl) {}

  ngOnInit() {
    if (this.control.value) {
      const formatted = this.format(this.control.value);
      setTimeout(() => this.control.valueAccessor.writeValue(formatted), 0);
    }
  }

  @HostListener('blur', ['$event'])
  onBlur() {
    if (this.control.value) {
      const newValue = this.format(this.control.value);

      this.control.viewToModelUpdate(newValue);
      this.control.valueAccessor.writeValue(newValue);
      this.control.control.setValue(newValue);
    }
  }

  format(value) {
    if (this.wmNumberFormat && this.wmNumberFormat > 0) {
      let decimalPlaces = '.';

      for (let index = 0; index < this.wmNumberFormat; index++) {
        decimalPlaces += '0';
      }

      return numeral(value).format(`0${decimalPlaces}`);
    }

    return numeral(value).format('0[.][00000000000000000000]');
  }
}

import { WorkflowGraph } from './workflow-graph';
import { Role } from './role';
import { Workflow } from '.';

export class WorkflowVersion {
  id: string;
  workflow?: Workflow;
  workflowId: string;
  name: string;
  description: string;
  numberPrefix: string;
  startingNumber: number;
  modifiedOn: Date;
  modifiedBy: string;
  adminRole: Role;
  adminRoleId: string;
  graph: WorkflowGraph;
  isDraft: boolean;
  applicationDescriptionTemplate: string;

  constructor(options?: Partial<WorkflowVersion>) {
    this.graph = new WorkflowGraph();
    if (options) {
      Object.assign(this, options);
    }
  }
}

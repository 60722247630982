<form [formGroup]="form" novalidate>
  <label [for]="contractorDocEntity.templateCode" class="bmd-label-floating">
    Document Category
  </label>
  <select
    class="form-control"
    [id]="contractorDocEntity.templateCode"
    [name]="contractorDocEntity.templateCode"
    formControlName="documentCategory"
    (change)="selectCategory($event.target.value)"
  >
    <option>Select Item...</option>
    <option
      *ngFor="let category of categories"
      [selected]="
        contractorDocEntity &&
        contractorDocEntity.documentCategory &&
        contractorDocEntity.documentCategory.id.toLowerCase() ===
          category.id.toLowerCase()
      "
      [value]="category.id"
      >{{ category.name }}</option
    >
  </select>

  <wm-document-data-entity
    [dataEntity]="dataEntity"
    [form]="form"
  ></wm-document-data-entity>
</form>

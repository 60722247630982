import { Component, OnInit } from '@angular/core';
import { ContractorDocumentCategory } from '../../../models/contractor-document-category';
import { ContractorService, WorkflowContextService } from '../../../services';
import { Subscription } from 'rxjs';

@Component({
  selector: 'wm-contractor-document-category-editor',
  templateUrl: './contractor-document-category-editor.component.html',
  styleUrls: ['./contractor-document-category-editor.component.css']
})
export class ContractorDocumentCategoryEditorComponent implements OnInit {
  categories: ContractorDocumentCategory[];
  newCategory: ContractorDocumentCategory;

  clientSub: Subscription;

  constructor(
    private _contractorSvc: ContractorService,
    private _context: WorkflowContextService
  ) {}

  loadCategories() {
    this._contractorSvc.getContractorDocumentCategories().subscribe(dcs => {
      this.categories = dcs;
    });
  }

  addDocumentCategory() {
    this.newCategory = new ContractorDocumentCategory();
  }

  removeDocumentCategory(id: string) {
    this._contractorSvc.deleteContractorDocumentCategory(id).subscribe(() => {
      this.loadCategories();
    });
  }

  saveDocumentCategory(category: ContractorDocumentCategory) {
    this._contractorSvc
      .saveContractorDocumentCategory(category)
      .subscribe(() => {
        category.isEditing = false;
        this.newCategory = null;
        this.loadCategories();
      });
  }

  ngOnInit() {
    this.loadCategories();
  }
}

<wm-form-activity-input-view
  [activity]="activity"
  [form]="form"
  [applicationId]="applicationId"
  [isReadOnly]="true"
></wm-form-activity-input-view>
<div class="form-group">
  <label for="Status" class="bmd-label-static">
    Status
  </label>
  <input
    type="text"
    readonly
    class="form-control-plaintext"
    id="Status"
    [value]="status"
  />
</div>

import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  OnDestroy
} from '@angular/core';
import { EmailAddress } from '../../../models';
import { ProcessingStatusComponent } from '../processing-status/processing-status.component';
import {
  AbstractControl,
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators
} from '@angular/forms';
import {
  DataService,
  SystemService,
  ValidationService
} from '../../../services';

@Component({
  selector: 'wm-email-address-detail',
  templateUrl: './email-address-detail.component.html',
  styleUrls: ['./email-address-detail.component.css']
})
export class EmailAddressDetailComponent implements OnInit, OnDestroy {
  ngOnDestroy(): void {
    if (this.showName) {
      this.form.removeControl('emailName');
    }
    this.form.removeControl('emailAddress');
  }
  @Input() emailAddress: EmailAddress;
  @Output() saved: EventEmitter<EmailAddress> = new EventEmitter<
    EmailAddress
  >();
  @Output() canceled: EventEmitter<EmailAddress> = new EventEmitter<
    EmailAddress
  >();
  @ViewChild('saveEmailAddressStatus', { static: false })
  saveEmailAddressStatus: ProcessingStatusComponent;
  @Input() showButtons = false;
  @Input() required = true;
  @Input() showName = true;

  emailNameControl: AbstractControl;
  @Input() form: UntypedFormGroup;
  @Input() parentType: string;
  @Input() emailAddressCtlName = 'emailAddress';
  @Input() showHeader = false;

  constructor(
    private _dataSvc: DataService,
    private _systemSvc: SystemService,
    private fb: UntypedFormBuilder
  ) {}
  update(value) {}
  ngOnInit() {
    if (!this.emailAddress) {
      this.emailAddress = this._systemSvc.createEmailAddress(this.parentType);
    }
    if (this.form) {
      if (this.showName == true) {
        this.form.addControl(
          'emailName',
          this.fb.control('', [Validators.required])
        );
      }
      //if (this.required == true) {
      //  this.form.addControl('emailAddress', this.fb.control('', Validators.compose([Validators.required, ValidationService.emailValidator])));

      //}
      //else {
      //  this.form.addControl('emailAddress', this.fb.control('', Validators.compose([ ValidationService.emailValidator])));

      //}
    }

    this.emailNameControl = this.form.controls['emailName'];
  }

  save() {
    this.saveEmailAddressStatus.show();
    this._systemSvc.saveEmailAddress(this.emailAddress).subscribe(res => {
      this.saveEmailAddressStatus
        .finished('Email Address Saved', 1000)
        .subscribe(() => {
          this.emailAddress = null;
          this.saved.next(res);
        });
    });
  }

  cancel() {
    this.canceled.next(this.emailAddress);
  }
}

<div>
  <div class="row">
    <div class="col">
      <h3>Workflows</h3>
    </div>
    <div class="col" *ngIf="canCreateWorkflow">
      <button
        type="button"
        class="btn btn-raised btn-success float-right"
        (click)="createWorkflow()"
      >
        New Workflow
      </button>
    </div>
  </div>

  <ul ngbNav #nav="ngbNav" [destroyOnHide]="false" class="nav-tabs">
    <li ngbNavItem>
      <a ngbNavLink>Workflows</a>
      <ng-template ngbNavContent>
        <wm-workflow-list
          [clientId]="_context?.client?.id"
          [workflows]="workflows"
          [showEdit]="true"
          [loadGlobal]="loadGlobal"
          (onRestore)="restoreWorkflow($event)"
          (onDisable)="disableWorkflow($event)"
          (onEnable)="enableWorkflow($event)"
          (onPublish)="publishWorkflow($event)"
          (onArchive)="archiveWorkflow($event)"
          (onCopy)="copyWorkflow($event)"
          (toggleVisible)="toggleVisibleToPublic($event)"
        ></wm-workflow-list>
      </ng-template>
    </li>
    <li ngbNavItem>
      <a ngbNavLink>Archive</a>
      <ng-template ngbNavContent>
        <wm-workflow-list
          [clientId]="_context?.client?.id"
          [workflows]="archivedWorkflows"
          [showEdit]="true"
          (onRestore)="restoreWorkflow($event)"
          (onDisable)="disableWorkflow($event)"
          (onEnable)="enableWorkflow($event)"
          (onPublish)="publishWorkflow($event)"
          (onArchive)="archiveWorkflow($event)"
          (onCopy)="copyWorkflow($event)"
        ></wm-workflow-list>
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="nav" class="mt-2"></div>
</div>

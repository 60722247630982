import * as moment from 'moment';
import { IDateValidator } from '../../date-validators';

export class TodayValidator implements IDateValidator {
  validateMaxDate(date: Date, options: any): boolean {
    return this.validateDate(date, options);
  }
  validateMinDate(date: Date, options: any): boolean {
    return this.validateDate(date, options);
  }
  validateDate(date: Date, options: any): boolean {
    return moment().diff(date, 'days') === 0;
  }
  calculateExample(date: Date, options: any): Date {
    return new Date();
  }
}

import { PaymentProcessorConfigurationBase } from '../payment-processor-configuration-base';

export class MunicipayPaymentProcessorConfiguration extends PaymentProcessorConfigurationBase {
  constructor(options?: Partial<MunicipayPaymentProcessorConfiguration>) {
    super(options);

    if (options) {
      Object.assign(this, options);
    }
  }
}

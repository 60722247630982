import { PaymentProcessorAccountConfigurationBase } from '../payment-processor-account-configuration-base';

export class OfficialpaymentsPaymentProcessorAccountConfiguration extends PaymentProcessorAccountConfigurationBase {
  productId: string;
  uniqueFieldId: string;
  permitNumberFieldId: string;
  parcelNumberFieldId: string;
  parcelNumberDataEntityName: string;

  constructor(
    options?: Partial<OfficialpaymentsPaymentProcessorAccountConfiguration>
  ) {
    super(options);
    if (options) {
      Object.assign(this, options);
    }
  }
}

import { Component, OnInit, ComponentFactoryResolver } from '@angular/core';
import { DataEntityViewComponent } from '../../data-entity-view/data-entity-view.component';
import { YDataEntity } from 'src/app/models/data-entities/y-data-entity';

@Component({
  selector: 'wm-y-data-entity-view',
  templateUrl: './y-data-entity-view.component.html',
  styleUrls: ['./y-data-entity-view.component.css']
})
export class YDataEntityViewComponent extends DataEntityViewComponent
  implements OnInit {
  constructor(componentFactoryResolver: ComponentFactoryResolver) {
    super(componentFactoryResolver);
  }
  yEntity: YDataEntity;

  ngOnInit() {
    this.yEntity = <YDataEntity>this.entity;
  }
}

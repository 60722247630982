import { Client } from './../../../../models/client';
import { DataTableHelpers } from './../../../../components/system/datatable/datatable-helper';
import {
  Component,
  OnInit,
  ViewChild,
  Output,
  EventEmitter,
  Input
} from '@angular/core';
import {
  ItemSearchOptionField,
  ItemSearchFilter,
  ItemSearchOption,
  ItemSearchOptionFieldOption
} from 'src/app/components/filter-list/models/filterClasses';
import { WorkflowContextService } from 'src/app/services';
import { InspectionQueueComponent } from 'src/app/components/inspections/inspection-queue/inspection-queue.component';
import { ComponentIds } from 'src/app/models/component-ids';

@Component({
  selector: 'app-upcoming-inspection-to-complete',
  templateUrl: './upcoming-inspection-to-complete.component.html',
  styles: []
})
export class UpcomingInspectionToCompleteComponent implements OnInit {
  @Output() updateStatus = new EventEmitter();
  @Output() dataLoaded = new EventEmitter<string>();
  @Output() recordCountSet = new EventEmitter<number>();
  @Output() filtersChanged = new EventEmitter<boolean>();
  @Input() client: Client;

  @Input() public sortField: string;
  @Input() public sortDescending: boolean;

  @ViewChild('wmInspectionQueue', { static: false })
  wmInspectionQueue: InspectionQueueComponent;

  id = ComponentIds.UPCOMING_INSPECTIONS_TO_COMPLETE;

  public columns = [
    {
      prop: 'address',
      name: 'Address'
    },
    {
      prop: 'city',
      name: 'City'
    },
    { prop: 'inspectionType', name: 'Inspection Type' },
    {
      prop: 'scheduledOn',
      name: 'Scheduled Time',
      pipe: this.dtHelpers.getDatePipe('MM/dd/yyy, h:mm a')
    },
    { prop: 'requestorName', name: 'Requestor Name' },
    { prop: 'requestorPhoneNumber', name: 'Requestor Phone' },
    {
      prop: 'inspectionStatus',
      name: 'Status'
    }
  ];
  staticFilters: ItemSearchFilter;

  constructor(
    private context: WorkflowContextService,
    private dtHelpers: DataTableHelpers
  ) {}

  reload() {
    this.wmInspectionQueue.loadInspections().subscribe(z => {
      this.wmInspectionQueue.inspectionRequests = z;
    });
  }

  ngOnInit() {
    const sevenDaysFromNow = new Date();
    sevenDaysFromNow.setDate(sevenDaysFromNow.getDate() + 7);

    this.staticFilters = new ItemSearchFilter({
      options: [
        new ItemSearchOption({
          field: new ItemSearchOptionField({
            title: 'CanPerformAction',
            options: [
              new ItemSearchOptionFieldOption({
                title: 'CanPerformAction',
                selected: true,
                filterText: '',
                searchText: 'completeActivity',
                strOperator: ''
              })
            ]
          })
        }),
        new ItemSearchOption({
          field: new ItemSearchOptionField({
            title: 'inspectorId',
            options: [
              new ItemSearchOptionFieldOption({
                title: 'inspectorId',
                selected: true,
                filterText: '',
                searchText: this.context.user.id,
                strOperator: ''
              })
            ]
          })
        }),
        new ItemSearchOption({
          field: new ItemSearchOptionField({
            title: 'scheduledOn',
            options: [
              new ItemSearchOptionFieldOption({
                title: 'scheduledOn',
                selected: true,
                filterText: '',
                searchText:
                  sevenDaysFromNow.getMonth() +
                  1 +
                  '/' +
                  sevenDaysFromNow.getDate() +
                  '/' +
                  sevenDaysFromNow.getFullYear(),
                strOperator: '<'
              })
            ]
          })
        })
      ]
    });
  }

  updateStatusEvent(evt) {
    this.updateStatus.emit(evt);
  }

  emitDataLoaded() {
    this.dataLoaded.emit('UpcomingInspectionToCompleteComponent');
  }

  setRecordCount(count: number) {
    this.recordCountSet.emit(count);
  }

  filtersChange() {
    this.filtersChanged.emit(true);
  }
}

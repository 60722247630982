import { ApplicationStatus, Role } from 'src/app/models';
import { Client } from './client';
import { CommunityWorkflow } from './community-workflow';
import { Securable } from './securable';
import { Actions } from './actions';
import { Permission } from './permission';
import { WorkflowVersion } from './workflow-version';
import { WorkflowTag } from './workflow-tag';
import { ConditionTarget } from '../components/system/condition-builder/condition-builder.model';
import { Requirement } from './requirement';

export enum WorkflowType {
  Permit,
  ContractorRegistration,
  ContractorRenewal,
  Other,
  Renewable
}

export class AutoVoidSettings {
  goBeyondActivity: string;
  daysUntilVoid: number;
}

export class Workflow implements Securable {
  id: string;
  client: Client;
  clientID: string;
  communityWorkflow?: CommunityWorkflow;
  permissions: { [key: string]: { [key: string]: Permission } };
  isArchived: boolean;
  isEnabled: boolean;
  attachments: string[];
  visibleToPublic: boolean;
  version: WorkflowVersion;
  isPublished: boolean;
  clientApplicationNumber: number;
  numberPrefix: string;
  numberPrefixCriteria: ConditionTarget[];
  workflowType: WorkflowType;
  areThereChangesNotPublished: boolean;
  lastPublishedOn: string;
  workflowTags: WorkflowTag[];
  name: string;
  designStatus: ApplicationStatus; // PER2-1340 this is a client only setting that is used in the designer instead of having to pass this around everywhere :)
  expirationFormulaCriteria: ConditionTarget[];
  parentWorkflowid?: string;
  renewalWorkflowId?: string;
  requirements?: Requirement[];
  fixedClientApplicationNumberLength?: number;
  resetClientAppNumberMonth?: number;
  resetClientAppNumberDay?: number;
  nextClientAppNumberResetDateText?: string;
  autoVoidSettings: AutoVoidSettings;
  canPublish: boolean;
  adminRole: Role;

  availableActions: string[];

  constructor(options?: Partial<Workflow>) {
    this.version = new WorkflowVersion();
    if (options) {
      Object.assign(this, options);
    }
  }
}

export class RenewableParentsResponse {
  renewableParentIds: string[];
}

<div class="form-group">
  <wm-de-label
    [entity]="entity"
    [for]="entity.templateCode"
    [displayOnly]="true"
  ></wm-de-label>
  <input
    type="text"
    readonly
    class="form-control-plaintext"
    [id]="entity.templateCode"
    [value]="entity.value | date: 'M/d/yyyy'"
  />
</div>

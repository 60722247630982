import { Input, Component, OnInit } from '@angular/core';
import { ActivityEditorBaseComponent } from '../../activity-editor-base/activity-editor-base.component';
import { RenewalDecisionActivity } from 'src/app/models/activities/renewal-decision-activity';

@Component({
  selector: 'wm-renewal-decision-activity-editor',
  templateUrl: './renewal-decision-activity-editor.component.html',
  styleUrls: ['./renewal-decision-activity-editor.component.css']
})
export class RenewalDecisionActivityEditorComponent
  extends ActivityEditorBaseComponent
  implements OnInit {
  constructor() {
    super();
  }

  @Input() activity: RenewalDecisionActivity;

  ngOnInit() {}
}

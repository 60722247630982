import { Injectable, Inject, forwardRef } from '@angular/core';
import { Observable } from 'rxjs';
import { DataService } from '.';
import { SearchParcelDataCriteria } from '../models/search-parcel-data-criteria';
import { SearchParcelDataResult } from '../models/search-parcel-data-result';

@Injectable()
export class SearchParcelDataProvider {
  constructor(
    @Inject(forwardRef(() => DataService)) private _dataService: DataService
  ) {}
  getSearchParcelDataResults(
    SearchCriteria: SearchParcelDataCriteria
  ): Observable<{ [key: string]: string }[]> {
    return this._dataService.getParcelDataSourceResults(SearchCriteria);
  }
}

import { TimeZoneInfo } from './../../../../../models/time-zone-info';
import { DataEntitySelectComponent } from './../../data-entity-select/data-entity-select.component';
import { WorkflowContextService } from './../../../../../services/workflow-context.service';
import {
  UntypedFormControl,
  Validators,
  ValidationErrors,
  UntypedFormGroup,
  AbstractControl
} from '@angular/forms';
import {
  Component,
  OnInit,
  ComponentFactoryResolver,
  Input,
  ViewChild,
  Inject,
  forwardRef,
  OnDestroy
} from '@angular/core';
import { DateDataEntity } from '../../../../../models/data-entities';
import { DataEntityInputComponent } from '../../data-entity-input/data-entity-input.component';
import { NgbDateCustomParserFormatter } from '../../../../../models/dateformat';
import {
  NgbDateParserFormatter,
  NgbDate,
  NgbCalendar,
  NgbInputDatepicker,
  NgbTimepicker,
  NgbDateStruct
} from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { Subscription } from 'rxjs';

@Component({
  selector: 'wm-date-data-entity-input',
  templateUrl: './date-data-entity-input.component.html',
  styleUrls: ['./date-data-entity-input.component.css'],
  providers: [
    { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter }
  ]
})
export class DateDataEntityInputComponent extends DataEntityInputComponent
  implements OnInit, OnDestroy {
  @Input() entity: DateDataEntity;
  @ViewChild('d', { static: false }) picker: NgbInputDatepicker;
  @ViewChild(NgbTimepicker, { static: false }) timePicker: NgbTimepicker;

  dateForm: UntypedFormGroup;
  dateTime: AbstractControl;
  timeZoneCode: string;
  tzOffset: string;
  changeStatusSub: Subscription;

  time: any;
  date: any;

  constructor(
    componentFactoryResolver: ComponentFactoryResolver,
    private calendar: NgbCalendar,
    @Inject(forwardRef(() => WorkflowContextService))
    private _context: WorkflowContextService
  ) {
    super(componentFactoryResolver);
  }
  ngOnDestroy(): void {
    if (this.changeStatusSub) {
      this.changeStatusSub.unsubscribe();
    }
  }

  private createLimit(days: number, direction: string): NgbDateStruct {
    if (days === null || days === undefined) {
      return null;
    }
    let date = moment();

    if (direction === 'add') {
      date = date.add(days, 'days');
    } else if (direction === 'subtract') {
      date = date.subtract(days, 'days');
    }

    const limit = {
      year: date.year(),
      month: date.month() + 1, // cause months start at zero...
      day: date.date()
    };

    return limit;
  }

  get maxDate(): NgbDateStruct {
    return this.createLimit(this.entity.forwardLimit, 'add');
  }

  get minDate(): NgbDateStruct {
    return this.createLimit(this.entity.backwardLimit, 'subtract');
  }

  ngOnInit() {
    if (
      !this.entity.externalDataSource ||
      !this.entity.externalDataSource.sourceConfig ||
      (this.entity.canEditExternalValue &&
        this.form.controls[this.entity.templateCode])
    ) {
      this.form.controls[this.entity.templateCode].enable();
    } else {
      this.form.controls[this.entity.templateCode].disable();
    }

    const dstNow = moment().isDST();

    this.changeStatusSub = this.entity.changeStatus.subscribe(
      (enabled: boolean) => {
        if (enabled) {
          this.dateForm.enable();
        } else {
          this.dateForm.disable();
        }
        this.dateTime.updateValueAndValidity();
      }
    );
    if (this.entity.valueObj && this.entity.valueObj.Value) {
      const dateValue = moment(this.entity.valueObj.Value);
      dateValue.utcOffset(
        dateValue.isDST() && !dstNow
          ? this.entity.timeZoneOffset + 1
          : !dateValue.isDST() && dstNow
          ? this.entity.timeZoneOffset - 1
          : this.entity.timeZoneOffset
      );

      if (dateValue.year() > 1) {
        this.date = {
          year: dateValue.year(),
          month: dateValue.month() + 1,
          day: dateValue.date()
        };
      }
      this.time = this.entity.valueObj.HasTime
        ? {
            hour: dateValue.hour(),
            minute: dateValue.minutes()
          }
        : null;
    }

    const timeRequiredValidator = (
      control: AbstractControl
    ): ValidationErrors => {
      const time = control.value || (this.timePicker && this.timePicker.model);

      if (!time) {
        return { required: time };
      }

      return null;
    };

    this.dateForm = new UntypedFormGroup(
      {
        [`${this.entity.templateCode}-DATE`]: new UntypedFormControl(this.date, [
          this.entity.isRequired
            ? Validators.required
            : Validators.nullValidator
        ]),
        [`${this.entity.templateCode}-TIME`]: new UntypedFormControl(this.time, [
          this.entity.includeTime && this.entity.requireTime
            ? timeRequiredValidator
            : Validators.nullValidator
        ])
      },
      {
        updateOn: 'change'
      }
    );

    const requiredDateTime = (control: AbstractControl): ValidationErrors => {
      let errors: ValidationErrors = null;

      const dateControl = this.dateForm.controls[
        `${this.entity.templateCode}-DATE`
      ];
      const timeControl = this.dateForm.controls[
        `${this.entity.templateCode}-TIME`
      ];

      if (this.entity.isRequired) {
        if (!dateControl.value) {
          if (!errors) {
            errors = {};
          }
          errors.requiredDate = { value: dateControl.value };
        }

        if (
          this.entity.includeTime &&
          this.entity.requireTime &&
          timeControl.invalid
        ) {
          if (!errors) {
            errors = {};
          }
          errors.requiredTime = {
            value:
              timeControl.value || (this.timePicker && this.timePicker.model)
          };
        }
      }

      return errors;
    };

    this.dateTime = this.form.controls[this.entity.templateCode];

    this.dateTime.setValidators([
      requiredDateTime,
      ...this.entity.getValidators()
    ]);
    this.dateTime.updateValueAndValidity();
    this.dateTime.setValue(this.entity.valueObj.Value);

    this.dateForm.valueChanges.subscribe(value => {
      const date = value[`${this.entity.templateCode}-DATE`];
      const time =
        value[`${this.entity.templateCode}-TIME`] ||
        (this.timePicker && this.timePicker.model);

      // if there is a time but no minute, default the minutes to 0
      if (time && time.hour && !time.minute) {
        time.minute = 0;
      }

      if (date && date.year > 1) {
        const dateParseResult = time
          ? this.parseObjectsToDate(date, time)
          : this.parseObjectsToDate(date);

        const isSet = t => t !== null && !isNaN(t) && t !== undefined;

        this.entity.value = { dSTFixed: true };
        this.entity.value.hasTime =
          (time && isSet(time.hour) && isSet(time.minute)) || false;

        this.entity.value.value = dateParseResult.value;
        if (this.dateTime.value != dateParseResult.value) {
          this.dateTime.setValue(dateParseResult.value);
        }
        if (this.entity.value.hasTime) {
          if (dateParseResult.isDST) {
            this.timeZoneCode = this.entity.daylightTimeZoneCode;
          } else {
            this.timeZoneCode = this.entity.timeZoneCode;
          }
        } else {
          this.timeZoneCode = '';
        }
      } else {
        if (this.entity && this.entity.value && this.entity.value.value) {
          this.entity.value.value = '0001-01-01';
          this.dateTime.setValue(null);
        }
      }
    });
  }

  isDST(date: Date): boolean {
    return moment(date).isDST();
  }

  parseObjectsToDate(
    date,
    time = { hour: 0, minute: 0 }
  ): { isDST: boolean; value: string } {
    const dt = moment([date.year, date.month - 1, date.day]);

    const isDST = dt.isDST();
    const dstNow = moment().isDST();

    if (time && !isNaN(time.hour)) {
      const result = moment(
        new Date(
          date.year,
          date.month - 1,
          date.day,
          time.hour,
          isNaN(time.minute) ? 0 : time.minute
        )
      ).utcOffset(
        isDST && !dstNow
          ? this.entity.timeZoneOffset + 1
          : !isDST && dstNow
          ? this.entity.timeZoneOffset - 1
          : this.entity.timeZoneOffset,
        true
      );

      return {
        isDST: isDST,
        value: result.utc().toISOString()
      };
    } else {
      return {
        isDST: isDST,
        value: moment(dt)
          .utcOffset(
            isDST && !dstNow
              ? this.entity.timeZoneOffset + 1
              : !isDST && dstNow
              ? this.entity.timeZoneOffset - 1
              : this.entity.timeZoneOffset,
            true
          )
          .utc()
          .toISOString()
      };
    }
  }

  isDisabled = (date: NgbDate) => {
    if (!this.entity.includeWeekends) {
      return this.calendar.getWeekday(date) >= 6;
    } else {
      return false;
    }
  }

  onFocus() {
    this.picker.open();
    return true;
  }
}

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Contact } from './../../../models/contact';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, OnDestroy, Input } from '@angular/core';

import { DataService } from '../../../services/data.service';
import {
  Client,
  ContractorRegistration,
  Workflow,
  WorkflowType
} from '../../../models';
import { WorkflowContextService } from '../../../services/workflow-context.service';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { Tag } from 'src/app/models/tag';
import {
  RegistrationStatus,
  RegistrationVM
} from 'src/app/models/registration';
import { ContractorService, WorkflowService } from 'src/app/services';
import {
  ItemSearchOptionField,
  ItemSearchOptionFieldOption
} from 'src/app/components/filter-list/models/filterClasses';

@Component({
  selector: 'wm-client-view',
  templateUrl: './client-view.component.html',
  styleUrls: ['./client-view.component.css']
})
export class ClientViewComponent implements OnInit, OnDestroy {
  @Input() areRenewalRegistrations: boolean;
  clientId: string;
  client: Client;
  workflows: Workflow[];
  contractorWorkflows: Workflow[];
  workflowTags: Tag[] = [];
  contractorTags: Tag[] = [];
  contacts: Contact[];
  contractorRegistrations: ContractorRegistration[];
  renewalRegistrations: RegistrationVM[];

  selectedContact: Contact;

  private clientSubscription: Subscription;

  constructor(
    private _route: ActivatedRoute,
    private _workflowSvc: WorkflowService,
    private _contractorSvc: ContractorService,
    private _service: DataService,
    private modalService: NgbModal,
    public context: WorkflowContextService
  ) {}

  ngOnInit() {
    this.context.viewAsRole$.emit(null);
    this._route.params.subscribe(params => {
      this.clientId = params['clientId'];

      this.getClient(this.clientId);
    });

    this.clientSubscription = this.context.client$.subscribe(client => {
      this.client = client;
    });
  }

  ngOnDestroy() {
    if (this.clientSubscription) {
      this.clientSubscription.unsubscribe();
      this.clientSubscription = null;
    }
  }

  getWorkflowsNotTagged(workflows: Workflow[]): Workflow[] {
    return workflows.filter(w => (w.workflowTags || []).length === 0);
  }
  getWorkflowsForTag(workflows: Workflow[], tagId: string): Workflow[] {
    return workflows.filter(w => this.isWorkflowTagged(w, tagId));
  }

  isWorkflowTagged(workflow: Workflow, tagId: string): boolean {
    return workflow.workflowTags.find(wt => wt.tagId === tagId) != null;
  }

  getWorkflows() {
    if (this.context.client) {
      this.workflows = null;

      const workflowCalls: Observable<any>[] = [
        this._service.getTagsForWorkflows(this.context.client.id).pipe(
          map(tags => {
            this.workflowTags = tags;
          })
        ),
        this._service.getTagsForContractorTypes(this.context.client.id).pipe(
          map(tags => {
            this.contractorTags = tags;
          })
        ),
        this._service
          .getPublishedWorkflows(this.context.client, [
            WorkflowType.Other,
            WorkflowType.Permit,
            WorkflowType.Renewable
          ])
          .pipe(
            map(workflows => {
              this.workflows = workflows;
            })
          )
      ];

      workflowCalls.push(
        this._service
          .getContractorRegistrationWorkflows(
            this.context.client.id,
            this.context.user ? this.context.user.id : null
          )
          .pipe(
            map(workflows => {
              this.contractorWorkflows = workflows;
            })
          )
      );

      forkJoin(workflowCalls).subscribe();

      this._service.getContacts(this.context.client.id).subscribe(contacts => {
        this.contacts = contacts;
      });
    } else {
      this.workflows = [];
      this.contractorWorkflows = [];
    }
  }

  getRegistrations() {
    // only return registrations by this user
    const staticFilters: ItemSearchOptionField[] = [
      {
        title: 'userId',
        options: [
          new ItemSearchOptionFieldOption({
            title: 'userId',
            selected: true,
            filterText: '',
            searchText: this.context.user ? this.context.user.id : '',
            strOperator: 'is'
          })
        ]
      },
      // only include registrations that expire
      {
        title: 'hasExpiresOn',
        options: [
          new ItemSearchOptionFieldOption({
            title: 'hasExpiresOn',
            selected: true,
            filterText: '',
            searchText: '',
            strOperator: ''
          })
        ]
      }
    ];

    // only include registrations with a status of Approved, Inactive, or Expired
    const crStatusFilter = {
      title: 'status',
      options: [
        new ItemSearchOptionFieldOption({
          title: 'status',
          selected: true,
          filterText: '',
          searchText:
            RegistrationStatus.Approved +
            ',' +
            RegistrationStatus.Inactive +
            ',' +
            RegistrationStatus.Expired,
          strOperator: 'in'
        })
      ]
    };
    const rrStatusFilter = {
      title: 'status',
      options: [
        new ItemSearchOptionFieldOption({
          title: 'status',
          selected: true,
          filterText: '',
          searchText: 'Approved,Inactive,Expired',
          strOperator: 'in'
        })
      ]
    };

    if (this.context.user) {
      this._contractorSvc
        .searchContractorRegistrationsUnpaged(
          this.clientId ||
            (this.context.client ? this.context.client.id : null),
          'expiresOn',
          false,
          [...staticFilters, crStatusFilter]
        )
        .subscribe(result => {
          this.contractorRegistrations = result.contractorRegistrations;
        });

      this._workflowSvc
        .searchRegistrationsUnpaged(
          this.clientId ||
            (this.context.client ? this.context.client.id : null),
          'expiresOn',
          false,
          [...staticFilters, rrStatusFilter]
        )
        .subscribe(result => {
          this.renewalRegistrations = result.registrations;
        });
    }
  }

  getClient(clientId) {
    this._service.getPublicClient(clientId).subscribe(client => {
      this.context.client$.emit(client);

      this.getWorkflows();
      this.getRegistrations();
    });
  }

  openContact(content, contact: Contact) {
    this.selectedContact = contact;
    this.modalService.open(content, { ariaLabelledBy: 'modal-contact' });
  }

  getOfficeHours(contact: Contact) {
    return contact.officeHours.replace(new RegExp('\n', 'g'), '<br />');
  }
}

import { Injectable } from '@angular/core';
import {
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';
import { WorkflowContextService, SecurityService } from '.';
import { Observable } from 'rxjs';
import { map, switchMap, concatMap } from 'rxjs/operators';
import { ClientService } from './client.service';
import { WorkflowService } from './workflow.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard {
  constructor(
    private router: Router,
    private _context: WorkflowContextService,
    private _securitySvc: SecurityService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this._context.authToken && this._context.user) {
      // logged in AND user is set, so return true
      return true;
    }

    // not logged in so redirect to login page with the return url
    this.router.navigate(['/auth/login'], {
      queryParams: { returnUrl: state.url }
    });
    return false;
  }
}

@Injectable({ providedIn: 'root' })
export class ActionGuard {
  constructor(
    private router: Router,
    private _context: WorkflowContextService,
    private _securitySvc: SecurityService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    return this._securitySvc.isLoginEntitled(next.data.action).pipe(
      map(result => {
        if (!result) {
          this.router.navigate(['/auth/login'], {
            queryParams: { returnUrl: state.url }
          });
          return false;
        }

        return result;
      })
    );
  }
}

@Injectable({ providedIn: 'root' })
export class ActionsGuard {
  constructor(private router: Router, private _securitySvc: SecurityService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    return this._securitySvc
      .isLoginEntitled(null, null, next.data.actions)
      .pipe(
        map(result => {
          if (!result) {
            this.router.navigate(['/admin/global/unauthorized'], {
              queryParams: { returnUrl: state.url }
            });
            return false;
          }

          return result;
        })
      );
  }
}

@Injectable({ providedIn: 'root' })
export class ClientAdminGuard {
  constructor(
    private router: Router,
    private _context: WorkflowContextService,
    private _securitySvc: SecurityService,
    private _clientSvc: ClientService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    return this._securitySvc.isSystemAdministrator().pipe(
      concatMap(isSysAdmin => {
        // clear the permission cache so the permissions are re-built for this client
        this._securitySvc.clearPermissionCache();
        return this._clientSvc.getAdminClients().pipe(
          map(clients => {
            if (clients && clients.length === 1 && !isSysAdmin) {
              this.router.navigate(['/admin/jurisdiction', clients[0].id]);
              return false;
            }
            return true;
          })
        );
      })
    );
  }
}

@Injectable({ providedIn: 'root' })
export class CompletedApplicationGuard {
  constructor(private router: Router, private _workflowSvc: WorkflowService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    return this._workflowSvc
      .isWorkflowApplicationInProgress(next.params.applicationId)
      .pipe(
        map(response => {
          if (response === false) {
            let historyState = history.state;
            this.router
              .navigate([
                '/application/workflow-application-summary/',
                next.params.applicationId
              ])
              .then(nav => {
                history.replaceState(
                  {},
                  'DataGrid',
                  historyState && historyState.navigationPath
                    ? historyState.navigationPath
                    : null
                );
                history.pushState(
                  {},
                  '',
                  `/application/workflow-application-summary/${next.params.applicationId}`
                );
              });
            return false;
          }
          return true;
        })
      );
  }
}

@Injectable({ providedIn: 'root' })
export class ClientIdGuard {
  constructor(
    private _context: WorkflowContextService,
    private _clientSvc: ClientService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!(this._context.client && this._context.client.id)) {
      const clientId: string = route.firstChild.params.clientId || null;

      if (clientId) {
        return this._clientSvc.getPublicClient(clientId).pipe(
          map(client => {
            if (client) {
              this._context.client$.emit(client);
            }

            return true;
          })
        );
      }
    }

    return true;
  }
}

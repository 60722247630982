<div class="container">
  <div class="row justify-content-center align-items-center pt-5">
    <div class="col-6">
      <div class="card">
        <div class="card-body">
          <h6 class="card-title">
            Auto Void Processor
          </h6>
          <div class="form-group">
            <label class="label">As if today was Date/Time</label>
            <input
              type="datetime"
              [(ngModel)]="asOfDateTime"
              class="form-control"
            />
          </div>
          <button
            class="btn btn-raised btn-primary btn-justified"
            (click)="runProcess()"
            [disabled]="processRunning"
          >
            Run
          </button>
          <div *ngIf="processRunning">
            Auto Void Processor is running as of
            {{ asOfDateTime | date: 'MM/dd/yyyy hh:mm aa' }}...
            <br />
            <app-loader></app-loader>
          </div>

          <div *ngIf="results && !processRunning">
            <div class="form-group">
              <div class="label">Applications Processed</div>
              <div>{{ results.applicationsProcessed }}</div>
            </div>
            <div class="form-group">
              <div class="label">Applications Voided</div>
              <div>{{ results.applicationsVoided }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

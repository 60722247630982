import { DataTableHelpers } from './../../../system/datatable/datatable-helper';
import { ApplicationStatusPipe } from 'src/app/pipes/application-status.pipe';
import { TableColumn } from '@swimlane/ngx-datatable';
import {
  Component,
  OnInit,
  Output,
  Input,
  ViewChild,
  TemplateRef
} from '@angular/core';
import { EventEmitter } from '@angular/core';
import { Workflow } from 'src/app/models';

@Component({
  selector: 'wm-workflow-applications-pending-payments',
  templateUrl: './workflow-applications-pending-payments.component.html',
  styleUrls: ['./workflow-applications-pending-payments.component.css']
})
export class WorkflowApplicationsPendingPaymentsComponent implements OnInit {
  @Output() updateStatus = new EventEmitter();
  @Output() dataLoaded = new EventEmitter<string>();
  @Output() recordCountSet = new EventEmitter<number>();
  @Output() filtersChanged = new EventEmitter<boolean>();
  @ViewChild('appIdTmpl', { static: true }) appIdTmpl: TemplateRef<any>;
  @Input() workflows: Workflow[];

  id = 'workflow-applications-pending-payments';

  pendingPaymentWorkflowsFilter = [
    {
      title: 'PendingPayments',
      options: [
        {
          title: 'PendingPayments',
          selected: true,
          filterText: '',
          searchText: '0',
          strOperator: '>'
        }
      ]
    }
  ];

  public columns: TableColumn[];

  constructor(private dtHelpers: DataTableHelpers) {}

  ngOnInit() {
    this.columns = [
      {
        prop: 'applicationNumber',
        name: 'Application Number',
        cellTemplate: this.appIdTmpl
      },
      { prop: 'permitType', name: 'Type' },
      { prop: 'description', name: 'Description' },
      { prop: 'applicantsName', name: 'Applicant' },
      {
        prop: 'dateCreated',
        name: 'Date Created',
        pipe: this.dtHelpers.getDatePipe()
      },
      {
        prop: 'status',
        name: 'Status',
        pipe: new ApplicationStatusPipe()
      },
      {
        prop: 'feeDollarsDue',
        name: 'Fee Due',
        pipe: this.dtHelpers.getCurrencyPipe()
      }
    ];
  }

  updateStatusEvent(evt) {
    this.updateStatus.emit(evt);
  }

  emitDataLoaded(evt) {
    this.dataLoaded.emit('WorkflowApplicationsPendingPaymentsComponent');
  }

  setRecordCount(count: number) {
    this.recordCountSet.emit(count);
  }

  filtersChange() {
    this.filtersChanged.emit(true);
  }
}

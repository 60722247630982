import {
  Component,
  OnInit,
  Inject,
  forwardRef,
  ChangeDetectorRef
} from '@angular/core';
import { DataEntityEditorBaseComponent } from '../../data-entity-editor-base/data-entity-editor-base.component';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ContactListDataEntity } from '../../../../../models/data-entities/contact-list-data-entity';
import {
  WorkflowService,
  WorkflowContextService,
  SystemService,
  ClientService
} from '../../../../../services';
import { Contact } from '../../../../../models';

@Component({
  selector: 'wm-contact-list-data-entity-editor',
  templateUrl: './contact-list-data-entity-editor.component.html',
  styleUrls: ['./contact-list-data-entity-editor.component.css']
})
export class ContactListDataEntityEditorComponent
  extends DataEntityEditorBaseComponent
  implements OnInit {
  contacts: Contact[];
  contactListEntity: ContactListDataEntity;
  constructor(
    @Inject(forwardRef(() => WorkflowService))
    public _workflowSvc: WorkflowService,
    @Inject(forwardRef(() => WorkflowContextService))
    private _context: WorkflowContextService,
    @Inject(forwardRef(() => SystemService)) private _systemSvc: SystemService,
    @Inject(forwardRef(() => ClientService)) private _service: ClientService,
    private changeDetRef: ChangeDetectorRef,
    private _fb: UntypedFormBuilder
  ) {
    super();
  }

  onChange(Id) {}
  ngOnInit() {
    this.contactListEntity = this.dataEntity as ContactListDataEntity;
    if (this._context.client) {
      this.getContacts();
    }
    this.form.addControl(
      'contact-list-data-entity-select',
      this._fb.control(false, [Validators.required])
    );
  }

  getContacts() {
    this._service.getContacts(this._context.client.id).subscribe(contacts => {
      this.contacts = contacts;
    });
  }
}

<form [formGroup]="form">
  <div class="form-group">
    <label class="bmd-label-floating" for="multiPaymentURL">
      Multi-payment URL
    </label>
    <input
      type="text"
      class="form-control"
      formControlName="multiPaymentURL"
      name="multiPaymentURL"
      [(ngModel)]="payGovConfig.multiPaymentURL"
    />
  </div>
</form>

export class ParentChildWorkflowNamed {
  public id: string;
  public activityId: string;
  public sourceWorkflowId: string;
  public targetWorkflowId: string;
  public targetWorkflowVersionId: string;
  public sourceWorkflowName: string;
  public targetWorkflowName: string;
  public activityName: string;
}
export class ParentChildWorkflowNamedResponse {
  public parentWorkflows: ParentChildWorkflowNamed[];
  public childWorkflows: ParentChildWorkflowNamed[];
}

import {
  Component,
  OnInit,
  Input,
  ChangeDetectorRef,
  OnDestroy,
  Output,
  EventEmitter,
  AfterViewChecked
} from '@angular/core';
import { DataEntity } from 'src/app/models/data-entities';
import {
  Validators,
  UntypedFormGroup,
  AbstractControl,
  UntypedFormBuilder,
  ValidationErrors
} from '@angular/forms';
import { WorkflowService } from 'src/app/services';
import {
  ExternalDataSource,
  ParcelSearchExternalSource,
  ParcelSearchExternalSourceConfig,
  RegistrationExternalSource,
  RegistrationExternalSourceConfig,
  RegistrationExternalSourceMapping,
  RegistrationExternalSourceOption,
  RegistrationExternalSourceSelectedMappings
} from 'src/app/models/external-data-source';

@Component({
  selector: 'wm-external-data-source-config',
  templateUrl: './external-data-source-config.component.html',
  styleUrls: ['./external-data-source-config.component.css']
})
export class ExternalDataSourceConfigComponent
  implements OnInit, OnDestroy, AfterViewChecked {
  // **** FOR ANY FUTURE EXPANSION OF EXTERNAL DATA SOURCE ****
  //
  // When a third type of External Data Source is added to the system, factor out
  // the existing types into their own components.
  //
  // The individual components should encapsulate their unique config logic
  // as well as their unique validation logic
  //
  // **********************************************************

  @Input() entity: DataEntity;
  @Input() form: UntypedFormGroup;
  @Output() externalSourceUpdated: EventEmitter<
    ExternalDataSource
  > = new EventEmitter<ExternalDataSource>();

  availableExternalDataSources: ExternalDataSource[];
  externalDataSource: ExternalDataSource;
  parcelSearchEDStypeCode = new ParcelSearchExternalSource().type;
  registrationEDStypeCode = new RegistrationExternalSource().type;
  renewableWorkflowForms = [WorkflowService.ACTIVITIES.Form];
  parcelSearchExternalDataFieldCtrl: AbstractControl;
  mappedValues: string[] = [];
  isFormSet = false;

  constructor(private _ref: ChangeDetectorRef, private _fb: UntypedFormBuilder) {}

  ngOnInit() {
    if (
      this.entity.availableExternalDataSources &&
      this.entity.availableExternalDataSources.length > 0
    ) {
      this.availableExternalDataSources = this.entity.availableExternalDataSources;

      if (this.entity.externalDataSource) {
        // do this so the object bound to the dropdown is the same object that is being iterated over for the dropdown
        this.externalDataSource = this.availableExternalDataSources.find(
          aeds => aeds.key === this.entity.externalDataSource.key
        );
        // it's possible the saved ExternalDataSource is no longer a valid option in the design.
        // Workflow validation will catch that, just keep it from erroring here.
        if (this.externalDataSource) {
          // and apply any existing selections from the entity.externalDataSource
          if (
            this.externalDataSource.type === this.parcelSearchEDStypeCode &&
            (this.entity.externalDataSource
              .sourceConfig as ParcelSearchExternalSourceConfig)
              .selectedOption != null
          ) {
            (this.externalDataSource
              .sourceConfig as ParcelSearchExternalSourceConfig).selectedOption = (this
              .externalDataSource
              .sourceConfig as ParcelSearchExternalSourceConfig).options.find(
              o =>
                o.fieldName.toLocaleLowerCase() ===
                (this.entity.externalDataSource
                  .sourceConfig as ParcelSearchExternalSourceConfig).selectedOption.fieldName.toLocaleLowerCase()
            );
          } else if (
            this.externalDataSource.type === this.registrationEDStypeCode
          ) {
            (this.externalDataSource
              .sourceConfig as RegistrationExternalSourceConfig).selectedMappings =
              (this.entity.externalDataSource
                .sourceConfig as RegistrationExternalSourceConfig)
                .selectedMappings ||
              new RegistrationExternalSourceSelectedMappings({
                mapFromThisWorkflow: false
              });

            this.buildRegistrationMappedValuesIndex();
          }
        }
      }
    }

    this.form.addControl(
      'externalDataSource',
      this._fb.control('', Validators.nullValidator)
    );

    this.form.addControl(
      'parcelSearchExternalDataField',
      this._fb.control(
        '',
        this.externalDataSource &&
          this.externalDataSource.type === this.parcelSearchEDStypeCode
          ? Validators.required
          : Validators.nullValidator
      )
    );

    this.parcelSearchExternalDataFieldCtrl = this.form.controls[
      'parcelSearchExternalDataField'
    ];

    this.form.addControl(
      'registrationExternalDataField',
      this._fb.control('', Validators.nullValidator)
    );

    this.form.addControl(
      'mapFromThisWorkflow',
      this._fb.control('', Validators.nullValidator)
    );

    this.form.addControl(
      'canEditExternalValue',
      this._fb.control('', Validators.nullValidator)
    );

    this.setValidators();

    this.isFormSet = true;
  }

  ngAfterViewChecked() {
    this._ref.detectChanges();
  }

  ngOnDestroy() {
    // this seems to be necessary for controls with custom validator functions
    this.form.removeControl('registrationExternalDataField');
  }

  handleChanges() {
    // emit a cloned object so destroying this component won't remove the EDS object from
    // the data entity before its changes are persisted
    this.externalSourceUpdated.emit(
      JSON.parse(JSON.stringify(this.externalDataSource))
    );

    this.setValidators();
  }

  changeExternalSource() {
    if (this.externalDataSource) {
      // set default value of CanEditExternalValue
      this.entity.canEditExternalValue = this.externalDataSource.canEditExternalValueDefault;

      if (this.externalDataSource.type === this.registrationEDStypeCode) {
        this.buildRegistrationMappedValuesIndex();

        if (
          !(this.externalDataSource
            .sourceConfig as RegistrationExternalSourceConfig).selectedMappings
        ) {
          (this.externalDataSource
            .sourceConfig as RegistrationExternalSourceConfig).selectedMappings = new RegistrationExternalSourceSelectedMappings(
            {
              mapFromThisWorkflow: false
            }
          );
        }
      }
    } else {
      // null out the depricated properties' values, since we are trying to clear the external data source configuration
      this.entity.externalDataField = null;
      this.entity.externalDataSourceId = null;
    }

    this.handleChanges();

    this._ref.detectChanges();
  }

  // index selected renewable workflow templateCodes (or lack thereof) in the same order as the workflow options.
  // this makes those values dynamically accesible to be bound models for their matching data-entity-autocomplete instance
  buildRegistrationMappedValuesIndex() {
    this.mappedValues = [];

    const selectedMappings = (this.externalDataSource
      .sourceConfig as RegistrationExternalSourceConfig).selectedMappings;

    const otherWorkflowMappings = selectedMappings
      ? selectedMappings.otherWorkflowMappings
      : null;

    for (const o of (this.externalDataSource
      .sourceConfig as RegistrationExternalSourceConfig).options) {
      if (otherWorkflowMappings) {
        const mappedValue = otherWorkflowMappings.find(
          owm => owm.sourceWorkflowId === o.sourceWorkflowId
        );

        this.mappedValues.push(
          mappedValue ? mappedValue.fieldTemplateCode : ''
        );
      } else {
        this.mappedValues.push('');
      }
    }
  }

  changeParentRenewableMapping(
    option: RegistrationExternalSourceOption,
    templateCode: string
  ) {
    const otherWorkflowMappings = (this.externalDataSource
      .sourceConfig as RegistrationExternalSourceConfig).selectedMappings
      .otherWorkflowMappings;

    const existingMapping = otherWorkflowMappings
      ? otherWorkflowMappings.find(
          owm => owm.sourceWorkflowId === option.sourceWorkflowId
        )
      : null;

    if (templateCode && templateCode !== '') {
      if (existingMapping) {
        // update the mapping
        existingMapping.fieldTemplateCode = templateCode;
      } else {
        // create a new otherWorkflowMappings array if it doesn't exist yet
        if (
          !(this.externalDataSource
            .sourceConfig as RegistrationExternalSourceConfig).selectedMappings
            .otherWorkflowMappings
        ) {
          (this.externalDataSource
            .sourceConfig as RegistrationExternalSourceConfig).selectedMappings.otherWorkflowMappings = [];
        }

        // add the mapping
        (this.externalDataSource
          .sourceConfig as RegistrationExternalSourceConfig).selectedMappings.otherWorkflowMappings.push(
          new RegistrationExternalSourceMapping({
            sourceWorkflowId: option.sourceWorkflowId,
            fieldTemplateCode: templateCode
          })
        );
      }
    } else {
      // remove the existing mapping because the change was to not map anything from this renewable parent
      (this.externalDataSource
        .sourceConfig as RegistrationExternalSourceConfig).selectedMappings.otherWorkflowMappings = otherWorkflowMappings
        ? otherWorkflowMappings.filter(
            m => m.sourceWorkflowId !== option.sourceWorkflowId
          )
        : null;
    }

    this.buildRegistrationMappedValuesIndex();

    this.handleChanges();
  }

  setValidators() {
    this.parcelSearchExternalDataFieldCtrl.clearValidators();
    this.form.get('registrationExternalDataField').clearValidators();

    this.form.get('parcelSearchExternalDataField').updateValueAndValidity();
    this.form.get('registrationExternalDataField').updateValueAndValidity();

    if (this.externalDataSource) {
      if (this.externalDataSource.type === this.parcelSearchEDStypeCode) {
        this.parcelSearchExternalDataFieldCtrl.setValidators(
          Validators.required
        );
      }

      if (this.externalDataSource.type === this.registrationEDStypeCode) {
        this.form
          .get('registrationExternalDataField')
          .setValidators(this.renwableFieldMapped.bind(this));
        this.form.get('registrationExternalDataField').updateValueAndValidity();
      }
    }
  }

  renwableFieldMapped(): ValidationErrors | null {
    if (this.externalDataSource) {
      if (
        (this.externalDataSource
          .sourceConfig as RegistrationExternalSourceConfig).selectedMappings
      ) {
        if (
          (this.externalDataSource
            .sourceConfig as RegistrationExternalSourceConfig).selectedMappings
            .mapFromThisWorkflow ||
          ((this.externalDataSource
            .sourceConfig as RegistrationExternalSourceConfig).selectedMappings
            .otherWorkflowMappings &&
            (this.externalDataSource
              .sourceConfig as RegistrationExternalSourceConfig)
              .selectedMappings.otherWorkflowMappings.length > 0)
        ) {
          return null;
        }
      }
    }

    return { noRenewableFieldMapped: true };
  }
}

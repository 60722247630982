<form [formGroup]="form" *ngIf="dataEntity">
  <div class="form-row">
    <div class="col-12">
      <div class="form-group">
        <wm-data-entity-formula
          id="formula"
          [form]="form"
          [workflow]="workflow"
          [activity]="activity"
          [criteria]="dataEntity.numberPrefixCriteria"
          [thenLabelGetter]="getThenLabel"
          (criteriaChanged)="updateCriteria($event)"
          [formulaTitle]="'Number Prefix'"
        ></wm-data-entity-formula>
        <wm-control-message
          [control]="form.controls['numberPrefix']"
        ></wm-control-message>
      </div>
    </div>
  </div>
</form>

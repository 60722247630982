import { ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Subscription, of } from 'rxjs';
import { DataService } from 'src/app/services/data.service';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import {
  Role,
  User,
  Action,
  Permission,
  RoleMember,
  Actions
} from '../../../../models';
import { WorkflowContextService, SecurityService } from '../../../../services';
import { ActivatedRoute, Router } from '@angular/router';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { map, flatMap, switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { PermissionListComponent } from 'src/app/components/security/permission-list/permission-list.component';

@Component({
  selector: 'wm-role-detail-view',
  templateUrl: './role-detail-view.component.html',
  styleUrls: ['./role-detail-view.component.css']
})
export class RoleDetailViewComponent implements OnInit, OnDestroy {
  /**
   * @ignore
   */
  @ViewChild('popover', { static: false }) private popover: NgbPopover;
  /**
   * ID of the current role
   */
  roleId = '';
  /**
   * The current role
   */
  role: Partial<Role>;
  /**
   * List of available permissions that can be added to the current role
   */
  allActions: Partial<Action>[];
  /**
   * List of permissions added to the current role
   */
  roleActions: Partial<Action>[];
  /**
   * Workflows that the role is in
   */
  occupiedWorkflows: string[];
  /**
   * Workflows that the role is an admin for.
   */
  adminWorkflows: string[];

  /**
   * Permissions that should not be removed from a role if the role is responsible for any activities
   */
  removalProtectedActions = Actions.RESPONSIBLE_ROLE_ACTIONS;
  removalProtectedAdminActions = Actions.WORKFLOW_ACTIONS;

  @ViewChild('permissionList') permissionList: PermissionListComponent;

  roleColors = [
    'green',
    'yellow',
    'orange',
    'red',
    'purple',
    'light-blue',
    'light-green',
    'light-yellow'
  ];

  workflowsLoaded = false;
  isGlobalAdmin = false;
  cannotEditPermissions = false;

  /**
   * @ignore
   */
  selectedActionId: string;
  /**
   * @ignore
   */
  form: UntypedFormGroup;
  clientSubscribe: Subscription;

  /**
   * @ignore
   */
  constructor(
    public context: WorkflowContextService,
    private _securitySvc: SecurityService,
    private _data: DataService,
    private _router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    public fb: UntypedFormBuilder,
    private _ref: ChangeDetectorRef
  ) {
    this.form = fb.group({
      roleName: ['', Validators.required],
      roleColor: ['']
    });
  }

  /**
   * @ignore
   */
  colorPreviewClass(color: string) {
    const classes = {
      'text-white': !!color
    };

    if (color) {
      classes['rc-' + color] = true;
    }
    return classes;
  }

  /**
   * @ignore
   */
  ngOnInit() {
    this.route.params.subscribe(async params => {
      this.roleId = params['id'] || '';

      this.loadRole().subscribe(r => {
        this.role = r;

        this._ref.detectChanges();
      });

      if (this.context.client) {
        this.loadAllRoles();
      } else {
        this.clientSubscribe = this.context.client$.subscribe(res => {
          this.loadAllRoles();
        });
      }

      this._securitySvc
        .isLoginEntitled(Actions.DO_ANYTHING)
        .subscribe(isEntitled => {
          this.isGlobalAdmin = isEntitled;
        });
    });
  }

  /**
   * Remove a permission from the current role
   *
   * @param actionId ID of the permission to remove from the role
   */
  removePermission(e: { actionId: string; securableId: string }) {
    // prompt that this will remove permission
    this._securitySvc
      .removePermission(this.role, e.actionId, e.securableId)
      .subscribe(res => {
        this.toastr.success('Permission Removed!');
        this.loadRole();
      });
  }

  /**
   * Add a permission to the current role
   *
   * @param actionId ID of the permission to add to the role
   */
  addPermission(actionId: string) {
    let obs: Observable<Permission> = null;

    const addP = (role: Partial<Role>, action: string) => {
      this.toastr.success('Permission Added!');
      return this._securitySvc.addPermission(role, action);
    };

    if ((this.role.id || '') === '' && this.canSave) {
      obs = this._securitySvc.saveRole(this.role).pipe(
        flatMap((role: Role) => {
          this.role.id = role.id;
          this.roleId = role.id;
          return addP(this.role, actionId);
        })
      );
    } else if ((this.role.id || '') !== '' && this.canSave) {
      obs = addP(this.role, actionId);
    }

    if (obs != null) {
      obs.subscribe(res => {
        this.selectedActionId = '';
        this.popover.close();
        this.loadRole();
      });
    }
  }

  /**
   * Load the current role
   */
  loadRole(): Observable<Role> {
    return new Observable<Role>(o => {
      if (this.roleId !== '') {
        this._securitySvc
          .getRole(this.roleId)
          .pipe(
            switchMap((r: Role) => {
              // get workflows for role
              this.getWorkflows(r);
              if (r.actions) {
                this.roleActions = r.actions.map(
                  permission => permission.action
                );
              } else {
                this.roleActions = [];
              }

              return of(r);
            })
          )
          .subscribe(r => {
            o.next(r);
          });
      } else {
        this.workflowsLoaded = true;
        o.next(this._securitySvc.createRole());
      }
    }).pipe(
      switchMap((role: Role) => {
        if (role) {
          if (
            this.context.client &&
            this.context.client.adminRoleID === role.id &&
            !this.isGlobalAdmin
          ) {
            this.cannotEditPermissions = true;
          }

          return this._securitySvc
            .getAvailableActions(role)
            .pipe(
              switchMap(permissions => {
                this.allActions = [
                  ...permissions,
                  ...(this.roleActions || [])
                ].sort((a, b) => (a.name > b.name ? 1 : -1));
                return of(role);
              })
            )
            .pipe(
              map(p => {
                return p;
              })
            );
        }
      })
    );
  }

  loadAllRoles() {
    this._data
      .searchRoles(this.context.client, null)
      .subscribe((roles: Role[]) => {
        if (!this.roleId) {
          this.form.controls['roleColor'].setValue(this.defaultColor(roles));
        }
      });
  }

  defaultColor(roles: Role[]) {
    const colors = this.roleColors.filter(
      rc => !roles.find(role => role.color === rc)
    );

    return colors[0];
  }

  /**
   * @ignore
   */
  private buildBaseRoute(): string[] {
    let routeDetails: string[] = [];

    if (this.context.client) {
      routeDetails = ['admin', 'jurisdiction', this.context.client.id, 'users'];
    } else {
      routeDetails = ['admin', 'global'];
    }

    routeDetails = routeDetails.concat(['roles']);

    return routeDetails;
  }

  /**
   * Save the current role
   */
  save() {
    this.role.client = this.context.client;
    const selectedActions = this.permissionList
      ? this.permissionList.allActions.filter(a =>
          this.permissionList.selectedActionIds.includes(a.id)
        )
      : [];
    // since role.actions are really permissions, then make these actions and convert them into permissions if they aren't already....
    const selectedPermissions = selectedActions.map(a => {
      // logic in SecurityProvider.UpdatePermissions as follows.

      // add a new permission with no securables to the table if id is empty guid,
      // remove if the actionid of the updated permissions isn't in the list of the existing permissions  (this is why we disabled anything with securables in it)
      // the id could be just a random guid and it wouldn't matter it isn't looking at the actual permission.id, which is why we can give it the action.id and it doesn't matter.....
      return new Permission({
        id: a.permissions ? a.id : null,
        action: null, // a, //extra unneeded payload
        actionId: a.id,
        hasSecuredItems: null, // it doesn't matter this.canRemoveAction(a.id) would work too, but providing it creates the impression it is actually used,
        roleID: this.role.id
      });
    });

    this.role.actions = [].concat(...selectedPermissions);

    this._securitySvc
      .isRoleNameAvailable(
        this.context.client ? this.context.client.id : null,
        this.role.name,
        this.role.id
      )
      .subscribe(r => {
        if (!r) {
          this._securitySvc.saveRole(this.role).subscribe(res => {
            let routeDetails = this.buildBaseRoute();

            if (this.role.id === '') {
              routeDetails = routeDetails.concat(['edit', res.id]);
            }

            this.toastr.success('Saved!');
            this._router.navigate(routeDetails);
          });
        } else {
          this.toastr.error(`${r.label} already exists!`);
        }
      });
  }

  /**
   * Add the current role to a user
   *
   * @param user The user to add the role to
   */
  associateUser(user: User) {
    this._securitySvc.associateRole(user, this.role).subscribe(res => {
      this.role.members.push(
        new RoleMember({
          user,
          role: this.role
        })
      );
      this.loadRole();
      this.toastr.success('User Associated!');
    });
  }

  /**
   * @ignore
   */
  backToList() {
    const routeDetails = this.buildBaseRoute();

    this._router.navigate(routeDetails);
  }

  /**
   * Get all workflows that a role is in
   *
   * @param role The role to get workflows for
   */
  private getWorkflows(role: Partial<Role>) {
    this._securitySvc.canDeleteRole(role).subscribe(res => {
      this.occupiedWorkflows = res.errorMessages;
      this.adminWorkflows = res.workflowAdmins;
      this.workflowsLoaded = true;
    });
  }

  /**
   * Delete the current role
   */
  deleteRole() {
    this._securitySvc.deleteRole(this.role).subscribe(res => {
      this.toastr.success('Deleted!');
      this.backToList();
    });
  }

  /**
   * Remove a user from the current role
   *
   * @param userId ID of the user to remove from the role
   */
  removeUser(userId: string) {
    this._securitySvc.removeUserRole(userId, this.roleId).subscribe(res => {
      const idx = this.role.members.findIndex(m => m.user.id === userId);
      if (idx > -1) {
        this.role.members.splice(idx, 1);
      }
      this.loadRole();
      this.toastr.success('Removed!');
    });
  }

  /**
   * Can the action be removed
   */
  canRemoveAction(id: string): boolean {
    if (this.role) {
      const action = this.role.actions.find(
        a => a.actionId.toUpperCase() === id.toUpperCase()
      );
      return !((action && action.hasSecuredItems) || false);
    }

    return false;
  }

  /**
   * Can the role be removed
   */
  get canRemove(): boolean {
    return this.role.id !== '';
  }

  /**
   * Can the role be saved
   */
  get canSave(): boolean {
    return this.form.valid;
  }

  ngOnDestroy() {
    if (this.clientSubscribe) {
      this.clientSubscribe.unsubscribe();
    }
  }
}

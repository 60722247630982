export class ComponentIds {
  // Renewals
  static RENEWALS_SEARCH = 'renewals-search';
  static WORKFLOW_RENEWALS_SEARCH = 'workflow-renewals-list';
  static EXPIRING_RENEWALS_SEARCH = 'expiring-renewals';

  // Contractors
  static CONTRACTOR_SEARCH = 'contractor-search';
  static CONTRACTOR_TYPE_REGISTRATION_LIST =
    'contractor-type-registration-list';
  static CONTRACTOR_PROFILE = 'contractor-profile';
  static CONTRACTOR_EXPIRE_REGISTRATIONS = 'contractor-expire-registrations';

  // Inspections
  static INSPECTION_QUEUE = 'inspection-queue';
  static ASSIGNED_INSPECTIONS = 'assigned-inspections-'; // type is suffixed with inspector id
  static MY_INSPECTION_QUEUE_COMPLETE = 'my-inspection-queue-complete';
  static INSPECTION_REQUESTED_QUEUE = 'inspection-requested-queue';
  static MY_INSPECTION_QUEUE_SCHEDULE = 'my-inspection-queue-schedule';
  static UPCOMING_INSPECTIONS_TO_COMPLETE = 'upcoming-inspection-to-complete';

  // Reports
  static FEES = 'daily-fees-dt';
  static FEE_RECIPIENTS = 'fee-recipients-dt';
  static PAYMENTS = 'payments-dt';
  static CONTRACTOR_SUMMARY = 'contractor-summary-dt';
  static CONTRACTOR_FEE_SUMMARY = 'contractor-fee-summary-dt';
  static PERMITS_SUMMARY = 'permits-dt';
  static DATA_EXPORT = 'export';
  static DATA_SET_EXPORT = 'data-set-export';
  static APPLICATIONS_NEEDING_MY_ATTENTION =
    'workflow-details-view-applicationsneedingmyattention';
  static RECENT_APPLICATIONS = 'workflow-details-view-recentapplications';
}

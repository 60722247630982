<div class="form-group">
  <p class="d-flex justify-content-between mb-0" for="savedFilter">
    <span>Saved Filters</span>
    <span>
      <a
        href="javascript:void(0)"
        *ngIf="activeFilter.id === null"
        (click)="editFilter(editContent)"
      >
        Save
      </a>
      <div
        ngbDropdown
        class="d-inline-block"
        placement="bottom-right"
        *ngIf="activeFilter.id !== null"
      >
        <a
          href="javascript:void(0)"
          id="saved-filter-dropdown"
          ngbDropdownToggle
        >
          Edit
        </a>
        <div ngbDropdownMenu aria-labelledby="saved-filter-dropdown">
          <button ngbDropdownItem (click)="editFilter(editContent)">
            Save
          </button>
          <button ngbDropdownItem (click)="editFilter(editContent, true)">
            Save as
          </button>
          <button ngbDropdownItem (click)="deleteFilter(selectedFilter)">
            Delete
          </button>
        </div>
      </div>
    </span>
  </p>
  <select
    class="form-control"
    name="savedFilter"
    id="savedFilter"
    [(ngModel)]="selectedFilter"
    (ngModelChange)="changeSelection()"
  >
    <option *ngFor="let filter of savedFilters" [ngValue]="filter.id">
      {{ filter.name }}
    </option>
    <option [ngValue]="null">
      (new filter)
    </option>
  </select>
</div>

<ng-template #editContent let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-edit-title">Save Filter</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('dismiss')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form>
      <div class="form-group">
        <label for="name">Name</label>
        <input
          type="text"
          id="name"
          name="name"
          class="form-control"
          [(ngModel)]="activeFilter.name"
        />
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-dark"
      (click)="modal.close('save')"
      [disabled]="!activeFilter.name"
    >
      Save
    </button>
  </div>
</ng-template>

import { PaymentConfirmationStatus } from './activities/payment-activity';
import { PaymentProcessorType } from './payment-providers/payment-processor';

export class PaymentRequest {
  activityId: string;
  confirmedOn: string;
  id: string;
  paymentMethod: string;
  processorType: PaymentProcessorType;
  requestedAmount: number;
  requestedOn: string;
  submittedBy: string;
  workflowApplicationId: string;
}

export class PaymentConfirmation {
  status: PaymentConfirmationStatus;
}

import { ToastrService } from 'ngx-toastr';
import { ErrorHandler, Inject, Injectable, Injector, inject, isDevMode } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import * as Sentry from '@sentry/browser';

@Injectable()
export class GPErrorHandler implements ErrorHandler {
  internalHandler: GPInternalHandler;

  constructor(@Inject(Injector) private injector: Injector) {
    this.internalHandler = new GPInternalHandler(injector);
  }

  handleError(error: any) {
    this.internalHandler.handleError(error);
  }
}

export class GPInternalHandler implements ErrorHandler {
  private suppressedErrorsList = [
    {
      message: `Cannot read property 'style' of null`,
      reason: 'see PER2-1524'
    },
    {
      message: `Cannot read property 'init' of null`,
      reason: 'see PER2-1524'
    }
  ];

  constructor(@Inject(Injector) private injector: Injector) {}

  private get toastrService() {
    return this.injector.get(ToastrService);
  }

  handleError(error: any) {
    try {
      const suppressedError = this.suppressedErrorsList.find(
        se => se.message === (error && error.message)
      );

      if (suppressedError) {
        if (window.location.host.includes('localhost')) {
          console.warn(`Suppressed error, ${suppressedError.reason}`, error);
        }
        return;
      }
    } catch (err) {}

    const isServerError =
      error instanceof HttpErrorResponse ||
      (error.name && error.name === 'HttpErrorResponse');

    let eventId: string;
    if (isServerError) {
      const httpError = error as HttpErrorResponse;

      if (httpError.headers && httpError.headers.has('x-sentry-id')) {
        eventId = httpError.headers.get('x-sentry-id');
      }
    } else {
      eventId = Sentry.captureException(error.originalError || error);
    }

    const title = isServerError ? 'Server Error!' : 'System Error!';
    let errMessage = isDevMode()
      ? 'See dev console for details.'
      : 'Contact support if this error persists.';

    if (eventId) {
      errMessage += ` Event ID: ${eventId}`;
    }

    console.log(this.toastrService, errMessage, title, error);

    this.toastrService.error(errMessage, title, { onActivateTick: true });

    console.error(error);
  }
}

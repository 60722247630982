import { ScreenActivity, ActivityModel } from '../../models/activities';
import { FeeDataEntity } from '../data-entities';
import { Actions, PaymentAccount } from '../../models';
import { WorkflowApplication } from '../workflow-application';
import { WorkflowPaymentRequest } from 'src/app/services';
import { PaymentAccountActivityConfigurationBase } from '../payment-providers/payment-account-activity-configuration-base';

export enum PaymentConfirmationStatus {
  Success,
  Denied,
  Error
}
export class PaymentLineItem {
  description: string;
  amount: number;
  additionalData: { [key: string]: string };
  recipient: string;
  feeName: string;
}

export class ConfirmationData {
  uniqueId: string;
  paymentDetail: any;
  confirmationNumber: string;
  amountPaid: number;
  confirmationStatus: PaymentConfirmationStatus;
  confirmationMessage: string;
}

export class PaymentRequest {
  confirmedOn: string;
  requestedOn: string;
  workflowApplicationId: string;
  id: string;
  processorType: string;
  requestedAmount: number;
  workflowApplication: WorkflowApplication;
  submittedBy: string;
  activityId: string;
  paymentMethod: string;
  providerRequest: WorkflowPaymentRequest;
  confirmationData: ConfirmationData;
  requestData: PaymentRequestData;
  applicationName: string;
  applicationNumber: string;
}

export class PaymentRequestData {
  uniqueId: string;
  totalToPay: number;
  lineItems: PaymentLineItem[];
  additionalData: { [key: string]: string };
}

export enum PaymentMethods {
  Check,
  Cash,
  CreditCardPOS,
  Online
}

export class PaymentActivityModel extends ActivityModel {
  includeOnlineOptions: boolean;
  fees: FeeDataEntity[];
  paymentMethod?: PaymentMethods;
  paymentAmount: number;
  transactionNumber: string;
  paymentTransaction: PaymentRequest;
  totalDue: number;
  totalFees: number;
  hasIntegration: boolean;
  potentialFees: FeeDataEntity[];
  payments: PaymentRequest[];
  totalPaid: number;

  autoContinue: boolean;
  allowMultiplePayments: boolean;

  constructor(options?: Partial<PaymentActivityModel>) {
    super(options);

    if (options) {
      Object.assign(this, options);
    }
  }
}

export class PaymentActivity extends ScreenActivity<PaymentActivityModel> {
  static ENTER_MANUAL_PAYMENT = 'A5470E7E-DFED-4253-A660-7A452A070AE6';

  model: PaymentActivityModel;
  activityAccountConfiguration: PaymentAccountActivityConfigurationBase;
  paymentAccount: PaymentAccount;

  constructor(options?: { [key: string]: any }) {
    super(options);

    this.type = 'payment-activity';
    this.description = 'Collect a payment for the calculated fees';
    this.name = 'Payment';

    if (options) {
      this.model = new PaymentActivityModel(options.model);
    } else {
      this.model = new PaymentActivityModel();
    }
    this.model.screenName = this.model.screenName || 'Payment';

    if (Actions) {
      Actions.registerAction({
        id: PaymentActivity.ENTER_MANUAL_PAYMENT,
        name: 'Enter Manual Payment'
      });
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { WorkflowContextService } from '../../../../services';

@Component({
  selector: 'app-global-admin',
  templateUrl: './global-admin.component.html',
  styleUrls: ['./global-admin.component.css']
})
export class GlobalAdminComponent implements OnInit {
  constructor(private _context: WorkflowContextService) {}

  ngOnInit() {
    this._context.client$.emit(null);
  }
}

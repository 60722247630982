import {
  Component,
  OnInit,
  ViewChild,
  ChangeDetectorRef,
  OnDestroy
} from '@angular/core';
import {
  Role,
  Milestone,
  Workflow,
  WorkflowType,
  Actions
} from '../../../models';
import {
  Utilities,
  WorkflowContextService,
  SecurityService,
  WorkflowService,
  SystemService
} from '../../../services';
import { AdvancedPermissionsEditorComponent } from '../../security/advanced-permissions-editor/advanced-permissions-editor.component';
import { Router, ActivatedRoute } from '@angular/router';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators
} from '@angular/forms';
import { WorkflowVersion } from '../../../models/workflow-version';
import { WorkflowGraph } from '../../../models/workflow-graph';
import {
  StartWorkflowActivity,
  StartWorkflowActivityModel,
  OnStartedActivity,
  ActivityModel,
  Activity
} from '../../../models/activities';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import { ItemTag } from 'src/app/models/item-tag';
import { Tag } from 'src/app/models/tag';
import { WorkflowTag } from 'src/app/models/workflow-tag';
import {
  ConditionItem,
  ConditionTarget
} from '../../system/condition-builder/condition-builder.model';
import { Requirement } from 'src/app/models/requirement';
import { DataEntity } from 'src/app/models/data-entities';
import { months } from 'moment';
import { ModalConfirmComponent } from '../../system/modal-confirm/modal-confirm.component';
import { range } from 'lodash';
import * as moment from 'moment';

@Component({
  selector: 'wm-workflow-settings-editor',
  templateUrl: './workflow-settings-editor.component.html',
  styleUrls: ['./workflow-settings-editor.component.css']
})
export class WorkflowSettingsEditorComponent implements OnInit, OnDestroy {
  adminRoles: Role[];
  newMilestone: Milestone = { id: Utilities.generateId(), name: '' };
  shouldShowAdvancePermissions = false;
  @ViewChild('advancedPermissions', { static: false })
  advancedPerm: AdvancedPermissionsEditorComponent;
  // @ViewChild('appDescriptionMessage')
  // appDescriptionModal: ModalConfirmComponent;
  adminRoleId: string;
  autoVoidForm: UntypedFormGroup;
  form: UntypedFormGroup;
  termLengthForm: UntypedFormGroup;
  workflowId: string;
  clientSubscription: Subscription;
  workflow: Workflow;
  EMPTY_GUID: string = Utilities.EMPTY_GUID;
  workflowTags: ItemTag[];
  canManageRoles = false;
  isDestroyed = false;
  isSaving = false;
  renewalWorkflowOptions: any[] = [];
  newRequirement: Requirement;
  entities: DataEntity[];
  expirationOptions: any[];
  selectedExpirationOption: {
    name: string;
    label: string;
    expSettings?: ConditionTarget[];
  };
  termLengthMonths: number;
  customFormulaCriteria: ConditionTarget[] = [];
  fixedNumberPreview: string;
  months: { key: string; value: number }[] = [];
  days: number[] = [];
  autoReset = false;
  nextResetDate: string;
  calcNextDate = false;
  adminRoleValid = true;
  enableAutoVoid = false;
  autoVoidActivities: Activity<ActivityModel>[];
  autoVoidExample: string;

  expirationCustomForumulaExcludedTemplateCodes = ['EXPIRATION_DATE'];

  ngOnDestroy(): void {
    if (this.clientSubscription) {
      this.clientSubscription.unsubscribe();
    }

    this._ref.detach();
    this.isDestroyed = true;
  }

  constructor(
    public context: WorkflowContextService,
    private _securitySvc: SecurityService,
    public _workflowSvc: WorkflowService,
    private _router: Router,
    private _fb: UntypedFormBuilder,
    private toastr: ToastrService,
    private _route: ActivatedRoute,
    private _ref: ChangeDetectorRef,
    private _systemSvc: SystemService
  ) {}

  selectRole(e) {
    const roleId = e.srcElement.value;

    if (this.adminRoles) {
      this.adminRoles.some((value: Role) => {
        if (value.id.toLowerCase() === roleId.toLowerCase()) {
          this.workflow.version.graph.adminRole = value;
          this.workflow.version.adminRole = value;
          this.workflow.version.adminRoleId = value.id;
          return true;
        }
      });
    }
  }

  refreshPermissions() {
    // TODO: this.advancedPerm.refreshPermissions();
  }

  loadWorkflowActivities() {
    this._workflowSvc
      .getWorkflowActivities(this.workflow)
      .subscribe(activities => {
        if (activities) {
          this.autoVoidActivities = activities
            .filter(a => a.showOnScreen)
            .sort((a, b) => (a.level < b.level ? -1 : 1));
        }
      });
  }

  checkChanges() {
    if (!this._ref['destroyed']) {
      this._ref.detectChanges();
    }
  }

  checkAdminRole() {
    if (this.adminRoleId && this.adminRoles) {
      if (!this.adminRoles.find(r => r.id == this.adminRoleId)) {
        this.adminRoleValid = false;
      } else {
        this.adminRoleValid = true;
      }
    }
  }

  calculateFixedNumberPreview() {
    let appNumber = null;
    let numberLength = null;

    if (this.form.controls['startingNumber']) {
      appNumber = this.form.controls['startingNumber'].value;
    }

    if (this.form.controls['totalLength']) {
      numberLength = this.form.controls['totalLength'].value;
    }

    if (appNumber && numberLength) {
      this.fixedNumberPreview = appNumber
        .toString()
        .padStart(numberLength, '0');
    } else if (appNumber) {
      this.fixedNumberPreview = appNumber.toString();
    } else {
      this.fixedNumberPreview = '';
    }
  }

  calculateAutoVoidExample() {
    setTimeout(() => {
      if (
        this.workflow.autoVoidSettings &&
        this.workflow.autoVoidSettings.goBeyondActivity &&
        this.workflow.autoVoidSettings.daysUntilVoid &&
        this.autoVoidActivities
      ) {
        const startDate = moment();
        const targetDate = moment();
        targetDate.add(this.workflow.autoVoidSettings.daysUntilVoid, 'days');
        const targetActivity = this.autoVoidActivities.find(
          a => a.id == this.workflow.autoVoidSettings.goBeyondActivity
        );

        if (targetActivity) {
          this.autoVoidExample = `An Application that starts on ${startDate.format(
            'MM/DD/YYYY'
          )} and doesn't complete the '${
            targetActivity.model.screenName
          }' Activity before the end of the day on ${targetDate.format(
            'MM/DD/YYYY'
          )} will get automatically voided the night of ${targetDate.format(
            'MM/DD/YYYY'
          )}.`;
        } else {
          this.autoVoidExample = null;
        }
      } else {
        this.autoVoidExample = null;
      }
    }, 100);
  }

  tagAdded(tag: Tag) {
    const addTag = () => {
      this.workflowTags.push(<ItemTag>{
        itemId: this.workflowId,
        tagId: tag.id,
        tag
      });
      this.workflow.workflowTags.push(<WorkflowTag>{
        workflowId: this.workflowId,
        tagId: tag.id,
        tag
      });
    };

    if (this.workflowId) {
      this._systemSvc
        .addWorkflowTag(<WorkflowTag>{
          workflowId: this.workflowId,
          tagId: tag.id
        })
        .subscribe(result => {
          addTag();
        });
    } else {
      addTag();
    }
  }

  tagRemoved(tag: Tag) {
    const removeTag = () => {
      const idx = this.workflowTags.findIndex(wt => wt.tagId === tag.id);
      this.workflowTags.splice(idx, 1);
    };

    if (this.workflowId) {
      this._systemSvc
        .removeWorkflowTag(<WorkflowTag>{
          workflowId: this.workflowId,
          tagId: tag.id
        })
        .subscribe(result => {
          removeTag();
        });
    } else {
      removeTag();
    }
  }

  toggleAutoReset() {
    if (this.autoReset) {
      this.form.controls['resetMonth'].setValue(this.workflow.resetClientAppNumberMonth);
      this.form.controls['resetDay'].setValue(this.workflow.resetClientAppNumberDay);
      this.form.controls['resetMonth'].enable();
      this.form.controls['resetDay'].enable();
    } else {
      this.workflow.resetClientAppNumberDay = null;
      this.workflow.resetClientAppNumberMonth = null;
      this.nextResetDate = null;
      this.form.controls['resetMonth'].disable();
      this.form.controls['resetDay'].disable();
    }
  }

  calculateNextResetDate(forceCalc: boolean = false) {
    this.workflow.resetClientAppNumberMonth = this.form.controls[
      'resetMonth'
    ].value;
    this.workflow.resetClientAppNumberDay = this.form.controls[
      'resetDay'
    ].value;

    if (
      this.workflow &&
      this.workflow.resetClientAppNumberDay &&
      this.workflow.resetClientAppNumberMonth &&
      this.calcNextDate
    ) {
      this._workflowSvc
        .calculateClientAppNumberResetDate(
          this.workflow.resetClientAppNumberMonth,
          this.workflow.resetClientAppNumberDay
        )
        .subscribe(resetDate => {
          this.nextResetDate = resetDate;
        });
    }
  }

  toggleAutoVoid(e) {
    if (this.enableAutoVoid) {
      if (!this.workflow.autoVoidSettings) {
        this.workflow.autoVoidSettings = {
          goBeyondActivity: '',
          daysUntilVoid: 0
        };
      }
      this.autoVoidForm.enable();
    } else {
      this.workflow.autoVoidSettings = null;
      this.autoVoidForm.disable();
    }
    this.calculateAutoVoidExample();
  }

  ngOnInit() {
    this.form = this._fb.group({
      descriptionTemplate: this._fb.control('', [Validators.nullValidator]),
      workflowName: this._fb.control('', [Validators.required]),
      adminRole: this.context.client
        ? this._fb.control(this.adminRoleId, [Validators.required])
        : this._fb.control(this.adminRoleId, [Validators.nullValidator]),
      milestoneName: this._fb.control('', [Validators.nullValidator]),
      visibleToPublic: this._fb.control('', [Validators.nullValidator]),
      startingNumber: this._fb.control('', [Validators.nullValidator]),
      totalLength: this._fb.control('', [Validators.nullValidator]),
      numberPrefix: this._fb.control('', [Validators.nullValidator]),
      customFormula: this._fb.control('', [Validators.nullValidator]),
      description: this._fb.control('', [Validators.nullValidator]),
      workflowType: this._fb.control('', [Validators.required]),
      resetMonth: this._fb.control(
        this.workflow ? this.workflow.resetClientAppNumberMonth : '',
        [Validators.required]
      ),
      resetDay: this._fb.control(
        this.workflow ? this.workflow.resetClientAppNumberDay : '',
        [Validators.required]
      ),
      autoReset: this._fb.control('', [Validators.nullValidator]),
      enableAutoVoid: this._fb.control('', [Validators.nullValidator])
    });

    this.autoVoidForm = this._fb.group({
      goBeyondActivity: this._fb.control('', [Validators.required]),
      daysUntilVoid: this._fb.control('', [Validators.required])
    });

    this.form.addControl('autoVoid', this.autoVoidForm);

    this.months = range(1, 13, 1).map((m, idx) => {
      return {
        key: moment()
          .month(m - 1)
          .format('MMMM'),
        value: m
      };
    });

    this.days = range(1, 29).map((d, idx) => d);

    this.termLengthForm = this._fb.group({
      termLength: this._fb.control('', [Validators.nullValidator])
    });

    this.termLengthForm.controls['termLength'].updateValueAndValidity();

    const mapTags = function() {
      this.workflowTags = (this.workflow.workflowTags || []).map(
        wt =>
          <ItemTag>{
            itemId: wt.workflowId,
            tagId: wt.tagId,
            tag: wt.tag
          }
      );
    }.bind(this);

    const init = function() {
      this._route.params.subscribe(params => {
        this.workflowId = params['workflowId'];
        if (this.workflowId) {
          this._workflowSvc.getWorkflow(this.workflowId).subscribe(workflow => {
            this.workflow = workflow;

            this.enableAutoVoid = this.workflow.autoVoidSettings != null;

            this.buildEntities().subscribe(entities => {
              this.entities = entities;
            });

            if (workflow) {
              this.nextResetDate = workflow.nextClientAppNumberResetDateText;
              this.autoReset = workflow.resetClientAppNumberMonth != null;
              this.toggleAutoReset();
            }
            mapTags();
            if (this.workflow && !this.workflow.version.graph.milestones) {
              this.workflow.version.graph.milestones = [];
            }

            if (this.workflow.version.adminRoleId) {
              this.adminRoleId = this.workflow.version.adminRoleId;
              this.form.controls['adminRole'].setValue(this.adminRoleId);
            }

            this.checkAdminRole();

            this.context.workflow$.emit(this.workflow);

            this.checkChanges();
            this.loadRenewalOptions();
            this.loadExpirationOptions();
            this.calculateFixedNumberPreview();
            this.loadWorkflowActivities();
            this.calculateAutoVoidExample();

            // this is here so we don't recalculate the next date right away.
            setTimeout(() => {
              this.calcNextDate = true;
              this.form.controls['autoVoid'].valueChanges.subscribe(changes => {
                this.calculateAutoVoidExample();
              });
            }, 200);
          });
        } else {
          this.workflow = new Workflow({
            id: Utilities.EMPTY_GUID,
            client: this.context.client,
            workflowType: WorkflowType.Other,
            workflowTags: [],
            version: new WorkflowVersion({
              graph: new WorkflowGraph({
                onStarted: new OnStartedActivity({
                  activities: [
                    new StartWorkflowActivity({
                      id: Utilities.generateId(),
                      model: new StartWorkflowActivityModel({
                        responsibleRole: SecurityService.APPLICANT_ROLE,
                        responsibleRoleId: SecurityService.APPLICANT_ROLE.id
                      })
                    })
                  ]
                })
              })
            })
          });

          this.autoReset = false;

          this.toggleAutoReset();
          this.loadRenewalOptions();
          this.loadExpirationOptions();

          mapTags();

          if (this.workflow && !this.workflow.version.graph.milestones) {
            this.workflow.version.graph.milestones = [];
          }

          if (this.workflow.version.graph.adminRole) {
            this.adminRoleId = this.workflow.version.graph.adminRole.id;
          }

          this.checkAdminRole();

          this.context.workflow$.emit(this.workflow);

          this.checkChanges();
        }

        this.calculateFixedNumberPreview();

        if (this.form.controls['totalLength']) {
          this.form.controls['totalLength'].valueChanges.subscribe(() => {
            this.calculateFixedNumberPreview();
          });
        }
        if (this.form.controls['startingNumber']) {
          this.form.controls['startingNumber'].valueChanges.subscribe(() => {
            this.calculateFixedNumberPreview();
          });
        }

        if (this.context.client) {
          this._securitySvc
            .getRolesWithAllActions(
              this.context.client.id,
              Actions.WORKFLOW_ACTIONS
            )
            .subscribe(roles => {
              this.adminRoles = roles;
              this.checkAdminRole();
              this.checkChanges();
            });
        }
      });

      this._securitySvc
        .isLoginEntitled(Actions.ROLE_MANAGE)
        .subscribe(isEntitled => {
          this.canManageRoles = isEntitled;
        });
    }.bind(this);

    this._route.data.subscribe(d => {
      const clientMode = d.clientMode;

      if (!clientMode) {
        init();
      } else {
        if (this.context.client) {
          init();
          this.checkChanges();
        } else {
          this.clientSubscription = this.context.client$.subscribe(c => {
            init();
            this.checkChanges();
          });
        }
      }
    });
  }

  onExpirationOptionChange() {
    if (this.selectedExpirationOption.name !== 'custom-months') {
      this.termLengthForm.controls['termLength'].setValidators(
        Validators.nullValidator
      );
      this.termLengthForm.controls['termLength'].updateValueAndValidity();
    } else {
      this.termLengthForm.controls['termLength'].setValidators([
        Validators.required,
        Validators.min(1)
      ]);
    }
  }

  addMilestone() {
    this.workflow.version.graph.milestones.push(this.newMilestone);
    this.newMilestone = { id: Utilities.generateId(), name: '' };
  }

  removeMilestone(milestone: Milestone) {
    this.workflow.version.graph.milestones.splice(
      this.workflow.version.graph.milestones.indexOf(milestone),
      1
    );
  }

  saveSettings() {
    let isNew: boolean;
    if (this.workflow.id === Utilities.EMPTY_GUID) {
      isNew = true;
    }

    this.isSaving = true;

    switch (this.selectedExpirationOption.name) {
      case 'infinite':
        this.selectedExpirationOption.expSettings = null;
        break;
      case 'end-of-issue-year':
        this.selectedExpirationOption.expSettings = [
          new ConditionTarget({
            id: '196B539E-B330-4E4B-B577-A850D73B13AA', // hard-coded for ease of selected option loading
            value: 'DATE(YEAR(${EFFECTIVE_DATE}),12,31)'
          })
        ];
        break;
      case 'custom-months':
        this.selectedExpirationOption.expSettings = [
          new ConditionTarget({
            id: '475A27B0-1AD9-4840-8E63-BB290D979F6C', // hard-coded for ease of selected option loading
            value: `EDATE(\${EFFECTIVE_DATE}, ${this.termLengthMonths})`
          })
        ];
        break;
      case 'custom-formula':
        this.selectedExpirationOption.expSettings = this.customFormulaCriteria;
        break;
      default:
        this.selectedExpirationOption.expSettings = null;
        break;
    }

    this.workflow.expirationFormulaCriteria = this.selectedExpirationOption.expSettings;

    this._workflowSvc.saveWorkflow(this.workflow).subscribe(wf => {
      this.isSaving = false;
      if (isNew) {
        this.isSaving = false;
        this._router.navigate(['admin', 'workflow-builder', wf.id]);
      } else {
        this.isSaving = false;
        this.workflow = wf;
        this.toastr.success('Saved!');
        this.loadRenewalOptions();
      }
    });
  }

  // requirements methods-------------------->
  addRequirement() {
    this.newRequirement = new Requirement();
    this.newRequirement.id = Utilities.generateId();
    this._ref.detectChanges();
  }

  newRequirementApplied(requirement: Requirement) {
    this.workflow.requirements.push(requirement);
    this.newRequirement = null;
    this.saveSettings();
  }

  newRequirementCancelled() {
    this.newRequirement = null;
    this._ref.detectChanges();
  }

  removeRequirement(requirement: Requirement) {
    const index = this.workflow.requirements.indexOf(requirement);
    this.workflow.requirements.splice(index, 1);
    this.saveSettings();
  }
  // <----------------------------

  detectChanges() {
    if (this._ref && !this.isDestroyed) {
      this._ref.detectChanges();
    }
  }

  updateCriteria(criteria: ConditionTarget[]) {
    this.workflow.numberPrefixCriteria = criteria;
    let hasValue = false;

    if (criteria && criteria.length > 0) {
      criteria.forEach((c, index) => {
        if ((c.value || '') !== '') {
          hasValue = true;
          return false;
        }
      });
    }
    this.form.controls['numberPrefix'].setValue(hasValue ? 'done' : '');
    this.detectChanges();
  }

  updateExpirationCriteria(criteria: ConditionTarget[]) {
    this.customFormulaCriteria = criteria;
    let hasValue = false;

    if (criteria && criteria.length > 0) {
      criteria.forEach((c, index) => {
        if ((c.value || '') !== '') {
          hasValue = true;
          return false;
        }
      });
    }
    this.form.controls['customFormula'].setValue(hasValue ? 'done' : '');
    this.detectChanges();
  }

  // Use => in order to force `this` into being the FeeDataEntityEditorComponent
  getThenLabel = (clause: ConditionTarget): string => {
    if (clause) {
      return clause.value;
    } else {
      return 'needs refactored';
    }
  };

  buildEntities(): Observable<DataEntity[]> {
    return this._workflowSvc.getDataEntities(this.workflow, null, null, [
      WorkflowService.ACTIVITIES.Form
    ]);
  }

  async loadRenewalOptions() {
    const workflows = await this._workflowSvc
      .getRenewableWorkflows(this.context.client, false)
      .toPromise();

    const responseWorkflows: any[] = [];

    if (!this.workflow.renewalWorkflowId) {
      this.workflow.renewalWorkflowId = this.workflow.id;
    }

    for (const wf of workflows) {
      if (wf.id === this.workflow.id) {
        responseWorkflows.push({ label: 'Re-use this workflow', value: wf.id });
      } else {
        responseWorkflows.push({ label: wf.version.name, value: wf.id });
      }
    }

    const thisWfIsPublished =
      responseWorkflows.filter(w => w.value === this.workflow.id).length > 0;

    if (this.workflow.id === Utilities.EMPTY_GUID || !thisWfIsPublished) {
      this.renewalWorkflowOptions.push({
        label: 'Re-use this workflow',
        value: this.workflow.id
      });
      this.renewalWorkflowOptions.push(...responseWorkflows);
    } else {
      this.renewalWorkflowOptions = responseWorkflows;
    }
  }

  loadExpirationOptions() {
    const defaultOption = {
      name: 'infinite',
      label: 'Does not expire (infinite)'
    };
    const endOfYearOption = {
      name: 'end-of-issue-year',
      label: 'Expires at end of effective year'
    };
    const customMonthsOption = {
      name: 'custom-months',
      label: 'Set a term length in months from effective date'
    };
    const customFormulaOption = {
      name: 'custom-formula',
      label: 'Calculate expiration date with custom formula'
    };

    this.customFormulaCriteria = null;

    if (this.workflow.expirationFormulaCriteria == null) {
      this.selectedExpirationOption = defaultOption;
    } else {
      if (
        this.workflow.expirationFormulaCriteria.filter(
          efc => efc.id === '196B539E-B330-4E4B-B577-A850D73B13AA'
        ).length > 0
      ) {
        this.selectedExpirationOption = endOfYearOption;
      } else if (
        this.workflow.expirationFormulaCriteria.filter(
          efc => efc.id === '475A27B0-1AD9-4840-8E63-BB290D979F6C'
        ).length > 0
      ) {
        this.selectedExpirationOption = customMonthsOption;
        const tls = this.workflow.expirationFormulaCriteria[0].value
          .match(/\d/g)
          .join('');
        this.termLengthMonths = parseInt(tls, 10);
        this.termLengthForm.controls['termLength'].setValidators([
          Validators.required,
          Validators.min(1)
        ]);
      } else {
        this.selectedExpirationOption = customFormulaOption;
        this.customFormulaCriteria = this.workflow.expirationFormulaCriteria;
      }
    }

    this.expirationOptions = [
      defaultOption,
      endOfYearOption,
      customMonthsOption,
      customFormulaOption
    ];
  }
}

import { Component, OnInit, EventEmitter } from '@angular/core';
import { ActivityView } from 'src/app/views/master-views/app.view/app.view.component';
import { UntypedFormGroup } from '@angular/forms';
import { WorkflowContextService } from 'src/app/services';
import { RenewalReviewActivity } from 'src/app/models/activities/renewal-review-activity';

@Component({
  selector: 'wm-renewal-review-activity-input',
  templateUrl: './renewal-review-activity-input.component.html',
  styleUrls: ['./renewal-review-activity-input.component.css']
})
export class RenewalReviewActivityInputComponent
  implements OnInit, ActivityView {
  activity: RenewalReviewActivity;
  isPreview: boolean;
  isTestApplication: boolean;
  showSave?: EventEmitter<boolean>;
  showCancel?: EventEmitter<boolean>;
  showNext?: EventEmitter<boolean>;
  showPrevious?: EventEmitter<boolean>;
  goNext?: EventEmitter<boolean>;
  value$: EventEmitter<any>;
  initActivity: any;
  nextOnSelect = false;
  form: UntypedFormGroup;
  context: WorkflowContextService;
  applicationId?: string;

  viewTitle(): string {
    return this.activity.model.screenName;
  }

  constructor() {}

  ngOnInit() {}
}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { PaymentAccount } from '../../../../models';
import { WorkflowContextService, SystemService } from '../../../../services';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'wm-payment-account-list-view',
  templateUrl: './payment-account-list-view.component.html',
  styleUrls: ['./payment-account-list-view.component.css']
})
export class PaymentAccountListViewComponent implements OnInit, OnDestroy {
  ngOnDestroy(): void {
    if (this.clientSubscription) {
      this.clientSubscription.unsubscribe();
    }
  }
  accounts: PaymentAccount[];
  clientSubscription: Subscription;

  constructor(
    private _context: WorkflowContextService,
    private _systemSvc: SystemService,
    private _router: Router
  ) {}

  loadPaymentAccounts() {
    this._systemSvc
      .getPaymentAccounts(this._context.client.id)
      .subscribe(accounts => {
        this.accounts = accounts;
      });
  }

  ngOnInit() {
    if (this._context.client) {
      this.loadPaymentAccounts();
    } else {
      this.clientSubscription = this._context.client$.subscribe(() => {
        this.loadPaymentAccounts();
      });
    }
  }

  createPaymentAccount() {
    this._router.navigate([
      'admin',
      'jurisdiction',
      this._context.client.id,
      'settings',
      'payment-accounts',
      'add'
    ]);
  }

  removed(e: string) {
    this._systemSvc.deletePaymentAccount(e).subscribe(result => {
      //load payment accounts
      this.loadPaymentAccounts();
    });
  }
}

import { ConditionTarget } from 'src/app/components/system/condition-builder/condition-builder.model';
import { DataEntity } from './data-entity';
import { Validators } from '@angular/forms';

export class GenerateClientApplicationNumberDataEntity extends DataEntity {
  feeFormula?: string;
  feeRecipient: string;
  paymentAccountId: string;
  canAdminEdit: boolean;
  numberPrefixCriteria: ConditionTarget[];

  constructor(options?: Partial<GenerateClientApplicationNumberDataEntity>) {
    super(options);

    this.dataEntityTypeCode = 'generate-client-application-number';
    this.dataEntityTypeDescription =
      'Use this entity type to generate a client application number.';
    this.dataEntityTypeName = 'Generate Client Application Number';
    this.hasEditor = true;

    if (options) {
      Object.assign(this, options);
    }

    if (!this.numberPrefixCriteria) {
      this.numberPrefixCriteria = [];
    }
  }

  getValidators(): any[] {
    return [Validators.nullValidator];
  }
}

export class Address {
  id: string;
  name: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  parent?: any;
  parentType: string;
}
export class TESTCLIENT {
  clientId: string;
  clientName: string;
  constructor(options?: Partial<TESTCLIENT>) {
    //this.adminRole = new Role();

    if (options) {
      Object.assign(this, options);
    }
  }
}
export class UserAddress extends Address {
  constructor(options?: Partial<UserAddress>) {
    super();
    this.name = 'Default';
    this.parentType = 'User';

    if (options) {
      Object.assign(this, options);
    }
  }
}

export class ContractorAddress extends Address {
  constructor(options?: Partial<UserAddress>) {
    super();
    this.name = 'Default';
    this.parentType = 'Contractor';

    if (options) {
      Object.assign(this, options);
    }
  }
}

export class ContactAddress extends Address {
  constructor(options?: Partial<UserAddress>) {
    super();
    this.name = 'Default';
    this.parentType = 'Contact';

    if (options) {
      Object.assign(this, options);
    }
  }
}

export class InspectionAddress extends Address {
  constructor(options?: Partial<UserAddress>) {
    super();
    this.name = 'Default';
    this.parentType = 'Inspector';

    if (options) {
      Object.assign(this, options);
    }
  }
}

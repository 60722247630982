import { Activity, ActivityModel } from 'src/app/models/activities';

export class CancelScheduledEmailActivityModel extends ActivityModel {
  scheduledEmailsToCancel: string[];
}

export class CancelScheduledEmailActivity extends Activity<
  CancelScheduledEmailActivityModel
> {
  availableScheduledEmailsToCancel: { id: string; name: string }[];

  constructor(options?: Partial<CancelScheduledEmailActivity>) {
    super(options);

    if (options) {
      Object.assign(this, options);
    }

    this.model = new CancelScheduledEmailActivityModel(
      options ? options.model : null
    );

    this.model.screenName = this.model.screenName || 'Cancel Scheduled Email';
  }
}

<div class="form-group">
  <wm-de-label
    [entity]="entity"
    [for]="entity.templateCode"
    [displayOnly]="true"
  ></wm-de-label>
  <input
    type="text"
    readonly
    *ngIf="!entity.isMultiline"
    class="form-control-plaintext"
    [id]="entity.templateCode"
    [value]="entity.value || ''"
  />

  <textarea
    class="form-control-plaintext"
    readonly
    *ngIf="entity.isMultiline"
    rows="5"
    [name]="entity.templateCode"
    [id]="entity.templateCode"
    >{{ entity.value || '' }}</textarea
  >
</div>

<form [formGroup]="form" *ngIf="config">
  <div class="form-group">
    <label class="bmd-label-floating" for="partnerId">
      Partner ID
    </label>
    <input
      type="text"
      class="form-control"
      formControlName="partnerId"
      name="partnerId"
      [(ngModel)]="config.partnerId"
    />
  </div>

  <div class="form-group">
    <label class="bmd-label-floating" for="lineItemDetailField">
      Line Item Detail field:
    </label>
    <select
      [(ngModel)]="config.lineItemDetailField"
      class="form-control"
      name="lineItemDetailField"
      formControlName="lineItemDetailField"
    >
      <option [value]="VPSLineItemDetail.Recipient">Recipient</option>
      <option [value]="VPSLineItemDetail.ApplicationNumber"
        >Application Number</option
      >
    </select>
  </div>
</form>

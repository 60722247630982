<form>
  <div class="form-group">
    <label for="frmState" class="bmd-label-static">State</label>
    <select
      class="form-control"
      [(ngModel)]="selectedState"
      name="frmState"
      (ngModelChange)="filterClients($event)"
    >
      <option value="">All</option>
      <option *ngFor="let clientState of clientStates" [value]="clientState">
        {{ clientState }}
      </option>
    </select>
  </div>

  <app-loader *ngIf="!filteredClients"></app-loader>
  <div class="form-group">
    <label for="frmJurisdiction" class="bmd-label-static">Jurisdiction</label>
    <!-- Show states for selected state -->
    <select
      name="frmJurisdiction"
      *ngIf="(selectedState || '') !== ''"
      class="form-control"
      (change)="navigateToLandingPage($event.target.value)"
    >
      <option></option>
      <option
        *ngFor="let client of sort(filteredClients)"
        [value]="client.id"
        >{{ client.name }}</option
      >
    </select>
    <!-- Show states grouped by state -->
    <select
      name="frmJurisdiction"
      *ngIf="(selectedState || '') === ''"
      class="form-control"
      (change)="navigateToLandingPage($event.target.value)"
    >
      <option></option>
      <optgroup *ngFor="let state of clientStates" [label]="state">
        <option
          *ngFor="let b of sort(filteredClients | clientStateFilter: state)"
          [value]="b.id"
        >
          {{ b.name }}
        </option>
      </optgroup>
    </select>
  </div>
</form>

<form [formGroup]="form" *ngIf="entity && entity.visibleToUser" novalidate>
  <div [ngClass]="divClass">
    <wm-de-label
      [entity]="entity"
      [displayOnly]="!entity.allowEdit"
      [for]="entity.templateCode"
    ></wm-de-label>
    <input
      *ngIf="!entity.isCalculating"
      type="text"
      [readonly]="!entity.allowEdit"
      [ngClass]="inputClass"
      [formControlName]="entity.templateCode"
      [(ngModel)]="this.modelValue"
      [name]="entity.templateCode"
      [id]="entity.templateCode"
    />
    <app-loader *ngIf="entity.isCalculating"></app-loader>
  </div>
</form>

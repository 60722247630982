import { IDateValidator } from '../../date-validators';
import { DayOfMonthOptions } from '../day-of-month-date-validator';

export class DayOfMonthValidator implements IDateValidator {
  validateMaxDate(date: Date, options: DayOfMonthOptions): boolean {
    throw new DOMException();
  }
  validateMinDate(date: Date, options: DayOfMonthOptions): boolean {
    throw new DOMException();
  }
  validateDate(date: Date, options: DayOfMonthOptions): boolean {
    throw new DOMException();
  }
  calculateExample(date: Date, options: DayOfMonthOptions): Date {
    let today = new Date();
    return new Date(today.getFullYear(), today.getMonth(), options.day);
  }
}

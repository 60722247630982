import { UntypedFormBuilder, Validators, UntypedFormControl } from '@angular/forms';
import { PointNPayPaymentProcessorAccountConfiguration } from './../../../models/payment-providers/pointnpay/pointnpay-payment-processor-account-configuration';
import { PaymentAccountConfigBaseComponent } from './../payment-account-config-base/payment-account-config-base.component';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'wm-payment-account-config-pointnpay',
  templateUrl: './payment-account-config-pointnpay.component.html',
  styleUrls: ['./payment-account-config-pointnpay.component.css']
})
export class PaymentAccountConfigPointnpayComponent
  extends PaymentAccountConfigBaseComponent
  implements OnInit {
  @Input() config: PointNPayPaymentProcessorAccountConfiguration;
  example: string;

  constructor(_fb: UntypedFormBuilder) {
    super(_fb);
    this.showUsername = false;
    this.showPassword = false;
  }

  addParameter() {
    if (!this.config.parameterNames) {
      this.config.parameterNames = [];
    }

    this.form.addControl(
      'param-' + this.config.parameterNames.length,
      new UntypedFormControl('', {
        updateOn: 'blur',
        validators: Validators.required
      })
    );

    this.config.parameterNames.push('');
  }

  buildExample() {
    const realestateProductID: string = this.config.productId;

    let configParamEx = '';
    if (this.config.parameterNames) {
      configParamEx = this.config.parameterNames
        .filter(pn => pn !== '')
        .map(pn => `${pn}="{EvaluatedValue}"`)
        .join(' ');
    }

    this.example = `
    <params UniqueID="{UniqueID}" ${configParamEx}>
      <product ID="${realestateProductID}" UniqueID="{UniqueID}" PermitNumber="{ApplicationNumber}" Amount="{feeAmount}"></product>
    </params>`;
  }

  removeParameter(p) {
    const idx = this.config.parameterNames.indexOf(p);

    this.config.parameterNames.splice(idx, 1);
    this.form.removeControl('param-' + idx);
  }

  ngOnInit() {
    this.addValidation('productId', Validators.required, this.config.productId);
    this.addValidation(
      'clientCode',
      Validators.required,
      this.config.clientCode
    );
    if (this.config.parameterNames) {
      for (let idx = 0; idx < this.config.parameterNames.length; idx++) {
        this.form.addControl('param-' + idx, new UntypedFormControl());
      }
    }

    this.buildExample();

    this.form.valueChanges.subscribe(() => {
      this.buildExample();
    });
  }
}

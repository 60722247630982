import { Component, OnInit, EventEmitter } from '@angular/core';
import { ActivityView } from 'src/app/views/master-views/app.view/app.view.component';
import { FormGroup } from '@angular/forms';
import { WorkflowContextService } from 'src/app/services';
import { ReviewActivity } from 'src/app/models/activities/review-activity';

@Component({
  selector: 'wm-review-activity-view',
  templateUrl: './review-activity-view.component.html',
  styleUrls: ['./review-activity-view.component.css']
})
export class ReviewActivityViewComponent
  implements OnInit, ActivityView {
  activity: ReviewActivity;
  isPreview: boolean;
  isTestApplication: boolean;
  showSave?: EventEmitter<boolean>;
  showCancel?: EventEmitter<boolean>;
  showNext?: EventEmitter<boolean>;
  showPrevious?: EventEmitter<boolean>;
  goNext?: EventEmitter<boolean>;
  value$: EventEmitter<any>;
  initActivity: any;
  form: FormGroup;
  context: WorkflowContextService;
  nextOnSelect = false;
  applicationId?: string;

  viewTitle(): string {
    return 'Review Application Activity';
  }

  constructor() {}

  ngOnInit() {}
}

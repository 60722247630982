<form [formGroup]="form" (submit)="save()" data-toggle="validator">
  <h3>{{ processor?.name }} Settings</h3>

  <div class="form-group" *ngIf="processor">
    <div>
      {{ processor.inTestMode ? "Using Test Settings" : "Using Live Settings" }}
    </div>
    <wm-modal-confirm
      [showButton]="true"
      [showButtonText]="true"
      commandText="Yes"
      [buttonText]="processor.inTestMode ? 'Go Live' : 'Switch to Test Mode'"
      [title]="
        processor.inTestMode
          ? 'Go Live (' + processor.name + ')'
          : 'Switch to test (' + processor.name + ')'
      "
      [btnColor]="processor.inTestMode ? 'danger' : 'success'"
      (accepted)="toggleTestMode()"
    >
      <div *ngIf="processor.inTestMode">
        Are you sure you want to change this payment provider to use the live
        settings? This will change all accounts to use their live settings
        unless the account is set to use test settings.
      </div>
      <div *ngIf="!processor.inTestMode">
        Are you sure you want to change this payment provider to use the test
        settings? This will change all accounts to use their test settings.
      </div>
    </wm-modal-confirm>
  </div>

  <ul ngbNav [destroyOnHide]="false" activeId="live" #settings="ngbNav" class="nav-tabs">
    <li ngbNavItem="live" id="live">
      <a ngbNavLink>Live Settings</a>
      <ng-template ngbNavContent>
        <div formGroupName="prodForm">
          <wm-payment-processor-config-base
            *ngIf="processor"
            [form]="prodForm"
            [processorConfig]="processor.configuration"
          >
          </wm-payment-processor-config-base>

          <template #prodProviderConfigView></template>
        </div>
      </ng-template>
    </li>
    <li ngbNavItem="test" id="test">
      <a ngbNavLink>Test Settings</a>
      <ng-template ngbNavContent>
        <div formGroupName="testForm">
          <wm-payment-processor-config-base
            *ngIf="processor"
            [form]="testForm"
            [processorConfig]="processor.testConfiguration"
          >
          </wm-payment-processor-config-base>

          <template #testProviderConfigView></template>
        </div>
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="settings"></div>

  <div class="row" *ngIf="form">
    <div class="col">
      <button
        type="submit"
        class="btn btn-raised btn-success"
        [disabled]="form.invalid"
      >
        Save
      </button>
    </div>
    <div class="col">
      <button
        type="button"
        class="btn btn-raised btn-default float-right"
        routerLink="/admin/global/payment-processors"
      >
        Cancel
      </button>
    </div>
  </div>
</form>

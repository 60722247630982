import { Component, OnInit, ComponentFactoryResolver } from '@angular/core';
import { DataEntityInputComponent } from '../../data-entity-input/data-entity-input.component';

@Component({
  selector: 'wm-system-data-entity-input',
  templateUrl: './system-data-entity-input.component.html',
  styleUrls: ['./system-data-entity-input.component.css']
})
export class SystemDataEntityInputComponent extends DataEntityInputComponent
  implements OnInit {
  constructor(componentFactoryResolver: ComponentFactoryResolver) {
    super(componentFactoryResolver);
  }

  ngOnInit() {}
}

import { Component, OnInit, Inject, forwardRef } from '@angular/core';
import { DataEntityEditorBaseComponent } from '../../data-entity-editor-base/data-entity-editor-base.component';
import { DateDataEntity } from '../../../../../models/data-entities';
import { Validators, UntypedFormBuilder } from '@angular/forms';
import { WorkflowService } from '../../../../../services';
import { NgbDateCustomParserFormatter } from '../../../../../models/dateformat';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { ExternalDataSource } from 'src/app/models/external-data-source';

@Component({
  selector: 'wm-date-data-entity-editor',
  templateUrl: './date-data-entity-editor.component.html',
  styleUrls: ['./date-data-entity-editor.component.css'],
  providers: [
    { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter }
  ]
})
export class DateDataEntityEditorComponent extends DataEntityEditorBaseComponent
  implements OnInit {
  constructor(
    @Inject(forwardRef(() => WorkflowService))
    public _workflowSvc: WorkflowService,
    private _fb: UntypedFormBuilder
  ) {
    super();
  }

  dateDataEntity: DateDataEntity;

  public includeTimeChanged() {
    this.dateDataEntity.includeTime = this.form.controls['includeTime'].value;
    // time cannot be required if the DE itself isn't required or time isn't even asked for
    if (!this.dateDataEntity.includeTime || !this.dateDataEntity.isRequired) {
      this.dateDataEntity.requireTime = false;
    }
  }

  ngOnInit() {
    this.dateDataEntity = this.dataEntity as DateDataEntity;
    this.form.addControl(
      'controlOptions',
      this._fb.control(false, [Validators.nullValidator])
    );
    this.form.addControl(
      'includeWeekends',
      this._fb.control(false, [Validators.nullValidator])
    );
    this.form.addControl(
      'includeTime',
      this._fb.control(this.dateDataEntity.includeTime, [
        Validators.nullValidator
      ])
    );
    this.form.addControl(
      'requireTime',
      this._fb.control(false, [Validators.nullValidator])
    );
    this.form.addControl(
      'forwardLimit',
      this._fb.control(null, [Validators.nullValidator])
    );
    this.form.addControl(
      'backwardLimit',
      this._fb.control(null, [Validators.nullValidator])
    );

    if (this.form.controls['isRequired']) {
      this.form.controls['isRequired'].valueChanges.subscribe(() =>
        this.includeTimeChanged()
      );
    }
  }

  externalDataSourceChanged(eds: ExternalDataSource) {
    this.dataEntity.externalDataSource = eds;
  }
}

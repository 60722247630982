import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-permits-report',
  templateUrl: './permits-report.component.html',
  styleUrls: ['./permits-report.component.css']
})
export class PermitsReportComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}

<div class="modal-header">
  <h4 class="modal-title">
    Activity Preview<span *ngIf="activity && viewTitle">: {{ viewTitle }}</span>
  </h4>
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="activeModal.dismiss('Cross click')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <app-loader *ngIf="loading"></app-loader>
  <wm-activity-container
    *ngIf="form && activity"
    [activity]="activity"
    [isPreview]="true"
    [role]="role"
    [form]="form"
    [showTitle]="false"
    (viewTitle$)="setViewTitle($event)"
  >
  </wm-activity-container>
</div>
<div class="modal-footer">
  <div class="form-group mr-auto">
    <label for="roleSelect" class="bmd-label-floating">
      Preview as
    </label>
    <select
      *ngIf="availableRoles"
      [ngModel]="selectedRole"
      class="form-control"
      id="roleSelect"
    >
      <option *ngFor="let r of availableRoles" [value]="r.id">
        {{ r.name }}
      </option>
    </select>
  </div>

  <button
    type="button"
    class="btn btn-outline-dark"
    (click)="activeModal.close('Close click')"
  >
    Close
  </button>
</div>

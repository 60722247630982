import { Component, OnInit, ComponentFactoryResolver } from '@angular/core';
import { GenerateClientApplicationNumberDataEntity } from '../../../../../models/data-entities/client-app-number-data-entity';
import { DataEntityViewComponent } from '../../data-entity-view/data-entity-view.component';

@Component({
  selector: 'wm-client-app-number-data-entity-view',
  templateUrl: './client-app-number-data-entity-view.component.html',
  styleUrls: ['./client-app-number-data-entity-view.component.css']
})
export class GenerateClientApplicationNumberDataEntityViewComponent
  extends DataEntityViewComponent
  implements OnInit {
  constructor(componentFactoryResolver: ComponentFactoryResolver) {
    super(componentFactoryResolver);
  }
  public entity: GenerateClientApplicationNumberDataEntity;

  ngOnInit() {
    this.entity = <GenerateClientApplicationNumberDataEntity>this.entity;
  }
}

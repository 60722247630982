<div class="form-group">
  <wm-de-label
    [entity]="entity"
    [for]="entity.templateCode"
    [displayOnly]="true"
  ></wm-de-label>
  <app-esri-map
    *ngIf="mapsketchDataEntity.mapConfig.mapServiceId"
    #mapComp
    [mapServiceId]="mapsketchDataEntity.mapConfig.mapServiceId"
    [basemapName]="mapsketchDataEntity.mapConfig.basemapName"
    [initialExtent]="mapsketchDataEntity.mapConfig.initialExtent"
    [mapLayerOptions]="mapsketchDataEntity.mapConfig.layers"
    [showToc]="mapsketchDataEntity.mapConfig.showToc"
    [allowZoom]="false"
    useSketchLayer="true"
    (mapLoaded)="onMapLoaded()"
    (sketchLayerReady)="onSketchLayerReady()"
  ></app-esri-map>
</div>

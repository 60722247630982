<app-navbar [client]="null"></app-navbar>

<div class="text-center" style="margin-top: 10rem;">
  <h2>Not Found</h2>
  <p>
    The page you were looking for could not be found...
  </p>

  <a [routerLink]="['/']" class="btn btn-raised btn-primary mt-2">Go Home</a>
</div>

import { Component, OnInit } from '@angular/core';
import { CardpointePaymentProcessorConfiguration } from '../../../models/payment-providers/cardpointe/cardpointe-payment-processor-configuration';
import { PaymentProcessorConfigBaseComponent } from '../payment-processor-config-base/payment-processor-config-base.component';
import { UntypedFormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'wm-payment-processor-config-cardpointe',
  templateUrl: './payment-processor-config-cardpointe.component.html',
  styleUrls: ['./payment-processor-config-cardpointe.component.css']
})
export class PaymentProcessorConfigCardpointeComponent
  extends PaymentProcessorConfigBaseComponent
  implements OnInit {
  processorConfig: CardpointePaymentProcessorConfiguration;

  constructor(fb: UntypedFormBuilder) {
    super(fb);
  }

  ngOnInit() {
    this.addValidation(
      'tokenURL',
      Validators.required,
      this.processorConfig.tokenURL
    );
  }
}

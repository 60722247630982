<wm-modal-confirm
  #editAppNumber
  commandText="Save"
  [buttonText]="linkText"
  [showButtonText]="true"
  [showButton]="showButton"
  title="Edit Application Number"
  (accepted)="saveApplicationNumber()"
  (canceled)="reset()"
  [buttonStyle]="buttonStyles.Link"
  class="ml-2"
  [form]="editAppForm"
  [closeOnAccept]="false"
  *ngIf="editAppForm"
>
  <div *ngIf="editAppForm" [formGroup]="editAppForm">
    <div class="form-group">
      <label class="bmd-label-floating" for="newApplicationNumber">
        Application Number
      </label>
      <input
        type="text"
        class="form-control"
        formControlName="newApplicationNumber"
        name="newApplicationNumber"
        [(ngModel)]="newApplicationNumber"
      />
      <wm-control-message
        [control]="editAppForm.controls['newApplicationNumber']"
      ></wm-control-message>
    </div>
  </div>
  <p *ngIf="!clientApplicationNumber" class="text-danger">
    WARNING: The number you are editing is the system generated number. The
    application has not reached the point of generating a number, or it is not
    configured to generate a specific application number. Changing this value
    now will override any prefix and incremented number logic that may be
    generated later on in the lifecycle of this application.
  </p>
  <p *ngIf="showExistingNumberWarning" class="text-warning">
    {{ newApplicationNumber }} is already in use by an application in this
    workflow. You may create a duplicate number but this may cause confusion
    when viewing and modifying applications.<br /><br />Do you want to continue?
  </p>
</wm-modal-confirm>

<div class="row">
  <div class="col">
    <wm-contractor-registration-list
      (recordCountSet)="setRecordCount($event)"
      [id]="id"
      [initialColumns]="columns"
      [staticFilters]="staticFilters"
      (filtersChanged)="filtersChange()"
    >
    </wm-contractor-registration-list>
  </div>
</div>

import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ViewChildren,
  QueryList,
  Output,
  EventEmitter,
  AfterViewInit,
  OnDestroy
} from '@angular/core';
import {
  DataEntityLayoutModel,
  ActivityModel,
  Activity
} from '../../../../models/activities';
import { UntypedFormGroup } from '@angular/forms';
import { DataEntityInputComponent } from '../data-entity-input/data-entity-input.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'wm-data-entity-input-list',
  templateUrl: './data-entity-input-list.component.html',
  styleUrls: ['./data-entity-input-list.component.css']
})
export class DataEntityInputListComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() entities: DataEntityLayoutModel[];
  @Input() form: UntypedFormGroup;
  @Input() activity: Activity<ActivityModel>;
  @Input() applicationId: string;
  @Output() calculateActivity: EventEmitter<string> = new EventEmitter();
  calculateSubscription: Subscription;

  @ViewChildren('dataEntityInput')
  dataEntityInputCtls: QueryList<DataEntityInputComponent>;

  constructor() {}
  ngOnDestroy(): void {
    if(this.calculateSubscription) {
      this.calculateSubscription.unsubscribe();
    }
  }
  ngAfterViewInit(): void {
    if (this.dataEntityInputCtls) {
      this.dataEntityInputCtls.forEach(dataEntityInputCrl => {
        this.calculateSubscription = dataEntityInputCrl.calculateActivity.subscribe(e => {
          this.calculateActivity.emit(e);
        });
      });
    }
  }

  async persistChildComponent() {
    const p: Promise<any>[] = [];
    this.dataEntityInputCtls.forEach(dataEntityInputCrl => {
      p.push(dataEntityInputCrl.persistChildComponent());
    });
    return Promise.all(p);
  }
  ngOnInit() {}
}

<form [formGroup]="form" *ngIf="config && form">
  <div class="form-group">
    <label class="bmd-label-floating" for="ttid">
      TTID
    </label>
    <input
      type="text"
      class="form-control"
      formControlName="ttid"
      name="ttid"
      [(ngModel)]="config.ttid"
    />
  </div>

  <div class="form-group">
    <label class="bmd-label-floating" for="duplicateNumber">
      Duplicate Number Field
    </label>
    <div class="switch">
      <label>
        <input
          type="checkbox"
          formControlName="duplicateNumber"
          name="duplicateNumber"
          [(ngModel)]="config.duplicateNumber"
        />
      </label>
    </div>
  </div>
  <div class="form-group">
    <label class="bmd-label-floating" for="parentAccountId">
      Parent Account
    </label>
    <select
      class="form-control"
      name="parentAccountId"
      formControlName="parentAccountId"
      [(ngModel)]="account.parentAccountId"
    >
      <option></option>
      <option *ngFor="let pa of parentAccounts" [value]="pa.id">{{
        pa.name
      }}</option>
    </select>
  </div>
</form>

import {
  Component,
  Input,
  OnInit,
  ViewChild,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import {
  ExportEntityOption,
  ItemSearchFilter,
  ItemSearchOptionField
} from 'src/app/components/filter-list/models/filterClasses';
import { ModalConfirmComponent } from 'src/app/components/system/modal-confirm/modal-confirm.component';
import {
  FilterBuilderOutput,
  FilterBuilderParam
} from 'src/app/models/filter-builder';
import { ColumnOption } from 'src/app/models/grid-settings';
import {
  ScheduledExport,
  ShapefileValidationCriteria
} from 'src/app/models/scheduled-export';
import { DataService, WorkflowContextService } from 'src/app/services';
import { ScheduledExportEditorComponent } from '../scheduled-export-editor/scheduled-export-editor.component';

@Component({
  selector: 'wm-scheduled-exports',
  templateUrl: './scheduled-exports.component.html',
  styleUrls: ['./scheduled-exports.component.css']
})
export class ScheduledExportsComponent implements OnInit, OnChanges {
  @Input() componentId: string;
  @Input() title: string;
  @Input() params: FilterBuilderParam[];
  @Input() currentFilters: FilterBuilderOutput | ItemSearchOptionField[];
  @Input() staticFilters: ItemSearchOptionField[] | ItemSearchFilter;
  @Input() currentColumnOptions: ColumnOption[];
  @Input() filterForm: UntypedFormGroup;
  @Input() form: UntypedFormGroup;
  @Input() shapefileValidationCriteria: ShapefileValidationCriteria;

  @ViewChild('scheduledExportEditor', { static: false })
  scheduledExporteditor: ScheduledExportEditorComponent;
  @ViewChild('newScheduledExportEditor', { static: false })
  newScheduledExportEditor: ScheduledExportEditorComponent;

  @ViewChild('scheduledExportModal', { static: true })
  scheduledExportModal: ModalConfirmComponent;
  @ViewChild('newScheduledExportModal', { static: false })
  newScheduledExportModal: ModalConfirmComponent;

  scheduledExports: ScheduledExport[];
  selectedScheduledExport: ScheduledExport;
  runExistingEditor = false;
  runNewEditor = false;

  constructor(
    private _context: WorkflowContextService,
    private _dataSvc: DataService,
    private _fb: UntypedFormBuilder
  ) {}
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.componentId) {
      this.getScheduledExportList();
    }
  }

  ngOnInit() {
    this.getScheduledExportList();

    // create an empty form group to be populated by children
    this.form = this._fb.group({});
  }

  saveScheduledExport(isNew: boolean) {
    if (isNew) {
      this.newScheduledExportEditor.save();
    } else {
      this.scheduledExporteditor.save();
    }
  }

  deleteScheduledExport() {
    this.scheduledExporteditor.delete();
  }

  handleSave() {
    this.destroyEditors();
    this.getScheduledExportList();

    // deleting is the modal confirm custom button,
    // which is not paired with any particular
    // modalRef actions. This only affects this.scheduledExportModal
    if (this.scheduledExportModal && this.scheduledExportModal.modalRef) {
      this.scheduledExportModal.modalRef.close();
    }
  }

  getScheduledExportList() {
    this._dataSvc
      .getScheduledExports(this._context.client.id, this.componentId)
      .subscribe(response => {
        this.scheduledExports = response.scheduledExports;
        this.scheduledExports = this.scheduledExports.slice();
      });
  }

  openModalForExisting(scheduledExport: ScheduledExport) {
    this.destroyEditors();

    this.selectedScheduledExport = scheduledExport;
    this.runExistingEditor = true;
    this.scheduledExportModal.open();
  }

  openModalForNew() {
    this.destroyEditors();

    this.runNewEditor = true;
    this.newScheduledExportModal.open();
  }

  destroyEditors() {
    this.runExistingEditor = false;
    this.runNewEditor = false;
  }
}

import { WorkflowService } from './../../../../../services/workflow.service';
import { WorkflowContextService } from 'src/app/services';
import { DragulaService } from 'ng2-dragula';
import { UntypedFormBuilder } from '@angular/forms';
import { FormActivityEditorComponent } from './../../form-activity-editor/form-activity-editor.component';
import {
  Component,
  OnInit,
  Inject,
  forwardRef,
  ChangeDetectorRef
} from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'wm-inspection-schedule-activity-editor',
  templateUrl: './inspection-schedule-activity-editor.component.html',
  styleUrls: ['./inspection-schedule-activity-editor.component.css']
})
export class InspectionScheduleActivityEditorComponent
  extends FormActivityEditorComponent
  implements OnInit {
  constructor(
    @Inject(forwardRef(() => WorkflowService)) workflowSvc: WorkflowService,
    @Inject(forwardRef(() => WorkflowContextService))
    context: WorkflowContextService,
    fb: UntypedFormBuilder,
    dragulaSvc: DragulaService,
    ref: ChangeDetectorRef,
    toastr: ToastrService
  ) {
    super(workflowSvc, context, toastr, fb, dragulaSvc, ref);
  }

  ngOnInit() {}
}

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MapService } from '../../../models';
import { Router } from '@angular/router';
import { WorkflowContextService } from 'src/app/services';

@Component({
  selector: 'wm-map-service-list',
  templateUrl: './map-service-list.component.html',
  styleUrls: ['./map-service-list.component.css']
})
export class MapServiceListComponent implements OnInit {
  @Input() mapServices: MapService[];
  @Input() allowSelect = true;
  @Input() showEdit = true;
  @Output() removed = new EventEmitter();

  constructor(
    private _router: Router,
    private context: WorkflowContextService
  ) {}

  editMapService(mapService: MapService) {
    if (this.allowSelect || this.showEdit) {
      this._router.navigate([
        '/admin/jurisdiction',
        this.context.client.id,
        'settings',
        'maps',
        'edit',
        mapService.id
      ]);
    }
  }

  ngOnInit() {}
}

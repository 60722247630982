import { Router } from '@angular/router';
import {
  Component,
  OnInit,
  Input,
  SimpleChange,
  OnChanges,
  Pipe,
  Injectable,
  PipeTransform
} from '@angular/core';
import { Client } from '../../../models';
import { WorkflowContextService, ClientService } from '../../../services';

@Pipe({
  name: 'clientStateFilter'
})
@Injectable()
export class ClientStateFilter implements PipeTransform {
  transform(clients: Client[], fullState: string): any {
    return clients.filter(
      client => client.fullState.toLowerCase() === fullState.toLowerCase()
    );
  }
}

@Component({
  selector: 'wm-client-select',
  templateUrl: './client-select.component.html',
  styleUrls: ['./client-select.component.css']
})
export class ClientSelectComponent implements OnInit, OnChanges {
  @Input() clients: Client[];

  clientStates: string[];
  filteredClients: Client[];
  selectedClient: Client;
  selectedState: string;

  constructor(
    public context: WorkflowContextService,
    private _clientSvc: ClientService,
    private _router: Router
  ) {}

  changeClient(value: number) {
    const client: Client = this.clients ? this.clients[value] : null;
    this._clientSvc.changeClient(client);
  }

  ngOnChanges(changes: { [propertyName: string]: SimpleChange }) {
    for (const propName in changes) {
      if (propName === 'clients') {
        this.buildStates();
        this.filteredClients = this.clients;
        break;
      }
    }
  }

  sort(clients: Client[]): Client[] {
    return clients.sort((a, b) => {
      if (a.name > b.name) {
        return 1;
      } else if (a.name < b.name) {
        return -1;
      } else {
        return 0;
      }
    });
  }

  navigateToLandingPage(clientId: string) {
    this._router.navigate(['/jurisdiction', clientId]);
  }

  buildStates() {
    // get unique list of states for clients;
    const states: string[] = [];

    if (this.clients) {
      for (const client of this.clients) {
        if (!states.includes(client.fullState)) {
          states.push(client.fullState);
        }
      }
    }

    // Initialize variables
    this.clientStates = states.sort();
  }

  filterClients(value: string = '') {
    const clients: Client[] = [];

    if (this.clients) {
      for (const client of this.clients) {
        if (client.fullState === value) {
          clients.push(client);
        } else if (value === '') {
          clients.push(client);
        }
      }
    }

    this.filteredClients = clients;
  }

  ngOnInit() {
    this.selectedState = '';
    this.changeClient(-1); // notify that no client is selected
  }
}

import { Client } from './client';
import { RoleMember } from './role-member';
import { Permission } from '.';

export class Role {
  id: string;
  name: string;
  color?: string;
  client: Partial<Client>;
  clientId?: string;
  members: Partial<RoleMember>[];
  actions: Partial<Permission>[];

  constructor(options?: Partial<Role>) {
    this.members = [];
    this.actions = [];

    if (options) {
      Object.assign(this, options);
    }
  }
}

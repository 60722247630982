import { Workflow } from '.';

export class WorkflowCheckpoint {
  id: string;
  workflowID: string;
  workflow: Workflow;
  createdOn: string;
  createdBy: string;
  notes: string;
  publishedWorkflow: string;
  draftWorkflow: string;

  constructor(options?: Partial<WorkflowCheckpoint>) {
    if (options) {
      Object.assign(this, options);
    }
  }
}

<div
  class="bmd-layout-container bmd-drawer-f-l"
  [ngClass]="{
    'bmd-drawer-overlay': isMobileDevice,
    'bmd-drawer-out': isMobileDevice && !menuOpen,
    'bmd-drawer-in': !isMobileDevice || menuOpen
  }"
>
  <header class="bmd-layout-header">
    <nav
      *ngIf="isMobileDevice"
      class="navbar navbar-expand-lg navbar-light bg-white"
    >
      <button
        *ngIf="isMobileDevice"
        (click)="menuOpen = !menuOpen"
        class="navbar-toggler mr-3"
        id="sidebar-toggler"
        type="button"
      >
        <span class="sr-only">Toggle drawer</span>
        <i class="material-icons">menu</i>
      </button>
      <a class="navbar-brand mr-auto" *ngIf="context?.client?.name">
        {{ context.client.name }}
      </a>
    </nav>
  </header>
  <div id="dw-app" class="bmd-layout-drawer bg-faded">
    <header>
      <a class="navbar-brand" *ngIf="context.client">
        <span *ngIf="!context.client.logoURL">{{ context.client.name }}</span>
        <img
          *ngIf="context.client.logoURL"
          [src]="context.client.logoURL"
          [alt]="context.client.name"
          style="max-height: 150px;"
          class="img-fluid"
        />
      </a>
      <div class="pt-3 pl-4">
        <!-- style="padding-left: 35px" -->
        <h4>{{ workflowName }}</h4>

        <h6 class="text-muted pb-3">
          <span class="float-left" style="width: 92%;">
            {{ applicationNumber }}
          </span>

          <span
            class="float-right"
            style="width: 8%"
            *ngIf="applicationNumber && canEditApplicationNumber"
          >
            <wm-edit-application-number
              linkText="Edit"
              [applicationId]="applicationId"
              [applicationNumber]="applicationNumber"
              [clientApplicationNumber]="clientApplicationNumber"
              (numberChanged)="applicationNumber = $event"
            ></wm-edit-application-number>
          </span>
        </h6>

        <wm-modal-confirm
          #newDocument
          commandText=""
          buttonText="View Summary"
          [showButtonText]="true"
          [showButton]="true"
          title="Summary"
          (opened)="retrieveSummary()"
          [buttonStyle]="buttonStyles.Button"
          *ngIf="(applicationNumber || '') !== ''"
        >
          <app-loader *ngIf="!summaryActivities"></app-loader>
          <a
            *ngIf="summaryActivities"
            [routerLink]="[
              '/application/workflow-application-print-summary',
              context.applicationId
            ]"
            target="_blank"
            >Printer Friendly</a
          >
          <div
            ngbDropdown
            class="d-inline-block float-right"
            *ngIf="canViewVersion"
          >
            <button
              class="btn btn-raised btn-primary"
              id="dropdownBasic1"
              ngbDropdownToggle
            >
              Actions
            </button>
            <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
              <a ngbDropdownItem (click)="openApplicationWorkflowBuilder()"
                >View Application Workflow</a
              >
            </div>
          </div>
          <wm-application-readonly
            *ngIf="summaryActivities"
            [applicationId]="context.applicationId"
            [activities]="summaryActivities"
            [isTestApplication]="isTestApplication"
          ></wm-application-readonly>
        </wm-modal-confirm>

        <wm-modal-confirm
          #newDocument
          [commandText]="canEditNotes ? 'Save' : null"
          buttonText="Notes"
          [showButtonText]="true"
          [showButton]="true"
          title="Notes"
          (opened)="retrieveApplication().subscribe()"
          (accepted)="saveNotes()"
          [buttonStyle]="buttonStyles.Button"
          *ngIf="applicationNumber"
          class="ml-2"
        >
          <app-loader *ngIf="!application"></app-loader>
          <ng-container *ngIf="application">
            <ul
              ngbNav
              #modalNotes="ngbNav"
              [destroyOnHide]="false"
              class="nav-tabs"
            >
              <li ngbNavItem="modalExternalNotes">
                <a ngbNavLink>External Notes</a>
                <ng-template ngbNavContent>
                  <wm-application-external-note-editor
                    [application]="application"
                  ></wm-application-external-note-editor>
                </ng-template>
              </li>
              <li ngbNavItem="modalInternalNotes" *ngIf="canEditNotes">
                <a ngbNavLink>Internal Notes</a>
                <ng-template ngbNavContent>
                  <ng-container *ngIf="canEditNotes">
                    <wm-application-internal-note-editor
                      [application]="application"
                    ></wm-application-internal-note-editor>
                  </ng-container>
                </ng-template>
              </li>
            </ul>
            <div [ngbNavOutlet]="modalNotes"></div>
          </ng-container>
        </wm-modal-confirm>
        <!-- <p>
        </p> -->
      </div>
    </header>

    <wm-workflow-application-navigation
      *ngIf="navigationItems"
      [currentActivity]="activity"
      [navigationItems]="navigationItems"
      [applicationId]="context.applicationId"
      (onNavigate)="navigate($event)"
      (switchApplication)="loadApplication($event)"
      class="mb-auto sidebar-child"
    ></wm-workflow-application-navigation>

    <wm-workflow-application-fee-summary
      *ngIf="hasFees && fees"
      [currentActivity]="activity"
      [applicationId]="context.applicationId"
      class="sidebar-child"
      [fees]="fees"
    ></wm-workflow-application-fee-summary>
  </div>
  <main
    class="bmd-layout-content"
    style="position: fixed; top: 0;bottom: 60px; right: 0;left:0"
    (click)="mainClick()"
    [ngClass]="{
      'overlay-bg': menuOpen && isMobileDevice,
      'mobile-top-margin': isMobileDevice
    }"
  >
    <div class="container-fluid px-4">
      <wm-application-input
        (onActivityChanged)="changeActivity($event)"
        (onIsCompleteChanged)="isLastActivity = $event"
        (onShowNextChanged)="canUseNextChanged($event)"
        (onShowPreviousChanged)="canUsePrevious = $event"
        (onShowSaveChanged)="canUseSave = $event"
        (onNavigationChanged)="navigationItems = $event"
        (onFeesChanged)="fees = $event"
        (onHasFeesChanged)="hasFees = $event"
        (switchApplication)="loadApplication($event)"
        [applicationId]="applicationId"
        [workflowId]="workflowId"
        (CanVoidDiscardChanged)="canVoidChanged($event)"
        [isTestApplication]="isTestApplication"
        (applicationIsProcessing)="changeButtonAvailability($event)"
        (loadApplication)="loadApplication(applicationId)"
        [shouldShowSave]="shouldShowSave"
        [isLastActivity]="isLastActivity"
      ></wm-application-input>
    </div>
  </main>
</div>

<nav
  id="bottom-nav"
  class="navbar fixed-bottom navbar-expand-lg navbar-light bg-white"
>
  <div class="container-fluid">
    <!-- left buttons -->
    <form class="form-inline">
      <button
        class="btn btn-raised mx-2 btn-primary"
        type="button"
        *ngIf="shouldShowPrevious"
        (click)="previous()"
        [disabled]="!canUsePrevious || applicationProcessing"
      >
        <span *ngIf="!isMobileDevice">Back</span>
        <i class="material-icons" *ngIf="isMobileDevice">
          arrow_back
        </i>
      </button>
      <div *ngIf="shouldShowCancel">
        <wm-modal-confirm
          *ngIf="!loadingDiscard"
          moduleId="cancel_application"
          [btnIcon]="isMobileDevice ? 'delete' : undefined"
          [buttonText]="isMobileDevice ? undefined : 'Discard'"
          btnColor="danger"
          [showButtonText]="true"
          [title]="'Discard Application'"
          [commandText]="'Discard Application'"
          (accepted)="cancel()"
          [enabled]="
            !isTestApplication && canUseCancelDiscard && !applicationProcessing
          "
        >
          <p>Are you sure you want to cancel this application?</p>

          <p>Canceling this application will do the following:</p>
          <ul>
            <li>
              Discard all data entered for this application and require you to
              start over.
            </li>
          </ul>
        </wm-modal-confirm>

        <button
          *ngIf="loadingDiscard"
          class="btn btn-raised btn-danger"
          disabled
        >
          <span
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>

          <span *ngIf="!isMobileDevice"> Loading...</span>
        </button>
      </div>
      <div *ngIf="shouldShowVoid" class="mx-2 ml-2">
        <wm-modal-confirm
          moduleId="cancel_application"
          [btnIcon]="isMobileDevice ? 'delete' : undefined"
          [buttonText]="isMobileDevice ? undefined : 'Void'"
          btnColor="danger"
          [showButtonText]="true"
          [title]="'Void Application'"
          [commandText]="'Void Application'"
          (accepted)="void()"
          [enabled]="
            !isTestApplication && canUseCancelDiscard && !applicationProcessing
          "
          [form]="voidForm"
        >
          <div [formGroup]="voidForm">
            <div class="row">
              <div class="col">
                <label for="voidReason">Void Reason</label>
                <textarea
                  style="height:100px"
                  id="voidReason"
                  name="voidReason"
                  class="form-control mx-2"
                  formControlName="voidReason"
                  placeholder="Void Reason"
                  aria-label="Void Reason"
                >
                </textarea>
                <div *ngIf="voidReason.untouched" class="text-danger">
                  * Void Reason is required.
                </div>
                <div
                  *ngIf="
                    voidReason.invalid &&
                    (voidReason.dirty || voidReason.touched)
                  "
                  class="alert alert-danger"
                >
                  <div
                    *ngIf="voidReason.errors['required']"
                    class="text-danger"
                  >
                    * Void Reason is required. *
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <label for="voidDateTime" class="mt-2"
                  >Void Time
                  <input
                    id="voidDateTime"
                    name="voidDateTime"
                    class="form-control mt-2"
                    formControlName="voidDateTime"
                    placeholder=""
                    aria-label="DateTime"
                  />
                </label>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <label class="mt-2">
                  Voided By
                  <input
                    name="voidedBy"
                    class="form-control mt-2"
                    formControlName="voidedBy"
                    placeholder=""
                    aria-label="VoidedBy"
                  />
                </label>
              </div>
            </div>
          </div>

          <p>Are you sure you want to void this application?</p>

          <p>Voiding this application will do the following:</p>
          <ul>
            <li>
              Mark the application as Voided. Please provide a reason for
              voiding this application.
            </li>
          </ul>
        </wm-modal-confirm>
      </div>
    </form>

    <!-- center buttons -->
    <form class="form-inline ml-auto" *ngIf="isTestApplication">
      <label for="viewAsRole" class="bmd-label-floating">
        Role
      </label>
      <select
        class="form-control mx-2"
        [(ngModel)]="selectedRole"
        (change)="changeRole($event)"
        *ngIf="testRoles"
        name="viewAsRole"
      >
        <option *ngFor="let role of testRoles" [value]="role.id">
          {{ role.name }}
        </option>
      </select>
      <button
        class="btn btn-raised mx-2 btn-danger"
        type="button"
        (click)="exitTestApplication()"
        [disabled]="loadingExit || applicationProcessing"
      >
        <span *ngIf="!loadingExit">Exit Test</span>

        <span *ngIf="loadingExit">
          <span
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
          Loading...
        </span>
      </button>
    </form>

    <!-- right buttons -->
    <form class="form-inline ml-auto">
      <div
        ngbDropdown
        display="dynamic"
        placement="top-right"
        class="d-inline-block mx-2"
        *ngIf="shouldShowSave"
      >
        <button
          class="btn btn-raised btn-success"
          id="dropdownMenuButton"
          ngbDropdownToggle
          [disabled]="!canUseSave || loadingSave || applicationProcessing || !appInputLoaded"
        >
          <i class="material-icons" *ngIf="isMobileDevice && !loadingSave">
            {{ activity && activity.canEdit ? 'save' : 'exit' }}
          </i>
          <span
            *ngIf="loadingSave"
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>

          <span *ngIf="!isMobileDevice && !loadingSave">
            {{ activity && activity.canEdit ? 'Save & Exit' : 'Exit' }}
          </span>
          <span *ngIf="!isMobileDevice && loadingSave">
            Loading...
          </span>
        </button>
        <div ngbDropdownMenu aria-labelledby="dropdownMenuButton">
          <button ngbDropdownItem (click)="save(false)">Save</button>
          <button ngbDropdownItem (click)="save()">
            Save & Exit
          </button>
        </div>
      </div>
      <button
        class="btn btn-raised btn-success"
        [disabled]="processing || applicationProcessing"
        *ngIf="!shouldShowSave"
        (click)="navigateToReferrer()"
      >
        Exit
      </button>

      <button
        class="btn btn-raised mx-2 btn-primary"
        type="button"
        *ngIf="shouldShowNext && activity?.type !== 'wait-activity'"
        (click)="next()"
        [disabled]="!canUseNext || applicationProcessing || !appInputLoaded"
      >
        <i class="material-icons" *ngIf="isMobileDevice">
          arrow_forward
        </i>
        <span *ngIf="!isMobileDevice">
          {{ !shouldShowSave ? 'Update' : isLastActivity ? 'Submit' : 'Next' }}
        </span>
      </button>
    </form>
  </div>
</nav>

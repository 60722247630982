import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { User } from '../../../models';
import { Observable } from 'rxjs';
import { UntypedFormControl } from '@angular/forms';
import { SecurityService } from '../../../services';
import {
  filter,
  switchMap,
  debounceTime,
  distinctUntilChanged
} from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'wm-associate-user',
  templateUrl: './associate-user.component.html',
  styleUrls: ['./associate-user.component.css']
})
export class AssociateUserComponent implements OnInit {
  @Output() userAssociated: EventEmitter<User> = new EventEmitter<User>();
  @Output() canceled: EventEmitter<any> = new EventEmitter<any>();
  @Input() label: string;

  users: Observable<User[]>;
  searchText = new UntypedFormControl();
  selectedUser: User = null;
  showList = false;

  searchText$: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    private _securitySvc: SecurityService,
    private toastr: ToastrService
  ) {
    this.users = this.searchText.valueChanges
      .pipe(
        filter((val: string) => {
          return val && val.length > 1;
        })
      )
      .pipe(
        switchMap((val: string) => {
          return this._securitySvc.searchUsers(null, { searchText: val });
        })
      );
  }

  ngOnInit() {
    if (!this.label) {
      this.label = 'User';
    }
    this.users.subscribe(users => {
      if (users) {
        if (users.length > 0) {
          this.showList = true;
        }
      }
    });
  }

  formatter = (x: User) => x.name;

  search = (text$: Observable<string>) => {
    return text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap(term => {
        return this._securitySvc.searchUsers(null, { searchText: term });
      })
    );
  }

  associateUser(e, user: User, input) {
    e.preventDefault();
    this.userAssociated.next(user);
    this.selectedUser = null;
    input.value = '';
    this.searchText.updateValueAndValidity();
  }

  cancel() {
    this.searchText.updateValueAndValidity();
    this.selectedUser = null;
    this.showList = false;
    this.canceled.next(null);
  }
}

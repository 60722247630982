import { Component, OnInit } from '@angular/core';
import { ActivityView } from '../../../../../views/master-views/app.view/app.view.component';
import { InspectionActivityModel } from 'src/app/models/activities/inspection-request-activity';
import { InspectionSimpleActivity } from 'src/app/models/activities/inspection-simple-activity';

@Component({
  selector: 'wm-inspection-simple-activity-view',
  templateUrl: './inspection-simple-activity-view.component.html',
  styleUrls: ['./inspection-simple-activity-view.component.css']
})
export class InspectionSimpleActivityViewComponent extends ActivityView
  implements OnInit {
  activity: InspectionSimpleActivity;
  model: InspectionActivityModel;
  constructor() {
    super();
  }

  ngOnInit() {}
}

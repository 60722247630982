import { ClientStateFilter } from './../components/client/client-select/client-select.component';
import {
  ActivityStatusPipe,
  ApplicationStatusPipe
} from './application-status.pipe';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharePermissionsPipe } from './share-permission.pipe';
import { RegistrationStatusPipe } from './registration-status.pipe';
import { AgendaQueueItemStatusPipe } from './agenda-queue-item-status.pipe';
import { DynamicFilterPipe } from './dynamic-filter';

@NgModule({
  imports: [CommonModule],
  declarations: [
    ApplicationStatusPipe,
    RegistrationStatusPipe,
    ClientStateFilter,
    SharePermissionsPipe,
    AgendaQueueItemStatusPipe,
    ActivityStatusPipe,
    DynamicFilterPipe
  ],
  providers: [
    ApplicationStatusPipe,
    RegistrationStatusPipe,
    ClientStateFilter,
    SharePermissionsPipe,
    AgendaQueueItemStatusPipe,
    ActivityStatusPipe,
    DynamicFilterPipe
  ],
  exports: [
    ApplicationStatusPipe,
    RegistrationStatusPipe,
    ClientStateFilter,
    SharePermissionsPipe,
    AgendaQueueItemStatusPipe,
    ActivityStatusPipe,
    DynamicFilterPipe
  ]
})
export class PipesModule {}

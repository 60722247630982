import { ToastrService } from 'ngx-toastr';
import { DataService } from './../../../services/data.service';
import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { WorkflowContextService } from 'src/app/services';

@Component({
  selector: 'app-custom-lists-list',
  templateUrl: './custom-lists-list.component.html',
  styleUrls: ['./custom-lists-list.component.css']
})
export class CustomListsListComponent implements OnInit {
  @Input() customLists: any[];

  constructor(
    private _router: Router,
    public _context: WorkflowContextService,
    private _service: DataService,
    private toastr: ToastrService
  ) {}

  ngOnInit() {}

  editCustomList(list: any) {
    this._router.navigate([
      '/admin/jurisdiction/',
      this._context.client.id,
      'settings',
      'lists',
      'edit',
      list.id
    ]);
  }
}

<div class="mt-4">
  <app-loader *ngIf="!activity || isNavigating"></app-loader>
  <div class="text-center" *ngIf="isProcessing">
    Application is currently processing...
  </div>
  <div class="text-center" *ngIf="applicationProcessing">
    <p>
      Cannot submit the activity at this time because another action is already
      in progress.
    </p>
    <p>
      You will be returned to the activity when the action is finished...
    </p>
    <app-loader></app-loader>
  </div>
  <div class="row" *ngIf="!applicationProcessing">
    <div class="col">
      <div *ngIf="activity && showActivity">
        <wm-activity-container
          [activity]="activity"
          [isPreview]="false"
          [isTestApplication]="isTestApplication"
          [form]="form"
          [applicationId]="applicationId"
          (saved)="saved($event)"
          (goPrevious)="goPrevious($event)"
          (committed)="committed($event).subscribe()"
          (onShouldShowNext)="onShowNextChanged.emit($event)"
          (onShouldShowSave)="onShowSaveChanged.emit($event)"
          (onShouldShowPrevious)="onShowPreviousChanged.emit($event)"
          (onShouldShowCancel)="onShowCancelChanged.emit($event)"
          (switchApplication)="switchApplication.emit($event)"
          (onViewDesigner)="viewDesigner($event)"
        >
        </wm-activity-container>

        <div class="form-group" *ngIf="statusMessage">
          <div class="text-success">{{ statusMessage }}</div>
        </div>

        <!-- <div *ngIf="isComplete" class="form-group">
          <a
            class="link"
            [routerLink]="[
              '/application/workflow-application/read-only/',
              applicationId,
              isTestApplication || false
            ]"
            >View Application Summary</a
          >
        </div> -->
      </div>
    </div>
  </div>
</div>

import { Client } from './client';
import { ContactAddress, UserEmailAddress, ContactEmailAddress } from '.';

export class Contact {
  id: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  emailAddressId: string;
  emailAddress: Partial<ContactEmailAddress>;
  addressId: string;
  address: Partial<ContactAddress>;
  title: string;
  officeHours: string;
  position: number;
  client: Partial<Client>;
  constructor(options?: Partial<Contact>) {
    if (options) {
      Object.assign(this, options);
    }
  }
}

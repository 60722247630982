import { ToastrService } from 'ngx-toastr';
import { Utilities } from './utilities/index';
import { Observable } from 'rxjs';
import { ErrorHandler, Inject, Injectable, Injector } from '@angular/core';
import * as Sentry from '@sentry/browser';
import SentryFullStory from '@sentry/fullstory';
import { environment } from 'src/environments/environment';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent
} from '@angular/common/http';
import { GPInternalHandler } from './gp-error';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler, HttpInterceptor {
  private _handler: GPInternalHandler;

  constructor(@Inject(Injector) injector: Injector) {
    this._handler = new GPInternalHandler(injector);
    // only initialize Sentry if the host isn't localhost
    if (!window.location.host.includes('localhost')) {
      this.initSentry();
    }
  }

  private initSentry() {
    Sentry.init({
      dsn: 'https://ab80f8ee1f66415b9ba2dfccd1ce054d@sentry.io/1761183',
      environment: environment.versions.branch,
      release: environment.versions.revision,
      ignoreErrors: ['Non-Error exception captured'],
      integrations: [new SentryFullStory('schneider-geospatial')],
      beforeSend(event) {
        try {
          if (event.extra.__serialized__.error === 'ProgressEvent') {
            return null;
          }
        } catch (err) {}

        return event;
      }
    });
  }

  handleError(error: any) {
    this._handler.handleError(error);
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const transactionId = Utilities.generateId();

    request = request.clone({
      setHeaders: {
        'x-transaction-id': transactionId
      }
    });

    return next.handle(request);
  }
}

<form [formGroup]="form">
  <div class="form-group">
    <label class="bmd-label-floating" for="soapURL">
      SOAP URL
    </label>
    <input
      type="text"
      class="form-control"
      formControlName="soapURL"
      name="soapURL"
      [(ngModel)]="eGOVConfig.soapurl"
    />
  </div>
</form>

<div class="card my-3">
  <div class="card-body" *ngIf="workflow">
    <h5 class="card-title">{{ workflow.version.name }}</h5>

    <p>{{ workflow.version.description }}</p>

    <button
      class="btn btn-raised btn-primary"
      [disabled]="saving || !workflow.isEnabled || !workflow.isPublished"
      (click)="startApplication(workflow.id)"
    >
      <span *ngIf="!saving">Start Application</span>

      <span *ngIf="saving">
        <span
          class="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
        Start Application...
      </span>
    </button>
  </div>

  <div class="card-body" *ngIf="application">
    <h5 class="card-title">{{ application.permitType }}</h5>
    <h6 class="card-subtitle mb-3 text-muted">
      {{ application.status | applicationStatus }}
      - {{ application.currentActivityName }}
    </h6>

    <p class="card-text">{{ application.description }}</p>
    <p class="text-muted">
      {{ application.combinedApplicationNumber }} -
      {{ application.dateCreated | date: 'medium' }}
    </p>
    <button
      class="btn btn-primary btn-raised"
      [routerLink]="['/application/workflow-application', application.appId]"
    >
      Continue Application
    </button>
  </div>
</div>

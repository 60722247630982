<div class="row flex">
  <div class="col">
    <div class="form-group">
      <template #entityContainer></template>
      <wm-control-message
        *ngIf="form"
        [control]="form.controls[entity.templateCode]"
      >
      </wm-control-message>
    </div>
  </div>
  <div class="col-2" *ngIf="entity.canUnlock && entity.isLocked">
    <div class="unlock-icon">
      <wm-modal-confirm
        [showCommandText]="true"
        [commandText]="'OK'"
        [showButtonText]="true"
        [showButton]="true"
        [buttonText]="'Unlock'"
        [title]="'Edit Entity Value'"
        [buttonClass]="'btn btn-primary btn-raised'"
        (accepted)="unlockEntity()"
      >
        <p>
          Since the application is completed, we will not be automatically
          recalculating formulas or regenerating documents that are using this
          value.
        </p>
        <p>The following Activities depend on this value.</p>
        <ul class="list-group">
          <li
            class="list-group-item"
            *ngFor="let a of entity.completedDependentActivtyInfo"
          >
            {{ a.activityLabel }}
          </li>
        </ul>
      </wm-modal-confirm>
    </div>
  </div>
</div>

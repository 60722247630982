<form [formGroup]="form">
  <div class="switch">
    <label>
      <input
        type="checkbox"
        formControlName="includeWeekends"
        [(ngModel)]="dateDataEntity.includeWeekends"
      />
      Allow Weekends
    </label>
  </div>
  <div class="switch">
    <label>
      <input
        type="checkbox"
        formControlName="includeTime"
        (change)="includeTimeChanged()"
      />
      Include Time
    </label>
  </div>
  <div
    class="switch"
    *ngIf="dateDataEntity.includeTime && dataEntity.isRequired"
  >
    <label>
      <input
        type="checkbox"
        formControlName="requireTime"
        [(ngModel)]="dateDataEntity.requireTime"
      />
      Require Time
    </label>
  </div>

  <div class="form-group">
    <label class="bmd-label-floating" for="forwardLimit">
      Maximum date limit
    </label>
    <input
      type="number"
      class="form-control"
      [(ngModel)]="dateDataEntity.forwardLimit"
      formControlName="forwardLimit"
      name="forwardLimit"
      min="0"
    />
    <small
      id="forwardLimitHelp"
      class="form-text text-muted"
      *ngIf="
        dateDataEntity.forwardLimit !== null &&
        dateDataEntity.forwardLimit !== undefined
      "
    >
      Limit available dates to {{ dateDataEntity.forwardLimit }} day<span
        *ngIf="dateDataEntity.forwardLimit !== 1"
        >s</span
      >
      in the future.
    </small>
  </div>

  <div class="form-group">
    <label class="bmd-label-floating" for="backwardLimit">
      Minimum date limit
    </label>
    <input
      type="number"
      class="form-control"
      [(ngModel)]="dateDataEntity.backwardLimit"
      formControlName="backwardLimit"
      name="backwardLimit"
      min="0"
    />
    <small
      id="backwardLimitHelp"
      class="form-text text-muted"
      *ngIf="
        dateDataEntity.backwardLimit !== null &&
        dateDataEntity.backwardLimit !== undefined
      "
    >
      Limit available dates to {{ dateDataEntity.backwardLimit }} day<span
        *ngIf="dateDataEntity.backwardLimit !== 1"
        >s</span
      >
      in the past.
    </small>
  </div>

  <wm-external-data-source-config
    [entity]="dataEntity"
    [form]="form"
    (externalSourceUpdated)="externalDataSourceChanged($event)"
  ></wm-external-data-source-config>
</form>

<form [formGroup]="form" *ngIf="entity">
  <br />
  <wm-de-label [entity]="entity" [for]="entity.templateCode"></wm-de-label>
  <app-esri-map
    *ngIf="mapsketchDataEntity.mapConfig.mapServiceId"
    #mapComp
    [mapServiceId]="mapsketchDataEntity.mapConfig.mapServiceId"
    [basemapName]="mapsketchDataEntity.mapConfig.basemapName"
    [initialExtent]="mapsketchDataEntity.mapConfig.initialExtent"
    [mapLayerOptions]="mapsketchDataEntity.mapConfig.layers"
    [showToc]="mapsketchDataEntity.mapConfig.showToc"
    [useSketchLayer]="true"
    (mapSketchDataChanged)="onMapSketchDataChange($event)"
    (mapLoaded)="onMapLoaded($event)"
    (mapExtentChanged)="onMapExtentChanged($event)"
    (mapChanging)="onMapChanging($event)"
    [mapDimensionLayout_class]="mapDimensionLayout_class"
    toolbarMode="sketch"
  ></app-esri-map>

  <p
    *ngIf="
      form.controls['map_loading'].invalid &&
      form.controls['map_loading'].touched
    "
    class="text-red mt-3 font-weight-bold"
  >
    Please wait while the map loads.
  </p>
</form>

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'wm-move-custom-field-info',
  templateUrl: './move-custom-field-info.component.html',
  styleUrls: ['./move-custom-field-info.component.css']
})
export class MoveCustomFieldInfoComponent implements OnInit {
  @Input() hasContractorToRegistrationMove: boolean;
  @Input() hasRegistrationToContractorMove: boolean;

  constructor() {}

  ngOnInit() {}
}

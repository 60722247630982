<app-navbar></app-navbar>

<div class="container" style="padding-top: 56px;">
  <div class="row">
    <div class="col-md-8 offset-md-2 col-lg-4 offset-lg-4">
      <div class="mt-5 mx-auto">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Login</h5>
            <wm-login (loggedIn)="loggedIn($event)"></wm-login>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

import { Component, OnInit } from '@angular/core';
import { ActivityEditorBaseComponent } from '../../activity-editor-base/activity-editor-base.component';

@Component({
  selector: 'wm-review-contractor-registration-activity-editor',
  templateUrl:
    './review-contractor-registration-activity-editor.component.html',
  styleUrls: ['./review-contractor-registration-activity-editor.component.css']
})
export class ReviewContractorRegistrationActivityEditorComponent
  extends ActivityEditorBaseComponent
  implements OnInit {
  constructor() {
    super();
  }

  ngOnInit() {}
}

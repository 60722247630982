<div class="form-group">
  <label for="workflowId" class="bmd-label-static">Child Workflow</label>
  <div>
    <p>
      <span *ngIf="this.activity.model.targetWorkflowName">
        <a
          [routerLink]="[
            '/admin/workflow-builder',
            this.activity.model.targetWorkflowId
          ]"
          routerLinkActive="active"
          target="_blank"
          >{{ activity.model.targetWorkflowName }}</a
        >
      </span>
      <span *ngIf="!this.activity.model.targetWorkflowName">None</span>
      <button class="btn btn-primary btn-sm ml-2" (click)="changeWorkflow()">
        <i class="material-icons">
          edit
        </i>
      </button>

      <button
        class="btn btn-primary btn-sm ml-2"
        (click)="startUpdateWorkflow()"
        [title]="getUpdateTitle()"
        [disabled]="
          activity.mostRecentlyPublishedTargetWorkflowVersionId ===
          activity.model.targetWorkflowVersionId
        "
      >
        <i class="material-icons">
          update
        </i>
      </button>
    </p>
  </div>
</div>

<wm-modal-confirm
  title="Update To Most Recently Published Version"
  [showButton]="false"
  commandText="Confirm"
  (accepted)="updateWorkflow()"
  #updateConfirmationModal
>
  <div>
    Are you sure you want to update the Activity to use the most recently
    published version of {{ activity.model.targetWorkflowName }}?
    <ul>
      <li>
        <span class="text-warning">Warnings</span> related to child Data
        Entities that do not exist on most recently published workflow will
        become
        <span class="text-danger">errors</span>
      </li>
    </ul>
  </div>
</wm-modal-confirm>

<div class="form-group" *ngIf="this.activity.model.targetWorkflowName">
  <p for="editMappings" class="bmd-label-static">Data Mappings</p>

  <wm-activity-details-modal
    [title]="activity.model.screenName + ' Mappings'"
    btnText="Edit Mappings"
    name="editMappings"
    [moduleId]="activity.id"
    [form]="activityForm"
    (saved)="onSave()"
  >
    <!-- Parent To Child Mapping -->
    <div class="card border-secondary mb-3">
      <div class="card-header">
        <h3>
          {{ parentName }}
          <i class="material-icons">
            arrow_right_alt
          </i>
          {{ this.activity.model.targetWorkflowName }}
        </h3>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col"></div>
          <div class="col-auto">
            <button
              class="btn btn-raised btn-primary mx-2"
              (click)="openAddMapping(addParentMappingModal, 'child')"
            >
              Add Mapping
            </button>
            <button
              class="btn btn-raised btn-primary"
              (click)="removeAllMappings('child')"
            >
              Remove All Mappings
            </button>
            <button
              class="btn btn-raised btn-primary float-right  mx-2"
              (click)="startRemoveInvalidMappings('child')"
            >
              Remove Invalid Mappings
            </button>
          </div>
        </div>

        <table class="table">
          <thead>
            <tr>
              <th scope="col">Parent Data Entity</th>
              <th scope="col"></th>
              <th scope="col"></th>
              <th scope="col">Child Data Entity</th>
              <th scope="col"></th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let mapping of activity.model.parentToChildMappings">
              <td
                scope="row"
                class="align-middle"
                [ngClass]="{
                  'text-danger': mapping.isSourceValid == false
                }"
              >
                {{ mapping.sourceDataEntity }}
              </td>
              <td
                class="align-middle center-cell"
                [ngClass]="{
                  'text-danger': mapping.isSourceValid == false
                }"
              >
                <div *ngIf="mapping.isSourceValid == false">
                  invalid
                </div>
              </td>
              <td class="align-middle center-cell">
                <i class="material-icons">
                  arrow_right_alt
                </i>
              </td>

              <!-- three options
                  isTargetValid == false class="text-danger"
                  isPublishedTargetValid ==true && isTargetValid == true
                  isPublishedTargetValid ==false && isTargetValid ==true text-warning
            -->
              <!-- <ng-template  [ngIf]="mapping.isPublishedTargetValid == true">
              <td>element</td>
              <td>
                Warning/error
              </td>
            </ng-template> -->
              <ng-template [ngIf]="mapping.isTargetValid == false">
                <td class="align-middle center-cell text-danger">
                  {{ mapping.targetDataEntity }}
                </td>
                <td class="align-middle center-cell text-danger">
                  invalid
                </td>
              </ng-template>
              <ng-template
                [ngIf]="
                  mapping.isPublishedTargetValid == false &&
                  mapping.isTargetValid == true
                "
              >
                <td class="align-middle center-cell text-warning">
                  {{ mapping.targetDataEntity }}
                </td>
                <td class="align-middle center-cell text-warning">
                  <div>
                    <div>warning - invalid <br />on most recent version</div>
                  </div>
                </td>
              </ng-template>

              <ng-template
                [ngIf]="
                  mapping.isPublishedTargetValid == true &&
                  mapping.isTargetValid == true
                "
              >
                <td>
                  <select
                    class="form-control"
                    [(ngModel)]="mapping.targetDataEntity"
                  >
                    <option
                      *ngFor="let option of childEntityOptions"
                      [ngValue]="option.templateCode"
                    >
                      {{ option.templateCode }}
                    </option>
                  </select>
                </td>
                <td></td>
              </ng-template>

              <td class="align-middle">
                <button
                  class="btn btn-raised btn-primary btn-sm float-right"
                  (click)="deleteMapping(mapping, 'child')"
                >
                  Delete
                </button>
              </td>
            </tr>
            <tr
              *ngIf="
                !activity.model.parentToChildMappings ||
                activity.model.parentToChildMappings.length < 1
              "
            >
              <td>There are no Parent to Child mappings yet</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="card border-secondary mb-3">
      <div class="card-header">
        <h3 class="align-items-center">
          {{ activity.model.targetWorkflowName }}
          <i class="material-icons">
            arrow_right_alt
          </i>
          {{ parentName }}
        </h3>
      </div>
      <div class="card-body">
        <!-- Child To Parent Mapping -->
        <div class="row">
          <div class="col"></div>
          <div class="col-auto">
            <button
              class="btn btn-raised btn-primary mx-2"
              (click)="openAddMapping(addParentMappingModal, 'parent')"
            >
              Add Mapping
            </button>
            <button
              class="btn btn-raised btn-primary mx-2"
              (click)="removeAllMappings('parent')"
            >
              Remove All Mappings
            </button>
            <button
              class="btn btn-raised btn-primary float-right mx-2"
              (click)="startRemoveInvalidMappings('parent')"
            >
              Remove Invalid Mappings
            </button>
          </div>
        </div>
        <table class="table">
          <thead>
            <tr>
              <th scope="col">Child Data Entity</th>
              <th scope="col"></th>
              <th scope="col"></th>
              <th scope="col">Parent Data Entity</th>
              <th scope="col"></th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let mapping of activity.model.childToParentMappings">
              <td
                scope="row"
                class="align-middle"
                [ngClass]="{
                  'text-warning': mapping.isPublishedTargetValid == false,
                  'text-danger': mapping.isTargetValid == false
                }"
              >
                {{ mapping.sourceDataEntity }}
              </td>

              <!-- three options
                  isTargetValid == false class="text-danger"
                  isPublishedTargetValid ==true && isTargetValid == true --nothing
                  isPublishedTargetValid ==false && isTargetValid ==true text-warning
            -->
              <!-- <ng-template  [ngIf]="mapping.isPublishedTargetValid == true">
              <td>element</td>
              <td>
                Warning/error
              </td>
            </ng-template> -->

              <td class="align-middle center-cell">
                <div>
                  <!-- the && part seems weird, but if the most recently published versionId is the same as the targetWorkflowVersionId, then there isn't a need to validate against the recentlyPublishedVersion -->

                  <div
                    class="text-danger"
                    *ngIf="mapping.isTargetValid == false"
                  >
                    invalid
                  </div>
                  <div
                    class="text-warning"
                    *ngIf="
                      mapping.isPublishedTargetValid == false &&
                      mapping.isTargetValid == true
                    "
                  >
                    warning - invalid <br />on most recent version
                  </div>
                </div>
              </td>
              <td style="display:table-cell; vertical-align: middle;">
                <div>
                  <i class="material-icons">
                    arrow_right_alt
                  </i>
                </div>
              </td>

              <td class="align-middle">
                <input
                  id="targetDataEntity"
                  type="text"
                  class="form-control"
                  [(ngModel)]="mapping.targetDataEntity"
                  [ngbTypeahead]="searchParent"
                  [resultFormatter]="formatter"
                />
              </td>
              <td class="align-middle center-cell">
                <span
                  class="text-warning"
                  *ngIf="mapping.isSourceValid == false"
                >
                  A new Data Entity will be created
                </span>
              </td>
              <td class="align-middle">
                <button
                  class="btn btn-raised btn-primary btn-sm float-right"
                  (click)="deleteMapping(mapping, 'parent')"
                >
                  Delete
                </button>
              </td>
            </tr>
            <tr
              *ngIf="
                !activity.model.childToParentMappings ||
                activity.model.childToParentMappings.length < 1
              "
            >
              <td>There are no Child to Parent mappings yet</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </wm-activity-details-modal>
</div>

<wm-modal-confirm
  title="Consider updating to the most recent version."
  [showButton]="false"
  commandText="Proceed"
  (accepted)="removeInvalidMappings()"
  #deleteInvalidConfirmationModal
>
  <p>
    {{ invalidMappingsTextHeader }}
    <button
      class="btn btn-primary btn-sm ml-2"
      (click)="toggleInvalidMappingsText()"
    >
      <i
        class="material-icons"
        style="font-size: 25px; vertical-align: middle;"
        *ngIf="!showInvalidMappingsText"
      >
        keyboard_double_arrow_down
      </i>
      <i
        class="material-icons"
        style="font-size: 25px; vertical-align: middle;"
        *ngIf="showInvalidMappingsText"
      >
        keyboard_double_arrow_up
      </i>
    </button>
  </p>
  <p>
    To update to the most recently published version, you may click CANCEL,
    close the Mappings dialog, then click the
    <i class="material-icons" style="vertical-align: middle;">
      update
    </i>
    button.
  </p>

  <div *ngIf="showInvalidMappingsText">
    Before removing invalid mappings we recommend updating the activity to
    reference the most recently published version of the child workflow.
    <ul>
      <li>
        Data Entities that are <strong>invalid</strong> on this version of the
        child workflow may be valid on the most recently published version.
      </li>
      <li>
        Data Entities that are <strong>valid</strong> on this version of the
        child workflow may be invalid on the most recently published version.
      </li>
    </ul>
  </div>
</wm-modal-confirm>

<ng-template #addParentMappingModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-add-title">
      Add {{ addMappingConfig.sourceName }} to
      {{ addMappingConfig.targetName }} Mapping
    </h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('close')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <form class="modal-body">
    <form>
      <div [formGroup]="dataMappingForm" *ngIf="newMapping">
        <div class="form-row align-items-center">
          <div class="form-group col-md-5 col-sm-12">
            <label for="s" class="bmd-label-static">
              {{ addMappingConfig.sourceName }} Data Entity
            </label>

            <select
              id="addMappingSource"
              name="addMappingSource"
              class="form-control"
              required
              [(ngModel)]="newMapping.sourceDataEntity"
              formControlName="addMappingSource"
              (change)="nameMyMappedDataEntity($event)"
            >
              <option [ngValue]="null" selected disabled>
                Select a Data Entity
              </option>
              <option
                *ngFor="let entity of addMappingConfig.sourceEntities"
                [value]="entity.templateCode"
              >
                {{ entity.templateCode }}
              </option>
            </select>
          </div>

          <div class="col-2 d-none d-md-block text-center">
            <i class="material-icons" style="font-size: 50px;">
              arrow_right_alt
            </i>
          </div>

          <div class="form-group col-md-5 col-sm-12">
            <label for="addMappingTarget" class="bmd-label-static">
              {{ addMappingConfig.targetName }} Data Entity
            </label>
            <select
              name="addMappingTarget"
              class="form-control"
              required
              *ngIf="addMappingConfig.to !== 'parent'"
              [(ngModel)]="newMapping.targetDataEntity"
              formControlName="addMappingTarget"
            >
              <option [ngValue]="null" selected disabled>
                Select a Data Entity
              </option>
              <option
                *ngFor="let entity of addMappingConfig.targetEntities"
                [ngValue]="entity.templateCode"
              >
                {{ entity.templateCode }}
              </option>
            </select>
            <input
              name="addMappingTargetInput"
              type="text"
              class="form-control"
              *ngIf="addMappingConfig.to === 'parent'"
              [(ngModel)]="newMapping.targetDataEntity"
              [ngbTypeahead]="searchParent"
              [resultFormatter]="formatter"
              formControlName="addMappingTargetInput"
            />
          </div>
        </div>
      </div>
    </form>
  </form>

  <div class="modal-footer" *ngIf="newMapping">
    <div
      *ngIf="
        addMappingSource &&
        addMappingSource.invalid &&
        (addMappingSource.dirty || addMappingSource.touched)
      "
      class="alert alert-danger"
    >
      <div *ngIf="addMappingSource && addMappingSource.errors.required">
        Please provide a source value.
      </div>
    </div>
    <div
      *ngIf="
        addMappingTarget &&
        addMappingTarget.invalid &&
        (addMappingTarget.dirty || addMappingTarget.touched)
      "
      class="alert alert-danger"
    >
      <div *ngIf="addMappingTarget && addMappingTarget.errors.required">
        Please provide a target value.
      </div>
    </div>
    <div
      *ngIf="
        addMappingTargetInput &&
        addMappingTargetInput.invalid &&
        (addMappingTargetInput.dirty || addMappingTargetInput.touched)
      "
      class="alert alert-danger"
    >
      <div>
        Please provide a target value.
      </div>
    </div>
    <button
      type="button"
      class="btn btn-outline-dark"
      (click)="modal.close('add')"
      [disabled]="
        !(
          !addMappingSource.invalid &&
          (!addMappingTargetInput.invalid || !addMappingTarget.invalid)
        )
      "
    >
      Add
    </button>
  </div>
</ng-template>

<ng-template #warnMappingsResetModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-warn-title">
      Change Child Workflow
    </h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('close')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div [hidden]="workflowExistsInOptionsList">
      <p class="text-primary">
        The currently selected Child Workflow is not active. You may restore
        <strong>{{ activity.mostRecentlyPublishedTargetWorkflowName }}</strong
        >, select a different workflow, or cancel out of this dialog to maintain
        the existing target.
      </p>
    </div>
    <p
      class="text-primary"
      *ngIf="
        activity.mostRecentlyPublishedTargetWorkflowVersionId !=
        activity.model.targetWorkflowVersionId
      "
    >
      Select a new workflow.
    </p>

    <div class="form-group">
      <label for="workflowId" class="bmd-label-static">Child Workflow</label>
      <div class="text-center" [hidden]="!loadingWorkflowVersionOptions">
        <div class="spinner-border" style="text-align: center;"></div>
      </div>

      <select
        class="form-control"
        name="workflowId"
        [(ngModel)]="newWorkflowVersionId"
        required
        [hidden]="loadingWorkflowVersionOptions"
      >
        <option [ngValue]="null" disabled selected>Select an option</option>
        <option
          [ngValue]="option.versionId"
          *ngFor="let option of workflowVersionOptions"
        >
          {{ option.workflowVersionName }}
        </option>
      </select>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-raised btn-default"
      (click)="modal.dismiss('cancel')"
    >
      Cancel
    </button>

    <button
      type="button"
      class="btn btn-raised btn-success mr-2"
      (click)="modal.close('continue')"
      [disabled]="
        activity.mostRecentlyPublishedTargetWorkflowVersionId ===
        newWorkflowVersionId
      "
    >
      Change
    </button>
  </div>
</ng-template>

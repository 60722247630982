// import * as Workflow from 'esri/widgets/Editor/Workflow';
import { PaymentRequest } from './payment-request';
import { RegistrationDocument } from './registration-document';
import { Requirement } from './requirement';
import { Workflow } from './workflow';

export enum RegistrationStatus {
  Pending,
  Approved,
  Denied,
  Expired,
  Inactive,
  Deleted,
  Renewed,
  Disabled
}

export class RegistrationNote {
  createdBy: string;
  modifiedBy: string;
  createdOn: Date;
  notes: string;
  registrationID: string;
}

export class Registration {
  id: string;
  clientId: string;
  registeredOnString: string;
  effectiveOnString: string;
  expiresOnString: string;
  approvedOnString: string;
  rejectedOnString: string;
  status: RegistrationStatus;
  paymentRequestId?: string;
  notes?: RegistrationNote[];
  documents?: RegistrationDocument[];
  registerApplicationId: string;
  workflowId: string;
  workflow: Workflow;
  workflowName: string;
  createdOn: Date;
  createdBy: string;
  modifiedOn: Date;
  modifiedBy: string;
  paymentRequest?: PaymentRequest;
  customFields: any[];
  certificatePath: string;
  certificateDocument: RegistrationDocument;
}

export class RegistrationVM {
  id: string;
  clientId: string;
  registerApplicationId: string;
  workflowId: string;
  appDescription: string;
  workflowName: string;
  workflowDescription: string;
  status: RegistrationStatus;
  registeredOnString: string;
  effectiveOnString: string;
  expiresOnString: string;
  customFields: any[];
  workflowRequirements?: Requirement[];
}

export class PagedRegistrationVM {
  pageCount: number;
  recordCount: number;
  unpermissibleCount: number;
  registrations: RegistrationVM[];
  requirements?: Requirement[];
}

export class UnpagedRegistrationVM {
  registrations: RegistrationVM[];
}

import { PaymentProcessorAccountConfigurationBase } from '../payment-processor-account-configuration-base';

export class KanPayPaymentProcessorAccountConfiguration extends PaymentProcessorAccountConfigurationBase {
  serviceCode: string;
  merchantKey: string;
  merchantID: string;
  applicationId: string;

  constructor(options?: Partial<KanPayPaymentProcessorAccountConfiguration>) {
    super(options);
  }
}

import { FormActivity } from './form-activity';
import { Activity, ActivityModel, ScreenActivity } from './activity';
import { Utilities, ActivityUtilities } from 'src/app/services';

export class ReviewActivity extends ScreenActivity<ActivityModel> {
  previousActivities: Activity<ActivityModel>[];

  constructor(options?: Partial<ReviewActivity>) {
    super(options);
    this.type = 'review-activity';
    this.name = 'Review Activity';
    this.description =
      'Use this activity to set the entry point for the application review process.';

    if (options) {
      Object.assign(this, options);
    }

    if (options) {
      this.model = new ActivityModel(options.model);
    } else {
      this.model = new ActivityModel();
    }

    this.model.screenName =
      this.model.screenName || 'Review Application';

    if (this.previousActivities) {
      const aObjs = ActivityUtilities.convertActivitiesJsonToClasses(
        this.previousActivities
      );
      const activities: Activity<ActivityModel>[] = [];

      for (const a in aObjs) {
        activities.push(aObjs[a]);
      }
      this.previousActivities = activities;
    }
  }
}

import {
  AppointmentActivity,
  AppointmentActivityModel
} from './../../../../../models/activities/appointment-activity';
import { ActivityView } from './../../../../../views/master-views/app.view/app.view.component';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'wm-appointment-activity-view',
  templateUrl: './appointment-activity-view.component.html',
  styleUrls: ['./appointment-activity-view.component.css']
})
export class AppointmentActivityViewComponent extends ActivityView
  implements OnInit {
  activity: AppointmentActivity;
  apptModel: AppointmentActivityModel;

  constructor() {
    super();
  }

  ngOnInit() {
    if (this.activity) {
      this.apptModel = this.activity.model as AppointmentActivityModel;
    }
  }
}

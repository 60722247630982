<div *ngIf="allActions && allActions.length > 0">
  <label>
    <input
      type="checkbox"
      [(ngModel)]="selectAllValue"
      (change)="toggleAllAvailableSelectionBoxes()"
    />
    Select All
  </label>
</div>

<div *ngIf="allActions && allActions.length > 0">
  {{ keysCount }} Selected
  <div id="actions" class="actions" *ngFor="let action of allActions">
    <div class="small-card-header">
      <div ngbAccordion>
        <div
          ngbAccordionItem="{{ action.id }}"
          title="Workflow Applications"
          [id]="action.id"
        >
          <div
            ngbAccordionHeader
            class="d-flex align-items-center justify-content-between small-card-header"
          >
            <label>
              <input
                type="checkbox"
                [(ngModel)]="checkedItems[action.id]"
                [disabled]="isDisabled(action)"
                (change)="updateSelectedActionProperties(action)"
              />
              <span
                class="tooltiptext"
                [ngbTooltip]="tooltipMessage[action.id]"
                [ngClass]="{
                  'tooltip-disabled': tooltipMessage[action.id] === 'disabled'
                }"
                placement="right"
                openDelay="3000"
              >
                {{ action.name }}
              </span>
            </label>
            <button
              ngbAccordionToggle
              class="btn btn-link p-0"
              *ngIf="!canRemove(action.id)"
            >
              {{ opened ? 'collapse' : 'expand' }}
            </button>
          </div>
          <div ngbAccordionCollapse class="card-body">
            <h5>Permissible Items</h5>
            <table class="table">
              <ng-container *ngFor="let p of action.permissions">
                <tr
                  class="permissibleItems"
                  *ngFor="let ps of p.actionSecurables"
                >
                  <td>{{ ps.securableName }}</td>
                  <td>
                    <wm-modal-confirm
                      [commandText]="'Delete Permission'"
                      [moduleId]="'deletePermissionModal' + p.id"
                      [showButtonText]="true"
                      [showButton]="true"
                      [btnIcon]="'delete'"
                      [title]="'Delete Permission'"
                      (accepted)="
                        removed.emit({
                          actionId: action.id,
                          securableId: ps.securableId
                        })
                      "
                      [buttonClass]="'btn btn-sm deletePermissible'"
                      class="mr-1"
                    >
                      <p>Are you sure you want to remove this permission?</p>
                      <p>Deleting this permission will do the following:</p>
                      <ul>
                        <li>
                          Remove the permission from {{ ps.securableName }}
                        </li>
                      </ul>
                    </wm-modal-confirm>
                  </td>
                </tr>
              </ng-container>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

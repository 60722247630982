import { Subscription } from 'rxjs';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { WorkflowContextService } from 'src/app/services';

@Component({
  selector: 'wm-contractor-detail-view',
  templateUrl: './contractor-detail-view.component.html',
  styleUrls: ['./contractor-detail-view.component.css']
})
export class ContractorDetailViewComponent implements OnInit, OnDestroy {
  contractorId: string;
  clientId: string;

  clientSubscription: Subscription;

  constructor(
    private _route: ActivatedRoute,
    private _context: WorkflowContextService
  ) {}

  ngOnInit() {
    this._route.params.subscribe(parms => {
      this.contractorId = parms.contractorId;

      if (this._context.client) {
        this.clientId = this._context.client.id;
      } else {
        this.clientSubscription = this._context.client$.subscribe(client => {
          if (!client) {
            return;
          }

          this.clientId = client.id;
        });
      }
    });
  }

  ngOnDestroy() {
    if (this.clientSubscription) {
      this.clientSubscription.unsubscribe();
      this.clientSubscription = null;
    }
  }
}

<div class="card">
  <div class="card-body">
    <h5 class="card-title">Tags</h5>
    <form [formGroup]="form">
      <div class="row">
        <div class="col">
          <div class="form-group">
            <label class="bmd-label-static" for="newTag">New Tag *</label>
            <select
              id="newTag"
              formControlName="newTag"
              [(ngModel)]="newTag"
              class="form-control"
            >
              <option *ngFor="let t of addTags" [value]="t.id">{{
                t.name
              }}</option>
            </select>
            <wm-control-message
              [control]="form.controls['newTag']"
            ></wm-control-message>
            <button
              class="btn btn-raised btn-primary"
              (click)="addTag(newTag)"
              [disabled]="form.invalid"
            >
              Add
            </button>
          </div>
        </div>
      </div>
    </form>
    <div *ngIf="(itemTags || []).length === 0">
      <i>No tags defined</i>
    </div>
    <div class="row" *ngFor="let tag of itemTags">
      <div class="col">
        {{ tag.tag.name }}
      </div>
      <div class="col">
        <div class="float-right">
          <wm-modal-confirm
            (accepted)="removeTag(tag)"
            [btnIcon]="'delete'"
            [showButton]="true"
            [showButtonText]="true"
            [commandText]="'Delete'"
            title="Delete Tag"
          >
            <p>
              Are you sure you want to remove ({{ tag.tag.name }}) from this
              workflow?
            </p>
            <br />
            <p>
              Some things to take note of.
            </p>
            <ul>
              <li>
                This tag will be removed from this workflow.
              </li>
            </ul>
          </wm-modal-confirm>
        </div>
      </div>
    </div>
  </div>
</div>

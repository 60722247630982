import { Component, OnInit, EventEmitter } from '@angular/core';
import { ActivityView } from '../../../../../views/master-views/app.view/app.view.component';
import { Activity, ActivityModel } from '../../../../../models/activities';
import { FormGroup } from '@angular/forms';
import { WorkflowContextService } from '../../../../../services';

@Component({
  selector: 'wm-contractor-registration-decision-activity-view',
  templateUrl:
    './contractor-registration-decision-activity-view.component.html',
  styleUrls: ['./contractor-registration-decision-activity-view.component.css']
})
export class ContractorRegistrationDecisionActivityViewComponent
  extends ActivityView
  implements OnInit {

  constructor() {
    super();
  }

  ngOnInit() {}
}

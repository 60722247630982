import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'wm-help-text',
  templateUrl: './help-text.component.html',
  styleUrls: ['./help-text.component.css']
})
export class HelpTextComponent implements OnInit {
  @Input() helpText = '';
  @Input() placement = 'left';

  constructor() {}

  ngOnInit() {}
}

import { DataEntity } from './data-entities/data-entity';

export class DataEntityDetail {
  templateCode: string;
  activityId: string;
  activityLabel: string;
  label: string;
  isRequired: boolean;
  dataEntityTypeCode: string;

  constructor(options?: DataEntity | DataEntityDetail) {
    if (options) {
      this.templateCode = options.templateCode;
      this.activityId = options.activityId;
      this.activityLabel = options.activityLabel;
      this.label = options.label;
      this.isRequired = options.isRequired;
      this.dataEntityTypeCode = options.dataEntityTypeCode;
    }
  }
}

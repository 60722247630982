import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  Component,
  OnInit,
  Input,
  Inject,
  forwardRef,
  ChangeDetectorRef
} from '@angular/core';
import { MapLayer, MapService } from 'src/app/models';
import {
  DataService,
  SystemService,
  ValidationService,
  WorkflowContextService,
  WorkflowService
} from 'src/app/services';
import { ActivityEditorBaseComponent } from '../../activity-editor-base/activity-editor-base.component';
import {
  QueryLayerConfig,
  QueryMapLayerActivity
} from 'src/app/models/activities';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { Observable, empty, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { MapDisplayConfiguration } from 'src/app/components/arcgis/map-display-configuration';

@Component({
  selector: 'wm-query-map-layer-activity-editor',
  templateUrl: './query-map-layer-activity-editor.component.html',
  styleUrls: ['./query-map-layer-activity-editor.component.css']
})
export class QueryMapLayerActivityEditorComponent
  extends ActivityEditorBaseComponent
  implements OnInit {
  constructor(
    @Inject(forwardRef(() => SystemService))
    private _systemSvc: SystemService,
    @Inject(forwardRef(() => DataService))
    private _dataSvc: DataService,
    @Inject(forwardRef(() => WorkflowContextService))
    private _context: WorkflowContextService,
    @Inject(forwardRef(() => WorkflowService))
    private _workflowSvc: WorkflowService,
    private _ref: ChangeDetectorRef
  ) {
    super();
  }

  @Input() activity: QueryMapLayerActivity;

  mapServices: MapService[];
  mapLayers: MapLayer[];
  mapServiceId: string;
  mapLayerId: number;
  featureIdField: string;
  showWarning = false;
  layerHasOtherConfigurations = false;
  newFeatureIdField: string;
  layerFieldIsNotUnique?: boolean;
  validatingFieldIsUnique = false;
  layerSupportsPagination: boolean = null;
  missingLayer: MapLayer;

  ngOnInit() {
    if ((this.activity.model.layerConfig || []).length > 0) {
      this.showWarning = true;
    }

    const initData = () => {
      this._systemSvc
        .getMapServicesByClient(this._context.client)
        .subscribe(mapServices => {
          this.mapServices = mapServices;

          if (this.activity.model.mapService) {
            this.mapServiceId = this.activity.model.mapService.id;
          }
          this.featureIdField = this.activity.model.featureIdField;

          if (!this.activity.model.mapConfig) {
            this.activity.model.mapConfig = new MapDisplayConfiguration();
          }

          this.activity.model.mapConfig.mapServiceId = this.mapServiceId;

          this._ref.detectChanges();
        });
    };

    this.form.addControl(
      'mapService',
      new FormControl('', Validators.required)
    );

    this.form.addControl('mapLayer', new FormControl('', Validators.required));

    this.form.addControl(
      'featureIdField',
      new FormControl('', Validators.required)
    );

    this.form.addControl(
      'newFeatureIdField',
      new FormControl('', Validators.nullValidator)
    );

    this.form.addControl(
      'isFeatureIdUnique',
      new FormControl('', Validators.required)
    );

    this.form.addControl(
      'isLayerValid',
      new FormControl('', Validators.required)
    );

    this.form.addControl('layerFields', new FormGroup({}));

    if (this._context.client) {
      initData();
    } else {
      this._context.client$.subscribe(c => {
        initData();
      });
    }
  }

  clearLayerData() {
    if (this.activity.model.mapLayer) {
      this.activity.model.mapLayer.id = null;
      this.activity.model.featureIdField = null;
      this.activity.model.mapLayer.fields = [];
    }
    this.layerHasOtherConfigurations = false;
    this.form.controls['featureIdField'].enable();
    this.featureIdField = null;
  }

  getMapLayers() {
    if (this.activity.model.mapService) {
      this.mapLayers = null;
      this._dataSvc
        .getIndexedMapLayers(this.activity.model.mapService.id)
        .subscribe(layers => {
          this.mapLayers = layers;

          if (this.activity.model.mapLayer) {
            const l = this.mapLayers.find(
              ml => ml.name == this.activity.model.mapLayer.name
            );

            // capture the layer details in missingLayer and clear out the data if the configured layer no longer exists
            if (!l) {
              this.missingLayer = { ...this.activity.model.mapLayer };
              this.clearLayerData();
            }
          }

          if (this.activity.model.mapLayer) {
            this.mapLayerId = this.activity.model.mapLayer.id;
            this.selectMapLayer(this.activity.model.mapLayer.id);
          }

          this._ref.detectChanges();
        });
    }
  }

  selectMapService(serviceId: string) {
    if (serviceId) {
      const service: MapService = this.mapServices.find(
        ms => ms.id === serviceId
      );

      this.activity.model.mapService = service;
      this.activity.model.mapConfig.mapServiceId = serviceId;

      this.getMapLayers();
    }
  }

  selectFeatureIdField(featureIdField: string) {
    if (featureIdField) {
      this.validatingFieldIsUnique = true;
      this.form.controls['isFeatureIdUnique'].setValue(null);
      this._dataSvc
        .isFieldUniqueInLayer(
          this.activity.model.mapLayer.mapLayerIndexItemId,
          featureIdField
        )
        .subscribe(isUnique => {
          this.validatingFieldIsUnique = false;
          this.layerFieldIsNotUnique = isUnique;
          if (isUnique) {
            this.activity.model.featureIdField = featureIdField;
            this.form.controls['isFeatureIdUnique'].setValue('true');
          } else {
            this.form.controls['isFeatureIdUnique'].setValue(null);
          }

          this._ref.detectChanges();
        });
    }
  }

  changeFeatureIdField() {
    this._systemSvc
      .changeQueryMapLayerFeatureIdField(
        this.activity.model.mapLayer.mapLayerIndexItemId,
        this.newFeatureIdField
      )
      .subscribe(result => {
        this.featureIdField = this.newFeatureIdField;
      });
  }

  doesLayerSupportPagination(layer: MapLayer): Observable<MapLayer> {
    this.form.controls['isLayerValid'].setValue(null);
    if (layer.mapLayerIndexItemId) {
      return this._dataSvc.getMapLayerDetails(layer.mapLayerIndexItemId).pipe(
        tap(result => {
          if (result && result.advancedQueryCapabilities) {
            this.layerSupportsPagination =
              result.advancedQueryCapabilities.supportsPagination;

            if (this.layerSupportsPagination) {
              this.form.controls['isLayerValid'].setValue('true');
            } else {
              this.form.controls['isLayerValid'].setValue(null);
            }
          }
          return result;
        })
      );
    } else {
      return of();
    }
  }

  changeTabs() {
    this._ref.detectChanges();
  }

  selectMapLayer(layerId: number) {
    if (layerId !== null) {
      const layer: MapLayer = this.mapLayers.find(l => l.id == layerId);

      if (layer) {
        if (
          this.activity.model.mapLayer &&
          this.activity.model.mapLayer.id !== layer.id
        ) {
          this.activity.model.layerConfig = [];
        }
        this.activity.model.mapLayer = { ...layer };
        this.mapLayerId = this.activity.model.mapLayer.id;

        this.layerSupportsPagination = null;

        this.doesLayerSupportPagination(layer).subscribe(() => {
          this._dataSvc
            .getQueryMapLayersForLayer(layer.mapLayerIndexItemId)
            .subscribe(queryMapLayers => {
              const queryMapLayersOtherThanMe = queryMapLayers.filter(
                qml =>
                  qml.workflowId !== this.activity.workflowId &&
                  qml.activityId !== this.activity.id
              );

              if (queryMapLayersOtherThanMe.length > 0) {
                this.layerHasOtherConfigurations = true;
                this.featureIdField =
                  queryMapLayersOtherThanMe[0].featureIdField;
                this.form.controls['featureIdField'].disable();
              } else {
                this.layerHasOtherConfigurations = false;
                this.form.controls['featureIdField'].enable();
              }

              if (layer && layer.fields) {
                this.buildLayerFields();
                this._ref.detectChanges();
              }
            });
        });
        return;
      }
    }

    this.clearLayerData();

    this.buildLayerFields();

    this._ref.detectChanges();
  }

  buildLayerFields() {
    if (this.activity.model.mapLayer && this.activity.model.mapLayer.fields) {
      if (this.form.controls['layerFields']) {
        this.form.removeControl('layerFields');
      }
      const layerFieldGroup: FormGroup = new FormGroup({});
      // don't show Geometry Fields
      this.activity.model.layerConfig = this.activity.model.mapLayer.fields
        .filter(f => f.type != 'esriFieldTypeGeometry')
        .map(f => {
          const existingLayerField = this.activity.model.layerConfig
            ? this.activity.model.layerConfig.find(
                lc => lc.fieldName === f.name
              )
            : null;

          if (layerFieldGroup) {
            layerFieldGroup.controls[f.name] = new FormGroup({
              isSearchable: new FormControl(
                existingLayerField ? existingLayerField.isSearchable : false,
                {
                  updateOn: 'blur',
                  validators: Validators.required
                }
              ),
              isResultsColumn: new FormControl(
                existingLayerField ? existingLayerField.showInResults : false,
                {
                  updateOn: 'blur',
                  validators: Validators.required
                }
              ),
              isDataEntity: new FormControl(
                existingLayerField ? existingLayerField.isDataEntity : false,
                {
                  updateOn: 'blur',
                  validators: [Validators.required]
                }
              ),
              searchLabel: new FormControl(
                existingLayerField ? existingLayerField.searchLabel : '',
                {
                  updateOn: 'blur',
                  validators: [Validators.required]
                }
              ),
              resultHeader: new FormControl(
                existingLayerField ? existingLayerField.resultHeader : '',
                {
                  updateOn: 'blur',
                  validators: [Validators.required]
                }
              ),
              entityLabel: new FormControl(
                existingLayerField ? existingLayerField.entityLabel : '',
                {
                  updateOn: 'blur',
                  validators: [Validators.required]
                }
              ),
              entityTemplateCode: new FormControl(
                existingLayerField ? existingLayerField.entityTemplateCode : '',
                {
                  updateOn: 'blur',
                  validators: [Validators.required]
                }
              ),
              entityDataTypeCode: new FormControl(
                existingLayerField ? existingLayerField.dataEntityTypeCode : '',
                {
                  updateOn: 'blur',
                  validators: Validators.required
                }
              )
            });
          }

          if (f.type != 'esriFieldTypeString') {
            // switch field searchable off in case it was already configured
            if (existingLayerField) {
              existingLayerField.isSearchable = false;
            }
            // disable the searchable field for fields that aren't text
            (<FormGroup>layerFieldGroup.controls[f.name]).controls[
              'isSearchable'
            ].disable();
          }

          return new QueryLayerConfig({
            fieldName: existingLayerField
              ? existingLayerField.fieldName
              : f.name,
            entityTemplateCode: existingLayerField
              ? existingLayerField.entityTemplateCode
              : f.name,
            entityLabel: existingLayerField
              ? existingLayerField.entityLabel
              : f.name,
            searchLabel: existingLayerField
              ? existingLayerField.searchLabel
              : f.name,
            resultHeader: existingLayerField
              ? existingLayerField.resultHeader
              : f.name,
            isDataEntity: existingLayerField
              ? existingLayerField.isDataEntity
              : false,
            isSearchable: existingLayerField
              ? existingLayerField.isSearchable
              : false,
            showInResults: existingLayerField
              ? existingLayerField.showInResults
              : false,
            dataEntityTypeCode: existingLayerField
              ? existingLayerField.dataEntityTypeCode
              : 'free-form-text' // we need to look at the data type and see if we can default this to something smarter
          });
        });

      this.form.addControl('layerFields', layerFieldGroup);
    }
  }
}

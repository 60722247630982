import {
  Activity,
  ScreenActivity,
  ActivityModel
} from '../../models/activities/activity';
import { Role } from '../role';
import { EmailActivityCellModel } from 'src/app/services';

export class EmailActivityModel extends ActivityModel {
  to: string[] = [];
  cc: string[] = [];
  bcc: string[] = [];
  roles: Role[] = [];
  body: string;
  subject: string;
  attachments: EmailActivityAttachment[] = [];
  emailApplicant: boolean;
  constructor(options?: Partial<EmailActivityModel>) {
    super(options);
    if (options) {
      Object.assign(this, options);
    }
  }
}

export class EmailActivityAttachment {
  name: string;
  path: string;
  activityId: string;
  deTemplateCode: string;
}

export class EmailActivity<
  T extends EmailActivityModel = EmailActivityModel
> extends ScreenActivity<EmailActivityModel> {
  applicationEmails: ApplicationEmailVM[];
  showResendEmails: boolean;

  constructor(options?: Partial<EmailActivity<T>>) {
    super(options);

    this.model = new EmailActivityModel();
    if (options) {
      Object.assign(this, options);
    }

    this.model = new EmailActivityModel(options ? options.model : null);

    this.model.screenName = this.model.screenName || 'Email';
  }

  public displayOnScreen(): boolean {
    return false;
  }
}

export class ApplicationEmailVM {
  roleNames: string[];
  toRecipients: string[];
  ccRecipients: string[];
  bccRecipients: string[];
  subject: string;
  body: string;
  attachments: EmailActivityAttachment[];
  emailApplicant: boolean;
  delivered: boolean;
  errorStatus: string;
  sentAtString: string;
  sendOnString: string;
  canceledOnString: string;
}

<wm-form-activity-editor
  [activity]="activity"
  [form]="form"
  [workflow]="workflow"
  [includedDataEntityTypes]="includedDataEntityTypes"
  [excludedDataEntityTypes]="excludedDataEntityTypes"
  (saved)="saved.emit($event)"
></wm-form-activity-editor>

<!-- *ngIf="
  listEntity.controlType.toString() === 'multi-select' ||
  listEntity.controlType.toString() === 'dropdown'
" -->

<label
  class="bmd-label-floating"
  for="responsibleRole"
  *ngIf="true && inspectorRequired()"
>
  Default Inspector
</label>
<form [formGroup]="form">
  <!-- <label for="first-name">First Name: </label>
  <input id="first-name" type="text" formControlName="firstName">

  <label for="last-name">Last Name: </label>
  <input id="last-name" type="text" formControlName="lastName">
  -->

  <div>
    <select
      class="form-control"
      id="options"
      name="options"
      formControlName="opt"
      (change)="addItem(true, $event.target.value)"
      *ngIf="true && inspectorRequired()"
    >
      <option value="" [disabled]="false">
        Select Item...
      </option>
      <option
        *ngFor="let s of os"
        [value]="s.value"
        [selected]="isDefaultSelected(s)"
      >
        {{ s.text }}
      </option>
    </select>
  </div>
  <wm-control-message [control]="form.controls['opt']"></wm-control-message>
</form>

<ul class="list-group" class="p-0 mb-0">
  <li
    class="list-group-item"
    *ngFor="let msg of error.messages"
    (click)="clickMessage.emit(error)"
  >
    <span
      [ngClass]="validationSvc.getSeverity(msg.severity).class"
      class="mr-1"
    >
      {{ validationSvc.getSeverity(msg.severity).name }}
    </span>
    <span>{{ msg.message }}</span>
  </li>
</ul>

import {
  Component,
  OnInit,
  Input,
  ComponentFactoryResolver
} from '@angular/core';
import { DataEntityViewComponent } from '../../data-entity-view/data-entity-view.component';
import { DataEntity } from '../../../../../models/data-entities';
import { FreeFormTextDataEntity } from 'src/app/models/data-entities/free-form-text-data-entity';

@Component({
  selector: 'wm-free-form-text-data-entity-view',
  templateUrl: './free-form-text-data-entity-view.component.html',
  styleUrls: ['./free-form-text-data-entity-view.component.css']
})
export class FreeFormTextDataEntityViewComponent extends DataEntityViewComponent
  implements OnInit {
  @Input() entity: FreeFormTextDataEntity;

  constructor(componentFactoryResolver: ComponentFactoryResolver) {
    super(componentFactoryResolver);
  }

  ngOnInit() {}
}

import {
  Component,
  OnInit,
  ViewChild
} from '@angular/core';
import { ActivityView } from '../../../../../views/master-views/app.view/app.view.component';
import { FormActivity } from '../../../../../models/activities';
import { UntypedFormGroup } from '@angular/forms';
import { FormActivityInputViewComponent } from './form-activity-input-view/form-activity-input-view.component';

export interface FormActivityInputLayout {
  activity: FormActivity;
  form: UntypedFormGroup;
}

@Component({
  selector: 'wm-form-activity-input',
  templateUrl: './form-activity-input.component.html',
  styleUrls: ['./form-activity-input.component.css']
})
export class FormActivityInputComponent extends ActivityView
  implements OnInit {
  @ViewChild('formActivityInputView', { static: true })
  formActivityInputViewCtl: FormActivityInputViewComponent;

  initActivity: any;

  constructor() {
    super();
  }

  ngOnInit() {}

  async persistChildComponent?() {
      return this.formActivityInputViewCtl.persistChildComponent();
  }

  refreshActivity(e) {
    this.activity = e;
  }

}

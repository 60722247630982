<h3>CSV Configuration</h3>
<form [formGroup]="form" *ngIf="configuration">
  <div class="form-row">
    <div class="switch">
      <label>
        <input
          type="checkbox"
          formControlName="includeHeaderRow"
          [(ngModel)]="configuration.includeHeaders"
        />
        Include Header Row?
      </label>
    </div>
  </div>
</form>

<div class="row justify-content-between">
  <div class="col"></div>
  <div
    class="col text-right float-right"
    *ngIf="bulkActions && bulkActions.length > 0"
  >
    <div
      ngbDropdown
      class="d-inline-block"
      display="dynamic"
      placement="bottom-right"
    >
      <button
        class="btn btn-primary btn-raised"
        id="bulk-actions"
        ngbDropdownToggle
      >
        Bulk Actions
      </button>
      <div ngbDropdownMenu class="dropdown-menu" aria-labelledby="bulk-actions">
        <button
          ngbDropdownItem
          *ngFor="let action of bulkActions"
          (click)="doBulkAction(action)"
        >
          {{ action.name }}
        </button>
      </div>
    </div>
  </div>
</div>

<wm-filter-builder
  #filterCtrl
  *ngIf="params"
  [id]="id"
  [params]="params"
  [defaultFilters]="defaultFilters"
  [filterTypes]="filterTypes"
  [showFilterBuilder]="showFilterBuilder"
  [showSimpleSearch]="showSimpleSearch"
  (filtersChange)="handleFiltersChanged($event)"
  (filtersChange2)="handleFiltersChanged2($event)"
  [savedFilters]="savedFilters"
  [simpleSearchTitle]="simpleSearchTitle"
  [columnOptions]="settings.columnOptions"
  (columnsChange)="columnsChanged($event)"
  [waitForParent]="waitForParent"
  [exportingEnabled]="exportingEnabled"
></wm-filter-builder>

<div *ngIf="!useMap">
  <ngx-datatable
    class="material"
    [loadingIndicator]="loading"
    [rows]="rows"
    [columns]="columns"
    [columnMode]="ColumnMode.force"
    [headerHeight]="50"
    [footerHeight]="50"
    rowHeight="auto"
    [externalPaging]="true"
    [count]="count"
    [offset]="offset"
    [limit]="limit"
    (page)="onPage($event)"
    [externalSorting]="true"
    (sort)="onSort($event)"
    [selected]="selected"
    [selectionType]="isBulkActionsEnabled ? SelectionType.checkbox : null"
    [selectAllRowsOnPage]="false"
    (select)="onSelect($event)"
    [rowIdentity]="getId"
  >
  </ngx-datatable>
</div>

<ng-container *ngIf="useMap">
  <ul
    ngbNav
    #navs="ngbNav"
    (navChange)="tabChangeEvent($event)"
    class="nav-tabs"
  >
    <li [ngbNavItem]="'datatab'">
      <a ngbNavLink>Data</a>
      <ng-template ngbNavContent>
        <ngx-datatable
          class="material"
          [loadingIndicator]="loading"
          [rows]="rows"
          [columnMode]="ColumnMode.force"
          [columns]="columns"
          [headerHeight]="50"
          [footerHeight]="50"
          rowHeight="auto"
          [externalPaging]="true"
          [count]="count"
          [offset]="offset"
          [limit]="limit"
          (page)="onPage($event)"
          [externalSorting]="true"
          (sort)="onSort($event)"
          [selected]="selected"
          [selectionType]="isBulkActionsEnabled ? SelectionType.checkbox : null"
          [selectAllRowsOnPage]="false"
          (select)="onSelect($event)"
          [rowIdentity]="getId"
        >
        </ngx-datatable>
      </ng-template>
    </li>
    <li [ngbNavItem]="'maptab'">
      <a ngbNavLink>Map</a>
      <ng-template ngbNavContent>
        <app-basic-esri-map
          [inputFilters]="settingsChanged"
          [initialFilters]="currentFilters"
            (mapExtentChanged)="mapExtentChanged($event)"
          (mapDataRequested)="mapDataRequested.emit($event)"
          [popupTemplateContent]="popupTemplateContent"
          [popupTemplateTitle]="popupTemplateTitle"
          [popupActions]="popupActions"
                            [startingExtent]="startingMapVariables.mapExtent"
        ></app-basic-esri-map>
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="navs"></div>
</ng-container>

<div class="row pt-2" *ngIf="unpermissibleCount > 0">
  <div class="col-12">
    <i
      >*There are {{ this.unpermissibleCount }} rows on this page that you do
      not have permission to view.</i
    >
  </div>
</div>
<div class="row" *ngIf="count > 10">
  <div class="col-2">
    <div class="form-group">
      <label for="pageSize">Page Size</label>
      <select
        name="pageSize"
        id="pageSize"
        class="form-control"
        [(ngModel)]="pageSize"
        (ngModelChange)="pageSizeChanged($event)"
      >
        <option *ngFor="let size of pageSizeOptions" [ngValue]="size">
          {{ size }}
        </option>
      </select>
    </div>
  </div>
</div>

import {
  Component,
  OnInit,
  Inject,
  forwardRef,
  ChangeDetectorRef,
  Input
} from '@angular/core';
import { DataEntityEditorBaseComponent } from '../../data-entity-editor-base/data-entity-editor-base.component';
import {
  WorkflowService,
  WorkflowContextService,
  SystemService
} from '../../../../../services';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { CalculatedValueDataEntity } from 'src/app/models/data-entities';
import { ConditionTarget } from 'src/app/components/system/condition-builder/condition-builder.model';

@Component({
  selector: 'wm-calculate-value-data-entity-editor',
  templateUrl: './calculate-value-data-entity-editor.component.html',
  styleUrls: ['./calculate-value-data-entity-editor.component.css']
})
export class CalculateValueDataEntityEditorComponent
  extends DataEntityEditorBaseComponent
  implements OnInit {
  @Input() dataEntity: CalculatedValueDataEntity;

  constructor(
    @Inject(forwardRef(() => WorkflowService))
    public _workflowSvc: WorkflowService,
    @Inject(forwardRef(() => WorkflowContextService))
    private _context: WorkflowContextService,
    @Inject(forwardRef(() => SystemService)) private _systemSvc: SystemService,
    private changeDetRef: ChangeDetectorRef,
    private _fb: UntypedFormBuilder
  ) {
    super();
  }

  ngOnInit() {
    this.form.addControl(
      'allowEdit',
      this._fb.control(false, [Validators.required])
    );
    this.form.addControl(
      'visibleToUser',
      this._fb.control(false, [Validators.required])
    );
    this.form.addControl(
      'formula',
      this._fb.control('', [Validators.required])
    );
    this.form.addControl(
      'output-format',
      this._fb.control('', [Validators.required])
    );
  }

  updateCriteria(criteria: ConditionTarget[]) {
    this.dataEntity.formulaCriteria = criteria;
    this.form.controls['formula'].setValue(
      (criteria || []).length > 0 ? 'done' : ''
    );
    this.updated.emit(this.dataEntity);
  }

  // Use => in order to force `this` into being the FeeDataEntityEditorComponent
  getThenLabel = (clause: ConditionTarget): string => {
    if (clause) {
      return clause.value;
    } else {
      return 'needs refactored';
    }
  }
}

<div class="card">
  <div class="card-body">
    <h3>Inspection Queue</h3>

    <wm-inspection-queue [client]="client" [id]="id"> </wm-inspection-queue>

    <div [class]="'d-none'">
      <template #alertContainer></template>
    </div>
  </div>
</div>

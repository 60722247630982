import {
  parse as parseAgs,
  convert as convertAgs
} from 'terraformer-arcgis-parser';
import {
  parse as parseWkt,
  convert as convertWkt
} from 'terraformer-wkt-parser';
import { EsriLoaderService } from 'src/app/components/arcgis/esri-loader.service';
import { Observable, Observer } from 'rxjs';
import * as Sublayer from 'esri/layers/support/Sublayer';
import { SpatialReference } from 'esri/geometry';

export class GeomUtils {
  private esriLoaderService: EsriLoaderService = new EsriLoaderService();

  static ArcGisGeomToWkt(geom: any): string {
    const tfg = parseAgs(geom);
    return convertWkt(tfg);
  }

  ////
  static WktToArcGisGeom(wkt: string): any {
    const tfg = parseWkt(wkt);
    const gj = convertAgs(tfg);
    if (gj['rings']) {
      gj['type'] = 'polygon';
    } else if (gj['paths']) {
      gj['type'] = 'polyline';
    } else {
      gj['type'] = 'point';
    }

    return gj;
  }
  ToggleDMS_DD(coords: any) {
    if (typeof coords === 'number') {
      return this.ConvertDDToDMS(coords, false).toString();
    } else {
      return this.parseDMS(coords);
    }
  }

  ConvertDDToDMS(D: number, lng: boolean) {
    const seconds2 = (((D * 60) % 1) * 6e9) / 1e8;
    return {
      // bitwise math to set to seven decimal places... not good enough for seconds.
      dir: D < 0 ? (lng ? 'W' : 'S') : lng ? 'E' : 'N',
      deg: 0 | (D < 0 ? (D = -D) : D),
      min: 0 | (((D += 1e-9) % 1) * 60),
      sec: ((((D * 60) % 1) * 6e9) / 1e8).toString(),
      toString: function() {
        return (
          (this.dir === 'W' || this.dir === 'S' ? '-' : '') +
          this.deg +
          ' ' +
          this.min +
          ' ' +
          this.sec
        );
      }
    };
  }
  async getGeoPoint(x: any, y: any, srid) {
    const [
      Graphic,
      Extent,
      SpatialReference,
      webMercatorUtils
    ] = await this.esriLoaderService.LoadModules([
      'esri/Graphic',
      'esri/geometry/Extent',
      'esri/geometry/SpatialReference',
      'esri/geometry/support/webMercatorUtils'
    ]);
    const point = {
      type: 'point', // autocasts as new Polyline()
      spatialReference: new SpatialReference({ wkid: srid }),
      x: parseFloat(x),
      y: parseFloat(y)
    };
    const graphic = { geometry: point };
    const g = Graphic.fromJSON(graphic);
    return g;
  }
  async ObservableProjectedGraphicPromise(mapPoint, toProj) {
    const [
      Graphic,
      SpatialReference,
      projection
    ] = await this.esriLoaderService.LoadModules([
      'esri/Graphic',
      'esri/geometry/SpatialReference',
      'esri/geometry/projection'
    ]);

    // return new Observable(this.sequenceSubscriber);
    return new Observable(observer => {
      const error = false;
      const outSR = new SpatialReference({ wkid: toProj }); // wkid or wkt//geographic
      projection.load().then(function() {
        mapPoint.geometry = projection.project(mapPoint.geometry, outSR);
        observer.next(mapPoint);
        observer.complete();
      });

      return { unsubscribe() {} };
    });
  }
  roundSeconds(dms: string, places) {
    const a = dms.split(' ');
    let s = 0;
    let ss = '00.00';
    if (a.length >= 3) {
      s = parseFloat(a[2]);
      ss = Number(s)
        .toFixed(places)
        .toString();
    }

    return a[0] + ' ' + a[1] + ' ' + ss;
  }

  parseDMS(dms: string) {
    const a = dms.split(' ');
    let d = 0,
      m = 0,
      s = 0;

    if (a.length >= 1) {
      d = parseFloat(a[0]);
    }
    if (a.length >= 2) {
      m = parseFloat(a[1]);
    }
    if (a.length >= 3) {
      s = parseFloat(a[2]);
    }

    if (d < 0) {
      return d - m / 60.0 - s / 3600.0;
    } else {
      return d + m / 60.0 + s / 3600.0;
    }
  }
}

export enum CustomFieldDataOperationType {
  MoveEntityFromContractorToRegistrationForm = 1,
  MoveEntityFromRegistrationToContractorForm = 2,
  RenameTemplateCode = 3
}

export class CustomFieldMigrationQueueRequest {
  migrationType: CustomFieldDataOperationType;
  templateCode: string;
  workflowId: string;

  constructor(options?: Partial<CustomFieldMigrationQueueRequest>) {
    if (options) {
      Object.assign(this, options);
    }
  }
}

export class CustomFieldMigrationQueueItem {
  id: string;
  operationType: CustomFieldDataOperationType;
  templateCode: string;
  workflowId: string;

  constructor(options?: Partial<CustomFieldMigrationQueueItem>) {
    if (options) {
      Object.assign(this, options);
    }
  }
}

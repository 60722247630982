<div *ngIf="workflowId && itemId && showView">
  <h5>Validation</h5>

  <app-loader *ngIf="loading"></app-loader>

  <div *ngIf="!loading">
    <p class="text-success" *ngIf="!validationErrors">
      No problems!
    </p>

    <wm-validation-message
      *ngIf="validationErrors"
      [error]="validationErrors"
    ></wm-validation-message>
  </div>
</div>

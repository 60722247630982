export class PaymentProcessorConfigurationBase {
  url: string;
  isConfigured: boolean;

  constructor(options?: Partial<PaymentProcessorConfigurationBase>) {
    this.url = '';

    if (options) {
      Object.assign(this, options);
    }
  }
}

import { CalculateValueDataEntityViewComponent } from './../calculate-value-data-entity/calculate-value-data-entity-view/calculate-value-data-entity-view.component';
import {
  ComponentFactoryResolver,
  OnDestroy,
  OnInit,
  forwardRef,
  Inject,
  AfterViewInit,
  Component,
  Output,
  EventEmitter,
  ChangeDetectorRef
} from '@angular/core';
import { DataEntityInputComponent } from './data-entity-input.component';
import { Subscription } from 'rxjs';
import {
  WorkflowService,
  WorkflowContextService,
  SecurityService
} from 'src/app/services';
import { CalculatedValueDataEntity } from '../../../../models/data-entities/calculated-value-data-entity';

import * as _ from 'lodash';
import { distinctUntilChanged } from 'rxjs/operators';
import { UntypedFormControl, Validators } from '@angular/forms';

@Component({
  selector: 'wm-calc-on-change-data-entity-input',
  template: ''
})
export class CalcOnChangeDataEntityComponent extends DataEntityInputComponent
  implements OnInit, OnDestroy, AfterViewInit {
  divClass = 'form-group';

  calculating: boolean;
  calculateDebouncer: any;
  previousCalculatedValue: string;
  hasChanges = false;
  changes: { [key: string]: any };
  entity: CalculatedValueDataEntity;
  labelClass = 'bmd-label-static'; // "col-sm-2 col-form-label";
  inputClass = 'form-control-plaintext';
  valueObj: { calculatedValue: string; editedValue: string; value: string };
  modelValue: string;
  @Output() calculateActivity: EventEmitter<string> = new EventEmitter();

  valueUpdatedSubscriber: Subscription;
  calculatingFormControlName = '';

  constructor(
    componentFactoryResolver: ComponentFactoryResolver,
    @Inject(forwardRef(() => WorkflowContextService))
    private _workflowSvc: WorkflowService,
    @Inject(forwardRef(() => WorkflowContextService))
    private _context: WorkflowContextService,
    @Inject(forwardRef(() => SecurityService))
    private _securitySvc: SecurityService,
    private _ref: ChangeDetectorRef
  ) {
    super(componentFactoryResolver);
  }
  ngAfterViewInit(): void {
    if (this.entity.allowEdit) {
      setTimeout(e => {
        const control = this.form.controls[this.entity.templateCode];
        control.valueChanges.subscribe(changes => {
          if (control.dirty) {
            if (changes == null || changes === '') {
              // add subscription back if we clear the value
              this.hasChanges = true;
              this.entity.editedValue = null;
              this.entity.value = JSON.stringify({
                CalculatedValue: null,
                EditedValue: null,
                Value: null
              });
              control.markAsPristine();
              this.watchForCalculation();
              this.fireCalculation();
              return;
            }

            this.entity.editedValue = changes;
          }
        });
      }, 100);
    }

    // watch for changes that are included in the formula for the data entity if there are some on the same activity
    if ((this.entity.sameActivityFormulaDataEntities || []).length > 0) {
      // check to see if there are changes ever second and calculate new value if there are changes
      this.watchForCalculation();
    }
  }
  ngOnDestroy(): void {
    if (this.form) {
      this.form.removeControl(this.calculatingFormControlName);
    }

    if (this.valueUpdatedSubscriber) {
      this.valueUpdatedSubscriber.unsubscribe();
    }
  }

  async persistChildComponent() {
    if (this.entity && !this.entity.isCalculating) {
      if (this.valueUpdatedSubscriber) {
        this.valueUpdatedSubscriber.unsubscribe();
      }
    }

    return new Promise<void>(a => a());
  }

  fireCalculation() {
    let calcControl = this.form.controls[this.calculatingFormControlName];

    if (calcControl) {
      calcControl.setValue('');

      this.entity.isCalculating = true;

      if (this.valueUpdatedSubscriber) {
        this.valueUpdatedSubscriber.unsubscribe();
      }
  
      if (this.valueObj) {
        this.valueObj.calculatedValue = null;
        this.valueObj.value = null;
      }
  
      this.entity.value = null;
  
      this.valueUpdatedSubscriber = this.entity.valueUpdate$.subscribe(value => {
        if (this.valueObj) {
          this.valueObj.calculatedValue = value;
          this.valueObj.value = value;
        }
        this.modelValue = value || '';
        this.entity.value = JSON.stringify(this.valueObj);
        this.form.controls[this.calculatingFormControlName].setValue('false');
      });
  
      this.calculateActivity.emit(this.entity.templateCode);
    }
  }

  watchForCalculation() {
    const calcValue = changes => {
      if ((this.entity.editedValue || '') == '') {
        this.fireCalculation();
      }
    };

    setTimeout(() => {
      // watch for changes on the data entity that are used in the formula
      this.entity.sameActivityFormulaDataEntities.forEach((value, idx) => {
        if (this.form.controls[value]) {
          this.form.controls[value].valueChanges
            .pipe(distinctUntilChanged())
            .subscribe(calcValue);
        }
      });
    }, 100);
  }

  async ngOnInit() {
    this.calculatingFormControlName = this.entity.templateCode + '-calculating';

    if (this.entity.allowEdit) {
      this.labelClass = 'bmd-label-floating';
      this.inputClass = 'form-control';
    }

    if (this.form) {
      this.form.addControl(
        this.calculatingFormControlName,
        new UntypedFormControl('false', Validators.required)
      );
    }

    if (
      (this.entity.value || '').toString().trim() !== '' &&
      (this.entity.value || '').toString().indexOf('{') > -1
    ) {
      this.valueObj = JSON.parse(this.entity.value as string);
    } else {
      this.valueObj = {
        calculatedValue: '',
        editedValue: '',
        value: this.entity.value
      };
    }

    this.entity.editedValue = this.valueObj.editedValue;
    this.modelValue = this.valueObj.value;

    this.entity.isCalculating = false;
  }
}

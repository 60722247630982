<h3>Financial Reports</h3>

<ul ngbNav #reports="ngbNav" [destroyOnHide]="false" (navChange)="handleTabsChanged()" class="nav-tabs">
  <li ngbNavItem>
    <a ngbNavLink>Fees</a>
    <ng-template ngbNavContent>
      <wm-daily-fees #dailyFeesReport></wm-daily-fees>
    </ng-template>
  </li>
  <li ngbNavItem>
    <a ngbNavLink>Fee Recipients</a>
    <ng-template ngbNavContent>
      <wm-fee-recipients-report #feeRecipientReport></wm-fee-recipients-report>
    </ng-template>
  </li>
  <li ngbNavItem>
    <a ngbNavLink>Payments</a>
    <ng-template ngbNavContent>
      <wm-payments
        #paymentsReport
        (itemRefunded)="loadFeeReceipients()"
      ></wm-payments>
    </ng-template>
  </li>
</ul>
<div [ngbNavOutlet]="reports"></div>

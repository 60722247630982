import { Component, OnInit, Input } from '@angular/core';
import { PaymentAccountConfigBaseComponent } from '../payment-account-config-base/payment-account-config-base.component';
import { SecurepayPaymentProcessorAccountConfiguration } from '../../../models/payment-providers/securepay/securepay-payment-processor-account-configuration';
import { UntypedFormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'wm-payment-account-config-securepay',
  templateUrl: './payment-account-config-securepay.component.html',
  styleUrls: ['./payment-account-config-securepay.component.css']
})
export class PaymentAccountConfigSecurepayComponent
  extends PaymentAccountConfigBaseComponent
  implements OnInit {
  @Input() config: SecurepayPaymentProcessorAccountConfiguration;

  constructor(fb: UntypedFormBuilder) {
    super(fb);
  }

  ngOnInit() {
    this.addValidation('billType', Validators.required, this.config.billType);
    this.addValidation(
      'accountName',
      Validators.required,
      this.config.userName
    );
  }
}

import { AvailabilityProvider } from '../../availability-providers';
import { WeekendAvailabilityProviderOptions } from '../weekend-availability-provider';
import { Directive } from "@angular/core";

///This is to only display weekend dates
@Directive()
export class WeekendAvailabilityProvider extends AvailabilityProvider {
  getAvailableDates(
    currentDate: Date,
    endDate: Date,
    options?: Partial<WeekendAvailabilityProviderOptions>
  ): Date[] {
    return null;
  }
  getAvailableTimes(
    currentDate: Date,
    options?: Partial<WeekendAvailabilityProviderOptions>
  ): Date[] {
    return null;
  }
}

import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { DataEntity } from 'src/app/models/data-entities';
import { DataEntityFactory } from 'src/app/services';
import { DataEntityLayoutModel } from 'src/app/models/activities';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'wm-contractor-custom-field-list',
  templateUrl: './contractor-custom-field-list.component.html',
  styleUrls: ['./contractor-custom-field-list.component.css']
})
export class ContractorCustomFieldListComponent implements OnInit, OnChanges {
  @Input() form: UntypedFormGroup;
  @Input() customFields: {
    createdBy: string;
    createdOn: string;
    dataEntityType: string;
    fieldValue: string;
    modifiedBy: string;
    modifiedOn: string;
    templateCode: string;
    label: string;
    dataEntityDef?: DataEntity;
  }[];
  @Input() isReadOnly = false;

  customDataEntities: DataEntityLayoutModel[];

  constructor(private _fb: UntypedFormBuilder) {}

  persistValues(): {
    createdBy: string;
    createdOn: string;
    dataEntityType: string;
    fieldValue: string;
    modifiedBy: string;
    modifiedOn: string;
    templateCode: string;
    label: string;
  }[] {
    return this.customDataEntities.map(de => {
      const field = this.customFields.find(
        f => f.templateCode === de.entity.templateCode
      );

      if (field) {
        if (de.entity.value instanceof Object) {
          field.fieldValue = JSON.stringify(de.entity.value);
        } else {
          field.fieldValue = de.entity.value;
        }
      }

      return field;
    });
  }

  ngOnInit() {
    if (this.customFields) {
      this.setCustomDataEntities();
    }
  }

  setCustomDataEntities() {
    if (this.customFields) {
      this.customDataEntities = this.customFields.sort().map((field, index) => {
        const deRef = field.dataEntityDef
          ? { ...field.dataEntityDef, value: field.fieldValue }
          : {
              label: field.label,
              value: field.fieldValue,
              templateCode: field.templateCode
            };

        const de = DataEntityFactory.createDataEntity(
          field.dataEntityType,
          deRef
        );

        if (de) {
          de.isRequired = false;

          const deValidators = de.getValidators();

          if (this.form) {
            if (!this.form.controls[field.templateCode]) {
              this.form.addControl(
                field.templateCode,
                this._fb.control('', deValidators)
              );
            }
          }

          return new DataEntityLayoutModel({
            entity: de,
            displayOrder: index
          });
        }
      });
    }
  }

  ngOnChanges(c: SimpleChanges) {
    if (c.customFields) {
      this.setCustomDataEntities();
    }
  }
}

export class RegistrationDocument {
    id: string;
    documentName: string;
    registrationId: string;
    documentPath: string;
    createdOn: string;
    createdBy: string;
    modifiedOn: string;
    modifiedBy: string;
    editing: boolean;
  
    constructor(options?: Partial<RegistrationDocument>) {
      if (options) {
        Object.assign(this, options);
      }
    }
  }
<h3>Users</h3>

<div
  *ngIf="statusMessage && statusMessage !== null && statusMessage !== ''"
  [class]="alertClass"
  role="alert"
>
  {{ statusMessage }}
</div>

<wm-user-search
  (searchStarted)="users = null"
  (searchFinished)="users = $event"
>
</wm-user-search>

<wm-user-list [users]="users"></wm-user-list>

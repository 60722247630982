import { Component, OnInit } from '@angular/core';
import { ActivityView } from '../../../../../views/master-views/app.view/app.view.component';
import { Validators } from '@angular/forms';
import { CalculatedValueDataEntity } from 'src/app/models/data-entities';
import { RenewalDecisionActivity } from 'src/app/models/activities/renewal-decision-activity';

@Component({
  selector: 'wm-renewal-decision-activity-input',
  templateUrl: './renewal-decision-activity-input.component.html',
  styleUrls: ['./renewal-decision-activity-input.component.css']
})
export class RenewalDecisionActivityInputComponent extends ActivityView
  implements OnInit {
  constructor() {
    super();
  }

  ngOnInit() {
    this.showNext.emit(true);

    const effectiveDate = this.activity.model
      .getEntities()
      .find(e => e.templateCode.includes('EFFECTIVE_DATE'));
    const status = this.activity.model
      .getEntities()
      .find(e => e.templateCode.includes('STATUS'));

    const validators = effectiveDate.getValidators();
    this.form.controls[status.templateCode].valueChanges.subscribe(
      newStatus => {
        const effectiveControl = this.form.controls[effectiveDate.templateCode];

        if (newStatus === 'Approved') {
          effectiveDate.isRequired = true;
          effectiveControl.setValidators(validators);
        } else if (newStatus === 'Denied') {
          effectiveDate.isRequired = false;
          const newValidators = validators.filter(
            v => v.toString() !== Validators.required.toString()
          );

          effectiveControl.setValidators(newValidators);
        }

        effectiveControl.updateValueAndValidity({ emitEvent: false});
      }
    );

    // if the workflow designer wanted the expiration date to be hidden,
    // then hide it
    const activity = this.activity as RenewalDecisionActivity;
    if (activity.hideExpirationDate) {
      const expirationDate = activity.model
        .getEntities()
        .find(e =>
          e.templateCode.includes('EXPIRATION_DATE')
        ) as CalculatedValueDataEntity;
      expirationDate.visibleToUser = false;
    }
  }
}

import {
  ActivityModel,
  FormActivity,
  FormActivityModel,
  ScreenActivity,
  SingleColumnFormLayoutModel,
  TemplateModel,
  TemplatePage
} from '.';
import { ListDataEntity } from '../data-entities';
import { ConditionTarget } from 'src/app/components/system/condition-builder/condition-builder.model';
import { AgendaQueueItem, AgendaType } from '../agenda';

export class AgendaItemActivity extends ScreenActivity<
  AgendaItemActivityModel
> {
  selectedAgendaTypeId: string;
  availableAgendaTypes: AgendaType[];
  requiresBallot: boolean;
  agendaItemTemplate: string;
  AgendaTypeDE: ListDataEntity;

  constructor(options?: Partial<AgendaItemActivity>) {
    super(options);

    this.type = 'agenda-item-activity';
    this.name = 'Agenda item';
    this.description = 'Add an item to an agenda queue';

    if (options) {
      Object.assign(this, options);
    }

    this.model = new AgendaItemActivityModel(options ? options.model : null);

    this.model.screenName = this.model.screenName || 'Agenda Item';
  }
}

export class AgendaItemActivityModel extends FormActivityModel {
  templateModel: TemplateModel;
  documentPath: string;
  customNameCriteria: ConditionTarget[];
  evaluatedDocName: string;

  queueItem: AgendaQueueItem;

  reset() {
    this.templateModel = {
      pages: [],
      templateDocName: null,
      templateDocPath: null
    };
  }

  constructor(options: { [key: string]: any }) {
    super(options);
    this.queueItem = new AgendaQueueItem();

    if (options && options.queueItem) {
      Object.assign(this.queueItem, options.queueItem);
    }
  }
}

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { WorkflowValidationService } from 'src/app/services/workflow-validation.service';
import { ValidationResponse } from 'src/app/models/validation';

@Component({
  selector: 'wm-validation-message',
  templateUrl: './validation-message.component.html',
  styleUrls: ['./validation-message.component.css']
})
export class ValidationMessageComponent implements OnInit {
  @Input() error: ValidationResponse;
  @Output() clickMessage: EventEmitter<ValidationResponse> = new EventEmitter<
    ValidationResponse
  >();

  constructor(public validationSvc: WorkflowValidationService) {}

  ngOnInit() {}
}

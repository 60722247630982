import { User } from '@sentry/browser';
import { Role } from './role';

export class InvitedUser {
  id: string;
  email: string;
  createdBy: string;
  createdOnString: string;
  selectedRoles: Role[];
  acceptedBy: string;
  acceptedOnString: string;
}

export class InviteUserRequest {
  email: string;
  roles: Role[];
  constructor(options?: Partial<InviteUserRequest>) {
    if (options) {
      Object.assign(this, options);
    }
  }
}

export class InviteUserResponse {
  invitationId?: string;
  resultMessage?: string;
  existingUsers?: User[];
}

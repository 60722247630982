import { Component, forwardRef, Inject, Input, OnInit } from '@angular/core';
import {
  FormBuilder,
  UntypedFormGroup,
  UntypedFormControl,
  Validators
} from '@angular/forms';
import { CancelScheduledEmailActivity } from 'src/app/models/activities/cancel-scheduled-email-activity';
import { WorkflowService } from 'src/app/services';
import { ActivityEditorBaseComponent } from '../../activity-editor-base/activity-editor-base.component';

@Component({
  selector: 'wm-cancel-scheduled-email-activity-editor',
  templateUrl: './cancel-scheduled-email-activity-editor.component.html',
  styleUrls: ['./cancel-scheduled-email-activity-editor.component.css']
})
export class CancelScheduledEmailActivityEditorComponent
  extends ActivityEditorBaseComponent
  implements OnInit {
  @Input() activity: CancelScheduledEmailActivity;
  form: UntypedFormGroup = new UntypedFormGroup({
    availableItems: new UntypedFormControl('', Validators.nullValidator)
  });

  constructor(
    @Inject(forwardRef(() => WorkflowService))
    public workflowSvc: WorkflowService
  ) {
    super();
  }

  ngOnInit() {}

  toggleItem(checked: boolean, value: string) {
    if (!this.activity.model.scheduledEmailsToCancel) {
      this.activity.model.scheduledEmailsToCancel = [];
    }
    const idx = this.activity.model.scheduledEmailsToCancel.indexOf(value);
    if (checked) {
      if (idx === -1) {
        this.activity.model.scheduledEmailsToCancel.push(value);
      }
    } else {
      this.activity.model.scheduledEmailsToCancel.splice(idx, 1);
    }
  }

  isSelected(value: string) {
    if (this.activity.model.scheduledEmailsToCancel) {
      return this.activity.model.scheduledEmailsToCancel.indexOf(value) > -1;
    }
    return false;
  }
}

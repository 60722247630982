import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChange
} from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { WorkflowService } from 'src/app/services';

@Component({
  selector: 'wm-my-application-parcels',
  templateUrl: './my-application-parcels.component.html',
  styleUrls: ['./my-application-parcels.component.css']
})
export class MyApplicationParcelsComponent implements OnInit {
  @Input() userId: string;
  @Input() allowMultiples = false;
  @Input() formControls: any[];
  results: { [key: string]: string }[];
  form = new UntypedFormGroup({});
  myParcelsDictionary_cache: {} = {};
  @Output() parcelSelected: EventEmitter<any> = new EventEmitter();

  constructor(private _workflowSvc: WorkflowService, private fb: UntypedFormBuilder) {}

  updateParcelControls() {
    if (this.myparcels && this.formControls) {
      this.myparcels.controls.forEach(myparcel => {
        let selectMyParcel = false;
        this.formControls.forEach(selectedParcel => {
          if (myparcel.value.parcelId === selectedParcel.parcelID) {
            selectMyParcel = true;
          }
        });
        myparcel.patchValue({ selected: selectMyParcel });
      });
    }
  }

  get myparcels() {
    return this.form.controls['myparcels'] as UntypedFormArray;
  }
  ngOnInit() {
    this.loadMyParcels();
    this.form.addControl('myparcels', this.fb.array([]));
  }

  selectParcel(parcel) {
    this.parcelSelected.emit(parcel.value.parcel);
  }

  addParcelControl(parcel: any) {
    const selected = false;
    const myParcelControl = this.fb.group({
      parcelId: [parcel.parcelID],
      selected: [selected],
      parcel: [parcel]
    });
    this.myparcels.push(myParcelControl);
  }

  loadMyParcels() {
    this._workflowSvc.getUserParcels(this.userId).subscribe(results => {
      this.results = results;
      this.results.forEach(r => this.addParcelControl(r));
      this.updateParcelControls();
    });
  }
}

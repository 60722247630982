import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { Role } from '../../../models';
import { WorkflowContextService } from '../../../services';
import { Router } from '@angular/router';

@Component({
  selector: 'wm-role-list',
  templateUrl: './role-list.component.html',
  styleUrls: ['./role-list.component.css']
})
export class RoleListComponent implements OnInit {
  @Input() roles: Role[];
  @Output() removed: EventEmitter<Role> = new EventEmitter<Role>();

  constructor(
    private _context: WorkflowContextService,
    private _router: Router
  ) {}

  buildBaseRoute(): string[] {
    let routeDetails: string[] = [];

    if (this._context.client) {
      routeDetails = [
        'admin',
        'jurisdiction',
        this._context.client.id,
        'users'
      ];
    } else {
      routeDetails = ['admin', 'global'];
    }

    routeDetails = routeDetails.concat(['roles']);

    return routeDetails;
  }

  editRole(role: Role) {
    const routeDetails = this.buildBaseRoute().concat(['edit', role.id]);

    this._router.navigate(routeDetails);
  }

  ngOnInit() {}
}

import {
  ListDataEntity,
  ListOption
} from './../../../../models/data-entities/list-data-entity';
import {
  Component,
  OnInit,
  ViewChild,
  ViewContainerRef,
  Type,
  ComponentFactoryResolver,
  Input,
  ChangeDetectorRef,
  Inject,
  forwardRef,
  OnDestroy,
  EventEmitter,
  Output
} from '@angular/core';
import {
  DataEntity,
  ParentReference
} from '../../../../models/data-entities/data-entity';
import {
  WorkflowService,
  WorkflowContextService,
  DataEntityFactory,
  ValidationService
} from '../../../../services';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
  AbstractControl,
  UntypedFormControl
} from '@angular/forms';
import { DataEntityEditorBaseComponent } from '../data-entity-editor-base/data-entity-editor-base.component';
import { Workflow, WorkflowType } from '../../../../models';
import { Activity, ActivityModel } from 'src/app/models/activities';
import { ActivityDetailsModalComponent } from '../../activities/activity-editor/activity-details-modal/activity-details-modal.component';
import { Observable, of, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'wm-data-entity-editor',
  templateUrl: './data-entity-editor.component.html',
  styleUrls: ['./data-entity-editor.component.css']
})
export class DataEntityEditorComponent implements OnInit, OnDestroy {
  @ViewChild('dataEntityEditor', { read: ViewContainerRef, static: false })
  itemViewContainer: ViewContainerRef;
  @Input()
  dataEntityTemplateCode: string;
  @Input() form: UntypedFormGroup;
  @Input() workflow: Workflow;
  @Input() activity: Activity<ActivityModel>;
  @Input() modalRef: ActivityDetailsModalComponent;
  @Output() updated: EventEmitter<DataEntity> = new EventEmitter<DataEntity>();

  dataEntity: DataEntity;
  dataEntityTypes: { code: string; name: string }[];
  selectedTypeCode: string;
  label: AbstractControl;
  templateCode: AbstractControl;
  previewForm: UntypedFormGroup;
  previewDE: DataEntity;
  isDestroyed: boolean;

  formChangesSub: Subscription;
  reloadingForms = false;

  validationState = {
    reloadingForms: false
  };

  get isPreviewReady() {
    return (this.previewDE && this.previewForm) || false;
  }

  originalTemplateCode: string;
  currentTemplateCode: string;
  changingCustomFieldTemplateCode = false;

  constructor(
    @Inject(forwardRef(() => WorkflowService))
    private _workflowSvc: WorkflowService,
    @Inject(forwardRef(() => WorkflowContextService))
    public _context: WorkflowContextService,
    private _formBuilder: UntypedFormBuilder,
    private componentFactoryResolver: ComponentFactoryResolver,
    private _ref: ChangeDetectorRef,
    private _toastr: ToastrService
  ) {}

  ngOnDestroy() {
    this.formChangesSub.unsubscribe();
    this.isDestroyed = true;
    this._ref.detach(); // do this
  }

  widthClass(hasOtherCol: boolean) {
    return hasOtherCol ? 'col-4' : 'col-8';
  }

  buildPreview() {
    this.previewForm = null;
    this.previewDE = null;
    this.detectChanges();

    if (this.dataEntity) {
      if (!this.dataEntity.hasPreview) {
        return;
      }

      setTimeout(() => {
        if (this.dataEntity) {
          this.previewForm = this._formBuilder.group({
            [this.dataEntityTemplateCode]: this._formBuilder.control(
              null,
              this.dataEntity.getValidators()
            )
          });

          this.previewDE = DataEntityFactory.createDataEntity(
            this.dataEntity.dataEntityTypeCode,
            this.dataEntity
          );
          this.detectChanges();
        }
      }, 500);
    }
  }

  async save() {
    let proceed: boolean;

    if (
      this.dataEntity.isRegistrationCustomField &&
      this.originalTemplateCode &&
      this.originalTemplateCode !== '' &&
      this.currentTemplateCode !== this.originalTemplateCode
    ) {
      proceed = await this._workflowSvc
        .queueCustomFieldTemplateCodeRename(
          this.currentTemplateCode,
          this.originalTemplateCode,
          this.workflow.id
        )
        .toPromise();
    } else {
      proceed = true;
    }

    if (proceed) {
      this.originalTemplateCode = this.dataEntity.templateCode;
      this.currentTemplateCode = this.dataEntity.templateCode;
      this.updated.emit(this.dataEntity);
      this.dataEntity = null;
      this.dataEntityTemplateCode = null;
      this.detectChanges();
    } else {
      this._toastr.error(
        'Failed to prepare the associated data updates. Please contact support if this error persists.',
        `${this.dataEntity.label} was not saved`,
        { disableTimeOut: true }
      );
    }
  }

  changeType(getDataEntity?: Observable<DataEntity>) {
    this._workflowSvc
      .createDataEntity(this.selectedTypeCode, this.activity)
      .subscribe(entity => {
        if (this.dataEntity) {
          Object.assign(entity, {
            label: this.dataEntity.label,
            templateCode: this.dataEntity.templateCode,
            instructions: this.dataEntity.instructions,
            isRequired: this.dataEntity.isRequired
          });
        }

        if (getDataEntity) {
          getDataEntity = of(entity);

          this.finishDataEntityInit(getDataEntity);
        } else {
          this.dataEntity = entity;
          if (!this.dataEntity.parent) {
            this.dataEntity.parent = new ParentReference({
              id: this.activity.id
            });
          }

          this.loadDataEntityEditor();
        }
      });
  }

  resetFormValidation() {
    if (this.form && this.dataEntity) {
      this.reloadingForms = true;
      this.validationState.reloadingForms = true;
      this.dataEntity.origLabel = this.dataEntity.label;
      const keys = Object.keys(this.form.controls);

      keys.forEach(control => {
        this.form.removeControl(control);
      });

      this.form.addControl(
        'label',
        this._formBuilder.control(this.dataEntity.label, Validators.required)
      );
      this.form.addControl(
        'dataEntityType',
        this._formBuilder.control(
          this.dataEntity.dataEntityTypeCode,
          Validators.nullValidator
        )
      );
      this.form.addControl(
        'instructions',
        this._formBuilder.control(
          this.dataEntity.instructions,
          Validators.nullValidator
        )
      );
      this.form.addControl(
        'isRequired',
        this._formBuilder.control(
          this.dataEntity.isRequired,
          Validators.nullValidator
        )
      );
      this.form.addControl(
        'isSearchable',
        this._formBuilder.control(
          this.dataEntity.isSearchable,
          Validators.nullValidator
        )
      );
      this.form.addControl(
        'isSensitive',
        this._formBuilder.control(
          this.dataEntity.isSearchable,
          Validators.nullValidator
        )
      );
      this.form.addControl(
        'templateCode',
        new UntypedFormControl(this.dataEntity.templateCode, {
          updateOn: 'blur',
          validators: Validators.required,
          asyncValidators:
            (!this.dataEntity.isSystemEntity || !this._context.client) &&
            this.dataEntity.allowTemplateCodeEdit
              ? [
                  ValidationService.templateCodeValidator(
                    this._workflowSvc,
                    this.workflow.id,
                    this.dataEntity.origLabel,
                    this.validationState
                  )
                ]
              : []
        })
      );

      this.form.get('templateCode').valueChanges.subscribe(newValue => {
        if (
          this.dataEntity.isRegistrationCustomField &&
          this.originalTemplateCode &&
          this.originalTemplateCode !== '' &&
          newValue !== this.originalTemplateCode
        ) {
          this.currentTemplateCode = newValue;

          // display message saying we'll alter data when publishing the template code change
          this.changingCustomFieldTemplateCode = true;
        }
      });

      this.form.addControl(
        'isSystemEntity',
        this._formBuilder.control(
          this.dataEntity.isSystemEntity,
          Validators.nullValidator
        )
      );

      this.label = this.form.controls['label'];
      this.templateCode = this.form.controls['templateCode'];

      this.templateCode.valueChanges.subscribe(changes => {
        this.dataEntityTemplateCode = changes;
      });

      if (this.dataEntity) {
        if (this.form.controls['dataEntityType']) {
          if (
            this._context.client &&
            (this.dataEntity.isSystemEntity || false)
          ) {
            this.form.controls['dataEntityType'].disable();
          } else {
            this.form.controls['dataEntityType'].enable();
          }
        }

        if (this.form.controls['templateCode']) {
          if (
            this._context.client &&
            (this.dataEntity.isSystemEntity ||
              false ||
              !this.dataEntity.allowTemplateCodeEdit)
          ) {
            this.form.controls['templateCode'].disable();
          } else {
            this.form.controls['templateCode'].enable();
          }
        }

        if (this.form.controls['isRequired']) {
          if (
            this._context.client &&
            (this.dataEntity.isSystemEntity || false)
          ) {
            this.form.controls['isRequired'].disable();
          } else {
            this.form.controls['isRequired'].enable();
          }
        }
      }

      // this horrible hack forces the form to re-render so that it gets the new validators 😩
      setTimeout(() => {
        this.reloadingForms = false;
        this.validationState.reloadingForms = false;
        this.detectChanges();
        this.form.updateValueAndValidity();
      }, 0);
    }
  }

  detectChanges() {
    if (!this.isDestroyed) {
      this._ref.detectChanges();
    }
  }

  loadDataEntityEditor() {
    let compType: Type<DataEntityEditorBaseComponent>;

    compType = DataEntityFactory.createEditComponent(this.dataEntity);
    if (compType && this.itemViewContainer) {
      const componentFactory = this.componentFactoryResolver.resolveComponentFactory<
        DataEntityEditorBaseComponent
      >(compType);
      this.itemViewContainer.clear();

      // wait until after destroying the last component before reseting
      this.resetFormValidation();

      const componentRef = this.itemViewContainer.createComponent(
        componentFactory
      );
      componentRef.instance.dataEntity = <DataEntity>(this.dataEntity || {});
      componentRef.instance.form = this.form;
      componentRef.instance.workflow = this.workflow;
      componentRef.instance.activity = this.activity;
      componentRef.instance.closeRequested.subscribe(
        this.cancelRequested.bind(this)
      );
      componentRef.instance.buildPreview.subscribe(
        this.buildPreview.bind(this)
      );
      componentRef.instance.updated.subscribe(e => {
        this.dataEntity = e;
        this.updated.emit(e);
      });
    }

    if (this.form) {
      this.form.updateValueAndValidity();
    }
    this.detectChanges();
    this.buildPreview();
  }

  cancelRequested() {
    this.modalRef.close();
  }

  sanitize() {
    // remove any html - this is an insecure aproach for preview only. the value is sanitized server side as well
    const ctrl = this.form.controls['label'];
    if (ctrl.value) {
      ctrl.patchValue(ctrl.value.replace(/<[^>]+>/g, ''));
    }
  }

  ngOnInit() {
    this.resetFormValidation();
    if (this.form) {
      this.formChangesSub = this.form.valueChanges
        .pipe(distinctUntilChanged(), debounceTime(200))
        .subscribe(() => {
          this.buildPreview();
        });
    }

    this._workflowSvc
      .getDataEntityTypes(this._context.client)
      .subscribe(dataEntityTypes => {
        if (this.isDestroyed) {
          return;
        }

        const wfType: WorkflowType = this.workflow
          ? this.workflow.workflowType
          : null;
        const contractorDataEntities: string[] = [
          WorkflowService.DATA_ENTITIES.ContractorDocument.code
        ];
        const isContractorWF =
          wfType === WorkflowType.ContractorRegistration ||
          wfType === WorkflowType.ContractorRenewal;
        const docDECode = WorkflowService.DATA_ENTITIES.Document.code;

        this.dataEntityTypes = dataEntityTypes.filter(de => {
          const isContractorDE = contractorDataEntities.indexOf(de.code) !== -1;

          // remove DEs that are hidden
          if (de.hideInEditor) {
            return false;
          }
          // If it isn't a contractor workflow and it isn't a contractor de then display
          if (!isContractorWF) {
            if (!isContractorDE) {
              return true;
            } else {
              return false;
            }
          } else {
            // If it is a contractor workflow then don't display document data entity
            if (de.code === docDECode) {
              return false;
            } else {
              return true;
            }
          }
        });

        this.initDataEntity();
      });
  }

  migrateYesNoToList() {
    this.selectedTypeCode = WorkflowService.DATA_ENTITIES.ListData.code;
    this.changeType();
    (<ListDataEntity>this.dataEntity).listTextValues = [
      new ListOption({ value: 'Yes', text: 'Yes' }),
      new ListOption({ value: 'No', text: 'No' })
    ];
  }

  initDataEntity() {
    let getDataEntity: Observable<DataEntity>;

    // reset preview details
    this.previewDE = null;
    this.previewForm = null;

    if (this.dataEntityTemplateCode) {
      getDataEntity = of(
        this.activity.model
          .getEntities()
          .find(f => f.templateCode === this.dataEntityTemplateCode)
      );

      this.finishDataEntityInit(getDataEntity);
    } else {
      const dataEntity = DataEntityFactory.createDataEntity(
        WorkflowService.DATA_ENTITIES.FreeFormText.code
      );
      getDataEntity = of(dataEntity);

      this.selectedTypeCode = dataEntity.dataEntityTypeCode;

      this.changeType(getDataEntity);
    }
  }

  finishDataEntityInit(getDataEntity: Observable<DataEntity>) {
    getDataEntity.subscribe(de => {
      if (de) {
        this.dataEntity = de;
        if (!this.dataEntity.parent) {
          this.dataEntity.parent = new ParentReference({
            id: this.activity.id
          });
        }
        if (
          this.dataEntity.dataEntityTypeCode ===
          WorkflowService.DATA_ENTITIES.YesNo.code
        ) {
          this.migrateYesNoToList();
        }

        this.selectedTypeCode = this.dataEntity.dataEntityTypeCode;

        this.originalTemplateCode = this.dataEntity.templateCode;
        this.currentTemplateCode = this.dataEntity.templateCode;

        this.loadDataEntityEditor();
      }

      if (this.form) {
        this.form.updateValueAndValidity();
      }
    });
  }
}

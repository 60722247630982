<div>
  <label [for]="for" class="text-label mb-0">
    {{ entity.label }}
    <span *ngIf="entity.isRequired && !displayOnly" class="text-red">*</span>
  </label>

  <wm-help-text
    class="ml-1"
    *ngIf="entity.instructions"
    placement="right"
    [helpText]="entity.instructions"
  ></wm-help-text>
</div>

import { Injectable } from '@angular/core';
import esri = __esri;
import { EsriLoaderService } from './esri-loader.service';
import { MapLayer } from 'src/app/models';
import { Dictionary } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class MapImageLayerService {
  _cacheLayer: Dictionary<esri.MapImageLayer> = {};

  constructor(private esriLoaderService: EsriLoaderService) {}

  async getMapServiceInfo(url: string): Promise<esri.MapImageLayer> {
    const [MapImageLayer] = await this.esriLoaderService.LoadModules([
      'esri/layers/MapImageLayer'
    ]);

    const lyr: esri.MapImageLayer = new MapImageLayer({
      url: url
    });

    await lyr.load(); // http request

    return lyr;
  }

  getLayers(svc: esri.MapImageLayer): Array<MapLayer> {
    const layers: any = svc.allSublayers; // HACK: the .td file doesn't have the collections specified in a way that works with TS
    return layers.items.map(lyr => {
      const l = new MapLayer();
      l.id = lyr.id;
      l.name = lyr.title;
      l.alias = lyr.title;
      l.visible = lyr.visible;
      return l;
    });
  }

  async getParcelGeometryWgs84(
    parcelId: string,
    serviceUrl: string,
    parcelLayerName: string,
    parcelFieldName: string
  ): Promise<any> {
    const [
      Query,
      QueryTask,
      // webMercatorUtils,
      MapImageLayer,
      SpatialReference
    ] = await this.esriLoaderService.LoadModules([
      'esri/tasks/support/Query',
      'esri/tasks/QueryTask',
      // "esri/geometry/support/webMercatorUtils",
      'esri/layers/MapImageLayer',
      'esri/geometry/SpatialReference'
    ]);

    let lyr = this._cacheLayer[serviceUrl];

    if (!lyr) {
      lyr = new MapImageLayer({
        url: serviceUrl
      });
      await lyr.load();

      this._cacheLayer[serviceUrl] = lyr;
    }

    // dig up the layer id of the parcel layer
    const parcelLayer = lyr.allSublayers.find(l => {
      return l.title === parcelLayerName;
    });
    if (!parcelLayer) {
      console.error('Parcel layer not found');
    }

    // fix quote characters, glue list together for use in a query
    const pidQuoted = parcelId.replace(`'`, `''`);

    const query = new Query({
      outSpatialReference: SpatialReference.WGS84,
      returnGeometry: true,
      outFields: parcelFieldName,
      where: `${parcelFieldName} = '${pidQuoted}'`
    });

    const queryTask = new QueryTask({
      url: parcelLayer.url
    });

    const response = await queryTask.execute(query);
    // HINT: if the querytask has an error, sometimes it appears as "Uncaught (in promise): Cancel Error" with nothing helpful to debug.

    if (response.features.length === 0) {
      return null;
    }

    const featureGeom = response.features[0].geometry;
    return featureGeom.toJSON();
  }
}

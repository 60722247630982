import {
  Component,
  forwardRef,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  FormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import {
  AgendaItemActivity,
  SingleColumnFormLayoutModel,
  TemplateModel
} from 'src/app/models/activities';
import { AgendaType } from 'src/app/models/agenda';
import { DataEntity } from 'src/app/models/data-entities';
import {
  DataService,
  TemplateService,
  WorkflowContextService,
  WorkflowService
} from 'src/app/services';
import { ActivityEditorBaseComponent } from '../../activity-editor-base/activity-editor-base.component';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ChangeEvent } from '@ckeditor/ckeditor5-angular';

@Component({
  selector: 'wm-agenda-item-activity-editor',
  templateUrl: './agenda-item-activity-editor.component.html',
  styleUrls: ['./agenda-item-activity-editor.component.css']
})
export class AgendaItemActivityEditorComponent
  extends ActivityEditorBaseComponent
  implements OnInit, OnDestroy {
  @Input() activity: AgendaItemActivity;
  @ViewChild('agendaItemTemplate', { static: false }) agendaItemTemplate: any;

  loadFull = false;
  ballotForm: UntypedFormGroup;
  activityForm: UntypedFormGroup;
  entities: DataEntity[];
  agendaItemTemplateField: AbstractControl;

  public Editor = ClassicEditor;
  editorConfig = {
    toolbar: [
      'insertTable',
      'alignment',
      'heading',
      '|',
      'bold',
      'italic',
      'link',
      '|',
      'bulletedList',
      'numberedList',
      '|',
      'undo',
      'redo'
    ]
  };

  constructor(
    @Inject(forwardRef(() => TemplateService))
    public _templateSvc: TemplateService,
    @Inject(forwardRef(() => WorkflowService))
    private _workflowSvc: WorkflowService,
    @Inject(forwardRef(() => DataService))
    private _dataSvc: DataService,
    @Inject(forwardRef(() => WorkflowContextService))
    public _context: WorkflowContextService,
    private _fb: UntypedFormBuilder
  ) {
    super();
  }

  ngOnInit() {
    // this.getClientAgendaTypes();
    // this.activity.model.formLayoutModel = new SingleColumnFormLayoutModel(
    //   this.activity
    // );

    this.ballotForm = this._fb.group({});

    if (this.loadFull) {
      this.init();
    }
  }

  ngOnDestroy(): void {
    this.loadFull = false;
  }

  // getClientAgendaTypes() {
  //   this._dataSvc.getAgendaTypes(this._context.client.id).subscribe(result => {
  //     this.agendaTypes = result;
  //   });
  // }

  // agenda item editor----------------------------------------------------->
  showDetails(e) {
    this.loadFull = true;
    this.init();
  }

  cleanup() {
    this.loadFull = false;
    this.activityForm = null;
  }

  init() {
    const activityGroup = this._fb.group({
      agendaItemTemplate: ['', Validators.required]
    });

    this.activityForm = activityGroup;

    this.agendaItemTemplateField = this.activityForm.controls[
      'agendaItemTemplate'
    ];
    this.agendaItemTemplateField.setValue(
      this.activity.agendaItemTemplate || ''
    );

    this._workflowSvc
      .getDataEntitiesBeforeMe(this.workflow, this.activity)
      .subscribe(entities => {
        this.entities = entities;
      });
  }

  insert(de: DataEntity) {
    const editor = this.agendaItemTemplate.editorInstance;

    editor.model.change(writer => {
      const insertPosition = editor.model.document.selection.getFirstPosition();
      writer.insertText(this._templateSvc.generateToken(de), insertPosition);
    });
  }

  onChange({ editor }: ChangeEvent) {
    const data = editor.data.get();
    this.activity.agendaItemTemplate = data;
  }
  // <-----------------------------------------------------agenda item editor

  requiresBallotChanged() {
    if (!this.activity.requiresBallot) {
      this.activity.model.reset();
      this.activity.model.customNameCriteria = [];
      this.activity.model.documentPath = '';
    }
  }
}

import { Component, OnInit, EventEmitter } from '@angular/core';
import { ActivityView } from '../../../../../views/master-views/app.view/app.view.component';
import { PrintTemplateActivity } from '../../../../../models/activities';
import { FormGroup } from '@angular/forms';
import { WorkflowContextService } from '../../../../../services';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'wm-print-template-activity-view',
  templateUrl: './print-template-activity-view.component.html',
  styleUrls: ['./print-template-activity-view.component.css']
})
export class PrintTemplateActivityViewComponent extends ActivityView
  implements OnInit {
  activity: PrintTemplateActivity;
  documentPath: SafeUrl;
  documentName: string;

  constructor(private sanitizer: DomSanitizer) {
    super();
  }

  ngOnInit() {
    this.documentName = this.activity.model.evaluatedDocName;
    this.documentPath = this.sanitizer.bypassSecurityTrustResourceUrl(
      this.activity.model.documentPath
    );
  }
}

import { forEach } from 'lodash';
import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidationErrors,
  Validators
} from '@angular/forms';
import { Role } from 'src/app/models';
import {
  ExportFormat,
  MonthlyFrequencyOptions,
  ScheduledExport,
  ScheduledExportEmailOptions,
  ScheduleFrequencyType,
  ShapefileValidationCriteria,
  WeeklyFrequencyOptions
} from 'src/app/models/scheduled-export';
import {
  DataService,
  SecurityService,
  Utilities,
  ValidationService,
  WorkflowContextService
} from 'src/app/services';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ChangeEvent } from '@ckeditor/ckeditor5-angular';
import {
  FilterBuilderFilter,
  FilterBuilderFilterFlat,
  FilterBuilderOutput,
  FilterBuilderParam,
  translateLegacyToFilterBuilder
} from 'src/app/models/filter-builder';
import { ColumnOption } from 'src/app/models/grid-settings';
import { ToastrService } from 'ngx-toastr';
import { Output, EventEmitter } from '@angular/core';
import { ModalConfirmComponent } from 'src/app/components/system/modal-confirm/modal-confirm.component';
import { ScheduleEditorComponent } from 'src/app/components/system/schedule-editor/schedule-editor.component';
import { SavedFilter, StoredFilter } from 'src/app/models/saved-filter';
import {
  ExportEntityOption,
  ItemSearchFilter,
  ItemSearchOptionField
} from 'src/app/components/filter-list/models/filterClasses';

@Component({
  selector: 'wm-scheduled-export-editor',
  templateUrl: './scheduled-export-editor.component.html',
  styleUrls: ['./scheduled-export-editor.component.css']
})
export class ScheduledExportEditorComponent
  implements OnInit, AfterViewChecked, OnDestroy {
  @Input() scheduledExport: ScheduledExport;
  @Input() params: FilterBuilderParam[];
  @Input() currentFilters: FilterBuilderOutput | ItemSearchOptionField[];
  @Input() staticFilters: ItemSearchOptionField[] | ItemSearchFilter;
  @Input() currentColumnOptions: ColumnOption[];
  @Input() componentId: string;
  @Input() form: UntypedFormGroup;
  @Input() shapefileValidationCriteria: ShapefileValidationCriteria;
  @Input() filterForm: UntypedFormGroup;

  @Output() saved: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('scheduleEditor', { static: false })
  scheduleEditor: ScheduleEditorComponent;
  @ViewChild('deleteConfirmModal', { static: true })
  deleteConfirmModal: ModalConfirmComponent;
  isNew: boolean;
  defaultFilters: FilterBuilderFilter[];
  normalizedStaticFilters: FilterBuilderFilter[];
  savedFilter: SavedFilter = new SavedFilter();
  columnOptions: ColumnOption[] = [];

  roleRecipientForm: UntypedFormGroup;
  toEmailForm: UntypedFormGroup;
  emailBodyField: AbstractControl;

  availableRoles: Role[];
  selectedRole: string;

  public Editor = ClassicEditor;
  editorConfig = {
    toolbar: [
      'heading',
      '|',
      'bold',
      'italic',
      'link',
      '|',
      'bulletedList',
      'numberedList',
      '|',
      'undo',
      'redo'
    ]
  };

  constructor(
    private _context: WorkflowContextService,
    private _dataSvc: DataService,
    private _fb: UntypedFormBuilder,
    private _securitySvc: SecurityService,
    private _ref: ChangeDetectorRef,
    private _toastr: ToastrService
  ) {}

  ngOnInit() {
    // break reference to parents' columnOptions so destroying this component can
    // once again have the parents column options reset as the default
    this.columnOptions = JSON.parse(JSON.stringify(this.currentColumnOptions));

    if (!this.form) {
      this.form = new UntypedFormGroup({});
    }

    if (!this.scheduledExport) {
      this.isNew = true;
      this.initScheduledExport();
    } else {
      this.isNew = false;

      const storedFilter = new StoredFilter();

      storedFilter.id = this.scheduledExport.savedFilter.id;
      storedFilter.name = this.scheduledExport.savedFilter.name;
      storedFilter.type = this.scheduledExport.savedFilter.type;
      storedFilter.clientId = this.scheduledExport.savedFilter.clientId;
      storedFilter.filters = this.scheduledExport.savedFilter.filters || [];
      storedFilter.columns = this.scheduledExport.savedFilter.columns || [];
      storedFilter.exportColumns =
        this.scheduledExport.savedFilter.exportColumns || [];

      this.savedFilter = SavedFilter.restore(storedFilter);
    }

    this.parseAndTranslateStaticFilters();

    this.initFiltersAndSettings();
    this.form.addControl('name', this._fb.control('', [Validators.required]));
    this.form.addControl(
      'recipientSpecified',
      this._fb.control('', this.recipientSpecified.bind(this))
    );
    this.form.addControl(
      'subject',
      this._fb.control('', [Validators.required])
    );
    this.form.addControl('body', this._fb.control('', [Validators.required]));

    const exportFormatValidators = [Validators.nullValidator];

    if (this.shapefileValidationCriteria) {
      this.shapefileValidationCriteria.exportedFieldCount = this.scheduledExport.savedFilter.exportColumns.length;
      exportFormatValidators.push(
        ValidationService.shapefileExportValidator(
          this.shapefileValidationCriteria
        )
      );
    }

    this.form.addControl(
      'exportFormat',
      this._fb.control('', exportFormatValidators)
    );

    if (this.filterForm) {
      this.form.addControl('filterValidators', this.filterForm);
    }

    this.updateIsGeoJSONSelected();
    this.roleRecipientForm = this._fb.group({
      role: this._fb.control('', Validators.required)
    });

    this.toEmailForm = this._fb.group({
      newEmailAddress: this._fb.control(
        '',
        ValidationService.emailOrEntityValidator
      ),
      newEmailType: this._fb.control('to', Validators.nullValidator)
    });

    this.emailBodyField = this.form.controls['body'];

    if (this.scheduledExport.scheduledExportEmailOptions.body != null) {
      this.emailBodyField.setValue(
        this.scheduledExport.scheduledExportEmailOptions.body
      );
    }

    this._securitySvc.getRoles(this._context.client).subscribe(roles => {
      this.availableRoles = roles;
    });
  }

  ngAfterViewChecked() {
    this._ref.detectChanges();
  }

  ngOnDestroy() {
    this.form.removeControl('recipientSpecified');
    this.form.removeControl('filterValidators');
    this.form.removeControl('exportFormat');
  }

  initScheduledExport() {
    this.scheduledExport = null;

    this.scheduledExport = new ScheduledExport({
      isEnabled: true,
      scheduledExportEmailOptions: new ScheduledExportEmailOptions(),
      exportFormat: ExportFormat.Excel,
      frequencyType: ScheduleFrequencyType.Weekly,
      scheduleFrequencyOptions: new WeeklyFrequencyOptions(),
      savedFilter: new SavedFilter()
    });

    this.savedFilter = this.scheduledExport.savedFilter;
  }

  initFiltersAndSettings() {
    let defaultFilters: FilterBuilderFilter[] = [];

    if (this.isNew) {
      // take current filters from parent component (whatever is currently being used on the grid)
      // and translate to default filter to pass to filter builder
      if (this.currentFilters) {
        // is this a FilterBuilderOutput or an ItemSearchOptionField[] ?
        if (Array.isArray(this.currentFilters)) {
          // if ItemSearchOptionField[], call new method translateLegacyToFilterBuilder()
          defaultFilters = this.getFilterBuilderFilters(
            translateLegacyToFilterBuilder(
              this.currentFilters as ItemSearchOptionField[]
            )
          );
        } else {
          defaultFilters = this.getFilterBuilderFilters(this.currentFilters);
        }

        // set the scheduled export saved filter
        this.scheduledExport.savedFilter.name =
          'scheduled-export-' + this.componentId;
        this.scheduledExport.savedFilter.clientId = this._context.client.id;
        this.scheduledExport.savedFilter.type = this.componentId;
        this.scheduledExport.savedFilter.filters = defaultFilters
          ? defaultFilters.map(fbf => fbf.flatten())
          : null;
        this.scheduledExport.savedFilter.columns = [];
        this.scheduledExport.savedFilter.exportColumns = this.columnOptions
          .filter(cco => cco.includeInExport)
          .map(cco => {
            return { name: cco.name, header: cco.label };
          });
      }

      this.defaultFilters = defaultFilters || [];
    } else {
      if (this.savedFilter.filters) {
        if (this.savedFilter.filters.length > 0) {
          for (const f of this.savedFilter.filters) {
            // static filters that were stored in the scheduled export SavedFilter
            // will come through here, but they won't be in the params, and they shouldn't
            // be added to defaultFilters to pass to filter-builder
            const param = this.params.find(p => p.id === f.paramId);

            // but it's possible they could be in the params if a static filter used the same paramId as
            // a filter in the filter params, if so don't add them to defaultFiters to pass to filter-builder
            let filterIsStatic = false;

            if (this.normalizedStaticFilters) {
              filterIsStatic = !!this.normalizedStaticFilters.find(
                sf => sf.paramId === f.paramId && sf.value === f.value
              );
            }

            if (param && !filterIsStatic) {
              const fbf = new FilterBuilderFilter({
                param: {
                  name: param.name,
                  id: f.paramId,
                  options: param && param.options ? param.options : null
                },
                paramId: f.paramId,
                queryType: f.type,
                value: f.value,
                customValue:
                  f.value.indexOf(',') > -1 ? f.value.split(',') : null
              });

              defaultFilters.push(fbf);
            }
          }
        }
      }

      this.defaultFilters = defaultFilters || [];

      for (const cco of this.columnOptions) {
        cco.checked = false; // keep these false, it isn't used in this context
        cco.includeInExport = !!this.savedFilter.exportColumns.find(
          ec => ec.name.toLowerCase() === cco.name.toLowerCase()
        );
      }

      // update scheduled export with export columns
      this.scheduledExport.savedFilter.exportColumns = this.columnOptions
        .filter(cco => cco.includeInExport)
        .map(cco => ({
          header: cco.label,
          name: cco.name
        }));

      this.scheduledExport.savedFilter.columns = [];
    }
  }

  getFilterBuilderFilters(
    filterBuilderOutput: FilterBuilderOutput
  ): FilterBuilderFilter[] {
    const filters: FilterBuilderFilter[] = [];

    if (filterBuilderOutput) {
      for (const f in filterBuilderOutput) {
        if (f != null) {
          // static filters won't be in the params, and they should provide an empty
          // string for FilterBuilderFilter.param.name
          const param = this.params.find(
            p => p.id === filterBuilderOutput[f].id
          );

          const fbf = new FilterBuilderFilter({
            param: {
              name: param ? param.name : '',
              id: filterBuilderOutput[f].id,
              options: param && param.options ? param.options : null
            },
            paramId: filterBuilderOutput[f].id,
            queryType: filterBuilderOutput[f].type,
            searchOptionMethod: filterBuilderOutput[f].searchOptionMethod,
            value: filterBuilderOutput[f].value,
            customValue:
              filterBuilderOutput[f].value.indexOf(',') > -1
                ? filterBuilderOutput[f].value.split(',')
                : null
          });

          filters.push(fbf);
        }
      }

      return filters;
    }

    return null;
  }

  parseAndTranslateStaticFilters() {
    if (
      this.staticFilters &&
      (this.staticFilters as ItemSearchOptionField[]).length > 0
    ) {
      let staticFilters: FilterBuilderFilter[] = [];

      // are this.StaticFilters an ItemSearchOptionField[] or an ItemSearchFilter ?
      if (Array.isArray(this.staticFilters)) {
        // they are an ItemSearchOptionField[]
        staticFilters = this.getFilterBuilderFilters(
          translateLegacyToFilterBuilder(
            this.staticFilters as ItemSearchOptionField[]
          )
        );
      } else {
        // they are an ItemSearchFilter
        const translatedStaticFilters = this.staticFilters.options.map(
          f => f.field
        );

        staticFilters = this.getFilterBuilderFilters(
          translateLegacyToFilterBuilder(translatedStaticFilters)
        );
      }

      this.normalizedStaticFilters = staticFilters;
    }
  }

  getFilterPrefix(key) {
    const filter = this.params.find(f => f.id == key);
    let name = key;

    if (filter) {
      name = filter.name;
    }

    return name;
  }

  get filterFormKeys() {
    let keys = [];

    if (this.filterForm) {
      keys = Object.keys(this.filterForm.controls);
    }

    return keys;
  }

  handleFiltersChanged(filter: FilterBuilderOutput) {
    // update form components if they exist
    if (this.filterForm) {
      const valKeys = Object.keys(this.filterForm.controls);

      valKeys.forEach(k => {
        this.filterForm.controls[k].setValue('');
        this.filterForm.markAsDirty();
        this.filterForm.markAsTouched();
      });

      if (filter) {
        for (const f in filter) {
          if (f != null) {
            const fCtl: AbstractControl = this.filterForm.controls[f];

            if (fCtl) {
              fCtl.setValue('populated'); // this is set to this because things like exists/not exist don't have a value
              fCtl.markAsDirty();
              fCtl.markAsTouched();
            }
          }
        }
      }
    }

    if (filter && Object.keys(filter).length > 0) {
      const newFilters: FilterBuilderFilterFlat[] = [];

      for (const f in filter) {
        if (f != null) {
          const newFilter = {
            id: '',
            paramId: '',
            type: '',
            queryType: '',
            value: ''
          };

          newFilter.id = Utilities.generateId();
          newFilter.paramId = filter[f].id;
          newFilter.type = filter[f].type;
          newFilter.queryType = filter[f].type;
          newFilter.value = filter[f].value;

          newFilters.push(newFilter);
        }
      }
      this.scheduledExport.savedFilter.filters = [...newFilters];
    } else {
      this.scheduledExport.savedFilter.filters = [];
    }
  }
  updateIsGeoJSONSelected() {
    if (this.shapefileValidationCriteria) {
      this.shapefileValidationCriteria.isGeoJSONSelected = false;

      this.scheduledExport.savedFilter.exportColumns.forEach(c => {
        if (this.shapefileValidationCriteria.geoJSONEntities[c.name]) {
          this.shapefileValidationCriteria.isGeoJSONSelected = true;
        }
      });
      this.shapefileValidationCriteria.exportedFieldCount = this.scheduledExport.savedFilter.exportColumns.length;

      this.form.get('exportFormat').updateValueAndValidity();
      this.form.get('exportFormat').markAsTouched();
    }
  }

  handleColumnsChanged(exportColumns: string[]) {
    if (exportColumns) {
      for (const cco of this.columnOptions) {
        cco.checked = false;
        cco.includeInExport = exportColumns.includes(cco.name);
      }
    }

    // update scheduled export with export columns
    this.scheduledExport.savedFilter.exportColumns = this.columnOptions
      .filter(cco => cco.includeInExport)
      .map(cco => ({
        header: cco.label,
        name: cco.name
      }));
    this.updateIsGeoJSONSelected();
  }

  disable() {
    this.scheduledExport.isEnabled = false;
  }

  enable() {
    this.scheduledExport.isEnabled = true;
  }

  addRole() {
    if (!this.scheduledExport.scheduledExportEmailOptions.roleRecipients) {
      this.scheduledExport.scheduledExportEmailOptions.roleRecipients = [];
    }

    const role = this.availableRoles.find(r => r.id === this.selectedRole);

    if (role) {
      if (
        !this.scheduledExport.scheduledExportEmailOptions.roleRecipients.includes(
          role
        )
      ) {
        this.scheduledExport.scheduledExportEmailOptions.roleRecipients.push(
          role
        );
      }
      this.selectedRole = null;
    }

    // the validator function needs re-bound because for some reason upon destroying and re-initting this component
    // this doesn't stay bound to the validator function and will return validation errors on updateValueAndValidity
    this.form
      .get('recipientSpecified')
      .setValidators(this.recipientSpecified.bind(this));
    this.form.get('recipientSpecified').updateValueAndValidity();
    this.form.get('recipientSpecified').markAsTouched();
  }

  removeRole(role) {
    this.scheduledExport.scheduledExportEmailOptions.roleRecipients.splice(
      this.scheduledExport.scheduledExportEmailOptions.roleRecipients.indexOf(
        role
      ),
      1
    );

    this.form.get('recipientSpecified').updateValueAndValidity();
    this.form.get('recipientSpecified').markAsTouched();
  }

  addEmail() {
    const type = this.toEmailForm.controls['newEmailType'];
    const email = this.toEmailForm.controls['newEmailAddress'];
    switch (type.value) {
      case 'bcc':
        this.addBCCAddress(email.value);
        break;
      case 'cc':
        this.addCCAddress(email.value);
        break;
      case 'to':
        this.addToAddress(email.value);
        break;
      default:
        alert(type.value);
        break;
    }

    email.setValue('');
    type.setValue('to');

    this._ref.detectChanges();
    email.markAsUntouched();
    this.form.updateValueAndValidity();
  }

  addToAddress(emailAddress: string) {
    if (!this.scheduledExport.scheduledExportEmailOptions.toRecipients) {
      this.scheduledExport.scheduledExportEmailOptions.toRecipients = [];
    }

    if (
      !this.scheduledExport.scheduledExportEmailOptions.toRecipients.includes(
        emailAddress
      )
    ) {
      this.scheduledExport.scheduledExportEmailOptions.toRecipients.push(
        emailAddress
      );
    }

    // the validator function needs re-bound because for some reason upon destroying and re-initting this component
    // this doesn't stay bound to the validator function and will return validation errors on updateValueAndValidity
    this.form
      .get('recipientSpecified')
      .setValidators(this.recipientSpecified.bind(this));
    this.form.get('recipientSpecified').updateValueAndValidity();
    this.form.get('recipientSpecified').markAsTouched();
  }

  removeToAddress(emailAddress) {
    this.scheduledExport.scheduledExportEmailOptions.toRecipients.splice(
      this.scheduledExport.scheduledExportEmailOptions.toRecipients.indexOf(
        emailAddress
      ),
      1
    );

    this.form.get('recipientSpecified').updateValueAndValidity();
    this.form.get('recipientSpecified').markAsTouched();
  }

  addCCAddress(emailAddress: string) {
    if (!this.scheduledExport.scheduledExportEmailOptions.ccRecipients) {
      this.scheduledExport.scheduledExportEmailOptions.ccRecipients = [];
    }

    if (
      !this.scheduledExport.scheduledExportEmailOptions.ccRecipients.includes(
        emailAddress
      )
    ) {
      this.scheduledExport.scheduledExportEmailOptions.ccRecipients.push(
        emailAddress
      );
    }
  }

  removeCCAddress(emailAddress) {
    this.scheduledExport.scheduledExportEmailOptions.ccRecipients.splice(
      this.scheduledExport.scheduledExportEmailOptions.ccRecipients.indexOf(
        emailAddress
      ),
      1
    );
  }

  addBCCAddress(emailAddress: string) {
    if (!this.scheduledExport.scheduledExportEmailOptions.bccRecipients) {
      this.scheduledExport.scheduledExportEmailOptions.bccRecipients = [];
    }

    if (
      !this.scheduledExport.scheduledExportEmailOptions.bccRecipients.includes(
        emailAddress
      )
    ) {
      this.scheduledExport.scheduledExportEmailOptions.bccRecipients.push(
        emailAddress
      );
    }
  }

  removeBCCAddress(emailAddress) {
    this.scheduledExport.scheduledExportEmailOptions.bccRecipients.splice(
      this.scheduledExport.scheduledExportEmailOptions.bccRecipients.indexOf(
        emailAddress
      ),
      1
    );
  }

  recipientSpecified(): ValidationErrors | null {
    if (
      this.scheduledExport &&
      this.scheduledExport.scheduledExportEmailOptions &&
      ((this.scheduledExport.scheduledExportEmailOptions.toRecipients &&
        this.scheduledExport.scheduledExportEmailOptions.toRecipients.length >
          0) ||
        (this.scheduledExport.scheduledExportEmailOptions.roleRecipients &&
          this.scheduledExport.scheduledExportEmailOptions.roleRecipients
            .length > 0))
    ) {
      return null;
    } else {
      return { recipientSpecified: true };
    }
  }

  onChange({ editor }: ChangeEvent) {
    const data = editor.data.get();
    this.scheduledExport.scheduledExportEmailOptions.body = data;
  }

  save(deleting = false) {
    this.scheduledExport.scheduleFrequencyOptions = this.scheduleEditor.getOptions();

    this.scheduledExport.frequencyType =
      this.scheduledExport.scheduleFrequencyOptions.type ===
      'weekly-frequency-options'
        ? ScheduleFrequencyType.Weekly
        : this.scheduledExport.scheduleFrequencyOptions.type ===
          'monthly-frequency-options'
        ? ScheduleFrequencyType.Monthly
        : ScheduleFrequencyType.Weekly;

    // we didn't display any static filters in the config modal, but now
    // that we're saving the ScheduledExport, it's time to apply any
    // static filters to the saved filter
    if (this.normalizedStaticFilters) {
      this.scheduledExport.savedFilter.filters = [
        ...(this.scheduledExport.savedFilter
          .filters as FilterBuilderFilterFlat[]),
        ...this.normalizedStaticFilters.map(fbf => fbf.flatten())
      ];
    }

    this.scheduledExport.savedFilter = SavedFilter.store(
      this.scheduledExport.savedFilter
    );

    this._dataSvc.saveScheduledExport(this.scheduledExport).subscribe(() => {
      this._toastr.success(
        deleting ? 'Scheduled export deleted' : 'Scheduled export saved'
      );
      this.saved.emit();
    });
  }

  delete() {
    this.deleteConfirmModal.open();
  }

  confirmDelete() {
    this.scheduledExport.deletedOnString = Utilities.getDateTimeString(
      new Date(),
      this._context.client.timeZoneOffset
    );

    this.save(true);
  }
}

<div class="mb-4">
  <h4 class="mb-2">{{ title }}</h4>

  <div class="row" *ngIf="!areRenewalRegistrations">
    <div class="col-md-6" *ngFor="let registration of contractorRegistrations">
      <wm-contractor-registration-item
        [registration]="registration"
      ></wm-contractor-registration-item>
    </div>
  </div>

  <div class="row" *ngIf="areRenewalRegistrations">
    <div class="col-md-6" *ngFor="let registration of renewalRegistrations">
      <wm-renewal-item [registration]="registration"></wm-renewal-item>
    </div>
  </div>
</div>

import { ListOption } from './../../../../../models/listOption';
import {
  Component,
  OnInit,
  Input,
  ComponentFactoryResolver
} from '@angular/core';
import { ListDataEntity, ControlType } from 'src/app/models/data-entities';
import { DataEntityInputComponent } from '../../data-entity-input/data-entity-input.component';

@Component({
  selector: 'wm-list-data-entity-input',
  templateUrl: './list-data-entity-input.component.html',
  styleUrls: ['./list-data-entity-input.component.css']
})
export class ListDataEntityInputComponent extends DataEntityInputComponent
  implements OnInit {
  @Input() entity: ListDataEntity;
  editMode: boolean;
  originalValue: any;
  selectedEntityValues: ListOption[];
  testTrue = true;
  listEntity: ListDataEntity;
  selectValue: any;

  constructor(componentFactoryResolver: ComponentFactoryResolver) {
    super(componentFactoryResolver);
    this.editMode = false;
  }

  ngOnInit() {
    this.selectedEntityValues = [];

    this.listEntity = this.entity;
    this.listEntity.selectedValues.forEach(e => this.addItem(true, e));

    if (
      !this.entity.externalDataSource ||
      !this.entity.externalDataSource.sourceConfig ||
      (this.entity.canEditExternalValue &&
        this.form.controls[this.entity.templateCode])
    ) {
      this.form.controls[this.entity.templateCode].enable();
    } else {
      this.form.controls[this.entity.templateCode].disable();
    }
  }

  addItem(checked: boolean, value: any) {
    if (!value) {
      // don't do anything if the value is an empty string...
      return;
    }
    this.listEntity.value = '';
    // const b = value; // value.indexOf('{') > -1 ? JSON.parse(value).value : value;
    if (typeof value !== 'string') {
      value = value.value;
    }
    let listItem = this.listEntity.availableListItems.find(
      a => a.value === value
    );

    // if selected item is not in the list, add it
    if (!listItem) {
      listItem = value;
      this.listEntity.listTextValues.push(listItem);
    }

    if (this.entity.controlType === ControlType.Check) {
      if (checked === false) {
        let i = this.selectedEntityValues.indexOf(value);
        let item = null;
        if (i === -1) {
          item = this.selectedEntityValues.find(a => a.value === value);
          i = this.selectedEntityValues.indexOf(item);
        }
        this.selectedEntityValues.splice(i, 1);
      } else {
        this.selectedEntityValues.push(listItem);
      }
    } else {
      this.selectedEntityValues = [];
      this.selectedEntityValues.push(listItem);
    }

    // remove all of the $id attributes before serializing
    this.selectedEntityValues.forEach(f => delete f['$id']);
    this.listEntity.value = JSON.stringify(this.selectedEntityValues);

    if (this.listEntity.controlType !== ControlType.Dropdown) {
      if (this.listEntity.value === '[]') {
        this.form.controls[this.entity.templateCode].setValue('');
      } else {
        this.form.controls[this.entity.templateCode].setValue(
          this.listEntity.value
        );
      }
    } else {
      this.form.controls[this.entity.templateCode].setValue(listItem.value);
    }
  }

  isSelected(value: any): boolean {
    const selected = !!this.selectedEntityValues.find(
      v =>
        v &&
        (v === value.id ||
          v === value.value ||
          v.value === value.value ||
          v.value === value.id)
    );

    return selected;
  }

  change($event, item) {
    this.addItem($event.target.checked, item);
  }
}

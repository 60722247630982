import * as moment from 'moment';

import { AvailabilityProvider } from '../../availability-providers';
import { WeekdayAvailabilityProviderOptions } from './weekday-availability-provider-options';
import { DayOfWeek } from '../../date-validators';
import { Directive } from "@angular/core";

///This is to only show weekday options
@Directive()
export class WeekdayAvailabilityProvider extends AvailabilityProvider {
  getAvailableDates(
    currentDate: Date,
    endDate: Date,
    options?: Partial<WeekdayAvailabilityProviderOptions>
  ): Date[] {
    let startMoment = moment(currentDate);
    let endMoment = moment(endDate);

    let differentDays = endMoment.diff(startMoment, 'days');

    let result: Date[] = [];

    let loopDate: Date;

    for (let x = 0; x < differentDays; x++) {
      loopDate = startMoment
        .clone()
        .add(x, 'days')
        .toDate();
      if (this.validateDate(currentDate, endDate, options)) {
        result.push(loopDate);
      }
      //if (loopDate.getDay() > DayOfWeek.Sunday && loopDate.getDay() < DayOfWeek.Saturday) {
      //    result.push(loopDate);
      //}
    }

    return result;
  }
  getAvailableTimes(
    currentDate: Date,
    options?: Partial<WeekdayAvailabilityProviderOptions>
  ): Date[] {
    return null;
  }
  validateDate(
    date: Date,
    endDate: Date,
    options?: Partial<WeekdayAvailabilityProviderOptions>
  ): boolean {
    let weekday = moment(date).weekday();

    return weekday > DayOfWeek.Sunday && weekday < DayOfWeek.Saturday;
  }
}

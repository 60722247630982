import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { Router } from '@angular/router';
import {
  ButtonStyle,
  ModalConfirmComponent
} from 'src/app/components/system/modal-confirm/modal-confirm.component';
import { AgendaQueueItem } from 'src/app/models/agenda';

@Component({
  selector: 'wm-agenda-queue-item-readonly',
  templateUrl: './agenda-queue-item-readonly.component.html',
  styleUrls: ['./agenda-queue-item-readonly.component.css']
})
export class AgendaQueueItemReadonlyComponent implements OnInit {
  @Input() queueItem: AgendaQueueItem;
  @Input() selected = false;
  @Input() selectable = true;
  @Output() selectedChanged: EventEmitter<{
    id: string;
    isSelected: boolean;
  }> = new EventEmitter<{ id: string; isSelected: boolean }>();

  @ViewChild('minutesModal', { static: false }) minutesModal: ModalConfirmComponent;

  ButtonStyle = ButtonStyle;

  constructor(private _router: Router) {}

  ngOnInit() {}

  viewMinutes() {
    this.minutesModal.open();
  }

  emitSelected() {
    setTimeout(() => {
      this.selectedChanged.emit({
        id: this.queueItem.id,
        isSelected: this.selected
      });
    }, 5);
  }
}

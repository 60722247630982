import { Activity, ActivityModel, ScreenActivity } from './activity';

export class StartWorkflowActivityModel extends ActivityModel {
  introductionText: string;

  constructor(options?: Partial<StartWorkflowActivityModel>) {
    super(options);

    this.screenName = 'Start';

    if (options) {
      Object.assign(this, options);
    }
  }
}

export class StartWorkflowActivity extends ScreenActivity<
  StartWorkflowActivityModel
> {
  constructor(options?: Partial<StartWorkflowActivity>) {
    super(options);

    this.type = 'start-workflow-activity';
    this.name = 'Start';
    this.description = 'Start the workflow';

    if (options) {
      Object.assign(this, options);
    }

    this.model = new StartWorkflowActivityModel(options ? options.model : null);

    this.model.screenName = this.model.screenName || 'Start';
  }
}

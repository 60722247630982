<form [formGroup]="form" *ngIf="processorConfig">
  <div class="form-group">
    <label class="bmd-label-floating" for="redirectURL">
      Redirect URL
    </label>
    <input
      type="text"
      class="form-control"
      formControlName="redirectURL"
      name="redirectURL"
      [(ngModel)]="processorConfig.redirectURL"
    />
  </div>
</form>

<h6>Schedule Settings</h6>

<div class="row mt-3">
  <div class="col-3 text-left float-left">
    <div class="radio">
      <label>
        <input
          type="radio"
          name="frequencyType"
          [value]="1"
          [(ngModel)]="type"
          [checked]="type === ScheduleFrequencyType.Weekly"
          (change)="convertFrequencyOptionsToWeekly()"
        />
        Weekly
      </label>
    </div>
  </div>
  <div class="col-3 text-left float-left">
    <div class="radio">
      <label>
        <input
          type="radio"
          name="frequencyType"
          [value]="2"
          [(ngModel)]="type"
          [checked]="type === ScheduleFrequencyType.Monthly"
          (change)="convertFrequencyOptionsToMonthly()"
        />
        Monthly
      </label>
    </div>
  </div>
  <div class="col-6"></div>
</div>

<!-- weekly options-->
<div *ngIf="type == 1" class="row m-3 justify-content-between">
  <div class="flex-column">
    <label>
      <input
        type="checkbox"
        [(ngModel)]="options.onSunday"
        (ngModelChange)="updateScheduleDaysSpecified()"
      />
      Sunday
    </label>
  </div>
  <div class="flex-column">
    <label>
      <input
        type="checkbox"
        [(ngModel)]="options.onMonday"
        (ngModelChange)="updateScheduleDaysSpecified()"
      />
      Monday
    </label>
  </div>
  <div class="flex-column">
    <label>
      <input
        type="checkbox"
        [(ngModel)]="options.onTuesday"
        (ngModelChange)="updateScheduleDaysSpecified()"
      />
      Tuesday
    </label>
  </div>
  <div class="flex-column">
    <label>
      <input
        type="checkbox"
        [(ngModel)]="options.onWednesday"
        (ngModelChange)="updateScheduleDaysSpecified()"
      />
      Wednesday
    </label>
  </div>
  <div class="flex-column">
    <label>
      <input
        type="checkbox"
        [(ngModel)]="options.onThursday"
        (ngModelChange)="updateScheduleDaysSpecified()"
      />
      Thursday
    </label>
  </div>
  <div class="flex-column">
    <label>
      <input
        type="checkbox"
        [(ngModel)]="options.onFriday"
        (ngModelChange)="updateScheduleDaysSpecified()"
      />
      Friday
    </label>
  </div>
  <div class="flex-column">
    <label>
      <input
        type="checkbox"
        [(ngModel)]="options.onSaturday"
        (ngModelChange)="updateScheduleDaysSpecified()"
      />
      Saturday
    </label>
  </div>
</div>

<!-- monthly options -->
<div *ngIf="type == 2" class="row m-3 justify-content-between">
  <div class="flex-column">
    <div class="row">
      <div class="col">
        <label>
          <input
            type="checkbox"
            [(ngModel)]="options.on1st"
            (ngModelChange)="updateScheduleDaysSpecified()"
          />
          1st
        </label>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <label>
          <input
            type="checkbox"
            [(ngModel)]="options.on2nd"
            (ngModelChange)="updateScheduleDaysSpecified()"
          />
          2nd
        </label>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <label>
          <input
            type="checkbox"
            [(ngModel)]="options.on3rd"
            (ngModelChange)="updateScheduleDaysSpecified()"
          />
          3rd
        </label>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <label>
          <input
            type="checkbox"
            [(ngModel)]="options.on4th"
            (ngModelChange)="updateScheduleDaysSpecified()"
          />
          4th
        </label>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <label>
          <input
            type="checkbox"
            [(ngModel)]="options.on5th"
            (ngModelChange)="updateScheduleDaysSpecified()"
          />
          5th
        </label>
      </div>
    </div>
  </div>

  <div class="flex-column">
    <div class="row">
      <div class="col">
        <label>
          <input
            type="checkbox"
            [(ngModel)]="options.on6th"
            (ngModelChange)="updateScheduleDaysSpecified()"
          />
          6th
        </label>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <label>
          <input
            type="checkbox"
            [(ngModel)]="options.on7th"
            (ngModelChange)="updateScheduleDaysSpecified()"
          />
          7th
        </label>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <label>
          <input
            type="checkbox"
            [(ngModel)]="options.on8th"
            (ngModelChange)="updateScheduleDaysSpecified()"
          />
          8th
        </label>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <label>
          <input
            type="checkbox"
            [(ngModel)]="options.on9th"
            (ngModelChange)="updateScheduleDaysSpecified()"
          />
          9th
        </label>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <label>
          <input
            type="checkbox"
            [(ngModel)]="options.on10th"
            (ngModelChange)="updateScheduleDaysSpecified()"
          />
          10th
        </label>
      </div>
    </div>
  </div>

  <div class="flex-column">
    <div class="row">
      <div class="col">
        <label>
          <input
            type="checkbox"
            [(ngModel)]="options.on11th"
            (ngModelChange)="updateScheduleDaysSpecified()"
          />
          11th
        </label>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <label>
          <input
            type="checkbox"
            [(ngModel)]="options.on12th"
            (ngModelChange)="updateScheduleDaysSpecified()"
          />
          12th
        </label>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <label>
          <input
            type="checkbox"
            [(ngModel)]="options.on13th"
            (ngModelChange)="updateScheduleDaysSpecified()"
          />
          13th
        </label>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <label>
          <input
            type="checkbox"
            [(ngModel)]="options.on14th"
            (ngModelChange)="updateScheduleDaysSpecified()"
          />
          14th
        </label>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <label>
          <input
            type="checkbox"
            [(ngModel)]="options.on15th"
            (ngModelChange)="updateScheduleDaysSpecified()"
          />
          15th
        </label>
      </div>
    </div>
  </div>

  <div class="flex-column">
    <div class="row">
      <div class="col">
        <label>
          <input
            type="checkbox"
            [(ngModel)]="options.on16th"
            (ngModelChange)="updateScheduleDaysSpecified()"
          />
          16th
        </label>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <label>
          <input
            type="checkbox"
            [(ngModel)]="options.on17th"
            (ngModelChange)="updateScheduleDaysSpecified()"
          />
          17th
        </label>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <label>
          <input
            type="checkbox"
            [(ngModel)]="options.on18th"
            (ngModelChange)="updateScheduleDaysSpecified()"
          />
          18th
        </label>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <label>
          <input
            type="checkbox"
            [(ngModel)]="options.on19th"
            (ngModelChange)="updateScheduleDaysSpecified()"
          />
          19th
        </label>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <label>
          <input
            type="checkbox"
            [(ngModel)]="options.on20th"
            (ngModelChange)="updateScheduleDaysSpecified()"
          />
          20th
        </label>
      </div>
    </div>
  </div>

  <div class="flex-column">
    <div class="row">
      <div class="col">
        <label>
          <input
            type="checkbox"
            [(ngModel)]="options.on21st"
            (ngModelChange)="updateScheduleDaysSpecified()"
          />
          21st
        </label>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <label>
          <input
            type="checkbox"
            [(ngModel)]="options.on22nd"
            (ngModelChange)="updateScheduleDaysSpecified()"
          />
          22nd
        </label>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <label>
          <input
            type="checkbox"
            [(ngModel)]="options.on23rd"
            (ngModelChange)="updateScheduleDaysSpecified()"
          />
          23rd
        </label>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <label>
          <input
            type="checkbox"
            [(ngModel)]="options.on24th"
            (ngModelChange)="updateScheduleDaysSpecified()"
          />
          24th
        </label>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <label>
          <input
            type="checkbox"
            [(ngModel)]="options.on25th"
            (ngModelChange)="updateScheduleDaysSpecified()"
          />
          25th
        </label>
      </div>
    </div>
  </div>

  <div class="flex-column">
    <div class="row">
      <div class="col">
        <label>
          <input
            type="checkbox"
            [(ngModel)]="options.on26th"
            (ngModelChange)="updateScheduleDaysSpecified()"
          />
          26th
        </label>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <label>
          <input
            type="checkbox"
            [(ngModel)]="options.on27th"
            (ngModelChange)="updateScheduleDaysSpecified()"
          />
          27th
        </label>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <label>
          <input
            type="checkbox"
            [(ngModel)]="options.on28th"
            (ngModelChange)="updateScheduleDaysSpecified()"
          />
          28th
        </label>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <label>
          <input
            type="checkbox"
            [(ngModel)]="options.onLastDOM"
            (ngModelChange)="updateScheduleDaysSpecified()"
          />
          Last day of month
        </label>
      </div>
    </div>
  </div>
</div>

<wm-control-message
  [control]="form.controls['scheduleDaysSpecified']"
></wm-control-message>

<form [formGroup]="form">
  <!-- base options (frequency, start date, end date, time) -->
  <div class="row mt-5">
    <!-- frequency -->
    <div class="col-4 align-self-center">
      <div class="row">
        <div class="col-5 align-self-center text-center p-0">
          Send every
        </div>
        <div class="col-2 align-self-center p-0">
          <input
            type="number"
            class="form-control"
            [(ngModel)]="options.frequency"
            [ngModelOptions]="{ standalone: true }"
            (ngModelChange)="refreshNextExportOn()"
            name="frequency"
            min="1"
          />
        </div>
        <div class="col-5 align-self-center p-0">
          <ng-container *ngIf="type == 1">week(s)</ng-container>
          <ng-container *ngIf="type == 2">month(s)</ng-container>
        </div>
      </div>
    </div>

    <!-- start date -->
    <div class="col-4 align-self-center">
      Start Date:
      <input
        ngbDatepicker
        #dStart="ngbDatepicker"
        class="form-control"
        placeholder="mm/dd/yyyy"
        name="startDate-name"
        id="startDate-name"
        (focus)="onFocusStartDate()"
        [restoreFocus]="false"
        [autoClose]="true"
        (dateSelect)="refreshNextExportOn()"
        formControlName="startDate"
        firstDayOfWeek="7"
      />
      <wm-control-message
        [control]="form.controls['startDate']"
      ></wm-control-message>
    </div>

    <!-- end date -->
    <div class="col-4 align-self-center">
      End Date (optional):
      <input
        ngbDatepicker
        #dEnd="ngbDatepicker"
        class="form-control"
        placeholder="mm/dd/yyyy"
        name="endDate-name"
        id="endDate-name"
        [restoreFocus]="false"
        [autoClose]="true"
        (dateSelect)="refreshNextExportOn()"
        (change)="refreshNextExportOn()"
        (focus)="onFocusEndDate()"
        formControlName="endDate"
        firstDayOfWeek="7"
      />
      <wm-control-message
        [control]="form.controls['endDate']"
      ></wm-control-message>
    </div>
  </div>

  <div class="row mt-3">
    <!-- time -->
    <div class="col-2 align-self-center">
      Time to send:
    </div>
    <div class="col-3 align-items-center">
      <ngb-timepicker
        class="p-0 m-0"
        id="sendTime-id"
        name="sendTime-name"
        [(ngModel)]="sendTime"
        (ngModelChange)="refreshNextExportOn()"
        [meridian]="true"
        formControlName="sendTime"
      ></ngb-timepicker>
      <wm-control-message
        [control]="form.controls['sendTime']"
      ></wm-control-message>
    </div>
    <div class="col-1 p-0 align-self-center text-left">
      {{ clientTimeZoneCode }}
    </div>
    <!-- Next Export On -->
    <div class="col-6 align-self-center">
      <div class="card p-2" *ngIf="form.valid && !gettingNextExport">
        Next Export On (when these settings are saved):
        <br />
        <br />
        <ng-container *ngIf="nextExportOn">
          <strong> {{ nextExportOn }} </strong>
        </ng-container>
        <ng-container *ngIf="!nextExportOn">
          <strong>
            No future export times
            <wm-help-text
              placement="right"
              helpText="This export is scheduled to end before the next scheduled export time."
            ></wm-help-text>
          </strong>
        </ng-container>
      </div>
      <div class="card p-2" *ngIf="form.valid && gettingNextExport">
        Calculating the next export from the current settings...
      </div>
    </div>
  </div>
</form>

import { GovPayPaymentProcessorAccountConfiguration } from 'src/app/models/payment-providers/govpay/govpay-payment-processor-account-configuration';
import { eGOVPaymentProcessorAccountConfiguration } from './egov/egov-payment-processor-account-configuration';
import { KanPayPaymentProcessorAccountConfiguration } from './kanpay/kanpay-payment-processor-account-configuration';
import { PaymentProcessor, Client } from '..';
import { PaymentProcessorAccountConfigurationBase } from './payment-processor-account-configuration-base';
import { PaymentProcessorType } from './payment-processor';
import { PayGovPaymentProcessorAccountConfiguration } from './paygov';
import { CardpointePaymentProcessorAccountConfiguration } from './cardpointe/cardpointe-payment-processor-account-configuration';
import { VpsPaymentProcessorAccountConfiguration } from './vps/vps-payment-processor-account-configuration';

export class PaymentAccount {
  id: string;
  name: string;
  description: string;
  processor: PaymentProcessor;
  processorId: string;
  client: Client;
  clientId: string;
  providerAccountSettings: PaymentProcessorAccountConfigurationBase;
  testProviderAccountSettings: PaymentProcessorAccountConfigurationBase;
  inTestMode: boolean;
  parentAccountId?: string;
  isConfigured: boolean;
  isEnabled: boolean;

  constructor(options?: Partial<PaymentAccount>) {
    this.inTestMode = true; // Default this to true;
    this.processor = new PaymentProcessor();
    if (options) {
      Object.assign(this, options);
    }
  }

  initConfig(
    config: PaymentProcessorAccountConfigurationBase,
    testConfig: PaymentProcessorAccountConfigurationBase
  ) {
    switch (this.processor.type) {
      case PaymentProcessorType.PayGov:
        this.providerAccountSettings = new PayGovPaymentProcessorAccountConfiguration(
          config
        );
        this.testProviderAccountSettings = new PayGovPaymentProcessorAccountConfiguration(
          testConfig
        );
        break;
      case PaymentProcessorType.Cardpointe:
        this.providerAccountSettings = new CardpointePaymentProcessorAccountConfiguration(
          config
        );
        this.testProviderAccountSettings = new CardpointePaymentProcessorAccountConfiguration(
          testConfig
        );
        break;
      case PaymentProcessorType.VPS:
        this.providerAccountSettings = new VpsPaymentProcessorAccountConfiguration(
          config
        );
        this.testProviderAccountSettings = new VpsPaymentProcessorAccountConfiguration(
          testConfig
        );
        break;
      case PaymentProcessorType.GovPay:
        this.providerAccountSettings = new GovPayPaymentProcessorAccountConfiguration(
          config
        );
        this.testProviderAccountSettings = new GovPayPaymentProcessorAccountConfiguration(
          testConfig
        );
        break;
      case PaymentProcessorType.KanPay:
        this.providerAccountSettings = new KanPayPaymentProcessorAccountConfiguration(
          config
        );
        this.testProviderAccountSettings = new KanPayPaymentProcessorAccountConfiguration(
          testConfig
        );
      case PaymentProcessorType.eGOV:
        this.providerAccountSettings = new eGOVPaymentProcessorAccountConfiguration(
          config
        );
        this.testProviderAccountSettings = new eGOVPaymentProcessorAccountConfiguration(
          testConfig
        );
      default:
        this.providerAccountSettings = new PaymentProcessorAccountConfigurationBase(
          config
        );
        this.testProviderAccountSettings = new PaymentProcessorAccountConfigurationBase(
          testConfig
        );
        break;
    }
  }
}

import {
  Component,
  EventEmitter,
  forwardRef,
  Inject,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { ActivityView } from '../../../../../views/master-views/app.view/app.view.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ContractorRegistrationDecisionActivity } from 'src/app/models/activities/contractor-registration-decision-activity';
import { CalculatedValueDataEntity } from 'src/app/models/data-entities';
import {
  Activity,
  ActivityModel,
  AgendaItemActivity
} from 'src/app/models/activities';
import { DataService } from 'src/app/services';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject, Subscription } from 'rxjs';

@Component({
  selector: 'wm-agenda-item-activity-input',
  templateUrl: './agenda-item-activity-input.component.html',
  styleUrls: ['./agenda-item-activity-input.component.css']
})
export class AgendaItemActivityInputComponent extends ActivityView
  implements OnInit {
  evaluatedAgendaItem: string;

  constructor(private _toastr: ToastrService) {
    super();
  }

  ngOnInit() {
    if (this.activity.feedbackMessage && this.activity.feedbackMessage !== '') {
      this._toastr.success(this.activity.feedbackMessage);
    }

    const activity = this.activity as AgendaItemActivity;
    this.evaluatedAgendaItem = activity.model.queueItem.evaluatedAgendaItem;
  }
}

import { ActivityEditorComponent } from './../../workflow/activities/activity-editor/activity-editor.component';
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  TemplateRef
} from '@angular/core';
import {
  NgbModal,
  ModalDismissReasons,
  NgbModalRef
} from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';

export enum ButtonStyle {
  Button,
  Link
}

@Component({
  selector: 'wm-modal-confirm',
  templateUrl: './modal-confirm.component.html',
  styleUrls: ['./modal-confirm.component.css']
})
export class ModalConfirmComponent implements OnInit {
  @Input() title: string;
  @Input() moduleId: string;
  @Input() commandText: string;
  @Input() cancelText = 'Cancel';
  @Input() options: { ariaLabelledBy: string };
  @Output() accepted: EventEmitter<any> = new EventEmitter<any>();
  @Output() canceled: EventEmitter<any> = new EventEmitter<any>();
  @Output() opened: EventEmitter<any> = new EventEmitter<any>();
  @Output() customButtonClicked: EventEmitter<any> = new EventEmitter<any>();
  @Input() enabled = true;
  @Input() showButtonText = false;
  @Input() buttonText: string;
  @Input() showButton = true;
  @Input() btnColor = 'primary';
  @Input() btnIcon: string;
  @Input() form: UntypedFormGroup;
  @Input() windowClass: string;
  @Input() small = false;
  @Input() tooltip: string;
  @Input() buttonClass: string;
  @Input() buttonStyle: ButtonStyle = ButtonStyle.Button;
  @Input() closeOnAccept = true;
  @Input() showCommandButton = true;
  @Input() customButtonText: string;
  @Input() customButtonColor = 'danger';
  @Input() showCancelButton = true;
  @Input() iconStyle;
  @Input() buttonStyling;

  processing = false;
  originalAcceptText: string;

  modalRef: NgbModalRef;

  @ViewChild('content', { static: true })
  private contentTpl: TemplateRef<any>;

  shouldShowButton() {
    return this.showButton && this.buttonStyle === ButtonStyle.Button;
  }

  shouldShowLink() {
    return this.showButton && this.buttonStyle === ButtonStyle.Link;
  }

  constructor(private modalService: NgbModal, fb: UntypedFormBuilder) {
    this.form = fb.group({});
  }

  ngOnInit() {
    if(!this.options) {
      this.options = { ariaLabelledBy: `${this.moduleId}_modallabel` };
    }
    else {
      Object.assign(this.options, { ariaLabelledBy: `${this.moduleId}_modallabel` });
    }

    if (!this.buttonText && !this.btnIcon) {
      this.buttonText = this.commandText;
    }

    if (!this.buttonClass) {
      this.buttonClass = this.showButtonText
        ? `btn btn-raised btn-${this.btnColor}`
        : `btn btn-raised btn-${this.btnColor} glyphicon glyphicon-remove`;
    }
  }

  setProcessing() {
    const btnText = this.originalAcceptText || this.commandText;

    if (!this.originalAcceptText) {
      this.originalAcceptText = btnText; // this.commandText;
    }

    this.commandText = btnText + '...';
    this.processing = true;
  }

  cancelProcessing() {
    if (this.originalAcceptText) {
      this.commandText = this.originalAcceptText;
    }
    this.processing = false;
  }

  cancel() {
    this.cancelProcessing();
    this.modalRef.close('cancel');
  }

  accept() {
    if (this.closeOnAccept) {
      this.modalRef.close('save');
    } else {
      this.setProcessing();
      this.accepted.emit();
    }
  }

  dismis(reason) {
    if (reason === 'save') {
      this.accepted.emit(this.moduleId);
    } else {
      this.canceled.emit();
    }
  }

  open(content?) {
    this.processing = false;
    this.modalRef = this.modalService.open(this.contentTpl, {
      ariaLabelledBy: `${this.moduleId}_modallabel`,
      size: this.small ? 'sm' : 'lg',
      windowClass: this.windowClass || ''
    });

    this.modalRef.result.then(
      result => this.dismis(result),
      reason => this.dismis(reason)
    );
    this.opened.emit(true);
    return false;
  }

  custom() {
    this.customButtonClicked.emit(true);
  }
}

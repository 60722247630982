<div class="mt-4">
  <div>
    <wm-exports-view
      *ngIf="columnOptions && columnOptions.length > 0"
      [id]="id"
      [title]="title"
      [params]="filterCriteria"
      [currentFilters]="filterOptions"
      [staticFilters]="staticFilters"
      [currentColumnOptions]="columnOptions"
      (exptExcel)="exportExcel()"
      (exptCsv)="exportCsv()"
    ></wm-exports-view>

    <wm-datatable
      [id]="id"
      [useMap]="true"
      [loading]="loading"
      [rows]="inspectionRequests"
      [columns]="columnsWithExtra"
      [count]="page.totalElements"
      [offset]="page.pageNumber"
      [limit]="page.size"
      (mapDataRequested)="onMapDataRequested($event)"
      popupTemplateTitle="Application ID: {permitNumber}"
      popupTemplateContent="Requested Date: {requestedOn}&lt;br>
  Requested Time: {aMpM}&lt;br>&lt;br>"
      [popupActions]="mapPopupActions"
      [params]="filterCriteria"
      (tabChange)="loadInspections().subscribe()"
      simpleSearchTitle="Search for inspections"
      [unpermissibleCount]="unpermissibleCount"
      (settingsChanged)="handleSettingsChanged($event)"
      [sortField]="sortField"
      [sortDescending]="sortDescending"
      [defaultHiddenColumns]="defaultHiddenColumns"
      [actionColumns]="actionColumns"
      [defaultExportExludedColumns]="defaultExportExludedColumns"
      [availableColumns]="availableColumns"
      [exportingEnabled]="true"
    ></wm-datatable>

    <ng-template #appIdTmpl let-row="row" let-value="value">
      <a
        [routerLink]="[
          '/application/workflow-application',
          row.workflowApplicationId
        ]"
      >
        {{ value }}
      </a>
    </ng-template>

    <ng-template #scheduledOnTmpl let-row="row" let-value="value">
      <ng-container *ngIf="showTime(value, row)">
        {{ value | date: 'MM/dd/yyy, h:mm a' }}
      </ng-container>
      <ng-container *ngIf="!showTime(value, row)">
        {{ value | date: 'MM/dd/yyy' }}
      </ng-container>
    </ng-template>

    <ng-template #actionsTmpl let-row="row">
      <div ngbDropdown container="body">
        <button
          class="btn btn-secondary"
          id="dropdownMenuButton"
          ngbDropdownToggle
          style="padding-top:0px;"
        >
          Action
        </button>
        <div ngbDropdownMenu aria-labelledby="dropdownMenuButton">
          <a
            ngbDropdownItem
            (click)="viewDetails(row.id)"
            href="javascript:void(0)"
          >
            View Details
          </a>
          <a
            ngbDropdownItem
            [disabled]="!row.canScheduleInspection"
            (click)="
              processInspectionActivity(
                row.workflowApplicationId,
                row.id,
                'scheduleInspection'
              )
            "
            href="javascript:void(0)"
          >
            Schedule Inspection
          </a>
          <a
            ngbDropdownItem
            [disabled]="!row.canCompleteInspection"
            (click)="
              processInspectionActivity(
                row.workflowApplicationId,
                row.id,
                'completeInspection'
              )
            "
            href="javascript:void(0)"
          >
            Complete Inspection
          </a>
        </div>
      </div>
    </ng-template>

    <div class="container">
      <div class="row">
        <div class="col-xs-2">
          <wm-modal-confirm
            #CompleteInspectionActivity
            [showButtonText]="true"
            [showButton]="false"
            [buttonText]="'blah'"
            [commandText]="'Continue Inspection'"
            [title]="'Continue Inspection Process'"
            (accepted)="completeInspectionActivity($event)"
            btnColor="danger"
            class="mx-2"
            [form]="activityForm"
          >
            <wm-activity-view
              [applicationId]="workflowApplicationId"
              [activity]="activity"
              [form]="activityForm"
              *ngIf="activity"
            >
            </wm-activity-view>
          </wm-modal-confirm>
        </div>
      </div>
    </div>
    <div [class]="'d-none'">
      <template #alertContainer></template>
    </div>
  </div>
</div>

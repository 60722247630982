import { EventEmitter } from '@angular/core';
import { ExternalDataConnection } from './../external-data-connection';
import { UntypedFormGroup } from '@angular/forms';
import { ActivityModel, ScreenActivity } from '.';

export class DestinationConfiguration {}

export class FormatConfiguration {}

export class CsvConfiguration extends FormatConfiguration {
  includeHeaders: boolean;

  constructor(options?: Partial<CsvConfiguration>) {
    super();

    if (options) {
      Object.assign(this, options);
    }
  }
}

export class SFTPConfiguration extends DestinationConfiguration {
  connectionId: string;
  availableConnections: ExternalDataConnection[];
}

export class ExportColumn {
  templateCode: string;
  alias: string;
  ordinal: number;
  selected: boolean;
  documentIndex: string;
}

export class ExportDataActivityModel extends ActivityModel {
  availableDataEntities: string[];
  availableFormats: { [key: string]: string };
  availableTransportMethods: { [key: string]: string };
  exportColumns: ExportColumn[];
  exportDocumentColumns: ExportColumn[];
  transportConfiguration: DestinationConfiguration;
  formatConfiguration: FormatConfiguration;
  outputFormatText: string;
  transportMethodText: string;

  constructor(options?: Partial<ExportDataActivityModel>) {
    super(options);

    this.exportColumns = [];
    this.exportDocumentColumns = [];

    if (options) {
      Object.assign(this, options);
    }
  }
}

export class ExportDataActivity<
  T extends ExportDataActivityModel = ExportDataActivityModel
> extends ScreenActivity<ExportDataActivityModel> {
  constructor(options?: Partial<ExportDataActivity>) {
    super(options);

    if (options) {
      Object.assign(this, options);
    }

    this.model = new ExportDataActivityModel(options ? options.model : null);

    this.model.screenName = this.model.screenName || 'Export Data';
  }
}

export class OutputEditor {
  form: UntypedFormGroup;
  configuration: FormatConfiguration;
}

export class TransportEditor {
  form: UntypedFormGroup;
  configuration: DestinationConfiguration;
  updated: EventEmitter<DestinationConfiguration>;
}

import { Activity, ActivityModel } from './activities';

export enum ShareAccessPermission {
  ReadOnly = 0,
  Edit = 1
}

export class SharedAcessActivity {
  id: string;
  activityId: string;
  activityDataId: string;
  activityName: string;
  permission: ShareAccessPermission;
  sharedAccessDetailsId: string;
  details: SharedAccessDetails;
  availablePermissions: number[];
}

export class SharedAccessDetails {
  id: string;
  emailAddress: string;
  activities: SharedAcessActivity[];
  priorActivities: SharedAcessActivity[];
  futureActivities: SharedAcessActivity[];
  currentActivity: SharedAcessActivity;
  sharedActivityDataId: string;
  claimedBy?: string;
  claimedOn?: Date;
  sharedOn: Date;
  sharedBy: string;
  revokedBy?: string;
  revokedOn?: Date;
  workflowApplicationId: string;
  shares: SharedAccessDetails[];
  showDetails = false;
}

export class SharedAccessRequest {
  applicationId: string;
  currentActivityDataId: string;
  currentActivity: Activity<ActivityModel>;
}

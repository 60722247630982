import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ListItemEditorComponent } from './list-item-editor/list-item-editor.component';
import { ListOption } from './../../../../../models/listOption';
import {
  Component,
  OnInit,
  forwardRef,
  Inject,
  ChangeDetectorRef,
  AfterViewChecked
} from '@angular/core';
import { DataEntityEditorBaseComponent } from '../../data-entity-editor-base/data-entity-editor-base.component';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { WorkflowService } from 'src/app/services';
import { ListDataEntity, ControlType } from 'src/app/models/data-entities';
import { WorkflowContextService } from './../../../../../services/workflow-context.service';
import { ExternalDataSource } from 'src/app/models/external-data-source';

@Component({
  selector: 'wm-list-data-entity-editor',
  templateUrl: './list-data-entity-editor.component.html',
  styleUrls: ['./list-data-entity-editor.component.css']
})
export class ListDataEntityEditorComponent extends DataEntityEditorBaseComponent
  implements OnInit, AfterViewChecked {
  constructor(
    @Inject(forwardRef(() => WorkflowService))
    public _workflowSvc: WorkflowService,
    private modalService: NgbModal,
    @Inject(forwardRef(() => WorkflowContextService))
    private _context: WorkflowContextService,
    private _fb: UntypedFormBuilder,
    private _ref: ChangeDetectorRef
  ) {
    super();
  }
  ListTypes = [ControlType.Dropdown, ControlType.Radio, ControlType.Check];
  listEntity: ListDataEntity;
  controlType: ControlType;
  availableDataSources: { key: string; value: string }[];
  canEditList: boolean;

  get canEditIfSystem(): boolean {
    if (!this._context.client) {
      return true; // global can always edit
    }

    return this.listEntity.canEditList;
  }

  ngAfterViewChecked() {
    this._ref.detectChanges();
  }

  calcCanEditList(): boolean {
    return !this.listEntity.selectedDataSource;
  }

  buildObjectKeys(obj: any): any {
    if (obj) {
      const result = Object.keys(obj)
        .filter(k => k !== '$id')
        .map<{ key: string; value: string }>(k => {
          return {
            key: k,
            value: obj[k]
          };
        });
      return result;
    }
    return null;
  }

  listChange(e) {
    this.listEntity.availableListItems = e;
    this.listEntity.listTextValues = e;
    this.buildPreview.emit();
  }

  ngOnInit() {
    this.listEntity = this.dataEntity as ListDataEntity;
    this.form.addControl(
      'listTitle',
      this._fb.control(false, [Validators.nullValidator])
    );
    this.form.addControl(
      'controlType',
      this._fb.control(false, [Validators.nullValidator])
    );
    this.form.addControl(
      'controlOptions',
      this._fb.control(false, [Validators.nullValidator])
    );
    this.form.addControl(
      'requiredEntry',
      this._fb.control(false, [Validators.nullValidator])
    );
    this.form.addControl(
      'listDataSource',
      this._fb.control(false, [Validators.nullValidator])
    );

    this.availableDataSources = this.buildObjectKeys(
      this.listEntity.availableDataSources
    );

    this.canEditList = this.calcCanEditList();

    if (!Array.isArray(this.listEntity.listTextValues)) {
      this.listEntity.listTextValues = [];
    }
  }

  externalDataSourceChanged(eds: ExternalDataSource) {
    this.dataEntity.externalDataSource = eds;
  }
}

import {
  Component,
  OnInit,
  Inject,
  forwardRef,
  ViewChild,
  ElementRef,
  ChangeDetectorRef
} from '@angular/core';
import { ActivityView } from '../../../../../views/master-views/app.view/app.view.component';
import {
  PaymentConfirmationStatus,
  PaymentActivity,
  PaymentMethods,
  PaymentRequest
} from '../../../../../models/activities';
import {
  UntypedFormGroup,
  Validators,
  UntypedFormBuilder,
  UntypedFormControl
} from '@angular/forms';
import {
  WorkflowService,
  WorkflowPaymentRequest,
  Utilities,
  SecurityService,
  PaymentRequestResult
} from '../../../../../services';
import { ToastrService } from 'ngx-toastr';
import { WorkflowApplicationFeePayment } from 'src/app/models/workflow-application-fee-payment';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Actions } from 'src/app/models';

@Component({
  selector: 'wm-payment-activity-input',
  templateUrl: './payment-activity-input.component.html',
  styleUrls: ['./payment-activity-input.component.css']
})
export class PaymentActivityInputComponent extends ActivityView
  implements OnInit {
  activity: PaymentActivity;
  @ViewChild('paymentForm', { read: ElementRef, static: true })
  paymentForm: ElementRef;
  showManualPayment = false;
  hasIntegration: boolean;
  manualForm: UntypedFormGroup;
  payments: PaymentRequest[];
  apiBase = Utilities.getRootURL();
  processing = false;
  isGlobalAdmin = false;
  viewingAsApplicantInTest = false;

  PaymentMethods = PaymentMethods;
  PaymentConfirmationStatus = PaymentConfirmationStatus;

  transactionNumberValidators: any[];

  get lineItems() {
    if (this.activity.model.fees) {
      return this.activity.model.fees.map(fee => ({
        name: fee.label,
        amount: fee.formulaValue
      }));
    }

    return null;
  }

  constructor(
    @Inject(forwardRef(() => WorkflowService))
    private _workflowSvc: WorkflowService,
    @Inject(forwardRef(() => SecurityService))
    private _securitySvc: SecurityService,
    private _fb: UntypedFormBuilder,
    private _ref: ChangeDetectorRef,
    private _toastrSvc: ToastrService
  ) {
    super();
  }

  enableNext() {
    // disable the next button if they still have money due
    if (this.activity.model.totalDue > 0) {
      this.showNext.emit(false);
    } else if (this.activity.canGoNext) {
      this.showNext.emit(true);
    }
  }

  ngOnInit() {
    this.hasIntegration = this.activity.model.hasIntegration;
    this.payments = this.activity.model.payments;

    // only default the payment method if we haven't made a payment already
    // and a payment method hasn't been saved by the user as something besides the server's default of Online
    if (
      this.activity.model.paymentMethod === null ||
      this.activity.model.paymentMethod === PaymentMethods.Online
    ) {
      // default to either Online or Cash, depending on if the payment activity has integration
      this.activity.model.paymentMethod = this.hasIntegration
        ? PaymentMethods.Online
        : PaymentMethods.Cash;
    }

    this.enableNext();

    // check to see if the user is an administrator and show the manual payment options if they are
    this._securitySvc.isAnAdministrator().subscribe(value => {
      this.showManualPayment = value;

      this.manualForm = this._fb.group({});

      if (this.showManualPayment) {
        this.transactionNumberValidators = [Validators.required];

        this.manualForm.addControl(
          'paymentMethod',
          new UntypedFormControl('', Validators.required)
        );

        this.manualForm.addControl(
          'transactionNumber',
          new UntypedFormControl('', this.transactionNumberValidators)
        );

        this.manualForm.controls['paymentMethod'].valueChanges.subscribe(
          result => {
            if (result == '2') {
              this.manualForm.controls['transactionNumber'].setValidators(
                this.transactionNumberValidators
              );
            } else {
              this.manualForm.controls['transactionNumber'].clearValidators();
            }

            this.manualForm.controls[
              'transactionNumber'
            ].updateValueAndValidity();
          }
        );
      } else {
        this.manualForm = this._fb.group({});
      }

      if (this.activity.model.allowMultiplePayments) {
        this.manualForm.addControl(
          'paymentAmountOption',
          new UntypedFormControl('', Validators.required)
        );

        this.manualForm.addControl(
          'paymentAmount',
          new UntypedFormControl('', [
            Validators.required,
            Validators.min(0.01),
            Validators.max(this.activity.model.totalDue)
          ])
        );
      }

      if (!this.activity.model.allowMultiplePayments) {
        this.activity.model.paymentAmount = this.activity.model.totalDue;
      }

      this._ref.detectChanges();

      if (this.activity.model.allowMultiplePayments) {
        this.manualForm.controls['paymentAmount'].disable();
      }
    });

    this._securitySvc
      .isLoginEntitled(Actions.DO_ANYTHING)
      .subscribe(isEntitled => {
        this.isGlobalAdmin = isEntitled;
      });

    // in test applications, only manual payment methods should be available to non-global-admin users
    if (this.isTestApplication && !this.isGlobalAdmin) {
      // default the payment method to Cash (Online option is removed in template)
      this.activity.model.paymentMethod = PaymentMethods.Cash;

      if (
        this.context.viewAsRole.id.toLowerCase() ===
        SecurityService.APPLICANT_ROLE.id.toLowerCase()
      ) {
        // disable the Make Payment button
        this.viewingAsApplicantInTest = true;
      }
    }
  }

  paymentOption: string = null;

  payFull() {
    this.paymentOption = 'fullAmount';
    this.activity.model.paymentAmount = this.activity.model.totalDue;
    this.manualForm.controls['paymentAmount'].disable();
  }

  payPartial() {
    this.paymentOption = 'partialAmount';
    this.manualForm.controls['paymentAmount'].enable();
  }

  private validateAllFormFields(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  submitPayment() {
    // An admin could have saved this activity with some kind of manual payment.
    // Then a non-admin (applicant) can come to the activity and be presented with
    // the Make Payment button because this has an integration.
    // In that situation, make sure the payment request is set to Online
    if (this.hasIntegration && !this.showManualPayment) {
      this.activity.model.paymentMethod = PaymentMethods.Online;
    }

    const form: HTMLFormElement = <HTMLFormElement>(
      this.paymentForm.nativeElement
    );
    const that = this;
    if (this.manualForm.invalid) {
      this.validateAllFormFields(this.manualForm);
    } else {
      this.processing = true;
      const form: HTMLFormElement = <HTMLFormElement>(
        this.paymentForm.nativeElement
      );
      const that = this;

      // null out the payments if it exists because of serialization issues
      this.payments = null;

      this._workflowSvc
        .prepareWorkflowPayment(
          this.context.applicationId,
          <PaymentActivity>this.activity
        )
        .subscribe((request: WorkflowPaymentRequest) => {
          if (request && request.result === PaymentRequestResult.Success) {
            if ((request.url || '') !== '') {
              request.execute(form);
            } else {
              that.goNext.emit(true);
            }
          } else {
            this.processing = false;
            let tstrMsg: Observable<any>;

            if (request.reloadActivity) {
              tstrMsg = this._workflowSvc
                .navigateToActivity(
                  this.context.applicationId,
                  this.activity.activityDataId
                )
                .pipe(
                  map(resp => {
                    const a = resp.activity;

                    this.activity = a as PaymentActivity;
                    return a;
                  })
                );
            } else {
              tstrMsg = of(null);
            }

            tstrMsg.subscribe(d => {
              if (this.activity) {
                this.enableNext();
              }

              this._toastrSvc.error(
                request && request.errorMessage,
                `Payment Failed to submit`
              );
            });
          }
        });
    }
  }
}

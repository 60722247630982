<form [formGroup]="form" *ngIf="config">
  <div class="form-group">
    <label class="bmd-label-floating" for="accountId">
      Account ID
    </label>
    <input
      type="text"
      class="form-control"
      formControlName="accountId"
      name="accountId"
      [(ngModel)]="config.accountId"
    />
  </div>
</form>

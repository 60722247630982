import {
  ChangeDetectorRef,
  Component,
  forwardRef,
  Inject,
  OnInit
} from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import {
  DataEntity,
  DisplayEntityValueDataEntity
} from 'src/app/models/data-entities';
import {
  SystemService,
  WorkflowContextService,
  WorkflowService
} from 'src/app/services';

import { DataEntityEditorBaseComponent } from '../../data-entity-editor-base/data-entity-editor-base.component';

@Component({
  selector: 'wm-display-entity-value-data-entity-editor',
  templateUrl: './display-entity-value-data-entity-editor.component.html',
  styleUrls: ['./display-entity-value-data-entity-editor.component.css']
})
export class DisplayEntityValueDataEntityEditorComponent
  extends DataEntityEditorBaseComponent
  implements OnInit {
  entities: DataEntity[];
  displayEntityValue: DisplayEntityValueDataEntity;
  useDataEntityLabel = true;
  hideLabelAndValue = false;
  constructor(
    @Inject(forwardRef(() => WorkflowService))
    public _workflowSvc: WorkflowService,
    @Inject(forwardRef(() => WorkflowContextService))
    private _context: WorkflowContextService,
    @Inject(forwardRef(() => SystemService)) private _systemSvc: SystemService,
    private changeDetRef: ChangeDetectorRef,
    private _fb: UntypedFormBuilder
  ) {
    super();
  }

  ngOnInit() {
    this.displayEntityValue = this.dataEntity as DisplayEntityValueDataEntity;
    const tCode = this.displayEntityValue.templateCode;
    this.useDataEntityLabel =
      this.displayEntityValue.useDataEntityLabel == null ||
      this.displayEntityValue.useDataEntityLabel === true;

    const existingReferenceEntity = this.activity.model
      .getEntities()
      .find(f => f.templateCode === tCode);

    if (existingReferenceEntity === undefined) {
      this.useDataEntityLabel = false;
      this.displayEntityValue.useDataEntityLabel = false;
      this.hideLabelAndValue = false;
      this.displayEntityValue.hideLabelAndValueWhenNullorEmpty = false;
    }

    this.hideLabelAndValue =
      this.displayEntityValue.hideLabelAndValueWhenNullorEmpty === true;
    this.form.addControl(
      'display-entity-value-select',
      this._fb.control(false, [Validators.required])
    );
    this.form.addControl(
      'use-data-entity-label-check',
      this._fb.control(false)
    );
    this.form.addControl(
      'hide-label-and-value-when-null-or-empty-check',
      this._fb.control(false)
    );

    this.getEntityOptions();
  }

  getEntityOptions() {
    this._workflowSvc
      .getDataEntitiesBeforeMe(this._context.workflow, this.activity, null, [
        WorkflowService.DATA_ENTITIES.DisplayEntityValue.code
      ])
      .subscribe(e => {
        this.entities = e.filter(
          f => f.templateCode !== this.displayEntityValue.templateCode
        );
      });
  }
}

import { Component, OnInit, EventEmitter } from '@angular/core';
import { ActivityView } from '../../../../../views/master-views/app.view/app.view.component';
import { Activity, ActivityModel } from '../../../../../models/activities';
import { FormGroup } from '@angular/forms';
import { WorkflowContextService } from '../../../../../services';
import { DataEntity } from '../../../../../models/data-entities';

@Component({
  selector: 'wm-form-activity-view',
  templateUrl: './form-activity-view.component.html',
  styleUrls: ['./form-activity-view.component.css']
})
export class FormActivityViewComponent extends ActivityView implements OnInit {
  entities: DataEntity[];

  constructor() {
    super();
  }

  ngOnInit() {
    this.entities = this.activity.model.getEntities();
  }
}

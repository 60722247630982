import { ConditionModalComponent } from './condition-modal/condition-modal.component';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import {
  Component,
  OnInit,
  Input,
  TemplateRef,
  ContentChild,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { faPen, faGripLines } from '@fortawesome/free-solid-svg-icons';

import {
  ConditionSource,
  OperatorTypes,
  ConditionTarget
} from './condition-builder.model';

@Component({
  selector: 'wm-condition-builder',
  templateUrl: './condition-builder.component.html',
  styleUrls: ['./condition-builder.component.css']
})
export class ConditionBuilderComponent implements OnInit, OnChanges {
  @ContentChild(TemplateRef, { static: false }) templateRef: TemplateRef<any>;

  @Input() title = 'Conditions';
  /**
   * Should the 'else' target be editable?
   */
  @Input() allowEditingElse = false;
  /**
   * Source conditions. Likely data entities.
   */
  @Input() conditionSources: ConditionSource[] = [];

  @Input() conditions: ConditionTarget[] = [];

  /**
   * A function to get the label of the "then value"
   *
   * @example
   * // Use => to force `this` into being the the consumer component context
   * getThenLabel = (target: ConditionTarget): string => {
   *   return 'Some label';
   * }
   */
  @Input('thenLabelGetter') thenLabelGetter: (
    target: ConditionTarget
  ) => string;

  @Output() conditionChanges: EventEmitter<
    ConditionTarget[]
  > = new EventEmitter();

  OperatorTypes = OperatorTypes;
  faPen = faPen;
  faGripLines = faGripLines;
  modalRef: NgbModalRef;

  otherwiseOpen = false;

  get conditionsWithoutOtherwise(): ConditionTarget[] {
    return this.conditions.filter(c => !c.isOtherwise);
  }
  get otherwiseCondition(): ConditionTarget {
    let otherwiseCondition = this.conditions.find(c => c.isOtherwise);
    if (!otherwiseCondition) {
      otherwiseCondition = new ConditionTarget({ isOtherwise: true });
      this.conditions.push(otherwiseCondition);
    }

    return otherwiseCondition;
  }

  constructor(private modalService: NgbModal) {}

  ngOnChanges(changes: SimpleChanges): void {
    // throw new Error('Method not implemented.');
    if (changes['conditionSources']) {
      if (this.modalRef && this.modalRef.componentInstance) {
        this.modalRef.componentInstance.conditionSources = this.conditionSources;
      }
    }
  }

  openTargetModal(target?: ConditionTarget) {
    this.modalRef = this.modalService.open(ConditionModalComponent, {
      size: 'lg'
    });
    this.modalRef.componentInstance.conditionSources = this.conditionSources;
    this.modalRef.componentInstance.target = target;
    this.modalRef.componentInstance.templateRef = this.templateRef;
    this.modalRef.componentInstance.conditionChanges.subscribe(e => {
      this.conditionChanges.emit(this.conditions);
    });
    this.modalRef.componentInstance.deleteTarget.subscribe(e => {
      this.removeTarget(e);
    });
  }

  reorderConditions(e) {
    e.forEach((condition, index) => {
      condition.order = index;
    });

    this.conditions = [...e, this.otherwiseCondition];

    this.conditionChanges.emit(this.conditions);
  }

  getThenLabel(target: ConditionTarget) {
    return this.thenLabelGetter && this.thenLabelGetter(target)
      ? this.thenLabelGetter(target)
      : '';
  }

  ngOnInit() {}

  applyTarget() {
    this.otherwiseOpen = false;
    this.conditionChanges.emit(this.conditions);
  }

  addTarget() {
    const target = new ConditionTarget();

    this.conditions.push(target);
    this.conditionChanges.emit(this.conditions);
  }
  removeTarget(targetId: string) {
    this.conditions = this.conditions.filter(c => c.id !== targetId);
    this.conditionChanges.emit(this.conditions);
  }
}

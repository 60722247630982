import { PaymentProcessorConfigurationBase } from '../payment-processor-configuration-base';

export class VpsPaymentProcessorConfiguration extends PaymentProcessorConfigurationBase {
  redirectURL: string;

  constructor(options?: Partial<VpsPaymentProcessorConfiguration>) {
    super(options);
    this.redirectURL = '';

    if (options) {
      Object.assign(this, options);
    }
  }
}

import {
  Injectable,
  Inject,
  forwardRef
} from '@angular/core';
import { Observable } from 'rxjs';

import { WorkflowContextService } from '../workflow-context.service';
import { DataService } from '../data.service';
import { Utilities } from '../utilities';
import {
  AvailabilityProviderOptions,
  AvailabilityProvider,
  AvailabilityProviderMetadata,
  DaysOfWeekAvailabilityProvider,
  WeekdayAvailabilityProvider,
  WeekendAvailabilityProvider,
  CalendarServiceAvailabilityProvider,
  TimeRangeAvailabilityProvider
} from './availability-providers';
import {
  DateValidatorProviderMetadata,
  IDateValidator,
  TodayValidator,
  BeginningOfWeekValidator,
  SpecificDateValidator,
  DayOfYearValidator,
  DayOfMonthValidator,
  TodayOffsetValidator
} from './date-validators';
//import * as System from 'system';

declare var System: any;

@Injectable()
export class CalendarService {
  static providerMetaData: { [key: string]: AvailabilityProviderMetadata } = {
    weekdays: { key: 'weekdays', display: 'Weekdays Only' },
    weekends: { key: 'weekends', display: 'Weekends Only' },
    'days-of-week': { key: 'days-of-week', display: 'Specified Days of Week' },
    'calendar-service': {
      key: 'calendar-service',
      display: 'Calendar Service'
    },
    'time-range': { key: 'time-range', display: 'Time Range' }
  };

  static dateProviderKeys: AvailabilityProviderMetadata[] = [
    CalendarService.providerMetaData['weekdays'],
    CalendarService.providerMetaData['weekends'],
    CalendarService.providerMetaData['days-of-week']
  ];

  static timeProviderKeys: AvailabilityProviderMetadata[] = [
    CalendarService.providerMetaData['calendar-service'],
    CalendarService.providerMetaData['time-range']
  ];

  static providers: { [key: string]: AvailabilityProvider } = {
    'days-of-week': new DaysOfWeekAvailabilityProvider(),
    weekdays: new WeekdayAvailabilityProvider(),
    weekends: new WeekendAvailabilityProvider(),
    'calendar-service': new CalendarServiceAvailabilityProvider(),
    'time-range': new TimeRangeAvailabilityProvider()
  };

  static dateValidatorMetaData: {
    [key: string]: DateValidatorProviderMetadata;
  } = {
    today: { key: 'today', display: 'Today' },
    'beginning-of-week': {
      key: 'beginning-of-week',
      display: 'Beginning of Week'
    },
    'specified-date': { key: 'specified-date', display: 'Specified Date' },
    'day-of-year': { key: 'day-of-year', display: 'Day of Year' },
    'day-of-month': { key: 'day-of-month', display: 'Day of Month' },
    'today-offset': { key: 'today-offset', display: 'Today +/- days' }
  };

  static dateValidatorProviders: { [key: string]: IDateValidator } = {
    today: new TodayValidator(),
    'beginning-of-week': new BeginningOfWeekValidator(),
    'specified-date': new SpecificDateValidator(),
    'day-of-year': new DayOfYearValidator(),
    'day-of-month': new DayOfMonthValidator(),
    'today-offset': new TodayOffsetValidator()
  };

  static minimumDateValidatorKeys: DateValidatorProviderMetadata[] = [
    CalendarService.dateValidatorMetaData['today'],
    CalendarService.dateValidatorMetaData['today-offset'],
    CalendarService.dateValidatorMetaData['beginning-of-week'],
    CalendarService.dateValidatorMetaData['specified-date'],
    CalendarService.dateValidatorMetaData['day-of-year'],
    CalendarService.dateValidatorMetaData['day-of-month']
  ];

  static maximumDateValidatorKeys: DateValidatorProviderMetadata[] = [
    CalendarService.dateValidatorMetaData['today'],
    CalendarService.dateValidatorMetaData['today-offset']
  ];

  constructor(
    @Inject(forwardRef(() => DataService)) private _dataService: DataService,
    @Inject(forwardRef(() => WorkflowContextService))
    private _context: WorkflowContextService
  ) {}

  getMinimumDateValidators(): Observable<DateValidatorProviderMetadata[]> {
    return Utilities.makeObservable(() => {
      return CalendarService.minimumDateValidatorKeys;
    });
  }
  getMaximumDateValidators(): Observable<DateValidatorProviderMetadata[]> {
    return Utilities.makeObservable(() => {
      return CalendarService.maximumDateValidatorKeys;
    });
  }
  getDateValidator(key: string): IDateValidator {
    return CalendarService.dateValidatorProviders[key];
  }
  getDateAvailabilityProviders(): Observable<AvailabilityProviderMetadata[]> {
    return Utilities.makeObservable(() => {
      return CalendarService.dateProviderKeys;
    });
  }
  getTimeAvailabilityProviders(): Observable<AvailabilityProviderMetadata[]> {
    return Utilities.makeObservable(() => {
      return CalendarService.timeProviderKeys;
    });
  }
  getAvailabilityProvider(
    provider: string,
    options: any
  ): AvailabilityProvider {
    if (CalendarService.providers[provider]) {
      return CalendarService.providers[provider];
    }
  }
  static createAvailabilityProvider(availabilityType: string) {
    return this.providers[availabilityType];
  }
  static createAvailabilityEditor(availabilityType: string): Promise<any> {
    let componentName: string,
      componentPath: string,
      availabilityMetaData: AvailabilityProviderMetadata;

    availabilityMetaData = this.providerMetaData[availabilityType];

    if (availabilityMetaData) {
      let els = availabilityType.split('-');

      componentName =
        availabilityMetaData.componentName ||
        els.map(e => e.substr(0, 1).toUpperCase() + e.substr(1)).join('') +
          'EditorComponent';
      componentPath =
        availabilityMetaData.componentPath ||
        'app/components/availability-providers/' +
          availabilityType +
          '/' +
          availabilityType +
          '-editor.component';
    }

    if (componentName) {
      //if (!System.has(componentName)) {
      //    return System.import(componentPath)
      //        .then(compModules => {
      //            if (compModules) {
      //                return compModules[componentName];
      //            }
      //        }, (e) => {
      //            return null;
      //        });
      //}
    } else {
      return Promise.resolve(null);
    }
  }
  static createDateValidator(validatorType: string): IDateValidator {
    if (this.dateValidatorProviders[validatorType]) {
      return this.dateValidatorProviders[validatorType];
    }

    return null;
  }
  static createDateValidatorEditor(validatorType: string): Promise<any> {
    let componentName: string,
      componentPath: string,
      validatorMetaData: DateValidatorProviderMetadata;

    validatorMetaData = this.dateValidatorMetaData[validatorType];

    if (validatorMetaData) {
      let els = validatorType.split('-');

      componentName =
        validatorMetaData.componentName ||
        els.map(e => e.substr(0, 1).toUpperCase() + e.substr(1)).join('') +
          'EditorComponent';
      componentPath =
        validatorMetaData.componentPath ||
        'app/components/date-validators/' +
          validatorType +
          '/' +
          validatorType +
          '-editor.component';
    }

    if (componentName) {
      //if (!System.has(componentName)) {
      //    return System.import(componentPath)
      //        .then(compModules => {
      //            if (compModules) {
      //                return compModules[componentName];
      //            }
      //        }, (e) => {
      //            return null;
      //        });
      //}
    } else {
      return Promise.resolve(null);
    }
  }
}

import { IDateValidator } from '../../date-validators';
import { BeginningOfWeekOptions } from '../beginning-of-week-date-validator';

export class BeginningOfWeekValidator implements IDateValidator {
  validateMinDate(date: Date, options: any): boolean {
    throw new DOMException();
  }
  validateMaxDate(date: Date, options: any): boolean {
    throw new DOMException();
  }
  validateDate(date: Date, options: any): boolean {
    throw new DOMException();
  }
  calculateExample(date: Date, options: BeginningOfWeekOptions): Date {
    let sample: Date;

    if (options.startOfWeek) {
      let startOfWeek: number = options.startOfWeek;

      let dateDOW = date.getDay(),
        dateDay = date.getDate(),
        diff =
          startOfWeek <= dateDOW
            ? dateDOW - startOfWeek
            : dateDOW + (7 - startOfWeek);
      //diff = date.getDate() - dateDOW + (dateDOW == startOfWeek ? -6 : 1); // adjust when day is sunday
      sample = new Date(date.setDate(dateDay - diff));
    }

    return sample;
  }
}

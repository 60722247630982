<div class="card">
  <div class="card-body">
    <div class="mb-2">
      <span class="pr-2">Match:</span>
      <div class="form-check form-check-inline">
        <input
          class="form-check-input"
          type="radio"
          [name]="'groupOperator' + group.id"
          [id]="'oppAnd' + group.id"
          [value]="LogicalOperator.And"
          [(ngModel)]="group.logicalOperator"
        />
        <label class="form-check-label" [for]="'oppAnd' + group.id">All</label>
      </div>
      <div class="form-check form-check-inline">
        <input
          class="form-check-input"
          type="radio"
          [name]="'groupOperator' + group.id"
          [id]="'oppOr' + group.id"
          [value]="LogicalOperator.Or"
          [(ngModel)]="group.logicalOperator"
        />
        <label class="form-check-label" [for]="'oppOr' + group.id">Any</label>
      </div>

      <fa-icon
        *ngIf="level !== 0"
        [icon]="faTrash"
        style="cursor: pointer"
        placement="top"
        ngbTooltip="Remove group"
        class="float-right"
        (click)="deleteGroup.emit(group.id)"
      ></fa-icon>
    </div>

    <div *ngFor="let cond of group.conditions; let i = index" class="mb-2">
      <div *ngIf="!cond.isGroup">
        <div
          class="card card-grey card-sm"
          *ngIf="openCondition !== i"
          style="cursor: pointer;"
          (click)="openCondition = i"
        >
          <div class="card-body">
            <div class="card-title">
              If
              <code>{{ cond.field }}</code>
              <span *ngIf="!cond.field" class="text-danger">(unset)</span>
              {{ getOperatorName(cond) }}
              <span *ngIf="isValueValid(cond)">{{ getValueName(cond) }}</span>
              <span
                *ngIf="
                  (cond.val === null ||
                    cond.val === undefined ||
                    cond.val === '') &&
                  !getOperator(cond.formulaOperator).noValue
                "
                class="text-danger"
                >(unset)</span
              >
              <span *ngIf="!isValueValid(cond)" class="text-danger"
                >(invalid)</span
              >

              <span class="float-right">
                <fa-icon
                  *ngIf="level < 2"
                  [icon]="faCodeBranch"
                  style="cursor: pointer"
                  placement="top"
                  ngbTooltip="Add to group"
                  class="ml-2"
                  (click)="addGroup(i)"
                ></fa-icon>
                <fa-icon
                  [icon]="faTrash"
                  style="cursor: pointer"
                  placement="top"
                  ngbTooltip="Remove"
                  class="ml-2"
                  (click)="deleteCondition(i)"
                ></fa-icon
              ></span>
            </div>
          </div>
        </div>

        <div class="card card-grey" *ngIf="openCondition === i">
          <div class="card-body">
            <form>
              <div class="form-row align-items-end mb-4">
                <div class="col-4">
                  <label for="field">If</label>
                  <select
                    class="form-control"
                    id="field"
                    name="field"
                    [(ngModel)]="cond.field"
                  >
                    <option [value]="undefined" selected [disabled]="true">
                      Choose...
                    </option>
                    <option
                      [ngValue]="source.value"
                      *ngFor="let source of conditionSources"
                    >
                      {{ source.name }}
                    </option>
                  </select>
                </div>
                <div class="col-4">
                  <select
                    class="form-control"
                    id="operator"
                    name="operator"
                    [(ngModel)]="cond.formulaOperator"
                  >
                    <option
                      [ngValue]="op.operator"
                      *ngFor="let op of getOperators(cond)"
                    >
                      {{ op.name }}
                    </option>
                  </select>
                </div>
                <div
                  class="col-4"
                  *ngIf="!getOperator(cond.formulaOperator).noValue"
                >
                  <input
                    *ngIf="
                      !getSource(cond) ||
                      (getSource(cond) && !getSource(cond).possibleValues)
                    "
                    type="text"
                    class="form-control"
                    name="value"
                    [(ngModel)]="cond.val"
                  />
                  <select
                    *ngIf="getSource(cond) && getSource(cond).possibleValues"
                    class="form-control"
                    id="value"
                    name="value"
                    [(ngModel)]="cond.val"
                  >
                    <option
                      value=""
                      *ngIf="cond.val === ''"
                      selected
                      [disabled]="true"
                    >
                      Choose...
                    </option>
                    <option
                      [value]="undefined"
                      *ngIf="cond.val === undefined"
                      selected
                      [disabled]="true"
                    >
                      Choose...
                    </option>
                    <option
                      [ngValue]="val.value"
                      *ngFor="let val of getSource(cond).possibleValues"
                    >
                      {{ val.name }}
                    </option>
                  </select>
                </div>
              </div>
            </form>

            <button
              class="btn btn-raised btn-primary ml-2 float-right"
              (click)="applyCondition(cond)"
            >
              Apply
            </button>
          </div>
        </div>
      </div>

      <wm-condition-group
        *ngIf="cond.isGroup"
        [group]="cond"
        [conditionSources]="conditionSources"
        [target]="target"
        [level]="level + 1"
        (deleteGroup)="removeGroup($event)"
        (conditionChanges)="conditionChanges.emit($event)"
      ></wm-condition-group>
    </div>

    <button
      type="button"
      class="btn btn-primary bmd-btn-fab bmd-btn-fab-sm mt-2"
      style="font-size: 1rem;"
      (click)="newCondition()"
    >
      <fa-icon [icon]="faPlus" size="xs" style="cursor: pointer"></fa-icon>
    </button>
  </div>
</div>

import { PaymentProcessorConfigurationBase } from '../payment-processor-configuration-base';

export class CardpointePaymentProcessorConfiguration extends PaymentProcessorConfigurationBase {
  tokenURL: string;

  constructor(options?: Partial<CardpointePaymentProcessorConfiguration>) {
    super(options);

    this.tokenURL = '';

    if (options) {
      Object.assign(this, options);
    }
  }
}

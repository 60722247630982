import { Injectable, Inject, forwardRef, Injector } from '@angular/core';
import { ActivityFactory, DataEntityFactory } from './workflow.service';
import { Activity, ActivityModel } from '../models/activities';
import { DataService } from './data.service';
import { ApplicationConfiguration, User, Action, Actions } from '../models';
import { WorkflowContextService } from './workflow-context.service';
import { SecurityService } from './security.service';
import { DataEntity } from '../models/data-entities';

@Injectable()
export class AppLoadService {
  constructor(
    private _context: WorkflowContextService,
    private dataService: DataService,
    private _injector: Injector
  ) {}

  initializeApp(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      // load available activities from the server
      return await this.dataService
        .initApplication()
        .toPromise()
        .then(
          (result: {
            systemActivities: Activity<ActivityModel>[];
            systemDataEntities: DataEntity[];
            appConfiguration: ApplicationConfiguration;
            userActions: { [key: string]: string[] };
            user: User;
            actions: { [key: string]: Action };
            version: string;
            hasPreviousSystemData: boolean;
            previousSystemDataWorkflowId: string;
            isProduction: boolean;
          }) => {
            const securitySvc = this._injector.get(SecurityService);

            // set the userActions for the user
            securitySvc.userActions = result.userActions;
            Actions.ActionInformation = result.actions;
            this._context.appConfiguration$.emit(result.appConfiguration);
            ActivityFactory.initialize(result.systemActivities);
            DataEntityFactory.initialize(result.systemDataEntities);
            this._context.user$.emit(result.user);
            this._context.versionHash = result.version;
            this._context.previousSystemDataWorkflowId$.emit(result.previousSystemDataWorkflowId);
            this._context.hasPreviousSystemData = result.hasPreviousSystemData;
            this._context.isProduction = result.isProduction;
            resolve(null);
          }
        );
    });
  }
}

<div *ngIf="queueItem">
  <div class="row">
    <div class="flex-column m-4 align-self-center" *ngIf="selectable">
      <div class="switch">
        <label>
          <input
            type="checkbox"
            [(ngModel)]="selected"
            (click)="emitSelected()"
            [disabled]="queueItem.status == 3 || queueItem.status == 4"
          />
        </label>
      </div>
    </div>

    <div class="col">
      <div class="row">
        <div class="col">
          <div [innerHTML]="queueItem.evaluatedAgendaItem"></div>
        </div>
      </div>
      <div
        class="row mt-2"
        *ngIf="queueItem.minutes && queueItem.minutes !== ''"
      >
        <div class="col">
          <wm-modal-confirm
            title="Minutes"
            cancelText="Close"
            [showButton]="true"
            [showButtonText]="true"
            buttonText="View Minutes"
            [buttonStyle]="ButtonStyle.Link"
            [showCommandButton]="false"
            #minutesModal
          >
            <div [innerHTML]="queueItem.minutes"></div>
          </wm-modal-confirm>
        </div>
      </div>
      <div class="pl-1 pr-5">
        <hr />
      </div>
      <div class="row mt-2">
        <div class="col-2">
          Status: {{ queueItem.status | agendaQueueItemStatus }}
        </div>
        <div class="col-3">
          Hearing Requested: {{ queueItem.requestedOn | date: 'M/d/yyyy' }}
        </div>
        <div class="col-2">
          <a
            [routerLink]="[
              '/application/workflow-application',
              queueItem.applicationId
            ]"
            target="blank"
          >
            View Application
          </a>
        </div>
        <div class="col-3">
          <div *ngIf="queueItem.ballotPath && queueItem.ballotPath !== ''">
            Ballot:
            <a href="{{ queueItem.ballotPath }}" target="blank">
              {{ queueItem.ballotName }}
            </a>
          </div>
        </div>
        <div class="col-2"></div>
      </div>
    </div>
  </div>
</div>

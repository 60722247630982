import { Subscription } from 'rxjs';
import {
  Component,
  OnInit,
  Input,
  forwardRef,
  Output,
  EventEmitter,
  Inject,
  OnDestroy
} from '@angular/core';
import { WorkflowValidationService } from 'src/app/services/workflow-validation.service';
import { ValidationResponse } from 'src/app/models/validation';

@Component({
  selector: 'wm-workflow-validation-item',
  templateUrl: './workflow-validation-item.component.html',
  styleUrls: ['./workflow-validation-item.component.css']
})
export class WorkflowValidationItemComponent implements OnInit, OnDestroy {
  @Input() workflowId: string;
  @Input() itemId: string;
  @Input() showView = true;
  @Input() activityId: string;

  @Output() valid: EventEmitter<ValidationResponse> = new EventEmitter<
    ValidationResponse
  >();

  isValidating = false;
  validatedListener: Subscription;

  validationErrors: ValidationResponse;
  loading = false;

  constructor(
    @Inject(forwardRef(() => WorkflowValidationService))
    private validationSvc: WorkflowValidationService
  ) {}

  ngOnDestroy(): void {
    if (this.validatedListener) {
      this.validatedListener.unsubscribe();
    }
  }

  ngOnInit() {
    this.validatedListener = this.validationSvc.validated.subscribe(() => {
      this.getValidation();
    });

    if (this.workflowId && this.itemId) {
      this.getValidation();
    }
  }

  async getValidation() {
    this.loading = true;
    const validationInfo = await this.validationSvc.validateDataEntity(
      this.workflowId,
      this.activityId,
      this.itemId
    );

    this.validationErrors = validationInfo.find(
      e => e.id === this.itemId && e.activityId === this.activityId
    );
    this.valid.emit(this.validationErrors);
    this.loading = false;
  }
}

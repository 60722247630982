import { ActivityView } from 'src/app/views/master-views/app.view/app.view.component';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'wm-export-data-activity-view',
  templateUrl: './export-data-activity-view.component.html',
  styleUrls: ['./export-data-activity-view.component.css']
})
export class ExportDataActivityViewComponent extends ActivityView
  implements OnInit {
  constructor() {
    super();
  }

  ngOnInit() {}
}

<div class="row" *ngFor="let de of entities">
  <div class="col">
    <div class="form-group">
      <wm-data-entity-view
        [entity]="de.entity"
        [form]="form"
        [applicationId]="applicationId"
        [activity]="activity"
      ></wm-data-entity-view>
    </div>
  </div>
</div>

import { Component, OnInit, Input, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EventEmitter } from '@angular/core';
import { Workflow } from 'src/app/models';

@Component({
  selector: 'wm-workflow-applications-needing-my-attention',
  templateUrl: './workflow-applications-needing-my-attention.component.html',
  styleUrls: ['./workflow-applications-needing-my-attention.component.css']
})
export class WorkflowApplicationsNeedingMyAttentionComponent implements OnInit {
  public workflowId = '';
  @Output() updateStatus = new EventEmitter();
  @Output() dataLoaded = new EventEmitter<string>();
  @Output() recordCountSet = new EventEmitter<number>();
  @Output() filtersChanged = new EventEmitter<boolean>();
  @Input() workflows: Workflow[];

  id = 'workflow-applications-needing-my-attention';

  myWorkflowsFilter = [
    {
      title: 'relevantToUser',
      options: [
        {
          title: 'relevantToUser',
          selected: true,
          filterText: 'true',
          searchText: null,
          strOperator: null
        }
      ]
    }
  ];

  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.workflowId = params['workflowId'];
      //  this.getWorkflowDetails();
    });
  }
  updateStatusEvent(evt) {
    this.updateStatus.emit(evt);
  }
  emitDataLoaded() {
    this.dataLoaded.emit('WorkflowApplicationsNeedingMyAttentionComponent');
  }

  setRecordCount(count: number) {
    this.recordCountSet.emit(count);
  }

  filtersChange() {
    this.filtersChanged.emit(true);
  }
}

import {
  Component,
  OnInit,
  Input,
  ComponentFactoryResolver,
  ElementRef,
  AfterViewInit,
  ViewChild,
  HostListener
} from '@angular/core';
import { DataEntityInputComponent } from '../../data-entity-input/data-entity-input.component';
import { SignatureDataEntity } from 'src/app/models/data-entities/signature-data-entity';
import { UntypedFormBuilder, NgForm, Validators } from '@angular/forms';
import { SignatureFieldComponent } from '../signature-field/signature-field.component';
import SignaturePad, { PointGroup } from 'signature_pad';
import { isArray } from 'jquery';

@Component({
  selector: 'wm-signature-data-entity-input',
  templateUrl: './signature-data-entity-input.component.html',
  styleUrls: ['./signature-data-entity-input.component.css']
})
export class SignatureDataEntityInputComponent extends DataEntityInputComponent
  implements OnInit, AfterViewInit {
  @Input() entity: SignatureDataEntity;
  @ViewChild(SignatureFieldComponent, { static: false })
  public sig: SignatureFieldComponent;
  @ViewChild('sigContainer1') public sigContainer1: ElementRef;

  constructor(
    componentFactoryResolver: ComponentFactoryResolver,
    private _fb: UntypedFormBuilder
  ) {
    super(componentFactoryResolver);
  }
  onFormSubmit(form: NgForm) {}

  ngOnInit() {
    this.form.addControl(
      'signatureField1',
      this._fb.control(false, [Validators.nullValidator])
    );
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.beResponsive();
    this.sig.signaturePad.fromData(this.sig.signaturePad.toData());
  }

  public ngAfterViewInit() {
    this.setOptions();

    // this is here to let the parent resize before we resize the canvas for the signature in modal dialogs.
    setTimeout(() => {
      this.beResponsive();

      if ((this.entity.value || '') !== '') {
        let value: { data: PointGroup[]; image: string };
        let parsedValue = null;
        parsedValue = JSON.parse(this.entity.value as string);

        // I can't determine how the value gets this way, but it could appear as {"data":null,"image":""}
        // If that's the case, treat it the same as a null value would be treated.
        if ((parsedValue.data !== null) && (parsedValue.image !== '')) {
          if (parsedValue.data) {
            if (parsedValue.data.indexOf('lx') > -1) {
              parsedValue.data = parsedValue.data
                .replaceAll('lx', 'x')
                .replaceAll('ly', 'y');
              parsedValue.data = `[` + parsedValue.data + `]`;
            } else {
              parsedValue = JSON.parse(this.entity.value as string);
            }
  
            value = parsedValue;
          }
  
          if (typeof parsedValue.data === 'string') {
            parsedValue.data = JSON.parse(parsedValue.data as string);
          }
  
          let newData = [];
  
          //
          /*
            old structure looks like this
            [
              [ // point group
                {
                  "x": 174,
                  "y": 51.6666259765625,
                  "time": 1710160764688,
                  "color": "rgb(255, 0, 0)",
                  "$id": "52"
                },
                {
                  "x": 174,
                  "y": 51.6666259765625,
                  "time": 1710160764688,
                  "color": "rgb(255, 0, 0)",
                  "$id": "52"
                }
              ],
              [ // point group
                {
                  "x": 174,
                  "y": 51.6666259765625,
                  "time": 1710160764688,
                  "color": "rgb(255, 0, 0)",
                  "$id": "52"
                },
                {
                  "x": 174,
                  "y": 51.6666259765625,
                  "time": 1710160764688,
                  "color": "rgb(255, 0, 0)",
                  "$id": "52"
                }
              ]
            ]
            new structure should look like this
            [
              {
                  points:[]
              },
              {
                points: []
              }
            ]
          */
          // check to see if data is an array
          if (isArray(value.data)) {
            if (value.data.length > 0) {
              if (isArray(value.data[0])) {
                for (let pgIdx = 0; pgIdx < value.data.length; pgIdx++) {
                  // add point group to new Data with value as points property
                  newData.push({
                    penColor: 'rgb(255, 0, 0)',
                    dotSize: 0,
                    minWidth: 0.5,
                    maxWidth: 2.5,
                    velocityFilterWeight: 0.7,
                    points: value.data[pgIdx]
                  });
                }
              } else {
                newData = value.data;
              }
            }
          }
  
          if (value && value.data) {
            this.sig.signaturePad.fromData(newData);
          }
          this.form.controls[this.entity.templateCode].setValue(
            this.entity.value
          );
        }
      }
    }, 100);
  }

  // set the dimensions of the signature pad canvas
  public beResponsive() {
    this.size(this.sigContainer1, this.sig);
  }

  public size(container: ElementRef, sig: SignatureFieldComponent) {
    sig.signaturePad.set('canvasWidth', container.nativeElement.clientWidth);
  }

  public setOptions() {
    this.sig.signaturePad.set('penColor', 'rgb(255, 0, 0)');
    this.sig.signaturePad.set('backgroundColor', 'rgba(0, 0, 0, 0)');
  }

  public clear() {
    this.sig.clear();
    this.entity.value = null;
    this.form.controls[this.entity.templateCode].setValue(null);
  }

  public finishedSigning(data: SignaturePad) {
    this.entity.value = {
      image: data.toDataURL('image/png', 1),
      data: data.toData()
    };
    this.form.controls[this.entity.templateCode].setValue(this.entity.value);
  }
}

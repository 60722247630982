import {
  Component,
  OnInit,
  ChangeDetectorRef,
  Inject,
  forwardRef
} from '@angular/core';
import {
  WorkflowService,
  WorkflowContextService,
  SystemService
} from '../../../../../services';
import { UntypedFormBuilder, Validators, UntypedFormControl } from '@angular/forms';
import { GenerateClientApplicationNumberDataEntity } from '../../../../../models/data-entities/client-app-number-data-entity';
import { DataEntityEditorBaseComponent } from '../../data-entity-editor-base/data-entity-editor-base.component';
import { ConditionTarget } from 'src/app/components/system/condition-builder/condition-builder.model';

@Component({
  selector: 'wm-client-app-number-data-entity',
  templateUrl: './client-app-number-data-entity-editor.component.html',
  styleUrls: ['./client-app-number-data-entity-editor.component.css']
})
export class GenerateClientApplicationNumberDataEntityEditorComponent
  extends DataEntityEditorBaseComponent
  implements OnInit {
  constructor(
    @Inject(forwardRef(() => WorkflowService))
    public _workflowSvc: WorkflowService,
    @Inject(forwardRef(() => SystemService)) private _systemSvc: SystemService,
    private changeDetRef: ChangeDetectorRef,
    private _fb: UntypedFormBuilder
  ) {
    super();
  }
  dataEntity: GenerateClientApplicationNumberDataEntity;
  isDestroyed = false;
  ngOnInit() {
    if (this.form) {
      this.form.addControl(
        'numberPrefix',
        new UntypedFormControl('', [Validators.nullValidator])
      );
    }
  }

  detectChanges() {
    if (this.changeDetRef && !this.isDestroyed) {
      this.changeDetRef.detectChanges();
    }
  }

  // Use => in order to force `this` into being the FeeDataEntityEditorComponent
  getThenLabel = (clause: ConditionTarget): string => {
    if (clause) {
      return clause.value;
    } else {
      return 'needs refactored';
    }
  }

  updateCriteria(criteria: ConditionTarget[]) {
    this.dataEntity.numberPrefixCriteria = criteria;
    let hasValue = false;

    if (criteria && criteria.length > 0) {
      criteria.forEach((c, index) => {
        if ((c.value || '') !== '') {
          hasValue = true;
          return false;
        }
      });
    }
    this.form.controls['numberPrefix'].setValue(hasValue ? 'done' : '');
    this.detectChanges();
  }
}

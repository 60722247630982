import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ValidationService } from '../../../services';

@Component({
  selector: 'wm-control-message',
  templateUrl: './control-message.component.html',
  styleUrls: ['./control-message.component.css']
})
export class ControlMessageComponent implements OnInit {
  @Input() control: UntypedFormControl;
  @Input() prefix?: string;

  constructor() {}

  get errorMessage() {
    // Find the control in the Host (Parent) form
    if (this.control && this.control.errors) {
      for (const propertyName in this.control.errors) {
        // If control has a error
        if (
          this.control.errors.hasOwnProperty(propertyName) &&
          (this.control.dirty || this.control.touched)
        ) {
          // Return the appropriate error message from the Validation Service
          const errors = ValidationService.getValidatorErrorMessage(
            propertyName,
            this.control.errors[propertyName].data ||
              this.control.errors[propertyName]
          );
          return (this.prefix || '') + errors;
        }
      }
    }

    return null;
  }

  ngOnInit() {}
}

<h3>Contractor Type Settings</h3>
<form [formGroup]="form" novalidate>
  <div class="mt-3" *ngIf="contractorType">
    <div *ngIf="contractorTypeId">
      <div class="btn-group mr-2">
        <button
          class="btn btn-raised"
          [ngClass]="validationInfo.register.class"
          [disabled]="validity.register.length > 0 || testRegisterLoading"
          (click)="startTestRegistration()"
        >
          <span *ngIf="!testRegisterLoading">Test Register Workflow</span>

          <span *ngIf="testRegisterLoading">
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
            Starting...
          </span>
        </button>
        <button
          class="btn btn-raised"
          [ngClass]="validationInfo.register.class"
          [ngbTooltip]="validationInfo.register.text"
          (click)="validateRegisterWorkflow()"
        >
          <i class="material-icons">{{ validationInfo.register.icon }}</i>
        </button>
      </div>

      <div class="btn-group mr-2">
        <button
          class="btn btn-raised"
          [ngClass]="validationInfo.renewal.class"
          [disabled]="validity.renewal.length > 0 || testRenewalLoading"
          (click)="startTestRenewal()"
        >
          <span *ngIf="!testRenewalLoading">Test Renew Workflow</span>

          <span *ngIf="testRenewalLoading">
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
            Starting...
          </span>
        </button>
        <button
          class="btn btn-raised"
          [ngClass]="validationInfo.renewal.class"
          [ngbTooltip]="validationInfo.renewal.text"
          (click)="validateRenewalWorkflow()"
        >
          <i class="material-icons">{{ validationInfo.renewal.icon }}</i>
        </button>
      </div>
    </div>

    <div ngbAccordion #acc="ngbAccordion">
      <div
        ngbAccordionItem="general"
        #general="ngbAccordionItem"
        id="general"
        [collapsed]="false"
        class="card-header"
      >
        <h2 ngbAccordionHeader>
          <button ngbAccordionToggle class="p-0 btn btn-link text-start ps-0">
            General Settings
          </button>
        </h2>
        <div ngbAccordionCollapse>
          <div ngbAccordionBody>
            <ng-template>
              <div class="row">
                <div class="col-6">
                  <div class="form-group">
                    <label class="bmd-label-floating" for="name">Name</label>
                    <input
                      type="text"
                      class="form-control"
                      formControlName="name"
                      [(ngModel)]="contractorType.name"
                    />
                    <wm-control-message
                      [control]="form.controls['name']"
                    ></wm-control-message>
                  </div>

                  <div class="form-group">
                    <label class="bmd-label-floating" for="description">
                      Description
                    </label>
                    <textarea
                      class="form-control"
                      formControlName="description"
                      [(ngModel)]="contractorType.description"
                    >
                    </textarea>
                  </div>

                  <div
                    class="form-group"
                    *ngIf="
                      !contractorTypeId ||
                      contractorType.registerWorkflow.parentWorkflowid
                    "
                  >
                    <label class="bmd-label-floating" for="registerWorkflowId">
                      Register Workflow Template
                    </label>
                    <select
                      class="form-control"
                      formControlName="registerWorkflowId"
                      [(ngModel)]="
                        contractorTypeId
                          ? contractorType.registerWorkflow?.parentWorkflowid
                          : contractorType.registerWorkflowId
                      "
                      [attr.disabled]="contractorTypeId ? true : null"
                    >
                      <option
                        *ngFor="let template of registerTemplates"
                        [ngValue]="template.id"
                      >
                        {{ template.version.name }}
                      </option>
                    </select>
                  </div>

                  <div
                    class="form-group"
                    *ngIf="
                      !contractorTypeId ||
                      contractorType.renewWorkflow.parentWorkflowid
                    "
                  >
                    <label class="bmd-label-floating" for="renewWorkflowId">
                      Renew Workflow Template
                    </label>
                    <select
                      class="form-control"
                      formControlName="renewWorkflowId"
                      [(ngModel)]="
                        contractorTypeId
                          ? contractorType.renewWorkflow?.parentWorkflowid
                          : contractorType.renewWorkflowId
                      "
                      [attr.disabled]="contractorTypeId ? true : null"
                    >
                      <option
                        *ngFor="let template of renewTemplates"
                        [ngValue]="template.id"
                      >
                        {{ template.version.name }}
                      </option>
                    </select>
                  </div>

                  <div class="row ml-1 mr-1">
                    <div class="col-12">
                      <div class="form-group">
                        <label for="expOptions" class="bmd-label-floating">
                          Expiration Date
                        </label>
                        <select
                          class="form-control"
                          name="expOptions"
                          [(ngModel)]="selectedExpirationOption"
                          [ngModelOptions]="{ standalone: true }"
                          required
                          (change)="onExpirationOptionChange()"
                        >
                          <option
                            *ngFor="let option of expirationOptions"
                            [ngValue]="option"
                          >
                            {{ option.label }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div class="row ml-1 mr-1" *ngIf="selectedExpirationOption">
                    <div
                      class="col-12"
                      *ngIf="selectedExpirationOption.name == 'custom-months'"
                    >
                      <div class="form-group">
                        <label class="bmd-label-floating" for="termLength">
                          Term length from issue date (months)
                        </label>
                        <input
                          name="termLength"
                          type="text"
                          class="form-control"
                          [(ngModel)]="termLengthMonths"
                          formControlName="termLength"
                        />
                        <wm-control-message
                          [control]="form.controls['termLength']"
                        ></wm-control-message>
                      </div>
                    </div>
                  </div>

                  <div
                    class="form-row ml-1 mr-1"
                    *ngIf="selectedExpirationOption"
                  >
                    <div
                      class="col-12"
                      *ngIf="selectedExpirationOption.name == 'custom-formula'"
                    >
                      <div class="form-group">
                        <wm-data-entity-formula
                          [required]="true"
                          [criteria]="customFormulaCriteria"
                          [workflow]="contractorType.registerWorkflow"
                          [thenLabelGetter]="getThenLabel"
                          (criteriaChanged)="updateExpirationCriteria($event)"
                          formulaTitle="Custom Expiration Date Formula"
                          [onlyGlobalDataEntities]="false"
                          [excludedTemplateCodes]="
                            expirationCustomForumulaExcludedTemplateCodes
                          "
                        >
                        </wm-data-entity-formula>
                        <wm-control-message
                          [control]="form.controls['customFormula']"
                        ></wm-control-message>
                      </div>
                    </div>
                  </div>
                  <div class="switch">
                    <label>
                      <input
                        type="checkbox"
                        formControlName="visibleToPublic"
                        [(ngModel)]="contractorType.visibleToPublic"
                      />

                      Visible to public
                    </label>
                  </div>
                </div>

                <div class="col-6" *ngIf="contractorTypeId">
                  <wm-item-tag-list
                    [itemTags]="contractorTypeTags"
                    (added)="tagAdded($event)"
                    (removed)="tagRemoved($event)"
                  ></wm-item-tag-list>
                </div>
              </div>

              <button
                class="btn btn-raised btn-success"
                [disabled]="saving"
                (click)="save()"
              >
                <span *ngIf="!saving">Save</span>

                <span *ngIf="saving">
                  <span
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Saving...
                </span>
              </button>
            </ng-template>
          </div>
        </div>
      </div>

      <ng-container *ngIf="contractorTypeId">
        <div
          ngbAccordionItem="settings"
          #settings="ngbAccordionItem"
          id="settings"
          (shown)="detectChanges()"
          class="card-header"
        >
          <h2 ngbAccordionHeader>
            <button ngbAccordionToggle class="p-0 btn btn-link text-start ps-0">
              Settings
            </button>
          </h2>
          <div ngbAccordionCollapse>
            <div ngbAccordionBody>
              <ng-template>
                <div class="row">
                  <div class="col-auto">
                    <ul
                      ngbNav
                      #navs="ngbNav"
                      [destroyOnHide]="false"
                      (navChange)="detectChanges()"
                      class="nav-tabs"
                    >
                      <li ngbNavItem>
                        <a ngbNavLink>Registration</a>
                        <ng-template ngbNavContent>
                          <div
                            class="form-group"
                            *ngIf="
                              form &&
                              form.controls[
                                'registrationApplicationDescriptionTemplate'
                              ]
                            "
                          >
                            <wm-data-entity-autocomplete
                              [types]=""
                              [form]="form"
                              [model]="
                                contractorType.registerWorkflow.version
                                  .applicationDescriptionTemplate
                              "
                              id="registrationApplicationDescriptionTemplate"
                              [required]="false"
                              [workflow]="contractorType.registerWorkflow"
                              controlName="registrationApplicationDescriptionTemplate"
                              [serverValidator]="
                                workflowSvc.validateApplicationTemplateCriteria
                              "
                              (valueUpdate)="
                                contractorType.registerWorkflow.version.applicationDescriptionTemplate = $event
                              "
                              title="Application Description Template"
                              *ngIf="contractorType.registerWorkflow"
                            ></wm-data-entity-autocomplete>
                            <wm-control-message
                              [control]="
                                form.controls[
                                  'registrationApplicationDescriptionTemplate'
                                ]
                              "
                            ></wm-control-message>
                          </div>

                          <div class="card">
                            <div class="card-body">
                              <h6 class="card-title">
                                Application Number Settings
                              </h6>
                              <div class="row">
                                <div class="col">
                                  <wm-data-entity-formula
                                    [required]="true"
                                    [workflow]="contractorType.registerWorkflow"
                                    [thenLabelGetter]="getThenLabel"
                                    (criteriaChanged)="
                                      updateCriteria(
                                        $event,
                                        contractorType.registerWorkflow,
                                        'registrationNumberPrefix'
                                      )
                                    "
                                    [criteria]="
                                      contractorType.registerWorkflow
                                        .numberPrefixCriteria
                                    "
                                    formulaTitle="Number Prefix"
                                  >
                                  </wm-data-entity-formula>
                                  <wm-control-message
                                    *ngIf="
                                      form.controls['registrationNumberPrefix']
                                    "
                                    [control]="
                                      form.controls['registrationNumberPrefix']
                                    "
                                  ></wm-control-message>
                                </div>
                                <div class="col">
                                  <div class="form-group">
                                    <label
                                      class="bmd-label-floating"
                                      for="registrationStartingNumber"
                                    >
                                      Starting Number
                                    </label>
                                    <input
                                      type="number"
                                      class="form-control"
                                      [(ngModel)]="
                                        contractorType.registerWorkflow
                                          .clientApplicationNumber
                                      "
                                      name="registrationStartingNumber"
                                      formControlName="registrationStartingNumber"
                                    />
                                    <wm-control-message
                                      *ngIf="
                                        form.controls[
                                          'registrationStartingNumber'
                                        ]
                                      "
                                      [control]="
                                        form.controls[
                                          'registrationStartingNumber'
                                        ]
                                      "
                                    ></wm-control-message>
                                  </div>
                                </div>
                                <div class="col">
                                  <div class="form-group">
                                    <div>
                                      <label
                                        class="bmd-label-static mb-0"
                                        for="registerTotalLength"
                                      >
                                        Fixed Number Length
                                      </label>
                                      <wm-help-text
                                        class="ml-1"
                                        style="position: absolute; left: 116px; top: .75rem;"
                                        placement="right"
                                        helpText="Total number of digits of the numeric portion of the application number. The number will be padded with zeros to make up the full length."
                                      ></wm-help-text>
                                    </div>
                                    <input
                                      type="number"
                                      class="form-control"
                                      [(ngModel)]="
                                        contractorType.registerWorkflow
                                          .fixedClientApplicationNumberLength
                                      "
                                      name="registerTotalLength"
                                      formControlName="registerTotalLength"
                                    />
                                    <wm-control-message
                                      [control]="
                                        form.controls['registerTotalLength']
                                      "
                                    ></wm-control-message>
                                    <div class="pt-1 font-italic">
                                      Preview: {{ registerFixedNumberPreview }}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col">
                                  <div class="switch">
                                    <label>
                                      <input
                                        type="checkbox"
                                        class="float-right"
                                        [(ngModel)]="registerAutoReset"
                                        formControlName="registerAutoReset"
                                        (change)="toggleRegisterAutoReset()"
                                      />
                                      Auto Reset?
                                    </label>
                                    <wm-help-text
                                      class="ml-1"
                                      placement="right"
                                      helpText="The auto reset will not effect applications that have already had a number generated prior to setting this value."
                                    ></wm-help-text>
                                  </div>
                                  <div *ngIf="registerNextResetDate">
                                    <label
                                      class="bmd-label-static"
                                      for="registerResetMonth"
                                    >
                                      Next Reset On
                                    </label>
                                    <div>{{ registerNextResetDate }}</div>
                                  </div>
                                </div>
                                <div class="col">
                                  <div class="form-group">
                                    <label
                                      class="bmd-label-floating"
                                      for="registerResetMonth"
                                    >
                                      Month of Year
                                    </label>
                                    <wm-help-text
                                      class="ml-1"
                                      style="position: absolute; left: 75px; top: .75rem;"
                                      placement="right"
                                      helpText="Month of year you want the number to reset."
                                    ></wm-help-text>
                                    <select
                                      class="form-control"
                                      name="registerResetMonth"
                                      formControlName="registerResetMonth"
                                      (change)="
                                        calculateRegisterNextResetDate()
                                      "
                                    >
                                      <option></option>
                                      <option
                                        *ngFor="let rm of months"
                                        [value]="rm.value"
                                        >{{ rm.key }}</option
                                      >
                                    </select>
                                    <wm-control-message
                                      [control]="
                                        form.controls['registerResetMonth']
                                      "
                                    ></wm-control-message>
                                  </div>
                                </div>
                                <div class="col">
                                  <div class="form-group">
                                    <label
                                      class="bmd-label-floating"
                                      for="registerResetDay"
                                    >
                                      Day of Month
                                    </label>
                                    <wm-help-text
                                      class="ml-1"
                                      style="position: absolute; left: 75px; top: .75rem;"
                                      placement="right"
                                      helpText="Day of the Month you want the number to reset."
                                    ></wm-help-text>
                                    <select
                                      class="form-control"
                                      name="registerResetDay"
                                      formControlName="registerResetDay"
                                      (change)="
                                        calculateRegisterNextResetDate()
                                      "
                                    >
                                      <option></option>
                                      <option
                                        *ngFor="let rd of days"
                                        [value]="rd"
                                        >{{ rd }}</option
                                      >
                                    </select>
                                    <wm-control-message
                                      [control]="
                                        form.controls['registerResetDay']
                                      "
                                    ></wm-control-message>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </ng-template>
                      </li>
                      <li ngbNavItem>
                        <a ngbNavLink>Renewal</a>
                        <ng-template ngbNavContent>
                          <div
                            class="form-group"
                            *ngIf="
                              form &&
                              form.controls[
                                'renewalApplicationDescriptionTemplate'
                              ]
                            "
                          >
                            <wm-data-entity-autocomplete
                              [types]=""
                              [form]="form"
                              [model]="
                                contractorType.renewWorkflow.version
                                  .applicationDescriptionTemplate
                              "
                              id="renewalApplicationDescriptionTemplate"
                              [required]="false"
                              [workflow]="contractorType.renewWorkflow"
                              controlName="renewalApplicationDescriptionTemplate"
                              [serverValidator]="
                                workflowSvc.validateApplicationTemplateCriteria
                              "
                              (valueUpdate)="
                                contractorType.renewWorkflow.version.applicationDescriptionTemplate = $event
                              "
                              title="Application Description Template"
                              *ngIf="contractorType.renewWorkflow"
                            ></wm-data-entity-autocomplete>
                            <wm-control-message
                              [control]="
                                form.controls[
                                  'renewalApplicationDescriptionTemplate'
                                ]
                              "
                            ></wm-control-message>
                          </div>
                          <div class="card">
                            <div class="card-body">
                              <h6 class="card-title">
                                Application Number Settings
                              </h6>
                              <div class="row">
                                <div class="col">
                                  <wm-data-entity-formula
                                    [required]="true"
                                    [workflow]="contractorType.renewWorkflow"
                                    [thenLabelGetter]="getThenLabel"
                                    (criteriaChanged)="
                                      updateCriteria(
                                        $event,
                                        contractorType.renewWorkflow,
                                        'renewalNumberPrefix'
                                      )
                                    "
                                    [criteria]="
                                      contractorType.renewWorkflow
                                        .numberPrefixCriteria
                                    "
                                    formulaTitle="Number Prefix"
                                  >
                                  </wm-data-entity-formula>
                                  <wm-control-message
                                    *ngIf="form.controls['renewalNumberPrefix']"
                                    [control]="
                                      form.controls['renewalNumberPrefix']
                                    "
                                  ></wm-control-message>
                                </div>
                                <div class="col">
                                  <div class="form-group">
                                    <label
                                      class="bmd-label-floating"
                                      for="renewalStartingNumber"
                                    >
                                      Starting Number
                                    </label>
                                    <input
                                      type="number"
                                      class="form-control"
                                      [(ngModel)]="
                                        contractorType.renewWorkflow
                                          .clientApplicationNumber
                                      "
                                      name="renewalStartingNumber"
                                      formControlName="renewalStartingNumber"
                                    />
                                    <wm-control-message
                                      *ngIf="
                                        form.controls['renewalStartingNumber']
                                      "
                                      [control]="
                                        form.controls['renewalStartingNumber']
                                      "
                                    ></wm-control-message>
                                  </div>
                                </div>
                                <div class="col">
                                  <div class="form-group">
                                    <div>
                                      <label
                                        class="bmd-label-static mb-0"
                                        for="renewalTotalLength"
                                      >
                                        Fixed Number Length
                                      </label>
                                      <wm-help-text
                                        class="ml-1"
                                        style="position: absolute; left: 116px; top: .75rem;"
                                        placement="right"
                                        helpText="Total number of digits of the numeric portion of the application number. The number will be padded with zeros to make up the full length."
                                      ></wm-help-text>
                                    </div>
                                    <input
                                      type="number"
                                      class="form-control"
                                      [(ngModel)]="
                                        contractorType.renewWorkflow
                                          .fixedClientApplicationNumberLength
                                      "
                                      name="renewalTotalLength"
                                      formControlName="renewalTotalLength"
                                    />
                                    <wm-control-message
                                      [control]="
                                        form.controls['renewalTotalLength']
                                      "
                                    ></wm-control-message>
                                    <div class="pt-1 font-italic">
                                      Preview: {{ renewalFixedNumberPreview }}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col">
                                  <div class="switch">
                                    <label>
                                      <input
                                        type="checkbox"
                                        class="float-right"
                                        [(ngModel)]="renewalAutoReset"
                                        formControlName="renewalAutoReset"
                                        (change)="toggleRenewalAutoReset()"
                                      />
                                      Auto Reset?
                                    </label>
                                    <wm-help-text
                                      class="ml-1"
                                      placement="right"
                                      helpText="The auto reset will not effect applications that have already had a number generated prior to setting this value."
                                    ></wm-help-text>
                                  </div>
                                  <div *ngIf="renewalNextResetDate">
                                    <label
                                      class="bmd-label-static"
                                      for="renewalResetMonth"
                                    >
                                      Next Reset On
                                    </label>
                                    <div>{{ renewalNextResetDate }}</div>
                                  </div>
                                </div>
                                <div class="col">
                                  <div class="form-group">
                                    <label
                                      class="bmd-label-floating"
                                      for="renewalResetMonth"
                                    >
                                      Month of Year
                                    </label>
                                    <wm-help-text
                                      class="ml-1"
                                      style="position: absolute; left: 75px; top: .75rem;"
                                      placement="right"
                                      helpText="Month of year you want the number to reset."
                                    ></wm-help-text>
                                    <select
                                      class="form-control"
                                      name="renewalResetMonth"
                                      formControlName="renewalResetMonth"
                                      (change)="calculateRenewalNextResetDate()"
                                    >
                                      <option></option>
                                      <option
                                        *ngFor="let rm of months"
                                        [value]="rm.value"
                                        >{{ rm.key }}</option
                                      >
                                    </select>
                                    <wm-control-message
                                      [control]="
                                        form.controls['renewalResetMonth']
                                      "
                                    ></wm-control-message>
                                  </div>
                                </div>
                                <div class="col">
                                  <div class="form-group">
                                    <label
                                      class="bmd-label-floating"
                                      for="renewalResetDay"
                                    >
                                      Day of Month
                                    </label>
                                    <wm-help-text
                                      class="ml-1"
                                      style="position: absolute; left: 75px; top: .75rem;"
                                      placement="right"
                                      helpText="Day of the Month you want the number to reset."
                                    ></wm-help-text>
                                    <select
                                      class="form-control"
                                      name="renewalResetDay"
                                      formControlName="renewalResetDay"
                                      (change)="calculateRenewalNextResetDate()"
                                    >
                                      <option></option>
                                      <option
                                        *ngFor="let rd of days"
                                        [value]="rd"
                                        >{{ rd }}</option
                                      >
                                    </select>
                                    <wm-control-message
                                      [control]="
                                        form.controls['renewalResetDay']
                                      "
                                    ></wm-control-message>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </ng-template>
                      </li>
                    </ul>
                    <div [ngbNavOutlet]="navs"></div>
                  </div>
                </div>
              </ng-template>
            </div>
          </div>
        </div>
        <div
          ngbAccordionItem="fees"
          #fees="ngbAccordionItem"
          id="fees"
          class="card-header"
        >
          <h2 ngbAccordionHeader>
            <button ngbAccordionToggle class="p-0 btn btn-link text-start ps-0">
              Fees
            </button>
          </h2>
          <div ngbAccordionCollapse>
            <div ngbAccordionBody>
              <ng-template>
                <div class="row">
                  <div class="col-auto">
                    <ul ngbNav #feesNav="ngbNav" class="nav-tabs">
                      <li ngbNavItem>
                        <a ngbNavLink>Registration</a>
                        <ng-template ngbNavContent>
                          <ul class="list-group">
                            <li
                              class="list-group-item"
                              *ngFor="
                                let regActivity of getValues(
                                  contractorType.registerFeeActivities
                                )
                              "
                            >
                              <div class="card">
                                <div class="card-body">
                                  <div class="card-title">
                                    <h3>{{ regActivity.model.screenName }}</h3>
                                  </div>
                                  <wm-form-activity-editor
                                    [form]="form"
                                    [includedDataEntityTypes]="['fee']"
                                    [workflow]="contractorType.registerWorkflow"
                                    [activity]="regActivity"
                                  ></wm-form-activity-editor>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </ng-template>
                      </li>
                      <li ngbNavItem>
                        <a ngbNavLink>Renewal</a>
                        <ng-template ngbNavContent>
                          <ul class="list-group">
                            <li
                              class="list-group-item"
                              *ngFor="
                                let renActivity of getValues(
                                  contractorType.renewFeeActivities
                                )
                              "
                            >
                              <div class="card">
                                <div class="card-body">
                                  <div class="card-title">
                                    <h3>{{ renActivity.model.screenName }}</h3>
                                  </div>
                                  <wm-form-activity-editor
                                    [form]="form"
                                    [includedDataEntityTypes]="['fee']"
                                    [workflow]="contractorType.renewWorkflow"
                                    [activity]="renActivity"
                                  ></wm-form-activity-editor>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </ng-template>
                      </li>
                    </ul>
                    <div [ngbNavOutlet]="feesNav"></div>
                  </div>
                </div>
              </ng-template>
            </div>
          </div>
        </div>
        <div
          ngbAccordionItem="emails"
          #emails="ngbAccordionItem"
          id="emails"
          class="card-header"
        >
          <h2 ngbAccordionHeader>
            <button ngbAccordionToggle class="p-0 btn btn-link text-start ps-0">
              Email Notifications
            </button>
          </h2>
          <div ngbAccordionCollapse>
            <div ngbAccordionBody>
              <ng-template>
                <div class="row">
                  <div class="col-auto">
                    <ul ngbNav #emailNavs="ngbNav" class="nav-tabs">
                      <li ngbNavItem>
                        <a ngbNavLink>Registration</a>
                        <ng-template ngbNavContent>
                          <ul class="list-group">
                            <li
                              class="list-group-item"
                              *ngFor="
                                let regEmail of getValues(
                                  contractorType.registerEmailActivities
                                )
                              "
                            >
                              <div class="col-auto">
                                {{ regEmail.model.screenName }}
                              </div>
                              <wm-email-activity-editor
                                [activity]="regEmail"
                                [workflow]="contractorType.registerWorkflow"
                                [form]="form"
                              ></wm-email-activity-editor>
                            </li>
                          </ul>
                        </ng-template>
                      </li>
                      <li ngbNavItem>
                        <a ngbNavLink>Renewal</a>
                        <ng-template ngbNavContent>
                          <ul class="list-group">
                            <li
                              class="list-group-item"
                              *ngFor="
                                let renEmail of getValues(
                                  contractorType.renewEmailActivities
                                )
                              "
                            >
                              <div class="col-auto">
                                {{ renEmail.model.screenName }}
                              </div>
                              <wm-email-activity-editor
                                [activity]="renEmail"
                                [workflow]="contractorType.renewWorkflow"
                                [form]="form"
                              ></wm-email-activity-editor>
                            </li>
                          </ul>
                        </ng-template>
                      </li>
                    </ul>
                    <div [ngbNavOutlet]="emailNavs"></div>
                  </div>
                </div>
              </ng-template>
            </div>
          </div>
        </div>
        <div ngbAccordionItem="fields" id="fields" class="card-header">
          <h2 ngbAccordionHeader>
            <button ngbAccordionToggle class="p-0 btn btn-link text-start ps-0">
              Form Fields
            </button>
          </h2>
          <div ngbAccordionCollapse>
            <div ngbAccordionBody>
              <ng-template>
                <div class="row">
                  <div class="col-auto">
                    <ul ngbNav #formNavs="ngbNav" class="nav-tabs">
                      <li ngbNavItem>
                        <a ngbNavLink>Registration</a>
                        <ng-template ngbNavContent>
                          <ul
                            class="list-group"
                            *ngIf="contractorType.registerFormActivities"
                          >
                            <li
                              class="list-group-item"
                              *ngFor="
                                let regActivity of getValues(
                                  contractorType.registerFormActivities
                                )
                              "
                            >
                              <div class="card">
                                <div class="card-body">
                                  <div class="card-title">
                                    <h3>{{ regActivity.model.screenName }}</h3>
                                  </div>
                                  <wm-form-activity-editor
                                    [form]="form"
                                    [excludedDataEntityTypes]="['fee']"
                                    [workflow]="contractorType.registerWorkflow"
                                    [activity]="regActivity"
                                  ></wm-form-activity-editor>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </ng-template>
                      </li>
                      <li ngbNavItem>
                        <a ngbNavLink>Renewal</a>
                        <ng-template ngbNavContent>
                          <ul class="list-group">
                            <li
                              class="list-group-item"
                              *ngFor="
                                let renActivity of getValues(
                                  contractorType.renewFormActivities
                                )
                              "
                            >
                              <div class="card">
                                <div class="card-body">
                                  <div class="card-title">
                                    <h3>{{ renActivity.model.screenName }}</h3>
                                  </div>
                                  <wm-form-activity-editor
                                    [form]="form"
                                    [workflow]="contractorType.renewWorkflow"
                                    [activity]="renActivity"
                                  ></wm-form-activity-editor>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </ng-template>
                      </li>
                    </ul>
                    <div [ngbNavOutlet]="formNavs"></div>
                  </div>
                </div>
              </ng-template>
            </div>
          </div>
        </div>
        <div
          ngbAccordionItem="requirements"
          id="requirements"
          class="card-header"
        >
          <h2 ngbAccordionHeader>
            <button ngbAccordionToggle class="p-0 btn btn-link text-start ps-0">
              Requirements
            </button>
          </h2>
          <div ngbAccordionCollapse>
            <div ngbAccordionBody>
              <ng-template>
                <app-loader *ngIf="saving"></app-loader>
                <div *ngIf="!saving">
                  <div class="card">
                    <div class="card-body">
                      <div class="card-title">
                        <h3>Contractor Requirements</h3>
                      </div>

                      <p>
                        <i>
                          A Contractor Requirement may be applied to a Data
                          Entity on any Contractor Info Form Activity in the
                          Register Workflow. The value of this Data Entity will
                          become associated with the Contractor, and that value
                          will be used to satisfy Requirements of any other
                          Contractor Type that the Contractor applies for that
                          uses the same Data Entity Template Code as this
                          Contractor Requirement, including Contractor Types in
                          other jurisdictions.
                        </i>
                      </p>

                      <button
                        class="btn btn-raised btn-primary float-right"
                        (click)="addNewContractorRequirement()"
                        [disabled]="newContractorRequirement"
                      >
                        Add Requirement
                      </button>
                      <div class="row">
                        <div class="col-auto">
                          <ul class="list-group">
                            <li
                              *ngIf="newContractorRequirement"
                              class="list-group-item"
                            >
                              <wm-contractor-requirement-editor
                                [requirement]="newContractorRequirement"
                                [parentForm]="form"
                                [entities]="availableContractorEntities"
                                (cancelled)="
                                  newContractorRequirementCancelled()
                                "
                                (applied)="newRequirementApplied($event)"
                                [isOpen]="true"
                              ></wm-contractor-requirement-editor>
                            </li>
                            <li
                              class="list-group-item"
                              *ngIf="
                                !contractorRequirements ||
                                contractorRequirements.length == 0
                              "
                            >
                              <i>
                                There are not any Contractor Requirements for
                                this Contractor Type
                              </i>
                            </li>
                            <li
                              class="list-group-item"
                              *ngFor="let requirement of contractorRequirements"
                            >
                              <wm-contractor-requirement-editor
                                [requirement]="requirement"
                                [parentForm]="form"
                                [entities]="contractorEntities"
                                (removed)="removeRequirement($event)"
                                (applied)="save()"
                              ></wm-contractor-requirement-editor>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="card">
                    <div class="card-body">
                      <div class="card-title">
                        <h3>Registration Requirements</h3>
                      </div>

                      <p>
                        <i>
                          A Registration Requirement may be applied to a Data
                          Entity on any Contractor Registration Info Form
                          Activity in the Register Workflow. The value of this
                          Data Entity will become associated with only the
                          specific registration, and that value will not impact
                          Requirements of any other Contractor Type that the
                          Contractor applies for.
                        </i>
                      </p>

                      <button
                        class="btn btn-raised btn-primary float-right"
                        (click)="addNewRegistrationRequirement()"
                        [disabled]="newRegistrationRequirement"
                      >
                        Add Requirement
                      </button>
                      <div class="row">
                        <div class="col-auto">
                          <ul class="list-group">
                            <li
                              *ngIf="newRegistrationRequirement"
                              class="list-group-item"
                            >
                              <wm-contractor-requirement-editor
                                [requirement]="newRegistrationRequirement"
                                [parentForm]="form"
                                [entities]="availableRegistrationEntities"
                                (cancelled)="
                                  newRegistrationRequirementCancelled()
                                "
                                (applied)="newRequirementApplied($event)"
                                [isOpen]="true"
                              ></wm-contractor-requirement-editor>
                            </li>
                            <li
                              class="list-group-item"
                              *ngIf="
                                !registrationRequirements ||
                                registrationRequirements.length == 0
                              "
                            >
                              <i>
                                There are not any Registration Requirements for
                                this Contractor Type
                              </i>
                            </li>
                            <li
                              class="list-group-item"
                              *ngFor="
                                let requirement of registrationRequirements
                              "
                            >
                              <wm-contractor-requirement-editor
                                [requirement]="requirement"
                                [parentForm]="form"
                                [entities]="registrationEntities"
                                (removed)="removeRequirement($event)"
                                (applied)="save()"
                              ></wm-contractor-requirement-editor>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </div>
          </div>
        </div>
        <div ngbAccordionItem="documents" id="documents" class="card-header">
          <h2 ngbAccordionHeader>
            <button ngbAccordionToggle class="p-0 btn btn-link text-start ps-0">
              Generated Documents
            </button>
          </h2>
          <div ngbAccordionCollapse>
            <div ngbAccordionBody>
              <ng-template>
                <div class="row">
                  <div class="col-12">
                    <ul
                      ngbNav
                      #navDocs="ngbNav"
                      [destroyOnHide]="false"
                      class="nav-tabs"
                    >
                      <li ngbNavItem>
                        <a ngbNavLink>Registration</a>
                        <ng-template ngbNavContent>
                          <div class="row">
                            <div class="col">
                              <div class="mt-2 float-right">
                                <wm-modal-confirm
                                  [buttonText]="'Add New Document'"
                                  [commandText]="'Add Document'"
                                  moduleId="addRegistrationGeneratedDoc"
                                  [showButton]="true"
                                  [showButtonText]="true"
                                  [title]="
                                    'Add Generated Document to Registration'
                                  "
                                  [form]="newGenDocForm"
                                  [windowClass]="'modal-wide'"
                                  (accepted)="addGeneratedDocument()"
                                >
                                  <form
                                    class="form-inline"
                                    [formGroup]="newGenDocForm"
                                  >
                                    <table class="table">
                                      <tr>
                                        <td>Previous Activity</td>
                                        <td>
                                          <select
                                            [(ngModel)]="newDocPriorActivity"
                                            formControlName="priorActivity"
                                            class="form-control"
                                          >
                                            <option
                                              *ngFor="
                                                let a of getValues(
                                                  contractorType.registerActivities
                                                )
                                              "
                                              [value]="a.id"
                                              >{{ a.model.screenName }} ({{
                                                a.name
                                              }})</option
                                            >
                                          </select>
                                        </td>
                                      </tr>
                                      <tr *ngIf="newRegOutputDoc">
                                        <td>Document Name</td>
                                        <td>
                                          <input
                                            type="text"
                                            class="form-control"
                                            [(ngModel)]="
                                              newRegOutputDoc.model.screenName
                                            "
                                            formControlName="newDocName"
                                          />
                                        </td>
                                      </tr>
                                    </table>
                                  </form>
                                </wm-modal-confirm>
                              </div>
                            </div>
                          </div>

                          <div
                            *ngFor="
                              let regOutputDoc of getValues(
                                contractorType.registerPrintTemplateActivities
                              )
                            "
                          >
                            <div class="row">
                              <div class="col-6 mb-5">
                                <div
                                  class="d-flex justify-content-between align-items-center mb-2"
                                >
                                  <h4>{{ regOutputDoc.model.screenName }}</h4>
                                  <div>
                                    <wm-modal-confirm
                                      [moduleId]="regOutputDoc.id"
                                      [showButtonText]="true"
                                      buttonText="Delete Document"
                                      [title]="'Delete Generated Document'"
                                      [commandText]="'Yes'"
                                      [tooltip]="
                                        !canRemoveDocument(regOutputDoc)
                                          ? 'You must remove this in the designer'
                                          : ''
                                      "
                                      [enabled]="
                                        canRemoveDocument(regOutputDoc)
                                      "
                                      (accepted)="
                                        removeActivity(
                                          contractorType.registerWorkflow,
                                          regOutputDoc,
                                          contractorType.registerPrintTemplateActivities
                                        )
                                      "
                                    >
                                      Are you sure you want to remove this
                                      generated document?
                                    </wm-modal-confirm>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col">
                                    <div class="border">
                                      <wm-print-template-activity-editor
                                        [activity]="regOutputDoc"
                                        [form]="form"
                                        [workflow]="
                                          contractorType.registerWorkflow
                                        "
                                        [showTitle]="false"
                                      ></wm-print-template-activity-editor>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </ng-template>
                      </li>
                      <li ngbNavItem>
                        <a ngbNavLink>Renewal</a>
                        <ng-template ngbNavContent>
                          <div class="row">
                            <div class="col">
                              <div class="mt-2 float-right">
                                <wm-modal-confirm
                                  [buttonText]="'Add New Document'"
                                  [commandText]="'Add Document'"
                                  moduleId="addRenewalGeneratedDoc"
                                  [showButton]="true"
                                  [showButtonText]="true"
                                  [title]="'Add Generated Document to Renewal'"
                                  [form]="newRenDocForm"
                                  [windowClass]="'modal-wide'"
                                  (accepted)="addGeneratedRenewalDocument()"
                                >
                                  <form
                                    class="form-inline"
                                    [formGroup]="newRenDocForm"
                                  >
                                    <table class="table">
                                      <tr>
                                        <td>Previous Activity</td>
                                        <td>
                                          <select
                                            [(ngModel)]="newDocRenPriorActivity"
                                            formControlName="priorActivity"
                                            class="form-control"
                                          >
                                            <option
                                              *ngFor="
                                                let a of getValues(
                                                  contractorType.renewActivities
                                                )
                                              "
                                              [value]="a.id"
                                              >{{ a.model.screenName }} ({{
                                                a.name
                                              }})</option
                                            >
                                          </select>
                                        </td>
                                      </tr>
                                      <tr *ngIf="newRenOutputDoc">
                                        <td>Document Name</td>
                                        <td>
                                          <input
                                            type="text"
                                            class="form-control"
                                            [(ngModel)]="
                                              newRenOutputDoc.model.screenName
                                            "
                                            formControlName="newDocName"
                                          />
                                        </td>
                                      </tr>
                                    </table>
                                  </form>
                                </wm-modal-confirm>
                              </div>
                            </div>
                          </div>

                          <div
                            *ngFor="
                              let renOutputDoc of getValues(
                                contractorType.renewPrintTemplateActivities
                              )
                            "
                          >
                            <div class="row">
                              <div class="col-6 mb-5">
                                <div
                                  class="d-flex justify-content-between align-items-center mb-2"
                                >
                                  <h4>{{ renOutputDoc.model.screenName }}</h4>
                                  <div>
                                    <wm-modal-confirm
                                      [moduleId]="renOutputDoc.id"
                                      [showButtonText]="true"
                                      buttonText="Delete Document"
                                      [title]="'Delete Generated Document'"
                                      [commandText]="'Yes'"
                                      [tooltip]="
                                        !canRemoveDocument(renOutputDoc)
                                          ? 'You must remove this in the designer'
                                          : ''
                                      "
                                      [enabled]="
                                        canRemoveDocument(renOutputDoc)
                                      "
                                      (accepted)="
                                        removeActivity(
                                          contractorType.renewWorkflow,
                                          renOutputDoc,
                                          contractorType.renewPrintTemplateActivities
                                        )
                                      "
                                    >
                                      Are you sure you want to remove this
                                      generated document?
                                    </wm-modal-confirm>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col">
                                    <div class="border">
                                      <wm-print-template-activity-editor
                                        [activity]="renOutputDoc"
                                        [form]="form"
                                        [workflow]="
                                          contractorType.renewWorkflow
                                        "
                                        [showTitle]="false"
                                      ></wm-print-template-activity-editor>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </ng-template>
                      </li>
                    </ul>
                    <div [ngbNavOutlet]="navDocs"></div>
                  </div>
                </div>
              </ng-template>
            </div>
          </div>
        </div>
        <div
          ngbAccordionItem="permissions"
          id="permissions"
          class="card-header"
        >
          <h2 ngbAccordionHeader>
            <button ngbAccordionToggle class="p-0 btn btn-link text-start ps-0">
              Advanced Permissions
            </button>
          </h2>
          <div ngbAccordionCollapse>
            <div ngbAccordionBody>
              <ng-template>
                <wm-advanced-permissions-editor
                  [securable]="contractorType"
                  [showExpandCollapse]="false"
                  permissionTitle="Permissions for this contractor type"
                  *ngIf="contractorTypeId != EMPTY_GUID"
                >
                </wm-advanced-permissions-editor>
              </ng-template>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</form>

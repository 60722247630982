import * as moment from 'moment';

import { IDateValidator } from '../../date-validators';
import { TodayOffsetOptions } from '../today-offset-date-validator';

export class TodayOffsetValidator implements IDateValidator {
  validateMaxDate(date: Date, options: TodayOffsetOptions): boolean {
    let value = this.calculateExample(date, options);

    return moment(date).isSameOrBefore(value, 'day');
  }
  validateMinDate(date: Date, options: TodayOffsetOptions): boolean {
    let value = this.calculateExample(date, options);

    return moment(date).isSameOrAfter(value, 'day');
  }
  validateDate(date: Date, options: TodayOffsetOptions): boolean {
    return false;
  }
  calculateExample(date: Date, options: TodayOffsetOptions): Date {
    let today = new Date();

    return options
      ? moment(today)
          .add(options.offset, 'days')
          .toDate()
      : null; // new Date(today.setDate(today.getDate() + options.offset));
  }
}

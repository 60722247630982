import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { Client } from '../../../models';
import { ClientService } from '../../../services';
import {
  debounceTime,
  distinctUntilChanged,
  switchMap,
  tap
} from 'rxjs/operators';

@Component({
  selector: 'wm-client-search',
  templateUrl: './client-search.component.html',
  styleUrls: ['./client-search.component.css']
})
export class ClientSearchComponent implements OnInit {
  @Input() currentClient: Client;
  @Output() searchFinished: EventEmitter<Client[]> = new EventEmitter<
    Client[]
  >();
  @Output() searchStarted: EventEmitter<any> = new EventEmitter<any>();
  searchText: string;

  constructor(private _clientSvc: ClientService) {}

  search() {
    this._clientSvc
      .searchClients({ searchText: this.searchText })
      .pipe(
        debounceTime(200),
        distinctUntilChanged(),
        tap(clients => this.searchFinished.next(clients))
      )
      .subscribe();
  }

  ngOnInit() {}
}

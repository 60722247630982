import { Component, OnInit } from '@angular/core';
import { VpsPaymentProcessorConfiguration } from '../../../models/payment-providers/vps/vps-payment-processor-configuration';
import { PaymentProcessorConfigBaseComponent } from '../payment-processor-config-base/payment-processor-config-base.component';
import { UntypedFormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'wm-payment-processor-config-vps',
  templateUrl: './payment-processor-config-vps.component.html',
  styleUrls: ['./payment-processor-config-vps.component.css']
})
export class PaymentProcessorConfigVpsComponent
  extends PaymentProcessorConfigBaseComponent
  implements OnInit {
  processorConfig: VpsPaymentProcessorConfiguration;

  constructor(fb: UntypedFormBuilder) {
    super(fb);
  }

  ngOnInit() {
    this.addValidation(
      'redirectURL',
      Validators.required,
      this.processorConfig.redirectURL
    );
  }
}

import {
  Component,
  OnInit,
  Inject,
  forwardRef,
  ChangeDetectorRef
} from '@angular/core';
import { DataEntityEditorBaseComponent } from '../data-entity-editor-base/data-entity-editor-base.component';
import { Validators, UntypedFormBuilder, AbstractControl } from '@angular/forms';
import {
  FreeFormTextDataEntity,
  RestrictedFormat
} from '../../../../models/data-entities';
import { WorkflowService, WorkflowContextService } from '../../../../services';
import { ExternalDataSource } from 'src/app/models/external-data-source';

interface FormatOption {
  id: number;
  value: string;
}

@Component({
  selector: 'wm-free-form-text-data-entity',
  templateUrl: './free-form-text-data-entity.component.html',
  styleUrls: ['./free-form-text-data-entity.component.css']
})
export class FreeFormTextDataEntityComponent
  extends DataEntityEditorBaseComponent
  implements OnInit {
  freeFormDE: FreeFormTextDataEntity;
  minimumLength: AbstractControl;
  maximumLength: AbstractControl;
  restrictedFormatField: AbstractControl;
  restrictedFormats: FormatOption[];

  constructor(
    private _fb: UntypedFormBuilder,
    @Inject(forwardRef(() => WorkflowService))
    private _workflowSvc: WorkflowService,
    @Inject(forwardRef(() => WorkflowContextService))
    private _context: WorkflowContextService,
    private _ref: ChangeDetectorRef
  ) {
    super();

    const formats = Object.keys(RestrictedFormat).map(key => {
      return { id: RestrictedFormat[key], value: key };
    });

    this.restrictedFormats = formats.slice(formats.length / 2);
  }

  restrictedFormatChange(e) {
    this._ref.detectChanges();
  }

  ngOnInit() {
    this.freeFormDE = this.dataEntity as FreeFormTextDataEntity;

    this.form.addControl(
      'minimumLength',
      this._fb.control('', Validators.nullValidator)
    );
    this.form.addControl(
      'maximumLength',
      this._fb.control('', Validators.nullValidator)
    );
    this.form.addControl(
      'isMultiline',
      this._fb.control('', Validators.nullValidator)
    );
    this.form.addControl(
      'restrictedFormat',
      this._fb.control(RestrictedFormat.None, Validators.nullValidator)
    );

    this.minimumLength = this.form.controls['minimumLength'];
    this.maximumLength = this.form.controls['maximumLength'];
    this.restrictedFormatField = this.form.controls['restrictedFormat'];
  }

  externalDataSourceChanged(eds: ExternalDataSource) {
    this.dataEntity.externalDataSource = eds;
  }
}

<div class="row">
  <div class="col">
    <wm-renewal-list
      (recordCountSet)="setRecordCount($event)"
      [id]="id"
      [staticFilters]="staticFilters"
      [renderTitle]="false"
      (filtersChanged)="filtersChange()"
    >
    </wm-renewal-list>
  </div>
</div>

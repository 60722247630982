export class Document {
  id: string;
  name: string;
  path: string;
  parentID: string;
  parentType: string;
  parentPath: string;
  tokenPath: string;
  metaData: { [key: string]: string };

  constructor(options?: Partial<Document>) {
    if (options) {
      Object.assign(this, options);
    }
  }
}

import { Component, OnInit, EventEmitter } from '@angular/core';
import { ActivityView } from '../../../../../views/master-views/app.view/app.view.component';
import { FormGroup } from '@angular/forms';
import {
  Activity,
  ActivityModel,
  CompleteWorkflowActivity
} from '../../../../../models/activities';
import {
  WorkflowContextService,
  ActivityUtilities
} from '../../../../../services';

@Component({
  selector: 'wm-complete-workflow-activity-input',
  templateUrl: './complete-workflow-activity-input.component.html',
  styleUrls: ['./complete-workflow-activity-input.component.css']
})
export class CompleteWorkflowActivityInputComponent extends ActivityView
  implements OnInit {
  activity: CompleteWorkflowActivity;

  constructor() {
    super();
  }

  ngOnInit() {
    // this.showSave.emit(false);
    this.showNext.emit(true);
    // this.showPrevious.emit(false);

    if (this.activity.model.activities) {
      const activities = ActivityUtilities.convertActivitiesJsonToClasses(
        this.activity.model.activities
      );

      this.activity.model.activities = Object.keys(activities).map(
        (value: string, index: number) => activities[value]
      );
    }
  }
}

<app-loader *ngIf="!processors"></app-loader>

<div *ngIf="processors && processors.length === 0">
  <p>No payment processors to display</p>
</div>

<ul class="list-group" *ngIf="processors && processors.length > 0">
  <li
    *ngFor="let processor of processors"
    class="list-group-item"
    style="cursor: pointer"
    [routerLink]="[
      '/admin/global/payment-processors/edit',
      processor.id,
      processor.type
    ]"
  >
    {{ processor.name }}
  </li>
</ul>

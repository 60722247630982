import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Inject,
  forwardRef,
  ViewChild,
  Output
} from '@angular/core';
import { SearchParcelDataActivity } from '../../../../../models/activities';
import {
  UntypedFormControl,
  AbstractControl,
  UntypedFormBuilder,
  Validators,
  UntypedFormGroup
} from '@angular/forms';
import {
  WorkflowContextService,
  DataService,
  Utilities,
  SecurityService
} from '../../../../../services';
import { ActivityView } from '../../../../../views/master-views/app.view/app.view.component';
import { SearchParcelDataCriteria } from '../../../../../models/search-parcel-data-criteria';
import { SearchParcelDataProvider } from '../../../../../services/search-parcel-data.service';
import { EsriMapComponent } from 'src/app/components/arcgis/esri-map/esri-map.component';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { GeomUtils } from 'src/app/components/arcgis/geom-utils';
import { MapService, Actions } from 'src/app/models';
import { MapImageLayerService } from 'src/app/components/arcgis/mapimagelayer.service';
import { ModalConfirmComponent } from 'src/app/components/system/modal-confirm/modal-confirm.component';
import { map } from 'rxjs/operators';
import { combineLatest, Observable } from 'rxjs';

@Component({
  selector: 'wm-search-parcel-data-activity-input',
  templateUrl: './search-parcel-data-activity-input.component.html',
  styleUrls: ['./search-parcel-data-activity-input.component.css']
})
export class SearchParcelDataActivityInputComponent extends ActivityView
  implements OnInit {
  @Input() activity: SearchParcelDataActivity;
  @Input() searchWorkfowApplications = false;
  @Input() hideResultsOnSelect = false;
  @Output() goNext: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() searched: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() form: UntypedFormGroup;

  searchParcelDataCriteria: SearchParcelDataCriteria;
  searchParcelIdCtl: AbstractControl;
  searchParcelOwnerNameCtl: AbstractControl;
  searchParcelAddressCtl: AbstractControl;
  publicNote: string;
  internalNote: string;
  notes: string;
  parcelId: string;
  isInternalUser = false;
  results: any[];
  canViewInternalParcelNotes: boolean;
  canManageParcelNotes: boolean;
  private queueMapZoomToPid = false;
  userId: string;
  activeTabId: string;

  @ViewChild(EsriMapComponent, { static: false })
  private esriMap: EsriMapComponent;

  _cachedMapService: MapService;

  loading = false;
  @ViewChild('ParcelNotesModal', { static: false }) parcelNotesModal: ModalConfirmComponent;
  constructor(
    private fb: UntypedFormBuilder,
    private _searchParcelDataService: SearchParcelDataProvider,
    private _mapImageLayerService: MapImageLayerService,
    @Inject(forwardRef(() => DataService)) private _dataSvc: DataService,
    @Inject(forwardRef(() => WorkflowContextService))
    private _context: WorkflowContextService
  ) {
    super();

    this.searchParcelIdCtl = new UntypedFormControl('');
    this.searchParcelOwnerNameCtl = new UntypedFormControl('');
    this.searchParcelAddressCtl = new UntypedFormControl('');
  }
  private loadPermissions() {
    if (this.results.length > 0) {
      const result = this.results[0];

      this.canViewInternalParcelNotes =
        result['canViewInternalParcelNotes'] === '1' ? true : false;
      this.canManageParcelNotes =
        result['canManageParcelNotes'] === '1' ? true : false;
    }
  }

  myParcelSelected(e) {
    this.selectOneResult(e, true, true);
  }

  ngOnInit() {
    if (this._context.user) {
      this.userId = this._context.user.id;
    }

    this.form.addControl(
      'ParcelId',
      new UntypedFormControl(
        this.activity.parcelId,
        this.activity.model.isRequired
          ? Validators.required
          : Validators.nullValidator
      )
    );

    this.searchParcelDataCriteria = new SearchParcelDataCriteria({
      maxResults: 200,
      searchParcelId: this.activity.model.parcelId,
      searchParcelOwnerName: '',
      searchParcelAddress: '',
      clientId: this._context.client.id
    });

    // if we load with a parcel id but don't have a selected parcel then this is an invalid parcel
    if (this.activity.model.parcelId && !this.activity.model.selectedParcel) {
      this.results = [];
    }

    if (this.activity.model.selectedParcel) {
      this.queueMapZoomToPid = true;
      this.results = [this.activity.model.selectedParcel];
      this.form.controls['ParcelId'].setValue(
        this.activity.model.selectedParcel
      );
    }
  }

  async getParcelLayerInfo(): Promise<MapService> {
    if (!this._cachedMapService) {
      this._cachedMapService = await this._dataSvc
        .getMapService(this.activity.model.mapConfig.mapServiceId)
        .toPromise();
    }
    return this._cachedMapService;
  }

  async onMapLoaded() {
    if (this.esriMap) {
      const ms = await this.getParcelLayerInfo();
      await this.esriMap.enableFeatureSelection(
        ms.parcelLayer,
        ms.parcelLayerKeyField,
        false
      );
  
      if (this.queueMapZoomToPid) {
        this.queueMapZoomToPid = false;
  
        let parcelIds = [];
  
        if (this.activity.model.selectedParcel) {
          parcelIds = this.results
            .filter(
              r => r.parcelID === this.activity.model.selectedParcel.parcelID
            )
            .map((v, i) => v.parcelID);
          await this.esriMap.selectFeaturesOnMap(parcelIds, 'redlayer');
        } else {
          parcelIds = this.results.map((v, i) => v.parcelID);
          await this.esriMap.selectFeaturesOnMap(parcelIds, 'bluelayer');
        }
      }
    }
  }

  search(
    searchCriteria: SearchParcelDataCriteria,
    selectResult = false,
    updateMap = false
  ) {
    this.searched.emit();
    this.loading = true;
    this._searchParcelDataService
      .getSearchParcelDataResults(searchCriteria)
      .subscribe(
        res => {
          this.loading = false;
          // results includes the header row, so filter it out
          this.results = res.filter((v, i) => i > 0);
          this.loadPermissions();
          if (this.results.length === 1 && selectResult) {
            this.selectOneResult(this.results[0], updateMap);
          }
        },
        err => {
          this.loading = false;
          throw err;
        }
      );
  }

  submitSearchParcelDataCriteria(e) {
    e.preventDefault();

    this.clearResults();

    this.search(this.searchParcelDataCriteria, false, true);
  }

  clearResults() {
    this.results = [];
    this.activity.model.selectedParcel = null;
    this.form.controls['ParcelId'].setValue(null);
  }

  // map interactions:
  onParcelsBeforeIdentifying() {
    this.clearResults();
    this.loading = true;
  }

  onParcelsIdentified(parcelIds: string[]) {
    if (parcelIds) {
      const search = new SearchParcelDataCriteria({
        maxResults: 200,
        clientId: this._context.client.id,
        searchParcelIds: parcelIds
      });

      this.search(search, true, false);
    } else {
      this.loading = false;
    }
  }

  onSearchMapTabChange(tce: NgbNavChangeEvent) {
    switch (tce.nextId) {
      case 'mapTab':
        this.switchToMapTab();
        break;
      case 'searchTab':
        this.switchToSearchTab();
        break;
    }
  }

  async selectOneResult(
    result: { [key: string]: string },
    updateMap: boolean,
    goNext = false
  ) {
    this.activity.model.selectedParcel = result;
    const parcelId = result.parcelID;

    if (this.activity.model.mapConfig.mapServiceId) {
      const apiUrl = Utilities.getRootURL();
      const url = `${apiUrl}/api/mapproxy/mapserver/${this.activity.model.mapConfig.mapServiceId}`;
      const ms = await this.getParcelLayerInfo();
      const geom = await this._mapImageLayerService.getParcelGeometryWgs84(
        parcelId,
        url,
        ms.parcelLayer,
        ms.parcelLayerKeyField
      );
      const wkt = geom != null ? GeomUtils.ArcGisGeomToWkt(geom) : null;
      this.activity.model.selectedGeometryWkt = wkt;
    } else {
      this.activity.model.selectedGeometryWkt = null;
    }

    this.form.controls['ParcelId'].setValue(result);

    if (this.esriMap && updateMap) {
      this.esriMap.selectFeaturesOnMap([parcelId], 'redlayer');
    }

    if (goNext) {
      this.goNext.emit(true);
    }
  }

  switchToMapTab() {
    this.queueMapZoomToPid = this.results && this.results.length > 0;
  }

  switchToSearchTab() {
    // NOOP
  }

  isParcelSelected(parcel: any) {
    return (
      this.activity.model.selectedParcel &&
      this.activity.model.selectedParcel.parcelID === parcel.parcelID
    );
  }

  showParcel(parcel: any) {
    return this.hideResultsOnSelect && this.activity.model.selectedParcel
      ? this.isParcelSelected(parcel)
      : true;
  }
  getParcelNote(parcelId: string) {
    this._dataSvc.getParcelNote(parcelId).subscribe(response => {
      this.internalNote = response.internalNote;
      this.publicNote = response.publicNote;
    });
  }

  notesModal(publicNotes: string, internalNotes: string, parcelId: string) {
    publicNotes = publicNotes ? publicNotes : '';
    internalNotes = internalNotes ? internalNotes : '';
    this._dataSvc
      .saveParcelNote(publicNotes, internalNotes, parcelId)
      .subscribe(response => {});
  }
}

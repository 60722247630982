import { Component, OnInit, EventEmitter } from '@angular/core';
import { ActivityView } from '../../../../../views/master-views/app.view/app.view.component';
import {
  Activity,
  ActivityModel,
  PaymentActivityModel,
  PaymentActivity,
  PaymentMethods
} from '../../../../../models/activities';
import { FormGroup } from '@angular/forms';
import { WorkflowContextService, Utilities } from '../../../../../services';

@Component({
  selector: 'wm-payment-activity-view',
  templateUrl: './payment-activity-view.component.html',
  styleUrls: ['./payment-activity-view.component.css']
})
export class PaymentActivityViewComponent extends ActivityView
  implements OnInit {
  activity: PaymentActivity;
  PaymentMethods = PaymentMethods;
  apiBase = Utilities.getRootURL();

  constructor() {
    super();
  }

  ngOnInit() {}

  toMethodString(value: PaymentMethods): string {
    return PaymentMethods[value].toString();
  }

  toCurrency(value: string) {
    return Utilities.formatCurrency(value);
  }
}

import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
  OnDestroy
} from '@angular/core';
import { DataSet } from 'src/app/models/report-data-set';
import { WorkflowService } from 'src/app/services';
import {
  FilterBuilderOutput,
  FilterBuilderParam
} from 'src/app/models/filter-builder';
import { DataSetField } from 'src/app/models/custom-report';
import { ColumnOption } from 'src/app/models/grid-settings';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators
} from '@angular/forms';
import { ShapefileValidationCriteria } from 'src/app/models/scheduled-export';
import { of } from 'rxjs';

@Component({
  selector: 'wm-dataset-input',
  templateUrl: './dataset-input.component.html',
  styleUrls: ['./dataset-input.component.css']
})
export class DatasetInputComponent implements OnInit, OnChanges, OnDestroy {
  constructor(private _workflowSvc: WorkflowService) {}
  @Input() shapefileValidationCriteria: ShapefileValidationCriteria;

  @Input() dataSetId: string;
  @Output() filtersChanged: EventEmitter<
    FilterBuilderOutput
  > = new EventEmitter();
  @Output() dataSetLoaded: EventEmitter<DataSet> = new EventEmitter();
  @Output() filterParamsChanged: EventEmitter<
    FilterBuilderParam[]
  > = new EventEmitter();
  @Output() columnsChanged: EventEmitter<string[]> = new EventEmitter();
  @Output() filterFormChanged: EventEmitter<
    UntypedFormGroup
  > = new EventEmitter();
  @Input() form: UntypedFormGroup;
  reqGrp: UntypedFormGroup;

  columns: ColumnOption[];
  dataSet: DataSet;
  @Input() initialDataSet: DataSet;
  dsFilterParams: FilterBuilderParam[];

  requiredFilterInput: DataSetField[];
  loading = false;

  configureDataSet() {
    if (this.dataSetId || this.dataSet) {
      this.loading = true;
      this.dsFilterParams = null;
      let dsObs = !this.dataSet
        ? this._workflowSvc.getDataSet(this.dataSetId, true)
        : of(this.dataSet);

      dsObs.subscribe(ds => {
        // reset all the controls
        if (this.reqGrp) {
          const valKeys = Object.keys(this.reqGrp.controls);

          valKeys.forEach(k => {
            this.reqGrp.removeControl(k);
          });
        }

        this.loading = false;

        // setup form controls for search filters
        this.requiredFilterInput = ds.filterConfig.fields.filter(
          f => f.requireInput
        );

        if(this.reqGrp) {
        this.requiredFilterInput.forEach(f => {
          this.reqGrp.addControl(
            f.name,
            new UntypedFormControl(f.defaultSearchValue, Validators.required)
          );
          this.reqGrp.controls[f.name].markAsDirty();
        });

        this.filterFormChanged.emit(this.reqGrp);
      }

        const fieldIndex: { [key: string]: DataSetField } = {};
        const fields = this.dataSet ? this.dataSet.dataSetConfig.fields : ds.publishedDataSetConfig
          ? ds.publishedDataSetConfig.fields
          : ds.dataSetConfig.fields;
        fields.forEach(f => {
          fieldIndex[f.name] = f;
        });

        const filterFields = this.dataSet ? this.dataSet.filterConfig.fields : ds.publishedFilterConfig
          ? ds.publishedFilterConfig.fields
          : ds.filterConfig.fields;

        this.dsFilterParams = filterFields.map(f => {
          if (fieldIndex[f.name]) {
            f.dataType = parseInt(fieldIndex[f.name].dataType.toString(),10);
          }
          return {
            name: f.label || f.name,
            id: f.name,
            inputType: DataSet.resolveInputTypeForField(fieldIndex[f.name]),
            types: DataSet.resolveTypesForField(f),
            options: (() => {
              const os = DataSet.resolveOptionsForField(f);
              if (os) {
                return os.map(o => {
                  return { name: o, value: o };
                });
              } else {
                return null;
              }
            })()
          };
        });

        this.columns = fields.map(
          f =>
            new ColumnOption({
              name: (f.resultHeader || '') != '' ? f.resultHeader : f.name,
              label: (f.resultHeader || '') != '' ? f.resultHeader : f.name,
              includeInExport: true
            })
        );

        this.dataSet = ds;

        this.dataSetLoaded.emit(ds);
        this.filterParamsChanged.emit(this.dsFilterParams);
        this.columnsChanged.emit(this.columns.map(c => c.name));
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['dataSetId'] || changes['dataSet'] || changes['initialDataSet']) {
      this.dataSet = this.initialDataSet;
      this.configureDataSet();
    }
  }

  ngOnDestroy(): void {
    this.form.removeControl('requiredParams');
  }

  ngOnInit() {
    if (!this.form) {
      this.form = new UntypedFormGroup({});
    }

    this.reqGrp = new UntypedFormGroup({});

    this.form.addControl('requiredParams', this.reqGrp);
    this.dataSet = this.initialDataSet;
  }

  dsFilterChanged(e) {
    const keys = Object.keys(e);

    const valKeys = Object.keys(this.reqGrp.controls);

    valKeys.forEach(k => {
      this.reqGrp.controls[k].setValue(null);
      this.reqGrp.controls[k].markAsDirty();
      this.reqGrp.controls[k].markAsTouched();
    });

    keys.forEach(k => {
      const ctl = this.reqGrp.controls[k];

      if (ctl) {
        ctl.setValue('populated');
        ctl.markAsDirty();
        ctl.markAsTouched();
      }
    });

    this.filtersChanged.emit(e);
  }

  dsColumnsChanged(e) {
    this.columnsChanged.emit(e);
  }
}

import { DocumentDataEntity } from '.';
import { ContractorDocumentCategory } from '../contractor-document-category';

export class ContractorDocumentDataEntity extends DocumentDataEntity {
  documentCategory: ContractorDocumentCategory;

  constructor(options?: Partial<ContractorDocumentDataEntity>) {
    super();
    this.dataEntityTypeCode = 'contractor-document';
    this.dataEntityTypeDescription =
      'Use this entity type to accept contractor documents.';
    this.dataEntityTypeName = 'Contractor Document';

    if (options) {
      Object.assign(this, options);
    }
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

@Pipe({
  name: 'phone'
})
export class PhonePipe implements PipeTransform {
  transform(value: string, args?: string): any {
    if (!value) {
      return value;
    }
    const phoneNumber = parsePhoneNumberFromString(value, 'US');

    if (!phoneNumber) {
      return value;
    }

    return phoneNumber.formatNational();
  }
}

import { Component, OnInit } from '@angular/core';
import { User } from '../../models';
import { SecurityService } from '../../services';
import { Params, Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'wm-reset-password-view',
  templateUrl: './reset-password-view.component.html',
  styleUrls: ['./reset-password-view.component.css']
})
export class ResetPasswordViewComponent implements OnInit {
  token: string;
  verified: boolean;
  verificationMessage = '';
  newPassword: string;
  user: User;
  userName: string;
  form: UntypedFormGroup;
  returnURL: string;

  constructor(
    private _securitySvc: SecurityService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _toastr: ToastrService,
    fb: UntypedFormBuilder
  ) {
    this._route.queryParams.subscribe(params => {
      this.token = params['token'];
      this.returnURL = params['returnUrl'] || '';
    });

    this.form = fb.group({
      userName: ['', Validators.required],
      newPassword: ['', Validators.required]
    });
  }

  ngOnInit() {}

  reset() {
    this._securitySvc
      .saveResetPassword(this.userName, this.token, this.newPassword)
      .subscribe(res => {
        if (res && res.loginValid === true) {
          if (this.returnURL !== '') {
            this._router.navigate([this.returnURL]);
          } else {
            this._router.navigate(['/']);
          }
        } else if (res) {
          this._toastr.error(res.errorMessage);
        }
      });
  }
}

import { filter } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
  FormControl,
  ValidatorFn
} from '@angular/forms';
import { User } from '../../../models';
import {
  WorkflowContextService,
  SecurityService,
  ValidationService
} from '../../../services';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Output, EventEmitter } from '@angular/core';
import { Input } from '@angular/core';

export enum LoginOrientation {
  horizontal = 1,
  vertical = 2
}

@Component({
  selector: 'wm-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  form: UntypedFormGroup;
  validationErrors: { [key: string]: string };
  statusMsg: string;
  mustChangePwd: boolean;
  changePwdForm: UntypedFormGroup;
  sendUsernamesEmailForm: UntypedFormGroup;
  resetPwdForm: UntypedFormGroup;
  showForgotPwd = false;
  showForgotUsername = false;

  loggedInSubscription: any;
  user: Partial<User>;

  userName: string;
  password: string;

  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
  sendUsernamesEmail: string;
  resetPwdUsername: string;
  @Output() loggedIn: EventEmitter<any> = new EventEmitter<any>();
  @Input() redirectURL: any = '';
  @Input() isPreview = false;
  saving = false;
  isAuthenticating = false;

  constructor(
    public context: WorkflowContextService,
    private _security: SecurityService,
    private _router: Router,
    private _route: ActivatedRoute,
    fb: UntypedFormBuilder,
    private _toastrSvc: ToastrService
  ) {
    // #region Setup Validation
    this.form = fb.group({
      userName: ['', Validators.required],
      password: ['', Validators.required]
    });

    this.changePwdForm = fb.group(
      {
        oldPassword: ['', Validators.required],
        newPassword: ['', Validators.required],
        confirmPassword: ['', Validators.required]
      },
      {
        validator: ValidationService.passwordMatchValidator(
          'newPassword',
          'confirmPassword'
        )
      }
    );

    this.resetPwdForm = fb.group({
      username: ['', Validators.required]
    });

    this.sendUsernamesEmailForm = fb.group({
      email: [
        '',
        [Validators.required, ValidationService.emailOrEntityValidatorCustom()]
      ]
    });
    // #endregion
  }

  ngOnInit() {
    this.user = this._security.createUser();
    this._route.queryParams
      .pipe(filter(parms => parms.returnURL))
      .subscribe(parms => {
        this.redirectURL = parms.returnURL;
        this.context.returnUrl$.next(this.redirectURL);
        if (this.context.isLoggedIn && this.redirectURL) {
          this.context.isLoggedIn$.emit(true);
        }
      });

    // check to see if the auth token was sent from the server and authenticate with it if it was
    this._route.queryParams
      .pipe(filter(parms => parms.token))
      .subscribe(parms => {
        if (parms.token) {
          this.isAuthenticating = true;
          this._security.authenticateByToken(parms.token).subscribe(
            a => {
              if (this.redirectURL) {
                window.location.href = this.redirectURL;
              } else {
                this._router.navigate(['/']);
              }
            },
            err => {
              this.isAuthenticating = false;
              this._router.navigate(['auth', 'login'], {
                queryParams: { returnUrl: this.redirectURL }
              });
            }
          );
        }
      });
  }

  cancelChangePwd() {
    this.mustChangePwd = false;
    this.statusMsg = '';
  }

  forgotPwd() {
    this.showForgotPwd = true;
  }
  forgotUsername() {
    this.showForgotUsername = true;
  }
  sendUsernamesEmailSubmit() {
    this._security
      .getUsersByEmail(this.sendUsernamesEmail, this.redirectURL)
      .subscribe(res => {
        if (res) {
          this.statusMsg =
            'If it is in our system, an Email has been sent to the email address you have on file with us.';
        } else {
          this.statusMsg =
            'If it is in our system, an Email has been sent to the email address you have on file with us.';
        }
      });
  }
  resetPwd() {
    this._security
      .resetPassword(this.resetPwdUsername, this.redirectURL)
      .subscribe(res => {
        if (res) {
          this.statusMsg =
            'Email has been sent to the email address you have on file with us.';
        } else {
          this.statusMsg = 'User not found with that username';
        }
      });
  }

  cancelResetPwd() {
    this.showForgotPwd = false;
    this.statusMsg = '';
  }
  cancelResetUsername() {
    this.showForgotUsername = false;
    this.statusMsg = '';
  }
  changePwd() {
    this._security
      .changePwd(
        this.user.userName,
        this.oldPassword,
        this.newPassword,
        this.confirmPassword
      )
      .subscribe(
        res => {},
        e => {
          if (e.status !== '200') {
            const body = e.json();

            this.statusMsg = body.Message;
          } else {
          }
        }
      );
  }

  login() {
    if (!this.isPreview) {
      this.saving = true;
      this._security.login(this.user).subscribe(
        result => {
          if (result.user) {
            this.user = result.user;
          }
          this.mustChangePwd = result.mustResetPassword;

          if (this.redirectURL) {
            this.loggedIn.emit(this.redirectURL);
          } else {
            this._router.navigate(['/']);
          }
        },
        () => {
          this.saving = false;
          this._toastrSvc.error('Invalid Username/password');
        }
      );
    } else {
      this.saving = false;
      this._toastrSvc.info('Login is disabled in preview mode');
    }
  }
}

<div class="form-group bmd-form-group">
  <label for="txtSearchText" class="bmd-label-placeholder">
    Search for a jurisdiction...
  </label>
  <input
    type="text"
    class="form-control"
    name="txtSearchText"
    [(ngModel)]="searchText"
    (ngModelChange)="search()"
  />
</div>

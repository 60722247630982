<wm-datatable
  [useMap]="UseMap"
  [loading]="loading"
  [rows]="applications"
  [columns]="columns"
  [count]="recordCount"
  [offset]="page - 1"
  [limit]="pageSize"
  (mapDataRequested)="onMapDataRequested($event)"
  popupTemplateTitle="Application ID: {applicationNumber}"
  popupTemplateContent="Type: {permitType}&lt;br>Description: {description}&lt;br>Applicant: {applicantsName}&lt;br>Created: {dateCreated}&lt;br>Status: {status}&lt;br>"
  [popupActions]="mapPopupActions"
  [params]="filterCriteria"
  (tabChange)="requestData()"
  [id]="id"
  [bulkActions]="bulkActions"
  [getId]="getId"
  simpleSearchTitle="Search for applications"
  [unpermissibleCount]="unpermissibleCount"
  (settingsChanged)="handleSettingsChanged($event)"
  [sortField]="sortField"
  [sortDescending]="sortDescending"
  [defaultHiddenColumns]="defaultHiddenColumns"
  [actionColumns]="actionColumns"
  [defaultExportExludedColumns]="defaultExportExludedColumns"
  [availableColumns]="availableColumns"
  (columnOptionsChanged)="handleColumnOptionsChanged($event)"
>
</wm-datatable>

<ng-template #currentActivityTmpl let-row="row" let-value="value">
  <div *ngIf="row.currentActivityDetails">
    <div *ngFor="let v of row.currentActivityDetails; let idx = index">
      <a
        *ngIf="idx < 3"
        [routerLink]="[
          '/application/workflow-application',
          row.appId,
          v.activityDataId
        ]"
      >
        {{ v.activityName }}
      </a>
    </div>
    <a
      [routerLink]=""
      *ngIf="row.currentActivityDetails.length > 3"
      (click)="showCurrentActivities(row)"
      >More...</a
    >
  </div>
</ng-template>

<ng-template #appIdTmpl let-row="row" let-value="value">
  <a
    *ngIf="row.status === ApplicationStatus.InProgress"
    [routerLink]="['/application/workflow-application', row.appId]"
  >
    {{ value }}
  </a>
  <a
    *ngIf="row.status !== ApplicationStatus.InProgress"
    [routerLink]="['/application/workflow-application-summary', row.appId]"
  >
    {{ value }}
  </a>
</ng-template>

<wm-modal-confirm
  moduleId="currentActivitiesConfirm"
  [showButton]="false"
  [title]="
    'Current Activities for Application ' +
    (selectedApplication ? selectedApplication.applicationNumber : '')
  "
  [enabled]="true"
  #currentActivitiesConfirm
>
  <ng-container *ngIf="selectedApplication">
    <div *ngFor="let v of selectedApplication.currentActivityDetails">
      <a
        [routerLink]="[
          '/application/workflow-application',
          selectedApplication.appId,
          v.activityDataId
        ]"
      >
        {{ v.activityName }}
      </a>
    </div>
  </ng-container>
</wm-modal-confirm>

<wm-modal-confirm
  moduleId="cancel_application"
  [showButton]="false"
  [title]="'Void ' + selected?.length + ' Application(s)'"
  [commandText]="'Void ' + selected?.length + ' Application(s)'"
  (accepted)="bulkVoidApplications(selected)"
  [enabled]="true"
  [form]="voidForm"
  #voidConfirm
>
  <div [formGroup]="voidForm">
    <div class="row">
      <div class="col">
        <label for="voidReason">Void Reason</label>
        <textarea
          style="height:100px"
          id="voidReason"
          name="voidReason"
          class="form-control mx-2"
          formControlName="voidReason"
          placeholder="Void Reason"
          aria-label="Void Reason"
        >
        </textarea>
        <div *ngIf="voidReason.untouched" class="text-danger">
          * Void Reason is required.
        </div>
        <div
          *ngIf="voidReason.invalid && (voidReason.dirty || voidReason.touched)"
          class="alert alert-danger"
        >
          <div *ngIf="voidReason.errors['required']" class="text-danger">
            * Void Reason is required. *
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <label for="voidDateTime" class="mt-2"
          >Void Time
          <input
            id="voidDateTime"
            name="voidDateTime"
            class="form-control mt-2"
            formControlName="voidDateTime"
            placeholder=""
            aria-label="DateTime"
          />
        </label>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <label class="mt-2">
          Voided By
          <input
            name="voidedBy"
            class="form-control mt-2"
            formControlName="voidedBy"
            placeholder=""
            aria-label="VoidedBy"
          />
        </label>
      </div>
    </div>
  </div>

  <p>
    Are you sure you want to void <b>{{ selected?.length }} application(s)</b>?
  </p>

  <p>Voiding these applications will do the following:</p>
  <ul>
    <li>
      Mark the applications as Voided. Please provide a reason for voiding the
      applications.
    </li>
  </ul>
</wm-modal-confirm>

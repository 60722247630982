import { WorkflowGraph } from './workflow-graph';

export class PermitWorkflowGraph extends WorkflowGraph {
  numberPrefix: string;
  startingNumber: number;

  constructor(options?: Partial<PermitWorkflowGraph>) {
    super(options);
    if (options) {
      Object.assign(this, options);
    }
  }
}

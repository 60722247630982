import { SftpConfigurationEditorComponent } from './sftp-configuration-editor/sftp-configuration-editor.component';
import { CsvConfigurationEditorComponent } from './csv-configuration-editor/csv-configuration-editor.component';
import {
  ExportDataActivityModel,
  OutputEditor,
  TransportEditor,
  DestinationConfiguration,
  ExportColumn
} from './../../../../../models/activities/export-data-activity';
import {
  UntypedFormControl,
  Validators,
  UntypedFormBuilder,
  UntypedFormArray
} from '@angular/forms';
import { ActivityEditorBaseComponent } from 'src/app/components/workflow/activities/activity-editor-base/activity-editor-base.component';
import {
  Component,
  OnInit,
  ChangeDetectorRef,
  Type,
  ComponentRef,
  ViewContainerRef,
  ViewChild,
  ComponentFactoryResolver,
  AfterViewInit
} from '@angular/core';
import { DragulaService } from 'ng2-dragula';

@Component({
  selector: 'wm-export-data-activity-editor',
  templateUrl: './export-data-activity-editor.component.html',
  styleUrls: ['./export-data-activity-editor.component.css']
})
export class ExportDataActivityEditorComponent
  extends ActivityEditorBaseComponent
  implements OnInit, AfterViewInit {
  model: ExportDataActivityModel;
  availableFormats: { key: string; value: string }[];
  availableTransports: { key: string; value: string }[];

  outputEditors: { [key: string]: Type<OutputEditor> } = {
    csv: CsvConfigurationEditorComponent,
    onbasedocument: CsvConfigurationEditorComponent
  };
  transportEditors: { [key: string]: Type<TransportEditor> } = {
    secureftp: SftpConfigurationEditorComponent
  };

  outputEditorComponentRef: ComponentRef<OutputEditor>;
  @ViewChild('outputEditor', { read: ViewContainerRef, static: false })
  outputEditor: ViewContainerRef;
  transportEditorComponentRef: ComponentRef<TransportEditor>;
  @ViewChild('transportEditor', { read: ViewContainerRef, static: false })
  transportEditor: ViewContainerRef;
  test = '';

  constructor(
    private changeDetRef: ChangeDetectorRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    private _dragulaService: DragulaService,
    private fb: UntypedFormBuilder
  ) {
    super();
  }

  ngAfterViewInit(): void {
    this._dragulaService.dropModel().subscribe(value => {
      this.onDropDataEntity(value);
    });

    this.changeDetRef.detectChanges();
  }
  get exportDocumentColumns() {
    return this.form.get('exportDocumentColumns') as UntypedFormArray;
  }

  ngOnInit() {
    if (this.activity) {
      this.model = <ExportDataActivityModel>this.activity.model;
    }

    if (this.form) {
      this.form.addControl(
        'formatMethod',
        new UntypedFormControl({ value: '', validator: Validators.required })
      );
      this.form.addControl(
        'transportMethod',
        new UntypedFormControl({ value: '', validator: Validators.required })
      );
      this.form.addControl('exportDocumentColumns', new UntypedFormArray([]));

      this.model.exportDocumentColumns.map((o, i) => {
        this.exportDocumentColumns.push(this.fb.control(o.documentIndex));
      });
    }

    this.availableFormats = this.convertDictionary(this.model.availableFormats);
    this.availableTransports = this.convertDictionary(
      this.model.availableTransportMethods
    );

    window.setTimeout(() => {
      this.loadOutputEditor();
      this.loadTransportEditor();

      this.changeDetRef.detectChanges();
    }, 0);
  }

  onDropDataEntity(args: any) {
    for (let i = 0; i < args.sourceModel.length; i++) {
      args.sourceModel[i].ordinal = i + 1;
    }
  }

  toggleDESelected(de: ExportColumn, e) {
    const checked = e.srcElement.checked;

    if (de) {
      de.selected = checked;
    }
  }
  updateDocumentIndex(de: ExportColumn, e: any) {
    const checked = e.srcElement.value;

    if (de) {
      de.documentIndex = checked;
    }
  }

  loadOutputEditor() {
    if (
      this.model.outputFormatText &&
      this.outputEditors[this.model.outputFormatText]
    ) {
      let compType: Type<OutputEditor>;

      if (this.outputEditorComponentRef) {
        this.outputEditorComponentRef.destroy();
      }

      if (this.outputEditor) {
        this.outputEditor.clear();
      }

      compType = this.outputEditors[this.model.outputFormatText];

      if (compType && this.outputEditor) {
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory<
          OutputEditor
        >(compType);

        this.outputEditorComponentRef = this.outputEditor.createComponent(
          componentFactory
        );
        this.outputEditorComponentRef.instance.form = this.form;
        if (!this.model.formatConfiguration) {
          this.model.formatConfiguration = {};
        }
        this.outputEditorComponentRef.instance.configuration = this.model.formatConfiguration;
      }
    }
  }

  loadTransportEditor() {
    if (
      this.model.transportMethodText &&
      this.transportEditors[this.model.transportMethodText]
    ) {
      let compType: Type<TransportEditor>;

      if (this.transportEditorComponentRef) {
        this.transportEditorComponentRef.destroy();
      }

      if (this.transportEditor) {
        this.transportEditor.clear();
      }

      compType = this.transportEditors[this.model.transportMethodText];

      if (compType && this.transportEditor) {
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory<
          TransportEditor
        >(compType);

        this.transportEditorComponentRef = this.transportEditor.createComponent(
          componentFactory
        );
        this.transportEditorComponentRef.instance.form = this.form;
        if (!this.model.transportConfiguration) {
          this.model.transportConfiguration = {};
        }
        this.transportEditorComponentRef.instance.configuration = this.model.transportConfiguration;
        this.transportEditorComponentRef.instance.updated.subscribe(
          e => (this.model.transportConfiguration = e)
        );
      }
    }
  }

  convertDictionary(value: {
    [key: string]: string;
  }): { key: string; value: string }[] {
    if (value) {
      const keys = Object.keys(value);
      const results: { key: string; value: string }[] = [];

      for (let keyIdx = 0; keyIdx < keys.length; keyIdx++) {
        const key = keys[keyIdx];
        if (key !== '$id') {
          results.push({ key: key, value: value[key] });
        }
      }

      return results;
    }
  }
}

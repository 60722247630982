import { ReportService } from './services/report.service';
import { PSNPaymentProcessorConfiguration } from './models/payment-providers/psn/psn-payment-processor-configuration';
import { FilterService } from 'src/app/services/filters.service';
import { WorkflowApplicationListViewComponent } from './views/admin/workflow-applications/workflow-application-list-view/workflow-application-list-view.component';
import { RegisterComponent } from './components/security/register-component/register.component';
import { NumberFormatDirective } from './components/workflow/data-entities/numeric-data-entity/numeric-data-entity-input/number.directive';
import { BasicEsriMapComponent } from './components/arcgis/basic-esri-map/basic-esri-map.component';
import { DataTableHelpers } from './components/system/datatable/datatable-helper';
import { PipesModule } from './pipes/pipes.module';
import { versions } from './../environments/versions';
import { BrowserModule } from '@angular/platform-browser';
import {
  NgModule,
  CUSTOM_ELEMENTS_SCHEMA,
  ErrorHandler,
  APP_INITIALIZER,
  inject
} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterModule,
  RouterStateSnapshot,
  Routes
} from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { DragulaModule } from 'ng2-dragula';
import {
  NgbTypeaheadModule,
  NgbModalModule,
  NgbModule,
  NgbDropdownModule,
  NgbAccordionModule,
  NgbNavModule,
  NgbTimepickerModule
} from '@ng-bootstrap/ng-bootstrap';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';

import { AppComponent } from './app.component';
import { ClientEditorComponent } from './views/admin/client-editor/client-editor.component';
import { AdminViewComponent } from './views/master-views/admin.view/admin.view.component';
import { LandingViewComponent } from './views/master-views/landing.view/landing.view.component';
// TODO: Delete FullScreenViewComponent
// import { FullScreenViewComponent } from './views/master-views/full-screen.view/full-screen.view.component';
import { AppViewComponent } from './views/master-views/app.view/app.view.component';
import { ContactEditorComponent } from './views/admin/contact-editor/contact-editor.component';
import { ContractorEditorComponent } from './views/admin/contractor-editor/contractor-editor.component';
import { ContractorRegistrationEditorComponent } from './views/admin/contractor-registration-editor/contractor-registration-editor.component';
import { ContractorRequirementTypeEditorComponent } from './views/admin/contractor-requirement-type-editor/contractor-requirement-type-editor.component';
import { ExternalDataConnectionsEditorComponent } from './views/admin/external-data-connections-editor/external-data-connections-editor.component';
import { RoleEditorComponent } from './views/admin/role-editor/role-editor.component';
import { MapServicesEditorComponent } from './views/admin/map-services-editor/map-services-editor.component';
import { UnauthorizedComponent } from './views/admin/unauthorized/unauthorized.component';
import { UserEditorComponent } from './views/admin/user-editor/user-editor.component';
import { ClientSearchComponent } from './components/client/client-search/client-search.component';
import { ClientSelectComponent } from './components/client/client-select/client-select.component';
import { ClientDetailViewComponent } from './views/admin/client-editor/client-detail/client-detail.component';
import { PaymentConfigComponent } from './views/admin/client-editor/payment-config/payment-config.component';
import { ClientListViewComponent } from './views/admin/client-editor/client-list/client-list.component';
import { WorkflowListComponent } from './components/workflow/workflow-list/workflow-list.component';
import { LoginComponent } from './components/system/login/login.component';
import {
  DataService,
  WorkflowContextService,
  SecurityService,
  ClientService,
  MapServiceProvider,
  ContractorService,
  SystemService,
  NotificationService,
  DocumentService,
  CalendarService,
  TemplateService,
  WorkflowService,
  SearchParcelDataProvider
} from './services';
import {
  MockWorkflowService,
  MockMapServiceProvider,
  MockDocumentService
} from './services/mock-data';
import { ControlMessageComponent } from './components/system/control-message/control-message.component';
import { ModalConfirmComponent } from './components/system/modal-confirm/modal-confirm.component';
import { ClientListComponent } from './components/client/client-list/client-list.component';
import { MapServiceListViewComponent } from './views/admin/map-services-editor/map-service-list-view/map-service-list-view.component';
import { MapServiceDetailViewComponent } from './views/admin/map-services-editor/map-service-detail-view/map-service-detail-view.component';
import { MapServiceListComponent } from './components/system/map-service-list/map-service-list.component';
import { MapServiceSelectComponent } from './components/system/map-service-select/map-service-select.component';
import { ExternalDataConnectionsListViewComponent } from './views/admin/external-data-connections-editor/external-data-connections-list-view/external-data-connections-list-view.component';
import { ExternalDataConnectionsDetailViewComponent } from './views/admin/external-data-connections-editor/external-data-connections-detail-view/external-data-connections-detail-view.component';
import { ExternalDataConnectionsSearchComponent } from './components/external-data-connections/external-data-connections-search/external-data-connections-search.component';
import { ExternalDataConnectionsListComponent } from './components/external-data-connections/external-data-connections-list/external-data-connections-list.component';
import { ParcelDataSearchComponent } from './components/external-data-connections/parcel-data-search/parcel-data-search.component';
import { UserListViewComponent } from './views/admin/user-editor/user-list-view/user-list-view.component';
import { UserDetailViewComponent } from './views/admin/user-editor/user-detail-view/user-detail-view.component';
import { ProcessingStatusComponent } from './components/system/processing-status/processing-status.component';
import { UserSearchComponent } from './components/user/user-search/user-search.component';
import { UserListComponent } from './components/user/user-list/user-list.component';
import { AssociateUserComponent } from './components/user/associate-user/associate-user.component';
import { PermissionListComponent } from './components/security/permission-list/permission-list.component';
import { AdvancedPermissionsEditorComponent } from './components/security/advanced-permissions-editor/advanced-permissions-editor.component';
import { RegisterViewComponent } from './views/register-view/register-view.component';
import { UserProfileViewComponent } from './views/user-profile-view/user-profile-view.component';
import { ResetPasswordViewComponent } from './views/reset-password-view/reset-password-view.component';
import { VerifyUserViewComponent } from './views/verify-user-view/verify-user-view.component';
import { WorkflowSettingsEditorComponent } from './components/workflow/workflow-settings-editor/workflow-settings-editor.component';
import { WorkflowDiagramComponent } from './components/workflow/workflow-diagram/workflow-diagram.component';
import { WorkflowDataEntityListComponent } from './components/workflow/workflow-data-entity-list/workflow-data-entity-list.component';
import { WorkflowDataEntitySelectComponent } from './components/workflow/workflow-data-entity-select/workflow-data-entity-select.component';
import { WorkflowCheckpointListComponent } from './components/workflow/workflow-checkpoint-list/workflow-checkpoint-list.component';
import { UserWorkflowListComponent } from './components/workflow/user-workflow-list/user-workflow-list.component';
import { CommunityWorkflowListComponent } from './components/workflow/community-workflow-list/community-workflow-list.component';
import { ArchiveWorkflowComponent } from './components/workflow/archive-workflow/archive-workflow.component';
import { ActivityEditorComponent } from './components/workflow/activities/activity-editor/activity-editor.component';
import { CompleteTimeoutActivityEditorComponent } from './components/workflow/activities/complete-timeout-activity-editor/complete-timeout-activity-editor.component';
import { CreateUserActivityEditorComponent } from './components/workflow/activities/create-user-activity-editor/create-user-activity-editor.component';
import { EmailActivityEditorComponent } from './components/workflow/activities/email-activity/email-activity-editor/email-activity-editor.component';
import { FormActivityEditorComponent } from './components/workflow/activities/form-activity-editor/form-activity-editor.component';
import { MilestoneActivityEditorComponent } from './components/workflow/activities/milestone-activity-editor/milestone-activity-editor.component';
import { QueryMapLayerActivityEditorComponent } from './components/workflow/activities/query-map-layer-activity/query-map-layer-activity-editor/query-map-layer-activity-editor.component';
import { ScheduleActivityEditorComponent } from './components/workflow/activities/schedule-activity-editor/schedule-activity-editor.component';
import { ScreenTemplateActivityEditorComponent } from './components/workflow/activities/screen-template-activity-editor/screen-template-activity-editor.component';
import { SearchExternalDataActivityEditorComponent } from './components/workflow/activities/search-external-data-activity-editor/search-external-data-activity-editor.component';
import { SearchParcelDataActivityEditorComponent } from './components/workflow/activities/search-parcel-data-activity-editor/search-parcel-data-activity-editor.component';
import { StartTimeoutActivityEditorComponent } from './components/workflow/activities/start-timeout-activity-editor/start-timeout-activity-editor.component';
import { ActivityContainerComponent } from './components/workflow/activities/activity-editor/activity-container/activity-container.component';
import { ActivityViewComponent } from './components/workflow/activities/activity-editor/activity-view/activity-view.component';
import { UserMenuComponent } from './components/system/user-menu/user-menu.component';
import { WorkflowListViewComponent } from './views/admin/workflow-editor/workflow-list-view/workflow-list-view.component';
import { RoleListViewComponent } from './views/admin/role-editor/role-list-view/role-list-view.component';
import { RoleDetailViewComponent } from './views/admin/role-editor/role-detail-view/role-detail-view.component';
import { RoleListComponent } from './components/security/role-list/role-list.component';
import { RoleListByClientComponent } from './components/security/role-list-by-client/role-list-by-client.component';
import { RoleMemberListComponent } from './components/security/role-member-list/role-member-list.component';
import { AssociateRoleComponent } from './components/security/associate-role/associate-role.component';
import { AddressDetailComponent } from './components/system/address-detail/address-detail.component';
import { AddressListComponent } from './components/system/address-list/address-list.component';
import { EmailAddressDetailComponent } from './components/system/email-address-detail/email-address-detail.component';
import {
  AuthGuard,
  ActionGuard,
  ClientAdminGuard,
  CompletedApplicationGuard,
  ActionsGuard,
  ClientIdGuard
} from './services/auth.guard';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor } from './services/jwt.interceptor';
import { JsonNetInterceptor } from './services/json-net-interceptor';
import { MapServerEditorComponent } from './views/admin/map-server-editor/map-server-editor.component';
import { MapServerListViewComponent } from './views/admin/map-server-editor/map-server-list-view/map-server-list-view.component';
import { MapServerDetailViewComponent } from './views/admin/map-server-editor/map-server-detail-view/map-server-detail-view.component';
import { MapServerListComponent } from './components/system/map-server-list/map-server-list.component';
import { MapServerSelectComponent } from './components/system/map-server-select/map-server-select.component';
import { environment } from '../environments/environment';
import { PaymentProcessorEditorComponent } from './views/admin/payment-processor-editor/payment-processor-editor.component';
import { PaymentProcessorDetailViewComponent } from './views/admin/payment-processor-editor/payment-processor-detail-view/payment-processor-detail-view.component';
import { PaymentProcessorListViewComponent } from './views/admin/payment-processor-editor/payment-processor-list-view/payment-processor-list-view.component';
import { PaymentAccountEditorComponent } from './views/admin/payment-account-editor/payment-account-editor.component';
import { PaymentAccountDetailViewComponent } from './views/admin/payment-account-editor/payment-account-detail-view/payment-account-detail-view.component';
import { PaymentAccountListViewComponent } from './views/admin/payment-account-editor/payment-account-list-view/payment-account-list-view.component';
import { PaymentProcessorListComponent } from './components/payment-processor/payment-processor-list/payment-processor-list.component';
import { PaymentAccountListComponent } from './components/payment-account/payment-account-list/payment-account-list.component';
import { PaymentProcessorConfigBaseComponent } from './components/payment-processor/payment-processor-config-base/payment-processor-config-base.component';
import { PaymentProcessorConfigPayGovComponent } from './components/payment-processor/payment-processor-config-paygov/payment-processor-config-paygov.component';
import { PaymentProcessorGuard } from './services/payment-processor.guard';
import { PaymentProcessorConfigurationResolver } from './services/payment-processor-configuration';
import { PaymentProcessorConfigVpsComponent } from './components/payment-processor/payment-processor-config-vps/payment-processor-config-vps.component';
import { PaymentProcessorConfigPointnpayComponent } from './components/payment-processor/payment-processor-config-pointnpay/payment-processor-config-pointnpay.component';
import { PaymentProcessorConfigCardpointeComponent } from './components/payment-processor/payment-processor-config-cardpointe/payment-processor-config-cardpointe.component';
import { PaymentAccountConfigBaseComponent } from './components/payment-account/payment-account-config-base/payment-account-config-base.component';
import { PaymentAccountConfigPaygovComponent } from './components/payment-account/payment-account-config-paygov/payment-account-config-paygov.component';
import { PaymentProcessorSelectComponent } from './components/payment-processor/payment-processor-select/payment-processor-select.component';
import { PaymentAccountConfigVpsComponent } from './components/payment-account/payment-account-config-vps/payment-account-config-vps.component';
import { PayGovPaymentProcessorAccountConfiguration, Actions } from './models';
import { PaymentAccountConfigSecurepayComponent } from './components/payment-account/payment-account-config-securepay/payment-account-config-securepay.component';
import { PaymentAccountConfigCardpointeComponent } from './components/payment-account/payment-account-config-cardpointe/payment-account-config-cardpointe.component';
import { PaymentAccountConfigOfficialpaymentsComponent } from './components/payment-account/payment-account-config-officialpayments/payment-account-config-officialpayments.component';
import { ContactsEditorComponent } from './views/admin/contacts-editor/contacts-editor.component';
import { FreeFormTextDataEntityComponent } from './components/workflow/data-entities/free-form-text-data-entity/free-form-text-data-entity.component';
import { DataEntityEditorComponent } from './components/workflow/data-entities/data-entity-editor/data-entity-editor.component';
import { DataEntityEditorBaseComponent } from './components/workflow/data-entities/data-entity-editor-base/data-entity-editor-base.component';
import { DataEntitySelectComponent } from './components/workflow/data-entities/data-entity-select/data-entity-select.component';
import { DataEntityOrderListComponent } from './components/workflow/data-entities/data-entity-order-list/data-entity-order-list.component';
import { ActivityEditorBaseComponent } from './components/workflow/activities/activity-editor-base/activity-editor-base.component';
import { ContactListViewComponent } from './views/admin/contacts-editor/contact-list-view/contact-list-view.component';
import { ContactDetailViewComponent } from './views/admin/contacts-editor/contact-detail-view/contact-detail-view.component';
import { ContactsListComponent } from './components/contacts/contacts-list/contacts-list.component';

import { ApplicationInputComponent } from './views/workflow/application-input/application-input.component';
import { ApplicationReadonlyComponent } from './views/workflow/application-readonly/application-readonly.component';
import { FormActivityInputComponent } from './components/workflow/activities/form-activity-editor/form-activity-input/form-activity-input.component';
import { FormActivityInputLayoutSingleColumnComponent } from './components/workflow/activities/form-activity-editor/form-activity-input/form-activity-input-layout-single-column/form-activity-input-layout-single-column.component';
import { FormActivityInputLayoutTwoColumnComponent } from './components/workflow/activities/form-activity-editor/form-activity-input/form-activity-input-layout-two-column/form-activity-input-layout-two-column.component';
import { FormActivityInputLayoutBaseComponent } from './components/workflow/activities/form-activity-editor/form-activity-input/form-activity-input-layout-base/form-activity-input-layout-base.component';
import { FreeFormTextDataEntityInputComponent } from './components/workflow/data-entities/free-form-text-data-entity/free-form-text-data-entity-input/free-form-text-data-entity-input.component';
import { DataEntityInputComponent } from './components/workflow/data-entities/data-entity-input/data-entity-input.component';
import { DataEntityInputListComponent } from './components/workflow/data-entities/data-entity-input-list/data-entity-input-list.component';
import { CompleteWorkflowActivityEditorComponent } from './components/workflow/activities/complete-workflow-activity-editor/complete-workflow-activity-editor.component';
import { CompleteWorkflowActivityInputComponent } from './components/workflow/activities/complete-workflow-activity-editor/complete-workflow-activity-input/complete-workflow-activity-input.component';
import { DocumentDataEntityComponent } from './components/workflow/data-entities/document-data-entity/document-data-entity.component';
import { DocumentDataEntityInputComponent } from './components/workflow/data-entities/document-data-entity/document-data-entity-input/document-data-entity-input.component';

import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { FlexLayoutModule } from '@angular/flex-layout';
// import { UploadService } from '../../services/upload/upload.service';

import { OtherFormatEditorComponent } from './components/workflow/data-entities/free-form-text-data-entity/other-format-editor/other-format-editor.component';
import { DecisionActivityEditorComponent } from './components/workflow/activities/decision-activity-editor/decision-activity-editor.component';
import { EmailAddressComponent } from './components/system/email-address/email-address.component';
import { NumericDataEntityComponent } from './components/workflow/data-entities/numeric-data-entity/numeric-data-entity.component';
import { NumericDataEntityInputComponent } from './components/workflow/data-entities/numeric-data-entity/numeric-data-entity-input/numeric-data-entity-input.component';
import { MapsketchDataEntityEditorComponent } from './components/workflow/data-entities/mapsketch-data-entity/mapsketch-data-entity-editor/mapsketch-data-entity-editor.component';
import { MapsketchDataEntityInputComponent } from './components/workflow/data-entities/mapsketch-data-entity/mapsketch-data-entity-input/mapsketch-data-entity-input.component';
import { MapsketchDataEntityViewComponent } from './components/workflow/data-entities/mapsketch-data-entity/mapsketch-data-entity-view/mapsketch-data-entity-view.component';
import { FormActivityInputViewComponent } from './components/workflow/activities/form-activity-editor/form-activity-input/form-activity-input-view/form-activity-input-view.component';
import { UserWorkflowApplicationListComponent } from './components/workflow/user-workflow-application-list/user-workflow-application-list.component';
import { WorkflowApplicationListComponent } from './components/workflow/workflow-application-list/workflow-application-list.component';
import { UploadComponent } from './components/upload/upload.component';
import { AuthenticationService } from './services/authentication.service';
import { FormActivityViewComponent } from './components/workflow/activities/form-activity-editor/form-activity-view/form-activity-view.component';
import { DataEntityViewComponent } from './components/workflow/data-entities/data-entity-view/data-entity-view.component';
import { DataEntityViewListComponent } from './components/workflow/data-entities/data-entity-view-list/data-entity-view-list.component';
import { FreeFormTextDataEntityViewComponent } from './components/workflow/data-entities/free-form-text-data-entity/free-form-text-data-entity-view/free-form-text-data-entity-view.component';
import { NumericDataEntityViewComponent } from './components/workflow/data-entities/numeric-data-entity/numeric-data-entity-view/numeric-data-entity-view.component';
import { FeeDataEntityEditorComponent } from './components/workflow/data-entities/fee-data-entity/fee-data-entity-editor/fee-data-entity-editor.component';
import { FeeDataEntityInputComponent } from './components/workflow/data-entities/fee-data-entity/fee-data-entity-input/fee-data-entity-input.component';
import { FeeDataEntityViewComponent } from './components/workflow/data-entities/fee-data-entity/fee-data-entity-view/fee-data-entity-view.component';
import { DataEntityAutocompleteComponent } from './components/workflow/data-entity-autocomplete/data-entity-autocomplete.component';
import { SearchParcelDataActivityInputComponent } from './components/workflow/activities/search-parcel-data-activity-editor/search-parcel-data-activity-input/search-parcel-data-activity-input.component';
import { SearchParcelDataActivityViewComponent } from './components/workflow/activities/search-parcel-data-activity-editor/search-parcel-data-activity-view/search-parcel-data-activity-view.component';
import { DocumentDataEntityViewComponent } from './components/workflow/data-entities/document-data-entity/document-data-entity-view/document-data-entity-view.component';

import { PaymentActivityInputComponent } from './components/workflow/activities/payment-activity/payment-activity-input/payment-activity-input.component';
import { PaymentActivityViewComponent } from './components/workflow/activities/payment-activity/payment-activity-view/payment-activity-view.component';
import { PaymentActivityEditorComponent } from './components/workflow/activities/payment-activity/payment-activity-editor/payment-activity-editor.component';
import { PrintTemplateActivityInputComponent } from './components/workflow/activities/print-template-activity/print-template-activity-input/print-template-activity-input.component';
import { PrintTemplateActivityViewComponent } from './components/workflow/activities/print-template-activity/print-template-activity-view/print-template-activity-view.component';
import { PrintTemplateActivityEditorComponent } from './components/workflow/activities/print-template-activity/print-template-activity-editor/print-template-activity-editor.component';
import { UploadService } from './services/upload/upload.service';

import { EsriMapComponent } from './components/arcgis/esri-map/esri-map.component';
import { EsriLoaderService } from './components/arcgis/esri-loader.service';
import { MapImageLayerService } from './components/arcgis/mapimagelayer.service';
import { WaitActivityEditorComponent } from './components/workflow/activities/wait-activity/wait-activity-editor/wait-activity-editor.component';
import { WaitActivityInputComponent } from './components/workflow/activities/wait-activity/wait-activity-input/wait-activity-input.component';
import { WaitActivityViewComponent } from './components/workflow/activities/wait-activity/wait-activity-view/wait-activity-view.component';
import { PaymentTransactionsComponent } from './views/reports/payment-transactions/payment-transactions.component';
import { PaymentRequestCriteriaComponent } from './components/system/payment-request-criteria/payment-request-criteria.component';
import { AuthInterceptor } from './services/auth.interceptor';
import { YesNoDataEntityEditorComponent } from './components/workflow/data-entities/yes-no-data-entity/yes-no-data-entity-editor/yes-no-data-entity-editor.component';
import { YesNoDataEntityInputComponent } from './components/workflow/data-entities/yes-no-data-entity/yes-no-data-entity-input/yes-no-data-entity-input.component';
import { YesNoDataEntityViewComponent } from './components/workflow/data-entities/yes-no-data-entity/yes-no-data-entity-view/yes-no-data-entity-view.component';
import { StartWorkflowActivityEditorComponent } from './components/workflow/activities/start-workflow-activity/start-workflow-activity-editor/start-workflow-activity-editor.component';
import { StartWorkflowActivityInputComponent } from './components/workflow/activities/start-workflow-activity/start-workflow-activity-input/start-workflow-activity-input.component';
import { StartWorkflowActivityViewComponent } from './components/workflow/activities/start-workflow-activity/start-workflow-activity-view/start-workflow-activity-view.component';
import { WorkflowExportComponent } from './components/reports/workflow-export/workflow-export.component';
import { DisplayEntityValueDataEntityEditorComponent } from './components/workflow/data-entities/display-entity-value/display-entity-value-data-entity-editor/display-entity-value-data-entity-editor.component';
import { DisplayEntityValueDataEntityViewComponent } from './components/workflow/data-entities/display-entity-value/display-entity-value-data-entity-view/display-entity-value-data-entity-view.component';
import { DisplayEntityValueDataEntityInputComponent } from './components/workflow/data-entities/display-entity-value/display-entity-value-data-entity-input/display-entity-value-data-entity-input.component';
import { WorkflowApplicationNavigationComponent } from './components/workflow/workflow-application-navigation/workflow-application-navigation.component';
import { WorkflowApplicationFeeSummaryComponent } from './components/workflow/workflow-application-fee-summary/workflow-application-fee-summary.component';
import { NavbarComponent } from './components/nav/navbar/navbar.component';
import { LoaderComponent } from './components/system/loader/loader.component';
import { LoginViewComponent } from './views/login-view/login-view.component';
import { ClientViewComponent } from './views/master-views/client-view/client-view.component';
import { WorkflowItemComponent } from './components/workflow/workflow-item/workflow-item.component';
import { UserApplicationsComponent } from './views/user-applications/user-applications.component';
import { PhonePipe } from './pipes/phone.pipe';
import { ApplicationSummaryComponent } from './views/workflow/application-summary/application-summary.component';
import { ClientAdminComponent } from './views/master-views/admin.view/client-admin/client-admin.component';
import { GlobalAdminComponent } from './views/master-views/admin.view/global-admin/global-admin.component';
import { WorkflowBuilderComponent } from './views/master-views/admin.view/workflow-builder/workflow-builder.component';
import { WorkflowActivitySidebarComponent } from './components/workflow/workflow-activity-sidebar/workflow-activity-sidebar.component';
import { ActivityEditorHostDirective } from './components/workflow/workflow-activity-sidebar/activity-editor-host.directive';
import { ActivityDetailsModalComponent } from './components/workflow/activities/activity-editor/activity-details-modal/activity-details-modal.component';
import { DataEntityItemComponent } from './components/workflow/activities/form-activity-editor/data-entity-item/data-entity-item.component';
import { TodayDataEntityEditorComponent } from './components/workflow/data-entities/today-data-entity/today-data-entity-editor/today-data-entity-editor.component';
import { TodayDataEntityInputComponent } from './components/workflow/data-entities/today-data-entity/today-data-entity-input/today-data-entity-input.component';
import { TodayDataEntityViewComponent } from './components/workflow/data-entities/today-data-entity/today-data-entity-view/today-data-entity-view.component';
import { SignatureDataEntityEditorComponent } from './components/workflow/data-entities/signature-data-entity/signature-data-entity-editor/signature-data-entity-editor.component';
import { SignatureDataEntityInputComponent } from './components/workflow/data-entities/signature-data-entity/signature-data-entity-input/signature-data-entity-input.component';
import { SignatureDataEntityViewComponent } from './components/workflow/data-entities/signature-data-entity/signature-data-entity-view/signature-data-entity-view.component';
// import { SignaturePadModule } from 'signature_pad';
import { SignatureFieldComponent } from './components/workflow/data-entities/signature-data-entity/signature-field/signature-field.component';
import { WorkflowDetailsViewComponent } from './views/admin/workflow-details/workflow-details-view/workflow-details-view.component';
import { ContractorTypeListViewComponent } from './views/admin/contractor-editor/contractor-type-list-view/contractor-type-list-view.component';
import { ContractorTypeListComponent } from './components/contractors/contractor-type-list/contractor-type-list.component';
import { ContractorTypeDetailsViewComponent } from './views/admin/contractor-editor/contractor-type-details-view/contractor-type-details-view.component';
import { ContractorTypeEditorViewComponent } from './views/admin/contractor-editor/contractor-type-editor-view/contractor-type-editor-view.component';
import { ContractorSearchComponent } from './views/admin/contractor-search/contractor-search.component';
import { ContractorListComponent } from './components/contractors/contractor-list/contractor-list.component';
import { ToastrModule } from 'ngx-toastr';
import { MapDisplayConfigurationEditorComponent } from './components/arcgis/map-display-configuration-editor/map-display-configuration-editor.component';
import { ContractorRegistrationDecisionActivityEditorComponent } from './components/workflow/activities/contractor-registration-decision-activity/contractor-registration-decision-activity-editor/contractor-registration-decision-activity-editor.component';
import { ContractorRegistrationDecisionActivityInputComponent } from './components/workflow/activities/contractor-registration-decision-activity/contractor-registration-decision-activity-input/contractor-registration-decision-activity-input.component';
import { ContractorRegistrationDecisionActivityViewComponent } from './components/workflow/activities/contractor-registration-decision-activity/contractor-registration-decision-activity-view/contractor-registration-decision-activity-view.component';
import { ContractorInfoFormActivityEditorComponent } from './components/workflow/activities/contractor-info-form-activity/contractor-info-form-activity-editor/contractor-info-form-activity-editor.component';
import { ContractorInfoFormActivityInputComponent } from './components/workflow/activities/contractor-info-form-activity/contractor-info-form-activity-input/contractor-info-form-activity-input.component';
import { ContractorInfoFormActivityViewComponent } from './components/workflow/activities/contractor-info-form-activity/contractor-info-form-activity-view/contractor-info-form-activity-view.component';
import { ContractorRegistrationInfoFormActivityEditorComponent } from './components/workflow/activities/contractor-registration-info-form-activity/contractor-registration-info-form-activity-editor/contractor-registration-info-form-activity-editor.component';
import { ContractorRegistrationInfoFormActivityInputComponent } from './components/workflow/activities/contractor-registration-info-form-activity/contractor-registration-info-form-activity-input/contractor-registration-info-form-activity-input.component';
import { ContractorRegistrationInfoFormActivityViewComponent } from './components/workflow/activities/contractor-registration-info-form-activity/contractor-registration-info-form-activity-view/contractor-registration-info-form-activity-view.component';
import { ContractorDocumentDataEntityEditorComponent } from './components/workflow/data-entities/contractor-document-data-entity/contractor-document-data-entity-editor/contractor-document-data-entity-editor.component';
import { ContractorDocumentDataEntityInputComponent } from './components/workflow/data-entities/contractor-document-data-entity/contractor-document-data-entity-input/contractor-document-data-entity-input.component';
import { ContractorDocumentDataEntityViewComponent } from './components/workflow/data-entities/contractor-document-data-entity/contractor-document-data-entity-view/contractor-document-data-entity-view.component';
import { ContractorDocumentCategoryEditorComponent } from './views/admin/contractor-document-category-editor/contractor-document-category-editor.component';
import { InspectionRequestActivityViewComponent } from './components/workflow/activities/inspection-request/inspection-request-activity-view/inspection-request-activity-view.component';
import { InspectionRequestActivityInputComponent } from './components/workflow/activities/inspection-request/inspection-request-activity-input/inspection-request-activity-input.component';
import { InspectionRequestActivityEditorComponent } from './components/workflow/activities/inspection-request/inspection-request-activity-editor/inspection-request-activity-editor.component';
import { PreviousRouteService } from './services/previous-route';
import { GenerateClientApplicationNumberDataEntityInputComponent } from './components/workflow/data-entities/client-app-number-data-entity/client-app-number-data-entity-input/client-app-number-data-entity-input.component';
import { GenerateClientApplicationNumberDataEntityViewComponent } from './components/workflow/data-entities/client-app-number-data-entity/client-app-number-data-entity-view/client-app-number-data-entity-view.component';
import { GenerateClientApplicationNumberDataEntityEditorComponent } from './components/workflow/data-entities/client-app-number-data-entity/client-app-number-data-entity-editor/client-app-number-data-entity-editor.component';
import { DateDataEntityEditorComponent } from './components/workflow/data-entities/date-data-entity/date-data-entity-editor/date-data-entity-editor.component';
import { DateDataEntityInputComponent } from './components/workflow/data-entities/date-data-entity/date-data-entity-input/date-data-entity-input.component';
import { DateDataEntityViewComponent } from './components/workflow/data-entities/date-data-entity/date-data-entity-view/date-data-entity-view.component';
import { ConfirmEmailViewComponent } from './views/confirm-email-view/confirm-email-view.component';
import { QrCodeComponent } from './components/system/qr-code/qr-code.component';
import { ContactListDataEntityEditorComponent } from './components/workflow/data-entities/contact-list-data-entity/contact-list-data-entity-editor/contact-list-data-entity-editor.component';
import { ContactListDataEntityInputComponent } from './components/workflow/data-entities/contact-list-data-entity/contact-list-data-entity-input/contact-list-data-entity-input.component';
import { ContactListDataEntityViewComponent } from './components/workflow/data-entities/contact-list-data-entity/contact-list-data-entity-view/contact-list-data-entity-view.component';
import { CalculateValueDataEntityEditorComponent } from './components/workflow/data-entities/calculate-value-data-entity/calculate-value-data-entity-editor/calculate-value-data-entity-editor.component';
import { CalculateValueDataEntityInputComponent } from './components/workflow/data-entities/calculate-value-data-entity/calculate-value-data-entity-input/calculate-value-data-entity-input.component';
import { CalculateValueDataEntityViewComponent } from './components/workflow/data-entities/calculate-value-data-entity/calculate-value-data-entity-view/calculate-value-data-entity-view.component';
import { EsriMapDesigntimeComponent } from './components/arcgis/esri-map-designtime/esri-map-designtime.component';
import { MapActivityEditorComponent } from './components/workflow/activities/map-activity/map-activity-editor/map-activity-editor.component';
import { MapActivityInputComponent } from './components/workflow/activities/map-activity/map-activity-input/map-activity-input.component';
import { MapActivityViewComponent } from './components/workflow/activities/map-activity/map-activity-view/map-activity-view.component';
import { InspectionsQueueComponent } from './views/admin/inspections/inspections-queue/inspections-queue.component';
import { InspectionsQueueMapComponent } from './components/inspections/inspections-queue-map/inspections-queue-map.component';

import { ContractorSelectionActivityEditorComponent } from './components/workflow/activities/contractor-selection-activity/contractor-selection-activity-editor/contractor-selection-activity-editor.component';
import { ContractorSelectionActivityInputComponent } from './components/workflow/activities/contractor-selection-activity/contractor-selection-activity-input/contractor-selection-activity-input.component';
import { ContractorSelectionActivityViewComponent } from './components/workflow/activities/contractor-selection-activity/contractor-selection-activity-view/contractor-selection-activity-view.component';
import { RequiredUserActivityEditorComponent } from './components/workflow/activities/required-user-activity/required-user-activity-editor/required-user-activity-editor.component';
import { RequiredUserActivityInputComponent } from './components/workflow/activities/required-user-activity/required-user-activity-input/required-user-activity-input.component';
import { RequiredUserActivityViewComponent } from './components/workflow/activities/required-user-activity/required-user-activity-view/required-user-activity-view.component';
import { InspectionQueueComponent } from './components/inspections/inspection-queue/inspection-queue.component';
import { MyInspectionQueueCompleteComponent } from './views/user-applications/my-inspection-queue/InspectionComplete/my-inspection-queue-complete.component';
import { MyInspectionQueueScheduleComponent } from './views/user-applications/my-inspection-queue/InspectionSchedule/my-inspection-queue-schedule.component';

import { GlobalUserListViewComponent } from './views/admin/user-editor/global-user-list-view/global-user-list-view.component';
import { ContractorRenewalDecisionActivityEditorComponent } from './components/workflow/activities/contractor-renewal-decision-activity-editor/contractor-renewal-decision-activity-editor.component';
import { ContractorRenewalDecisionActivityInputComponent } from './components/workflow/activities/contractor-renewal-decision-activity-input/contractor-renewal-decision-activity-input.component';
import { ContractorRenewalDecisionActivityViewComponent } from './components/workflow/activities/contractor-renewal-decision-activity-view/contractor-renewal-decision-activity-view.component';
import { ContractorProfileComponent } from './components/contractors/contractor-profile/contractor-profile.component';
import { ContractorProfileViewComponent } from './views/contractor-profile-view/contractor-profile-view.component';
import { ContractorDocumentListComponent } from './components/contractors/contractor-document-list/contractor-document-list.component';
import { ContractorCustomFieldListComponent } from './components/contractors/contractor-custom-field-list/contractor-custom-field-list.component';
import { ContractorRegistrationDetailsComponent } from './components/contractors/contractor-registration-details/contractor-registration-details.component';
import { EmailActivityViewComponent } from './components/workflow/activities/email-activity/email-activity-view/email-activity-view.component';
import { WorkflowValidityModalComponent } from './components/workflow/workflow-validity-modal/workflow-validity-modal.component';
import { PaymentAccountConfigPaymentStubComponent } from './components/payment-account/payment-account-config-payment-stub/payment-account-config-payment-stub.component';

import { ListDataEntityEditorComponent } from './components/workflow/data-entities/list-data-entity/list-data-entity-editor/list-data-entity-editor.component';
import { ListDataEntityInputComponent } from './components/workflow/data-entities/list-data-entity/list-data-entity-input/list-data-entity-input.component';
import { ListDataEntityViewComponent } from './components/workflow/data-entities/list-data-entity/list-data-entity-view/list-data-entity-view.component';

import { AppLoadService } from './services/app-initializer';
import { InspectionScheduleActivityEditorComponent } from './components/workflow/activities/inspection-schedule/inspection-schedule-activity-editor/inspection-schedule-activity-editor.component';
import { InspectionScheduleActivityInputComponent } from './components/workflow/activities/inspection-schedule/inspection-schedule-activity-input/inspection-schedule-activity-input.component';
import { InspectionScheduleActivityViewComponent } from './components/workflow/activities/inspection-schedule/inspection-schedule-activity-view/inspection-schedule-activity-view.component';
import { ContractorDetailViewComponent } from './views/admin/contractor-editor/contractor-detail-view/contractor-detail-view.component';
import { ReviewContractorRegistrationActivityEditorComponent } from './components/workflow/activities/review-contractor-registration-activity/review-contractor-registration-activity-editor/review-contractor-registration-activity-editor.component';
import { ReviewContractorRegistrationActivityInputComponent } from './components/workflow/activities/review-contractor-registration-activity/review-contractor-registration-activity-input/review-contractor-registration-activity-input.component';
import { ReviewContractorRegistrationActivityViewComponent } from './components/workflow/activities/review-contractor-registration-activity/review-contractor-registration-activity-view/review-contractor-registration-activity-view.component';
import { CompleteInspectionActivityEditorComponent } from './components/workflow/activities/complete-inspection-activity/complete-inspection-activity-editor/complete-inspection-activity-editor.component';
import { CompleteInspectionActivityInputComponent } from './components/workflow/activities/complete-inspection-activity/complete-inspection-activity-input/complete-inspection-activity-input.component';
import { CompleteInspectionActivityViewComponent } from './components/workflow/activities/complete-inspection-activity/complete-inspection-activity-view/complete-inspection-activity-view.component';
import { ContractorExpireRegistrationsComponent } from './views/user-applications/contractor-expire-registrations/contractor-expire-registrations.component';
// import { ContractorRegistrationListComponent } from './views/admin/contractor-editor/contractor-registration-list/contractor-registration-list.component';
import { ContractorRegistrationListComponent } from './components/contractors/contractor-registration-list/contractor-registration-list.component';
import { InspectionDetailsComponent } from './components/inspections/inspection-details/inspection-details.component';
import { InpectionDocumentListComponent } from './components/inspections/inspection-document-list/inspection-document-list.component';
import { AssignedInspectionsViewComponent } from './views/admin/inspections/assigned-inspections/assigned-inspections.component';
import { AssignedInspectionsComponent } from './components/inspections/assigned-inspections/assigned-inspections.component';
import { KeysPipe } from './pipes/keys.pipe';

import { WorkflowActivityPreviewModalComponent } from './components/workflow/workflow-activity-preview-modal/workflow-activity-preview-modal.component';
import { RichTextDataEntityInputComponent } from './components/workflow/data-entities/rich-text-data-entity/rich-text-data-entity-input/rich-text-data-entity-input.component';
import { RichTextDataEntityViewComponent } from './components/workflow/data-entities/rich-text-data-entity/rich-text-data-entity-view/rich-text-data-entity-view.component';
import { RichTextDataEntityEditorComponent } from './components/workflow/data-entities/rich-text-data-entity/rich-text-data-entity-editor/rich-text-data-entity-editor.component';
import { WorkflowApplicationSearchViewComponent } from './views/admin/workflow-applications/workflow-application-search-view/workflow-application-search-view.component';
import { UpcomingInspectionToCompleteComponent } from './views/user-applications/my-inspection-queue/UpcomingInspectionToComplete/upcoming-inspection-to-complete.component';
import { InspectionRequestedQueueComponent } from './views/user-applications/my-inspection-queue/InspectionRequested/inspection-requested-queue.component';
import { InspectionDocument } from './models/inspection-document';
import { InspectionDocumentDataEntityInputComponent } from './components/workflow/data-entities/inspection-document-data-entity/inspection-document-data-entity-input/inspection-document-data-entity-input.component';
import { InspectionDocumentDataEntityEditorComponent } from './components/workflow/data-entities/inspection-document-data-entity/inspection-document-data-entity-editor/inspection-document-data-entity-editor.component';
import { InspectionDocumentDataEntityViewComponent } from './components/workflow/data-entities/inspection-document-data-entity/inspection-document-data-entity-view/inspection-document-data-entity-view.component';
import { CallWorkflowEditorComponent } from './components/workflow/activities/call-workflow/call-workflow-editor/call-workflow-editor.component';
import { CallWorkflowInputComponent } from './components/workflow/activities/call-workflow/call-workflow-input/call-workflow-input.component';
import { CallWorkflowViewComponent } from './components/workflow/activities/call-workflow/call-workflow-view/call-workflow-view.component';
import { FinancialComponent } from './views/reports/financial/financial.component';
import { ContractorsReportComponent } from './views/reports/contractors-report/contractors-report.component';
import { PermitsReportComponent } from './views/reports/permits-report/permits-report.component';
import { FeeRecipientsComponent } from './components/reports/financial/fee-recipients/fee-recipients.component';
import { MetricComponent } from './components/reports/metric/metric.component';
import { ContractorSummaryComponent } from './components/reports/contractors/summary/summary.component';
import { FilterBuilderComponent } from './components/system/filter-builder/filter-builder.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { DailyFeesComponent } from './components/reports/financial/daily-fees/daily-fees.component';
import { FeeSummaryComponent } from './components/reports/contractors/fee-summary/fee-summary.component';
import { PermitsSummaryComponent } from './components/reports/permits/permits-summary/permits-summary.component';
import { WorkflowApplicationsNeedingMyAttentionComponent } from './components/workflow/workflow-application-list/workflow-applications-needing-my-attention/workflow-applications-needing-my-attention.component';
import { WorkflowApplicationsPendingPaymentsComponent } from './components/workflow/workflow-application-list/workflow-applications-pending-payments/workflow-applications-pending-payments.component';
import { ItemTagListComponent } from './components/system/item-tag-list/item-tag-list.component';
import { TagEditorComponent } from './views/admin/tag-editor/tag-editor.component';
import { ConditionBuilderComponent } from './components/system/condition-builder/condition-builder.component';
import { UserInfoComponent } from './components/user/user-info/user-info.component';
import { SystemDataEntityInputComponent } from './components/workflow/data-entities/system-data-entity/system-data-entity-input/system-data-entity-input.component';
import { SystemDataEntityViewComponent } from './components/workflow/data-entities/system-data-entity/system-data-entity-view/system-data-entity-view.component';
import { DataEntityFormulaComponent } from './components/workflow/data-entity-formula/data-entity-formula.component';
import { ConditionGroupComponent } from './components/system/condition-builder/condition-group/condition-group.component';
import { ConditionModalComponent } from './components/system/condition-builder/condition-modal/condition-modal.component';
import { ContractorRequirementEditorComponent } from './views/admin/contractor-editor/contractor-type-editor-view/contractor-requirement-editor/contractor-requirement-editor.component';
import { ListItemEditorComponent } from './components/workflow/data-entities/list-data-entity/list-data-entity-editor/list-item-editor/list-item-editor.component';
import { HelpTextComponent } from './components/system/help-text/help-text.component';
import { DeLabelComponent } from './components/system/de-label/de-label.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { DatatableComponent } from './components/system/datatable/datatable.component';
import { ValidationMessageComponent } from './components/workflow/workflow-validity-modal/validation-message/validation-message.component';
import { WorkflowValidationItemComponent } from './components/workflow/workflow-validation-item/workflow-validation-item.component';
import { WorkflowListSectionComponent } from './components/workflow/workflow-list-section/workflow-list-section.component';
import { CalcOnChangeDataEntityComponent } from './components/workflow/data-entities/data-entity-input/calc-on-change-data-entity';
import { AppointmentActivityEditorComponent } from './components/workflow/activities/appointment-activity/appointment-activity-editor/appointment-activity-editor.component';
import { AppointmentActivityViewComponent } from './components/workflow/activities/appointment-activity/appointment-activity-view/appointment-activity-view.component';
import { ApplicationPrintSummaryComponent } from './views/workflow/application-print-summary/application-print-summary.component';
import { ApplicationExternalNoteEditorComponent } from './components/workflow/application-external-note-editor/application-external-note-editor.component';
import { ApplicationInternalNoteEditorComponent } from './components/workflow/application-internal-note-editor/application-internal-note-editor.component';
import { NmiPaymentFormComponent } from './components/system/nmi-payment-form/nmi-payment-form.component';
import { PaymentAccountConfigNmiComponent } from './components/payment-account/payment-account-config-nmi/payment-account-config-nmi.component';
import { PaymentFormComponent } from './components/system/payment-form/payment-form.component';
import { InitiateWorkflowEditorComponent } from './components/workflow/activities/initiate-workflow/initiate-workflow-editor/initiate-workflow-editor.component';
import { InitiateWorkflowInputComponent } from './components/workflow/activities/initiate-workflow/initiate-workflow-input/initiate-workflow-input.component';
import { InitiateWorkflowViewComponent } from './components/workflow/activities/initiate-workflow/initiate-workflow-view/initiate-workflow-view.component';
import { NotFoundComponent } from './views/notfound/notfound.component';
import { PaymentProcessorConfigSecurepayComponent } from './components/payment-processor/payment-processor-config-securepay/payment-processor-config-securepay.component';
import { SavedFilterComponent } from './components/system/saved-filter/saved-filter.component';
import { GenerateApplicationNumberActivityEditorComponent } from './components/workflow/activities/generate-application-number-activity/generate-application-number-activity-editor/generate-application-number-activity-editor.component';
import { GenerateApplicationNumberActivityInputComponent } from './components/workflow/activities/generate-application-number-activity/generate-application-number-activity-input/generate-application-number-activity-input.component';
import { GenerateApplicationNumberActivityViewComponent } from './components/workflow/activities/generate-application-number-activity/generate-application-number-activity-view/generate-application-number-activity-view.component';
import { PaymentAccountConfigPsnComponent } from './components/payment-account/payment-account-config-psn/payment-account-config-psn.component';
import { PaymentProcessorConfigPsnComponent } from './components/payment-processor/payment-processor-config-psn/payment-processor-config-psn.component';
import { PSNPaymentProcessorAccountConfiguration } from './models/payment-providers/psn/psn-payment-processor-account-configuration';
import { PaymentAccountConfigForteComponent } from './components/payment-account/payment-account-config-forte/payment-account-config-forte.component';
import { PaymentProcessorConfigForteComponent } from './components/payment-processor/payment-processor-config-forte/payment-processor-config-forte.component';
import { CardpointePaymentFormComponent } from './components/system/cardpointe-payment-form/cardpointe-payment-form.component';
import { PaymentAccountConfigGovPayComponent } from './components/payment-account/payment-account-config-govpay/payment-account-config-govpay.component';
import { PaymentAccountConfigPointnpayComponent } from './components/payment-account/payment-account-config-pointnpay/payment-account-config-pointnpay.component';
import { ListEditorComponent } from './components/system/list-editor/list-editor.component';
import { CustomListEditorComponent } from './views/admin/custom-list-editor/custom-list-editor.component';
import { CustomListDetailViewComponent } from './views/admin/custom-list-editor/custom-list-detail-view/custom-list-detail-view.component';
import { CustomListListViewComponent } from './views/admin/custom-list-editor/custom-list-list-view/custom-list-list-view.component';
import { CustomListsListComponent } from './components/custom-lists/custom-lists-list/custom-lists-list.component';
import { ExternalDataSourceConfigComponent } from './components/workflow/data-entities/external-data-source-config/external-data-source-config.component';
import { ScheduleEmailActivityEditorComponent } from './components/workflow/activities/schedule-email-activity/schedule-email-activity-editor/schedule-email-activity-editor.component';
import { ScheduleEmailActivityViewComponent } from './components/workflow/activities/schedule-email-activity/schedule-email-activity-view/schedule-email-activity-view.component';
import { ExportDataActivityEditorComponent } from './components/workflow/activities/export-data-activity/export-data-activity-editor/export-data-activity-editor.component';
import { ExportDataActivityInputComponent } from './components/workflow/activities/export-data-activity/export-data-activity-input/export-data-activity-input.component';
import { ExportDataActivityViewComponent } from './components/workflow/activities/export-data-activity/export-data-activity-view/export-data-activity-view.component';
import { CsvConfigurationEditorComponent } from './components/workflow/activities/export-data-activity/export-data-activity-editor/csv-configuration-editor/csv-configuration-editor.component';
import { SftpConfigurationEditorComponent } from './components/workflow/activities/export-data-activity/export-data-activity-editor/sftp-configuration-editor/sftp-configuration-editor.component';
import { WorkflowShareApplicationComponent } from './components/workflow/workflow-share-application/workflow-share-application.component';
import { GPErrorHandler } from './services/gp-error';
import { PaymentAccountConfigKanpayComponent } from './components/payment-account/payment-account-config-kanpay/payment-account-config-kanpay.component';
import { PaymentProcessorConfigKanpayComponent } from './components/payment-processor/payment-processor-config-kanpay/payment-processor-config-kanpay.component';
import { WorkflowPreviousSystemListComponent } from './components/workflow/workflow-application-list/workflow-previous-system-list/workflow-previous-system-list.component';
import { WorkflowApplicationPreviousSystemListViewComponent } from './views/admin/workflow-applications/workflow-application-previous-system-list-view/workflow-application-previous-system-list-view.component';
import { InspectionSimpleActivityEditorComponent } from './components/workflow/activities/inspection-simple-activity/inspection-simple-activity-editor/inspection-simple-activity-editor.component';
import { InspectionSimpleActivityInputComponent } from './components/workflow/activities/inspection-simple-activity/inspection-simple-activity-input/inspection-simple-activity-input.component';
import { InspectionSimpleActivityViewComponent } from './components/workflow/activities/inspection-simple-activity/inspection-simple-activity-view/inspection-simple-activity-view.component';
import { RenewalListComponent } from './components/renewals/renewal-list/renewal-list.component';
import { WorkflowRenewalListComponent } from './components/renewals/workflow-renewal-list/workflow-renewal-list.component';
import { RenewalDetailsComponent } from './components/renewals/renewal-details/renewal-details.component';
import { RenewalCustomFieldListComponent } from './components/renewals/renewal-custom-field-list/renewal-custom-field-list.component';
import { RenewalDocumentListComponent } from './components/renewals/renewal-document-list/renewal-document-list.component';
import { RequirementEditorComponent } from './components/renewals/requirement-editor/requirement-editor.component';
import { RenewalDecisionActivityInputComponent } from './components/workflow/activities/renewal-decision-activity/renewal-decision-activity-input/renewal-decision-activity-input.component';
import { RenewalDecisionActivityViewComponent } from './components/workflow/activities/renewal-decision-activity/renewal-decision-activity-view/renewal-decision-activity-view.component';
import { RenewalDecisionActivityEditorComponent } from './components/workflow/activities/renewal-decision-activity/renewal-decision-activity-editor/renewal-decision-activity-editor.component';
import { RenewalReviewActivityViewComponent } from './components/workflow/activities/renewal-review-activity/renewal-review-activity-view/renewal-review-activity-view.component';
import { RenewalReviewActivityEditorComponent } from './components/workflow/activities/renewal-review-activity/renewal-review-activity-editor/renewal-review-activity-editor.component';
import { RenewalReviewActivityInputComponent } from './components/workflow/activities/renewal-review-activity/renewal-review-activity-input/renewal-review-activity-input.component';
import { ExpiringRenewalsComponent } from './views/user-applications/expiring-renewals/expiring-renewals.component';
import { RegistrationListSectionComponent } from './components/workflow/registration-list-section/registration-list-section.component';
import { RenewalItemComponent } from './components/workflow/renewal-item/renewal-item.component';
import { ContractorRegistrationItemComponent } from './components/workflow/contractor-registration-item/contractor-registration-item.component';
import { WorkFlowPreviousSytemImportComponent } from './components/workflow/workflow-previous-sytem-import/workflow-previous-sytem-import.component';
import { YDataEntityViewComponent } from './components/workflow/data-entities/y-data-entity/y-data-entity-view/y-data-entity-view.component';
import { XDataEntityViewComponent } from './components/workflow/data-entities/x-data-entity/x-data-entity-view/x-data-entity-view.component';
import { GeoJSONDataEntityViewComponent } from './components/workflow/data-entities/geojson-data-entity/geojson-data-entity-view/geojson-data-entity-view.component';
import { PaymentProcessorConfigMunicipayComponent } from './components/payment-processor/payment-processor-config-municipay/payment-processor-config-municipay.component';
import { PaymentAccountConfigMunicipayComponent } from './components/payment-account/payment-account-config-municipay/payment-account-config-municipay.component';
import { PaymentProcessorConfigSquareComponent } from './components/payment-processor/payment-processor-config-square/payment-processor-config-square.component';
import { PaymentAccountConfigSquareComponent } from './components/payment-account/payment-account-config-square/payment-account-config-square.component';
import { AgendaTypeListComponent } from './views/admin/agendas/agenda-type-list/agenda-type-list.component';
import { AgendaListComponent } from './views/admin/agendas/agenda-list/agenda-list.component';
import { AgendaSettingsEditorComponent } from './views/admin/agendas/agenda-settings-editor/agenda-settings-editor.component';
import { AgendaViewComponent } from './views/admin/agendas/agenda-view/agenda-view.component';
import { AgendaQueueItemInputComponent } from './views/admin/agendas/agenda-queue-item-input/agenda-queue-item-input.component';
import { AgendaQueueItemReadonlyComponent } from './views/admin/agendas/agenda-queue-item-readonly/agenda-queue-item-readonly.component';
import { AgendaItemActivityInputComponent } from './components/workflow/activities/agenda-item-activity/agenda-item-activity-input/agenda-item-activity-input.component';
import { AgendaItemActivityEditorComponent } from './components/workflow/activities/agenda-item-activity/agenda-item-activity-editor/agenda-item-activity-editor.component';
import { AgendaItemActivityViewComponent } from './components/workflow/activities/agenda-item-activity/agenda-item-activity-view/agenda-item-activity-view.component';
import { CustomReportListComponent } from './components/reports/custom-report-list/custom-report-list.component';
import { CustomReportInputComponent } from './components/reports/custom-report-input/custom-report-input.component';
import { PaymentAccountConfigeGOVComponent } from './components/payment-account/payment-account-config-egov/payment-account-config-egov.component';
import { PaymentProcessorConfigeGOVComponent } from './components/payment-processor/payment-processor-config-egov/payment-processor-config-egov.component';
import { PaymentProcessorConfigCertifiedpaymentsComponent } from './components/payment-processor/payment-processor-config-certifiedpayments/payment-processor-config-certifiedpayments.component';
import { PaymentAccountConfigCertifiedpaymentsComponent } from './components/payment-account/payment-account-config-certifiedpayments/payment-account-config-certifiedpayments.component';
import { PaymentAccountActivityConfigBaseComponent } from './components/payment-account/payment-account-activity-config-base/payment-account-activity-config-base.component';
import { PaymentAccountActivityConfigPointnpayComponent } from './components/payment-account/payment-account-activity-config-pointnpay/payment-account-activity-config-pointnpay.component';
import { DecisionActivityViewComponent } from './components/workflow/activities/decision-activity-view/decision-activity-view.component';
import { CancelScheduledEmailActivityEditorComponent } from './components/workflow/activities/cancel-scheduled-email-activity/cancel-scheduled-email-activity-editor/cancel-scheduled-email-activity-editor.component';
import { ScheduledExportEditorComponent } from './views/admin/scheduled-export-editor/scheduled-export-editor.component';
import { ScheduledExportsComponent } from './views/admin/scheduled-exports/scheduled-exports.component';
import { ScheduleEditorComponent } from './components/system/schedule-editor/schedule-editor.component';
import { ExportsViewComponent } from './views/admin/exports-view/exports-view.component';
import { SchedlingEngineTesterComponent } from './views/schedling-engine-tester/schedling-engine-tester.component';
import { MultipleParcelSearchActivityEditorComponent } from './components/workflow/activities/multiple-parcel-search-activity/multiple-parcel-search-activity-editor/multiple-parcel-search-activity-editor.component';
import { MultipleParcelSearchActivityInputComponent } from './components/workflow/activities/multiple-parcel-search-activity/multiple-parcel-search-activity-input/multiple-parcel-search-activity-input.component';
import { MultipleParcelSearchActivityViewComponent } from './components/workflow/activities/multiple-parcel-search-activity/multiple-parcel-search-activity-view/multiple-parcel-search-activity-view.component';
import { PaymentsComponent } from './components/reports/financial/payments/payments.component';
import { MyApplicationParcelsComponent } from './components/my-application-parcels/my-application-parcels.component';
import { InviteUserComponent } from './components/security/invite-user/invite-user.component';
import { VersionInterceptor } from './services/version.interceptor';
import { UserContractorRegistrationDetailsComponent } from './views/user-contractor-registration-details/user-contractor-registration-details.component';
import { DatasetDesignerComponent } from './components/reports/dataset-designer/dataset-designer.component';
import { DatasetListComponent } from './components/reports/dataset-list/dataset-list.component';
import { DatasetInputComponent } from './components/reports/dataset-input/dataset-input.component';
import { EditApplicationNumberComponent } from './components/workflow/edit-application-number/edit-application-number.component';
import { UserRenewableRegistrationDetailsComponent } from './views/user-renewable-registration-details/user-renewable-registration-details.component';
import { ReviewActivityEditorComponent } from './components/workflow/activities/review-activity/review-activity-editor/review-activity-editor.component';
import { ReviewActivityViewComponent } from './components/workflow/activities/review-activity/review-activity-view/review-activity-view.component';
import { ReviewActivityInputComponent } from './components/workflow/activities/review-activity/review-activity-input/review-activity-input.component';
import { FormActivityDataEntitiesBulkActionsComponent } from './components/workflow/activities/form-activity-editor/form-activity-data-entities-bulk-actions/form-activity-data-entities-bulk-actions.component';
import { MoveCustomFieldInfoComponent } from './components/workflow/move-custom-field-info/move-custom-field-info.component';
import { QueryMapLayerActivityInputComponent } from './components/workflow/activities/query-map-layer-activity/query-map-layer-activity-input/query-map-layer-activity-input.component';
import { QueryMapLayerActivityViewComponent } from './components/workflow/activities/query-map-layer-activity/query-map-layer-activity-view/query-map-layer-activity-view.component';
import { WorkflowApplicationSearchByFeatureViewComponent } from './views/admin/workflow-applications/workflow-application-search-by-feature-view/workflow-application-search-by-feature-view.component';
import { AutoVoidTesterComponent } from './views/auto-void-tester/auto-void-tester.component';
import { WorkflowActionsMenuComponent } from './components/workflow/workflow-actions-menu/workflow-actions-menu.component';
import { NgMentionsModule } from '@nth-cloud/ng-mentions';
import { AngularSignaturePadModule } from '@almothafar/angular-signature-pad';
import { CustomReportFilterInputComponent } from './components/reports/custom-report-filter-input/custom-report-filter-input.component';
import { CustomReportRequestListComponent } from './components/reports/custom-report-request-list/custom-report-request-list.component';

const routes: Routes = [
  {
    path: '',
    component: LandingViewComponent
  },
  { path: 'inspection-queue', component: InspectionQueueComponent },
  {
    path: 'home',
    component: LandingViewComponent
  },

  {
    path: 'admin',
    component: AdminViewComponent,
    canActivate: [
      (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
        inject(AuthGuard).canActivate(next, state),
      (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
        inject(SecurityService).canActivate(next, state),
      (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
        inject(ClientIdGuard).canActivate(next, state)
    ],
    children: [
      {
        path: 'jurisdiction/:clientId',
        component: ClientAdminComponent,
        children: [
          {
            path: '',
            redirectTo: 'workflows',
            pathMatch: 'full'
          },
          {
            path: 'workflows',
            children: [
              {
                path: 'list',
                component: WorkflowListViewComponent
                // canActivate: [ActionGuard],
                // data: {
                //   action: Actions.WORKFLOW_VIEW
                // }
              },
              {
                path: '',
                redirectTo: 'list',
                pathMatch: 'full'
              },
              {
                path: 'edit/:workflowId',
                component: WorkflowSettingsEditorComponent,
                data: {
                  clientMode: true
                }
              },
              {
                path: 'details/:workflowId',
                component: WorkflowDetailsViewComponent
              },
              {
                path: 'add',
                component: WorkflowSettingsEditorComponent,
                data: {
                  clientMode: true
                }
              }
            ]
          },
          {
            path: 'workflow-applications',
            children: [
              {
                path: '',
                redirectTo: 'search',
                pathMatch: 'full'
              },
              {
                path: 'search',
                component: WorkflowApplicationSearchViewComponent
              },
              {
                path: 'search-feature',
                component: WorkflowApplicationSearchByFeatureViewComponent
              },
              {
                path: 'list',
                component: WorkflowApplicationListViewComponent
              },
              {
                path: 'previous-system-list',
                component: WorkflowApplicationPreviousSystemListViewComponent
              }
            ]
          },
          {
            path: 'renewals',
            children: [
              {
                path: 'list',
                component: RenewalListComponent
              },
              {
                path: 'details/:registrationId',
                component: RenewalDetailsComponent
              }
            ]
          },
          {
            path: 'contractors',
            children: [
              {
                path: 'types',
                children: [
                  {
                    path: '',
                    redirectTo: 'list',
                    pathMatch: 'full'
                  },
                  {
                    path: 'list',
                    component: ContractorTypeListViewComponent
                  },
                  {
                    path: 'edit/:contractorTypeId',
                    component: ContractorTypeEditorViewComponent
                  },
                  {
                    path: 'details/:contractorTypeId',
                    component: ContractorTypeDetailsViewComponent
                  },
                  {
                    path: 'add',
                    component: ContractorTypeEditorViewComponent
                  }
                ]
              },
              {
                path: 'search',
                component: ContractorSearchComponent
              },
              {
                path: 'contractor-profile/:contractorId',
                component: ContractorDetailViewComponent
              },
              {
                path: 'registration-details/:registrationId',
                component: ContractorRegistrationDetailsComponent
              },
              {
                path: '',
                redirectTo: 'types',
                pathMatch: 'full'
              }
            ]
          },
          {
            path: 'inspections',

            children: [
              {
                path: 'list',
                component: InspectionsQueueComponent
              },
              {
                path: '',
                redirectTo: 'list',
                pathMatch: 'full'
              },
              {
                path: 'details/:inspectionId',
                component: InspectionDetailsComponent
              },
              {
                path: 'assignedInspections',
                component: AssignedInspectionsViewComponent
              }
            ]
          },
          {
            path: 'reports',
            children: [
              {
                path: '',
                redirectTo: 'financial',
                pathMatch: 'full'
              },
              {
                path: 'financial',
                component: FinancialComponent,
                canActivate: [
                  (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
                    inject(ActionGuard).canActivate(next, state)
                ],
                data: {
                  action: Actions.REPORT_PAYMENT_TRANSACTION_VIEW // TODO: update with proper permissions
                }
              },
              {
                path: 'contractors',
                component: ContractorsReportComponent,
                canActivate: [
                  (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
                    inject(ActionGuard).canActivate(next, state)
                ],
                data: {
                  action: Actions.REPORT_PAYMENT_TRANSACTION_VIEW // TODO: update with proper permissions
                }
              },
              {
                path: 'permits',
                component: PermitsReportComponent,
                canActivate: [
                  (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
                    inject(ActionGuard).canActivate(next, state)
                ],
                data: {
                  action: Actions.REPORT_PAYMENT_TRANSACTION_VIEW // TODO: update with proper permissions
                }
              },
              {
                path: 'export',
                component: WorkflowExportComponent,
                canActivate: [
                  (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
                    inject(ActionGuard).canActivate(next, state)
                ],
                data: {
                  action: Actions.REPORT_DATA_EXPORT_VIEW
                }
              },
              {
                path: 'custom-reports',
                component: CustomReportListComponent,
                canActivate: [
                  (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
                    inject(ActionGuard).canActivate(next, state)
                ],
                data: {
                  action: Actions.VIEW_CUSTOM_REPORTS
                }
              },
              {
                path: 'custom-report/:reportId',
                component: CustomReportInputComponent
              },
              {
                path: 'data-sets',
                component: DatasetListComponent
              },
              {
                path: 'data-set/:dataSetId',
                component: DatasetDesignerComponent
              }
            ]
          },
          {
            path: 'meetings',
            children: [
              {
                path: 'agenda-types',
                canActivate: [
                  (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
                    inject(ActionsGuard).canActivate(next, state)
                ],
                data: {
                  actions: Actions.AGENDA_ACTIONS
                },
                children: [
                  {
                    path: 'list',
                    component: AgendaTypeListComponent
                  },
                  {
                    path: 'agenda-type/:agendaTypeId',
                    component: AgendaListComponent
                  },

                  {
                    path: 'agenda-type/:agendaTypeId/edit-agenda/:agendaId',
                    component: AgendaSettingsEditorComponent,
                    canActivate: [
                      (
                        next: ActivatedRouteSnapshot,
                        state: RouterStateSnapshot
                      ) => inject(ActionsGuard).canActivate(next, state)
                    ],
                    data: {
                      actions: [Actions.DO_ANYTHING, Actions.MANAGE_AGENDAS]
                    }
                  },
                  {
                    path: 'agenda-type/:agendaTypeId/add-agenda',
                    component: AgendaSettingsEditorComponent,
                    canActivate: [
                      (
                        next: ActivatedRouteSnapshot,
                        state: RouterStateSnapshot
                      ) => inject(ActionsGuard).canActivate(next, state)
                    ],
                    data: {
                      actions: [Actions.DO_ANYTHING, Actions.MANAGE_AGENDAS]
                    }
                  },
                  {
                    path: 'agenda-type/:agendaTypeId/view-agenda/:agendaId',
                    component: AgendaViewComponent
                  },
                  {
                    path: '',
                    redirectTo: 'list',
                    pathMatch: 'full'
                  }
                ]
              }
            ]
          },
          {
            path: 'users',
            component: UserEditorComponent,
            children: [
              {
                path: 'list',
                component: UserListViewComponent
              },
              {
                path: 'edit/:userId',
                component: UserDetailViewComponent
              },
              {
                path: 'roles',
                component: RoleEditorComponent,
                children: [
                  {
                    path: 'list',
                    component: RoleListViewComponent
                  },
                  {
                    path: 'edit/:id',
                    component: RoleDetailViewComponent
                  },
                  {
                    path: 'add',
                    component: RoleDetailViewComponent
                  },
                  {
                    path: '',
                    redirectTo: 'list',
                    pathMatch: 'full'
                  }
                ]
              },
              {
                path: 'invitations',
                children: [
                  {
                    path: 'add',
                    component: InviteUserComponent
                  },
                  {
                    path: '',
                    redirectTo: 'add',
                    pathMatch: 'full'
                  }
                ]
              },
              {
                path: '',
                redirectTo: 'list',
                pathMatch: 'full'
              }
            ]
          },
          {
            path: 'settings',
            children: [
              {
                path: '',
                redirectTo: 'general',
                pathMatch: 'full'
              },
              {
                path: 'general',
                component: ClientDetailViewComponent
              },

              {
                path: 'import-previous-system',
                component: WorkFlowPreviousSytemImportComponent
              },
              {
                path: 'maps',
                component: MapServicesEditorComponent,
                children: [
                  {
                    path: 'list',
                    component: MapServiceListViewComponent
                  },
                  {
                    path: 'edit/:id',
                    component: MapServiceDetailViewComponent
                  },
                  {
                    path: 'add',
                    component: MapServiceDetailViewComponent
                  },
                  {
                    path: '',
                    redirectTo: 'list',
                    pathMatch: 'full'
                  }
                ]
              },
              {
                path: 'tags',
                component: TagEditorComponent
              },
              {
                path: 'payment-accounts',
                component: PaymentAccountEditorComponent,
                children: [
                  {
                    path: 'list',
                    component: PaymentAccountListViewComponent
                  },
                  {
                    path: 'edit/:paymentAccountId',
                    component: PaymentAccountDetailViewComponent
                  },
                  {
                    path: 'add',
                    component: PaymentAccountDetailViewComponent
                  },
                  {
                    path: '',
                    redirectTo: 'list',
                    pathMatch: 'full'
                  }
                ]
              },
              {
                path: 'data',
                component: ExternalDataConnectionsEditorComponent,
                children: [
                  {
                    path: 'list',
                    component: ExternalDataConnectionsListViewComponent
                  },
                  {
                    path: 'edit/:id',
                    component: ExternalDataConnectionsDetailViewComponent
                  },
                  {
                    path: 'add',
                    component: ExternalDataConnectionsDetailViewComponent
                  },
                  {
                    path: '',
                    redirectTo: 'list',
                    pathMatch: 'full'
                  }
                ]
              },
              {
                path: 'lists',
                component: CustomListEditorComponent,
                children: [
                  {
                    path: 'list',
                    component: CustomListListViewComponent
                  },
                  {
                    path: 'edit/:id',
                    component: CustomListDetailViewComponent
                  },
                  {
                    path: 'add',
                    component: CustomListDetailViewComponent
                  },
                  {
                    path: '',
                    redirectTo: 'list',
                    pathMatch: 'full'
                  }
                ]
              },
              {
                path: 'contacts',
                component: ContactsEditorComponent,
                children: [
                  {
                    path: 'list',
                    component: ContactListViewComponent
                  },
                  {
                    path: 'edit/:id',
                    component: ContactDetailViewComponent
                  },
                  {
                    path: 'add',
                    component: ContactDetailViewComponent
                  },
                  {
                    path: '',
                    redirectTo: 'list',
                    pathMatch: 'full'
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        path: 'workflow-builder/:workflowId',
        component: WorkflowBuilderComponent,
        children: [
          {
            path: '',
            component: WorkflowDiagramComponent
          },
          {
            path: 'activity-editor/:activityId/:activityType',
            component: ActivityEditorComponent
          },
          {
            path: 'data-entity-editor/add',
            component: DataEntityEditorComponent
          },
          {
            path: 'data-entity-editor/:dataEntityId',
            component: DataEntityEditorComponent
          }
        ]
      },
      {
        path: 'workflow-builder/:workflowId/:versionId',
        component: WorkflowBuilderComponent,
        children: [
          {
            path: '',
            component: WorkflowDiagramComponent
          },
          {
            path: 'activity-editor/:activityId/:activityType',
            component: ActivityEditorComponent
          },
          {
            path: 'data-entity-editor/add',
            component: DataEntityEditorComponent
          },
          {
            path: 'data-entity-editor/:dataEntityId',
            component: DataEntityEditorComponent
          }
        ]
      },
      {
        path: 'workflow-builder/:workflowId/:versionId/:applicationId',
        component: WorkflowBuilderComponent,
        children: [
          {
            path: '',
            component: WorkflowDiagramComponent
          },
          {
            path: 'activity-editor/:activityId/:activityType',
            component: ActivityEditorComponent
          },
          {
            path: 'data-entity-editor/add',
            component: DataEntityEditorComponent
          },
          {
            path: 'data-entity-editor/:dataEntityId',
            component: DataEntityEditorComponent
          }
        ]
      },
      {
        path: 'global',
        component: GlobalAdminComponent,
        children: [
          {
            path: 'unauthorized',
            component: UnauthorizedComponent
          },
          {
            path: 'jurisdictions',
            component: ClientEditorComponent,
            children: [
              {
                path: 'list',
                component: ClientListViewComponent,
                canActivate: [
                  (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
                    inject(ClientAdminGuard).canActivate(next, state)
                ]
              },
              {
                path: '',
                redirectTo: 'list',
                pathMatch: 'full'
              },
              {
                path: 'add',
                component: ClientDetailViewComponent
              }
            ]
          },
          {
            path: 'payment-processors',
            component: PaymentProcessorEditorComponent,
            children: [
              {
                path: 'list',
                component: PaymentProcessorListViewComponent
              },
              {
                path: 'edit/:processorId/:type',
                component: PaymentProcessorDetailViewComponent
              },
              {
                path: '',
                redirectTo: 'list',
                pathMatch: 'full'
              }
            ]
          },
          {
            path: 'users',
            component: UserEditorComponent,
            children: [
              {
                path: 'list',
                component: GlobalUserListViewComponent
              },
              {
                path: 'edit/:userId',
                component: UserDetailViewComponent
              },
              {
                path: '',
                redirectTo: 'list',
                pathMatch: 'full'
              }
            ]
          },
          {
            path: 'roles',
            component: RoleEditorComponent,
            children: [
              {
                path: 'list',
                component: RoleListViewComponent,
                data: {
                  loadGlobal: true
                }
              },
              {
                path: 'edit/:id',
                component: RoleDetailViewComponent
              },
              {
                path: 'add',
                component: RoleDetailViewComponent
              },
              {
                path: '',
                redirectTo: 'list',
                pathMatch: 'full'
              }
            ]
          },
          {
            path: 'workflows',
            children: [
              {
                path: 'list',
                component: WorkflowListViewComponent,
                data: {
                  loadGlobal: true
                }
              },
              {
                path: 'edit/:workflowId',
                component: WorkflowSettingsEditorComponent
              },
              {
                path: 'add',
                component: WorkflowSettingsEditorComponent
              },
              {
                path: '',
                redirectTo: 'list',
                pathMatch: 'full'
              }
            ]
          },
          {
            path: 'contractors',
            children: [
              {
                path: 'documentCategories',
                component: ContractorDocumentCategoryEditorComponent
              }
            ]
          },
          {
            path: 'testing/runSchedulingEngine',
            component: SchedlingEngineTesterComponent,
            canActivate: [ActionGuard],
            data: {
              action: Actions.DO_ANYTHING
            }
          },
          {
            path: 'testing/autoVoidProcessRunner',
            component: AutoVoidTesterComponent,
            canActivate: [ActionGuard],
            data: {
              action: Actions.DO_ANYTHING
            }
          },
          {
            path: '',
            redirectTo: 'jurisdictions',
            pathMatch: 'full'
          }
        ]
      },
      {
        path: '',
        redirectTo: 'global',
        pathMatch: 'full'
      }
    ]
  },

  {
    path: 'application/workflow-application/:applicationId/:activityDataId',
    component: AppViewComponent,
    canActivate: [
      (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
        inject(CompletedApplicationGuard).canActivate(next, state)
    ]
  },
  {
    path: 'application/workflow-application/:applicationId',
    component: AppViewComponent,
    canActivate: [
      (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
        inject(CompletedApplicationGuard).canActivate(next, state)
    ]
  },
  {
    path: 'application/workflow-application/read-only/:applicationId/:isTest',
    component: ApplicationReadonlyComponent
  },
  {
    path: 'application/workflow-application/read-only/:applicationId/',
    component: ApplicationReadonlyComponent
  },
  {
    path: 'application/workflow-application-summary/:applicationId/:isTest',
    component: ApplicationSummaryComponent
  },
  {
    path: 'application/workflow-application-summary/:applicationId',
    component: ApplicationSummaryComponent
  },
  {
    path: 'application/workflow-application-print-summary/:applicationId',
    component: ApplicationSummaryComponent,
    data: {
      printMode: true
    }
  },
  {
    path: 'payment-forms',
    component: PaymentFormComponent,
    children: [
      {
        path: 'nmi/payment-form/:paymentRequestId',
        component: NmiPaymentFormComponent
      },
      {
        path: 'cardpointe/payment-form/:paymentRequestId',
        component: CardpointePaymentFormComponent
      }
    ]
  },
  {
    path: 'auth',
    children: [
      {
        path: 'account',
        component: UserProfileViewComponent,
        data: {
          title: 'User Profile'
        }
      },
      {
        path: 'register',
        component: RegisterViewComponent,
        data: {
          title: 'Register User'
        }
      },
      {
        path: 'register/:returnApplicationId',
        component: RegisterViewComponent,
        data: {
          title: 'Register User'
        }
      },
      {
        path: 'confirm',
        component: ConfirmEmailViewComponent,
        data: {
          title: 'Confirm Registration'
        }
      },
      {
        path: 'login',
        component: LoginViewComponent,
        data: {
          title: 'Login'
        }
      },
      {
        path: 'reset',
        component: ResetPasswordViewComponent,
        data: {
          title: 'Reset Password'
        }
      },
      {
        path: 'contractor-profile/:contractorId',
        component: ContractorProfileViewComponent,
        data: {
          title: 'Contrctor Profile',
          global: true
        }
      },
      {
        path: 'contractor-profile',
        component: ContractorProfileViewComponent,
        data: {
          title: 'Contractor Profile',
          global: true
        }
      },
      {
        path: 'registration-details/:registrationId',
        component: UserContractorRegistrationDetailsComponent,
        data: {
          title: 'Registration Details',
          global: true
        }
      },
      {
        path: 'renewable-registration-details/:registrationId',
        component: UserRenewableRegistrationDetailsComponent,
        data: {
          title: 'Renewable Registration Details',
          global: true
        }
      }
    ]
  },
  {
    path: 'jurisdiction/:clientId',
    component: ClientViewComponent,
    data: {
      title: 'Available Applications'
    }
  },
  {
    path: 'applications',
    component: UserApplicationsComponent,
    canActivate: [
      (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
        inject(AuthGuard).canActivate(next, state)
    ],
    data: {
      title: 'My Dashboard'
    }
  },
  {
    path: '**',
    component: NotFoundComponent
  }
];
export function init_app(appLoadService: AppLoadService) {
  return () => appLoadService.initializeApp();
}

@NgModule({
  declarations: [
    DocumentDataEntityComponent,
    InspectionDetailsComponent,
    UploadComponent,
    AppComponent,
    ClientEditorComponent,
    AdminViewComponent,
    LandingViewComponent,
    AppViewComponent,
    ContactEditorComponent,
    ContractorEditorComponent,
    ContractorRegistrationEditorComponent,
    ContractorRequirementTypeEditorComponent,
    ExternalDataConnectionsEditorComponent,
    RoleEditorComponent,
    ClientListViewComponent,
    MapServicesEditorComponent,
    UnauthorizedComponent,
    UserEditorComponent,
    ClientSearchComponent,
    ClientSelectComponent,
    ClientDetailViewComponent,
    PaymentConfigComponent,
    WorkflowListComponent,
    LoginComponent,
    ControlMessageComponent,
    ModalConfirmComponent,
    ClientListComponent,
    MapServiceListViewComponent,
    MapServiceDetailViewComponent,
    MapServiceListComponent,
    MapServiceSelectComponent,
    ExternalDataConnectionsListViewComponent,
    ExternalDataConnectionsDetailViewComponent,
    ExternalDataConnectionsSearchComponent,
    ExternalDataConnectionsListComponent,
    ParcelDataSearchComponent,
    UserListViewComponent,
    UserDetailViewComponent,
    ProcessingStatusComponent,
    UserSearchComponent,
    UserListComponent,
    AssociateUserComponent,
    PermissionListComponent,
    AdvancedPermissionsEditorComponent,
    RegisterViewComponent,
    RegisterComponent,
    UserProfileViewComponent,
    ResetPasswordViewComponent,
    VerifyUserViewComponent,
    WorkflowSettingsEditorComponent,
    WorkflowDiagramComponent,
    WorkflowDataEntityListComponent,
    WorkflowDataEntitySelectComponent,
    WorkflowCheckpointListComponent,
    UserWorkflowListComponent,
    CommunityWorkflowListComponent,
    ArchiveWorkflowComponent,
    ActivityEditorComponent,
    CompleteTimeoutActivityEditorComponent,
    CreateUserActivityEditorComponent,
    EmailActivityEditorComponent,
    ScheduleEmailActivityEditorComponent,
    FormActivityEditorComponent,
    MilestoneActivityEditorComponent,
    PrintTemplateActivityEditorComponent,
    QueryMapLayerActivityEditorComponent,
    ScheduleActivityEditorComponent,
    ScreenTemplateActivityEditorComponent,
    SearchExternalDataActivityEditorComponent,
    SearchParcelDataActivityEditorComponent,
    StartTimeoutActivityEditorComponent,
    ActivityContainerComponent,
    ActivityViewComponent,
    UserMenuComponent,
    WorkflowListViewComponent,
    RoleListViewComponent,
    RoleDetailViewComponent,
    RoleListComponent,
    RoleListByClientComponent,
    RoleMemberListComponent,
    AssociateRoleComponent,
    AddressDetailComponent,
    AddressListComponent,
    EmailAddressDetailComponent,
    MapServerEditorComponent,
    MapServerListViewComponent,
    MapServerDetailViewComponent,
    MapServerListComponent,
    MapServerSelectComponent,
    PaymentProcessorEditorComponent,
    PaymentProcessorDetailViewComponent,
    PaymentProcessorListViewComponent,
    PaymentAccountEditorComponent,
    PaymentAccountDetailViewComponent,
    PaymentAccountListViewComponent,
    PaymentProcessorListComponent,
    PaymentAccountListComponent,
    PaymentProcessorConfigBaseComponent,
    PaymentProcessorConfigPayGovComponent,
    PaymentProcessorConfigVpsComponent,
    PaymentProcessorConfigPointnpayComponent,
    PaymentProcessorConfigCardpointeComponent,
    PaymentAccountConfigBaseComponent,
    PaymentAccountConfigPaygovComponent,
    PaymentProcessorSelectComponent,
    PaymentAccountConfigVpsComponent,
    PaymentAccountConfigSecurepayComponent,
    PaymentAccountConfigCardpointeComponent,
    PaymentAccountConfigOfficialpaymentsComponent,
    ContactsEditorComponent,
    ContactListViewComponent,
    ContactDetailViewComponent,
    ContactsListComponent,
    FreeFormTextDataEntityComponent,
    DataEntityEditorComponent,
    DataEntityEditorBaseComponent,
    DataEntitySelectComponent,
    DataEntityOrderListComponent,
    ActivityEditorBaseComponent,
    ApplicationInputComponent,
    ApplicationReadonlyComponent,
    FormActivityInputComponent,
    WorkflowListViewComponent,
    FormActivityInputLayoutSingleColumnComponent,
    FormActivityInputLayoutTwoColumnComponent,
    FormActivityInputLayoutBaseComponent,
    FreeFormTextDataEntityInputComponent,
    DataEntityInputComponent,
    DataEntityInputListComponent,
    CompleteWorkflowActivityEditorComponent,
    CompleteWorkflowActivityInputComponent,
    OtherFormatEditorComponent,
    DecisionActivityEditorComponent,
    EmailAddressComponent,
    NumericDataEntityComponent,
    NumericDataEntityInputComponent,
    MapsketchDataEntityEditorComponent,
    MapsketchDataEntityInputComponent,
    MapsketchDataEntityViewComponent,
    EsriMapComponent,
    FormActivityInputViewComponent,
    UserWorkflowApplicationListComponent,
    WorkflowApplicationListComponent,
    DocumentDataEntityInputComponent,
    FormActivityViewComponent,
    DataEntityViewComponent,
    DataEntityViewListComponent,
    FreeFormTextDataEntityViewComponent,
    NumericDataEntityViewComponent,
    FeeDataEntityEditorComponent,
    FeeDataEntityInputComponent,
    FeeDataEntityViewComponent,
    DataEntityAutocompleteComponent,
    SearchParcelDataActivityInputComponent,
    SearchParcelDataActivityViewComponent,
    DocumentDataEntityViewComponent,
    PaymentActivityInputComponent,
    PaymentActivityViewComponent,
    PaymentActivityEditorComponent,
    PrintTemplateActivityInputComponent,
    PrintTemplateActivityViewComponent,
    PaymentActivityEditorComponent,
    PaymentActivityEditorComponent,
    ListDataEntityEditorComponent,
    ListDataEntityInputComponent,
    ListDataEntityViewComponent,
    PaymentTransactionsComponent,
    PaymentRequestCriteriaComponent,
    WaitActivityEditorComponent,
    WaitActivityInputComponent,
    WaitActivityViewComponent,
    YesNoDataEntityEditorComponent,
    YesNoDataEntityInputComponent,
    YesNoDataEntityViewComponent,
    StartWorkflowActivityEditorComponent,
    StartWorkflowActivityInputComponent,
    StartWorkflowActivityViewComponent,
    WorkflowApplicationNavigationComponent,
    WorkflowApplicationFeeSummaryComponent,
    NavbarComponent,
    LoaderComponent,
    LoginViewComponent,
    ClientViewComponent,
    WorkflowItemComponent,
    UserApplicationsComponent,
    KeysPipe,
    ApplicationSummaryComponent,
    PhonePipe,
    ClientAdminComponent,
    GlobalAdminComponent,
    WorkflowBuilderComponent,
    WorkflowActivitySidebarComponent,
    ActivityEditorHostDirective,
    ActivityDetailsModalComponent,
    DataEntityItemComponent,
    TodayDataEntityEditorComponent,
    TodayDataEntityInputComponent,
    TodayDataEntityViewComponent,
    SignatureDataEntityEditorComponent,
    SignatureDataEntityInputComponent,
    SignatureDataEntityViewComponent,
    SignatureFieldComponent,
    WorkflowDetailsViewComponent,
    DisplayEntityValueDataEntityEditorComponent,
    DisplayEntityValueDataEntityViewComponent,
    DisplayEntityValueDataEntityInputComponent,
    WorkflowExportComponent,
    MapDisplayConfigurationEditorComponent,
    ContractorTypeListViewComponent,
    ContractorTypeListComponent,
    ContractorTypeDetailsViewComponent,
    ContractorTypeEditorViewComponent,
    ContractorSearchComponent,
    ContractorListComponent,
    ContractorRegistrationDecisionActivityEditorComponent,
    ContractorRegistrationDecisionActivityInputComponent,
    ContractorRegistrationDecisionActivityViewComponent,
    InspectionRequestActivityEditorComponent,
    InspectionRequestActivityInputComponent,
    InspectionRequestActivityViewComponent,
    ContractorInfoFormActivityEditorComponent,
    ContractorInfoFormActivityInputComponent,
    ContractorInfoFormActivityViewComponent,
    ContractorRegistrationInfoFormActivityEditorComponent,
    ContractorRegistrationInfoFormActivityInputComponent,
    ContractorRegistrationInfoFormActivityViewComponent,
    ContractorDocumentDataEntityEditorComponent,
    ContractorDocumentDataEntityInputComponent,
    ContractorDocumentDataEntityViewComponent,
    ContractorDocumentCategoryEditorComponent,
    InspectionDocumentDataEntityEditorComponent,
    InspectionDocumentDataEntityInputComponent,
    InspectionDocumentDataEntityViewComponent,

    GenerateClientApplicationNumberDataEntityInputComponent,
    GenerateClientApplicationNumberDataEntityViewComponent,
    GenerateClientApplicationNumberDataEntityEditorComponent,
    DateDataEntityEditorComponent,
    DateDataEntityInputComponent,
    DateDataEntityViewComponent,
    ConfirmEmailViewComponent,
    QrCodeComponent,
    ContactListDataEntityEditorComponent,
    ContactListDataEntityInputComponent,
    ContactListDataEntityViewComponent,
    CalculateValueDataEntityEditorComponent,
    CalculateValueDataEntityInputComponent,
    CalculateValueDataEntityViewComponent,
    InspectionsQueueComponent,
    InspectionsQueueMapComponent,
    InspectionScheduleActivityEditorComponent,
    InspectionScheduleActivityInputComponent,
    InspectionScheduleActivityViewComponent,
    ContractorDetailViewComponent,
    ReviewContractorRegistrationActivityEditorComponent,
    ReviewContractorRegistrationActivityInputComponent,
    ReviewContractorRegistrationActivityViewComponent,
    CompleteInspectionActivityEditorComponent,
    CompleteInspectionActivityInputComponent,
    CompleteInspectionActivityViewComponent,
    EsriMapDesigntimeComponent,
    MapActivityEditorComponent,
    MapActivityInputComponent,
    MapActivityViewComponent,
    ContractorDetailViewComponent,
    RequiredUserActivityEditorComponent,
    RequiredUserActivityInputComponent,
    RequiredUserActivityViewComponent,
    WorkflowValidityModalComponent,
    ContractorSelectionActivityEditorComponent,
    ContractorSelectionActivityInputComponent,
    ContractorSelectionActivityViewComponent,
    GlobalUserListViewComponent,
    ContractorExpireRegistrationsComponent,
    ContractorRenewalDecisionActivityEditorComponent,
    ContractorRenewalDecisionActivityInputComponent,
    ContractorRenewalDecisionActivityViewComponent,
    ContractorRegistrationListComponent,
    ContractorProfileComponent,
    ContractorProfileViewComponent,
    ContractorDocumentListComponent,
    ContractorCustomFieldListComponent,
    GlobalUserListViewComponent,
    RequiredUserActivityEditorComponent,
    RequiredUserActivityInputComponent,
    RequiredUserActivityViewComponent,
    ContractorRegistrationDetailsComponent,
    EmailActivityViewComponent,
    ScheduleEmailActivityViewComponent,
    WorkflowActivityPreviewModalComponent,
    PaymentAccountConfigPaymentStubComponent,
    ContractorRenewalDecisionActivityViewComponent,
    AssignedInspectionsViewComponent,
    AssignedInspectionsComponent,
    InspectionQueueComponent,
    MyInspectionQueueScheduleComponent,
    MyInspectionQueueCompleteComponent,
    UpcomingInspectionToCompleteComponent,
    InspectionRequestedQueueComponent,
    WorkflowActivityPreviewModalComponent,
    RichTextDataEntityInputComponent,
    RichTextDataEntityViewComponent,
    RichTextDataEntityEditorComponent,
    InpectionDocumentListComponent,
    WorkflowApplicationSearchViewComponent,
    WorkflowApplicationListViewComponent,
    CallWorkflowEditorComponent,
    CallWorkflowInputComponent,
    CallWorkflowViewComponent,
    InitiateWorkflowEditorComponent,
    InitiateWorkflowInputComponent,
    InitiateWorkflowViewComponent,
    WorkflowApplicationsNeedingMyAttentionComponent,
    WorkflowApplicationsPendingPaymentsComponent,
    FinancialComponent,
    ContractorsReportComponent,
    PermitsReportComponent,
    FeeRecipientsComponent,
    MetricComponent,
    ContractorSummaryComponent,
    FilterBuilderComponent,
    DailyFeesComponent,
    FeeSummaryComponent,
    PermitsSummaryComponent,
    ItemTagListComponent,
    TagEditorComponent,
    UserInfoComponent,
    ConditionGroupComponent,
    ConditionModalComponent,
    DataEntityFormulaComponent,
    ConditionBuilderComponent,
    SystemDataEntityInputComponent,
    ContractorRequirementEditorComponent,
    ListItemEditorComponent,
    HelpTextComponent,
    DeLabelComponent,
    DatatableComponent,
    ValidationMessageComponent,
    WorkflowValidationItemComponent,
    WorkflowListSectionComponent,
    BasicEsriMapComponent,
    NumberFormatDirective,
    SystemDataEntityViewComponent,
    CalcOnChangeDataEntityComponent,
    AppointmentActivityEditorComponent,
    AppointmentActivityViewComponent,
    ApplicationPrintSummaryComponent,
    ApplicationExternalNoteEditorComponent,
    ApplicationInternalNoteEditorComponent,
    NmiPaymentFormComponent,
    PaymentAccountConfigNmiComponent,
    NotFoundComponent,
    ListEditorComponent,
    CustomListEditorComponent,
    CustomListDetailViewComponent,
    CustomListListViewComponent,
    CustomListsListComponent,
    GenerateApplicationNumberActivityEditorComponent,
    GenerateApplicationNumberActivityInputComponent,
    GenerateApplicationNumberActivityViewComponent,
    SavedFilterComponent,
    PaymentFormComponent,
    ExternalDataSourceConfigComponent,
    PaymentAccountConfigPsnComponent,
    PaymentProcessorConfigPsnComponent,
    PaymentAccountConfigForteComponent,
    PaymentProcessorConfigForteComponent,
    CardpointePaymentFormComponent,
    PaymentAccountConfigGovPayComponent,
    ExportDataActivityEditorComponent,
    ExportDataActivityInputComponent,
    ExportDataActivityViewComponent,
    CsvConfigurationEditorComponent,
    SftpConfigurationEditorComponent,
    PaymentAccountConfigPointnpayComponent,
    PaymentProcessorConfigSecurepayComponent,
    WorkflowShareApplicationComponent,
    ApplicationInternalNoteEditorComponent,
    WorkflowPreviousSystemListComponent,
    WorkflowApplicationPreviousSystemListViewComponent,
    PaymentAccountConfigKanpayComponent,
    PaymentProcessorConfigKanpayComponent,
    InspectionSimpleActivityEditorComponent,
    InspectionSimpleActivityInputComponent,
    InspectionSimpleActivityViewComponent,
    WorkFlowPreviousSytemImportComponent,
    YDataEntityViewComponent,
    XDataEntityViewComponent,
    GeoJSONDataEntityViewComponent,
    RenewalListComponent,
    WorkflowRenewalListComponent,
    RenewalDetailsComponent,
    RenewalCustomFieldListComponent,
    RenewalDocumentListComponent,
    RequirementEditorComponent,
    RenewalDecisionActivityInputComponent,
    RenewalDecisionActivityViewComponent,
    RenewalDecisionActivityEditorComponent,
    RenewalReviewActivityViewComponent,
    RenewalReviewActivityEditorComponent,
    RenewalReviewActivityInputComponent,
    ExpiringRenewalsComponent,
    RegistrationListSectionComponent,
    RenewalItemComponent,
    ContractorRegistrationItemComponent,
    PaymentProcessorConfigMunicipayComponent,
    PaymentAccountConfigMunicipayComponent,
    PaymentProcessorConfigSquareComponent,
    PaymentAccountConfigSquareComponent,
    AgendaTypeListComponent,
    AgendaListComponent,
    AgendaSettingsEditorComponent,
    AgendaViewComponent,
    AgendaQueueItemInputComponent,
    AgendaQueueItemReadonlyComponent,
    AgendaItemActivityInputComponent,
    AgendaItemActivityEditorComponent,
    AgendaItemActivityViewComponent,
    PaymentAccountConfigSquareComponent,
    CustomReportListComponent,
    CustomReportInputComponent,
    PaymentProcessorConfigeGOVComponent,
    PaymentAccountConfigeGOVComponent,
    PaymentProcessorConfigCertifiedpaymentsComponent,
    PaymentAccountConfigCertifiedpaymentsComponent,
    PaymentAccountActivityConfigBaseComponent,
    PaymentAccountActivityConfigPointnpayComponent,
    DecisionActivityViewComponent,
    CancelScheduledEmailActivityEditorComponent,
    ScheduledExportEditorComponent,
    ScheduledExportsComponent,
    ScheduleEditorComponent,
    ExportsViewComponent,
    SchedlingEngineTesterComponent,
    PaymentsComponent,
    MultipleParcelSearchActivityEditorComponent,
    MultipleParcelSearchActivityInputComponent,
    MultipleParcelSearchActivityViewComponent,
    MyApplicationParcelsComponent,
    InviteUserComponent,
    UserContractorRegistrationDetailsComponent,
    InspectionSimpleActivityViewComponent,
    DatasetDesignerComponent,
    DatasetListComponent,
    DatasetInputComponent,
    EditApplicationNumberComponent,
    UserRenewableRegistrationDetailsComponent,
    FormActivityDataEntitiesBulkActionsComponent,
    MoveCustomFieldInfoComponent,
    QueryMapLayerActivityInputComponent,
    QueryMapLayerActivityViewComponent,
    WorkflowApplicationSearchByFeatureViewComponent,
    ReviewActivityEditorComponent,
    ReviewActivityViewComponent,
    ReviewActivityInputComponent,
    AutoVoidTesterComponent,
    WorkflowActionsMenuComponent,
    CustomReportFilterInputComponent,
    CustomReportRequestListComponent
  ],
  imports: [
    CommonModule,
    // FlexLayoutModule,
    HttpClientModule,
    BrowserAnimationsModule,
    BrowserModule,
    HttpClientModule,
    RouterModule.forRoot(routes, {
      anchorScrolling: 'enabled'
    }),
    ReactiveFormsModule,
    FormsModule,
    DragulaModule.forRoot(),
    // NgbTabsetModule,
    NgbTypeaheadModule,
    NgbModalModule,
    NgbDropdownModule,
    NgbAccordionModule,
    NgbNavModule,
    NgbTimepickerModule,
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right',
      maxOpened: 3
    }),
    NgbModule,
    CKEditorModule,
    FontAwesomeModule,
    PipesModule,
    NgxDatatableModule,
    NgMentionsModule,
    AngularSignaturePadModule
  ],
  providers: [
    AppLoadService,
    {
      provide: APP_INITIALIZER,
      useFactory: init_app,
      deps: [AppLoadService],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: environment.globalErrorHandlerClass,
      multi: true
    },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: JsonNetInterceptor, multi: true }, // this one needs to be last HTTP_INTERCEPTORS, as it makes WebApi/Json.net data usable
    { provide: HTTP_INTERCEPTORS, useClass: VersionInterceptor, multi: true },
    { provide: DataService, useClass: environment.dataServiceClass },
    // DataService,
    WorkflowContextService,
    DocumentService,
    { provide: SecurityService, useClass: environment.securityServiceClass },
    { provide: ClientService, useClass: environment.clientServiceClass },
    { provide: MapServiceProvider, useClass: environment.mapServiceClass },
    {
      provide: SearchParcelDataProvider,
      useClass: environment.searchParcelDataClass
    },
    {
      provide: ContractorService,
      useClass: environment.contractorServiceClass
    },
    { provide: SystemService, useClass: environment.systemServiceClass },
    {
      provide: NotificationService,
      useClass: environment.notificationServiceClass
    },
    { provide: DocumentService, useClass: environment.documentServiceClass },
    { provide: CalendarService, useClass: environment.calendarServiceClass },
    { provide: TemplateService, useClass: environment.templateServiceClass },
    { provide: WorkflowService, useClass: environment.workflowServiceClass },
    ReportService,
    {
      provide: AuthenticationService,
      useClass: environment.authenticationServiceClass
    },
    PaymentProcessorConfigurationResolver,
    EsriLoaderService,
    MapImageLayerService,
    UploadService,
    PreviousRouteService,
    FilterService,
    DataTableHelpers,
    { provide: ErrorHandler, useClass: environment.globalErrorHandlerClass },
    GPErrorHandler
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule {}

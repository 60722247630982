import { DatePipe, CurrencyPipe } from '@angular/common';
import { Injectable } from '@angular/core';

@Injectable()
export class DataTableHelpers {
  public getDatePipe(format = 'MM/dd/yyy') {
    return {
      transform: (value: any) => {
        if (!value) {
          return 'N/A';
        }
        const datePipe = new DatePipe('en-US');
        return datePipe.transform(value, format);
      }
    };
  }

  public getDatePipeWithTime(format = 'short') {
    return {
      transform: (value: any) => {
        if (!value) {
          return 'N/A';
        }
        const datePipe = new DatePipe('en-US');
        return datePipe.transform(value, format);
      }
    };
  }

  public getCurrencyPipe(currencyCode = 'USD') {
    return {
      transform: (value: any) => {
        const currencyPipe = new CurrencyPipe('en-US');
        return currencyPipe.transform(value, currencyCode);
      }
    };
  }
}

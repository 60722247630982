import {
  Component,
  OnInit,
  Inject,
  forwardRef,
  ComponentFactoryResolver
} from '@angular/core';
import { DataEntityInputComponent } from '../../data-entity-input/data-entity-input.component';
import {
  DocumentService,
  WorkflowContextService
} from '../../../../../services';
import { DocumentDataEntityInputComponent } from '../../document-data-entity/document-data-entity-input/document-data-entity-input.component';
import { UntypedFormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'wm-contractor-document-data-entity-input',
  templateUrl: './contractor-document-data-entity-input.component.html',
  styleUrls: ['./contractor-document-data-entity-input.component.css']
})
export class ContractorDocumentDataEntityInputComponent
  extends DocumentDataEntityInputComponent
  implements OnInit {
  constructor(
    componentFactory: ComponentFactoryResolver,
    @Inject(forwardRef(() => DocumentService)) uploadService: DocumentService,
    @Inject(forwardRef(() => WorkflowContextService))
    context: WorkflowContextService,
    fb: UntypedFormBuilder,
    _toastrSvc: ToastrService
  ) {
    super(componentFactory, uploadService, context, fb, _toastrSvc);
  }

  ngOnInit() {}
}

import { ContainerActivity, ActivityModel } from '.';

export class OnStartedActivity extends ContainerActivity<ActivityModel> {
  constructor(options?: Partial<OnStartedActivity>) {
    super(options);

    this.type = 'on-started-activity';
    this.activities = [];
    this.name = 'On Started Steps';
    this.description =
      'Use this activity to define a set of activities to run on start & in progress .';
    this.isContainer = true;

    if (options) {
      Object.assign(this, options);
    }
  }
}

import { ConditionTarget } from './../../components/system/condition-builder/condition-builder.model';
import {
  Activity,
  ScreenActivity,
  ActivityModel
} from 'src/app/models/activities/activity';

export class GenerateApplicationNumberActivityModel extends ActivityModel {
  numberPrefixCriteria: ConditionTarget[];

  constructor(options?: Partial<GenerateApplicationNumberActivityModel>) {
    super(options);

    if (options) {
      Object.assign(this, options);
    }

    if (!this.numberPrefixCriteria) {
      this.numberPrefixCriteria = [];
    }
  }
}

export class GenerateApplicationNumberActivity<
  T extends GenerateApplicationNumberActivityModel = GenerateApplicationNumberActivityModel
> extends ScreenActivity<GenerateApplicationNumberActivityModel> {
  constructor(options?: Partial<GenerateApplicationNumberActivity<T>>) {
    super(options);

    this.model = new GenerateApplicationNumberActivityModel();
    if (options) {
      Object.assign(this, options);
    }

    this.model = new GenerateApplicationNumberActivityModel(
      options ? options.model : null
    );

    this.model.screenName =
      this.model.screenName || 'Generate Application Number';
  }

  public displayOnScreen(): boolean {
    return false;
  }
}

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CustomReportRequestItem } from 'src/app/models/custom-report-request';
import { DataService } from 'src/app/services';

@Component({
  selector: 'wm-queued-custom-report-list',
  templateUrl: './custom-report-request-list.component.html',
  styleUrl: './custom-report-request-list.component.css'
})
export class CustomReportRequestListComponent implements OnInit, OnDestroy {
  @Input() customReportId: string;
  @Input() userId: string;
  queuedReports: CustomReportRequestItem[];
  runningReports: { [key: string]: CustomReportRequestItem };
  intervalSub = null;
  constructor(private _dataSvc: DataService) {}

  ngOnDestroy(): void {
    if(this.intervalSub) {
      clearInterval(this.intervalSub);
    }
  }

  ngOnInit(): void {
    this.refresh();
  }

  refresh() {
    this._dataSvc
      .getCustomReportRequests(this.customReportId, this.userId)
      .subscribe(reports => {
        this.queuedReports = reports;

        this.runningReports = {};

        // update the running reports data
        reports
          .filter(r => r.status == 'Running' || r.status == 'Requested')
          .forEach(r => {
            this.runningReports[r.id] = r;
          });

        if (this.intervalSub) {
          clearInterval(this.intervalSub);
        }

        this.intervalSub = setInterval(() => {
          this.updateStatus();
        }, 3000);
      });
  }

  updateStatus() {
    if (this.runningReports) {
      const keys = Object.keys(this.runningReports);
      if (keys.length > 0) {
        const requestIds = {};

        keys.forEach(k => {
          requestIds[k] = this.runningReports[k].status;
        });

        this._dataSvc
          .getCustomReportRequestDetails(requestIds)
          .subscribe((reportDetails: CustomReportRequestItem[]) => {
            if (reportDetails) {
              reportDetails.forEach(rd => {
                if (this.runningReports[rd.id]) {
                  this.runningReports[rd.id].status = rd.status;
                  this.runningReports[rd.id].documentPath = rd.documentPath;
                  this.runningReports[rd.id].completedOnString = rd.completedOnString;
                  if (rd.status == 'Failed' || rd.status == 'Complete') {
                    delete this.runningReports[rd.id];
                  }
                }
              });
            }
          });
      }
    }
  }
}

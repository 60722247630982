<app-navbar></app-navbar>

<div style="padding-top: 75px;" *ngIf="!application">
  <app-loader></app-loader>
</div>

<div class="summary-content" *ngIf="application">
  <div class="application-header">
    <div class="container">
      <div class="row">
        <div class="col-sm-6">
          <div class="row">
            <div class="col-sm-4">
              <span>{{ application.name }}</span>
              <h6>{{ application.combinedApplicationNumber }}</h6>
            </div>
            <div class="col-sm-4">
              <span>Amount Paid</span>
              <h6>{{ totalPaid | currency }}</h6>
            </div>
            <!-- TODO: Removed for now until we have Actions implemented -->
            <div class="col-sm-4">
              <div
                ngbDropdown
                class="d-inline-block float-right"
                *ngIf="!printMode"
              >
                <button
                  class="btn btn-raised btn-secondary"
                  id="dropdownBasic1"
                  ngbDropdownToggle
                >
                  Actions
                </button>
                <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                  <!-- <button ngbDropdownItem>Continue application</button> -->
                  <a
                    *ngFor="let pr of paymentRequests; let i = index"
                    ngbDropdownItem
                    [href]="apiBase + '/files/receipt/' + pr.id"
                    target="_blank"
                    rel="noopener"
                  >
                    View receipt
                    <span *ngIf="paymentRequests.length > 1">#{{ i + 1 }}</span>
                  </a>
                  <a *ngIf="canUnVoid()" ngbDropdownItem (click)="unVoid()">
                    Unvoid
                  </a>
                  <a
                    *ngIf="isChildApplication"
                    ngbDropdownItem
                    (click)="returnToParent()"
                  >
                    Return to Parent
                  </a>
                  <a
                    ngbDropdownItem
                    [routerLink]="[
                      '/application/workflow-application-print-summary',
                      applicationId
                    ]"
                    target="_blank"
                    >Printer Friendly</a
                  >
                  <a
                    *ngIf="
                      registrationId &&
                      applicationStatus === ApplicationStatus.Completed &&
                      applicationWorkflowType === WorkflowType.Renewable
                    "
                    ngbDropdownItem
                    (click)="startRenewalApplication()"
                  >
                    Renew
                  </a>
                  <a
                    ngbDropdownItem
                    (click)="openApplicationWorkflowBuilder()"
                    *ngIf="canViewVersion"
                    >View Application Workflow</a
                  >
                  <a
                    ngbDropdownItem
                    (click)="recalculateApplicationDescription()"
                    *ngIf="canEditCompletedApplication"
                    >Recalculate Application Description</a
                  >
                  <a
                    *ngIf="showUnDiscardButton && canEditCompletedApplication"
                    ngbDropdownItem
                    (click)="undiscardApplication()"
                    >Un-Discard Application</a
                  >
                  <a
                    ngbDropdownItem
                    *ngIf="canEditApplicationNumber"
                    (click)="editApplicationNumber()"
                  >
                    Edit Application Number
                    <wm-edit-application-number
                      #editAppNumber
                      [form]="form"
                      [showButton]="false"
                      (numberChanged)="
                        application.combinedApplicationNumber = $event
                      "
                      [clientApplicationNumber]="
                        application.clientApplicationNumber
                      "
                      [applicationId]="application.id"
                      [applicationNumber]="
                        application.combinedApplicationNumber
                      "
                    ></wm-edit-application-number>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-4" *ngIf="submitter">
              <span>Applicant</span>
              <h6>{{ submitter.name }}</h6>
            </div>
            <div class="col-sm-4">
              <span>Created</span>
              <h6>{{ application.startedOn | date: 'longDate' }}</h6>
            </div>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="card">
            <div class="card-body">
              <div class="row align-items-center text-center">
                <div class="col-3" style="border-right: 1px solid black;">
                  <span>Number</span>
                  <h6>{{ application.combinedApplicationNumber }}</h6>
                </div>
                <div class="col-6">
                  <span class="font-weight-bold">{{
                    application.description
                  }}</span>
                  <br />
                  <span class="text-muted"
                    >Submitted
                    <span class="text-muted" *ngIf="submitter">
                      by {{ submitter.userName }}
                    </span>
                    on {{ application.startedOn | date: 'M/d/yyyy' }}
                  </span>
                </div>
                <div class="col-3" style="border-left: 1px solid black;">
                  <wm-qr-code [data]="applicationUrl"></wm-qr-code>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="row mt-4">
      <div class="col" *ngIf="submitter">
        <h4>Applicant</h4>
        <p>{{ submitter.name }}</p>
        <p>
          <a href="tel:{{ submitter.phoneNumber }}">
            {{ submitter.phoneNumber }}
          </a>
        </p>
        <p>
          <a href="mailto:{{ submitter.emailAddress }}">{{
            submitter.emailAddress
          }}</a>
        </p>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col">
        <wm-application-readonly
          *ngIf="summaryActivities && !printMode"
          [applicationId]="application.id"
          [application]="application"
          [activities]="summaryActivities"
        >
        </wm-application-readonly>
        <wm-application-print-summary
          *ngIf="summaryActivities && printMode"
          [activities]="summaryActivities"
          [applicationId]="application.id"
          [application]="application"
        ></wm-application-print-summary>
      </div>
    </div>
  </div>
</div>
<nav
  class="navbar fixed-bottom navbar-expand-lg navbar-light bg-white"
  *ngIf="isTestApplication"
>
  <div class="container-fluid">
    <form class="form-inline ml-auto mr-auto">
      <button
        class="btn btn-raised mx-2 btn-danger"
        type="button"
        (click)="exitTestApplication()"
      >
        Exit Test Application
      </button>
    </form>
  </div>
</nav>

<wm-modal-confirm
  #RecalculateDescription
  [showButtonText]="true"
  [showButton]="false"
  [buttonText]="'blah'"
  commandText="Yes"
  title="Update Application Description"
  (accepted)="recalculateApplicationDescription(true)"
  btnColor="danger"
  class="mx-2"
  [closeOnAccept]="false"
>
  Are you sure you want to recalculate the Application Description?
</wm-modal-confirm>

<wm-modal-confirm
  #UndiscardApplication
  [showButtonText]="true"
  [showButton]="false"
  [buttonText]="''"
  commandText="Yes"
  title="Set Application To In Progress"
  (accepted)="undiscardApplication(true)"
  btnColor="success"
  class="mx-2"
  [closeOnAccept]="false"
>
  Are you sure you want to set this application to In Progress?
</wm-modal-confirm>

import { UntypedFormBuilder, Validators } from '@angular/forms';
import { PaymentAccountConfigBaseComponent } from './../payment-account-config-base/payment-account-config-base.component';
import { FortePaymentProcessorAccountConfiguration } from './../../../models/payment-providers/forte/forte-payment-processor-account-configuration';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'wm-payment-account-config-forte',
  templateUrl: './payment-account-config-forte.component.html',
  styleUrls: ['./payment-account-config-forte.component.css']
})
export class PaymentAccountConfigForteComponent
  extends PaymentAccountConfigBaseComponent
  implements OnInit {
  constructor(fb: UntypedFormBuilder) {
    super(fb);
    this.showPassword = false;
    this.showUsername = true;
  }

  ngOnInit() {}
}

import { User } from './user';

export interface UserSearchOptions {
  searchText: string;
}

export class LoginResponse {
  constructor(options?: Partial<LoginResponse>) {
    if (options) {
      Object.assign(this, options);
    }
  }

  user: User;
  mustResetPassword: boolean;
  loginValid: boolean;
  errorMessage: string;
  access_Token: string;
  refresh_Token: string;
  expires_in: number;
}

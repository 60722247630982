<wm-email-activity-editor
  [form]="form"
  [activity]="activity"
  [loadFull]="true"
></wm-email-activity-editor>
<form [formGroup]="form">
  <div class="form-row">
    <div class="col">
      <wm-data-entity-autocomplete
        [types]="['date', 'today', 'calculated-value']"
        [form]="form"
        [model]="model.dateDataEntity"
        id="appt_date"
        [activity]="activity"
        [required]="true"
        controlName="appt_date"
        [title]="'Appointment Date Data Entity'"
        [workflow]="workflow"
        placement="left"
        [serverValidator]="workflowSvc.validateEmailFields"
        (valueUpdate)="model.dateDataEntity = $event"
        #appointmentDate
      >
      </wm-data-entity-autocomplete>
    </div>
  </div>
  <div class="form-row">
    <div class="col">
      <wm-data-entity-autocomplete
        [types]="['list-data', 'free-form-text']"
        [form]="form"
        [model]="model.location"
        [activity]="activity"
        id="appt_location"
        [required]="true"
        [title]="'Appointment Location'"
        [workflow]="workflow"
        [serverValidator]="workflowSvc.validateEmailFields"
        (valueUpdate)="model.location = $event"
        placement="left"
        #appointmentLocation
      >
      </wm-data-entity-autocomplete>
    </div>
  </div>
  <div class="form-row">
    <div class="col">
      <div class="switch">
        <label>
          <input
            type="checkbox"
            formControlName="allDay"
            id="allDay"
            [(ngModel)]="model.allDay"
          />
          All Day Event
        </label>
      </div>
    </div>
  </div>
  <div class="form-row" *ngIf="!model.allDay">
    <div class="col">
      <wm-data-entity-autocomplete
        [types]="['numeric-data']"
        [form]="form"
        [model]="model.durationMinutes"
        id="duration_minutes"
        [activity]="activity"
        [required]="!model.allDay"
        [title]="'Duration (minutes)'"
        [workflow]="workflow"
        placement="left"
        [serverValidator]="workflowSvc.validateEmailFields"
        (valueUpdate)="model.durationMinutes = $event"
        #appointmentDuration
      >
      </wm-data-entity-autocomplete>
    </div>
  </div>
</form>

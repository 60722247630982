export class ActivityNavigationInfo {
  activityDataId: string;
  activityId: string;
  activityType: string;
  activityLabel: string;
  activityRole: string;
  activityColor: string;
  applicationId: string;
  completed: boolean;
  isEnd: boolean;
  iteration: number;
  canNavigate: boolean;
}

import {
  ItemSearchOptionField,
  ItemSearchOptionFieldOption
} from 'src/app/components/filter-list/models/filterClasses';
import { Utilities } from 'src/app/services';

export interface FilterBuilderParamOpt {
  name: string;
  value: string;
}

export type FilterBuilderParamOpts = string | FilterBuilderParamOpt;

export type FilterValueTransformFunction = (value: any) => string;

export interface FilterBuilderParam {
  name: string;
  id: string;
  options?: FilterBuilderParamOpts[];
  rangeOptions?: FilterBuilderParamOpt[];
  inputType?: string;
  dataEntityTypeCode?: string;
  types?: string[];
  searchOptionMethod?: string;
  valueTransform?: FilterValueTransformFunction;
}

export interface FilterBuilderFilterFlat {
  id: string;
  paramId: string;
  type?: string;
  queryType: string;
  value?: string;
  customValue?: string;
  inputType?: string;
}

export class FilterBuilderFilter {
  id: string = Utilities.generateId();
  param?: FilterBuilderParam;
  paramId: string;
  type?: string;
  queryType: string;
  value?: string = null;
  customValue?: any = {};
  searchOptionMethod?: string;
  inputType?: string;
  dataEntityTypeCode?: string;

  constructor(opts: Partial<FilterBuilderFilter>) {
    Object.assign(this, opts);

    if (!this.param) {
      throw new Error('There must be a param!');
    }

    if (this.queryType) {
      this.queryType = this.queryType;
    } else if (this.param.types) {
      this.queryType = this.param.types[0];
    } else if (this.param.options) {
      this.queryType = 'is';
    } else {
      this.queryType = 'contains';
    }
  }

  static unflatten(opts: Partial<FilterBuilderFilter>): FilterBuilderFilter {
    if (typeof opts.customValue === 'string') {
      opts.customValue = JSON.parse(opts.customValue);
    }

    return new FilterBuilderFilter(opts);
  }

  flatten(): FilterBuilderFilterFlat {
    return {
      id: this.id,
      paramId: this.paramId,
      type: this.type,
      queryType: this.queryType,
      value: this.value,
      customValue: JSON.stringify(this.customValue),
      inputType: this.inputType
    };
  }
}

export interface Filter {
  id: string;
  type: string;
  inputType?: string;
  dataEntityTypeCode?: string;
  value?: string;
  searchOptionMethod?: string;
}

export interface FilterBuilderOutput {
  [id: string]: Filter;
}

export interface SearchFilterBuilderOutput {
  [id: string]: Filter[];
}

export const translateLegacyToFilterBuilder = (
  filters: ItemSearchOptionField[]
): FilterBuilderOutput => {
  const newFilters: FilterBuilderOutput = {};

  if (filters && filters.length > 0) {
    for (const optionField of filters) {
      if (optionField && optionField.options) {
        for (const optionFieldOption of optionField.options) {
          const type =
            optionFieldOption.filterText !== ''
              ? 'contains'
              : optionFieldOption.strOperator !== ''
              ? optionFieldOption.strOperator
              : 'is';

          newFilters[optionField.title] = {
            id: optionField.title,
            type: type,
            value:
              type == 'contains'
                ? optionFieldOption.filterText
                : optionFieldOption.searchText,
            searchOptionMethod: optionFieldOption.searchOptionMethod
          };
        }
      }
    }
  }

  return newFilters;
};

export const translateFilterBuilderToLegacy = (
  filters: SearchFilterBuilderOutput | FilterBuilderOutput
): ItemSearchOptionField[] => {
  const newFilters: ItemSearchOptionField[] = [];

  for (const i in filters) {
    if (filters[i]) {
      const filterArr = filters[i];
      if ('id' in filterArr) {
        // "we have a FilterBuilder Output"
        let filter: Filter;
        filter = filterArr;
        processOption(filter);
      } else {
        // "we have a SearchFilterBuilderOutput";
        if (filterArr) {
          for (const j in filterArr) {
            if (filterArr.hasOwnProperty(j)) {
              let filter: Filter;
              filter = filterArr[j];
              processOption(filter);
            }
          }
        }
      }
    }
  }

  return newFilters;
  function processOption(filter: Filter) {
    // if there is already an ItemSearchField, then add it as another search option
    const existingFilter = newFilters.filter(x => x.title === filter.id);

    const search =
      existingFilter.length > 0
        ? existingFilter[0]
        : new ItemSearchOptionField({
            title: filter.id,
            options: [
              new ItemSearchOptionFieldOption({
                title: '',
                selected: true,
                inputType: filter.inputType,
                filterText:
                  filter.type === 'contains' ? filter.value || '' : '',
                searchOptionMethod: filter.searchOptionMethod,
                searchText:
                  filter.type === 'is' ||
                  filter.type === 'range' ||
                  filter.type === 'in'
                    ? filter.value || ''
                    : '',
                strOperator:
                  filter.type === 'range' || filter.type === 'in'
                    ? filter.type
                    : ''
              })
            ]
          });

    if (existingFilter.length > 0) {
      search.options.push(
        new ItemSearchOptionFieldOption({
          title: '',
          selected: true,
          inputType: filter.inputType,
          filterText: filter.type === 'contains' ? filter.value || '' : '',
          searchOptionMethod: filter.searchOptionMethod,
          searchText:
            filter.type === 'is' ||
            filter.type === 'range' ||
            filter.type === 'in'
              ? filter.value || ''
              : '',
          strOperator:
            filter.type === 'range' || filter.type === 'in' ? filter.type : ''
        })
      );
    } else {
      newFilters.push(search);
    }
  }
};

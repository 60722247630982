<app-esri-map
  #mapComp
  [mapServiceId]="activity.model.mapConfig.mapServiceId"
  [basemapName]="activity.model.mapConfig.basemapName"
  [initialExtent]="activity.model.mapConfig.initialExtent"
  [mapLayerOptions]="activity.model.mapConfig.layers"
  [showToc]="activity.model.mapConfig.showToc"
  [useSketchLayer]="true"
  [useSketchSingleItemMode]="true"
  (mapSketchDataChanged)="onMapSketchDataChange($event)"
  (mapExtentChanged)="onMapExtentChanged($event)"
  (mapLoaded)="onMapLoaded()"
  toolbarMode="sketch"
></app-esri-map>

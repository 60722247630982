<app-loader *ngIf="!workflow"></app-loader>

<div *ngIf="context && context.client && workflow">
  <div class="row mb-3">
    <div class="col-6">
      <div class="row">
        <div class="col-auto">
          <h3>{{ workflow?.version?.name }}</h3>
          <p>
            {{ workflow?.version?.description }}
          </p>
        </div>

        <div class="col">
          <div class="float-right">
            <div
              class="btn-group float-right  pl-2"
              ngbDropdown
              placement="bottom-left"
            >
              <button
                class="btn btn-raised btn-default"
                [disabled]="!canEditWorkflow"
                [routerLink]="[
                  '/admin/jurisdiction',
                  context.client.id,
                  'workflows',
                  'edit',
                  workflow.id
                ]"
              >
                Settings
              </button>
              <button
                class="btn btn-raised dropdown-toggle-split"
                ngbDropdownToggle
              ></button>
              <div class="dropdown-menu pr-4" ngbDropdownMenu>
                <button ngbDropdownItem (click)="copyStartAppURL()">
                  Start Application URL
                </button>
                <button ngbDropdownItem (click)="copyStartAppWithPIDURL()">
                  Start Application URL With Parcel ID
                </button>
              </div>
            </div>
            <wm-workflow-actions-menu
              *ngIf="workflow"
              [workflow]="workflow"
              [clientId]="clientId"
              [loadGlobal]="loadGlobal"
              (onRestore)="restoreWorkflow($event)"
              (onDisable)="disableWorkflow($event)"
              (onEnable)="enableWorkflow($event)"
              (onPublish)="publishWorkflow($event)"
              (onArchive)="archiveWorkflow($event)"
              (onCopy)="copyWorkflow($event)"
              (toggleVisible)="toggleVisible($event)"
            ></wm-workflow-actions-menu>
          </div>
        </div>
      </div>
    </div>
    <div class="col-6">
      <div class="card">
        <div class="card-body">
          <div class="row" *ngIf="workflow">
            <div class="col-auto">
              <p class="text-muted">Number Prefix</p>
              <h4>{{ getPrefixNumber() }}</h4>
            </div>
            <div class="col-auto">
              <p class="text-muted">Next Number</p>
              <h4>{{ workflow.clientApplicationNumber || '' }}</h4>
            </div>
            <div class="col-auto">
              <p class="text-muted">Public</p>
              <h4>{{ workflow.visibleToPublic ? 'Yes' : 'No' }}</h4>
            </div>
            <div class="col-auto">
              <p class="text-muted">Archived</p>
              <h4>{{ workflow.isArchived ? 'Yes' : 'No' }}</h4>
            </div>
            <div class="col-auto">
              <p class="text-muted">Enabled</p>
              <h4>{{ workflow.isEnabled ? 'Yes' : 'No' }}</h4>
            </div>
            <div class="col-auto">
              <p class="text-muted">Last Published On</p>
              <h4>
                <span *ngIf="workflow.lastPublishedOn">
                  {{ workflow.lastPublishedOn | date: 'MM/dd/y, hh:mm a' }}
                </span>
              </h4>
              <span
                *ngIf="!workflow.lastPublishedOn"
                class="badge badge-pill badge-warning"
              >
                Never published
              </span>
              <span
                *ngIf="
                  workflow.lastPublishedOn &&
                  workflow.areThereChangesNotPublished
                "
                class="badge badge-pill badge-success"
              >
                Changes available
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row mb-3">
    <div class="col">
      <div class="card">
        <div class="card-body">
          <h4>Applications Needing My Attention</h4>
          <wm-workflow-application-list
            [id]="workflowDetailsViewApplicationsNeedingMyAttentionId"
            [workflowId]="workflowId"
            [staticFilters]="myWorkflowsFilter"
          >
          </wm-workflow-application-list>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <div class="card">
        <div class="card-body">
          <h4>Recent Applications</h4>
          <wm-workflow-application-list
            [id]="workflowDetailsViewRecentApplicationsId"
            [workflowId]="workflowId"
            [staticFilters]="topTenRecentFilter"
            [sortField]="'dateCreated'"
            sortDescending="true"
          >
          </wm-workflow-application-list>
        </div>
      </div>
    </div>
  </div>
  <div class="row mt-3" *ngIf="isRenewable">
    <div class="col">
      <div class="card">
        <div class="card-body">
          <h4>Renewals</h4>
          <wm-renewal-list
            [id]="renewalsId"
            [renderTitle]="false"
            [staticFilters]="workflowRegistrationFilter"
            [dynamicColumnsVisibleByDefault]="true"
          >
          </wm-renewal-list>
        </div>
      </div>
    </div>
  </div>
  <wm-modal-confirm
    [id]="'startApplicationLink'"
    title="Start Application Link"
    [showButton]="false"
    cancelText="Close"
    #copyLink
  >
    <h5>
      Copy the text below and place it in the website of your choice to start an
      application for this workflow.
    </h5>
    <h5 *ngIf="startAppMsg">
      {{ startAppMsg }}
    </h5>
    <div>
      <code>
        {{ startAppLink }}
      </code>
    </div>
  </wm-modal-confirm>
</div>

<div class="mt-4">
  <div *ngIf="report">
    <div>
      <h4>Totals</h4>
      <div class="row">
        <wm-metric
          class="col-sm-4 col-xl-3"
          name="Payments"
          [value]="report.totalPayments"
        ></wm-metric>
        <wm-metric
          class="col-sm-4 col-xl-3"
          name="Amount"
          [value]="report.totalAmount | currency"
        ></wm-metric>
      </div>
    </div>
    <div>
      <div class="d-flex justify-content-between align-items-center">
        <div>
          <h4>Fee Recipients</h4>
        </div>
      </div>
    </div>
  </div>

  <wm-exports-view
    *ngIf="columnOptions && columnOptions.length > 0"
    [id]="id"
    [title]="title"
    [params]="params"
    [currentFilters]="filters"
    [currentColumnOptions]="columnOptions"
    (exptExcel)="exportExcel()"
    (exptCsv)="exportCsv()"
  ></wm-exports-view>

  <wm-datatable
    [id]="id"
    [loading]="loading"
    [useMap]="false"
    [rows]="rows"
    [columns]="columns"
    [count]="page.totalElements"
    [limit]="page.size"
    [offset]="page.pageNumber"
    [params]="params"
    [translateToLegacy]="false"
    [showSimpleSearch]="false"
    [savedFilters]="true"
    (settingsChanged)="handleSettingsChanged($event)"
    [sortField]="sortField"
    [sortDescending]="sortDescending"
    [defaultHiddenColumns]="defaultHiddenColumns"
    [actionColumns]="actionColumns"
    [defaultExportExludedColumns]="defaultExportExludedColumns"
    [availableColumns]="availableColumns"
    (columnOptionsChanged)="handleColumnOptionsChanged($event)"
    [exportingEnabled]="true"
  ></wm-datatable>
</div>

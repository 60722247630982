<div class="row">
  <div class="col">
    <wm-data-entity-input-list
      [form]="form"
      [entities]="formLayoutModel.leftColumnEntities"
      [application]="application"
      [activityId]="activity.id"
      *ngIf="!isReadOnly"
    >
    </wm-data-entity-input-list>
    <wm-data-entity-view-list
      [form]="form"
      [entities]="formLayoutModel.leftColumnEntities"
      [application]="application"
      [activityId]="activity.id"
      *ngIf="isReadOnly"
    >
    </wm-data-entity-view-list>
  </div>
  <div class="col">
    <wm-data-entity-input-list
      [form]="form"
      [entities]="formLayoutModel.rightColumnEntities"
      [application]="application"
      [activityId]="activity.id"
      *ngIf="!isReadOnly"
    >
    </wm-data-entity-input-list>
    <wm-data-entity-view-list
      [form]="form"
      [entities]="formLayoutModel.rightColumnEntities"
      [application]="application"
      [activityId]="activity.id"
      *ngIf="isReadOnly"
    >
    </wm-data-entity-view-list>
  </div>
</div>

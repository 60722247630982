import { ListOption } from './../../../../../../models/listOption';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';

@Component({
  selector: 'wm-list-item-editor',
  templateUrl: './list-item-editor.component.html',
  styleUrls: ['./list-item-editor.component.css']
})
export class ListItemEditorComponent implements OnInit {
  @Output() save: EventEmitter<ListOption> = new EventEmitter();
  @Input() item: ListOption = new ListOption({ value: '', text: '' });
  @Input() title = 'New list item';
  @Input() saveText = 'Add item';

  listItemForm: UntypedFormGroup;

  labelIsCustom = false;

  get hasLabelChanged() {
    return this.listItemForm.controls['text'].dirty || this.labelIsCustom;
  }

  constructor() {
    this.listItemForm = new UntypedFormGroup({
      text: new UntypedFormControl('', [Validators.nullValidator]),
      value: new UntypedFormControl('', [Validators.required])
    });
  }

  ngOnInit() {
    if (
      this.item.value !== '' &&
      this.item.text !== '' &&
      this.item.text != this.item.value
    ) {
      this.labelIsCustom = true;
    }
    this.listItemForm.patchValue({ ...this.item });
  }

  textChange() {
    this.item.text = this.listItemForm.controls['text'].value;
  }

  valueChange() {
    this.item.value = this.listItemForm.controls['value'].value;

    if (!this.hasLabelChanged) {
      this.item.text = this.item.value;
      this.listItemForm.controls['text'].patchValue(this.item.value);
    }
  }

  updateItem() {
    this.textChange();
    this.valueChange();
  }

  keyPress(keyCode) {
    if (keyCode.key === 'Enter') {
      this.addItem();
    }
    return true;
  }

  addItem() {
    if (this.listItemForm.valid) {
      this.save.emit(new ListOption({ ...this.item }));
    }
  }

  reset() {
    this.item = new ListOption({ text: '', value: '' });
    this.listItemForm.reset();
  }
}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { Contact, Client } from '../../../../models';
import { WorkflowContextService, ClientService } from '../../../../services';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'wm-contact-list-view',
  templateUrl: './contact-list-view.component.html',
  styleUrls: ['./contact-list-view.component.css']
})
export class ContactListViewComponent implements OnInit, OnDestroy {
  ngOnDestroy(): void {
    if (this.clientSubscribe) {
      this.clientSubscribe.unsubscribe();
    }
  }
  contacts: Contact[];
  client: Partial<Client>;
  clientSubscribe: Subscription;

  constructor(
    private _context: WorkflowContextService,
    private _service: ClientService,
    private _router: Router
  ) {}

  getContacts() {
    this._service.getContacts(this._context.client.id).subscribe(contacts => {
      this.contacts = contacts;
    });
  }

  ngOnInit() {
    if (this._context.client) {
      this.getContacts();
    } else {
      this.clientSubscribe = this._context.client$.subscribe(res => {
        //if (!this._context.client) {
        //  this._router.navigate(['/admin']);
        //  return;
        //}
        this.getContacts();
      });
    }
  }

  removeContact(e: Contact) {
    const index = this.contacts.indexOf(e);
    this.contacts.splice(index, 1);
  }

  addContact() {
    this._router.navigate([
      `/admin/jurisdiction/${this._context.client.id}/settings/contacts/add`
    ]);
  }
}

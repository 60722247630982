import { WorkflowContextService } from 'src/app/services';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { faTimes, faGlobe } from '@fortawesome/free-solid-svg-icons';
import {
  Component,
  OnInit,
  Input,
  Output,
  ViewChild,
  QueryList,
  ViewChildren,
  EventEmitter,
  SimpleChanges,
  OnChanges,
  ViewEncapsulation
} from '@angular/core';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import {
  FilterBuilderParam,
  FilterBuilderOutput,
  SearchFilterBuilderOutput,
  FilterBuilderFilter,
  FilterBuilderFilterFlat,
  FilterBuilderParamOpt,
  FilterValueTransformFunction
} from 'src/app/models/filter-builder';
import {
  ApplyFilter,
  GetFilter,
  FilterSet,
  ColumnSet,
  SavedFilter,
  SavedColumn
} from 'src/app/models/saved-filter';
import { ModalConfirmComponent } from '../modal-confirm/modal-confirm.component';
import { ExportEntityOption } from '../../filter-list/models/filterClasses';
import { ShapefileValidationCriteria } from 'src/app/models/scheduled-export';

const defaultTransforms: {
  [inputType: string]: FilterValueTransformFunction;
} = {
  date: (value: string) => {
    if (!value) {
      return '(unset)';
    }
    return moment(value).format('MM/DD/YYYY');
  },
  ['datetime-local']: (value: string) => {
    if (!value) {
      return '(unset)';
    }
    return moment(value).format('MM/DD/YYYY h:mm a');
  }
};

@Component({
  selector: 'wm-filter-builder',
  templateUrl: './filter-builder.component.html',
  styleUrls: ['./filter-builder.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class FilterBuilderComponent implements OnInit, OnChanges {
  @ViewChildren('popovers') filterPopovers: QueryList<NgbPopover>;
  @ViewChild('fp', { static: false }) filterPopover: NgbPopover;
  @ViewChild('columnOptionsModal', { static: false }) columnOptionsModal: ModalConfirmComponent;
  @Input() savedFilters = false;
  @Input() params: FilterBuilderParam[] = [];
  @Input() filterTypes = [
    {
      name: 'contains'
    },
    {
      name: 'is'
    },
    {
      name: 'in'
    },
    {
      name: 'range'
    },
    {
      name: 'is not'
    },
    {
      name: 'exists'
    },
    {
      name: 'not exists'
    },
    {
      name: '<'
    },
    {
      name: '>'
    },
    {
      name: '<='
    },
    {
      name: '>='
    }
  ];
  @Input() id: string;
  @Input() waitForParent = false;
  @Input() exportingEnabled = false;
  @Input() parentId: string;
  @Input() shapefileValidationCriteria: ShapefileValidationCriteria;

  @Output() filtersChange: EventEmitter<
    FilterBuilderOutput
  > = new EventEmitter();

  @Output() filtersChange2: EventEmitter<
    SearchFilterBuilderOutput
  > = new EventEmitter();
  @Input() showFilterBuilder = true;
  @Input() showSimpleSearch = true;

  @Output() columnsChange: EventEmitter<ColumnSet> = new EventEmitter<
    ColumnSet
  >();
  @Output() exportColumnsChange: EventEmitter<string[]> = new EventEmitter<
    string[]
  >();

  @Input() columnOptions: any[];
  @Input() onlyExportOptions = false;

  private filtersChangedSubject: Subject<FilterBuilderOutput> = new Subject<
    FilterBuilderOutput
  >();
  private filtersChangedSubject2: Subject<
    SearchFilterBuilderOutput
  > = new Subject<SearchFilterBuilderOutput>();
  private simpleSearchSubject: Subject<string> = new Subject<string>();

  defaultDateRangeOpts = [
    {
      name: 'Today',
      value: 'this_1_days'
    },
    {
      name: 'Yesterday',
      value: 'previous_1_day'
    },
    {
      name: 'Current Week',
      value: 'this_1_weeks'
    },
    {
      name: 'Previous Week',
      value: 'previous_1_weeks'
    },
    {
      name: 'Current Month',
      value: 'this_1_months'
    },
    {
      name: 'Last Month',
      value: 'previous_1_months'
    },
    {
      name: 'Current Calendar Quarter',
      value: 'this_1_calendarquarter'
    },
    {
      name: 'Previous Calendar Quarter',
      value: 'previous_1_calendarquarter'
    },
    {
      name: 'Current Year',
      value: 'this_1_years'
    },
    {
      name: 'Last Year',
      value: 'previous_1_years'
    },
    {
      name: 'Custom',
      value: 'custom'
    }
  ];
  filters: FilterBuilderFilter[] = [];
  @Input() defaultFilters: FilterBuilderFilter[];
  @Input() simpleSearchTitle = 'Search for applications!';

  faTimes = faTimes;
  faGlobe = faGlobe;
  allTriggered = false;
  hasAutoTriggered = false;

  searchModel: string;
  simpleSearchModel: string;
  simpleFilters: FilterBuilderFilter[] = [];
  trialColumnOptions: any[] = [];

  get filteredParams() {
    if (this.searchModel) {
      return this.params.filter(param =>
        param.name.toLowerCase().includes(this.searchModel.toLowerCase())
      );
    } else {
      return this.params;
    }
  }

  get cacheId(): string {
    return `filter-builder-${this.id}`;
  }

  get selectedExportColumnsNumber() {
    return (
      this.columnOptions &&
      this.columnOptions.filter(opt => opt.includeInExport).length
    );
  }

  constructor(public context: WorkflowContextService) {
    this.filtersChangedSubject
      .pipe(
        debounceTime(300),
        // stringify the JSON so that we can compare the objects
        distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
      )
      .subscribe(filterOutputs => {
        this.filtersChange.emit(filterOutputs);
      });

    this.filtersChangedSubject2
      .pipe(
        debounceTime(300),
        // stringify the JSON so that we can compare the objects
        distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
      )
      .subscribe(filterOutputs2 => {
        this.filtersChange2.emit(filterOutputs2);
      });

    this.simpleSearchSubject.pipe(debounceTime(500)).subscribe(value => {
      this.changeSimpleSearch();
    });
  }

  ngOnInit() {
    // don't show the filter builder if there aren't any parameters defined.
    if((this.params || []).length == 0) {
      this.showFilterBuilder = false;
    }
    // restore filters from localStorage
    this.filters = this.getCachedFilters() || this.filters;
    if (this.filters.length === 0 && this.defaultFilters) {
      this.filters = this.defaultFilters;
    }
    this.filtersChanged();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.defaultFilters) {
      this.filters = this.defaultFilters;
    }
  }

  isGeoJSON(column: any) {
    if (
      this.shapefileValidationCriteria &&
      this.shapefileValidationCriteria.geoJSONEntities != null
    ) {
      const isgeoJSONEntity = this.shapefileValidationCriteria.geoJSONEntities[
        column.name
      ];
      if (isgeoJSONEntity) {
        return true;
      }
    }
    return false;
  }
  /**
   * Get filters that are cached in localStorage
   */
  private getCachedFilters(): FilterBuilderFilter[] {
    if (!this.id) {
      return [];
    }

    try {
      const stringData = localStorage.getItem(this.cacheId);

      if (!stringData) {
        return [];
      }

      const jsonData = JSON.parse(stringData);

      const data: FilterBuilderFilter[] = jsonData
        .map((filter: FilterBuilderFilterFlat) => {
          try {
            const param = this.params.find(p => p.id === filter.paramId);

            if (!param) {
              return null;
            }

            return FilterBuilderFilter.unflatten({ ...filter, param });
          } catch (err) {
            // if there was an error, the param probably doesn't exist anymore so we should just swallow the error and then remove this filter
            return null;
          }
        })
        .filter(filter => !!filter); // remove dead filters
      return data;
    } catch (error) {
      return [];
    }
  }

  /**
   * Set filters cache in localStorage
   */
  private setCachedFilters(filters: FilterBuilderFilter[]) {
    if (!this.id) {
      return;
    }

    const flattenedFilters = filters.map(filter => filter.flatten());

    const stringData = JSON.stringify(flattenedFilters);
    localStorage.setItem(this.cacheId, stringData);
  }

  showFilters() {
    this.filterPopovers.forEach(pop => pop.close());
    this.filterPopover.toggle();
  }

  addFilter(param: FilterBuilderParam) {
    const filter = new FilterBuilderFilter({
      paramId: param.id,
      param
    });
    this.filters.push(filter);
    this.filterPopover.close();

    // hack to make sure that the popover component has rendered before trying to do stuff with it
    setTimeout(() => {
      // find the popover and open it
      const popover = this.filterPopovers.find((p: any) => {
        return (
          p &&
          p._nativeElement &&
          p._nativeElement.id === `filter-${filter.id}`
        );
      });
      this.openFilter(popover);
    }, 10);
  }

  removeFilter(filter: FilterBuilderFilter) {
    this.filters = this.filters.filter(f => f.id !== filter.id);
    this.filtersChanged();
  }

  openFilter(popover: NgbPopover) {
    this.filterPopovers.forEach(pop => {
      if (pop !== popover) {
        pop.close();
      }
    });
    this.filterPopover.close();
    popover.toggle();
  }

  private transformFilterValue(filter: FilterBuilderFilter): string {
    // if there are pre-defined options for this param, map them to a KV object so they are easy to use later
    const optionsObject = {};
    if (filter.param.options) {
      for (const opt of filter.param.options) {
        if (typeof opt === 'object') {
          // if it is an object, there is a name and a value
          const optObject = opt as FilterBuilderParamOpt;
          optionsObject[optObject.value] = optObject.name;
        } else {
          // if it is just a string, there is only a value
          optionsObject[opt] = opt;
        }
      }
    }

    if (filter.param.valueTransform) {
      // first, if there is a value transform, use it
      return filter.param.valueTransform(filter.value);
    } else if (filter.param.options && filter.queryType !== 'contains') {
      // then, if there are pre-defined options, use the option name
      return optionsObject[filter.value];
    } else if (defaultTransforms[filter.param.inputType]) {
      // next, if there is a default transform for the input type, use it
      const transformFn = defaultTransforms[filter.param.inputType];
      return transformFn(filter.value);
    } else {
      // otherwise, fallback to the value
      return filter.value;
    }
  }

  filterText(filter: FilterBuilderFilter) {
    if (filter.queryType === 'exists') {
      return 'exists';
    }

    if (filter.queryType === 'not exists') {
      return 'not exists';
    }

    if (filter.queryType === 'range') {
      if (filter.value && filter.value !== 'custom') {
        const range = this.defaultDateRangeOpts.find(
          opt => opt.value === filter.value
        );

        if (!range) {
          return '';
        }

        return range.name;
      }

      if (filter.customValue) {
        const transformFn = defaultTransforms[filter.param.inputType];

        let start = filter.customValue.start;
        let end = filter.customValue.end;

        if (transformFn) {
          start = transformFn(start);
          end = transformFn(end);
        } else {
          start = start || '(unset)';
          end = end || '(unset)';
        }
        return `from ${start} to ${end}`;
      }

      return '';
    } else if (filter.queryType === 'in' && Array.isArray(filter.customValue)) {
      const list = filter.customValue.map(value => {
        const tempFilter = Object.assign({}, filter);
        tempFilter.value = value;

        return this.transformFilterValue(tempFilter);
      });

      return `${filter.queryType} ${list.join(', ')}`;
    } else if (filter.queryType && filter.value) {
      const plainTextValue = this.transformFilterValue(filter);

      return `${filter.queryType} ${plainTextValue}`;
    }
    return '';
  }

  getFilterTypes(param: FilterBuilderParam) {
    let filterTypes = [...this.filterTypes];

    // only allow the range filter if it's a date or text
    if (
      param.inputType !== 'date' &&
      param.inputType !== 'datetime-local' &&
      param.inputType !== 'text' &&
      param.inputType !== 'number'
    ) {
      filterTypes = filterTypes.filter(t => t.name !== 'range');
    }

    // only allow the 'in' filter type if there's a collection of values to choose from
    if (param.options == null) {
      filterTypes = filterTypes.filter(t => t.name !== 'in');
    }

    if (param.types) {
      filterTypes = filterTypes.filter(t => param.types.includes(t.name));
    }

    return filterTypes;
  }

  getFilterOptions(param: FilterBuilderParam): FilterBuilderParamOpt[] {
    return param.options.map(opt => {
      if (typeof opt === 'string') {
        return { name: opt, value: opt };
      } else {
        return opt as FilterBuilderParamOpt;
      }
    });
  }

  getFilterRangeOptions(param: FilterBuilderParam): FilterBuilderParamOpt[] {
    return param.rangeOptions || this.defaultDateRangeOpts;
  }

  // angular binding will nullify the zero value if an enum of value = 0 is selected as an option.  This bypasses that binding
  bypassBinding(f, e) {
    f.value = e.srcElement.value;
    this.filtersChanged();
  }

  filtersChanged() {
    let submissionFilter: FilterBuilderFilter[];
    let submissionFilter2: FilterBuilderFilter[];
    setTimeout(() => {
      // on 20220415 we decided to simple search on available results
      // when there were filters set.
      // previously we removed simple filters from the filters.

      // remove any filters from the simple filters...
      const results = this.simpleFilters.filter(
        a =>
          !this.filters.some(b => {
            return a.param.id === b.param.id;
          })
      );

      submissionFilter = this.filters.concat(results);
      submissionFilter2 = this.filters.concat(this.simpleFilters);

      const filterOutputs: FilterBuilderOutput = {};
      const filterOutputs2: SearchFilterBuilderOutput = {};

      // do the submission filter with only one option.
      for (const filter of submissionFilter) {
        let value = filter.value;
        if (
          filter.queryType === 'range' &&
          (filter.value === 'custom' ||
            filter.param.inputType === 'text' ||
            filter.param.inputType == 'number') &&
          filter.customValue &&
          filter.customValue.start &&
          filter.customValue.end
        ) {
          // if it is a date range, parse the value into a single string
          value = JSON.stringify(filter.customValue);
        } else if (
          filter.queryType === 'range' &&
          (filter.value === 'custom' ||
            filter.param.inputType === 'text' ||
            filter.param.inputType == 'number')
        ) {
          // if it is a custom date range that hasn't been set yet, make value null
          value = null;
        } else if (
          filter.queryType === 'in' &&
          Array.isArray(filter.customValue)
        ) {
          value = filter.customValue.join(',');
        }

        // only use the filter if it actually is complete
        if (
          value ||
          filter.queryType == 'exists' ||
          filter.queryType == 'not exists'
        ) {
          filterOutputs[filter.param.id] = {
            id: filter.param.id,
            type: filter.queryType,
            searchOptionMethod: filter.searchOptionMethod,
            value,
            inputType: filter.param.inputType
          };
        }
      }

      // do the submissionFilter with multiple options
      // currently the only thing listening to this is datatable component so the contractor-registration-list can react to it,
      // but to limit the scope of testing it is essentially doing the same thing as above, but this is returning
      // multiple search options in an array instead of just one.

      for (const filter of submissionFilter2) {
        let value = filter.value;
        if (
          filter.queryType === 'range' &&
          (filter.value === 'custom' ||
            filter.param.inputType === 'text' ||
            filter.param.inputType == 'number') &&
          filter.customValue &&
          filter.customValue.start &&
          filter.customValue.end
        ) {
          // if it is a date range, parse the value into a single string
          value = JSON.stringify(filter.customValue);
        } else if (
          filter.queryType === 'range' &&
          (filter.value === 'custom' ||
            filter.param.inputType === 'text' ||
            filter.param.inputType == 'number')
        ) {
          // if it is a custom date range that hasn't been set yet, make value null
          value = null;
        } else if (
          filter.queryType === 'in' &&
          Array.isArray(filter.customValue)
        ) {
          value = filter.customValue.join(',');
        }

        // only use the filter if it actually is complete
        if (
          value ||
          filter.queryType == 'exists' ||
          filter.queryType == 'not exists'
        ) {
          filterOutputs2[filter.param.id] =
            filter.param.id in filterOutputs2
              ? filterOutputs2[filter.param.id]
              : [];
          filterOutputs2[filter.param.id].push({
            id: filter.param.id,
            type: filter.queryType,
            searchOptionMethod: filter.searchOptionMethod,
            value,
            inputType: filter.param.inputType
          });
        }
      }
      this.setCachedFilters(this.filters);

      this.filtersChangedSubject2.next(filterOutputs2);
      this.filtersChangedSubject.next(filterOutputs);
    }, 10);
  }

  simpleSearchKeyUp(e) {
    this.simpleSearchSubject.next(e);
  }

  changeSimpleSearch() {
    this.simpleFilters.length = 0;

    if (this.simpleSearchModel) {
      // go through all the fields and add this filter....
      let tempString = '';
      this.params.forEach(value => {
        const p: FilterBuilderParam = {
          name: value.name,
          id: value.id
        };
        tempString += '"' + value.id + '",';

        const filter = new FilterBuilderFilter({
          value: this.simpleSearchModel,
          queryType: 'contains',
          param: p,
          paramId: p.id,
          searchOptionMethod: 'simpleSearch'
        });

        this.simpleFilters.push(filter);
      });
    }
    this.filtersChanged();
  }
  changeFilterType(filter: FilterBuilderFilter) {
    filter.value = null;
    filter.customValue = {};

    this.filtersChanged();
  }

  triggerAll(checked: boolean) {
    if (this.columnOptions && this.onlyExportOptions) {
      for (const column of this.columnOptions) {
        column.includeInExport = checked;
      }
    }

    this.filtersChanged();
  }

  translateColumns(): ColumnSet {
    if (!this.columnOptions) {
      return null;
    }

    // for the time being, this is the flag that tells us if the auto-trigger should happen
    // workflow-export.component is currently the only thing that should do that
    if (this.onlyExportOptions) {
      let anyChecked = false;
      for (const opt of this.columnOptions) {
        if (opt.includeInExport) {
          anyChecked = true;
        }
      }

      if (!anyChecked && !this.hasAutoTriggered) {
        this.triggerAll(true);
        this.allTriggered = true;
        this.hasAutoTriggered = true;
      }
    }

    const columns = this.columnOptions
      .filter(opt => opt.checked === true)
      .map(opt => ({
        header: opt.label,
        name: opt.name
      }));
    const exportColumns = this.columnOptions
      .filter(opt => opt.includeInExport === true)
      .map(opt => ({
        header: opt.label,
        name: opt.name
      }));

    return { columns, exportColumns };
  }

  getCurrentFilter: GetFilter = async () => {
    let translatedColumns: ColumnSet = null;

    if (this.columnOptions) {
      translatedColumns = this.translateColumns();
    }

    return {
      filters: this.filters.map(filter => filter.flatten()),
      columns: translatedColumns ? translatedColumns.columns : [],
      exportColumns: translatedColumns ? translatedColumns.exportColumns : []
    };
  }

  applyFilter: ApplyFilter = async (savedFilter: FilterSet) => {
    // if no saved filter is selected, don't change filters

    if (savedFilter.filters.length > 0) {
      this.hasAutoTriggered = false;
      const data: FilterBuilderFilter[] = savedFilter.filters
        .map((filter: FilterBuilderFilterFlat) => {
          try {
            const param = this.params.find(p => p.id === filter.paramId);

            if (!param) {
              return null;
            }

            return FilterBuilderFilter.unflatten({ ...filter, param });
          } catch (err) {
            // if there was an error, the param probably doesn't exist anymore so we should just swallow the error and then remove this filter
            return null;
          }
        })
        .filter(filter => !!filter); // remove dead filters

      this.filters = data;
    }

    if (this.columnOptions) {
      for (const opt of this.columnOptions) {
        if (savedFilter.columns && savedFilter.columns.length > 0) {
          opt.checked = !!savedFilter.columns.find(
            column => column.name.toLowerCase() === opt.name.toLowerCase()
          );
        } // this should almost certainly be if (savedFilter.exportColumns && ...
        if (savedFilter.columns && savedFilter.exportColumns.length > 0) {
          opt.includeInExport = !!savedFilter.exportColumns.find(
            column => column.name.toLowerCase() === opt.name.toLowerCase()
          );
        }
      }
    }

    // trying to triggerAll when you take off a saved filter
    this.hasAutoTriggered = false;

    this.filtersChanged();

    if (this.columnOptions) {
      if (this.onlyExportOptions) {
        this.updateAndEmitExportColumns();
      } else {
        this.updateAndEmitColumnSet();
      }
    }
  }

  openColumnModal() {
    this.trialColumnOptions = JSON.parse(JSON.stringify(this.columnOptions));
    this.columnOptionsModal.open();
  }

  columnModalClosed() {
    this.columnOptions = JSON.parse(JSON.stringify(this.trialColumnOptions));

    this.updateAndEmitColumnSet();
  }

  columnModalCanceled() {
    this.trialColumnOptions = [];
  }

  dropdownChanged(open: boolean) {
    this.updateAndEmitExportColumns();
  }

  updateAndEmitColumnSet() {
    const columns = this.columnOptions
      .filter(co => co.checked)
      .map(co => ({
        name: co.name,
        header: co.label
      }));

    const exportColumns = this.columnOptions
      .filter(co => co.includeInExport)
      .map(co => ({
        name: co.name,
        header: co.label
      }));

    this.columnsChange.emit({ columns, exportColumns });
  }

  updateAndEmitExportColumns() {
    const exportColumns = this.columnOptions
      .filter(co => co.includeInExport)
      .map(co => co.name);

    this.exportColumnsChange.emit(exportColumns);
  }
}

import { Client } from '.';

export class ResultField {
  constructor(options?: Partial<ResultField>) {
    if (options) {
      Object.assign(this, options);
    }
  }

  field: string;
  alias: string;
}
export class SearchParameter {
  constructor(options?: Partial<SearchParameter>) {
    if (options) {
      Object.assign(this, options);
    }
  }

  label: string;
  name: string;
  value: string;
}
export class ExternalCredential {
  userName: string;
  password: string;
}
export class ExternalDataConnection {
  id: string;
  name: string;
  url: string;
  credentials: Partial<ExternalCredential>;
  method: string; // GET or POST
  parameters: Partial<SearchParameter>[];
  resultFields: Partial<ResultField>[];
  connectionType: string;
  clientID: string;
  client: Partial<Client>;
  constructor(options?: Partial<ExternalDataConnection>) {
    this.parameters = [];
    this.resultFields = [];

    if (options) {
      Object.assign(this, options);
    }
  }
}

export class ParcelDataConnection extends ExternalDataConnection {
  // id: string;
  // name: string;
  // url: string;
  // credentials: ExternalCredential;
  // method: string; //GET or POST
  // parameters: SearchParameter[];
  // resultFields: ResultField[];
  // connectionType: string;
  sourceId: number;

  setSourceId(value: number) {
    this.sourceId = value;
    const sourceParam = this.parameters.find(p => p.name === 'SourceID');
    if (sourceParam) {
      if (value) {
        sourceParam.value = value.toString();
      } else {
        sourceParam.value = null;
      }
    }
  }
  getSourceId(): number {
    return this.sourceId;
  }
  constructor(options?: Partial<ExternalDataConnection>) {
    super();

    this.name = 'Parcel Data';
    this.url = '';
    this.method = 'POST';
    this.connectionType = 'ParcelData';
    this.parameters = [
      new SearchParameter({
        label: 'ParcelId',
        name: 'ParcelID',
        value: null
      }),
      new SearchParameter({
        label: 'Source ID',
        name: 'SourceID',
        value: null
      })
    ];
    this.resultFields = [
      { field: 'ParcelId', alias: 'Parcel ID' },
      { field: 'OwnerName', alias: 'Owner Name' },
      { field: 'OwnerCity', alias: 'Owner City' },
      { field: 'OwnerState', alias: 'Owner State' },
      { field: 'OwnerZip', alias: 'Owner Zip' },
      { field: 'OwnerAddr1', alias: 'Owner Address 1' },
      { field: 'OwnerAddr2', alias: 'Owner Address 2' },
      { field: 'OwnerAddr3', alias: 'Owner Address 3' },
      { field: 'str' },
      { field: 'acres', alias: 'Acres' },
      { field: 'parcelClass', alias: 'Parcel Class' },
      { field: 'taxDistrict', alias: 'Tax District' },
      { field: 'HouseNum', alias: 'House Number' },
      { field: 'streetName', alias: 'Street Name' },
      { field: 'unitNumber', alias: 'Unit Number' },
      { field: 'address', alias: 'Address' },
      { field: 'city', alias: 'City' },
      { field: 'legalDesc', alias: 'Legal Description' },
      { field: 'ownerAddress', alias: 'Owner Address' },
      { field: 'assessedValue', alias: 'Assessed Value' },
      { field: 'book', alias: 'Book' },
      { field: 'page', alias: 'Page' },
      { field: 'instType', alias: 'Instrument Type' },
      { field: 'refNum', alias: 'Reference Number' },
      { field: 'alternateID', alias: 'Alternate ID' },
      { field: 'dba', alias: 'DBA' },
      { field: 'custom0', alias: 'Custom 0' },
      { field: 'custom1', alias: 'Custom 1' },
      { field: 'custom2', alias: 'Custom 2' },
      { field: 'custom3', alias: 'Custom 3' },
      { field: 'custom4', alias: 'Custom 4' },
      { field: 'custom5', alias: 'Custom 5' },
      { field: 'custom6', alias: 'Custom 6' },
      { field: 'custom7', alias: 'Custom 7' },
      { field: 'custom8', alias: 'Custom 8' },
      { field: 'custom9', alias: 'Custom 9' },
      { field: 'custom10', alias: 'Custom 10' },
      { field: 'custom11', alias: 'Custom 11' },
      { field: 'custom12', alias: 'Custom 12' },
      { field: 'custom13', alias: 'Custom 13' },
      { field: 'custom14', alias: 'Custom 14' },
      { field: 'customNumeric0', alias: 'Custom Numeric 0' },
      { field: 'customNumeric1', alias: 'Custom Numeric 1' },
      { field: 'customNumeric2', alias: 'Custom Numeric 2' },
      { field: 'customNumeric3', alias: 'Custom Numeric 3' },
      { field: 'customNumeric4', alias: 'Custom Numeric 4' },
      { field: 'customNumeric5', alias: 'Custom Numeric 5' },
      { field: 'customNumeric6', alias: 'Custom Numeric 6' },
      { field: 'customNumeric7', alias: 'Custom Numeric 7' },
      { field: 'customNumeric8', alias: 'Custom Numeric 8' },
      { field: 'customNumeric9', alias: 'Custom Numeric 9' },
      { field: 'customNumeric10', alias: 'Custom Numeric 10' },
      { field: 'customNumeric11', alias: 'Custom Numeric 11' },
      { field: 'customNumeric12', alias: 'Custom Numeric 12' },
      { field: 'customNumeric13', alias: 'Custom Numeric 13' },
      { field: 'customNumeric14', alias: 'Custom Numeric 14' },
      { field: 'neighborhood', alias: 'Neighborhood' },
      { field: 'subdivision', alias: 'Subdivision' },
      { field: 'parcelType', alias: 'Parcel Type' },
      { field: 'zipCode', alias: 'Zip Code' },
      { field: 'hasPool', alias: 'Has Pool' },
      { field: 'lastSaleDateYMD', alias: 'Last Sale Date (YYYYMMDD)' }
    ].map(
      (value: any, index: number) =>
        new ResultField({
          field: value.field,
          alias: value.alias || value.field
        })
    );
    if (options) {
      Object.assign(this, options);
    }
  }
}

export class SFTPExternalConnection extends ExternalDataConnection {
  host: string;
  userName: string;
  password: string;
  path: string;

  constructor() {
    super();

    this.name = 'Secure FTP';
    this.connectionType = 'SecureFTP';
  }
}

import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { WorkflowContextService } from 'src/app/services';

@Component({
  selector: 'wm-contractor-profile-view',
  templateUrl: './contractor-profile-view.component.html',
  styleUrls: ['./contractor-profile-view.component.css']
})
export class ContractorProfileViewComponent implements OnInit {
  contractorId: string;
  clientId: string;

  constructor(
    private _context: WorkflowContextService,
    private _route: ActivatedRoute
  ) {}

  ngOnInit() {
    this._route.data.subscribe(data => {
      if (!data.global) {
        this.clientId = this._context.client.id;
      }

      if (this._context.user && this._context.user.contractorId) {
        this.contractorId = this._context.user.contractorId;
      }
    });
  }
}

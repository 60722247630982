import { DateDataEntity } from './../../../../../models/data-entities/date-data-entity';
import { ToastrService } from 'ngx-toastr';
import {
  Component,
  OnInit,
  EventEmitter,
  ChangeDetectorRef,
  ViewChild
} from '@angular/core';
import { ActivityView } from 'src/app/views/master-views/app.view/app.view.component';
import {
  FormGroup,
  FormControl,
  AbstractControl,
  Validators
} from '@angular/forms';
import { WorkflowContextService } from 'src/app/services';
import { Activity, ActivityModel } from 'src/app/models/activities';
import { FormActivityInputViewComponent } from '../../form-activity-editor/form-activity-input/form-activity-input-view/form-activity-input-view.component';

@Component({
  selector: 'wm-complete-inspection-activity-input',
  templateUrl: './complete-inspection-activity-input.component.html',
  styleUrls: ['./complete-inspection-activity-input.component.css']
})
export class CompleteInspectionActivityInputComponent extends ActivityView
  implements OnInit {

  @ViewChild('formActivityInputView', { static: false })
  formActivityInputViewCtl: FormActivityInputViewComponent; 
  
  constructor(private _ref: ChangeDetectorRef) {
    super();
  }

  ngOnInit() {
    const completeApproveControl: AbstractControl = this.form.controls[
      'COMPLETE_APPROVE'
    ];
    const completeDate: AbstractControl = this.form.controls['COMPLETE_DATE'];
    const completeDateDE = this.activity.model
      .getEntities()
      .find(e => e.templateCode.includes('COMPLETE_DATE')) as DateDataEntity;
    const completeNotesDE = this.activity.model
      .getEntities()
      .find(e => e.templateCode.includes('COMPLETE_SDL_NOTES'));

    let notesAreRequired = false;

    if (completeNotesDE) {
      notesAreRequired = completeNotesDE.isRequired;
    }

    // we need to get the form control based off of the completeNotesDE found above instead of a hard coded value
    const completeNotes: AbstractControl = completeNotesDE
      ? this.form.controls[completeNotesDE.templateCode]
      : null;

    if (completeApproveControl) {
      const completeDEValidators = completeDateDE.getValidators();
      const notesDEValidators = completeNotesDE
        ? completeNotesDE.getValidators()
        : null;

      completeApproveControl.valueChanges.subscribe(e => {
        if (
          e
            .toString()
            .toLowerCase()
            .includes('reschedule')
        ) {
          completeDateDE.isRequired = false;

          const newValidators = completeDEValidators.filter(
            v => v.toString() !== Validators.required.toString()
          );

          // disable the date form control
          completeDateDE.changeStatus.emit(false);
          
          completeDate.setValue('');
          completeDate.setValidators(newValidators);
          completeDate.updateValueAndValidity();

          if (completeNotesDE) {
            completeNotesDE.isRequired = false;

            const completeNotesNonRequiredValidators = notesDEValidators.filter(
              v => v.toString() !== Validators.required.toString()
            );

            if (completeNotes) {
              completeNotes.setValidators(completeNotesNonRequiredValidators);
            }
          }
        } else {
          completeDateDE.isRequired = true;

          completeDate.addValidators(Validators.required);
          completeDate.updateValueAndValidity();

          // disable the date form control
          completeDateDE.changeStatus.emit(true);

          if (completeNotesDE) {
            if (notesAreRequired) {
              completeNotesDE.isRequired = true;
              completeNotes.addValidators(Validators.required);
            }

            if (completeNotes) {
              completeNotes.setValidators(notesDEValidators);
              completeNotes.updateValueAndValidity();
            }
          }
        }
        
        this.form.updateValueAndValidity();
        this._ref.detectChanges();
      });
    }
  }

  async persistChildComponent() {
    return this.formActivityInputViewCtl.persistChildComponent();
  }
}

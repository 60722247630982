import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { User } from '../../../../models';
import { SecurityService, WorkflowContextService } from '../../../../services';
import { Router, ActivatedRoute } from '@angular/router';

export enum StatusType {
  Success,
  Information,
  Warning,
  Critical
}

@Component({
  selector: 'wm-user-list-view',
  templateUrl: './user-list-view.component.html',
  styleUrls: ['./user-list-view.component.css']
})
export class UserListViewComponent implements OnInit {
  users: User[] = null;
  statusMessage: string;
  statusType: StatusType;
  StatusType = StatusType;
  @Output() selected: EventEmitter<User> = new EventEmitter<User>();
  @Input() raiseEvent = false;
  searching = false;
  searchText: string;

  // VIEW_PERMISSION_ID: string = Actions.VIEW_USERS; //'A324BBA0-3840-452A-807C-E8CF8C639260';

  constructor(
    private _securitySvc: SecurityService,
    private _router: Router,
    private route: ActivatedRoute,
    private _context: WorkflowContextService
  ) {}

  get alertClass() {
    switch (this.statusType) {
      case StatusType.Success:
        return 'alert alert-success';

      case StatusType.Information:
        return 'alert alert-info';

      case StatusType.Warning:
        return 'alert alert-warning';

      case StatusType.Critical:
        return 'alert alert-danger';

      default:
        return 'alert alert-primary';
    }
  }

  searchStarted() {
    this.searching = true;
  }
  searchFinished(evt) {
    this.searching = false;
    this.users = evt;
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      if (params['statusMessage']) {
        this.statusMessage = (
          decodeURIComponent(params['statusMessage']) || ''
        ).replace('null', '');
      }
      if (params['statusType']) {
        this.statusType = parseInt(params['statusType'], 10);
      }
      if (params['searchText']) {
        this.searchText = params['searchText'];
      }
    });
  }

  userSelected(e: User) {
    if (this.raiseEvent) {
      this.selected.emit(e);
    }
  }

  addUser() {
    this._router.navigate(['/admin/global/users/add']);
  }
}

<app-loader *ngIf="!report"></app-loader>

<div *ngIf="report">
  <h3>{{ report.name }}</h3>
  <h6>{{ report.description }}</h6>

  <wm-custom-report-filter-input
    [report]="report"
    (filtersChanged)="this.filters = $event"
  ></wm-custom-report-filter-input>

  <button
    [disabled]="executeDisabled"
    class="btn btn-primary btn-raised mt-2"
    id="export"
    (click)="executeReport()"
  >
    Execute
  </button>
  <span class="ml-3 text-danger"
    >Note: Depending on the criteria you choose above, the report may take
    several minutes to complete.</span
  >
  <wm-queued-custom-report-list #customReportList [customReportId]="reportId" [userId]="userId"></wm-queued-custom-report-list>
  <!-- <div [hidden]="!executing">
    <div class="spinner-border"></div>
    Exporting...
  </div>
  <div [hidden]="!showDownloadLink">
    <div>
      <a [attr.href]="sanitize(link.href)" [attr.download]="link.download">
        Download {{ link.download }}
      </a>
    </div>
  </div> -->
</div>

<form [formGroup]="form" autocomplete="off" novalidate>
  <div class="form-group">
    <div *ngIf="entity.controlType === 'radio'">
      <wm-de-label [entity]="entity" [for]="entity.templateCode"></wm-de-label>

      <div class="custom-control custom-radio">
        <input
          type="radio"
          [(ngModel)]="entity.value"
          [formControlName]="entity.templateCode"
          [name]="entity.templateCode"
          [id]="entity.templateCode + '-NO'"
          value="No"
          class="custom-control-input"
        />
        <label class="custom-control-label" [for]="entity.templateCode + '-NO'">
          No
        </label>
      </div>
      <div class="custom-control custom-radio">
        <input
          type="radio"
          [(ngModel)]="entity.value"
          [formControlName]="entity.templateCode"
          [name]="entity.templateCode"
          [id]="entity.templateCode + '-YES'"
          value="Yes"
          class="custom-control-input"
        />
        <label
          class="custom-control-label"
          [for]="entity.templateCode + '-YES'"
        >
          Yes
        </label>
      </div>
    </div>

    <div *ngIf="entity.controlType === 'checkbox'">
      <div class="custom-control custom-checkbox">
        <input
          type="checkbox"
          class="custom-control-input"
          [id]="'checkbox-' + entity.templateCode"
          [name]="'check-' + entity.templateCode"
          [(ngModel)]="checkBoxValue"
          [formControlName]="entity.templateCode"
          (change)="selectChange()"
          value="Yes"
        />
        <label
          class="custom-control-label"
          [for]="'checkbox-' + entity.templateCode"
        >
          <span [innerHTML]="entity.entityLabel"></span>
        </label>
        <wm-help-text
          class="ml-1"
          *ngIf="entity.instructions"
          placement="right"
          [helpText]="entity.instructions"
        ></wm-help-text>
      </div>
    </div>

    <div *ngIf="entity.controlType === 'combo'">
      <wm-de-label [entity]="entity" [for]="entity.templateCode"></wm-de-label>
      <select
        [(ngModel)]="entity.value"
        [formControlName]="entity.templateCode"
        [id]="entity.templateCode"
        [name]="entity.templateCode"
        class="form-control"
      >
        <option value="Yes">Yes</option>
        <option value="No">No</option>
      </select>
    </div>
  </div>
</form>

<div *ngIf="defaultTemplate">
  <div class="row pr-4 justify-content-between">
    <div class="col text-right float-right">
      <wm-scheduled-exports
        *ngIf="
          defaultTemplate &&
          columnOptions &&
          columnOptions.length > 0 &&
          canManageScheduledExports &&
          clientId
        "
        class="m-2"
        [componentId]="id"
        [title]="title"
        [params]="params"
        [currentFilters]="currentFilters"
        [staticFilters]="staticFilters"
        [currentColumnOptions]="columnOptions"
        [shapefileValidationCriteria]="shapefileValidationCriteria"
      ></wm-scheduled-exports>
      <div
        ngbDropdown
        class="d-inline-block ml-2"
        display="dynamic"
        placement="bottom-right"
      >
        <button
          class="btn btn-primary btn-raised"
          id="export"
          ngbDropdownToggle
        >
          Export
        </button>
        <div
          ngbDropdownMenu
          class="dropdown-menu"
          aria-labelledby="dropdownMenuButton"
        >
          <button ngbDropdownItem (click)="exportExcel()">
            Excel Format (.xlsx)
          </button>
          <button ngbDropdownItem (click)="exportCsv()">
            CSV Format (.csv)
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Data Export uses a different template-->
<div *ngIf="!defaultTemplate">
  <div class="row justify-content-between">
    <div class="col float-left">
      <div
        ngbDropdown
        class="d-inline-block mr-2"
        display="dynamic"
        placement="bottom-left"
      >
        <button
          class="btn btn-primary btn-raised"
          id="export"
          ngbDropdownToggle
          [disabled]="!form.valid"
        >
          Export
        </button>
        <div
          ngbDropdownMenu
          class="dropdown-menu"
          aria-labelledby="dropdownMenuButton"
        >
          <span
            style="text-wrap: nowrap;"
            [ngClass]="
              shapefileValidationCriteria.isGeoJSONSelected == true
                ? 'tooltip-wrapper'
                : ''
            "
            placement="right"
            [ngbTooltip]="
              shapefileValidationCriteria.isGeoJSONSelected == true
                ? shapefileValidationCriteria.tooltipErrors
                    .csvXlsShapefileOnWarning
                : ''
            "
          >
            <button
              ngbDropdownItem
              (click)="exportExcel()"
              [disabled]="shapefileValidationCriteria.isGeoJSONSelected"
            >
              Excel Format (.xlsx)
            </button>
            <button
              ngbDropdownItem
              (click)="exportCsv()"
              [disabled]="shapefileValidationCriteria.isGeoJSONSelected"
            >
              <label>
                CSV Format (.csv)
              </label>
            </button>
          </span>
          <span
            style="text-wrap: nowrap;"
            [ngClass]="
              !shapefileValidationCriteria.isGeoJSONSelected == true ||
              (shapefileValidationCriteria.isGeoJSONSelected == true &&
                shapefileValidationCriteria.showShapeFieldError)
                ? 'tooltip-wrapper'
                : ''
            "
            placement="right"
            [ngbTooltip]="
              !shapefileValidationCriteria.isGeoJSONSelected == true
                ? shapefileValidationCriteria.tooltipErrors.shapefileOffWarning
                : shapefileValidationCriteria.showShapeFieldError == true
                ? shapefileValidationCriteria.tooltipErrors
                    .shapefileExcessFieldsWarning
                : ''
            "
          >
            <button
              ngbDropdownItem
              (click)="exportShapefile()"
              [disabled]="
                !shapefileValidationCriteria.isGeoJSONSelected ||
                shapefileValidationCriteria.showShapeFieldError
              "
            >
              <label>
                <span style="text-wrap: nowrap;">
                  Shape Format (.zip)&nbsp;
                  <fa-icon [icon]="faGlobe"></fa-icon>
                </span>
              </label>
            </button>
          </span>
          <!-- //there needs to be something that will tell it to only export shape -->
        </div>
      </div>
      <wm-scheduled-exports
        *ngIf="
          !defaultTemplate &&
          columnOptions &&
          columnOptions.length > 0 &&
          canManageScheduledExports &&
          clientId &&
          form
        "
        class="m-2"
        [componentId]="id"
        [title]="title"
        [params]="params"
        [currentFilters]="currentFilters"
        [staticFilters]="staticFilters"
        [currentColumnOptions]="columnOptions"
        [filterForm]="clonedFilterForm"
        [form]="form"
        [shapefileValidationCriteria]="shapefileValidationCriteria"
      >
      </wm-scheduled-exports>
    </div>
  </div>
</div>

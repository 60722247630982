import { Component, OnInit, ViewChild } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbInputDatepicker, NgbTimepicker } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { map } from 'rxjs/operators';
import {
  Agenda,
  AgendaItem,
  AgendaPagedRequest,
  AgendaQueueItem,
  AgendaQueueItemsPagedRequest,
  AgendaQueueItemsPagedResponse
} from 'src/app/models/agenda';
import { DateDataEntity } from 'src/app/models/data-entities';
import {
  DataService,
  Utilities,
  WorkflowContextService
} from 'src/app/services';
import { CKEditorComponent } from '@ckeditor/ckeditor5-angular';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CkeditorUploadService } from 'src/app/services/ckeditor-upload.service';

@Component({
  selector: 'wm-agenda-settings-editor',
  templateUrl: './agenda-settings-editor.component.html',
  styleUrls: ['./agenda-settings-editor.component.css']
})
export class AgendaSettingsEditorComponent implements OnInit {
  @ViewChild('otherItemsEditor', { static: false })
  otherItemsEditor: CKEditorComponent;

  clientId: string;
  isNewAgenda: boolean;
  agenda: Agenda;
  agendaTypeId: string;
  agendaTypeName: string;
  defaultItemCount: number;
  agendaTypeQueueItems: AgendaQueueItem[];
  pagedAgendaQueueItems: AgendaQueueItem[];
  form: UntypedFormGroup;
  otherItemsForm: UntypedFormGroup;
  agendaPagedRequest: AgendaPagedRequest;
  agendaTypePagedRequest: AgendaQueueItemsPagedRequest;
  pageNumber = 1;
  recordCount: number;
  pageCount: number;
  selectedQueueItems: string[] = []; // AgendaQueueItem ids
  isSaving = false;

  public Editor = ClassicEditor;
  public editorConfig = {
    toolbar: [
      'heading',
      '|',
      'bold',
      'italic',
      'link',
      '|',
      'bulletedList',
      'numberedList',
      'imageUpload',
      '|',
      'undo',
      'redo'
    ]
  };

  constructor(
    private _context: WorkflowContextService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _dataSvc: DataService,
    private _fb: UntypedFormBuilder,
    private _toastr: ToastrService,
    private _uploadAdapter: CkeditorUploadService
  ) {}

  ngOnInit() {
    this.clientId = this._context.client.id;
    this.agendaTypeId = this._route.snapshot.params.agendaTypeId;

    this.loadFullAgendaType().subscribe(() => {
      if (this._route.snapshot.params.agendaId) {
        this.isNewAgenda = false;
        this.loadAgenda();
      } else {
        this.isNewAgenda = true;
        this.initEmptyAgenda();
        this.selectDefaultQueueItems();
      }
    });

    this.form = this._fb.group({
      meetingDateTime: this._fb.control('', [Validators.required]),
      description: this._fb.control('', [Validators.nullValidator]),
      otherItems: this._fb.control('', [Validators.nullValidator]),
      otherMinutes: this._fb.control('', [Validators.nullValidator])
    });
    this.otherItemsForm = this._fb.group({
      otherItems: this._fb.control('', [Validators.nullValidator])
    });
  }

  loadAgenda() {
    this.agendaPagedRequest = new AgendaPagedRequest({
      agendaId: this._route.snapshot.params.agendaId,
      pageNumber: this.pageNumber - 1
    });

    return this._dataSvc
      .getAgendaWithPagedQueue(this.agendaPagedRequest)
      .subscribe(result => {
        this.agenda = result.agenda;
        this.pagedAgendaQueueItems = result.agenda.agendaQueueItems;
        this.recordCount = result.recordCount;
        this.pageCount = result.pageCount;

        this.agenda.meetingDateTimeDE = new DateDataEntity({
          label: 'Meeting Time',
          templateCode: 'meetingDateTime',
          value: this.agenda.meetingDateTimeDE.value,
          timeZoneOffset: 0,
          includeTime: true
        });

        for (const agendaItem of result.agenda.agendaItems) {
          if (
            !(
              this.selectedQueueItems.filter(
                qi => qi === agendaItem.agendaQueueItemId
              ).length > 0
            )
          ) {
            this.selectedQueueItems.push(agendaItem.agendaQueueItemId);
          }
        }
      });
  }

  loadFullAgendaType() {
    this.agendaTypePagedRequest = new AgendaQueueItemsPagedRequest({
      agendaTypeId: this.agendaTypeId,
      pageNumber: this.pageNumber - 1
    });

    return this._dataSvc.getFullAgendaType(this.agendaTypePagedRequest).pipe(
      map(result => {
        this.agendaTypeName = result.agendaType.name;
        this.defaultItemCount = result.agendaType.defaultItemCount;
        this.recordCount = result.recordCount;
        this.pageCount = result.pageCount;
        this.pagedAgendaQueueItems = result.agendaType.agendaQueueItems;
        this.agendaTypeQueueItems = result.agendaType.agendaQueueItems;
      })
    );
  }

  initEmptyAgenda() {
    this.agenda = new Agenda({
      id: Utilities.EMPTY_GUID,
      meetingDateTimeDE: new DateDataEntity({
        label: 'Meeting Time',
        templateCode: 'meetingDateTime',
        value: new Date().toISOString().slice(0, 10),
        timeZoneOffset: 0,
        includeTime: true
      }),
      description: '',
      agendaTypeId: this.agendaTypeId,
      agendaType: null,
      agendaItems: [],
      agendaQueueItems: [],
      otherItems: '',
      otherMinutes: ''
    });
  }

  selectDefaultQueueItems() {
    const populate = () => {
      if (this.agendaTypeQueueItems && this.agendaTypeQueueItems.length > 0) {
        for (
          let i = 0;
          i <
          (this.defaultItemCount <= this.agendaTypeQueueItems.length
            ? this.defaultItemCount
            : this.agendaTypeQueueItems.length);
          i++
        ) {
          this.selectedQueueItems.push(this.agendaTypeQueueItems[i].id);
        }
      }
    };

    if (this.defaultItemCount && this.defaultItemCount > 10) {
      // if the agenda type is configured to have more default agenda items than the size of the page (10),
      // then fetch more pages of agenda queue items so some or all of their items can be added to selectedQueueItems
      const defaultItemPages =
        Math.floor(this.defaultItemCount / 10) +
        (this.defaultItemCount % 10 ? 1 : 0);

      const p: Promise<AgendaQueueItemsPagedResponse>[] = [];

      for (let i = 1; i < defaultItemPages; i++) {
        p.push(
          this._dataSvc
            .getFullAgendaType({
              agendaTypeId: this.agendaTypeId,
              pageNumber: i
            })
            .pipe(
              map(result => {
                this.agendaTypeQueueItems.push(
                  ...result.agendaType.agendaQueueItems
                );
                return result;
              })
            )
            .toPromise()
        );
      }
      Promise.all(p).then(() => populate());
    }

    populate();
  }

  updateSelected(event: any) {
    if (!event.isSelected) {
      for (let i = 0; i < this.selectedQueueItems.length; i++) {
        if (this.selectedQueueItems[i] === event.id) {
          this.selectedQueueItems.splice(i, 1);
        }
      }
    } else {
      this.selectedQueueItems.push(event.id);
    }
  }

  cancelAgendaChanges() {
    if (this.isNewAgenda) {
      this._router.navigate([
        '/admin/jurisdiction',
        this.clientId,
        'meetings',
        'agenda-types',
        'agenda-type',
        this.agendaTypeId
      ]);
    } else {
      this._router.navigate([
        '/admin/jurisdiction',
        this.clientId,
        'meetings',
        'agenda-types',
        'agenda-type',
        this.agendaTypeId,
        'view-agenda',
        this.agenda.id
      ]);
    }
  }

  saveAgenda() {
    this.isSaving = true;
    const agendaItemsBuilder =
      this.agenda.agendaItems && this.agenda.agendaItems.length > 0
        ? this.agenda.agendaItems
        : [];
    this.agenda.agendaItems = [];

    if (agendaItemsBuilder && agendaItemsBuilder.length > 0) {
      for (const ai of agendaItemsBuilder) {
        if (
          this.selectedQueueItems.filter(qi => qi === ai.agendaQueueItemId)
            .length > 0
        ) {
          this.agenda.agendaItems.push(ai);
        }
      }
    }

    for (const qi of this.selectedQueueItems) {
      if (agendaItemsBuilder && agendaItemsBuilder.length > 0) {
        if (
          agendaItemsBuilder.filter(ai => ai.agendaQueueItemId === qi)
            .length === 0
        ) {
          const newAgendaItem = new AgendaItem({
            agendaQueueItemId: qi
          });
          this.agenda.agendaItems.push(newAgendaItem);
        }
      } else {
        const newAgendaItem = new AgendaItem({
          agendaQueueItemId: qi
        });
        this.agenda.agendaItems.push(newAgendaItem);
      }
    }

    this._dataSvc.saveAgenda(this.agenda, true).subscribe(() => {
      if (this.isNewAgenda) {
        this._router.navigate([
          '/admin/jurisdiction',
          this.clientId,
          'meetings',
          'agenda-types',
          'agenda-type',
          this.agendaTypeId
        ]);
      } else {
        this._toastr.success('Agenda Saved!');
        this.isSaving = false;
      }
    });
  }

  public pageChanged(pageNumber: number) {
    this.pageNumber = pageNumber;
    this.isNewAgenda
      ? this.loadFullAgendaType().subscribe()
      : this.loadAgenda();
  }

  public editorReady(editor) {
    const parentId: string = this.agenda.id;
    editor.plugins.get('FileRepository').createUploadAdapter = loader => {
      this._uploadAdapter.setLoader(loader, parentId);
      this._uploadAdapter.loader = loader;

      this._uploadAdapter.isUploading.subscribe((isUploading: boolean) => {
        if (this.form.controls['otherItems-IS_UPLOADING']) {
          this.form.controls['$otherItems-IS_UPLOADING'].setValue(
            isUploading ? 1 : 0
          );
        }
      });
      return this._uploadAdapter;
    };
  }
}

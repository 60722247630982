import {
  ScreenActivity,
  ActivityModel
} from '../../models/activities/activity';

import {
  MapService,
  MapLayer
} from '../../models';
import { MapDisplayConfiguration } from 'src/app/components/arcgis/map-display-configuration';

export class QueryLayerConfig {
  fieldName: string;
  searchLabel: string;
  resultHeader: string;
  entityLabel: string;
  entityTemplateCode: string;
  isSearchable: boolean;
  showInResults: boolean;
  isDataEntity: boolean;
  dataEntityTypeCode: string;
  value?: string;
  constructor(options?: Partial<QueryLayerConfig>) {
    if(options) {
      Object.assign(this, options);
    }
  }
}

export class QueryMapLayerActivityModel extends ActivityModel {
  layerConfig: QueryLayerConfig[];
  mapService: MapService;
  mapLayer: MapLayer;
  featureIdField: string;
  selectedFeature: {[key: string]: string};
  selectedGeometryWkt: string;
  isRequired: boolean;
  referenceGeometryWKT: { [key: string]: { item1: string; item2: string }[] };
  mapConfig: MapDisplayConfiguration;

  constructor(options?: Partial<QueryMapLayerActivityModel>) {
    super(options);

    if(options) {
      Object.assign(this, options);
    }
  }
}

export class QueryMapLayerActivity extends ScreenActivity<ActivityModel> {
  model: QueryMapLayerActivityModel;

  constructor(options?: { [key: string]: any }) {
    super(options);

    this.model = new QueryMapLayerActivityModel(options ? options.model : null);
  }
}

import { KanPayPaymentProcessorConfiguration } from './../../../models/payment-providers/kanpay/kanpay-payment-processor-configuration';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { PaymentProcessorConfigBaseComponent } from '../payment-processor-config-base/payment-processor-config-base.component';

@Component({
  selector: 'wm-payment-processor-config-kanpay',
  templateUrl: './payment-processor-config-kanpay.component.html',
  styleUrls: ['./payment-processor-config-kanpay.component.css']
})
export class PaymentProcessorConfigKanpayComponent
  extends PaymentProcessorConfigBaseComponent
  implements OnInit {
  kanPayConfig: KanPayPaymentProcessorConfiguration;

  constructor(fb: UntypedFormBuilder) {
    super(fb);
  }

  ngOnInit() {
    this.kanPayConfig = this
      .processorConfig as KanPayPaymentProcessorConfiguration;

    this.addValidation('soapURL', Validators.required);
  }
}

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { RoleMember } from '../../../models';

@Component({
  selector: 'wm-role-member-list',
  templateUrl: './role-member-list.component.html',
  styleUrls: ['./role-member-list.component.css']
})
export class RoleMemberListComponent implements OnInit {
  @Input() members: RoleMember[];
  @Input() removeWarning: string;
  @Output() removed: EventEmitter<RoleMember> = new EventEmitter<RoleMember>();

  constructor() {}

  ngOnInit() {}
}

import { MapDisplayConfiguration } from 'src/app/components/arcgis/map-display-configuration';
import {
  Activity,
  ScreenActivity,
  ActivityModel
} from 'src/app/models/activities/activity';
import { ParcelDataConnection, ResultField } from '../external-data-connection';
import { DataEntity } from '../data-entities';

export class MultipleParcelSearchActivityModel extends ActivityModel {
  message = '';
  selectedParcels: { [key: string]: string }[];
  selectedGeometryWkts: { [key: string]: string }[] = null;
  mapConfig: MapDisplayConfiguration = new MapDisplayConfiguration();
  hasMap = false;
  isRequired = false;
  parcelId: string;

  constructor(options?: Partial<MultipleParcelSearchActivityModel>) {
    super(options);

    if (options) {
      Object.assign(this, options);
    }
  }
  getEntities() {
    return new ParcelDataConnection().resultFields.map(
      (value: ResultField, index: number) =>
        new DataEntity({
          templateCode: (
            (this.screenName || '') +
            '.' +
            value.field
          ).toLowerCase(),
          label: value.alias || value.field
        })
    );
  }
}

export class MultipleParcelSearchActivity<
  T extends MultipleParcelSearchActivityModel = MultipleParcelSearchActivityModel
> extends ScreenActivity<MultipleParcelSearchActivityModel> {
  parcelId: string;
  parcelOwner: string;
  parcelAddress: string;
  resultFields: ResultField[];
  selectedData: any;

  constructor(options?: Partial<MultipleParcelSearchActivity<T>>) {
    super(options);

    this.model = new MultipleParcelSearchActivityModel();
    if (options) {
      Object.assign(this, options);
    }

    this.model = new MultipleParcelSearchActivityModel(
      options ? options.model : null
    );

    this.model.screenName = this.model.screenName || 'Multiple Parcel Search'; // this should be changed
    this.model.selectedParcels = this.model.selectedParcels || [];
  }

  public displayOnScreen(): boolean {
    return true;
  }
}

import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/models';
import { StatusType } from '../user-list-view/user-list-view.component';
import { SecurityService, WorkflowContextService } from 'src/app/services';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'wm-global-user-list-view',
  templateUrl: './global-user-list-view.component.html',
  styleUrls: ['./global-user-list-view.component.css']
})
export class GlobalUserListViewComponent implements OnInit {
  users: User[] = [];
  statusMessage: string;
  statusType: StatusType;
  StatusType = StatusType;

  //VIEW_PERMISSION_ID: string = Actions.VIEW_USERS; //'A324BBA0-3840-452A-807C-E8CF8C639260';

  constructor(
    private _securitySvc: SecurityService,
    private _router: Router,
    private route: ActivatedRoute,
    private _context: WorkflowContextService
  ) {}

  get alertClass() {
    switch (this.statusType) {
      case StatusType.Success:
        return 'alert alert-success';

      case StatusType.Information:
        return 'alert alert-info';

      case StatusType.Warning:
        return 'alert alert-warning';

      case StatusType.Critical:
        return 'alert alert-danger';

      default:
        return 'alert alert-primary';
    }
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      if (params['statusMessage']) {
        this.statusMessage = (
          decodeURIComponent(params['statusMessage']) || ''
        ).replace('null', '');
      }
      if (params['statusType']) {
        this.statusType = parseInt(params['statusType']);
      }
    });
  }

  addUser() {
    this._router.navigate(['/admin/global/users/add']);
  }
}

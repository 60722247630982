<h3 class="mb-4">Assigned Inspections</h3>

<app-loader *ngIf="loading"></app-loader>

<div *ngIf="!loading">
  <div *ngFor="let entry of assignedInspections | keys">
    <h5 *ngIf="entry.key === 'unscheduled'">Unscheduled</h5>
    <h5 *ngIf="entry.key !== 'unscheduled'">
      {{ InspectorId[entry.key] | titlecase }}
    </h5>

    <wm-inspection-queue
      #wmInspectionQueue
      *ngIf="assignedInspections[entry.key].staticFilters"
      [id]="idPrefix + entry.key"
      [client]="context.client"
      [staticFilters]="assignedInspections[entry.key].staticFilters"
      (dataLoaded)="dataLoaded($event)"
    >
    </wm-inspection-queue>
  </div>
</div>

import { MapLayerField } from './map-layer-field';
import { MapExtent } from './mapextent';

export class MapLayer {
  id: number;
  mapLayerIndexItemId: string;
  name: string;
  alias: string;
  visible: boolean;
  type: string;
  extent: MapExtent;
  isNew?: boolean;

  endpointUrl: string;
  fields: MapLayerField[];
  advancedQueryCapabilities: AdvancedQueryCapabilities;

  constructor(options?: Partial<MapLayer>) {
    if (options) {
      Object.assign(this, options);
    }
  }
}

export class AdvancedQueryCapabilities {
  useStandardizedQueries: boolean;
  supportsStatistics: boolean;
  supportsHavingClause: boolean;
  supportsCountDistinct: boolean;
  supportsOrderBy: boolean;
  supportsDistinct: boolean;
  supportsPagination: boolean;
  supportsTrueCurve: boolean;
  supportsReturningQueryExtent: boolean;
  supportsQueryWithDistance: boolean;
  supportsSqlExpression: boolean;
}

export class MapIndexLayer {
  id: string;
  mapServiceId: string;
  name: string;
  index: number;

  constructor(options?: Partial<MapIndexLayer>) {
    if (options) {
      Object.assign(this, options);
    }
  }
}

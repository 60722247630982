<form [formGroup]="form">
  <div class="form-group">
    <label class="bmd-label-floating" for="searchName">Search</label>
    <input
      type="text"
      class="form-control"
      [(ngModel)]="searchText"
      formControlName="searchText"
      name="searchName"
    />
  </div>
</form>

<div>
  <input
    #fileUpload
    style="display: none;"
    type="file"
    id="customFile"
    [accept]="validDocumentTypes"
    [multiple]="allowMultipleFiles"
  />

  <button
    #uploadButton
    type="button"
    class="btn btn-raised btn-primary mr-1"
    (click)="onClick()"
  >
    {{ buttonText }}
  </button>

  <span style="padding-left: 10px" *ngIf="files && files.length > 0">
    {{ files[0].progress }}%
  </span>
</div>
<!-- Files being uploaded -->
<div *ngIf="allowMultipleFiles">
  <ul *ngIf="files.length > 0">
    <li *ngFor="let file of files">
      {{ file.data.name }} ({{ file.size }}) - {{ file.progress }}%
    </li>
  </ul>
</div>

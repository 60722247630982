import { Component, OnInit, EventEmitter } from '@angular/core';
import { ActivityView } from '../../../../../views/master-views/app.view/app.view.component';
import { WaitActivity } from '../../../../../models/activities/wait-activity';
import { FormGroup } from '@angular/forms';
import {
  WorkflowContextService,
  ActivityUtilities
} from '../../../../../services';

@Component({
  selector: 'wm-wait-activity-input',
  templateUrl: './wait-activity-input.component.html',
  styleUrls: ['./wait-activity-input.component.css']
})
export class WaitActivityInputComponent extends ActivityView implements OnInit {
  activity: WaitActivity;

  constructor() {
    super();
  }

  ngOnInit() {
    if (this.activity && this.activity.model.activities) {
      const activities = ActivityUtilities.convertActivitiesJsonToClasses(
        this.activity.model.activities
      );

      this.activity.model.activities = Object.keys(activities).map(
        (value: string, index: number) => activities[value]
      );
    }
    this.showNext.emit(false);
  }
}

export class SearchParcelDataCriteria {
  searchParcelId: string;
  searchParcelIds: string[];
  searchParcelOwnerName: string;
  searchParcelAddress: string;
  maxResults: number;
  searchWorkflowApplications: boolean;
  clientId: string;
  constructor(options?: Partial<SearchParcelDataCriteria>) {
    if (options) {
      Object.assign(this, options);
    }
  }
}

import { MapDimensionLayout_htmlClassName } from './../../../../../models/data-entities/mapsketch-data-entity';
import { UntypedFormControl, Validators } from '@angular/forms';
import {
  Component,
  OnInit,
  ComponentFactoryResolver,
  ViewChild
} from '@angular/core';
import { DataEntityInputComponent } from '../../data-entity-input/data-entity-input.component';
import {
  MapsketchDataEntity,
  ScreenCaptureLayoutMethod
} from 'src/app/models/data-entities';
import { EsriMapComponent } from 'src/app/components/arcgis/esri-map/esri-map.component';
import { MapsketchData } from 'src/app/components/arcgis/mapsketch-data';
import { MapExtent } from 'src/app/models/mapextent';

@Component({
  selector: 'wm-mapsketch-data-entity-input',
  templateUrl: './mapsketch-data-entity-input.component.html',
  styleUrls: ['./mapsketch-data-entity-input.component.css']
})
export class MapsketchDataEntityInputComponent extends DataEntityInputComponent
  implements OnInit {
  mapsketchDataEntity: MapsketchDataEntity;
  currentExtent: MapExtent;
  loaded = false;
  ScreenCaptureLayoutMethods = [
    ScreenCaptureLayoutMethod.Default,
    ScreenCaptureLayoutMethod.Landscape,
    ScreenCaptureLayoutMethod.Portrait,
    ScreenCaptureLayoutMethod.Square
  ];
  mapDimensionLayout_class: MapDimensionLayout_htmlClassName;
  @ViewChild(EsriMapComponent, { static: false })
  private esriMap: EsriMapComponent;
  private mapSketchGraphics: any[] = null;

  constructor(componentFactoryResolver: ComponentFactoryResolver) {
    super(componentFactoryResolver);
  }
  async persistChildComponent() {
    this.mapsketchDataEntity.featureGeometryWKT = null;
    return this.esriMap.persistChildComponent();
  }
  ngOnInit() {
    this.mapsketchDataEntity = this.entity as MapsketchDataEntity;
    this.mapDimensionLayout_class = this.mapsketchDataEntity.generateValid_htmlClassNameFromOptionText(
      ScreenCaptureLayoutMethod[
        this.mapsketchDataEntity.screenCaptureLayoutMethod
      ].toLowerCase()
    );

    // add a map loading validator to disable the next button while the map is loading changes
    if (this.form) {
      this.form.addControl(
        'map_loading',
        new UntypedFormControl('', Validators.required)
      );
    }
  }
  async onMapChanging(e: boolean) {
    if (e) {
      this.form.controls['map_loading'].setValue('');
    } else {
      this.form.controls['map_loading'].setValue('true');
      this.form.controls['map_loading'].markAsUntouched();
      if (this.loaded) {
      }
    }
  }

  async onMapLoaded() {
    // get the parcel number captured
    const parcelNumber = this.mapsketchDataEntity.parcelNumber || '';
    const parcelNumbers = this.mapsketchDataEntity.parcelNumbers || [];
    let extent = null;
    // If the data was not captured before and there is a parcel selected, we want to capture the geometry now.
    if (this.mapsketchDataEntity.geometryWKTs == null) {
      this.mapsketchDataEntity.geometryWKTs = [];
    }
    if (
      this.mapsketchDataEntity.geometryWKTs.length === 0 &&
      parcelNumbers.length > 0
    ) {
      const features = await this.esriMap.getFeatureGeometry(
        this.mapsketchDataEntity.mapConfig.parcelLayerName,
        this.mapsketchDataEntity.mapConfig.parcelIdField,
        parcelNumbers
      );

      if (features && features.length > 0) {
        for (let i = 0; i <= features.length - 1; i++) {
          const feature = features[i];
          const wkt = await this.esriMap.webMercatorToWkt(feature.geometry);
          this.mapsketchDataEntity.geometryWKTs.push(wkt);
        }
      }
    }

    let referenceGeoWkt: string[] = [];

    if(this.mapsketchDataEntity.geometryWKTs) {
      this.mapsketchDataEntity.geometryWKTs.forEach(g => {
        referenceGeoWkt.push(g);
      });
    }

    if (this.mapsketchDataEntity.geometryWKT) {
      referenceGeoWkt.push(this.mapsketchDataEntity.geometryWKT);
    }

    if (this.mapsketchDataEntity.featureGeometryWKT) {
      const keys = Object.keys(
        this.mapsketchDataEntity.featureGeometryWKT
      ).filter(f => f != '$id');
      const featureWkt: string[] = [];
      keys.forEach(k => {
        if (this.mapsketchDataEntity.featureGeometryWKT[k]) {
          this.mapsketchDataEntity.featureGeometryWKT[k].forEach(f => {
            if (f.item2) {
              featureWkt.push(f.item2);
            }
          });
        }
      });

      referenceGeoWkt = referenceGeoWkt.concat(featureWkt);
    }

    if (this.mapsketchDataEntity.value) {
      const g: MapsketchData = JSON.parse(this.mapsketchDataEntity
        .value as string);

      this.mapSketchGraphics = g.graphics;

      await this.esriMap.setSketchData(g.graphics, g.extent, referenceGeoWkt);
    } else {
      await this.esriMap.setSketchData(null, extent, referenceGeoWkt);
    }

    this.loaded = true;

    // hack to make sure that the map images have loaded before taking the screenshot
    // this will not work on slower devices/internet
    this.getCurrentData();
  }

  async onZoomMapExtent() {
    await this.esriMap.zoomToInitialExtent();
  }

  async onMapExtentChanged(ext: MapExtent) {
    if (!this.mapSketchGraphics) {
      return;
    }
    this.currentExtent = ext;
  }

  async updateSketchData(sdc: MapsketchData) {
    const newValue: MapsketchData = {
      extent: sdc.extent,
      graphics: this.mapSketchGraphics,
      imageURL: sdc.imageURL
    };

    const mapSketchValue = JSON.stringify(newValue);
    this.mapsketchDataEntity.value = mapSketchValue;

    this.form.controls[this.entity.templateCode].setValue(mapSketchValue);
  }

  async onMapSketchDataChange(sdc: MapsketchData) {
    if (!sdc.graphics) {
      return;
    }
    this.mapSketchGraphics = sdc.graphics;
    await this.updateSketchData(sdc);
  }

  async getCurrentData() {
    const ext = await this.esriMap.getCurrentMapExtentWGS84();
    const sd = await this.esriMap.getSketchData();
    const data: MapsketchData = {
      graphics: sd,
      extent: ext,
      imageURL: null
    };

    this.currentExtent = data.extent;
    await this.onMapSketchDataChange(data);

    return data;
  }
}

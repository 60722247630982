<ng-container *ngIf="activityConfig">
  <div *ngFor="let param of activityConfig.parameterValues">
    <wm-data-entity-formula
      [id]="'parameterConfig-' + param.parameterName"
      [criteria]="param.criteria"
      [thenLabelGetter]="getThenLabel"
      (criteriaChanged)="paramChanged(param, $event)"
      [formulaTitle]="param.parameterName"
      [workflow]="workflow"
      [activity]="activity"
      [overrideAdvancedFee]="true"
      [allowTypeChange]="false"
    ></wm-data-entity-formula>
  </div>
  <div *ngIf="(activityConfig.parameterValues?.length || 0) == 0">
    <i>No Parameters configured for this account</i>
  </div>
</ng-container>

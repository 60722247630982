import {
  Activity,
  ScreenActivity,
  ActivityModel
} from '../../models/activities/activity';
import { ContractorType } from '../contractor-type';

export class ContractorSelectionVM {
  contractorTypeId: string;
  selectedContractorId: string;
  selectedContractorBusinessName: string;
  notInList: boolean;
  selectedRegistrationId: string;
}

export class ContractorSelectionActivityModel extends ActivityModel {
  public contractorSelection: ContractorSelectionVM;
  public allowNotListedOption: boolean;

  constructor(options?: Partial<ContractorSelectionActivityModel>) {
    super(options);
    if (options) {
      Object.assign(this, options);
    }
  }
}

export class ContractorSelectionActivity extends ScreenActivity<
  ContractorSelectionActivityModel
> {
  contractorTypeIds: string[];
  isRequired: boolean;
  canEditContractor: boolean;

  constructor(options?: Partial<ContractorSelectionActivity>) {
    super(options);
    this.contractorTypeIds = [];
    this.model = new ContractorSelectionActivityModel();
    if (options) {
      Object.assign(this, options);
    }

    this.model = new ContractorSelectionActivityModel(
      options ? options.model : null
    );

    this.model.screenName = this.model.screenName || 'Select contractor';
  }
}

<form [formGroup]="form" *ngIf="model">
  <div class="form-row">
    <div class="col">
      <div class="form-group col-md-12 col-sm-12">
        <label for="formatMethod" class="bmd-label-static">
          Output Format
        </label>
        <select
          class="form-control"
          name="formatMethod"
          formControlName="formatMethod"
          [(ngModel)]="model.outputFormatText"
          (change)="loadOutputEditor()"
        >
          <option [ngValue]="null" selected disabled>
            Select a Formatting Option
          </option>
          <option
            *ngFor="let format of availableFormats"
            [ngValue]="format.key.toLowerCase()"
          >
            {{ format.value }}
          </option>
        </select>
      </div>
    </div>
  </div>
  <div>
    <template #outputEditor></template>
  </div>
  <div class="form-row">
    <div class="col">
      <div class="form-group col-md-12 col-sm-12">
        <label for="formatMethod" class="bmd-label-static">
          Transfer Method
        </label>
        <select
          class="form-control"
          name="transortMethod"
          formControlName="transportMethod"
          [(ngModel)]="model.transportMethodText"
          (change)="loadTransportEditor()"
        >
          <option [ngValue]="null" selected disabled>
            Select a Transfer Method
          </option>
          <option
            *ngFor="let transport of availableTransports"
            [ngValue]="transport.key.toLowerCase()"
          >
            {{ transport.value }}
          </option>
        </select>
      </div>
    </div>
  </div>
  <div>
    <template #transportEditor></template>
  </div>
  <div class="form-row">
    <div class="col">
      <h3>Fields to Export</h3>
      <div class="form-group col-12">
        <ul
          class="list-group"
          dragula="exportColumns"
          [(dragulaModel)]="model.exportColumns"
        >
          <li *ngFor="let de of model.exportColumns" class="list-group-item">
            <div class="switch">
              <input
                type="checkbox"
                [checked]="de.selected"
                (change)="toggleDESelected(de, $event)"
              />
              {{ de.templateCode }}
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <div class="form-row" [hidden]="model.outputFormatText != 'onbasedocument'">
    <div class="col">
      <h3>Documents to Export</h3>
      <div formArrayName="exportDocumentColumns" class="form-group col-12">
        <table class="list-group" style="border:2px;">
          <thead>
            <tr>
              <th colspan="2">Template Code</th>
              <th>Document&nbsp;Index</th>
            </tr>
          </thead>
          <tbody
            dragula="exportDocumentColumns"
            [(dragulaModel)]="model.exportDocumentColumns"
          >
            <tr
              *ngFor="let de of model.exportDocumentColumns; let i = index"
              class="documentitem"
            >
              <td class="switch">
                <input
                  type="checkbox"
                  [checked]="de.selected"
                  (change)="toggleDESelected(de, $event)"
                />
              </td>
              <td>{{ de.templateCode }}</td>
              <td>
                <input
                  [attr.id]="'docidx-' + i"
                  (change)="updateDocumentIndex(de, $event)"
                  [formControlName]="i"
                  style="width:80px;"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</form>

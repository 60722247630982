import { MapLayer } from 'src/app/models';
import { MapExtent } from 'src/app/models/mapextent';

export class MapDisplayConfiguration {
  /** ArcGIS map service definition */
  //mapService: MapService;
  mapServiceId: string; //guid

  /** Layer options */
  layers?: MapLayer[];

  /** Show a TOC view next to the map - lets users turn layers on and off */
  showToc = true;

  /** Initial map extent: use WGS84.  null will let the map control get the extent from the layer */
  initialExtent?: MapExtent; // Always WGS84

  /** Basemap name - as defined by arcgis online, or null for no basemap */
  basemapName?: string;

  /** Name of the layer for the selected map service that has the parcel data */
  parcelLayerName?: string;

  /** Name of the field in the parcel layer that has the parcel id values */
  parcelIdField?: string;

  constructor(options?: Partial<MapDisplayConfiguration>) {
    if (options) {
      Object.assign(this, options);
    }
  }
}

import { DateDataEntity } from './data-entities';

export class AgendaType {
  id: string;
  clientId: string;
  name: string;
  description: string;
  header: string;
  defaultItemCount: number;
  agendas?: Agenda[];
  agendaQueueItems?: AgendaQueueItem[];

  constructor(options?: Partial<AgendaType>) {
    if (options) {
      Object.assign(this, options);
    }
  }
}

export class Agenda {
  id: string;
  meetingDateTime: Date;
  meetingDateTimeDE: DateDataEntity;
  description: string;
  agendaTypeId: string;
  agendaType: AgendaType;
  agendaItems: AgendaItem[];
  agendaQueueItems: AgendaQueueItem[];
  otherItems: string;
  otherMinutes: string;

  constructor(options?: Partial<Agenda>) {
    if (options) {
      Object.assign(this, options);
    }
  }
}

export class AgendaItem {
  id: string;
  agendaId: string;
  agenda: Agenda;
  agendaQueueItemId: string;
  agendaQueueItem: AgendaQueueItem;

  constructor(options?: Partial<AgendaItem>) {
    if (options) {
      Object.assign(this, options);
    }
  }
}

export class AgendaQueueItem {
  id: string;
  agendaTypeId: string;
  evaluatedAgendaItem: string;
  minutes: string;
  status: AgendaQueueItemStatus;
  ballotName: string;
  ballotPath: string;
  applicationId: string;
  requestedOn: Date;
  activityDataId: string;
  canEdit: boolean;
}

export enum AgendaQueueItemStatus {
  Unheard = 1,
  Tabled = 2,
  Approved = 3,
  Denied = 4
}

export class AgendaPagedRequest {
  agendaId: string;
  pageNumber: number;

  constructor(options?: Partial<AgendaPagedRequest>) {
    if (options) {
      Object.assign(this, options);
    }
  }
}

export class AgendaPagedResponse {
  recordCount: number;
  pageCount: number;
  agenda: Agenda;
}

export class AgendaQueueItemsPagedRequest {
  agendaTypeId: string;
  pageNumber: number;

  constructor(options?: Partial<AgendaQueueItemsPagedRequest>) {
    if (options) {
      Object.assign(this, options);
    }
  }
}

export class AgendaQueueItemsPagedResponse {
  recordCount: number;
  pageCount: number;
  agendaType: AgendaType;
}

<!-- <p>
  workflow-applications-needing-my-attention works!
</p> -->
<wm-workflow-application-list
  [id]="id"
  (recordCountSet)="setRecordCount($event)"
  [workflowId]="workflowId"
  [workflows]="workflows"
  [staticFilters]="myWorkflowsFilter"
  (updateStatus)="updateStatusEvent($event)"
  (dataLoaded)="emitDataLoaded()"
  (filtersChanged)="filtersChange()"
>
</wm-workflow-application-list>

<div class="row" *ngIf="securable && showExpandCollapse && canManageRoles">
  <div class="col-lg-12">
    <div class="form-group">
      <a style="cursor: pointer" (click)="showAdvancedPermissions()">{{
        shouldShowAdvancePermissions
          ? 'Hide Permissions'
          : 'Advanced Permissions'
      }}</a>
    </div>
  </div>
</div>
<div class="row" *ngIf="shouldShowAdvancePermissions && securable">
  <div class="col-lg-12">
    <div class="card">
      <div class="card-header">
        <h4>{{ permissionTitle || 'Need Title' }}</h4>
      </div>
      <div class="card-body">
        <div ngbAccordion class="card">
          <div
            ngbAccordionItem="{{ r.id }}"
            *ngFor="let r of roles"
            [id]="r.id"
            class="card-header"
          >
            <h2 ngbAccordionHeader>
              <button
                ngbAccordionToggle
                class="p-0 btn btn-link text-start ps-0"
              >
                {{ r.name }}
              </button>
            </h2>
            <div ngbAccordionCollapse class="card-body">
              <div ngbAccordionBody>
                <ng-template>
                  <ul class="list-group">
                    <li
                      *ngFor="let a of availableActions"
                      class="list-group-item"
                    >
                      <div class="row">
                        <div class="col">
                          <input
                            type="checkbox"
                            [disabled]="isInherited(r.id, a)"
                            [attr.title]="disabledTooltip(r.id, a)"
                            [checked]="hasPermission(r.id, a)"
                            (click)="
                              togglePermission(
                                r.id,
                                a,
                                $event.srcElement.checked
                              )
                            "
                          />
                          {{ getActionName(a) }}
                        </div>
                      </div>
                    </li>
                  </ul>
                </ng-template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="!externalDataConnections">
  <div class="col-xs-12">
    <div>Loading external data connections...</div>
  </div>
</div>
<div *ngIf="externalDataConnections && externalDataConnections.length === 0">
  <div class="col-xs-12">
    <div>No external data connections to display</div>
  </div>
</div>
<div *ngIf="externalDataConnections && externalDataConnections.length > 0">
  <ul class="list-group">
    <li
      *ngFor="let externalDataConnection of externalDataConnections"
      class="list-group-item"
      [ngStyle]="{ cursor: allowSelect ? 'pointer' : 'default' }"
      (click)="editExternalDataConnnection(externalDataConnection)"
    >
      <div class="row">
        <div [class]="showRemove ? 'col-xs-10' : 'col-xs-12'">
          <h5>{{ externalDataConnection.name }}</h5>
        </div>
        <div *ngIf="showRemove" class="col-xs-2">
          <wm-modal-confirm
            [commandText]="'Remove External Data Connection'"
            [id]="externalDataConnection.id"
            [title]="'Remove External Data Connection'"
            (accepted)="removed.emit($event)"
          >
            <h4>
              Are you sure you want to remove this map service ({{
                externalDataConnection.name
              }})?
            </h4>
            <div>
              <h4>Disabling this contractor will do the following:</h4>
              <ul>
                <li>
                  Keep this contractor from being selected as a contractor on
                  applications.
                </li>
              </ul>
            </div>
          </wm-modal-confirm>
        </div>
      </div>
    </li>
  </ul>
</div>

import { ToastrService } from 'ngx-toastr';
import {
  FilterBuilderParam,
  FilterBuilderOutput
} from 'src/app/models/filter-builder';
import { PagedContractorFeeSummaryReportResponse } from './../../../../models/contractor-reports';
import { Component, OnInit } from '@angular/core';
import {
  ContractorService,
  DataService,
  WorkflowContextService
} from 'src/app/services';
import { Utilities } from 'src/app/services';
import { ColumnOption, GridSettings } from 'src/app/models/grid-settings';
import { TableColumn } from '@swimlane/ngx-datatable';
import { ComponentIds } from 'src/app/models/component-ids';

@Component({
  selector: 'wm-contractor-fee-summary',
  templateUrl: './fee-summary.component.html',
  styleUrls: ['./fee-summary.component.css']
})
export class FeeSummaryComponent implements OnInit {
  id = ComponentIds.CONTRACTOR_FEE_SUMMARY;
  loading = true;
  report: PagedContractorFeeSummaryReportResponse;
  params: FilterBuilderParam[] = [
    {
      name: 'Confirmed Date',
      id: 'timeframe',
      inputType: 'date',
      types: ['range']
    }
  ];
  filters: FilterBuilderOutput = {};

  public useExcelFormat: boolean;

  columns: TableColumn[];
  availableColumns: TableColumn[];
  columnOptions: ColumnOption[] = [];
  exportColumns: string[];
  rows: any[];
  public page = {
    // The number of elements in the page
    size: 10,
    // The total number of elements
    totalElements: 0,
    // The total number of pages
    totalPages: 0,
    // The current page number
    pageNumber: 0
  };
  public sortField: string;
  public sortDescending = false;

  lastQuery = {};

  // column prop strings to be hidden on the grid by default
  defaultHiddenColumns: string[] = [];
  // column prop strings that should have no option to hide in the grid, and are not exportable (such as button columns like Refund or Actions)
  actionColumns: string[] = [];
  // column prop strings to be excluded from exports by default
  defaultExportExludedColumns: string[] = [];

  constructor(
    private contractorSvc: ContractorService,
    private dataSvc: DataService,
    private toast: ToastrService,
    private _context: WorkflowContextService
  ) {}

  ngOnInit() {
    this.columns = [
      {
        prop: 'registrationType',
        name: 'Registration Type'
      },
      {
        prop: 'total',
        name: 'Total'
      },
      {
        prop: 'amount',
        name: 'Amount'
      },
      {
        prop: 'registrations',
        name: 'Registrations'
      },
      {
        prop: 'registrationAmount',
        name: 'Registration Amount'
      },
      {
        prop: 'renewals',
        name: 'Renewals'
      },
      {
        prop: 'renewalAmount',
        name: 'Renewal Amount'
      }
    ];
    this.setAvailableColumns();
  }

  setAvailableColumns() {
    this.availableColumns = this.columns;
  }

  isEquivalent(a: any, b: any) {
    const aProps = Object.getOwnPropertyNames(a);
    const bProps = Object.getOwnPropertyNames(b);

    if (aProps.length !== bProps.length) {
      return false;
    }

    for (let i = 0; i < aProps.length; i++) {
      const propName = aProps[i];
      if (a[propName] !== b[propName]) {
        return false;
      }
    }

    return true;
  }

  handleSettingsChanged(event: GridSettings) {
    this.filters = event.filters;
    this.page.size = event.pageSize;
    this.page.pageNumber = event.pageNumber;
    this.sortField = event.sortField;
    this.sortDescending = event.sortDescending;
    this.columnOptions = event.columnOptions;

    const visibleColumnProps = this.columnOptions
      .filter(co => co.checked)
      .map(co => co.name);

    this.columns = this.availableColumns.filter(
      c =>
        visibleColumnProps.includes(c.prop as string) ||
        this.actionColumns.includes(c.prop as string)
    );

    this.exportColumns = this.columnOptions
      .filter(co => co.includeInExport)
      .map(co => co.name);

    this.getReport();
  }

  handleColumnOptionsChanged(event: ColumnOption[]) {
    this.columnOptions = event;

    this.exportColumns = this.columnOptions
      .filter(co => co.includeInExport)
      .map(co => co.name);
  }

  getQuery() {
    const timeframe = this.filters['timeframe'];

    const query = {
      clientId: this._context.client.id,
      timeframe: timeframe && timeframe.value,
      useExcelFormat: this.useExcelFormat,
      sortField: this.sortField,
      sortDescending: this.sortDescending,
      exportColumns: this.exportColumns
    };

    return query;
  }

  getPagedQuery() {
    const timeframe = this.filters['timeframe'];

    const query = {
      clientId: this._context.client.id,
      timeframe: timeframe && timeframe.value,
      pageNumber: this.page.pageNumber,
      pageSize: this.page.size,
      sortField: this.sortField,
      sortDescending: this.sortDescending
    };

    return query;
  }

  getReport() {
    const query = this.getPagedQuery();

    if (!this.isEquivalent(query, this.lastQuery)) {
      this.lastQuery = { ...query };
      this.loading = true;
      this.contractorSvc
        .getPagedContractorFeeSummaryReport(query)
        .subscribe(report => {
          this.page.totalElements = report.recordCount;
          this.page.totalPages = report.pageCount;
          this.rows = report.items.map(i => {
            i['registrationType'] = i.registrationType;
            i['total'] = i.count;
            i['amount'] = Utilities.formatCurrency(i.amount);
            i['registrations'] = i.registrationCount;
            i['registrationAmount'] = Utilities.formatCurrency(
              i.registrationAmount
            );
            i['renewals'] = i.renewalCount;
            i['renewalAmount'] = Utilities.formatCurrency(i.renewalAmount);
            return i;
          });
          this.report = report;
          this.loading = false;
        });
    }
  }

  exportExcel() {
    this.useExcelFormat = true;
    this.export();
  }

  exportCsv() {
    this.useExcelFormat = false;
    this.export();
  }

  export() {
    const query = this.getQuery();
    this.dataSvc.exportContractorFeeSummaryReport(query).subscribe(
      data => {
        if (this.useExcelFormat) {
          const blob = new Blob([data], {
            type:
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          });
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = `contractor-fee-summary-report-export-${new Date().getTime()}.xlsx`;
          link.click();
        } else {
          const blob = new Blob([data], { type: 'text/csv' });
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = `contractor-fee-summary-report-export-${new Date().getTime()}.csv`;
          link.click();
        }
      },
      err => {
        this.toast.error('Problem while downloading the file.');
        console.error(err);
      }
    );
  }
}

import { Component, OnInit, forwardRef, Inject } from '@angular/core';
import { DocumentDataEntityComponent } from '../../document-data-entity/document-data-entity.component';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import {
  WorkflowService,
  WorkflowContextService,
  SystemService,
  ClientService,
  DataService
} from '../../../../../services';
import { Subscription } from 'rxjs';
import { InspectionDocumentDataEntity } from 'src/app/models/data-entities/inspector-document-data-entity';

@Component({
  selector: 'wm-inspection-document-data-entity-editor',
  templateUrl: './inspection-document-data-entity-editor.component.html',
  styleUrls: ['./inspection-document-data-entity-editor.component.css']
})
export class InspectionDocumentDataEntityEditorComponent
  extends DocumentDataEntityComponent
  implements OnInit {
  inspectionDocEntity: InspectionDocumentDataEntity;

  constructor(
    _fb: UntypedFormBuilder,
    _route: ActivatedRoute,
    @Inject(forwardRef(() => WorkflowService))
    _workflowSvc: WorkflowService,
    @Inject(forwardRef(() => WorkflowContextService))
    _context: WorkflowContextService,
    @Inject(forwardRef(() => SystemService)) _systemSvc: SystemService,
    @Inject(forwardRef(() => ClientService)) _clientSvc: ClientService,
    @Inject(forwardRef(() => DataService)) _dataSvc: DataService
  ) {
    super(
      _fb,
      _route,
      _workflowSvc,
      _context,
      _systemSvc,
      _clientSvc,
      _dataSvc
    );
  }

  loadCategories() {}

  selectCategory(categoryId: string) {}

  ngOnInit() {
    this.inspectionDocEntity = this.dataEntity as InspectionDocumentDataEntity;

    this.loadCategories();

    this.form.addControl(
      'documentCategory',
      this._fb.control('', Validators.nullValidator)
    );
  }
}

<div class="card mb-4 card-grey">
  <div class="card-header" style="font-weight: bold">
    Condition Entity Values
  </div>
  <div class="card-body">
    <table class="table">
      <tr>
        <th>Template Code</th>
        <th>Value</th>
      </tr>
      <tr *ngFor="let v of conditionAppData">
        <td>{{ v.templateCode }}</td>
        <td>{{ v.value }}</td>
      </tr>
    </table>
  </div>
</div>

<wm-decision-activity-editor
  [activity]="activity"
  [form]="form"
></wm-decision-activity-editor>

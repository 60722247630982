import { MapDimensionLayout_htmlClassName } from './../../../models/data-entities/mapsketch-data-entity';
import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  Query
} from '@angular/core';

//Esri bits:

// the esri-loader npm module defines the default api version, but you have to also include that version in the css ref in the .css file.
import esri = __esri;
import { MapLayer } from 'src/app/models';
import { MapExtent } from 'src/app/models/mapextent';
import { EsriLoaderService } from 'src/app/components/arcgis/esri-loader.service';
import { MapImageLayerService } from '../mapimagelayer.service';
import { Extent } from 'esri/geometry';
import { Utilities } from 'src/app/services';

@Component({
  selector: 'app-esri-map-designtime',
  templateUrl: './esri-map-designtime.component.html',
  styleUrls: ['./esri-map-designtime.component.css']
})
export class EsriMapDesigntimeComponent implements OnInit, OnChanges {
  @Output()
  mapLoaded = new EventEmitter();
  @Output()
  mapExtentChanged = new EventEmitter<MapExtent>();
  @Output()
  mapImageLayerChanged = new EventEmitter<MapLayer[]>();
  @Output()
  mapImageLayerFirstLoad = new EventEmitter<MapLayer[]>();
  @Output()
  featuresIdentified = new EventEmitter<string[]>();
  @Output()
  featuresBeforeIdentifying = new EventEmitter();
  @Input()
  @Input()
  mapDimensionLayout_class?: MapDimensionLayout_htmlClassName;

  @ViewChild('mapViewNode', { static: true })
  private mapViewEl: ElementRef;
  @ViewChild('tocViewNode', { static: true })
  private tocViewEl: ElementRef;

  @Input()
  useSketchLayer = false;

  /* --------------------------------------------------------------------------------------------------- */
  /* MapdisplayConfiguration Object Binding - separate properties for now to keep change detection happy */

  @Input()
  mapServiceId: string;

  @Input()
  initialExtent?: MapExtent; // This should always be WGS84 coords

  @Input()
  basemapName?: string;

  @Input()
  showToc: boolean;

  @Input()
  mapLayerOptions: MapLayer[] = null;

  /* --------------------------------------------------------------------------------------------------- */

  private map: esri.Map;
  private mapView: esri.MapView;
  private mapLayer: esri.MapImageLayer;

  constructor(
    private esriLoaderService: EsriLoaderService,
    private mapImageLayerService: MapImageLayerService
  ) {}

  async initializeMap() {
    const [
      EsriMap,
      EsriMapView,
      LayerList,
      watchUtils,
      Extent,
      SpatialReference
    ] = await this.esriLoaderService.LoadModules([
      'esri/Map',
      'esri/views/MapView',
      'esri/widgets/LayerList',
      'esri/core/watchUtils',
      'esri/geometry/Extent',
      'esri/geometry/SpatialReference'
    ]);

    if (!this.map && this.mapServiceId) {
      // Set type of map
      const mapProperties: esri.MapProperties = {
        basemap: this.basemapName
      };
      this.map = new EsriMap(mapProperties);

      // Set type of map view
      const mapViewProperties: esri.MapViewProperties = {
        container: this.mapViewEl.nativeElement,
        map: this.map,
        spatialReference: SpatialReference.WebMercator // Required for any of the esri basemaps to work.
      };

      if (this.initialExtent) {
        const initExt = new Extent(
          this.initialExtent.xmin,
          this.initialExtent.ymin,
          this.initialExtent.xmax,
          this.initialExtent.ymax
        );
        initExt.spatialReference = SpatialReference.WGS84;
        mapViewProperties.extent = initExt;
      } else {
        // required when there is no basemap to make the map initialize correctly
        mapViewProperties.center = [0, 0];
        mapViewProperties.scale = 1000000;
      }

      this.mapView = new EsriMapView(mapViewProperties);

      // We can't await a mapview with no layers, it kinda never will load.  If we used a basemap or initialized with some layers, this probably would be ok.
      //await this.mapView.when();
      //this.mapLoaded.emit(true);

      // wire up extent tracking
      watchUtils.whenTrue(this.mapView, 'stationary', async () => {
        if (this.mapView.extent) {
          const ext = await this.getCurrentMapExtentWGS84();
          this.mapExtentChanged.emit(ext);
        }
      });

      const layerList = new LayerList({
        view: this.mapView,
        container: this.tocViewEl.nativeElement
      });
    }
  }

  async getCurrentMapExtentWGS84() {
    const [
      Extent,
      SpatialReference,
      webMercatorUtils
    ] = await this.esriLoaderService.LoadModules([
      'esri/geometry/Extent',
      'esri/geometry/SpatialReference',
      'esri/geometry/support/webMercatorUtils'
    ]);

    if (!this.mapView.extent) {
      throw new Error('No map extent has been defined');
    }

    const wgs84Ext = webMercatorUtils.webMercatorToGeographic(
      this.mapView.extent
    );
    return new MapExtent(
      wgs84Ext.xmin,
      wgs84Ext.ymin,
      wgs84Ext.xmax,
      wgs84Ext.ymax
    );
  }

  async ChangeMapServerURL(firstUse: boolean) {
    if (!this.map) {
      await this.initializeMap();
    }

    const [
      MapImageLayer,
      projection,
      SpatialReference
    ] = await this.esriLoaderService.LoadModules([
      'esri/layers/MapImageLayer',
      'esri/geometry/projection',
      'esri/geometry/SpatialReference'
    ]);

    if (this.mapLayer) {
      this.map.layers.remove(this.mapLayer);
    }
    this.mapLayer = new MapImageLayer({
      url: this.getMapServiceEndpointUrl()
      // sublayers: (this.mapLayerOptions) ? this.createSublayerObject(this.mapLayerOptions) : null
    });

    this.map.layers.add(this.mapLayer, 0);
    await this.mapLayer.when();
    await this.mapView.whenLayerView(this.mapLayer);

    this.updateSublayers();

    const mapLayerInfoUpdate = this.mapImageLayerService.getLayers(
      this.mapLayer
    );
    if (firstUse) {
      this.mapImageLayerFirstLoad.emit(mapLayerInfoUpdate);
    } else {
      this.mapImageLayerChanged.emit(mapLayerInfoUpdate);
    }

    // if we've got a defined initial extent, zoom for initial view
    if (this.initialExtent) {
      this.zoomToInitialExtent();
    } else {
      // need to reproject it...  load the magic esri projector sauce.  It uses Web Assemblies - so if you are here to add IE support, you'll be sad.
      if (!projection.isLoaded()) {
        await projection.load();
      }
      const wmExt: esri.Extent = projection.project(
        this.mapLayer.fullExtent,
        SpatialReference.WebMercator
      );
      wmExt.expand(1.2);
      this.mapView.goTo(wmExt);
    }

    if (firstUse) {
      this.mapLoaded.emit();
    }
  }

  // createSublayerObject(layers: MapLayer[]): any[] /*esri.Sublayer[]*/ {
  //   return layers.map((layer: MapLayer, idx) => {
  //     return {
  //       id: layer.id,
  //       visible: layer.visible
  //     }
  //   });
  // }

  updateSublayers() {
    if (!this.mapLayer) {
      return;
    }
    if (!this.mapLayerOptions) {
      return;
    }

    this.mapLayerOptions.forEach((layer, idx) => {
      const mapSubLayer = this.mapLayer.findSublayerById(layer.id);
      if (mapSubLayer) {
        mapSubLayer.visible = layer.visible;
      }
    });
  }

  getMapServiceEndpointUrl(): string {
    const apiUrl = Utilities.getRootURL();
    if (this.mapServiceId) {
      return `${apiUrl}/api/mapproxy/mapserver/${this.mapServiceId}`;
    } else {
      return null;
    }
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes.mapServiceId && changes.mapServiceId.currentValue) {
      await this.ChangeMapServerURL(changes.mapServiceId.previousValue == null);
    }
    if (changes.basemapName) {
      this.map && this.map.set('basemap', this.basemapName);
    }
  }

  async ngOnInit() {
    await this.initializeMap();
  }

  zoom(ext: Extent) {
    this.mapView.goTo(ext);
  }

  async zoomToInitialExtent() {
    const [
      Extent,
      SpatialReference,
      projection
    ] = await this.esriLoaderService.LoadModules([
      'esri/geometry/Extent',
      'esri/geometry/SpatialReference',
      'esri/geometry/projection'
    ]);

    if (this.initialExtent) {
      const initExt = new Extent(
        this.initialExtent.xmin,
        this.initialExtent.ymin,
        this.initialExtent.xmax,
        this.initialExtent.ymax
      );
      initExt.spatialReference = SpatialReference.WGS84;
      //    this.mapView.extent = initExt;
      this.mapView.goTo(initExt);
    } else {
      // need to reproject it...  load the magic esri projector sauce.  It uses Web Assemblies - so if you are here to add IE support, you'll be sad.
      if (!projection.isLoaded()) {
        await projection.load();
      }
      const wmExt: esri.Extent = projection.project(
        this.mapLayer.fullExtent,
        SpatialReference.WebMercator
      );
      wmExt.expand(1.2);
      this.mapView.goTo(wmExt);
    }
  }
}

import {
  Component,
  OnInit,
  EventEmitter,
  ViewChild,
  Input
} from '@angular/core';
import { ActivityView } from 'src/app/views/master-views/app.view/app.view.component';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { WorkflowContextService } from 'src/app/services';
import { EsriMapComponent } from 'src/app/components/arcgis/esri-map/esri-map.component';
import { MapActivity } from 'src/app/models/activities/map-activity';
import { MapsketchData } from 'src/app/components/arcgis/mapsketch-data';
import { MapExtent } from 'src/app/models/mapextent';
import { GeomUtils } from 'src/app/components/arcgis/geom-utils';

@Component({
  selector: 'wm-map-activity-input',
  templateUrl: './map-activity-input.component.html',
  styleUrls: ['./map-activity-input.component.css']
})
export class MapActivityInputComponent implements OnInit, ActivityView {
  @Input() activity: MapActivity;
  isPreview: boolean;
  isTestApplication: boolean;
  showSave?: EventEmitter<boolean>;
  showCancel?: EventEmitter<boolean>;
  showNext?: EventEmitter<boolean>;
  nextOnSelect: true;
  showPrevious?: EventEmitter<boolean>;
  goNext: EventEmitter<boolean> = new EventEmitter<boolean>();
  value$: EventEmitter<any>;
  initActivity: any;
  form: UntypedFormGroup;
  context: WorkflowContextService;

  @ViewChild(EsriMapComponent, { static: true })
  private esriMap: EsriMapComponent;

  constructor(private fb: UntypedFormBuilder) {}
  switchApplication?: EventEmitter<string>;
  applicationId?: string;

  async persistChildComponent() {
    return this.esriMap.persistChildComponent();
  }

  viewTitle(): string {
    return this.activity.model.screenName;
  }

  ngOnInit() {
    this.form.addControl(
      'mapstate',
      this.fb.control('', [Validators.required])
    );
  }

  next() {
    //this.select(r);
    this.goNext.emit(true);
  }

  async onMapLoaded() {
    if (this.activity.model.selectedGeometryWkt) {
      const geom = GeomUtils.WktToArcGisGeom(
        this.activity.model.selectedGeometryWkt
      );
      const graphic = {
        geometry: geom
      };
      await this.esriMap.setSketchData(
        [graphic],
        this.activity.model.mapExtent,
        null
      );
      this.form.controls['mapstate'].setValue('ok');
    }
  }

  onMapExtentChanged(ext: MapExtent) {
    this.activity.model.mapExtent = ext;
  }

  async onMapSketchDataChange(sdc: MapsketchData) {
    if (!sdc.graphics || sdc.graphics.length == 0) {
      // cleared
      this.activity.model.selectedGeometryWkt = null;
      this.activity.model.mapExtent = null;
      this.form.controls['mapstate'].setValue(null);
    } else {
      const geom = sdc.graphics[0].geometry;
      const wkt = geom != null ? GeomUtils.ArcGisGeomToWkt(geom) : null;
      this.activity.model.selectedGeometryWkt = wkt;
      this.activity.model.mapExtent = sdc.extent;
      this.form.controls['mapstate'].setValue('ok');
    }
  }
}

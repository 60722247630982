import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'wm-unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.css']
})
export class UnauthorizedComponent implements OnInit {
  constructor(private route: ActivatedRoute) {}
  message: string;

  ngOnInit() {
    this.route.queryParams.subscribe(parms => {
      this.message = parms['message'];
    });
  }
}

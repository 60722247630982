<div class="row" *ngIf="customDataEntities">
  <div class="col">
    <wm-data-entity-input-list
      [entities]="customDataEntities"
      [form]="form"
      *ngIf="!isReadOnly"
    ></wm-data-entity-input-list>
    <wm-data-entity-view-list
      [entities]="customDataEntities"
      *ngIf="isReadOnly"
    ></wm-data-entity-view-list>
  </div>
</div>

<ng-container *ngIf="!isNew">
  <p>
    Created by: {{ scheduledExport.owner.name }} ({{
      scheduledExport.owner.userName
    }})
    <wm-help-text
      placement="right"
      helpText="The data contained in this export will only be data that this export&#39;s creator has permission to view."
    ></wm-help-text>
  </p>
</ng-container>

<form [formGroup]="form" *ngIf="form">
  <!-- export name and enable/disable-->
  <div class="row">
    <div class="col-6">
      <div class="form-group">
        <label class="bmd-label-floating" for="workflowName">Export Name</label>
        <input
          type="text"
          class="form-control"
          [(ngModel)]="scheduledExport.name"
          name="workflowName"
          formControlName="name"
        />
        <wm-control-message
          [control]="form.controls['name']"
        ></wm-control-message>
      </div>
    </div>
    <ng-container *ngIf="scheduledExport.isEnabled">
      <div class="col-4">
        This export is active. <br />
        It will send exports based <br />
        on the configured schedule. <br />
      </div>
      <div class="col-2 align-self-center p-0">
        <button class="btn btn-raised btn-primary" (click)="disable()">
          Disable
        </button>
      </div>
    </ng-container>
    <ng-container *ngIf="!scheduledExport.isEnabled">
      <div class="col-4">
        This export is disabled. <br />
        It will not send any exports <br />
        until it is enabled. <br />
      </div>
      <div class="col-2 align-self-center p-0">
        <button class="btn btn-raised btn-primary" (click)="enable()">
          Enable
        </button>
      </div>
    </ng-container>
  </div>

  <!-- filters and columns editor -->
  <br />
  <div class="row">
    <div class="col">
      <wm-filter-builder
        *ngIf="defaultFilters"
        parentId="scheduled-export-editor"
        [showSimpleSearch]="false"
        [savedFilters]="false"
        [params]="params"
        [defaultFilters]="defaultFilters"
        [columnOptions]="columnOptions"
        [onlyExportOptions]="true"
        (filtersChange)="handleFiltersChanged($event)"
        (exportColumnsChange)="handleColumnsChanged($event)"
        [shapefileValidationCriteria]="shapefileValidationCriteria"
      ></wm-filter-builder>
      <div *ngIf="filterForm" class="mb-3">
        <wm-control-message
          *ngFor="let k of filterFormKeys"
          [control]="filterForm.controls[k]"
          [prefix]="getFilterPrefix(k) + ' Filter is '"
        ></wm-control-message>
      </div>
    </div>
  </div>

  <!-- email editor -->
  <h6>Email Recipients</h6>

  <form [formGroup]="roleRecipientForm">
    <div class="form-row">
      <div class="col">
        <label class="bmd-label-static" for="role">Role</label>
        <select
          class="form-control"
          id="role"
          [(ngModel)]="selectedRole"
          formControlName="role"
        >
          <option></option>
          <option *ngFor="let r of availableRoles" [value]="r.id">
            {{ r.name }}
          </option>
        </select>
      </div>
      <div class="col-auto align-self-center">
        <button
          type="button"
          class="btn btn-raised btn-primary"
          [disabled]="roleRecipientForm.invalid"
          (click)="addRole()"
        >
          Add
        </button>
      </div>
    </div>
  </form>

  <form [formGroup]="toEmailForm">
    <div class="form-row">
      <div class="col">
        <div class="form-group">
          <label class="bmd-label-floating" for="recipient"
            >Recipient Email Address</label
          >
          <input
            type="text"
            class="form-control"
            name="recipient"
            formControlName="newEmailAddress"
          />
          <wm-control-message
            [control]="toEmailForm.controls['newEmailAddress']"
          ></wm-control-message>
        </div>
      </div>

      <div class="col-2">
        <label class="bmd-label-static" for="emailRecipientType">
          Type
        </label>
        <select
          class="form-control"
          id="emailRecipientType"
          formControlName="newEmailType"
        >
          <option value="to" selected>To</option>
          <option value="cc">CC</option>
          <option value="bcc">BCC</option>
        </select>
      </div>
      <div class="col-auto align-self-center">
        <button
          type="button"
          class="btn btn-raised btn-primary"
          [disabled]="
            !toEmailForm.valid || !toEmailForm.controls['newEmailAddress'].value
          "
          (click)="addEmail()"
        >
          Add
        </button>
      </div>
    </div>
  </form>

  <ul class="list-group">
    <li
      class="list-group-item d-flex justify-content-between align-items-start"
      *ngFor="
        let toAddress of scheduledExport.scheduledExportEmailOptions
          .toRecipients
      "
    >
      <span class="align-self-center">{{ toAddress }}</span>

      <div class="d-flex align-items-center">
        <span class="pr-3">To</span>
        <a style="cursor: pointer" (click)="removeToAddress(toAddress)">
          <i class="material-icons">
            delete
          </i>
        </a>
      </div>
    </li>
    <li
      class="list-group-item d-flex justify-content-between align-items-start"
      *ngFor="
        let bccAddress of scheduledExport.scheduledExportEmailOptions
          .bccRecipients
      "
    >
      <span class="align-self-center">{{ bccAddress }}</span>

      <div class="d-flex align-items-center">
        <span class="pr-3">BCC</span>
        <a style="cursor: pointer" (click)="removeBCCAddress(bccAddress)">
          <i class="material-icons">
            delete
          </i>
        </a>
      </div>
    </li>
    <li
      class="list-group-item d-flex justify-content-between align-items-start"
      *ngFor="
        let ccAddress of scheduledExport.scheduledExportEmailOptions
          .ccRecipients
      "
    >
      <span class="align-self-center">{{ ccAddress }}</span>

      <div class="d-flex align-items-center">
        <span class="pr-3">CC</span>
        <a style="cursor: pointer" (click)="removeCCAddress(ccAddress)">
          <i class="material-icons">
            delete
          </i>
        </a>
      </div>
    </li>
    <li
      class="list-group-item d-flex justify-content-between align-items-start"
      *ngFor="
        let role of scheduledExport.scheduledExportEmailOptions.roleRecipients
      "
    >
      <span class="align-self-center">Role: {{ role.name }}</span>

      <div class="d-flex align-items-center">
        <span class="pr-3">To</span>
        <a style="cursor: pointer" (click)="removeRole(role)">
          <i class="material-icons">
            delete
          </i>
        </a>
      </div>
    </li>
    <wm-control-message
      [control]="form.controls['recipientSpecified']"
    ></wm-control-message>
  </ul>

  <div class="form-group">
    <label class="bmd-label-floating" for="subject">Subject</label>
    <input
      type="text"
      class="form-control"
      #emailSubject
      [(ngModel)]="scheduledExport.scheduledExportEmailOptions.subject"
      formControlName="subject"
      name="subject"
    />
    <wm-control-message
      [control]="form.controls['subject']"
    ></wm-control-message>
  </div>

  <div class="form-group">
    <label class="bmd-label-static" for="body">Body</label>
    <ckeditor
      #emailBody
      [editor]="Editor"
      [config]="editorConfig"
      (change)="onChange($event)"
      formControlName="body"
    ></ckeditor>
    <wm-control-message [control]="form.controls['body']"></wm-control-message>
  </div>

  <!-- export format -->
  <div class="row">
    <div class="col form-group">
      <label class="bmd-label-floating" for="exportFormat">
        Export Format
      </label>
      <select
        [(ngModel)]="scheduledExport.exportFormat"
        class="form-control"
        name="exportFormat"
        formControlName="exportFormat"
      >
        <option
          value="1"
          [disabled]="
            shapefileValidationCriteria &&
            shapefileValidationCriteria.isGeoJSONSelected
          "
          [ngClass]="
            shapefileValidationCriteria &&
            shapefileValidationCriteria.isGeoJSONSelected == true
              ? 'tooltip-wrapper'
              : ''
          "
          placement="right"
          [title]="
            shapefileValidationCriteria &&
            shapefileValidationCriteria.isGeoJSONSelected == true
              ? shapefileValidationCriteria.tooltipErrors
                  .csvXlsShapefileOnWarning
              : ''
          "
          >Excel (.xlsx)</option
        >
        <option
          value="2"
          [disabled]="
            shapefileValidationCriteria &&
            shapefileValidationCriteria.isGeoJSONSelected
          "
          [ngClass]="
            shapefileValidationCriteria &&
            shapefileValidationCriteria.isGeoJSONSelected == true
              ? 'tooltip-wrapper'
              : ''
          "
          placement="right"
          [title]="
            shapefileValidationCriteria &&
            shapefileValidationCriteria.isGeoJSONSelected == true
              ? shapefileValidationCriteria.tooltipErrors
                  .csvXlsShapefileOnWarning
              : ''
          "
          >Comma-Separated Values (.csv)</option
        >
        <option
          value="3"
          *ngIf="shapefileValidationCriteria"
          [disabled]="
            shapefileValidationCriteria &&
            !shapefileValidationCriteria.isGeoJSONSelected
          "
          [ngClass]="
            shapefileValidationCriteria &&
            !shapefileValidationCriteria.isGeoJSONSelected == true
              ? 'tooltip-wrapper'
              : ''
          "
          placement="bottom"
          [title]="
            !shapefileValidationCriteria.isGeoJSONSelected == true
              ? shapefileValidationCriteria.tooltipErrors.shapefileOffWarning
              : shapefileValidationCriteria.showShapeFieldError == true
              ? shapefileValidationCriteria.tooltipErrors
                  .shapefileExcessFieldsWarning
              : ''
          "
          >Shapefile (.zip)</option
        >
      </select>
      <wm-control-message
        [control]="form.controls['exportFormat']"
      ></wm-control-message>
    </div>
  </div>

  <!-- schedule options -->
  <div class="row mt-3">
    <div class="col">
      <wm-schedule-editor
        #scheduleEditor
        [type]="scheduledExport.frequencyType"
        [options]="scheduledExport.scheduleFrequencyOptions"
        [existingNextExportOn]="scheduledExport.nextExportOnString"
        [form]="form"
      ></wm-schedule-editor>
    </div>
  </div>
</form>

<wm-modal-confirm
  #deleteConfirmModal
  [title]="'Delete Scheduled Export - ' + scheduledExport.name"
  [showButton]="false"
  commandText="Delete"
  (accepted)="confirmDelete()"
>
  This export will no longer be sent. Are you sure you want to delete the
  scheduled export '{{ scheduledExport.name }}'?
</wm-modal-confirm>

import { Component, OnInit, EventEmitter } from '@angular/core';
import { ActivityView } from '../../../../../views/master-views/app.view/app.view.component';
import { WorkflowContextService } from '../../../../../services';
import { FormGroup } from '@angular/forms';
import { Activity, ActivityModel } from '../../../../../models/activities';

@Component({
  selector: 'wm-contractor-info-form-activity-view',
  templateUrl: './contractor-info-form-activity-view.component.html',
  styleUrls: ['./contractor-info-form-activity-view.component.css']
})
export class ContractorInfoFormActivityViewComponent extends ActivityView
  implements OnInit {

  constructor() {
    super();
  }

  ngOnInit() {}
}

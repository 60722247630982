import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'wm-metric',
  templateUrl: './metric.component.html',
  styleUrls: ['./metric.component.css']
})
export class MetricComponent implements OnInit {
  @Input() name: string;
  @Input() value: any;

  constructor() {}

  ngOnInit() {}
}

<div *ngIf="showLogin == true">
  <wm-login
    [redirectURL]="router.url"
    (loggedIn)="onLoggedIn()"
    [isPreview]="isPreview"
  ></wm-login>
  <a
    class="nav-link"
    [routerLink]="['/auth/register']"
    [queryParams]="{ returnUrl: returnUrl }"
    [routerLinkActive]="['active']"
    >SIGNUP</a
  >
</div>
<div *ngIf="showLogin == false">
  <div *ngIf="canApplyOnBehalfOf">
    <div class="card">
      <div class="card-body">
        <div>
          <div [formGroup]="userForm">
            <!--       Stack the columns on mobile by making one full-width and the other half-width -->
            <div class="row">
              <div class="col-12 mb-2">
                Apply on behalf of:
              </div>
            </div>
            <div class="row ml-2">
              <div class="col-3 col-sm-3 col-md-4 col-lg-2 col-xl-2">
                <input
                  type="radio"
                  value="anotherUser"
                  formControlName="behalfOf"
                  [checked]="true"
                />
                Existing User
              </div>
              <div
                class="col-9 col-sm-9 col-md-8 col-lg-10 col-xl-10"
                [ngClass]="{
                  disabledInActiveDiv: !behalfOfValue('anotherUser')
                }"
              >
                <div class="card-title">
                  <span *ngIf="this.activity.model.user">{{
                    this.activity.model.user.userName
                  }}</span
                  ><span *ngIf="this.activity.model.user"
                    >&nbsp;<a href="#" (click)="clearUser()">Clear</a></span
                  >
                </div>

                <wm-user-list-view
                  (selected)="selectUser($event)"
                  [raiseEvent]="true"
                  *ngIf="!this.activity.model.userId && !showRegister"
                ></wm-user-list-view>
              </div>
            </div>

            <hr />
            <div class="row ml-2">
              <div class="col-3 col-sm-3 col-md-4 col-lg-2 col-xl-2">
                <input
                  type="radio"
                  value="registerNewUser"
                  formControlName="behalfOf"
                  click="startRegister()"
                />
                New User
              </div>
              <div>
                <wm-register
                  *ngIf="showRegister"
                  [showPasswordInput]="false"
                  [returnURL]="returnUrl"
                  [returnApplicationId]="returnApplicationId"
                  (registered)="registered($event)"
                  (canceled)="registerCanceled($event)"
                  [raiseEvents]="true"
                >
                </wm-register>
              </div>
            </div>
            <hr />
            <div class="row ml-2">
              <div class="col-3 col-sm-3 col-md-4 col-lg-2 col-xl-2">
                <input
                  type="radio"
                  value="loggedInUser"
                  formControlName="behalfOf"
                />
                Myself
              </div>
              <div
                class="col-9 col-sm-9 col-md-8 col-lg-10 col-xl-10"
                [ngClass]="{
                  disabledInActiveDiv: !behalfOfValue('loggedInUser')
                }"
              >
                {{ context.user?.userName }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

import { Client } from './client';
import { MapServer } from './map-server';
import { Utilities } from '../services/utilities';
import { MapLayer } from '.';

export class MapService {
  id: string;
  name: string;
  parcelLayer: string;
  endpointUrl: string;
  parcelLayerKeyField: string;
  client: Partial<Client>;
  clientID: string;
  mapKeyValue: string;

  constructor(options?: Partial<MapService>) {
    if (options) {
      Object.assign(this, options);
    }
  }
}

import { Utilities } from './../services/utilities/index';
import { Client } from './client';

export class CustomListItem {
  id: string = Utilities.generateId();
  position: number;
  customListId: string;
  text: string;
  value: string;

  constructor(options?: Partial<CustomListItem>) {
    if (options) {
      Object.assign(this, options);
    }
  }
}

export class CustomList {
  id: string;
  name: string;
  items: CustomListItem[];
  clientId: string;
  client?: Partial<Client>;
  constructor(options?: Partial<CustomList>) {
    if (options) {
      Object.assign(this, options);
    }
  }
}

<div class="row">
  <div class="col">
    <div class="form-group">
      <a [href]="documentPath" target="blank">{{ documentName }}</a>
    </div>
  </div>
</div>
<div class="row" *ngIf="activity.canRegenerate">
  <div class="col">
    <div class="form-group">
      <wm-modal-confirm
        [showButtonText]="true"
        [showButton]="true"
        buttonText="Regenerate"
        commandText="Yes"
        cancelText="No"
        title="Regenerate"
        btnColor="primary"
        (accepted)="regenerateDocument()"
      >
        Are you sure you want to regenerate this PDF?
      </wm-modal-confirm>
      <div *ngIf="regeneratingDocument">Regenerating document...</div>
    </div>
  </div>
</div>

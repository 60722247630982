import { Role } from './../../../models/role';
import { ValidationService } from './../../../services/validation.service';
import { UntypedFormBuilder } from '@angular/forms';
import { UntypedFormGroup } from '@angular/forms';
import { Activity } from 'src/app/models/activities';
import { ActivityModel } from './../../../models/activities/activity';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import {
  WorkflowService,
  WorkflowContextService,
  SecurityService
} from 'src/app/services';
import { Workflow } from 'src/app/models';
import * as moment from 'moment';

@Component({
  selector: 'wm-workflow-activity-preview-modal',
  templateUrl: './workflow-activity-preview-modal.component.html',
  styleUrls: ['./workflow-activity-preview-modal.component.css']
})
export class WorkflowActivityPreviewModalComponent implements OnInit {
  @Input() activityId: string;
  @Input() workflow: Workflow;

  form: UntypedFormGroup;
  activity: Activity<ActivityModel>;
  availableRoles: Role[] = [SecurityService.APPLICANT_ROLE];
  selectedRole = SecurityService.APPLICANT_ROLE.id;
  viewTitle: string;
  loading = false;

  get role() {
    return this.availableRoles.find(r => r.id === this.selectedRole);
  }

  constructor(
    public activeModal: NgbActiveModal,
    private _fb: UntypedFormBuilder,
    private _workflowSvc: WorkflowService,
    private _context: WorkflowContextService,
    private _securitySvc: SecurityService,
    private changeRef: ChangeDetectorRef
  ) {}

  async getData() {
    this.loading = true;

    const stopWatch = new StopWatch(false);

    stopWatch.lap('activity loaded started');

    const activity = await this._workflowSvc
      .getWorkflowActivityPreview(this._context.workflow, this.activityId)
      .toPromise();

    stopWatch.lap('activity loaded');
    const roles = await this._securitySvc
      .getRoles(this._context.client)
      .toPromise();
    stopWatch.lap('roles loaded');
    for (const role of roles) {
      this.availableRoles.push(role);
    }

    this.activity = activity;
    this.form = this._fb.group(
      ValidationService.createValidationGroup(this.activity)
    );
    stopWatch.lap('validation created');
    this.loading = false;
    stopWatch.stop();
  }

  ngOnInit() {
    this.getData();
  }

  setViewTitle(e: string) {
    this.viewTitle = e;
    this.changeRef.detectChanges();
  }
}

export class StopWatch {
  startTime: Date;
  lapTimes: { label?: string; time: string }[] = [];
  stopTime: Date;
  outToConsole: boolean;

  start(): Date {
    this.lapTimes = [];
    this.startTime = new Date();
    return this.startTime;
  }

  output(label, output) {
    if (this.outToConsole) {
      console.log(label, output);
    }
  }

  stop(): Date {
    this.stopTime = new Date();
    this.output('laps', this.lapTimes);
    return this.stopTime;
  }
  lap(label?: string): string {
    const currentTime = new Date();
    const lapValue: string = moment()
      .diff(this.startTime, 'milliseconds')
      .toString();
    this.lapTimes.push({ label: label, time: lapValue });
    this.output(label ? label + ': ' : '', lapValue);
    return lapValue;
  }
  constructor(outToConsole?: boolean) {
    this.outToConsole = outToConsole;
    this.start();
  }
}

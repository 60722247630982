<h3>Workflow Settings</h3>

<app-loader *ngIf="!workflow"></app-loader>

<form *ngIf="workflow" [formGroup]="form">
  <div class="row">
    <div class="col-lg-6">
      <div class="form-group">
        <label class="bmd-label-floating" for="workflowName">Name</label>
        <input
          type="text"
          class="form-control"
          [(ngModel)]="workflow.version.name"
          name="workflowName"
          formControlName="workflowName"
        />
        <wm-control-message
          [control]="form.controls['workflowName']"
        ></wm-control-message>
      </div>

      <div class="form-group">
        <label class="bmd-label-floating" for="description">Description</label>
        <textarea
          class="form-control"
          [(ngModel)]="workflow.version.description"
          name="description"
          formControlName="description"
        >
        </textarea>
        <wm-control-message
          [control]="form.controls['description']"
        ></wm-control-message>
      </div>

      <div class="form-group">
        <wm-data-entity-autocomplete
          [types]=""
          [form]="form"
          [model]="workflow.version.applicationDescriptionTemplate"
          id="applicationDescriptionTemplate"
          [required]="false"
          [workflow]="workflow"
          controlName="applicationDescriptionTemplate"
          [serverValidator]="_workflowSvc.validateApplicationTemplateCriteria"
          (valueUpdate)="
            workflow.version.applicationDescriptionTemplate = $event
          "
          title="Application Description Template"
          *ngIf="context.workflow"
          columns="140"
        ></wm-data-entity-autocomplete>
        <!-- <input class="form-control"
                  [(ngModel)]="workflow.version.applicationDescriptionTemplate"
                  name="descriptionTemplate"
                  formControlName="descriptionTemplate" /> -->
        <wm-control-message
          [control]="form.controls['descriptionTemplate']"
        ></wm-control-message>
      </div>

      <div class="form-row">
        <div class="col">
          <div class="card">
            <div class="card-body">
              <h6 class="card-title">Application Number Settings</h6>
              <div class="row">
                <div class="col">
                  <wm-data-entity-formula
                    [required]="true"
                    [workflow]="workflow"
                    [thenLabelGetter]="getThenLabel"
                    (criteriaChanged)="updateCriteria($event)"
                    [criteria]="workflow.numberPrefixCriteria"
                    formulaTitle="Number Prefix"
                    [onlyGlobalDataEntities]="true"
                  >
                  </wm-data-entity-formula>
                  <wm-control-message
                    [control]="form.controls['numberPrefix']"
                  ></wm-control-message>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label class="bmd-label-floating" for="startingNumber">
                      Starting Number
                    </label>
                    <input
                      type="number"
                      class="form-control"
                      [(ngModel)]="workflow.clientApplicationNumber"
                      name="startingNumber"
                      formControlName="startingNumber"
                    />
                    <wm-control-message
                      [control]="form.controls['startingNumber']"
                    ></wm-control-message>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <div>
                      <label class="bmd-label-floating mb-0" for="totalLength">
                        Fixed Number Length
                      </label>
                      <wm-help-text
                        class="ml-1"
                        style="position: absolute; left: 150px"
                        placement="right"
                        helpText="Total number of digits of the numeric portion of the application number. The number will be padded with zeros to make up the full length."
                      ></wm-help-text>
                    </div>
                    <input
                      type="number"
                      class="form-control"
                      [(ngModel)]="workflow.fixedClientApplicationNumberLength"
                      name="totalLength"
                      formControlName="totalLength"
                    />
                    <wm-control-message
                      [control]="form.controls['totalLength']"
                    ></wm-control-message>
                    <div class="pt-1 font-italic">
                      Preview: {{ fixedNumberPreview }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="switch">
                    <label>
                      <input
                        type="checkbox"
                        class="float-right"
                        [(ngModel)]="autoReset"
                        formControlName="autoReset"
                        (change)="toggleAutoReset()"
                      />
                      Auto Reset?
                    </label>
                    <wm-help-text
                      class="ml-1"
                      placement="right"
                      helpText="The auto reset will not effect applications that have already had a number generated prior to setting this value."
                    ></wm-help-text>
                  </div>
                  <div *ngIf="nextResetDate">
                    <label class="bmd-label-static" for="resetMonth">
                      Next Reset On
                    </label>
                    <div>{{ nextResetDate }}</div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label class="bmd-label-floating" for="resetMonth">
                      Month of Year
                      <span class="text-red" *ngIf="autoReset">*</span>
                    </label>
                    <wm-help-text
                      class="ml-1"
                      style="position: absolute; left: 85px; top: .75rem;"
                      placement="right"
                      helpText="Month of year you want the number to reset."
                    ></wm-help-text>

                    <select
                      class="form-control"
                      name="resetMonth"
                      formControlName="resetMonth"
                      (change)="calculateNextResetDate()"
                    >
                      <option></option>
                      <option *ngFor="let rm of months" [value]="rm.value">{{
                        rm.key
                      }}</option>
                    </select>
                    <wm-control-message
                      [control]="form.controls['resetMonth']"
                    ></wm-control-message>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label class="bmd-label-floating" for="resetDay">
                      Day of Month
                      <span class="text-red" *ngIf="autoReset">*</span>
                    </label>
                    <wm-help-text
                      class="ml-1"
                      style="position: absolute; left: 80px; top: .75rem;"
                      placement="right"
                      helpText="Day of the Month you want the number to reset."
                    ></wm-help-text>
                    <select
                      class="form-control"
                      name="resetDay"
                      formControlName="resetDay"
                      (change)="calculateNextResetDate()"
                    >
                      <option></option>
                      <option *ngFor="let rd of days" [value]="rd">{{
                        rd
                      }}</option>
                    </select>
                    <wm-control-message
                      [control]="form.controls['resetDay']"
                    ></wm-control-message>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="workflow.id !== EMPTY_GUID" class="pt-2">
        <label>
          Visible to public users? {{ workflow.visibleToPublic ? 'Yes' : 'No' }}
        </label>
      </div>
      <div *ngIf="workflow.id === EMPTY_GUID" class="pt-2">
        <div class="switch">
          <label>
            <input
              type="checkbox"
              name="visibleToPublic"
              formControlName="visibleToPublic"
              [(ngModel)]="workflow.visibleToPublic"
            />

            Visible to public users?
          </label>
        </div>
      </div>

      <div class="form-row">
        <div class="card col">
          <div class="card-body">
            <h6 class="card-title">
              Auto Void Application Settings
              <div class="switch float-right pl-3">
                <label>
                  <input
                    type="checkbox"
                    name="enableAutoVoid"
                    formControlName="enableAutoVoid"
                    [(ngModel)]="enableAutoVoid"
                    (ngModelChange)="toggleAutoVoid($event)"
                  />

                  Enable
                </label>
              </div>
            </h6>
            <div class="row" *ngIf="workflow.autoVoidSettings" formGroupName="autoVoid">
              <div class="col-10">
                <div class="form-group">
                  <label class="bmd-label-floating" for="goBeyondActivity">
                    Activity to go beyond
                  </label>
                  <select
                    [(ngModel)]="workflow.autoVoidSettings.goBeyondActivity"
                    class="form-control"
                    name="goBeyondActivity"
                    formControlName="goBeyondActivity"
                  >
                    <option
                      *ngFor="let activity of autoVoidActivities"
                      [value]="activity.id"
                    >
                      {{ activity.model.screenName }}
                    </option>
                  </select>
                  <wm-control-message
                    [control]="form.controls['goBeyondActivity']"
                  ></wm-control-message>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label class="bmd-label-floating" for="daysUntilVoid">
                    Days until void
                  </label>
                  <input
                    type="number"
                    class="form-control"
                    [(ngModel)]="workflow.autoVoidSettings.daysUntilVoid"
                    name="daysUntilVoid"
                    formControlName="daysUntilVoid"
                  />
                  <wm-control-message
                    [control]="form.controls['daysUntilVoid']"
                  ></wm-control-message>
                </div>
              </div>
            </div>
            <div class="row" *ngIf="autoVoidExample">
              <div class="col">
                <p>Example: {{ autoVoidExample }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="form-group" *ngIf="context.client">
        <label class="bmd-label-floating" for="adminRole">
          Administrative Role
        </label>
        <select
          class="form-control"
          (change)="selectRole($event); refreshPermissions()"
          name="adminRole"
          formControlName="adminRole"
          [attr.disabled]="canManageRoles ? null : true"
        >
          <option *ngFor="let role of adminRoles" [value]="role.id">
            {{ role.name }}
          </option>
        </select>
        <div *ngIf="!adminRoleValid" class="text-danger">
          Selected Role has either been deleted or permissions have been removed.
        </div>
        <wm-control-message
          [control]="form.controls['adminRole']"
        ></wm-control-message>
      </div>

      <div class="form-group">
        <label class="bmd-label-floating" for="workflowType">
          Workflow Type
        </label>
        <select
          [(ngModel)]="workflow.workflowType"
          class="form-control"
          name="workflowType"
          formControlName="workflowType"
        >
          <option value="0">Permit</option>
          <option value="4">Renewable</option>
          <option value="1" *ngIf="!context.client"
            >Contractor Registration</option
          >
          <option value="2" *ngIf="!context.client">Contractor Renewal</option>
          <option value="3">Other</option>
          <!--<option *ngFor="let role of adminRoles" [value]="role.id">
            {{ role.name }}
          </option>-->
        </select>
        <wm-control-message
          [control]="form.controls['workflowType']"
        ></wm-control-message>
      </div>

      <div class="card mr-1 mt-3 mb-4" *ngIf="workflow.workflowType == 4">
        <div class="row ml-1 mr-1">
          <div class="col-11">
            <div class="form-group">
              <label for="renewalWorkflow" class="bmd-label-static">
                Renewal Workfow
              </label>
              <select
                class="form-control"
                name="renewalWorkflow"
                [(ngModel)]="workflow.renewalWorkflowId"
                [ngModelOptions]="{ standalone: true }"
                required
              >
                <option
                  [ngValue]="workflow.value"
                  *ngFor="let workflow of renewalWorkflowOptions"
                >
                  {{ workflow.label }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-1 pt-5 pr-2">
            <wm-help-text
              placement="right"
              helpText="After a renewable registration is created through this workflow, renewals of that registration can be processed by this workflow, or another renewable workflow can be selected."
            ></wm-help-text>
          </div>
        </div>

        <div class="row ml-1 mr-1">
          <div class="col-12">
            <div class="form-group">
              <label for="expOptions" class="bmd-label-floating">
                Expiration Date
              </label>
              <select
                class="form-control"
                name="expOptions"
                [(ngModel)]="selectedExpirationOption"
                [ngModelOptions]="{ standalone: true }"
                required
                (change)="onExpirationOptionChange()"
              >
                <option
                  *ngFor="let option of expirationOptions"
                  [ngValue]="option"
                >
                  {{ option.label }}
                </option>
              </select>
            </div>
          </div>
        </div>

        <div class="row ml-1 mr-1" *ngIf="selectedExpirationOption">
          <div
            [formGroup]="termLengthForm"
            class="col-12"
            *ngIf="selectedExpirationOption.name == 'custom-months'"
          >
            <div class="form-group">
              <label class="bmd-label-floating" for="termLength">
                Term length from issue date (months)
              </label>
              <input
                name="termLength"
                type="text"
                class="form-control"
                [(ngModel)]="termLengthMonths"
                formControlName="termLength"
              />
              <wm-control-message
                [control]="termLengthForm.controls['termLength']"
              ></wm-control-message>
            </div>
          </div>
        </div>

        <div class="form-row ml-1 mr-1" *ngIf="selectedExpirationOption">
          <div
            class="col-12"
            *ngIf="selectedExpirationOption.name == 'custom-formula'"
          >
            <div class="form-group">
              <wm-data-entity-formula
                [required]="true"
                [criteria]="customFormulaCriteria"
                [workflow]="workflow"
                [thenLabelGetter]="getThenLabel"
                (criteriaChanged)="updateExpirationCriteria($event)"
                formulaTitle="Custom Expiration Date Formula"
                [onlyGlobalDataEntities]="false"
                [excludedTemplateCodes]="
                  expirationCustomForumulaExcludedTemplateCodes
                "
              >
              </wm-data-entity-formula>
              <wm-control-message
                [control]="form.controls['customFormula']"
              ></wm-control-message>
            </div>
          </div>
        </div>

        <div class="row ml-1">
          <div class="col-12">
            <!-- <div class="card mt-2" *ngIf="workflowId"> -->
            <div class="card-body">
              <h6 class="card-title">Requirements</h6>
              <app-loader *ngIf="isSaving"></app-loader>
              <div *ngIf="!isSaving">
                <button
                  class="btn btn-raised btn-primary float-right"
                  (click)="addRequirement()"
                  [disabled]="newRequirement"
                >
                  Add Requirement
                </button>
                <div class="row">
                  <div class="col-auto">
                    <ul class="list-group">
                      <li *ngIf="newRequirement" class="list-group-item">
                        <wm-requirement-editor
                          [requirement]="newRequirement"
                          [parentForm]="form"
                          [entities]="entities"
                          (cancelled)="newRequirementCancelled()"
                          (applied)="newRequirementApplied($event)"
                          [isOpen]="true"
                        ></wm-requirement-editor>
                      </li>
                      <li
                        class="list-group-item"
                        *ngIf="
                          !workflow.requirements ||
                          workflow.requirements.length == 0
                        "
                      >
                        <i>
                          There are not any requirements for this renewal
                        </i>
                      </li>
                      <ng-container *ngIf="entities">
                        <li
                          class="list-group-item"
                          *ngFor="let requirement of workflow.requirements"
                        >
                          <wm-requirement-editor
                            [requirement]="requirement"
                            [parentForm]="form"
                            [entities]="entities"
                            (removed)="removeRequirement($event)"
                            (applied)="save()"
                          ></wm-requirement-editor>
                        </li>
                      </ng-container>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <!-- </div> -->
          </div>
        </div>
      </div>

      <div class="form-group">
        <wm-advanced-permissions-editor
          [securable]="workflow"
          permissionTitle="Permissions for this workflow"
          *ngIf="workflow.id && workflow.id !== EMPTY_GUID"
          #advancedPermissions
        ></wm-advanced-permissions-editor>
      </div>
    </div>
    <div class="col-lg-3" *ngIf="context.client">
      <wm-item-tag-list
        *ngIf="workflowTags"
        [itemTags]="workflowTags"
        (added)="tagAdded($event)"
        (removed)="tagRemoved($event)"
      ></wm-item-tag-list>
    </div>
    <!-- TODO: This is commented out until it is usable -->
    <!-- <div class="col-lg-6">
      <div class="form-group">
        <div class="form-group">
          <div class="input-group">
            <span class="input-group-addon">Milestone Name</span>
            <input
              type="text"
              class="form-control"
              [(ngModel)]="newMilestone.name"
              name="milestoneName"
              formControlName="milestoneName"
            />
            <span class="input-group-btn">
              <button class="btn" (click)="addMilestone()">Add</button>
            </span>
          </div>
        </div>

        <div class="form-group">
          <span
            *ngIf="
              !workflow.milestones ||
              workflow.milestones.length === 0
            "
          >
            <i>No milestones have been defined</i>
          </span>
          <ul
            class="list-group"
            *ngIf="
              workflow.milestones &&
              workflow.milestones.length > 0
            "
          >
            <li
              class="list-group-item"
              *ngFor="let m of workflow.milestones"
            >
              <div class="row">
                <div class="col-lg-1">
                  <button
                    class="btn"
                    type="button"
                    (click)="removeMilestone(m)"
                  >
                    <span class="glyphicon glyphicon-remove"></span>
                  </button>
                </div>
                <div class="col-lg-11">
                  {{ m.name }}
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div> -->
  </div>

  <!-- <wm-modal-confirm
    #appDescriptionMessage
    title="No Application Description"
    commandText="save anyway"
    (accepted)="saveSettings()"
    [showButton]="false"
  >
    <p>
      Application Description is not required, but for Renewable workflows the
      Application Description can be used to reference data entities that
      identify the applicant later when viewing registrations. Data entities
      referenced in Application Description will show as 'Registration Info' in
      the Renewals list.
    </p>
    <p>Do you wish to save anyway?</p>
  </wm-modal-confirm> -->

  <div class="row">
    <div class="col">
      <button
        type="button"
        class="btn btn-raised btn-success"
        [disabled]="form.invalid || termLengthForm.invalid || isSaving"
        (click)="saveSettings()"
      >
        {{ isSaving ? 'Saving...' : 'Save' }}
      </button>
    </div>
  </div>
</form>

import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
  OnChanges
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import {
  ExportEntityOption,
  ItemSearchFilter,
  ItemSearchOptionField
} from 'src/app/components/filter-list/models/filterClasses';
import { Actions } from 'src/app/models';
import {
  FilterBuilderOutput,
  FilterBuilderParam
} from 'src/app/models/filter-builder';
import { ColumnOption } from 'src/app/models/grid-settings';
import { SecurityService, WorkflowContextService } from 'src/app/services';
import { faTimes, faGlobe } from '@fortawesome/free-solid-svg-icons';
import { ShapefileValidationCriteria } from 'src/app/models/scheduled-export';

@Component({
  selector: 'wm-exports-view',
  templateUrl: './exports-view.component.html',
  styleUrls: ['./exports-view.component.css']
})
export class ExportsViewComponent implements OnInit, OnChanges {
  @Input() id: string;
  @Input() title: string;
  @Input() params: FilterBuilderParam[];
  @Input() currentFilters: FilterBuilderOutput | ItemSearchOptionField[];
  @Input() staticFilters: ItemSearchOptionField[] | ItemSearchFilter;
  @Input() currentColumnOptions: ColumnOption[];
  @Input() defaultTemplate = true;
  @Input() form: UntypedFormGroup;
  @Input() filterForm: UntypedFormGroup;
  clonedFilterForm: UntypedFormGroup;

  @Output() exptExcel: EventEmitter<void> = new EventEmitter<void>();
  @Output() exptCsv: EventEmitter<void> = new EventEmitter<void>();
  @Output() exptShapefile: EventEmitter<void> = new EventEmitter<void>();
  @Input() shapefileValidationCriteria: ShapefileValidationCriteria;
  columnOptions: ColumnOption[] = [];
  canManageScheduledExports: boolean;
  clientId: string;
  faGlobe = faGlobe;
  constructor(
    private _context: WorkflowContextService,
    private _securitySvc: SecurityService
  ) {}

  ngOnInit() {
    if (!this.form) {
      this.form = new UntypedFormGroup({});
    }

    if (this.filterForm) {
      const clonedForm = new UntypedFormGroup({});

      const keys = Object.keys(this.filterForm.controls);

      keys.forEach(k => {
        const ctl = new UntypedFormControl(
          null,
          this.filterForm.controls[k].validator
        );
        clonedForm.addControl(k, ctl);
        ctl.markAsDirty();
      });

      this.clonedFilterForm = clonedForm;
    }

    // break the reference to the parent's columnOptions
    if (this.currentColumnOptions) {
      this.columnOptions = JSON.parse(
        JSON.stringify(this.currentColumnOptions)
      );
    }

    if (this._context.client) {
      this.clientId = this._context.client.id;
    }

    this._securitySvc
      .isLoginEntitled(Actions.MANAGE_SCHEDULED_EXPORTS)
      .subscribe(result => {
        this.canManageScheduledExports = result;
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.currentColumnOptions && this.currentColumnOptions) {
      this.columnOptions = [...this.currentColumnOptions];
    }
  }

  exportExcel() {
    this.exptExcel.emit();
  }
  exportCsv() {
    this.exptCsv.emit();
  }
  exportShapefile() {
    if (
      this.shapefileValidationCriteria.isGeoJSONSelected &&
      this.shapefileValidationCriteria.showShapeFieldError == false
    ) {
      this.exptShapefile.emit();
    }
  }
}

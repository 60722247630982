import { LogEmail } from '../log-email';
import { EmailActivityModel, EmailActivity } from './email-activity';

export class ScheduleEmailActivityModel extends EmailActivityModel {
  dateDataEntity: string;
  timeOfDay: string;
  cancelIfDisabled?: boolean;
  cancelIfRenewed?: boolean;
  showCancelOptions: boolean;
}

export class ScheduleEmailActivity extends EmailActivity<
  ScheduleEmailActivityModel
> {
  scheduledEmail: LogEmail;

  constructor(options?: Partial<ScheduleEmailActivity>) {
    super(options);

    this.model = new ScheduleEmailActivityModel();
    if (options) {
      Object.assign(this, options);
    }

    this.model = new ScheduleEmailActivityModel(options ? options.model : null);

    this.model.screenName = this.model.screenName || 'Schedule Email';
  }
}

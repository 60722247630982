import { ValidationService } from 'src/app/services';
import { DataEntity } from './data-entity';
import { Validators } from '@angular/forms';

export enum NumericDataType {
  Number,
  Currency
}

export class NumericDataEntity extends DataEntity {
  minimumValue?: number;
  maximumValue?: number;
  decimalPlaces?: number;
  type = NumericDataType.Number;

  constructor(options?: Partial<NumericDataEntity>) {
    super(options);
    this.decimalPlaces = 0;

    this.dataEntityTypeCode = 'numeric-data';
    this.dataEntityTypeDescription =
      'Use this entity type if you need to collect numeric data.';
    this.dataEntityTypeName = 'Numeric Data';

    if (options) {
      Object.assign(this, options);
    }
  }

  getValidators(): any[] {
    const validators = super.getValidators() || [];

    if (this.minimumValue !== null && this.minimumValue !== undefined) {
      validators.push(ValidationService.min(this.minimumValue));
    }

    if (this.maximumValue !== null && this.maximumValue !== undefined) {
      validators.push(ValidationService.max(this.maximumValue));
    }

    return validators;
  }
}

<form *ngIf="form" [formGroup]="form">
  <div class="row">
    <!-- Main -->
    <div class="col-4" *ngIf="dataEntity && !reloadingForms">
      <div class="form-group" [class.has-error]="label.touched && !label.valid">
        <label class="bmd-label-floating" for="label">
          Label
        </label>
        <input
          type="text"
          class="form-control"
          formControlName="label"
          name="label"
          (blur)="sanitize()"
          [(ngModel)]="dataEntity.label"
        />
        <wm-control-message
          [control]="form.controls['label']"
        ></wm-control-message>
      </div>

      <div class="form-group" *ngIf="dataEntity.isHelpText">
        <label class="bmd-label-floating" for="instructions">
          Help Text
        </label>
        <textarea
          class="form-control"
          rows="3"
          formControlName="instructions"
          name="instructions"
          [(ngModel)]="dataEntity.instructions"
        ></textarea>
      </div>

      <div class="form-group">
        <label class="bmd-label-floating" for="dataEntityType">
          Data Entity Type
        </label>
        <select
          class="form-control"
          (change)="changeType()"
          formControlName="dataEntityType"
          name="dataEntityType"
          [(ngModel)]="selectedTypeCode"
        >
          <option *ngFor="let de of dataEntityTypes" [value]="de.code">
            {{ de.name }}
          </option>
        </select>
      </div>
      <div
        class="form-group"
        [class.has-error]="templateCode.touched && !templateCode.valid"
      >
        <label class="bmd-label-floating" for="templateCode">
          Template Code
        </label>
        <input
          type="text"
          class="form-control required"
          formControlName="templateCode"
          name="templateCode"
          [(ngModel)]="dataEntity.templateCode"
        />
        <div *ngIf="changingCustomFieldTemplateCode" class="text-warning">
          Note: All Custom Fields associated with this Data Entity will be
          updated with the new Template Code once the change is published.
        </div>
        <wm-control-message
          [control]="form.controls['templateCode']"
        ></wm-control-message>
      </div>
      <div class="switch">
        <label>
          <input
            type="checkbox"
            formControlName="isRequired"
            [(ngModel)]="dataEntity.isRequired"
          />
          Required
        </label>
      </div>
      <div class="switch" *ngIf="!_context.client">
        <label>
          <input
            type="checkbox"
            formControlName="isSystemEntity"
            [(ngModel)]="dataEntity.isSystemEntity"
          />
          System Entity
        </label>
      </div>
    </div>
    <!-- Entity specific-->
    <div
      [ngStyle]="{
        display: dataEntity && dataEntity.hasEditor ? 'block' : 'none'
      }"
      [ngClass]="widthClass(dataEntity && dataEntity.hasPreview)"
    >
      <template #dataEntityEditor></template>
    </div>

    <div
      *ngIf="dataEntity && dataEntity.hasPreview"
      [ngClass]="widthClass(dataEntity.hasEditor)"
    >
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">
            Preview
          </h5>

          <app-loader *ngIf="!isPreviewReady"></app-loader>

          <div *ngIf="isPreviewReady">
            <wm-data-entity-input
              *ngIf="previewDE.templateCode"
              [entity]="previewDE"
              [form]="previewForm"
              [isPreview]="true"
              [activity]="activity"
            ></wm-data-entity-input>
          </div>
        </div>
      </div>
    </div>
  </div>

  <wm-workflow-validation-item
    *ngIf="dataEntity"
    [workflowId]="workflow.id"
    [activityId]="dataEntity.parent.id"
    [itemId]="dataEntity.templateCode"
  ></wm-workflow-validation-item>
</form>

import { Component, OnInit, EventEmitter } from '@angular/core';
import { ActivityView } from '../../../../../views/master-views/app.view/app.view.component';
import { WorkflowContextService } from '../../../../../services';
import {
  ActivityModel,
  Activity,
  StartWorkflowActivityModel
} from '../../../../../models/activities';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'wm-start-workflow-activity-input',
  templateUrl: './start-workflow-activity-input.component.html',
  styleUrls: ['./start-workflow-activity-input.component.css']
})
export class StartWorkflowActivityInputComponent extends ActivityView
  implements OnInit {
  activity: Activity<StartWorkflowActivityModel>;

  constructor() {
    super();
  }

  ngOnInit() {}
}

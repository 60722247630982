import * as Workflow from "esri/widgets/Editor/Workflow";
import { Client } from "./client";
import { Activity, ActivityModel } from "./activities";

export class QueryMapLayerSearchField {
  id: string;
  queryMapLayerId: string;
  queryMapLayer: QueryMapLayer;
  fieldName: string;
  label: string;
  createdOn: string;
  createdBy: string;
  modifiedOn: string;
  modifiedBy: string;
  deletedOn: string;
  deletedBy: string;
  searchValue: string; // this is client only
}

export class QueryMapLayerResultField {
  id: string;
  queryMapLayerId: string;
  queryMapLayer: QueryMapLayer;
  fieldName: string;
  resultHeader: string;
  createdOn: string;
  createdBy: string;
  modifiedOn: string;
  modifiedBy: string;
  deletedOn: string;
  deletedBy: string;
}

export class QueryMapLayer {
  id: string;
  clientId: string;
  client: Client;
  workflowId: string;
  workflow: Workflow;
  activityId: string;
  activity: Activity<ActivityModel>;
  mapLayerIndexItemId: string;
  mapLayerIndexItem: any;
  createdOn: string;
  createdBy: string;
  modifiedOn: string;
  modifiedBy: string;
  deletedOn: string;
  deletedBy: string;
  featureIdField: string;
  searchFields: QueryMapLayerSearchField[];
  resultFields: QueryMapLayerResultField[];
}

export class SearchField {
  field: string;
  value: string;
  searchContains: boolean;

  constructor(options: Partial<SearchField>) {
    if(options) {
      Object.assign(this, options);
    }
  }
}

export class QueryMapLayerRequest {
  mapServiceURL: string;
  mapLayerId: number;
  searchFields: SearchField[];
  pageNumber: number;
  pageSize?: number;
  featureIdField: string;
  sortField: string;
  sortDescending: boolean;
}

export class QueryMapLayerResult {
  totalRecords: number;
  results: {[key: string]: {[key: string]: string}};
}

<app-loader *ngIf="!customLists"></app-loader>

<div *ngIf="customLists && customLists.length === 0">
  <p>No custom lists to display</p>
</div>

<table class="table mt-2" *ngIf="customLists && customLists.length > 0">
  <thead>
    <tr>
      <th scope="col">Name</th>
      <th scope="col">Actions</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let list of customLists">
      <th scope="row">
        {{ list.name }}
      </th>
      <td>
        <button class="btn btn-primary btn-sm" (click)="editCustomList(list)">
          Edit
        </button>
      </td>
    </tr>
  </tbody>
</table>

<form [formGroup]="form" *ngIf="config">
  <div class="form-group">
    <label class="bmd-label-floating" for="apiKey">
      API Key
    </label>
    <input
      type="text"
      class="form-control"
      formControlName="apiKey"
      name="apiKey"
      [(ngModel)]="config.apiKey"
    />
  </div>
</form>

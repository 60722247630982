import { PaymentProcessorAccountConfigurationBase } from '../payment-processor-account-configuration-base';

export class eGOVPaymentProcessorAccountConfiguration extends PaymentProcessorAccountConfigurationBase {
  serviceCode: string;
  merchantKey: string;
  merchantID: string;
  stateCode: string;

  constructor(options?: Partial<eGOVPaymentProcessorAccountConfiguration>) {
    super(options);
  }
}

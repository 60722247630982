<p *ngIf="!results">
  No parcel was selected.
</p>

<div *ngIf="results">
  <div class="tab-pane fade active show">
    <app-esri-map
      #mapComp
      [mapServiceId]="activity.model.mapService.id"
      [mapLayerOptions]="[activity.model.mapLayer]"
      [showToc]="false"
      (mapLoaded)="onMapLoaded()"
      useSketchLayer="false"
      [featureKeyAsString]="false"
    ></app-esri-map>
  </div>

  <div *ngIf="results && results.length > 0" style="background-color: white">
    <table class="table">
      <tr
        *ngFor="let feature of results; let isFirstRow = first"
        [ngClass]="{ RowHeaderCSS: isFirstRow }"
      >
        <td *ngFor="let f of resultFields">
          {{
            isFirstRow
              ? feature[f.fieldName]
              : feature[f.fieldName.toLowerCase()]
          }}
        </td>
      </tr>
    </table>
  </div>
</div>

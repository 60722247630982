import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { NMIPaymentRequestGet } from './../../../models/payment-providers/nmi/nmi-payment-settings';
import { SystemService } from './../../../services/system.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'wm-nmi-payment-form',
  templateUrl: './nmi-payment-form.component.html',
  styleUrls: ['./nmi-payment-form.component.css']
})
export class NmiPaymentFormComponent implements OnInit {
  @ViewChild('f', { static: false }) f: HTMLFormElement;
  processing = false;

  requestDetails: {
    label: string;
    description: string;
    lineItems: { feeName: string; feeAmount: number }[];
  };
  totalAmount: number;
  formPostURL: string;
  form: UntypedFormGroup;

  constructor(
    private route: ActivatedRoute,
    private _systemSvc: SystemService
  ) {}

  submit() {
    this.processing = true;
    this.f.nativeElement.submit();
  }

  ngOnInit() {
    this.form = new UntypedFormGroup({
      name: new UntypedFormControl('', Validators.required),
      creditCardNumber: new UntypedFormControl('', Validators.required),
      expDate: new UntypedFormControl('', Validators.required),
      cvv: new UntypedFormControl('', Validators.required)
    });

    this.route.params.subscribe(parms => {
      const requestId = parms['paymentRequestId'];

      this._systemSvc.getPaymentRequest(requestId).subscribe(request => {
        if (request) {
          const nmiProviderRequest: NMIPaymentRequestGet = request.providerRequest as NMIPaymentRequestGet;

          if (nmiProviderRequest && nmiProviderRequest.requestSetting) {
            this.formPostURL = nmiProviderRequest.requestSetting.formAction;

            this.requestDetails = {
              label: nmiProviderRequest.requestSetting.paymentLabel,
              description: nmiProviderRequest.requestSetting.paymentDescription,
              lineItems: (request.requestData.lineItems || []).map(li => {
                return {
                  feeName: li.feeName,
                  feeAmount: li.amount
                };
              })
            };

            this.totalAmount = 0;
            this.requestDetails.lineItems.forEach((fee, idx) => {
              this.totalAmount = this.totalAmount + fee.feeAmount || 0;
            });
          }
        }
      });
    });
  }
}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { ExternalDataConnection } from '../../../../models';
import { SystemService, WorkflowContextService } from '../../../../services';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'wm-external-data-connections-list-view',
  templateUrl: './external-data-connections-list-view.component.html',
  styleUrls: ['./external-data-connections-list-view.component.css']
})
export class ExternalDataConnectionsListViewComponent
  implements OnInit, OnDestroy {
  externalDataConnections: ExternalDataConnection[];
  clientSubscription: Subscription;

  constructor(
    private _context: WorkflowContextService,
    private _systemSvc: SystemService,
    private _router: Router
  ) {}

  ngOnDestroy(): void {
    if (this.clientSubscription) {
      this.clientSubscription.unsubscribe();
    }
  }

  addExternalDataConnection() {
    this._router.navigate([
      'admin',
      'jurisdiction',
      this._context.client.id,
      'settings',
      'data',
      'add'
    ]);
  }

  loadConnections() {
    this._systemSvc
      .searchExternalDataConnections({
        searchText: '',
        clientId: this._context.client.id
      })
      .subscribe(res => {
        this.externalDataConnections = res;
      });
  }

  ngOnInit() {
    this.clientSubscription = this._context.client$.subscribe(c => {
      if (c) {
        this.loadConnections();
      } else {
        this._router.navigate(['/admin']);
      }
    });

    this.loadConnections();
  }
}

import { PaymentProcessorConfigBaseComponent } from './../payment-processor-config-base/payment-processor-config-base.component';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { eGOVPaymentProcessorConfiguration } from './../../../models/payment-providers/egov/egov-payment-processor-configuration';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'wm-payment-processor-config-egov',
  templateUrl: './payment-processor-config-egov.component.html',
  styleUrls: ['./payment-processor-config-egov.component.css']
})
export class PaymentProcessorConfigeGOVComponent
  extends PaymentProcessorConfigBaseComponent
  implements OnInit {
  eGOVConfig: eGOVPaymentProcessorConfiguration;

  constructor(fb: UntypedFormBuilder) {
    super(fb);
  }

  ngOnInit() {
    this.eGOVConfig = this.processorConfig as eGOVPaymentProcessorConfiguration;

    this.addValidation('soapURL', Validators.required);
  }
}

<div [formGroup]="form" *ngIf="form" novalidate>
  <label class="text-label mb-2 font-weight-bold">
    Scheduled Emails to Cancel
  </label>
  <div
    class="custom-control"
    ngClass="custom-checkbox"
    *ngFor="let s of activity.availableScheduledEmailsToCancel"
  >
    <input
      class="custom-control-input"
      type="checkbox"
      (change)="toggleItem($event.target.checked, s.id)"
      [checked]="isSelected(s.id)"
      [name]="s.id"
      [id]="s.id"
      [value]="s.id"
    />
    <label class="custom-control-label" [for]="s.id">
      {{ s.name }}
    </label>
  </div>
</div>

import { Utilities } from 'src/app/services/index';

export interface ConditionSource {
  name: string;
  value: string;
  operators?: ConditionOperator[];
  possibleValues?: ConditionValue[];
}

export interface ConditionValue {
  name: string;
  value: string;
}

export interface ConditionOperator {
  name: string;
  operator: Operator;
  noValue?: boolean;
}

export const OperatorTypes: { [key: string]: ConditionOperator } = {
  IS: {
    name: 'is',
    operator: '=='
  },
  IS_NOT: {
    name: 'is not',
    operator: '!='
  },
  GREATER_THAN: {
    name: 'is greater than',
    operator: '>'
  },
  LESS_THAN: {
    name: 'is less than',
    operator: '<'
  },
  EXISTS: {
    name: 'exists',
    operator: '!!', // this operator doesn't exactly make sense
    noValue: true
  },
  NOT_EXISTS: {
    name: 'does not exist', // this operator doesn't exactly make sense
    operator: '!',
    noValue: true
  },
  CONTAINS: {
    name: 'contains',
    operator: '~'
  },
  RANGE: {
    name: 'range',
    operator: '~~'
  }
};

export enum LogicalOperator {
  And,
  Or
}
export type Operator = '==' | '!=' | '>' | '<' | '!!' | '!' | '~' | '~~';
// ConditionParams are parsed into `field formulaOperator val`
// e.g. `'foo' == 'bar'`
export class ConditionItem {
  constructor(opts: Partial<ConditionItem> = {}) {
    Object.assign(this, opts);

    if (this.isGroup) {
      this.logicalOperator = opts.logicalOperator || LogicalOperator.And;
    } else {
      this.formulaOperator = opts.formulaOperator || '==';
    }
  }
  id = Utilities.generateId();

  /**
   * ConditionParams
   */
  field?: string;
  formulaOperator?: Operator = '==';
  val?: string | number | boolean;

  /**
   * ConditionGroup
   */
  // The logical operator for the group. E.g. AND / OR.
  logicalOperator?: LogicalOperator;
  // A group of conditions
  conditions?: ConditionItem[] = [];
  isGroup: boolean;
}

export class ConditionTarget {
  shouldRoute: boolean;
  constructor(opts?: Partial<ConditionTarget>) {
    Object.assign(this, opts);

    this.id = (opts ? opts.id : null) || Utilities.generateId();
  }

  id: string;
  isOtherwise = false;
  order = 0;
  // A group of conditions
  group: ConditionItem = new ConditionItem({ isGroup: true });
  // if all conditions eval true, then this is the value
  value = '';
}

export const defaultOperators: ConditionOperator[] = [
  OperatorTypes.IS,
  OperatorTypes.IS_NOT,
  OperatorTypes.GREATER_THAN,
  OperatorTypes.LESS_THAN,
  OperatorTypes.EXISTS,
  OperatorTypes.NOT_EXISTS,
  OperatorTypes.CONTAINS
];

export const getOperator = (symbol: string) => {
  for (const op in OperatorTypes) {
    if (
      OperatorTypes[op] &&
      OperatorTypes[op].operator &&
      OperatorTypes[op].operator === symbol
    ) {
      return OperatorTypes[op] || OperatorTypes.IS;
    }
  }

  // default operator if we get here
  return OperatorTypes.IS;
};

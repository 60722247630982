import { Output, EventEmitter, Directive } from '@angular/core';

import { AvailabilityProviderOptions } from '../availability-providers';

@Directive()
export class AvailabilityProvider {
  @Output() selectedDate$: EventEmitter<Date> = new EventEmitter<Date>();
  selectedDate: Date;

  constructor() {
    this.selectedDate$.subscribe((value: Date) => {
      this.selectedDate = value;
    });
  }

  setDate(date: Date) {
    this.selectedDate$.next(date);
  }

  validateDate(
    date: Date,
    endDate: Date,
    options?: Partial<AvailabilityProviderOptions>
  ): boolean {
    return true;
  }

  getAvailableDates(
    currentDate: Date,
    endDate: Date,
    options?: Partial<AvailabilityProviderOptions>
  ): Date[] {
    return null;
  }

  getAvailableTimes(
    currentDate: Date,
    options?: Partial<AvailabilityProviderOptions>
  ): Date[] {
    return null;
  }
}

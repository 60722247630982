<wm-inspection-queue
  #wmInspectionQueue
  [id]="id"
  (recordCountSet)="setRecordCount($event)"
  *ngIf="staticFilters"
  [client]="client"
  [sortField]="sortField"
  [sortDescending]="sortDescending"
  [staticFilters]="staticFilters"
  [columns]="columns"
  (updateStatus)="updateStatusEvent($event)"
  (dataLoaded)="emitDataLoaded()"
  (filtersChanged)="filtersChange()"
>
</wm-inspection-queue>

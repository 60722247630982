import { FeeRecipientsComponent } from './../../../components/reports/financial/fee-recipients/fee-recipients.component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { DailyFeesComponent } from 'src/app/components/reports/financial/daily-fees/daily-fees.component';

@Component({
  selector: 'wm-financial',
  templateUrl: './financial.component.html',
  styleUrls: ['./financial.component.css']
})
export class FinancialComponent implements OnInit {
  constructor() {}

  @ViewChild('feeRecipientReport', { static: false }) feeRecipientReport: FeeRecipientsComponent;
  @ViewChild('dailyFeesReport', { static: false }) dailyFeesReport: DailyFeesComponent;

  ngOnInit() {}

  loadFeeReceipients() {
    // reload fee recipients report on any refund, because the
    // list of fee recipients could potentially change when a refund happens
    this.feeRecipientReport.getReport();
  }

  handleTabsChanged() {
    this.dailyFeesReport.resetColumnWidths();
  }
}

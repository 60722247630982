<div class="mt-4 text-danger">
  IMPORTANT: This report has changed! The fees shown on this report have not
  changed but we have moved some payment related fields to the Payments Report.
  <wm-modal-confirm
    title="Upcoming Changes to Daily Fees Report"
    [windowClass]="'modal-90'"
    id="upcomingChanges"
    moduleId="dailyFeesUpcomingChanges"
    [showButtonText]="true"
    buttonText="Click here to read full details."
    [buttonStyle]="ButtonStyle.Link"
  >
    <div class="fs-2">
      <p>
        The only option the user currently has when paying fees, is to make a
        single payment of the full amount with one payment method (Cash, Check,
        Online, Credit Card POS). We are working on a change to allow for
        multiple payments for a single transaction.
      </p>
      <p>
        For example, there is a total amount due for a building permit, and the
        contractor would like to pay a portion and the homeowner a portion.
        These payments can be made with different methods as well (online,
        check, cash, credit card POS). A total amount due could also include
        multiple fee recipients such as the County Department and a line item
        for a State Surcharge.
      </p>
      <p>
        We will be splitting Daily Fees report into two Financial Reports - a
        Payments Report and a Fees Report.
      </p>

      <ul>
        <li>
          The Payments report will show each payment (or partial payment) and
          how it was made (online, check, cash, credit card POS)
        </li>
        <li>
          The Fees Report will show the break down of Fees collected and their
          Fee Recipients (different departments, State Surcharge, or accounting
          code that you use)
        </li>
      </ul>

      <p>
        The following is a side-by-side of each report and what information will
        be available for each report:
      </p>
      <table class="table">
        <tr>
          <th>Data</th>
          <th>Fees Report</th>
          <th>Payments Report</th>
        </tr>
        <tr>
          <td>Permit #</td>
          <td>Yes</td>
          <td>Yes</td>
        </tr>
        <tr>
          <td>Order Number</td>
          <td>No</td>
          <td>Yes</td>
        </tr>
        <tr>
          <td>Transaction Number</td>
          <td>No</td>
          <td>Yes</td>
        </tr>
        <tr>
          <td>Amount</td>
          <td>Yes (Fee Amount)</td>
          <td>Yes (Payment Amount)</td>
        </tr>
        <tr>
          <td>Payment Method</td>
          <td>No</td>
          <td>Yes</td>
        </tr>
        <tr>
          <td>Paid (date)</td>
          <td>Yes</td>
          <td>Yes</td>
        </tr>
        <tr>
          <td>Fee Recipient</td>
          <td>Yes</td>
          <td>No</td>
        </tr>
        <tr>
          <td>Application Name</td>
          <td>Yes</td>
          <td>Yes</td>
        </tr>
        <tr>
          <td>Payer</td>
          <td>No</td>
          <td>Yes</td>
        </tr>
        <tr>
          <td>Status</td>
          <td>Yes</td>
          <td>No</td>
        </tr>
        <tr>
          <td>Application Description</td>
          <td>Yes</td>
          <td>Yes</td>
        </tr>
        <tr>
          <td>Payment Request ID</td>
          <td>No</td>
          <td>Yes</td>
        </tr>
        <tr>
          <td>Jurisdiction</td>
          <td>Yes</td>
          <td>Yes</td>
        </tr>
        <tr>
          <td>Collected By</td>
          <td>No</td>
          <td>Yes</td>
        </tr>
        <tr>
          <td>Is Refunded (y/n)</td>
          <td>No</td>
          <td>Yes</td>
        </tr>
      </table>

      <p>
        The following are some mock-ups of how the changes will appear (not all
        available fields are shown in the examples):
      </p>

      <div>Payments Report (New)</div>
      <img
        src="/assets/images/fees-report-changes/daily-payments-mockup.png"
        style="width: 100%"
      />

      <div>Fees Report (Change)</div>
      <img
        src="/assets/images/fees-report-changes/daily-fees-report-mockup.png"
        style="width: 100%"
      />

      <p>Let's look at the example from above in more detail.</p>

      <div class="row">
        <div class="col">
          <div>Fees Collected</div>
          <small>
            <table class="table">
              <tr>
                <th>Fee</th>
                <th>Fee Amount</th>
                <th>Recipient</th>
              </tr>
              <tr>
                <td>County Fee</td>
                <td>10.00</td>
                <td>County Department</td>
              </tr>
              <tr>
                <td>Surcharge Fee</td>
                <td>5.00</td>
                <td>State Surcharge</td>
              </tr>
              <tr>
                <td>Permit Fee</td>
                <td>3.00</td>
                <td>County Department</td>
              </tr>
            </table>
          </small>
        </div>
        <div class="col">
          <div>Payments Made</div>
          <small>
            <table class="table">
              <tr>
                <th>Payment Method</th>
                <th>Transaction #</th>
                <th>Payment Amount</th>
                <th>Paid On</th>
              </tr>
              <tr>
                <td>Cash</td>
                <td>Cash on Hand</td>
                <td>4.00</td>
                <td>4/3/2023 5:00 PM EST</td>
              </tr>
              <tr>
                <td>Online</td>
                <td>2320934093</td>
                <td>14.00</td>
                <td>4/10/2023 3:00 PM EST</td>
              </tr>
            </table>
          </small>
        </div>
      </div>
      <p>
        If we left the Daily Fees Report the way it is currently designed, we
        would see the above example represented as the following. (NOTE: Not all
        available columns are represented).
      </p>
      <table class="table">
        <tr>
          <th>Permit #</th>
          <th>Transaction #</th>
          <th>Fee Amount</th>
          <th>Payment Method</th>
          <th>Paid</th>
          <th>Recipient</th>
          <th>Application</th>
          <th>Payer</th>
          <th>Collected By</th>
        </tr>
        <tr>
          <td>1</td>
          <td>2320934093</td>
          <td>10.00</td>
          <td>Online</td>
          <td>4/10/2023 3:00 PM</td>
          <td>County Department</td>
          <td>Building Permit</td>
          <td>Jane</td>
          <td>Jane</td>
        </tr>
        <tr>
          <td>1</td>
          <td>2320934093</td>
          <td>5.00</td>
          <td>Online</td>
          <td>4/10/2023 3:00 PM</td>
          <td>State Surcharge</td>
          <td>Building Permit</td>
          <td>Jane</td>
          <td>Jane</td>
        </tr>
        <tr>
          <td>1</td>
          <td>2320934093</td>
          <td>3.00</td>
          <td>Online</td>
          <td>4/10/2023 3:00 PM</td>
          <td>County Department</td>
          <td>Building Permit</td>
          <td>Jane</td>
          <td>Jane</td>
        </tr>
        <tr>
          <td>1</td>
          <td>Cash on hand</td>
          <td>10.00</td>
          <td>Cash</td>
          <td>4/3/2023 5:00 PM</td>
          <td>County Department</td>
          <td>Building Permit</td>
          <td>Jane</td>
          <td>Jane</td>
        </tr>
        <tr>
          <td>1</td>
          <td>Cash on hand</td>
          <td>5.00</td>
          <td>Cash</td>
          <td>4/3/2023 5:00 PM</td>
          <td>State Surcharge</td>
          <td>Building Permit</td>
          <td>Jane</td>
          <td>Jane</td>
        </tr>
        <tr>
          <td>1</td>
          <td>Cash on hand</td>
          <td>3.00</td>
          <td>Cash</td>
          <td>4/3/2023 5:00 PM</td>
          <td>County Department</td>
          <td>Building Permit</td>
          <td>Jane</td>
          <td>Jane</td>
        </tr>
      </table>

      <p>
        With the proposed change to Daily Fees and the addition of Daily
        Payments, here is how the above example would be represented on the two
        reports. (NOTE: Not all available columns are represented)
      </p>
      <p>Daily Fees</p>

      <table class="table">
        <tr>
          <th>Permit #</th>
          <th>Fee Amount</th>
          <th>Paid in Full</th>
          <th>Recipient</th>
          <th>Application</th>
        </tr>
        <tr>
          <td>1</td>
          <td>10.00</td>
          <td>4/10/2023 3:00 PM</td>
          <td>County Department</td>
          <td>Building Permit</td>
        </tr>
        <tr>
          <td>1</td>
          <td>5.00</td>
          <td>4/10/2023 3:00 PM</td>
          <td>State Surcharge</td>
          <td>Building Permit</td>
        </tr>
        <tr>
          <td>1</td>
          <td>3.00</td>
          <td>4/10/2023 3:00 PM</td>
          <td>County Department</td>
          <td>Building Permit</td>
        </tr>
      </table>

      <p>Daily Payments</p>

      <table class="table">
        <tr>
          <th>Permit #</th>
          <th>Transaction #</th>
          <th>Payment Amount</th>
          <th>Payment Method</th>
          <th>Paid</th>
          <th>Application</th>
          <th>Payer</th>
          <th>Collected By</th>
        </tr>
        <tr>
          <td>1</td>
          <td>2320934093</td>
          <td>14.00</td>
          <td>Online</td>
          <td>4/10/2023 3:00 PM</td>
          <td>Building Permit</td>
          <td>Jane</td>
          <td>Jane</td>
        </tr>
        <tr>
          <td>1</td>
          <td>Cash on hand</td>
          <td>4.00</td>
          <td>Cash</td>
          <td>4/3/2023 5:00 PM</td>
          <td>Building Permit</td>
          <td>Jane</td>
          <td>Jane</td>
        </tr>
      </table>
    </div>
  </wm-modal-confirm>
</div>
<div class="mt-4">
  <wm-exports-view
    *ngIf="columnOptions && columnOptions.length > 0"
    [id]="id"
    [title]="title"
    [params]="params"
    [currentFilters]="filters"
    [currentColumnOptions]="columnOptions"
    (exptExcel)="exportExcel()"
    (exptCsv)="exportCsv()"
  ></wm-exports-view>

  <wm-datatable
    [id]="id"
    [loading]="loading"
    [useMap]="false"
    [rows]="rows"
    [columns]="columns"
    [count]="page.totalElements"
    [limit]="page.size"
    [offset]="page.pageNumber"
    [defaultFilters]="defaultFilters"
    [params]="params"
    [translateToLegacy]="false"
    [showSimpleSearch]="false"
    [savedFilters]="true"
    (settingsChanged)="handleSettingsChanged($event)"
    [sortField]="sortField"
    [sortDescending]="sortDescending"
    [defaultHiddenColumns]="defaultHiddenColumns"
    [actionColumns]="actionColumns"
    [defaultExportExludedColumns]="defaultExportExludedColumns"
    [availableColumns]="availableColumns"
    (columnOptionsChanged)="handleColumnOptionsChanged($event)"
    [exportingEnabled]="true"
  ></wm-datatable>

  <ng-template #permitNumTmpl let-row="row" let-value="value">
    <a [routerLink]="['/application/workflow-application/', row.applicationId]">
      {{ value }}
    </a>
  </ng-template>

  <ng-template #stackTmpl let-row="row" let-value="value">
    <b>Order:</b> {{ row.orderNumber }} <br />
    <b>Transaction:</b> {{ row.transactionNumber }}
  </ng-template>

  <ng-template #paymentDateTmpl let-row="row" let-value="value">
    {{ row.clientPaymentDate }}
  </ng-template>

  <ng-template #refundTmpl let-row="row">
    <span *ngIf="row.isRefunded">Refunded</span>
    <wm-modal-confirm
      *ngIf="!row.isRefunded && canRefund"
      [moduleId]="row.paymentRequestId"
      title="Refund Payment"
      commandText="Refund"
      [showButtonText]="true"
      buttonText="Refund"
      (accepted)="refundPayment(row.paymentRequestId)"
    >
      Are you sure you want to mark this payment as refunded in GeoPermits? This
      action cannot be undone.<br /><br />This will only mark the payment as
      refunded. You will still need to refund the payment manually in the source
      payment system.
    </wm-modal-confirm>
  </ng-template>
</div>

import { ScreenActivity } from './activity';
import { ActivityModel, Activity } from '.';
import { DataEntity } from '../data-entities';
import { Workflow } from '../workflow';
import { DataEntityMapping } from './data-entity-mapping';

export class InitiateWorkflowActivityModel extends ActivityModel {
  targetWorkflowVersionId: string;
  targetWorkflowName: string;
  targetWorkflowId: string;
  parentToChildMappings: DataEntityMapping[];
  // no childToParentMappings
  callingWorkflowApplicationId: string;
  referencesPublishedWorkflow: boolean;

  constructor(options?: Partial<InitiateWorkflowActivityModel>) {
    super(options);

    if (options) {
      Object.assign(this, options);
    }
  }

  getEntities() {
    // this is only done for childToParentMappings.
    return [];
  }
}

export class InitiateWorkflowActivity extends ScreenActivity<
  InitiateWorkflowActivityModel
> {
  mostRecentlyPublishedTargetWorkflowName: string;
  mostRecentlyPublishedTargetWorkflowVersionId: string;

  constructor(options: { [key: string]: any }) {
    super(options);

    if (options) {
      Object.assign(this, options);
    }
    this.model = new InitiateWorkflowActivityModel(
      options ? options.model : null
    );
  }
}

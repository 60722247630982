import {
  Component,
  OnInit,
  Input,
  ChangeDetectorRef,
  EventEmitter,
  Output
} from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { WorkflowService, WorkflowContextService } from 'src/app/services';
import {
  ConditionTarget,
  ConditionSource
} from '../../system/condition-builder/condition-builder.model';
import { Activity, ActivityModel } from 'src/app/models/activities';
import { DataEntity } from 'src/app/models/data-entities';
import { Workflow } from 'src/app/models';

@Component({
  selector: 'wm-data-entity-formula',
  templateUrl: './data-entity-formula.component.html',
  styleUrls: ['./data-entity-formula.component.css']
})
export class DataEntityFormulaComponent implements OnInit {
  constructor(
    public workflowSvc: WorkflowService,
    private changeDetRef: ChangeDetectorRef,
    private context: WorkflowContextService
  ) {}
  @Input() overrideAdvancedFee = false;
  advancedFee = false;

  conditions: ConditionTarget[] = [];
  conditionSources: ConditionSource[];
  otherwiseCondition: ConditionTarget = new ConditionTarget({
    isOtherwise: true
  });

  @Input() form: UntypedFormGroup;
  @Input() criteria: ConditionTarget[];
  @Input('thenLabelGetter') thenLabelGetter: (
    clause: ConditionTarget
  ) => string;
  @Input() formulaTitle: string;
  @Input() required = true;
  @Input() activity: Activity<ActivityModel>;
  @Input() entityTypes: string[];
  @Input() workflow: Workflow;
  @Input() allowTypeChange = true;
  @Input() onlyGlobalDataEntities = false;
  @Input() placement: string;
  @Input() excludedTemplateCodes: string[] = [];
  @Output() criteriaChanged: EventEmitter<ConditionTarget[]> = new EventEmitter<
    ConditionTarget[]
  >();

  conditionsChanged(e) {
    if (e) {
      this.conditions = e;
    }
    this.criteriaChanged.emit(this.conditions);

    this.handleAdvancedToggleState();

    this.changeDetRef.detectChanges();
  }
  toggleAdvanced() {
    if (!this.allowTypeChange) {
      return;
    }
    this.advancedFee = !this.advancedFee;
    this.changeDetRef.detectChanges();
  }
  handleAdvancedToggleState() {
    if (!this.canSwitchAdvancedFee()) {
      this.form.controls['advancedFee'].disable();
    } else {
      this.form.controls['advancedFee'].enable();
    }
  }
  canSwitchAdvancedFee(): boolean {
    return (this.conditions || []).length === 1; // !this.conditions || this.conditions.length === 0;
  }

  async ngOnInit() {
    if (this.overrideAdvancedFee) {
      this.advancedFee = this.overrideAdvancedFee;
    }

    if (!this.form) {
      this.form = new UntypedFormGroup({});
    }

    if (!this.formulaTitle) {
      this.formulaTitle = 'needs set';
    }

    this.form.addControl(
      'advancedFee',
      new UntypedFormControl(this.advancedFee, [Validators.nullValidator])
    );
    if (!this.criteria) {
      this.criteria = [];
    }
    this.conditions = this.criteria; // .filter(c => !c.isOtherwise);
    this.otherwiseCondition = this.criteria.find(c => c.isOtherwise);
    if (this.conditions.length === 0) {
      this.otherwiseCondition = new ConditionTarget({
        isOtherwise: true
      });
      this.criteria.push(this.otherwiseCondition);
    }
    this.handleAdvancedToggleState();

    if (this.conditions.length > 1) {
      this.advancedFee = true;
    } else {
      this.advancedFee = false;
    }

    if (this.overrideAdvancedFee) {
      this.advancedFee = this.overrideAdvancedFee;
    }

    // TODO: talk to Noah about this 11-18-2019, shouldn't this be in the condition builder?
    //    Is there a reason it isn't?
    const buildEntities = (dataEntities: DataEntity[]) => {
      if (dataEntities) {
        this.conditionSources = dataEntities.map(de => {
          return {
            name: de.templateCode,
            value: de.templateCode,
            operators: de.getAvailableOperators(),
            possibleValues: de.getPossibleValues()
          };
        });
      } else {
        this.conditionSources = [];
      }
    };

    if (this.activity) {
      this.workflowSvc
        .getDataEntitiesBeforeMe(
          this.workflow || this.context.workflow,
          this.activity,
          this.entityTypes
        )
        .subscribe(buildEntities);
    } else {
      this.workflowSvc
        .getDataEntities(
          this.workflow || this.context.workflow,
          this.entityTypes,
          this.onlyGlobalDataEntities
        )
        .subscribe(buildEntities);
    }

    // this.conditionSources = (await this.workflowSvc
    //   .getTemplateItems(this.context.workflow)
    //   .toPromise()).map(t => {
    //   return {
    //     name: t.label,
    //     value: t.templateCode
    //   };
    // });

    this.changeDetRef.detectChanges();
  }
}

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Tag } from 'src/app/models/tag';
import { SystemService, WorkflowContextService } from 'src/app/services';
import { Observable } from 'rxjs';
import { ItemTag } from 'src/app/models/item-tag';
import { map } from 'rxjs/operators';
import { UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'wm-item-tag-list',
  templateUrl: './item-tag-list.component.html',
  styleUrls: ['./item-tag-list.component.css']
})
export class ItemTagListComponent implements OnInit {
  constructor(
    private _systemSvc: SystemService,
    private _context: WorkflowContextService
  ) {}

  @Input() itemTags: ItemTag[];
  @Output() added: EventEmitter<Tag> = new EventEmitter<Tag>();
  @Output() removed: EventEmitter<Tag> = new EventEmitter<Tag>();
  newTag: string;
  addTags: Tag[];
  tags: Tag[];
  form: UntypedFormGroup;

  ngOnInit() {
    this.form = new UntypedFormGroup({
      newTag: new UntypedFormControl('', Validators.required)
    });

    if (this._context.client) {
      this._systemSvc
        .getTagsAlphabetically(this._context.client.id)
        .pipe(
          map(results => {
            this.tags = results;
            this.addTags = results.filter(
              t => !this.itemTags.find(it => it.tagId === t.id)
            );
          })
        )
        .subscribe();
    }
  }

  removeTag(removedTag: ItemTag) {
    const tag = this.tags.find(t => t.id === removedTag.tagId);
    this.removed.emit(tag);
  }

  addTag(tagId: string) {
    const tag = this.tags.find(t => t.id === tagId);
    this.added.emit(tag);
    this.newTag = null;
  }
}

import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Inject,
  forwardRef,
  ViewChild,
  Output
} from '@angular/core';
import {
  MultipleParcelSearchActivity,
  SearchParcelDataActivity
} from '../../../../../models/activities';
import {
  UntypedFormControl,
  AbstractControl,
  UntypedFormBuilder,
  Validators,
  UntypedFormGroup,
  UntypedFormArray
} from '@angular/forms';
import {
  WorkflowContextService,
  DataService,
  Utilities,
  SecurityService
} from '../../../../../services';
import { ActivityView } from '../../../../../views/master-views/app.view/app.view.component';
import { SearchParcelDataCriteria } from '../../../../../models/search-parcel-data-criteria';
import { SearchParcelDataProvider } from '../../../../../services/search-parcel-data.service';
import { EsriMapComponent } from 'src/app/components/arcgis/esri-map/esri-map.component';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { GeomUtils } from 'src/app/components/arcgis/geom-utils';
import { MapService, Actions } from 'src/app/models';
import { MapImageLayerService } from 'src/app/components/arcgis/mapimagelayer.service';
import { ModalConfirmComponent } from 'src/app/components/system/modal-confirm/modal-confirm.component';
import { map } from 'rxjs/operators';
import { combineLatest, Observable } from 'rxjs';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'wm-multiple-parcel-search-activity-input',
  templateUrl: './multiple-parcel-search-activity-input.component.html',
  styleUrls: ['./multiple-parcel-search-activity-input.component.css']
})
export class MultipleParcelSearchActivityInputComponent extends ActivityView
  implements OnInit {
  @Input() activity: MultipleParcelSearchActivity;
  @Input() searchWorkfowApplications = false;
  @Input() hideResultsOnSelect = false;
  @Output() goNext: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() searched: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() form: UntypedFormGroup;

  faTimes = faTimes;
  searchParcelDataCriteria: SearchParcelDataCriteria;
  searchParcelIdCtl: AbstractControl;
  searchParcelOwnerNameCtl: AbstractControl;
  searchParcelAddressCtl: AbstractControl;
  publicNote: string;
  internalNote: string;
  notes: string;
  parcelId: string;
  isInternalUser = false;
  results: any[];
  canViewInternalParcelNotes: boolean;
  canManageParcelNotes: boolean;
  private queueMapZoomToPid = false;
  userId: string;
  showSelectedParcelCard = true;
  showSearchResults = true;

  @ViewChild(EsriMapComponent, { static: false })
  private esriMap: EsriMapComponent;

  _cachedMapService: MapService;
  loading = false;
  @ViewChild('ParcelNotesModal', { static: false }) parcelNotesModal: ModalConfirmComponent;
  constructor(
    private fb: UntypedFormBuilder,
    private _searchParcelDataService: SearchParcelDataProvider,
    private _mapImageLayerService: MapImageLayerService,
    @Inject(forwardRef(() => DataService)) private _dataSvc: DataService,
    @Inject(forwardRef(() => WorkflowContextService))
    private _context: WorkflowContextService
  ) {
    super();
  }
  get parcels() {
    return this.form.controls['parcels'] as UntypedFormArray;
  }

  private loadPermissions() {
    if (this.results.length > 0) {
      const result = this.results[0];

      this.canViewInternalParcelNotes =
        result['canViewInternalParcelNotes'] === '1' ? true : false;
      this.canManageParcelNotes =
        result['canManageParcelNotes'] === '1' ? true : false;
    }
  }

  myParcelSelected(e) {
    this.onParcelsIdentified([e.parcelID]);
  }

  ngOnInit() {
    if (this._context.user) {
      this.userId = this._context.user.id;
    }
    if (this.activity.model.selectedParcels == null) {
      this.activity.model.selectedParcels = [];
    }
    if (this.activity.model.selectedGeometryWkts == null) {
      this.activity.model.selectedGeometryWkts = [];
    }

    this.form.addControl('parcels', this.fb.array([]));
    const searchParcelDataCriteriaGrp = new UntypedFormGroup({
      searchParcelId: new UntypedFormControl('', { updateOn: 'change' }),
      searchParcelOwnerName: new UntypedFormControl('', { updateOn: 'change' }),
      searchParcelAddress: new UntypedFormControl('', { updateOn: 'change' }),
      maxResults: new UntypedFormControl(''),
      clientId: new UntypedFormControl('')
    });
    this.form.addControl(
      'searchParcelDataCriteria',
      searchParcelDataCriteriaGrp
    );
    this.updateSearchParcelCriteria();
    // if we load with a parcel id but don't have a selected parcel then this is an invalid parcel
    if (this.activity.model.parcelId && !this.activity.model.selectedParcels) {
      this.results = [];
    }

    this.results = this.results || [];
    this.activity.model.selectedGeometryWkts.forEach(element => {
      delete element['$id'];
    });
    this.activity.model.selectedParcels.forEach(element => {
      delete element['$id'];
      this.addParcelControl(element);
    });
  }
  updateSearchParcelCriteria() {
    this.searchParcelDataCriteria = new SearchParcelDataCriteria({
      maxResults: 200,
      searchParcelId: this.activity.model.parcelId,
      searchParcelOwnerName: '',
      searchParcelAddress: '',
      clientId: this._context.client.id
    });
  }

  async getParcelLayerInfo(): Promise<MapService> {
    if (!this._cachedMapService) {
      this._cachedMapService = await this._dataSvc
        .getMapService(this.activity.model.mapConfig.mapServiceId)
        .toPromise();
    }
    return this._cachedMapService;
  }

  async onMapLoaded() {
    const ms = await this.getParcelLayerInfo();
    await this.esriMap.enableFeatureSelection(
      ms.parcelLayer,
      ms.parcelLayerKeyField,
      false
    );

    // add to blue layer
    if (this.queueMapZoomToPid) {
      this.queueMapZoomToPid = false;

      let parcelIds = [];
      parcelIds = this.parcels.controls.map((v, i) => v.value.parcelId);

      // add a control for each result.
      await this.esriMap.selectFeaturesOnMap(parcelIds);
    }
    if (this.activity.model.selectedParcels) {
      let parcelIds = [];
      parcelIds = this.activity.model.selectedParcels.map(p => p.parcelID);
      await this.esriMap.selectFeaturesOnMap(parcelIds, 'redlayer');
    }
  }

  addParcelControl(parcel: any) {
    // check to see if it is already on the model, if it is, then selected ==true
    // is it already in the list
    let ctrl = this.selectControlFromResultList(parcel.parcelID)[0];
    const selected = this.isParcelSelected(parcel.parcelID);

    if (!ctrl) {
      const parcelControlForm = this.fb.group({
        parcelId: [parcel.parcelID, Validators.required],
        selected: [
          selected,
          { validators: [Validators.required], updateOn: 'change' }
        ],
        publicNote: [parcel.publicNote],
        internalNote: [parcel.internalNote],
        parcel: [parcel]
      });
      ctrl = parcelControlForm;
      this.parcels.insert(0, parcelControlForm);
      this.results.push(parcel);
    }

    return ctrl;
  }
  search(
    searchCriteria: SearchParcelDataCriteria,
    toggleResult = false,
    updateMap = false
  ) {
    this.searched.emit();
    this.loading = true;
    this._searchParcelDataService
      .getSearchParcelDataResults(searchCriteria)
      .subscribe(
        res => {
          this.loading = false;
          // results includes the header row, so filter it out
          this.results = this.results || [];
          const localresults = res.filter((v, i) => i > 0);

          this.loadPermissions();

          localresults.forEach(r => {
            const ctrl = this.addParcelControl(r);
            if (toggleResult) {
              const selected = this.isParcelSelected(r.parcelID);
              ctrl.patchValue({ selected: !selected });
            }

            if (toggleResult) {
              this.selectOneResult(ctrl, updateMap);
            }
          });
        },
        err => {
          this.loading = false;
          throw err;
        }
      );
  }

  submitSearchParcelDataCriteria(e) {
    e.preventDefault();
    this.clearResults();
    this.search(this.searchParcelDataCriteria, false, true);
  }

  clearResults() {
    this.results = [];
    this.clearFormArray(this.parcels);
  }
  clearFormArray = (formArray: UntypedFormArray) => {
    for (let i = formArray.length - 1; i >= 0; i--) {
      const selected = formArray.at(i).value.selected;
      // we decided not to clear the selectedParcels every search  during code review on 20230630
      if (selected === false) {
        formArray.removeAt(i);
      }
    }
  }
  // map interactions:
  onParcelsBeforeIdentifying() {
    this.loading = true;
  }

  onParcelsIdentified(parcelIds: string[]) {
    if (parcelIds) {
      const search = new SearchParcelDataCriteria({
        maxResults: 200,
        clientId: this._context.client.id,
        searchParcelIds: parcelIds
      });
      this.search(search, true, false);
    } else {
      this.loading = false;
    }
  }

  onSearchMapTabChange(tce: NgbNavChangeEvent) {
    switch (tce.nextId) {
      case 'mapTab':
        this.showSelectedParcelCard = false;
        this.showSearchResults = true;
        this.switchToMapTab();
        break;
      case 'searchTab':
        this.showSelectedParcelCard = true;
        this.showSearchResults = true;
        break;
      case 'myParcelsTab':
        this.showSelectedParcelCard = true;
        this.showSearchResults = false;
        break;
      default:
        this.showSearchResults = true;
        this.showSelectedParcelCard = true;
    }
  }
  alertChildComponentChange() {
    // child components don't detect changes when you just push it, you need to reassign the array.
    this.activity.model.selectedParcels = this.activity.model.selectedParcels.slice();
  }

  async selectOneResult(result: UntypedFormGroup, updateMap: boolean, goNext = false) {
    const parcelId = result.value.parcel.parcelID;

    // if value ==true add it to the model
    if (result.value.selected === true) {
      // if it isn't already in the model...
      if (
        this.getParcelIdsFromSelectedList(result.value.parcelId).length === 0
      ) {
        // strip off the id
        delete result.value.parcel['$id'];
        this.activity.model.selectedParcels.push(result.value.parcel);
      }

      if (this.activity.model.mapConfig.mapServiceId) {
        const apiUrl = Utilities.getRootURL();
        const url = `${apiUrl}/api/mapproxy/mapserver/${this.activity.model.mapConfig.mapServiceId}`;
        const ms = await this.getParcelLayerInfo();
        const geom = await this._mapImageLayerService.getParcelGeometryWgs84(
          parcelId,
          url,
          ms.parcelLayer,
          ms.parcelLayerKeyField
        );
        const wkt = geom != null ? GeomUtils.ArcGisGeomToWkt(geom) : null;
        this.activity.model.selectedGeometryWkts.push({
          parcelId: parcelId,
          wkt: wkt
        });
      } else {
        this.activity.model.selectedGeometryWkts.push({
          parcelId: parcelId,
          wkt: null
        });
      }

      if (this.esriMap && updateMap) {
        this.esriMap.selectFeaturesOnMap([parcelId]);
      }
    }
    // if value == false take it away from the model
    if (result.value.selected === false) {
      if (this.esriMap && updateMap) {
        this.esriMap.selectFeaturesOnMap([parcelId]);
      }
      this.removeFromSelected({ parcelID: parcelId });
    }
    this.alertChildComponentChange();
    if (goNext) {
      this.goNext.emit(true);
    }
  }

  removeFromSelected(s: any) {
    this.removeFromSelectedParcels(s.parcelID);
    this.removeFromSelectedGeometryWkts(s.parcelID);
    this.unselectFromResultList(s.parcelID);
  }

  isParcelSelected(parcelId: string) {
    let selected = false;
    const item_i = this.getParcelIdsFromSelectedList(parcelId);
    if (item_i.length > 0) {
      selected = true;
    }
    return selected;
  }
  unselectFromResultList(parcelId: string) {
    const ctls = this.selectControlFromResultList(parcelId);
    ctls.forEach(v => {
      v.patchValue({
        selected: false
      });
    });
  }
  removeFromSelectedParcels(parcelId: string) {
    const selectedParcelIds = this.getParcelIdsFromSelectedList(parcelId);
    selectedParcelIds.forEach(item => {
      this.activity.model.selectedParcels.splice(item.index, 1);
    });
    this.alertChildComponentChange();
  }
  removeFromSelectedGeometryWkts(parcelId: string) {
    const item_i = this.getWktsFromSelectedList(parcelId);
    item_i.forEach(item => {
      this.activity.model.selectedGeometryWkts.splice(item.index, 1);
    });
  }
  selectControlFromResultList(parcelId) {
    const item_i = this.parcels.value
      .map((r, z) => {
        return {
          parcel: r.parcel,
          index: z,
          selected: r.selected
        };
      })
      .filter(i => {
        return i.parcel.parcelID === parcelId;
      });
    const ctls: any[] = [];
    item_i.forEach(v => {
      const ctl = this.parcels.at(v.index);
      ctls.push(ctl);
    });

    return ctls;
  }
  getWktsFromSelectedList(parcelId) {
    const itemindex = this.activity.model.selectedGeometryWkts.findIndex(
      i => i.parcelId === parcelId
    );

    let item_i = [];
    if (itemindex !== -1) {
      item_i = [this.activity.model.selectedGeometryWkts[itemindex]].map(
        (parcelItem, z) => {
          return {
            parcel: parcelItem.parcelId,
            index: itemindex,
            selected: parcelItem.parcelId === parcelId
          };
        }
      );
    }
    return item_i;
  }

  getParcelIdsFromSelectedList(parcelId) {
    const itemindex = this.activity.model.selectedParcels.findIndex(
      i => i.parcelID === parcelId
    );
    let item_i = [];
    if (itemindex !== -1) {
      item_i = [this.activity.model.selectedParcels[itemindex]].map(
        (parcelItem, z) => {
          return {
            parcel: parcelItem.parcelID,
            index: itemindex,
            selected: parcelItem.parcelID === parcelId
          };
        }
      );
    }
    return item_i;
  }

  switchToMapTab() {
    this.queueMapZoomToPid = this.results && this.results.length > 0;
  }

  showParcel(parcel: any) {
    // it's supposed to hide the result after you check it, but we decided not to implement it right now.  We might turn it back on after user feedback.  (+ plus it's contantly running)
    return this.hideResultsOnSelect && this.activity.model.selectedParcels
      ? this.isParcelSelected(parcel)
      : true;
  }
  getParcelNote(parcelId: string) {
    this._dataSvc.getParcelNote(parcelId).subscribe(response => {
      this.internalNote = response.internalNote;
      this.publicNote = response.publicNote;
    });
  }

  notesModal(publicNotes: string, internalNotes: string, parcelId: string) {
    publicNotes = publicNotes ? publicNotes : '';
    internalNotes = internalNotes ? internalNotes : '';
    this._dataSvc
      .saveParcelNote(publicNotes, internalNotes, parcelId)
      .subscribe(response => {});
  }
}

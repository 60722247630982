<form [formGroup]="form" *ngIf="entity">
  <br />
  <wm-de-label [entity]="entity" [for]="entity.templateCode"></wm-de-label>
  <div>
    <div class="row">
      <div class="col-3">
        <wm-upload
          [validDocumentTypes]="this.documentEntity.validDocumentTypes"
          [pathSegments]="[
            this.applicationId,
            this.parentEntityId,
            this.documentTemplateCode
          ]"
          alignment="left"
          (fileUploaded)="fileUploaded($event)"
          [documentService]="this.uploadService"
          [key]="entity.templateCode"
          [isPreview]="isPreview"
          [showSingleButton]="true"
          category="Application"
        ></wm-upload>
      </div>
    </div>
    <ul class="list-group">
      <li
        *ngFor="let d of documentEntity.documents; let idx = index"
        class="list-group-item"
        [ngStyle]="{ cursor: allowSelect ? 'pointer' : 'default' }"
      >
        <div class="row">
          <div
            [class]="showRemove ? 'col-xs-10' : 'col-xs-12'"
            style="padding-top: 5px"
          >
            <a href="{{ d.path }}" target="_blank">{{ d.name }}</a>
          </div>
          <div class="col-xs-3">
            <wm-modal-confirm
              *ngIf="deleteStoredDocument"
              [moduleId]="'delete_document' + d.id"
              [commandText]="'Delete Document'"
              [title]="'Delete Document'"
              [showButtonText]="true"
              (accepted)="deleteDocument(d)"
              [enabled]="true"
            >
              <p>Are you sure you want to delete {{ d.name }}?</p>
              <p>
                Deleting this Document will permanently remove the document from
                this entity.
              </p>
            </wm-modal-confirm>
            <wm-modal-confirm
              *ngIf="!deleteStoredDocument"
              [moduleId]="'delete_document' + d.id"
              [buttonText]="'Delete Document'"
              [commandText]="'Ok'"
              [title]="'Delete Document'"
              [showButtonText]="true"
              (accepted)="deleteDocument(d)"
              [enabled]="true"
            >
              <p>
                {{ d.name }} will be permanently deleted when Save, Next or
                Update is clicked.
              </p>
            </wm-modal-confirm>
          </div>
        </div>
      </li>
    </ul>
  </div>
  <span>Valid Document Types: {{ documentEntity.validDocumentTypes }}</span>
</form>

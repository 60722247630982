<wm-de-label
  [entity]="entity"
  [for]="entity.templateCode"
  [displayOnly]="true"
></wm-de-label>
<div class="sig-container" style="border-bottom: 1px solid black">
  <div fxLayout="row">
    <img [src]="imageDataPath" style="height: 100px" *ngIf="imageDataPath" />
    <div style="height: 100px" *ngIf="!imageDataPath"></div>
  </div>
</div>

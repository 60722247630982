<div>
  <div class="row pb-3">
    <div class="flex-column">
      <h3>Agenda Types</h3>
    </div>
    <div class="flex-column pl-5">
      <div *ngIf="canManageAgendas() | async">
        <button
          type="button"
          class="btn btn-raised btn-success float-right"
          (click)="newAgendaTypeModal.open()"
        >
          New Agenda Type
        </button>
      </div>
    </div>
  </div>

  <table class="table">
    <tr>
      <th>Name</th>
      <th>Description</th>
    </tr>
    <tr *ngFor="let agendaType of agendaTypes">
      <td class="align-middle">
        <a
          [routerLink]="[
            '/admin/jurisdiction',
            clientId,
            'meetings',
            'agenda-types',
            'agenda-type',
            agendaType.id
          ]"
        >
          {{ agendaType.name }}
        </a>
      </td>
      <td class="align-left">
        <i>
          {{ agendaType.description }}
        </i>
      </td>
    </tr>
    <tr *ngIf="agendaTypes && agendaTypes.length === 0">
      <td>No agenda types to display</td>
    </tr>
  </table>
</div>

<wm-modal-confirm
  title="New Agenda Type"
  [showButtonText]="false"
  [showButton]="false"
  commandText="Save"
  (canceled)="onModalCancel()"
  (accepted)="onModalSave()"
  [form]="form"
  #newAgendaTypeModal
>
  <div [formGroup]="form">
    <div class="form-group">
      <label class="bmd-label-floating" for="name">Name</label>
      <input
        type="text"
        class="form-control"
        [(ngModel)]="newAgendaType.name"
        name="name"
        formControlName="name"
      />
      <wm-control-message
        [control]="form.controls['name']"
      ></wm-control-message>
    </div>
    <div class="form-group">
      <label class="bmd-label-floating" for="description">Description</label>
      <input
        type="text"
        class="form-control"
        [(ngModel)]="newAgendaType.description"
        name="description"
        formControlName="description"
      />
      <wm-control-message
        [control]="form.controls['description']"
      ></wm-control-message>
    </div>
    <div class="form-group">
      <label class="bmd-label-floating" for="header">Agenda Header</label>
      <ckeditor
        #headerEditor
        [editor]="Editor"
        [config]="editorConfig"
        formControlName="header"
        [(ngModel)]="newAgendaType.header"
        (ready)="editorReady($event)"
      ></ckeditor>
      <wm-control-message
        [control]="form.controls['header']"
      ></wm-control-message>
    </div>
    <div class="form-group">
      <label class="bmd-label-floating" for="defaultItemsNumber"
        >Default number of agenda items per meeting</label
      >
      <input
        type="text"
        class="form-control"
        [(ngModel)]="newAgendaType.defaultItemCount"
        name="defaultItemsNumber"
        formControlName="defaultItemsNumber"
      />
      <wm-control-message
        [control]="form.controls['defaultItemsNumber']"
      ></wm-control-message>
    </div>
  </div>
</wm-modal-confirm>

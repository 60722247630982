export class EmailAddress {
  id: string;
  name: string;
  email: string;
  parent: any;
  parentType: string;
}

export class UserEmailAddress extends EmailAddress {
  constructor(options?: Partial<UserEmailAddress>) {
    super();
    this.name = 'Default';
    this.parentType = 'User';
    if (options) {
      Object.assign(this, options);
    }
  }
}
export class ContactEmailAddress extends EmailAddress {
  constructor(options?: Partial<ContactEmailAddress>) {
    super();
    this.name = 'Default';
    this.parentType = 'Contact';
    if (options) {
      Object.assign(this, options);
    }
  }
}
export class ContractorEmailAddress extends EmailAddress {
  constructor(options?: Partial<ContractorEmailAddress>) {
    super();
    this.name = 'Default';
    this.parentType = 'Contractor';

    if (options) {
      Object.assign(this, options);
    }
  }
}

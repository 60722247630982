<div id="activities" *ngIf="activities">
  <ng-container *ngFor="let activity of activities">
    <h5>
      {{ activity.model.screenName }}&nbsp;
      <span
        style="font-size: .9rem;"
        *ngIf="activity && activity.status === ActivityStatus.Completed"
        >Completed On {{ activity.completedOnString }} by
        {{ activity.completedBy }}</span
      >
    </h5>
    <wm-activity-view
      [activity]="activity"
      [isReadOnly]="true"
      [form]="form"
      [isPreview]="false"
    ></wm-activity-view>
  </ng-container>
  <div>
    <h5>External Notes</h5>
    <wm-application-external-note-editor
      [application]="application"
      [readOnly]="true"
    ></wm-application-external-note-editor>
  </div>
  <div *ngIf="canViewInternalNotes">
    <h5>Internal Notes</h5>
    <wm-application-internal-note-editor
      [application]="application"
      [readOnly]="true"
    ></wm-application-internal-note-editor>
  </div>
</div>

import {
  Component,
  OnInit,
  OnChanges,
  Input,
  Output,
  EventEmitter,
  SimpleChange
} from '@angular/core';
import { Role } from '../../../models';

@Component({
  selector: 'wm-role-list-by-client',
  templateUrl: './role-list-by-client.component.html',
  styleUrls: ['./role-list-by-client.component.css']
})
export class RoleListByClientComponent implements OnInit, OnChanges {
  @Input() roles: Role[];
  @Output() removed: EventEmitter<Role> = new EventEmitter<Role>();
  clientRoles: any = [];

  ngOnChanges(changes: { [propertyName: string]: SimpleChange }) {
    for (const propName in changes) {
      if (propName === 'roles') {
        //this.clientRoles = this.roles;
        if (this.roles) {
          const rolesByClientDict = [];

          this.roles.forEach((value, i) => {
            //if (value.client) {
            const clientId: string = value.client ? value.client.id : '';

            //check to see if we have processed the client yet
            if (!rolesByClientDict[clientId]) {
              rolesByClientDict[clientId] = {
                name: value.client ? value.client.name : 'System',
                roles: []
              };
            }

            const found = rolesByClientDict[clientId].roles.some((gValue, i) => {
              if (gValue.id === value.id) {
                return true;
              }
            });

            if (!found) {
              rolesByClientDict[clientId].roles.push(value);
            }
            //}
          });

          const rolesByClient = [];

          for (const o in rolesByClientDict) {
            rolesByClient.push(rolesByClientDict[o]);
          }

          this.clientRoles = rolesByClient;
        } else {
          this.clientRoles = [];
        }
      }
    }
  }

  ngOnInit() {}
}

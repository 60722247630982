<app-loader *ngIf="!results"></app-loader>

<table class="table" *ngIf="results && results.length > 0">
  <thead>
    <tr>
      <th scope="col"></th>
      <th scope="col">Parcel ID</th>
      <th scope="col">Address</th>
      <th scope="col">City</th>
      <th scope="col">Owner Name</th>
      <th scope="col" class="align-right">Acres</th>
      <th scope="col"></th>
    </tr>
  </thead>
  <tbody [formGroup]="form">
    <ng-container formArrayName="myparcels">
      <!-- <ng-container *ngFor="let r of results"> -->
      <ng-container
        *ngFor="let parcelControl of myparcels.controls; let i = index"
      >
        <tr
          [ngClass]="{ 'table-active': parcelControl.value.selected }"
          [formGroup]="parcelControl"
        >
          <td>
            <div
              class="custom-control custom-checkbox"
              [hidden]="!allowMultiples"
            >
              <input
                type="checkbox"
                class="custom-control-input"
                formControlName="selected"
                [id]="'pControl' + parcelControl.value.parcelId"
                [value]="parcelControl.value.selected"
                (change)="selectParcel(parcelControl)"
              />
              <label
                class="custom-control-label"
                [for]="'pControl' + parcelControl.value.parcelId"
              ></label>
            </div>

            <a
              href="javascript:void(0)"
              (click)="selectParcel(parcelControl)"
              [hidden]="allowMultiples"
            >
              Select
            </a>
          </td>
          <td>{{ parcelControl.value.parcelId }}</td>
          <td>{{ parcelControl.value.parcel.address }}</td>
          <td>{{ parcelControl.value.parcel.city }}</td>
          <td>{{ parcelControl.value.parcel.ownerName }}</td>
          <td class="align-right">
            {{ parcelControl.value.parcel.acres * 1 | number: '1.3-3' }}
          </td>
        </tr>
      </ng-container>
    </ng-container>
  </tbody>
</table>

<p *ngIf="results && results.length < 1">No results</p>

<div class="modal-header">
  <h4 class="modal-title" id="modal-validity-title">
    {{ workflow?.version?.name || 'Workflow' }} Validation
  </h4>
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="activeModal.dismiss('Cross click')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body validation">
  <app-loader *ngIf="loading"></app-loader>
  <div *ngIf="!loading">
    <p [ngClass]="validity.length > 0 ? 'text-danger' : 'text-success'">
      {{ validity.length }} problems in this workflow
    </p>

    <ul ngbNav #navs="ngbNav" class="nav-tabs">
      <li ngbNavItem>
        <a ngbNavLink>
          Activities
          <span class="badge badge-pill badge-primary">
            {{ activityErrors.length }}
          </span>
        </a>
        <ng-template ngbNavContent>
          <div class="mt-3">
            <div ngbAccordion>
              <div
                ngbAccordionItem
                [collapsed]="false"
                class="card-header"
                *ngFor="let error of activityErrors"
              >
                <h2 ngbAccordionHeader>
                  <button
                    ngbAccordionToggle
                    class="p-0 btn btn-link text-start ps-0"
                  >
                    {{ error.activity?.model.screenName }}

                    <span class="badge badge-pill badge-primary">
                      {{ error.value.messages.length }}
                    </span>
                  </button>
                </h2>
                <div ngbAccordionCollapse>
                  <div ngbAccordionBody>
                    <ul class="list-group" class="p-0 mb-0">
                      <li
                        class="list-group-item"
                        *ngFor="let msg of error.value.messages"
                        (click)="click(error)"
                      >
                        <div *ngIf="msg">
                          <span
                            [ngClass]="severity(msg.severity).class"
                            class="mr-1"
                          >
                            {{ severity(msg.severity).name }}
                          </span>
                          <span>{{ msg.message }}</span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <!-- <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panel-0">
              <ngb-panel *ngFor="let error of activityErrors">
                <ng-template ngbPanelTitle>
                  {{ error.activity?.model.screenName }}

                  <span class="badge badge-pill badge-primary">
                    {{ error.value.messages.length }}
                  </span>
                </ng-template>
                <ng-template ngbPanelContent>
                  <ul class="list-group" class="p-0 mb-0">
                    <li
                      class="list-group-item"
                      *ngFor="let msg of error.value.messages"
                      (click)="click(error)"
                    >
                      <span
                        [ngClass]="severity(msg.severity).class"
                        class="mr-1"
                      >
                        {{ severity(msg.severity).name }}
                      </span>
                      <span>{{ msg.message }}</span>
                    </li>
                  </ul>
                </ng-template>
              </ngb-panel>
            </ngb-accordion> -->
          </div>
        </ng-template>
      </li>
      <li ngbNavItem>
        <a ngbNavLink>
          Data Entities
          <span class="badge badge-pill badge-primary">
            {{ deErrors.length }}
          </span>
        </a>
        <ng-template ngbNavContent>
          <div class="mt-3">
            <div ngbAccordion #deAcc="ngbAccordion">
              <div
                ngbAccordionItem
                #entities="ngbAccordionItem"
                [collapsed]="false"
                class="card-header"
                *ngFor="let error of deErrors"
              >
                <h2 ngbAccordionHeader>
                  <button
                    ngbAccordionToggle
                    class="p-0 btn btn-link text-start ps-0"
                  >
                    <span *ngIf="error.dataEntity">{{
                      error.dataEntity.templateCode
                    }}</span
                    >&nbsp;

                    <span class="badge badge-pill badge-primary">
                      {{ error.value.messages.length }}
                    </span>
                  </button>
                </h2>
                <div ngbAccordionCollapse>
                  <div ngbAccordionBody>
                    <ul class="list-group" class="p-0 mb-0">
                      <li
                        class="list-group-item"
                        *ngFor="let msg of error.value.messages"
                        (click)="click(error)"
                      >
                        <div *ngIf="msg">
                          <span
                            [ngClass]="severity(msg.severity).class"
                            class="mr-1"
                          >
                            {{ severity(msg.severity).name }}
                          </span>
                          <span>{{ msg.message }}</span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <!-- <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panel-0">
              <ngb-panel *ngFor="let error of deErrors">
                <ng-template ngbPanelTitle>
                  <span *ngIf="error.dataEntity">{{
                    error.dataEntity.templateCode
                  }}</span>

                  <span class="badge badge-pill badge-primary">
                    {{ error.value.messages.length }}
                  </span>
                </ng-template>
                <ng-template ngbPanelContent>
                  <ul class="list-group" class="p-0 mb-0">
                    <li
                      class="list-group-item"
                      *ngFor="let msg of error.value.messages"
                      (click)="click(error)"
                    >
                      <span
                        [ngClass]="severity(msg.severity).class"
                        class="mr-1"
                      >
                        {{ severity(msg.severity).name }}
                      </span>
                      <span>{{ msg.message }}</span>
                    </li>
                  </ul>
                </ng-template>
              </ngb-panel>
            </ngb-accordion> -->
          </div>
        </ng-template>
      </li>
      <li ngbNavItem>
        <a ngbNavLink>
          Workflow
          <span class="badge badge-pill badge-primary">
            {{ workflowErrors.length }}
          </span>
        </a>
        <ng-template ngbNavContent>
          <div class="mt-3">
            <div ngbAccordion #deAcc="ngbAccordion">
              <div
                ngbAccordionItem
                #entities="ngbAccordionItem"
                [collapsed]="false"
                class="card-header"
                *ngFor="let error of workflowErrors"
              >
                <h2 ngbAccordionHeader>
                  <button
                    ngbAccordionToggle
                    class="p-0 btn btn-link text-start ps-0"
                  >
                    Topology

                    <span class="badge badge-pill badge-primary">
                      {{ error.value.messages.length }}
                    </span>
                  </button>
                </h2>
                <div ngbAccordionCollapse>
                  <div ngbAccordionBody>
                    <ul class="list-group" class="p-0 mb-0">
                      <li
                        class="list-group-item"
                        *ngFor="let msg of error.value.messages"
                        (click)="click(error)"
                      >
                        <div *ngIf="msg">
                          <span
                            [ngClass]="severity(msg.severity).class"
                            class="mr-1"
                          >
                            {{ severity(msg.severity).name }}
                          </span>
                          <span>{{ msg.message }}</span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <!-- <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panel-0">
              <ngb-panel *ngFor="let error of workflowErrors">
                <ng-template ngbPanelTitle>
                  Topology

                  <span class="badge badge-pill badge-primary">
                    {{ error.value.messages.length }}
                  </span>
                </ng-template>
                <ng-template ngbPanelContent>
                  <ul class="list-group" class="p-0 mb-0">
                    <li
                      class="list-group-item"
                      *ngFor="let msg of error.value.messages"
                      (click)="click(error)"
                    >
                      <span
                        [ngClass]="severity(msg.severity).class"
                        class="mr-1"
                      >
                        {{ severity(msg.severity).name }}
                      </span>
                      <span>{{ msg.message }}</span>
                    </li>
                  </ul>
                </ng-template>
              </ngb-panel>
            </ngb-accordion> -->
          </div>
        </ng-template>
      </li>
    </ul>
    <div [ngbNavOutlet]="navs"></div>
  </div>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-outline-dark"
    (click)="activeModal.close('Close click')"
  >
    Close
  </button>
</div>

<div
  class="bmd-layout-container bmd-drawer-f-l container-fluid"
  [ngClass]="{
    'bmd-drawer-overlay': isMobileDevice,
    'bmd-drawer-out': isMobileDevice && !menuOpen,
    'bmd-drawer-in': !isMobileDevice || menuOpen
  }"
>
  <header class="bmd-layout-header">
    <nav
      *ngIf="isMobileDevice"
      class="navbar navbar-expand-lg navbar-light bg-white"
    >
      <button
        *ngIf="isMobileDevice"
        (click)="menuOpen = !menuOpen"
        class="navbar-toggler mr-3"
        id="sidebar-toggler"
        type="button"
      >
        <span class="sr-only">Toggle drawer</span>
        <i class="material-icons">menu</i>
      </button>
      <a class="navbar-brand mr-auto" *ngIf="context?.client?.name">
        {{ context.client.name }}
      </a>
    </nav>
  </header>
  <div id="dw-app" class="bmd-layout-drawer bg-faded">
    <header>
      <a class="navbar-brand" *ngIf="context.client">
        <span *ngIf="!context.client.logoURL">{{ context.client.name }}</span>
        <img
          *ngIf="context.client.logoURL"
          [src]="context.client.logoURL"
          [alt]="context.client.name"
          style="max-height: 150px;"
          class="img-fluid"
        />
      </a>
      <div class="pt-3 pl-4">
        <!-- style="padding-left: 35px" -->
        <h4>{{ workflowName }}</h4>

        <h6 class="text-muted">
          {{ applicationNumber }}
        </h6>
      </div>
    </header>
  </div>
  <main
    class="bmd-layout-content"
    style="position: fixed; top: 0;bottom: 60px; right: 0;left:0"
    (click)="mainClick()"
    [ngClass]="{
      'overlay-bg': menuOpen && isMobileDevice,
      'mobile-top-margin': isMobileDevice
    }"
  >
    <router-outlet></router-outlet>
  </main>
</div>

import { Client } from '.';

export class PaymentTransactionItem {
  requestedOn: Date;
  confirmedOn: Date;
  parentReference: string;
  parentType: string;
  name: string;
  client: Client;
  recipient: string;
  feeName: string;
  amount: number;
  paymentMethod: string;
}

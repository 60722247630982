import { SortDirection } from './../../../models/report-data-set';
import { translateFilterBuilderToLegacy } from './../../../models/filter-builder';
import {
  FilterBuilderParam,
  FilterBuilderOutput,
  FilterBuilderFilter
} from 'src/app/models/filter-builder';
import { ItemSearchOptionField } from './../../filter-list/models/filterClasses';
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
  SimpleChanges,
  OnChanges,
  ViewChild,
  ChangeDetectorRef,
  AfterViewInit
} from '@angular/core';
import {
  TableColumn,
  ColumnMode,
  SelectionType
} from '@swimlane/ngx-datatable';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { ColumnOption, GridSettings } from 'src/app/models/grid-settings';
import { ColumnSet } from 'src/app/models/saved-filter';
import { Observable, Subscription } from 'rxjs';
import { throwIfEmpty } from 'rxjs/operators';
import {
  Location,
  LocationStrategy,
  PathLocationStrategy
} from '@angular/common';
import { toJSON } from 'esri/identity/IdentityManager';

export interface BulkAction {
  name: string;
  id: string;
  action: (rows: any[]) => Promise<boolean>;
}

@Component({
  selector: 'wm-datatable',
  providers: [
    Location,
    { provide: LocationStrategy, useClass: PathLocationStrategy }
  ],
  templateUrl: './datatable.component.html',
  styleUrls: ['./datatable.component.css']
})
export class DatatableComponent
  implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  @ViewChild('navs') tabset: any;
  @Input() useMap = false;
  location: Location;
  // ngx-datatable options
  @Input() loading: boolean;
  @Input() rows: any[];
  @Input() columns: TableColumn[];
  @Input() count: number;
  @Input() offset: number;
  @Input() limit: number;
  @Input() selected = [];
  @Input() popupTemplateContent: string;
  @Input() popupTemplateTitle: string;
  @Input() popupActions = [];
  // filter builder options
  @Input() simpleSearchTitle = 'Search for applications';
  // id passed from parent component
  @Input() id: string;
  @Input() params: FilterBuilderParam[];
  @Input() defaultFilters: FilterBuilderFilter[];
  @Input() filterTypes = [
    {
      name: 'contains'
    },
    {
      name: 'is'
    },
    {
      name: 'in'
    },
    {
      name: 'range'
    }
  ];
  @Input() savedFilters = false;
  @Input() translateToLegacy = true;
  @Input() showFilterBuilder = true;
  @Input() showSimpleSearch = true;
  @Input() getId = null;
  @Input() bulkActions: BulkAction[] = [];
  @Input() unpermissibleCount = 0;
  @Input() pageSize = 10;
  @Input() sortField: string;
  @Input() sortDescending: boolean;
  @Input() defaultHiddenColumns: string[] = []; // column prop strings to be hidden on the grid by default
  @Input() actionColumns: string[] = []; // column prop strings that should have no option to hide in the grid, and are not exportable (such as button columns like Refund or Actions)
  @Input() defaultExportExludedColumns: string[] = []; // column prop strings to be excluded from exports by default
  @Input() dynamicColumnsSet: Observable<TableColumn[]>;
  @Input() waitForParent = false;
  @Input() availableColumns: TableColumn[] = [];
  @Input() dynamicColumnsVisibleByDefault: boolean;
  @Input() exportingEnabled = false;

  @Output() settingsChanged: EventEmitter<GridSettings> = new EventEmitter<
    GridSettings
  >();

  @Input() listeningToSC2 = false;
  // needed for contractor-registration-list until other endpoints handle multiple filter options like the contractor search
  @Output() settingsChanged2: EventEmitter<GridSettings> = new EventEmitter<
    GridSettings
  >();

  @Output() columnOptionsChanged: EventEmitter<
    ColumnOption[]
  > = new EventEmitter<ColumnOption[]>();

  @Output() reportFiltersChange: EventEmitter<
    FilterBuilderOutput
  > = new EventEmitter();

  @Output() tabChange: EventEmitter<NgbNavChangeEvent> = new EventEmitter<
    NgbNavChangeEvent
  >();
  // esri-map options
  @Output() mapDataRequested: EventEmitter<any> = new EventEmitter();

  public pageSizeOptions = [10, 25, 50, 100];
  public currentFilters: ItemSearchOptionField[];
  ColumnMode = ColumnMode;
  SelectionType = SelectionType;
  settings: GridSettings = new GridSettings({
    pageNumber: 0,
    pageSize: 10
  });
  selectedTab = 'data';
  urlParams = {};

  startingMapVariables: {
    mapExtent: {
      xmax: number;
      ymax: number;
      xmin: number;
      ymin: number;
    };
    mapRequests: number;
  } = { mapExtent: null, mapRequests: 0 };

  private columnsSetSubscription: Subscription;

  get cacheId(): string {
    return `datatable-${this.id}`;
  }

  constructor(location: Location, private _ref: ChangeDetectorRef) {
    this.location = location;
  }
  tabChangeEvent(event) {
    this.tabChange.emit(event);
    this.selectedTab = event.nextId;
    this.setURLFromSettings();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['columns']) {
      this.includeBulkActionsSelect();
    }
  }

  includeBulkActionsSelect() {
    if (this.isBulkActionsEnabled) {
      if (this.columns && this.columns[0].prop !== 'select') {
        // add checkboxes column to the beginning if there are bulk actions
        this.columns.unshift({
          width: 30,
          prop: 'select',
          sortable: false,
          canAutoResize: false,
          draggable: false,
          resizeable: false,
          headerCheckboxable: true,
          checkboxable: true
        });
      }
    }
  }

  getStartingMapExtentFromURL() {
    const xmax = this.urlParams['xmax'];
    const ymax = this.urlParams['ymax'];
    const xmin = this.urlParams['xmin'];
    const ymin = this.urlParams['ymin'];

    if (xmax === 0 || ymax === 0 || xmin === 0 || ymin === 0) {
      return null;
    }
    const startingExtent = { xmax, ymax, xmin, ymin };
    return startingExtent;
  }
  mapExtentChanged(extentChangedValue: {
    xmax: number;
    ymax: number;
    xmin: number;
    ymin: number;
  }) {
    const initialExent = {
      // Continental USA - helps with initial query.  When the map zooms out too far, it starts producing extents that don't make sense.
      xmax: -66.15485030280396,
      xmin: -126.56035948902678,
      ymax: 52.276217308571994,
      ymin: 20.439053181092586
    };

    const sortandTruncateMapExtents = object => {
      if (object == null) {
        return '';
      }
      return Object.keys(object)
        .sort()
        .reduce((acc, c) => {
          const number = object[c].toString();
          acc[c] = number.slice(0, number.indexOf('.') + 5);
          return acc;
        }, {});
    };
    if (this.startingMapVariables.mapExtent) {
      delete this.startingMapVariables.mapExtent['spatialReference'];
    }
    const changeExtent = {
      initialExtent: JSON.stringify(sortandTruncateMapExtents(initialExent)),
      startingExtent: JSON.stringify(
        sortandTruncateMapExtents(this.startingMapVariables.mapExtent)
      ),
      newExtent: JSON.stringify(sortandTruncateMapExtents(extentChangedValue))
    };
    if (changeExtent.newExtent !== changeExtent.initialExtent) {
      if (changeExtent.newExtent !== changeExtent.startingExtent) {
        this.startingMapVariables.mapExtent = JSON.parse(
          changeExtent.newExtent
        );
        this.setURLFromSettings();
      }
    } else {
      this.startingMapVariables.mapRequests = 0;
    }
  }
  getSettingsFromURL() {
    const urlParams = new URL(location.href).searchParams;
    const parsedValue = urlParams.get(`${this.id}_SSSettings`);
    // set defaults
    const psodic = {
      page: this.offset,
      sortdescending: this.sortDescending,
      sortfield: this.sortField,
      // starting settings of contental us
      xmax: 0,
      ymax: 0,
      xmin: 0,
      ymin: 0,
      selectedTab: ''
    };

    if (parsedValue) {
      const pso = parsedValue.split('|');
      psodic['page'] = parseInt(pso[0], 10);
      psodic['sortdescending'] = pso[1] === 'true' ? true : false;
      psodic['sortfield'] = pso[2];
      psodic['xmax'] = parseFloat(pso[3]);
      psodic['ymax'] = parseFloat(pso[4]);
      psodic['xmin'] = parseFloat(pso[5]);
      psodic['ymin'] = parseFloat(pso[6]);
      psodic['selectedTab'] = pso[7];
    }
    return psodic;
  }

  setURLFromSettings(mapChange: boolean = false) {
    const urlParams = new URL(location.href).searchParams;

    const pagingSortDescendingSortFieldParam =
      (this.settings.pageNumber ? this.settings.pageNumber.toString() : '') +
      '|' +
      (this.settings.sortDescending
        ? this.settings.sortDescending.toString()
        : '') +
      '|' +
      (this.settings.sortField ? this.settings.sortField : '') +
      '|' +
      (this.startingMapVariables.mapExtent
        ? this.startingMapVariables.mapExtent.xmax
        : '') +
      '|' +
      (this.startingMapVariables.mapExtent
        ? this.startingMapVariables.mapExtent.ymax
        : '') +
      '|' +
      (this.startingMapVariables.mapExtent
        ? this.startingMapVariables.mapExtent.xmin
        : '') +
      '|' +
      (this.startingMapVariables.mapExtent
        ? this.startingMapVariables.mapExtent.ymin
        : '') +
      '|' +
      (this.selectedTab ? this.selectedTab : 'datatab');

    if (urlParams.get(`${this.id}_SSSettings`)) {
      urlParams.set(
        `${this.id}_SSSettings`,
        pagingSortDescendingSortFieldParam
      );
    } else {
      // if it's fired from the filter change event, don't append it because it will be a bunch of null values, but
      // I need a way to reset the starting extent of the map when we update the filter.
      if (!mapChange) {
        urlParams.append(
          `${this.id}_SSSettings`,
          pagingSortDescendingSortFieldParam
        );
      }
    }

    const urlpath = location.pathname + '?' + `${urlParams}`;
    this.location.go(urlpath, '', { navigationPath: `${urlpath}` });
  }

  nativeSettingsChange(settings: GridSettings) {
    if (this.listeningToSC2) {
      this.settingsChange2(settings);
    } else {
      this.settingsChange(settings);
    }
  }

  settingsChange(settings: GridSettings) {
    this.setCachedSettings();
    this.settingsChanged.emit(settings);
    // this.settingsChanged2.emit(settings);

    // why was this emitting both?
    // it was because native datatable settings changes were getting passed to here.
    // I changed it so they know what emitter is being listened to, so we don't have to call both here

    // when this changes we no longer want to go to the same map settings.
    this.startingMapVariables.mapExtent = null;
    this.setURLFromSettings(true);
  }

  settingsChange2(settings: GridSettings) {
    this.setCachedSettings();
    this.settingsChanged2.emit(settings);
    this.startingMapVariables.mapExtent = null;
    this.setURLFromSettings(true);
  }

  // a filter change event is always emitted to this datatable component on load of any datatable-consuming component
  // use that filter change event to time the emitting of a single event that contains any values from in localstorage
  handleFiltersChanged(filters: FilterBuilderOutput) {
    if (this.translateToLegacy) {
      const newFilters = translateFilterBuilderToLegacy(filters);
      this.currentFilters = newFilters;
      this.settings.legacyFilters = newFilters;
      this.settingsChange(this.settings);
    } else {
      this.settings.filters = filters;
      this.settingsChange(this.settings);
    }
  }

  handleFiltersChanged2(filters: FilterBuilderOutput) {
    if (this.translateToLegacy) {
      const newFilters = translateFilterBuilderToLegacy(filters);
      this.currentFilters = newFilters;
      this.settings.legacyFilters = newFilters;
      this.settingsChange2(this.settings);
    } else {
      // nothing using the handleFiltersChanged2 will be subscribing to reportFiltersChange
    }
  }

  columnsChanged(columnSet?: ColumnSet) {
    if (columnSet) {
      for (const co of this.settings.columnOptions) {
        co.checked = !!columnSet.columns.find(c => c.name === co.name);
        co.includeInExport = !!columnSet.exportColumns.find(
          c => c.name === co.name
        );
      }
    }

    this.sortColumnOptions();

    this.setCachedSettings();

    const visibleColumnProps = this.settings.columnOptions
      .filter(co => co.checked)
      .map(co => co.name);

    // // null out TableColumn width property so ngx-datatable can
    // // recalculate it when it renders the updated columns
    // for (const ac of this.availableColumns) {
    //   ac.width = null;
    // }

    // filter columns on visibility while putting action columns at the end
    // put the select column at the beginning if that is enabled
    this.columns = [
      ...this.availableColumns.filter(c => c.prop === 'select'),
      ...this.availableColumns.filter(c =>
        visibleColumnProps.includes(c.prop as string)
      ),
      ...this.availableColumns.filter(c =>
        this.actionColumns.includes(c.prop as string)
      )
    ];

    this.columnOptionsChanged.emit(this.settings.columnOptions);
  }

  pageSizeChanged(pageSize: number) {
    this.settings.pageSize = pageSize;
    this.nativeSettingsChange(this.settings);
  }

  onPage(pageEvent: any) {
    this.settings.pageNumber = pageEvent.offset;
    this.startingMapVariables.mapExtent = null;

    this.nativeSettingsChange(this.settings);
    this.setURLFromSettings();
  }

  onSort(sortEvent: any) {
    const sort = sortEvent.sorts[0];
    this.settings.sortField = sort.prop;
    this.settings.sortDescending = sort.dir === 'desc';
    this.startingMapVariables.mapExtent = null;

    this.nativeSettingsChange(this.settings);
    this.setURLFromSettings();
  }

  onSelect({ selected }) {
    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
  }

  async doBulkAction(action: BulkAction) {
    await action.action(this.selected);

    this.selected = [];
  }

  get isBulkActionsEnabled() {
    return this.bulkActions && this.bulkActions.length > 0;
  }

  ngOnInit() {
    // set these settings to any cached settings
    this.initSettings();
    if (this.waitForParent) {
      this.columnsSetSubscription = this.dynamicColumnsSet.subscribe(
        availableColumns => {
          // now that the parent component knows what its dynamic columns are,
          // update this.settings.columnOptions to include those new additions to availableColumns
          this.updateColumnOptions(availableColumns);
        }
      );
    } else {
      this.updateColumnOptions(this.columns);
    }
  }
  ngAfterViewInit() {
    if (this.tabset) {
      if (this.selectedTab) {
        this.tabset.select(this.selectedTab);
      }
    }

    this._ref.detectChanges();
  }

  ngOnDestroy() {
    if (this.waitForParent) {
      this.columnsSetSubscription.unsubscribe();
    }
  }

  private updateColumnOptions(availableColumns: TableColumn[]) {
    // goal is to update this.settings.columnOptions to include all availableColumns
    let newColumnOptions: ColumnOption[] = [];

    // start with whatever the current columnOptions are
    newColumnOptions = [...this.settings.columnOptions];

    // check if any of the availableColumns don't exist in currentColumnOptions
    // if not, add them based on the requirement column show/hide rule from the parent
    let columnsToAdd: ColumnOption[] = [];

    columnsToAdd = availableColumns
      .filter(
        ac =>
          !newColumnOptions.find(nco => nco.name === (ac.prop as string)) &&
          !this.actionColumns.includes(ac.prop as string) &&
          ac.prop !== 'select'
      )
      .map(
        ac =>
          new ColumnOption({
            label: ac.name,
            name: ac.prop as string,
            checked: this.dynamicColumnsVisibleByDefault,
            includeInExport: true
          })
      );

    newColumnOptions.push(...columnsToAdd);

    this.settings.columnOptions = newColumnOptions;

    this.columnsChanged();
  }

  private initSettings() {
    this.urlParams = this.getSettingsFromURL();
    this.settings.pageNumber = this.urlParams['page'];
    this.settings.pageSize = this.limit;
    this.settings.sortField = this.urlParams['sortfield'];
    this.settings.sortDescending = this.urlParams['sortdescending'];
    this.startingMapVariables.mapExtent = this.getStartingMapExtentFromURL();
    this.selectedTab = this.urlParams['selectedTab'];

    this.settings.columnOptions = this.columns
      .map(
        c =>
          new ColumnOption({
            label: c.name,
            name: c.prop as string,
            checked: !this.defaultHiddenColumns.includes(c.prop as string),
            includeInExport: !this.defaultExportExludedColumns.includes(
              c.prop as string
            )
          })
      )
      .filter(
        co => !this.actionColumns.includes(co.name) && co.name !== 'select'
      ); // don't include actionColumns and the select in column options

    if (this.id) {
      const dataJSON = localStorage.getItem(this.cacheId);

      if (dataJSON) {
        const cachedObject = JSON.parse(dataJSON);

        if (cachedObject) {
          // set the datatable settings being saved in localstorage
          this.pageSize = cachedObject.pageSize || this.limit;
          this.settings.pageSize = cachedObject.pageSize || this.limit;

          if (
            cachedObject.columnOptions &&
            cachedObject.columnOptions.length > 0
          ) {
            // if a new column was added to the component since last time
            // columnOptions was updated in cache, add a ColumnOption object for the new column
            // and default it to be hidden and excluded from export
            if (
              cachedObject.columnOptions.length <
              this.settings.columnOptions.length
            ) {
              const newColumnOptions: ColumnOption[] = [];

              for (const o of this.settings.columnOptions) {
                const matchingColumnOption = cachedObject.columnOptions.find(
                  opt => opt.name === o.name
                );
                const newColumnOption =
                  matchingColumnOption ||
                  new ColumnOption({
                    label: o.label,
                    name: o.name,
                    checked: false,
                    includeInExport: false
                  });

                newColumnOptions.push(newColumnOption);
              }

              cachedObject.columnOptions = [...newColumnOptions];
            }

            if (!this.waitForParent) {
              this.sortColumnOptions();
            }

            // if a column was removed from the component since last time
            // columnOptions was updated in cache, remove the ColumnOption object from options
            if (
              cachedObject.columnOptions.length >
              this.settings.columnOptions.length
            ) {
              const currentOptionNames = this.settings.columnOptions.map(
                o => o.name
              );

              cachedObject.columnOptions = cachedObject.columnOptions.filter(
                co => currentOptionNames.includes(co.name)
              );
            }

            this.settings.columnOptions = cachedObject.columnOptions;
          }
        }
      }
    }
  }

  private setCachedSettings() {
    if (this.id) {
      // datatable settings to save in localstorage
      const settingsToCache = {
        pageSize: this.settings.pageSize,
        columnOptions: this.settings.columnOptions
      };

      const stringData = JSON.stringify(settingsToCache);

      localStorage.setItem(this.cacheId, stringData);
    }
  }

  sortColumnOptions() {
    // sort the new column options based on the defined order of columns in component
    const columnSorter = (a: ColumnOption, b: ColumnOption) => {
      const aPos = this.availableColumns
        .map(e => e.prop as string)
        .indexOf(a.name);
      const bPos = this.availableColumns
        .map(e => e.prop as string)
        .indexOf(b.name);

      if (aPos >= 0 && bPos >= 0) {
        return aPos > bPos ? 1 : -1;
      }

      return 0;
    };

    this.settings.columnOptions.sort(columnSorter);
  }
}

<form [formGroup]="form" *ngIf="config">
  <div class="form-group">
    <label class="bmd-label-floating" for="billType">
      Bill Type
    </label>
    <input
      type="text"
      class="form-control"
      formControlName="billType"
      name="billType"
      [(ngModel)]="config.billType"
    />
  </div>
  <div class="form-group">
    <label class="bmd-label-floating" for="accountName">
      Account Name
    </label>
    <input
      type="text"
      class="form-control"
      formControlName="accountName"
      name="accountName"
      [(ngModel)]="config.accountName"
    />
  </div>
</form>

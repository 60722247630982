import {
  Component,
  OnInit,
  ComponentFactoryResolver,
  Inject,
  forwardRef,
  Input
} from '@angular/core';
import { DataEntityViewComponent } from '../../data-entity-view/data-entity-view.component';
import {
  DisplayEntityValueDataEntity,
  DataEntity
} from 'src/app/models/data-entities';

@Component({
  selector: 'wm-display-entity-value-data-entity-view',
  templateUrl: './display-entity-value-data-entity-view.component.html',
  styleUrls: ['./display-entity-value-data-entity-view.component.css']
})
export class DisplayEntityValueDataEntityViewComponent
  extends DataEntityViewComponent
  implements OnInit {
  showEntity = true;

  @Input() entity: DisplayEntityValueDataEntity;
  constructor(componentFactoryResolver: ComponentFactoryResolver) {
    super(componentFactoryResolver);
  }
  testEntity: DisplayEntityValueDataEntity;

  ngOnInit() {
    this.entity.evaluateLabelSource();
    this.showEntity = this.entity.evaluateVisibility();
  }
}

<app-loader *ngIf="!applications"></app-loader>

<p *ngIf="applications && applications.length === 0">
  There are no applications
</p>

<div class="row" *ngIf="applications && applications.length > 0">
  <div class="col-md-6" *ngFor="let application of applications">
    <wm-workflow-item [application]="application"></wm-workflow-item>
  </div>
</div>

<app-loader *ngIf="loading"></app-loader>

<form [formGroup]="form" novalidate [hidden]="loading">
  <div *ngIf="activity">
    <div
      class="form-group"
      [class.has-error]="activityName.touched && !activityName.valid"
    >
      <label class="bmd-label-floating" for="activityName">
        Activity Name
      </label>
      <input
        type="text"
        class="form-control required"
        formControlName="activityName"
        maxlength="100"
        name="activityName"
        [(ngModel)]="activity.model.screenName"
      />
    </div>

    <div
      *ngIf="showResponsibleRole && roles"
      class="form-group"
      [class.has-error]="responsibleRole.touched && !responsibleRole.valid"
    >
      <label class="bmd-label-floating" for="responsibleRole">
        Responsible Role
      </label>
      <select
        formControlName="responsibleRole"
        name="responsibleRole"
        (change)="selectRole($event); refreshPermissions()"
        class="form-control required"
      >
        <option *ngFor="let role of roles" [value]="role.id.toUpperCase()">
          {{ role.name }}
        </option>
      </select>
    </div>

    <div class="form-group" *ngIf="showHelpText">
      <label class="bmd-label-floating" for="screenHelpText">
        Activity Help Text
      </label>
      <textarea
        class="form-control"
        rows="5"
        formControlName="screenHelpText"
        name="screenHelpText"
        [(ngModel)]="activity.model.screenHelpText"
      >
      </textarea>
    </div>

    <div class="switch" *ngIf="!roleIsApplicant">
      <label>
        <input
          type="checkbox"
          formControlName="visibleToPublic"
          [(ngModel)]="activity.model.visibleToPublic"
        />
        Visible to Applicant
      </label>
    </div>
  </div>

  <template #activityTypeEditor></template>
</form>

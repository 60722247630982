import { Workflow } from './../../../models/workflow';
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import {
  WorkflowContextService,
  ContractorService,
  SecurityService
} from 'src/app/services';
import { ContractorType, Actions } from 'src/app/models';
import { WorkflowValidationService } from 'src/app/services/workflow-validation.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  CustomFieldMigrationQueueItem,
  CustomFieldDataOperationType
} from 'src/app/models/custom-field-data-operations';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';

@Component({
  selector: 'wm-contractor-type-list',
  templateUrl: './contractor-type-list.component.html',
  styleUrls: ['./contractor-type-list.component.css']
})
export class ContractorTypeListComponent implements OnInit {
  @Input() contractorTypes: any;
  @Output() onPublish: EventEmitter<string> = new EventEmitter<string>();
  @Output() onEnable: EventEmitter<string> = new EventEmitter<string>();
  @Output() onDelete: EventEmitter<string> = new EventEmitter<string>();
  @Output() onCopy: EventEmitter<ContractorType> = new EventEmitter<
    ContractorType
  >();
  @Output() onHistory: EventEmitter<Workflow> = new EventEmitter<Workflow>();

  canManageContractors = false;
  publishForm: UntypedFormGroup;
  isValidating = false;

  customFieldMigrationQueueItems: CustomFieldMigrationQueueItem[] = [];

  hasContractorToRegistrationMove = false;
  hasRegistrationToContractorMove = false;

  constructor(
    public context: WorkflowContextService,
    public _contractorSvc: ContractorService,
    private _securitySvc: SecurityService,
    private _workflowValidationSvc: WorkflowValidationService,
    private modalService: NgbModal
  ) {}

  ngOnInit() {
    this.publishForm = new UntypedFormGroup({
      isWorkflowValid: new UntypedFormControl(1, Validators.max(0))
    });

    this._securitySvc
      .isLoginEntitled(Actions.CONTRACTORS_MANAGE)
      .subscribe(result => {
        this.canManageContractors = result;
      });
  }

  canViewHistory(): boolean {
    return this.canManageContractors;
  }

  canPublish(ct: ContractorType): boolean {
    return !ct.isPublished && this.canManageContractors; // ct.isEnabled;
  }

  canDelete(ct: ContractorType): boolean {
    return true && this.canManageContractors; // ct.isEnabled;
  }
  delete(id: string): boolean {
    this._contractorSvc.deleteContractorType(id).subscribe(t => {
      this.onDelete.emit(id);
    });

    return true;
  }

  dismissPublish() {
    if (this.modalService.hasOpenModals()) {
      this.modalService.dismissAll('navigate to builder');
    }
  }

  async validateContractorType(contractorType: ContractorType) {
    this.isValidating = true;
    this.publishForm.controls['isWorkflowValid'].setValue(1);
    const registerValidationResults = await this._workflowValidationSvc.validate(
      contractorType.registerWorkflowId
    );
    const renewValidationResults = await this._workflowValidationSvc.validate(
      contractorType.renewWorkflowId
    );

    this.customFieldMigrationQueueItems = await this._contractorSvc.getPendingCustomFieldMigrations(
      contractorType.id
    );

    this.hasContractorToRegistrationMove =
      this.customFieldMigrationQueueItems.filter(
        qi =>
          qi.operationType ===
          CustomFieldDataOperationType.MoveEntityFromContractorToRegistrationForm
      ).length > 0;
    this.hasRegistrationToContractorMove =
      this.customFieldMigrationQueueItems.filter(
        qi =>
          qi.operationType ===
          CustomFieldDataOperationType.MoveEntityFromRegistrationToContractorForm
      ).length > 0;

    this.isValidating = false;
    this.publishForm.controls['isWorkflowValid'].setValue(
      ([...registerValidationResults, ...renewValidationResults] || []).length
    );
  }

  resetQueuedMigrations() {
    this.customFieldMigrationQueueItems = [];
    this.hasContractorToRegistrationMove = false;
    this.hasRegistrationToContractorMove = false;
  }
}

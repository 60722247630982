<div class="form-group" *ngIf="activity">
  {{
    !activity.model.screenHelpText
      ? 'Thank you for submitting your information. You may view the submitted information on the tabs below.'
      : ''
  }}
</div>
<div *ngIf="context && activity">
  <wm-application-readonly
    [applicationId]="context.applicationId"
    [activities]="activity.model.activities"
    [isTestApplication]="isTestApplication"
  ></wm-application-readonly>
</div>

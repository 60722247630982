import {
  Component,
  ComponentFactoryResolver,
  Input,
  OnInit
} from '@angular/core';
import { NgForm } from '@angular/forms';
import {
  DataEntity,
  DisplayEntityValueDataEntity
} from 'src/app/models/data-entities';

import { DataEntityInputComponent } from '../../data-entity-input/data-entity-input.component';

@Component({
  selector: 'wm-display-entity-value-data-entity-input',
  templateUrl: './display-entity-value-data-entity-input.component.html',
  styleUrls: ['./display-entity-value-data-entity-input.component.css']
})
export class DisplayEntityValueDataEntityInputComponent
  extends DataEntityInputComponent
  implements OnInit {
  @Input() entity: DisplayEntityValueDataEntity;
  selectedTypeCode: any;
  dataEntity: any;
  label: string;
  showEntity = true;

  constructor(componentFactoryResolver: ComponentFactoryResolver) {
    super(componentFactoryResolver);
  }
  onFormSubmit(form: NgForm) {}

  ngOnInit() {
    this.entity.evaluateLabelSource();
    this.showEntity = this.entity.evaluateVisibility();
  }
}

import { DataTableHelpers } from './../../../components/system/datatable/datatable-helper';
import { TableColumn } from '@swimlane/ngx-datatable';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ContractorRegistration, Contractor } from 'src/app/models';
import {
  ItemSearchOptionField,
  ItemSearchOptionFieldOption,
  ItemSearchFilter,
  ItemSearchOption
} from 'src/app/components/filter-list/models/filterClasses';
import { RegistrationStatus } from 'src/app/models/registration';
import { ComponentIds } from 'src/app/models/component-ids';

@Component({
  selector: 'app-contractor-expire-registrations',
  templateUrl: './contractor-expire-registrations.component.html',
  styleUrls: ['./contractor-expire-registrations.component.css']
})
export class ContractorExpireRegistrationsComponent implements OnInit {
  @Output() recordCountSet = new EventEmitter<number>();
  @Output() filtersChanged = new EventEmitter<boolean>();

  id = ComponentIds.CONTRACTOR_EXPIRE_REGISTRATIONS;
  contractor: Contractor;
  contractorRegistrations: ContractorRegistration[];
  staticFilters: any;
  public columns: TableColumn[] = [];

  constructor(private dtHelpers: DataTableHelpers) {}

  ngOnInit() {
    this.columns = [
      {
        prop: 'registeredOn',
        name: 'Registration Date',
        pipe: this.dtHelpers.getDatePipe()
      },
      {
        prop: 'effectiveOn',
        name: 'Effective Date',
        pipe: this.dtHelpers.getDatePipe()
      },
      {
        prop: 'expiresOn',
        name: 'Expiration Date',
        pipe: this.dtHelpers.getDatePipe()
      },
      {
        prop: 'approvedOn',
        name: 'Approved Date',
        pipe: this.dtHelpers.getDatePipe()
      },
      {
        prop: 'type',
        name: 'Contractor Type'
      },
      {
        prop: 'paymentMethod',
        name: 'Payment Method'
      }
    ];

    const sevenDaysFromNow = new Date();
    sevenDaysFromNow.setDate(sevenDaysFromNow.getDate() + 7);

    // this.staticFilters = new ItemSearchOptionField({
    //   title: '<expiresOn',
    //   options: [
    //     new ItemSearchOptionFieldOption({
    //       title: '<expiresOn',
    //       selected: true,
    //       filterText: '',
    //       searchText:
    //         sevenDaysFromNow.getMonth() +
    //         1 +
    //         '/' +
    //         sevenDaysFromNow.getDate() +
    //         '/' +
    //         sevenDaysFromNow.getFullYear(),
    //       strOperator: '<'
    //     })
    //   ]
    // });

    this.staticFilters = [
      {
        title: '<expiresOn',
        options: [
          new ItemSearchOptionFieldOption({
            title: '<expiresOn',
            selected: true,
            filterText: '',
            searchText:
              sevenDaysFromNow.getMonth() +
              1 +
              '/' +
              sevenDaysFromNow.getDate() +
              '/' +
              sevenDaysFromNow.getFullYear(),
            strOperator: '<'
          })
        ]
      },
      {
        title: 'status',
        options: [
          new ItemSearchOptionFieldOption({
            title: 'status',
            selected: true,
            filterText: '',
            searchText:
              RegistrationStatus.Approved +
              ',' +
              RegistrationStatus.Inactive +
              ',' +
              RegistrationStatus.Expired,
            strOperator: 'in'
          })
        ]
      }
    ];
  }

  setRecordCount(count: number) {
    this.recordCountSet.emit(count);
  }

  filtersChange() {
    this.filtersChanged.emit(true);
  }
}

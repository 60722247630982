<div class="card my-3">
  <div class="card-body" *ngIf="registration">
    <h5 class="card-title">{{ registration.type.name }}</h5>
    <p>{{ registration.type.description }}</p>
    <p>Expires on {{ registration.expiresOn | date: 'M/d/yyyy' }}</p>
    <button
      class="btn btn-raised btn-primary"
      [disabled]="saving"
      (click)="startRenewal()"
    >
      <span *ngIf="!saving">Start Renewal</span>
      <span *ngIf="saving">
        <span
          class="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
        Start Renewal...
      </span>
    </button>
  </div>
</div>

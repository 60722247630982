<form [formGroup]="form">
  <div class="form-group">
    <wm-data-entity-formula
      id="formula"
      [form]="form"
      [workflow]="workflow"
      [activity]="activity"
      [criteria]="activity.model.numberPrefixCriteria"
      [thenLabelGetter]="getThenLabel"
      (criteriaChanged)="updateCriteria($event)"
      [formulaTitle]="'Number Prefix'"
    ></wm-data-entity-formula>
    <wm-control-message
      [control]="form.controls['numberPrefix']"
    ></wm-control-message>
  </div>
</form>

<div class="row">
  <div class="col">
    <h2>Contractor Document Categories</h2>
  </div>
  <div class="col">
    <button
      class="btn btn-raised btn-primary float-right"
      (click)="addDocumentCategory()"
    >
      Add Document Category
    </button>
  </div>
</div>

<div class="row">
  <div class="col">
    <div>
      <app-loader *ngIf="!categories"></app-loader>

      <ul
        class="list-group"
        *ngIf="categories && categories.length === 0 && !newCategory"
      >
        <li class="list-group-item">
          No document categories to display
        </li>
      </ul>

      <ul class="list-group">
        <ng-container *ngIf="categories && categories.length > 0">
          <a *ngFor="let category of categories" class="list-group-item">
            <div class="bmd-list-group-col">
              <p class="list-group-item-heading">
                <span *ngIf="category.isEditing != true">{{
                  category.name
                }}</span>
                <ng-container *ngIf="category.isEditing == true">
                  <label
                    [for]="category.id + '-name'"
                    class="bmd-label-floating"
                  >
                    Category Name
                  </label>
                  <input
                    type="text"
                    [name]="category.id + '-name'"
                    [(ngModel)]="category.name"
                    class="form-control required"
                  />
                </ng-container>
              </p>
            </div>
            <button
              *ngIf="category.isEditing != true"
              class="btn btn-raised btn-primary"
              (click)="category.isEditing = true"
            >
              Edit
            </button>
            <button
              *ngIf="category.isEditing == true"
              class="btn btn-raised btn-success"
              (click)="saveDocumentCategory(category)"
            >
              Save
            </button>
            <wm-modal-confirm
              [buttonText]="'Delete'"
              [commandText]="'Delete Document Category'"
              [moduleId]="category.id + 'disable'"
              [showButtonText]="true"
              [title]="'Disable Contractor Document Category'"
              (accepted)="removeDocumentCategory(category.id)"
              btnColor="danger"
            >
              <p>
                Are you sure you want to delete this category ({{
                  category.name
                }})?
              </p>
              <br />
              <p>
                Some things to take note of.
              </p>
              <ul>
                <li>
                  The contractor document data entities using this category will
                  no longer have a category.
                </li>
              </ul>
            </wm-modal-confirm>
          </a>
        </ng-container>
        <li *ngIf="newCategory" class="list-group-item">
          <div class="bmd-list-group-col">
            <p class="list-group-item-heading">
              <label [for]="newCategoryName" class="bmd-label-floating">
                Category Name
              </label>
              <input
                type="text"
                name="newCategoryName"
                [(ngModel)]="newCategory.name"
                class="form-control required"
              />
              <button
                class="btn btn-raised btn-success"
                (click)="saveDocumentCategory(newCategory)"
              >
                Save
              </button>
            </p>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>

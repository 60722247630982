<form [formGroup]="form" *ngIf="entity">
  <wm-de-label [entity]="entity" [for]="entity.templateCode"></wm-de-label>

  <div
    [ngClass]="
      numericDE.type === NumericDataType.Currency ? 'input-group mb-3' : ''
    "
  >
    <div
      *ngIf="numericDE.type === NumericDataType.Currency"
      class="input-group-prepend"
    >
      <span class="input-group-text">$</span>
    </div>
    <input
      type="text"
      class="form-control"
      [ngClass]="{ required: entity.isRequired }"
      [formControlName]="entity.templateCode"
      [(ngModel)]="entity.value"
      [name]="entity.templateCode"
      [min]="numericDE.minimumValue"
      [max]="numericDE.maximumValue"
      [required]="entity.isRequired"
      [wmNumberFormat]="numericDE.decimalPlaces"
    />
  </div>
</form>

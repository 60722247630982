import {
  Component,
  OnInit,
  ComponentFactoryResolver,
  Inject,
  forwardRef,
  OnDestroy,
  Input,
  ViewChild,
  ElementRef
} from '@angular/core';
import { Subscription, empty, Observable } from 'rxjs';
import { Document } from '../../../models/document';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
  FormControl,
  FormArray,
  ReactiveFormsModule
} from '@angular/forms';
import { DocumentService } from '../../../services/document.service';
import { ToastrService } from 'ngx-toastr';
import {
  ContractorService,
  DataService,
  Utilities,
  WorkflowContextService,
  WorkflowService
} from 'src/app/services';
import { ModalConfirmComponent } from '../../system/modal-confirm/modal-confirm.component';
import { UploadComponent } from '../../upload/upload.component';
@Component({
  selector: 'wm-workflow-previous-sytem-import',
  templateUrl: './workflow-previous-sytem-import.component.html',
  styleUrls: ['./workflow-previous-sytem-import.component.css']
})
export class WorkFlowPreviousSytemImportComponent implements OnInit, OnDestroy {
  @Input() isPreview: boolean;
  @ViewChild('clearModal', { static: true }) clearModal: ModalConfirmComponent;
  @ViewChild('uploadComponent', { static: true }) uploadComponent: UploadComponent;

  public form: UntypedFormGroup;
  showRemove: boolean;
  value: any;
  document: Document;
  @Input() applicationId: string;
  self: any;

  documentTemplateCode: string;
  fileCompleteSub: Subscription;
  allowSelect = false;
  fileAddedSub: Subscription;
  documents: Document[];
  newFiles = [];
  profileForm = null;
  clientId = this._context.client.id;
  newUploadKey: string; // upload key for when the file dialog gets opend
  activeUploadKey: string = null; // sets the activeUploadKey one the user selects the files to upload and loads the newUploadKey for when you select upload the next time
  constructor(
    @Inject(forwardRef(() => DocumentService))
    public uploadService: DocumentService,
    private _fb: UntypedFormBuilder,
    private _context: WorkflowContextService,
    private toastr: ToastrService,
    private _workflowSvc: WorkflowService
  ) {}

  ngOnDestroy(): void {
    if (this.fileCompleteSub) {
      this.fileCompleteSub.unsubscribe();
    }

    if (this.fileAddedSub) {
      this.fileAddedSub.unsubscribe();
    }
  }

  acceptClear() {
    this.uploadComponent.onClick();
  }

  canceledClear() {}

  importFile(previousSystemDocument: Document) {
    const clientID: string = this._context.client.id;
    const email: string = this._context.user.emailAddress;
    this._workflowSvc
      .importPreviousSystemData(
        previousSystemDocument,
        clientID,
        email,
        this.activeUploadKey,
        this.profileForm.value.clearRecords
      )
      .subscribe(result => {
        if (result['success'] === true) {
          this.toastr.success(result['message']);
        } else {
          this.toastr.error(result['message']);
        }
      });
  }
  checkUpload(evt) {
    if (this.profileForm.value.clearRecords) {
      this.clearModal.open();
    } else {
      this.uploadComponent.onClick();
    }
  }
  fileUploaded(e: Document) {
    if (!this.documents) {
      this.documents = [];
    }
    const exDoc = this.documents.find(d => d.name === e.name);

    if (exDoc) {
      const docIdx = this.documents.indexOf(exDoc);

      this.documents[docIdx] = e;
    } else {
      this.documents.push(e);
    }
    // once it's been uploaded tell the server to process it...
    this.importFile(e);
  }

  newDocumentsUploaded(files) {
    this.newFiles = files;
  }

  ngOnInit() {
    this.uploadComponent.uploadButton.nativeElement.style.display = 'none';
    this.newUploadKey = Utilities.generateId();
    this.profileForm = this._fb.group({
      clearRecords: [''],
      type: this._fb.group({
        documents: this._fb.array([]) // create empty form array
      })
    });

    if (this.fileAddedSub) {
      this.fileAddedSub.unsubscribe();
    }
    this.fileAddedSub = this.uploadService.filesAdded.subscribe(
      (files: { files: any; key: string }) => {
        this.activeUploadKey = files.key;
        this.newUploadKey = Utilities.generateId();
      }
    );

    if (this.fileCompleteSub) {
      this.fileCompleteSub.unsubscribe();
    }

    this.fileCompleteSub = this.uploadService.fileUploadComplete.subscribe(
      (files: { files: any; key: string }) => {}
    );

    this.showRemove = false;
  }

  updateValue() {
    this.value = JSON.stringify(this.documents);
  }
  deleteDocument(document: Document) {
    this.uploadService
      .removeDocument(this.applicationId, document)
      .subscribe(a => {
        const docs = this.documents.filter(d => d.id !== document.id);
        this.documents = docs;
        this.updateValue();
      });
  }
}

import { User } from './user';
import { Role } from './role';

export class RoleMember {
  id: string;
  user: Partial<User>;
  role: Partial<Role>;

  constructor(options?: Partial<RoleMember>) {
    if (options) {
      Object.assign(this, options);
    }
  }
}

<div>
  <div class="row">
    <div class="col">
      <h3>Contractor Types</h3>
    </div>
    <div class="col" *ngIf="canAddContractorType">
      <button
        type="button"
        class="btn btn-raised btn-success float-right"
        (click)="createContractorType()"
      >
        New Contractor Type
      </button>
    </div>
  </div>

  <wm-contractor-type-list
    #contractorTypeList
    [contractorTypes]="contractorTypes"
    (onPublish)="publishContractorType($event)"
    (onDelete)="deletedContractorType($event)"
    (onCopy)="startTypeCopy($event)"
    (onHistory)="showHistory($event)"
  >
  </wm-contractor-type-list>

  <wm-modal-confirm
    title="Copy Contractor Type"
    moduleId="copy-contractor-type"
    [showButtonText]="true"
    [enabled]="true"
    [showButton]="false"
    [closeOnAccept]="false"
    commandText="Copy"
    [form]="targetForm"
    (accepted)="copyType(copyTypeId)"
    #copyTypeModal
  >
    <form [formGroup]="targetForm">
      <label for="targetClientId" class="bmd-label-static">
        Jurisdiction
      </label>
      <select
        class="form-control"
        [(ngModel)]="targetClientId"
        name="targetClientId"
        formControlName="targetClientId"
        *ngIf="(clients || []).length > 1"
      >
        <option *ngFor="let client of clients" [value]="client.id">
          {{ client.name }}
        </option>
      </select>
      <label for="targetName" class="bmd-label-static">New Name</label>
      <input
        type="text"
        name="targetName"
        formControlName="targetName"
        [(ngModel)]="targetName"
        class="form-control"
      />
    </form>
  </wm-modal-confirm>

  <wm-modal-confirm
    [title]="checkpointTitle"
    moduleId="workflow-checkpoints"
    [showButtonText]="false"
    [enabled]="true"
    [showButton]="false"
    #checkpointList
  >
    <wm-workflow-checkpoint-list
      [checkpoints]="checkpoints"
      (onRestore)="restoreCheckpoint($event)"
      [workflowId]="selectedWorkflowId"
    ></wm-workflow-checkpoint-list>
  </wm-modal-confirm>
</div>

<p [ngClass]="['text-' + txtClass]">{{ message }}</p>

<div class="switch">
  <label>
    <input type="checkbox" [(ngModel)]="activity.model.isRequired" />
    Required
  </label>
</div>

<div class="switch">
  <label>
    <input type="checkbox" [(ngModel)]="activity.model.hasMap" />
    Enable map search
  </label>
</div>

<wm-activity-details-modal
  *ngIf="activity.model.hasMap"
  [title]="activity.model.screenName"
  btnText="Configure Map"
  name="mapConfig"
  [moduleId]="activity.id"
  [form]="form"
  (saved)="save()"
>
  <app-map-display-configuration-editor
    [client]="client"
    [mapConfig]="activity.model.mapConfig"
  >
  </app-map-display-configuration-editor>
</wm-activity-details-modal>

import { ApplyFilter } from './../../../models/saved-filter';
import { Utilities } from 'src/app/services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit, Input } from '@angular/core';
import { FilterBuilderFilterFlat } from 'src/app/models/filter-builder';
import {
  SavedFilter,
  IFilterStorageAdapter,
  SavedColumn,
  GetFilter
} from 'src/app/models/saved-filter';
import { FilterService } from 'src/app/services/filters.service';

@Component({
  selector: 'wm-saved-filter',
  templateUrl: './saved-filter.component.html',
  styleUrls: ['./saved-filter.component.css']
})
export class SavedFilterComponent implements OnInit {
  @Input() id: string;
  @Input() clientId: string;
  @Input() storage: IFilterStorageAdapter = this.filterSvc;
  @Input() getCurrentFilter: GetFilter;
  @Input() applyFilter: ApplyFilter;

  selectedFilter: string = null;
  activeFilter: SavedFilter;

  savedFilters: SavedFilter[];

  // get filterKey() {
  //   return `last-filter:${this.id}`;
  // }

  constructor(
    private modalService: NgbModal,
    private router: Router,
    private filterSvc: FilterService,
    private activatedRoute: ActivatedRoute
  ) {}

  async load() {
    await this.getFilters();

    this.activatedRoute.queryParams.subscribe(params => {
      if (params.filter && this.selectedFilter !== params.filter) {
        this.selectedFilter = params.filter;
        this.changeSelection();
      }
    });

    // const selectedFilter = localStorage.getItem(this.filterKey);
    // this.selectedFilter = selectedFilter === 'null' ? null : selectedFilter;

    this.changeSelection();
  }

  ngOnInit() {
    this.activeFilter = this.getNewFilter();
    this.load();
  }

  private getNewFilter(): SavedFilter {
    return {
      id: null,
      name: '',
      type: this.id,
      clientId: this.clientId,
      filters: [],
      columns: [],
      exportColumns: []
    };
  }

  private updateRoute(filterId: string) {
    // update the filter ID in the URL
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: { filter: filterId },
      queryParamsHandling: 'merge'
    });
  }

  changeSelection() {
    //localStorage.setItem(this.filterKey, this.selectedFilter);

    const savedFilter = this.savedFilters.find(
      f => f.id === this.selectedFilter
    );

    let newFilters: FilterBuilderFilterFlat[];
    let newColumns: SavedColumn[];
    let newExportColumns: SavedColumn[];

    if (savedFilter) {
      newFilters = savedFilter.filters;
      newColumns = savedFilter.columns;
      newExportColumns = savedFilter.exportColumns;
      this.applyFilter({
        filters: savedFilter.filters,
        columns: savedFilter.columns,
        exportColumns: savedFilter.exportColumns
      });
    } else if (this.selectedFilter === null) {
      newFilters = [];
      newColumns = [];
      newExportColumns = [];
    } else {
      // the saved filter couldn't be found and isn't null so it probably doesn't exist anymore
      this.selectedFilter = null;
      this.updateRoute(this.selectedFilter);
    }

    if (newFilters) {
      this.applyFilter({
        filters: newFilters,
        columns: newColumns,
        exportColumns: newExportColumns
      });
      this.updateRoute(this.selectedFilter);
    }

    this.setActiveFilter();
  }

  setActiveFilter(): SavedFilter {
    if (!this.savedFilters || this.savedFilters.length < 1) {
      this.activeFilter = this.getNewFilter();
      return;
    }

    const activeFilter =
      this.savedFilters.find(sf => sf.id === this.selectedFilter) ||
      this.getNewFilter();

    this.activeFilter = { ...activeFilter };
  }

  async getFilters() {
    this.savedFilters = await this.storage.list(this.clientId, this.id);
    const currentFilter = await this.getCurrentFilter();
  }

  public editFilter(content: any, clone?: boolean) {
    this.setActiveFilter();
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-edit-title' })
      .result.then(
        async result => {
          const filter = { ...this.activeFilter };

          if (!filter.id || clone) {
            filter.id = Utilities.generateId();
          }

          const currentFilter = await this.getCurrentFilter();

          filter.filters = currentFilter.filters;
          filter.columns = currentFilter.columns;
          filter.exportColumns = currentFilter.exportColumns;

          await this.storage.upsert(filter.id, filter);

          this.savedFilters = await this.storage.list(this.clientId, this.id);
          this.selectedFilter = filter.id;
          this.changeSelection();
        },
        reason => {}
      );
  }

  public async deleteFilter(id: string) {
    await this.storage.destroy(id);
    this.savedFilters = await this.storage.list(this.clientId, this.id);
    this.selectedFilter = null;
    this.changeSelection();
  }
}

import {
  ConditionSource,
  ConditionTarget,
  ConditionItem,
  defaultOperators,
  ConditionOperator,
  LogicalOperator,
  getOperator,
  OperatorTypes
} from './../condition-builder.model';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {
  faPlus,
  faTrash,
  faCodeBranch
} from '@fortawesome/free-solid-svg-icons';
import { Operators } from 'src/app/models/activities';

@Component({
  selector: 'wm-condition-group',
  templateUrl: './condition-group.component.html',
  styleUrls: ['./condition-group.component.css']
})
export class ConditionGroupComponent implements OnInit {
  @Input() group: ConditionItem;
  @Input() conditionSources: ConditionSource[];
  @Input() target: ConditionTarget[];

  @Input() level = 0;

  @Output() deleteGroup: EventEmitter<string> = new EventEmitter();
  @Output() conditionChanges: EventEmitter<boolean> = new EventEmitter();
  @Output() deleteValue: EventEmitter<string> = new EventEmitter();

  faPlus = faPlus;
  faTrash = faTrash;
  faCodeBranch = faCodeBranch;
  /**
   * Available operators for the condition builder
   */
  defaultConditionOperators: ConditionOperator[] = defaultOperators;
  getOperator = getOperator;

  openCondition: number = null;
  LogicalOperator = LogicalOperator;

  constructor() {}

  ngOnInit() {
    this.conditionSources = this.conditionSources.sort((a, b) =>
      a.name.localeCompare(b.name)
    );
  }

  getOperators(condition: ConditionItem): ConditionOperator[] {
    const source = this.getSource(condition);
    return (source && source.operators) || this.defaultConditionOperators;
  }

  getSource(condition: ConditionItem): ConditionSource {
    const source = this.conditionSources.find(s => s.value === condition.field);

    if (source && source.possibleValues) {
      source.possibleValues = source.possibleValues.sort((a, b) =>
        a.name.localeCompare(b.name)
      );
    }

    return source;
  }

  isValueValid(cond: ConditionItem): boolean {
    const src = this.getSource(cond);

    if (
      src &&
      cond.formulaOperator !== OperatorTypes.EXISTS.operator &&
      cond.formulaOperator !== OperatorTypes.NOT_EXISTS.operator &&
      (cond.val || '') !== '' &&
      src.possibleValues
    ) {
      const foundItem = src.possibleValues.find(
        pv =>
          (pv.value || '').toLowerCase() ===
          (cond.val || '').toString().toLowerCase()
      );

      return !(foundItem === null || foundItem === undefined);
    }
    return true;
  }

  getValueName(condition: ConditionItem) {
    const source = this.getSource(condition);

    if (source && source.possibleValues) {
      const value = source.possibleValues.find(s => s.value === condition.val);

      return (value && value.name) || condition.val;
    } else {
      return condition.val;
    }
  }

  getOperatorName(condition: ConditionItem) {
    const operators = this.getOperators(condition);

    const operatorObj = operators.find(
      o => o.operator === condition.formulaOperator
    );
    return operatorObj && operatorObj.name;
  }

  addGroup(index: number) {
    const condition = this.group.conditions[index];

    const group = new ConditionItem({
      isGroup: true,
      conditions: [condition]
    });
    this.group.conditions.splice(index, 1, group);
    this.conditionChanges.emit(true);
  }

  deleteCondition(index: number) {
    this.openCondition = null;
    this.group.conditions.splice(index, 1);
    this.conditionChanges.emit(true);
  }

  applyCondition(condition: ConditionItem) {
    this.openCondition = null;
    this.conditionChanges.emit(true);
  }

  newCondition() {
    this.group.conditions.push(new ConditionItem());
    this.conditionChanges.emit(true);
  }

  removeGroup(id: string) {
    const index = this.group.conditions.findIndex(
      (g: ConditionItem) => g.id === id
    );
    if (index >= 0) {
      this.group.conditions.splice(index, 1);
    } else {
      this.deleteGroup.emit(id);
    }
    this.conditionChanges.emit(true);
  }
}

import { Component, OnInit, forwardRef, Inject } from '@angular/core';
import { DocumentDataEntityComponent } from '../../document-data-entity/document-data-entity.component';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import {
  WorkflowService,
  WorkflowContextService,
  SystemService,
  ClientService,
  DataService,
  ContractorService
} from '../../../../../services';
import { ContractorDocumentDataEntity } from '../../../../../models/data-entities/contractor-document-data-entity';
import { ContractorDocumentCategory } from '../../../../../models/contractor-document-category';
import { Subscription } from 'rxjs';

@Component({
  selector: 'wm-contractor-document-data-entity-editor',
  templateUrl: './contractor-document-data-entity-editor.component.html',
  styleUrls: ['./contractor-document-data-entity-editor.component.css']
})
export class ContractorDocumentDataEntityEditorComponent
  extends DocumentDataEntityComponent
  implements OnInit {
  contractorDocEntity: ContractorDocumentDataEntity;
  categories: ContractorDocumentCategory[];

  constructor(
    _fb: UntypedFormBuilder,
    _route: ActivatedRoute,
    @Inject(forwardRef(() => WorkflowService))
    _workflowSvc: WorkflowService,
    @Inject(forwardRef(() => WorkflowContextService))
    _context: WorkflowContextService,
    @Inject(forwardRef(() => SystemService)) _systemSvc: SystemService,
    @Inject(forwardRef(() => ClientService)) _clientSvc: ClientService,
    @Inject(forwardRef(() => DataService)) _dataSvc: DataService,
    @Inject(forwardRef(() => ContractorService))
    private _contractorSvc: ContractorService
  ) {
    super(
      _fb,
      _route,
      _workflowSvc,
      _context,
      _systemSvc,
      _clientSvc,
      _dataSvc
    );
  }

  loadCategories() {
    this._contractorSvc
      .getContractorDocumentCategories()
      .subscribe(categories => {
        this.categories = categories;
      });
  }

  selectCategory(categoryId: string) {
    const cat = this.categories.find(c => c.id === categoryId);

    this.contractorDocEntity.documentCategory = cat;
  }

  ngOnInit() {
    this.contractorDocEntity = this.dataEntity as ContractorDocumentDataEntity;

    this.loadCategories();

    this.form.addControl(
      'documentCategory',
      this._fb.control(
        this.contractorDocEntity && this.contractorDocEntity.documentCategory
          ? this.contractorDocEntity.documentCategory.id
          : '',
        Validators.nullValidator
      )
    );
  }
}

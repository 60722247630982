<ng-container *ngIf="isAuthenticating">
  <h4 class="text-center pt-3">Authenticating...</h4>
  <app-loader class="pt-2"></app-loader>
</ng-container>
<!-- Login -->
<form
  [formGroup]="form"
  (submit)="login()"
  novalidate
  *ngIf="
    user &&
    !mustChangePwd &&
    !showForgotPwd &&
    !isAuthenticating &&
    !showForgotUsername
  "
>
  <div class="form-group">
    <label for="inputLoginUsername" class="bmd-label-floating">Username</label>
    <input
      type="text"
      id="inputLoginUsername"
      class="form-control"
      [(ngModel)]="user.userName"
      formControlName="userName"
      name="inputLoginUsername"
    />
    <wm-control-message [control]="form.controls.userName"></wm-control-message>
  </div>

  <div class="form-group">
    <label for="inputLoginPassword" class="bmd-label-floating">Password</label>
    <input
      type="password"
      id="inputLoginPassword"
      class="form-control"
      [(ngModel)]="user.password"
      formControlName="password"
      name="inputLoginPassword"
    />
    <wm-control-message [control]="form.controls.password"></wm-control-message>
  </div>
  <div class="text-danger">{{ statusMsg }}</div>

  <div class="mt-5">
    <button type="button" (click)="forgotPwd()" class="btn btn-default">
      Forgot Password
    </button>
    <button type="button" (click)="forgotUsername()" class="btn btn-default">
      Forgot Username
    </button>
    <button
      class="btn btn-raised btn-primary float-right"
      [disabled]="!form.valid || saving"
    >
      <span *ngIf="!saving">SIGN IN</span>

      <span *ngIf="saving">
        <span
          class="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
        SIGN IN...
      </span>
    </button>
  </div>
  <div class="card-footer">
    <a
      id="lnkUserPrivacyPolicy"
      title="Opens in a new tab"
      href="https://www.schneidergis.com/privacypolicy"
      target="_blank"
      >User Privacy Policy</a
    >
    <br />
    <a
      id="lnkGDPRPrivacyPolicy"
      title="Opens in a new tab"
      href="https://www.schneidergis.com/gdprprivacynotice"
      target="_blank"
      >GDPR Privacy Notice</a
    >
  </div>
</form>

<!-- Must Change Password -->
<form
  *ngIf="mustChangePwd"
  [formGroup]="changePwdForm"
  (submit)="changePwd()"
  novalidate
>
  <div class="form-group">
    <label for="oldPassword" class="bmd-label-floating">Old Password</label>
    <input
      type="password"
      class="form-control"
      formControlName="oldPassword"
      [(ngModel)]="oldPassword"
      name="oldPassword"
    />
    <wm-control-message
      [control]="form.controls.oldPassword"
    ></wm-control-message>
  </div>
  <div class="form-group">
    <label for="newPassword" class="bmd-label-floating">New Password</label>
    <input
      type="password"
      [(ngModel)]="newPassword"
      formControlName="newPassword"
      class="form-control"
      name="newPassword"
    />
    <wm-control-message
      [control]="form.controls.newPassword"
    ></wm-control-message>
  </div>
  <div class="form-group">
    <label for="confirmPassword" class="bmd-label-floating"
      >Confirm Password</label
    >
    <input
      type="password"
      [(ngModel)]="confirmPassword"
      formControlName="confirmPassword"
      class="form-control"
      name="confirmPassword"
    />
    <wm-control-message
      [control]="form.controls.confirmPassword"
    ></wm-control-message>
  </div>

  <div class="text-danger">{{ statusMsg }}</div>

  <div class="mt-5">
    <button type="button" class="btn btn-default" (click)="cancelChangePwd()">
      Cancel
    </button>
    <button
      type="submit"
      class="btn btn-primary btn-raised float-right"
      [disabled]="!changePwdForm.valid"
    >
      Change Password
    </button>
  </div>
</form>

<!-- Forgot Password -->
<form
  *ngIf="showForgotPwd"
  [formGroup]="resetPwdForm"
  (submit)="resetPwd()"
  novalidate
>
  <div class="form-group">
    <label for="inputForgotPwdUsername" class="bmd-label-floating"
      >Username</label
    >
    <input
      type="text"
      id="inputForgotPwdUsername"
      class="form-control"
      [(ngModel)]="resetPwdUsername"
      formControlName="username"
      name="inputForgotPwdUsername"
    />
    <wm-control-message
      [control]="resetPwdForm.controls.username"
    ></wm-control-message>
  </div>

  <div class="text-danger">{{ statusMsg }}</div>

  <div class="mt-5">
    <button type="button" class="btn btn-default" (click)="cancelResetPwd()">
      Cancel
    </button>
    <button
      type="submit"
      class="btn btn-primary btn-raised float-right"
      [disabled]="!resetPwdForm.valid"
    >
      Reset Password
    </button>
  </div>
</form>
<!-- Forgot Username -->
<form
  *ngIf="showForgotUsername"
  [formGroup]="sendUsernamesEmailForm"
  (submit)="sendUsernamesEmailSubmit()"
  novalidate
>
  <div class="form-group">
    <label for="inputForgotUsernameEmail" class="bmd-label-floating"
      >Email Address</label
    >
    <input
      type="email"
      id="inputForgotUsernameEmail"
      class="form-control"
      [(ngModel)]="sendUsernamesEmail"
      formControlName="email"
      name="inputForgotUsernameEmail"
    />
    <wm-control-message
      [control]="sendUsernamesEmailForm.controls.email"
    ></wm-control-message>
    <wm-error>
      <div
        *ngIf="
          !sendUsernamesEmailForm.controls.email.valid &&
          (sendUsernamesEmailForm.controls.email.dirty ||
            sendUsernamesEmailForm.controls.email.touched)
        "
        class="text-red"
      >
        <div *ngIf="sendUsernamesEmailForm.controls.email.errors.invalidMsg">
          {{ sendUsernamesEmailForm.controls.email.errors.invalidMsg }}
        </div>
      </div>
    </wm-error>
  </div>

  <div class="text-danger">{{ statusMsg }}</div>

  <div class="mt-5">
    <button
      type="button"
      class="btn btn-default"
      (click)="cancelResetUsername()"
    >
      Cancel
    </button>
    <button
      type="submit"
      class="btn btn-primary btn-raised float-right"
      [disabled]="!sendUsernamesEmailForm.valid"
    >
      Send Username
    </button>
  </div>
</form>

<form [formGroup]="form" novalidate *ngIf="config">
  <div class="form-group">
    <label class="bmd-label-floating" for="productId">Product ID</label>
    <input
      type="text"
      class="form-control"
      formControlName="productId"
      name="productId"
      [(ngModel)]="config.productId"
    />
  </div>

  <div class="form-group">
    <label class="bmd-label-floating" for="uniqueFieldId">
      Unique Value Field ID
    </label>
    <input
      type="text"
      class="form-control"
      formControlName="uniqueFieldId"
      name="uniqueFieldId"
      [(ngModel)]="config.uniqueFieldId"
    />
  </div>

  <div class="form-group">
    <label class="bmd-label-floating" for="permitNumberFieldId">
      Permit Number Field ID
    </label>
    <input
      type="text"
      class="form-control"
      formControlName="permitNumberFieldId"
      name="permitNumberFieldId"
      [(ngModel)]="config.permitNumberFieldId"
    />
  </div>

  <div class="form-group">
    <label class="bmd-label-floating" for="parcelNumberFieldId">
      Parcel Number Field ID
    </label>
    <input
      type="text"
      class="form-control"
      formControlName="parcelNumberFieldId"
      name="parcelNumberFieldId"
      [(ngModel)]="config.parcelNumberFieldId"
    />
  </div>

  <div class="form-group">
    <label class="bmd-label-floating" for="parcelNumberDataEntityName">
      Parcel Number Data Entity
    </label>
    <input
      type="text"
      class="form-control"
      formControlName="parcelNumberDataEntityName"
      name="parcelNumberDataEntityName"
      [(ngModel)]="config.parcelNumberDataEntityName"
    />
  </div>
</form>

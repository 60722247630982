<div class="mt-2">
  <app-loader *ngIf="!clientRoles"></app-loader>

  <div *ngIf="clientRoles && clientRoles.length === 0">
    <p>No roles to display</p>
  </div>

  <div *ngFor="let client of clientRoles">
    <ul class="list-group" *ngIf="clientRoles && clientRoles.length > 0">
      <li class="list-group-item">
        <h5>{{ client ? client.name : "System" }}</h5>
      </li>
      <li
        *ngFor="let role of client.roles"
        class="list-group-item d-flex justify-content-between align-items-start"
      >
        <span class="align-self-center">{{ role.name }}</span>

        <wm-modal-confirm
          [commandText]="'Remove'"
          [showButtonText]="true"
          [moduleId]="role.id"
          [title]="'Remove Role'"
          (accepted)="removed.emit($event)"
          class="align-self-center"
        >
          Are you sure you want to remove the user from this role ({{
            role.name
          }})?
        </wm-modal-confirm>
      </li>
    </ul>
  </div>
</div>

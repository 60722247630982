import { Utilities } from 'src/app/services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MapIndexLayer } from './../../../../models/map-layer';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MapService, MapServer, MapLayer, Client } from '../../../../models';
import {
  UntypedFormGroup,
  AbstractControl,
  UntypedFormBuilder,
  Validators,
  UntypedFormControl
} from '@angular/forms';
import {
  DataService,
  MapServiceProvider,
  WorkflowContextService,
  ValidationService
} from '../../../../services';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { map } from 'rxjs/operators';
import { MapLayerField } from '../../../../models/map-layer-field';
import { Observable, forkJoin } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'wm-map-service-detail-view',
  templateUrl: './map-service-detail-view.component.html',
  styleUrls: ['./map-service-detail-view.component.css']
})
export class MapServiceDetailViewComponent implements OnInit {
  @Input() mapService: MapService;
  @Input() showTitle = true;
  mapServiceId: string;
  mapServers: MapServer[];
  mapLayers: MapLayer[];
  mapIndexLayers: MapIndexLayer[];
  mapLayerFields: MapLayerField[];

  layerIndexToIdMap: { [key: number]: string } = {};
  public indexLoading = false;

  @Input() redirectOnSave = true;
  @Input() showGoBackToList = true;
  @Input() client: Client = null;
  @Input() showControls = true;
  @Output() saved: EventEmitter<MapService> = new EventEmitter<MapService>();
  @Output() canceled: EventEmitter<MapService> = new EventEmitter<MapService>();
  @Input() form: UntypedFormGroup;
  serviceNameCtl: AbstractControl;
  urlCtl: AbstractControl;
  mapKeyValueCtl: AbstractControl;
  layerCtl: AbstractControl;
  fieldCtl: AbstractControl;
  @Input() isRequired = true;

  constructor(
    private _dataSvc: DataService,
    private route: ActivatedRoute,
    private _mapSvc: MapServiceProvider,
    private _router: Router,
    private fb: UntypedFormBuilder,
    private _location: Location,
    private toastr: ToastrService,
    private modalService: NgbModal,
    private _context: WorkflowContextService
  ) {
    if (!this.form) {
      this.form = new UntypedFormGroup({});
    }
  }

  requiredIfValueEntered(validateControl: string, ifControl: string) {
    return (control: AbstractControl) => {};
  }

  ngOnInit() {
    this.form.addControl(
      'url',
      new UntypedFormControl(
        '',
        this.isRequired ? Validators.required : Validators.nullValidator
      )
    );

    this.form.addControl(
      'mapKeyValue',
      new UntypedFormControl('', Validators.nullValidator)
    );

    this.form.addControl(
      'serviceName',
      new UntypedFormControl(
        '',
        this.isRequired
          ? Validators.required
          : ValidationService.requiredIfValidator(
              'serviceName',
              'url',
              this.form
            )
      )
    );
    this.form.addControl(
      'layer',
      new UntypedFormControl(
        '',
        this.isRequired
          ? Validators.required
          : ValidationService.requiredIfValidator('layer', 'url', this.form)
      )
    );
    this.form.addControl(
      'field',
      new UntypedFormControl(
        '',
        this.isRequired
          ? Validators.required
          : ValidationService.requiredIfValidator('field', 'url', this.form)
      )
    );

    this.serviceNameCtl = this.form.controls['serviceName'];
    this.urlCtl = this.form.controls['url'];
    this.mapKeyValueCtl = this.form.controls['mapKeyValue'];
    this.layerCtl = this.form.controls['layer'];
    this.fieldCtl = this.form.controls['field'];

    this.route.params.subscribe(params => {
      this.mapServiceId = params['id'];

      if (this.mapServiceId && this.mapServiceId !== '') {
        this._dataSvc.getMapService(this.mapServiceId).subscribe(res => {
          this.mapService = res;
          this.layerCtl.setValue(this.mapService.parcelLayer);

          this.buildLayers().subscribe(lrs => {
            if (lrs) {
              this.mapIndexLayers = lrs.index;
              if (res) {
                this.onMapLayerChange(res.parcelLayer);
              }
            } else {
              this.toastr.error(
                'Map Service not found at configured URL. Please confirm and update the URL.'
              );
            }
          });
        });
      } else {
        if (!this.mapService) {
          this.mapService = this._mapSvc.createMapService();
        }
      }
    });
  }

  canTest() {
    return true;
  }

  goBack() {
    this._location.back();
  }

  getLayers() {
    if (this.mapService.endpointUrl) {
      this.buildLayers().subscribe(res => {
        // this is here to make sure the observable is called

        this.onMapLayerChange(this.mapService.parcelLayer);
      });
    }
  }

  async indexMapLayers() {
    const isDiscrepancies = await this._dataSvc
      .indexMapServiceLayers(this.mapService.id)
      .toPromise();

    return isDiscrepancies;
  }

  async remapLayers(content: any) {
    this.indexLoading = true;
    const isDiscrepancies = await this.indexMapLayers();

    if (!isDiscrepancies) {
      this.indexLoading = false;
      return;
    }

    await this.buildLayers().toPromise();

    for (const mapLayer of this.mapLayers) {
      const dbLayer = this.mapIndexLayers.find(l => l.name === mapLayer.name);

      if (dbLayer) {
        this.layerIndexToIdMap[mapLayer.id] = dbLayer.id;
      } else {
        this.layerIndexToIdMap[mapLayer.id] = null;
      }
    }

    let result;
    try {
      result = await this.modalService.open(content, {
        ariaLabelledBy: 'modal-map-title',
        size: 'lg'
      }).result;
    } catch (err) {}

    if (result === 'save') {
      for (const mapLayer of this.mapLayers) {
        const indexId = this.layerIndexToIdMap[mapLayer.id];

        let dbLayer = this.mapIndexLayers.find(l => l.id === indexId);

        if (indexId === 'new') {
          dbLayer = new MapIndexLayer({
            id: Utilities.generateId(),
            mapServiceId: this.mapService.id
          });
        }
        dbLayer.name = mapLayer.name;
        dbLayer.index = mapLayer.id;

        const res = await this._dataSvc
          .saveMapLayerIndexItem(dbLayer)
          .toPromise();
      }
      await this.indexMapLayers();
    }

    this.indexLoading = false;
  }

  buildLayers(): Observable<any> {
    const indexObservable = this._dataSvc.getMapLayerIndex(this.mapService.id);

    const layersObservable = this._mapSvc.getMapLayers(
      this.mapService.endpointUrl
    );

    return forkJoin([layersObservable, indexObservable]).pipe(
      map(res => {
        if (res && res.length > 0) {
          this.mapLayers = res[0];

          if (res.length > 1) {
            this.mapIndexLayers = res[1];

            if (res[0] && res[0].length > 0) {
              // only set this value to the default if it isn't already set
              if (!this.mapService.parcelLayer) {
                this.mapService.parcelLayer = res[0][0].name;
              }
              return {
                layers: res[0],
                index: res[1]
              };
            }
          }
        }
      })
    );
  }

  buildFields(layerName: string) {
    if (this.mapLayers) {
      this.mapLayers.some((lyr: MapLayer, index) => {
        if (lyr.name === layerName) {
          this.mapLayerFields = lyr.fields;
          // only set this value to the default if it isn't already set
          if (!this.mapService.parcelLayerKeyField) {
            this.mapService.parcelLayerKeyField = this.mapLayerFields[0].name;
          }
          return true;
        }
      });
    }
  }

  onMapLayerChange(layerName: string) {
    this.mapService.parcelLayer = this.layerCtl.value;
    this.buildFields(this.layerCtl.value);
  }

  removeMapService(e) {
    this._mapSvc.deleteMapService(this.mapService).subscribe(() => {
      this._location.back();
    });
  }

  saveMapService(e) {
    e.preventDefault();
    if (this._context.client) {
      this.mapService.client = this._context.client;
      this.mapService.clientID = this._context.client.id;
    }
    this._dataSvc.saveMapService(this.mapService).subscribe(res => {
      this.toastr.success('Saved!');
      if (this.redirectOnSave) {
        this._router.navigate([
          'admin',
          'jurisdiction',
          this._context.client.id,
          'settings',
          'maps'
        ]);
      } else {
        this.saved.next(res);
      }
    });
  }

  cancel() {
    if (this.redirectOnSave) {
      this._location.back();
    } else {
      this.canceled.next(null);
    }
  }

  canRemove() {
    return (this.mapService || { id: '' }).id !== ('' || null || undefined);
  }
}

import * as Activities from '../../models/activities';
import { ActivityModel } from '../../models/activities';
import { ActivityFactory } from '../workflow.service';
import { Workflow } from '../../models';
import { JsonNetDecycle } from './json-net-decycle';
import { WorkflowGraph } from 'src/app/models/workflow-graph';

export class JSONUtilities {
  elements = {};

  fixReferences(json) {
    const tree = json;

    for (const x in tree) {
      if (typeof tree[x] === 'object' && tree[x] !== null) {
        const result = this.dfsVisit(tree[x]);
        tree[x] = result;
      }
    }

    return tree;
  }

  dfsVisit(tree) {
    for (const x in tree) {
      if (typeof tree[x] === 'object' && tree[x] !== null) {
        const result = this.dfsVisit(tree[x]);
        tree[x] = result;
      }
    }
    if (tree['$ref'] !== undefined) {
      const ref = tree.$ref;
      if (this.elements[ref] !== undefined) {
        tree = this.elements[ref];
      }
    } else if (tree['$id'] !== undefined) {
      const element = tree;
      this.elements[element.$id] = element;
    }

    return tree;
  }
}

export class ActivityUtilities {
  static convertActivitiesJsonToClasses(activities: {
    [key: number]: Activities.Activity<ActivityModel>;
  }) {
    let index = 0;
    for (const key in activities) {
      if (activities[key]) {
        const value: Activities.Activity<ActivityModel> = activities[key];
        const containerActivity: Activities.ContainerActivity<
          ActivityModel
        > = value as Activities.ContainerActivity<ActivityModel>;

        if (!containerActivity.activities) {
          activities[key] = ActivityFactory.createActivity(value, value, true);
        } else {
          this.convertActivitiesJsonToClasses(containerActivity.activities);
        }
        index++;
      }
    }
    return activities;
  }

  static flattenActivities(activities: {
    [key: number]: Activities.Activity<ActivityModel>;
  }): Activities.Activity<ActivityModel>[] {
    const returnActivities: Activities.Activity<ActivityModel>[] = [];
    let foundActivities: Activities.Activity<ActivityModel>[];

    for (const key in activities) {
      if (activities[key]) {
        if (key === '$id') {
          continue;
        }
        const activity: Activities.Activity<ActivityModel> = activities[key];

        const containerActivity: Activities.ContainerActivity<
          ActivityModel
        > = activity as Activities.ContainerActivity<ActivityModel>;

        if (!containerActivity.activities) {
          returnActivities.push(activity);
        } else if (containerActivity && containerActivity.activities) {
          foundActivities = this.flattenActivities(
            containerActivity.activities
          );
          if (foundActivities && foundActivities.length > 0) {
            foundActivities.forEach((value, i) => {
              returnActivities.push(value);
            });
          }
        }
      }
    }

    return returnActivities;
  }

  static convertWorkflowGraphJsonToClasses(
    graph: WorkflowGraph
  ): WorkflowGraph {
    if (graph) {
      if (graph) {
        if (!(graph instanceof WorkflowGraph)) {
          graph = new WorkflowGraph(graph);
        }

        if (graph.onStarted) {
          this.convertActivitiesJsonToClasses(graph.onStarted.activities);
        }

        if (graph.onCompleted) {
          this.convertActivitiesJsonToClasses(graph.onCompleted.activities);
        }
      }

      return graph;
    }
  }

  static convertWorkflowJsonToClasses(workflow: Workflow): Workflow {
    if (workflow) {
      workflow.version.graph = this.convertWorkflowGraphJsonToClasses(
        workflow.version.graph
      );
    }

    return workflow;
  }
}

import { SortDirection } from './../../../../../models/report-data-set';
import { KeysPipe } from './../../../../../pipes/keys.pipe';
import {
  Component,
  OnInit,
  Input,
  forwardRef,
  Inject,
  ViewChild,
  Output,
  EventEmitter,
  TemplateRef,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { TableColumn } from '@swimlane/ngx-datatable';
import { EsriMapComponent } from 'src/app/components/arcgis/esri-map/esri-map.component';
import { GeomUtils } from 'src/app/components/arcgis/geom-utils';
import { MapDisplayConfiguration } from 'src/app/components/arcgis/map-display-configuration';
import {
  QueryMapLayerActivity,
  ActivityModel,
  QueryMapLayerActivityModel
} from 'src/app/models/activities';
import { GridSettings } from 'src/app/models/grid-settings';
import {
  QueryMapLayerRequest,
  QueryMapLayerResult,
  SearchField
} from 'src/app/models/query-map-layer';
import { DataService } from 'src/app/services';
import { ActivityView } from 'src/app/views/master-views/app.view/app.view.component';

@Component({
  selector: 'wm-query-map-layer-activity-input',
  templateUrl: './query-map-layer-activity-input.component.html',
  styleUrls: ['./query-map-layer-activity-input.component.css']
})
export class QueryMapLayerActivityInputComponent extends ActivityView
  implements OnInit, OnChanges {
  @Input() activity: QueryMapLayerActivity;
  @Input() goNextOnMapClick: boolean = false;
  @Output() goNext: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() searched: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild('keyTmpl', { static: true }) keyTmpl: TemplateRef<any>;

  private queueMapZoomToPid = false;
  results: any[];
  hideResultsOnSelect = true;
  loading = false;
  columns: TableColumn[] = [];
  defaultHiddenColumns: string[] = [];
  availableColumns: TableColumn[] = [];
  searchForm: FormGroup;
  searching = false;
  referenceGeometryWKT: { [key: string]: { item1: string; item2: string }[] };
  featureIdAsString: boolean = false;
  sortField: string;
  sortDescending: boolean;

  public page = {
    // The number of elements in the page
    size: 100,
    // The total number of elements
    totalElements: 0,
    // The total number of pages
    totalPages: 0,
    // The current page number
    pageNumber: 0
  };

  @ViewChild(EsriMapComponent)
  private esriMap: EsriMapComponent;

  get resultFields() {
    return this.activity.model.layerConfig
      ? this.activity.model.layerConfig.filter(f => f.showInResults)
      : null;
  }

  get searchFields() {
    return this.activity.model.layerConfig
      ? this.activity.model.layerConfig.filter(f => f.isSearchable)
      : null;
  }

  constructor(
    @Inject(forwardRef(() => DataService)) private _dataSvc: DataService
  ) {
    super();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.activity) {
      this.buildUI();
    }
  }

  async buildUI() {
    if (this.searchForm) {
      // remove any controls that might be there
      for (const c in this.searchForm.controls) {
        if (c) {
          this.searchForm.removeControl(c);
        }
      }

      this.results = null;
      this.page.pageNumber = 0;
      this.columns = null;
      this.availableColumns = null;

      if (this.searchFields) {
        this.searchFields.forEach(sf => {
          this.searchForm.addControl(
            sf.fieldName,
            new FormControl('', {
              updateOn: 'change',
              validators: Validators.nullValidator
            })
          );
        });
      }

      this.searchForm.addControl(
        'FeatureId',
        new FormControl(
          '',
          this.activity.model.isRequired
            ? Validators.required
            : Validators.nullValidator
        )
      );

      const resultFields = this.activity.model.layerConfig.filter(
        f => f.showInResults
      );

      const actionColumns = [
        { prop: 'select', name: '', cellTemplate: this.keyTmpl }
      ];
      const displayColumns = resultFields.map(rf => {
        return {
          prop: rf.fieldName.toUpperCase(),
          name: rf.resultHeader,
          cellTemplate: null
        };
      });

      this.columns = actionColumns.concat(displayColumns);
      this.availableColumns = this.columns;

      if (this.activity.model.mapLayer) {
        this.activity.model.mapLayer.visible = true;
      }

      if (this.esriMap) {
        this.esriMap.refreshLayers();
        this.esriMap.enableFeatureSelection(
          this.activity.model.mapLayer.name,
          this.activity.model.featureIdField,
          false
        );
      }

      if (this.activity.model.selectedFeature) {
        const selectedFeature = this.convertFeatureKeyCasing(
          this.activity.model.selectedFeature
        );
        this.activity.model.selectedFeature = selectedFeature;
        this.queueMapZoomToPid = true;
        this.results = [selectedFeature];

        this.searchForm.controls['FeatureId'].setValue(selectedFeature.KEY);

        this.setFeatureIdAsString();
      }
    }
  }

  setFeatureIdAsString() {
    let keyValue = null;

    if (this.activity.model.selectedFeature) {
      keyValue = this.activity.model.selectedFeature.KEY;
    } else if (this.results && this.results.length > 0) {
      keyValue = this.results[0].KEY;
    }

    const isNumericKey =
      parseFloat(keyValue).toString() == keyValue &&
      !isNaN(parseFloat(keyValue));
    this.featureIdAsString = !isNumericKey;
  }

  async selectOneResult(
    result: { [key: string]: string },
    updateMap: boolean,
    goNext = false
  ) {
    this.activity.model.selectedFeature = result;

    const geom = JSON.parse(result.GEOMETRY);
    const wkt = geom !== null ? GeomUtils.ArcGisGeomToWkt(geom) : null;

    this.activity.model.selectedGeometryWkt = wkt;
    // this.activity.model.mapExtent = sdc.extent;

    if (goNext) {
      this.goNext.emit(true);
    }
  }
  ngOnInit() {
    this.searchForm = new FormGroup({});
    if (this.form) {
      this.form.addControl('searchForm', this.searchForm);
    }
    if (!this.activity.model.mapConfig) {
      this.activity.model.mapConfig = new MapDisplayConfiguration({
        mapServiceId: this.activity.model.mapService.id,
        layers: [this.activity.model.mapLayer],
        showToc: false
      });
    }
    // capture geometry off activity
    this.referenceGeometryWKT =
      this.activity.model.referenceGeometryWKT || this.referenceGeometryWKT;

    // clear the geometry so we don't get serialization issues on submit.
    this.activity.model.referenceGeometryWKT = null;

    this.buildUI();
  }

  convertFeatureKeyCasing(item: {
    [key: string]: string;
  }): { [key: string]: string } {
    const itemResult: { [key: string]: string } = {};

    const itemKeys = Object.keys(item);

    itemKeys.forEach(ik => {
      itemResult[ik.toUpperCase()] = item[ik];
    });

    return itemResult;
  }

  search(request: QueryMapLayerRequest, selectResult = false) {
    this.loading = true;
    this.searched.emit();
    request.featureIdField = this.activity.model.featureIdField;

    this.page.pageNumber = 0;

    this._dataSvc
      .queryMapLayer(request)
      .subscribe((result: QueryMapLayerResult) => {
        this.loading = false;

        const keys = Object.keys(result.results);
        const results = [];
        keys.forEach(k => {
          const itemResult = this.convertFeatureKeyCasing(result.results[k]);
          results.push({
            KEY: k.toUpperCase(),
            ...itemResult
          });
        });
        this.page.totalElements = result.totalRecords;
        this.results = results;
        if (this.results.length === 1 && selectResult) {
          this.selectOneResult(this.results[0], false, this.goNextOnMapClick);
        }
        this.searching = false;
        this.setFeatureIdAsString();
      });
  }

  onPage(e: GridSettings) {
    if (
      e.pageSize !== this.page.size ||
      e.pageNumber !== this.page.pageNumber ||
      e.sortField !== this.sortField ||
      e.sortDescending !== this.sortDescending
    ) {
      this.page.size = e.pageSize;
      this.page.pageNumber = e.pageNumber;
      this.sortField = e.sortField;
      this.sortDescending = e.sortDescending;
      this.submitSearchLayerCriteria();
    }
  }

  submitSearchLayerCriteria(e?) {
    this.clearResults();

    const request: QueryMapLayerRequest = new QueryMapLayerRequest();

    request.mapLayerId = this.activity.model.mapLayer.id;
    request.mapServiceURL = this.activity.model.mapService.endpointUrl;
    request.pageNumber = this.page.pageNumber;
    request.pageSize = this.page.size;
    request.sortField = this.sortField;
    request.sortDescending = this.sortDescending;

    request.searchFields = this.searchFields.map(
      sf =>
        new SearchField({
          field: sf.fieldName,
          value: sf.value,
          searchContains: true
        })
    );

    this.searching = true;
    this.search(request);
  }

  isFeatureSelected(feature: any) {
    return (
      this.activity.model.selectedFeature &&
      this.activity.model.selectedFeature.KEY &&
      this.activity.model.selectedFeature.KEY.toString() ===
        feature.KEY.toString()
    );
  }

  showFeature(feature: any) {
    return this.hideResultsOnSelect && this.activity.model.selectedFeature
      ? this.isFeatureSelected(feature)
      : true;
  }

  anySearchDataEntered() {
    return this.searchFields.some(sf => sf.value !== null);
  }

  onSearchMapTabChange(nce: NgbNavChangeEvent) {
    switch (nce.nextId) {
      case 'mapTab':
        this.switchToMapTab();
        break;
      case 'searchTab':
        this.switchToSearchTab();
        break;
    }
  }

  switchToMapTab() {
    this.queueMapZoomToPid = this.results && this.results.length > 0;
  }

  switchToSearchTab() {
    // NOOP
  }

  async onMapLoaded() {
    const keyField = this.activity.model.featureIdField;

    // set reference data for features that have been selected before this activity
    let referenceWkt: string[] = [];

    if (this.referenceGeometryWKT) {
      const keys = Object.keys(this.referenceGeometryWKT).filter(
        f => f != '$id'
      );
      const featureWkt: string[] = [];
      keys.forEach(k => {
        if (this.referenceGeometryWKT[k]) {
          this.referenceGeometryWKT[k].forEach(f => {
            if (f.item2) {
              referenceWkt.push(f.item2);
            }
          });
        }
      });
    }

    if (referenceWkt && referenceWkt.length > 0) {
      await this.esriMap.setSketchData(
        [],
        this.activity.model.mapConfig.initialExtent,
        referenceWkt
      );
    }

    await this.esriMap.enableFeatureSelection(
      this.activity.model.mapLayer.name,
      keyField,
      false,
      this.featureIdAsString ? `UPPER(${keyField})` : keyField
    );

    if (this.queueMapZoomToPid) {
      this.queueMapZoomToPid = false;

      let featureIds = [];

      if (this.activity.model.selectedFeature) {
        featureIds = this.results
          .filter(
            r =>
              r.KEY.toString() ===
              this.activity.model.selectedFeature.KEY.toString()
          )
          .map((v, i) => v.KEY);
      } else {
        featureIds = this.results.map((v, i) => v.KEY.toString().toUpperCase());
      }

      await this.esriMap.selectFeaturesOnMap(featureIds);
    }
  }

  clearResults() {
    //this.results = null;
    this.activity.model.selectedFeature = null;
    this.searchForm.controls['FeatureId'].setValue(null);
  }

  onFeaturesIdentified(featureIds: string[]) {
    if (featureIds) {
      const request: QueryMapLayerRequest = new QueryMapLayerRequest();

      const key: string = this.activity.model.featureIdField;

      request.mapLayerId = this.activity.model.mapLayer.id;
      request.mapServiceURL = this.activity.model.mapService.endpointUrl;
      request.pageNumber = 0;
      request.pageSize = featureIds.length;
      request.searchFields = [
        new SearchField({
          field: key,
          value: featureIds.join(','),
          searchContains: false
        })
      ];

      this.search(request, true);
    } else {
      this.loading = false;
    }
  }

  onFeaturesBeforeIdentifying(e) {
    this.clearResults();
    this.loading = true;
  }
}

<h3>Permit Reports</h3>

<ul ngbNav #nav="ngbNav" class="nav-tabs">
  <li ngbNavItem>
    <a ngbNavLink>Summary</a>
    <ng-template ngbNavContent>
      <wm-permits-summary></wm-permits-summary>
    </ng-template>
  </li>
</ul>
<div [ngbNavOutlet]="nav"></div>

import { Client } from '.';

export class ContractorDocumentCategory {
  id: string;
  name: string;
  isEditing: boolean;

  constructor(options?: Partial<ContractorDocumentCategory>) {
    if (options) {
      Object.assign(this, options);
    }
  }
}

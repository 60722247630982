import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-workflow-builder',
  templateUrl: './workflow-builder.component.html',
  styleUrls: ['./workflow-builder.component.css']
})
export class WorkflowBuilderComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}

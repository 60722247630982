import { Workflow } from './../../../models/workflow';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'wm-workflow-list-section',
  templateUrl: './workflow-list-section.component.html',
  styleUrls: ['./workflow-list-section.component.css']
})
export class WorkflowListSectionComponent implements OnInit {
  @Input() title: string;
  @Input() workflows: Workflow[];

  constructor() {}

  ngOnInit() {}
}

import { StatusType } from './../../../../../views/admin/user-editor/user-list-view/user-list-view.component';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Component, OnInit, Inject, forwardRef } from '@angular/core';
import { ContractorSelectionActivity } from 'src/app/models/activities/contractor-selection-activity';
import { ActivityView } from 'src/app/views/master-views/app.view/app.view.component';
import {
  ContractorType,
  ContractorRegistration,
  Contractor
} from 'src/app/models';
import { ContractorService } from 'src/app/services';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Observable, of, Subject } from 'rxjs';
import {
  ItemSearchOptionField,
  ItemSearchOptionFieldOption
} from '../../../../filter-list/models/filterClasses';
import * as moment from 'moment';
import { RegistrationStatus } from 'src/app/models/registration';

@Component({
  selector: 'wm-contractor-selection-activity-input',
  templateUrl: './contractor-selection-activity-input.component.html',
  styleUrls: ['./contractor-selection-activity-input.component.css']
})
export class ContractorSelectionActivityInputComponent extends ActivityView
  implements OnInit {
  activity: ContractorSelectionActivity;
  contractorType: ContractorType;
  contractorTypeId: string;
  public sortField = '';
  public sortDescending: boolean;
  registrations: ContractorRegistration[];
  contractor: Contractor;
  loadContractorObs: Observable<Contractor>;

  public searchText: string;
  public searchTextChanged: Subject<string> = new Subject<string>();

  public pagination: {
    pageCount?: number;
    pageNumber?: number;
    perPage?: number;
  } = {
    pageNumber: 0,
    pageCount: 0,
    perPage: 10
  };
  searching = false;

  get pages() {
    const pages = [];
    for (let index = 0; index < this.pagination.pageCount; index++) {
      pages.push(index + 1);
    }

    return pages;
  }

  constructor(
    @Inject(forwardRef(() => ContractorService))
    public _contractorSvc: ContractorService
  ) {
    super();
  }

  async ngOnInit() {
    this.form.addControl(
      'notInList',
      new UntypedFormControl('', Validators.nullValidator)
    );
    this.form.addControl(
      'selectedContractor',
      new UntypedFormControl('', {
        updateOn: 'change',
        validators: Validators.required
      })
    );

    await this.loadContractors();

    if (this.activity.model.contractorSelection.selectedRegistrationId) {
      await this.selectItem(
        this.activity.model.contractorSelection.selectedRegistrationId
      );
    }

    this.searchTextChanged
      .pipe(
        debounceTime(400),
        distinctUntilChanged()
      )

      .subscribe(model => {
        this.searchText = model;

        this.loadContractors();
      });
  }

  clearSelection() {
    this.form.controls['selectedContractor'].setValue(null);
    this.activity.model.contractorSelection.selectedRegistrationId = null;
    this.contractor = null;
  }

  notInListChanged(e) {
    if (e.srcElement.checked) {
      this.clearSelection();
      this.form.controls['selectedContractor'].disable();
    } else {
      this.form.controls['selectedContractor'].enable();
    }
  }

  async selectItem(value) {
    if (value) {
      this.form.controls['selectedContractor'].setValue(value);
      this.activity.model.contractorSelection.notInList = false;
      // get the information to display!
      if (this.registrations) {
        const reg = this.registrations.find(r => r.id === value);

        if (reg) {
          this.activity.model['contractorSelection']['selectedContractorId'] =
            reg.contractorID;

          this.activity.model['contractorSelection'][
            'selectedRegistrationId'
          ] = value;

          let businessname = '';

          businessname = reg.contractor.businessName;

          this.activity.model['contractorSelection'][
            'selectedContractorBusinessName'
          ] = businessname;
        }

        this.loadContractorObs = this._contractorSvc.getContractor(
          this.activity.model['contractorSelection']['selectedContractorId']
        );
      }
    } else {
      this.form.controls['selectedContractor'].setValue(null);
      if (!this.loadContractorObs) {
        this.loadContractorObs = of(null);
      }
    }

    const contractor = await this.loadContractorObs.toPromise();

    this.loadContractorObs = null;
    if (contractor != null && value) {
      this.contractor = contractor;
    } else {
      this.activity.model['contractorSelection']['selectedContractorId'] = null;

      this.contractor = null;
    }
  }

  private getFilters(): ItemSearchOptionField[] {
    const filterOptions = [];

    filterOptions.push({
      title: 'status',
      options: [
        new ItemSearchOptionFieldOption({
          title: 'status',
          selected: true,
          filterText: '',
          searchText:
            RegistrationStatus.Approved + ',' + RegistrationStatus.Renewed,
          strOperator: 'in'
        })
      ]
    });

    const strToday = moment().format('MM/DD/YYYY');
    filterOptions.push({
      title: 'effectiveOn',
      options: [{ title: '', strOperator: '<=', filterText: strToday }]
    });
    filterOptions.push({
      title: 'expiresOn',
      options: [{ title: '', strOperator: '>=', filterText: strToday }]
    });

    filterOptions.push({
      title: 'closestExpiration',
      options: [{ title: '', strOperator: '', filterText: '' }]
    });

    if (
      this.activity.contractorTypeIds &&
      this.activity.contractorTypeIds.length > 0
    ) {
      filterOptions.push({
        title: 'ContractorTypeIds',
        options: [
          { title: '', filterText: this.activity.contractorTypeIds.join(',') }
        ]
      });
    }

    if (this.searchText) {
      filterOptions.push({
        title: 'contractor',
        options: [
          {
            title: '',
            filterText: this.searchText,
            searchOptionMethod: 'simpleSearch'
          }
        ]
      });
    }

    return filterOptions;
  }

  public setPage(pageNumber: number) {
    this.pagination.pageNumber = pageNumber;
    this.loadContractors();
  }

  async loadContractors() {
    if (this.context.client) {
      const filterOptions = this.getFilters();

      this.searching = true;
      const results = await this._contractorSvc
        .searchContractorRegistrations(
          this.context.client.id,
          this.sortField,
          this.sortDescending,
          filterOptions,
          this.pagination.pageNumber,
          this.pagination.perPage,
          true
        )
        .toPromise();

      this.searching = false;

      this.registrations = results.contractorRegistrations;
      this.pagination.pageCount = results.pageCount;

      this.form.controls['selectedContractor'].setValue(
        this.activity.model.contractorSelection.selectedRegistrationId
      );

      if (!this.activity.canEditContractor) {
        this.form.controls['selectedContractor'].disable();
        this.form.controls['notInList'].disable();
        await this.selectItem(
          this.activity.model.contractorSelection.selectedRegistrationId
        );
      }
    }
  }
}

import { DataService } from './';
import { Observable, of } from 'rxjs';
import { Injectable, forwardRef, Inject } from '@angular/core';
import { CustomReport, CustomReportRequest } from '../models/custom-report';
import { DataSet } from '../models/report-data-set';

@Injectable({
  providedIn: 'root'
})
export class ReportService {
  testDataSet(request: CustomReportRequest): Observable<any> {
    return this._dataSvc.testDataSet(request);
  }
  constructor(
    @Inject(forwardRef(() => DataService)) private _dataSvc: DataService
  ) {}

  getCustomReports(clientId?: string): Observable<CustomReport[]> {
    return this._dataSvc.getCustomReports(clientId);
  }

  getCustomReportForInput(reportId: string): Observable<CustomReport> {
    return this._dataSvc.getCustomReportForInput(reportId);
  }

  executeCustomReport(request: CustomReportRequest): Observable<any> {
    return this._dataSvc.executeCustomReport(request);
  }

  getDataSetForFilterInput(id: string): Observable<DataSet> {
    return this._dataSvc.getDataSetForFilterInput(id);
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { CertifiedPaymentsPaymentProcessorAccountConfiguration } from 'src/app/models/payment-providers/certifiedpayments/certifiedpayments-payment-processor-account-configuration';
import { PaymentAccountConfigBaseComponent } from '../payment-account-config-base/payment-account-config-base.component';

@Component({
  selector: 'wm-payment-account-config-certifiedpayments',
  templateUrl: './payment-account-config-certifiedpayments.component.html',
  styleUrls: ['./payment-account-config-certifiedpayments.component.css']
})
export class PaymentAccountConfigCertifiedpaymentsComponent
  extends PaymentAccountConfigBaseComponent
  implements OnInit {
  @Input() config: CertifiedPaymentsPaymentProcessorAccountConfiguration;

  constructor(fb: UntypedFormBuilder) {
    super(fb);
    this.showPassword = false;
    this.showUsername = false;
  }

  ngOnInit() {
    this.addValidation('id', Validators.required, this.config.id);
    this.addValidation(
      'accessCode',
      Validators.required,
      this.config.accessCode
    );
    this.addValidation(
      'bureauCode',
      Validators.required,
      this.config.bureauCode
    );
    this.addValidation(
      'paymentType',
      Validators.nullValidator,
      this.config.paymentType
    );
  }
}

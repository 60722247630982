import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, throwError, empty } from 'rxjs';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { SecurityService } from './security.service';
import { WorkflowContextService } from './workflow-context.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private _context: WorkflowContextService,
    private _securitySvc: SecurityService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError(err => {
        if (err.status === 401) {
          this._securitySvc.clearSession();
        } else if (err.status === 403) {
          if ((this._context.authToken || '') === '') {
            this.router.navigate(['/auth/login'], {
              queryParams: { returnUrl: this.router.url }
            });
          } else {
            this.router.navigate(['/admin/global/unauthorized']);
          }
          return empty();
        } else {
          return throwError(err);
        }
      })
    );
  }
}

import {
  Component,
  OnInit,
  Inject,
  forwardRef,
  ViewChild
} from '@angular/core';
import { EsriMapComponent } from 'src/app/components/arcgis/esri-map/esri-map.component';
import { MapService } from 'src/app/models';
import { QueryMapLayerActivity } from 'src/app/models/activities';
import { DataService } from 'src/app/services';
import { ActivityView } from 'src/app/views/master-views/app.view/app.view.component';

@Component({
  selector: 'wm-query-map-layer-activity-view',
  templateUrl: './query-map-layer-activity-view.component.html',
  styleUrls: ['./query-map-layer-activity-view.component.css']
})
export class QueryMapLayerActivityViewComponent extends ActivityView
  implements OnInit {
  @ViewChild(EsriMapComponent)
  private esriMap: EsriMapComponent;

  activity: QueryMapLayerActivity;
  public results: { [key: string]: string }[];
  private _cachedMapService: MapService;
  referenceGeometryWKT: { [key: string]: { item1: string; item2: string }[] };

  constructor(
    @Inject(forwardRef(() => DataService)) private _dataSvc: DataService
  ) {
    super();
  }

  get resultFields() {
    return this.activity.model.layerConfig.filter(lc => lc.showInResults);
  }

  ngOnInit() {
    if (this.activity.model.selectedFeature) {
      const headerResults = {};
      this.activity.model.layerConfig
        .filter(lc => lc.showInResults)
        .forEach(lc => {
          headerResults[lc.fieldName] = lc.resultHeader;
        });

      // convert property names to lowercase
      const sfKeys = Object.keys(this.activity.model.selectedFeature);

      const result = {};

      sfKeys.forEach(sfKey => {
        result[sfKey.toLowerCase()] = this.activity.model.selectedFeature[sfKey];
      });

      this.results = [headerResults, <any>result];
    }
  }

  async onMapLoaded() {
    if (this.esriMap) {
      const keyField = this.activity.model.featureIdField;

      const keyLayerField = this.activity.model.mapLayer.fields.find(
        f => f.name === keyField
      );

      if (keyLayerField) {
        if (keyLayerField && keyLayerField.type === 'esriFieldTypeOID') {
          this.esriMap.featureKeyAsString = false;
        } else {
          this.esriMap.featureKeyAsString = true;
        }
      }

      // set reference data for features that have been selected before this activity
      let referenceWkt: string[] = [];

      // capture geometry off activity
      this.referenceGeometryWKT =
        this.activity.model.referenceGeometryWKT || this.referenceGeometryWKT;

      // clear the geometry so we don't get serialization issues on submit.
      this.activity.model.referenceGeometryWKT = null;

      if (this.referenceGeometryWKT) {
        const keys = Object.keys(this.referenceGeometryWKT).filter(
          f => f != '$id'
        );
        const featureWkt: string[] = [];
        keys.forEach(k => {
          if (this.referenceGeometryWKT[k]) {
            this.referenceGeometryWKT[k].forEach(f => {
              if (f.item2) {
                referenceWkt.push(f.item2);
              }
            });
          }
        });
      }

      if (referenceWkt && referenceWkt.length > 0) {
        await this.esriMap.setSketchData([], null, referenceWkt);
      }

      await this.esriMap.enableFeatureSelection(
        this.activity.model.mapLayer.name,
        keyField,
        true
      );

      const featureKeys = this.results
        .filter((feature, i) => i > 0) // skip the header row
        .map(feature => feature['key']);

      await this.esriMap.selectFeaturesOnMap(featureKeys);
    }
  }
}

import { DataEntity } from './data-entity';
import { Validators } from '@angular/forms';

declare const $: any;

export class RichTextDataEntity extends DataEntity {
  html?: string;

  get renderedHtml(): string {
    const html = this.value;
    if (!html) {
      return '';
    }

    const $html = $('<div />', { html });
    $html.find('img').addClass('img-fluid');

    return $html.html();
  }

  constructor(options?: Partial<RichTextDataEntity>) {
    super(options);

    this.dataEntityTypeCode = 'rich-text';
    this.dataEntityTypeDescription = 'Display formatted text in a form';
    this.dataEntityTypeName = 'Formatted Text';

    if (options) {
      Object.assign(this, options);
    }

    this.hasPreview = false;
  }

  getValidators(): any[] {
    return [Validators.nullValidator];
  }
}

import { SecurepayPaymentProcessorConfiguration } from './../../../models/payment-providers/securepay/securepay-payment-processor-configuration';
import { Component, OnInit } from '@angular/core';
import { PaymentProcessorConfigBaseComponent } from '../payment-processor-config-base/payment-processor-config-base.component';
import { UntypedFormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'wm-payment-processor-config-securepay',
  templateUrl: './payment-processor-config-securepay.component.html',
  styleUrls: ['./payment-processor-config-securepay.component.css']
})
export class PaymentProcessorConfigSecurepayComponent
  extends PaymentProcessorConfigBaseComponent
  implements OnInit {
  securePayConfig: SecurepayPaymentProcessorConfiguration;

  constructor(fb: UntypedFormBuilder) {
    super(fb);
  }

  ngOnInit() {
    this.securePayConfig = this
      .processorConfig as SecurepayPaymentProcessorConfiguration;

    this.addValidation('paymentRedirectURL', Validators.required);
  }
}

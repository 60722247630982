import { ActivityView } from 'src/app/views/master-views/app.view/app.view.component';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'wm-generate-application-number-activity-view',
  templateUrl: './generate-application-number-activity-view.component.html',
  styleUrls: ['./generate-application-number-activity-view.component.css']
})
export class GenerateApplicationNumberActivityViewComponent extends ActivityView
  implements OnInit {
  constructor() {
    super();
  }

  ngOnInit() {}
}

import { WorkflowService } from 'src/app/services/workflow.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import {
  Component,
  OnInit,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  AfterViewInit,
  Inject,
  forwardRef,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'wm-activity-details-modal',
  templateUrl: './activity-details-modal.component.html',
  styleUrls: ['./activity-details-modal.component.css']
})
export class ActivityDetailsModalComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() title: string;
  @Input() moduleId: string;
  @Output() saved: EventEmitter<any> = new EventEmitter<any>();
  @Output() canceled: EventEmitter<any> = new EventEmitter<any>();
  @Output() closed: EventEmitter<any> = new EventEmitter<any>();
  @Output() opened: EventEmitter<any> = new EventEmitter<any>();
  @Input() btnShow = true;
  @Input() btnText: string;
  @Input() btnColor = 'primary';
  @Input() form: UntypedFormGroup;
  @Input() containerType = 'container';
  @Input() disabled = false;
  @ViewChild('content', { static: false }) content;

  modalRef: NgbModalRef;

  constructor(
    private modalService: NgbModal,
    @Inject(forwardRef(() => WorkflowService))
    public _workflowSvc: WorkflowService,
    private _fb: UntypedFormBuilder
  ) {
    this.form = _fb.group({});
  }
  ngOnChanges(changes: SimpleChanges): void {
    if(changes['form'] && !this.form) {
      this.form = this._fb.group({});
    }
  }

  ngOnInit() {
    if (!this.form) {
      this.form = this._fb.group({});
    }
  }

  dismis(reason) {
    if (reason === 'save') {
      this.saved.emit(this.moduleId);
    } else {
      this.canceled.emit();
    }
    this.closed.emit();
  }

  close() {
    this.modalRef.dismiss();
  }
  open() {
    this.modalRef = this.modalService.open(this.content, {
      ariaLabelledBy: `${this.moduleId}_modallabel`,
      windowClass: 'modal-full'
    });

    this.modalRef.result.then(
      result => this.dismis(result),
      reason => this.dismis(reason)
    );
    this.opened.emit();
    return false;
  }
  ngAfterViewInit(): void {
    if (this.content.cancelRequested) {
      this.content.cancelRequested.subscribe(() => {
        this.dismis(null);
      });
    }
  }
}

import { User } from './user';
import { Address } from './address';
import { Client } from './client';
import { ContractorRegistration } from './contractor-registration';
import { ContractorRequirementType } from './contractor-requirement-type';
import { EmailAddress } from './email-address';
import { PhoneNumber } from './phone-number';
import { ContractorDocument } from './contractor-document';

// This is the type that defines the value of a contractor requirement type for a contractor
export interface IContractorRequirement {
  id: string;
  type: ContractorRequirementType;
  contractor?: Contractor;
  value: any;
}

export class Contractor {
  id: string;
  contactFirstName: string;
  contactLastName: string;
  businessName: string;
  contactTitle: string;
  contactEmail?: EmailAddress;
  companyWebAddress?: string;
  isEnabled: boolean;
  client: Client;
  address?: Address;
  businessPhone?: PhoneNumber;
  contactFaxNumber?: PhoneNumber;
  businessCell?: PhoneNumber;
  documents: ContractorDocument[];
  customFields: any[];
  user?: User;
  userId?: string;

  requirements?: IContractorRequirement[];
  registrations?: ContractorRegistration[];

  constructor(options?: Partial<Contractor>) {
    if (options) {
      Object.assign(this, options);

      if (options.documents) {
        this.documents = options.documents.map(d => new ContractorDocument(d));
      }
    }
  }
}

<div [formGroup]="form" *ngIf="form && activity">
  <div class="form-group">
    <wm-control-message [control]="form.controls['selectedContractor']">
    </wm-control-message>
  </div>
  <div class="form-group">
    <label for="search">Search</label>
    <input
      type="text"
      class="form-control"
      name="search"
      id="search"
      placeholder="Search..."
      [(ngModel)]="searchText"
      (ngModelChange)="searchTextChanged.next($event)"
      [ngModelOptions]="{ standalone: true }"
    />
  </div>

  <ul class="list-group">
    <span class="list-group-item" *ngIf="searching">Searching...</span>
    <button
      type="button"
      class="list-group-item list-group-item-action"
      style="cursor: pointer;"
      [disabled]="!activity.canEditContractor"
      [ngClass]="{
        active:
          registration.id ===
          this.activity.model?.contractorSelection?.selectedRegistrationId
      }"
      (click)="selectItem(registration.id)"
      *ngFor="let registration of registrations"
    >
      {{ registration.contractor.businessName }} ({{ registration.type.name }})
    </button>
  </ul>

  <nav aria-label="Page navigation" *ngIf="pagination.pageCount > 1">
    <ul class="pagination">
      <li
        class="page-item"
        [ngClass]="{ disabled: pagination.pageNumber === 0 }"
      >
        <a
          class="page-link"
          href="javascript:void(0)"
          (click)="setPage(pagination.pageNumber - 1)"
        >
          Previous
        </a>
      </li>
      <li
        class="page-item"
        [ngClass]="{ active: page - 1 === pagination.pageNumber }"
        *ngFor="let page of pages"
      >
        <a
          class="page-link"
          href="javascript:void(0)"
          (click)="setPage(page - 1)"
        >
          {{ page }}
        </a>
      </li>
      <li
        class="page-item"
        [ngClass]="{
          disabled: pagination.pageNumber === pagination.pageCount - 1
        }"
      >
        <a
          class="page-link"
          href="javascript:void(0)"
          (click)="setPage(pagination.pageNumber + 1)"
        >
          Next
        </a>
      </li>
    </ul>
  </nav>

  <div
    class="switch"
    *ngIf="activity.model.allowNotListedOption"
    (change)="notInListChanged($event)"
  >
    <label>
      <input
        type="checkbox"
        formControlName="notInList"
        [(ngModel)]="activity.model.contractorSelection.notInList"
      />
      Contractor Not Listed
    </label>
  </div>
</div>
<address *ngIf="contractor">
  <h4>Selected Contractor</h4>
  <strong>{{ contractor.businessName }}</strong
  ><br />
  {{ contractor.address?.address1 }}<br />
  {{ contractor.address?.address2 }}<br *ngIf="contractor.address.address2" />
  {{ contractor.address?.city }}, {{ contractor.address?.state }}
  {{ contractor.address?.zip }}<br /><br />
  <abbr title="Business Phone">Bus. Ph:</abbr>
  <span *ngIf="contractor.businessPhone">
    {{ contractor.businessPhone?.number }}</span
  ><br />
  <abbr title="Fax Number">Fax:</abbr>
  <span *ngIf="contractor.contactFaxNumber">
    {{ contractor.contactFaxNumber?.number }}</span
  ><br />
  <abbr title="Business Cell">Cell:</abbr>
  <span *ngIf="contractor.businessCell">
    {{ contractor.businessCell?.number }}</span
  >
</address>

<address *ngIf="contractor">
  <a href="mailto:#">{{ contractor.contactEmail?.email }}</a>
</address>

import { NumericDataType } from './../../../../models/data-entities/numeric-data-entity';
import { Component, OnInit } from '@angular/core';
import { DataEntityEditorBaseComponent } from '../data-entity-editor-base/data-entity-editor-base.component';
import { NumericDataEntity } from '../../../../models/data-entities';
import {
  Validators,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidationErrors
} from '@angular/forms';
import { ExternalDataSource } from 'src/app/models/external-data-source';

@Component({
  selector: 'wm-numeric-data-entity',
  templateUrl: './numeric-data-entity.component.html',
  styleUrls: ['./numeric-data-entity.component.css']
})
export class NumericDataEntityComponent extends DataEntityEditorBaseComponent
  implements OnInit {
  numericDE: NumericDataEntity;

  types: any[];

  constructor(private _fb: UntypedFormBuilder) {
    super();

    this.types = [
      {
        label: 'Number',
        value: NumericDataType.Number
      },
      {
        label: 'Currency',
        value: NumericDataType.Currency
      }
    ];
  }

  ngOnInit() {
    this.numericDE = this.dataEntity as NumericDataEntity;

    this.form.addControl(
      'maximumValue',
      this._fb.control('', Validators.pattern(/^(0|[1-9][0-9]*)$/))
    );
    this.form.addControl(
      'minimumValue',
      this._fb.control('', Validators.pattern(/^(0|[1-9][0-9]*)$/))
    );
    this.form.addControl(
      'decimalPlaces',
      this._fb.control('', Validators.pattern(/^(0|[1-9][0-9]*)$/))
    );
    this.form.addControl(
      'type',
      this._fb.control(NumericDataType.Number, Validators.nullValidator)
    );

    this.form.setValidators([this.validateDate.bind(this)]);
  }

  validateDate(group: UntypedFormGroup): ValidationErrors | null {
    const maxVal: number = parseFloat(
      group.controls['maximumValue'] && group.controls['maximumValue'].value
    );
    const minVal: number = parseFloat(
      group.controls['minimumValue'] && group.controls['minimumValue'].value
    );

    if (!isNaN(minVal) && !isNaN(maxVal)) {
      if (minVal > maxVal) {
        return { validateDate: 'Max Value cannot be less than Minimum Value!' };
      }
    }

    return null;
  }

  getErrors() {
    if (this.form.errors) {
      const errors = Object.keys(this.form.errors).map(
        e => this.form.errors[e]
      );

      return errors;
    }

    return [];
  }

  externalDataSourceChanged(eds: ExternalDataSource) {
    this.dataEntity.externalDataSource = eds;
  }
}

<div class="form-group" *ngIf="entity && entity.visibleToUser">
  <wm-de-label
    [entity]="entity"
    [for]="entity.templateCode"
    [displayOnly]="true"
  ></wm-de-label>
  <input
    type="text"
    readonly
    class="form-control-plaintext"
    [id]="entity.templateCode"
    [value]="entity.formattedValue"
  />
</div>

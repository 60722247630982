<form [formGroup]="form">
  <div class="row">
    <div class="col">
      <h3 *ngIf="showTitle">Map Service Settings</h3>
      <app-loader *ngIf="!mapService"></app-loader>

      <div *ngIf="mapService">
        <div
          class="form-group"
          [class.has-error]="serviceNameCtl.touched && !serviceNameCtl.valid"
        >
          <label class="bmd-label-floating" for="mapName">Name</label>
          <input
            type="text"
            class="form-control"
            [(ngModel)]="mapService.name"
            [formControl]="serviceNameCtl"
            name="mapName"
          />
          <wm-control-message
            [controlName]="'serviceName'"
          ></wm-control-message>
        </div>

        <div
          class="form-group"
          [class.has-error]="urlCtl.touched && !urlCtl.valid"
        >
          <label class="bmd-label-floating" for="mapUrl">Service URL</label>
          <input
            type="text"
            class="form-control"
            [(ngModel)]="mapService.endpointUrl"
            [formControl]="urlCtl"
            name="mapUrl"
          />
          <wm-control-message [controlName]="'url'"></wm-control-message>
        </div>

        <div
          class="form-group"
          [class.has-error]="mapKeyValueCtl.touched && !mapKeyValueCtl.valid"
        >
          <label class="bmd-label-floating" for="mapKeyValue"
            >Map Key Value (Used by Venturi Map Publishing)</label
          >
          <input
            type="text"
            class="form-control"
            [(ngModel)]="mapService.mapKeyValue"
            [formControl]="mapKeyValueCtl"
            name="mapKeyValue"
          />
          <wm-control-message
            [controlName]="'mapKeyValue'"
          ></wm-control-message>
        </div>

        <button class="btn btn-block btn-primary" (click)="getLayers()">
          Get Layers
        </button>

        <div class="form-row">
          <div class="col-6">
            <div
              class="form-group"
              [class.has-error]="layerCtl.touched && !layerCtl.valid"
            >
              <label class="bmd-label-floating" for="parcelLayer">
                Parcel Layer
              </label>
              <select
                class="form-control"
                (change)="onMapLayerChange($event)"
                [formControl]="layerCtl"
                name="parcelLayer"
              >
                <option *ngIf="!mapLayers || mapLayers.length < 1"></option>
                <option
                  *ngFor="let mapLayer of mapLayers"
                  [value]="mapLayer.name"
                >
                  {{ mapLayer.name }}
                </option>
              </select>
              <wm-control-message [controlName]="'layer'"></wm-control-message>
            </div>
          </div>
          <div class="col-6">
            <div
              class="form-group"
              [class.has-error]="fieldCtl.touched && !fieldCtl.valid"
            >
              <div class="bmd-label-floating" for="parcelIdField">
                Parcel ID Field
              </div>
              <select
                [(ngModel)]="mapService.parcelLayerKeyField"
                class="form-control"
                [formControl]="fieldCtl"
                name="parcelIdField"
              >
                <option
                  *ngIf="!mapLayerFields || mapLayerFields.length < 1"
                ></option>
                <option
                  *ngFor="let layerField of mapLayerFields"
                  [value]="layerField.name"
                >
                  {{ layerField.name + ' (' + layerField.alias + ')' }}
                </option>
              </select>
              <wm-control-message [controlName]="'field'"></wm-control-message>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="mapService && showControls">
    <div class="col">
      <button
        type="submit"
        class="btn btn-raised btn-success mr-2"
        [disabled]="!form.valid"
        (click)="saveMapService($event)"
      >
        Save
      </button>
      <button
        class="btn btn-raised btn-default mx-2"
        [disabled]="indexLoading"
        *ngIf="mapServiceId"
        (click)="remapLayers(content)"
      >
        <ng-container *ngIf="indexLoading">
          <span
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
          Loading...
        </ng-container>

        <ng-container *ngIf="!indexLoading">
          Re-index
        </ng-container>
      </button>
    </div>
    <div class="col">
      <div class="float-right">
        <wm-modal-confirm
          [commandText]="'Delete'"
          [title]="'Remove Map Service'"
          [showButtonText]="true"
          (accepted)="removeMapService($event)"
          *ngIf="canRemove()"
          btnColor="danger"
          class="mx-2"
        >
          <p>
            Are you sure you want to remove this map service ({{
              mapService.name
            }}) from the system?
          </p>
        </wm-modal-confirm>

        <button
          type="button"
          class="btn btn-raised btn-default mx-2"
          (click)="cancel($event)"
        >
          Cancel
        </button>
      </div>
    </div>
  </div>
</form>

<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-map-title">Map Layers</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('close')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>Please match the new layers with old ones or create new layers.</p>

    <table class="table">
      <thead>
        <tr>
          <th scope="col">New Layers</th>
          <th scope="col">Existing Layers</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let layer of mapLayers">
          <tr
            [ngClass]="{
              'table-danger': this.layerIndexToIdMap[layer.id] === null
            }"
          >
            <th scope="row">{{ layer.name }} ({{ layer.type }})</th>
            <td>
              <select
                class="form-control"
                [(ngModel)]="this.layerIndexToIdMap[layer.id]"
              >
                <option [ngValue]="null" disabled selected>
                  (needs reconciliation)
                </option>
                <option value="new">
                  (new layer)
                </option>
                <option *ngFor="let ml of mapIndexLayers" [ngValue]="ml.id">
                  {{ ml.name }}
                </option>
              </select>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-dark"
      (click)="modal.close('save')"
    >
      Save
    </button>
  </div>
</ng-template>

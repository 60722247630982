<!-- <div class="container"> -->
<div class="row">
  <div [class.col-md-3]="showToc" [class.hidden]="!showToc">
    <div #tocViewNode class="esri-toc"></div>
  </div>
  <div [class.col-md-9]="showToc" [class.col-md-12]="!showToc">
    <div
      #mapViewNode
      class="esri-view"
      [ngClass]="mapDimensionLayout_class"
    ></div>
  </div>
</div>
<!-- </div> -->

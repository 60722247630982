export class VoidRequest {
  voidReason: string;
  voidedByUser: string;
  voidDateTime: Date;
  constructor(options?: Partial<VoidRequest>) {
    if (options) {
      Object.assign(this, options);
    }
  }
}

export class BulkVoidRequest extends VoidRequest {
  applicationIds: string[];
}

<form [formGroup]="form">
  <app-loader *ngIf="!conditionSources"></app-loader>
  <ng-container *ngIf="conditionSources">
    <wm-condition-builder
      *ngIf="advancedFee"
      [title]="formulaTitle"
      [conditionSources]="conditionSources"
      [conditions]="conditions"
      [allowEditingElse]="true"
      [thenLabelGetter]="thenLabelGetter"
      (conditionChanges)="conditionsChanged($event)"
    >
      <ng-template let-target="target">
        <label for="then">Then the {{ formulaTitle }} is</label>

        <wm-data-entity-autocomplete
          [types]="entityTypes"
          [title]="'Formula (enter number for fixed value)'"
          [model]="target.value"
          [id]="'feeFormula' + target.id"
          [required]="true"
          [workflow]="workflow"
          [serverValidator]="workflowSvc.validateFeeFormula"
          [activity]="activity"
          [onlyGlobalDataEntities]="onlyGlobalDataEntities"
          [excludedTemplateCodes]="excludedTemplateCodes"
          (valueUpdate)="target.value = $event; conditionsChanged()"
        ></wm-data-entity-autocomplete>
      </ng-template>
    </wm-condition-builder>

    <div class="form-row">
      <div class="col-12">
        <wm-data-entity-autocomplete
          *ngIf="!advancedFee"
          [types]="entityTypes"
          id="formula"
          [activity]="activity"
          [title]="formulaTitle"
          [model]="otherwiseCondition.value"
          [required]="required"
          [workflow]="workflow"
          [controlName]="'formula'"
          [serverValidator]="workflowSvc.validateFeeFormula"
          [onlyGlobalDataEntities]="onlyGlobalDataEntities"
          [excludedTemplateCodes]="excludedTemplateCodes"
          (valueUpdate)="otherwiseCondition.value = $event; conditionsChanged()"
          [placement]="placement"
        ></wm-data-entity-autocomplete>
      </div>
    </div>

    <div class="switch" *ngIf="allowTypeChange">
      <label>
        <input
          type="checkbox"
          [(ngModel)]="advancedFee"
          formControlName="advancedFee"
          (click)="toggleAdvanced()"
        />
        Advanced {{ formulaTitle }} editor
      </label>
    </div>
  </ng-container>
</form>

<div class="card card-grey">
  <div class="card-body">
    <form [formGroup]="listItemForm" (ngSubmit)="addItem()">
      <p class="card-title">
        {{ title }}
      </p>
      <div class="form-group">
        <label class="bmd-label-static" for="listValue">
          Value <span class="text-danger">*</span>
        </label>
        <input
          type="text"
          id="listValue"
          name="listValue"
          class="form-control"
          (keypress)="keyPress($event)"
          formControlName="value"
          (change)="valueChange()"
        />
      </div>
      <div class="form-group">
        <label class="bmd-label-static" for="listText">
          Label
        </label>
        <input
          type="text"
          id="listText"
          name="listText"
          class="form-control"
          (keypress)="keyPress($event)"
          formControlName="text"
          (change)="textChange()"
        />
      </div>

      <button
        class="btn btn-outline-secondary float-right"
        type="submit"
        (click)="updateItem()"
        [disabled]="listItemForm.invalid"
      >
        {{ saveText }}
      </button>
    </form>
  </div>
</div>

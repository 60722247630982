<div class="form-group">
  <label class="bmd-label-floating" for="introductionText">
    Introduction Text
  </label>
  <textarea
    class="form-control"
    [(ngModel)]="activity.model.introductionText"
    name="introductionText"
    rows="10"
  >
  </textarea>
</div>

import { Component, OnInit, Input } from '@angular/core';
import { DataEntity } from '../../../../models/data-entities';
import { WorkflowApplication } from '../../../../models';
import {
  Activity,
  ActivityModel,
  DataEntityLayoutModel
} from '../../../../models/activities';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'wm-data-entity-view-list',
  templateUrl: './data-entity-view-list.component.html',
  styleUrls: ['./data-entity-view-list.component.css']
})
export class DataEntityViewListComponent implements OnInit {
  @Input() entities: DataEntityLayoutModel[];
  @Input() applicationId: string;
  @Input() activity: Activity<ActivityModel>;
  @Input() form: UntypedFormGroup;

  constructor() {}
  ngOnInit() {}
}

import { Injectable, Inject, forwardRef } from '@angular/core';
import { Observable } from 'rxjs';
import { MapServer } from '../models/map-server';
import { MapService } from '../models/map-service';
import { MapLayer } from '../models/map-layer';
import { DataService } from '.';

@Injectable()
export class MapServiceProvider {
  constructor(
    @Inject(forwardRef(() => DataService)) private _dataService: DataService
  ) {}

  deleteMapService(mapService: MapService): Observable<boolean> {
    return this._dataService.deleteMapService(mapService);
  }
  createMapService(): MapService {
    return new MapService();
  }
  getMapServers(): Observable<MapServer[]> {
    return null;
  }
  getMapLayers(endpointUrl: string): Observable<MapLayer[]> {
    return this._dataService.getMapLayers(endpointUrl);
  }
  getMapLayerFields(url: string, layerName: string): Observable<string[]> {
    return null;
  }
  isFieldUniqueInLayer(
    mapLayerIndexItemId: string,
    fieldName: string
  ): Observable<boolean> {
    return this._dataService.isFieldUniqueInLayer(
      mapLayerIndexItemId,
      fieldName
    );
  }
}

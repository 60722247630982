<button
  class="btn btn-raised btn-primary"
  type="button"
  (click)="launchChild()"
>
  <span *ngIf="initiateActivity.status !== ActivityStatus.Completed"
    >Open {{ workflowName }}</span
  >
  <span *ngIf="initiateActivity.status === ActivityStatus.Completed">
    Open Summary
  </span>
</button>

<div class="container">
  <h3>Invite User</h3>

  <form [formGroup]="form" *ngIf="!response">
    <div class="form-group">
      <label class="bmd-label-floating" for="email"
        >Email <span class="text-red">*</span></label
      >
      <input
        type="text"
        formControlName="email"
        [(ngModel)]="email"
        class="form-control"
        name="email"
      />
      <wm-control-message
        [control]="form.controls['email']"
      ></wm-control-message>
    </div>
    <div class="card" *ngIf="canManageRoles">
      <div class="card-body">
        <div class="card-title"><h5>Add User to following Role(s)</h5></div>
        <div class="row" *ngFor="let r of availableRoles">
          <div class="col">
            <div class="form-group align-middle">
              <input
                type="checkbox"
                [checked]="isRoleSelected(r.id)"
                (change)="toggleRole($event)"
                [value]="r.id"
                class="mr-2"
              />
              {{ r.name }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col">
        <button
          class="btn btn-default btn-raised mr-3"
          type="button"
          [routerLink]="['../']"
        >
          Cancel
        </button>
        <button
          class="btn btn-primary btn-raised "
          type="button"
          (click)="inviteUser()"
        >
          Invite
        </button>
      </div>
    </div>
  </form>
  <div *ngIf="response">
    <div *ngIf="response.existingUsers">
      <div>{{ response.resultMessage }}</div>
      <a
        [routerLink]="['../../list/']"
        [queryParams]="{ searchText: this.email }"
        *ngIf="canManageUsers"
        >Go to User List</a
      >
      <a
        [routerLink]="['../../roles']"
        *ngIf="!canManageUsers && canManageRoles"
        >Go to Role List</a
      >
    </div>
    <div *ngIf="!response.resultMessage">
      An invitation to use GeoPermits has been sent to {{ this.email }}.
    </div>
    <div class="mt-1">
      <a href="javascript:void()" (click)="inviteAnotherUser()"
        >Invite another user</a
      >
    </div>
  </div>
</div>

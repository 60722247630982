import {
  Component,
  OnInit,
  Input,
  ComponentFactoryResolver
} from '@angular/core';
import { YesNoDataEntity } from 'src/app/models/data-entities';
import { DataEntityInputComponent } from '../../data-entity-input/data-entity-input.component';
import { UntypedFormBuilder } from '@angular/forms';

@Component({
  selector: 'wm-yes-no-data-entity-input',
  templateUrl: './yes-no-data-entity-input.component.html',
  styleUrls: ['./yes-no-data-entity-input.component.css']
})
export class YesNoDataEntityInputComponent extends DataEntityInputComponent
  implements OnInit {
  @Input() entity: YesNoDataEntity;
  editMode: boolean;
  originalValue: any;
  selectedEntityValues: string[];
  checkBoxValue: boolean;

  constructor(
    componentFactoryResolver: ComponentFactoryResolver,
    private _fb: UntypedFormBuilder
  ) {
    super(componentFactoryResolver);
    this.editMode = false;
  }

  ngOnInit() {}

  selectChange() {
    // the checkbox only passes true/false - convert to yes/no
    if (this.checkBoxValue === true) {
      this.entity.value = 'Yes';
    }
    if (this.checkBoxValue === false) {
      this.entity.value = 'No';
    }
  }
}

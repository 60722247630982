import {
  ScheduleEmailActivity,
  ScheduleEmailActivityModel
} from './../../../../../models/activities/schedule-email-activity';
import { ActivityView } from './../../../../../views/master-views/app.view/app.view.component';
import { Component, forwardRef, Inject, OnInit } from '@angular/core';
import { WorkflowService } from 'src/app/services';

@Component({
  selector: 'wm-schedule-email-activity-view',
  templateUrl: './schedule-email-activity-view.component.html',
  styleUrls: ['./schedule-email-activity-view.component.css']
})
export class ScheduleEmailActivityViewComponent extends ActivityView
  implements OnInit {
  activity: ScheduleEmailActivity;
  scheduleModel: ScheduleEmailActivityModel;

  constructor(
    @Inject(forwardRef(() => WorkflowService))
    public _workflowSvc: WorkflowService
  ) {
    super();
  }

  isVersionMode(): boolean {
    return this._workflowSvc.designerVersionMode;
  }

  ngOnInit() {
    if (this.activity) {
      this.scheduleModel = this.activity.model as ScheduleEmailActivityModel;
    }
  }
}

import { ActivityStatus } from './../../../models/activities/activity';
import { RichTextDataEntity } from 'src/app/models/data-entities/rich-text-data-entity';
import { Activity, ActivityModel } from 'src/app/models/activities';
import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import {
  SecurityService,
  ActivityFactory,
  DataEntityFactory
} from 'src/app/services';
import { Actions, WorkflowApplication } from 'src/app/models';

@Component({
  selector: 'wm-application-print-summary',
  templateUrl: './application-print-summary.component.html',
  styleUrls: ['./application-print-summary.component.css']
})
export class ApplicationPrintSummaryComponent implements OnInit {
  @Input() activities: Activity<ActivityModel>[];
  form: UntypedFormGroup;
  @Input() application: WorkflowApplication;
  externalNotesDE: RichTextDataEntity;
  internalNotesDE: RichTextDataEntity;
  canViewInternalNotes: boolean;

  ActivityStatus = ActivityStatus;

  constructor(private _securitySvc: SecurityService) {}

  ngOnInit() {
    this.form = new UntypedFormGroup({});
    this.internalNotesDE = new RichTextDataEntity({
      value: this.application.internalNotes,
      templateCode: 'internalNotes'
    });
    this.externalNotesDE = new RichTextDataEntity({
      value: this.application.externalNotes,
      templateCode: 'externalNotes'
    });

    this._securitySvc
      .isLoginEntitled(Actions.WORKFLOW_APPLICATIONS_EDIT)
      .subscribe(result => {
        this.canViewInternalNotes = result;
      });
  }
}

<app-navbar></app-navbar>

<div class="container mt-4" style="padding-top: 56px;">
  <app-loader *ngIf="!user"></app-loader>
  <div *ngIf="user">
    <div class="row">
      <div class="col">
        <h3>My Account</h3>
      </div>
      <div class="col">
        <button
          class="btn btn-raised btn-success float-right"
          (click)="save()"
          [disabled]="loading"
        >
          <span
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
            *ngIf="loading"
          ></span>
          Save
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="card mt-4 mb-4">
          <div class="card-body">
            <h6 class="card-title">Profile</h6>
            <div class="form-group">
              <label for="fullName" class="bmd-label-floating">Full Name</label>
              <input
                type="text"
                class="form-control"
                [(ngModel)]="user.name"
                name="fullName"
              />
            </div>

            <div class="form-group">
              <label for="userName" class="bmd-label-floating">User Name</label>
              <input
                type="text"
                class="form-control"
                [(ngModel)]="user.userName"
                name="userName"
              />
            </div>

            <div class="form-group">
              <button
                class="btn btn-primary btn-sm btn-block"
                (click)="showChangePassword = !showChangePassword"
                role="button"
                aria-expanded="false"
                aria-controls="changePwdCollapse"
              >
                Change Password
              </button>
              <div id="changePwdCollapse" *ngIf="showChangePassword">
                <div class="form-group">
                  <label for="oldPassword" class="bmd-label-floating">
                    Old Password
                  </label>
                  <input
                    type="password"
                    class="form-control"
                    [(ngModel)]="oldPassword"
                    name="oldPassword"
                  />
                </div>
                <div class="form-group">
                  <label for="newPassword" class="bmd-label-floating">
                    New Password
                  </label>
                  <input
                    type="password"
                    class="form-control"
                    [(ngModel)]="newPassword"
                    name="newPassword"
                  />
                </div>
                <div class="form-group">
                  <label for="newPasswordConfirmed" class="bmd-label-floating">
                    Confirm Password
                  </label>
                  <input
                    type="password"
                    class="form-control"
                    [(ngModel)]="newPasswordConfirmed"
                    name="newPasswordConfirmed"
                  />
                </div>

                <button
                  class="btn btn-raised btn-success float-right"
                  (click)="changePassword()"
                  [disabled]="loading"
                >
                  <span
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                    *ngIf="loading"
                  ></span>
                  Save Password
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="card mt-4 mb-4">
          <div class="card-body">
            <h6 class="card-title">Contact Info</h6>

            <div class="form-group">
              <label for="emailAddress" class="bmd-label-floating"
                >Email Address</label
              >
              <input
                type="text"
                class="form-control"
                [(ngModel)]="user.emailAddress"
                name="emailAddress"
              />
            </div>

            <div class="form-group">
              <label for="phoneNumber" class="bmd-label-floating"
                >Phone Number</label
              >
              <input
                type="text"
                class="form-control"
                [(ngModel)]="user.phoneNumber"
                name="phoneNumber"
              />
            </div>

            <!--<div class="row">
          <div class="col-sm-12">
            <h3>Email Address</h3>
            <div class="form-group">
              <button class="btn btn-primary btn-sm btn-block" *ngIf="!showNewEmail" (click)="enterNewEmailAddress()">Add new Email Address</button>
            </div>
            <div class="form-group">
              <email-wm-address-detail *ngIf="showNewEmail" (saved)="newEmailAddressSaved($event)" (canceled)="newEmailAddressCanceled($event)"></email-wm-address-detail>
            </div>
            <div class="form-group">
              <wm-email-address-list [emailAddresses]="user.emailAddresses"></wm-email-address-list>
            </div>
          </div>
        </div>-->
          </div>
        </div>

        <div class="card mt-4 mb-4">
          <div class="card-body">
            <h6 class="card-title">Address</h6>

            <div class="form-group">
              <wm-address-detail
                [address]="user.address"
                [showButtons]="false"
                [required]="false"
                [showName]="false"
              ></wm-address-detail>
            </div>
          </div>
        </div>

        <!--<div class="row">
          <div class="col-sm-12">
            <h3>Addresses</h3>
            <div class="form-group">
              <button class="btn btn-primary btn-sm btn-block" *ngIf="!showNewAddress" (click)="enterNewAddress()">Add new Address</button>
            </div>
            <div class="form-group">
              <wm-address-detail *ngIf="showNewAddress" (saved)="newAddressSaved($event)" (canceled)="newAddressCanceled($event)"></wm-address-detail>
            </div>
            <div class="form-group">
              <wm-address-list [addresses]="user.addresses"></wm-address-list>
            </div>
          </div>
        </div>-->
        <!--<div class="row">
          <div class="col-sm-12">
            <h3>Phone Numbers</h3>
            <div class="form-group">
              <button class="btn btn-primary btn-sm btn-block" *ngIf="!showNewPhoneNumber" (click)="enterNewPhoneNumber()">Add new Phone Number</button>
            </div>
            <div class="form-group">
              <wm-phone-number-detail *ngIf="showNewPhoneNumber" (saved)="newPhoneNumberSaved($event)" (canceled)="newPhoneNumberCanceled($event)"></wm-phone-number-detail>
            </div>
            <div class="form-group">
              <wm-phone-list [phoneNumbers]="user.phoneNumbers"></wm-phone-list>
            </div>
          </div>
        </div>-->
      </div>
    </div>
    <div class="row mb-4">
      <div class="col">
        <button
          class="btn btn-raised btn-success float-right"
          (click)="save()"
          [disabled]="loading"
        >
          <span
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
            *ngIf="loading"
          ></span>
          Save
        </button>
      </div>
    </div>
  </div>
</div>

import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { Activity, ActivityModel } from '../../../models/activities';
import { FeeDataEntity } from '../../../models/data-entities';

@Component({
  selector: 'wm-workflow-application-fee-summary',
  templateUrl: './workflow-application-fee-summary.component.html',
  styleUrls: ['./workflow-application-fee-summary.component.css']
})
export class WorkflowApplicationFeeSummaryComponent
  implements OnInit, OnChanges {
  @Input() applicationId: string;
  @Input() currentActivity: Activity<ActivityModel>;

  @Input() fees: FeeDataEntity[];
  displayFees: { label: string; value: number }[];

  totalFees: number;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['applicationId'] || changes['currentActivity']) {
      this.loadFees();
    }
  }

  loadFees() {
    this.displayFees = [];

    // total the fees
    let total = 0;

    for (let x = 0; x < this.fees.length; x++) {
      const f: FeeDataEntity = this.fees[x];

      this.displayFees.push({
        label: f.label,
        value: isNaN(parseFloat(f.formulaValue))
          ? 0
          : parseFloat(f.formulaValue)
      });

      total +=
        parseFloat(f && f.formulaValue && f.formulaValue.toString()) || 0;
    }

    this.totalFees = total;
  }

  ngOnInit() {
    this.loadFees();
  }
}

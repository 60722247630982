import { IDateValidator } from '../../date-validators';
import { SpecifiedDateOptions } from '../specific-date-validator';

export class SpecificDateValidator implements IDateValidator {
  validateDate(date: Date, options: SpecifiedDateOptions): boolean {
    throw new DOMException();
  }
  validateMinDate(date: Date, options: SpecifiedDateOptions): boolean {
    throw new DOMException();
  }
  validateMaxDate(date: Date, options: SpecifiedDateOptions): boolean {
    throw new DOMException();
  }
  calculateExample(date: Date, options: SpecifiedDateOptions): Date {
    return options.specifiedDate;
  }
}

<ng-template #popContent>
  <select
    class="form-control"
    (change)="addPermission(selectedActionId)"
    [(ngModel)]="selectedActionId"
    placeholder="Select a permission"
  >
    <option *ngFor="let a of allActions" [ngValue]="a.id">
      {{ a.name }}
    </option>
  </select>
</ng-template>

<form [formGroup]="form">
  <h3>Role Settings</h3>
  <app-loader *ngIf="!role"></app-loader>

  <div *ngIf="role">
    <div class="form-group">
      <label class="bmd-label-floating" for="roleName">Role Name</label>
      <input
        type="text"
        class="form-control"
        [(ngModel)]="role.name"
        formControlName="roleName"
        name="roleName"
      />
      <wm-control-message
        [control]="form.controls['roleName']"
      ></wm-control-message>
    </div>
    <div class="row">
      <div class="col">
        <div class="form-group">
          <label class="bmd-label-floating" for="roleColor">Role Color</label>

          <select
            class="form-control"
            name="roleColor"
            id="roleColor"
            [(ngModel)]="role.color"
            formControlName="roleColor"
          >
            <option [ngValue]="undefined">
              none
            </option>
            <option *ngFor="let rc of roleColors" [ngValue]="rc">
              {{ rc }}
            </option>
          </select>
          <wm-control-message
            [control]="form.controls['roleColor']"
          ></wm-control-message>
        </div>
      </div>
      <div class="col-3">
        <div
          class="card card-activity"
          [ngClass]="colorPreviewClass(role?.color)"
          style="min-height: 65px;"
        >
          <div class="card-body">
            <h5 class="card-title">
              Color Preview
            </h5>
            <p class="card-text text-center">
              Activity - <span class="font-weight-bold">{{ role.name }}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="role">
    <div class="col-md-6 col-lg-4 mt-3" *ngIf="roleId">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">Members</h5>

          <wm-associate-user (userAssociated)="associateUser($event)">
          </wm-associate-user>

          <wm-role-member-list
            [members]="role.members"
            (removed)="removeUser($event)"
          >
          </wm-role-member-list>
        </div>
      </div>
    </div>
    <div class="col-md-6 col-lg-4 mt-3" *ngIf="!workflowsLoaded">
      <app-loader></app-loader>
    </div>
    <div class="col-md-6 col-lg-4 mt-3" *ngIf="roleId">
      <div *ngIf="workflowsLoaded">
        <div class="card">
          <div class="card-body" *ngIf="allActions">
            <wm-permission-list
              #permissionList
              *ngIf="roleActions"
              [allActions]="allActions"
              [actions]="roleActions"
              [showAdd]="false"
              [showRemove]="true"
              (removed)="removePermission($event)"
              [canRemove]="canRemoveAction.bind(this)"
              [removalProtectedActions]="removalProtectedActions"
              [occupiedWorkflows]="occupiedWorkflows"
              [cannotEditPermissions]="cannotEditPermissions"
              [removalProtectedAdminActions]="removalProtectedAdminActions"
              [adminWorkflows]="adminWorkflows"
            >
            </wm-permission-list>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-6 col-lg-4 mt-3" *ngIf="occupiedWorkflows?.length > 0">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">Role Dependencies</h5>
          <p class="card-text">
            The role can't be deleted unless the role is no longer in use in the
            following areas.
          </p>
          <ul class="list-group">
            <li
              class="list-group-item"
              *ngFor="let workflow of occupiedWorkflows"
            >
              {{ workflow }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <div class="row mt-3">
    <div class="col">
      <button
        type="button"
        class="btn btn-raised btn-success"
        (click)="save()"
        [disabled]="!canSave"
      >
        Save
      </button>
    </div>

    <div class="col">
      <div class="float-right">
        <wm-modal-confirm
          *ngIf="role && canRemove && roleId"
          [commandText]="'Delete Role'"
          [moduleId]="'deleteRoleModal'"
          [showButtonText]="true"
          [title]="'Delete Role'"
          (accepted)="deleteRole()"
          btnColor="danger"
          class="mr-1"
          [enabled]="occupiedWorkflows?.length === 0"
        >
          <p>Are you sure you want to disable this role ({{ role.name }})?</p>
          <p>Deleting this role will do the following:</p>
          <ul>
            <li>
              Remove all of the permissions that were granted by this role from
              the users that are a members.
            </li>
          </ul>
        </wm-modal-confirm>
        <button
          type="button"
          class="btn btn-raised btn-default"
          (click)="backToList()"
        >
          Cancel
        </button>
      </div>
    </div>
  </div>
</form>

import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Action } from '../../../models';

@Component({
  selector: 'wm-permission-list',
  templateUrl: './permission-list.component.html',
  styleUrls: ['./permission-list.component.css']
})
export class PermissionListComponent implements OnInit {
  @Input() allActions: Action[];
  @Input() actions: Action[];
  @Input() showAdd = true;
  @Input() showRemove = false;
  @Input() removalProtectedActions: string[];
  @Input() removalProtectedAdminActions: string[];
  @Input() occupiedWorkflows: string[];
  @Input() adminWorkflows: string[];
  @Input() cannotEditPermissions = false;
  @Output() added: EventEmitter<string> = new EventEmitter<string>();
  @Output() removed: EventEmitter<{
    actionId: string;
    securableId: string;
  }> = new EventEmitter<{ actionId: string; securableId: string }>();
  @Input() canRemove: (id: string) => false;
  checkedItems: { [key: string]: boolean } = {};
  tooltipMessage: { [key: string]: string } = {};
  selectAllValue = false;
  constructor() {}
  keysCount = 0;
  selectedActionIds: string[];

  ngOnInit() {
    if (this.allActions) {
      this.allActions.forEach(b => {
        this.checkedItems[b.id] =
          this.actions.filter(a => a.id === b.id).length > 0;
      });
      this.allActions.forEach(b => {
        this.tooltipMessage[b.id] = this.getTooltipMessage(b);
      });
      this.updateSelectedActionProperties(null);
      // occupiedWorkflows will have an item stating the role is the client admin role.
      // in this context, we don't need that message.
      this.occupiedWorkflows = this.occupiedWorkflows.filter(
        owf => owf !== 'Role is defined as the Client Administrator Role.'
      );
    }
  }

  private updateSelectedActionProperties(action) {
    // update the number of actions selected for the UI.
    this.keysCount = this.getSelectedActions().length;
    // update the tooltip message of whatever action got selected or unselected.
    if (action) {
      this.tooltipMessage[action.id] = this.getTooltipMessage(action);
    }
  }

  private getSelectedActions() {
    this.selectedActionIds = Object.keys(this.checkedItems).filter(k => {
      return this.checkedItems[k] === true;
    });
    return this.selectedActionIds;
  }

  isDisabled(action) {
    return (
      this.hasSecurables(action) ||
      this.isAdministratorOnOtherWorkflows(action) ||
      (this.isAdministratorOfWorkflow(action) && this.checkedItems[action.id] === true) ||
      this.cannotEditPermissions
    );
  }

  hasSecurables(action) {
    return (
      action.permissions &&
      action.permissions.length > 0 &&
      !this.canRemove(action.id)
    );
  }

  isAdministratorOfWorkflow(action) {
    if (
      this.removalProtectedAdminActions.includes(action.id.toUpperCase()) &&
      this.adminWorkflows &&
      this.adminWorkflows.length > 0
    ) {
      return true;
    }
    return false;
  }

  isAdministratorOnOtherWorkflows(action) {
    if (
      this.removalProtectedActions.includes(action.id.toUpperCase()) &&
      this.occupiedWorkflows &&
      this.occupiedWorkflows.length > 0
    ) {
      return true;
    }
    return false;
  }

  getTooltipMessage(action) {
    if (this.hasSecurables(action)) {
      return 'Cannot remove because this role has permissible items.';
    }

    if (
      this.removalProtectedActions.includes(action.id.toUpperCase()) &&
      this.occupiedWorkflows &&
      this.occupiedWorkflows.length > 0
    ) {
      return (
        'Cannot remove permission to ' +
        action.name +
        ' because this role is responsible for activities in other workflows.'
      );
    }

    if (this.isAdministratorOfWorkflow(action)) {
      return (
        'Cannot remove permission to ' +
        action.name +
        ' because this role is the admin role for a workflow.'
      );
    }

    if (this.cannotEditPermissions) {
      return 'Only a global administrator can remove permissions from a Client Admin role.';
    }

    if (
      !(
        this.removalProtectedActions.includes(action.id.toUpperCase()) &&
        this.occupiedWorkflows &&
        this.occupiedWorkflows.length > 0
      )
    ) {
      return (
        (this.checkedItems[action.id] === true
          ? 'Uncheck to remove this permission from all members.'
          : 'disabled') + ''
      );
    }

    return action.name;
  }

  toggleAllAvailableSelectionBoxes() {
    Object.keys(this.checkedItems).forEach(key => {
      if (
        !this.removalProtectedActions.includes(key.toUpperCase()) &&
        this.canRemove(key)
      ) {
        this.checkedItems[key] = this.selectAllValue;
      }
    });
    // update the messages and of number of selected items property.
    this.updateSelectedActionProperties(null);

    // update the message for all the items (not just the selected ones).
    this.allActions.forEach(b => {
      this.tooltipMessage[b.id] = this.getTooltipMessage(b);
    });
  }
}

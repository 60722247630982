<app-loader *ngIf="loading"></app-loader>
<div *ngIf="dataSet">
  <wm-filter-builder
    #dsFilterCtrl
    *ngIf="dsFilterParams"
    [id]="dataSet.id + '|input-filter'"
    [params]="dsFilterParams"
    [showFilterBuilder]="true"
    [showSimpleSearch]="false"
    [waitForParent]="false"
    [onlyExportOptions]="true"
    [columnOptions]="columns"
    (filtersChange2)="dsFilterChanged($event)"
    (exportColumnsChange)="dsColumnsChanged($event)"
    [shapefileValidationCriteria]="shapefileValidationCriteria"
  ></wm-filter-builder>
  <ng-container *ngIf="requiredFilterInput">
    <wm-control-message
      *ngFor="let f of requiredFilterInput"
      [prefix]="(f.label || f.name) + ' Filter is '"
      [control]="reqGrp.controls[f.name]"
    ></wm-control-message>
  </ng-container>
</div>

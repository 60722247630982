export class PhoneNumber {
  id: string;
  name: string;
  number: string;
  parent: any;
  parentID: string;
  parentType: string;
}

export class UserPhoneNumber extends PhoneNumber {
  constructor(options?: Partial<ContractorPhoneNumber>) {
    super();
    this.name = 'Default';
    this.parentType = 'User';
  }
  if(options) {
    Object.assign(this, options);
  }
}

export class ContractorPhoneNumber extends PhoneNumber {
  constructor(options?: Partial<ContractorPhoneNumber>) {
    super();
    this.name = 'Default';
    this.parentType = 'Contractor';

    if (options) {
      Object.assign(this, options);
    }
  }
}

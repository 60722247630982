import { ActivityView } from 'src/app/views/master-views/app.view/app.view.component';
import { Component, OnInit } from '@angular/core';
import { CallWorkflowActivity } from 'src/app/models/activities/call-workflow-activity';
import { Router } from '@angular/router';

@Component({
  selector: 'wm-call-workflow-view',
  templateUrl: './call-workflow-view.component.html',
  styleUrls: ['./call-workflow-view.component.css']
})
export class CallWorkflowViewComponent extends ActivityView implements OnInit {
  callActivity: CallWorkflowActivity;

  constructor(private router: Router) {
    super();
  }

  launchChildSummary() {
    if (this.callActivity.model.callingWorkflowApplicationId) {
      this.router.navigate([
        '/application/workflow-application-summary',
        this.callActivity.model.callingWorkflowApplicationId
      ]);
    }
  }

  ngOnInit() {
    this.callActivity = this.activity as CallWorkflowActivity;
  }
}

import { ConditionItem } from '../components/system/condition-builder/condition-builder.model';
import { Utilities } from '../services';
import {
  DataSetField,
  FilterConfig,
  WorkflowFilter,
  ReportDataSetFieldDataType
} from './custom-report';
import {
  DataEntity,
  DateDataEntity,
  FeeDataEntity,
  FreeFormTextDataEntity,
  ListDataEntity,
  NumericDataEntity
} from './data-entities';
import { SystemDataEntity } from './data-entities/system-data-entity';
import { TodayDataEntity } from './data-entities/today-data-entity';

export enum SortDirection {
  Ascending = 1,
  Descending = 2
}

export class ReportDataSetOrder {
  fieldName: string;
  direction: SortDirection;
  ordinal: number;
  constructor(options?: Partial<ReportDataSetOrder>) {
    if (options) {
      Object.assign(this, options);
    }
  }
}

export enum AggregateFunctions {
  Sum = 1,
  Count = 2,
  Max = 3,
  First = 4
}

export class AggregatedDataSetFieldTypes {
  static groupKeyField = 'keyField';
  static calculatedField = 'calculatedField';
  static staticField = 'staticField';
}

export class AggregatedDataSetRowTypes {
  static groupingRow = 'groupingRow';
  static groupingCriteriaRow = 'groupingCriteriaRow';
}

export class DataSetAggregateField {
  headerText: string;
  name: string;
  dataType: ReportDataSetFieldDataType;
  type: string;
  init?(): void {}
}

export class DataSetAggregateKeyField extends DataSetAggregateField {
  keyField: string;
  constructor(options?: Partial<DataSetAggregateKeyField>) {
    super();
    this.type = AggregatedDataSetFieldTypes.groupKeyField;

    if (options) {
      Object.assign(this, options);
    }
  }
}

export class DataSetAggregateCalculatedField extends DataSetAggregateField {
  function: AggregateFunctions;
  criteriaGroup: ConditionItem;
  fieldName: string;
  constructor(options?: Partial<DataSetAggregateCalculatedField>) {
    super();
    this.type = AggregatedDataSetFieldTypes.calculatedField;

    if (options) {
      Object.assign(this, options);
    }

    this.init();
  }

  init(): void {
    if (!this.criteriaGroup) {
      if (!this.criteriaGroup) {
        this.criteriaGroup = new ConditionItem({
          isGroup: true,
          id: Utilities.generateId(),
          conditions: []
        });
      }
    }
  }
}

export class DataSetAggregateStaticField extends DataSetAggregateField {
  output: string;
  constructor(options?: Partial<DataSetAggregateStaticField>) {
    super();
    this.type = AggregatedDataSetFieldTypes.staticField;

    if (options) {
      Object.assign(this, options);
    }
  }
}

export class DataSetAggregateRow {
  fields: DataSetAggregateField[];
  type: string;
  constructor() {
    this.fields = [];
  }
  init?(): void {}
}

export class DataSetAggregrateGroupingRow extends DataSetAggregateRow {
  groupByFields: string[];
  sortFields: ReportDataSetOrder[];

  constructor(options?: Partial<DataSetAggregrateGroupingRow>) {
    super();
    this.type = AggregatedDataSetRowTypes.groupingRow;
    this.groupByFields = [];
    this.sortFields = this.sortFields || [];

    if (options) {
      Object.assign(this, options);
    }
  }
}

export class DataSetAggregateGroupedCriteriaRow extends DataSetAggregrateGroupingRow {
  criteriaGroup: ConditionItem;
  constructor(options?: Partial<DataSetAggregateGroupedCriteriaRow>) {
    super();
    this.type = AggregatedDataSetRowTypes.groupingCriteriaRow;

    if (options) {
      Object.assign(this, options);
    }

    this.init();
  }

  init(): void {
    if (!this.criteriaGroup) {
      if (!this.criteriaGroup) {
        this.criteriaGroup = new ConditionItem({
          isGroup: true,
          id: Utilities.generateId(),
          conditions: []
        });
      }
    }
  }
}

export class AggregatedDataSet {
  name: string;
  rows: DataSetAggregateRow[];
  fieldOrder: ReportDataSetOrder[];
  constructor() {
    this.rows = [];
    this.fieldOrder = [];
  }
}

export class ReportDataSet {
  id: string;
  fields: DataSetField[];
  fieldOrder: ReportDataSetOrder[];
  type: string;
  outputDataSet: string;
  aggregatedDataSets: AggregatedDataSet[];

  constructor() {
    this.fields = [];
    this.fieldOrder = [];
    this.aggregatedDataSets = [];
    this.outputDataSet = '';
  }
}

export class WorkflowApplicationReportDataSet extends ReportDataSet {
  workflowIds: string[];
  clientIds: string[];
  workflowFilters: WorkflowFilter[];
  constructor() {
    super();
    this.type = 'workflow-application-dataset';
    this.workflowIds = [];
    this.workflowFilters = [];
  }
}

export class DataSet {
  id: string;
  description: string;
  clientId: string;
  name: string;
  publishedOnString: string;
  dataSetConfig: WorkflowApplicationReportDataSet;
  filterConfig: FilterConfig;
  publishedDataSetConfig: WorkflowApplicationReportDataSet;
  publishedFilterConfig: FilterConfig;
  canEdit: boolean;
  hasCustomReports: boolean;

  static resolveInputType(entity: DataEntity): string {
    if (entity instanceof DateDataEntity || entity instanceof TodayDataEntity) {
      return 'date';
    } else if (
      entity instanceof NumericDataEntity ||
      entity instanceof FeeDataEntity
    ) {
      return 'number';
    } else if (entity instanceof ListDataEntity) {
      return 'list';
    } else {
      return 'text';
    }
  }

  static resolveOptions(entity: DataEntity): string[] {
    const pv = entity.getPossibleValues();
    if ((pv || []).length > 0) {
      return pv.map(mpv => mpv.value);
    } else {
      return null;
    }
  }

  static resolveInputTypeForField(field: DataSetField): string {
    switch (field.dataType) {
      case ReportDataSetFieldDataType.Date:
        return 'date';
      case ReportDataSetFieldDataType.Decimal:
      case ReportDataSetFieldDataType.Integer:
        return 'number';
      default:
        return 'text';
    }
  }

  static resolveOptionsForField(field: DataSetField): string[] {
    if (field.availableFilterItems) {
      return field.availableFilterItems.map(i => i.value);
    } else {
      return null;
    }
  }

  static resolveTypesForField(field: DataSetField): string[] {
    const types = ['is', 'is not', 'exists', 'not exists'];

    const pv = DataSet.resolveOptionsForField(field);

    if ((pv || []).length > 0) {
      types.push('in');
    }

    if (field.dataType === ReportDataSetFieldDataType.String) {
      types.push('contains');
      types.push('not contains');
    }

    if (field.dataType !== ReportDataSetFieldDataType.String) {
      types.push('range');
      types.push('<');
      types.push('>');
      types.push('<=');
      types.push('>=');
    }

    return types;
  }

  static resolveTypes(entity: DataEntity): string[] {
    const types = ['is', 'is not', 'exists', 'not exists'];

    const pv = DataSet.resolveOptions(entity);

    if ((pv || []).length > 0) {
      types.push('in');
    }

    if (
      entity instanceof FreeFormTextDataEntity ||
      (entity instanceof SystemDataEntity &&
        (<SystemDataEntity>entity).dataType.indexOf('String') > -1)
    ) {
      types.push('contains');
      types.push('not contains');
    }

    if (
      entity instanceof NumericDataEntity ||
      entity instanceof FeeDataEntity ||
      entity instanceof DateDataEntity ||
      entity instanceof TodayDataEntity
    ) {
      types.push('range');
      types.push('<');
      types.push('>');
      types.push('<=');
      types.push('>=');
    }

    return types;
  }
}

import {
  ActivityModel,
  ScreenActivity,
  WaitCriteria
} from '../../models/activities';
import { Activity } from './activity';

export class WaitActivityModel extends ActivityModel {
  activities: Activity<ActivityModel>[];

  constructor(options?: Partial<WaitActivityModel>) {
    super(options);

    if (options) {
      Object.assign(this, options);
    }
  }
}

export class WaitActivity extends ScreenActivity<WaitActivityModel> {
  constructor(options?: Partial<WaitActivity>) {
    super(options);

    if (options) {
      Object.assign(this, options);
    }

    this.model = new WaitActivityModel(<WaitActivityModel>(
      (options ? options.model : null)
    ));
  }
}

import { PaymentProcessorAccountConfigurationBase } from '../payment-processor-account-configuration-base';

export class PayGovPaymentProcessorAccountConfiguration extends PaymentProcessorAccountConfigurationBase {
  duplicateNumber: string;
  ttid: string;

  constructor(options?: Partial<PayGovPaymentProcessorAccountConfiguration>) {
    super(options);
    if (options) {
      Object.assign(this, options);
    }
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivityView } from '../../../../../views/master-views/app.view/app.view.component';
import { InspectionSimpleActivity } from 'src/app/models/activities/inspection-simple-activity';
import { FormActivityInputViewComponent } from '../../form-activity-editor/form-activity-input/form-activity-input-view/form-activity-input-view.component';

@Component({
  selector: 'wm-inspection-simple-activity-input',
  templateUrl: './inspection-simple-activity-input.component.html',
  styleUrls: ['./inspection-simple-activity-input.component.css']
})
export class InspectionSimpleActivityInputComponent extends ActivityView
  implements OnInit {
  activity: InspectionSimpleActivity;

  @ViewChild('formActivityInputView', { static: false })
  formActivityInputViewCtl: FormActivityInputViewComponent; 

  constructor() {
    super();
  }

  ngOnInit() {
    if (this.form.controls['INSP_SELECTED_INSPECTOR']) {
      this.form.controls['INSP_SELECTED_INSPECTOR'].valueChanges.subscribe(
        selectedValue => {}
      );
    }
  }

  async persistChildComponent() {
    return this.formActivityInputViewCtl.persistChildComponent();
  }
}

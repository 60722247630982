import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'wm-not-found',
  templateUrl: './notfound.component.html',
  styleUrls: ['./notfound.component.css']
})
export class NotFoundComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}

import { DataEntity } from './data-entity';
import { Type } from '@angular/core';
import { ValidationService } from '../../services';
import { fabric } from 'fabric';
import { Validators, ValidatorFn } from '@angular/forms';
import { TemplateField } from '../activities';

export enum RestrictedFormat {
  None = 3,
  Email = 0,
  PhoneNumber = 1
  // Custom = 2,
}

export abstract class RestrictedFormatHandler {
  abstract isValid(value: string): boolean;
  abstract getValidator(): any;
  abstract getRestrictionMask(): string;
}

export class EmailFormatHandler extends RestrictedFormatHandler {
  constructor(options?: EmailFormatHandler) {
    super();
    if (options) {
      Object.assign(this, options);
    }
  }

  isValid(value: string): boolean {
    if ((value || '') !== '') {
      return ValidationService.validateEmail(value);
    } else {
      return true;
    }
  }
  getValidator(): any {
    return Validators.email;
  }
  getRestrictionMask(): string {
    return '';
  }
}

export class PhoneNumberFormatHandler extends RestrictedFormatHandler {
  constructor(options?: PhoneNumberFormatHandler) {
    super();

    if (options) {
      Object.assign(this, options);
    }
  }

  isValid(value: string): boolean {
    if ((value || '') !== '') {
      return ValidationService.validatePhoneNumber(value);
    } else {
      return true;
    }
  }
  getValidator(): any {
    return ValidationService.phoneNumberValidator;
  }
  getRestrictionMask(): string {
    return '';
  }
}

export class CustomFormatHandler extends RestrictedFormatHandler {
  customPattern: string;
  customMask: string;

  constructor(options?: CustomFormatHandler) {
    super();
    if (options) {
      Object.assign(this, options);
    }
  }

  isValid(value: string): boolean {
    const exp: RegExp = new RegExp(this.customPattern);

    return exp.test(value);
  }
  getValidator(): any {
    return ValidationService.customPatternValidator(this.customPattern);
  }
  getRestrictionMask(): string {
    return this.customMask || '';
  }
}
export class NoneFormatHandler extends RestrictedFormatHandler {
  constructor(options?: NoneFormatHandler) {
    super();

    if (options) {
      Object.assign(this, options);
    }
  }

  isValid(value: string): boolean {
    return true;
  }
  getValidator(): any {
    return Validators.nullValidator;
  }
  getRestrictionMask(): string {
    return '';
  }
}

export class FreeFormTextDataEntity extends DataEntity {
  minLength?: number;
  maxLength?: number;
  isMultiline?: boolean;
  restrictedFormat: RestrictedFormat = RestrictedFormat.None;

  _handlers: { [id: number]: Type<RestrictedFormatHandler> } = {
    [RestrictedFormat.Email]: EmailFormatHandler,
    [RestrictedFormat.PhoneNumber]: PhoneNumberFormatHandler,
    // [RestrictedFormat.Custom]: CustomFormatHandler,
    [RestrictedFormat.None]: NoneFormatHandler
  };

  get restrictedFormatHandler(): RestrictedFormatHandler {
    if (this.restrictedFormat === null || this.restrictedFormat === undefined) {
      return new NoneFormatHandler();
    }

    const FormatHandler = this._handlers[this.restrictedFormat];

    if (!FormatHandler) {
      return new NoneFormatHandler();
    }

    return new FormatHandler();
  }

  getRestrictionMask(): string {
    if (this.restrictedFormatHandler) {
      return this.restrictedFormatHandler.getRestrictionMask();
    }

    return '';
  }

  getValidators(): ValidatorFn[] {
    const validators = super.getValidators() || [];

    if (this.restrictedFormatHandler) {
      validators.push(this.restrictedFormatHandler.getValidator());
    }

    if ((this.minLength || 0) > 0) {
      validators.push(Validators.minLength(this.minLength));
    }

    if ((this.maxLength || 0) > 0) {
      validators.push(Validators.maxLength(this.maxLength));
    }

    return validators;
  }

  constructor(options?: Partial<FreeFormTextDataEntity>) {
    super(options);

    if (options) {
      Object.assign(this, options);
    }
  }
}

<form [formGroup]="form" *ngIf="config">
  <div class="form-group" *ngIf="showUsername">
    <label class="bmd-label-floating" for="userName">Username</label>
    <input
      type="text"
      class="form-control"
      formControlName="userName"
      name="userName"
      [(ngModel)]="config.userName"
    />
  </div>

  <div class="form-group" *ngIf="showPassword">
    <label class="bmd-label-floating" for="password">Password</label>
    <input
      type="text"
      class="form-control"
      formControlName="password"
      name="password"
      [(ngModel)]="config.password"
    />
  </div>
</form>

<div class="row">
  <div class="col-6">
    <h6>
      Source Activity
    </h6>
    <div *ngIf="sourceActivities && sourceActivities.length > 0">
      <p *ngFor="let a of sourceActivities; let i = index">
        {{ i > 0 ? ',' : '' }}{{ a?.model?.screenName }}
      </p>
    </div>
  </div>
  <div class="col-6">
    <h6>
      Destination Activity
    </h6>
    <p *ngIf="targetActivity">
      {{ targetActivity?.model?.screenName }}
    </p>
  </div>
</div>

<wm-condition-builder
  [conditionSources]="conditionSources"
  [conditions]="conditions"
  [allowEditingElse]="true"
  [thenLabelGetter]="getThenLabel"
  (conditionChanges)="conditionsChanged($event)"
>
  <ng-template let-target="target">
    <label for="then">Then go to</label>
    <select
      class="form-control"
      id="then"
      name="then"
      [(ngModel)]="target.value"
      [ngModelOptions]="{ standalone: true }"
    >
      <option selected value="" disabled>Choose...</option>
      <option
        *ngFor="let activity of workflowActivities"
        [ngValue]="activity.id"
      >
        {{ activity?.model?.screenName }}
      </option>
    </select>
  </ng-template>
</wm-condition-builder>

import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ModalConfirmComponent } from 'src/app/components/system/modal-confirm/modal-confirm.component';
import { Agenda, AgendaQueueItem } from 'src/app/models/agenda';
import {
  DataService,
  SecurityService,
  Utilities,
  WorkflowContextService
} from 'src/app/services';
import { UntypedFormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { Actions } from 'src/app/models';

@Component({
  selector: 'wm-agenda-view',
  templateUrl: './agenda-view.component.html',
  styleUrls: ['./agenda-view.component.css']
})
export class AgendaViewComponent implements OnInit {
  @ViewChild('printModal', { static: false }) printModal: ModalConfirmComponent;

  agendaId: string;
  agenda: Agenda;
  hasOtherItems = false;
  hasBallots = false;
  otherMinutesForm: UntypedFormGroup;
  disableOtherControls: boolean;
  isSaving = false;

  printRequest = {
    agendaId: Utilities.EMPTY_GUID,
    includeMinutes: false,
    includeStatus: false,
    includeOtherItems: true,
    includeBallots: true,
    ballotsOnly: false
  };

  printing = false;
  printDisabled = false;
  showDownloadLink = false;
  link = document.createElement('a');

  constructor(
    private sanitizer: DomSanitizer,
    private _context: WorkflowContextService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _dataSvc: DataService,
    private _toastr: ToastrService,
    private _securitySvc: SecurityService
  ) {}

  ngOnInit() {
    this.agendaId = this._route.snapshot.params.agendaId;
    this.printRequest.agendaId = this.agendaId;

    this.loadAgenda();
  }

  loadAgenda() {
    this._dataSvc.getAgenda(this.agendaId).subscribe(result => {
      this.agenda = result;

      if (
        this.agenda.agendaQueueItems.filter(
          aqi => aqi.ballotPath && aqi.ballotPath !== ''
        ).length > 0
      ) {
        this.hasBallots = true;
      }

      if (this.agenda.otherItems && this.agenda.otherItems !== '') {
        this.hasOtherItems = true;
      }
    });
  }

  saveAgenda() {
    this.isSaving = true;
    this._dataSvc.saveAgenda(this.agenda).subscribe(() => {
      this._toastr.success('Agenda Saved!');
      this.isSaving = false;
    });
  }

  goToAgendaSettings() {
    this._router.navigate([
      '/admin/jurisdiction',
      this._context.client.id,
      'meetings',
      'agenda-types',
      'agenda-type',
      this.agenda.agendaTypeId,
      'edit-agenda',
      this.agendaId
    ]);
  }

  backToAgendas() {
    this._router.navigate([
      '/admin/jurisdiction',
      this._context.client.id,
      'meetings',
      'agenda-types',
      'agenda-type',
      this.agenda.agendaTypeId
    ]);
  }

  ballotsOnlyChanged() {
    if (this.printRequest.ballotsOnly) {
      this.disableOtherControls = true;
      this.printRequest.includeMinutes = false;
      this.printRequest.includeStatus = false;
      this.printRequest.includeOtherItems = false;
      this.printRequest.includeBallots = false;
    } else {
      this.disableOtherControls = false;
    }
  }

  printAgenda() {
    this.showDownloadLink = false;
    this.printing = true;
    this.printDisabled = true;

    this._dataSvc.printAgenda(this.printRequest).subscribe(response => {
      const blob = new Blob([response], { type: 'application/pdf' });
      this.link.href = window.URL.createObjectURL(blob);
      this.link.download =
        'agenda-' + this.agenda.meetingDateTime.toString() + '.pdf';

      this.printing = false;
      this.printDisabled = false;
      this.showDownloadLink = true;
    });
  }

  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  canManageAgendas(): Observable<boolean> {
    return this._securitySvc.isLoginEntitled(Actions.MANAGE_AGENDAS);
  }
}

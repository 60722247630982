import {
  NumericDataEntity,
  NumericDataType
} from './../../../../../models/data-entities/numeric-data-entity';
import { Component, OnInit, ComponentFactoryResolver } from '@angular/core';
import { DataEntityViewComponent } from '../../data-entity-view/data-entity-view.component';

@Component({
  selector: 'wm-numeric-data-entity-view',
  templateUrl: './numeric-data-entity-view.component.html',
  styleUrls: ['./numeric-data-entity-view.component.css']
})
export class NumericDataEntityViewComponent extends DataEntityViewComponent
  implements OnInit {
  numericDE: NumericDataEntity;
  NumericDataType = NumericDataType;

  constructor(componentFactoryResolver: ComponentFactoryResolver) {
    super(componentFactoryResolver);
  }

  ngOnInit() {
    this.numericDE = this.entity as NumericDataEntity;
    this.numericDE.value = this.numericDE.value || '';
  }
}

import { Component, OnInit, EventEmitter } from '@angular/core';
import { ActivityView } from '../../../../../views/master-views/app.view/app.view.component';
import { WaitActivity } from '../../../../../models/activities/wait-activity';
import { FormGroup } from '@angular/forms';
import { WorkflowContextService } from '../../../../../services';

@Component({
  selector: 'wm-wait-activity-view',
  templateUrl: './wait-activity-view.component.html',
  styleUrls: ['./wait-activity-view.component.css']
})
export class WaitActivityViewComponent extends ActivityView implements OnInit {
  activity: WaitActivity;

  constructor() {
    super();
  }

  ngOnInit() {}
}

<wm-rich-text-data-entity-editor
  *ngIf="notesDE && !readOnly"
  [form]="form"
  [showInsertDataEntities]="false"
  [dataEntity]="notesDE"
  [parentId]="application.id"
>
</wm-rich-text-data-entity-editor>
<div class="card" *ngIf="notesDE && readOnly">
  <div class="card-body">
    <wm-rich-text-data-entity-view
      [entity]="notesDE"
      [parentId]="application.id"
    ></wm-rich-text-data-entity-view>
  </div>
</div>
<wm-document-data-entity-input
  *ngIf="documentsDE && !readOnly && application"
  [form]="form"
  [entity]="documentsDE"
  [applicationId]="application.id"
  [parentEntityId]="'externalDocuments'"
  [deleteStoredDocument]="true"
>
</wm-document-data-entity-input>
<div class="card" *ngIf="documentsDE && readOnly && application">
  <div class="card-body">
    <div class="card-header"><h5>Documents</h5></div>
    <wm-document-data-entity-view
      [form]="form"
      [entity]="documentsDE"
      [parentEntityId]="'externalDocuments'"
      [applicationId]="application.id"
    >
    </wm-document-data-entity-view>
  </div>
</div>

import { Validators } from '@angular/forms';
import { DataEntity } from './data-entity';
import { Contact } from '../contact';

export class ContactListDataEntity extends DataEntity {
  selectedContact: Contact; //don't get stuck. I tried to bind to the object, but when the DataEntityConvert tried to convert it, it was null..
  selectedContactId: string;

  constructor(options?: Partial<ContactListDataEntity>) {
    super(options);

    this.dataEntityTypeCode = 'contact-list';
    this.dataEntityTypeDescription =
      'Use this entity type to select a contact from a list.';
    this.dataEntityTypeName = 'Contact List';
    if (options) {
      Object.assign(this, options);
    }
  }

  getValidators(): any[] {
    return [Validators.nullValidator];
  }
}

<app-navbar></app-navbar>

<div class="container" style="padding-top: 56px;">
  <div class="row">
    <div class="col-md-8 offset-md-2 col-lg-6 offset-lg-3">
      <div class="mt-5 mx-auto">
        <div class="card">
          <div class="card-body">
            <h6>Account Confirmation</h6>
            <p>
              Thank you for confirming your email. Click here to
              <a
                [routerLink]="['/auth/login']"
                [queryParams]="{ returnUrl: returnURL }"
                >Login</a
              >
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

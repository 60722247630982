<div class="mt-4">
  <div>
    <h4>Totals</h4>
    <div class="row" *ngIf="!loading && report">
      <wm-metric
        class="col-sm-4 col-xl-3"
        name="Total"
        [value]="report.totalCount"
      ></wm-metric>
      <wm-metric
        class="col-sm-4 col-xl-3"
        name="Amount"
        [value]="report.totalAmount | currency"
      ></wm-metric>
      <wm-metric
        class="col-sm-4 col-xl-3"
        name="Registrations"
        [value]="report.totalRegistrationCount"
      ></wm-metric>
      <wm-metric
        class="col-sm-4 col-xl-3"
        name="Registrations Amount"
        [value]="report.totalRegistrationAmount | currency"
      ></wm-metric>
      <wm-metric
        class="col-sm-4 col-xl-3"
        name="Renewals"
        [value]="report.totalRenewalCount"
      ></wm-metric>
      <wm-metric
        class="col-sm-4 col-xl-3"
        name="Renewals Amount"
        [value]="report.totalRenewalAmount | currency"
      ></wm-metric>
    </div>
  </div>
  <div>
    <div class="d-flex justify-content-between align-items-center">
      <div>
        <h4>Summary</h4>
      </div>
    </div>

    <wm-exports-view
      *ngIf="columnOptions && columnOptions.length > 0"
      [id]="id"
      [title]="title"
      [params]="params"
      [currentFilters]="filters"
      [currentColumnOptions]="columnOptions"
      (exptExcel)="exportExcel()"
      (exptCsv)="exportCsv()"
    ></wm-exports-view>

    <wm-datatable
      [id]="id"
      [loading]="loading"
      [useMap]="false"
      [rows]="rows"
      [columns]="columns"
      [count]="page.totalElements"
      [limit]="page.size"
      [offset]="page.pageNumber"
      [params]="params"
      [translateToLegacy]="false"
      [showSimpleSearch]="false"
      [savedFilters]="true"
      (settingsChanged)="handleSettingsChanged($event)"
      [sortField]="sortField"
      [sortDescending]="sortDescending"
      [defaultHiddenColumns]="defaultHiddenColumns"
      [actionColumns]="actionColumns"
      [defaultExportExludedColumns]="defaultExportExludedColumns"
      [availableColumns]="availableColumns"
      (columnOptionsChanged)="handleColumnOptionsChanged($event)"
      [exportingEnabled]="true"
    ></wm-datatable>
  </div>
</div>

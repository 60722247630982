<form [formGroup]="form" *ngIf="entity && form" class="mb-0" novalidate>
  <wm-de-label [entity]="entity" [for]="entity.templateCode"></wm-de-label>
  <input
    type="text"
    class="form-control"
    [formControlName]="entity.templateCode"
    [placeholder]="placeholder"
    [(ngModel)]="entity.value"
    *ngIf="!entity.isMultiline"
    [name]="entity.templateCode"
    [id]="entity.templateCode"
    [required]="entity.isRequired"
  />
  <textarea
    class="form-control"
    [formControlName]="entity.templateCode"
    [placeholder]="placeholder"
    *ngIf="entity.isMultiline"
    rows="5"
    [(ngModel)]="entity.value"
    [name]="entity.templateCode"
    [id]="entity.templateCode"
    [required]="entity.isRequired"
  ></textarea>
</form>

import { Inject, forwardRef, Input } from '@angular/core';
import { TemplateService } from './../../../../../services/template.service';
import { DataEntity } from './../../../../../models/data-entities/data-entity';
import { CKEditorComponent } from '@ckeditor/ckeditor5-angular';
import { CkeditorUploadService } from './../../../../../services/ckeditor-upload.service';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { DataEntityEditorBaseComponent } from './../../data-entity-editor-base/data-entity-editor-base.component';
import { Component, OnInit, ViewChild } from '@angular/core';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { WorkflowService } from 'src/app/services';
import { RichTextDataEntity } from 'src/app/models/data-entities/rich-text-data-entity';

@Component({
  selector: 'wm-rich-text-data-entity-editor',
  templateUrl: './rich-text-data-entity-editor.component.html',
  styleUrls: ['./rich-text-data-entity-editor.component.css']
})
export class RichTextDataEntityEditorComponent
  extends DataEntityEditorBaseComponent
  implements OnInit {
  @ViewChild('body', { static: false }) body: CKEditorComponent;
  public richDe: RichTextDataEntity;
  public Editor = ClassicEditor;
  @Input() showInsertDataEntities = true;
  @Input() parentId: string;

  public entities: DataEntity[];

  public editorConfig = {
    toolbar: [
      'heading',
      '|',
      'bold',
      'italic',
      'link',
      '|',
      'bulletedList',
      'numberedList',
      'imageUpload',
      '|',
      'undo',
      'redo'
    ]
  };

  constructor(
    private _fb: UntypedFormBuilder,
    private uploadAdapter: CkeditorUploadService,
    private templateSvc: TemplateService,
    @Inject(forwardRef(() => WorkflowService))
    public workflowSvc: WorkflowService
  ) {
    super();
  }

  insertBody(de: DataEntity) {
    this.body.editorInstance.model.change(writer => {
      const insertPosition = this.body.editorInstance.model.document.selection.getFirstPosition();
      writer.insertText(this.templateSvc.generateToken(de), insertPosition);
    });
  }

  private stripHtml(html = '') {
    return html
      .replace(/<span class="sr-only"> \(opens in new tab\)<\/span>/g, '')
      .replace(/<span> \(opens in new tab\)<\/span>/g, '');
  }

  ngOnInit() {
    this.richDe = this.dataEntity as RichTextDataEntity;

    this.richDe.html = this.stripHtml(this.richDe.html);
    this.form.addControl(
      this.dataEntity.templateCode,
      this._fb.control(this.richDe.html, Validators.nullValidator)
    );
    // will be set to 1 if is uploading
    this.form.addControl(
      `${this.dataEntity.templateCode}-IS_UPLOADING`,
      this._fb.control(0, [Validators.max(0)])
    );

    if (this.showInsertDataEntities) {
      this.workflowSvc
        .getDataEntitiesBeforeMe(this.workflow, this.activity)
        .subscribe(entities => {
          // filter out the current DE
          this.entities = entities.filter(
            de => de.templateCode !== this.dataEntity.templateCode
          );
        });
    }
  }
  public editorReady(editor) {
    const parentId: string = this.parentId || this.activity.id;
    editor.plugins.get('FileRepository').createUploadAdapter = loader => {
      this.uploadAdapter.setLoader(loader, parentId);
      this.uploadAdapter.loader = loader;

      this.uploadAdapter.isUploading.subscribe((isUploading: boolean) => {
        if (
          this.form.controls[`${this.dataEntity.templateCode}-IS_UPLOADING`]
        ) {
          this.form.controls[
            `${this.dataEntity.templateCode}-IS_UPLOADING`
          ].setValue(isUploading ? 1 : 0);
        }
      });
      return this.uploadAdapter;
    };
  }
}

import { Component, OnInit, EventEmitter } from '@angular/core';
import { ActivityView } from '../../../../../views/master-views/app.view/app.view.component';
import {
  Activity,
  ActivityModel,
  StartWorkflowActivity,
  StartWorkflowActivityModel
} from '../../../../../models/activities';
import { FormGroup } from '@angular/forms';
import { WorkflowContextService } from '../../../../../services';

@Component({
  selector: 'wm-start-workflow-activity-view',
  templateUrl: './start-workflow-activity-view.component.html',
  styleUrls: ['./start-workflow-activity-view.component.css']
})
export class StartWorkflowActivityViewComponent extends ActivityView
  implements OnInit {
  activity: StartWorkflowActivity;

  constructor() {
    super();
  }

  ngOnInit() {}
}

<app-loader *ngIf="searching && !users"></app-loader>

<div *ngIf="users && users.length === 0">
  <p>No users to display</p>
</div>

<div *ngIf="users && users.length > 0">
  <table class="table">
    <tr>
      <td></td>
      <th>Full Name</th>
      <th>Username</th>
      <th>Email</th>
      <th>Last Activity Date</th>
    </tr>
    <tr *ngFor="let user of users">
      <td>
        <a href="#" (click)="editUser(user)" style="cursor: pointer;">Select</a>
      </td>
      <td>
        {{ user.name }}
      </td>
      <td>
        {{ user.userName }}
      </td>
      <td>
        {{ user.emailAddress }}
      </td>
      <td>
        {{ user.lastActivityDate | date: 'MM/dd/yyyy hh:mm aa' }}
      </td>
    </tr>
  </table>
</div>

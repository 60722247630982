<div class="form-group">
  <br />
  <wm-de-label
    [entity]="entity"
    [for]="entity.templateCode"
    [displayOnly]="true"
  ></wm-de-label>
  <ul class="list-group">
    <a
      class="list-group-item"
      href="javascript:void(0)"
      (click)="open(content)"
    >
      <div class="bmd-list-group-col">
        <p class="list-group-item-heading">
          {{ entity?.selectedContact?.firstName }}
          {{ entity?.selectedContact?.lastName }}
        </p>
        <p class="list-group-item-text">
          {{ entity?.selectedContact?.title }}
          <span *ngIf="entity?.selectedContact?.phoneNumber">
            - {{ entity?.selectedContact?.phoneNumber }}
          </span>
        </p>
      </div>
    </a>
  </ul>
</div>

<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-contact">
      {{ entity?.selectedContact?.firstName }}
      {{ entity?.selectedContact?.lastName }}
    </h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <table class="table table-borderless">
      <tbody>
        <tr *ngIf="entity?.selectedContact?.title">
          <th scope="row">Title</th>
          <td>{{ entity?.selectedContact?.title }}</td>
        </tr>
        <tr *ngIf="entity?.selectedContact?.phoneNumber">
          <th scope="row">Phone</th>
          <td>
            <a href="tel:{{ entity?.selectedContact?.phoneNumber }}">
              {{ entity?.selectedContact?.phoneNumber }}
            </a>
          </td>
        </tr>
        <tr *ngIf="entity?.selectedContact?.emailAddress?.email">
          <th scope="row">Email</th>
          <td>
            <a href="mailto:{{ entity?.selectedContact?.emailAddress.email }}">
              {{ entity?.selectedContact?.emailAddress.email }}
            </a>
          </td>
        </tr>
        <tr *ngIf="entity?.selectedContact?.address?.address1">
          <th scope="row">Address</th>
          <td>
            {{ entity?.selectedContact?.address.address1 }}<br />
            {{ entity?.selectedContact?.address.address2 }}
            <br *ngIf="entity?.selectedContact?.address.address2" />

            {{ entity?.selectedContact?.address.city }},
            {{ entity?.selectedContact?.address.state }}
            {{ entity?.selectedContact?.address.zip }}
          </td>
        </tr>
        <tr *ngIf="entity?.selectedContact?.officeHours">
          <th scope="row">Office Hours</th>
          <td>
            <div [innerHTML]="getOfficeHours(entity.selectedContact)"></div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-dark"
      (click)="modal.close('Close click')"
    >
      Close
    </button>
  </div>
</ng-template>

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'wm-payment-processor-config-pointnpay',
  templateUrl: './payment-processor-config-pointnpay.component.html',
  styleUrls: ['./payment-processor-config-pointnpay.component.css']
})
export class PaymentProcessorConfigPointnpayComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}

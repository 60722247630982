import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-assigned-inspections',
  templateUrl: './assigned-inspections.component.html',
  styleUrls: ['./assigned-inspections.component.css']
})
export class AssignedInspectionsViewComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}

import { Component, OnInit } from '@angular/core';
import { PaymentProcessorConfigBaseComponent } from '../payment-processor-config-base/payment-processor-config-base.component';
import { ActivatedRoute } from '@angular/router';
import { PayGovPaymentProcessorConfiguration } from '../../../models';
import { UntypedFormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'wm-payment-processor-config-paygov',
  templateUrl: './payment-processor-config-paygov.component.html',
  styleUrls: ['./payment-processor-config-paygov.component.css']
})
export class PaymentProcessorConfigPayGovComponent
  extends PaymentProcessorConfigBaseComponent
  implements OnInit {
  payGovConfig: PayGovPaymentProcessorConfiguration;

  constructor(fb: UntypedFormBuilder) {
    super(fb);
  }

  ngOnInit() {
    this.payGovConfig = this
      .processorConfig as PayGovPaymentProcessorConfiguration;

    this.addValidation('multiPaymentURL', Validators.required);
  }
}

<div *ngIf="agenda">
  <div class="row m-1 pb-3">
    <div class="flex-column">
      <div *ngIf="isNewAgenda">
        <h3>New Agenda - {{ agendaTypeName }}</h3>
      </div>
      <div *ngIf="!isNewAgenda">
        <h3>{{ agendaTypeName }} agenda</h3>
      </div>
    </div>

    <!-- save and cancel buttons -->
    <div class="flex-column pl-5">
      <button
        type="button"
        class="btn btn-raised btn-success float-right"
        (click)="saveAgenda()"
        [disabled]="isSaving"
      >
        {{ isSaving ? 'Saving...' : 'Save' }}
      </button>
    </div>
    <div class="flex-column pl-3">
      <button
        type="button"
        class="btn btn-raised float-right"
        (click)="cancelAgendaChanges()"
      >
        Cancel
      </button>
    </div>
    <div class="col"></div>
  </div>

  <!--date and time settings -->
  <div class="row mt-1">
    <div class="col">
      <wm-date-data-entity-input
        [entity]="agenda.meetingDateTimeDE"
        [form]="form"
        [labelClass]="text - primary"
      ></wm-date-data-entity-input>
    </div>
  </div>

  <!-- description -->
  <div class="row mt-1 mb-5">
    <div class="col">
      <div [formGroup]="form">
        <div class="form-group">
          <label class="bmd-label-floating" for="description"
            >Description (optional)</label
          >
          <input
            type="text"
            class="form-control"
            [(ngModel)]="agenda.description"
            name="description"
            formControlName="description"
          />
          <wm-control-message
            [control]="form.controls['description']"
          ></wm-control-message>
        </div>
      </div>
    </div>
  </div>

  <div
    class="pb-5"
    *ngIf="!pagedAgendaQueueItems || pagedAgendaQueueItems.length === 0"
  >
    <i>
      There are no items waiting for inclusion in this type of agenda
    </i>
  </div>

  <div *ngIf="pagedAgendaQueueItems && pagedAgendaQueueItems.length > 0">
    <div class="row m-1">
      <h5>Included items:</h5>
      &nbsp;
      {{ selectedQueueItems.length }}
    </div>

    <!-- queue item selections -->
    <div class="p-1">
      <div class="row" *ngFor="let qi of pagedAgendaQueueItems">
        <div class="col">
          <div class="card border border-primary">
            <div class="card-body">
              <wm-agenda-queue-item-readonly
                [queueItem]="qi"
                [selected]="selectedQueueItems.includes(qi.id)"
                (selectedChanged)="updateSelected($event)"
              ></wm-agenda-queue-item-readonly>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- queue item selection pagination controls -->
    <div class="row m-1" *ngIf="recordCount > 10">
      <div class="col"></div>
      <div class="flex-column">
        <ngb-pagination
          [(page)]="pageNumber"
          [pageSize]="10"
          [collectionSize]="recordCount"
          (pageChange)="pageChanged($event)"
        ></ngb-pagination>
      </div>
    </div>
  </div>

  <!-- other times editor-->
  <div class="row mt-5 ml-1">
    <h5>Other items:</h5>
  </div>
  <div [formGroup]="otherItemsForm">
    <div class="row mt-1 mb-1">
      <div class="col form-group">
        <!-- <label class="bmd-label-floating" for="otherItems"></label> -->
        <ckeditor
          #otherItemsEditor
          [editor]="Editor"
          [config]="editorConfig"
          formControlName="otherItems"
          [(ngModel)]="agenda.otherItems"
          (ready)="editorReady($event)"
        ></ckeditor>
        <wm-control-message
          [control]="otherItemsForm.controls['otherItems']"
        ></wm-control-message>
      </div>
    </div>
  </div>

  <!-- another save and cancel button -->
  <div class="row mt-3 ml-1">
    <div class="flex-column pl-2">
      <button
        type="button"
        class="btn btn-raised btn-success float-right"
        (click)="saveAgenda()"
        [disabled]="isSaving"
      >
        {{ isSaving ? 'Saving...' : 'Save' }}
      </button>
    </div>
    <div class="flex-column pl-3">
      <button
        type="button"
        class="btn btn-raised float-right"
        (click)="cancelAgendaChanges()"
      >
        Cancel
      </button>
    </div>
  </div>
</div>

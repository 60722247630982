<wm-email-activity-editor
  [form]="form"
  [activity]="activity"
></wm-email-activity-editor>
<form [formGroup]="form">
  <wm-data-entity-autocomplete
    [types]="['date', 'today', 'calculated-value']"
    [form]="form"
    [model]="model.dateDataEntity"
    id="schedule_date"
    [activity]="activity"
    [required]="true"
    controlName="schedule_date"
    [title]="'Delivery Date'"
    [workflow]="workflow"
    placement="left"
    [serverValidator]="workflowSvc.validateEmailFields"
    (valueUpdate)="model.dateDataEntity = $event"
  >
  </wm-data-entity-autocomplete>
  <small id="dateHelpBlock" class="form-text text-muted">
    If the formula includes a time, the time will be ignored.
  </small>

  <div class="form-group">
    <label class="bmd-label-floating" for="schedule_time">
      Delivery Time of Day
    </label>
    <select
      class="form-control"
      [(ngModel)]="model.timeOfDay"
      formControlName="schedule_time"
      aria-describedby="timeHelpBlock"
    >
      <option *ngFor="let time of times" [ngValue]="time">
        {{ time }}
      </option>
    </select>
    <small id="timeHelpBlock" class="form-text text-muted">
      Time of day that the email should be delivered at in your jurisdiction's
      time zone.
    </small>
    <ng-container *ngIf="model.showCancelOptions">
      <div class="switch">
        <label>
          <input
            id="cancelIfDisabled"
            formControlName="cancelIfDisabled"
            type="checkbox"
            [(ngModel)]="model.cancelIfDisabled"
          />
          Cancel if Disabled
        </label>
      </div>
      <div class="switch">
        <label>
          <input
            id="cancelIfRenewed"
            type="checkbox"
            formControlName="cancelIfRenewed"
            [(ngModel)]="model.cancelIfRenewed"
          />
          Cancel if Renewed
        </label>
      </div>
    </ng-container>
  </div>
</form>

import { map, catchError } from 'rxjs/operators';
import {
  HttpEventType,
  HttpErrorResponse,
  HttpResponse
} from '@angular/common/http';
import { UploadService } from './../../services/upload/upload.service';
import { ToastrService } from 'ngx-toastr';
import {
  Component,
  ViewChild,
  ElementRef,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
  AfterViewInit
} from '@angular/core';
import { DocumentService, Utilities } from '../../services';
import * as numeral from 'numeral';

export interface UploadResponse {
  id: string;
  name: string;
  parentId: string;
  parentPath: string;
  parentType: string;
  path: string;
  pathName: string;
}

interface UploadFile {
  data: File;
  inProgress: boolean;
  progress: number;
  size: any;
}

@Component({
  selector: 'wm-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.css']
})
export class UploadComponent implements OnInit {
  @ViewChild('uploadButton', { static: true }) uploadButton: ElementRef;
  @Input() validDocumentTypes: string;
  @Input() allowMultipleFiles = true;
  @Input() pathSegments: string[];
  @Input() key: string;
  @Input() isPreview = false;
  // deprecated, always true
  @Input() showSingleButton = true;
  @Input() buttonText: string;
  // deprecated
  @Input() alignment = 'right';
  @Input() category: string;
  @Output() uploadComplete: EventEmitter<Document[]> = new EventEmitter<
    Document[]
  >();
  @Output() fileUploaded: EventEmitter<UploadResponse> = new EventEmitter<
    UploadResponse
  >();

  // Files being uploaded.
  uploadedFiles: any[] = [];

  @ViewChild('fileUpload', { static: true }) fileUpload: ElementRef;
  files: UploadFile[] = [];

  constructor(
    private documentService: DocumentService,
    private toast: ToastrService,
    private uploadSvc: UploadService
  ) {}

  ngOnInit() {
    this.validDocumentTypes = this.stripFileTypes(this.validDocumentTypes);

    if (!this.buttonText) {
      this.buttonText = 'Select file';

      if (this.allowMultipleFiles) {
        this.buttonText += 's';
      }
    }
  }

  stripFileTypes(fileTypes: string) {
    if (!fileTypes) {
      return '';
    }

    return fileTypes
      .split(',') // split it into an array of each type
      .map(type => `.${type.trim()}`) // then map it to an array of trimmed file types
      .join(','); // then join it back into a comma separated list
  }

  public async uploadFile(
    file: UploadFile
  ): Promise<HttpResponse<UploadResponse>> {
    const extraData = {
      name: file.data.name,
      parentPath: this.pathSegments,
      filename: file.data.name,
      origName: file.data.name,
      category: this.category
    };

    file.inProgress = true;

    const res = await this.documentService
      .upload(file.data, extraData)
      .pipe(
        map(event => {
          switch (event.type) {
            case HttpEventType.UploadProgress:
              file.progress = Math.round((event.loaded / event.total) * 100);
              break;
            case HttpEventType.Response:
              return event;
          }
        }),
        catchError((error: HttpErrorResponse) => {
          file.inProgress = false;
          throw new Error(
            `Upload failed: ${file.data.name}, (${error.message})`
          );
        })
      )
      .toPromise();

    return res;
  }

  onClick() {
    const fileUpload = this.fileUpload.nativeElement;
    fileUpload.onchange = () => {
      for (let index = 0; index < fileUpload.files.length; index++) {
        const file = fileUpload.files[index];
        this.files.push({
          data: file,
          inProgress: false,
          progress: 0,
          size: numeral(file.size).format('0.0 b')
        });
      }

      if (this.files.length > 0) {
        this.documentService.filesAdded.emit({
          files: this.files,
          key: this.key
        });

        this.uploadFiles();
      }
    };
    fileUpload.click();
  }

  async uploadFiles() {
    for (const file of this.files) {
      try {
        const result = await this.uploadFile(file);

        if (result.status === 200 && result.body != null) {
          this.uploadedFiles.push(result.body);
          this.fileUploaded.emit(result.body);
        }
      } catch (err) {
        this.toast.error(err.message, 'Upload failed!');
      }
    }

    this.uploadComplete.emit(this.uploadedFiles);
    this.documentService.fileUploadComplete.emit({
      files: this.uploadedFiles,
      key: this.key
    });

    this.reset();
  }

  reset() {
    this.uploadedFiles = [];
    this.files = [];
    this.fileUpload.nativeElement.value = null;
  }
}

<wm-data-entity-input-list
  [form]="form"
  [entities]="formLayoutModel.columnEntities"
  [applicationId]="applicationId"
  [activity]="activity"
  *ngIf="!isReadOnly"
  #dataEntityInputList
>
</wm-data-entity-input-list>
<wm-data-entity-view-list
  [form]="form"
  [entities]="formLayoutModel.columnEntities"
  [applicationId]="applicationId"
  [activity]="activity"
  *ngIf="isReadOnly"
  #dataEntityViewList
>
</wm-data-entity-view-list>

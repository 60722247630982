<form [formGroup]="form" *ngIf="config">
  <div class="form-group">
    <label class="bmd-label-floating" for="siteId">
      Site Id
    </label>
    <input
      type="text"
      class="form-control"
      formControlName="siteId"
      name="siteId"
      [(ngModel)]="config.siteId"
    />
  </div>
  <div class="form-group">
    <label class="bmd-label-floating" for="prodId">
      Product Id
    </label>
    <input
      type="text"
      class="form-control"
      formControlName="prodId"
      name="prodId"
      [(ngModel)]="config.prodId"
    />
  </div>
  <div class="form-group">
    <label class="bmd-label-floating" for="urlKey">
      URL Key
    </label>
    <input
      type="text"
      class="form-control"
      formControlName="urlKey"
      name="urlKey"
      [(ngModel)]="config.urlKey"
    />
  </div>
</form>

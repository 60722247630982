import {
  PrintTemplateActivity,
  PrintTemplateActivityModel
} from './print-template-activity';

export class RenewalCertificateActivity extends PrintTemplateActivity {
  constructor(options?: Partial<PrintTemplateActivity>) {
    super(options);
    this.type = 'renewal-certificate-activity';
    this.description = 'Generate a PDF representing the renewal certificate';
    this.name = 'Renewal certificate';

    this.model = new PrintTemplateActivityModel(options ? options.model : null);

    this.model.screenName = this.model.screenName || 'Renewal certificate';
  }
}

import { DataTableHelpers } from './../../../../components/system/datatable/datatable-helper';
import { TableColumn } from '@swimlane/ngx-datatable';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  WorkflowContextService,
  ContractorService,
  SecurityService
} from 'src/app/services';
import { ContractorType, Actions } from '../../../../models';
import { Subscription, Observable, empty } from 'rxjs';
import { ItemSearchOptionField } from 'src/app/components/filter-list/models/filterClasses';
import { map } from 'rxjs/operators';
import { RegistrationStatus } from 'src/app/models/registration';
import { ComponentIds } from 'src/app/models/component-ids';

@Component({
  selector: 'app-contractor-type-details-view',
  templateUrl: './contractor-type-details-view.component.html',
  styleUrls: ['./contractor-type-details-view.component.css']
})
export class ContractorTypeDetailsViewComponent implements OnInit, OnDestroy {
  id = ComponentIds.CONTRACTOR_TYPE_REGISTRATION_LIST;
  contractorType: ContractorType;
  contractorTypeId: string;
  canEdit = false;
  clientSubscription: Subscription;
  dynamicColumnsVisibleByDefault = true;

  public filterOptions: ItemSearchOptionField[];
  commands: {
    title: string;
    name: string;
    idField: string;
    canPerform: (item: any) => boolean;
    handler: any;
  }[];

  public columns: TableColumn[] = [
    {
      prop: 'registerApplicationNumber',
      name: 'Application Number'
    },
    {
      prop: 'contractorName',
      name: 'Name'
    },
    {
      prop: 'contractor',
      name: 'Business Name'
    },
    {
      prop: 'address',
      name: 'Address'
    },
    {
      prop: 'phone',
      name: 'Phone'
    },
    {
      prop: 'email',
      name: 'Email'
    },
    {
      prop: 'registeredOn',
      name: 'Registration Date',
      pipe: this.dtHelpers.getDatePipe()
    },
    {
      prop: 'effectiveOn',
      name: 'Effective Date',
      pipe: this.dtHelpers.getDatePipe()
    },
    {
      prop: 'expiresOn',
      name: 'Expiration Date',
      pipe: this.dtHelpers.getDatePipe()
    },
    {
      prop: 'approvedOn',
      name: 'Approved Date',
      pipe: this.dtHelpers.getDatePipe()
    },
    {
      prop: 'status',
      name: 'Status',
      pipe: {
        transform: (value: any) => {
          return value ? RegistrationStatus[value] : 'N/A';
        }
      }
    },
    {
      prop: 'submittedBy',
      name: 'Submitted By'
    }
  ];

  constructor(
    public context: WorkflowContextService,
    private route: ActivatedRoute,
    private _router: Router,
    private _contractorSvc: ContractorService,
    private dtHelpers: DataTableHelpers,
    private _securitySvc: SecurityService
  ) {}

  ngOnDestroy(): void {
    if (this.clientSubscription) {
      this.clientSubscription.unsubscribe();
    }
  }

  ngOnInit() {
    this._securitySvc
      .isLoginEntitled(Actions.CONTRACTORS_MANAGE)
      .pipe(
        map(e => {
          this.canEdit = e;
        })
      )
      .subscribe();

    this.route.params.subscribe(params => {
      this.contractorTypeId = params['contractorTypeId'];

      if (this.context.client) {
        this.loadContractors();
      }

      this.clientSubscription = this.context.client$.subscribe(res => {
        this.loadContractors();
      });
    });
    // });
  }

  canViewProfile(item: any): boolean {
    return true;
  }

  viewProfile(id: string, item: any) {
    this._router.navigate([
      '/admin/jurisdiction',
      this.context.client.id,
      'contractors',
      'contractor-profile',
      item.contractorId
    ]);
  }

  loadContractors() {
    this.loadContractorType().subscribe(() => {
      if (this.context.client) {
        this.filterOptions = [
          {
            title: 'contractorTypeId',
            options: [
              {
                title: '',
                filterText: this.contractorType.id,
                selected: true,
                searchText: '',
                strOperator: ''
              }
            ]
          }
        ];
      }
    });
  }

  loadContractorType(): Observable<ContractorType> {
    if (this.context.client) {
      if (this.contractorTypeId == null) {
        return this._contractorSvc
          .initNewContractorType(this.context.client.id)
          .pipe(
            map(type => {
              this.contractorType = type;
              return type;
            })
          );
      } else {
        return this._contractorSvc
          .getContractorType(this.contractorTypeId, true)
          .pipe(
            map(type => {
              this.contractorType = type;
              return type;
            })
          );
      }
    }
    return empty();
  }
}

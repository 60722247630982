<h3>Contractor Reports</h3>

<ul ngbNav #navs="ngbNav" class="nav-tabs">
  <li ngbNavItem *ngIf="canViewSummary">
    <a ngbNavLink>Summary</a>
    <ng-template ngbNavContent>
      <wm-contractor-summary></wm-contractor-summary>
    </ng-template>
  </li>
  <li ngbNavItem *ngIf="canViewFeeSummary">
    <a ngbNavLink>Fee Summary</a>
    <ng-template ngbNavContent>
      <wm-contractor-fee-summary></wm-contractor-fee-summary>
    </ng-template>
  </li>
</ul>
<div [ngbNavOutlet]="navs"></div>

import { Component, OnInit, ComponentFactoryResolver } from '@angular/core';
import { DateDataEntity } from '../../../../../models/data-entities';
import { DataEntityViewComponent } from '../../data-entity-view/data-entity-view.component';

@Component({
  selector: 'wm-date-data-entity-view',
  templateUrl: './date-data-entity-view.component.html',
  styleUrls: ['./date-data-entity-view.component.css']
})
export class DateDataEntityViewComponent extends DataEntityViewComponent
  implements OnInit {
  constructor(componentFactoryResolver: ComponentFactoryResolver) {
    super(componentFactoryResolver);
  }
  dateEntity: DateDataEntity;

  ngOnInit() {
    this.dateEntity = <DateDataEntity>this.entity;
  }
}

<form [formGroup]="form">
  <div class="form-group">
    <label class="bmd-label-floating" for="validDocumentTypes">File Type</label>
    <input
      type="text"
      class="form-control"
      formControlName="validDocumentTypes"
      name="validDocumentTypes"
      aria-describedby="validDocumentTypesHelp"
      [(ngModel)]="documentDE.validDocumentTypes"
    />
    <small id="validDocumentTypesHelp" class="form-text text-muted">
      File Types (seperate multiple files using ",")
    </small>
    <wm-control-message
      [control]="form.controls['validDocumentTypes']"
    ></wm-control-message>
  </div>
</form>

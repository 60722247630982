import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { Actions, Client, Workflow, WorkflowCheckpoint } from 'src/app/models';
import { ModalConfirmComponent } from '../../system/modal-confirm/modal-confirm.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  ClientService,
  SecurityService,
  WorkflowContextService,
  WorkflowService,
  WorkflowValidationService
} from 'src/app/services';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { StartApplicationResponse } from 'src/app/models/start-application-response';
import { ValidationSeverity } from 'src/app/models/validation';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'wm-workflow-actions-menu',
  templateUrl: './workflow-actions-menu.component.html',
  styleUrls: ['./workflow-actions-menu.component.css']
})
export class WorkflowActionsMenuComponent implements OnInit, OnChanges {
  @Input() workflow: Workflow;
  @Input() loadGlobal: boolean;
  @Input() clientId: string;

  @Output() onRestore: EventEmitter<string> = new EventEmitter<string>();
  @Output() onDisable: EventEmitter<string> = new EventEmitter<string>();
  @Output() onEnable: EventEmitter<string> = new EventEmitter<string>();
  @Output() onPublish: EventEmitter<string> = new EventEmitter<string>();
  @Output() onCopy: EventEmitter<Workflow> = new EventEmitter<Workflow>();
  @Output() onArchive: EventEmitter<{
    id: string;
    archiveNotes: string;
  }> = new EventEmitter<{ id: string; archiveNotes: string }>();
  @Output() toggleVisible: EventEmitter<Workflow> = new EventEmitter<
    Workflow
  >();

  @ViewChild('checkpointList') checkpointList: ModalConfirmComponent;
  @ViewChild('copyWorkflowModal') copyWorkflowModal: ModalConfirmComponent;
  @ViewChild('denyStartApp') denyStartAppModal: ModalConfirmComponent;

  clients: Client[];
  targetClientId?: string;
  targetName: string;
  copyWorkflowId: string;
  targetForm: FormGroup;
  copyingWorkflow = false;
  archiveNotes: string;

  isEntitledToDisable: boolean;
  isEntitledToPublish: boolean;
  isEntitledToHistory: boolean;
  isEntitledToBuilder: boolean;
  isEntitledToStart: boolean;
  isEntitledToArchive: boolean;

  checkpoints: WorkflowCheckpoint[];
  isValidating: boolean;
  publishForm: any;

  constructor(
    public _context: WorkflowContextService,
    private _workflowSvc: WorkflowService,
    private toastr: ToastrService,
    private _router: Router,
    private _securitySvc: SecurityService,
    private _workflowValidationSvc: WorkflowValidationService,
    private modalService: NgbModal,
    private _clientSvc: ClientService
  ) {}
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['workflow']) {
      this.buildPermissions();
    }
  }

  ngOnInit() {
    this.publishForm = new FormGroup({
      isWorkflowValid: new FormControl(1, Validators.max(0))
    });

    this.targetForm = new FormGroup({
      targetClientId: new FormControl('', Validators.nullValidator),
      targetName: new FormControl('', Validators.required)
    });

    this.buildPermissions();
  }

  canDisable(): boolean {
    return (
      !this.workflow.isArchived &&
      this.workflow.isEnabled &&
      this.isEntitledToDisable === true
    );
  }
  canEnable(): boolean {
    return (
      !this.workflow.isArchived &&
      !this.workflow.isEnabled &&
      this.isEntitledToDisable === true
    );
  }
  canStartApplication(): boolean {
    return (
      (!this.workflow.isArchived &&
        this.workflow.isEnabled &&
        this.workflow.isPublished) ||
      (this.workflow.lastPublishedOn && this.isEntitledToPublish === true)
    );
  }
  canArchiveWorkflow(): boolean {
    return !this.workflow.isArchived && this.isEntitledToArchive === true;
  }
  canRestoreWorkflow(): boolean {
    return this.workflow.isArchived;
  }
  canPublish(): boolean {
    return (
      this.workflow.isEnabled &&
      this.workflow.areThereChangesNotPublished &&
      this.isEntitledToPublish === true
    );
  }
  canViewHistory(): boolean {
    return this.isEntitledToHistory === true;
  }
  canEditWorkflow(): boolean {
    return this.isEntitledToBuilder == true && !this.workflow.isArchived;
  }

  dismissPublish() {
    if (this.modalService.hasOpenModals()) {
      this.modalService.dismissAll('navigate to builder');
    }
  }

  async validateWorkflow() {
    this.isValidating = true;
    this.publishForm.controls['isWorkflowValid'].setValue(1);
    const validationResults = await this._workflowValidationSvc
      .validate(this.workflow.id, true, true)
      .then();

    const validityErrors = [];

    for (const item of validationResults) {
      if (
        item.messages.filter(
          message => message.severity === ValidationSeverity.Error
        ).length > 0
      ) {
        validityErrors.push(item);
      }
    }

    this.isValidating = false;
    this.publishForm.controls['isWorkflowValid'].setValue(
      (validityErrors || []).length
    );
  }

  startApplication() {
    const rpIds = this._workflowSvc
      .getRenewableParentIds(this.workflow.id)
      .subscribe(response => {
        if (response.renewableParentIds.length > 0) {
          this.denyStartAppModal.open();
        } else {
          this._workflowSvc
            .startApplication(this.workflow.id, false)
            .subscribe((applicationResponse: StartApplicationResponse) => {
              const navOptions = [
                '/application/workflow-application',
                applicationResponse.applicationId
              ];
              this._router.navigate(navOptions);
            });
        }
      });
  }

  onArchiveCanceled() {
    this.archiveNotes = '';
  }

  onArchiveCompleted() {
    this._workflowSvc
      .archiveWorkflow(this.workflow.id, this.archiveNotes)
      .subscribe(res => {
        this.toastr.success('Archived!');
      });
    this.onArchive.emit({
      id: this.workflow.id,
      archiveNotes: this.archiveNotes
    });
    this.archiveNotes = '';
  }

  buildPermissions() {
    if (this.workflow) {
      this._securitySvc
        .isLoginEntitled(
          Actions.WORKFLOW_EDIT,
          this.workflow,
          null,
          this.loadGlobal
        )
        .subscribe(result => {
          this.isEntitledToBuilder = result;
          this.isEntitledToPublish = result;
          this.isEntitledToDisable = result;
          this.isEntitledToHistory = result;
          this.isEntitledToArchive = result;
          this.isEntitledToStart = true;
        });
    }
  }

  restoreWorkflow() {
    this._workflowSvc.restoreWorkflow(this.workflow.id).subscribe(res => {
      this.toastr.success('Workflow restored!');
      this.onRestore.emit(this.workflow.id);
    });
  }

  startWorkflowCopy() {
    if (this._context.client) {
      this.targetClientId = this._context.client.id;
    }
    this.targetName = `Copy Of - ${this.workflow.version.name}`;

    this.copyWorkflowId = this.workflow.id;
    this._clientSvc.getAdminClients().subscribe(c => {
      this.clients = c;
      this.copyWorkflowModal.open();
    });
  }

  copyWorkflow() {
    this.copyingWorkflow = true;
    this._workflowSvc
      .copyWorkflow({
        workflowId: this.copyWorkflowId,
        targetClientId: this.targetClientId,
        targetName: this.targetName
      })
      .subscribe(newWorkflow => {
        this.copyingWorkflow = false;
        this.copyWorkflowModal.cancel();
        this.toastr.success('Workflow Copied');
        this.onCopy.emit(newWorkflow);
      });
  }

  enableWorkflow() {
    this._workflowSvc.enableWorkflow(this.workflow.id).subscribe(res => {
      this.toastr.success('Enabled!');
      this.onEnable.emit(this.workflow.id);
    });
  }

  disableWorkflow() {
    this._workflowSvc.disableWorkflow(this.workflow.id).subscribe(res => {
      this.toastr.success('Disabled!');
      this.onDisable.emit(this.workflow.id);
    });
  }

  publishWorkflow() {
    this._workflowSvc.publishWorkflow(this.workflow.id).subscribe(res => {
      this.toastr.success('Published!');
      this.onPublish.emit(this.workflow.id);
    });
  }

  restoreCheckpoint(e) {
    this.checkpointList.cancel();
    this.toastr.success(`Workflow Restored`);
    this.checkpointList.dismis('saved');
    this.onRestore.emit(e);
  }

  archiveWorkflow(e) {
    this._workflowSvc.archiveWorkflow(e.id, e.archiveNotes).subscribe(res => {
      this.toastr.success('Archived!');
      this.onArchive.emit(e);
    });
  }

  hideWorkflow() {
    this._workflowSvc
      .hideWorkflowFromPublic(this.workflow.id)
      .subscribe(result => {
        if (result) {
          this.workflow.visibleToPublic = false;
          this.toastr.success('Workflow is no longer Visible to public');
        }
      });
  }

  makeWorkflowVisible() {
    this._workflowSvc
      .makeWorkflowVisibleToPublic(this.workflow.id)
      .subscribe(result => {
        if (result) {
          this.workflow.visibleToPublic = true;
          this.toastr.success('Workflow is now Visible to public');
        }
      });
  }

  toggleVisibleToPublic() {
    if (this.workflow.visibleToPublic) {
      this.hideWorkflow();
    } else {
      this.makeWorkflowVisible();
    }
  }

  loadHistory() {
    this._workflowSvc
      .getWorkflowCheckpoints(this.workflow.id)
      .subscribe(res => {
        this.checkpoints = res;
        this.checkpointList.open();
      });
  }
}

<form [formGroup]="form" novalidate>
  <div class="form-group" *ngIf="canEditIfSystem">
    <p>
      List Type
    </p>

    <div class="radio" *ngFor="let l of ListTypes">
      <label>
        <input
          type="radio"
          name="controlType"
          [id]="['type-' + l]"
          [(ngModel)]="listEntity.controlType"
          formControlName="controlType"
          [value]="l"
          checked
        />
        {{ l }}
      </label>
    </div>
  </div>
  <div class="form-group" *ngIf="canEditIfSystem">
    <label class="bmd-label-floating" for="listValue">List Data Source</label>
    <div class="input-group">
      <select
        class="form-control"
        [(ngModel)]="listEntity.selectedDataSource"
        formControlName="listDataSource"
        (change)="canEditList = calcCanEditList()"
      >
        <option></option>
        <option
          *ngFor="let source of availableDataSources"
          [value]="source.key"
          >{{ source.value }}</option
        >
      </select>
    </div>
  </div>

  <wm-list-editor
    [listItems]="listEntity.listTextValues"
    [canEdit]="canEditIfSystem"
    *ngIf="canEditList"
    (listChange)="listChange($event)"
  ></wm-list-editor>

  <wm-external-data-source-config
    *ngIf="canEditIfSystem"
    [entity]="dataEntity"
    [form]="form"
    (externalSourceUpdated)="externalDataSourceChanged($event)"
  ></wm-external-data-source-config>
</form>

<div
  ngbDropdown
  class="d-inline-block"
  display="dynamic"
  placement="bottom-right"
>
  <button class="btn btn-primary btn-raised" id="export" ngbDropdownToggle>
    Scheduled Exports
  </button>
  <div
    ngbDropdownMenu
    class="dropdown-menu"
    aria-labelledby="dropdownMenuButton"
  >
    <button ngbDropdownItem (click)="openModalForNew()">
      + &nbsp;<strong>Create New</strong>
    </button>
    <ng-container *ngIf="scheduledExports && scheduledExports.length > 0">
      <button
        ngbDropdownItem
        *ngFor="let scheduledExport of scheduledExports"
        (click)="openModalForExisting(scheduledExport)"
      >
        {{ scheduledExport.name }}
      </button>
    </ng-container>
  </div>
</div>
<!-- existing scheduled export modal-->
<wm-modal-confirm
  #scheduledExportModal
  title="Scheduled Export - {{ title }}"
  [showButton]="false"
  commandText="Save"
  customButtonText="Delete"
  (canceled)="destroyEditors()"
  (accepted)="saveScheduledExport(false)"
  (customButtonClicked)="deleteScheduledExport()"
  [form]="form"
>
  <wm-scheduled-export-editor
    *ngIf="
      runExistingEditor &&
      selectedScheduledExport &&
      currentColumnOptions &&
      currentColumnOptions.length > 0
    "
    #scheduledExportEditor
    [scheduledExport]="selectedScheduledExport"
    [params]="params"
    [staticFilters]="staticFilters"
    [currentColumnOptions]="currentColumnOptions"
    (saved)="handleSave()"
    [componentId]="componentId"
    [filterForm]="filterForm"
    [form]="form"
    [shapefileValidationCriteria]="shapefileValidationCriteria"
  >
  </wm-scheduled-export-editor>
</wm-modal-confirm>

<!-- new scheduled export modal -->
<wm-modal-confirm
  #newScheduledExportModal
  title="Scheduled Export - {{ title }}"
  [showButton]="false"
  commandText="Save"
  (canceled)="destroyEditors()"
  (accepted)="saveScheduledExport(true)"
  [form]="form"
  *ngIf="form"
>
  <wm-scheduled-export-editor
    *ngIf="
      runNewEditor &&
      currentFilters &&
      currentColumnOptions &&
      currentColumnOptions.length > 0
    "
    #newScheduledExportEditor
    [params]="params"
    [currentFilters]="currentFilters"
    [staticFilters]="staticFilters"
    [currentColumnOptions]="currentColumnOptions"
    (saved)="handleSave()"
    [componentId]="componentId"
    [form]="form"
    [shapefileValidationCriteria]="shapefileValidationCriteria"
    [filterForm]="filterForm"
  ></wm-scheduled-export-editor>
</wm-modal-confirm>

import { DataEntity } from './data-entity';
import { Validators } from '@angular/forms';

export class TodayDataEntity extends DataEntity {
  feeFormula?: string;
  feeRecipient: string;
  paymentAccountId: string;
  canAdminEdit: boolean;

  constructor(options?: Partial<TodayDataEntity>) {
    super(options);

    this.dataEntityTypeCode = 'today';
    this.dataEntityTypeDescription =
      'Use this entity type to date the application with the data the user is entering information.';
    this.dataEntityTypeName = 'Today';
    this.hasEditor = false;

    if (options) {
      Object.assign(this, options);
    }
  }

  getValidators(): any[] {
    return [Validators.nullValidator];
  }
}

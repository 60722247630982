<div class="form-group">
  <wm-de-label
    [entity]="entity"
    [for]="entity.templateCode"
    [displayOnly]="true"
  ></wm-de-label>

  <div
    [ngClass]="
      numericDE.type === NumericDataType.Currency ? 'input-group mb-3' : ''
    "
  >
    <div
      *ngIf="numericDE.type === NumericDataType.Currency"
      class="input-group-prepend"
    >
      <span class="input-group-text">$</span>
    </div>
    <input
      type="text"
      readonly
      class="form-control-plaintext"
      [id]="entity.templateCode"
      [value]="entity.value"
    />
  </div>
</div>

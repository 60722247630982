import { MapDimensionLayout_htmlClassName } from './../../../models/data-entities/mapsketch-data-entity';
import {
  Component,
  OnInit,
  ViewChild,
  Inject,
  forwardRef,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { MapService, MapLayer, Client } from 'src/app/models';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
  ValidatorFn,
  AbstractControl,
  UntypedFormControl
} from '@angular/forms';
import { EsriMapDesigntimeComponent } from '../esri-map-designtime/esri-map-designtime.component';
import { SystemService, DataService, WorkflowService } from 'src/app/services';
import { MapExtent } from 'src/app/models/mapextent';
import { MapDisplayConfiguration } from '../map-display-configuration';

@Component({
  selector: 'app-map-display-configuration-editor',
  templateUrl: './map-display-configuration-editor.component.html',
  styleUrls: ['./map-display-configuration-editor.component.css']
})
export class MapDisplayConfigurationEditorComponent implements OnInit {
  // loaded from service
  mapServices: MapService[];
  mapService: MapService; // bound to dropdown list

  @Input()
  showMapServiceSelection: boolean = true;

  @Input()
  mapConfig: MapDisplayConfiguration;

  layerOptionGroup: UntypedFormGroup;

  @ViewChild(EsriMapDesigntimeComponent, { static: false })
  private esriMap: EsriMapDesigntimeComponent;

  @Input()
  client: Client;
  @Input()
  mapDimensionLayout_class?: MapDimensionLayout_htmlClassName;

  form: UntypedFormGroup = new UntypedFormGroup({});

  @Output()
  mapSaved = new EventEmitter();
  mapExtent: MapExtent;

  constructor(
    private _fb: UntypedFormBuilder,
    @Inject(forwardRef(() => DataService)) private _dataSvc: DataService,
    @Inject(forwardRef(() => SystemService)) private _systemsvc: SystemService,
    @Inject(forwardRef(() => WorkflowService))
    private _workflowSvc: WorkflowService
  ) {}

  async ngOnInit() {
    if (this.showMapServiceSelection) {
      this.form.addControl(
        'mapService',
        this._fb.control(this.mapService, Validators.nullValidator)
      );
    }

    this.form.addControl(
      'showToc',
      this._fb.control(this.mapConfig.showToc, [])
    );

    this.form.addControl(
      'basemap',
      this._fb.control(this.mapConfig.basemapName, Validators.nullValidator)
    );

    this.layerOptionGroup = this._fb.group({});
    this.layerOptionGroup.setValidators(
      this.mustHaveAtLeastOneLayerValidator()
    );
    this.form.addControl('layerOptionGroup', this.layerOptionGroup);

    this.updateLayerBindings();

    if (this.showMapServiceSelection) {
      this._systemsvc.getMapServicesByClient(this.client).subscribe(result => {
        this.mapServices = result;
        this.mapService = result.find(
          ms => ms.id === this.mapConfig.mapServiceId
        );
      });
    }
  }

  private updateLayerIds() {
    if (!this.mapConfig.layers) {
      return;
    }
    this._dataSvc
      .getMapLayerIndex(this.mapConfig.mapServiceId)
      .subscribe(res => {
        this.mapConfig.layers.map(layer => {
          const dbLayer = res.find(l => l.index === layer.id);

          layer.mapLayerIndexItemId = dbLayer && dbLayer.id;

          return layer;
        });
      });
  }

  private updateLayerBindings() {
    if (this.mapConfig.layers) {
      // Clear the group to drop all of the old controls:
      this.layerOptionGroup.controls = {};

      for (let i = 0; i < this.mapConfig.layers.length; i++) {
        const lyr = this.mapConfig.layers[i];
        this.layerOptionGroup.addControl(
          `layerVis-${i}`,
          this._fb.control(lyr.visible)
        );
      }
    }
  }

  onMapExtentChanged(e: MapExtent) {
    this.mapExtent = e;
  }

  updateLayerConfig(e: MapLayer[]) {
    if (this._workflowSvc.designerVersionMode) {
      const layerIdx = {};

      this.mapConfig.layers.forEach(l => (layerIdx[l.name] = l));

      const lyrs = e.map(l => {
        return { ...l, isNew: !layerIdx[l.name] };
      });
      this.mapConfig.layers = lyrs;
    } else {
      this.mapConfig.layers = e;
    }
  }
  onMapImageLayerChanged(e: MapLayer[]) {
    this.updateLayerConfig(e);
    this.updateLayerIds();
    this.updateLayerBindings();
  }

  onMapImageLayerFirstLoad(e: MapLayer[]) {
    this.updateLayerConfig(e);
    this.updateLayerIds();
    this.updateLayerBindings();
  }

  onSaveMapExtent() {
    this.mapConfig.initialExtent = this.mapExtent;
  }
  onClearMapExtent() {
    this.mapConfig.initialExtent = null;
  }

  onZoomMapExtent() {
    this.esriMap.zoomToInitialExtent();
  }

  compareMapServiceFn(m1: MapService, m2: MapService): boolean {
    return m1 && m2 ? m1.id === m2.id : m1 === m2;
  }

  /* form validation functions */
  mustHaveAtLeastOneLayerValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      let hasVisibleLayer = false;
      const controls = (control as UntypedFormGroup).controls;
      for (const key in controls) {
        if (key.startsWith('layerVis')) {
          const fc = controls[key] as UntypedFormControl;
          const chkVis = fc.value as boolean;
          if (chkVis) {
            hasVisibleLayer = true;
          }
        }
      }
      const result = !hasVisibleLayer
        ? { visibleLayerMissing: { value: control.value } }
        : null;
      return result;
    };
  }

  onMapServiceChanged() {
    this.mapConfig.mapServiceId = this.mapService ? this.mapService.id : null;
  }
}

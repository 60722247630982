import {
  Component,
  OnInit,
  Input,
  ViewContainerRef,
  ViewChild,
  ComponentRef,
  Type,
  ComponentFactoryResolver,
  Output,
  EventEmitter
} from '@angular/core';
import { DataEntity } from '../../../../models/data-entities';
import { UntypedFormGroup } from '@angular/forms';
import { DataEntityFactory, ValidationService } from '../../../../services';
import { Activity, ActivityModel } from '../../../../models/activities';
import { DataEntityViewComponent } from '../data-entity-view/data-entity-view.component';

@Component({
  selector: 'wm-data-entity-input',
  templateUrl: './data-entity-input.component.html',
  styleUrls: ['./data-entity-input.component.css']
})
export class DataEntityInputComponent implements OnInit {
  @Input() entity: DataEntity;
  @Input() form: UntypedFormGroup;
  @Input() applicationId: string;
  @Input() activity: Activity<ActivityModel>;
  @Input() isPreview: boolean;
  @Output() calculateActivity: EventEmitter<string> = new EventEmitter();

  @ViewChild('entityContainer', { read: ViewContainerRef, static: true })
  entityContainer: ViewContainerRef;
  inputComponentRef: ComponentRef<DataEntityInputComponent>;
  viewComponentRef: ComponentRef<DataEntityViewComponent>;
  activities: string;

  constructor(private componentFactoryResolver: ComponentFactoryResolver) {}
  async persistChildComponent?() {
    if (this.inputComponentRef) {
      return this.inputComponentRef.instance.persistChildComponent();
    } else {
      return new Promise(resolve => {
        resolve(null);
      });
    }
  }
  ngOnInit() {
    if (this.entity && this.entity.completedDependentActivtyInfo) {
      this.activities = this.entity.completedDependentActivtyInfo
        .map(m => m.activityLabel)
        .join(',');
    }
    this.loadEntity();
  }

  unlockEntity() {
    this.entity.isLocked = false;
    this.loadEntity();
  }

  loadEntity() {
    const code = this.entity.dataEntityTypeCode;
    if (!(this.entity instanceof DataEntity)) {
      this.entity = DataEntityFactory.createDataEntity(code, this.entity);
    }
    if (this.entityContainer) {
      this.entityContainer.clear();

      // show the view component for the entity if there are completed dependencies
      if (this.entity.isLocked) {
        const viewCompType: Type<
          DataEntityViewComponent
        > = DataEntityFactory.createViewComponent(this.entity, this.entity);

        if (viewCompType && this.entityContainer) {
          this.viewComponentRef = this.entityContainer.createComponent(
            viewCompType
          );
          this.viewComponentRef.instance.entity = this.entity;
          this.viewComponentRef.instance.activity = this.activity;
          this.viewComponentRef.instance.persistChildComponent = this
            .viewComponentRef.instance.persistChildComponent
            ? this.viewComponentRef.instance.persistChildComponent
            : async function() {
                return new Promise(resolve => {
                  resolve(null);
                });
              };
        }
        this.form.removeControl(this.entity.templateCode);
      } else {
        if (!this.form.controls[this.entity.templateCode]) {
          this.form.addControl(
            this.entity.templateCode,
            ValidationService.createEntityValidationGroup(this.entity)
          );
        }

        const compType: Type<
          DataEntityInputComponent
        > = DataEntityFactory.createInputComponent(this.entity, null);
        if (compType && this.entityContainer) {
          this.inputComponentRef = this.entityContainer.createComponent(
            compType
          );
          this.inputComponentRef.instance.form = this.form;
          this.inputComponentRef.instance.entity = this.entity;
          this.inputComponentRef.instance.applicationId = this.applicationId;
          this.inputComponentRef.instance.activity = this.activity;
          this.inputComponentRef.instance.calculateActivity.subscribe(e => {
            this.calculateActivity.emit(e);
          });
          this.inputComponentRef.instance.isPreview = this.isPreview;
          // this.inputComponentRef.instance.persistChildComponent = this.inputComponentRef.instance.persistChildComponent
          // ? this.inputComponentRef.instance.persistChildComponent
          // : function() {
          //     //no persistChildComponent function added to the dataEntityInput/View/EditorComponent
          //   };
          this.inputComponentRef.instance.persistChildComponent = this
            .inputComponentRef.instance.persistChildComponent
            ? this.inputComponentRef.instance.persistChildComponent
            : async function() {
                return new Promise(resolve => {
                  resolve(null);
                });
              };
        }
      }
    }
  }
}

<div class="card">
  <div class="card-body">
    <label class="bmd-label-floating" for="roleName">Role</label>
    <input
      type="text"
      class="form-control"
      [formControl]="searchText"
      [(ngModel)]="selectedRole"
      name="roleName"
      [ngbTypeahead]="search"
      [resultTemplate]="rt"
      [inputFormatter]="formatter"
      #instance="ngbTypeahead"
    />
    <div class="row mt-2">
      <div class="col">
        <button
          class="btn btn-raised btn-primary"
          [disabled]="!selectedRole"
          (click)="associateRole()"
        >
          Add Role
        </button>
      </div>
      <div class="col">
        <button
          class="btn btn-raised btn-default float-right"
          (click)="cancel()"
        >
          Cancel
        </button>
      </div>
    </div>
  </div>
</div>

<ng-template #rt let-r="result" let-t="term">
  <span>{{ r.name }}</span>
</ng-template>

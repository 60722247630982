import { PaymentProcessorAccountConfigurationBase } from '../payment-processor-account-configuration-base';

export class CardpointePaymentProcessorAccountConfiguration extends PaymentProcessorAccountConfigurationBase {
  merchantId: string;

  constructor(
    options?: Partial<CardpointePaymentProcessorAccountConfiguration>
  ) {
    super(options);
    if (options) {
      Object.assign(this, options);
    }
  }
}

import { Subscription } from 'rxjs';
import { WorkflowCheckpoint } from './../../../../models/workflow-checkpoint';
import { WorkflowService } from './../../../../services/workflow.service';
import { ModalConfirmComponent } from './../../../../components/system/modal-confirm/modal-confirm.component';
import { ClientService } from './../../../../services/client.service';
import { ContractorType } from './../../../../models/contractor-type';
import { Client } from './../../../../models/client';
import { UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import {
  WorkflowContextService,
  ContractorService,
  SecurityService
} from 'src/app/services';
import { ToastrService } from 'ngx-toastr';
import { Actions } from 'src/app/models';
import { GPErrorHandler } from 'src/app/services/gp-error';
import { ContractorTypeListComponent } from 'src/app/components/contractors/contractor-type-list/contractor-type-list.component';

@Component({
  selector: 'wm-contractor-type-list-view',
  templateUrl: './contractor-type-list-view.component.html',
  styleUrls: ['./contractor-type-list-view.component.css']
})
export class ContractorTypeListViewComponent implements OnInit, OnDestroy {
    @ViewChild('contractorTypeList', { static: true })
  contractorTypeList: ContractorTypeListComponent;
  @ViewChild('copyTypeModal', { static: true }) copyTypeModal: ModalConfirmComponent;
  @ViewChild('checkpointList', { static: true }) checkpointList: ModalConfirmComponent;
  contractorTypes: any;
  canAddContractorType = false;
  copyTypeId: string;
  targetClientId: string;
  targetName: string;
  checkpoints: WorkflowCheckpoint[];
  checkpointTitle: string;
  targetForm: UntypedFormGroup;
  selectedWorkflowId: string;

  clients: Client[];

  private clientSubscription: Subscription;

  constructor(
    private _context: WorkflowContextService,
    private _router: Router,
    private _contractorSvc: ContractorService,
    private _toastr: ToastrService,
    private _clientSvc: ClientService,
    private _securitySvc: SecurityService,
    private _workflowSvc: WorkflowService,
    private _errorHandler: GPErrorHandler
  ) {}

  loadContractorTypes() {
    if (this._context.client) {
      this._contractorSvc
        .getContractorRegistrationTypes(this._context.client)
        .subscribe(types => {
          this.contractorTypes = types;
        });
    }
  }
  ngOnInit() {
    this.targetForm = new UntypedFormGroup({
      targetClientId: new UntypedFormControl('', Validators.nullValidator),
      targetName: new UntypedFormControl('', Validators.required)
    });

    this._securitySvc
      .isLoginEntitled(Actions.CONTRACTORS_MANAGE)
      .subscribe(result => {
        this.canAddContractorType = result;
      });

    this.loadContractorTypes();
    if (!this._context.client) {
      this.clientSubscription = this._context.client$.subscribe(res => {
        this.loadContractorTypes();
      });
    }
  }

  ngOnDestroy() {
    if (this.clientSubscription) {
      this.clientSubscription.unsubscribe();
      this.clientSubscription = null;
    }
  }

  deletedContractorType(contractorTypeId: string) {
    this.loadContractorTypes();
  }

  publishContractorType(contractorTypeId: string) {
    this._toastr.info('Publishing...', null, {
      disableTimeOut: true
    });

    this._contractorSvc
      .publishContractorType(contractorTypeId)
      .subscribe(success => {
        this._toastr.clear();

        if (success) {
          this._toastr.success('Published!');
        } else {
          this._toastr.error(
            'Something went wrong while publishing the Contractor Type. Please contact support if this persists.'
          );
        }

        this.contractorTypeList.resetQueuedMigrations();
        this.loadContractorTypes();
      });
  }

  createContractorType() {
    this._router.navigate([
      'admin',
      'jurisdiction',
      this._context.client.id,
      'contractors',
      'types',
      'add'
    ]);
  }

  copyType(ctId) {
    this._contractorSvc
      .copyContractorType({
        contractorTypeId: this.copyTypeId,
        clientId: this.targetClientId,
        name: this.targetName
      })
      .subscribe(
        () => {
          this._toastr.success('Contractor Type Copied');
          this.copyTypeModal.cancel();
          this.loadContractorTypes();
        },
        err => {
          this.copyTypeModal.cancel();
          this._errorHandler.handleError(err);
        }
      );
  }

  startTypeCopy(ct: ContractorType) {
    if (this._context.client) {
      this.targetClientId = this._context.client.id;
    }
    this.targetName = `Copy Of - ${ct.name}`;

    this.copyTypeId = ct.id;
    this._clientSvc.getAdminClients().subscribe(c => {
      this.clients = c;
      this.copyTypeModal.open();
    });
  }

  showHistory(e: { id: string; name: string }) {
    this._workflowSvc.getWorkflowCheckpoints(e.id).subscribe(res => {
      this.selectedWorkflowId = e.id;
      this.checkpointTitle = e.name;
      this.checkpoints = res;
      this.checkpointList.open();
    });
  }

  restoreCheckpoint(e) {
    this.checkpointList.cancel();
    this._toastr.success(`Workflow Restored`);
    this.checkpointList.dismis('saved');
  }
}

import { Component, OnInit, Input } from '@angular/core';
import { ExternalDataConnection } from '../../../models';
import { Router } from '@angular/router';
import { WorkflowContextService } from '../../../services';

@Component({
  selector: 'wm-external-data-connections-list',
  templateUrl: './external-data-connections-list.component.html',
  styleUrls: ['./external-data-connections-list.component.css']
})
export class ExternalDataConnectionsListComponent implements OnInit {
  @Input() allowSelect = true;
  @Input() externalDataConnections: ExternalDataConnection[];

  constructor(
    private _router: Router,
    private _context: WorkflowContextService
  ) {}

  editExternalDataConnnection(edc: ExternalDataConnection) {
    if (this.allowSelect) {
      this._router.navigate([
        'admin',
        'jurisdiction',
        this._context.client.id,
        'settings',
        'data',
        'edit',
        edc.id
      ]);
    }
  }

  ngOnInit() {}
}

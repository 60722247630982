import { DataTableHelpers } from './../../../components/system/datatable/datatable-helper';
import { Component, OnInit } from '@angular/core';
import { TableColumn } from '@swimlane/ngx-datatable';
import { ItemSearchOptionField } from 'src/app/components/filter-list/models/filterClasses';
import { RegistrationStatusPipe } from 'src/app/pipes/registration-status.pipe';
import { ComponentIds } from 'src/app/models/component-ids';

@Component({
  selector: 'wm-contractor-search',
  templateUrl: './contractor-search.component.html',
  styleUrls: ['./contractor-search.component.css']
})
export class ContractorSearchComponent implements OnInit {
  id = ComponentIds.CONTRACTOR_SEARCH;

  public columns: TableColumn[] = [
    {
      prop: 'registerApplicationNumber',
      name: 'Application Number'
    },
    {
      prop: 'contractorName',
      name: 'Name'
    },
    {
      prop: 'contractor',
      name: 'Business Name'
    },
    {
      prop: 'address', // new
      name: 'Address'
    },
    {
      prop: 'phone', // new
      name: 'Phone'
    },
    {
      prop: 'email', // new
      name: 'Email'
    },
    {
      prop: 'type',
      name: 'Contractor Type'
    },
    {
      prop: 'registeredOn',
      name: 'Registration Date',
      pipe: this.dtHelpers.getDatePipe()
    },
    {
      prop: 'expiresOn',
      name: 'Expiration Date',
      pipe: this.dtHelpers.getDatePipe()
    },
    {
      prop: 'status',
      name: 'Status',
      pipe: new RegistrationStatusPipe()
    },
    {
      prop: 'submittedBy',
      name: 'Submitted By'
    }
  ];

  filterOptions: ItemSearchOptionField[];

  constructor(private dtHelpers: DataTableHelpers) {}

  ngOnInit() {
    this.filterOptions = [];
  }
}

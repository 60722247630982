import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'wm-user-renewable-registration-details',
  templateUrl: './user-renewable-registration-details.component.html',
  styleUrls: ['./user-renewable-registration-details.component.css']
})
export class UserRenewableRegistrationDetailsComponent implements OnInit {

  registrationId: string;

  constructor(private _route: ActivatedRoute) { }

  ngOnInit() {
    this._route.params.subscribe(parms => {
      this.registrationId = parms['registrationId'];
    });
  }

}

<form [formGroup]="form" novalidate>
  <div class="form-group">
    <label class="bmd-label-floating" for="contact-list-data-entity-select"
      >Select The Contact To Display On the Application:</label
    >
    <select
      class="form-control"
      [(ngModel)]="contactListEntity.selectedContactId"
      formControlName="contact-list-data-entity-select"
      (change)="onChange($event.target.value)"
    >
      <option *ngFor="let c of contacts" [ngValue]="c.id"
        >{{ c.lastName }}, {{ c.firstName }}
      </option>
    </select>
  </div>
</form>

import { Component, OnInit } from '@angular/core';
import { DataEntityEditorBaseComponent } from '../../data-entity-editor-base/data-entity-editor-base.component';

@Component({
  selector: 'wm-today-data-entity-editor',
  templateUrl: './today-data-entity-editor.component.html',
  styleUrls: ['./today-data-entity-editor.component.css']
})
export class TodayDataEntityEditorComponent
  extends DataEntityEditorBaseComponent
  implements OnInit {
  constructor() {
    super();
  }

  ngOnInit() {}
}

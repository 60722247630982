export class ExternalDataSource {
  type: string;
  key: string;
  label: string;
  canEditExternalValueDefault: boolean;
  sourceConfig: ExternalDataSourceConfig;
}

export class ExternalDataSourceConfig {}

export class ParcelSearchExternalSource extends ExternalDataSource {
  activityId: string;

  constructor() {
    super();

    this.type = 'parcel-search-external-source';
  }
}

export class ParcelSearchExternalSourceConfig extends ExternalDataSourceConfig {
  type: string;
  options: ParcelSearchExternalSourceOption[];
  selectedOption: ParcelSearchExternalSourceOption;
}

export class ParcelSearchExternalSourceOption {
  parcelSearchActivityId: string;
  fieldName: string;
  fieldAlias: string;
}

export class RegistrationExternalSource extends ExternalDataSource {
  constructor() {
    super();

    this.type = 'registration-external-source';
  }
}

export class RegistrationExternalSourceConfig extends ExternalDataSourceConfig {
  type: string;
  options: RegistrationExternalSourceOption[];
  selectedMappings: RegistrationExternalSourceSelectedMappings;
}

export class RegistrationExternalSourceOption {
  isThisWorkflow: boolean;
  sourceWorkflowId: string;
  sourceWorkflowDraftVersionId: string;
  sourceWorkflowDraftVersionName: string;
}

export class RegistrationExternalSourceSelectedMappings {
  mapFromThisWorkflow: boolean;
  otherWorkflowMappings: RegistrationExternalSourceMapping[];

  public constructor(
    options?: Partial<RegistrationExternalSourceSelectedMappings>
  ) {
    if (options) {
      Object.assign(this, options);
    }
  }
}

export class RegistrationExternalSourceMapping {
  sourceWorkflowId: string;
  fieldTemplateCode: string;

  public constructor(options?: Partial<RegistrationExternalSourceMapping>) {
    if (options) {
      Object.assign(this, options);
    }
  }
}

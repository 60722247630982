import { Component, OnInit, OnDestroy } from '@angular/core';
import { WorkflowContextService, DataService } from '../../../../services';
import { Role } from '../../../../models';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'wm-role-list-view',
  templateUrl: './role-list-view.component.html',
  styleUrls: ['./role-list-view.component.css']
})
export class RoleListViewComponent implements OnInit, OnDestroy {
  roles: Role[];
  clientSubscribe: Subscription;

  constructor(
    private _context: WorkflowContextService,
    private _service: DataService,
    private _router: Router,
    private _route: ActivatedRoute
  ) {}

  getRoles() {
    this._service
      .searchRoles(this._context.client, null)
      .subscribe(roles => {
        this.roles = roles;
      });
  }

  ngOnInit() {
    this._route.data.subscribe(data => {
      if (data.loadGlobal) {
        this.getRoles();
      } else {
        if (this._context.client) {
          this.getRoles();
        } else {
          this.clientSubscribe = this._context.client$.subscribe(res => {
            this.getRoles();
          });
        }
      }
    });
  }

  ngOnDestroy() {
    //Stop listening to client changes.
    if (this.clientSubscribe) {
      this.clientSubscribe.unsubscribe();
    }
  }

  removeRole(e: Role) {
    const index = this.roles.indexOf(e);
    this.roles.splice(index, 1);
  }

  addRole() {
    if (this._context.client) {
      this._router.navigate([
        'admin',
        'jurisdiction',
        this._context.client.id,
        'users',
        'roles',
        'add'
      ]);
    } else {
      this._router.navigate(['/admin/global/roles/add']);
    }
  }
}

<app-loader *ngIf="!checkpoints"></app-loader>

<p *ngIf="checkpoints && checkpoints.length === 0">No history to display</p>

<wm-modal-confirm
  *ngIf="checkpoints && checkpoints.length > 0 && workflow"
  [commandText]="'Cleanup History'"
  [showButtonText]="true"
  [btnColor]="'danger'"
  [title]="'Cleanup History'"
  id="cleanup_history"
  [moduleId]="'cleanup_history'"
  (accepted)="cleanupHistory($event)"
>
  <p>
    This will cleanup all of the "Auto" checkpoints for this workflow. Are you
    sure you want to cleanup the history of this workflow ({{
      workflow.version.name
    }})?
  </p>
  <p>
    Cleaning up the history of this workflow will have the following effects:
  </p>
  <ul>
    <li>
      You will not be able to go back to any of the checkpoints that have been
      removed.
    </li>
  </ul>
</wm-modal-confirm>

<ul class="list-group" *ngIf="checkpoints && checkpoints.length > 0">
  <li *ngFor="let cp of checkpoints" class="list-group-item">
    <button
      class="btn btn-raised btn-primary"
      [disabled]="isRestoring(cp.id)"
      (click)="restoreVersion(cp.id)"
    >
      <span *ngIf="!isRestoring(cp.id)">Restore</span>

      <span *ngIf="isRestoring(cp.id)">
        <span
          class="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
        Restoring...
      </span>
    </button>

    <div class="bmd-list-group-col">
      <p class="list-group-item-heading">{{ cp.notes }}</p>
      <p class="list-group-item-text">
        {{ cp.createdOn | date: 'short' }}
      </p>
    </div>
  </li>
</ul>

import { Injectable, Inject, forwardRef } from '@angular/core';
import { WorkflowContextService, Utilities } from '.';
import { Observable } from 'rxjs';
import { User, LoginResponse } from '../models';
import { SaveUserResponse } from '../models/user';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  getUserListByEmail(email: string): Observable<User[]> {
    return Utilities.getDataList<User>(
      this._context,
      this._http,
      User,
      '/api/security/GetUserListByEmail/',
      {
        email
      }
    );
  }
  constructor(
    @Inject(forwardRef(() => WorkflowContextService))
    private _context: WorkflowContextService,
    @Inject(forwardRef(() => HttpClient)) private _http: HttpClient
  ) {}

  getUsers(): Observable<User[]> {
    return Utilities.getDataList<User>(
      this._context,
      this._http,
      User,
      '/api/security/getUsers/'
    );
  }
  getAdminUsers(): Observable<User[]> {
    return Utilities.getDataList<User>(
      this._context,
      this._http,
      User,
      '/api/security/getAdminUsers/'
    );
  }
  changePwd(
    username: string,
    oldPassword: string,
    newPassword: string,
    confirmPassword: string
  ) {
    return Utilities.postData<User>(
      this._context,
      this._http,
      User,
      '/api/security/ChangePassword/',
      null,
      null,
      null,
      {
        username: username,
        oldPassword: oldPassword,
        newPassword: newPassword,
        confirmPassword: confirmPassword
      }
    );
  }
  authenticateByToken(token): Observable<Partial<LoginResponse>> {
    return Utilities.postData<LoginResponse>(
      this._context,
      this._http,
      LoginResponse,
      '/api/security/AuthByMigrationToken',
      null,
      null,
      null,
      {
        token: token
      }
    );
  }
  authenticate(): Observable<Partial<LoginResponse>> {
    if (this._context.refreshToken) {
      return Utilities.postData<LoginResponse>(
        this._context,
        this._http,
        LoginResponse,
        '/token',
        {
          refresh_token: this._context.refreshToken,
          grant_type: 'refresh_token'
        },
        'application/x-www-form-urlencoded'
      ).pipe(
        map((e: any) => {
          // here to deal with case change.
          e.access_Token = e.access_token;
          e.refresh_Token = e.refresh_token;
          return e;
        })
      );
    }
    return new Observable();
  }
  login(userName: string, pwd: string): Observable<LoginResponse> {
    const password: string = pwd;

    return Utilities.postData<LoginResponse>(
      this._context,
      this._http,
      LoginResponse,
      '/token',
      {
        userName: userName,
        password: password,
        grant_type: 'password'
      },
      'application/x-www-form-urlencoded'
    ).pipe(
      map((e: any) => {
        // here to deal with case change.
        e.access_Token = e.access_token;
        e.refresh_Token = e.refresh_token;
        return e;
      })
    );
  }
  getUsersByEmail(email: string, redirectURL: string): Observable<boolean> {
    return Utilities.postData<boolean>(
      this._context,
      this._http,
      null,
      '/api/security/GetUsersByEmail/?returnURL=' + redirectURL,
      null,
      null,
      null,
      { email: email }
    );
  }

  resetPassword(userName: string, redirectURL: string): Observable<boolean> {
    return Utilities.postData<boolean>(
      this._context,
      this._http,
      null,
      '/api/security/resetPassword/?returnURL=' + redirectURL,
      null,
      null,
      null,
      { userName: userName }
    );
  }
  saveResetPassword(
    userName: string,
    token: string,
    newPassword: string
  ): Observable<LoginResponse> {
    return Utilities.postData<LoginResponse>(
      this._context,
      this._http,
      null,
      '/api/security/saveResetPassword/',
      { userName: userName, token: token, newPassword: newPassword },
      null,
      null,
      null
    );
  }
  logOut(user: Partial<User>): Observable<boolean> {
    return Utilities.postData<boolean>(
      this._context,
      this._http,
      null,
      '/api/security/logOut/',
      null,
      null,
      null,
      { userId: user.id }
    );
  }
  getUserByLogin(userName: string): Observable<User> {
    return Utilities.getData<User>(
      this._context,
      this._http,
      User,
      '/api/security/getUserByLogin/',
      { userName: userName }
    );
  }
  saveUser(user: Partial<User>): Observable<SaveUserResponse> {
    user.members = [];
    user.contractors = [];
    return Utilities.postData<SaveUserResponse>(
      this._context,
      this._http,
      SaveUserResponse,
      '/api/security/saveUser/',
      user
    );
  }
  getUser(userId: string): Observable<User> {
    return Utilities.getData<User>(
      this._context,
      this._http,
      User,
      '/api/security/getUser/' + userId
    );
  }
  deleteUser(user: Partial<User>): Observable<User> {
    return Utilities.postData<User>(
      this._context,
      this._http,
      User,
      '/api/security/deleteUser/',
      user
    );
  }
  registerUser(
    user: User,
    returnUrl?: string,
    generatePassword: boolean = false,
    invitationId?: string
  ): Observable<LoginResponse> {
    return Utilities.postData<LoginResponse>(
      this._context,
      this._http,
      LoginResponse,
      '/api/security/registeruser/',
      user,
      null,
      null,
      {
        returnUrl,
        generatePassword,
        invitationId
      }
    );
  }
  registerUserFromApplication(
    user: User,
    applicationId: string,
    returnUrl?: string
  ): Observable<LoginResponse> {
    return Utilities.postData<LoginResponse>(
      this._context,
      this._http,
      LoginResponse,
      '/api/security/registeruserFromApplication',
      { user: user, appId: applicationId, returnUrl }
    );
  }
  verifyUser(token: string): Observable<User> {
    return Utilities.postData<User>(
      this._context,
      this._http,
      User,
      '/api/security/verifyUser/',
      null,
      null,
      null,
      { token: token }
    );
  }
}

import { DataEntity } from 'src/app/models/data-entities';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'wm-de-label',
  templateUrl: './de-label.component.html',
  styleUrls: ['./de-label.component.css']
})
export class DeLabelComponent implements OnInit {
  @Input() for: string;
  @Input() entity: DataEntity;
  @Input() displayOnly = false;

  constructor() {}

  ngOnInit() {}
}

import {
  ButtonStyle,
  ModalConfirmComponent
} from './../../../../system/modal-confirm/modal-confirm.component';
import { WorkflowService } from './../../../../../services/workflow.service';
import { ParentReference } from './../../../../../models/data-entities/data-entity';
import { ValidationResponse } from './../../../../../models/validation';
import { ContractorService, DataEntityFactory } from 'src/app/services';
import {
  FormActivity,
  FormActivityModel,
  SingleColumnFormLayoutModel
} from './../../../../../models/activities/form-activity';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild
} from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  UntypedFormControl,
  Validators
} from '@angular/forms';
import {
  DataEntityLayoutModel,
  ActivityModel,
  Activity
} from '../../../../../models/activities';
import { Workflow } from '../../../../../models';
import { WorkflowContextService } from '../../../../../services';
import { DataEntityEditorComponent } from '../../../data-entities/data-entity-editor/data-entity-editor.component';
import * as _ from 'lodash';
import { customAlphabet } from 'nanoid';
import {
  CustomFieldMigrationQueueRequest,
  CustomFieldDataOperationType
} from 'src/app/models/custom-field-data-operations';
import { ToastrService } from 'ngx-toastr';

export interface MoveDataEntityConfig {
  type: string;
  activityId: string;
}

const nanoid = customAlphabet('0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ', 6);

@Component({
  selector: 'wm-data-entity-item',
  templateUrl: './data-entity-item.component.html',
  styleUrls: ['./data-entity-item.component.css']
})
export class DataEntityItemComponent implements OnInit {
  @Input() de: any;
  @Output() editEntity: EventEmitter<any> = new EventEmitter<any>();
  @Output() deleteEntity: EventEmitter<any> = new EventEmitter<any>();
  @Output() saveEntity: EventEmitter<any> = new EventEmitter<any>();
  @Output() saveActivity: EventEmitter<
    Activity<ActivityModel>
  > = new EventEmitter<Activity<ActivityModel>>();
  @Input() workflow: Workflow;
  @Input() activity: Activity<ActivityModel>;
  @ViewChild('deEditor', { static: false }) deEditor: DataEntityEditorComponent;
  @ViewChild('moveCopyModal', { static: false })
  moveCopyModal: ModalConfirmComponent;
  @ViewChild('confirmMoveModal', { static: false })
  confirmMoveModal: ModalConfirmComponent;

  form: UntypedFormGroup;
  deId: string;

  moveForm: UntypedFormGroup;

  validationError: ValidationResponse;

  ButtonStyle = ButtonStyle;

  customFieldDataOperationType: any = null;
  CustomFieldDataOperationType = CustomFieldDataOperationType;

  selectedActivityId: string;

  get hasErrors() {
    return this.validationError && this.validationError.messages.length > 0;
  }

  get formActivities() {
    return this.workflow.version.graph
      .getActivities(this.workflow.designStatus)
      .filter(
        a =>
          (a.type === 'form-activity' ||
            a.type === 'contractor-info-form-activity' ||
            a.type === 'contractor-registration-info-form-activity') &&
          (this.moveForm.controls['type'].value === 'copy' ||
            a.id !== this.activity.id)
      );
  }

  get moveLabel() {
    return this.moveForm.controls['type'].value === 'move' ? 'Move' : 'Copy';
  }

  get modalLabel() {
    return `${this.activity.model.screenName} - ${this.de.entity.templateCode}`;
  }

  get selectedActivityName() {
    return this.selectedActivityId
      ? this.formActivities.find(a => a.id === this.selectedActivityId).model
          .screenName
      : '';
  }

  constructor(
    private _fb: UntypedFormBuilder,
    private modalService: NgbModal,
    public context: WorkflowContextService,
    public _workflowSvc: WorkflowService,
    public _contractorSvc: ContractorService,
    public _toastr: ToastrService
  ) {
    this.form = this._fb.group({});
  }

  updateEntity(e) {
    this.de.entity = e;
  }

  onSave() {
    if (this.deEditor) {
      this.deEditor.save();
    }
    this.saveEntity.emit(this.de.entity);
    this.deId = null;
  }
  onCancel() {
    this.deId = null;
  }

  loadEditor() {
    this.deId = this.de.entity.templateCode;
  }

  onMoveCopyCanceled() {
    this.resetMoveForm();
  }

  onMoveCopyAccepted() {
    this.moveCopyModal.setProcessing();

    this.prepareToMove(this.moveForm.value);
  }

  acceptMoveCopyModal() {
    this.moveCopyModal.cancelProcessing();

    // dismiss the modal without modal-confirm emitting another accepted event
    // a canceled event will emit which will be handled by this componenent by resetting the form
    this.moveCopyModal.cancel();
  }

  highlightActivity(activityId) {
    this._workflowSvc.centerOnActivity$.emit(activityId);
    return false;
  }

  showConditions(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-move-title' });
  }

  getFormModel(activity: FormActivity): SingleColumnFormLayoutModel {
    return activity.model.formLayoutModel as SingleColumnFormLayoutModel;
  }

  createCopy(newActivity: FormActivity, moveConfig: MoveDataEntityConfig) {
    const newDe = DataEntityFactory.createDataEntity(
      this.de.entity.dataEntityTypeCode,
      _.cloneDeep(this.de.entity)
    );
    newDe.parent = new ParentReference({ id: newActivity.id });

    if (moveConfig.type === 'copy') {
      newDe.templateCode = `${newDe.templateCode}_COPY_${nanoid()}`;
    }

    return newDe;
  }

  onAbortMove() {
    this.moveCopyModal.cancelProcessing();
  }

  prepareToMove(moveConfig: MoveDataEntityConfig) {
    // check for situations where moving the entity constitutes a migration of custom field data
    this.customFieldDataOperationType = null;

    const selectedActivityType = this.formActivities.find(
      a => a.id === this.selectedActivityId
    ).type;

    this.customFieldDataOperationType =
      this.activity.type === 'contractor-info-form-activity' &&
      selectedActivityType === 'contractor-registration-info-form-activity'
        ? CustomFieldDataOperationType.MoveEntityFromContractorToRegistrationForm
        : this.activity.type === 'contractor-registration-info-form-activity' &&
          selectedActivityType === 'contractor-info-form-activity'
        ? CustomFieldDataOperationType.MoveEntityFromRegistrationToContractorForm
        : null;

    if (this.customFieldDataOperationType) {
      this.confirmMoveModal.open();
    } else {
      this.move(moveConfig);
    }
  }

  move(moveConfig: MoveDataEntityConfig) {
    this._workflowSvc
      .getActivityEditor(
        this.workflow.id,
        moveConfig.activityId,
        !this.workflow.version.isDraft ? this.workflow.version.id : null
      )
      .subscribe(activity => {
        const newActivity: FormActivity = activity as FormActivity;

        // if any of the customFieldMigration situations apply, build and send the custom field migration queue request
        if (this.customFieldDataOperationType) {
          const request = new CustomFieldMigrationQueueRequest({
            migrationType: this
              .customFieldDataOperationType as CustomFieldDataOperationType,
            templateCode: this.de.entity.templateCode,
            workflowId: this.workflow.id
          });

          this._contractorSvc.queueCustomFieldMigration(request).subscribe(
            success => {
              if (success) {
                // perform the move of the de
                this.moveDataEntity(newActivity, moveConfig);
              }
            },
            err => {
              this.acceptMoveCopyModal();

              this._toastr.error(
                'Error when queueing Custom Fields update. Please contact support if this error persists.',
                `Could not ${this.moveLabel.toLowerCase()} ${
                  this.de.entity.label
                }.`,
                { disableTimeOut: true }
              );
            }
          );
        } else {
          // perform the move/copy of the de
          this.moveDataEntity(newActivity, moveConfig);
        }
      });
  }

  moveDataEntity(
    newActivity: FormActivity<FormActivityModel>,
    moveConfig: MoveDataEntityConfig
  ) {
    const newFormModel = this.getFormModel(newActivity);

    const newDe = this.createCopy(newActivity, moveConfig);
    newDe['allowTemplateCodeEdit'] = true;

    // order so the largest displayOrder is first
    const orderedEntities = newFormModel.columnEntities.sort(
      (a, b) => b.displayOrder - a.displayOrder
    );

    newFormModel.columnEntities.push(
      new DataEntityLayoutModel({
        entity: newDe,
        displayOrder:
          orderedEntities && orderedEntities.length > 0
            ? orderedEntities[0].displayOrder + 1
            : 0
      })
    );

    if (moveConfig.type === 'move') {
      const oldFormModel = this.getFormModel(this.activity as FormActivity);
      oldFormModel.columnEntities = oldFormModel.columnEntities.filter(
        a => a.entity.templateCode !== this.de.entity.templateCode
      );
    }

    // trigger a save for the destination activity if we are copying/moving to another activity.
    this.saveActivity.emit(newActivity);

    // trigger a save for the current activity if we are moving to another activity.
    if (newActivity.id !== this.activity.id && moveConfig.type === 'move') {
      this.saveActivity.emit(this.activity);
    }

    this.acceptMoveCopyModal();
  }

  resetMoveForm() {
    this.moveForm.reset();
    this.moveForm.patchValue({ type: 'move' });
  }

  ngOnInit() {
    this.moveForm = new UntypedFormGroup({
      activityId: new UntypedFormControl(null, [Validators.required]),
      type: new UntypedFormControl('move', [Validators.required])
    });
  }
}

import { ComponentFactoryResolver } from '@angular/core';
import { DataEntityInputComponent } from './../../data-entity-input/data-entity-input.component';
import { Component, OnInit } from '@angular/core';
import { RichTextDataEntity } from 'src/app/models/data-entities/rich-text-data-entity';

@Component({
  selector: 'wm-rich-text-data-entity-input',
  templateUrl: './rich-text-data-entity-input.component.html',
  styleUrls: ['./rich-text-data-entity-input.component.css']
})
export class RichTextDataEntityInputComponent extends DataEntityInputComponent
  implements OnInit {
  public de: RichTextDataEntity;

  constructor(componentFactoryResolver: ComponentFactoryResolver) {
    super(componentFactoryResolver);
  }

  ngOnInit() {
    this.de = this.entity as RichTextDataEntity;
  }
}

import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { RichTextDataEntity } from 'src/app/models/data-entities/rich-text-data-entity';
import { WorkflowApplication } from './../../../models/workflow-application';
import { Component, OnInit, Input } from '@angular/core';
import { DocumentDataEntity } from 'src/app/models/data-entities';
import { Actions } from 'src/app/models';
import { SecurityService, DocumentService } from 'src/app/services';

@Component({
  selector: 'wm-application-external-note-editor',
  templateUrl: './application-external-note-editor.component.html',
  styleUrls: ['./application-external-note-editor.component.css']
})
export class ApplicationExternalNoteEditorComponent implements OnInit {
  @Input() application: WorkflowApplication;
  notesDE: RichTextDataEntity;
  documentsDE: DocumentDataEntity;
  form: UntypedFormGroup;
  @Input() readOnly = false;

  constructor(
    private _securitySvc: SecurityService,
    private _uploadSvc: DocumentService
  ) {}

  async ngOnInit() {
    if (!this.form) {
      this.form = new UntypedFormGroup({
        externalDocuments: new UntypedFormControl('', Validators.nullValidator),
        externalNotes: new UntypedFormControl(
          this.application.externalNotes,
          Validators.nullValidator
        )
      });
    } else {
      this.form.addControl(
        'externalDocuments',
        new UntypedFormControl('', Validators.nullValidator)
      );
      this.form.addControl(
        'externalNotes',
        new UntypedFormControl(
          this.application.externalNotes,
          Validators.nullValidator
        )
      );
    }

    this.form.controls['externalNotes'].valueChanges.subscribe(e => {
      this.application.externalNotes = e;
    });

    this.notesDE = new RichTextDataEntity({
      html: this.application.externalNotes,
      value: this.application.externalNotes,
      templateCode: 'externalNotes'
    });

    const externalDocs = await this._uploadSvc
      .getDocuments([
        'Application',
        this.application.id,
        'externalDocuments',
        'externalDocuments'
      ])
      .toPromise();

    this.documentsDE = new DocumentDataEntity({
      templateCode: 'externalDocuments',
      documents: externalDocs,
      validDocumentTypes: 'pdf,png,jpg,gif,jpeg'
    });

    this._securitySvc
      .isLoginEntitled(Actions.WORKFLOW_APPLICATIONS_EDIT)
      .subscribe(result => {
        this.readOnly = this.readOnly || !result;
      });
  }
}

import { AbstractControl } from '@angular/forms';
import { Observable, throwError } from 'rxjs';
import { Renderer2 } from '@angular/core';
import * as _ from 'underscore';

import * as Models from '../../models';
import { WorkflowContextService, NoParamConstructor } from '../';
import {
  HttpParams,
  HttpClient,
  HttpErrorResponse
} from '@angular/common/http';
import { map, catchError, timeout } from 'rxjs/operators';
import { JsonNetDecycle } from './json-net-decycle';
import { ContractorDocument } from 'src/app/models/contractor-document';
import * as moment from 'moment';

export class Utilities {
  static EMPTY_GUID = '00000000-0000-0000-0000-000000000000';

  static formatCurrency(value: any): any {
    return '$' + parseFloat(value.toString()).toFixed(2);
  }
  static formatDate(value: string): string {
    return new Date(value.split('T').join(' ')).toDateString();
  }
  static makeObservable(callback, delay: number = 333) {
    const $ua = Observable.create(function(o) {
      setTimeout(() => {
        o.next(callback());
        o.complete();
      }, delay);
    });

    return $ua;
  }

  static generateId() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      const r = (Math.random() * 16) | 0,
        v = c == 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  static insertValueCodeMirror(editor: any, value: string) {
    editor.replaceSelection(value);
  }

  static getClosestToken(
    element: HTMLTextAreaElement
  ): {
    text: string;
    tokenIndex: number;
    isInsideToken: boolean;
    tokenValue: string;
  } {
    let isInside = false;

    const cursorLocation = element.selectionStart;
    const inputText: string = element.value;

    const tokenExp: RegExp = /\$\{(\w+)?\}?/g;
    const matches = inputText.match(tokenExp);
    let matchedToken: string;

    // iterate over matches
    if (matches) {
      matches.forEach((value: string, index: number) => {
        const tokenIndex = inputText.indexOf(value, 0);

        if (tokenIndex > -1) {
          if (
            cursorLocation >= tokenIndex &&
            cursorLocation <= tokenIndex + value.length
          ) {
            matchedToken = value;
            isInside = true;
            return false; // exit foreach
          }
        }
      });
    }

    if (isInside) {
      const tokenStartIndex = inputText
        .substring(0, cursorLocation)
        .lastIndexOf('${');
      let tokenEndIndex = inputText.indexOf('}', tokenStartIndex);
      if (tokenEndIndex == -1) {
        tokenEndIndex = inputText.length;
      }
      const returnText = matchedToken.replace('${', '').replace('}', ''); // inputText;
      return {
        text: matchedToken,
        tokenIndex: tokenStartIndex,
        isInsideToken: isInside,
        tokenValue: returnText
      };
    } else {
      return {
        text: '${',
        tokenIndex: cursorLocation + 1,
        isInsideToken: false,
        tokenValue: ''
      };
    }
  }

  static selectText(
    element: HTMLTextAreaElement,
    renderer: Renderer2,
    value: string,
    tokenStartIndex: number
  ) {
    const text = element.value;

    const startIndex = text.indexOf(value, tokenStartIndex);
    element.selectionStart = startIndex;
    element.selectionEnd = startIndex + value.length;
  }

  static insertValue(
    element: HTMLTextAreaElement,
    renderer: Renderer2,
    value: string,
    formControl?: AbstractControl
  ) {
    const selectionStart = element.selectionStart;
    const selectionEnd = element.selectionEnd;
    const inputText = element.value;

    // insert value
    const textBefore: string = inputText.substr(0, selectionStart);
    const textAfter: string = inputText.substr(selectionEnd);

    const newText = textBefore + value + textAfter;
    const newLocation: number = selectionStart + value.length;

    if (formControl) {
      formControl.setValue(newText);
    } else {
      renderer.setProperty(element, 'value', newText);
    }

    // we can't rely on setting the model if we want to set the cursor position
    element.selectionStart = newLocation;
    element.selectionEnd = newLocation;
  }

  static stringAsDate(stringDate: string): Date {
    return new Date(stringDate);
  }

  // pass a clientTimeZoneOffset if we need to convert a browser-generated date.
  // things like user input dates should not need converted here, they will be handled
  // in the persistence layer
  static getDateTimeString(date: Date, clientTimeZoneOffset?: number): string {
    if (clientTimeZoneOffset) {
      date.setMinutes(date.getMinutes() + date.getTimezoneOffset());
      date.setHours(date.getHours() + clientTimeZoneOffset);
    }

    return moment(date).format('YYYY-MM-DD HH:mm:ss');
  }

  static addSpaceToTitleCase(
    input: string,
    uppercaseFirstLetter: boolean = true
  ): string {
    let items: string[] = input.split(/(?=[A-Z])/g); // input.split(/([a-z0-9])([A-Z])/g);

    if (uppercaseFirstLetter) {
      items = items.map(v => v.slice(0, 1).toUpperCase() + v.slice(1));
    }

    return items.join(' '); // input.replace(, '$1 $2');
  }

  static getAzureUrl(doc: any): string {
    return (
      Utilities.getRootURL() +
      '/home/GetAzureDocument?documentPath=' +
      escape(doc.documentPath)
    );
  }

  static getRootURL(): string {
    return window['_globalConfig'].api;
  }

  static getHeaders(
    context: WorkflowContextService,
    contentType?: string,
    additionalHeaders?: { [key: string]: string }
  ) {
    const now = new Date();

    const headers = {
      'Content-Type': contentType || 'application/json',
      ClientId: context.client ? context.client.id : '',
      ViewAsRole: context.viewAsRole ? context.viewAsRole.id : '',
      UserTimeZoneOffset: new Date().getTimezoneOffset().toString()
    };

    if (additionalHeaders) {
      for (const h in additionalHeaders) {
        headers[h] = additionalHeaders[h];
      }
    }

    return headers;
  }

  static buildParams(params?: { [key: string]: any }): HttpParams {
    const searchParams: HttpParams = new HttpParams();

    if (params) {
      for (const a in params) {
        // searchParams.append(a, params[a]);
        searchParams.set(a, params[a]);
      }
    }

    return searchParams;
  }

  static performGetList<T>(
    http: HttpClient,
    type: NoParamConstructor<T>,
    url,
    options
  ): Observable<T[]> {
    return http.get<T[]>(url, options).pipe(
      map((res: any) => {
        const resp = res as T[];

        return resp ? this.toListObject<T>(type, resp) : null;
      }),
      catchError(err => Utilities.handleError(err))
    );
  }

  static performGet<T>(
    http: HttpClient,
    type: NoParamConstructor<T>,
    url,
    options
  ): Observable<T> {
    return http.get<T>(url, options).pipe(
      map((res: any) => {
        const resp = res as T;
        const result: T = resp != null ? this.toObject<T>(type, <T>resp) : null;
        return result;
      }),
      catchError(err => Utilities.handleError(err))
    );
  }

  static getDataList<T>(
    context: WorkflowContextService,
    http: HttpClient,
    type: NoParamConstructor<T>,
    url: string,
    params?: { [key: string]: any }
  ): Observable<T[]> {
    const headers = this.getHeaders(context);

    const searchParams: HttpParams = this.buildParams(params);

    const options = { headers: headers }; // , params: searchParams };

    let urlSeparator = '?';

    if (params) {
      if (url.indexOf('?') > -1) {
        urlSeparator = '&';
      }
    }

    url = this.getRootURL() + url;

    for (const param in params) {
      if (Array.isArray(params[param])) {
        for (let x = 0; x < params[param].length; x++) {
          url += urlSeparator + param + '=' + params[param][x];
          urlSeparator = '&';
        }
      } else {
        url += urlSeparator + param + '=' + params[param];
        urlSeparator = '&';
      }
    }

    return this.performGetList<T>(http, type, url, options);
  }

  static getData<T>(
    context: WorkflowContextService,
    http: HttpClient,
    type: NoParamConstructor<T>,
    url: string,
    params?: { [key: string]: any }
  ): Observable<T> {
    const headers = this.getHeaders(context);

    const searchParams: HttpParams = this.buildParams(params);

    let urlSeparator = '?';

    url = this.getRootURL() + url;

    if (params) {
      if (url.indexOf('?') > -1) {
        urlSeparator = '&';
      }
    }

    for (const param in params) {
      url += urlSeparator + param + '=' + params[param];
      urlSeparator = '&';
    }

    const options = { headers: headers }; // , params: searchParams };

    return this.performGet(http, type, url, options);
  }

  static toListObject<T>(type: NoParamConstructor<T>, data: any[]): T[] {
    if (type) {
      const list = []; // new type();

      for (let i = 0; i < data.length; i++) {
        const model = new type();

        for (const p in data[i]) {
          model[p] = data[i][p];
        }
        list.push(model);
      }

      return list;
    } else {
      return data;
    }
  }

  static toObject<T>(type: NoParamConstructor<T>, data: any): T {
    if (type) {
      let model = null;

      model = new type(data);
      if (_.isFunction(model.map)) {
        model = model.map(data);
      } else {
        for (const p in data) {
          model[p] = data[p];
        }
      }

      return model;
    } else {
      return data;
    }
  }

  static setAuthToken(context: WorkflowContextService, resObj: any) {
    const a = resObj;

    if (a.headers.get('authToken')) {
      const token = a.headers.get('authToken');
      context.authToken$.next(token);
    }
  }

  static handleError(error: HttpErrorResponse) {
    // if (error.error instanceof ErrorEvent) {
    //  // A client-side or network error occurred. Handle it accordingly.
    //  console.error('An error occurred:', error.error.message);
    // } else {
    //  // The backend returned an unsuccessful response code.
    //  // The response body may contain clues as to what went wrong,
    //  console.error(
    //    `Backend returned code ${error.status}, ` +
    //    `body was: ${error.error}`);
    // }
    // return an observable with a user-facing error message
    return throwError(error);
    //  throwError('Something bad happened; please try again later.');
  }

  static postDataList<T>(
    context: WorkflowContextService,
    http: HttpClient,
    type: NoParamConstructor<T>,
    url: string,
    data: any,
    params?: { [key: string]: any },
    contentType?: string
  ): Observable<T[]> {
    const headers = this.getHeaders(context, contentType); // new Headers({ 'Content-Type': 'application/json', 'ClientId': (this._context.client ? this._context.client.id : ''), 'UserName': (this._context.user ? this._context.user.userName : '')  });
    const searchParams: HttpParams = this.buildParams(params);
    const options = { headers: headers }; // , params: searchParams }; // new RequestOptions({ headers: headers, search: searchParams });

    let urlSeparator = '?';

    if (params) {
      if (url.indexOf('?') > -1) {
        urlSeparator = '&';
      }
    }

    url = this.getRootURL() + url;

    for (const param in params) {
      url += urlSeparator + param + '=' + params[param];
      urlSeparator = '&';
    }

    // , options
    return http.post(url, data, options).pipe(
      map((res: T[]) => {
        return res ? this.toListObject<T>(type, res) : null;
        // var resObj = res//.json();
        // return null; //(resObj ? this.toListObject(type, resObj) : null)
      }),
      catchError(err => Utilities.handleError(err))
    );
  }

  static objectToPost(data: any): string {
    let response = '';

    for (const n in data) {
      if (response != '') {
        response += '&';
      }

      response += n + '=' + data[n];
    }

    return response;
  }

  static postDataFile<T>(
    context: WorkflowContextService,
    http: HttpClient,
    type: NoParamConstructor<T>,
    url: string,
    data: any,
    contentType?: string,
    additionalHeaders?: { [key: string]: string },
    params?: { [key: string]: any },
    responseType?: string
  ): Observable<T> {
    const headers = this.getHeaders(context, contentType, additionalHeaders);

    const searchParams: HttpParams = this.buildParams(params);
    const rt: any = responseType || 'json';
    const options = {
      headers: headers,
      params: params,
      method: 'POST',
      responseType: rt
    };

    let postData: string;

    if (!contentType) {
      postData = data;
    } else {
      postData = this.objectToPost(data);
    }

    url = this.getRootURL() + url;

    return http.post<T>(url, postData, options);
  }
  static postData<T>(
    context: WorkflowContextService,
    http: HttpClient,
    type: NoParamConstructor<T>,
    url: string,
    data: any,
    contentType?: string,
    additionalHeaders?: { [key: string]: string },
    params?: { [key: string]: any },
    timeoutMS?: number
  ): Observable<T> {
    const headers = this.getHeaders(context, contentType, additionalHeaders);

    const searchParams: HttpParams = this.buildParams(params);
    const options = { headers: headers, params: params, method: 'POST' };

    let postData: any;

    if (!contentType) {
      postData = data;
    } else {
      postData = this.objectToPost(data);
    }

    url = this.getRootURL() + url;

    // options
    if (timeoutMS) {
      return http.post<T>(url, postData, options).pipe(
        timeout(timeoutMS),
        map((res: T) => {
          return res ? this.toObject(type, res) : null;
        }),
        catchError(err => Utilities.handleError(err))
      );
    } else {
      return http.post<T>(url, postData, options).pipe(
        map((res: T) => {
          return res ? this.toObject(type, res) : null;
        }),
        catchError(err => Utilities.handleError(err))
      );
    }
  }
}

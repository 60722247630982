import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FilterBuilderOutput, FilterBuilderParam, translateFilterBuilderToLegacy } from 'src/app/models/filter-builder';
import { ItemSearchOptionField } from '../../filter-list/models/filterClasses';
import { CustomReport, ReportDataSetFieldDataType } from 'src/app/models/custom-report';

@Component({
  selector: 'wm-custom-report-filter-input',
  templateUrl: './custom-report-filter-input.component.html',
  styleUrl: './custom-report-filter-input.component.css'
})
export class CustomReportFilterInputComponent implements OnInit {
  @Input()
  public report: CustomReport;
  public params: FilterBuilderParam[] = [];
  public filters: ItemSearchOptionField[];
  @Output()
  filtersChanged: EventEmitter<any> = new EventEmitter();

  ngOnInit(): void {
    if (this.report.filterConfig) {
      const parms = this.report.filterConfig.fields.map(f => {
        if (f.dataType === ReportDataSetFieldDataType.Date) {
          return {
            name: f.label,
            id: f.name,
            inputType: 'date',
            types: ['range']
          };
        } else if (f.dataType === ReportDataSetFieldDataType.String) {
          return {
            name: f.label,
            id: f.name,
            inputType: 'text',
            types: ['range'],
            options:
              f.availableFilterItems && f.availableFilterItems.length > 0
                ? f.availableFilterItems.map(afi => {
                    return {
                      name: afi.text,
                      value: afi.value
                    };
                  })
                : null
          };
        }
      });

      this.params = parms;
    }
  }



  filtersChangedHandler(filters: FilterBuilderOutput) {
    this.filters = translateFilterBuilderToLegacy(filters);
    this.filtersChanged.emit(this.filters);
  }
}

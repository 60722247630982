<button
  *ngIf="shouldShowButton()"
  type="button"
  style="min-width: unset; white-space:nowrap;"
  [class]="buttonClass + ' mr-0'"
  [disabled]="!enabled"
  (click)="open()"
  [id]="moduleId + '_btn'"
  [title]="tooltip || ''"
  [ngStyle]="buttonStyling"
>
  {{ showButtonText && buttonText ? buttonText : '' }}

  <i
    *ngIf="showButtonText && btnIcon"
    class="material-icons"
    [ngStyle]="iconStyle"
    >{{ btnIcon }}</i
  >
</button>
<a
  *ngIf="shouldShowLink()"
  style="min-width: unset; white-space:nowrap;"
  (click)="open()"
  [id]="moduleId + '_btn'"
  [title]="tooltip || ''"
  href="#"
>
  {{ showButtonText && buttonText ? buttonText : '' }}

  <i *ngIf="showButtonText && btnIcon" class="material-icons">{{ btnIcon }}</i>
</a>

<ng-template #content let-modal>
  <form [formGroup]="form">
    <div class="modal-header">
      <h4 class="modal-title" [id]="moduleId + '_modallabel'">{{ title }}</h4>
      <button
        type="button"
        class="close"
        aria-label="Close"
        [disabled]="processing"
        (click)="modal.dismiss('Cross click')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <ng-content></ng-content>
    </div>

    <div class="row ml-2 mr-2 mb-1">
      <div class="col-4 text-left">
        <ng-containter *ngIf="customButtonText && customButtonText !== ''">
          <button
            type="button"
            [class]="'btn btn-' + customButtonColor"
            class="ml-2"
            [disabled]="processing"
            (click)="custom()"
          >
            {{ customButtonText }}
          </button>
        </ng-containter>
      </div>

      <div class="col-8">
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-default mr-2"
            [disabled]="processing"
            (click)="cancel()"
            *ngIf="showCancelButton && cancelText"
          >
            {{ cancelText }}
          </button>
          <button
            *ngIf="(commandText || '') !== '' && showCommandButton"
            type="button"
            [disabled]="(form && form.invalid) || processing"
            [class]="'btn btn-' + btnColor"
            class="mr-2"
            (click)="accept()"
          >
            <span
              *ngIf="processing"
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
            {{ commandText }}
          </button>
        </div>
      </div>
    </div>
  </form>
</ng-template>

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { User } from '../../../models';
import { WorkflowContextService } from 'src/app/services';
import { Router } from '@angular/router';

@Component({
  selector: 'wm-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css']
})
export class UserListComponent implements OnInit {
  @Input() users: User[];
  @Output() selected: EventEmitter<User> = new EventEmitter<User>();
  @Input() raiseEvent = false;
  @Input() searching = false;

  constructor(
    private _context: WorkflowContextService,
    private _router: Router
  ) {}
  buildBaseRoute(): string[] {
    let routeDetails: string[] = [];

    if (this._context.client) {
      routeDetails = [
        'admin',
        'jurisdiction',
        this._context.client.id,
        'users'
      ];
    } else {
      routeDetails = ['admin', 'global', 'users'];
    }

    //  routeDetails = routeDetails.concat(['users']);

    return routeDetails;
  }
  editUser(user: User): boolean {
    if (window.event) {
      window.event.preventDefault();
    }
    if (this.raiseEvent) {
      this.selected.emit(user);
    } else {
      const routeDetails = this.buildBaseRoute().concat(['edit', user.id]);
      this._router.navigate(routeDetails);
      return false;
    }
  }
  ngOnInit() {}
}

<header>
  <a class="navbar-brand">Estimated Fees</a>
</header>
<ul class="list-group mb-auto">
  <li class="list-group-item" *ngFor="let fee of displayFees">
    <span>{{ fee.label }}</span>
    <span class="text-muted ml-auto">{{ fee.value | currency }}</span>
  </li>

  <li class="list-group-item">
    <span class="font-weight-bold">Estimated Total</span>
    <span class="text-muted ml-auto">{{ totalFees | currency }}</span>
  </li>
</ul>

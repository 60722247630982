<router-outlet></router-outlet>
<wm-modal-confirm
  #updateVersion
  (accepted)="reloadSite()"
  commandText="Refresh"
  title="Version Update"
  [showButton]="false"
  [showCancelButton]="false"
>
  This site has been updated. Please Refresh to get the latest version.
</wm-modal-confirm>

import { PaymentProcessorConfigurationBase } from '../payment-processor-configuration-base';

export class PointNPayPaymentProcessorConfiguration extends PaymentProcessorConfigurationBase {
  constructor(options?: Partial<PointNPayPaymentProcessorConfiguration>) {
    super(options);

    if (options) {
      Object.assign(this, options);
    }
  }
}

import { DataEntity } from './data-entity';
import { Validators } from '@angular/forms';

export class DisplayEntityValueDataEntity extends DataEntity {
  templateCodeRef: string;
  dataEntityRef: DataEntity;
  useDataEntityLabel: boolean | null;
  hideLabelAndValueWhenNullorEmpty: boolean | null;

  constructor(options?: Partial<DisplayEntityValueDataEntity>) {
    super(options);

    this.dataEntityTypeCode = 'display-entity-value';
    this.dataEntityTypeDescription = 'display entity value';
    this.dataEntityTypeName = 'Display Entity Value';

    if (options) {
      Object.assign(this, options);
    }

    this.hasPreview = false;
  }

  /**
   *
   * @returns whether to display the space where the display entity value shows up.
   *
   * @remarks
   * if I set it to hide label and value when null or empty AND
   * if the referenced entity is  null, empty, or undefined, the return false
   * (false= don't show)
   * any other time return true
   * (true = show)
   */
  evaluateVisibility(): boolean {
    if (this.dataEntityRef) {
      if (
        this.hideLabelAndValueWhenNullorEmpty &&
        (this.dataEntityRef.value === '' ||
          this.dataEntityRef.value == null ||
          this.dataEntityRef.value === undefined)
      ) {
        return false;
      }
    } else {
      return false;
    }
    return true; // default behavior.
  }
  /**
   * @remarks
   * Set the label for the dataEntityRef to be the label from the dataEntityRef
   * if I set the useDataEntityLabel or if I haven't set it
   * otherwise use the label from the display-entity-value de.
   */
  evaluateLabelSource(): void {
    if (this.dataEntityRef) {
      this.dataEntityRef.label =
        this.useDataEntityLabel ||
        this.useDataEntityLabel == null ||
        this.useDataEntityLabel === undefined
          ? this.dataEntityRef.label
          : this.label;
    }
  }
  getValidators(): any[] {
    return [Validators.nullValidator];
  }
}

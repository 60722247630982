<app-navbar></app-navbar>

<form [formGroup]="form">
  <div class="container" style="padding-top: 56px;">
    <div class="row">
      <div class="col-md-8 offset-md-2 col-lg-4 offset-lg-4">
        <div class="mt-5 mx-auto">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">Reset Password</h5>
              <div class="form-group">
                <label for="inputLoginUsername" class="bmd-label-floating"
                  >Username</label
                >
                <input
                  type="text"
                  id="inputUsername"
                  class="form-control required"
                  [(ngModel)]="userName"
                  formControlName="userName"
                  name="inputUsername"
                />
                <wm-control-message
                  [control]="form.controls.userName"
                ></wm-control-message>
              </div>
              <div class="form-group">
                <label for="inputLoginUsername" class="bmd-label-floating"
                  >New Password</label
                >
                <input
                  type="password"
                  id="inputNewPassword"
                  class="form-control required"
                  [(ngModel)]="newPassword"
                  formControlName="newPassword"
                  name="inputNewPassword"
                />
                <wm-control-message
                  [control]="form.controls.newPassword"
                ></wm-control-message>
              </div>
              <div class="mt-5">
                <button
                  type="button"
                  class="btn btn-raised btn-primary float-right"
                  [disabled]="!form.valid"
                  (click)="reset()"
                >
                  Reset Password
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--
  <div class="row">
    <div class="col-md-8 offset-md-2 col-lg-6 offset-lg-3">
      <div class="mt-5 mx-auto">
        <div class="card">
          <div class="card-body">
            <h6>Reset Password</h6>

            <div class="row">
              <div class="col-lg-3">
                <div class="form-group">
                  <p class="form-control-static">Username</p>
                </div>
              </div>
              <div class="col-lg-9">
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control required"
                    [(ngModel)]="userName"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-3">
                <div class="form-group">
                  <p class="form-control-static">New Password</p>
                </div>
              </div>
              <div class="col-lg-9">
                <div class="form-group">
                  <input
                    type="password"
                    class="form-control required"
                    [(ngModel)]="newPassword"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12">
                <div class="form-group">
                  <button
                    type="button"
                    class="btn btn-raised btn-primary"
                    (click)="reset()"
                  >
                    Reset Password
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  --></div>
</form>

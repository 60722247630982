<form [formGroup]="form" (ngSubmit)="submit()" *ngIf="contractorTypes">
  <div
    class="form-check"
    *ngFor="
      let contractorType of form.controls.contractorTypes.controls;
      let i = index
    "
  >
    <label formArrayName="contractorTypes">
      <input
        type="checkbox"
        [formControlName]="i"
        (change)="selectItem($event.target.checked, contractorTypes[i].id)"
      />
      {{ contractorTypes[i].name }}
    </label>
  </div>
  <div class="switch">
    <label>
      <input
        type="checkbox"
        formControlName="allowNotListedOption"
        [(ngModel)]="activity.model.allowNotListedOption"
      />
      Allow Not Listed Option
    </label>
  </div>
</form>

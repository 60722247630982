<form [formGroup]="form" novalidate>
  <input
    type="text"
    class="form-control"
    [ngClass]="{ required: required }"
    [(ngModel)]="emailModel"
    formControlName="{{ controlName }}"
    name="emailAddress"
  />
</form>

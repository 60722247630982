import * as moment from 'moment';

import { AvailabilityProvider } from '../../availability-providers';
import { TimeRangeAvailabilityProviderOptions } from '../time-range-availability-provider';
import { AppointmentTimeframe } from '../appointment-time-frame';
import { Directive } from "@angular/core";

///This is to return a list of time ranges
@Directive()
export class TimeRangeAvailabilityProvider extends AvailabilityProvider {
  getAvailableDates(
    currentDate: Date,
    endDate: Date,
    options?: Partial<TimeRangeAvailabilityProviderOptions>
  ): Date[] {
    return null;
  }
  getAvailableTimes(
    currentDate: Date,
    options?: Partial<TimeRangeAvailabilityProviderOptions>
  ): Date[] {
    let times: Date[] = [];

    let lengthHours: number = +options.lengthOfAppointmentHours;
    let lengthMinutes: number = +options.lengthOfAppointmentMinutes;

    let totalMinutes = moment.duration(
      lengthHours * 60 + lengthMinutes,
      'minutes'
    );

    options.timeFrames.forEach((value: AppointmentTimeframe, index: number) => {
      let startDate = moment.parseZone(
        new Date().toDateString() + ' ' + value.startTime
      );
      let endDate = moment.parseZone(
        new Date().toDateString() + ' ' + value.endTime
      );

      let minutesDiff = moment.duration(endDate.diff(startDate)).asMinutes();

      let intervals = minutesDiff / totalMinutes.asMinutes();

      for (let interval = 0; interval < intervals; interval++) {
        let intervalDate: Date = startDate
          .clone()
          .add(totalMinutes.asMinutes() * interval, 'minutes')
          .toDate();

        times.push(intervalDate);
      }
    });

    return times;
  }
}

import { ScheduleEmailActivityModel } from './../../../../../models/activities/schedule-email-activity';
import { WorkflowService } from './../../../../../services/workflow.service';
import { ActivityEditorBaseComponent } from './../../activity-editor-base/activity-editor-base.component';
import { Component, OnInit, Inject, forwardRef } from '@angular/core';
import {
  UntypedFormControl,
  Validators,
  AbstractControl,
  ValidationErrors
} from '@angular/forms';

@Component({
  selector: 'wm-schedule-email-activity-editor',
  templateUrl: './schedule-email-activity-editor.component.html',
  styleUrls: ['./schedule-email-activity-editor.component.css']
})
export class ScheduleEmailActivityEditorComponent
  extends ActivityEditorBaseComponent
  implements OnInit {
  constructor(
    @Inject(forwardRef(() => WorkflowService))
    public workflowSvc: WorkflowService
  ) {
    super();
  }

  model: ScheduleEmailActivityModel;

  get times() {
    const times = [];

    times.push('12:00 AM');
    for (let index = 0; index < 11; index++) {
      times.push(`${index + 1}:00 AM`);
    }
    times.push('12:00 PM');
    for (let index = 0; index < 11; index++) {
      times.push(`${index + 1}:00 PM`);
    }

    return times;
  }

  ngOnInit() {
    if (this.activity) {
      this.model = this.activity.model as ScheduleEmailActivityModel;
    }

    if (this.form) {
      this.form.addControl(
        'schedule_date',
        new UntypedFormControl('', Validators.required)
      );
      this.form.addControl(
        'schedule_time',
        new UntypedFormControl('12:00 AM', Validators.nullValidator)
      );
      this.form.addControl(
        'cancelIfDisabled',
        new UntypedFormControl(false, Validators.nullValidator)
      );
      this.form.addControl(
        'cancelIfRenewed',
        new UntypedFormControl(false, Validators.nullValidator)
      );
    }
  }
}

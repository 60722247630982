import {
  Component,
  OnInit,
  Input,
  ViewChild,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { ContractorDocument } from 'src/app/models/contractor-document';
import { ModalConfirmComponent } from '../../system/modal-confirm/modal-confirm.component';
import {
  ContractorService,
  DataService,
  Utilities,
  DocumentService
} from 'src/app/services';
import { ContractorDocumentCategory } from 'src/app/models/contractor-document-category';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';

@Component({
  selector: 'wm-contractor-document-list',
  templateUrl: './contractor-document-list.component.html',
  styleUrls: ['./contractor-document-list.component.css']
})
export class ContractorDocumentListComponent implements OnInit, OnChanges {
  @Input() documents: {
    categoryId?: string;
    category?: ContractorDocumentCategory;
    id: string;
    documentName: string;
    documentPath: string;
    createdOn: string;
    createdBy: string;
    modifiedOn: string;
    modifiedBy: string;
    editing: boolean;
  }[];
  @Input() documentCategories: ContractorDocumentCategory[];
  @Input() parentId: string;

  @Output() deleted: EventEmitter<any> = new EventEmitter<any>();
  @Output() added: EventEmitter<any> = new EventEmitter<any>();
  @Output() saved: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('deleteDocModal', { static: true }) deleteDocModal: ModalConfirmComponent;

  newDoc: boolean;
  newDocForm: UntypedFormGroup;
  newFiles = [];

  form: UntypedFormGroup;

  deleteDocumentId: string;

  commands: {
    title: string;
    idField: string;
    canPerform: (item: any) => boolean;
    handler: (id: string) => void;
  }[];

  constructor(
    private _contractorSvc: ContractorService,
    private _fb: UntypedFormBuilder,
    private _toastr: ToastrService,
    public uploadService: DocumentService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.documents) {
      this.buildValidation();
    }
  }

  buildValidation() {
    if (!this.form) {
      this.form = this._fb.group({});
    }

    const ctls = this.form.controls;

    if (this.documents) {
      this.documents.forEach((d, i) => {
        if (!ctls[d.id]) {
          ctls[d.id] = this._fb.group({
            docCategory: [d.categoryId, Validators.nullValidator],
            docName: [d.documentName, Validators.required]
          });
          ctls[d.id].disable();
        }
      });
    }

    this.form = this._fb.group(ctls);
  }
  ngOnInit() {
    if (!this.documentCategories) {
      this._contractorSvc
        .getContractorDocumentCategories()
        .subscribe(categories => {
          this.documentCategories = categories;
        });
    }

    this.newDocForm = this._fb.group({
      documentUploaded: ['', Validators.required]
    });

    this.commands = [
      {
        title: 'Delete',
        idField: 'id',
        canPerform: this.canDeleteDocument,
        handler: this.startDeleteDocument.bind(this)
      },
      {
        title: 'Edit',
        idField: 'id',
        canPerform: (e: any) => true,
        handler: this.editDocument.bind(this)
      }
    ];

    if (this.documents) {
      this.buildValidation();
    }
  }

  newDocStarted() {
    this.newDoc = true;
    this.newDocForm.reset();
    this.newFiles = [];
  }
  newDocumentsUploaded(files) {
    this.newFiles = files;
    this.newDocForm.controls.documentUploaded.setValue(true);
  }

  addDocuments() {
    // retrives uploaded documents and add them to the contractor
    if ((this.newFiles || []).length > 0) {
      this.added.emit(this.newFiles);
    }
  }

  cancelEdit(item: ContractorDocument) {
    item.editing = false;
  }
  editDocument(id: string) {
    const item: any = this.documents.find(d => d.id === id);

    if (item) {
      item.editing = true;
    }
    if (this.form.controls[id]) {
      this.form.controls[id].enable();
    }
  }

  saveDocument(item: ContractorDocument) {
    item.category = this.documentCategories.find(d => d.id == item.categoryId);
    item.editing = false;
    this.saved.emit(item);
  }

  canDeleteDocument(item: any): boolean {
    return true;
  }

  startDeleteDocument(id: string) {
    this.deleteDocumentId = id;
    this.deleteDocModal.open();
  }

  deleteDocument() {
    this.deleted.emit(this.deleteDocumentId);
  }

  cancelDocumentDelete() {
    this.deleteDocumentId = '';
  }

  canViewDocument(item: any): boolean {
    return true;
  }

  getDocPath(doc: ContractorDocument): string {
    return Utilities.getAzureUrl(doc);
  }
  viewDocument(id: string) {
    const doc: any = this.documents.find(d => d.id === id);

    if (doc) {
      window.open(Utilities.getAzureUrl(doc), '_blank');
    }
  }
}

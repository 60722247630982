import { Component, OnInit, Input, Inject, forwardRef } from '@angular/core';
import { ActivityEditorBaseComponent } from '../../activity-editor-base/activity-editor-base.component';
import { StartWorkflowActivity } from '../../../../../models/activities';
import {
  WorkflowService,
  WorkflowContextService
} from '../../../../../services';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'wm-start-workflow-activity-editor',
  templateUrl: './start-workflow-activity-editor.component.html',
  styleUrls: ['./start-workflow-activity-editor.component.css']
})
export class StartWorkflowActivityEditorComponent
  extends ActivityEditorBaseComponent
  implements OnInit {
  @Input() activity: StartWorkflowActivity;
  activityId: string;

  constructor(
    @Inject(forwardRef(() => WorkflowService))
    private _workflowSvc: WorkflowService,
    @Inject(forwardRef(() => WorkflowContextService))
    private _context: WorkflowContextService,
    private _route: ActivatedRoute
  ) {
    super();
    this.activity = new StartWorkflowActivity();
  }

  save(): boolean {
    return true;
  }

  ngOnInit() {
    if (!this.activity) {
      this.activity = new StartWorkflowActivity();
    }
  }
}

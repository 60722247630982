import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpRequest,
  HttpEventType,
  HttpResponse,
  HttpBackend
} from '@angular/common/http';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs';
import { Utilities } from '../utilities';

export interface UploadProgress {
  uploadTotal?: number;
  uploaded?: number;
  path?: string;
}

@Injectable()
export class UploadService {
  http: HttpClient;
  apiUrl: string;

  constructor(handler: HttpBackend) {
    this.http = new HttpClient(handler);
    this.apiUrl = Utilities.getRootURL();
  }

  public upload(
    files: Set<File>,
    parent: string
  ): { [key: string]: { progress: Observable<UploadProgress> } } {
    // this will be the our resulting map
    const status: {
      [key: string]: { progress: Observable<UploadProgress> };
    } = {};

    files.forEach((file: File) => {
      // create a new progress-subject for every file
      const progress = new Subject<UploadProgress>();

      // reject files above 20000000 bytes (20mb) - the server will do this too
      if (file.size > 20000000) {
        progress.error(
          new Error(
            'The file you uploaded is too large. Please upload a file below 20mb.'
          )
        );
      }

      // create a new multipart-form for every file
      const formData: FormData = new FormData();

      formData.append('file', file, file.name);
      formData.append('parentPath[]', parent);
      formData.append('filename', file.name);
      formData.append('origName', file.name);

      // create a http-post request and pass the form
      // tell it to report the upload progress
      const req = new HttpRequest(
        'POST',
        `${this.apiUrl}/api/Document/upload`,
        formData,
        {
          reportProgress: true
        }
      );
      // send the http-request and subscribe for progress-updates
      this.http.request<any>(req).subscribe(
        event => {
          if (event.type === HttpEventType.UploadProgress) {
            // pass the percentage into the progress-stream
            progress.next({
              uploadTotal: event.total,
              uploaded: event.loaded
            });
          } else if (event instanceof HttpResponse) {
            // Close the progress-stream if we get an answer form the API
            // The upload is complete

            const path = event.body ? event.body.path : null;

            progress.next({
              path
            });
            progress.complete();
          }
        },
        err => {
          progress.error(err);
        }
      );

      // Save every progress-observable in a map of all observables
      status[file.name] = {
        progress: progress.asObservable()
      };
    });
    // return the map of progress.observables
    return status;
  }
}

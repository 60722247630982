<wm-workflow-application-list
  [id]="id"
  (recordCountSet)="setRecordCount($event)"
  [staticFilters]="pendingPaymentWorkflowsFilter"
  [columns]="columns"
  [workflows]="workflows"
  (updateStatus)="updateStatusEvent($event)"
  (dataLoaded)="emitDataLoaded($event)"
  (filtersChanged)="filtersChange()"
>
</wm-workflow-application-list>

<ng-template #appIdTmpl let-row="row" let-value="value">
  <a [routerLink]="['/application/workflow-application', row.appId]">
    {{ value }}
  </a>
</ng-template>

import { Component, OnInit, Input } from '@angular/core';
import { PaymentAccountConfigBaseComponent } from '../payment-account-config-base/payment-account-config-base.component';
import { CardpointePaymentProcessorAccountConfiguration } from '../../../models/payment-providers/cardpointe/cardpointe-payment-processor-account-configuration';
import { UntypedFormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'wm-payment-account-config-cardpointe',
  templateUrl: './payment-account-config-cardpointe.component.html',
  styleUrls: ['./payment-account-config-cardpointe.component.css']
})
export class PaymentAccountConfigCardpointeComponent
  extends PaymentAccountConfigBaseComponent
  implements OnInit {
  @Input() config: CardpointePaymentProcessorAccountConfiguration;

  constructor(fb: UntypedFormBuilder) {
    super(fb);
  }

  ngOnInit() {
    this.addValidation(
      'merchantId',
      Validators.required,
      this.config ? this.config.merchantId : ''
    );
  }
}

import { AvailabilityProvider } from '../../availability-providers';
import { CalendarServiceAvailabilityProviderOptions } from '../calendar-service-availability-provider';
import { Directive } from "@angular/core";

///This is to integrate with a 3rd party calendar service like Outlook
@Directive()
export class CalendarServiceAvailabilityProvider extends AvailabilityProvider {
  getAvailableDates(
    currentDate: Date,
    endDate: Date,
    options?: Partial<CalendarServiceAvailabilityProviderOptions>
  ): Date[] {
    return null;
  }
  getAvailableTimes(
    currentDate: Date,
    options?: Partial<CalendarServiceAvailabilityProviderOptions>
  ): Date[] {
    return null;
  }
}

import { CopyContractorTypeRequest } from './../models/copy-contractor-request';
import {
  ContractorFeeSummaryReportRequest,
  PagedContractorFeeSummaryReportRequest,
  ContractorSummaryReportRequest,
  PagedContractorSummaryReportRequest
} from './../models/contractor-reports';
import { Injectable, Inject, forwardRef } from '@angular/core';
import { Observable, of } from 'rxjs';

import { ClientService } from './client.service';
import { DataService } from './data.service';
import { WorkflowContextService } from './workflow-context.service';
import { Utilities } from './utilities';
import {
  ContractorRequirementType,
  ContractorSearchOptions,
  ContractorTypeSearchOptions,
  Contractor,
  ContractorRegistration,
  Client,
  ContractorType,
  EmailNotificationTemplate,
  TextingNotificationTemplate,
  PagedContractorRegistrationVM,
  Workflow,
  Document,
  ContractorRegistrationNote,
  UnpagedContractorRegistrationVM
} from '../models';
import { map } from 'rxjs/operators';
import { WorkflowService, ActivityUtilities, JSONUtilities } from '.';
import {
  Activity,
  ActivityModel,
  FormActivity,
  PrintTemplateActivity,
  EmailActivity
} from '../models/activities';
import { DataEntity, FeeDataEntity } from '../models/data-entities';
import { ContractorDocumentCategory } from '../models/contractor-document-category';
import { ContractorDocument } from '../models/contractor-document';
import { ContractorRegistrationDocument } from '../models/contractor-registration-document';
import {
  ExportEntityOption,
  ItemSearchOptionField
} from '../components/filter-list/models/filterClasses';
import { RegistrationStatus } from '../models/registration';
import {
  CustomFieldMigrationQueueItem,
  CustomFieldMigrationQueueRequest,
  CustomFieldDataOperationType
} from '../models/custom-field-data-operations';

export class ContractorProfileVM {
  contractor: Contractor;
  documentCategories: ContractorDocumentCategory[];

  constructor(options?: Partial<ContractorProfileVM>) {
    if (options) {
      Object.assign(this, options);

      this.contractor = new Contractor(options ? options.contractor : null);

      this.documentCategories = new Array<ContractorDocumentCategory>();

      if (options.documentCategories) {
        this.documentCategories = options.documentCategories.map(
          dc => new ContractorDocumentCategory(dc)
        );
      }
    }
  }
}

@Injectable()
export class ContractorService {
  utilites: Utilities = new Utilities();

  getExportEntities(): Observable<{ [key: string]: string }> {
    return this._dataSvc.getContractorExportEntities();
  }

  getExportEntityOptions(): Observable<ExportEntityOption[]> {
    return this._dataSvc.getContractorExportEntityOptions();
  }
  deleteContractorRegistrationDocument(documentId: string): Observable<void> {
    return this._dataSvc.deleteContractorRegistrationDocument(documentId);
  }
  saveContractorRegistrationDocument(document: ContractorRegistrationDocument) {
    return this._dataSvc.saveContractorRegistrationDocument(document);
  }
  getContractorRegistrationDocuments(registrationId: string) {
    return this._dataSvc.getContractorRegistrationDocuments(registrationId);
  }
  saveContractorRegistrationDocuments(
    registrationId: string,
    documents: ContractorRegistrationDocument[]
  ): Observable<ContractorRegistrationDocument[]> {
    return this._dataSvc.saveContractorRegistrationDocuments(
      registrationId,
      documents
    );
  }
  getContractorRegistration(
    registrationId: string
  ): Observable<ContractorRegistration> {
    return this._dataSvc.getContractorRegistration(registrationId);
  }
  saveContractorDocument(
    item: ContractorDocument
  ): Observable<ContractorDocument> {
    return this._dataSvc.saveContractorDocument(item);
  }
  getContractorProfile(contractorId: string): Observable<ContractorProfileVM> {
    return this._dataSvc.getContractorProfile(contractorId);
  }
  saveContractorDocuments(
    contractorId: string,
    documents: ContractorDocument[]
  ): Observable<ContractorDocument[]> {
    return this._dataSvc.saveContractorDocuments(contractorId, documents);
  }
  getContractorDocuments(
    contractorId: string
  ): Observable<ContractorDocument[]> {
    return this._dataSvc.getContractorDocuments(contractorId);
  }
  deleteContractorDocument(documentId: string): Observable<void> {
    return this._dataSvc.deleteContractorDocument(documentId);
  }
  deleteContractorRegistration(
    deleteRegistrationId: string
  ): Observable<boolean> {
    return this._dataSvc.deleteContractorRegistration(deleteRegistrationId);
  }
  startRegistrationRenewal(
    registrationId?: any,
    contractorTypeId?: string,
    isTestApplication: boolean = false
  ): Observable<{ applicationId: string }> {
    return this._dataSvc.startContractorRegistrationRenewal(
      registrationId,
      contractorTypeId,
      isTestApplication
    );
  }

  constructor(
    @Inject(forwardRef(() => DataService)) private _dataSvc: DataService,
    @Inject(forwardRef(() => ClientService)) private _clientSvc: ClientService,
    @Inject(forwardRef(() => WorkflowContextService))
    private _context: WorkflowContextService,
    @Inject(forwardRef(() => WorkflowService))
    private _workflowSvc: WorkflowService
  ) {}
  createContractor(): Contractor {
    return {
      id: '',
      businessName: '',
      contactFirstName: '',
      contactLastName: '',
      contactTitle: '',
      isEnabled: false,
      client: this._clientSvc.createClient(),
      registrations: [],
      documents: [],
      customFields: []
    };
  }
  createContractorRequirementType(client: Client): ContractorRequirementType {
    return {
      id: '',
      name: '',
      description: '',
      type: 'Text',
      client: client
    };
  }
  createContractorType(client: Client): ContractorType {
    return new ContractorType({
      id: '',
      name: '',
      description: '',
      expirationFormulaCriteria: null,
      registrationFee: '',
      renewalFee: '',
      lateFee: ''
    });
  }

  saveContractorDetails(contractor: Contractor): Observable<Contractor> {
    return this._dataSvc.saveContractorDetails(contractor);
  }

  saveContractorRegistrationCustomFields(
    registrationCustomFields: any[],
    contractorId: string
  ): Observable<any[]> {
    // clear out the registration object off of each of the custom fields to prevent $ref entries from being serialized instead of the whole object.
    registrationCustomFields.forEach((cf, idx) => {
      cf.registration = null;
    });

    return this._dataSvc.saveContractorRegistrationCustomFields(
      registrationCustomFields,
      contractorId
    );
  }

  saveRegistrationNote(
    note: ContractorRegistrationNote,
    contractorID: string,
    contractorUserID: string
  ): Observable<Contractor> {
    return this._dataSvc.saveContractorRegistrationNote(
      note,
      contractorID,
      contractorUserID
    );
  }
  saveContractor(contractor: Contractor): Observable<Contractor> {
    return this._dataSvc.saveContractor(contractor);
  }
  getContractorFlat(id: string): Observable<Contractor> {
    return this._dataSvc.getContractor(id);
  }
  getContractor(id: string): Observable<Contractor> {
    return this._dataSvc.getContractor(id);
  }
  getContractorByRegistrationId(id: string): Observable<Contractor> {
    return this._dataSvc.getContractorByRegistrationId(id);
  }
  searchExpiredContractorRegistrations(
    clientId: string,
    sortField: string,
    sortDescending: boolean,
    filterObjects: ItemSearchOptionField[],
    pageNumber: number,
    pageSize: number
  ): Observable<PagedContractorRegistrationVM> {
    return this._dataSvc
      .searchExpiredContractorRegistrations(
        clientId,
        sortField,
        sortDescending,
        filterObjects,
        pageNumber,
        pageSize
      )
      .pipe(
        map(regs => {
          return regs;
        })
      );
  }

  searchContractorRegistrationsUnpaged(
    clientId: string,
    sortField?: string,
    sortDescending?: boolean,
    filterObjects?: ItemSearchOptionField[],
    publicData?: boolean
  ): Observable<UnpagedContractorRegistrationVM> {
    return this._dataSvc
      .searchContractorRegistrationsUnpaged(
        clientId,
        sortField,
        sortDescending,
        filterObjects,
        publicData
      )
      .pipe(
        map(regs => {
          return regs;
        })
      );
  }

  searchContractorRegistrations(
    clientId: string,
    sortField?: string,
    sortDescending?: boolean,
    filterObjects?: ItemSearchOptionField[],
    pageNumber?: number,
    pageSize?: number,
    publicData?: boolean
  ): Observable<PagedContractorRegistrationVM> {
    return this._dataSvc
      .searchContractorRegistrations(
        clientId,
        sortField,
        sortDescending,
        filterObjects,
        pageNumber,
        pageSize,
        publicData
      )
      .pipe(
        map(regs => {
          return regs;
        })
      );
  }
  exportContractorRegistrations(
    clientId: string,
    useExcelFormat: boolean,
    exportColumns: string[],
    sortField?: string,
    sortDescending?: boolean,
    filterObjects?: ItemSearchOptionField[],
    pageNumber?: number,
    pageSize?: number,
    publicData?: boolean
  ): Observable<any> {
    return this._dataSvc
      .exportContractorRegistrations(
        clientId,
        useExcelFormat,
        exportColumns,
        sortField,
        sortDescending,
        filterObjects,
        pageNumber,
        pageSize,
        publicData
      )
      .pipe(
        map(regs => {
          return regs;
        })
      );
  }
  searchContractors(options: ContractorSearchOptions) {
    return this._dataSvc.searchContractors(options);
  }
  searchContractorTypes(options: ContractorTypeSearchOptions) {
    return this._dataSvc.searchContractorTypes(this._context.client, options);
  }
  saveContractorType(contractorType: ContractorType) {
    return this._dataSvc.saveContractorType(contractorType).pipe(
      map(ct => {
        return this.buildContractorType(ct);
      })
    );
  }
  copyContractorType(
    request: CopyContractorTypeRequest
  ): Observable<ContractorType> {
    return this._dataSvc.copyContractorType(request);
  }
  getContractorSummaryReport(params: ContractorSummaryReportRequest) {
    return this._dataSvc.getContractorSummaryReport(params);
  }
  getPagedContractorSummaryReport(params: PagedContractorSummaryReportRequest) {
    return this._dataSvc.getPagedContractorSummaryReport(params);
  }
  getContractorFeeSummaryReport(params: ContractorFeeSummaryReportRequest) {
    return this._dataSvc.getContractorFeeSummaryReport(params);
  }
  getPagedContractorFeeSummaryReport(
    params: PagedContractorFeeSummaryReportRequest
  ) {
    return this._dataSvc.getPagedContractorFeeSummaryReport(params);
  }
  indexDataEntityActivity(
    dataEntities: DataEntity[],
    index: { [key: string]: Activity<ActivityModel> }
  ) {
    if (dataEntities) {
      const keys = Object.keys(dataEntities);
      keys.forEach(k => {
        const a = dataEntities[k].parentActivity;

        if (a && a.id) {
          if (!index[a.id]) {
            index[a.id] = a;
          }
        }
      });
    }
  }
  indexActivity(
    activities: Activity<ActivityModel>[],
    index: { [key: string]: Activity<ActivityModel> }
  ) {
    if (activities) {
      const keys = Object.keys(activities);
      keys.forEach(k => {
        const a = activities[k];

        if (a && a.id) {
          if (!index[a.id]) {
            index[a.id] = a;
          }
        }
      });
    }
  }
  updateDataEntityActivity(
    dataEntities: DataEntity[],
    index: { [key: string]: Activity<ActivityModel> }
  ) {
    if (dataEntities) {
      for (const k in dataEntities) {
        // for (var i = 0; i < dataEntities.length; i++) {
        if (k) {
          const de = dataEntities[k];
          if (de && de.parentActivity) {
            de.parentActivity = <Activity<ActivityModel>>(
              index[de.parentActivity.id]
            );
          }
        }
      }
    }
  }
  updateActivity(
    activities: Activity<ActivityModel>[],
    index: { [key: string]: Activity<ActivityModel> }
  ) {
    if (activities) {
      for (const k in activities) {
        if (k) {
          const activity = activities[k];

          if (activity && activity.id) {
            activities[k] = <Activity<ActivityModel>>index[activity.id];
          }
        }
      }
    }
  }
  buildContractorType(contractorType: ContractorType): ContractorType {
    const ctRefFixed = contractorType;

    if (ctRefFixed.registerWorkflow) {
      ctRefFixed.registerWorkflow = ActivityUtilities.convertWorkflowJsonToClasses(
        ctRefFixed.registerWorkflow
      );

      const activityTypesToFilter = [
        WorkflowService.ACTIVITIES.Decision,
        WorkflowService.ACTIVITIES.CompleteWorkflow
      ];

      const filteredActivityTypes = Object.keys(WorkflowService.ACTIVITIES)
        .filter(
          key =>
            activityTypesToFilter.indexOf(WorkflowService.ACTIVITIES[key]) ===
            -1
        )
        .map(key => WorkflowService.ACTIVITIES[key]);

      this._workflowSvc
        .getWorkflowActivities(
          ctRefFixed.registerWorkflow,
          filteredActivityTypes
        )
        .subscribe(activities => {
          const regActivities: {
            [key: string]: Activity<ActivityModel>;
          } = {};

          activities.forEach(a => {
            // load the editor details if it is configured to load details on load
            if (a.needsEditorDataLoaded) {
              this._workflowSvc
                .getActivityEditor(ctRefFixed.registerWorkflow.id, a.id)
                .subscribe(m => {
                  a.model = m.model;
                });
            }
            regActivities[a.id] = <Activity<ActivityModel>>a;
          });

          ctRefFixed.registerActivities = regActivities;

          this._workflowSvc
            .getWorkflowActivities(ctRefFixed.registerWorkflow, [
              WorkflowService.ACTIVITIES.Form,
              WorkflowService.ACTIVITIES.ContractorRegistrationInfo,
              WorkflowService.ACTIVITIES.ContractorInfo
            ])
            .subscribe(aList => {
              const result: { [type: string]: FormActivity } = {};

              aList.forEach(a => {
                result[a.id] = <FormActivity>a;
              });

              const feeResult: { [type: string]: FormActivity } = {};
              const nonFeeResults: {
                [type: string]: FormActivity;
              } = {};

              // pull the activities out that have fees
              for (const key in result) {
                if (!key) {
                  continue;
                }

                const activity = result[key];

                const des = activity.model.getEntities();

                if (
                  des.filter(de => {
                    return (
                      de.dataEntityTypeCode ===
                      WorkflowService.DATA_ENTITIES.Fee.code
                    );
                  }).length > 0
                ) {
                  feeResult[activity.id] = activity;
                } else {
                  nonFeeResults[activity.id] = activity;
                }
              }

              ctRefFixed.registerFormActivities = nonFeeResults;
              ctRefFixed.registerFeeActivities = feeResult;
            });

          this._workflowSvc
            .getWorkflowActivities(ctRefFixed.registerWorkflow, [
              WorkflowService.ACTIVITIES.PrintTemplate,
              WorkflowService.ACTIVITIES
                .ContractorRegistrationCertificateActivity
            ])
            .subscribe(aList => {
              const result: { [type: string]: PrintTemplateActivity } = {};

              aList.forEach(a => {
                result[a.id] = <PrintTemplateActivity>a;
              });

              ctRefFixed.registerPrintTemplateActivities = result;
            });

          this._workflowSvc
            .getWorkflowActivities(ctRefFixed.registerWorkflow, [
              WorkflowService.ACTIVITIES.Email
            ])
            .subscribe(aList => {
              const result: { [type: string]: EmailActivity } = {};

              aList.forEach(a => {
                result[a.id] = <EmailActivity>a;
              });

              ctRefFixed.registerEmailActivities = result;
            });
        });

      if (ctRefFixed.renewWorkflow) {
        ctRefFixed.renewWorkflow = ActivityUtilities.convertWorkflowJsonToClasses(
          ctRefFixed.renewWorkflow
        );

        this._workflowSvc
          .getWorkflowActivities(
            ctRefFixed.renewWorkflow,
            filteredActivityTypes
          )
          .subscribe(activities => {
            const renActivities: {
              [key: string]: Activity<ActivityModel>;
            } = {};

            activities.forEach(a => {
              // load the editor details if it is configured to load details on load
              if (a.needsEditorDataLoaded) {
                this._workflowSvc
                  .getActivityEditor(ctRefFixed.renewWorkflowId, a.id)
                  .subscribe(m => {
                    if (m) {
                      a.model = m.model;
                    }
                  });
              }
              renActivities[a.id] = <Activity<ActivityModel>>a;
            });

            ctRefFixed.renewActivities = renActivities;

            this._workflowSvc
              .getWorkflowActivities(ctRefFixed.renewWorkflow, [
                WorkflowService.ACTIVITIES.Form,
                WorkflowService.ACTIVITIES.ContractorRegistrationInfo,
                WorkflowService.ACTIVITIES.ContractorInfo
              ])
              .subscribe(aList => {
                const result: { [type: string]: FormActivity } = {};

                aList.forEach(a => {
                  result[a.id] = <FormActivity>a;
                });

                ctRefFixed.renewFormActivities = result;

                const feeResult: {
                  [type: string]: FormActivity;
                } = {};

                for (const k in ctRefFixed.renewFormActivities) {
                  if (k) {
                    const a = ctRefFixed.renewFormActivities[k];

                    if (
                      a.model
                        .getEntities()
                        .filter(
                          de =>
                            de.dataEntityTypeCode ===
                            WorkflowService.DATA_ENTITIES.Fee.code
                        ).length > 0
                    ) {
                      feeResult[a.id] = a;
                    }
                  }
                }

                ctRefFixed.renewFeeActivities = feeResult;
              });

            this._workflowSvc
              .getWorkflowActivities(ctRefFixed.renewWorkflow, [
                WorkflowService.ACTIVITIES.PrintTemplate,
                WorkflowService.ACTIVITIES
                  .ContractorRegistrationCertificateActivity
              ])
              .subscribe(aList => {
                const result: { [type: string]: PrintTemplateActivity } = {};

                aList.forEach(a => {
                  result[a.id] = <PrintTemplateActivity>a;
                });

                ctRefFixed.renewPrintTemplateActivities = result;
              });

            this._workflowSvc
              .getWorkflowActivities(ctRefFixed.renewWorkflow, [
                WorkflowService.ACTIVITIES.Email
              ])
              .subscribe(aList => {
                const result: { [type: string]: EmailActivity } = {};

                aList.forEach(a => {
                  result[a.id] = <EmailActivity>a;
                });

                ctRefFixed.renewEmailActivities = result;
              });
          });
      }
      return ctRefFixed;
    }
  }

  getContractorType(
    id: string,
    loadDraft?: boolean
  ): Observable<ContractorType> {
    return this._dataSvc.getContractorType(id, loadDraft).pipe(
      map(ct => {
        return this.buildContractorType(ct);
      })
    );
  }
  deleteContractorType(id: string): Observable<boolean> {
    return this._dataSvc.deleteContractorType(id);
  }
  addContractor(contractor: Contractor): Observable<Contractor> {
    return this._dataSvc.saveContractor(contractor);
  }
  enableContractor(contractor: Contractor): Observable<Contractor> {
    contractor.isEnabled = true;
    return this._dataSvc.saveContractor(contractor);
  }
  disableContractor(contractor: Contractor): Observable<Contractor> {
    contractor.isEnabled = false;
    return this._dataSvc.saveContractor(contractor);
  }

  // getContractorRegistration(
  //   registrationId: string
  // ): Observable<ContractorRegistration> {
  //   var that = this;

  //   var $ua = Observable.create(function(o) {
  //     //load contractor requirement types
  //     that._dataSvc
  //       .getContractorDocuments(registrationId, "ContractorRegistration")
  //       .subscribe(docs => {
  //         that._dataSvc
  //           .getContractorRegistration(registrationId)
  //           .subscribe(reg => {
  //             reg.documents = docs;

  //             o.next(reg);
  //             o.complete();
  //           });
  //       });
  //   });

  //   return $ua;
  // }
  getContractorRegistrationHistory(
    registrationId: string
  ): Observable<ContractorRegistration[]> {
    return this._dataSvc.getContractorRegistrationHistory(registrationId);
  }
  approveRegistration(
    registration: ContractorRegistration
  ): Observable<ContractorRegistration> {
    registration.status = RegistrationStatus.Approved;
    return this._dataSvc.saveContractorRegistration(registration);
  }
  denyRegistration(
    registration: ContractorRegistration
  ): Observable<ContractorRegistration> {
    registration.status = RegistrationStatus.Denied;
    return this._dataSvc.saveContractorRegistration(registration);
  }
  getContractorRequirementTypes(
    clientId: string
  ): Observable<ContractorRequirementType[]> {
    return this._dataSvc.getContractorRequirementTypes(clientId);
  }
  getContractorRequirementType(
    requirementTypeId: string
  ): Observable<ContractorRequirementType> {
    return this._dataSvc.getContractorRequirementType(requirementTypeId);
  }
  saveContractorRequirementType(
    requirementType: ContractorRequirementType
  ): Observable<ContractorRequirementType> {
    return this._dataSvc.saveContractorRequirementType(requirementType);
  }
  getContractorRegistrationTypes(client: Client): Observable<ContractorType[]> {
    return this._dataSvc.searchContractorTypes(client, { searchText: '' });
  }
  getAvailableContractorTypes(
    clientId: string,
    contractorId: string
  ): Observable<ContractorType[]> {
    return this._dataSvc.getAvailableContractorTypes(clientId, contractorId);
  }
  getContractorsByTypes(
    clientId: string,
    contractorTypes: ContractorType[]
  ): Observable<Contractor[]> {
    const contractorTypeList: string[] = contractorTypes.map(ct => {
      return ct.name;
    });

    return this._dataSvc.searchContractors({
      clientId: clientId,
      contractorTypeList: contractorTypeList
    });
  }
  initNewContractorType(clientId: string): Observable<ContractorType> {
    return this._dataSvc.initNewContractorType(clientId).pipe(
      map(ct => {
        return this.buildContractorType(ct);
      })
    );
  }
  getContractorDocumentCategories(): Observable<ContractorDocumentCategory[]> {
    return this._dataSvc.getContractorDocumentCategories();
  }
  saveContractorDocumentCategory(
    category: ContractorDocumentCategory
  ): Observable<boolean> {
    return this._dataSvc.saveContractorDocumentCategory(category);
  }
  deleteContractorDocumentCategory(categoryId: string): Observable<boolean> {
    return this._dataSvc.deleteContractorDocumentCategory(categoryId);
  }
  publishContractorType(contractorTypeId: string): Observable<boolean> {
    return this._dataSvc.publishContractorType(contractorTypeId);
  }
  getGlobalContractorWorkflows(): Observable<Workflow[]> {
    return this._dataSvc.getGlobalContractorWorkflows();
  }

  disableContractorRegistration(registrationId: string): Observable<boolean> {
    return this._dataSvc.disableContractorRegistration(registrationId);
  }

  reEnableContractorRegistration(registrationId: string): Observable<boolean> {
    return this._dataSvc.reEnableContractorRegistration(registrationId);
  }

  queueCustomFieldMigration(
    request: CustomFieldMigrationQueueRequest
  ): Observable<boolean> {
    return this._dataSvc.queueCustomFieldMigration(request);
  }

  async getPendingCustomFieldMigrations(
    contractorTypeId: string
  ): Promise<CustomFieldMigrationQueueItem[]> {
    return this._dataSvc
      .getPendingCustomFieldMigrations(contractorTypeId)
      .toPromise();
  }
}

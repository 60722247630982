import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import {
  ItemSearchOptionField,
  ItemSearchOptionFieldOption
} from 'src/app/components/filter-list/models/filterClasses';
import { ComponentIds } from 'src/app/models/component-ids';

@Component({
  selector: 'wm-expiring-renewals',
  templateUrl: './expiring-renewals.component.html',
  styleUrls: ['./expiring-renewals.component.css']
})
export class ExpiringRenewalsComponent implements OnInit {
  @Output() recordCountSet = new EventEmitter<number>();
  @Output() filtersChanged = new EventEmitter<boolean>();

  id = ComponentIds.EXPIRING_RENEWALS_SEARCH;
  staticFilters: any;

  constructor() {}

  ngOnInit() {
    const sevenDaysFromNow = new Date();
    sevenDaysFromNow.setDate(sevenDaysFromNow.getDate() + 7);

    // this.staticFilters = new ItemSearchOptionField({
    //   title: '<expiresOn',
    //   options: [
    //     new ItemSearchOptionFieldOption({
    //       title: '<expiresOn',
    //       selected: true,
    //       filterText: '',
    //       searchText:
    //         sevenDaysFromNow.getMonth() +
    //         1 +
    //         '/' +
    //         sevenDaysFromNow.getDate() +
    //         '/' +
    //         sevenDaysFromNow.getFullYear(),
    //       strOperator: '<'
    //     })
    //   ]
    // });

    this.staticFilters = [
      {
        title: '<expiresOn',
        options: [
          new ItemSearchOptionFieldOption({
            title: '<expiresOn',
            selected: true,
            filterText: '',
            searchText:
              sevenDaysFromNow.getMonth() +
              1 +
              '/' +
              sevenDaysFromNow.getDate() +
              '/' +
              sevenDaysFromNow.getFullYear(),
            strOperator: '<'
          })
        ]
      },
      {
        title: 'staticStatusFilter',
        options: [
          new ItemSearchOptionFieldOption({
            title: 'status',
            selected: true,
            filterText: '',
            searchText: 'Approved, Inactive, Expired',
            strOperator: 'in'
          })
        ]
      }
    ];
  }

  setRecordCount(count: number) {
    this.recordCountSet.emit(count);
  }

  filtersChange() {
    this.filtersChanged.emit(true);
  }
}

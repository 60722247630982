import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
  AbstractControl
} from '@angular/forms';
import { ValidationService } from '../../../services';

@Component({
  selector: 'wm-email-address',
  templateUrl: './email-address.component.html',
  styleUrls: ['./email-address.component.css']
})
export class EmailAddressComponent implements OnInit {
  @Input() form: UntypedFormGroup;
  getEmailModel: any = '';
  @Output() emailModelChange: EventEmitter<any> = new EventEmitter<any>();
  @Input()
  get emailModel(): any {
    return this.getEmailModel;
  }
  set emailModel(value) {
    this.getEmailModel = value;
    this.emailModelChange.emit(this.getEmailModel);
  }

  @Input() controlName: string;
  @Input() required = false;

  emailAddressControl: AbstractControl;

  constructor(private fb: UntypedFormBuilder) {}
  update() {
    // this.emailModelChange.emit( this.getEmailModel);
  }
  ngOnInit() {
    if ((this.controlName || '') == '') {
      this.controlName = 'emailAddress';
    }

    if (this.required == true) {
      this.form.addControl(
        this.controlName,
        this.fb.control(
          '',
          Validators.compose([
            Validators.required,
            ValidationService.emailValidator
          ])
        )
      );
    } else {
      this.form.addControl(
        this.controlName,
        this.fb.control('', [ValidationService.emailValidator])
      );
    }

    this.emailAddressControl = this.form.controls[this.controlName];
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { ContractorRegistrationDecisionActivity } from 'src/app/models/activities/contractor-registration-decision-activity';
import { ActivityEditorBaseComponent } from '../activity-editor-base/activity-editor-base.component';

@Component({
  selector: 'wm-contractor-renewal-decision-activity-editor',
  templateUrl: './contractor-renewal-decision-activity-editor.component.html',
  styleUrls: ['./contractor-renewal-decision-activity-editor.component.css']
})
export class ContractorRenewalDecisionActivityEditorComponent
  extends ActivityEditorBaseComponent
  implements OnInit {
  @Input() activity: ContractorRegistrationDecisionActivity;

  constructor() {
    super();
  }

  ngOnInit() {}
}

import { Router } from '@angular/router';
import { Component, OnInit, Input } from '@angular/core';
import { ContractorService } from 'src/app/services';
import { StartApplicationResponse } from 'src/app/models/start-application-response';
import { ContractorRegistration } from 'src/app/models';

@Component({
  selector: 'wm-contractor-registration-item',
  templateUrl: './contractor-registration-item.component.html',
  styleUrls: ['./contractor-registration-item.component.css']
})
export class ContractorRegistrationItemComponent implements OnInit {
  @Input() registration: ContractorRegistration;
  saving = false;

  constructor(
    private _contractorSvc: ContractorService,
    private _router: Router
  ) {}

  startRenewal() {
    this.saving = true;
    this._contractorSvc
      .startRegistrationRenewal(
        this.registration.id,
        this.registration.contractorTypeId
      )
      .subscribe((applicationResponse: StartApplicationResponse) => {
        const navOptions = [
          '/application/workflow-application',
          applicationResponse.applicationId
        ];
        this._router.navigate(navOptions);
      });
  }
  ngOnInit() {}
}

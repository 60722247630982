<form [formGroup]="form">
  <div class="form-group">
    <label class="bmd-label-floating" for="paymentRedirectURL">
      Payment Redirect URL
    </label>
    <input
      type="text"
      class="form-control"
      formControlName="paymentRedirectURL"
      name="paymentRedirectURL"
      [(ngModel)]="securePayConfig.paymentRedirectURL"
    />
  </div>
</form>

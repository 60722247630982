import { Workflow } from './workflow';

export class CommunityWorkflow {
  id: string;
  name: string;
  description: string;
  workflow: Workflow;
  sharedBy: string;
  lastUpdated: Date;
  createdOn: Date;

  constructor(options?: Partial<CommunityWorkflow>) {
    if (options) {
      Object.assign(this, options);
    }
  }
}

import { Component, OnInit, EventEmitter } from '@angular/core';
import { ActivityView } from '../../../../../views/master-views/app.view/app.view.component';

@Component({
  selector: 'wm-renewal-decision-activity-view',
  templateUrl: './renewal-decision-activity-view.component.html',
  styleUrls: ['./renewal-decision-activity-view.component.css']
})
export class RenewalDecisionActivityViewComponent extends ActivityView
  implements OnInit {
  constructor() {
    super();
  }

  ngOnInit() {}
}

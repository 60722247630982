import { ScreenActivity } from './activity';
import { ActivityModel, Activity } from './';
import { DataEntity } from '../data-entities';
import { Workflow } from '../workflow';
import { DataEntityMapping } from './data-entity-mapping';

export class CallWorkflowActivityModel extends ActivityModel {
  targetWorkflowVersionId: string;
  targetWorkflowName: string;
  targetWorkflowId: string;
  parentToChildMappings: DataEntityMapping[];
  childToParentMappings: DataEntityMapping[];
  callingWorkflowApplicationId: string;
  skipPayment: boolean;

  constructor(options?: Partial<CallWorkflowActivityModel>) {
    super(options);

    if (options) {
      Object.assign(this, options);
    }
  }

  getEntities() {
    if (this.childToParentMappings) {
      return this.childToParentMappings.map(
        (value: DataEntityMapping, index: number) => {
          return new DataEntity({
            templateCode: value.targetDataEntity,
            label: value.targetDataEntity || value.targetDataEntity
          });
        }
      );
    }
  }
}

export class CallWorkflowActivity extends ScreenActivity<
  CallWorkflowActivityModel
> {
  mostRecentlyPublishedTargetWorkflowName: string;
  mostRecentlyPublishedTargetWorkflowVersionId: string;

  constructor(options: { [key: string]: any }) {
    super(options);

    if (options) {
      Object.assign(this, options);
    }
    this.model = new CallWorkflowActivityModel(options ? options.model : null);
  }
}

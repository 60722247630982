import { PointNPayPaymentAccountActivityConfiguration } from './../../../models/payment-providers/pointnpay/pointnpay-payment-account-activity-configuration';
import { PointNPayActivityParameterConfiguration } from '../../../models/payment-providers/pointnpay/pointnpay-payment-account-activity-parameter-configuration';
import { PaymentAccountActivityConfigBaseComponent } from '../payment-account-activity-config-base/payment-account-activity-config-base.component';
import { Component, OnInit } from '@angular/core';
import { PointNPayPaymentProcessorAccountConfiguration } from 'src/app/models/payment-providers/pointnpay/pointnpay-payment-processor-account-configuration';
import { ConditionTarget } from '../../system/condition-builder/condition-builder.model';

@Component({
  selector: 'wm-payment-account-activity-config-pointnpay',
  templateUrl: './payment-account-activity-config-pointnpay.component.html',
  styleUrls: ['./payment-account-activity-config-pointnpay.component.css']
})
export class PaymentAccountActivityConfigPointnpayComponent
  extends PaymentAccountActivityConfigBaseComponent
  implements OnInit {
  activityConfig: PointNPayPaymentAccountActivityConfiguration;
  accountConfig: PointNPayPaymentProcessorAccountConfiguration;

  constructor() {
    super();
  }

  getThenLabel = (clause: ConditionTarget): string => {
    if (clause) {
      return clause.value;
    } else {
      return '(unset)';
    }
  }

  paramChanged(param: PointNPayActivityParameterConfiguration, criteria) {
    param.criteria = criteria;
    this.configUpdated.next(this.activityConfig);
  }

  ngOnInit() {
    if (this.paymentActivity) {
      if (this.paymentActivity.paymentAccount) {
        this.accountConfig = <PointNPayPaymentProcessorAccountConfiguration>(
          this.paymentActivity.paymentAccount.providerAccountSettings
        );
      }

      let activityConfig = <PointNPayPaymentAccountActivityConfiguration>(
        this.paymentActivity.activityAccountConfiguration
      );

      if (!activityConfig) {
        activityConfig = this.accountConfig.activityConfiguration;
      }

      activityConfig.accountId = this.paymentActivity.paymentAccount.id;

      if (this.accountConfig && this.accountConfig.parameterNames) {
        this.accountConfig.parameterNames.forEach(pn => {
          if (!activityConfig.parameterValues) {
            activityConfig.parameterValues = [];
          }
          const activityConfigParm = activityConfig.parameterValues.find(
            pv => pv.parameterName === pn
          );

          if (!activityConfigParm) {
            const paramConfig = new PointNPayActivityParameterConfiguration();
            paramConfig.parameterName = pn;
            activityConfig.parameterValues.push(paramConfig);
          }
        });
      }

      this.activityConfig = activityConfig;
    }
  }
}

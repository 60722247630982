<form [formGroup]="form">
  <select
    #sel
    (change)="changePaymentProcessor(sel.value)"
    [(ngModel)]="selectedPaymentProcessor"
    formControlName="paymentProcessor"
    class="form-control"
  >
    <option></option>
    <option *ngFor="let processor of processors" [ngValue]="processor">
      {{ processor.name }}
    </option>
  </select>
</form>

import { Component, OnInit, ViewChild } from '@angular/core';
import {
  User,
  Role,
  Actions,
  EmailAddress,
  PhoneNumber,
  Address,
  UserAddress
} from '../../../../models';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { StatusType } from '../user-list-view/user-list-view.component';
import {
  WorkflowContextService,
  SecurityService,
  DataService,
  SystemService
} from '../../../../services';
import { ActivatedRoute, Router } from '@angular/router';
import { ProcessingStatusComponent } from '../../../../components/system/processing-status/processing-status.component';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'wm-user-detail-view',
  templateUrl: './user-detail-view.component.html',
  styleUrls: ['./user-detail-view.component.css']
})
export class UserDetailViewComponent implements OnInit {
  userId: string;
  user: User;
  roles: Role[];
  @ViewChild('saveProcessingStatus', { static: false })
  saveProcessingStatus: ProcessingStatusComponent;
  showAssociateRole = false;
  showAssociateGroup = false;
  showNewEmail = false;
  showNewAddress = false;
  showNewPhoneNumber = false;
  form: UntypedFormGroup;
  canDeleteUser = false;
  resetPasswordText = 'Reset Password';
  canResetPassword = true;
  statusMessage: string;
  statusType: StatusType;

  constructor(
    public context: WorkflowContextService,
    private _securitySvc: SecurityService,
    private _dataSvc: DataService,
    private route: ActivatedRoute,
    private _router: Router,
    private _systemSvc: SystemService,
    private toastr: ToastrService,
    fb: UntypedFormBuilder
  ) {
    if (fb) {
      this.form = fb.group({
        fullName: ['', Validators.required],
        userName: ['', Validators.required],
        emailAddress: ['', Validators.required],
        phoneNumber: ['', Validators.nullValidator]
      });
    }
  }
  buildBaseRoute(): string[] {
    let routeDetails: string[] = [];

    if (this.context.client) {
      routeDetails = ['admin', 'jurisdiction', this.context.client.id, 'users'];
    } else {
      routeDetails = ['admin', 'global', 'users'];
    }

    return routeDetails;
  }

  ngOnInit() {
    if (this.route) {
      this.route.params.subscribe(params => {
        this.userId = params['userId'];

        this.loadUser();
        //this._securitySvc.isLoginEntitled(Actions.DELETE_USER).subscribe(entitled => {
        //  this.canDeleteUser = entitled;
        //});
      });
    }
  }
  loadUser() {
    if (this.userId !== '' && this.userId !== null) {
      this._securitySvc.getUser(this.userId).subscribe(user => {
        if (user) {
          if (!user.phoneNumber) {
            user.phoneNumber = '';
          }
          if (!user.emailAddress) {
            user.emailAddress = '';
          }
          if (!user.address) {
            user.address = new UserAddress();
          }

          this.user = user;

          //this.loadGroups();
          this.loadRoles();
        } else {
          this.user = this._securitySvc.createUser();
        }
      });
    } else {
      this.user = this._securitySvc.createUser();
    }
  }
  save() {
    this.saveProcessingStatus.show();
    this._securitySvc.saveUser(this.user).subscribe(
      res => {
        if (!res.responseMessage) {
          this.saveProcessingStatus
            .finished('User saved successfully')
            .subscribe(finished => {
              const routeDetails = this.buildBaseRoute();
              this.toastr.success('Saved!');
              this._router.navigate(routeDetails);
            });
        } else {
          this.saveProcessingStatus
            .finished('Save failed')
            .subscribe(finished => {
              this.toastr.error(res.responseMessage, 'Could not save user', {
                disableTimeOut: true
              });
            });
        }
      },
      err => {
        this.statusMessage = err;
        this.statusType = StatusType.Critical;
      }
    );
  }
  roleRemoved(e) {
    this._securitySvc.removeUserRole(this.userId, e).subscribe(
      res => {
        this.toastr.success('Removed!');
        this.loadRoles();
      },
      err => {
        this.statusMessage = err;
        this.statusType = StatusType.Critical;
      }
    );
  }
  loadRoles() {
    this._securitySvc.getUserRoles(this.userId).subscribe(roles => {
      this.roles = roles;
    });
  }
  backToList() {
    const routeDetails = this.buildBaseRoute();
    this._router.navigate(routeDetails);
  }
  cancel() {
    this.backToList();
    // this._router.navigate(['/admin/global/users']);
  }
  enterNewEmailAddress() {
    this.showNewEmail = true;
  }
  //newEmailAddressSaved(emailAddress: EmailAddress) {
  //  this.user.emailAddress = emailAddress;
  //}
  newEmailAddressCanceled(emailAddress: EmailAddress) {
    this.showNewEmail = false;
  }
  enterNewAddress() {
    this.showNewAddress = true;
  }
  //newAddressSaved(address: Address) {
  //  this.user.addresses.push(address);
  //}
  newAddressCanceled(address: Address) {
    this.showNewAddress = false;
  }
  enterNewPhoneNumber() {
    this.showNewPhoneNumber = true;
  }
  //newPhoneNumberSaved(phoneNumber: PhoneNumber) {
  //  if (!this.user.phoneNumbers) {
  //    this.user.phoneNumbers = [];
  //  }
  //  this.user.phoneNumbers.push(phoneNumber);
  //}
  newPhoneNumberCanceled(phoneNumber: PhoneNumber) {
    this.showNewPhoneNumber = false;
  }
  canDisable() {
    return this.user.id !== '';
  }

  resetPassword(user: User) {
    this._securitySvc.resetPassword(this.user.userName).subscribe(
      res => {
        if (res) {
          this.resetPasswordText = 'Email sent to user';
          this.canResetPassword = false;
        }
      },
      err => {
        this.statusMessage = err;
        this.statusType = StatusType.Critical;
      }
    );
  }
  associateRole(role: Role) {
    this._securitySvc.associateRole(this.user, role).subscribe(
      () => {
        this.loadRoles();
        this.toastr.success('Added!');
        this.showAssociateRole = false;
      },
      err => {
        this.statusMessage = err;
        this.statusType = StatusType.Critical;
      }
    );
  }
  associateRoleCanceled(role: Role) {
    this.showAssociateRole = false;
  }
  enableUser() {
    this._securitySvc.enableUser(this.user).subscribe(
      res => {
        this.toastr.success('Enabled!');
        this.loadUser();
      },
      err => {
        this.statusMessage = err;
        this.statusType = StatusType.Critical;
      }
    );
  }
  disableUser() {
    this._securitySvc.disableUser(this.user).subscribe(
      res => {
        this.toastr.success('Disabled!');
        this.loadUser();
      },
      err => {
        this.toastr.warning('Error!');
        this.statusMessage = err;
        this.statusType = StatusType.Critical;
      }
    );
  }
}

import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { WorkflowApplication } from './../../../models/workflow-application';
import { RichTextDataEntity } from 'src/app/models/data-entities/rich-text-data-entity';
import { Component, OnInit, Input } from '@angular/core';
import { DocumentDataEntity } from 'src/app/models/data-entities';
import { SecurityService, DocumentService } from 'src/app/services';
import { Actions } from 'src/app/models';

@Component({
  selector: 'wm-application-internal-note-editor',
  templateUrl: './application-internal-note-editor.component.html',
  styleUrls: ['./application-internal-note-editor.component.css']
})
export class ApplicationInternalNoteEditorComponent implements OnInit {
  notesDE: RichTextDataEntity;
  documentsDE: DocumentDataEntity;
  @Input() application: WorkflowApplication;
  @Input() form: UntypedFormGroup;
  @Input() readOnly: boolean;

  constructor(
    private _securitySvc: SecurityService,
    private _uploadSvc: DocumentService
  ) {}

  async ngOnInit() {
    if (!this.form) {
      this.form = new UntypedFormGroup({
        internalDocuments: new UntypedFormControl('', Validators.nullValidator),
        internalNotes: new UntypedFormControl(
          this.application.internalNotes,
          Validators.nullValidator
        )
      });
    } else {
      this.form.addControl(
        'internalDocuments',
        new UntypedFormControl('', Validators.nullValidator)
      );
      this.form.addControl(
        'internalNotes',
        new UntypedFormControl(
          this.application.internalNotes,
          Validators.nullValidator
        )
      );
    }

    this.form.controls['internalNotes'].valueChanges.subscribe(e => {
      this.application.internalNotes = e;
    });

    this.notesDE = new RichTextDataEntity({
      html: this.application.internalNotes,
      value: this.application.internalNotes,
      templateCode: 'internalNotes'
    });

    const internalDocs = await this._uploadSvc
      .getDocuments([
        'Application',
        this.application.id,
        'internalDocuments',
        'internalDocuments'
      ])
      .toPromise();

    this.documentsDE = new DocumentDataEntity({
      templateCode: 'internalDocuments',
      documents: internalDocs,
      validDocumentTypes: 'pdf,png,jpg,gif,jpeg'
    });

    this._securitySvc
      .isLoginEntitled(Actions.WORKFLOW_APPLICATIONS_EDIT)
      .subscribe(result => {
        this.readOnly = this.readOnly || !result;
      });
  }
}

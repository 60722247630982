import { DataEntity } from './data-entity';
import { DataEntityYesNoItem } from '../../components/workflow/activities/print-template-activity/print-template-activity-editor/print-template-activity-editor.component';

export class YesNoDataEntity extends DataEntity {
  listValues = [];
  public controlType = 'radio';

  constructor(options?: Partial<YesNoDataEntity>) {
    super(options);

    this.dataEntityTypeCode = 'yes-no';
    this.dataEntityTypeDescription = 'Use this entity type to create a list.';
    this.dataEntityTypeName = 'List';

    if (options) {
      Object.assign(this, options);
    }
  }

  getPrintTemplateItems(): DataEntityYesNoItem[] {
    return [
      new DataEntityYesNoItem({
        code: this.templateCode,
        text: this.templateCode,
        children: [
          ...this.listValues.map(
            a =>
              new DataEntityYesNoItem({
                code: this.templateCode,
                text: a,
                extraData: a
              })
          ),
          new DataEntityYesNoItem({
            code: this.templateCode,
            text: 'Selected Value',
            extraData: 'Selected'
          })
        ]
      })
    ];
  }

  getPossibleValues() {
    return [{ name: 'Yes', value: 'Yes' }, { name: 'No', value: 'No' }];
  }
}

import { Component, OnInit, Input } from '@angular/core';
import { FormActivityInputLayout } from '../form-activity-input.component';
import {
  FormActivity,
  TwoColumnFormLayoutModel
} from '../../../../../../models/activities';
import { UntypedFormGroup } from '@angular/forms';
import { FormActivityInputLayoutBaseComponent } from '../form-activity-input-layout-base/form-activity-input-layout-base.component';
import { WorkflowApplication } from '../../../../../../models';

@Component({
  selector: 'wm-form-activity-input-layout-two-column',
  templateUrl: './form-activity-input-layout-two-column.component.html',
  styleUrls: ['./form-activity-input-layout-two-column.component.css']
})
export class FormActivityInputLayoutTwoColumnComponent
  extends FormActivityInputLayoutBaseComponent
  implements OnInit, FormActivityInputLayout {
  @Input() activity: FormActivity;
  @Input() form: UntypedFormGroup;
  @Input() application: WorkflowApplication;

  formLayoutModel: TwoColumnFormLayoutModel;

  constructor() {
    super();
  }

  ngOnInit() {
    this.formLayoutModel = this.activity.model
      .formLayoutModel as TwoColumnFormLayoutModel;
  }
}

<form method="POST" [formGroup]="form">
  <form method="POST" #paymentForm></form>

  <div class="row">
    <!-- Manual Payment Details -->
    <div class="col-6">
      <div *ngIf="activity.model.totalDue > 0">
        <form *ngIf="manualForm" [formGroup]="manualForm">
          <ng-container *ngIf="showManualPayment">
            <div class="form-group">
              <label for="paymentMethod" class="bmd-label-floating">
                Payment Method <span class="text-red">*</span>
              </label>
              <select
                class="form-control"
                [(ngModel)]="activity.model.paymentMethod"
                formControlName="paymentMethod"
                name="paymentMethod"
              >
                <option [ngValue]="2">Credit Card POS</option>
                <option [ngValue]="0">Check</option>
                <option [ngValue]="1">Cash</option>
                <option
                  [ngValue]="3"
                  *ngIf="
                    hasIntegration && (!isTestApplication || isGlobalAdmin)
                  "
                  >Online</option
                >
              </select>
              <wm-control-message
                [control]="manualForm.controls['paymentMethod']"
              ></wm-control-message>
              <div
                *ngIf="activity.model.paymentMethod == '2'"
                class="text-danger"
              >
                Credit Card POS (Point of Sale) should only be used by
                authorized users to manually record a credit card payment that
                was collected outside of GeoPermits.
              </div>
            </div>
            <div class="form-group">
              <label for="transactionNumber" class="bmd-label-floating">
                {{
                  activity.model.paymentMethod == PaymentMethods.Check
                    ? 'Check #'
                    : activity.model.paymentMethod ==
                      PaymentMethods.CreditCardPOS
                    ? 'Confirmation Number'
                    : 'Notes'
                }}
                <span
                  class="text-red"
                  *ngIf="activity.model.paymentMethod == '2'"
                  >*</span
                >
              </label>
              <input
                type="text"
                class="form-control"
                [(ngModel)]="activity.model.transactionNumber"
                formControlName="transactionNumber"
                name="transactionNumber"
              />
            </div>
            <wm-control-message
              [control]="manualForm.controls['transactionNumber']"
            ></wm-control-message>
          </ng-container>
          <ng-container *ngIf="hasIntegration || showManualPayment">
            <div
              class="form-group"
              *ngIf="activity.model.allowMultiplePayments"
            >
              <div class="row">
                <div class="col-12 mb-2">
                  Payment Amount <span class="text-red">*</span>
                  <wm-control-message
                    [control]="manualForm.controls['paymentAmountOption']"
                  ></wm-control-message>
                </div>
              </div>
              <div class="row ml-2">
                <div class="col-3 col-sm-3 col-md-4 col-lg-2 col-xl-4">
                  <input
                    type="radio"
                    value="fullAmount"
                    formControlName="paymentAmountOption"
                    [checked]="false"
                    (click)="payFull()"
                  />
                  Total Due
                </div>
                <div
                  class="col-9 col-sm-9 col-md-8 col-lg-10 col-xl-8"
                  [ngClass]="{
                    disabledInActiveDiv: paymentOption !== 'fullAmount'
                  }"
                >
                  {{ activity.model.totalDue | currency }}
                </div>
              </div>
              <div class="row ml-2">
                <div class="col-3 col-sm-3 col-md-4 col-lg-2 col-xl-4">
                  <input
                    type="radio"
                    value="partialAmount"
                    formControlName="paymentAmountOption"
                    [checked]="false"
                    (click)="payPartial()"
                  />
                  Other Amount
                </div>
                <div
                  class="col-9 col-sm-9 col-md-8 col-lg-10 col-xl-8"
                  [ngClass]="{
                    disabledInActiveDiv: paymentOption !== 'partialAmount'
                  }"
                >
                  <input
                    type="number"
                    class="form-control"
                    formControlName="paymentAmount"
                    [(ngModel)]="activity.model.paymentAmount"
                  />
                  <div class="small">
                    You will not be able to continue past this step in the
                    process until the fees are paid in full.
                  </div>
                  <wm-control-message
                    [control]="manualForm.controls['paymentAmount']"
                  ></wm-control-message>
                </div>
              </div>
            </div>
            <button
              class="btn btn-raised btn-primary"
              [disabled]="
                processing ||
                !PaymentMethods[activity.model.paymentMethod] ||
                viewingAsApplicantInTest
              "
              (click)="submitPayment()"
            >
              <span *ngIf="!processing">Make Payment</span>

              <span *ngIf="processing">
                <span
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                Loading Payment Form...
              </span>
            </button>
          </ng-container>
        </form>
        <span *ngIf="viewingAsApplicantInTest">
          <i>
            Cannot make an online payment in a test application. Please change
            to an admin role to make a manual payment.
          </i>
        </span>
        <span
          *ngIf="
            isGlobalAdmin &&
            isTestApplication &&
            activity.model.paymentMethod === PaymentMethods.Online
          "
        >
          <i>
            Note: Not all online payment providers are set up to work in a test
            application.
          </i>
        </span>
        <span *ngIf="!hasIntegration && !showManualPayment">
          Please pay in the office
        </span>
      </div>
      <div *ngIf="!(activity.model.totalDue > 0)">
        <i>No fees due.</i>
      </div>
    </div>
    <div class="col-6">
      <div class="card">
        <div class="card-body">
          <h6 class="card-title">Fee Summary</h6>
          <div *ngIf="!lineItems"><i>Loading Fees</i></div>

          <ng-container *ngIf="lineItems">
            <ul class="list-unstyled mt-3">
              <li *ngFor="let fee of lineItems" class="my-2">
                {{ fee.name }}
                <span class="float-right">{{ fee.amount | currency }}</span>
              </li>
            </ul>

            <hr />
          </ng-container>

          <ng-container *ngIf="activity.model.fees">
            <ul class="list-unstyled mt-3">
              <li>
                Total Fees
                <span class="float-right">
                  {{ activity.model.totalFees | currency }}
                </span>
              </li>
            </ul>

            <hr />
          </ng-container>

          <h6 class="mt-4">
            Total Due

            <span class="float-right font-weight-bold">
              {{ activity.model.totalDue | currency }}
            </span>
          </h6>
        </div>
      </div>
      <ng-container
        *ngIf="activity.model.payments && activity.model.payments.length > 0"
      >
        <div class="card mt-4">
          <div class="card-body">
            <h6 class="card-title">
              Payments Made
            </h6>
            <table class="table">
              <thead>
                <tr>
                  <th>Payment Method</th>
                  <th>Paid On</th>
                  <th style="text-align: right">Amount</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let pmt of activity.model.payments">
                  <td>{{ pmt.paymentMethod }}</td>
                  <td>{{ pmt.confirmedOn | date: 'MMMM d, yyyy h:mm a' }}</td>
                  <td align="right">{{ pmt.requestedAmount | currency }}</td>
                  <td>
                    <a
                      [href]="apiBase + '/files/receipt/' + pmt.id"
                      class="btn btn-raised btn-primary float-right"
                      target="_blank"
                      rel="noopener"
                    >
                      View receipt
                    </a>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td align="right" colspan="3">
                    <b>Total Paid:</b>&nbsp;&nbsp;
                    {{ activity.model.totalPaid | currency }}
                  </td>
                  <td>
                    <a
                      [href]="
                        apiBase +
                        '/files/activityReceipt/' +
                        activity.activityDataId
                      "
                      class="btn btn-raised btn-primary float-right"
                      target="_blank"
                      rel="noopener"
                      *ngIf="
                        activity.model.payments &&
                        activity.model.payments.length > 1
                      "
                    >
                      Full Receipt
                    </a>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</form>

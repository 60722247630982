<h6>{{ title | titlecase }}</h6>

<div
  dragula="TARGETS"
  [dragulaModel]="conditionsWithoutOtherwise"
  (dragulaModelChange)="reorderConditions($event)"
>
  <div
    class="card card-sm mb-2"
    [ngClass]="{
      'text-white': target.shouldRoute || false,
      'bg-success': target.shouldRoute || false,
      'card-grey': !(target.shouldRoute || false)
    }"
    *ngFor="let target of conditionsWithoutOtherwise"
  >
    <div class="card-body">
      <div class="card-title">
        <fa-icon
          [icon]="faGripLines"
          style="cursor: move;"
          class="pr-2"
        ></fa-icon>

        {{ getThenLabel(target)
        }}<span *ngIf="!getThenLabel(target)" class="text-danger">(unset)</span>

        <fa-icon
          class="float-right"
          [icon]="faPen"
          style="cursor: pointer"
          (click)="openTargetModal(target)"
        ></fa-icon>
      </div>
    </div>
  </div>
</div>

<div id="otherwise-target">
  <div
    class="card card-sm"
    [ngClass]="{
      'text-white': otherwiseCondition.shouldRoute || false,
      'bg-success': otherwiseCondition.shouldRoute || false,
      'card-grey': !(otherwiseCondition.shouldRoute || false)
    }"
    *ngIf="!otherwiseOpen"
  >
    <div class="card-body">
      <div class="card-title">
        Otherwise
        <span *ngIf="otherwiseCondition">{{
          getThenLabel(otherwiseCondition)
        }}</span
        ><span *ngIf="!getThenLabel(otherwiseCondition)" class="text-danger"
          >(unset)</span
        >

        <fa-icon
          *ngIf="allowEditingElse"
          class="float-right"
          [icon]="faPen"
          style="cursor: pointer"
          (click)="otherwiseOpen = true"
        ></fa-icon>
      </div>
    </div>
  </div>
  <div class="card card-grey" *ngIf="otherwiseOpen">
    <div class="card-body">
      <p>Otherwise</p>
      <form>
        <ng-container
          *ngTemplateOutlet="
            templateRef;
            context: { target: otherwiseCondition }
          "
        >
        </ng-container>
      </form>

      <button
        class="btn btn-raised btn-primary ml-2 float-right"
        (click)="applyTarget()"
      >
        Apply
      </button>
    </div>
  </div>
</div>

<button class="btn btn-raised btn-primary mt-3" (click)="addTarget()">
  Add Target
</button>

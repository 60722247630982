<div class="row">
  <div class="col-md-8 offset-md-2 col-lg-6 offset-lg-3">
    <div class="mt-5 mx-auto">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">Register New User</h5>

          <app-loader *ngIf="loading"></app-loader>

          <form
            (submit)="save()"
            #registerForm="ngForm"
            [formGroup]="form"
            novalidate
            *ngIf="!registrationCompleted && !invitedEmailHasUser && !loading"
          >
            <div
              class="form-group"
              [class.is-invalid]="fullName.hasError('required')"
            >
              <label for="fullName" class="bmd-label-static"
                >Full Name <span class="text-danger">*</span></label
              >
              <input
                type="text"
                class="form-control field"
                [(ngModel)]="user.name"
                [formControl]="fullName"
                name="fullName"
              />
              <wm-control-message
                [controlName]="'fullName'"
              ></wm-control-message>
              <!--<div *ngIf="fullName.hasError('required') && fullName.touched" class="text-danger">
              Full Name is required
          </div>-->
            </div>

            <div
              class="form-group"
              [class.is-invalid]="userName.hasError('required')"
            >
              <label for="userName" class="bmd-label-static"
                >User Name <span class="text-danger">*</span></label
              >
              <input
                type="text"
                class="form-control"
                [(ngModel)]="user.userName"
                [formControl]="userName"
                name="userName"
              />
              <wm-control-message
                [controlName]="'userName'"
              ></wm-control-message>
              <!--<div *ngIf="userName.hasError('required') && userName.touched" class="text-danger">
              Username is required
          </div>-->
            </div>

            <div
              class="form-group"
              [class.is-invalid]="password.hasError('required')"
              *ngIf="showPasswordInput"
            >
              <label for="password" class="bmd-label-static"
                >Password <span class="text-danger">*</span></label
              >
              <input
                type="password"
                class="form-control"
                [(ngModel)]="user.password"
                [formControl]="password"
                name="password"
              />
              <wm-control-message
                [controlName]="'password'"
              ></wm-control-message>
              <!--<div *ngIf="password.hasError('required') && password.touched" class="text-danger">
              Password is required
          </div>-->
            </div>

            <div
              class="form-group"
              [class.is-invalid]="
                confirmPasswordCtl.hasError('required') ||
                form.hasError('mismatchedPasswords')
              "
              *ngIf="showPasswordInput"
            >
              <label for="confirmPassword" class="bmd-label-static"
                >Confirm Password <span class="text-danger">*</span></label
              >
              <input
                type="password"
                class="form-control"
                [(ngModel)]="user.confirmPassword"
                [formControl]="confirmPasswordCtl"
                name="confirmPassword"
              />
              <wm-control-message
                [controlName]="'confirmPassword'"
              ></wm-control-message>
              <!--<div *ngIf="confirmPasswordCtl.hasError('required') && confirmPasswordCtl.touched" class="text-danger">
              Confirm Password is required
          </div>-->
              <div
                *ngIf="
                  form.hasError('mismatchedPasswords') &&
                  confirmPasswordCtl.touched
                "
                class="text-danger"
              >
                Passwords do not match
              </div>
            </div>

            <div
              class="form-group"
              [class.is-invalid]="
                emailAddress.hasError('required') || form.hasError('required')
              "
            >
              <label for="email" class="bmd-label-static"
                >Email Address <span class="text-danger">*</span></label
              >
              <input
                type="text"
                class="form-control"
                [(ngModel)]="user.emailAddress"
                [formControl]="emailAddress"
                name="email"
              />
              <wm-control-message
                [controlName]="'emailAddress'"
              ></wm-control-message>
              <div
                *ngIf="
                  emailAddress.hasError('required') && emailAddress.touched
                "
                class="text-danger"
              >
                Email Address is required
              </div>
            </div>

            <div class="form-group">
              <wm-address-detail
                [address]="user.address"
                [showButtons]="false"
                [required]="false"
                [showName]="false"
              ></wm-address-detail>
            </div>

            <div class="form-group">
              <label for="phoneNumber" class="bmd-label-static"
                >Phone Number</label
              >
              <input
                type="text"
                class="form-control"
                [(ngModel)]="user.phoneNumber"
                [formControl]="phoneNumber"
                name="phoneNumber"
              />
            </div>

            <div class="mt-5">
              <button class="btn btn-default" (click)="cancel()">
                Cancel
              </button>
              <button
                class="btn btn-raised btn-primary"
                [disabled]="!registerForm.valid || saving"
              >
                <span *ngIf="!saving">Register</span>

                <span *ngIf="saving">
                  <span
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Registering...
                </span>
              </button>
            </div>
          </form>

          <div *ngIf="registrationCompleted && !invitation">
            <h6>
              Account Verification
            </h6>
            <p>
              An email has been sent to the address you provided. Please click
              on the link included to activate your account.
            </p>
          </div>
          <div *ngIf="registrationCompleted && invitation">
            <h6>
              Account Created
            </h6>
            <p>
              Your account has been created succesfully.
            </p>
            <p>
              <a [routerLink]="['/auth/login']">Log In</a>
            </p>
          </div>
          <div *ngIf="invitedEmailHasUser">
            <p>
              There has already been an account created for
              {{ invitation.email }}.
            </p>
            <p>
              <a [routerLink]="['/auth/login']">Log In</a>
            </p>
          </div>
        </div>
        <div class="card-footer">
          <a
            id="lnkUserPrivacyPolicy"
            title="Opens in a new tab"
            href="https://www.schneidergis.com/privacypolicy"
            target="_blank"
            >User Privacy Policy</a
          >
          <br />
          <a
            id="lnkGDPRPrivacyPolicy"
            title="Opens in a new tab"
            href="https://www.schneidergis.com/gdprprivacynotice"
            target="_blank"
            >GDPR Privacy Notice</a
          >
        </div>
      </div>
    </div>
  </div>
</div>

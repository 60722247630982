import { Component, OnInit, ComponentFactoryResolver } from '@angular/core';
import { DataEntityViewComponent } from '../../data-entity-view/data-entity-view.component';
import { CalculatedValueDataEntity } from '../../../../../models/data-entities';

@Component({
  selector: 'wm-calculate-value-data-entity-view',
  templateUrl: './calculate-value-data-entity-view.component.html',
  styleUrls: ['./calculate-value-data-entity-view.component.css']
})
export class CalculateValueDataEntityViewComponent
  extends DataEntityViewComponent
  implements OnInit {
  entity: CalculatedValueDataEntity;

  constructor(componentFactoryResolver: ComponentFactoryResolver) {
    super(componentFactoryResolver);
  }

  ngOnInit() {
    if (this.entity && !this.entity.formattedValue) {
      this.entity.formattedValue = '';
    }
  }
}

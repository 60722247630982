<ng-container *ngIf="ds">
  <div *ngIf="(ds.id || '') == ''" class="mb-4">
    <h4>New Data Set</h4>
    <p class="small">
      A Data Set allows you to combine data entities from multiple workflows
      into a list of columns that you define. Once created and published, you
      can export this Data Set with appropriate filters. If you map multiple
      data entities from a workflow into a column, those values will be
      concatenated with a comma.
    </p>
    <ol class="small">
      <li>Name the Data Set and give it a description.</li>
      <li>
        Add columns to your Data Set in the order you want them displayed.
      </li>
      <li>Choose at least one column to use as the default sort order.</li>
      <li>
        Optional: Add workflows to the Data Set if you want to restrict the
        results to specific workflows.
      </li>
      <li>
        Optional: Add filtering to any workflows if you want to filter the data
        from that workflow that will be included in the Data Set.
      </li>
      <li>
        Optional: Add column(s) to the filter input to allow the user(s) that
        are using the Data Set to export data to filter the Data Set even
        further.
      </li>
      <li>Save the Data Set.</li>
      <li>
        Publish the Data Set when you are ready for it to be used by others.
      </li>
      <li>
        Once configured, utilize the Export Data Set tab in the Data Export
        Report to run reports from the Data Set.
      </li>
    </ol>
    <p class="small">
      To view more details about this feature, please see our User Guide.
    </p>
  </div>
  <div class="row" [formGroup]="form">
    <div class="col">
      <div class="card">
        <div class="card-body">
          <div class="card-title">
            <h4>Data Set Information</h4>
          </div>
          <div class="row">
            <div class="col">
              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <label class="bmd-label-floating" for="dataSetName"
                      >Name <span class="text-danger">*</span></label
                    >
                    <input
                      type="text"
                      class="form-control"
                      [(ngModel)]="ds.name"
                      name="dataSetName"
                      formControlName="dataSetName"
                    />
                    <wm-control-message
                      [control]="form.controls['dataSetName']"
                    ></wm-control-message>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <label class="bmd-label-floating" for="dataSetDesc"
                      >Description</label
                    >
                    <input
                      type="text"
                      class="form-control"
                      [(ngModel)]="ds.description"
                      name="dataSetDesc"
                      formControlName="dataSetDesc"
                    />
                    <wm-control-message
                      [control]="form.controls['dataSetDesc']"
                    ></wm-control-message>
                  </div>
                </div>
              </div>
              <div class="row" *ngIf="ds.hasCustomReports && isDeveloper">
                <div class="col">
                  <div class="form-group">
                    <label class="bmd-label-floating" for="dataSetType"
                      >Data Set Type</label
                    >
                    <select
                      class="form-control"
                      [(ngModel)]="ds.dataSetConfig.type"
                      name="dataSetType"
                      formControlName="dataSetType"
                    >
                      <option value="workflow-application-dataset"
                        >Workflow Applications</option
                      >
                      <option value="workflow-application-parcel-search-dataset"
                        >Workflow Application Parcel Search</option
                      >
                      <option
                        value="workflow-application-wi-sanitary-permit-submittal-summary-dataset"
                        >Workflow Application WI Sanitary Submittal
                        Summary</option
                      >
                    </select>
                    <wm-control-message
                      [control]="form.controls['dataSetType']"
                    ></wm-control-message>
                  </div>
                </div>
              </div>
              <div class="row" *ngIf="isDeveloper">
                <div class="col">
                  <div class="form-group">
                    <label class="bmd-label-floating" for="dataSetOutput"
                      >Output Data Set</label
                    >
                    <select
                      class="form-control"
                      [(ngModel)]="ds.dataSetConfig.outputDataSet"
                      name="dataSetOutput"
                      formControlName="dataSetOutput"
                    >
                      <option [ngValue]="null">Base Data Set</option>
                      <option
                        *ngFor="let aggDS of dataSet.aggregatedDataSets"
                        [ngValue]="aggDS.name"
                        >{{ aggDS.name }}</option
                      >
                    </select>
                    <wm-control-message
                      [control]="form.controls['dataSetOutput']"
                    ></wm-control-message>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<div *ngIf="dataSet">
  <div class="row" *ngIf="ds.hasCustomReports">
    <div class="col-12">
      <div class="card bg-danger mt-3">
        <div class="card-body">
          <div class="text-white">
            NOTE! There are Custom Reports associated with this Data Set. Modify
            this Data Set carefully.
            <ol>
              <li>
                Changes to the Filter Input Configuration will have an impact on
                the filter inputs for the Custom Reports.
              </li>
              <li>
                Removing a column that is used by the report will cause the data
                from that column to no longer show up on the report.
              </li>
              <li>
                Changing a column name that is used by the report will cause the
                data from that column to no longer show up in the report.
              </li>
              <li>
                The Custom Report Input Filters only support Date Ranges and
                Text Ranges. Any other filters added to the Data Set will cause
                an error when trying to run the custom report associated with
                this Data Set.
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col">
      <div class="card">
        <div class="card-body">
          <div class="card-title">
            <h4 class="float-left">
              Filter Input Configuration
            </h4>
            <div class="switch float-right">
              <label
                title="This will allow you to specify any columns from the Data Set for which the executer can apply filters."
              >
                <input
                  id="filterInputAllowFiltering"
                  type="checkbox"
                  [(ngModel)]="allowFiltering"
                  (ngModelChange)="allowFilteringChanged($event)"
                  [disabled]="dataSet.fields.length == 0"
                />
                Allow User filtering
              </label>
            </div>
          </div>

          <table class="table" *ngIf="allowFiltering">
            <thead>
              <tr>
                <th></th>
                <th>Column</th>
                <th>Label</th>
                <th>Required</th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngIf="(ds.filterConfig.fields || []).length > 0">
                <tr *ngFor="let sf of ds.filterConfig.fields">
                  <td>
                    <button
                      class="btn btn-sm"
                      (click)="editFilterInput(sf)"
                      title="Edit Filter Input"
                    >
                      <fa-icon [icon]="faCog" class="pl-2 pr-2"></fa-icon>
                    </button>
                    <wm-modal-confirm
                      (accepted)="removeFilterInput(sf)"
                      [btnIcon]="'delete'"
                      [showButton]="true"
                      [showButtonText]="true"
                      [commandText]="'Yes'"
                      title="Delete Filter Input"
                      tooltip="Delete Filter Input"
                      [buttonClass]="'btn btn-sm'"
                      [iconStyle]="{ fontSize: '18px' }"
                    >
                      Are you sure you want to remove this Filter Input?
                    </wm-modal-confirm>
                  </td>
                  <td>{{ sf.name }}</td>
                  <td>{{ sf.label }}</td>
                  <td>{{ sf.requireInput ? 'Yes' : 'No' }}</td>
                </tr>
              </ng-container>
              <ng-container *ngIf="(ds.filterConfig.fields || []).length == 0">
                <tr>
                  <td colspan="3">
                    You have not configured any columns to allow the user to
                    filter this data set.
                  </td>
                </tr>
              </ng-container>
            </tbody>
            <tfoot>
              <tr>
                <td colspan="3">
                  <a
                    href="javascript:void(0)"
                    (click)="addFilterInput()"
                    title="Add Filter Input"
                    ><fa-icon [icon]="faPlus" class="pl-2 pr-2"></fa-icon
                  ></a>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
    <div class="col">
      <div class="card">
        <div class="card-body">
          <div class="card-title">
            <h4>Sorting Configuration</h4>
          </div>
          <div class="row">
            <table class="table">
              <tr>
                <th></th>
                <th>Order By <span class="text-danger">*</span></th>
                <th>Direction <span class="text-danger">*</span></th>
                <th></th>
              </tr>
              <tbody
                *ngIf="form.controls['fieldOrder']"
                dragula="sortFields"
                [(dragulaModel)]="dataSet.fieldOrder"
                (dragulaModelChange)="orderChange()"
              >
                <tr *ngFor="let fo of dataSet.fieldOrder; index as idx">
                  <td scope="row" style="text-align: center;" class="pt-4">
                    <fa-icon
                      [icon]="faBars"
                      class="pt-1"
                      style="cursor: grab;"
                      title="Order the sorting priority"
                      *ngIf="dataSet.fieldOrder.length > 1"
                    ></fa-icon>
                  </td>
                  <td>
                    <select
                      class="form-control"
                      [(ngModel)]="fo.fieldName"
                      [formControl]="
                        form.controls['fieldOrder'].controls[
                          'field' + idx.toString()
                        ]
                      "
                    >
                      <option></option>
                      <option *ngIf="dataSet.fields.length == 0"
                        >(Add Column to Data Set)</option
                      >
                      <option
                        *ngFor="let f of dataSet.fields"
                        [value]="f.name"
                        >{{ f.name }}</option
                      >
                    </select>
                    <wm-control-message
                      [control]="
                        form.controls['fieldOrder'].controls[
                          'field' + idx.toString()
                        ]
                      "
                    ></wm-control-message>
                  </td>
                  <td>
                    <select
                      class="form-control"
                      [(ngModel)]="fo.direction"
                      [formControl]="
                        form.controls['fieldOrder'].controls[
                          'order' + idx.toString()
                        ]
                      "
                    >
                      <option value="1">Ascending</option>
                      <option value="2">Descending</option>
                    </select>
                    <wm-control-message
                      [control]="
                        form.controls['fieldOrder'].controls[
                          'order' + idx.toString()
                        ]
                      "
                    ></wm-control-message>
                  </td>
                  <td>
                    <wm-modal-confirm
                      (accepted)="removeSortOrder(fo)"
                      [btnIcon]="'delete'"
                      [showButton]="true"
                      [showButtonText]="true"
                      [commandText]="'Yes'"
                      title="Remove Sorting Column"
                      tooltip="Remove Sorting Column"
                      [buttonClass]="'btn btn-sm'"
                      [iconStyle]="{ fontSize: '18px' }"
                      *ngIf="idx != 0"
                    >
                      Are you sure you want to remove this Sorting Column?
                    </wm-modal-confirm>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div>
            <a
              href="javascript:void(0)"
              (click)="addSortOrder()"
              title="Add Sorting Column"
              ><fa-icon [icon]="faPlus" class="pl-2 pr-2"></fa-icon
            ></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="mt-3" style="position: relative; height: 40px">
  <div
    [ngStyle]="{
      visibility: hoverColumn ? 'visible' : 'hidden',
      left: hoverLeft + 'px'
    }"
    style="position: absolute; border: 1px solid black"
    (mouseover)="hoveringOnAction = true"
    (mouseout)="hoveringOnAction = false; hideColumnActions()"
    #columnActions
  >
    <button
      class="btn btn-sm"
      (click)="moveFieldLeft(hoverColumn)"
      [ngStyle]="{ visibility: canMoveLeft() ? 'visible' : 'hidden' }"
      title="Move Column Left"
    >
      <fa-icon [icon]="faArrowLeft" class="pl-2 pr-2"></fa-icon>
    </button>
    <button
      class="btn btn-sm"
      (click)="configureField(hoverColumn)"
      title="Configure Column"
    >
      <fa-icon [icon]="faCog" class="pl-2 pr-2"></fa-icon>
    </button>
    <div style="display: inline-block" (mouseover)="actionColumn = hoverColumn">
      <wm-modal-confirm
        (accepted)="removeColumn(actionColumn)"
        [btnIcon]="'delete'"
        [showButton]="true"
        [showButtonText]="true"
        [commandText]="'Yes'"
        title="Remove Column"
        tooltip="Remove Column"
        [buttonClass]="'btn btn-sm'"
        [iconStyle]="{ fontSize: '18px' }"
        [buttonStyling]="{ paddingTop: '7px', paddingBottom: '5px' }"
      >
        Are you sure you want to remove this Column?
      </wm-modal-confirm>
    </div>
    <button
      class="btn btn-sm"
      (click)="moveFieldRight(hoverColumn)"
      [ngStyle]="{ visibility: canMoveRight() ? 'visible' : 'hidden' }"
      title="Move Column Right"
    >
      <fa-icon [icon]="faArrowRight" class="pl-2 pr-2"></fa-icon>
    </button>

    <wm-modal-confirm
      [title]="fieldSettingsTitle + ' Column Settings'"
      [showButton]="false"
      commandText="Save"
      [form]="fieldSettingsForm"
      (accepted)="updateColumn()"
      (canceled)="cancelColumn()"
      #fieldSettings
    >
      <div *ngIf="actionField" [formGroup]="fieldSettingsForm">
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label
                class="bmd-label-floating"
                style="display: contents"
                for="fieldName"
                >Name <span class="text-danger">*</span></label
              >
              <wm-help-text
                class="ml-1 float-clear"
                placement="right"
                helpText="This is the name of the column. This can be a template code that you use in your workflows or it can be an arbitrary name that is unique in the data set. If you have set the name to an arbitrary value, you will need to map data entities for each workflow to this column."
              ></wm-help-text>
              <input
                type="text"
                class="form-control"
                style="width: 94%;display: inline-block"
                [(ngModel)]="actionField.name"
                name="fieldName"
                formControlName="fieldName"
              />
              <wm-modal-confirm
                title="Global Entities"
                [showButtonText]="true"
                [showButton]="true"
                commandText="Select"
                #globalEntitySearch
                btnIcon="search"
                [buttonClass]="'btn btn-sm'"
              >
                <wm-data-entity-autocomplete
                  *ngIf="actionField"
                  [types]=""
                  [form]="form"
                  [model]="actionField.name"
                  [clearOnSelect]="true"
                  id="globalEntitySearch"
                  [showFormulaEditor]="false"
                  controlName="globalEntitySearch"
                  [onlyGlobalDataEntities]="true"
                  (valueUpdate)="actionField.name = $event"
                  title="Global Entity Search"
                ></wm-data-entity-autocomplete>
              </wm-modal-confirm>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label
                class="bmd-label-floating"
                style="display: contents"
                for="fieldHeader"
                >Header <span class="text-danger">*</span>
              </label>
              <wm-help-text
                class="ml-1"
                placement="right"
                helpText="This is the column header in the export file."
              ></wm-help-text>
              <input
                type="text"
                class="form-control"
                [(ngModel)]="actionField.resultHeader"
                name="fieldHeader"
                formControlName="fieldHeader"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label class="bmd-label-floating" for="fieldDataType"
                >Data Type <span class="text-danger">*</span></label
              >
              <select
                class="form-control"
                [(ngModel)]="actionField.dataType"
                name="fieldDataType"
                formControlName="fieldDataType"
              >
                <option value="1">Text</option>
                <option value="2">Date</option>
                <option value="3">Number with Decimals</option>
                <option value="4">Number without Decimals</option>
              </select>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="ds.hasCustomReports && isDeveloper">
          <div class="col">
            <div class="form-group">
              <div class="switch">
                <label>
                  <input
                    id="fieldIsParcelNumber"
                    type="checkbox"
                    [(ngModel)]="actionField.isParcelNumber"
                    formControlName="fieldIsParcelNumber"
                  />
                  Is Parcel Number?
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="ds.hasCustomReports && isDeveloper">
          <div class="col">
            <div class="form-group">
              <div class="switch">
                <label>
                  <input
                    id="fieldIsPermitNumber"
                    type="checkbox"
                    [(ngModel)]="actionField.isPermitNumber"
                    formControlName="fieldIsPermitNumber"
                  />
                  Is Permit Number?
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="ds.hasCustomReports && isSystemAdmin">
          <div class="col">
            <div class="form-group">
              <div class="switch">
                <label>
                  <input
                    id="fieldConditionalOutput"
                    type="checkbox"
                    [(ngModel)]="actionField.conditionalOutput"
                    formControlName="fieldConditionalOutput"
                    (change)="toggleConditionalOutput(actionField)"
                  />
                  Conditional Output?
                </label>
              </div>
              <label class="bmd-label-floating" for="fieldOutputFormula"
                >Output Formula</label
              >
              <wm-condition-builder
                [conditionSources]="outputFormulaConditionSources"
                [conditions]="actionField.outputFormula"
                [allowEditingElse]="true"
                [thenLabelGetter]="getThenLabel"
                (conditionChanges)="conditionsChanged($event)"
                *ngIf="
                  outputFormulaConditionSources && actionField.outputFormula
                "
              >
                <ng-template let-target="target">
                  <label for="then">Then</label>
                  <input
                    type="text"
                    class="form-control"
                    [(ngModel)]="target.value"
                    formControlName="fieldOutputFormula"
                  />
                </ng-template>
              </wm-condition-builder>
            </div>
          </div>
        </div>
      </div>
    </wm-modal-confirm>
  </div>
</div>
<ngx-datatable
  class="material mt-3"
  [columns]="columns"
  [rows]="rows"
  [columnMode]="ColumnMode.force"
  [headerHeight]="50"
  [footerHeight]="50"
  [messages]="{ emptyMessage: 'All Workflows' }"
  (reorder)="columnsReordered($event)"
  rowHeight="auto"
  [scrollbarH]="true"
  #dsGrid
  *ngIf="columns && rows"
>
  <ngx-datatable-footer *ngIf="true">
    <ng-template
      ngx-datatable-footer-template
      let-rowCount="rowCount"
      let-pageSize="pageSize"
      let-selectedCount="selectedCount"
      let-curPage="curPage"
      let-offset="offset"
    >
      <button
        class="btn btn-sm btn-primary"
        *ngIf="!value || !value.prop"
        (click)="addWorkflow()"
      >
        Add Workflow
      </button>
    </ng-template>
  </ngx-datatable-footer>
</ngx-datatable>

<ng-template #addFieldButtons let-row="row">
  <button
    class="btn btn-raised btn-primary"
    (click)="addAggregateKeyField(row)"
  >
    Add Key Field
  </button>
  <button
    class="btn btn-raised btn-primary ml-3"
    (click)="addAggregateCalculatedField(row)"
  >
    Add Aggregated Field
  </button>
  <button
    class="btn btn-raised btn-primary ml-3"
    (click)="addAggregateStaticField(row)"
  >
    Add Static Field
  </button>
</ng-template>
<div
  class="row mt-3"
  *ngIf="ds && ds.hasCustomReports && dataSet && isDeveloper"
>
  <div class="col-12">
    <div class="card">
      <div class="card-body">
        <div class="card-title">
          <h4>Aggregated Data Sets</h4>
        </div>
        <button
          class="btn btn-primary btn-raised"
          (click)="addAggregatedDataSet()"
        >
          Add Aggregated Data
        </button>

        <ng-template #groupingFields let-row="row">
          Grouping By Fields
          <select class="form-control" [(ngModel)]="newGroupingField">
            <option [ngValue]=""></option>
            <option
              *ngFor="let dsField of dataSet.fields"
              [ngValue]="dsField.name"
              >{{ dsField.resultHeader }}</option
            >
          </select>
          <button
            class="btn btn-raised btn-primary"
            (click)="addGroupingField(row)"
          >
            Add Group By Field
          </button>
          <table class="table">
            <tr>
              <td></td>
              <td>Field</td>
            </tr>
            <tr *ngFor="let field of row.groupByFields">
              <td><a (click)="removeGroupingField(row, field)">X</a></td>
              <td>
                {{ field }}
              </td>
            </tr>
          </table>
        </ng-template>

        <ng-template #generalFields let-field="field">
          <div class="form-group">
            <div class="control-label">
              Header Text
            </div>
            <input
              type="text"
              class="form-control"
              [(ngModel)]="field.headerText"
            />
          </div>
          <div class="form-group">
            <div class="control-label">
              Name
            </div>
            <input type="text" class="form-control" [(ngModel)]="field.name" />
          </div>
          <div class="form-group">
            <div class="control-label">
              Data Type
            </div>
            <select class="form-control" [(ngModel)]="field.dataType">
              <option value="1">Text</option>
              <option value="2">Date</option>
              <option value="3">Number with Decimals</option>
              <option value="4">Number without Decimals</option>
            </select>
          </div>
        </ng-template>

        <ng-template #keyFields let-row="row">
          <div>Fields</div>
          <ng-template
            *ngTemplateOutlet="addFieldButtons;context:{row}"
          ></ng-template>
          <ng-template *ngTemplateOutlet="fields;context:{row}"></ng-template>
          <ng-template
            *ngTemplateOutlet="addFieldButtons;context:{row}"
          ></ng-template>
        </ng-template>

        <ng-template #staticField let-field="field">
          <ng-template
            *ngTemplateOutlet="generalFields;context: {field}"
          ></ng-template>
          <div class="form-group">
            <div class="control-label">Text</div>
            <input
              type="text"
              [(ngModel)]="field.output"
              class="form-control"
            />
          </div>
        </ng-template>

        <ng-template #keyField let-field="field" let-row="row">
          <ng-template
            *ngTemplateOutlet="generalFields;context: {field}"
          ></ng-template>
          <select class="form-control" [(ngModel)]="field.keyField">
            <option [ngValue]=""></option>
            <option
              *ngFor="let dsField of row.groupByFields"
              [ngValue]="dsField"
              >{{ dsField }}</option
            >
          </select>
        </ng-template>

        <ng-template #calculatedField let-field="field" let-row="row">
          <ng-template
            *ngTemplateOutlet="generalFields;context: {field}"
          ></ng-template>
          <div class="form-group">
            <div class="control-label">Function</div>
            <select class="form-control" [(ngModel)]="field.function">
              <option [ngValue]="2">Count</option>
              <option [ngValue]="3">Max</option>
              <option [ngValue]="4">First</option>
            </select>
          </div>
          <div class="form-group">
            <div class="control-label">Field</div>
            <select class="form-control" [(ngModel)]="field.fieldName">
              <option [ngValue]=""></option>
              <option
                *ngFor="let dsField of row.fields"
                [ngValue]="dsField.name"
                >{{ dsField.headerText }}</option
              >
            </select>
          </div>
          <div class="form-group">
            <div class="control-label">Criteria (Optional)</div>
            <wm-condition-group
              [conditionSources]="conditionSources"
              [group]="field.criteriaGroup"
              (conditionChanges)="
                field.criteria = field.criteriaGroup.conditions
              "
              *ngIf="conditionSources"
            >
            </wm-condition-group>
          </div>
        </ng-template>

        <ng-template #fields let-row="row">
          <table class="table">
            <tr>
              <td></td>
              <td>Field</td>
            </tr>
            <tr *ngFor="let field of row.fields">
              <td><a (click)="removeAggregateRowField(row, field)">X</a></td>
              <td>
                <ng-template
                  *ngTemplateOutlet="this[field.type];context:{row,field}"
                ></ng-template>
              </td>
            </tr>
          </table>
        </ng-template>

        <ng-template #aggregateFields let-row="row">
          Agg Fields
          <button
            class="btn btn-raised btn-primary"
            (click)="
              addAggregateRowField(
                row,
                AggregatedDataSetFieldTypes.calculatedField
              )
            "
          >
            Add Field
          </button>
          <table class="table">
            <tr>
              <td></td>
              <td>Field</td>
            </tr>
            <tr
              *ngFor="
                let field of getRowFieldsByType(
                  row,
                  AggregatedDataSetFieldTypes.calculatedField
                )
              "
            >
              <td><a (click)="removeAggregateRowField(row, field)">X</a></td>
              <td>
                <select class="form-control" [(ngModel)]="field.keyField">
                  <option [ngValue]=""></option>
                  <option
                    *ngFor="let dsField of row.groupByFields"
                    [ngValue]="dsField"
                    >{{ dsField }}</option
                  >
                </select>
              </td>
            </tr>
          </table>
        </ng-template>

        <ng-template #groupingRow let-row="row">
          <div class="card">
            <div class="card-body">
              <div class="card-title">
                <h4>Child Sorting Configuration</h4>
              </div>
              <div class="row">
                <table class="table">
                  <tr>
                    <th></th>
                    <th>Order By <span class="text-danger">*</span></th>
                    <th>Direction <span class="text-danger">*</span></th>
                    <th></th>
                  </tr>
                  <tbody
                    dragula="childSortFields"
                    [(dragulaModel)]="row.sortFields"
                    (dragulaModelChange)="childOrderChange()"
                  >
                    <tr *ngFor="let fo of row.sortFields; index as idx">
                      <td scope="row" style="text-align: center;" class="pt-4">
                        <fa-icon
                          [icon]="faBars"
                          class="pt-1"
                          style="cursor: grab;"
                          title="Order the sorting priority"
                          *ngIf="row.sortFields.length > 1"
                        ></fa-icon>
                      </td>
                      <td>
                        <select class="form-control" [(ngModel)]="fo.fieldName">
                          <option></option>
                          <option
                            *ngFor="let f of row.fields"
                            [value]="f.name"
                            >{{ f.name }}</option
                          >
                        </select>
                      </td>
                      <td>
                        <select class="form-control" [(ngModel)]="fo.direction">
                          <option value="1">Ascending</option>
                          <option value="2">Descending</option>
                        </select>
                      </td>
                      <td>
                        <wm-modal-confirm
                          (accepted)="removeChildSortOrder(row, fo)"
                          [btnIcon]="'delete'"
                          [showButton]="true"
                          [showButtonText]="true"
                          [commandText]="'Yes'"
                          title="Remove Child Sorting Column"
                          tooltip="Remove Child Sorting Column"
                          [buttonClass]="'btn btn-sm'"
                          [iconStyle]="{ fontSize: '18px' }"
                          *ngIf="idx != 0"
                        >
                          Are you sure you want to remove this Child Sorting
                          Column?
                        </wm-modal-confirm>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div>
                <a
                  href="javascript:void(0)"
                  (click)="addChildSortOrder(row)"
                  title="Add Sorting Column"
                  ><fa-icon [icon]="faPlus" class="pl-2 pr-2"></fa-icon
                ></a>
              </div>
            </div>
          </div>
          <ng-template
            *ngTemplateOutlet="groupingFields;context:{row}"
          ></ng-template>
          <ng-template
            *ngTemplateOutlet="keyFields;context:{row}"
          ></ng-template>
        </ng-template>

        <ng-template #groupingCriteriaRow let-row="row">
          <ng-template
            *ngTemplateOutlet="groupingFields;context:{row}"
          ></ng-template>
          <div class="form-group">
            <div class="control-label">Criteria</div>
            <wm-condition-group
              [conditionSources]="conditionSources"
              [group]="row.criteriaGroup"
              (conditionChanges)="row.criteria = row.criteriaGroup.conditions"
              *ngIf="conditionSources && row.criteriaGroup"
            >
            </wm-condition-group>
          </div>
          <ng-template
            *ngTemplateOutlet="keyFields;context:{row}"
          ></ng-template>
        </ng-template>

        <ng-template #rowFields let-aggDS="aggDS">
          <div *ngIf="aggDS && aggDS.rows && aggDS.rows.length > 0">
            <div>Output</div>
            <table class="table">
              <tr>
                <td *ngFor="let field of aggDS.rows[0].fields">
                  {{ field.headerText }}
                </td>
              </tr>
              <tr *ngFor="let row of aggDS.rows">
                <td *ngFor="let field of row.fields">{{ field.name }}</td>
              </tr>
            </table>
          </div>
        </ng-template>

        <ul ngbNav #aggSets="ngbNav" class="nav-tabs">
          <li
            *ngFor="let aggDS of dataSet.aggregatedDataSets"
            [ngbNavItem]="aggDS.name ? aggDS.name : 'New'"
          >
            <a ngbNavLink>{{ aggDS.name ? aggDS.name : 'New' }}</a>
            <ng-template ngbNavContent>
              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <div class="control-label">Name</div>
                    <input
                      type="text"
                      class="form-control"
                      [(ngModel)]="aggDS.name"
                    />
                  </div>
                </div>
              </div>
              <div ngbAccordion>
                <div
                  [ngbAccordionItem]="i"
                  *ngFor="let aggRow of aggDS.rows; let i = $index"
                  [collapsed]="true"
                >
                  <div ngbAccordionHeader>
                    <div class="row">
                      <div class="col-11">
                        <button ngbAccordionButton class="btn btn-link">
                          {{ aggRow.type || 'New' }}
                        </button>
                      </div>
                      <div class="col-1">
                        <a (click)="removeAggregateDataSetRow(aggDS, aggRow)"
                          >X</a
                        >
                      </div>
                    </div>
                  </div>
                  <div ngbAccordionCollapse>
                    <div ngbAccordionBody>
                      <ng-template>
                        <select
                          class="form-control"
                          [(ngModel)]="aggRow.type"
                          (ngModelChange)="
                            changeRowType(aggDS, aggRow, aggRow.type)
                          "
                        >
                          <option [ngValue]="null"></option>
                          <option
                            *ngFor="let rowType of aggregateRowTypes"
                            [ngValue]="rowType.key"
                            >{{ rowType.value }}</option
                          >
                        </select>
                        <ng-template
                          *ngTemplateOutlet="this[aggRow.type];context:{row:aggRow,aggDS}"
                        ></ng-template>
                      </ng-template>
                    </div>
                  </div>
                </div>
              </div>

              <!-- <table class="table">
                <tr>
                  <td></td>
                  <td>Type</td>
                </tr>
                <tr *ngFor="let aggRow of aggDS.rows">
                  <td>
                    <a (click)="removeAggregateDataSetRow(aggDS, aggRow)">X</a>
                  </td>
                  <td>
                    <select class="form-control" [(ngModel)]="aggRow.type">
                      <option [ngValue]="null"></option>
                      <option
                        *ngFor="let rowType of aggregateRowTypes"
                        [ngValue]="rowType.key"
                        >{{ rowType.value }}</option
                      >
                    </select>
                    <ng-template
                      *ngTemplateOutlet="this[aggRow.type];context:{row:aggRow,aggDS}"
                    ></ng-template>
                  </td>
                </tr>
              </table> -->

              <button
                class="btn btn-primary btn-raised"
                (click)="addAggregateDataSetRow(aggDS)"
              >
                Add Row
              </button>

              <ng-template
                *ngTemplateOutlet="rowFields;context: {aggDS}"
              ></ng-template>
            </ng-template>
          </li>
        </ul>
        <div [ngbNavOutlet]="aggSets"></div>
      </div>
    </div>
  </div>
</div>

<div class="mt-3">
  <ng-container *ngIf="isDeveloper">
    <wm-modal-confirm
      title="Test Data Set Input"
      [showButtonText]="true"
      buttonText="Test Data Set"
      commandText="Execute"
      cancelText="Cancel"
      (accepted)="testDataSet()"
      [enabled]="canTest()"
      [buttonClass]="'btn btn-primary btn-raised mr-2'"
    >
      <wm-dataset-input
        *ngIf="ds"
        [initialDataSet]="ds"
        (filtersChanged)="filters = $event"
      >
      </wm-dataset-input>
    </wm-modal-confirm>
  </ng-container>
  <button
    class="btn btn-raised btn-primary mr-2"
    (click)="testDataSet()"
    [disabled]="!canTest()"
    *ngIf="!isDeveloper"
  >
    Test Data Set
  </button>

  <button class="btn btn-raised btn-primary mr-2" (click)="saveDataSet()">
    Save Data Set
  </button>

  <wm-modal-confirm
    title="Publish Data Set Confirmation"
    [showButtonText]="true"
    buttonText="Publish Data Set"
    commandText="Yes"
    cancelText="No"
    (accepted)="publishDataSet()"
    [enabled]="canPublish"
  >
    Are you sure you want to publish the changes to this Data Set?
  </wm-modal-confirm>

  <!-- <button class="btn btn-raised btn-primary" (click)="publishDataSet()"
  [disabled]="!canTest()">
    Publish Data Set
  </button> -->
</div>

<app-loader *ngIf="executingTest"></app-loader>

<ngx-datatable
  class="material mt-3"
  [columns]="resultColumns"
  [rows]="resultRows"
  [columnMode]="ColumnMode.force"
  [headerHeight]="50"
  [footerHeight]="50"
  rowHeight="auto"
  *ngIf="resultColumns && resultRows"
  [scrollbarH]="true"
>
</ngx-datatable>

<div
  *ngIf="
    !executingTest && aggResultKeys && aggResultKeys.length > 0 && isDeveloper
  "
>
  <ul ngbNav #aggregateResults="ngbNav" class="nav-tabs">
    <li
      *ngFor="let aggResultKey of aggResultKeys"
      [ngbNavItem]="aggResultKey"
      [id]="aggResultKey"
    >
      <a ngbNavLink>{{ aggResultKey }}</a>
      <ng-template ngbNavContent>
        <ngx-datatable
          class="material mt-3"
          [columns]="aggColumns[aggResultKey]"
          *ngIf="aggColumns[aggResultKey] && aggregatedResultRows[aggResultKey]"
          [rows]="aggregatedResultRows[aggResultKey]"
          [headerHeight]="50"
          [footerHeight]="50"
          [scrollbarH]="true"
          rowHeight="auto"
        >
        </ngx-datatable>
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="aggregateResults"></div>
</div>

<ng-template
  #workflow
  let-row="row"
  let-value="value"
  let-column="column"
  let-rowIndex="rowIndex"
>
  <div *ngIf="row['empty']">All workflows</div>
  <ng-container *ngIf="!row['empty']">
    <select
      class="form-control"
      (change)="updateWorkflow(row)"
      [formControl]="form.controls['workflows'].controls[rowIndex]"
    >
      <option value>(Select Workflow)</option>
      <option
        *ngIf="hasPreviousSystemData"
        [value]="_context.previousSystemDataWorkflowId"
        >Previous System Data</option
      >
      <option *ngFor="let w of availbleWorkflows" [value]="w.id">{{
        w.version.name
      }}</option>
      <span class="text-danger">*</span>
    </select>
    <wm-control-message
      [control]="form.controls['workflows'].controls[rowIndex]"
    ></wm-control-message>
    <wm-modal-confirm
      (accepted)="removeWorkflow(value)"
      [btnIcon]="'delete'"
      [showButton]="true"
      [showButtonText]="true"
      [commandText]="'Yes'"
      title="Remove Workflow"
      tooltip="Remove Workflow"
      [buttonClass]="'btn btn-sm'"
      [iconStyle]="{ fontSize: '18px' }"
    >
      Are you sure you want to remove this Workflow?
    </wm-modal-confirm>
  </ng-container>
  <button
    class="btn btn-sm"
    (click)="filterWorkflow(value || ALL_WORKFLOWS_ID)"
    title="Filter Workflow Applications"
  >
    <!-- *ngIf="row['workflowId']" -->
    <fa-icon [icon]="faFilter" class="pl-2 pr-2"></fa-icon>
  </button>
  <span *ngIf="isFiltered(value)">Filtered</span>
</ng-template>

<ng-template #cell let-row="row" let-value="value" let-column="column">
  <ng-container *ngIf="row['workflowId']">
    <ng-container *ngIf="value && value.length">
      <ng-container *ngFor="let m of value; let idx = index">
        {{ m.templateCode }}
        <wm-modal-confirm
          (accepted)="removeMapping(column, value, idx)"
          [btnIcon]="'delete'"
          [showButton]="true"
          [showButtonText]="true"
          [commandText]="'Yes'"
          title="Remove Data Entity Mapping"
          tooltip="Remove Data Entity"
          [buttonClass]="'btn btn-sm'"
          [iconStyle]="{ fontSize: '18px' }"
        >
          Are you sure you want to remove this mapping?
        </wm-modal-confirm>
        <!-- <button
        class="btn btn-sm"
        (click)="removeMapping(column, value, idx)"
        title="Remove Data Entity"
      >
        <fa-icon [icon]="faTrash" class="pl-2 pr-2"></fa-icon>
      </button> -->
      </ng-container>
    </ng-container>
    <button
      class="btn btn-sm"
      (click)="addMapping(row, column)"
      title="Add Data Entity"
    >
      <fa-icon [icon]="faPlus" class="pl-2 pr-2"></fa-icon>
    </button>
  </ng-container>
</ng-template>

<wm-modal-confirm
  title="Column/Entity Mapping"
  [showButton]="false"
  commandText="Save"
  (accepted)="updateMapping()"
  (canceled)="cancelMapping()"
  (opened)="openMapping()"
  [form]="mappingForm"
  #mappingConfig
>
  <wm-data-entity-autocomplete
    *ngIf="
      activeMapping &&
      activeMapping.workflowId != _context.previousSystemDataWorkflowId
    "
    includeHistoricalEntities="true"
    [types]=""
    [form]="mappingForm"
    [model]="activeMapping.templateCode"
    id="columnEntity"
    [required]="true"
    [showFormulaEditor]="false"
    [clearOnSelect]="true"
    [workflow]="
      workflowIdx &&
      activeMapping.workflowId &&
      workflowIdx[activeMapping.workflowId]
    "
    (valueUpdate)="
      activeMapping.templateCode = $event;
      mappingForm.controls['columnEntity'].setValue($event)
    "
    title="Mapping Entity"
  ></wm-data-entity-autocomplete>
  <div
    *ngIf="
      activeMapping &&
      activeMapping.workflowId == _context.previousSystemDataWorkflowId
    "
  >
    Previous System Data Fields
    <div class="col-12">
      <div *ngIf="!previousSystemFields">Loading Fields...</div>
      <input
        *ngIf="previousSystemFields"
        type="text"
        class="form-control"
        #previousSystemFieldInput
        placeholder="Search fields..."
        [ngbTypeahead]="searchPreviousSystemFields"
        (selectItem)="itemSelected($event)"
        (focus)="focus$.next($event.target.value)"
        #typeahead="ngbTypeahead"
        [focusFirst]="false"
      />
    </div>
    <!-- <select *ngIf="previousSystemFields">
      <option *ngFor="let f of previousSystemFields" [value]="f.name">{{f.name}} ({{f.dataType}})</option>
    </select> -->
  </div>
</wm-modal-confirm>

<wm-modal-confirm
  title="Filter Input Column Settings"
  [showButton]="false"
  commandText="Save"
  (accepted)="updateFilterInput()"
  #filterInputConfig
  [form]="filterInputForm"
>
  <div *ngIf="filterInputField" [formGroup]="filterInputForm">
    <div class="row">
      <div class="col">
        <div class="form-group">
          <label class="bmd-label-floating" for="filterInputField"
            >Column <span class="text-danger">*</span></label
          >
          <select
            class="form-control"
            [(ngModel)]="filterInputField.name"
            formControlName="field"
          >
            <option *ngIf="dataSet.fields.length == 0"
              >(Add Column to Data Set)</option
            >
            <option *ngFor="let f of dataSet.fields" [value]="f.name">{{
              f.name
            }}</option>
          </select>
          <wm-control-message
            [control]="form.controls['field']"
          ></wm-control-message>
        </div>
      </div>
      <div class="col">
        <div class="form-group">
          <label class="bmd-label-floating" for="filterInputLabel"
            >Label <span class="text-danger">*</span></label
          >
          <input
            type="text"
            class="form-control"
            [(ngModel)]="filterInputField.label"
            name="filterInputLabel"
            formControlName="label"
          />
        </div>
      </div>
      <div class="col">
        <div class="form-group">
          <div class="switch">
            <label>
              <input
                id="filterInputRequired"
                type="checkbox"
                (change)="
                  (filterInputField.requireInput !=
                    filterInputField.requireInput)
                "
                formControlName="isRequired"
              />
              Required
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="filterInputField && isDeveloper">
      <div class="col-12">
        <div class="form-group">
          <label class="bmd-label-floating">Additional Search Columns</label>
          <select
            class="form-control"
            [(ngModel)]="filterInputSearchField"
            formControlName="searchField"
          >
            <option *ngIf="dataSet.fields.length == 0"
              >(Add Column to Data Set)</option
            >
            <option *ngFor="let f of dataSet.fields" [value]="f.name">{{
              f.name
            }}</option>
          </select>
          <button
            class="btn btn-primary btn-raised"
            (click)="addFilterSearchField(filterInputField)"
          >
            Add Search Field
          </button>
        </div>
        <table class="table">
          <tr>
            <td>Field</td>
            <td></td>
          </tr>
          <tr *ngFor="let f of filterInputField.searchFields || []">
            <td>{{ f.name }}</td>
            <td>
              <wm-modal-confirm
                (accepted)="removeFilterInputSearchField(filterInputField, f)"
                [btnIcon]="'delete'"
                [showButton]="true"
                [showButtonText]="true"
                [commandText]="'Yes'"
                title="Delete Filter Input Search Field"
                tooltip="Delete Filter Input Search Field"
                [buttonClass]="'btn btn-sm'"
                [iconStyle]="{ fontSize: '18px' }"
              >
                Are you sure you want to remove this Filter Input Search Field?
              </wm-modal-confirm>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</wm-modal-confirm>

<wm-modal-confirm
  title="Workflow Filter Settings"
  [showButton]="false"
  commandText="Save"
  (accepted)="updateWorkflowFilter(workflowFilterConfig)"
  #workflowStaticFilter
>
  <app-loader *ngIf="!workflowFilters && !workflowFilterParams"></app-loader>
  <wm-filter-builder
    #workflowFilterCtrl
    *ngIf="workflowFilterParams"
    [id]="
      ds.id + '|' + workflowFilterConfig.workflowId + '|workflowStaticFilter'
    "
    [params]="workflowFilterParams"
    [defaultFilters]="workflowFilters"
    [showFilterBuilder]="true"
    [showSimpleSearch]="false"
    [waitForParent]="false"
    [exportingEnabled]="false"
    (filtersChange2)="workflowFilterChanges2($event)"
    [shapefileValidationCriteria]="shapefileValidationCriteria"
  ></wm-filter-builder>
</wm-modal-confirm>

<ng-template
  #columnHeader
  let-row="row"
  let-value="value"
  let-column="column"
  ngx-datatable-header-template
>
  <button
    class="btn btn-sm btn-primary"
    (click)="addColumn()"
    *ngIf="
      (!column || !column.prop) && columns.indexOf(column) == columns.length - 1
    "
    title="Add Column"
  >
    Add Column
  </button>
  <div class="datatable-header-cell-wrapper">
    <span
      class="datatable-header-cell-label draggable"
      (mouseover)="columnHover(column, $event)"
      (mouseout)="hideColumnActions()"
    >
      {{ column.name }}
    </span>
  </div>
</ng-template>

<ul ngbNav
  #tabs="ngbNav"
  [(activeId)]="activeTabId"
  (navChange)="onSearchMapTabChange($event)"
  [destroyOnHide]="true"
  class="nav-tabs"
>
  <li [ngbNavItem]="'searchTab'" id="searchTab">
    <a ngbNavLink>Search</a>
    <ng-template ngbNavContent>
      <form
        [formGroup]="form"
        (submit)="submitSearchParcelDataCriteria($event)"
      >
        <div class="form-group">
          <label for="parcelIdSearch" class="bmd-label-static">
            Parcel ID:
          </label>
          <input
            id="parcelIdSearch"
            type="text"
            class="form-control"
            [formControl]="searchParcelIdCtl"
            [(ngModel)]="searchParcelDataCriteria.searchParcelId"
          />
        </div>
        <div class="form-group">
          <label for="parcelOwnerSearch" class="bmd-label-static">
            Property Owner:
          </label>
          <input
            id="parcelOwnerSearch"
            type="text"
            class="form-control"
            [formControl]="searchParcelOwnerNameCtl"
            [(ngModel)]="searchParcelDataCriteria.searchParcelOwnerName"
          /><br />
        </div>
        <div class="form-group">
          <label for="propertyAddressSearch" class="bmd-label-static">
            Property Address:
          </label>
          <input
            id="propertyAddressSearch"
            type="text"
            class="form-control"
            [formControl]="searchParcelAddressCtl"
            [(ngModel)]="searchParcelDataCriteria.searchParcelAddress"
          />
        </div>

        <button
          type="submit"
          class="btn btn-primary btn-raised"
          [disabled]="
            !(
              searchParcelDataCriteria.searchParcelAddress ||
              searchParcelDataCriteria.searchParcelOwnerName ||
              searchParcelDataCriteria.searchParcelId
            )
          "
          (click)="submitSearchParcelDataCriteria($event)"
        >
          Search
        </button>
      </form>
    </ng-template>
  </li>
  <li ngbNavItem="mapTab" id="mapTab" [disabled]="!activity.model.hasMap">
    <a ngbNavLink>Map</a>
    <ng-template ngbNavContent>
      <div class="tab-pane fade active show" id="mapTab">
        <app-esri-map
          #esriMap
          [mapServiceId]="activity.model.mapConfig.mapServiceId"
          [basemapName]="activity.model.mapConfig.basemapName"
          [initialExtent]="activity.model.mapConfig.initialExtent"
          [mapLayerOptions]="activity.model.mapConfig.layers"
          [showToc]="activity.model.mapConfig.showToc"
          (featuresIdentified)="onParcelsIdentified($event)"
          (featuresBeforeIdentifying)="onParcelsBeforeIdentifying()"
          (mapLoaded)="onMapLoaded()"
          useSketchLayer="false"
        ></app-esri-map>
      </div>
    </ng-template>
  </li>
  <li ngbNavItem="mayParcels" id="mayParcels" [disabled]="!userId">
    <a ngbNavLink>My Parcels</a>
    <ng-template ngbNavContent>
      <div style="border-bottom: 1px solid gray">
        <wm-my-application-parcels
          [userId]="userId"
          (parcelSelected)="myParcelSelected($event)"
        ></wm-my-application-parcels>
      </div>
    </ng-template>
  </li>
</ul>
<div [ngbNavOutlet]="tabs"></div>

<app-loader *ngIf="loading"></app-loader>

<table class="table" *ngIf="results && results.length > 0">
  <thead>
    <tr>
      <th scope="col"></th>
      <th scope="col">Parcel ID</th>
      <th scope="col">Address</th>
      <th scope="col">City</th>
      <th scope="col">Owner Name</th>
      <th scope="col" class="align-right">Acres</th>
      <th scope="col"></th>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngFor="let r of results">
      <tr
        *ngIf="showParcel(r)"
        [ngClass]="{ 'table-active': isParcelSelected(r) }"
      >
        <td>
          <div class="custom-control custom-radio" *ngIf="!hideResultsOnSelect">
            <input
              type="radio"
              class="custom-control-input"
              [(ngModel)]="activity.model.selectedParcel"
              [value]="r"
              name="search"
              [id]="r.parcelID"
              (change)="selectOneResult(r, true)"
            />
            <label class="custom-control-label" [for]="r.parcelID"></label>
          </div>

          <a
            *ngIf="hideResultsOnSelect"
            href="javascript:void(0)"
            (click)="selectOneResult(r, true, true)"
          >
            <span *ngIf="isParcelSelected(r)">Selected</span>
            <span *ngIf="!isParcelSelected(r)">Select</span>
          </a>
        </td>
        <td>{{ r.parcelID }}</td>
        <td>{{ r.address }}</td>
        <td>{{ r.city }}</td>
        <td>{{ r.ownerName }}</td>
        <td class="align-right">
          {{ r.acres * 1 | number: '1.3-3' }}
        </td>

        <td class="align-right">
          <button
            type="button"
            class="btn btn-raised btn-primary float-right notes-button"
            (click)="parcelNotesModal.open()"
            *ngIf="
              !(
                (r.internalNote == '0' &&
                  r.publicNote == '0' &&
                  !canManageParcelNotes == true) ||
                (r.publicNote == '0' && !canViewInternalParcelNotes == true)
              )
            "
          >
            <!--
           *ngIf ="!(((r.internalNote=='0' && r.publicNote=='0') && !canManageParcelNotes==true)||((r.publicNote=='0') && !canViewInternalParcelNotes==true))"
        Currently No Notes on the parcel, but I can add some.
        Currently Either internal or public Notes on the parcel that can be edited
        Currently Either internal or public Notes on the parcel that can't be edited
        Currently There are either No Notes on the parcel OR no public notes and you don't have permission to view the internal notes.  --there is no visible button in this case because it won't pass the inIf for the button element.
      -->
            <span
              *ngIf="
                r.internalNote == '0' &&
                r.publicNote == '0' &&
                canManageParcelNotes == true
              "
              >Add Note</span
            >
            <span
              *ngIf="
                !(r.internalNote == '0' && r.publicNote == '0') &&
                canManageParcelNotes == true
              "
              >Modify Note</span
            >
            <span
              *ngIf="
                !(r.internalNote == '0' && r.publicNote == '0') &&
                !canManageParcelNotes == true
              "
              >View Note</span
            >
            <!-- this should stay hidden -->
            <span
              *ngIf="
                (r.internalNote == '0' &&
                  r.publicNote == '0' &&
                  !canManageParcelNotes == true) ||
                (r.publicNote == '0' && !canViewInternalParcelNotes == true)
              "
              >No Note</span
            >
          </button>
        </td>

        <wm-modal-confirm
          title="Parcel Notes"
          [small]="false"
          [showButtonText]="false"
          [showButton]="false"
          commandText="Save"
          (accepted)="notesModal(publicNote, internalNote, r.parcelID)"
          (opened)="getParcelNote(r.parcelID)"
          [showCommandButton]="canManageParcelNotes"
          #parcelNotesModal
        >
          <label id="lblParcelID"
            ><strong>{{ r.parcelID }}</strong></label
          ><br />
          <label id="lblPublicNote">Public Note</label>
          <textarea
            class="form-control"
            name="publicNote"
            id="publicNote"
            rows="20"
            aria-describedby="lblPublicNote"
            [(ngModel)]="publicNote"
            [readonly]="!canManageParcelNotes"
          >
          </textarea>
          <!-- TODO: Make sure I check permissions server side, so I don't even return value if I don't have permissions.  -->
          <br />
          <label
            id="lblInternalNote"
            *ngIf="canViewInternalParcelNotes == true"
          >
            Internal Note
          </label>
          <textarea
            class="form-control"
            name="InternalNote"
            id="InternalNote"
            rows="20"
            aria-describedby="lblInternalNote"
            [(ngModel)]="internalNote"
            *ngIf="canViewInternalParcelNotes == true"
            [readonly]="!canManageParcelNotes"
          >
          </textarea>
        </wm-modal-confirm>
      </tr>
    </ng-container>
  </tbody>
</table>

<p *ngIf="!loading && results && results.length < 1">No results</p>

import { FormActivity, FormActivityModel } from './form-activity';
import { InspectionActivityModel } from './inspection-request-activity';

export class CompleteInspectionActivity extends FormActivity {
  inspectionId: string;
  completedOn: any;
  model: InspectionActivityModel;
  constructor(options?: Partial<CompleteInspectionActivity>) {
    super(options);

    if (options) {
      Object.assign(this, options);
    }

    this.model = new InspectionActivityModel(options ? options.model : null);
  }
}

import { Client } from './client';

// This is the object that defines the definition for an available contractor requirement type
export class ContractorRequirementType {
  id: string;
  name: string;
  description: string;
  type: string;
  client: Client;

  constructor(options?: Partial<ContractorRequirementType>) {
    if (options) {
      Object.assign(this, options);
    }
  }
}

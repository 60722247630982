import { WorkflowApplication } from './workflow-application';
import { ContactAddress, InspectionAddress } from './address';
import { Time } from '@angular/common';
import { InspectionDocument } from './inspection-document';

export class InspectionStatusDict {
  PendingSchedule = <any>'Pending Schedule';
  PendingInspection = <any>'Pending Inspection';
  Approved = <any>'Approved';
  Denied = <any>'Denied';
  Deleted = <any>'Deleted';
  Voided = <any>'Voided';
  Requested = <any>'Requested';
  Scheduled = <any>'Scheduled';
  constructor(options?: Partial<PagedInspectionsVM>) {
    if (options) {
      Object.assign(this, options);
    }
  }
}
export enum InspectionStatus {
  PendingSchedule,
  PendingInspection,
  Approved,
  Denied,
  Deleted,
  Voided,
  Requested,
  Scheduled
}
export enum InspectionRequestTod {
  AM,
  PM,
  Unset
}
export class PagedInspectionsVM {
  recordCount: number;
  pageCount: number;
  unpermissibleCount: number;
  inspections: InspectionVM[];

  constructor(options?: Partial<PagedInspectionsVM>) {
    this.inspections = [new InspectionVM()];

    if (options) {
      Object.assign(this, options);
    }
  }
}
export class InspectionVM {
  permitNumber: string;
  id: string;
  inspectorId?: string;
  status: InspectionStatus;
  reqTOD: InspectionRequestTod;
  workflowApplicationId: string;
  createdOn: Date;
  createdBy: string;
  modifiedOn: Date;
  inspectionType: string;
  documents?: InspectionDocument[];
  workflowApplicationActivityDataId: string;
  activityName?: string;
  requestorName: string;
  requestorPhoneNumber: string;
  requestorAddress: InspectionAddress;
  requestorAddress_Id: string;
  requestedOn: string;
  notes: any[];
  customFields: any[];

  scheduledOn?: Date;
  scheduledOnIncludesTime?: boolean;
  completedOn?: Date;
  modifiedBy: string;
  requestorEmail?: string;

  inspectorName?: string;
  canCompleteInspection: boolean;
  canScheduleInspection: boolean;
  canViewDetailsActivity: boolean;
  geometryWkts: string[];
  clientId: string;
  parcelId: string;
  applicationName: string;
  workflowApplication: WorkflowApplication;

  constructor(options?: Partial<InspectionVM>) {
    //    let z:InspectionVM = new InspectionVM();

    if (options) {
      Object.assign(this, options);
    }
  }
}

import { Component, OnInit, EventEmitter } from '@angular/core';
import { ActivityView } from 'src/app/views/master-views/app.view/app.view.component';
import { FormGroup } from '@angular/forms';
import { WorkflowContextService } from 'src/app/services';
import { ReviewActivity } from 'src/app/models/activities/review-activity';

@Component({
  selector: 'wm-review-activity-input',
  templateUrl: './review-activity-input.component.html',
  styleUrls: ['./review-activity-input.component.css']
})
export class ReviewActivityInputComponent
  implements OnInit, ActivityView {
  activity: ReviewActivity;
  isPreview: boolean;
  isTestApplication: boolean;
  showSave?: EventEmitter<boolean>;
  showCancel?: EventEmitter<boolean>;
  showNext?: EventEmitter<boolean>;
  showPrevious?: EventEmitter<boolean>;
  goNext?: EventEmitter<boolean>;
  value$: EventEmitter<any>;
  initActivity: any;
  nextOnSelect = false;
  form: FormGroup;
  context: WorkflowContextService;
  applicationId?: string;

  viewTitle(): string {
    return this.activity.model.screenName;
  }

  constructor() {}

  ngOnInit() {}
}

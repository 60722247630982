import { ApplicationStatus } from 'src/app/models';
import {
  ActivityModel,
  Activity,
  OnStartedActivity,
  OnCompletedActivity
} from './activities';
import { Role } from './role';
import { Milestone } from './milestone';

export class WorkflowGraph {
  onStarted?: OnStartedActivity;
  onCompleted?: OnCompletedActivity;
  activities?: Activity<ActivityModel>[];
  name: string;
  description: string;
  visibleToPublic: boolean;
  adminRole: Role;
  milestones?: Milestone[];
  startingNumber: number;

  public constructor(options?: Partial<WorkflowGraph>) {
    this.onStarted = new OnStartedActivity();
    this.onCompleted = new OnCompletedActivity();

    if (options) {
      Object.assign(this, options);
    }
  }

  public getActivities(status?: ApplicationStatus): Activity<ActivityModel>[] {
    let activities: Activity<ActivityModel>[] = [];
    switch (status) {
      case ApplicationStatus.NotStarted:
      case ApplicationStatus.InProgress:
        this.onStarted = this.onStarted || new OnStartedActivity();

        activities = this.onStarted.activities;
        break;
      case ApplicationStatus.Completed:
        this.onCompleted = this.onCompleted || new OnCompletedActivity();

        activities = this.onCompleted.activities;
        break;
      default:
        activities = [
          ...this.onStarted.activities,
          ...(this.onCompleted ? this.onCompleted.activities : null)
        ];
        break;
    }

    return activities;
  }

  public addActivity(
    activity: Activity<ActivityModel>,
    status: ApplicationStatus
  ) {
    const activities = this.getActivities(status);
    if (activity) {
      const exActivity = activities.find(a => a.id === activity.id);

      if (!exActivity) {
        activities.push(activity);
      }
    }
  }

  public removeActivity(
    activity: Activity<ActivityModel>,
    status: ApplicationStatus
  ) {
    const activities = this.getActivities(status);

    if (activity) {
      const index = activities.findIndex(value => value.id === activity.id);

      if (index > -1) {
        activities.splice(index, 1);
      }

      // remove all references
      for (const act of activities) {
        act.previousActivityIds = act.previousActivityIds.filter(
          id => activity.id !== id
        );
      }
    }
  }
}

import { PaymentAccountActivityConfigurationBase } from './payment-account-activity-configuration-base';

export class PaymentProcessorAccountConfigurationBase {
  userName: string;
  password: string;
  isConfigured: boolean;
  activityConfiguration: PaymentAccountActivityConfigurationBase;

  constructor(options?: Partial<PaymentProcessorAccountConfigurationBase>) {
    if (options) {
      Object.assign(this, options);
    }
  }
}

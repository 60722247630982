<ul
  ngbNav
  #tabs="ngbNav"
  (navChange)="onSearchMapTabChange($event)"
  [destroyOnHide]="true"
>
  <li ngbNavItem="searchTab" title="Search" id="searchTab">
    <a ngbNavLink>Search</a>
    <ng-template ngbNavContent>
      <form [formGroup]="searchForm">
        <div class="form-group" *ngFor="let f of searchFields">
          <label [for]="f.fieldName" class="bmd-label-static">
            {{ f.searchLabel }}:
          </label>
          <input
            [id]="f.fieldName"
            type="text"
            class="form-control"
            [formControlName]="f.fieldName"
            [(ngModel)]="f.value"
          />
        </div>

        <button
          type="submit"
          class="btn btn-primary btn-raised"
          (click)="submitSearchLayerCriteria($event)"
        >
          Search
        </button>
      </form>
    </ng-template>
  </li>
  <li ngbNavItem="mapTab" title="Map" id="mapTab">
    <a ngbNavLink>Map</a>
    <ng-template ngbNavContent>
      <div
        class="tab-pane fade active show"
        id="mapTab"
        *ngIf="activity.model.mapService"
      >
        <app-esri-map
          #esriMap
          [mapServiceId]="activity.model.mapService.id"
          [basemapName]="activity.model.mapConfig.basemapName"
          [initialExtent]="activity.model.mapConfig.initialExtent"
          [mapLayerOptions]="activity.model.mapConfig.layers"
          [showToc]="activity.model.mapConfig.showToc"
          (featuresIdentified)="onFeaturesIdentified($event)"
          (featuresBeforeIdentifying)="onFeaturesBeforeIdentifying()"
          (mapLoaded)="onMapLoaded()"
          [featureKeyAsString]="featureIdAsString"
          [useSketchLayer]="false"
        ></app-esri-map>
      </div>
    </ng-template>
  </li>
</ul>
<div [ngbNavOutlet]="tabs" class="mt-2"></div>

<ng-template #keyTmpl let-row="row" let-value="value">
  <a href="javascript:void(0)" (click)="selectOneResult(row, true, true)">
    Select
  </a>
</ng-template>

<app-loader *ngIf="loading && !results"></app-loader>

<wm-datatable
  [id]="this.activity.id + '-results'"
  [useMap]="false"
  [loading]="loading"
  [rows]="results"
  [count]="page.totalElements"
  [offset]="page.pageNumber"
  [pageSize]="page.size"
  [limit]="page.size"
  [params]="[]"
  [columns]="columns"
  [showSimpleSearch]="false"
  [showFilterBuilder]="true"
  [defaultHiddenColumns]="defaultHiddenColumns"
  [availableColumns]="availableColumns"
  [exportingEnabled]="false"
  (settingsChanged)="onPage($event)"
  *ngIf="columns && !this.activity.model.selectedFeature && results"
>
</wm-datatable>

<table
  class="table"
  *ngIf="results && results.length > 0 && this.activity.model.selectedFeature"
>
  <thead>
    <tr>
      <th scope="col" *ngFor="let rf of resultFields">{{ rf.resultHeader }}</th>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngFor="let r of results">
      <tr
        *ngIf="showFeature(r)"
        [ngClass]="{ 'table-active': isFeatureSelected(r) }"
      >
        <td *ngFor="let rf of resultFields">
          {{ r[rf.fieldName.toUpperCase()] }}
        </td>
      </tr>
    </ng-container>
  </tbody>
</table>

import { PaymentAccountConfigBaseComponent } from './../payment-account-config-base/payment-account-config-base.component';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, Input } from '@angular/core';
import { PSNPaymentProcessorAccountConfiguration } from 'src/app/models/payment-providers/psn/psn-payment-processor-account-configuration';

@Component({
  selector: 'wm-payment-account-config-psn',
  templateUrl: './payment-account-config-psn.component.html',
  styleUrls: ['./payment-account-config-psn.component.css']
})
export class PaymentAccountConfigPsnComponent
  extends PaymentAccountConfigBaseComponent
  implements OnInit {
  @Input() config: PSNPaymentProcessorAccountConfiguration;

  constructor(_fb: UntypedFormBuilder) {
    super(_fb);
    this.showUsername = false;
    this.showPassword = false;
  }

  ngOnInit() {
    this.addValidation('accountId', Validators.required, this.config.accountId);
  }
}

import {
  Component,
  OnInit,
  Input,
  Type,
  ViewContainerRef,
  ViewChild,
  ComponentFactoryResolver,
  ComponentRef,
  Output,
  EventEmitter
} from '@angular/core';
import { DataEntity } from '../../../../models/data-entities';
import { WorkflowApplication } from '../../../../models';
import { DataEntityFactory } from '../../../../services';
import { Activity, ActivityModel } from '../../../../models/activities';

@Component({
  selector: 'wm-data-entity-view',
  templateUrl: './data-entity-view.component.html',
  styleUrls: ['./data-entity-view.component.css']
})
export class DataEntityViewComponent implements OnInit {
  @Input() entity: DataEntity;
  @Input() application: WorkflowApplication;
  @Input() activity: Activity<ActivityModel>;
  @Output() calculateActivity: EventEmitter<string> = new EventEmitter();

  @ViewChild('entityContainer', { read: ViewContainerRef, static: true })
  entityContainer: ViewContainerRef;
  componentRef: ComponentRef<DataEntityViewComponent>;

  constructor(private componentFactoryResolver: ComponentFactoryResolver) {}
  async persistChildComponent?() {
    return new Promise(resolve => {
      resolve(null);
    });
  }
  ngOnInit() {
    this.loadEntity();
  }

  loadEntity() {
    const code = this.entity.dataEntityTypeCode;
    if (!(this.entity instanceof DataEntity)) {
      this.entity = DataEntityFactory.createDataEntity(code, this.entity);
    }
    let compType: Type<DataEntityViewComponent>;

    if (this.entityContainer) {
      this.entityContainer.clear();

      compType = DataEntityFactory.createViewComponent(
        this.entity,
        this.entity
      );
      if (compType && this.entityContainer) {

        this.componentRef = this.entityContainer.createComponent(
          compType
        );
        this.componentRef.instance.entity = this.entity;
        this.componentRef.instance.activity = this.activity;
        this.componentRef.instance.application = this.application;
        this.componentRef.instance.calculateActivity = this.calculateActivity;

        this.componentRef.instance.persistChildComponent = this.componentRef
          .instance.persistChildComponent
          ? this.componentRef.instance.persistChildComponent
          : async function() {
              return new Promise(resolve => {
                resolve(null);
              });
            };
      }
    }
  }
}

import { WaitActivity } from './../../../../../models/activities/wait-activity';
import {
  Component,
  OnInit,
  OnChanges,
  Input,
  SimpleChange,
  ViewContainerRef,
  ComponentFactoryResolver,
  ComponentRef,
  Output,
  EventEmitter,
  ViewChild,
  Inject,
  forwardRef
} from '@angular/core';
import { Activity, ActivityModel } from '../../../../../models/activities';
import {
  ActivityFactory,
  WorkflowContextService,
  SecurityService,
  WorkflowService
} from '../../../../../services';
import { UntypedFormGroup } from '@angular/forms';
import { ActivityView } from '../../../../../views/master-views/app.view/app.view.component';

@Component({
  selector: 'wm-activity-view',
  templateUrl: './activity-view.component.html',
  styleUrls: ['./activity-view.component.css']
}) //extends ActivityView
export class ActivityViewComponent implements OnInit, OnChanges {
  @Output() showSave: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() showNext: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() showPrevious: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() goNext: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() switchApplication: EventEmitter<string> = new EventEmitter<
    string
  >();

  initActivity: any;
  @Input() activity: any;
  @Input() isPreview: boolean;
  @Input() isTestApplication: boolean;
  activityView: ActivityView;
  view: ComponentRef<ActivityView>;
  @Output() value$: EventEmitter<any> = new EventEmitter<any>();
  @Output() viewTitle$: EventEmitter<string> = new EventEmitter<string>();
  @Input() form: UntypedFormGroup;
  @Input() isReadOnly = false;
  @Input() applicationId: string;
  @Input() nextOnSelect = false;
  showViewComponent = false;

  constructor(
    @Inject(forwardRef(() => WorkflowContextService))
    public context: WorkflowContextService,
    @Inject(forwardRef(() => ComponentFactoryResolver))
    private componentFactoryResolver: ComponentFactoryResolver,
    @Inject(forwardRef(() => ViewContainerRef))
    private _viewRef: ViewContainerRef,
    @Inject(forwardRef(() => SecurityService))
    private _securitySvc: SecurityService,
    @Inject(forwardRef(() => WorkflowService))
    private _workflowSvc: WorkflowService
  ) {
    //super();
  }

  get instanceLoaded(): boolean {
    return this.view.instance != null;
  }

  async persistChildComponent() {
    return this.view.instance.persistChildComponent();

    // if (this.view.instance.persistChildComponent) {
    //      this.view.instance.persistChildComponent();
    // }
  }
  ngOnChanges(changes: { [propertyName: string]: SimpleChange }) {
    for (const propName in changes) {
      if (propName === 'activity') {
        this.loadView(this.activity);
        break;
      }
    }
  }

  ngOnInit() {
    if (this.isReadOnly) {
      this.showViewComponent = true;
    }
    if (this.activity) {
      this.context.previewActivity$.next(this.activity);
      this.loadView(this.activity);
    }
  }

  viewTitle() {
    if (this.activityView) {
      return (
        (this.activityView.viewTitle ? this.activityView.viewTitle() : null) ||
        (this.activity ? this.activity.name : 'Unknown')
      );
    }
  }

  childValueChanged(value: any) {
    this.value$.next(value);
  }

  async loadView(activity: Activity<ActivityModel>) {
    let a = activity;

    if (activity) {
      const canEdit = activity.canEdit || activity instanceof WaitActivity;

      this.showViewComponent =
        activity.isReadOnly || this.isReadOnly || !canEdit;

      let editorComp = null;

      if (this.showViewComponent) {
        editorComp = ActivityFactory.getViewComponent(activity.type);
      } else {
        // use the input component if it exists, otherwise, use the view component
        editorComp = ActivityFactory.getInputComponent(activity.type)
          ? ActivityFactory.getInputComponent(activity.type)
          : ActivityFactory.getViewComponent(activity.type);
      }

      if (editorComp) {
        const ref = this._viewRef.createComponent<ActivityView>(editorComp);
        if (ref) {
          if (this.view) {
            this.view.destroy();
          }

          this.view = ref;
          this.activityView = ref.instance as ActivityView;

          if (ref.instance.initActivity) {
            a = ref.instance.initActivity(a);
          }

          if (!this.isReadOnly) {
            ref.instance.form = this.form;
            ref.instance.showNext = this.showNext;
            ref.instance.showSave = this.showSave;
            ref.instance.showPrevious = this.showPrevious;
            ref.instance.nextOnSelect = this.nextOnSelect;
          }
          ref.instance.activity = a;
          ref.instance.isPreview = this.isPreview;
          ref.instance.isTestApplication = this.isTestApplication;
          ref.instance.context = this.context;
          ref.instance.goNext = this.goNext;
          ref.instance.applicationId = this.applicationId;
          ref.instance.persistChildComponent = ref.instance
            .persistChildComponent
            ? ref.instance.persistChildComponent
            : async function() {
                return new Promise(resolve => {
                  resolve(null);
                });
              };

          if (ref.instance.switchApplication) {
            ref.instance.switchApplication.subscribe(
              (applicationId: string) => {
                this.switchApplication.emit(applicationId);
              }
            );
          }

          if (ref.instance.refreshView) {
            ref.instance.refreshView.subscribe(() => {
              this._workflowSvc
                .navigateToActivity(
                  this.context.applicationId,
                  this.activity.activityDataId
                )
                .subscribe(resp => {
                  const activity = resp.activity;

                  this.loadView(activity);
                });
            });
          }

          this.viewTitle$.next(
            (this.activityView.viewTitle
              ? this.activityView.viewTitle()
              : null) || a.name
          );
        }
      }
    }
  }
}

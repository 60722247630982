import { ModalConfirmComponent } from './../../../../components/system/modal-confirm/modal-confirm.component';
import { Utilities } from './../../../../services/utilities/index';
import { WorkflowService } from './../../../../services/workflow.service';
import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { ActivatedRoute, UrlSegment, Router } from '@angular/router';
import { Workflow, Actions, WorkflowType } from 'src/app/models';
import { WorkflowContextService, SecurityService } from 'src/app/services';
import { ItemSearchOptionField } from 'src/app/components/filter-list/models/filterClasses';
import { ComponentIds } from 'src/app/models/component-ids';

@Component({
  selector: 'wm-workflow-details-view',
  templateUrl: './workflow-details-view.component.html',
  styleUrls: ['./workflow-details-view.component.css']
})
export class WorkflowDetailsViewComponent implements OnInit, AfterViewInit {
  public workflowId: string;
  public workflow: Workflow;
  renewalsId = ComponentIds.WORKFLOW_RENEWALS_SEARCH;
  workflowDetailsViewApplicationsNeedingMyAttentionId =
    ComponentIds.APPLICATIONS_NEEDING_MY_ATTENTION;
  workflowDetailsViewRecentApplicationsId = ComponentIds.RECENT_APPLICATIONS;

  @ViewChild('copyLink', { static: false })
  copyLinkModal: ModalConfirmComponent;
  canEditWorkflow: boolean;
  workflowRegistrationFilter: ItemSearchOptionField[];
  isRenewable = false;
  startAppLink: string;
  startAppMsg: string;
  clientId: string;

  myWorkflowsFilter = [
    {
      title: 'relevantToUser',
      options: [
        {
          title: 'relevantToUser',
          selected: true,
          filterText: 'true',
          searchText: null,
          strOperator: null
        }
      ]
    }
  ];

  topTenRecentFilter = [];

  constructor(
    public context: WorkflowContextService,
    private route: ActivatedRoute,
    private router: Router,
    private _workflowSvc: WorkflowService,
    private _securitySvc: SecurityService
  ) {}

  getPrefixNumber() {
    if (this.workflow.numberPrefixCriteria) {
      if (this.workflow.numberPrefixCriteria.length > 1) {
        return 'Conditional';
      } else {
        return this.workflow.numberPrefixCriteria[0].value;
      }
    }
  }

  getWorkflowDetails() {
    let workflow: Workflow;

    this._workflowSvc.getWorkflow(this.workflowId, false).subscribe(r => {
      if (r) {
        workflow = r;
        this._securitySvc
          .isLoginEntitled(Actions.WORKFLOW_EDIT, workflow)
          .subscribe(isEntitledResult => {
            this.canEditWorkflow = isEntitledResult;
            this.workflow = workflow;
            this.context.workflow$.emit(workflow);
            this.clientId = this.workflow.clientID;
            this.context.client$.emit(this.workflow.client);
  
            if (
              this.workflow &&
              this.workflow.workflowType &&
              this.workflow.workflowType === WorkflowType.Renewable
            ) {
              this.isRenewable = true;
            }
          });
      }
    });
  }

  buildWorkflows() {
    this.route.params.subscribe(params => {
      this.workflowId = params['workflowId'];
      this.getWorkflowDetails();
    });
  }

  ngOnInit() {
    this.buildWorkflows();

    this.workflowRegistrationFilter = [
      {
        title: 'workflowId',
        options: [
          {
            title: '',
            selected: true,
            filterText: '',
            searchText: this.workflowId,
            strOperator: 'is'
          }
        ]
      }
    ];
  }

  ngAfterViewInit(): void {}

  buildBaseStartAppURL(): string {
    const workflowId = this.route.snapshot.paramMap.get('workflowId');
    return (
      Utilities.getRootURL() +
      `/home/StartWorkflowApplication?WorkflowId=${workflowId}`
    );
  }
  copyStartAppWithPIDURL() {
    const url = this.buildBaseStartAppURL() + '&ParcelID={ParcelId}';

    this.startAppMsg =
      'Replace the {ParcelId} text with the actual Parcel Number in the URL.';

    this.startAppLink = `<a href="${url}">Start ${this.workflow.version.name} Application</a>`;
    this.copyLinkModal.open();
  }

  copyStartAppURL(e) {
    const url = this.buildBaseStartAppURL();

    this.startAppMsg = null;
    this.startAppLink = `<a href="${url}">Start ${this.workflow.version.name} Application</a>`;
    this.copyLinkModal.open();
  }

  restoreWorkflow(workflowId: string) {
    this.workflow.isArchived = false;
  }

  enableWorkflow(workflowId: string) {
    this.workflow.isEnabled = true;
  }

  disableWorkflow(workflowId: string) {
    this.workflow.isEnabled = false;
  }

  publishWorkflow(workflowId: string) {
    this.getWorkflowDetails();
  }

  archiveWorkflow(workflowId: string) {
    this.workflow.isArchived = true;
  }

  copyWorkflow(newWorkflow: Workflow) {
    if (newWorkflow) {
      this.workflow = null;
      this.workflowId = null;
      // load the new workflow in the screen
      this.router.navigate([
        '/admin/jurisdiction',
        newWorkflow.clientID,
        'workflows',
        'details',
        newWorkflow.id
      ]);
    }
  }

  toggleVisible(workflowId: string) {
    this.workflow.visibleToPublic = !this.workflow.visibleToPublic;
  }
}

<div class="container">
  <div class="row">
    <div class="col-sm-6 offset-sm-3">
      <div class="card text-white bg-danger">
        <div class="card-body">
          <h5 class="card-title">Unauthorized</h5>
          <p class="card-text" *ngIf="!message">
            You are unauthorized to take the action you attempted!
          </p>
          <p *ngIf="message">
            {{ message }}
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

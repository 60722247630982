import {
  Component,
  ViewChild,
  forwardRef,
  Output,
  OnInit,
  EventEmitter
} from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import SignaturePad from 'signature_pad';

import {
  NgSignaturePadOptions,
  SignaturePadComponent
} from '@almothafar/angular-signature-pad';

/*
  Generated class for the SignatureField component.
  See https://angular.io/docs/ts/latest/api/core/ComponentMetadata-class.html
  for more info on Angular 2 Components.
*/
@Component({
  selector: 'wm-signature-field',
  templateUrl: 'signature-field.component.html',
  styleUrls: ['signature-field.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SignatureFieldComponent),
      multi: true
    }
  ]
})
export class SignatureFieldComponent implements ControlValueAccessor, OnInit {
  @ViewChild('signaturePad', { static: false })
  public signaturePad: SignaturePadComponent;
  @Output() EndEvent = new EventEmitter();

  public options: Object = {};
  public signaturePadOptions: NgSignaturePadOptions = {
    // passed through to szimek/signature_pad constructor
    backgroundColor: 'rgba(0, 0, 0, 0)',
    minWidth: 0.5,
    canvasWidth: 500,
    canvasHeight: 145
  };

  public _signature: any = null;

  public propagateChange: Function = null;

  get signature(): any {
    return this._signature;
  }

  set signature(value: any) {
    this._signature = value;
    if (this.propagateChange) {
      this.propagateChange(this.signature);
    }
  }

  public writeValue(value: any): void {
    if (!value) {
      return;
    }
    this._signature = value;
    this.signaturePad.fromDataURL(this.signature);
  }

  public registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  public registerOnTouched(): void {
    // no-op
  }
  public ngOnInit(): void {}

  public drawBegin(): void {}

  public drawComplete(): void {
    this.signature = this.signaturePad.toDataURL('image/png', 0.5);
    this.EndEvent.emit(this.signaturePad);
  }

  public clear(): void {
    this.signaturePad.clear();
    this.signature = '';
  }
}

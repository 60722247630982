import { Subscription } from 'rxjs';
import { Client } from './../../../../models/client';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { WorkflowContextService } from 'src/app/services';
import { ComponentIds } from 'src/app/models/component-ids';

@Component({
  selector: 'wm-inspections-queue',
  templateUrl: './inspections-queue.component.html',
  styleUrls: ['./inspections-queue.component.css']
})
export class InspectionsQueueComponent implements OnInit, OnDestroy {
  @Input() client: Client;

  id = ComponentIds.INSPECTION_QUEUE;

  private clientSubscription: Subscription;

  constructor(private _context: WorkflowContextService) {}

  ngOnInit() {
    if (!this.client) {
      if (this._context.client) {
        this.client = this._context.client;
      } else {
        this.clientSubscription = this._context.client$.subscribe(c => {
          this.client = c;
        });
      }
    }
  }

  ngOnDestroy() {
    if (this.clientSubscription) {
      this.clientSubscription.unsubscribe();
      this.clientSubscription = null;
    }
  }
}

import { NumericDataEntity } from './../../../models/data-entities/numeric-data-entity';
import { DateDataEntity } from './../../../models/data-entities/date-data-entity';
import { DataEntityFactory } from './../../../services/workflow.service';
import { DataTableHelpers } from './../../system/datatable/datatable-helper';
import { ItemSearchOptionField } from './../../filter-list/models/filterClasses';
import {
  Component,
  OnInit,
  Input,
  ViewChild,
  Output,
  EventEmitter,
  TemplateRef
} from '@angular/core';
import {
  ContractorRegistration,
  Actions,
  User,
  ContractorTypeRequirement
} from 'src/app/models';
import { ModalConfirmComponent } from '../../system/modal-confirm/modal-confirm.component';
import {
  ContractorService,
  WorkflowContextService,
  SecurityService
} from 'src/app/services';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { TableColumn } from '@swimlane/ngx-datatable';
import {
  FilterBuilderFilter,
  FilterBuilderParam
} from 'src/app/models/filter-builder';
import { DataEntity } from 'src/app/models/data-entities';
import { RegistrationStatus } from 'src/app/models/registration';
import { ColumnOption, GridSettings } from 'src/app/models/grid-settings';

export interface Command {
  title: string;
  canPerform: (item: any) => boolean;
  handler: (id: string, item?: any) => void;
  idField?: string;
  name?: string;
}

@Component({
  selector: 'wm-contractor-registration-list',
  templateUrl: './contractor-registration-list.component.html',
  styleUrls: ['./contractor-registration-list.component.css']
})
export class ContractorRegistrationListComponent implements OnInit {
  @Input() id: string;
  statusOptions = [
    {
      name: 'Pending',
      value: '0'
    },
    {
      name: 'Approved',
      value: '1'
    },
    {
      name: 'Denied',
      value: '2'
    },
    {
      name: 'Expired',
      value: '3'
    },
    {
      name: 'Inactive',
      value: '4'
    },
    {
      name: 'Deleted',
      value: '5'
    },
    {
      name: 'Renewed',
      value: '6'
    },
    {
      name: 'Disabled',
      value: '7'
    }
  ];
  // this was turned on for testing 2564
  contactNameOptions = [
    { name: 'Normal', value: 'Normal' },
    { name: 'Not Normal', value: 'NotNormal' },
    { name: 'Normal Business', value: 'Normal Business' }
  ];

  @Input() filterCriteria: FilterBuilderParam[] = [
    {
      id: 'registerApplicationNumber',
      name: 'Application Number',
      types: ['is', 'contains']
    },
    {
      id: 'contactName',
      name: 'Contact Name'
      /// this was turned on for testing 2564
      // ,types: ['is', 'in'],
      // options: this.contactNameOptions
    },
    {
      id: 'contractor',
      name: 'Business Name'
      /// this was turned on for testing 2564
      //  ,types: ['is', 'in'],
      //  options: this.contactNameOptions
    },
    {
      id: 'contractorName',
      name: 'Contractor Name'
    },
    {
      id: 'type',
      name: 'Contractor Type'
    },
    {
      id: 'paymentMethod',
      name: 'Payment Method'
    },
    {
      id: 'registeredOn',
      name: 'Registered On',
      inputType: 'date',
      types: ['is', 'range']
    },
    {
      id: 'effectiveOn',
      name: 'Effective On',
      types: ['is', 'range'],
      inputType: 'date'
    },
    {
      id: 'expiresOn',
      name: 'Expires On',
      types: ['is', 'range'],
      inputType: 'date'
    },
    {
      id: 'approvedOn',
      name: 'Approved On',
      types: ['is', 'range'],
      inputType: 'date'
    },
    {
      id: 'rejectedOn',
      name: 'Rejected On',
      types: ['is', 'range'],
      inputType: 'date'
    },
    {
      id: 'status',
      name: 'Status',
      types: ['is', 'in'],
      options: this.statusOptions
    },
    {
      id: 'notes',
      name: 'Notes'
    }
  ];
  @Input() clientId: string;
  @Input() additionalCommands: Command[];
  @Input() public initialColumns: TableColumn[];
  @Input() public sort: { dir: string; prop: string } = {
    dir: 'desc',
    prop: 'registeredOn'
  };
  @Input() staticFilters: ItemSearchOptionField[] = [];
  @Input() dynamicColumnsVisibleByDefault = false;
  @Input() allowPermissibleActions = true;
  @Input() accessedFromContractorProfile = false;

  @Output() SortEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() filter: EventEmitter<any> = new EventEmitter<any>();
  @Output() recordCountSet = new EventEmitter<number>();
  @Output() filtersChanged = new EventEmitter<boolean>();

  @ViewChild('deleteRegModal', { static: true }) deleteRegModal: ModalConfirmComponent;
  @ViewChild('disableRegModal', { static: true }) disableRegModal: ModalConfirmComponent;
  @ViewChild('enableRegModal', { static: true }) enableRegModal: ModalConfirmComponent;
  @ViewChild('actionsTmpl', { static: true }) actionsTmpl: TemplateRef<any>;

  mappedRegistrations: any[];
  contractorRegistrations: ContractorRegistration[] = [];
  requirements: ContractorTypeRequirement[] = [];
  deleteRegistrationId: string;
  disableRegistrationId: string;
  enableRegistrationId: string;
  defaultFilters: FilterBuilderFilter[] = [];

  commands: Command[];

  canApprove = false;
  canDeny = false;
  canRenew = false;
  canEdit = false;
  canViewContractorProfile = false;
  unpermissibleCount = 0;

  public loading = false;
  public page = {
    // The number of elements in the page
    size: 10,
    // The total number of elements
    totalElements: 0,
    // The total number of pages
    totalPages: 0,
    // The current page number
    pageNumber: 0
  };
  public filterOptions: ItemSearchOptionField[] = [];

  customFields = {};

  columnsWithExtra: TableColumn[] = [];
  availableColumns: TableColumn[];
  columnOptions: ColumnOption[] = [];
  exportColumns: string[];
  dynamicColumnsSetSubject: Subject<TableColumn[]> = new Subject<
    TableColumn[]
  >();

  public useExcelFormat: boolean;

  // column prop strings to be hidden on the grid by default
  defaultHiddenColumns: string[] = [
    'registerApplicationNumber',
    'address',
    'phone',
    'email',
    'submittedBy'
  ];
  // column prop strings that should have no option to hide in the grid, and are not exportable (such as button columns like Refund or Actions)
  actionColumns: string[] = ['actions'];
  // column prop strings to be excluded from exports by default
  defaultExportExludedColumns: string[] = [
    'registerApplicationNumber',
    'address',
    'phone',
    'email',
    'submittedBy'
  ];

  constructor(
    public context: WorkflowContextService,
    private _contractorSvc: ContractorService,
    private _router: Router,
    private _toastrSvc: ToastrService,
    private _context: WorkflowContextService,
    private dtHelpers: DataTableHelpers,
    private _securitySvc: SecurityService
  ) {}

  setColumns(columns: TableColumn[]) {
    this.columnsWithExtra = [...this.initialColumns, ...columns];

    this.columnsWithExtra.push({
      prop: 'actions',
      name: 'Actions',
      cellTemplate: this.actionsTmpl
    });

    this.setAvailableColumns();
  }

  setExtraColumns(columns: TableColumn[]) {
    for (const c of columns) {
      if (
        !this.availableColumns.find(
          ac => (ac.prop as string) === (c.prop as string)
        )
      ) {
        this.availableColumns.push(c);
      }
    }

    // create columnOptions from new available ones since datatable hasn't updated it at this point yet
    for (const ac of this.availableColumns) {
      if (
        !this.columnOptions.find(co => co.name === (ac.prop as string)) &&
        !this.actionColumns.includes(ac.prop as string)
      ) {
        const newOption = new ColumnOption({
          label: ac.name,
          name: ac.prop as string,
          checked: this.dynamicColumnsVisibleByDefault,
          includeInExport: true
        });

        this.columnOptions.push(newOption);
      }
    }

    const visibleColumnProps = this.columnOptions
      .filter(co => co.checked)
      .map(co => co.name);

    // filter columns on visibility while putting action columns at the end
    this.columnsWithExtra = [
      ...this.availableColumns.filter(c =>
        visibleColumnProps.includes(c.prop as string)
      ),
      ...this.availableColumns.filter(c =>
        this.actionColumns.includes(c.prop as string)
      )
    ];
  }

  setAvailableColumns() {
    this.availableColumns = this.columnsWithExtra;
  }

  handleSettingsChanged(event: GridSettings) {
    this.filterOptions = event.legacyFilters;
    this.page.size = event.pageSize;
    this.page.pageNumber = event.pageNumber;
    this.sort.prop = event.sortField;
    this.sort.dir = event.sortDescending ? 'desc' : 'asc';
    this.columnOptions = event.columnOptions;

    const visibleColumnProps = this.columnOptions
      .filter(co => co.checked)
      .map(co => co.name);

    // filter columns on visibility while putting action columns at the end
    this.columnsWithExtra = [
      ...this.availableColumns.filter(c =>
        visibleColumnProps.includes(c.prop as string)
      ),
      ...this.availableColumns.filter(c =>
        this.actionColumns.includes(c.prop as string)
      )
    ];

    this.exportColumns = this.columnOptions
      .filter(co => co.includeInExport)
      .map(co => co.name);

    this.searchContractors();

    this.filtersChanged.emit(true);
  }

  handleColumnOptionsChanged(event: ColumnOption[]) {
    this.columnOptions = event;

    this.exportColumns = this.columnOptions
      .filter(co => co.includeInExport)
      .map(co => co.name);
  }

  ngOnInit() {
    const NotExpired = new FilterBuilderFilter({
      customValue: ['0', '1', '2', '4', '5', '6', '7'],
      queryType: 'in',
      param: {
        id: 'status',
        name: 'Status',
        options: this.statusOptions,
        types: ['is', 'in']
      },
      paramId: 'status',
      value: '1:\'0\''
    });

    this.defaultFilters = [NotExpired];

    // this is initialized in OnInit so that the cell templates exist before trying to render it
    this.initialColumns = this.initialColumns || [
      {
        prop: 'registerApplicationNumber',
        name: 'Application Number'
      },
      {
        prop: 'registeredOn',
        name: 'Registration Date',
        pipe: this.dtHelpers.getDatePipe()
      },
      {
        prop: 'effectiveOn',
        name: 'Effective Date',
        pipe: this.dtHelpers.getDatePipe()
      },
      {
        prop: 'expiresOn',
        name: 'Expiration Date',
        pipe: this.dtHelpers.getDatePipe()
      },
      {
        prop: 'approvedOn',
        name: 'Approved Date',
        pipe: this.dtHelpers.getDatePipe()
      },
      {
        prop: 'client',
        name: 'Jurisdiction'
      },
      {
        prop: 'type',
        name: 'Contractor Type'
      },
      {
        prop: 'paymentMethod',
        name: 'Payment Method'
      },
      {
        prop: 'submittedBy',
        name: 'Submitted By'
      }
    ];

    this.setColumns([]);

    this.loadPermissions().subscribe(() => {
      this.commands = [
        {
          title: 'View Details',
          canPerform: this.canViewDetails.bind(this),
          handler: this.viewDetails.bind(this)
        },
        {
          title: 'View Application',
          canPerform: this.canViewApplication.bind(this),
          handler: this.navigateToApplication.bind(this)
        },
        {
          title: 'Renew',
          canPerform: this.canRenewRegistration.bind(this),
          handler: this.renewRegistration.bind(this)
        },
        {
          title: 'Delete',
          canPerform: this.canDeleteRegistration.bind(this),
          handler: this.startDeleteRegistration.bind(this)
        },
        {
          title: 'Disable',
          canPerform: this.canDisableRegistration.bind(this),
          handler: this.startDisableRegistration.bind(this)
        },
        {
          title: 'Enable',
          canPerform: this.canEnableRegistration.bind(this),
          handler: this.startEnableRegistration.bind(this)
        }
      ];

      const viewProfileCommand = {
        title: 'View Profile',
        name: 'view-profile',
        idField: 'contractorId',
        canPerform: this.canViewProfile.bind(this),
        handler: this.viewProfile.bind(this)
      };

      if (!this.accessedFromContractorProfile) {
        this.commands.push(viewProfileCommand);
      }
    });
  }

  private loadPermissions(): Observable<any> {
    // allowPermissibleActions is passed to this component as true when we expect a client on the context
    return this.allowPermissibleActions
      ? combineLatest(
          this._securitySvc
            .isLoginEntitled(Actions.CONTRACTOR_TYPE_APPROVE)
            .pipe(
              map(e => {
                this.canApprove = e;
                return e;
              })
            ),
          this._securitySvc.isLoginEntitled(Actions.CONTRACTOR_TYPE_DENY).pipe(
            map(e => {
              this.canDeny = e;
              return e;
            })
          ),
          this._securitySvc.isLoginEntitled(Actions.CONTRACTOR_TYPE_RENEW).pipe(
            map(e => {
              this.canRenew = e;
              return e;
            })
          ),
          this._securitySvc.isLoginEntitled(Actions.CONTRACTOR_TYPE_RENEW).pipe(
            map(e => {
              this.canEdit = e;
              return e;
            })
          ),
          this._securitySvc.isLoginEntitled(Actions.CONTRACTORS_MANAGE).pipe(
            map(e => {
              this.canViewContractorProfile = e;
              return e;
            })
          )
        )
      : // allowPermissibleActions is passed to this component as false when we expect no client on the context because the user has no roles and therefore no permissions.
        // In that situation there is no need to attempt to set any of the class-level variable actions to true.
        of({});
  }

  canViewProfile(item: any): boolean {
    return this.canViewContractorProfile;
  }

  viewProfile(id: string, item: any) {
    this._router.navigate([
      '/admin/jurisdiction',
      this._context.client.id,
      'contractors',
      'contractor-profile',
      item.contractorId
    ]);
  }

  searchContractors() {
    this.loading = true;
    this.contractorRegistrations = null;

    const sortDescending = this.sort.dir === 'desc' ? true : false;

    let filters = [...this.filterOptions];

    if (this.staticFilters) {
      // filter options without simple search params in static filters
      // copy the static objects to a new array so I don't modify the static filter options.
      // fyi I tried slice, [...this.staticFilters], Array.from.  This is the only one that didn't modify this.staticFilters.
      const ref_staticFilters = this.staticFilters.map(a => {
        return { ...a };
      });

      // only the filters that DO not have a static filters.
      const filteredFilterOptions = this.filterOptions.filter(f => {
        // see if there are any static items in the static filter.
        const staticObjects = ref_staticFilters.filter(obj => {
          return obj.title === f.title;
        });
        if (staticObjects.length === 0) {
          // if there are not any then add it to the filteredFilterOptions
          return true;
        } else {
          // if there are some then take the options and add it to the staticFilter options instead.
          staticObjects[0].options = [
            ...staticObjects[0].options,
            ...f.options
          ];
          return false;
        }
      });
      filters = [...filteredFilterOptions, ...ref_staticFilters];
    }

    return this._contractorSvc
      .searchContractorRegistrations(
        this.clientId ||
          (this._context.client ? this._context.client.id : null),
        this.sort.prop,
        sortDescending,
        filters,
        this.page.pageNumber,
        this.page.size
      )
      .subscribe(
        result => {
          this.contractorRegistrations = result.contractorRegistrations;
          this.requirements = result.requirements;
          this.page.totalPages = result.pageCount;
          this.page.totalElements = result.recordCount;
          this.unpermissibleCount = result.unpermissibleCount;

          this.recordCountSet.emit(this.page.totalElements);

          if (this.contractorRegistrations) {
            // add requirement columns to the grid
            if (this.requirements && this.requirements.length > 0) {
              for (const requirement of this.requirements) {
                this.customFields[requirement.templateCode] = requirement.label;
              }
            }

            const extraColumns = [];
            for (const field in this.customFields) {
              if (this.customFields[field]) {
                extraColumns.push({
                  name: this.customFields[field],
                  prop: field
                });
              }
            }

            this.setExtraColumns(extraColumns);

            this.dynamicColumnsSetSubject.next(this.availableColumns);

            const isSortCustomField: boolean =
              extraColumns.find(f => f.prop === this.sort.prop) != null;

            const customDataTypes: { [key: string]: string } = {};
            const registrations = this.contractorRegistrations.map(
              registration => {
                const customData = {};

                if (registration.type.requirements) {
                  for (const req of registration.type.requirements) {
                    let customField = registration.customFields.find(
                      f => f.templateCode === req.templateCode
                    );

                    if (!customField) {
                      customField = registration.contractor.customFields.find(
                        f => f.templateCode === req.templateCode
                      );
                    }

                    if (customField) {
                      const contractorFieldDE: DataEntity = DataEntityFactory.createDataEntity(
                        customField.dataEntityType,
                        {
                          value: customField.fieldValue,
                          label: customField.label,
                          templateCode: customField.templateCode
                        }
                      );

                      customDataTypes[customField.templateCode] =
                        contractorFieldDE instanceof DateDataEntity
                          ? 'date'
                          : contractorFieldDE instanceof NumericDataEntity
                          ? 'numeric'
                          : 'string';
                      customData[customField.templateCode] =
                        contractorFieldDE.getFormattedValue() || 'N/A';
                    }
                  }
                }
                return {
                  id: registration.id,
                  type: registration.type ? registration.type.name : null,
                  client:
                    registration.type && registration.type.client
                      ? registration.type.client.name
                      : null,
                  registeredOn: registration.registeredOnString,
                  effectiveOn: registration.effectiveOnString,
                  expiresOn: registration.expiresOnString,
                  approvedOn: registration.approvedOnString,
                  rejectedOn: registration.rejectedOnString,
                  status: registration.status,
                  paymentMethod: registration.paymentRequest
                    ? registration.paymentRequest.paymentMethod
                    : null,
                  amountPaid: registration.paymentRequest
                    ? registration.paymentRequest.requestedAmount
                    : null,
                  paymentRequestId: registration.paymentRequestId,
                  createdApplicationId: registration.registerApplicationId,
                  contractorId: registration.contractorID,
                  contractorName:
                    registration.contractor.contactFirstName +
                    ' ' +
                    (registration.contractor.contactLastName || ''),
                  address: registration.contractor.address
                    ? (registration.contractor.address.address1
                        ? registration.contractor.address.address1 + ' '
                        : registration.contractor.address.address2
                        ? registration.contractor.address.address2 + ' '
                        : '') +
                      (registration.contractor.address.city
                        ? registration.contractor.address.city + ', '
                        : '') +
                      (registration.contractor.address.state
                        ? registration.contractor.address.state + ' '
                        : '') +
                      (registration.contractor.address.zip
                        ? registration.contractor.address.zip + ' '
                        : '')
                    : '',
                  phone: registration.contractor.businessCell
                    ? registration.contractor.businessCell.number
                    : registration.contractor.businessPhone
                    ? registration.contractor.businessPhone.number
                    : '',
                  email: registration.contractor.contactEmail
                    ? registration.contractor.contactEmail.email
                    : '',
                  contractor: registration.contractor.businessName,
                  registerApplicationNumber:
                    registration.registerApplication.combinedApplicationNumber,
                  submittedBy:
                    registration.registerApplication.submitterName &&
                    registration.registerApplication.submitterName !== ''
                      ? registration.registerApplication.submitterName
                      : registration.registerApplication.createdByName,
                  ...customData
                };
              }
            );

            if (isSortCustomField) {
              this.mappedRegistrations = registrations.sort((a, b) => {
                const dataType = customDataTypes[this.sort.prop];
                const aValue = (a[this.sort.prop] || '').trim().toLowerCase();
                const bValue = (b[this.sort.prop] || '').trim().toLowerCase();

                if (dataType === 'date') {
                  let sortValue = -1;

                  if (sortDescending) {
                    sortValue = !aValue
                      ? 1
                      : new Date(aValue) < new Date(bValue)
                      ? 1
                      : new Date(aValue) === new Date(bValue)
                      ? 0
                      : -1;
                  } else {
                    sortValue = !aValue
                      ? -1
                      : new Date(aValue) < new Date(bValue)
                      ? -1
                      : new Date(aValue) === new Date(bValue)
                      ? 0
                      : 1;
                  }
                  return sortValue;
                } else if (dataType === 'numeric') {
                  if (sortDescending) {
                    return !aValue
                      ? 1
                      : parseInt(aValue, 10) < parseInt(bValue, 10)
                      ? 1
                      : parseInt(aValue, 10) === parseInt(bValue, 10)
                      ? 0
                      : -1;
                  } else {
                    return !aValue
                      ? -1
                      : parseInt(aValue, 10) > parseInt(bValue, 10)
                      ? 1
                      : parseInt(aValue, 10) === parseInt(bValue, 10)
                      ? 0
                      : -1;
                  }
                } else {
                  let sortValue = 0;
                  if (sortDescending) {
                    sortValue = !aValue
                      ? 1
                      : aValue < bValue
                      ? 1
                      : aValue === bValue
                      ? 0
                      : -1;
                  } else {
                    sortValue = !aValue
                      ? -1
                      : aValue < bValue
                      ? -1
                      : aValue === bValue
                      ? 0
                      : 1;
                  }
                  return sortValue;
                }
              });
            } else {
              this.mappedRegistrations = registrations;
            }
          }

          this.loading = false;
        },
        err => {
          this.loading = false;
          throw err;
        }
      );
  }

  viewDetails(id: string) {
    const clientId = this.contractorRegistrations.find(i => i.id === id).type
      .clientID;

    if (clientId && id) {
      if (this.canEdit) {
        this._router.navigate([
          '/admin/jurisdiction',
          clientId,
          'contractors',
          'registration-details',
          id
        ]);
      } else {
        this._router.navigate(['/auth', 'registration-details', id]);
      }
    }
  }

  deleteRegistration() {
    this._contractorSvc
      .deleteContractorRegistration(this.deleteRegistrationId)
      .subscribe(() => {
        this._toastrSvc.success('Registration Deleted');
        this.searchContractors();
      });
  }

  disableRegistration() {
    this._contractorSvc
      .disableContractorRegistration(this.disableRegistrationId)
      .subscribe(() => {
        this._toastrSvc.success('Registration Disabled');
        this.searchContractors();
      });
  }

  enableRegistration() {
    this._contractorSvc
      .reEnableContractorRegistration(this.enableRegistrationId)
      .subscribe(() => {
        this._toastrSvc.success('Registration Enabled');
        this.searchContractors();
      });
  }

  navigateToApplication(id: string) {
    const reg = this.mappedRegistrations.find(mr => mr.id === id);
    if (reg) {
      this._router.navigate([
        '/application/workflow-application',
        reg.createdApplicationId
      ]);
    }
  }

  renewRegistration(id: string) {
    this._contractorSvc.startRegistrationRenewal(id).subscribe(result => {
      this._router.navigate([
        '/application/workflow-application',
        result.applicationId
      ]);
    });
  }

  startDeleteRegistration(id: string) {
    this.deleteRegistrationId = id;
    this.deleteRegModal.open();
  }

  startDisableRegistration(id: string) {
    this.disableRegistrationId = id;
    this.disableRegModal.open();
  }

  startEnableRegistration(id: string) {
    this.enableRegistrationId = id;
    this.enableRegModal.open();
  }

  canViewDetails(item: any): boolean {
    return true;
  }

  canViewReceipt(item: any): boolean {
    return item.paymentRequestId != null;
  }

  canDeleteRegistration(item: any): boolean {
    return (
      item.status !== RegistrationStatus.Deleted &&
      (this.canEdit ||
        (item.status === RegistrationStatus.Pending &&
          ((this._context.user || new User()).contractorId &&
            item.contractorId ===
              (this._context.user || new User()).contractorId)))
    );
  }

  canDisableRegistration(item: any): boolean {
    return (
      (item.status === RegistrationStatus.Approved ||
        item.status === RegistrationStatus.Denied ||
        item.status === RegistrationStatus.Inactive) &&
      (this.canEdit ||
        ((this._context.user || new User()).contractorId &&
          item.contractorId ===
            (this._context.user || new User()).contractorId))
    );
  }

  canEnableRegistration(item: any): boolean {
    return item.status === RegistrationStatus.Disabled && this.canEdit;
  }

  canViewApplication(item: any): boolean {
    return (
      this.canApprove ||
      this.canDeny ||
      ((this._context.user || new User()).contractorId &&
        item.contractorId === (this._context.user || new User()).contractorId)
    );
  }

  canRenewRegistration(item: any): boolean {
    return (
      item.status !== RegistrationStatus.Pending &&
      item.status !== RegistrationStatus.Deleted &&
      (this.canRenew || item.contractorId === this._context.user.contractorId)
    );
  }

  exportExcel() {
    this.useExcelFormat = true;
    this.export();
  }

  exportCsv() {
    this.useExcelFormat = false;
    this.export();
  }

  export() {
    const sortDescending = this.sort.dir === 'desc' ? true : false;

    let filters = [...this.filterOptions];
    if (this.staticFilters) {
      // filter options without simple search params in static filters
      // copy the static objects to a new array so I don't modify the static filter options.
      // fyi I tried slice, [...this.staticFilters], Array.from.  This is the only one that didn't modify this.staticFilters.
      const ref_staticFilters = this.staticFilters.map(a => {
        return { ...a };
      });

      // only the filters that DO not have a static filters.
      const filteredFilterOptions = this.filterOptions.filter(f => {
        // see if there are any static items in the static filter.
        const staticObjects = ref_staticFilters.filter(obj => {
          return obj.title === f.title;
        });
        if (staticObjects.length === 0) {
          // if there are not any then add it to the filteredFilterOptions
          return true;
        } else {
          // if there are some then take the options and add it to the staticFilter options instead.
          staticObjects[0].options = [
            ...staticObjects[0].options,
            ...f.options
          ];
          return false;
        }
      });
      filters = [...filteredFilterOptions, ...ref_staticFilters];
    }

    return this._contractorSvc
      .exportContractorRegistrations(
        this.clientId ||
          (this._context.client ? this._context.client.id : null),
        this.useExcelFormat,
        this.exportColumns,
        this.sort.prop,
        sortDescending,
        filters,
        this.page.pageNumber,
        this.page.size
      )
      .subscribe(
        data => {
          if (this.useExcelFormat) {
            const blob = new Blob([data], {
              type:
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            });
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = `contractor-registrations-export-${new Date().getTime()}.xlsx`;
            link.click();
          } else {
            const blob = new Blob([data], { type: 'text/csv' });
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = `contractor-registrations-export-${new Date().getTime()}.csv`;
            link.click();
          }
        },
        err => {
          this._toastrSvc.error('Problem while downloading the file.');
          console.error(err);
        }
      );
  }
}

<app-loader *ngIf="!mapServices"></app-loader>

<div *ngIf="mapServices && mapServices.length === 0">
  <p>No map services to display</p>
</div>

<ul class="list-group" *ngIf="mapServices && mapServices.length > 0">
  <li
    *ngFor="let mapService of mapServices"
    class="list-group-item"
    [ngStyle]="{ cursor: allowSelect ? 'pointer' : 'default' }"
    (click)="editMapService(mapService)"
  >
    {{ mapService.name }}
  </li>
</ul>

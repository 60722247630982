export class ApplicationConfiguration {
  applicationName: string;
  applicationLogo: string;
  applicationTitle: string;
  workflowTitle: string;
  applicationSmallLogo: string;

  constructor(options?: Partial<ApplicationConfiguration>) {
    if (options) {
      Object.assign(this, options);
    }
  }
}

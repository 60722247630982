import { Injectable } from '@angular/core';

export enum DiagramControlColor {
  primary = 'primary',
  danger = 'danger',
  warn = 'warning'
}

/**
 * A diagram control
 */
export class DiagramControl {
  /**
   * Creates a new control from the provided options
   *
   * @param opts An object of params to create a control with
   */
  constructor(opts: DiagramControl) {
    this.id = opts.id;
    this.name = opts.name;
    this.icon = opts.icon;
    this.color = opts.color || DiagramControlColor.primary;
    this.position = opts.position || 0;

    if (opts.click) {
      this.click = opts.click;
    }
    if (opts.disabled) {
      this.disabled = opts.disabled;
    }
  }

  /**
   * ID of the control
   */
  id: string;
  /**
   * Name of the control
   */
  name: string;
  /**
   * Material Design Icon to display on the controls button
   */
  icon: string;
  /**
   * Color of the control icon
   */
  color?: DiagramControlColor;
  /**
   * Position to order the control in
   */
  position?: number;
  /**
   * Click handler for the control
   */
  click?(): any {
    return null;
  }
  /**
   * Checks if the control is disabled
   */
  disabled?(): any {
    return false;
  }
}

/**
 * Immutable service to manage diagram controls
 */
@Injectable({
  providedIn: 'root'
})
export class DiagramControlsService {
  /**
   * The current set of controls
   */
  private _controls: DiagramControl[] = [];

  /**
   * Immutable version of the current set of controls
   */
  public get controls(): DiagramControl[] {
    const controls = [...this._controls];

    return controls.sort((a, b) => a.position - b.position);
  }

  /**
   * @ignore
   */
  constructor() {}

  /**
   * Get a control
   *
   * @param id ID of the control that you want to get
   */
  public getControl(id: string): DiagramControl {
    const ctrl = this._controls.find(c => c.id === id);

    return { ...ctrl };
  }

  /**
   * Update a control
   *
   * @param id ID of the control that you want to update
   * @param newCtrl An object of params to change on the control
   */
  public setControl(
    id: string,
    newCtrl: Partial<DiagramControl>
  ): DiagramControl {
    const controls = [...this._controls];
    const oldCtrl = this.getControl(id);

    // combine the two objects and make sure that the ID doesn't change
    const updatedCtrl = new DiagramControl({
      ...oldCtrl,
      ...newCtrl,
      id
    });

    const index = controls.findIndex(c => c.id === id);
    controls[index] = updatedCtrl;

    this._controls = controls;

    return updatedCtrl;
  }

  /**
   * Delete a control
   *
   * @param id ID of the control that you want to delete
   */
  public deleteControl(id: string): void {
    const controls = [...this._controls];

    const index = controls.findIndex(c => c.id === id);
    controls.splice(index, 1);

    this._controls = controls;
  }

  /**
   * Add a control
   *
   * @param newCtrl An array of controls to initialize
   */
  public addControl(newCtrl: DiagramControl): DiagramControl {
    const controls = [...this._controls];
    const ctrl = new DiagramControl({ ...newCtrl });

    controls.push(ctrl);

    this._controls = controls;

    return ctrl;
  }

  /**
   * Initialize the controls and replaces existing controls
   *
   * @param controls An array of controls to initialize
   */
  public init(controls: DiagramControl[]) {
    const newControls = [];

    for (const ctrl of controls) {
      newControls.push(new DiagramControl({ ...ctrl }));
    }

    this._controls = newControls;
  }
}

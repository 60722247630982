<div class="row">
  <div [class.col-md-3]="showToc" [class.hidden]="!showToc">
    <div #tocViewNode class="esri-toc"></div>
  </div>
  <div
    [class.col-md-9]="showToc"
    [class.col-md-12]="!showToc"
    style="max-height: 500px !important;"
  >
    <div #mapViewNode class="esri-view" [ngClass]="mapDimensionLayout_class">
      <div
        class="bControlDisplayXY"
        [hidden]="!displayXYCoords"
        [title]="displayXYCoordsTitle"
      >
        <div
          class="bControlDisplayXYToggle"
          title="Change coordinate system"
          (click)="toggleDisplayCoordinateSystem()"
        ></div>
        <span
          id="info"
          class="esri-attribution__powered-by esri-attribution bControlDisplayXYText"
          >{{ mapDisplayX }}, {{ mapDisplayY }}</span
        >
      </div>
      <app-loader
        [style.visibility]="mapLoading ? 'visible' : 'hidden'"
        style="position: absolute; right: 25px;top: 5px"
      ></app-loader>
      <div
        #toolbarItems
        *ngIf="toolbarMode != 'none'"
        [hidden]="!toolbarVisible || !sketchViewModel"
      >
        <div
          (click)="resetTool($event)"
          [ngClass]="{ 'esri-widget--button-active': activeButton === null }"
          class="esri-widget esri-widget--button esri-interactive"
          ngbTooltip="Select tool"
          container="body"
          placement="right"
        >
          <span class="esri-icon-cursor"></span>
        </div>
        <div
          (click)="openPlaceByCoordinatesDialog($event)"
          [ngClass]="{
            'esri-widget--button-active': activeButton === 'xypoint'
          }"
          class="esri-widget esri-widget--button esri-interactive"
          ngbTooltip="Place By Coordinates"
          container="body"
          placement="right"
        >
          <span class="esri-icon-applications"></span>
        </div>

        <div
          (click)="startTextSketch($event)"
          [ngClass]="{ 'esri-widget--button-active': activeButton === 'text' }"
          class="esri-widget esri-widget--button esri-interactive"
          ngbTooltip="Add text"
          container="body"
          placement="right"
        >
          <span class="esri-icon-comment"></span>
        </div>
        <div
          (click)="startPointSketch()"
          [ngClass]="{ 'esri-widget--button-active': activeButton === 'point' }"
          class="esri-widget esri-widget--button esri-interactive"
          ngbTooltip="Draw a point"
          container="body"
          placement="right"
        >
          <span class="esri-icon-map-pin"></span>
        </div>
        <div
          (click)="startRectangleSketch()"
          [ngClass]="{ 'esri-widget--button-active': activeButton === 'rect' }"
          class="esri-widget esri-widget--button esri-interactive"
          ngbTooltip="Draw a rectangle"
          container="body"
          placement="right"
        >
          <span class="esri-icon-sketch-rectangle"></span>
        </div>
        <div
          (click)="startPolygonSketch()"
          [ngClass]="{
            'esri-widget--button-active': activeButton === 'polygon'
          }"
          class="esri-widget esri-widget--button esri-interactive"
          ngbTooltip="Draw a polyline"
          container="body"
          placement="right"
        >
          <span class="esri-icon-polygon"></span>
        </div>

        <div
          (click)="startPolylineSketch()"
          [ngClass]="{
            'esri-widget--button-active': activeButton === 'polyline'
          }"
          class="esri-widget esri-widget--button esri-interactive"
          ngbTooltip="Draw a line"
          container="body"
          placement="right"
        >
          <span class="esri-icon-polyline"></span>
        </div>
        <div
          (click)="startMeasureSketch()"
          [ngClass]="{
            'esri-widget--button-active': activeButton === 'measure'
          }"
          class="esri-widget esri-widget--button esri-interactive"
          *ngIf="!useSketchSingleItemMode"
          ngbTooltip="Measure"
          container="body"
          placement="right"
        >
          <span class="esri-icon-measure"></span>
        </div>
        <div
          (click)="undoSketch()"
          class="esri-widget esri-widget--button esri-interactive"
          ngbTooltip="Undo the last edit to the map"
          container="body"
          placement="right"
        >
          <span class="esri-icon-undo"></span>
        </div>
        <div
          (click)="clearSketchLayer()"
          class="esri-widget esri-widget--button esri-interactive"
          ngbTooltip="Undo all edits to the map"
          container="body"
          placement="right"
        >
          <span class="esri-icon-trash"></span>
        </div>

        <div
          (click)="zoomToInitialExtent()"
          class="esri-widget esri-widget--button esri-interactive"
          ngbTooltip="Zoom to initial view"
          container="body"
          placement="right"
        >
          <span class="esri-icon-globe"></span>
        </div>
      </div>
    </div>
  </div>
</div>
<wm-modal-confirm
  title="Enter Map Coordinates"
  [showButton]="false"
  commandText="Add Coordinates"
  (accepted)="saveXYCoordinates()"
  (canceled)="resetCoordinateSystemDropdown()"
  [closeOnAccept]="false"
  #SaveXYCoordinates
>
  <div class="container">
    <form>
      <div *ngIf="!validCoordinate" class="text-danger">
        The coordinates you entered are outside the bounds of the county parcel
        layer.
      </div>

      <div [formGroup]="addCoordinatesForm" class="form-group">
        <label class="bmd-label-floating" for="ddlXyCoordSys"
          >Coordinate System:
        </label>

        <select
          class="form-control"
          id="ddlXyCoordSys"
          aria-label="Coordinate system"
          [formControl]="ddlXyCoordSys"
        >
          <option *ngFor="let item of coordinateSystems" [value]="item.value">{{
            item.text
          }}</option>
        </select>
      </div>

      <div class="form-group">
        <label class="bmd-label-floating" for="xvalue">X: </label>
        <input
          id="xvalue"
          class="form-control"
          type="text"
          [formControl]="xvalue"
        />
      </div>

      <div
        *ngIf="
          xvalue.errors?.invalidCoordinate && (xvalue.touched || xvalue.dirty)
        "
        class="text-danger"
      >
        Please enter a valid coordinate.
      </div>

      <div class="form-group">
        <label class="bmd-label-floating" for="yvalue">Y: </label>

        <input
          id="yvalue"
          class="form-control"
          type="text"
          [formControl]="yvalue"
        />
      </div>

      <div
        *ngIf="
          yvalue.errors?.invalidCoordinate && (yvalue.touched || yvalue.dirty)
        "
        class="text-danger"
      >
        Please enter a valid coordinate.
      </div>
    </form>
  </div>
</wm-modal-confirm>

<form [formGroup]="form">
  <div class="form-row">
    <div class="form-group col-6">
      <label class="bmd-label-floating" for="minLength">Min Length</label>
      <input
        type="text"
        class="form-control"
        formControlName="minimumLength"
        name="minLength"
        [(ngModel)]="freeFormDE.minLength"
      />
    </div>
    <div class="form-group col-6">
      <label class="bmd-label-floating" for="maxLength">Max Length</label>
      <input
        type="text"
        class="form-control"
        formControlName="maximumLength"
        name="maxLength"
        [(ngModel)]="freeFormDE.maxLength"
      />
    </div>
  </div>

  <div>
    <div class="form-group">
      <label class="bmd-label-floating">
        Format Validation
      </label>
      <select
        class="form-control"
        formControlName="restrictedFormat"
        (change)="restrictedFormatChange($event)"
        [(ngModel)]="freeFormDE.restrictedFormat"
      >
        <option *ngFor="let rf of restrictedFormats" [value]="rf.id">
          {{ rf.value }}
        </option>
      </select>
    </div>
  </div>

  <div class="switch">
    <label>
      <input
        type="checkbox"
        formControlName="isMultiline"
        [(ngModel)]="freeFormDE.isMultiline"
      />
      Multiline
    </label>
  </div>

  <wm-external-data-source-config
    [entity]="dataEntity"
    [form]="form"
    (externalSourceUpdated)="externalDataSourceChanged($event)"
  ></wm-external-data-source-config>
</form>

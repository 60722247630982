import {
  Component,
  OnInit,
  Input,
  ViewChild,
  OnDestroy,
  Output,
  EventEmitter,
  OnChanges
} from '@angular/core';
import {
  ButtonStyle,
  ModalConfirmComponent
} from '../../system/modal-confirm/modal-confirm.component';
import { WorkflowService } from 'src/app/services';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { SimpleChanges } from '@angular/core';

@Component({
  selector: 'wm-edit-application-number',
  templateUrl: './edit-application-number.component.html',
  styleUrls: ['./edit-application-number.component.css']
})
export class EditApplicationNumberComponent
  implements OnInit, OnDestroy, OnChanges {
  @Input()
  linkText = 'Edit';

  @Input()
  applicationId: string;

  @Input()
  applicationNumber: string;

  @Input()
  form: UntypedFormGroup;
  @Input()
  showButton = true;

  @Input()
  clientApplicationNumber: string;

  @Output()
  numberChanged: EventEmitter<string> = new EventEmitter<string>();

  editAppForm: UntypedFormGroup;

  @ViewChild('editAppNumber', { static: false }) editAppNumber: ModalConfirmComponent;

  newApplicationNumber: string;

  showExistingNumberWarning = false;

  buttonStyles = ButtonStyle;

  constructor(private _workflowSvc: WorkflowService) {}
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['applicationNumber']) {
      this.newApplicationNumber = this.applicationNumber;
    }
  }

  ngOnDestroy(): void {
    if (this.form) {
      this.form.removeControl('editAppForm');
    }
  }

  ngOnInit() {
    this.newApplicationNumber = this.applicationNumber;

    this.editAppForm = new UntypedFormGroup({
      newApplicationNumber: new UntypedFormControl('', Validators.required)
    });

    if (this.form) {
      this.form.addControl('editAppForm', this.editAppForm);
    }
  }

  reset() {
    this.showExistingNumberWarning = false;
    this.newApplicationNumber = this.applicationNumber;
  }

  saveApplicationNumber() {
    this._workflowSvc
      .updateClientApplicationNumber(
        this.applicationId,
        this.newApplicationNumber,
        this.showExistingNumberWarning
      )
      .subscribe(
        result => {
          if (result.applicationNumberExist) {
            this.showExistingNumberWarning = true;
            this.editAppNumber.cancelProcessing();
          } else {
            this.numberChanged.emit(this.newApplicationNumber);
            this.editAppNumber.cancel();
          }
        },
        e => {
          this.editAppNumber.cancelProcessing();
          throw e;
        }
      );
  }

  open() {
    this.editAppNumber.open();
  }
}

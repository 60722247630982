<form [formGroup]="form" *ngIf="config">
  <div class="form-group">
    <label class="bmd-label-floating" for="serviceCode">
      Service Code
    </label>
    <input
      type="text"
      class="form-control"
      formControlName="serviceCode"
      name="serviceCode"
      [(ngModel)]="config.serviceCode"
    />
  </div>
  <div class="form-group">
    <label class="bmd-label-floating" for="merchantKey">
      Merchant Key
    </label>
    <input
      type="text"
      class="form-control"
      formControlName="merchantKey"
      name="merchantKey"
      [(ngModel)]="config.merchantKey"
    />
  </div>
  <div class="form-group">
    <label class="bmd-label-floating" for="merchantID">
      Merchant ID
    </label>
    <input
      type="text"
      class="form-control"
      formControlName="merchantID"
      name="merchantID"
      [(ngModel)]="config.merchantID"
    />
  </div>
  <div class="form-group">
    <label class="bmd-label-floating" for="stateCode">
      State Code
    </label>
    <input
      type="text"
      class="form-control"
      formControlName="stateCode"
      name="stateCode"
      [(ngModel)]="config.stateCode"
    />
  </div>
</form>

import { Injectable } from '@angular/core';
import {
  loadModules as esriLoadModules,
  loadCss,
  ILoadScriptOptions
} from 'esri-loader'; // https://github.com/Esri/esri-loader
import esri = __esri;

@Injectable({
  providedIn: 'root'
})
export class EsriLoaderService {
  // Notes: anything before v4.9 may fail due to a complete change in how Promises interact with ES6 promises.

  private loaderOptions: ILoadScriptOptions = {
    css: 'https://js.arcgis.com/4.20/esri/css/main.css',
    url: 'https://js.arcgis.com/4.20'
  };

  constructor() {}

  LoadModules(modules: string[]): Promise<any> {
    const p1 = esriLoadModules(
      ['esri/config', 'esri/core/urlUtils'],
      this.loaderOptions
    );
    // p1.then(([esriConfig,urlUtils])=>{
    //   esriConfig.request.proxyUrl = "/ArcGIS/proxy.ashx";
    // });

    return esriLoadModules(modules, this.loaderOptions);
  }
}

<div class="container" *ngIf="parcelDataConfigured">
  <div class="row">
    <div class="col-xs-2 col-lg-12">
      <wm-search-parcel-data-activity-input
        id="activityInput"
        [activity]="activity"
        [hideResultsOnSelect]="true"
        [form]="form"
        *ngIf="activity"
        (goNext)="goNext($event)"
        (searched)="onParcelSearch()"
      >
      </wm-search-parcel-data-activity-input>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <wm-workflow-application-list
        *ngIf="showResults && myWorkflowsFilter"
        id="workflow-application-list-parcel"
        [columns]="columns"
        [workflowId]="null"
        [staticFilters]="myWorkflowsFilter"
        [expandToChild]="true"
        [UseMap]="false"
        [showAll]="false"
      >
      </wm-workflow-application-list>
    </div>
  </div>

  <div class="row" *ngIf="canViewPSD() | async">
    <div class="col">
      <wm-workflow-previous-system-list
        id="previous-system-list-parcel"
        *ngIf="showPreviousSystemResults && activity.model.selectedParcel"
        [clientId]="clientId"
        [staticFilters]="parcelNumberFilter"
      ></wm-workflow-previous-system-list>
    </div>
  </div>
</div>

<div *ngIf="!parcelDataConfigured" class="text-center mb-5">
  <p>Please set up a parcel data connection before searching by parcel.</p>
  <button
    class="btn btn-primary"
    [routerLink]="['../../settings/data/add']"
    *ngIf="canEditExternalConnections"
  >
    Set up Parcel Data
  </button>
</div>

<ng-template #currentActivityTmpl let-row="row" let-value="value">
  {{value}}
</ng-template>

<ng-template #appIdTmpl let-row="row" let-value="value">
  <a
    *ngIf="row.status === ApplicationStatus.InProgress"
    [routerLink]="['/application/workflow-application', row.appId]"
  >
    {{ value }} {{ row.status }} {{ ApplicationStatus.InProgress }}
  </a>
  <a
    *ngIf="row.status !== ApplicationStatus.InProgress"
    [routerLink]="['/application/workflow-application-summary', row.appId]"
  >
    {{ value }}
  </a>
  (<a href="javascript:void(0)" (click)="openInspections(inspectionsModal, row)"
    >Inspections</a
  >)
</ng-template>

<ng-template #inspectionsModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-inspections-title">
      Inspections related to {{ currentApplication.combinedApplicationNumber }}
    </h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <wm-inspection-queue
      [client]="context.client"
      [staticFilters]="buildInspectionFilter(currentApplication.appId)"
    ></wm-inspection-queue>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-dark"
      (click)="modal.close('Close click')"
    >
      Close
    </button>
  </div>
</ng-template>

<form [formGroup]="form" (submit)="save()">
  <div class="row">
    <div class="col-6">
      <h3>Contact</h3>
      <app-loader *ngIf="!contact"></app-loader>

      <div *ngIf="contact">
        <div class="form-row">
          <div
            class="form-group col"
            [class.has-error]="firstName.touched && !firstName.valid"
          >
            <label class="bmd-label-floating" for="firstName">First Name</label>
            <input
              type="text"
              class="form-control"
              [(ngModel)]="contact.firstName"
              formControlName="firstName"
              name="firstName"
            />
            <wm-control-message [control]="form.controls.firstName">
            </wm-control-message>
          </div>

          <div class="form-group col">
            <label class="bmd-label-floating" for="lastName">Last Name</label>
            <input
              type="text"
              class="form-control"
              [(ngModel)]="contact.lastName"
              formControlName="lastName"
              name="lastName"
            />
            <wm-control-message [control]="form.controls.lastName">
            </wm-control-message>
          </div>
        </div>

        <div class="form-group">
          <label class="bmd-label-floating" for="phoneNumber">
            Phone Number
          </label>
          <input
            type="text"
            class="form-control"
            [(ngModel)]="contact.phoneNumber"
            formControlName="phoneNumber"
            name="phoneNumber"
          />
          <wm-control-message [control]="form.controls.phoneNumber">
          </wm-control-message>
        </div>

        <wm-email-address-detail
          [emailAddress]="contact.emailAddress"
          [showButtons]="false"
          [showHeader]="true"
          [required]="false"
          [showName]="false"
          [parentType]="'Contact'"
          [form]="form"
        >
        </wm-email-address-detail>
        <wm-control-message [control]="form.controls.emailAddress">
        </wm-control-message>

        <div class="form-group">
          <label class="bmd-label-floating" for="title">
            Title
          </label>
          <input
            type="text"
            class="form-control"
            [(ngModel)]="contact.title"
            formControlName="title"
            name="title"
          />
          <wm-control-message [control]="form.controls.title">
          </wm-control-message>
        </div>
      </div>
    </div>

    <div class="col-6">
      <div *ngIf="contact">
        <h3>Registered Addresses</h3>
        <div class="form-group">
          <wm-address-detail
            [required]="false"
            [showButtons]="false"
            [address]="contact.address"
            [showName]="false"
            [parentType]="'Contact'"
          ></wm-address-detail>
        </div>

        <div class="form-group">
          <label for="officeHours">Office Hours</label>
          <textarea
            class="form-control"
            name="officeHours"
            [(ngModel)]="contact.officeHours"
            formControlName="officeHours"
            id="officeHours"
            rows="3"
          ></textarea>
          <wm-control-message [control]="form.controls.officeHours">
          </wm-control-message>
        </div>
      </div>
    </div>
  </div>

  <div class="row mt-2" *ngIf="contact">
    <div class="col">
      <button
        type="submit"
        class="btn btn-raised btn-success"
        [disabled]="!form.valid"
      >
        Save
      </button>
    </div>
    <div class="col">
      <div class=" float-right">
        <wm-modal-confirm
          *ngIf="canRemove()"
          [commandText]="'Remove Contact'"
          [title]="'Remove Contact'"
          [showButtonText]="true"
          (accepted)="removeContact($event)"
          class="mx-2"
          btnColor="danger"
        >
          <p>
            Are you sure you want to remove this contact ({{
              contact.firstName
            }}
            {{ contact.lastName }}) from the system?
          </p>
        </wm-modal-confirm>
        <button
          type="button"
          class="btn btn-raised btn-default mx-2"
          routerLink="/admin/jurisdiction/{{
            _context?.client?.id
          }}/settings/contacts"
        >
          Cancel
        </button>
      </div>
    </div>
  </div>
</form>

import { ActivatedRoute, Params } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { WorkflowContextService, SystemService } from 'src/app/services';

@Component({
  selector: 'wm-payment-form',
  templateUrl: './payment-form.component.html',
  styleUrls: ['./payment-form.component.css']
})
export class PaymentFormComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    public context: WorkflowContextService,
    private systemSvc: SystemService
  ) {}

  isMobileDevice = false;
  menuOpen = false;
  workflowName: string;
  applicationNumber: string;

  requestId: string;

  ngOnInit() {
    this.route.params.subscribe(param => {
      this.requestId = this.route.snapshot.firstChild.params[
        'paymentRequestId'
      ];

      this.systemSvc.getPaymentRequest(this.requestId).subscribe(pr => {
        this.workflowName = pr.applicationName;
        this.applicationNumber = pr.applicationNumber;
      });
    });
  }

  mainClick() {
    if (this.menuOpen) {
      this.menuOpen = !this.menuOpen;
    }
  }

}

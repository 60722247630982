import { ValidationService } from 'src/app/services';
import { Component, OnInit, Inject, forwardRef } from '@angular/core';
import { UntypedFormBuilder, AbstractControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import {
  WorkflowService,
  WorkflowContextService,
  SystemService,
  ClientService,
  DataService
} from '../../../../services';
import { DocumentDataEntity } from '../../../../models/data-entities';
import { DataEntityEditorBaseComponent } from '../data-entity-editor-base/data-entity-editor-base.component';

@Component({
  selector: 'wm-document-data-entity',
  templateUrl: './document-data-entity.component.html',
  styleUrls: ['./document-data-entity.component.css']
})
export class DocumentDataEntityComponent extends DataEntityEditorBaseComponent
  implements OnInit {
  documentDE: DocumentDataEntity;
  validDocumentTypes: AbstractControl;

  constructor(
    protected _fb: UntypedFormBuilder,
    protected _route: ActivatedRoute,
    @Inject(forwardRef(() => WorkflowService))
    protected _workflowSvc: WorkflowService,
    @Inject(forwardRef(() => WorkflowContextService))
    protected _context: WorkflowContextService,
    @Inject(forwardRef(() => SystemService))
    protected _systemSvc: SystemService,
    @Inject(forwardRef(() => ClientService))
    protected _clientSvc: ClientService,
    @Inject(forwardRef(() => DataService)) protected _dataSvc: DataService
  ) {
    super();
  }

  ngOnInit() {
    this.documentDE = this.dataEntity as DocumentDataEntity;
    this.form.addControl(
      'validDocumentTypes',
      this._fb.control('', [
        Validators.required,
        ValidationService.fileTypeValidator
      ])
    );
    this.validDocumentTypes = this.form.controls['validDocumentTypes'];
  }
}

<p *ngIf="!paymentAccounts">Loading payment accounts...</p>

<p *ngIf="paymentAccounts && paymentAccounts.length === 0">
  No payment accounts to display
</p>

<ul class="list-group" *ngIf="paymentAccounts && paymentAccounts.length > 0">
  <li
    *ngFor="let paymentAccount of paymentAccounts"
    class="list-group-item d-flex justify-content-between align-items-center"
    [ngStyle]="{ cursor: allowSelect ? 'pointer' : 'default' }"
    (click)="editpaymentAccount(paymentAccount)"
  >
    <h5>
      {{ paymentAccount.name }}
    </h5>

    <wm-modal-confirm
      *ngIf="showRemove"
      commandText="Remove"
      [showButtonText]="true"
      [moduleId]="paymentAccount.id"
      [title]="'Remove Payment Account'"
      (accepted)="removed.emit($event)"
      (click)="$event.stopPropagation()"
    >
      <h4>
        Are you sure you want to remove this payment account ({{
          paymentAccount.name
        }})?
      </h4>
      <div>
        <h4>Disabling this payment account will do the following:</h4>
        <ul>
          <li>
            Keep online payments from being completed for applications.
          </li>
        </ul>
      </div>
    </wm-modal-confirm>
  </li>
</ul>

import { Component, OnInit } from '@angular/core';
import { InspectionDocument } from 'src/app/models/inspection-document';
import { ToastrService } from 'ngx-toastr';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { DataService, WorkflowContextService } from 'src/app/services';
import { ActivatedRoute } from '@angular/router';
import { InspectionVM, InspectionStatus, Document } from 'src/app/models';
import {
  ItemSearchUtils,
  ItemSearchFilter,
  ItemSearchOption,
  ItemSearchOptionField,
  ItemSearchOptionFieldOption
} from '../../filter-list/models/filterClasses';
import { Utilities, WorkflowService } from 'src/app/services';

@Component({
  selector: 'wm-inspection-details',
  templateUrl: './inspection-details.component.html',
  styles: []
})
export class InspectionDetailsComponent implements OnInit {
  inspectionId: string;
  inspection: InspectionVM;
  InspectionStatus = InspectionStatus;
  form: UntypedFormGroup;
  documents: InspectionDocument[];
  staticFilters: ItemSearchFilter;
  constructor(
    private _route: ActivatedRoute,
    private _dataSvc: DataService,
    private _fb: UntypedFormBuilder,
    private _toastrSvc: ToastrService,
    private _context: WorkflowContextService
  ) {}

  ngOnInit() {
    this.form = this._fb.group({});
    this._route.params.subscribe(parms => {
      this.inspectionId = parms.inspectionId;

      this.staticFilters = new ItemSearchFilter({
        options: [
          new ItemSearchOption({
            field: new ItemSearchOptionField({
              title: 'inspectionId',
              options: [
                new ItemSearchOptionFieldOption({
                  title: 'inspectionId',
                  selected: true,
                  filterText: '',
                  searchText: this.inspectionId,
                  strOperator: ''
                })
              ]
            })
          })
        ]
      });
      const staticFields = this.staticFilters
        ? this.staticFilters.options.map(f => f.field)
        : null;
      const clientId = this._context.client ? this._context.client.id : null;
      this._dataSvc.getInspection(this.inspectionId).subscribe(inspectionVM => {
        if (inspectionVM != null) {
          this.inspection = inspectionVM;
        }

        if (this.inspection) {
          this.documents = this.inspection.documents;
        }
      });
    });
  }

  addNewDocuments(documents: Document[]) {
    const regDocs: InspectionDocument[] = documents.map(d => {
      const doc = new InspectionDocument();

      doc.documentName = d.name;
      doc.documentPath = d.path;
      doc.inspectionId = this.inspectionId;

      return doc;
    });

    this._dataSvc
      .saveInspectionDocuments(this.inspectionId, regDocs)
      .subscribe(docs => {
        this.documents = docs;
        this._toastrSvc.success('Document(s) Saved');
      });
  }

  loadDocuments() {
    this._dataSvc.getInspectionDocuments(this.inspectionId).subscribe(docs => {
      this.documents = docs;
    });
  }

  deleteDocument(documentId: string) {
    this._dataSvc.deleteInspectionDocument(documentId).subscribe(
      () => {
        this._toastrSvc.success('Document Deleted');
        this.loadDocuments();
      },
      err => {
        console.error(err);
      }
    );
  }

  saveDocument(document: InspectionDocument) {
    this._dataSvc.saveInspectionDocument(document).subscribe(doc => {
      this._toastrSvc.success('Document Saved');
    });
  }
}

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { User } from '../../../models';
import { SecurityService, WorkflowContextService } from '../../../services';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators
} from '@angular/forms';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';

@Component({
  selector: 'wm-user-search',
  templateUrl: './user-search.component.html',
  styleUrls: ['./user-search.component.css']
})
export class UserSearchComponent implements OnInit {
  @Output() searchStarted: EventEmitter<any> = new EventEmitter<any>();
  @Output() searchFinished: EventEmitter<User[]> = new EventEmitter<User[]>();
  @Input() searchText: string;
  form: UntypedFormGroup;

  constructor(
    private _securitySvc: SecurityService,
    private _context: WorkflowContextService,
    fb: UntypedFormBuilder
  ) {
    this.form = fb.group({ searchText: ['', Validators.nullValidator] });
    this.form.controls.searchText.valueChanges
      .pipe(
        debounceTime(400),
        distinctUntilChanged(),
        filter((query: string) => !query || query.length > 2)
      )
      .subscribe(a => {
        this.search();
      });
  }

  search() {
    if ((this.searchText || '') !== '') {
      this.searchStarted.next(null);
      this._securitySvc
        .searchUsers(this._context.client, { searchText: this.searchText })
        .subscribe(users => {
          this.searchFinished.next(users);
        });
    } else {
      this.searchFinished.next(null);
    }
  }

  ngOnInit() {
    if (this.searchText) {
      this.search();
    }
  }
}

<div class="form-group" *ngIf="entity">
  <wm-de-label [entity]="entity" [for]="entity.templateCode"></wm-de-label>
  <input
    type="text"
    readonly
    class="form-control-plaintext"
    [id]="entity.templateCode"
    [value]="entity.value"
  />
</div>

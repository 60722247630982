<app-navbar isAdminArea="true"></app-navbar>

<div
  class="bmd-layout-container bmd-drawer-f-l bmd-drawer-in-md-up"
  [ngClass]="sidebarClass"
  style="margin-top: 58.39px;"
>
  <div id="dw-s1" class="bmd-layout-drawer bg-faded admin-sidebar">
    <header>
      <a class="navbar-brand">
        {{ context.client?.name ? context.client?.name : 'Jurisdiction Name' }}
      </a>
    </header>
    <app-loader *ngIf="!menuItems"></app-loader>
    <ul class="list-group">
      <ng-container *ngFor="let mi of menuItems">
        <div *ngIf="mi.canView">
          <a
            *ngIf="mi.children"
            class="list-group-item"
            aria-expanded="false"
            [attr.aria-controls]="'#menu-' + mi.name"
            [routerLink]="getDefaultLink(mi)"
            [routerLinkActive]="['is-active']"
          >
            <i class="material-icons" *ngIf="mi.children">
              {{ activeRoute(mi.url) ? 'arrow_drop_up' : 'arrow_drop_down' }}
            </i>
            {{ mi.name }}
          </a>

          <a
            *ngIf="!mi.children"
            class="list-group-item"
            [href]="createUrl(mi.url)"
            [routerLinkActive]="['is-active']"
            target="_blank"
          >
            <span class="list-group-sub-item-placeholder"></span>
            {{ mi.name }}
          </a>

          <div
            class="collapse"
            [id]="'#menu-' + mi.name"
            [class.show]="activeRoute(mi.url)"
            *ngIf="mi.children"
          >
            <ng-container *ngFor="let smi of mi.children">
              <a
                class="list-group-item list-group-sub-item"
                [routerLink]="createUrl(smi.url)"
                [routerLinkActive]="['is-active']"
                *ngIf="smi.canView"
              >
                <span class="list-group-sub-item-placeholder"></span>
                {{ smi.name }}
              </a>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </ul>
    <div class="container">
      <div class="mb-3">
        <a
          [href]="createUrl(helpDocumentLink)"
          [routerLinkActive]="['is-active']"
          target="_blank"
          class="help-document-link d-flex align-self-center justify-content-center"
          *ngIf="canViewUserGuide"
        >
          <i class="material-icons help-icon">help</i>
          <span>User Guide</span>
        </a>
      </div>
    </div>
  </div>
  <header class="bmd-layout-header"></header>
  <main class="bmd-layout-content">
    <div class="container-fluid">
      <router-outlet></router-outlet>
    </div>
  </main>
</div>

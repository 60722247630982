import { Component, OnInit, ViewChild } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { ModalConfirmComponent } from 'src/app/components/system/modal-confirm/modal-confirm.component';
import { AgendaType } from 'src/app/models/agenda';
import {
  DataService,
  SecurityService,
  Utilities,
  WorkflowContextService
} from 'src/app/services';
import { CKEditorComponent } from '@ckeditor/ckeditor5-angular';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CkeditorUploadService } from 'src/app/services/ckeditor-upload.service';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { Actions } from 'src/app/models';

@Component({
  selector: 'wm-agenda-type-list',
  templateUrl: './agenda-type-list.component.html',
  styleUrls: ['./agenda-type-list.component.css']
})
export class AgendaTypeListComponent implements OnInit {
  clientId: string;
  form: UntypedFormGroup;
  agendaTypes: AgendaType[];
  newAgendaType: AgendaType;

  public Editor = ClassicEditor;

  public editorConfig = {
    toolbar: [
      'heading',
      '|',
      'bold',
      'italic',
      'link',
      '|',
      'bulletedList',
      'numberedList',
      'imageUpload',
      '|',
      'undo',
      'redo'
    ]
  };
  @ViewChild('headerEditor', { static: true }) headerEditor: CKEditorComponent;

  @ViewChild('newAgendaTypeModal', { static: true })
  newAgendaTypeModal: ModalConfirmComponent;

  constructor(
    private _context: WorkflowContextService,
    private _fb: UntypedFormBuilder,
    private _dataSvc: DataService,
    private _uploadAdapter: CkeditorUploadService,
    private _toastr: ToastrService,
    private _securitySvc: SecurityService
  ) {}

  ngOnInit() {
    this.clientId = this._context.client.id;

    this.initEmptyAgendaType();

    this.form = this._fb.group({
      name: this._fb.control('', [Validators.required]),
      description: this._fb.control('', [Validators.nullValidator]),
      header: this._fb.control('', [Validators.required]),
      defaultItemsNumber: this._fb.control('', [Validators.required])
    });

    this.loadAgendaTypes();
  }

  loadAgendaTypes() {
    this._dataSvc.getAgendaTypes(this._context.client.id).subscribe(result => {
      this.agendaTypes = result;
    });
  }

  initEmptyAgendaType() {
    this.newAgendaType = new AgendaType({
      id: Utilities.EMPTY_GUID,
      clientId: this.clientId,
      header: ''
    });
  }

  onModalCancel() {
    this.initEmptyAgendaType();
  }

  onModalSave() {
    this._dataSvc.saveAgendaType(this.newAgendaType).subscribe(() => {
      this.initEmptyAgendaType();
      this.loadAgendaTypes();
      this._toastr.success('Agenda Type Saved!');
    });
  }

  public editorReady(editor) {
    const parentId: string = this.newAgendaType.id;
    editor.plugins.get('FileRepository').createUploadAdapter = loader => {
      this._uploadAdapter.setLoader(loader, parentId);
      this._uploadAdapter.loader = loader;

      this._uploadAdapter.isUploading.subscribe((isUploading: boolean) => {
        if (this.form.controls['header-IS_UPLOADING']) {
          this.form.controls['$header-IS_UPLOADING'].setValue(
            isUploading ? 1 : 0
          );
        }
      });
      return this._uploadAdapter;
    };
  }

  canManageAgendas(): Observable<boolean> {
    return this._securitySvc.isLoginEntitled(Actions.MANAGE_AGENDAS);
  }
}

import { Component, OnInit } from '@angular/core';
import { WorkflowContextService } from '../../services';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'wm-register-view',
  templateUrl: './register-view.component.html',
  styleUrls: ['./register-view.component.css']
})
export class RegisterViewComponent implements OnInit {
  returnApplicationId: string;
  invitationId: string;

  constructor(
    public context: WorkflowContextService,
    private _route: ActivatedRoute
  ) {}

  ngOnInit() {
    this._route.params.subscribe(params => {
      if (params['returnApplicationId']) {
        this.returnApplicationId = params['returnApplicationId'];
      }
    });

    this._route.queryParams.subscribe(params => {
      if (params['invitationid']) {
        this.invitationId = params['invitationid'];
      }
    });
  }
}

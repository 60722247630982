import { Router } from '@angular/router';
import { Component, OnInit, Input } from '@angular/core';
import { WorkflowService } from 'src/app/services';
import { StartApplicationResponse } from 'src/app/models/start-application-response';
import { RegistrationVM } from 'src/app/models/registration';

@Component({
  selector: 'wm-renewal-item',
  templateUrl: './renewal-item.component.html',
  styleUrls: ['./renewal-item.component.css']
})
export class RenewalItemComponent implements OnInit {
  @Input() registration: RegistrationVM;
  saving = false;

  constructor(private _workflowSvc: WorkflowService, private _router: Router) {}

  startRenewal() {
    this.saving = true;
    this._workflowSvc
      .startRegistrationRenewal(this.registration.id)
      .subscribe((applicationResponse: StartApplicationResponse) => {
        const navOptions = [
          '/application/workflow-application',
          applicationResponse.applicationId
        ];
        this._router.navigate(navOptions);
      });
  }
  ngOnInit() {}
}

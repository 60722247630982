import { DataEntity } from 'src/app/models/data-entities';
import { ContractorRequirementType, ContractorType, Client } from './';

// This is the object that defines the contractor requirement types for a contractor type
export class ContractorTypeRequirement {
  id: string;
  contractorTypeId: string;
  contractorType: ContractorType;
  isEditing: boolean;
  label: string;
  templateCode: string;
  dataEntityDefinition?: DataEntity;
  isContractorRequirement: boolean;

  constructor(options?: Partial<ContractorTypeRequirement>) {
    if (options) {
      Object.assign(this, options);
    }
  }
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'wm-user-editor',
  templateUrl: './user-editor.component.html',
  styleUrls: ['./user-editor.component.css']
})
export class UserEditorComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login-view',
  templateUrl: './login-view.component.html',
  styleUrls: ['./login-view.component.css']
})
export class LoginViewComponent implements OnInit {
  constructor(private _router: Router) {}

  ngOnInit() {}

  loggedIn(redirectUrl?: string) {
    if (redirectUrl) {
      this._router.navigateByUrl(redirectUrl);
    }
  }
}

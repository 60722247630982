import { Component, OnInit } from '@angular/core';
import { ActivityEditorBaseComponent } from '../../activity-editor-base/activity-editor-base.component';

@Component({
  selector: 'wm-complete-inspection-activity-editor',
  templateUrl: './complete-inspection-activity-editor.component.html',
  styleUrls: ['./complete-inspection-activity-editor.component.css']
})
export class CompleteInspectionActivityEditorComponent
  extends ActivityEditorBaseComponent
  implements OnInit {
  constructor() {
    super();
  }

  ngOnInit() {}
}

import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse
} from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { JsonNetDecycle } from './utilities/json-net-decycle';

@Injectable()
export class JsonNetInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const origObject: any = request.body;
    let decycledObject: any;

    if (
      origObject == null ||
      origObject['$id'] ||
      origObject instanceof FormData
    ) {
      // already decycled or its null, don't do it again
      decycledObject = origObject;
    } else {
      // decycle the graph
      decycledObject = JsonNetDecycle.decycle(origObject);
    }

    const newRequest = request.clone({
      body: decycledObject
    });

    return next.handle(newRequest).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          // mess with response here:
          // if the body has the $id or the body is an array and the first element has the $id, then retrocycle the result
          // I'm going to keep the scope of retrocycling to only those things we know since it would have a greater impact of things we don't know
          if (
            event.body &&
            (event.body['$id'] ||
              (Array.isArray(event.body) &&
                event.body.length > 0 &&
                event.body[0]['$id']))
          ) {
            const body = JsonNetDecycle.retrocycle(event.body);
            event = event.clone({
              body: body
            });
          }
        }
        return event;
      })
    );
  }
}

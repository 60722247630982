<div class="row">
  <div class="col">
    <h3>Custom Lists</h3>
  </div>
  <div class="col-2">
    <button
      class="btn btn-raised btn-primary float-right"
      (click)="addCustomList()"
    >
      Add Custom List
    </button>
  </div>
</div>

<app-custom-lists-list [customLists]="customLists"></app-custom-lists-list>

<div *ngFor="let de of entities">
  <wm-data-entity-input
    *ngIf="!isPreview"
    [entity]="de.entity"
    [form]="form"
    [applicationId]="applicationId"
    [activity]="activity"
    #dataEntityInput
  ></wm-data-entity-input>
</div>

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators
} from '@angular/forms';
import { BulkAction } from '../../../../system/datatable/datatable.component';
import {
  Activity,
  ActivityModel,
  DataEntityLayoutModel,
  FormActivity,
  SingleColumnFormLayoutModel
} from '../../../../../models/activities';
import { Workflow } from '../../../../../models';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  DataEntityFactory,
  WorkflowContextService,
  WorkflowService
} from '../../../../../services';
import { customAlphabet } from 'nanoid';
import { ParentReference } from '../../../../../models/data-entities';
import * as _ from 'lodash';

export interface MoveDataEntityConfig {
  bulkAction: BulkAction;
  activityId: string;
}
const nanoid = customAlphabet('0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ', 6);

@Component({
  selector: 'wm-form-activity-data-entities-bulk-actions',
  templateUrl: './form-activity-data-entities-bulk-actions.component.html',
  styleUrls: ['./form-activity-data-entities-bulk-actions.component.css']
})
export class FormActivityDataEntitiesBulkActionsComponent implements OnInit {
  activityForm: FormGroup;
  moveForm: FormGroup;
  selectedDEs = {};
  selectedActionType = 'move';
  selected = false;
  @Input() public dataEntities: DataEntityLayoutModel[] = [];
  @Input() workflow: Workflow;
  @Input() activity: Activity<ActivityModel>;
  @Output() saveActivity: EventEmitter<
    Activity<ActivityModel>
  > = new EventEmitter<Activity<ActivityModel>>();

  public bulkActions: BulkAction[] = [];

  get moveLabel() {
    if (this.moveForm.controls['bulkAction'].value) {
      return this.moveForm.controls['bulkAction'].value.id === 'move'
        ? 'Move'
        : 'Copy';
    }
    return 'Copy';
  }

  opened = false;

  constructor(
    private _fb: FormBuilder,
    private modalService: NgbModal,
    public context: WorkflowContextService,
    public _workflowSvc: WorkflowService
  ) {
    this.activityForm = this._fb.group({});
  }
  onOpen() {
    this.initSelectedDes();

    this.opened = true;

    for (const key in this.selectedDEs) {
      if (this.selectedDEs[key]) {
        this.selectedDEs[key].selected = false;
      }
    }
  }
  onCancel() {
    this.opened = false;

    for (const key in this.selectedDEs) {
      if (this.selectedDEs[key]) {
        this.selectedDEs[key].selected = false;
      }
    }
  }
  onSave() {
    this.opened = false;
  }

  async doBulkAction() {
    const v = this.moveForm.value as MoveDataEntityConfig;
    await v.bulkAction.action([]);
  }
  isEntitySelected(id) {}
  toggleEntity(event, de) {
    const isChecked: boolean = event.target['checked'];
    const templateCode: string = event.target['value'];
    this.selectedDEs[templateCode] = { selected: isChecked, de: de };
  }
  selectOneResult(p: any, v: any) {}
  initBulkActions() {
    this.bulkActions.push({
      name: 'Copy',
      id: 'copy',
      action: async boolean => {
        this.move();
        return false;
      }
    });
    this.bulkActions.push({
      name: 'Move',
      id: 'move',
      action: async boolean => {
        this.move();
        return false;
      }
    });
  }
  get formActivities() {
    return this.workflow.version.graph
      .getActivities(this.workflow.designStatus)
      .filter(
        a =>
          a instanceof FormActivity &&
          this.moveForm.controls['bulkAction'].value != null &&
          (this.moveForm.controls['bulkAction'].value.id === 'copy' ||
            a.id !== this.activity.id)
      );
  }
  initSelectedDes() {
    this.selectedDEs = {};

    // remove System DE's from entities that can be copied.
    this.dataEntities = this.dataEntities.filter(de => {
      return !de.entity.isSystemEntity;
    });

    this.dataEntities.forEach(de => {
      if (de && de.entity && de.entity.templateCode) {
        this.selectedDEs[de.entity.templateCode] = { selected: false };
      }
    });
  }
  ngOnInit() {
    this.activityForm = new FormGroup({});

    this.initBulkActions();
    this.initSelectedDes();
    this.moveForm = new FormGroup({
      activityId: new FormControl(null, [Validators.required]),
      bulkAction: new FormControl(this.bulkActions[1], [Validators.required]),
      selected: new FormControl(null)
    });
  }
  dismiss() {
    this.modalService.dismissAll('cancel');
  }
  getFormModel(activity: FormActivity): SingleColumnFormLayoutModel {
    return activity.model.formLayoutModel as SingleColumnFormLayoutModel;
  }
  createCopy(
    newActivity: FormActivity,
    moveConfig: MoveDataEntityConfig,
    de: any
  ) {
    const newDe = DataEntityFactory.createDataEntity(
      de.entity.dataEntityTypeCode,
      _.cloneDeep(de.entity)
    );
    if (newDe) {
      newDe.parent = new ParentReference({ id: newActivity.id });
      if (moveConfig.bulkAction.id === 'copy') {
        newDe.templateCode = `${newDe.templateCode}_COPY_${nanoid()}`;
      }
    }
    return newDe;
  }

  move() {
    const moveConfig = this.moveForm.value;
    this._workflowSvc
      .getActivityEditor(
        this.workflow.id,
        moveConfig.activityId,
        !this.workflow.version.isDraft ? this.workflow.version.id : null
      )
      .subscribe(activity => {
        const newActivity: FormActivity = activity as FormActivity;
        const newFormModel = this.getFormModel(newActivity);
        for (const key in this.selectedDEs) {
          if (this.selectedDEs[key]) {
            if (this.selectedDEs[key].selected === true) {
              const de = this.selectedDEs[key].de;
              const newDe = this.createCopy(newActivity, moveConfig, de);
              //       order so the largest displayOrder is first
              newDe['allowTemplateCodeEdit'] = true;

              const orderedEntities = newFormModel.columnEntities.sort(
                (a, b) => b.displayOrder - a.displayOrder
              );

              newFormModel.columnEntities.push(
                new DataEntityLayoutModel({
                  entity: newDe || undefined,
                  displayOrder:
                    orderedEntities && orderedEntities.length > 0
                      ? orderedEntities[0].displayOrder + 1
                      : 0
                })
              );

              if (moveConfig.bulkAction.id === 'move') {
                const oldFormModel = this.getFormModel(
                  this.activity as FormActivity
                );
                oldFormModel.columnEntities = oldFormModel.columnEntities.filter(
                  a => a.entity.templateCode !== de.entity.templateCode
                );
              }
            }
          }
        }
        // trigger a save for the destination activity if we are copying/moving to another activity.
        this.saveActivity.emit(newActivity);

        // trigger a save for the current activity if we are moving to another activity.
        if (
          newActivity.id !== this.activity.id &&
          moveConfig.bulkAction.id === 'move'
        ) {
          this.saveActivity.emit(this.activity);
        }

        // all done!
        this.resetMoveForm();
        this.modalService.dismissAll('saved');
      });
  }
  selectedDe(templateCode) {
    console.log(this.selectedDEs[templateCode]);
    return this.selectedDEs[templateCode];
  }
  resetMoveForm() {
    this.initSelectedDes();
    this.moveForm.patchValue({ bulkActions: this.bulkActions[1] });
  }
}

<form [formGroup]="form" novalidate>
  <div
    class="form-group"
    [class.has-error]="
      form.controls['minimumValue'].touched &&
      !form.controls['minimumValue'].valid
    "
  >
    <label class="bmd-label-floating" for="minimumValue">
      Minimum
    </label>
    <input
      type="number"
      class="form-control"
      [(ngModel)]="numericDE.minimumValue"
      formControlName="minimumValue"
      name="minimumValue"
    />
    <wm-control-message [control]="form.controls['minimumValue']">
    </wm-control-message>
  </div>

  <div
    class="form-group"
    [class.has-error]="
      form.controls['maximumValue'].touched &&
      !form.controls['maximumValue'].valid
    "
  >
    <label class="bmd-label-floating" for="maximumValue">
      Maximum
    </label>
    <input
      type="number"
      class="form-control"
      [(ngModel)]="numericDE.maximumValue"
      formControlName="maximumValue"
      name="maximumValue"
      for="maximumValue"
    />
    <wm-control-message [control]="form.controls['maximumValue']">
    </wm-control-message>
  </div>

  <div
    class="form-group"
    [class.has-error]="
      form.controls['decimalPlaces'].touched &&
      !form.controls.decimalPlaces.valid
    "
  >
    <label class="bmd-label-floating" for="decimalPlaces">
      Required decimal places
    </label>
    <input
      type="number"
      class="form-control"
      [(ngModel)]="numericDE.decimalPlaces"
      formControlName="decimalPlaces"
      name="decimalPlaces"
      min="0"
    />
    <wm-control-message [control]="form.controls['decimalPlaces']">
    </wm-control-message>
  </div>

  <div class="form-group">
    <label class="bmd-label-floating">
      Type
    </label>
    <select
      class="form-control"
      formControlName="type"
      [(ngModel)]="numericDE.type"
    >
      <option *ngFor="let t of types" [ngValue]="t.value">
        {{ t.label }}
      </option>
    </select>
  </div>

  <wm-external-data-source-config
    [entity]="dataEntity"
    [form]="form"
    (externalSourceUpdated)="externalDataSourceChanged($event)"
  ></wm-external-data-source-config>

  <div class="text-danger" *ngIf="!form.valid">
    <div *ngFor="let e of getErrors()">
      <p class="text-danger">
        {{ e }}
      </p>
    </div>
  </div>
</form>

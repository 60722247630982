import { Component, OnInit } from '@angular/core';
import { Client, Actions } from '../../../../models';
import {
  WorkflowContextService,
  DataService,
  SecurityService
} from '../../../../services';
import { Router } from '@angular/router';

@Component({
  selector: 'wm-client-list-view',
  templateUrl: './client-list.component.html',
  styleUrls: ['./client-list.component.css']
})
export class ClientListViewComponent implements OnInit {
  clients: Client[];

  //VIEW_PERMISSION_ID: string = Actions.VIEW_CLIENTS; //'A324BBA0-3840-452A-807C-E8CF8C639260';

  constructor(
    private _context: WorkflowContextService,
    private _service: DataService,
    private _router: Router,
    private _securitySvc: SecurityService
  ) {}

  canAddJurisdiction: boolean;

  ngOnInit() {
    this._service.getAdminClients(false).subscribe(res => {
      // if(res.length == 1) {
      //   this._router.navigate(['/admin/jurisdiction', res[0].id]);
      // }
      // else {
      this.clients = res;
      //}
    });
    this._securitySvc
      .isLoginEntitled(Actions.CLIENTS_MANAGE, null, null, true)
      .subscribe(a => {
        this.canAddJurisdiction = a;
      });
  }

  removeClient(e: Client) {
    const index = this.clients.indexOf(e);
    this.clients.splice(index, 1);
  }

  addClient() {
    this._router.navigate(['/admin/global/jurisdictions/add']);
  }
}

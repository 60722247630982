import { ToastrService } from 'ngx-toastr';
import { Client } from './../../../models/client';
import { DataTableHelpers } from './../../system/datatable/datatable-helper';
import { map } from 'rxjs/operators';
import {
  Component,
  OnInit,
  ViewChild,
  Output,
  Input,
  EventEmitter,
  OnDestroy,
  ChangeDetectorRef,
  TemplateRef
} from '@angular/core';
import { ActivityModel, Activity } from 'src/app/models/activities';
import { ModalConfirmComponent } from '../../system/modal-confirm/modal-confirm.component';
import {
  ItemSearchOptionField,
  ItemSearchFilter,
  ItemSearchUtils
} from '../../filter-list/models/filterClasses';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import {
  SystemService,
  WorkflowService,
  WorkflowContextService,
  ValidationService
} from 'src/app/services';
import { Subscription } from 'rxjs';
import {
  PagedInspectionsVM,
  InspectionVM,
  InspectionRequestTod,
  InspectionStatus,
  InspectionStatusDict
} from 'src/app/models';
import { MapDataRequest } from '../../arcgis/basic-esri-map/basic-esri-map.component';
import { TableColumn } from '@swimlane/ngx-datatable';
import { FilterBuilderParam } from 'src/app/models/filter-builder';
import * as moment from 'moment';
import { ColumnOption, GridSettings } from 'src/app/models/grid-settings';

@Component({
  selector: 'wm-inspection-queue',
  templateUrl: './inspection-queue.component.html'
  // styles: ['./inspection-queue.component.css']
})
export class InspectionQueueComponent implements OnInit, OnDestroy {
  @Input() public id: string;
  @Input() private staticFilters: ItemSearchFilter;
  @Input() public columns: TableColumn[];
  @Input() public client: Client;
  @Input() public sortField: string;
  @Input() public sortDescending: boolean;
  @Output() private updateStatus = new EventEmitter();
  @Output() private dataLoaded = new EventEmitter<string>();
  @Output() recordCountSet = new EventEmitter<number>();
  @Output() filtersChanged = new EventEmitter<boolean>();
  @ViewChild('appIdTmpl', { static: true }) private appIdTmpl: TemplateRef<any>;
  @ViewChild('scheduledOnTmpl', { static: true })
  private scheduledOnTmpl: TemplateRef<any>;
  @ViewChild('actionsTmpl', { static: true }) private actionsTmpl: TemplateRef<
    any
  >;
  @ViewChild('CompleteInspectionActivity', { static: true })
  private completeInspectionModal: ModalConfirmComponent;

  public filterOptions: ItemSearchOptionField[] = [];
  private q: MapDataRequest;
  public activityForm: UntypedFormGroup;
  public detailActivity: Activity<ActivityModel>;
  private clientSub$: Subscription;
  public filterCriteria: FilterBuilderParam[] = [];

  public page = {
    // The number of elements in the page
    size: 10,
    // The total number of elements
    totalElements: 0,
    // The total number of pages
    totalPages: 0,
    // The current page number
    pageNumber: 0
  };

  unpermissibleCount = 0;

  public loading = true;

  private workflowApplicationId: string;
  public activity: Activity<ActivityModel>;
  public inspectionRequests: any[];
  private isDestroyed = false;
  columnsWithExtra: TableColumn[] = [];
  availableColumns: TableColumn[];
  columnOptions: ColumnOption[] = [];
  exportColumns: string[];
  public useExcelFormat: boolean;

  // column prop strings to be hidden on the grid by default
  defaultHiddenColumns: string[] = [];
  // column prop strings that should have no option to hide in the grid, and are not exportable (such as button columns like Refund or Actions)
  actionColumns: string[] = ['actions'];
  // column prop strings to be excluded from exports by default
  defaultExportExludedColumns: string[] = [];

  constructor(
    private systemSvc: SystemService,
    private _router: Router,
    private _workflowSvc: WorkflowService,
    private _context: WorkflowContextService,
    private _fb: UntypedFormBuilder,
    private dtHelpers: DataTableHelpers,
    private ref: ChangeDetectorRef,
    private toast: ToastrService
  ) {
    const statuses = new InspectionStatusDict();
    const statusOptions = [];
    for (const status in statuses) {
      if (statuses[status]) {
        statusOptions.push({
          name: statuses[status],
          value: InspectionStatus[status]
        });
      }
    }

    this.filterCriteria = [
      {
        id: 'applicationNumber',
        name: 'Application Number'
      },
      {
        id: 'scheduledOn',
        name: 'Scheduled Date',
        inputType: 'date',
        types: ['is', 'range']
      },
      {
        id: 'requestedOn',
        name: 'Requested Date',
        inputType: 'date',
        types: ['is', 'range']
      },
      {
        id: 'inspectionType',
        name: 'Inspection Type',
        types: ['is', 'contains']
      },
      {
        id: 'address',
        name: 'Address'
      },
      {
        id: 'city',
        name: 'City'
      },
      {
        id: 'status',
        name: 'Status',
        types: ['is', 'in'],
        options: statusOptions
      },
      {
        name: 'Description',
        id: 'applicationDescription',
        types: ['is', 'contains']
      },
      {
        id: 'inspectorId',
        name: 'Inspector',
        options: [
          {
            name: 'None',
            value: 'NULL'
          }
        ]
      },
      {
        id: 'parcelId',
        name: 'Parcel ID'
      }
    ];
  }

  mapPopupActions = [
    {
      // This text is displayed as a tooltip
      title: 'View Application',
      // The ID by which to reference the action in the event handler
      id: 'view-application',
      // Sets the icon font used to style the action button
      className: 'esri-icon-zoom-out-magnifying-glass',
      // Handler of the action
      handler: item => {
        // navigate to the application
        this._router.navigate([
          `/application/workflow-application/${item.workflowApplicationId}`
        ]);
      }
    }
  ];

  setColumns(columns: TableColumn[]) {
    this.columnsWithExtra = [
      {
        prop: 'permitNumber',
        name: 'Application',
        cellTemplate: this.appIdTmpl
      },
      {
        prop: 'applicationName',
        name: 'Application Type'
      },
      ...this.columns,
      ...columns,
      {
        prop: 'actions',
        name: 'Actions',
        cellTemplate: this.actionsTmpl
      }
    ];
    this.setAvailableColumns();
  }

  setAvailableColumns() {
    this.availableColumns = this.columnsWithExtra;
  }

  handleSettingsChanged(event: GridSettings) {
    this.filterOptions = event.legacyFilters;
    this.page.size = event.pageSize;
    this.page.pageNumber = event.pageNumber;
    this.sortField = event.sortField;
    this.sortDescending = event.sortDescending;
    this.columnOptions = event.columnOptions;

    const visibleColumnProps = this.columnOptions
      .filter(co => co.checked)
      .map(co => co.name);

    this.columnsWithExtra = this.availableColumns.filter(
      c =>
        visibleColumnProps.includes(c.prop as string) ||
        this.actionColumns.includes(c.prop as string)
    );

    this.exportColumns = this.columnOptions
      .filter(co => co.includeInExport)
      .map(co => co.name);

    this.loadInspections().subscribe();
    this.filtersChanged.emit(true);
  }

  ngOnInit() {
    // this is initialized in OnInit so that the cell templates exist before trying to render it
    this.columns = this.columns || [
      {
        prop: 'address',
        name: 'Address'
      },
      {
        prop: 'city',
        name: 'City'
      },
      {
        prop: 'inspectionType',
        name: 'Inspection Type'
      },
      {
        prop: 'requestedOn',
        name: 'Requested Date',
        pipe: this.dtHelpers.getDatePipe()
      },
      {
        prop: 'scheduledOn',
        name: 'Scheduled Time',
        cellTemplate: this.scheduledOnTmpl
      },
      {
        prop: 'inspectorName',
        name: 'Assigned Inspector'
      },
      {
        prop: 'requestorName',
        name: 'Requestor Name'
      },
      {
        prop: 'requestorPhoneNumber',
        name: 'Requestor Phone'
      },
      {
        prop: 'applicationDescription',
        name: 'Application Description'
      },
      {
        prop: 'inspectionStatus',
        name: 'Status'
      }
    ];
    this.setColumns([]);

    this.loadData();
  }

  ngOnDestroy() {
    this.isDestroyed = true;
    this.ref.detach();
    if (this.clientSub$) {
      this.clientSub$.unsubscribe();
    }
  }

  private loadData() {
    this._workflowSvc
      .getUniqueInspectorIds(this.client ? this.client.id : null)
      .subscribe(data => {
        const inspectorFilter = this.filterCriteria.find(
          filter => filter.id === 'inspectorId'
        );

        if (inspectorFilter) {
          for (const id in data) {
            if (data[id]) {
              inspectorFilter.options.push({
                name: data[id],
                value: id
              });
            }
          }
        }
      });
  }

  public viewDetails(inspectionId: string) {
    const inspection = this.inspectionRequests.find(
      (i: InspectionVM) => i.id === inspectionId
    );

    this._router.navigate([
      '/admin/jurisdiction',
      inspection.clientId,
      'inspections',
      'details',
      inspectionId
    ]);
  }

  private emitDataLoaded() {
    this.dataLoaded.emit('InspectionQueueComponent');
  }

  public processInspectionActivity(
    workflowApplicationId: string,
    inspectionId: string,
    actionType: string
  ) {
    this.workflowApplicationId = workflowApplicationId;
    this._workflowSvc
      .getInspectionActivity(workflowApplicationId, inspectionId, actionType)
      .subscribe(activityResp => {
        if (activityResp) {
          this.activityForm = this._fb.group(
            ValidationService.createValidationGroup(activityResp)
          );
          this.activity = activityResp;
          this.completeInspectionModal.moduleId = this.activity.id;
          this.completeInspectionModal.open();
          this.ref.detectChanges();
        } else {
          this.toast.info(
            'The action you requested is not available for this inspection.',
            'Action unavailable.'
          );
        }
      });
  }

  public completeInspectionActivity(evt: any) {
    this._workflowSvc
      .submitWorkflowActivity(this.workflowApplicationId, this.activity, false)
      .subscribe(async a => {
        this.loadInspections().subscribe();
        this.updateStatus.emit();
      });
  }

  public onMapDataRequested(q: MapDataRequest) {
    this.q = q;
    this.loadInspections().subscribe(inspections => {
      if (this.q) {
        const inspectionMapData = inspections.map(insp => {
          return {
            geometryWkts: insp.geometryWkts,
            data: {
              id: insp.id,
              permitNumber: insp.permitNumber,
              requestedOn: moment(insp.requestedOn).format('MM/DD/YYYY'),
              aMpM: moment(insp.requestedOn).format('hh:mm A'),
              workflowApplicationId: insp.workflowApplicationId
            }
          };
        });

        this.q.gimmeTheData$.emit(inspectionMapData);
        this.q = null;
      }
    });
  }

  private detectChanges() {
    if (!this.isDestroyed) {
      this.ref.detectChanges();
    }
  }

  public showTime(scheduledOn, inspection) {
    const date = new Date(scheduledOn);

    // if scheduledOnIncludesTime is an actual value, use it
    if (inspection.scheduledOnIncludesTime === true) {
      return true;
    } else if (inspection.scheduledOnIncludesTime === false) {
      return false;
    }

    // otherwise, make a guess
    // if the UTC hours, minutes and seconds are all zero, then there probably is not actually a time to show
    return (
      date.getUTCHours() + date.getUTCMinutes() + date.getUTCSeconds() !== 0
    );
  }

  public loadInspections() {
    this.loading = true;
    this.detectChanges();

    const staticFields = this.staticFilters
      ? this.staticFilters.options.map(f => f.field)
      : null;
    const filter = this.filterOptions
      ? ItemSearchUtils.mergeFilters(this.filterOptions, staticFields)
      : staticFields;

    const allFilters = filter ? filter : [];
    const mapextent = this.q ? this.q.ext : null;

    const clientId = this.client ? this.client.id : null;

    return this._workflowSvc
      .searchInspections(
        clientId,
        this.sortField,
        this.sortDescending,
        allFilters,
        this.page.pageNumber,
        this.page.size,
        mapextent
      )
      .pipe(
        map(result => {
          this.page.totalElements = result.recordCount;
          this.unpermissibleCount = result.unpermissibleCount;
          const pagedInspections: PagedInspectionsVM = new PagedInspectionsVM(
            result
          );

          const inspections = pagedInspections.inspections.map(i => {
            i = new InspectionVM(i);
            i['city'] = i.requestorAddress.city;
            i['address'] = i.requestorAddress.address1;
            i['aMpM'] = InspectionRequestTod[i.reqTOD];
            i['scheduledOn'] = i.scheduledOn;
            i['inspectionType'] = i.inspectionType;
            i['inspectionStatus'] = new InspectionStatusDict()[
              InspectionStatus[i.status]
            ];
            i['clientId'] = i.clientId;
            i['parcelId'] = i.parcelId;
            i['applicationName'] = i.applicationName;
            i['applicationDescription'] = i.workflowApplication.description;
            return i;
          });

          this.inspectionRequests = inspections;
          this.loading = false;
          this.recordCountSet.emit(this.page.totalElements);
          this.emitDataLoaded();

          return inspections;
        })
      );
  }

  exportExcel() {
    this.useExcelFormat = true;
    this.export();
  }

  exportCsv() {
    this.useExcelFormat = false;
    this.export();
  }

  export() {
    const staticFields = this.staticFilters
      ? this.staticFilters.options.map(f => f.field)
      : null;
    const filter = this.filterOptions
      ? ItemSearchUtils.mergeFilters(this.filterOptions, staticFields)
      : staticFields;

    const allFilters = filter ? filter : [];
    const mapextent = this.q ? this.q.ext : null;

    const clientId = this.client ? this.client.id : null;

    this.systemSvc
      .exportInspectionQueue(
        clientId,
        this.sortField,
        this.sortDescending,
        allFilters,
        this.page.pageNumber,
        this.page.size,
        mapextent,
        this.useExcelFormat,
        this.exportColumns
      )
      .subscribe(
        data => {
          if (this.useExcelFormat) {
            const blob = new Blob([data], {
              type:
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            });
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = `inspection-queue-export-${new Date().getTime()}.xlsx`;
            link.click();
          } else {
            const blob = new Blob([data], { type: 'text/csv' });
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = `inspection-queue-export-${new Date().getTime()}.csv`;
            link.click();
          }
        },
        err => {
          this.toast.error('Problem while downloading the file.');
          console.error(err);
        }
      );
  }
}

<app-loader *ngIf="!contacts"></app-loader>

<div *ngIf="contacts && contacts.length === 0">
  <p>No contacts to display</p>
</div>

<table class="table mt-2" *ngIf="contacts && contacts.length > 0">
  <thead>
    <tr>
      <th scope="col">Name</th>
      <th scope="col">Phone Number</th>
      <th scope="col">Title</th>
      <th scope="col">Actions</th>
    </tr>
  </thead>
  <tbody
    dragula="contacts"
    [(dragulaModel)]="contacts"
    (dragulaModelChange)="orderChange()"
  >
    <tr *ngFor="let contact of contacts">
      <th scope="row" style="cursor: move;">
        {{ contact.firstName }} {{ contact.lastName }}
      </th>
      <td>{{ contact.phoneNumber | phone }}</td>
      <td>{{ contact.title }}</td>
      <td>
        <button class="btn btn-primary btn-sm" (click)="editContact(contact)">
          Edit
        </button>
      </td>
    </tr>
  </tbody>
</table>

<form [formGroup]="form" *ngIf="config">
  <div class="form-group">
    <label class="bmd-label-floating" for="productId">
      Product ID
    </label>
    <input
      type="text"
      class="form-control"
      formControlName="productId"
      name="productId"
      [(ngModel)]="config.productId"
    />
  </div>
  <div class="form-group">
    <label class="bmd-label-floating" for="clientCode">
      Client Code
    </label>
    <input
      type="text"
      class="form-control"
      formControlName="clientCode"
      name="clientCode"
      [(ngModel)]="config.clientCode"
    />
  </div>
  <!-- area to add a list of parameters -->
  <div class="card col-6">
    <div class="card-body">
      <h5>Configured Product Parameters</h5>
      <h6>
        <i
          >The parameters listed below will be added to the product tag that is
          sent to Point N Pay</i
        >
      </h6>
      <div>
        example:
        <code
          ><pre>{{ example }}</pre></code
        >
      </div>
      <table class="table">
        <tr>
          <td>Parameter Name</td>
        </tr>
        <tr *ngFor="let p of config.parameterNames; let idx = index">
          <td>
            <input
              type="text"
              class="form-control"
              [(ngModel)]="config.parameterNames[idx]"
              [formControlName]="'param-' + idx"
            />
          </td>
          <td>
            <wm-modal-confirm
              [showCommandText]="true"
              [commandText]="'Yes'"
              [cancelText]="'No'"
              [showButtonText]="true"
              [showButton]="true"
              [btnIcon]="'delete'"
              [title]="'Remove Parameter'"
              [buttonClass]="'btn btn-sm'"
              class="mr-1"
              (accepted)="removeParameter(p)"
            >
              <p>
                Are you sure you want to delete this parameter?
              </p>
            </wm-modal-confirm>
          </td>
        </tr>
        <tr>
          <td>
            <button class="btn btn-primary btn-raised" (click)="addParameter()">
              Add Parameter
            </button>
          </td>
        </tr>
      </table>
    </div>
  </div>
</form>

import { Injectable, EventEmitter } from '@angular/core';
import { UploadService, UploadProgress } from './upload/upload.service';

@Injectable({
  providedIn: 'root'
})
export class CkeditorUploadService {
  loader: any;
  parentId: string;
  public isUploading: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private uploadSvc: UploadService) {}

  public setLoader(loader: any, parentId: string) {
    this.loader = loader;
    this.parentId = parentId;
  }

  // Starts the upload process.
  public async upload() {
    this.isUploading.emit(true);
    let genericErrorText: string;
    try {
      const file = await this.loader.file;
      genericErrorText = `Couldn't upload file: ${file.name}.`;

      const files = new Set<File>();
      files.add(file);
      const status = this.uploadSvc.upload(files, this.parentId);

      const fileStatus = status[file.name].progress;

      fileStatus.subscribe((data: UploadProgress) => {
        this.loader.uploadTotal = data.uploadTotal;
        this.loader.uploaded = data.uploaded;
      });

      const resp = await fileStatus.toPromise();

      return { default: resp.path };
    } catch (err) {
      let errorMessage = genericErrorText;

      if (err && err.error) {
        errorMessage = err.error.message;
      } else if (err && err.message) {
        errorMessage = err.message;
      }

      if (err.status === 413) {
        errorMessage = 'The file you uploaded is too large.';
      }

      throw new Error(errorMessage);
    } finally {
      this.isUploading.emit(false);
    }
  }

  // Aborts the upload process.
  public abort() {}

  public getAdapter() {
    return {
      abort: this.abort.bind(this),
      upload: this.upload.bind(this)
    };
  }
}

import { WorkflowService } from 'src/app/services';
import { ActivityStatus } from './../../../../../models/activities/activity';
import { ActivityView } from 'src/app/views/master-views/app.view/app.view.component';
import { Component, OnInit, forwardRef, Inject } from '@angular/core';
import { InitiateWorkflowActivity } from 'src/app/models/activities/initiate-workflow-activity';

@Component({
  selector: 'wm-call-workflow-input',
  templateUrl: './initiate-workflow-input.component.html',
  styleUrls: ['./initiate-workflow-input.component.css']
})
export class InitiateWorkflowInputComponent extends ActivityView
  implements OnInit {
  initiateActivity: InitiateWorkflowActivity;
  workflowName: string;

  constructor(
    @Inject(forwardRef(() => WorkflowService))
    private workflowSvc: WorkflowService
  ) {
    super();
  }
  ActivityStatus = ActivityStatus;

  launchChild() {
    if (this.initiateActivity.model.callingWorkflowApplicationId) {
      window.open(
        `/application/workflow-application/${this.initiateActivity.model.callingWorkflowApplicationId}`,
        '_blank'
      );
    }
  }

  ngOnInit() {
    this.initiateActivity = this.activity as InitiateWorkflowActivity;

    if (this.initiateActivity.model.targetWorkflowVersionId) {
      this.workflowSvc
        .getWorkflowVersion(
          this.initiateActivity.model.targetWorkflowVersionId,
          false
        )
        .subscribe(version => {
          this.workflowName = version && version.version.name;

          const serverDefaultHelpText =
            'Click Open to begin the application or click Next to continue on this application.';
          if (
            !this.initiateActivity.model.screenHelpText ||
            this.initiateActivity.model.screenHelpText.trim().toLowerCase() ===
              serverDefaultHelpText.trim().toLowerCase()
          ) {
            this.initiateActivity.model.screenHelpText = `Click "Open ${this.workflowName}" to begin the application or click Next to continue on this application.`;
          }
        });
    }
  }
}

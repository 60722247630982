<form [formGroup]="form">
  <p>List Type</p>

  <div *ngIf="yesnoEntity">
    <div class="radio">
      <label>
        <input
          formControlName="controlType"
          [(ngModel)]="yesnoEntity.controlType"
          id="rdoRdo"
          type="radio"
          value="radio"
        />
        Radio
      </label>
    </div>

    <div class="radio">
      <label>
        <input
          formControlName="controlType"
          [(ngModel)]="yesnoEntity.controlType"
          id="rdoChk"
          type="radio"
          value="checkbox"
        />
        Checkbox
      </label>
    </div>

    <div class="radio">
      <label>
        <input
          formControlName="controlType"
          [(ngModel)]="yesnoEntity.controlType"
          id="rdoCbo"
          type="radio"
          value="combo"
        />
        Drop Down
      </label>
    </div>
  </div>
</form>

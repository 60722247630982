import {
  Component,
  OnInit,
  Input,
  SimpleChange,
  Output,
  EventEmitter
} from '@angular/core';
import { PaymentProcessor } from '../../../models';
import { WorkflowContextService, SystemService } from '../../../services';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'wm-payment-processor-select',
  templateUrl: './payment-processor-select.component.html',
  styleUrls: ['./payment-processor-select.component.css']
})
export class PaymentProcessorSelectComponent implements OnInit {
  @Input() selectedPaymentProcessorId: string;
  @Input() form: UntypedFormGroup;
  processors: PaymentProcessor[];
  @Output() processorSelected = new EventEmitter();
  selectedPaymentProcessor: PaymentProcessor;

  constructor(private _systemSvc: SystemService) {}

  changePaymentProcessor(e) {
    this.processorSelected.next(this.selectedPaymentProcessor);
  }

  ngOnInit() {
    this._systemSvc.getPaymentProcessors().subscribe(processors => {
      this.processors = processors;
      this.selectedPaymentProcessor = this.processors.find(
        p => p.id == this.selectedPaymentProcessorId
      );
    });
  }
}

import { ExportEntityOption } from '../components/filter-list/models/filterClasses';
import { ValidationService } from '../services';
import { Role } from './role';
import { User } from './user';

export enum ExportFormat {
  Excel = 1,
  CSV = 2,
  ShapeFile = 3
}
export class ScheduledExport {
  id: string;
  name: string;
  isEnabled: boolean;
  savedFilterId: string;
  savedFilter: any;
  ownerId: string;
  owner: User;
  scheduledExportEmailOptions: ScheduledExportEmailOptions;
  exportFormat: ExportFormat;
  frequencyType: ScheduleFrequencyType; // monthly or weekly
  scheduleFrequencyOptions: ScheduleFrequencyOptions;
  nextExportOnString: string;
  deletedOnString?: string;

  constructor(options?: Partial<ScheduledExport>) {
    if (options) {
      Object.assign(this, options);
    }
  }
}

export class ScheduledExportEmailOptions {
  subject: string;
  body?: string;
  roleRecipients?: Role[];
  toRecipients?: string[];
  ccRecipients?: string[];
  bccRecipients?: string[];
}

export class ScheduleFrequencyOptions {
  startDateString: string; // captures both the date to begin scheduling and the time all tasks should occur
  endDateString?: string;
  frequency: number; // how often? Ex: if weekly is the period and the frequency is 2, then every 2 weeks
  type: string;

  constructor(options?: Partial<ScheduleFrequencyOptions>) {
    if (options) {
      Object.assign(this, options);
    }
  }
}

export class WeeklyFrequencyOptions extends ScheduleFrequencyOptions {
  onSunday = false;
  onMonday = false;
  onTuesday = false;
  onWednesday = false;
  onThursday = false;
  onFriday = false;
  onSaturday = false;

  constructor(options?: Partial<WeeklyFrequencyOptions>) {
    super();

    this.type = 'weekly-frequency-options';

    if (options) {
      Object.assign(this, options);
    }
  }
}

export class MonthlyFrequencyOptions extends ScheduleFrequencyOptions {
  on1st = false;
  on2nd = false;
  on3rd = false;
  on4th = false;
  on5th = false;
  on6th = false;
  on7th = false;
  on8th = false;
  on9th = false;
  on10th = false;
  on11th = false;
  on12th = false;
  on13th = false;
  on14th = false;
  on15th = false;
  on16th = false;
  on17th = false;
  on18th = false;
  on19th = false;
  on20th = false;
  on21st = false;
  on22nd = false;
  on23rd = false;
  on24th = false;
  on25th = false;
  on26th = false;
  on27th = false;
  on28th = false;
  onLastDOM = false;

  constructor(options?: Partial<MonthlyFrequencyOptions>) {
    super();

    this.type = 'monthly-frequency-options';

    if (options) {
      Object.assign(this, options);
    }
  }
}


export enum ScheduleFrequencyType {
  Weekly = 1,
  Monthly = 2
}

export class ScheduledExportsResponse {
  scheduledExports: ScheduledExport[];
}

export class ShapefileValidationCriteria {
  allowedExportFieldCount: 50 = 50;
  geoJSONEntities: { [key: string]: ExportEntityOption };
  isGeoJSONSelected: boolean;
  exportedFieldCount: number;
  showShapeFieldError: boolean;
  tooltipErrors: { [key: string]: string } = {
    GISDataTip: 'GIS data',
    csvXlsShapefileOnWarning: 'Enable by unchecking GIS Data Entities.',
    shapefileOffWarning: 'Enable by checking a GIS data type.',
    shapefileExcessFieldsWarning: ValidationService.CONFIG.shapeFieldTooMany
  };

  validate(exportFormat: ExportFormat) {
    // note: exportFormat is converted to a string because the html value needs to be a string.  Some browsers automatically convert it.
    //       ExportFormat.ShapeFile is converted to a string because exportFormat is coming in as a string and the enum is a number
    //       the reason I don't just use != instead of !== is because the linter complains about it and I don't want to see the linter complain everytime we do a check in.

    if (
      this.exportedFieldCount > this.allowedExportFieldCount &&
      exportFormat.toString() === ExportFormat.ShapeFile.toString()
    ) {
      return { shapeFieldTooMany: true };
    }
    if (
      exportFormat.toString() !== ExportFormat.ShapeFile.toString() &&
      this.isGeoJSONSelected === true
    ) {
      return { notShapefileWithGeoJSONSelected: true };
    }
    if (
      exportFormat.toString() === ExportFormat.ShapeFile.toString() &&
      this.isGeoJSONSelected === false
    ) {
      return { shapefileWithNoGeoJSONSelected: true };
    }

    return null;
  }
}

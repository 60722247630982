import { Component, OnInit, ViewChild } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { ModalConfirmComponent } from 'src/app/components/system/modal-confirm/modal-confirm.component';
import { Agenda, AgendaType } from 'src/app/models/agenda';
import {
  DataService,
  SecurityService,
  WorkflowContextService
} from 'src/app/services';
import { CKEditorComponent } from '@ckeditor/ckeditor5-angular';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CkeditorUploadService } from 'src/app/services/ckeditor-upload.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions } from 'src/app/models';
import { Observable } from 'rxjs';

@Component({
  selector: 'wm-agenda-list',
  templateUrl: './agenda-list.component.html',
  styleUrls: ['./agenda-list.component.css']
})
export class AgendaListComponent implements OnInit {
  clientId: string;
  agendaTypeId: string;
  form: UntypedFormGroup;
  agendaType: AgendaType;
  changingAgendaType: AgendaType;
  agendas: Agenda[] = [];

  public Editor = ClassicEditor;
  public editorConfig = {
    toolbar: [
      'heading',
      '|',
      'bold',
      'italic',
      'link',
      '|',
      'bulletedList',
      'numberedList',
      'imageUpload',
      '|',
      'undo',
      'redo'
    ]
  };
  @ViewChild('headerEditor', { static: false }) headerEditor: CKEditorComponent;

  @ViewChild('agendaTypeSettingsModal', { static: true })
  agendaTypeSettingsModal: ModalConfirmComponent;

  constructor(
    private _context: WorkflowContextService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _fb: UntypedFormBuilder,
    private _dataSvc: DataService,
    private _uploadAdapter: CkeditorUploadService,
    private _toastr: ToastrService,
    private _securitySvc: SecurityService
  ) {}

  ngOnInit() {
    this.agendaTypeId = this._route.snapshot.params.agendaTypeId;
    this.clientId = this._context.client.id;

    this.form = this._fb.group({
      name: this._fb.control('', [Validators.required]),
      description: this._fb.control('', [Validators.nullValidator]),
      header: this._fb.control('', [Validators.required]),
      defaultItemsNumber: this._fb.control('', [Validators.required])
    });

    this.loadAgendaType();
  }

  setChangingAgendaType() {
    this.changingAgendaType = this.agendaType;
  }

  loadAgendaType() {
    this._dataSvc.getAgendaType(this.agendaTypeId).subscribe(result => {
      this.agendaType = result;
      this.agendas = result.agendas;
      this.agendaType.agendas = [];
      this.setChangingAgendaType();
    });
  }

  newAgenda() {
    this._router.navigate([
      '/admin/jurisdiction',
      this._context.client.id,
      'meetings',
      'agenda-types',
      'agenda-type',
      this.agendaTypeId,
      'add-agenda'
    ]);
  }

  onModalCancel() {
    this.setChangingAgendaType();
  }

  onModalSave() {
    this._dataSvc.saveAgendaType(this.changingAgendaType).subscribe(() => {
      this._toastr.success('Agenda Type Saved!');
    });
  }

  public editorReady(editor) {
    const parentId: string = this.changingAgendaType.id;
    editor.plugins.get('FileRepository').createUploadAdapter = loader => {
      this._uploadAdapter.setLoader(loader, parentId);
      this._uploadAdapter.loader = loader;

      this._uploadAdapter.isUploading.subscribe((isUploading: boolean) => {
        if (this.form.controls['header-IS_UPLOADING']) {
          this.form.controls['$header-IS_UPLOADING'].setValue(
            isUploading ? 1 : 0
          );
        }
      });
      return this._uploadAdapter;
    };
  }

  canManageAgendas(): Observable<boolean> {
    return this._securitySvc.isLoginEntitled(Actions.MANAGE_AGENDAS);
  }
}

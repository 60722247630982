import { Observable, of, Subscription } from 'rxjs';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { PaymentAccountConfigBaseComponent } from '../payment-account-config-base/payment-account-config-base.component';
import {
  PayGovPaymentProcessorAccountConfiguration,
  PaymentAccount,
  PaymentProcessorType,
  Client
} from '../../../models';
import { Validators, UntypedFormBuilder } from '@angular/forms';
import { SystemService, WorkflowContextService } from 'src/app/services';

@Component({
  selector: 'wm-payment-account-config-paygov',
  templateUrl: './payment-account-config-paygov.component.html',
  styleUrls: ['./payment-account-config-paygov.component.css']
})
export class PaymentAccountConfigPaygovComponent
  extends PaymentAccountConfigBaseComponent
  implements OnInit, OnDestroy {
  @Input() config: PayGovPaymentProcessorAccountConfiguration;

  parentAccounts: PaymentAccount[];

  private clientSubscription: Subscription;

  constructor(
    fb: UntypedFormBuilder,
    private _systemSvc: SystemService,
    private context: WorkflowContextService
  ) {
    super(fb);
    this.showUsername = false;
  }

  ngOnInit() {
    this.addValidation('ttid', Validators.required, '');
    this.addValidation('duplicateNumber', Validators.required, '');
    this.addValidation('parentAccountId', Validators.nullValidator, '');

    if (this.context.client) {
      this.getParentAccounts();
    } else {
      this.clientSubscription = this.context.client$.subscribe(client => {
        if (!client) {
          return;
        }
        this.getParentAccounts();
      });
    }
  }

  ngOnDestroy() {
    if (this.clientSubscription) {
      this.clientSubscription.unsubscribe();
      this.clientSubscription = null;
    }
  }

  getParentAccounts() {
    if (this.account && this.account.id) {
      this._systemSvc
        .getParentAccounts(
          PaymentProcessorType.PayGov,
          this.context.client.id,
          this.account.id
        )
        .subscribe(accounts => {
          this.parentAccounts = accounts;
        });
    }
  }
}

<div class="card">
  <div class="card-body">
    <h6 class="card-title">Fee Summary</h6>

    <ng-container *ngIf="activity.model.fees">
      <ul class="list-unstyled mt-3">
        <li *ngFor="let fee of activity.model.fees" class="my-2">
          {{ fee.label }}
          <span class="float-right">{{ fee.formulaValue | currency }}</span>
        </li>
      </ul>

      <hr />
    </ng-container>

    <ng-container *ngIf="activity.model.fees">
      <ul class="list-unstyled mt-3">
        <li>
          Total Fees
          <span class="float-right">
            {{ activity.model.totalFees | currency }}
          </span>
        </li>
      </ul>

      <hr />
    </ng-container>

    <h6 class="mt-4">
      Total Due

      <span class="float-right font-weight-bold">
        {{ activity.model.totalDue | currency }}
      </span>
    </h6>
  </div>
</div>

<ng-container
  *ngIf="activity.model.payments && activity.model.payments.length > 0"
>
  <div class="card mt-4">
    <div class="card-body">
      <h6 class="card-title">
        Payments Made
      </h6>
      <table class="table">
        <thead>
          <tr>
            <th>Payment Method</th>
            <th>Paid On</th>
            <th>Confirmation Number</th>
            <th style="text-align: right">Amount</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let pmt of activity.model.payments">
            <td>{{ pmt.paymentMethod }}</td>
            <td>{{ pmt.confirmedOn | date: 'MMMM d, yyyy h:mm a' }}</td>
            <td>{{ pmt.confirmationData?.confirmationNumber }}</td>
            <td align="right">{{ pmt.requestedAmount | currency }}</td>
            <td>
              <a
                [href]="apiBase + '/files/receipt/' + pmt.id"
                class="btn btn-raised btn-primary float-right"
                target="_blank"
                rel="noopener"
              >
                View receipt
              </a>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td align="right" colspan="4">
              <b>Total Paid:</b>&nbsp;&nbsp;
              {{ activity.model.totalPaid | currency }}
            </td>
            <td>
              <a
                [href]="
                  apiBase + '/files/activityReceipt/' + activity.activityDataId
                "
                class="btn btn-raised btn-primary float-right"
                target="_blank"
                rel="noopener"
                *ngIf="
                  activity.model.payments && activity.model.payments.length > 1
                "
              >
                Full Receipt
              </a>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
</ng-container>

<form [formGroup]="form" *ngIf="processorConfig">
  <div class="form-group">
    <label class="bmd-label-floating" for="tokenURL">Token URL</label>
    <input
      type="text"
      class="form-control"
      formControlName="tokenURL"
      name="tokenURL"
      [(ngModel)]="processorConfig.tokenURL"
    />
  </div>
</form>

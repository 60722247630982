<wm-email-activity-view
  *ngIf="activity"
  [isPreview]="isPreview"
  [form]="form"
  [activity]="activity"
></wm-email-activity-view>

<!-- ##########    before appointment data can be displayed in this view, the values    ########## -->
<!-- ##########    need calculated on the server before rendering to this view          ########## -->

<!-- <div class="form-group" *ngIf="(apptModel.messages || []).length > 0">
  <h5 class="text-danger">Errors</h5>
  <ul class="list-group">
    <li
      class="list-group-item text-danger"
      *ngFor="let m of apptModel.messages"
    >
      {{ m }}
    </li>
  </ul>
</div>

<div class="card">
  <div class="card-body">
    <h5 class="card-title">Appointment Details</h5>
    <div class="form-group">
      <label class="text-label mb-0">
        Date
      </label>
      <div>{{ apptModel.dateDataEntity }}</div>
    </div>
    <div class="form-group">
      <label class="text-label mb-0">
        Location
      </label>
      <div>{{ apptModel.location }}</div>
    </div>

    <div class="form-group">
      <label class="text-label mb-0"> Duration (minutes)</label>
      <div>{{ apptModel.durationMinutes }}</div>
    </div>
  </div>
</div> -->

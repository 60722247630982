<wm-exports-view
  *ngIf="columnOptions && columnOptions.length > 0"
  [id]="id"
  [title]="title"
  [params]="filterCriteria"
  [currentFilters]="filterOptions"
  [staticFilters]="staticFilters"
  [currentColumnOptions]="columnOptions"
  (exptExcel)="exportExcel()"
  (exptCsv)="exportCsv()"
></wm-exports-view>

<wm-datatable
  [id]="id"
  [useMap]="false"
  [loading]="loading"
  [rows]="mappedRegistrations"
  [columns]="columnsWithExtra"
  [count]="page.totalElements"
  [offset]="page.pageNumber"
  [limit]="page.size"
  [params]="filterCriteria"
  [defaultFilters]="defaultFilters"
  simpleSearchTitle="Search for contractors"
  [unpermissibleCount]="unpermissibleCount"
  (settingsChanged2)="handleSettingsChanged($event)"
  [listeningToSC2]="true"
  [sortField]="sort.prop"
  [sortDescending]="sort.dir === 'desc'"
  [defaultHiddenColumns]="defaultHiddenColumns"
  [actionColumns]="actionColumns"
  [defaultExportExludedColumns]="defaultExportExludedColumns"
  [dynamicColumnsSet]="dynamicColumnsSetSubject.asObservable()"
  [waitForParent]="true"
  [availableColumns]="availableColumns"
  [dynamicColumnsVisibleByDefault]="dynamicColumnsVisibleByDefault"
  (columnOptionsChanged)="handleColumnOptionsChanged($event)"
  [exportingEnabled]="true"
>
</wm-datatable>

<ng-template #actionsTmpl let-row="row">
  <div ngbDropdown container="body">
    <button
      class="btn btn-secondary"
      id="dropdownMenuButton"
      ngbDropdownToggle
      style="padding-top:0px;"
    >
      Action
    </button>
    <div ngbDropdownMenu aria-labelledby="dropdownMenuButton">
      <a
        *ngFor="let action of commands"
        ngbDropdownItem
        [disabled]="!action.canPerform(row)"
        (click)="action.handler(row.id, row)"
        href="javascript:void(0)"
      >
        {{ action.title }}
      </a>
    </div>
  </div>
</ng-template>

<wm-modal-confirm
  title="Delete Contractor Registration"
  [showButton]="false"
  commandText="Delete"
  (accepted)="deleteRegistration()"
  #deleteRegModal
>
  <div>
    Are you sure you want to delete this registration?
  </div>
</wm-modal-confirm>

<wm-modal-confirm
  title="Disable Contractor Registration"
  [showButton]="false"
  commandText="Disable"
  (accepted)="disableRegistration()"
  #disableRegModal
>
  <div>
    <p>
      This registration will not be available in contractor selection activities
      while disabled.
    </p>
    <p>Are you sure you want to disable this registration?</p>
  </div>
</wm-modal-confirm>
<wm-modal-confirm
  title="Enable Contractor Registration"
  [showButton]="false"
  commandText="Enable"
  (accepted)="enableRegistration()"
  #enableRegModal
>
  <div>
    <p>
      This registration will be restored to a status determined by its
      expiration date and/or requirements, if any.
    </p>
    <p>Are you sure you want to enable this registration?</p>
  </div>
</wm-modal-confirm>

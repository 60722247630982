<wm-activity-details-modal
  [title]="activity.model.screenName"
  btnText="Configure Layer"
  name="mapConfig"
  [moduleId]="activity.id"
  [form]="form"
  (saved)="save()"
>
  <div [formGroup]="form">
    <div class="form-group" *ngIf="mapServices">
      <label class="bmd-label-floating" for="mapService"
        >Map Service <span class="text-danger">*</span></label
      >
      <select
        [(ngModel)]="mapServiceId"
        formControlName="mapService"
        (ngModelChange)="selectMapService($event)"
        class="form-control"
      >
        <option></option>
        <option *ngFor="let ms of mapServices" [value]="ms.id">{{
          ms.name
        }}</option>
      </select>
      <wm-control-message
        [control]="form.controls['mapService']"
      ></wm-control-message>
    </div>
    <ul ngbNav #nav="ngbNav" (change)="changeTabs()" [destroyOnHide]="false">
      <li ngbNavItem title="Search Configuration">
        <ng-template ngbNavContent>
          <div class="form-group" *ngIf="mapLayers">
            <label class="bmd-label-floating" for="mapLayer"
              >Map Layer <span class="text-danger">*</span></label
            >
            <select
              [ngModel]="mapLayerId"
              formControlName="mapLayer"
              (ngModelChange)="selectMapLayer($event)"
              class="form-control"
            >
              <option [value]="null"></option>
              <option *ngFor="let ml of mapLayers" [value]="ml.id">{{
                ml.name
              }}</option>
            </select>
            <wm-control-message
              [control]="form.controls['mapLayer']"
            ></wm-control-message>

            <div *ngIf="missingLayer" class="text-danger">
              ERROR: This activity was referencing layer
              {{ missingLayer.name }} and this layer no longer exists in the map
              service.
            </div>
            <div *ngIf="layerSupportsPagination == false" class="text-danger">
              ERROR: The layer you have selected is not supported by this
              feature. Please contact support to discuss options for this layer.
            </div>
          </div>

          <div class="form-group" *ngIf="activity.model.layerConfig">
            <label
              class="bmd-label-floating"
              style="position: unset !important;"
              for="mapLayer"
              >Feature Id Field &nbsp;<span class="text-danger">*</span></label
            >
            <wm-help-text
              placement="right"
              helpText="This field will be used to capture the value to associate an Application with the selected feature. This field should hold a value that is unique for each feature in the map layer. While the OBJECTID field is unique for each feature in a map layer, that value can change for a feature over time if not managed properly. This would cause the value associated with the application to be disconnected from previous applications or cause previous applications to be referencing the wrong feature. Please choose this field wisely."
            ></wm-help-text>
            <select
              [(ngModel)]="featureIdField"
              formControlName="featureIdField"
              class="form-control"
              (ngModelChange)="selectFeatureIdField($event)"
            >
              <option [ngValue]="null"></option>
              <option
                *ngFor="let lf of activity.model.layerConfig"
                [ngValue]="lf.fieldName"
                >{{ lf.fieldName }}</option
              >
            </select>
            <wm-control-message
              [control]="form.controls['featureIdField']"
            ></wm-control-message>
            <div *ngIf="validatingFieldIsUnique" class="text-danger">
              Validating that the selected field is unique in the layer...
            </div>
            <div
              *ngIf="!validatingFieldIsUnique && !layerFieldIsNotUnique"
              class="text-danger"
            >
              The field you have selected is not unique in the layer. You need
              to select a field that is unique across all of the records.
            </div>
            <wm-modal-confirm
              [showCommandText]="true"
              commandText="Change"
              cancelText="Cancel"
              [showButtonText]="true"
              [showButton]="true"
              btnIcon="edit"
              title="Layer Key Field"
              buttonClass="btn btn-sm"
              class="mr-1"
              *ngIf="layerHasOtherConfigurations"
              (accepted)="changeFeatureIdField()"
            >
              <h6>
                This layer is used in other workflows and the field used for the
                Feature Id needs to stay in sync. This will allow you to update
                the field for all workflows using this layer.
              </h6>
              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <label class="bmd-label-floating">Old Key Field</label>
                    <div>{{ featureIdField }}</div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <label class="bmd-label-floating">New Key Field</label>
                    <select
                      class="form-control"
                      [(ngModel)]="newFeatureIdField"
                      formControlName="newFeatureIdField"
                    >
                      <option></option>
                      <option
                        *ngFor="let lf of activity.model.layerConfig"
                        [value]="lf.fieldName"
                        >{{ lf.fieldName }}</option
                      >
                    </select>
                  </div>
                </div>
              </div>
            </wm-modal-confirm>
          </div>
          <div class="text-danger" *ngIf="showWarning">
            NOTE: Changing this configuration can potentially disconnect map
            layer information from applications!
          </div>
          <div class="card">
            <div class="card-body">
              <div class="card-title">
                <h5>Layer Fields</h5>
              </div>
              <table
                *ngIf="mapLayerId != null && activity.model.layerConfig"
                formGroupName="layerFields"
                class="table"
              >
                <tr>
                  <th>Name</th>
                  <th></th>
                  <th style="text-align: center;">Searchable?</th>
                  <th style="text-align: center;">Results Column?</th>
                  <th style="text-align: center;">Data Entity?</th>
                </tr>
                <tbody>
                  <ng-container *ngFor="let f of activity.model.layerConfig">
                    <tr
                      [formGroupName]="f.fieldName"
                      *ngIf="form.controls['layerFields'].controls[f.fieldName]"
                    >
                      <td class="align-middle">{{ f.fieldName }}</td>
                      <td class="align-middle">
                        <wm-modal-confirm
                          [showCommandText]="true"
                          commandText="Save"
                          cancelText="Cancel"
                          [showButtonText]="true"
                          [showButton]="true"
                          btnIcon="settings"
                          title="Field Settings"
                          buttonClass="btn btn-sm"
                          class="mr-1"
                          [form]="
                            form.controls['layerFields'].controls[f.fieldName]
                          "
                        >
                          <div>
                            <div>{{ f.fieldName }} Settings</div>
                            <div class="form-group">
                              <label
                                class="bmd-label-floating"
                                for="searchLabel"
                                >Search Label
                                <span class="text-red">*</span></label
                              >
                              <input
                                type="text"
                                formControlName="searchLabel"
                                [(ngModel)]="f.searchLabel"
                                class="form-control"
                              />
                              <wm-control-message
                                [control]="
                                  form.controls['layerFields'].controls[
                                    f.fieldName
                                  ].controls['searchLabel']
                                "
                              >
                              </wm-control-message>
                            </div>
                            <div class="form-group">
                              <label
                                class="bmd-label-floating"
                                for="resultHeader"
                                >Result Header
                                <span class="text-red">*</span></label
                              >
                              <input
                                type="text"
                                formControlName="resultHeader"
                                [(ngModel)]="f.resultHeader"
                                class="form-control"
                              />
                              <wm-control-message
                                [control]="
                                  form.controls['layerFields'].controls[
                                    f.fieldName
                                  ].controls['resultHeader']
                                "
                              >
                              </wm-control-message>
                            </div>
                            <div class="form-group">
                              <label
                                class="bmd-label-floating"
                                for="entityLabel"
                                >Entity Label
                                <span class="text-red">*</span></label
                              >
                              <input
                                type="text"
                                formControlName="entityLabel"
                                [(ngModel)]="f.entityLabel"
                                class="form-control"
                              />
                              <wm-control-message
                                [control]="
                                  form.controls['layerFields'].controls[
                                    f.fieldName
                                  ].controls['entityLabel']
                                "
                              >
                              </wm-control-message>
                            </div>
                            <div class="form-group">
                              <label
                                class="bmd-label-floating"
                                for="entityTemplateCode"
                                >Entity Template Code
                                <span class="text-red">*</span></label
                              >
                              <input
                                type="text"
                                formControlName="entityTemplateCode"
                                [(ngModel)]="f.entityTemplateCode"
                                class="form-control"
                              />
                              <wm-control-message
                                [control]="
                                  form.controls['layerFields'].controls[
                                    f.fieldName
                                  ].controls['entityTemplateCode']
                                "
                              >
                              </wm-control-message>
                            </div>
                            <div class="form-group">
                              <label
                                class="bmd-label-floating"
                                for="entityDataTypeCode"
                                >Entity Data Type
                                <span class="text-red">*</span></label
                              >
                              <select
                                formControlName="entityDataTypeCode"
                                [(ngModel)]="f.dataEntityTypeCode"
                                class="form-control"
                              >
                                <option value="free-form-text">Text</option>
                                <option value="date">Date</option>
                                <option value="numeric-data">Numeric</option>
                              </select>
                              <wm-control-message
                                [control]="
                                  form.controls['layerFields'].controls[
                                    f.fieldName
                                  ].controls['entityDataTypeCode']
                                "
                              >
                              </wm-control-message>
                            </div>
                          </div>
                        </wm-modal-confirm>
                      </td>
                      <td style="text-align: center;" class="align-middle">
                        <input
                          type="checkbox"
                          formControlName="isSearchable"
                          [(ngModel)]="f.isSearchable"
                        />
                      </td>
                      <td style="text-align: center;" class="align-middle">
                        <input
                          type="checkbox"
                          formControlName="isResultsColumn"
                          [(ngModel)]="f.showInResults"
                        />
                      </td>
                      <td style="text-align: center;" class="align-middle">
                        <input
                          type="checkbox"
                          formControlName="isDataEntity"
                          [(ngModel)]="f.isDataEntity"
                        />
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>
          </div>
        </ng-template>
      </li>
      <li ngbNavItem title="Display Configuration">
        <ng-template ngbNavContent>
          <div class="pt-3">
            <app-map-display-configuration-editor
              [client]="client"
              [mapConfig]="activity.model.mapConfig"
              [showMapServiceSelection]="false"
              *ngIf="activity.model.mapConfig"
            >
            </app-map-display-configuration-editor>
          </div>
        </ng-template>
      </li>
    </ul>
    <div [ngbNavOutlet]="nav" ></div>
  </div>
</wm-activity-details-modal>

<form
  [formGroup]="form"
  novalidate
  *ngIf="form"
  [ngClass]="{ required: required === true }"
>
  <div
    class="form-group"
    style="margin-bottom: 0;padding-top: 0px"
    *ngIf="showFormulaEditor"
  >
    <p *ngIf="title && title !== ''" class="mb-1" style="font-size: .75em;">
      {{ title | titlecase }}
    </p>
    <span *ngIf="required" class="text-danger">*</span>
    <div class="basic-example" *ngIf="entities">
      <ng-mentions
        #mentions
        [mentions]="entities"
        [(ngModel)]="model"
        [cols]="columns"
        rows="2"
        markup="@(__templateCode__)"
        displayName="templateCode"
        [formControlName]="id"
        placeholder=""
        (valueChanges)="formulaChange($event)"
      >
        <ng-template let-item="item">
          <span>{{ item.templateCode }}</span>
        </ng-template>
      </ng-mentions>
    </div>
  </div>
  <span *ngIf="!showFormulaEditor">
    Selected Value: {{ this.model }}
    &nbsp;
    <a
      *ngIf="model && model !== ''"
      href="javascript:void(0)"
      (click)="clearSelectedValue()"
    >
      Clear
    </a>
  </span>
  <div class="form-group" style="padding-top: 0;">
    <div class="row">
      <ng-container *ngIf="showFormulaEditor">
        <div class="col-11">
          <input
            type="text"
            class="form-control"
            #searchInput
            placeholder="Search data entities..."
            [ngbTypeahead]="search"
            (selectItem)="itemSelected($event)"
            (focus)="focus$.next($event.target.value)"
            #typeahead="ngbTypeahead"
            [focusFirst]="false"
          />
        </div>
        <div class="col-1" style="padding-top: 15px; padding-left: 0">
          <wm-help-text
            [placement]="placement"
            helpText='When combining text together in a formula, you must surround your text with quotes and include an ampersand (&) between text and other values. Example: "text value" & 3*4'
          ></wm-help-text>
        </div>
      </ng-container>
      <ng-container *ngIf="!showFormulaEditor">
        <div class="col-12">
          <input
            type="text"
            class="form-control"
            #searchInput
            placeholder="Search data entities..."
            [ngbTypeahead]="search"
            (selectItem)="itemSelected($event)"
            (focus)="focus$.next($event.target.value)"
            #typeahead="ngbTypeahead"
            [focusFirst]="false"
          />
        </div>
      </ng-container>
    </div>
    <wm-control-message [control]="form.controls[id]"></wm-control-message>
  </div>
</form>

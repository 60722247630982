import { Component, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'wm-processing-status',
  templateUrl: './processing-status.component.html',
  styleUrls: ['./processing-status.component.css']
})
export class ProcessingStatusComponent implements OnInit {
  @Input() status: string;
  @Input() showDelay = 1000;
  showStatus = false;
  showSubscription: any;

  show() {
    this.showSubscription = setTimeout(
      () => (this.showStatus = true),
      this.showDelay
    );
  }

  finished(finishStatus, delay: number = 2000): Observable<any> {
    if (this.showSubscription) {
      clearTimeout(this.showSubscription);
      this.showSubscription = undefined;
    }

    this.status = finishStatus;
    this.showStatus = true;

    let $ua = Observable.create(function(o) {
      setTimeout(a => {
        this.showStatus = false;
        o.next(true);
        o.complete();
      }, delay);
    });

    return $ua;
  }

  ngOnInit() {}
}

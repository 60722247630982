import { map } from 'rxjs/operators';
import { DataService } from './data.service';
import { WorkflowContextService } from './workflow-context.service';

import { Injectable, Inject, forwardRef, EventEmitter } from '@angular/core';
import {
  HttpClient,
  HttpEvent,
  HttpRequest,
  HttpEventType,
  HttpResponse
} from '@angular/common/http';
import { Subject, merge, of, concat, Subscriber } from 'rxjs';
import { Observable } from 'rxjs';
import { Document } from '../models/document';
import { UploadResponse } from 'src/app/components/upload/upload.component';
import { Utilities } from 'src/app/services';
import * as numeral from 'numeral';

@Injectable()
export class DocumentService {
  public fileUploadComplete: EventEmitter<any> = new EventEmitter<any>();
  public filesAdded: EventEmitter<any> = new EventEmitter<any>();
  public filesRemoved: EventEmitter<any> = new EventEmitter<any>();

  public MAX_FILE_SIZE = '100MB';
  public CHUNK_SIZE = '200KB';

  constructor(
    @Inject(forwardRef(() => DataService)) private _dataService: DataService,
    @Inject(forwardRef(() => WorkflowContextService))
    private _context: WorkflowContextService,
    private httpClient: HttpClient,
    public http: HttpClient
  ) {}
  removeDocument(
    applicationId: string,
    document: Document
  ): Observable<boolean> {
    return this._dataService.removeDocument(applicationId, document);
  }

  cleanupPDFImages(container: string, doc: Document): any {
    return this._dataService.cleanupPDFImages(container, doc);
  }

  convertPDFToPageImages(file: Document): any {
    return this._dataService.convertPDFToPageImages(file);
  }

  getDocuments(pathSegments: string[]): Observable<Document[]> {
    return this._dataService.getDocuments(pathSegments);
  }
  getDocument(documentId: string): Observable<Document> {
    return null;
  }
  saveDocument(document: Document): Observable<Document> {
    return null;
  }

  public upload(
    file: File,
    extraData: any
  ): Observable<HttpEvent<UploadResponse>> {
    return new Observable(observer => {
      this.uploadChunks(observer, file, extraData);
    });
  }

  private async uploadChunks(
    observer: Subscriber<HttpEvent<UploadResponse>>,
    file: File,
    extraData: any
  ) {
    // reject files that have special characters in their name
    const invalidCharacterExp = new RegExp(
      '[\\*\\\\+/:;|&()^%$#@!={}"\'?<>,`~§]'
    );

    if (invalidCharacterExp.test(file.name)) {
      return observer.error(
        new Error(
          'The file you uploaded has special characters. Please rename your file to remove all special characters.'
        )
      );
    }

    const chunkSize = numeral(this.CHUNK_SIZE).value();

    // reject files above 20000000 bytes (20mb) - the server will do this too
    if (file.size > numeral(this.MAX_FILE_SIZE).value()) {
      return observer.error(
        new Error(
          `The file you uploaded is too large. Please upload a file below ${this.MAX_FILE_SIZE}.`
        )
      );
    }

    // round totalChunks up because the last chunk will just be smaller than the rest
    const totalChunks = Math.ceil(file.size / chunkSize);

    let currentChunk = 0;
    const fileSize = {
      total: file.size,
      loaded: 0
    };
    const dataObject = {
      pathName: null
    };

    for (let offset = 0; offset < file.size; offset += chunkSize) {
      const chunk = file.slice(offset, offset + chunkSize);
      const chunkExtraData = {
        file: chunk,
        chunk: currentChunk,
        chunks: totalChunks
      };

      const data = Object.assign(chunkExtraData, extraData);

      const formData = this.createFormData(data);

      await this.uploadChunk(formData, dataObject, currentChunk)
        .pipe(
          map(event => {
            if (event.type === HttpEventType.UploadProgress) {
              fileSize.loaded += event.loaded;

              // the actual loaded file size is larger than the original file size so replace it so that the loaded size doesn't go above the total size
              event.loaded =
                fileSize.loaded > fileSize.total
                  ? fileSize.total
                  : fileSize.loaded;
              event.total = fileSize.total;
            }

            observer.next(event);

            if (
              event.type === HttpEventType.Response &&
              event.body.pathName &&
              currentChunk === 0
            ) {
              dataObject.pathName = event.body.pathName;
            }

            return event;
          })
        )
        .toPromise();

      currentChunk++;
    }

    observer.complete();
  }

  private uploadChunk(
    formData: FormData,
    dataObject: any,
    chunk: number
  ): Observable<HttpEvent<UploadResponse>> {
    if (dataObject.pathName) {
      formData.set('filename', dataObject.pathName);
    }

    return this.httpClient.post<any>(
      `${Utilities.getRootURL()}/api/Document/upload`,
      formData,
      {
        reportProgress: true,
        observe: 'events'
      }
    );
  }

  private createFormData(object: any): FormData {
    const formData: FormData = new FormData();

    for (const key in object) {
      if (object[key]) {
        const value = object[key];
        if (Array.isArray(value)) {
          const name = `${key}[]`;

          for (const item of value) {
            formData.append(name, item);
          }
        } else {
          formData.append(key, value);
        }
      }
    }

    return formData;
  }
}

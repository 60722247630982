import { Component, OnInit, Input } from '@angular/core';
import {
  ItemSearchOptionField,
  ItemSearchOptionFieldOption,
  ItemSearchFilter,
  ItemSearchOption
} from '../../filter-list/models/filterClasses';
import { WorkflowService, WorkflowContextService } from 'src/app/services';
import { map } from 'rxjs/operators';
import * as _ from 'lodash';
import { ComponentIds } from 'src/app/models/component-ids';

@Component({
  selector: 'wm-assigned-inspections',
  templateUrl: './assigned-inspections.component.html',
  styleUrls: ['./assigned-inspections.component.css']
})
export class AssignedInspectionsComponent implements OnInit {
  @Input() staticFilters: ItemSearchFilter;

  idPrefix = ComponentIds.ASSIGNED_INSPECTIONS;

  assignedInspections: _.Dictionary<{
    staticFilters: ItemSearchFilter;
  }> = {};
  loadCount = 0;
  InspectorId: {};
  loading = true;

  constructor(
    private _workflowSvc: WorkflowService,
    public context: WorkflowContextService
  ) {}

  ngOnInit() {
    this.loadInspectors();
    // if (!this.context.client) {
    //   this.context.client$.subscribe((res: any) => {
    //     this.loadInspectors();
    //   });
    // }
  }

  loadInspectors() {
    const inspectorsQuery = this._workflowSvc
      .getUniqueInspectorIds(this.context.client.id)
      .pipe(
        map(result => {
          return result;
        })
      );

    inspectorsQuery.subscribe(inspectors => {
      inspectors['NULL'] = null;
      this.InspectorId = inspectors;

      const sortUndefinedToTop = <T extends object>(obj: T): T =>
        Object.keys(obj)
          .sort((a, b) => {
            if (a === 'NULL') {
              return -1;
            }
            if (b === 'NULL') {
              return 1;
            }
            if (a > b) {
              return -1;
            }
            if (a < b) {
              return 1;
            }
            if (a === b) {
              return 0;
            }
          })
          .reduce((acc, c) => {
            acc[c] = obj[c];
            return acc;
          }, {}) as T;
      inspectors = sortUndefinedToTop(inspectors);

      for (const inspectorId of Object.keys(inspectors)) {
        const staticFilters = new ItemSearchFilter({
          options: [
            new ItemSearchOption({
              field: new ItemSearchOptionField({
                title: 'inspectorId',
                options: [
                  new ItemSearchOptionFieldOption({
                    title: 'inspectorId',
                    selected: true,
                    filterText: '',
                    searchText: inspectorId,
                    strOperator: 'is'
                  })
                ]
              })
            })
          ]
        });

        // this inspectorId shows up in the URL during sorting and paging and it looks a little silly to see the NULL show up.
        // even though it isn't an error it sure looks like one, this might make it look a little more integrated.
        this.assignedInspections[
          inspectorId === 'NULL' ? 'unscheduled' : inspectorId
        ] = {
          staticFilters: staticFilters
        };
      }
      this.loading = false;
    });
  }

  dataLoaded(value) {
    this.loadCount += 1;
  }
}

<div
  class="card border-info mb-3"
  *ngFor="let activity of activity.previousActivities"
>
  <div class="card-header" [id]="'heading' + activity.id">
    <h5 class="mb-0">
      <button
        class="btn btn-link"
        type="button"
        data-toggle="collapse"
        [attr.data-target]="'#' + activity.id"
        aria-expanded="true"
        [attr.aria-controls]="'#' + activity.id"
      >
        {{ activity.model.screenName }}
      </button>
    </h5>
  </div>
  <div
    [id]="activity.id"
    class="collapse show"
    [attr.aria-labelledby]="'heading' + activity.id"
  >
    <div class="card-body">
      <wm-activity-view
        [activity]="activity"
        [isReadOnly]="true"
        [form]="form"
        [isPreview]="false"
      ></wm-activity-view>
    </div>
  </div>
</div>

<form [formGroup]="form" (submit)="save()">
  <h3>Payment Account Settings</h3>

  <div class="form-group" *ngIf="account && account.processor && account.id">
    <div>
      {{ account.inTestMode ? 'Using Test Settings' : 'Using Live Settings' }}
    </div>
    <div
      *ngIf="
        account &&
        account.processor &&
        account.inTestMode &&
        account.processor.inTestMode
      "
      class="text-danger"
    >
      The payment processor is in Test Mode and therefore the account cannot be
      made live. The payment provider needs to be switched to Live Mode before
      you can switch the Payment Account to live mode.
      <div *ngIf="canEditProcessor">
        <a
          [routerLink]="[
            '/admin/global/payment-processors/edit/',
            account.processor.id,
            account.processor.type
          ]"
          >Edit Processor Settings</a
        >
      </div>
    </div>
    <wm-modal-confirm
      *ngIf="account && account.processor && account.id"
      [showButton]="true"
      [showButtonText]="true"
      commandText="Yes"
      [buttonText]="account.inTestMode ? 'Go Live' : 'Switch to Test Mode'"
      [title]="
        account.inTestMode
          ? 'Go Live (' + account.name + ')'
          : 'Switch to test (' + account.name + ')'
      "
      [btnColor]="account.inTestMode ? 'danger' : 'success'"
      (accepted)="toggleTestMode()"
      [enabled]="account.inTestMode && !account.processor.inTestMode"
    >
      <div *ngIf="account.inTestMode">
        Are you sure you want to change this payment account to use the live
        settings? This will change all workflow applications to use the live
        settings for payment processing.
      </div>
      <div *ngIf="!account.inTestMode">
        Are you sure you want to change this payment account to use the test
        settings? This will change all workflow applications to use the test
        settings for payment processing.
      </div>
    </wm-modal-confirm>
    <wm-modal-confirm
      *ngIf="account && account.processor && account.id"
      [showButton]="true"
      [showButtonText]="true"
      commandText="Yes"
      [buttonText]="account.isEnabled ? 'Disable' : 'Enable'"
      [title]="
        account.isEnabled
          ? 'Disable (' + account.name + ')'
          : 'Enable (' + account.name + ')'
      "
      [buttonClass]="
        'pl-3 ml-2 btn btn-raised ' +
        (account.isEnabled ? 'btn-success' : 'btn-danger')
      "
      (accepted)="toggleEnabled()"
    >
      <div *ngIf="account.isEnabled">
        Are you sure you want to disable this payment account? This will prevent
        all workflow applications using this account from submitting online
        payments.
      </div>
      <div *ngIf="!account.isEnabled">
        Are you sure you want to enable this payment account? This will allow
        all workflow applications using this account from submitting online
        payments.
      </div>
    </wm-modal-confirm>
  </div>
  <div *ngIf="!account || !account.processor || !account.id">
    Using Test Settings
  </div>

  <div class="form-group" *ngIf="account">
    <label class="bmd-label-floating" for="accountName">Account Name</label>
    <input
      type="text"
      class="form-control"
      formControlName="accountName"
      [(ngModel)]="account.name"
      name="accountName"
    />
  </div>

  <div class="form-group" *ngIf="account">
    <label class="bmd-label-floating" for="paymentProvider">
      Payment Provider
    </label>

    <wm-payment-processor-select
      [selectedPaymentProcessorId]="account.processor.id"
      [form]="form"
      (processorSelected)="processorChanged($event)"
      name="paymentProvider"
    >
    </wm-payment-processor-select>
  </div>
  <div
    *ngIf="account && account.processor.id && isProcessorConfigured === false"
    class="card text-white bg-danger mb-3"
  >
    <div class="card-body">
      <p class="card-text">The Processesor is not configured yet.</p>
    </div>
  </div>

  <ul
    ngbNav
    #settings="ngbNav"
    [destroyOnHide]="false"
    activeId="live"
    class="nav-tabs"
  >
    <li ngbNavItem="live" id="live" [disabled]="!hasControls(prodForm)">
      <a ngbNavLink>Live Settings</a>
      <ng-template ngbNavContent>
        <div formGroupName="prodForm">
          <wm-payment-account-config-base
            *ngIf="account"
            [form]="prodForm"
            [showUsername]="prodComponentRef?.instance?.showUsername"
            [showPassword]="prodComponentRef?.instance?.showPassword"
            [config]="account.providerAccountSettings"
          >
          </wm-payment-account-config-base>

          <template #prodProviderConfigView></template>
        </div>
      </ng-template>
    </li>
    <li ngbNavItem="test" id="test" [disabled]="!hasControls(testForm)">
      <a ngbNavLink>Test Settings</a>
      <ng-template ngbNavContent>
        <div formGroupName="testForm">
          <wm-payment-account-config-base
            *ngIf="account"
            [form]="testForm"
            [showUsername]="testComponentRef?.instance?.showUsername"
            [showPassword]="testComponentRef?.instance?.showPassword"
            [config]="account.testProviderAccountSettings"
          >
          </wm-payment-account-config-base>

          <template #testProviderConfigView></template>
        </div>
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="settings"></div>

  <div class="row" *ngIf="form">
    <div class="col">
      <button
        type="submit"
        class="btn btn-raised btn-success"
        [disabled]="form.invalid"
      >
        Save
      </button>
    </div>
    <div class="col">
      <button
        type="button"
        class="btn btn-raised btn-default float-right"
        (click)="goBack()"
      >
        Cancel
      </button>
    </div>
  </div>
</form>

import {
  PrintTemplateActivity,
  PrintTemplateActivityModel
} from './print-template-activity';

export class ContractorRegistrationCertificateActivity extends PrintTemplateActivity {
  constructor(options?: Partial<PrintTemplateActivity>) {
    super(options);
    this.type = 'contractor-registration-certificate-activity';
    this.description =
      'Generate a PDF representing the Contractor Registration Certificate';
    this.name = 'Contractor certificate';

    this.model = new PrintTemplateActivityModel(options ? options.model : null);

    this.model.screenName =
      this.model.screenName || 'Contractor certificate';
  }
}

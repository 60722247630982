import { User } from "./user";

export class CustomReportRequestItem {
  id: string;
  customReportId: string;
  queuedOn: string;
  queuedOnString: string;
  queuedBy: string;
  queuedByUser: User;
  status: string;
  completedOn: string;
  completedOnString: string;
  documentPath: string;
}

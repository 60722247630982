import { PaymentProcessorConfigeGOVComponent } from './../../../../components/payment-processor/payment-processor-config-egov/payment-processor-config-egov.component';
import { PaymentProcessorConfigKanpayComponent } from './../../../../components/payment-processor/payment-processor-config-kanpay/payment-processor-config-kanpay.component';
import {
  Component,
  OnInit,
  Type,
  ViewChild,
  ViewContainerRef,
  OnChanges,
  SimpleChanges,
  AfterViewInit,
  ChangeDetectorRef
} from '@angular/core';
import {
  PaymentProcessor,
  PaymentProcessorType,
  PaymentProcessorConfigurationBase
} from '../../../../models';
import { ActivatedRoute, Router } from '@angular/router';
import { SystemService } from '../../../../services';
import { PaymentProcessorConfigBaseComponent } from '../../../../components/payment-processor/payment-processor-config-base/payment-processor-config-base.component';
import { PaymentProcessorConfigPayGovComponent } from '../../../../components/payment-processor/payment-processor-config-paygov/payment-processor-config-paygov.component';
import { PaymentProcessorConfigCardpointeComponent } from '../../../../components/payment-processor/payment-processor-config-cardpointe/payment-processor-config-cardpointe.component';
import { PaymentProcessorConfigVpsComponent } from '../../../../components/payment-processor/payment-processor-config-vps/payment-processor-config-vps.component';
import { PaymentProcessorConfigPointnpayComponent } from '../../../../components/payment-processor/payment-processor-config-pointnpay/payment-processor-config-pointnpay.component';
import { PaymentProcessorConfigSecurepayComponent } from 'src/app/components/payment-processor/payment-processor-config-securepay/payment-processor-config-securepay.component';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  AbstractControl,
  Validators
} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'wm-payment-processor-detail-view',
  templateUrl: './payment-processor-detail-view.component.html',
  styleUrls: ['./payment-processor-detail-view.component.css']
})
export class PaymentProcessorDetailViewComponent
  implements OnInit, AfterViewInit {
  processorId: string;
  processor: PaymentProcessor;
  form: UntypedFormGroup;
  testForm: UntypedFormGroup;
  prodForm: UntypedFormGroup;

  configComponents: any;

  @ViewChild('testProviderConfigView', {
    read: ViewContainerRef,
    static: false
  })
  testItemViewContainer: ViewContainerRef;

  @ViewChild('prodProviderConfigView', {
    read: ViewContainerRef,
    static: false
  })
  prodItemViewContainer: ViewContainerRef;

  constructor(
    private route: ActivatedRoute,
    private _systemSvc: SystemService,
    private _router: Router,
    fb: UntypedFormBuilder,
    private ref: ChangeDetectorRef,
    private _toastr: ToastrService
  ) {
    this.testForm = fb.group({});
    this.prodForm = fb.group({});

    this.form = fb.group({
      testForm: this.testForm,
      prodForm: this.prodForm,
      inTestMode: fb.control(false, Validators.nullValidator)
    });
    // Setup the component types for each processor type
    this.configComponents = {};

    this.configComponents[
      PaymentProcessorType.PayGov
    ] = PaymentProcessorConfigPayGovComponent;
    this.configComponents[
      PaymentProcessorType.Cardpointe
    ] = PaymentProcessorConfigCardpointeComponent;
    this.configComponents[
      PaymentProcessorType.VPS
    ] = PaymentProcessorConfigVpsComponent;
    this.configComponents[
      PaymentProcessorType.PointNPay
    ] = PaymentProcessorConfigPointnpayComponent;
    this.configComponents[
      PaymentProcessorType.SecurePay
    ] = PaymentProcessorConfigSecurepayComponent;
    this.configComponents[
      PaymentProcessorType.KanPay
    ] = PaymentProcessorConfigKanpayComponent;
    this.configComponents[
      PaymentProcessorType.eGOV
    ] = PaymentProcessorConfigeGOVComponent;
  }

  toggleTestMode() {
    this.processor.inTestMode = !this.processor.inTestMode;
    this.save(false);
  }

  async ngOnInit() {
    this.processorId = this.route.snapshot.params.processorId;

    const payType: PaymentProcessorType = parseInt(
      this.route.snapshot.params.type,
      10
    ) as PaymentProcessorType;

    if (this.processorId) {
      const proc = await this._systemSvc
        .getPaymentProcessor(this.processorId)
        .toPromise();

      this.processor = proc;

      this.loadComponent(payType);
    }
  }

  ngAfterViewInit(): void {
    // this.form.updateValueAndValidity({ emitEvent: true }); // .controls.forEach((control: AbstractControl) => control.updateValueAndValidity());
    // this.ref.detectChanges();
  }

  save(redirect: boolean = true) {
    this._systemSvc.savePaymentProcessor(this.processor).subscribe(result => {
      if (redirect) {
        this._router.navigate(['/admin/global/payment-processors/list']);
      } else {
        this._toastr.success('Saved');
      }
    });
  }

  loadComponent(type: PaymentProcessorType) {
    let compType: Type<PaymentProcessorConfigBaseComponent>;

    if (this.configComponents[type]) {
      compType = this.configComponents[type];
    }

    if (compType && this.prodItemViewContainer) {
      this.prodItemViewContainer.clear();

      const componentRef = this.prodItemViewContainer.createComponent<
        PaymentProcessorConfigBaseComponent
      >(compType);
      componentRef.instance.form = this.prodForm;
      componentRef.instance.processorConfig = this.processor.configuration;
    }

    if (compType && this.testItemViewContainer) {
      this.testItemViewContainer.clear();

      const componentRef = this.testItemViewContainer.createComponent<
        PaymentProcessorConfigBaseComponent
      >(compType);
      componentRef.instance.form = this.testForm;
      componentRef.instance.processorConfig = this.processor.testConfiguration;
    }

    this.form.updateValueAndValidity({ emitEvent: true }); // .controls.forEach((control: AbstractControl) => control.updateValueAndValidity());
    this.ref.detectChanges();
  }
}

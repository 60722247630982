import { InspectionDocumentCategory } from './inspection-document-category';

export class InspectionDocument {
  id: string;
  documentName: string;
  inspectionId: string;
  categoryId?: string = null;
  category?: InspectionDocumentCategory;
  documentPath: string;
  createdOn: string;
  createdBy: string;
  modifiedOn: string;
  modifiedBy: string;
  editing: boolean;

  constructor(options?: Partial<InspectionDocument>) {
    if (options) {
      Object.assign(this, options);
    }
  }
}

import { WorkflowVersion } from './workflow-version';
import { User } from '.';

export enum ApplicationStatus {
  NotStarted = 0,
  InProgress = 1,
  Completed = 2,
  Voided = 3,
  Rejected = 4,
  Discarded = 5
}
export enum FeedbackMessageType {
  Info = 0,
  Success = 1,
  Warning = 2,
  Error = 3
}
export enum ApplicationProcessingStatus {
  Idle = 1,
  Processing = 2
}

export class WorkflowApplication {
  id: string;
  appId: string;
  applicationName: string;
  status: ApplicationStatus;
  activityData: any[];
  entityData: any[];
  currentActivities?: any[];
  submitterId: string;
  submitterName: string;
  createdByName: string;
  workflowVersion: WorkflowVersion;
  workflowVersionId: string;
  name: string;
  permitType: string;
  applicant: User;
  isTestApplication: boolean;
  startedOn: Date;
  applicationNumber: string;
  description: string;
  hasParentApplication: boolean;
  parentApplicationId: string;
  combinedApplicationNumber?: string;
  internalNotes?: string;
  externalNotes?: string;
  processingStatus?: ApplicationProcessingStatus;

  constructor(options?: Partial<WorkflowApplication>) {
    if (options) {
      Object.assign(this, options);
    }
  }
}
export class PagedWorkflowApplicationVM {
  recordCount: number;
  pageCount: number;
  filterOptions: { [key: string]: { [key: string]: string } };
  workflowApplications: WorkflowApplicationVM[];
  unpermissibleCount: number;
}
export class WorkflowApplicationVM {
  id: string;
  appId: string;
  applicationNumber: string;
  permitType: string;
  parcelAddress: string;
  parcelCity: string;
  parcelId: string;
  parcelOwner: string;
  description: string;
  applicantsName: string;
  status: string;
  dateCreated: Date;
  currentActivityData: any;
  currentActivityDetails: any[];
  currentActivityName: string;
  submitterId: string;
  geometryWkts: string[];
  FeeDollarsDue: number;
  combinedApplicationNumber?: string;
  internalNotes?: string;
  externalNotes?: string;
}

import { TemplateField } from './../activities/print-template-activity';
import { Utilities } from 'src/app/services';
import { DataEntity } from './data-entity';
import { DataEntityListItem } from '../../components/workflow/activities/print-template-activity/print-template-activity-editor/print-template-activity-editor.component';
import { fabric } from 'fabric';
import { of } from 'rxjs';

export enum ControlType {
  Radio = 'radio',
  Check = 'checkbox',
  Dropdown = 'dropdown',
  MultiSelect = 'multi-select'
}

export class ListOption {
  constructor(options: Partial<ListOption>) {
    Object.assign(this, options);
  }
  id: string = Utilities.generateId();
  customListId: string;
  position: number;
  value: string;
  text: string;
}

export class ListDataEntity extends DataEntity {
  controlType: ControlType = ControlType.Radio;
  listTitle = '';
  listValues = [];
  listTextValues: ListOption[] = [];
  selectedDataSource: string;
  availableDataSources: { [key: string]: string };
  excludeItemsFromCriteria: string[];
  availableListItems: ListOption[] = [];

  selectedValue = 'fake';
  selectedTextValues = [];
  selectedValues = [];
  selectedItems: ListOption[];

  canEditList: boolean;

  constructor(options?: Partial<ListDataEntity>) {
    super(options);

    this.dataEntityTypeCode = 'list-data';
    this.dataEntityTypeDescription = 'Use this entity type to create a list.';
    this.dataEntityTypeName = 'List';

    if (options) {
      Object.assign(this, options);
      // support legecy listValues[]:string[]
      if (
        this.listValues &&
        this.listValues.length > 0 &&
        !this.listTextValues
      ) {
        this.listTextValues = this.listValues.map(v => {
          return new ListOption({ text: v, value: v });
        });
      }

      // change the this.value to set the selectedTextValues... this is to support legacy.
      if (this.value) {
        if (
          (this.selectedValues || []).length === 0 &&
          (this.value || '') !== ''
        ) {
          if ((this.value || '').indexOf('[') === -1) {
            const items = this.value.split(',');
            this.selectedValues = items.map(m => {
              return {
                id: '',
                text: m,
                value: m
              };
            });
          }
        }

        const textValueArray = this.selectedValues;

        const operation = (list1, list2, isUnion = false) =>
          list1.filter(
            a =>
              isUnion ===
              list2.some(b => a.value === b.value || a.id === b.value)
          ); // (b.indexOf('{') > -1 ? JSON.parse(b).value : b)));

        const inBoth = (list1, list2) => operation(list1, list2, true);

        const newValuesArray = inBoth(this.availableListItems, textValueArray);
        this.selectedTextValues = newValuesArray;
      }
    }

    // convert options into ListOption classes
    if (this.listTextValues) {
      this.listTextValues = this.listTextValues.map(l => new ListOption(l));
    }
  }

  getPrintTemplateItems(): DataEntityListItem[] {
    return [
      new DataEntityListItem({
        code: this.templateCode,
        text: this.templateCode,
        children: [
          ...this.availableListItems.map(
            a =>
              new DataEntityListItem({
                code: this.templateCode,
                text: a.text,
                extraData: a
              })
          ),
          new DataEntityListItem({
            code: this.templateCode,
            text: 'Selected Value',
            extraData: 'Selected'
          })
        ]
      })
    ];
  }

  getSpecialAvailablePrintFormatOptions(): { [key: string]: string[] } {
    const listEntityAvailablePrintFormatOptions: {
      [key: string]: string[];
    } = {};

    const optionsForAnyListEntity = [
      'fontColor',
      'fontSize',
      'fontWeight',
      'emptyText',
      'orientation',
      'verticalOrientation'
    ];

    listEntityAvailablePrintFormatOptions['Selected'] = [
      ...optionsForAnyListEntity,
      'criteria',
      'outputFormat'
    ];

    for (const li of this.availableListItems) {
      listEntityAvailablePrintFormatOptions[
        (this.templateCode + '.' + li.text).toUpperCase()
      ] = [...optionsForAnyListEntity];
    }

    return listEntityAvailablePrintFormatOptions;
  }

  getPossibleValues() {
    const excludedItems = this.excludeItemsFromCriteria || [];
    return this.availableListItems
      .filter(f => excludedItems.indexOf(f.value) === -1)
      .map(l => {
        return { name: l.text || l.value, value: l.value || l.text };
      });
  }

  async formatTemplateItem(fieldInfo, helperFunctions): Promise<fabric.Object> {
    const obj: fabric.Object = await super.formatTemplateItem(
      fieldInfo,
      helperFunctions
    );

    obj.lockScalingY = false;
    obj.lockSkewingY = false;

    return of(obj).toPromise();
  }
}

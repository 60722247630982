<form [formGroup]="form" novalidate>
  <label [for]="inspectionDocEntity.templateCode" class="bmd-label-floating">
    Document Category
  </label>

  <wm-document-data-entity
    [dataEntity]="dataEntity"
    [form]="form"
  ></wm-document-data-entity>
</form>

<div class="row" *ngIf="statusMessage">
  <div class="col-sm-12">
    <div
      data-ng-class="{'panel panel-success': statusType === StatusType.Success, 'panel panel-info': statusType === StatusType.Information, 'panel panel-warning': statusType === StatusType.Warning, 'panel panel-danger': statusType === StatusType.Critical }"
    >
      <div class="panel-body">{{ statusMessage }}</div>
    </div>
  </div>
</div>

<form [formGroup]="form">
  <!-- Forms -->
  <div class="row">
    <div class="col">
      <h3>User Settings</h3>
      <app-loader *ngIf="!user"></app-loader>

      <div *ngIf="user">
        <div
          class="form-group"
          [class.has-error]="
            form.controls.fullName.touched && !form.controls.fullName.valid
          "
        >
          <label class="bmd-label-static" for="fullName"
            >Full Name <span class="text-danger">*</span></label
          >
          <input
            type="text"
            class="form-control required"
            [(ngModel)]="user.name"
            formControlName="fullName"
            name="fullName"
          />
        </div>

        <div
          class="form-group"
          [class.has-error]="
            form.controls.userName.touched && !form.controls.userName.valid
          "
        >
          <label class="bmd-label-static" for="userName"
            >User Name <span class="text-danger">*</span></label
          >
          <input
            type="text"
            class="form-control required"
            [(ngModel)]="user.userName"
            formControlName="userName"
            name="userName"
          />
        </div>

        <div
          class="form-group"
          [class.has-error]="
            form.controls.emailAddress.touched &&
            !form.controls.emailAddress.valid
          "
        >
          <label class="bmd-label-static" for="emailAddress"
            >Email Address <span class="text-danger">*</span></label
          >
          <input
            type="text"
            class="form-control required"
            [(ngModel)]="user.emailAddress"
            formControlName="emailAddress"
            name="emailAddress"
          />
        </div>

        <div
          class="form-group"
          [class.has-error]="
            form.controls.phoneNumber.touched &&
            !form.controls.phoneNumber.valid
          "
        >
          <label class="bmd-label-static" for="phoneNumber">Phone Number</label>
          <input
            type="text"
            class="form-control"
            [(ngModel)]="user.phoneNumber"
            formControlName="phoneNumber"
            name="phoneNumber"
          />
        </div>

        <wm-address-detail
          [required]="true"
          [showButtons]="false"
          [address]="user.address"
          [showName]="false"
          [parentType]="'User'"
        >
        </wm-address-detail>
      </div>
    </div>
    <div class="col" *ngIf="user">
      <h3>Roles</h3>

      <button
        class="btn btn-raised btn-primary"
        *ngIf="!showAssociateRole"
        (click)="showAssociateRole = true"
      >
        Add Role
      </button>

      <wm-associate-role
        [client]="context.client"
        *ngIf="showAssociateRole"
        (roleAssociated)="associateRole($event)"
        (canceled)="associateRoleCanceled($event)"
      >
      </wm-associate-role>

      <wm-role-list-by-client [roles]="roles" (removed)="roleRemoved($event)">
      </wm-role-list-by-client>
    </div>
  </div>

  <!-- Buttons -->
  <div class="row" *ngIf="user">
    <div class="col">
      <button
        type="submit"
        class="btn btn-raised btn-success mr-1"
        [disabled]="!form.valid"
        (click)="save()"
      >
        Save
      </button>

      <wm-processing-status [status]="'Saving User'" #saveProcessingStatus>
      </wm-processing-status>

      <button
        class="btn btn-raised btn-primary"
        *ngIf="user && canDisable() && !user?.isEnabled"
        (click)="enableUser()"
      >
        Enable
      </button>
      <wm-modal-confirm
        [commandText]="'Disable'"
        [title]="'Disable User'"
        [showButtonText]="true"
        (accepted)="disableUser($event)"
        [enabled]="user?.isEnabled"
        moduleId="disable_user"
        *ngIf="user && canDisable() && user?.isEnabled"
        btnColor="danger"
        class="mr-1"
      >
        <p>Are you sure you want to disable this user ({{ user?.name }})?</p>

        <p>Disabling this user will do the following:</p>
        <ul>
          <li>
            Keep user from logging into the system and therfore keep them from
            continuing any started applications.
          </li>
          <li>
            The user will be able to start an application that doesn't require a
            login.
          </li>
        </ul>
      </wm-modal-confirm>

      <button
        class="btn btn-raised btn-primary mr-1"
        [disabled]="!canResetPassword"
        (click)="resetPassword(user)"
      >
        {{ resetPasswordText }}
      </button>
    </div>

    <div class="col">
      <button
        type="button"
        class="btn btn-raised btn-default float-right"
        (click)="cancel()"
      >
        Cancel
      </button>
    </div>
  </div>
</form>

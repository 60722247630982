<div class="form-group">
  <wm-de-label
    [entity]="entity"
    [for]="entity.templateCode"
    [displayOnly]="true"
  ></wm-de-label>
  <ul class="list-group">
    <a
      *ngFor="let d of entity.documents; let idx = index"
      class="list-group-item"
      [href]="d.path"
      target="_blank"
    >
      {{ d.name }}
    </a>
  </ul>
</div>

import {
  Component,
  OnInit,
  Input,
  ViewChild,
  TemplateRef,
  ViewChildren,
  QueryList,
  Inject,
  forwardRef
} from '@angular/core';
import { TableColumn } from '@swimlane/ngx-datatable';
import { DataTableHelpers } from 'src/app/components/system/datatable/datatable-helper';
import {
  Filter,
  FilterBuilderOutput,
  FilterBuilderParam
} from 'src/app/models/filter-builder';
import {
  PreviousSystemDataDetail,
  PreviousSystemDataHeader,
  PreviousSystemPermitAttachment
} from 'src/app/models/previous-system-data';
import {
  DataService,
  DocumentService,
  SecurityService
} from 'src/app/services';
import { Observable } from 'rxjs';
import {
  ButtonStyle,
  ModalConfirmComponent
} from 'src/app/components/system/modal-confirm/modal-confirm.component';
import { ColumnOption, GridSettings } from 'src/app/models/grid-settings';
import { DocumentDataEntity } from 'src/app/models/data-entities';
import { ToastrService } from 'ngx-toastr';
import { Actions } from 'src/app/models';

import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { Command } from 'src/app/components/contractors/contractor-registration-list/contractor-registration-list.component';
@Component({
  selector: 'wm-workflow-previous-system-list',
  templateUrl: './workflow-previous-system-list.component.html',
  styleUrls: ['./workflow-previous-system-list.component.css']
})
export class WorkflowPreviousSystemListComponent implements OnInit {
  @Input() clientId: string;
  @Input() staticFilters: Filter[];
  @Input() id = 'previous-system-list';
  faTrash = faTrash;
  @ViewChild('detailsTmpl', { static: true }) detailsTmpl: TemplateRef<any>;
  @ViewChildren('detailsModal') detailsModal: QueryList<ModalConfirmComponent>;

  @ViewChild('actionsTmpl', { static: true }) attachmentsEditTmpl: TemplateRef<any>;
  @ViewChild('attachmentsEditModal', { static: true })
  attachmentsEditModal: ModalConfirmComponent;

  @ViewChild('detailsEditModal', { static: true }) detailsEditModal: ModalConfirmComponent;

  isPreview = false;
  commands: Command[];
  loading = true;
  detailsLoading = true;

  selectedAttachmentHeaderId: number;
  selectedHeaderRow: PreviousSystemDataHeader;
  attachmentsLoading = false;

  columns: TableColumn[];
  availableColumns: TableColumn[];
  columnOptions: ColumnOption[] = [];
  rows: PreviousSystemDataHeader[];
  detailRows: PreviousSystemDataDetail[];
  attachmentRows: PreviousSystemPermitAttachment[];
  selectedPermitNum: string;
  ButtonStyle = ButtonStyle;

  validDocumentTypes = 'pdf,jpg,jpeg';
  page = {
    // The number of elements in the page
    size: 10,
    // The total number of elements
    totalElements: 0,
    // The total number of pages
    totalPages: 0,
    // The current page number
    pageNumber: 0
  };
  sortField: string;
  sortDescending: boolean;

  // this is here for if it needs implemented in the future...
  // must set wm-datatable input showFilterBuilder to true
  params: FilterBuilderParam[] = [
    {
      id: 'type',
      name: 'Type'
    },
    {
      id: 'number',
      name: 'Number'
    },
    {
      id: 'name',
      name: 'Name'
    },
    {
      id: 'address',
      name: 'Address'
    },
    {
      id: 'date',
      name: 'Date',
      types: ['is', 'range'],
      inputType: 'date'
    },
    {
      id: 'parcelNumber',
      name: 'Parcel Number'
    }
  ];
  filters: FilterBuilderOutput = {};

  // column prop strings to be hidden on the grid by default
  defaultHiddenColumns: string[] = [];
  // column prop strings that should have no option to hide in the grid, and are not exportable (such as button columns like Refund or Actions)
  actionColumns: string[] = [];
  // column prop strings to be excluded from exports by default
  defaultExportExludedColumns: string[] = [];
  showDateErrorMessage = false;
  constructor(
    @Inject(forwardRef(() => DocumentService))
    public uploadService: DocumentService,
    private _dataSvc: DataService,
    private _dtHelpers: DataTableHelpers,
    private _toastr: ToastrService,
    private _securitySvc: SecurityService
  ) {}

  ngOnInit() {
    this.columns = [
      {
        prop: 'view',
        name: '',
        cellTemplate: this.detailsTmpl
      },
      {
        prop: 'type',
        name: 'Type'
      },
      {
        prop: 'number',
        name: 'Number'
      },
      {
        prop: 'name',
        name: 'Name'
      },
      {
        prop: 'address',
        name: 'Address'
      },
      {
        prop: 'date',
        name: 'Date'
      },

      {
        prop: 'parcelNumber',
        name: 'Parcel Number'
      },
      {
        prop: 'attachments',
        name: '',
        cellTemplate: this.attachmentsEditTmpl
      }
    ];

    this.commands = [
      {
        title: 'Edit Attachments',
        canPerform: () => true,
        handler: this.startAttachments.bind(this)
      },
      {
        title: 'Edit Details',
        canPerform: () => true,
        handler: this.startDetails.bind(this)
      }
    ];
    this.actionColumns.push('attachments');
    this.actionColumns.push('edit');
    this.actionColumns.push('view');
    this.setAvailableColumns();
    this.loading = false;
  }
  startDetails(row: PreviousSystemDataHeader) {
    this.selectedHeaderRow = row;
    this.detailsEditModal.open();
  }
  startAttachments(row: PreviousSystemDataHeader) {
    this.selectedHeaderRow = row;
    this.selectedAttachmentHeaderId = row.id;
    this.attachmentsEditModal.open();
  }
  setAvailableColumns() {
    this.availableColumns = this.columns;
  }

  handleSettingsChanged(event: GridSettings) {
    this.filters = event.filters;
    this.page.size = event.pageSize;
    this.page.pageNumber = event.pageNumber;
    this.sortField = event.sortField;
    this.sortDescending = event.sortDescending;
    this.columnOptions = event.columnOptions;

    const visibleColumnProps = this.columnOptions
      .filter(co => co.checked)
      .map(co => co.name);
    this.columns = this.availableColumns.filter(
      c =>
        visibleColumnProps.includes(c.prop as string) ||
        this.actionColumns.includes(c.prop as string)
    );

    this.getPreviousSystemData();
  }

  getPreviousSystemData() {
    this.loading = true;

    const allFilters: Filter[] = [];
    if (this.staticFilters) {
      for (const filter of this.staticFilters) {
        allFilters.push(filter);
      }
    }
    for (const id in this.filters) {
      if (this.filters[id]) {
        allFilters.push(this.filters[id]);
      }
    }

    this._dataSvc
      .getPreviousSystemDataHeaders({
        clientId: this.clientId,
        isPagedRequest: true,
        pageSize: this.page.size,
        pageNumber: this.page.pageNumber,
        filters: allFilters,
        sortField: this.sortField,
        sortDescending: this.sortDescending
      })
      .subscribe(results => {
        this.page.totalElements = results.recordCount;
        this.page.totalPages = results.pageCount;
        this.rows = results.items.map(i => {
          i['id'] = i.id;
          i['type'] = i.type;
          i['number'] = i.number;
          i['name'] = i.name;
          i['address'] = i.address;
          i['date'] = i.dateTimeString;
          i['parcelNumber'] = i.parcelNumber;
          return i;
        });

        this.loading = false;
      });
  }
  canEditPreviousSystemData(): Observable<boolean> {
    return this._securitySvc.isLoginEntitled(Actions.EDIT_PREVIOUS_SYSTEM_DATA);
  }
  onModalSave() {
    this._dataSvc
      .saveDetailRows(
        this.detailRows,
        {
          clientId: this.clientId,
          headerId: this.selectedHeaderRow.id
        },
        this.selectedHeaderRow
      )
      .subscribe(
        result => {
          if (result.success === true) {
            this._toastr.success('Previous System Detail Saved!');
            this.showDateErrorMessage = false;
            this.detailsEditModal.cancel();
          } else {
            this._toastr.error('Error with input.  Failed to save.');
            if (result.messages['dateFailed']) {
              this.showDateErrorMessage = true;
              this.detailsEditModal.cancelProcessing();
            }
          }
        },
        e => {
          // occasionally this modal fails to close.  It doesn't occur consistently enough to resolve the issue, yet I want to be aware when it happens so I don't forget about it.
          console.log({
            message: 'An error occured closing the modal.',
            error: e
          });
        }
      );
  }
  loadViewDetails(row) {
    this.selectedHeaderRow = row;
    this.loadDetails();
  }
  loadDetails() {
    this.detailsLoading = true;
    // the selected header row doesn't need the date field, in fact it confuses things.  Just get rid of it so when I send it back to the server it doesn't have it.  It isn't on the PreviousSystemDataHeader anyway..
    delete this.selectedHeaderRow['date'];
    this.selectedAttachmentHeaderId = this.selectedHeaderRow.id;
    this.getPreviousSystemDataDetails().subscribe(results => {
      this.detailRows = results.items.map(i => {
        i['detailKey'] = i.detailKey;
        i['detailValue'] = i.detailValue;
        return i;
      });

      this.detailsLoading = false;
    });
  }

  getPreviousSystemDataDetails(): Observable<any> {
    this.detailsLoading = true;
    return this._dataSvc.getPreviousSystemDataDetails({
      clientId: this.clientId,
      headerId: this.selectedAttachmentHeaderId
    });
  }
  loadPreviousSystemPermitAttachments() {
    this.attachmentsLoading = true;
    this._dataSvc
      .getPreviousSystemPermitAttachments({
        headerId: this.selectedAttachmentHeaderId
      })
      .subscribe(results => {
        this.attachmentRows = results.items;
        this.attachmentsLoading = false;
      });
  }

  savePreviousSystemPermitAttachments(document: any) {
    const attachment: PreviousSystemPermitAttachment = new PreviousSystemPermitAttachment();
    attachment.id = null;
    attachment.name = document.name;
    attachment.path = document.path;
    attachment.pathName = document.pathName;
    attachment.previousSystemPermitHeaderId = this.selectedAttachmentHeaderId;
    this._dataSvc
      .savePreviousSystemAttachment(attachment)
      .subscribe(results => {
        this.attachmentRows.push(results);
      });
  }

  deletePreviousSystemPermitAttachment(
    document: PreviousSystemPermitAttachment
  ) {
    const updatePreviousSystemPermitAttachments = () => {
      const docs = this.attachmentRows.filter(d => d.id !== document.id);
      this.attachmentRows = docs;
    };
    updatePreviousSystemPermitAttachments();
    document.deletedOn = new Date();
    this._dataSvc.savePreviousSystemAttachment(document).subscribe(results => {
      // do nothing with it.
    });
  }

  updateHref(attachment: PreviousSystemPermitAttachment) {
    this._dataSvc
      .updatePreviousSystemPermitAttachmentPath(attachment)
      .subscribe(results => {});
  }
  fileUploaded(e) {
    // save this guy
    this.savePreviousSystemPermitAttachments(e);
  }
}

import { ScreenActivity, ActivityModel } from './activity';
import {
  DataEntity,
  ParentReference
} from '../../models/data-entities/data-entity';
import { OnChanges, SimpleChanges, Type } from '@angular/core';
import { DisplayEntityValueDataEntity } from '../data-entities';
import { DataEntityFactory } from '../../services';

export enum FormLayoutModes {
  SingleColumn = 0,
  TwoColumn = 1
}

export class DataEntityLayoutModel {
  entity: DataEntity;
  entityTemplateCode: string;
  displayOrder: number;

  constructor(options?: Partial<DataEntityLayoutModel>) {
    if (options) {
      Object.assign(this, options);

      if (options.entity) {
        this.entity = DataEntityFactory.createDataEntity(
          options.entity.dataEntityTypeCode,
          options.entity
        );
      }
    }
  }
}

export abstract class FormLayoutModel {
  static modeModels: { [key: number]: Type<FormLayoutModel> } = {};
  abstract getEntities(): DataEntity[];
  abstract addEntity(de: DataEntity);
  abstract removeEntity(de: DataEntityLayoutModel);
  abstract clearEntities();

  static getModeModel(
    activity: FormActivity,
    mode: FormLayoutModes,
    options?: Partial<FormLayoutModel>
  ) {
    if (FormLayoutModel.modeModels[mode]) {
      return new FormLayoutModel.modeModels[mode](activity, options);
    }
  }

  static initialize() {
    FormLayoutModel.modeModels[
      FormLayoutModes.SingleColumn
    ] = SingleColumnFormLayoutModel;
    FormLayoutModel.modeModels[
      FormLayoutModes.TwoColumn
    ] = TwoColumnFormLayoutModel;
  }

  constructor() {}
}

export class SingleColumnFormLayoutModel extends FormLayoutModel {
  columnEntities: DataEntityLayoutModel[];

  constructor(
    activity: FormActivity,
    options?: Partial<SingleColumnFormLayoutModel>
  ) {
    super();

    // Get the list of entities from the activity if we are switching from one layout to another
    if (activity && activity.model.getEntities) {
      this.columnEntities = activity.model
        .getEntities()
        .map((de: DataEntity, index: number) => {
          return new DataEntityLayoutModel({ entity: de, displayOrder: index });
        });
    }

    if (options) {
      if (options.columnEntities) {
        this.columnEntities = options.columnEntities.map(
          (de: DataEntityLayoutModel, index: number) => {
            return new DataEntityLayoutModel(de);
          }
        );
      }
    }

    if (!this.columnEntities) {
      this.columnEntities = [];
    }
  }

  getEntities(): DataEntity[] {
    if (this.columnEntities) {
      return this.columnEntities.map(de => de && de.entity);
    }

    return [];
  }
  addEntity(de: DataEntity) {
    const entity = this.columnEntities.find(
      e => e.entity.templateCode === de.templateCode
    );
    if (!entity) {
      this.columnEntities.push(
        new DataEntityLayoutModel({
          entity: de,
          displayOrder: this.columnEntities.length
        })
      );
    }
  }
  removeEntity(de: DataEntityLayoutModel) {
    const ceDE = this.columnEntities.find(
      cde => cde.entity.templateCode === de.entity.templateCode
    );

    if (ceDE) {
      const index = this.columnEntities.indexOf(ceDE);

      if (index > -1) {
        this.columnEntities.splice(index, 1);
      }
    }
  }
  clearEntities() {
    this.columnEntities.splice(0, this.columnEntities.length);
  }
}

export class TwoColumnFormLayoutModel extends FormLayoutModel {
  leftColumnEntities: DataEntityLayoutModel[] = [];
  rightColumnEntities: DataEntityLayoutModel[] = [];

  constructor(
    activity: FormActivity,
    options?: Partial<TwoColumnFormLayoutModel>
  ) {
    super();

    // Get the list of entities from the activity if we are switching from one layout to another
    if (activity && activity.model.getEntities) {
      this.leftColumnEntities = activity.model
        .getEntities()
        .map((de: DataEntity, index: number) => {
          return new DataEntityLayoutModel({ entity: de, displayOrder: 0 });
        });
    }

    if (options) {
      if (options.leftColumnEntities) {
        this.leftColumnEntities = options.leftColumnEntities.map(
          (de: DataEntityLayoutModel, index: number) => {
            return new DataEntityLayoutModel(de);
          }
        );
      }

      if (options.rightColumnEntities) {
        this.rightColumnEntities = options.rightColumnEntities.map(
          (de: DataEntityLayoutModel, index: number) => {
            return new DataEntityLayoutModel(de);
          }
        );
      }
    }
  }

  getEntities(): DataEntity[] {
    return (this.leftColumnEntities || [])
      .map(de => de.entity)
      .concat((this.rightColumnEntities || []).map(de => de.entity));
  }

  addEntity(de: DataEntity) {
    const entity = this.leftColumnEntities.find(
      e => e.entity.templateCode === de.templateCode
    );
    if (!entity) {
      this.leftColumnEntities.push(
        new DataEntityLayoutModel({
          entity: de,
          displayOrder: this.leftColumnEntities.length
        })
      );
    }
  }

  removeEntity(de: DataEntityLayoutModel) {
    let ceDE = this.leftColumnEntities.find(
      lde => lde.entity.templateCode === de.entity.templateCode
    );

    if (ceDE) {
      const index = this.leftColumnEntities.indexOf(ceDE);

      if (index > -1) {
        this.leftColumnEntities.splice(index, 1);
      }
    }

    if (!ceDE) {
      ceDE = this.rightColumnEntities.find(
        rde => rde.entity.templateCode === de.entity.templateCode
      );

      if (ceDE) {
        const index = this.rightColumnEntities.indexOf(ceDE);

        if (index > -1) {
          this.rightColumnEntities.splice(index, 1);
        }
      }
    }
  }

  clearEntities() {
    this.leftColumnEntities.splice(0, this.leftColumnEntities.length);
    this.rightColumnEntities.splice(0, this.rightColumnEntities.length);
  }
}

// Static constructor on FormLayoutModel, this needs to be after any LayoutModel definition to make sure they are all defined with this is called
FormLayoutModel.initialize();

export class FormActivityModel extends ActivityModel {
  values: { [key: string]: string };
  formLayout: FormLayoutModes = FormLayoutModes.SingleColumn;
  formLayoutModel: FormLayoutModel = new SingleColumnFormLayoutModel(null);

  constructor(options?: Partial<FormActivityModel>) {
    super(options);

    if (options) {
      Object.assign(this, options);
    }

    this.formLayoutModel = FormLayoutModel.getModeModel(
      null,
      this.formLayout,
      this.formLayoutModel
    );
  }

  getEntities(): DataEntity[] {
    if (this.formLayoutModel) {
      return this.formLayoutModel.getEntities();
    }
    return [];
  }
}

export class FormActivity<
  T extends FormActivityModel = FormActivityModel
> extends ScreenActivity<FormActivityModel> {
  constructor(options?: Partial<FormActivity<T>>) {
    super(options);
    this.type = 'form-activity';
    this.name = 'Form';
    this.description = 'Collect required data via a form';

    if (options) {
      Object.assign(this, options);
    }

    this.model = new FormActivityModel(options ? options.model : null);

    this.model.screenName = this.model.screenName || 'Form';
  }

  public displayOnScreen(): boolean {
    return super.displayOnScreen();
  }

  getValues(): { [key: string]: string } {
    if (!this.model.values) {
      const values: { [key: string]: any } = {};

      (this.model as FormActivityModel).formLayoutModel
        .getEntities()
        .forEach((value: DataEntity, index: number) => {
          if (value.value && value.value !== '') {
            values[value.templateCode] = value.value;
          }
        });

      this.model.values = values;
    }

    return this.model.values;
  }

  addEntity(entity: DataEntity) {
    entity.parent = new ParentReference({ id: this.id });
    if (this.model.formLayoutModel) {
      this.model.formLayoutModel.addEntity(entity);
    }
  }
}

<app-loader *ngIf="!agenda"></app-loader>
<div *ngIf="agenda">
  <div class="row m-1 pb-3">
    <div class="flex-column" *ngIf="agenda.agendaType">
      <h3>{{ agenda.agendaType.name }} Agenda</h3>
    </div>

    <div class="flex-column pl-5">
      <button
        type="button"
        class="btn btn-raised float-right"
        (click)="backToAgendas()"
      >
        Back to Agendas
      </button>
    </div>
    <div class="flex-column pl-4">
      <div *ngIf="canManageAgendas() | async">
        <button
          type="button"
          class="btn btn-raised btn-primary float-right"
          (click)="goToAgendaSettings()"
        >
          Agenda Settings
        </button>
      </div>
    </div>
    <div class="flex-column pl-4">
      <button
        type="button"
        class="btn btn-raised btn-primary float-right"
        (click)="printModal.open()"
        [disabled]="printDisabled"
      >
        Print
      </button>
    </div>
    <div class="flex-column pl-3">
      <div [hidden]="!printing">
        <div class="spinner-border"></div>
        Printing...
      </div>
      <div [hidden]="!showDownloadLink">
        <div>
          <a [attr.href]="sanitize(link.href)" [attr.download]="link.download">
            Download {{ link.download }}
          </a>
        </div>
      </div>
    </div>
  </div>

  <!-- agenda info-->
  <div class="row p-3">
    <div class="col align-left">
      <h4>
        <p>
          {{ agenda.meetingDateTime | date: 'fullDate' }}
        </p>
        <p>
          {{ agenda.meetingDateTime | date: 'h:mm a' }}
        </p>
      </h4>
    </div>
  </div>
  <div class="row pl-3 pb-3" *ngIf="agenda.description">
    <div class="col align-left">
      <i>
        {{ agenda.description }}
      </i>
    </div>
  </div>

  <!-- agenda items -->
  <div
    class="p-1 mt-5"
    *ngIf="agenda.agendaQueueItems && agenda.agendaQueueItems.length > 0"
  >
    <div class="row">
      <div class="col-1 text-center">
        <h5><b>Item #</b></h5>
      </div>
    </div>
    <div class="row" *ngFor="let qi of agenda.agendaQueueItems; index as i">
      <div class="col-1 text-center align-self-center">
        <h3>
          <b>{{ i + 1 }}</b>
        </h3>
      </div>
      <div class="col">
        <div class="card border border-primary">
          <div class="card-body">
            <wm-agenda-queue-item-input
              *ngIf="qi.canEdit"
              [queueItem]="qi"
            ></wm-agenda-queue-item-input>
            <wm-agenda-queue-item-readonly
              *ngIf="!qi.canEdit"
              [queueItem]="qi"
              [selectable]="false"
            ></wm-agenda-queue-item-readonly>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="p-1 mt-5"
    *ngIf="!agenda.agendaQueueItems || agenda.agendaQueueItems.length === 0"
  >
    There are no items on this agenda
  </div>

  <!-- other items -->
  <div *ngIf="agenda.otherItems && agenda.otherItems !== ''">
    <div class="row mt-5 ml-1">
      <h5>Other items:</h5>
    </div>
    <div class="row">
      <div class="col">
        <div class="card border border-primary">
          <div class="card-body">
            <div [innerHTML]="agenda.otherItems"></div>

            <!-- other minutes -->
            <div class="row form-group">
              <div class="col">
                <label for="minutes">Minutes</label>
                <textarea
                  class="form-control border-primary"
                  id="minutes"
                  rows="6"
                  [(ngModel)]="agenda.otherMinutes"
                ></textarea>
              </div>
            </div>

            <button
              type="button"
              class="btn btn-raised btn-success float-right"
              (click)="saveAgenda()"
              [disabled]="isSaving"
            >
              {{ isSaving ? 'Saving...' : 'Save' }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- print modal-->
  <wm-modal-confirm
    title="Print Agenda"
    [small]="true"
    [showButtonText]="false"
    [showButton]="false"
    commandText="Print"
    (accepted)="printAgenda()"
    #printModal
  >
    <div class="switch">
      <label>
        <input
          type="checkbox"
          [(ngModel)]="printRequest.includeMinutes"
          [disabled]="disableOtherControls"
        />
        <div *ngIf="disableOtherControls">
          <span class="text-muted">
            Include Minutes
          </span>
        </div>
        <div *ngIf="!disableOtherControls">
          Include Minutes
        </div>
      </label>
    </div>
    <br />
    <div class="switch">
      <label>
        <input
          type="checkbox"
          [(ngModel)]="printRequest.includeStatus"
          [disabled]="disableOtherControls"
        />
        <div *ngIf="disableOtherControls">
          <span class="text-muted">
            Include Status
          </span>
        </div>
        <div *ngIf="!disableOtherControls">
          Include Status
        </div>
      </label>
    </div>
    <div class="switch" *ngIf="hasOtherItems">
      <br />
      <label>
        <input
          type="checkbox"
          [(ngModel)]="printRequest.includeOtherItems"
          [disabled]="disableOtherControls"
        />
        <div *ngIf="disableOtherControls">
          <span class="text-muted">
            Include Other Items
          </span>
        </div>
        <div *ngIf="!disableOtherControls">
          Include Other Items
        </div>
      </label>
    </div>
    <div class="switch" *ngIf="hasBallots">
      <br />
      <label>
        <input
          type="checkbox"
          [(ngModel)]="printRequest.includeBallots"
          [disabled]="disableOtherControls"
        />
        <div *ngIf="disableOtherControls">
          <span class="text-muted">
            Include Ballots
          </span>
        </div>
        <div *ngIf="!disableOtherControls">
          Include Ballots
        </div>
      </label>
    </div>
    <div class="switch" *ngIf="hasBallots">
      <br />
      <label>
        <input
          type="checkbox"
          [(ngModel)]="printRequest.ballotsOnly"
          (change)="ballotsOnlyChanged()"
        />
        Ballots Only
      </label>
    </div>
  </wm-modal-confirm>
</div>

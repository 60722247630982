import { Component, OnInit, EventEmitter, Input, DoCheck, Output } from '@angular/core';
import { ActivityView } from '../../../../../../views/master-views/app.view/app.view.component';
import { Activity, ActivityModel } from '../../../../../../models/activities';
import { FormGroup } from '@angular/forms';
import { WorkflowContextService } from '../../../../../../services';
import { WorkflowApplication } from '../../../../../../models';

@Component({
  selector: 'wm-form-activity-input-layout-base',
  templateUrl: './form-activity-input-layout-base.component.html',
  styleUrls: ['./form-activity-input-layout-base.component.css']
})
export class FormActivityInputLayoutBaseComponent extends ActivityView
  implements OnInit, DoCheck {
  @Input() isReadOnly: boolean;
  @Output() calculateActivity: EventEmitter<string> = new EventEmitter();

  ngDoCheck(): void {
    this.value$.emit(this.activity);
  }


  constructor() {
    super();
    this.isReadOnly = false;
  }

  ngOnInit() {}
}

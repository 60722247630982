import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'wm-confirm-email-view',
  templateUrl: './confirm-email-view.component.html',
  styleUrls: ['./confirm-email-view.component.css']
})
export class ConfirmEmailViewComponent implements OnInit {
  constructor(private _route: ActivatedRoute) {}
  returnURL: string;

  ngOnInit() {
    this._route.queryParams
      .pipe(filter(parms => parms.returnUrl))
      .subscribe(parms => {
        this.returnURL = parms.returnUrl;
      });
  }
}

import {
  UntypedFormGroup,
  Validators,
  UntypedFormControl
} from '@angular/forms';
import { DataEntity } from './../../../../../models/data-entities/data-entity';
import { ContractorTypeRequirement } from './../../../../../models/contractor-type-requirement';
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
  ViewChild
} from '@angular/core';
import { ModalConfirmComponent } from 'src/app/components/system/modal-confirm/modal-confirm.component';

@Component({
  selector: 'wm-contractor-requirement-editor',
  templateUrl: './contractor-requirement-editor.component.html',
  styleUrls: ['./contractor-requirement-editor.component.css']
})
export class ContractorRequirementEditorComponent implements OnInit, OnDestroy {
  @Input() requirement: ContractorTypeRequirement;
  @Input() entities: DataEntity[];
  @Input() parentForm: UntypedFormGroup;

  @Output() applied: EventEmitter<ContractorTypeRequirement> = new EventEmitter<
    ContractorTypeRequirement
  >();
  @Output() removed: EventEmitter<ContractorTypeRequirement> = new EventEmitter<
    ContractorTypeRequirement
  >();
  @Output() cancelled: EventEmitter<
    ContractorTypeRequirement
  > = new EventEmitter<ContractorTypeRequirement>();

  modifiedRequirement: ContractorTypeRequirement;
  form: UntypedFormGroup;
  @Input() isOpen = false;

  @ViewChild('confirmTemplateCodeChange')
  confirmTemplateCodeChange: ModalConfirmComponent;
  @ViewChild('deleteConfirmModal') deleteConfirmModal: ModalConfirmComponent;

  get label(): string {
    if (this.requirement) {
      return `${this.requirement.label || ''} {${this.requirement
        .templateCode || ''}}`;
    } else {
      return '';
    }
  }

  get formId() {
    return `requirement-${this.requirement.id}`;
  }

  get dataEntityLabel(): string {
    const entity = this.entities
      ? this.entities.find(
          de => de.templateCode === this.requirement.templateCode
        )
      : null;

    return (entity && entity.label) || this.requirement.templateCode;
  }

  constructor() {}

  ngOnInit() {
    if (this.isOpen) {
      this.edit();
    }

    this.form = new UntypedFormGroup({
      label: new UntypedFormControl(
        this.requirement.label,
        Validators.required
      ),
      entity: new UntypedFormControl(
        this.requirement.templateCode,
        Validators.required
      )
    });

    this.parentForm.addControl(this.formId, this.form);
  }

  ngOnDestroy() {
    this.parentForm.removeControl(this.formId);
  }

  openDeleteConfirmModal() {
    this.deleteConfirmModal.open();
  }

  deleteCancel() {
    this.deleteConfirmModal.cancel();
  }

  remove() {
    this.removed.emit(this.requirement);
  }

  cancel() {
    this.isOpen = false;
    this.cancelled.emit(this.requirement);
  }

  edit() {
    this.modifiedRequirement = { ...this.requirement };
    this.isOpen = true;
  }

  attemptApply() {
    if (
      this.requirement.templateCode &&
      this.requirement.templateCode !== '' &&
      this.modifiedRequirement.templateCode !== this.requirement.templateCode
    ) {
      this.confirmTemplateCodeChange.open();
    } else {
      this.apply();
    }
  }

  confirmTemplateCodeCancel() {
    this.confirmTemplateCodeChange.cancel();
  }

  apply() {
    Object.assign(this.requirement, this.modifiedRequirement);
    this.isOpen = false;
    this.applied.emit(this.requirement);
  }
}

import { Component, OnInit, EventEmitter, ViewChild } from '@angular/core';
import { ActivityView } from '../../../../../views/master-views/app.view/app.view.component';
import { WorkflowContextService } from '../../../../../services';
import { FormGroup } from '@angular/forms';
import { Activity, ActivityModel } from '../../../../../models/activities';
import { FormActivityInputViewComponent } from '../../form-activity-editor/form-activity-input/form-activity-input-view/form-activity-input-view.component';

@Component({
  selector: 'wm-contractor-info-form-activity-input',
  templateUrl: './contractor-info-form-activity-input.component.html',
  styleUrls: ['./contractor-info-form-activity-input.component.css']
})
export class ContractorInfoFormActivityInputComponent extends ActivityView
  implements OnInit {

  @ViewChild('formActivityInputView', { static: false })
  formActivityInputViewCtl: FormActivityInputViewComponent;

  constructor() {
    super();
  }

  ngOnInit() {}

  async persistChildComponent() {
    return this.formActivityInputViewCtl.persistChildComponent();
  }
}

<form [formGroup]="form" novalidate>
  <div class="form-group">
    <div class="card">
      <div class="card-body">
        <p>Print Template Layout</p>
        <select
          *ngIf="mapsketchDataEntity.screenCaptureLayoutMethod"
          class="form-control"
          aria-label="Print Template Layout"
          name="frmPrintTemplateLayout"
          formControlName="PrintTemplateLayoutControl"
          (change)="printTemplateLayout_ChangeSelection($event)"
        >
          <option *ngFor="let l of ScreenCaptureLayoutMethods" [value]="l">
            {{ ScreenCaptureLayoutMethod_Options[l] }}
          </option>
        </select>
      </div>
    </div>
  </div>
</form>
<app-map-display-configuration-editor
  [client]="client"
  [mapConfig]="mapsketchDataEntity.mapConfig"
  [mapServiceId]="mapsketchDataEntity.mapConfig.mapServiceId"
  [mapDimensionLayout_class]="mapDimensionLayout_class"
>
</app-map-display-configuration-editor>

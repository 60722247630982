import { DataEntity } from './data-entity';

export class XDataEntity extends DataEntity {
  constructor(options?: Partial<XDataEntity>) {
    super(options);

    this.dataEntityTypeCode = 'xdata';
    this.dataEntityTypeDescription = 'This Data Entity Returns the XData.';
    this.dataEntityTypeName = 'X Data Entity';

    if (options) {
      Object.assign(this, options);
    }
  }
}

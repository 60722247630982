import {
  Component,
  OnInit,
  Inject,
  forwardRef,
  ComponentFactoryResolver
} from '@angular/core';
import { DataEntityViewComponent } from '../../data-entity-view/data-entity-view.component';
import { DocumentDataEntityViewComponent } from '../../document-data-entity/document-data-entity-view/document-data-entity-view.component';
import {
  DocumentService,
  WorkflowContextService
} from '../../../../../services';
// import { updateClassProp } from '@angular/core/src/render3/styling';

@Component({
  selector: 'wm-contractor-document-data-entity-view',
  templateUrl: './contractor-document-data-entity-view.component.html',
  styleUrls: ['./contractor-document-data-entity-view.component.css']
})
export class ContractorDocumentDataEntityViewComponent
  extends DocumentDataEntityViewComponent
  implements OnInit {
  constructor(
    componentFactoryResolver: ComponentFactoryResolver,
    @Inject(forwardRef(() => DocumentService)) uploadService: DocumentService,
    @Inject(forwardRef(() => WorkflowContextService))
    context: WorkflowContextService
  ) {
    super(componentFactoryResolver, uploadService, context);
  }

  ngOnInit() {}
}

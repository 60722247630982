import { DataEntity } from './data-entity';

export class GeoJSONDataEntity extends DataEntity {
  constructor(options?: Partial<GeoJSONDataEntity>) {
    super(options);

    this.dataEntityTypeCode = 'geojsondata';
    this.dataEntityTypeDescription =
      'This Data Entity Returns the GeoJSONData.';
    this.dataEntityTypeName = 'GeoJSON Data Entity';

    if (options) {
      Object.assign(this, options);
    }
  }
}

import { ToastrService } from 'ngx-toastr';
import { DataService } from './../../../services/data.service';
import { Component, OnInit, Input } from '@angular/core';
import { Contact } from '../../../models';
import { Router } from '@angular/router';
import { WorkflowContextService } from 'src/app/services';

@Component({
  selector: 'wm-contacts-list',
  templateUrl: './contacts-list.component.html',
  styleUrls: ['./contacts-list.component.css']
})
export class ContactsListComponent implements OnInit {
  @Input() contacts: Contact[];
  constructor(
    private _router: Router,
    public _context: WorkflowContextService,
    private _service: DataService,
    private toastr: ToastrService
  ) {}

  ngOnInit() {}

  async orderChange() {
    await Promise.all(
      this.contacts.map(async (contact, i) => {
        contact.position = i;

        const newContact = await this._service.saveContact(contact).toPromise();

        return newContact;
      })
    );

    this.toastr.success('Saved!');
  }

  editContact(contact: Contact) {
    this._router.navigate([
      '/admin/jurisdiction/',
      this._context.client.id,
      'settings',
      'contacts',
      'edit',
      contact.id
    ]);
  }
}

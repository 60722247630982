import { ResultField, ParcelDataConnection } from '../../models';
import {
  Activity,
  ScreenActivity,
  ActivityModel
} from '../../models/activities/activity';
import { DataEntity } from '../data-entities';
import { MapDisplayConfiguration } from 'src/app/components/arcgis/map-display-configuration';

export class SearchParcelDataActivityModel extends ActivityModel {
  message = '';
  selectedParcel: { [key: string]: string };
  selectedGeometryWkt: string = null;
  mapConfig: MapDisplayConfiguration = new MapDisplayConfiguration();
  hasMap = false;
  isRequired = false;
  parcelId: string;

  constructor(options?: Partial<SearchParcelDataActivityModel>) {
    super(options);
    if (options) {
      Object.assign(this, options);
    }
  }

  getEntities() {
    return new ParcelDataConnection().resultFields.map(
      (value: ResultField, index: number) =>
        new DataEntity({
          templateCode: (
            (this.screenName || '') +
            '.' +
            value.field
          ).toLowerCase(),
          label: value.alias || value.field
        })
    );
  }
}

export class SearchParcelDataActivity extends ScreenActivity<
  SearchParcelDataActivityModel
> {
  parcelId: string;
  parcelOwner: string;
  parcelAddress: string;
  resultFields: ResultField[];
  selectedData: any;

  constructor(options: { [key: string]: any }) {
    super(options);
    const modelOptions = options != null ? options.model : null;
    this.model = new SearchParcelDataActivityModel(modelOptions);
    this.name = 'Parcel search';
    this.description = 'Search for a parcel to populate application fields';
    this.type = 'search-parcel-data-activity';

    this.model.screenName = this.model.screenName || 'Parcel search';

    this.resultFields = [{ alias: 'Parcel #', field: 'PIN' }];
  }

  public displayOnScreen(): boolean {
    return true;
  }
}

import { Component, OnInit, EventEmitter, ViewChild } from '@angular/core';
import { ActivityView } from '../../../../../views/master-views/app.view/app.view.component';
import { Activity, ActivityModel } from '../../../../../models/activities';
import { FormGroup } from '@angular/forms';
import { WorkflowContextService } from '../../../../../services';
import { FormActivityInputViewComponent } from '../../form-activity-editor/form-activity-input/form-activity-input-view/form-activity-input-view.component';

@Component({
  selector: 'wm-inspection-request-activity-input',
  templateUrl: './inspection-request-activity-input.component.html',
  styleUrls: ['./inspection-request-activity-input.component.css']
})
export class InspectionRequestActivityInputComponent extends ActivityView
  implements OnInit {

  @ViewChild('formActivityInputView', { static: false })
  formActivityInputViewCtl: FormActivityInputViewComponent; 
  
  constructor() {
    super();
  }

  ngOnInit() {
    const f = this.form;
  }

  async persistChildComponent() {
    return this.formActivityInputViewCtl.persistChildComponent();
  }
}

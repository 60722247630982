import { Component, OnInit, ViewChild } from '@angular/core';
import { WorkflowContextService } from './services';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
import { PlatformLocation } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import initSwitcher from 'favicon-switcher';
import * as FullStory from '@fullstory/browser';
import { environment } from '../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { ModalConfirmComponent } from './components/system/modal-confirm/modal-confirm.component';
declare var gtag: Function;

initSwitcher();

@Component({
  selector: 'wm-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'app-cli';

  @ViewChild('updateVersion', { static: true }) updateVersion: ModalConfirmComponent;

  constructor(
    private _context: WorkflowContextService,
    private route: ActivatedRoute,
    private router: Router,
    private location: PlatformLocation,
    private modalService: NgbModal,
    private titleService: Title,
    private _toastrSvc: ToastrService
  ) {
    FullStory.init({
      orgId: 'TFM44',
      devMode: !environment.production
    });
  }

  ngOnInit() {
    // close all modals on state change
    this.location.onPopState(() => {
      this.modalService.dismissAll();
    });
    this.router.events
      .pipe(
        filter((event, index) => event instanceof NavigationEnd),
        map(() => this.route),
        map(route => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter(route => route.outlet === 'primary'),
        mergeMap(route => route.data)
      )
      .subscribe(event => {
        gtag('config', 'G-8WXD7G4EVD', { page_path: event.urlAfterRedirects });

        let title = 'GeoPermits';

        if (event['title']) {
          title = `${title} - ${event['title']}`;
        }
        this.titleService.setTitle(title);
      });

    this.route.queryParams.subscribe(params => {
      let returnUrl = params['returnUrl'];

      if (this._context) {
        this._context.isLoggedIn$.subscribe((loggedIn: boolean) => {
          if (loggedIn && returnUrl) {
            if (returnUrl.startsWith('http')) {
              let qs = '';
              let qsStart = returnUrl.indexOf('?');
              if (qsStart > -1) {
                qs = `${returnUrl.substring(qsStart)}&userName=${
                  this._context.user.userName
                }`;
              } else {
                qs = `?userName=${this._context.user.userName}`;
                qsStart = returnUrl.length;
              }
              window.location.href = `${returnUrl.substring(0, qsStart)}${qs}`;
            } else {
              this.router.navigate([returnUrl]);
            }
            returnUrl = '';
          } else if (!loggedIn) {
            this.router.navigate(['/']);
          }
        });
      }
    });

    this._context.needsReload$.subscribe(result => {
      if (result) {
        this.updateVersion.open();
      }
    });
  }

  reloadSite() {
    window.location.href = window.location.href;
  }
}

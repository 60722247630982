import { Component, Input, OnInit, ChangeDetectorRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { PaymentProcessorConfigurationBase } from '../../../models';

@Component({
  selector: 'wm-payment-processor-config-base',
  templateUrl: './payment-processor-config-base.component.html',
  styleUrls: ['./payment-processor-config-base.component.css']
})
export class PaymentProcessorConfigBaseComponent implements OnInit {
  @Input() processorConfig: PaymentProcessorConfigurationBase;
  @Input() form: UntypedFormGroup;

  _fb: UntypedFormBuilder;

  constructor(fb: UntypedFormBuilder) {
    this._fb = fb;
  }

  addValidation(name: string, validator?: any, initialValue?: any) {
    this.form.addControl(name, this._fb.control(initialValue || '', validator));
  }

  ngOnInit() {
    this.addValidation('url', Validators.required);
  }
}

import { Utilities } from './../../../../services/utilities/index';
import { ToastrService } from 'ngx-toastr';
import { ClientService } from './../../../../services/client.service';
import { CustomList, CustomListItem } from './../../../../models/custom-list';
import { ActivatedRoute, Router } from '@angular/router';
import { ListOption } from './../../../../models/listOption';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { WorkflowContextService } from './../../../../services/workflow-context.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-custom-list-detail-view',
  templateUrl: './custom-list-detail-view.component.html',
  styleUrls: ['./custom-list-detail-view.component.css']
})
export class CustomListDetailViewComponent implements OnInit {
  customListId: string;
  customList: CustomList;
  form: UntypedFormGroup;

  constructor(
    public _context: WorkflowContextService,
    private _clientSvc: ClientService,
    private _router: Router,
    private toastr: ToastrService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.form = new UntypedFormGroup({
      name: new UntypedFormControl(null, [Validators.required])
    });

    this.route.params.subscribe(params => {
      this.customListId = params['id'];

      if (this.customListId) {
        this._clientSvc.getCustomList(this.customListId).subscribe(list => {
          this.customList = list;
        });
      } else {
        this.customList = {
          id: Utilities.EMPTY_GUID,
          name: null,
          clientId: this._context.client.id,
          items: []
        };
      }
    });
  }

  public remove() {
    this._clientSvc.deleteCustomList(this.customListId).subscribe(res => {
      this.toastr.success('Removed!');
      this.goBack();
    });
  }

  public removeItem(item) {
    this._clientSvc
      .deleteCustomListItem(this.customList.id, item.id)
      .subscribe(res => {
        this.toastr.success('Item removed');
      });
  }

  public save() {
    for (const item of this.customList.items) {
      item.customListId = this.customList.id;
    }

    const list: CustomList = {
      id: this.customList.id,
      clientId: this.customList.clientId,
      name: this.customList.name,
      items: this.customList.items.map(
        (item): CustomListItem => ({
          id: item.id,
          customListId: this.customList.id,
          position: item.position,
          text: item.text,
          value: item.value
        })
      )
    };

    this._clientSvc.saveCustomList(list).subscribe(res => {
      this.toastr.success('Saved!');
      this.goBack();
    });
  }

  public goBack() {
    this._router.navigate([
      'admin',
      'jurisdiction',
      this._context.client.id,
      'settings',
      'lists'
    ]);
  }

  public canRemove() {
    return this.customList.id !== Utilities.EMPTY_GUID;
  }
}
